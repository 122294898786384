import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import ButtonGeneral from 'components/ButtonGeneral';
import { MAX_TEMPLATES_COUNT_RESTRICTION } from 'constants/accountSettings';
import { useRolePermission } from 'hooks/useRolePermission';
import { RootStateType } from 'store/reducers';

interface IDocumentSubHeader {
  title: string;
  buttonText: string;
  buttonLink: string;
  secondButtonText?: string;
  secondButtonLink?: string;
}

const DocumentsSubHeader = ({
  title,
  buttonText,
  buttonLink,
  secondButtonText,
  secondButtonLink,
}: IDocumentSubHeader) => {
  const history = useHistory();
  const { documentsCount } = useSelector((state: RootStateType) => state.user);
  const { isUserReadOnly, maxTemplatesNumber, permissionUsingSignNow } = useRolePermission();

  const onClickButtonHandler = (link: string) => {
    if (maxTemplatesNumber && maxTemplatesNumber <= documentsCount) {
      toast.info(MAX_TEMPLATES_COUNT_RESTRICTION);
    } else {
      history.push(link);
    }
  };

  const canCreateSingleDocument = secondButtonText
    && secondButtonLink
    && !maxTemplatesNumber
    && !permissionUsingSignNow;

  return (
    <div className="documents-sub-header">
      <div className="container">
        <div className="row flex-column w-75 mx-auto">
          <div
            className={'col-12 mx-auto d-flex justify-content-center'
              + 'justify-content-md-start pl-1 documents-sub-header_item-wrapper'}
          >
            <h3 className="documents-sub-header_title">{title}</h3>
          </div>
          {
            !isUserReadOnly && (
              <div
                className={'col-12 mx-auto gap-2 gap-md-4 d-flex flex-wrap justify-content-center'
                  + 'justify-content-md-start pl-1 documents-sub-header_item-wrapper'}
              >
                <ButtonGeneral
                  buttonText={buttonText}
                  onClickCallback={() => onClickButtonHandler(buttonLink)}
                  additionalClassName={'documents-sub-header_button d-flex justify-content-center '
                    + 'align-items-center w-auto'}
                  block
                />
                {
                  canCreateSingleDocument && (
                    <ButtonGeneral
                      buttonText={secondButtonText}
                      onClickCallback={() => history.push(secondButtonLink)}
                      additionalClassName={'documents-sub-header_button documents-sub-header_button--secondary '
                        + 'd-flex justify-content-center align-items-center w-auto'}
                      block
                    />
                  )
                }
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default DocumentsSubHeader;