import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useDispatch } from 'react-redux';

import { setCarbonCopyUsersAction } from 'store/actions/sendDocuments';
import { SignersInfoType, SignerType } from 'types/SendDocuments';
import { getNewUserCard } from 'utils/signNow/signNowHookHelpers';

interface ICarbonCopyContext {
  carbonCopyUsers: SignersInfoType[];
  setCarbonCopyUsers: Dispatch<SetStateAction<SignersInfoType[]>>;
  addCarbonCopyHandler: () => void;
}

const CarbonCopyContext = createContext<ICarbonCopyContext>({
  carbonCopyUsers: [],
  setCarbonCopyUsers: () => {
    throw new Error('Function setCarbonCopyUsers must be implemented');
  },
  addCarbonCopyHandler: () => {
    throw new Error('Function setCarbonCopyUsers must be implemented');
  },
});

export const useCarbonCopy = () => useContext(CarbonCopyContext);

export const CarbonCopyProvider: FC = ({ children }) => {
  const dispatch = useDispatch();
  const [carbonCopyUsers, setCarbonCopyUsers] = useState<SignersInfoType[]>([]);

  useEffect((): void => {
    dispatch(setCarbonCopyUsersAction(carbonCopyUsers));
  }, [dispatch, carbonCopyUsers]);

  const addCarbonCopyHandler = useCallback((): void => {
    if (carbonCopyUsers.length < 5) {
      setCarbonCopyUsers((prevCopyUsersState) => [
        ...prevCopyUsersState,
        {
          ...getNewUserCard({
            position: prevCopyUsersState.length,
            label: 'CC',
            type: SignerType.CC_RECIPIENT,
          }),
        },
      ]);
    }
  }, [carbonCopyUsers]);

  const value: ICarbonCopyContext = useMemo(() => ({
    carbonCopyUsers,
    setCarbonCopyUsers,
    addCarbonCopyHandler,
  }), [carbonCopyUsers, setCarbonCopyUsers, addCarbonCopyHandler]);

  return (
    <CarbonCopyContext.Provider value={value}>
      {children}
    </CarbonCopyContext.Provider>
  );
};