import { CSSProperties } from 'react';

import { IFiles } from 'types/PublicPage';

const NotUploadedFilesListRender = ({
  currentAttachments,
  fileNameClass,
  style,
  isPDFDocument = false,
}: {
  currentAttachments: IFiles[];
  fileNameClass: string;
  style: CSSProperties;
  isPDFDocument: boolean;
}) => {
  if (!currentAttachments?.length) return null;
  return (
    <>
      {
        currentAttachments.map((file: IFiles) => (
          <div className="dz-preview" key={file.upload.filename}>
            <div
              className={`dz-message${isPDFDocument ? '' : ' mx-2 py-1'}`}
            >
              <span className={fileNameClass} style={style}>{file.upload.filename}</span>
            </div>
          </div>
        ))
      }
    </>
  );
};

export default NotUploadedFilesListRender;