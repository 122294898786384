import { ICollectionDetails, ICollectionTemplate } from 'types/MultiTemplate';
import { FieldTypeOption, PDFFieldTypeObject } from 'types/PdfTemplates';
import { IPublicPageFormBuilderFields } from 'types/PublicPage';
import { ITemplateDetails } from 'types/Templates';
import { getIsPDFDocument } from 'utils/PublicPage/documentTypeChecker';
import { getFieldsFromChildrenAndUpdate } from 'utils/PublicPage/getFieldsFromData';

type UpdatedTemplatesForFormBuilderType = (
  data: ICollectionDetails,
  formBuilderFields: IPublicPageFormBuilderFields
) => ICollectionTemplate[];

const getUpdatedPDF = (
  pdfFieldsJson: PDFFieldTypeObject | null,
  formBuilderFields: IPublicPageFormBuilderFields,
): PDFFieldTypeObject => {
  if (!pdfFieldsJson) return {};
  return Object.fromEntries(
    Object
      .entries(pdfFieldsJson)
      .map(([key, field]) => {
        if (formBuilderFields[Number(key)]) {
          const updatedField = {
            ...field,
            ...formBuilderFields[Number(key)],
            options: field.options?.map((option: FieldTypeOption, index: number) => {
              const optionsFromFormBuilder = formBuilderFields[Number(key)].options;
              return {
                ...option,
                ...(optionsFromFormBuilder ? optionsFromFormBuilder[index] : {}),
                coords: option.coords,
              };
            }),
          };
          return [String(key), updatedField];
        }
        return [String(key), field];
      }),
  );
};

export const getUpdatedTemplateForFormBuilder = (
  template: ITemplateDetails,
  formBuilderFields: IPublicPageFormBuilderFields,
) => {
  if (getIsPDFDocument(template.type)) {
    return {
      ...template,
      pdf_fields_json: {
        ...template.pdf_fields_json,
        ...getUpdatedPDF(template.pdf_fields_json, formBuilderFields),
      },
    };
  }
  return {
    ...template,
    sections: template.sections.map((section) => ({
      ...section,
      section: {
        ...section.section,
        content_json: getFieldsFromChildrenAndUpdate(
          section.section.content_json || [],
          (node) => {
            if (formBuilderFields[Number(node.key)]) {
              return {
                ...node,
                ...formBuilderFields[Number(node.key)],
              };
            }
            return node;
          },
        ),
      },
    })),
  };
};

export const getUpdatedTemplatesForFormBuilder: UpdatedTemplatesForFormBuilderType = (
  data,
  formBuilderFields,
) => (
  data.templates?.map((template: ICollectionTemplate) => ({
    ...template,
    template: {
      ...template.template,
      ...getUpdatedTemplateForFormBuilder(template.template, formBuilderFields),
    },
  })) || []
);

type UpdatedCollectionWithFormBuilderFunctionType = (
  data: ICollectionDetails,
  formBuilderFields: IPublicPageFormBuilderFields
) => ICollectionDetails;

export const getUpdatedCollectionWithFormBuilder: UpdatedCollectionWithFormBuilderFunctionType = (
  data,
  formBuilderFields,
) => {
  const formBuilderFieldsKeys = Object.keys(formBuilderFields).map(Number);

  const updatedTemplatesFromFormBuilder = getUpdatedTemplatesForFormBuilder(data, formBuilderFields);

  return {
    ...data,
    form_builder_structure: data.form_builder_structure?.map((section) => ({
      ...section,
      fields: section.fields.map((field) => {
        if (formBuilderFieldsKeys.includes(field.key ?? 0)) {
          return {
            ...field,
            ...formBuilderFields[field.key ?? 0],
          };
        }
        return field;
      }),
    })),
    templates: updatedTemplatesFromFormBuilder,
  };
};

export default undefined;