/* eslint-disable */
import { Editor as SlateEditor, Range, Transforms } from 'slate';
import { insertImage, isImageUrl } from 'utils/editorImageHelpers';
import { isElementInEditor } from 'utils/editorHelpers';
import { IMAGE_TYPE, PARAGRAPH } from 'constants/editors';

export const withImages = (editor: SlateEditor) => {
  const { deleteForward, insertData, isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === IMAGE_TYPE ? true : isVoid(element);
  }

  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (text.length && data.files && data.items.length !== data.files.length) {
      insertData(data);
    } else if (data.files && data.files.length > 0) {
      for (const file of Array.from(data.files)) {
          const reader = new FileReader();
          const [mime] = file.type.split('/');
          if (mime === 'image') {
            reader.readAsDataURL(file);
            reader.onload = (readerEvent) => {
              const image: HTMLImageElement = new Image();
              image.src = String(readerEvent.target?.result) || '';
                image.onload = () => {
                  const canvas = document.createElement('canvas');
                  const context = canvas.getContext('2d');
                  canvas.width = image.width;
                  canvas.height = image.height;
                  context?.drawImage(image, 0, 0, image.width, image.height);
                  const base64String = context?.canvas.toDataURL('image/jpeg', 0.5);
                  insertImage(editor, 'image', { url: String(base64String), alt: '' });
                }
            }
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, 'image', { url: text, alt: '' });
    } else {
      insertData(data);
    }
  }

  editor.deleteForward = (...args) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const contentAfter = SlateEditor.after(editor, selection.anchor, { unit: 'block' });
      if (contentAfter) {
        Transforms.move(editor, { distance: 1, unit: 'character' });
        const shouldDenyDeleting = isElementInEditor(editor, IMAGE_TYPE);
        Transforms.move(editor, { distance: 1, unit: 'character', reverse: true });
        if (shouldDenyDeleting) {
          if (selection.focus.offset === 0 && isElementInEditor(editor, PARAGRAPH)) {
            // the first empty paragraph deleting
            Transforms.removeNodes(editor);
          }
          return;
        }
      }
    }

    deleteForward(...args);
  };

  return editor
}