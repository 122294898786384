import { FC } from 'react';

import { Button as MuiButton } from '@mui/material';

import StatusIconWrapper from 'components/Base/StatusIconWrapper';
import IntroAttachmentsList from 'components/Intro/IntroAttachmentsList';
import useRedirectToPublicPage from 'hooks/useRedirectToPublicPage';
import { IPublicPageDocumentStructure } from 'types/PublicPage';

import classes from 'scss/components/Intro/DocumentItem.module.scss';

export interface IDocumentItem {
  document: IPublicPageDocumentStructure;
}

export const DocumentItem: FC<IDocumentItem> = ({ document }) => {
  const redirectTo = useRedirectToPublicPage(document.id);
  return (
    <>
      <MuiButton
        className="document-link"
        startIcon={<StatusIconWrapper status={document.status} />}
        onClick={redirectTo}
      >
        <span className={classes.Name}>
          {document.name}
        </span>
        <span className={classes.ArrowIcon} />
      </MuiButton>
      <IntroAttachmentsList document={document} />
    </>
  );
};

export default DocumentItem;