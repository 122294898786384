import moment from 'moment';

import { DATE_FIELD_FORMAT } from 'constants/dateField';

export const getSubscriptionEndsMessage = (subEndDate: string) => (
  `Your trial ends ${moment(subEndDate).format(DATE_FIELD_FORMAT)}! You will be automatically charged.`
);

export const getSubscriptionEndsTooltipTitle = (subEndDate: string) => (
  `Your trial ends ${moment(subEndDate).format(DATE_FIELD_FORMAT)}`
);