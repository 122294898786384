import { useSelector } from 'react-redux';

import { ATTACHMENTS_TAB_ID, DOCUMENT_TAB_ID, FORM_TAB_ID } from 'constants/tabs';
import useReadOnlyMode from 'hooks/useReadOnlyMode';
import { RootStateType } from 'store/reducers';
import { IPublicPageDocumentStructure, IPublicPageState } from 'types/PublicPage';
import {
  getIsCollectionFormBuilder,
  getIsFormBuilder,
  getIsFormDocument,
  getIsPDFDocument,
} from 'utils/PublicPage/documentTypeChecker';
import getIsTheLastDocumentAndAllOthersDone from 'utils/PublicPage/getIsTheLastDocumentAndAllOthersDone';

const usePublicPageState = () => {
  const isReadOnlyMode = useReadOnlyMode();

  const { data, structure }: IPublicPageState = useSelector((state: RootStateType) => state.publicPages);
  const {
    documentType,
    activeEditorTab,
    totalIndexSlides,
    currentSlideIndex,
    attachmentFields,
    emptyDocument,
  }: IPublicPageDocumentStructure = useSelector((state: RootStateType) => state.publicPages.currentDocument);

  const isPDFDocument = getIsPDFDocument(documentType);
  const isFormDocument = getIsFormDocument(documentType);
  const isCollectionFormBuilder = getIsCollectionFormBuilder(data);
  const isFormBuilder = getIsFormBuilder(data?.view_mode);

  const isFormsView = activeEditorTab === FORM_TAB_ID;
  const isAttachmentsView = activeEditorTab === ATTACHMENTS_TAB_ID;
  const isDocumentView = activeEditorTab === DOCUMENT_TAB_ID;
  const isLastSlide = currentSlideIndex === totalIndexSlides;
  const isTheLastDocumentAndAllOthersDone = getIsTheLastDocumentAndAllOthersDone(structure);

  const currentAttachmentFieldsLength = attachmentFields?.filter((field) => (
    field.assignment === structure.main.mainAssignment
  )).length;

  const shouldShowPublicTabNavigation = !emptyDocument
    && !isFormDocument
    && !isReadOnlyMode
    && !isCollectionFormBuilder
    && !isFormBuilder;
  const shouldShowNextButton = isFormsView && !isLastSlide;
  const shouldShowAttachmentsButton = isDocumentView && currentAttachmentFieldsLength > 0;
  const shouldShowFinishDocumentButton = (isDocumentView && currentAttachmentFieldsLength === 0)
    || (isFormsView && isLastSlide)
    || isAttachmentsView
    || isReadOnlyMode;

  return {
    isPDFDocument,
    isFormDocument,
    isFormsView,
    isReadOnlyMode,
    emptyDocument,
    shouldShowNextButton,
    shouldShowAttachmentsButton,
    shouldShowFinishDocumentButton,
    shouldShowPublicTabNavigation,
    isTheLastDocumentAndAllOthersDone,
  };
};

export default usePublicPageState;