import { FC, useCallback } from 'react';

import { arrayMove, SortEnd, SortEndHandler } from 'react-sortable-hoc';

import SignerCard from 'components/SendDocuments/SignerCard';
import SortableListContainer from 'components/SortableListContainer';
import { ISelectSigners, SignersInfoType } from 'types/SendDocuments';

const SignersContainer: FC<ISelectSigners> = ({
  signersArray,
  setSignersArray,
  canDeleteCard = false,
  isOrderVisible = false,
  isDisabled = false,
  isSignNow = false,
}) => {
  const handlerDeleteButton = useCallback((index: number): void => {
    const updatedSigner = signersArray
      .filter((_, idx) => idx !== index)
      .map((signer, idx) => ({
        ...signer,
        position: idx,
      }));
    setSignersArray(updatedSigner);
  }, [setSignersArray, signersArray]);

  const handlerSaveSignerInfo = useCallback((currentIndex: number, updatedUser: Partial<SignersInfoType>): void => {
    setSignersArray((prevState: SignersInfoType[]) => prevState
      .map((signer: SignersInfoType, index: number): SignersInfoType => {
        if (index === currentIndex) {
          if (isSignNow) {
            const userName = updatedUser.firstName ?? signer.firstName;
            const lastName = updatedUser.lastName ?? signer.lastName;
            const fullName = `${userName} ${lastName}`.trim();
            return {
              ...signer,
              ...updatedUser,
              label: fullName || 'Signer',
            };
          }
          return { ...signer, ...updatedUser };
        }
        return signer;
      }));
  }, [signersArray]);

  const onSortEnd: SortEndHandler = (sort: SortEnd): void => {
    const updatedSignersList: SignersInfoType[] = arrayMove(signersArray, sort.oldIndex, sort.newIndex)
      .map((signer: SignersInfoType, index: number): SignersInfoType => ({ ...signer, position: index }));
    setSignersArray(updatedSignersList);
  };

  return (
    <SortableListContainer
      lockAxis="y"
      onSortEnd={onSortEnd}
      distance={1}
    >
      <div>
        {
          signersArray.map((signer: SignersInfoType, index: number) => (
            <div
              className="position-relative"
              key={`SelectSignerNumber${index + 1}`}
            >
              {
                isOrderVisible && <div className="select-signers-order">{index + 1}</div>
              }
              <SignerCard
                index={index}
                signer={signer}
                indexPosition={index}
                onChange={handlerSaveSignerInfo}
                onDelete={handlerDeleteButton}
                canDeleteCard={canDeleteCard}
                isDisabled={isDisabled}
                isSignNow={isSignNow}
              />
            </div>
          ))
        }
      </div>
    </SortableListContainer>
  );
};

export default SignersContainer;