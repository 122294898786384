import React from 'react';

import { useHistory } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

export interface IModalContentWindowProps {
  showModal: boolean;
  onCloseModal: (show: boolean) => void;
  titleText?: string;
  redirectTo?: string;
  showDefaultText?: boolean;
  goBack?: boolean;
  centered?: boolean;
  showCloseButton?: boolean;
}

const ModalContentWindow: React.FC<IModalContentWindowProps> = ({
  children,
  showModal,
  onCloseModal,
  titleText = 'Message',
  redirectTo = '',
  showDefaultText = true,
  goBack = false,
  centered = false,
  showCloseButton = true,
}) => {
  const history = useHistory();

  const handlerCloseModal = (): void => {
    onCloseModal(false);
    if (redirectTo.length) {
      history.push(redirectTo);
    } else if (goBack) {
      history.goBack();
    }
  };

  return (
    <Modal isOpen={showModal} centered={centered}>
      <ModalHeader toggle={showCloseButton ? handlerCloseModal : undefined}>
        {titleText}
      </ModalHeader>
      <ModalBody>
        {children}
        {showDefaultText ? <div>Please, contact a manager.</div> : ''}
      </ModalBody>
    </Modal>
  );
};

export default ModalContentWindow;