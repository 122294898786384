/* eslint-disable */
import { Editor } from "slate";
import isUrl from "is-url";
import { wrapLink } from "utils/editorLinkHelpers";

export const withLinks = (editor: Editor) => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element) => {
    return element.type === 'link' ? true : isInline(element);
  }

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, 'link', { url: text, blank: false });
    } else {
      insertText(text);
    }
  }

  editor.insertData = data => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, 'link', { url: text, blank: false });
    } else {
      insertData(data);
    }
  }

  return editor
}