import { Element as SlateElement } from 'slate';

import { PublicPageDataType } from 'types/PublicPage';
import { getIsPDFDocument } from 'utils/PublicPage/documentTypeChecker';
import getFieldsFromData from 'utils/PublicPage/getFieldsFromData';

export const doesDocumentIncludeDocAttachments = (document: PublicPageDataType): boolean => {
  const fields: Partial<SlateElement>[] = getFieldsFromData({
    data: document,
    isPDF: getIsPDFDocument(document.type),
  });

  const attachmentValues: string[] = [];

  fields.forEach(({ value }: Partial<SlateElement>) => {
    if (value) {
      attachmentValues.push(...value.split(','));
    }
  });

  return Boolean(attachmentValues.find((value: string) => (
    value.endsWith('.doc')
  )));
};

export default undefined;