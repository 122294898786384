import { useSlate } from 'slate-react';

import CustomScrollbar from 'components/CustomScrollbar';
import { DROPDOWN_TYPES, NUMBERED_LIST_VARIANTS } from 'constants/editors';
import { BlockFormatType, BlockTextAlignType, IDropdownItem, IToolbarDropdown, MarkFormatType } from 'types/Editor';
import { isBlockActive, isListTypeActive } from 'utils/editorBlockHelpers';
import { isFontTypeActive } from 'utils/editorFontTypeHelpers';
import { isMarkActive } from 'utils/editorMarkHelpers';
import { isTextAlignActive } from 'utils/editorTextAlignHelpers';
import { addPropertiesToText, getDropdownIcon } from 'utils/horizontalToolbarHelpers';

const ToolbarDropdown = ({
  formats,
  setIsDropdownVisible,
  dropdownType,
}: IToolbarDropdown) => {
  const editor = useSlate();

  const onMouseLeaveHandler = (): void => {
    setIsDropdownVisible(false);
  };

  const onMouseDown = (
    format: BlockFormatType | BlockTextAlignType | MarkFormatType,
    type: string,
  ) => {
    addPropertiesToText({ editor, dropdownType, format, type });
  };

  const shouldShowScrollbar = formats.length > 5;
  const scrollbarHeight = 30 * formats.length;

  const renderDropdownList = (formats: IDropdownItem[]): JSX.Element => (
    <ul className="toolbar-dropdown-list">
      {
        formats.map(({ type, format, label }: IDropdownItem) => {
          const isFontActive: boolean = isFontTypeActive(editor, type);
          const isAlignmentActive: boolean = isTextAlignActive(editor, format as BlockTextAlignType);
          const isActiveBlock: boolean = isBlockActive(editor, format as BlockFormatType);
          const isActiveMark: boolean = isMarkActive(editor, format as MarkFormatType);
          const isActiveFontSize: boolean = isMarkActive(editor, format as MarkFormatType, type);
          const isActiveNumberedList: boolean = NUMBERED_LIST_VARIANTS.includes(type)
            && isListTypeActive(editor, format as BlockFormatType, type);

          const isActive = {
            [DROPDOWN_TYPES.FONT_TYPE]: isFontActive,
            [DROPDOWN_TYPES.TITLE_FORMAT]: isActiveBlock,
            [DROPDOWN_TYPES.TEXT_ALIGNMENT]: isAlignmentActive,
            [DROPDOWN_TYPES.TEXT_FORMAT]: isActiveMark,
            [DROPDOWN_TYPES.TEXT_SIZE]: isActiveFontSize,
            [DROPDOWN_TYPES.NUMBERED_LIST_VIEW]: isActiveNumberedList,
          }[dropdownType] || false;

          const icon: JSX.Element | null = getDropdownIcon(format) || null;
          const additionalClassname = formats.length < 5 ? 'without-scrollbar' : '';
          return (
            <li
              key={type}
              onMouseDown={() => onMouseDown(format, type)}
              className={`toolbar-dropdown-list_item ${isActive ? 'active' : ''} ${additionalClassname}`}
            >
              {icon && <span className="icon">{icon}</span>}
              {label ?? type}
            </li>
          );
        })
      }
    </ul>
  );

  return (
    <div className="toolbar-dropdown" onMouseLeave={onMouseLeaveHandler}>
      {shouldShowScrollbar ? (
        <CustomScrollbar
          className="horizontal-toolbar-dropdown"
          style={{ height: `${scrollbarHeight}px` }}
        >
          {renderDropdownList(formats)}
        </CustomScrollbar>
      ) : (
        renderDropdownList(formats)
      )}
    </div>
  );
};

export default ToolbarDropdown;