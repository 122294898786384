import { SortableElement } from 'react-sortable-hoc';

import AttachmentIcon from 'svg/AttachmentIcon';
import DragHandleIcon from 'svg/DragHandleIcon';
import { IDocumentSortableItem } from 'types/SendDocuments';

const DocumentSortableItem = SortableElement(({
  value,
}: IDocumentSortableItem) => (
  <div className="send-documents-sortable">
    <DragHandleIcon />
    <AttachmentIcon />
    {value.name}
  </div>
));

export default DocumentSortableItem;