import {
  CLEAR_SECTIONS,
  CREATE_TEMPLATE_SECTION,
  DELETE_TEMPLATE_SECTION,
  SORT_FORM_DOCUMENT_SECTION,
  UPDATE_TEMPLATE_SECTION_DESCRIPTION,
  UPDATE_TEMPLATE_SECTION_NAME,
  UPDATE_TEMPLATE_SECTIONS,
} from 'store/actions/actionTypes';
import { ITemplateSection } from 'types/redux';

export const createTemplateSection = (payload: any, indexOfSourceSection?: number) => ({
  type: CREATE_TEMPLATE_SECTION,
  payload: {
    section: payload,
    indexOfSourceSection,
  },
});

export const updateTemplateSectionName = (key: number, name: string) => ({
  type: UPDATE_TEMPLATE_SECTION_NAME,
  payload: {
    key,
    section: {
      name,
    },
  },
});

export const updateTemplateSectionDescription = (key: number, description: string) => ({
  type: UPDATE_TEMPLATE_SECTION_DESCRIPTION,
  payload: {
    key,
    section: {
      description,
    },
  },
});

export const deleteTemplateSection = (key: number) => ({
  type: DELETE_TEMPLATE_SECTION,
  payload: key,
});

export const updateTemplateSections = (sections: ITemplateSection[]) => ({
  type: UPDATE_TEMPLATE_SECTIONS,
  payload: sections,
});

export const sortFormDocumentSections = (isSortState: boolean) => ({
  type: SORT_FORM_DOCUMENT_SECTION,
  payload: isSortState,
});

export const clearSections = () => ({
  type: CLEAR_SECTIONS,
});