import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Descendant } from 'slate';

import DocumentNavigator from 'components/DocumentNavigator';
import Editor from 'components/Editor';
import EditorContainer from 'components/Editor/EditorContainer';
import EditorWrapper from 'components/Editor/EditorWrapper';
import EditorHeader from 'components/EditorHeader/EditorHeader';
import SearchFieldAndContent from 'components/Sidebar/SearchFieldAndContent';
import SectionsOutline from 'components/Sidebar/SectionsOutline';
import SortableList from 'components/SortableList';
import { INITIAL_EDITOR_VALUE, INITIAL_SECTION_VALUE } from 'constants/editors';
import { DOCUMENT_NAME_LABEL } from 'constants/general';
import { useRolePermission } from 'hooks/useRolePermission';
import useSingleDocument from 'hooks/useSingleDocument';
import {
  createTemplate,
  createTemplateAndSection,
  editSections,
  setLastCreatedSection,
} from 'store/actions/userData';
import { RootStateType } from 'store/reducers';
import { IMetadataId } from 'types/Metadata';
import { ISection } from 'types/Sections';
import { ITemplateData, ITemplateRequestObj } from 'types/Templates';
import { IErrorField } from 'types/validation';
import { trimHTMLValue } from 'utils/descriptionHelper';
import { addSectionsDocument, drawSectionsInEditorContent, parseSections } from 'utils/editorHelpers';
import setDefaultPageValuesHelper from 'utils/reduxHelpers';
import { errorSectionsName, validationForm } from 'utils/validation';

const CreateDocument = () => {
  const dispatch = useDispatch();
  const isSingleDocument = useSingleDocument();

  const { doctypes, lastCreatedSection } = useSelector((state: RootStateType) => state.user);
  const { sections } = useSelector((state: RootStateType) => state.template);
  const { assignments } = useSelector((state: RootStateType) => state.editorSlate);

  const { permissionUsingSections, permissionUsingSectionsLight } = useRolePermission();

  const [content, setContent] = useState<Descendant[]>(INITIAL_SECTION_VALUE);
  const [previewMode, setPreviewMode] = useState<boolean>(false);
  const [sectionsAddModal, setSectionsAddModal] = useState<boolean>(false);
  const [templateName, setTemplateName] = useState<string>('');
  const [templateTitle, setTemplateTitle] = useState<string>('');
  const [templateDescription, setTemplateDescription] = useState<string>('');
  const [formErrors, setFormErrors] = useState<IErrorField>({ template_name: null, title: null });
  const [templateDoctype, setTemplateDoctype] = useState<IMetadataId | null>(null);

  const [templateSections, setTemplateSections] = useState<ISection[]>([]);
  const [templateContent, setTemplateContent] = useState<Descendant[]>(INITIAL_EDITOR_VALUE);

  const [searchString, setSearchString] = useState<string>('');

  useEffect(() => () => {
    setDefaultPageValuesHelper(dispatch, permissionUsingSectionsLight);
    dispatch(setLastCreatedSection(null));
  }, [dispatch, permissionUsingSectionsLight]);

  useEffect(() => {
    if (!templateDoctype && doctypes.length) {
      const defaultDoctype = doctypes[0];
      setTemplateDoctype(defaultDoctype);
    }
  }, [doctypes, templateDoctype]);

  const updateSectionAndContent = (sections: ISection[]): void => {
    setTemplateSections(sections);
    setTemplateContent(addSectionsDocument(sections, !permissionUsingSectionsLight));
  };

  useEffect(() => {
    if (!lastCreatedSection) return;
    updateSectionAndContent([...templateSections, lastCreatedSection]);
  }, [lastCreatedSection]);

  useEffect(() => {
    if (!permissionUsingSectionsLight) return;
    setContent(drawSectionsInEditorContent(sections, content));
  }, [sections]);

  useEffect(() => {
    setTemplateSections(parseSections(templateContent, templateSections));
  }, [templateContent]);

  const addSection = (section: ISection) => {
    updateSectionAndContent([...templateSections, section]);
  };

  const removeSection = (id: number) => {
    updateSectionAndContent(templateSections.filter((sect) => sect.id !== id));
  };

  const onUpdateOrder = (sections: ISection[]) => {
    updateSectionAndContent([...sections]);
  };

  const validateFieldsNameTitle = (documentName: string | null) => {
    const validateArray = [];
    if (documentName !== null) {
      validateArray.push({
        titleField: DOCUMENT_NAME_LABEL, nameField: 'template_name', valueField: templateName, required: true,
      });
    }
    const validateResult = validationForm(validateArray);
    setFormErrors((prev) => ({ ...prev, ...validateResult.validationFields }));
    return validateResult;
  };

  const onChangeTemplateName: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    setTemplateName(target.value);
    if (!templateTitle || templateTitle === templateName) {
      setTemplateTitle(target.value);
    }
  };

  const createNewTemplate = () => {
    const validateNameResult = validateFieldsNameTitle(templateName);

    if (validateNameResult.isError) return null;
    if (errorSectionsName(sections)) return null;
    if (!templateDoctype) return null;

    const selectedStates = [{ id: 1 }];
    if (permissionUsingSectionsLight) {
      const newTemplate: ITemplateData = {
        name: templateName.trim(),
        sections: sections.map((section) => {
          const sectionInEditor: Descendant | undefined = content.find(
            (el) => Number(el.key) === Number(section.key),
          );
          return {
            name: section.name.trim(),
            description: trimHTMLValue(section.description),
            // INITIAL_EDITOR_VALUE was added to avoid critical error with empty section content
            content_json: sectionInEditor ? sectionInEditor.children : INITIAL_EDITOR_VALUE,
            states: selectedStates,
          };
        }),
        template: {
          name: templateName.trim(),
          description: trimHTMLValue(templateDescription),
          template_title: templateTitle.trim() || '',
          doctype_id: templateDoctype.id,
          states: selectedStates,
          sections: [],
          is_single: isSingleDocument,
          assignments,
        },
      };
      return dispatch(createTemplateAndSection(newTemplate));
    }

    if (!templateSections.length) {
      const newTemplate: ITemplateData = {
        name: templateName.trim(),
        sections: [{
          name: templateName.trim(),
          description: '',
          content_json: templateContent,
          states: selectedStates,
        }],
        template: {
          name: templateName.trim(),
          description: trimHTMLValue(templateDescription),
          template_title: templateTitle.trim() || '',
          doctype_id: templateDoctype.id,
          states: selectedStates,
          sections: [],
          is_single: isSingleDocument,
          assignments,
        },
      };
      return dispatch(createTemplateAndSection(newTemplate));
    }

    const section = {
      sections: templateSections.map((section) => ({
        id: section.id,
        name: section.name.trim(),
        content_json: section.content_json,
      })),
    };

    dispatch(editSections(section));

    const newTemplate: ITemplateRequestObj = {
      name: templateName.trim(),
      description: trimHTMLValue(templateDescription),
      template_title: templateTitle.trim(),
      doctype_id: templateDoctype.id,
      states: selectedStates,
      sections: templateSections.map((section, index) => ({
        section_id: section.id,
        position: index,
      })),
      is_single: isSingleDocument,
      assignments,
    };
    dispatch(createTemplate(newTemplate));
  };

  return (
    <EditorWrapper>
      <EditorHeader
        documentName={templateName}
        onChangeDocumentName={onChangeTemplateName}
        errorText={formErrors.template_name}
        saveDocument={createNewTemplate}
        previewMode={previewMode}
        setPreviewMode={setPreviewMode}
        description={templateDescription}
        setDescription={setTemplateDescription}
      />
      <EditorContainer>
        <DocumentNavigator
          sections={templateSections}
          addSection={addSection}
          sectionsAddModal={sectionsAddModal}
          setSectionsAddModal={setSectionsAddModal}
          renderChildrenUnderSidebar={(
            <SearchFieldAndContent
              content={permissionUsingSectionsLight ? content : templateContent}
              setContent={permissionUsingSectionsLight ? setContent : setTemplateContent}
              setString={setSearchString}
              showSearch
            />
          )}
        >
          {
            permissionUsingSectionsLight
              ? (<SectionsOutline />)
              : (
                <SortableList
                  showModal={setSectionsAddModal}
                  sections={templateSections}
                  onUpdateOrder={onUpdateOrder}
                  removeSection={removeSection}
                />
              )
          }
        </DocumentNavigator>
        <Editor
          search={searchString}
          content={permissionUsingSectionsLight ? content : templateContent}
          onChange={permissionUsingSectionsLight ? setContent : setTemplateContent}
          previewMode={previewMode}
          styledSections={permissionUsingSections || permissionUsingSectionsLight}
        />
      </EditorContainer>
    </EditorWrapper>
  );
};

export default CreateDocument;