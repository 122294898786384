import { FC } from 'react';

import { Form, FormGroup } from 'reactstrap';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import Button from 'components/Base/Button';
import InputField from 'components/Base/InputField';
import SimpleSpinner from 'components/Base/SimpleSpinner';
import Intro from 'components/Intro/Intro';
import { LOGIN } from 'constants/general';
import { USER_REGISTRATION_TITLES } from 'constants/validation';
import { ILoginRequestObj } from 'types/Auth';

interface ILoginForm {
  user: ILoginRequestObj;
  changeUserHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error: string;
  forgotPasswordHandler: () => void;
  onLoginUserHandler: () => void;
  isButtonClicked: boolean;
  disableLogin: boolean;
  showForgotPasswordLink?: boolean;
}

const LoginForm: FC<ILoginForm> = ({
  user,
  changeUserHandler,
  error,
  forgotPasswordHandler,
  onLoginUserHandler,
  isButtonClicked,
  disableLogin,
  showForgotPasswordLink = true,
}) => (
  <Intro
    title="Login"
    description=""
  >
    <Form className="col-12 col-sm-6 col-md-3 mx-auto">
      <FormGroup>
        <InputField
          enableLabelShrink
          required
          name={USER_REGISTRATION_TITLES.username.toLowerCase()}
          label={USER_REGISTRATION_TITLES.username}
          value={user.username}
          onChange={changeUserHandler}
        />
      </FormGroup>
      <FormGroup>
        <InputField
          enableLabelShrink
          required
          type="password"
          name={USER_REGISTRATION_TITLES.password.toLowerCase()}
          label={USER_REGISTRATION_TITLES.password}
          value={user.password}
          onChange={changeUserHandler}
        />
      </FormGroup>
      {
        error && typeof error === 'string' && (
          <div className="account-settings__error mb-1">
            <ErrorOutlineOutlinedIcon color="error" />
            <span className="ms-2">{error}</span>
          </div>
        )
      }
      {
        showForgotPasswordLink && (
          <div className="d-flex justify-content-end">
            <button type="button" className="btn btn-link w-auto p-0" onClick={forgotPasswordHandler}>
              Forgot password?
            </button>
          </div>
        )
      }
      <div className="mt-3">
        <Button
          classes="w-100"
          onClick={onLoginUserHandler}
          disabled={(isButtonClicked && !error) || disableLogin}
        >
          {
            !error && isButtonClicked
              ? (<SimpleSpinner />)
              : (<div>{LOGIN}</div>)
          }
        </Button>
      </div>
    </Form>
  </Intro>
);

export default LoginForm;