import { Element as SlateElement } from 'slate';

import {
  ATTACHMENT_DEFAULT_PARAMS,
  FIELD_GROUPING_VALUE,
  GROUP_BY_KEY,
  QUESTION_ANSWER_STRING,
  SELECT_FIELD,
} from 'constants/editors';
import { SIGNATURE_TABS } from 'constants/signatures';
import { BlockFormatType, CustomEditorType, ICustomElement } from 'types/Editor';
import { PDFFieldTypesType } from 'types/PdfTemplates';
import { ISelectFieldType } from 'types/Properties';
import { createFieldName } from 'utils/editorFieldHelpers';
import {
  getIsAttachmentField,
  getIsDateField,
  getIsQuestionField,
  getIsSelectField,
  getIsSignatureField,
} from 'utils/Fields/fieldsTypeChecker';

export const updateGroupedNodeFromPropertyTab = (
  editor: CustomEditorType,
  keys: number[],
  newField: Partial<SlateElement>,
): void => {
  editor.findNodesAndUpdate((node) => {
    if (node.key && keys.includes(node.key) && newField) {
      const validationText = newField.validationText;
      const children = newField.children;
      const todayDate = newField?.isTodaysDate !== undefined && { isTodaysDate: newField.isTodaysDate };
      const selectPropertiesToUpdate = getIsSelectField(newField.type)
        ? {
          selectFieldType: newField.selectFieldType,
          selectedOtherOption: newField.selectedOtherOption,
          addedOtherOption: newField.addedOtherOption,
          ...(newField.options && { options: newField.options }),
        }
        : {};
      return {
        ...node,
        ...{
          helpText: newField.helpText || '',
          requiredField: newField.requiredField || false,
          ...(newField.maxLength && { maxLength: newField.maxLength }),
          ...(typeof validationText === 'string' && { validationText }),
          ...(children && { children }), // field label and name
          ...todayDate,
          ...selectPropertiesToUpdate,
        },
      };
    }
    return node;
  });
};

export const getFieldChangingText = (
  fieldData: ICustomElement,
  groupedFieldCount: number,
  isPdf: boolean = false,
): string => {
  // We use grouped fields for only one document for now, collections are not editable
  const fieldLabel = (isPdf ? fieldData.fieldName : createFieldName(fieldData.children)) || '';
  const fieldNameText = fieldLabel.length ? `"${fieldLabel}"` : 'the field';

  return (
    `Are you sure you want to update the field properties for ${fieldNameText}? `
    + `This will update ${groupedFieldCount} grouped fields in the document.`
  );
};

export const getChangedTypeFieldProperties = (
  newType: BlockFormatType | PDFFieldTypesType,
  customFieldProps: Partial<ICustomElement>,
) => {
  const selectFieldProps = getIsSelectField(newType)
    ? { selectedOtherOption: false, addedOtherOption: false }
    : {};
  return {
    type: newType,
    value: '',
    subtype: '',
    groupBy: '',
    isTodaysDate: getIsDateField(newType) ? customFieldProps.isTodaysDate : false,
    properties: getIsAttachmentField(newType) ? ATTACHMENT_DEFAULT_PARAMS : null,
    shortName: getIsQuestionField(newType) ? customFieldProps.shortName : '',
    answerType: getIsQuestionField(newType) ? QUESTION_ANSWER_STRING : '',
    signatureTab: getIsSignatureField(newType) ? SIGNATURE_TABS.DRAW_TAB : '',
    ...selectFieldProps,
  };
};

export const getPropertyOptions = (propKey?: string, propOptions?: ISelectFieldType[], fieldType?: any) => {
  if (propOptions && propKey === GROUP_BY_KEY && fieldType === SELECT_FIELD) {
    return propOptions.filter((option) => option.type !== FIELD_GROUPING_VALUE.BY_FILTER);
  }
  return propOptions;
};