import { OutlinedInput, styled, TextField } from '@mui/material';

import vars from 'variables.module.scss';

export const StyledInputField = styled(OutlinedInput)({
  '& input': {
    padding: '12px 14px',
  },
  '& fieldset': {
    top: 0,
    '& legend': {
      display: 'none',
    },
  },
  '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
    borderColor: vars.brandPrimaryFocused,
    borderWidth: '1px',
  },
});

export const StyledTextField = styled(TextField)({
  '& .MuiAutocomplete-inputRoot input.MuiAutocomplete-input': {
    padding: '3px 4px',
  },
  '& fieldset': {
    top: 0,
    '& legend': {
      display: 'none',
    },
  },
  '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
    borderColor: vars.brandPrimaryFocused,
    borderWidth: '1px',
  },
});