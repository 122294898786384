import type { Location } from 'slate';
import { Editor } from 'slate';

import type { ListsEditor } from '../types';

export const isInList = (editor: ListsEditor, location: Location | null = editor.selection) => {
  if (!location) {
    return false;
  }

  for (const [currentNode] of Array.from(Editor.levels(editor, { at: location }))) {
    if (editor.isListNode(currentNode)) {
      return true;
    }
  }

  return false;
};

export default isInList;