import { SortEvent } from 'react-sortable-hoc';

const arrayMove = <T = unknown[]>(array: T, from: number, to: number, sectionsLight: boolean = false) => {
  if (!Array.isArray(array)) return array;

  const arrayClone = [...array];

  const startIndex = from < 0 ? arrayClone.length + from : from;

  if (startIndex >= 0 && startIndex < arrayClone.length) {
    const endIndex = to < 0 ? arrayClone.length + to : to;

    const [item] = arrayClone.splice(from, 1);
    arrayClone.splice(endIndex, 0, item);
  }

  if (sectionsLight) {
    return arrayClone.map((el, index) => ({
      ...el,
      position: index,
    }));
  }

  return arrayClone.map((el, index) => ({
    ...el,
    section: { ...el.section },
    position: index,
  }));
};

export const arrayFieldMove = <T = unknown[]>(array: T, from: number, to: number) => {
  if (!Array.isArray(array)) return array;

  const arrayClone = [...array];
  const startIndex = from < 0 ? arrayClone.length + from : from;

  if (startIndex >= 0 && startIndex < arrayClone.length) {
    const endIndex = to < 0 ? arrayClone.length + to : to;
    const [item] = arrayClone.splice(from, 1);
    arrayClone.splice(endIndex, 0, item);
  }

  return arrayClone.map((el, index) => ({
    ...el,
    children: el.children?.map((field: typeof el.children[0]) => {
      const fieldPosition = field.assignment ? { position: index } : {};
      return {
        ...field,
        ...fieldPosition,
      };
    }),
  }));
};

export const shouldCancelStartOfSorting = (event: SortEvent, isException = false) => {
  if (isException) {
    return true;
  }
  const target = event.target as HTMLElement;
  const targetNode = target?.nodeName.toLowerCase();
  switch (targetNode) {
    case 'input':
      return true;
    case 'svg':
    case 'path':
      return !target.getAttribute('data-disabled');
    default:
      return false;
  }
};

export default arrayMove;