/* eslint-disable */
import {
  SET_PDF_TEMPLATE_FILE_KEY,
  SET_PDF_TEMPLATE_FILE_LINK,
  SET_SELECTED_FIELD_KEY,
  UPDATE_FIELD_BY_KEY,
  DELETE_PDF_FIELD,
  SET_ALL_PDF_FIELDS_AND_INFO,
  CLEAR_PDF_TEMPLATE_DATA,
  SET_PDF_TEMPLATE_INFO,
  SET_PDF_TEMPLATE_WIDTH,
  SET_PDF_FORM_FIELDS,
  SET_ALL_PDF_FIELDS,
  SET_ALL_PDF_TEMPLATE_INFO
} from 'store/actions/actionTypes';
import { Action } from 'types/redux';
import { PdfTemplateStateType } from 'types/PdfTemplates';

const initialState: PdfTemplateStateType = {
  pdfTemplateWidth: null,
  pdfTemplateKey: '',
  pdfTemplateLink: '',
  selectedFieldKey: null,
  pdfTemplateInfo: {
    templateName: '',
    templateTitle: '',
    templateDescription: ''
  },
  pdfTemplateFields: {},
  pdfFormFields: [],
};

const pdfTemplates = (state: PdfTemplateStateType = initialState, { type, payload }: Action) => {
  switch (type) {
    case SET_ALL_PDF_TEMPLATE_INFO:
      return {
        ...state,
        ...payload,
      }
    case SET_PDF_TEMPLATE_WIDTH:
      return {
        ...state,
        pdfTemplateWidth: payload
      };
    case SET_PDF_TEMPLATE_INFO:
      return {
        ...state,
        pdfTemplateInfo: { ...state.pdfTemplateInfo, [payload.infoKey]: payload.value }
      };
    case SET_ALL_PDF_FIELDS_AND_INFO:
      return {
        ...state,
        pdfTemplateFields: payload.fields,
        pdfTemplateInfo: payload.info
      };
    case SET_PDF_TEMPLATE_FILE_KEY:
      return {
        ...state,
        pdfTemplateKey: payload,
      };
    case SET_PDF_TEMPLATE_FILE_LINK:
      return {
        ...state,
        pdfTemplateLink: payload,
      };
    case SET_SELECTED_FIELD_KEY:
      return {
        ...state,
        selectedFieldKey: payload
      };
    case UPDATE_FIELD_BY_KEY:
      return {
        ...state,
        pdfTemplateFields: {
          ...state.pdfTemplateFields,
          [payload.key]: {
            ...state.pdfTemplateFields[payload.key],
            ...payload.field,
          },
        },
      };
    case DELETE_PDF_FIELD:
      const updatedPdfFields = { ...state.pdfTemplateFields };
      delete updatedPdfFields[payload];
      return {
        ...state,
        pdfTemplateFields: updatedPdfFields
      };
    case CLEAR_PDF_TEMPLATE_DATA:
      return {
        ...state,
        ...initialState,
      };
    case SET_PDF_FORM_FIELDS:
      return {
        ...state,
        pdfFormFields: payload,
      };
    case SET_ALL_PDF_FIELDS:
      return {
        ...state,
        pdfTemplateFields: payload,
      };
    default:
      return state;
  }
};

export default pdfTemplates;