const DocumentIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 62H44C44.2652 62 44.5196 61.8946 44.7071 61.7071C44.8946 61.5196 45 61.2652 45 61V3C45
      2.73478 44.8946 2.48043 44.7071 2.29289C44.5196 2.10536 44.2652 2 44 2H3C2.73478 2 2.48043 2.10536
      2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V61C2 61.2652 2.10536 61.5196 2.29289 61.7071C2.48043
      61.8946 2.73478 62 3 62ZM4 4H43V60H4V4Z"
      fill="#2F3947"
    />
    <path d="M41 14H11V16H41V14Z" fill="#2F3947" />
    <path d="M41 18H6V20H41V18Z" fill="#2F3947" />
    <path d="M33 22H17V24H33V22Z" fill="#2F3947" />
    <path d="M25 26H17V28H25V26Z" fill="#2F3947" />
    <path d="M33 30H17V32H33V30Z" fill="#2F3947" />
    <path d="M30 34H17V36H30V34Z" fill="#2F3947" />
    <path d="M41 40H13V42H41V40Z" fill="#2F3947" />
    <path d="M41 44H6V46H41V44Z" fill="#2F3947" />
    <path d="M37 48H6V50H37V48Z" fill="#2F3947" />
    <path d="M41 54H25V56H41V54Z" fill="#2F3947" />
    <path d="M33 8H13V10H33V8Z" fill="#2F3947" />
    <path
      d="M10 28H14C14.2652 28 14.5196 27.8946 14.7071 27.7071C14.8946 27.5196 15 27.2652 15 27V23C15
      22.7348 14.8946 22.4804 14.7071 22.2929C14.5196 22.1054 14.2652 22 14 22H10C9.73478 22 9.48043 22.1054
      9.29289 22.2929C9.10536 22.4804 9 22.7348 9 23V27C9 27.2652 9.10536 27.5196 9.29289 27.7071C9.48043
      27.8946 9.73478 28 10 28ZM11 24H13V26H11V24Z"
      fill="#2F3947"
    />
    <path
      d="M9 35C9 35.2652 9.10536 35.5196 9.29289 35.7071C9.48043 35.8946 9.73478 36 10 36H14C14.2652
      36 14.5196 35.8946 14.7071 35.7071C14.8946 35.5196 15 35.2652 15 35V31C15 30.7348 14.8946 30.4804
      14.7071 30.2929C14.5196 30.1054 14.2652 30 14 30H10C9.73478 30 9.48043 30.1054 9.29289 30.2929C9.10536
      30.4804 9 30.7348 9 31V35ZM11 32H13V34H11V32Z"
      fill="#2F3947"
    />
    <path
      d="M60.584 33.811C60.7126 33.7185 60.8174 33.5967 60.8898 33.4557C60.9621 33.3146 60.9999 33.1585
      61 33V3C61 2.73478 60.8946 2.48043 60.7071 2.29289C60.5196 2.10536 60.2652 2 60 2H56C55.7348 2
      55.4804 2.10536 55.2929 2.29289C55.1054 2.48043 55 2.73478 55 3V5.193L51.75 6.032C51.5354
      6.08741 51.3453 6.21256 51.2095 6.38778C51.0738 6.56299 51.0001 6.77835 51 7V17H53V7.775L55
      7.259V17C55 17.012 55.006 17.022 55.007 17.034C55.008 17.046 55.007 17.055 55.007 17.066L56.007
      32.066C56.0201 32.2643 56.0919 32.4542 56.2134 32.6115C56.3349 32.7687 56.5005 32.8862 56.689
      32.949L57.88 33.349V34.441L56.11 33.556L55.11 35.556C55.0287 35.719 54.9935 35.901 55.0081
      36.0826C55.0228 36.2641 55.0867 36.4381 55.193 36.586L58.068 40.586C58.1605 40.715 58.2824
      40.82 58.4236 40.8926C58.5648 40.9651 58.7213 41.0029 58.88 41.003C58.9842 41.0028 59.0877
      40.9866 59.187 40.955C59.3879 40.889 59.5628 40.761 59.6864 40.5894C59.81 40.4179 59.876
      40.2115 59.875 40V33.98C59.9162 33.9891 59.958 33.9958 60 34C60.2097 34.0003 60.4142 33.9341
      60.584 33.811ZM57.875 36.9L57.165 35.911L57.875 34.49V36.9ZM59 31.613L57.953 31.264L57.069
      18H59V31.613ZM59 16H57V4H59V16Z"
      fill="#2F3947"
    />
    <path
      d="M60 49V43H58V49C57.9998 49.3509 57.9073 49.6956 57.7318 49.9994C57.5563 50.3032
      57.3039 50.5556 57 50.731V49.5C57 48.8078 56.7947 48.1311 56.4101 47.5555C56.0256 46.9799
      55.4789 46.5313 54.8394 46.2664C54.1999 46.0015 53.4961 45.9322 52.8172 46.0673C52.1383
      46.2023 51.5146 46.5356 51.0251 47.0251C50.5356 47.5146 50.2023 48.1383 50.0673 48.8172C49.9322
      49.4961 50.0015 50.1999 50.2664 50.8394C50.5313 51.4789 50.9799 52.0256 51.5555 52.4101C52.1311
      52.7947 52.8078 53 53.5 53H54.731C54.5556 53.3039 54.3032 53.5563 53.9994 53.7318C53.6956
      53.9073 53.3509 53.9998 53 54H47V56H53C53.9045 55.9993 54.7822 55.6926 55.4903 55.1298C56.1984
      54.5669 56.6953 53.7811 56.9 52.9C57.7811 52.6953 58.5669 52.1984 59.1298 51.4903C59.6926
      50.7822 59.9993 49.9045 60 49ZM55 51H53.5C53.2033 51 52.9133 50.912 52.6666 50.7472C52.42
      50.5824 52.2277 50.3481 52.1142 50.074C52.0006 49.7999 51.9709 49.4983 52.0288 49.2074C52.0867
      48.9164 52.2296 48.6491 52.4393 48.4393C52.6491 48.2296 52.9164 48.0867 53.2074 48.0288C53.4983
      47.9709 53.7999 48.0006 54.074 48.1142C54.3481 48.2277 54.5824 48.42 54.7472 48.6666C54.912
      48.9133 55 49.2033 55 49.5V51Z"
      fill="#2F3947"
    />
  </svg>
);

export default DocumentIcon;