import { useSlate } from 'slate-react';

import { IFontTypeButtonProps } from 'types/Editor';
import { addPropertyFontType, isFontTypeActive } from 'utils/editorFontTypeHelpers';

const FontTypeButton = ({ format, value, icon }: IFontTypeButtonProps) => {
  const editor = useSlate();

  const active: boolean = isFontTypeActive(editor, value);

  const onMouseDown: React.MouseEventHandler<HTMLSpanElement> = (event) => {
    event.preventDefault();
    addPropertyFontType(editor, format, value);
  };

  return (
    <span
      role="button"
      tabIndex={-1}
      className={`btn-toolbar-editor ${active ? '' : 'text-dark'}`}
      style={{ fill: active ? 'black' : '#ccc' }}
      onMouseDown={onMouseDown}
    >
      {icon}
    </span>
  );
};

export default FontTypeButton;