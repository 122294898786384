import { ChangeEventHandler, FC, MouseEventHandler, MutableRefObject } from 'react';

import ReactQuill from 'react-quill';
import { FormFeedback, FormGroup, Input } from 'reactstrap';

import DescriptionEditor from 'components/Editor/FormView/DescriptionEditor';
import SimpleTitle from 'components/Editor/FormView/SimpleTitle';
import { TITLE_MAX_LEN } from 'constants/validation';

type TitleAndDescriptionType = {
  isReadOnlyMode: boolean;
  isPublicPage?: boolean;
  title: string;
  description: string;
  changeFormTitle?: ChangeEventHandler<HTMLInputElement>;
  changeFormDescription?: (value: string) => void;
  isError?: boolean;
  errorMessage?: string;
  titleMaxLength?: number;
  titlePlaceholder?: string;
  onActivateDescriptionEditor?: MouseEventHandler<HTMLDivElement>;
  updateSectionName?: () => void;
  descriptionInputRef?: MutableRefObject<ReactQuill | undefined>;
}

const TitleAndDescription: FC<TitleAndDescriptionType> = ({
  isReadOnlyMode,
  isPublicPage = false,
  title,
  description,
  changeFormTitle,
  changeFormDescription,
  isError = false,
  errorMessage = '',
  titleMaxLength = TITLE_MAX_LEN,
  titlePlaceholder,
  onActivateDescriptionEditor = undefined,
  updateSectionName = () => null,
  descriptionInputRef,
}) => {
  const readonlyPageClasses = isPublicPage ? 'form-description max-w-450 disabled' : 'description-text-area';
  const formPageProps = onActivateDescriptionEditor === undefined
    ? {}
    : {
      placeholder: 'Write a description here...',
      onMouseDown: onActivateDescriptionEditor,
      role: 'button',
      tabIndex: -1,
    };

  return (
    <>
      {
        isReadOnlyMode
          ? <SimpleTitle isPublicPage={isPublicPage} title={title} />
          : (
            <FormGroup contentEditable={false}>
              <Input
                onBlurCapture={updateSectionName}
                className="form-title"
                value={title}
                invalid={isError}
                maxLength={titleMaxLength}
                onBlur={changeFormTitle}
                onChange={changeFormTitle}
                placeholder={titlePlaceholder}
                readOnly={isReadOnlyMode}
                required
              />
              <FormFeedback className="text-center" contentEditable={false}>{errorMessage}</FormFeedback>
            </FormGroup>
          )
      }
      {
        (onActivateDescriptionEditor === undefined && !isPublicPage)
          ? (
            <div
              className={`description-text-area${(isReadOnlyMode || isPublicPage) ? ' disabled' : ''}`}
              data-slate-editor={!isReadOnlyMode && !isPublicPage}
              data-empty-description={!description}
            >
              <DescriptionEditor
                isReadOnly={isPublicPage || isReadOnlyMode}
                onChange={changeFormDescription}
                value={description}
                descriptionInputRef={descriptionInputRef}
              />
            </div>
          )
          : (
            <div
              contentEditable={false}
              data-empty-description={!description}
              className={`mt-3 form-description-public ${readonlyPageClasses}`}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: description }}
              {...formPageProps}
            />
          )
      }
    </>
  );
};

export default TitleAndDescription;