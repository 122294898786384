import React, { SyntheticEvent } from 'react';

import { useSlate } from 'slate-react';

import { IBlockButtonProps } from 'types/Editor';
import { isBlockActive, toggleBlock } from 'utils/editorBlockHelpers';

const BlockButton: React.FC<IBlockButtonProps> = (props) => {
  const {
    key,
    format,
    icon,
    title,
    horizontalToolbar = false,
  } = props;

  const editor = useSlate();
  const active: boolean = isBlockActive(editor, format);

  const onMouseDownButton = (event: SyntheticEvent) => {
    event.preventDefault();
    toggleBlock(editor, format);
  };

  if (horizontalToolbar) {
    return (
      <span
        className={`btn-toolbar-editor ${active ? 'text-dark' : ''} horizontal-toolbar-icon`}
        style={{ fill: active ? 'black' : '#ccc' }}
        onMouseDown={onMouseDownButton}
        role="button"
        tabIndex={0}
      >
        {icon}
      </span>
    );
  }

  return (
    <li
      key={key}
      className={`item-toolbar-editor ${active ? 'text-dark' : ''}`}
      style={{ fill: active ? '#343a40' : '#ccc' }}
      onMouseDown={onMouseDownButton}
    >
      <div className="d-flex">
        <div className="icon">{icon}</div>
        <div>
          <div className="text-dark">{title}</div>
          <div className="command-description text-muted">Shortcut 4 + &#8984; or type - + space</div>
        </div>
      </div>
    </li>
  );
};

export default BlockButton;