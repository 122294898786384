import { CSSProperties, useEffect } from 'react';

const useRootStyles = (styles: CSSProperties, isMainOverflowHidden: boolean | undefined = true) => {
  useEffect(() => {
    const root = document.getElementById('root');
    const main = document.getElementById('main');
    if (!root) return;

    (Object.keys(styles) as Array<keyof CSSProperties>).forEach((key: keyof CSSProperties) => {
      const value = styles[key];
      if (typeof value === 'string') {
        root.style.setProperty(key, value);
      }
    });

    if (isMainOverflowHidden && main) {
      main.style.setProperty('overflow', 'hidden');
    }

    return () => {
      (Object.keys(styles) as Array<keyof CSSProperties>).forEach((key: keyof CSSProperties) => {
        root.style.removeProperty(key);
      });
      if (isMainOverflowHidden && main) {
        main.style.removeProperty('overflow');
      }
    };
  }, [styles]);
};

export default useRootStyles;