import { MutableRefObject } from 'react';

import QuestionMark from '@mui/icons-material/QuestionMark';

import TableQuestionButton from 'components/Editor/components/Fields/TableQuestion/TableQuestionButton';
import BlockButton from 'components/Editor/components/ToolbarFieldsButtons/BlockButton';
import CheckboxButton from 'components/Editor/components/ToolbarFieldsButtons/CheckboxButton';
import ImageButton from 'components/Editor/components/ToolbarFieldsButtons/ImageButton';
import LinkButton from 'components/Editor/components/ToolbarFieldsButtons/LinkButton';
import PropertiesSelect from 'components/Editor/components/ToolbarFieldsButtons/PropertiesSelect';
import QuestionFieldButton from 'components/Editor/components/ToolbarFieldsButtons/QuestionFieldButton';
import SelectFieldButton from 'components/Editor/components/ToolbarFieldsButtons/SelectFieldButton';
import SigningFieldButton from 'components/Editor/components/ToolbarFieldsButtons/SigningFieldButton';
import TableButton from 'components/Editor/components/ToolbarFieldsButtons/TableButton';
import TextAreaButton from 'components/Editor/components/ToolbarFieldsButtons/TextAreaButton';
import TextFieldButton from 'components/Editor/components/ToolbarFieldsButtons/TextFieldButton';
import Tooltip from 'components/Tooltip';
import {
  ATTACHMENT_FIELD,
  BULLETED_LIST,
  DATE_FIELD,
  DROPDOWN_TYPES,
  FONT_TYPE_FORMAT,
  NUMBERED_LIST_VIEW,
  QUESTION_FIELD,
  SELECT_FIELD,
  SELECT_FIELD_TYPE_CHECKBOX,
  SIGNING_FIELD,
  TABLE_QUESTION,
  TEXT_ALIGMENT_FORMAT,
  TEXT_FIELD,
  TEXT_FORMAT,
  TEXT_SIZE_FORMATS,
  TEXTAREA_FIELD,
  TITLE_FORMAT,
} from 'constants/editors';
import {
  AlignmentIcon,
  AttachIcon,
  BulletedListIcon,
  CalendarIcon,
  CheckboxIcon,
  DropdownIcon,
  FontStyleVariantIcon,
  FontTypeVariantIcon,
  ImageIcon,
  InputFieldIcon,
  InsertTextIcon,
  LinkIcon,
  NumberedListIcon,
  SignatureIcon,
  TableIcon,
  TextSizeIcon,
  ToolbarHeadersIcon,
} from 'svg/FieldsToolbarsIcons';
import { isSafariBrowser } from 'utils/getBrowserName';

import vars from 'variables.module.scss';

interface IHorizontalToolbarProps {
  toolbarRef: React.MutableRefObject<HTMLDivElement | null>;
  permissionUsingTableField?: boolean;
  closeToolbar: (toolbarRef: MutableRefObject<HTMLDivElement | null>) => void;
}

const HorizontalToolbar: React.FC<IHorizontalToolbarProps> = ({
  toolbarRef,
  permissionUsingTableField,
  closeToolbar,
}) => {
  const onMouseLeaveHandlerForSafari = () => {
    if (isSafariBrowser()) {
      closeToolbar(toolbarRef);
    }
  };

  return (
    <div
      className="horizontal-toolbar user-select-none"
      ref={toolbarRef}
      onMouseLeave={onMouseLeaveHandlerForSafari}
    >
      <div className="d-flex justify-content-around w-100 pb-2">
        <div className="d-flex align-items-center">
          <span className="row-title">Format:</span>
        </div>
        <Tooltip title="Font type">
          <span>
            <PropertiesSelect
              dropdownType={DROPDOWN_TYPES.FONT_TYPE}
              formats={FONT_TYPE_FORMAT}
              icon={<FontTypeVariantIcon />}
              horizontalToolbar
            />
          </span>
        </Tooltip>
        <Tooltip title="Font size">
          <span>
            <PropertiesSelect
              dropdownType={DROPDOWN_TYPES.TEXT_SIZE}
              formats={TEXT_SIZE_FORMATS}
              icon={<TextSizeIcon />}
              horizontalToolbar
            />
          </span>
        </Tooltip>
        <Tooltip title="Title">
          <span>
            <PropertiesSelect
              dropdownType={DROPDOWN_TYPES.TITLE_FORMAT}
              formats={TITLE_FORMAT}
              icon={<ToolbarHeadersIcon />}
              horizontalToolbar
            />
          </span>
        </Tooltip>
        <Tooltip title="Font style">
          <span>
            <PropertiesSelect
              dropdownType={DROPDOWN_TYPES.TEXT_FORMAT}
              formats={TEXT_FORMAT}
              icon={<FontStyleVariantIcon />}
              horizontalToolbar
            />
          </span>
        </Tooltip>
        <Tooltip title="Link">
          <span>
            <LinkButton format="link" icon={<LinkIcon />} horizontalToolbar />
          </span>
        </Tooltip>
        <Tooltip title="Image">
          <span>
            <ImageButton format="image" icon={<ImageIcon />} horizontalToolbar />
          </span>
        </Tooltip>
        <Tooltip title="Table">
          <span>
            <TableButton icon={<TableIcon />} format="table" horizontalToolbar />
          </span>
        </Tooltip>
        <Tooltip title="Alignment">
          <span>
            <PropertiesSelect
              dropdownType={DROPDOWN_TYPES.TEXT_ALIGNMENT}
              formats={TEXT_ALIGMENT_FORMAT}
              icon={<AlignmentIcon />}
              horizontalToolbar
            />
          </span>
        </Tooltip>
        <Tooltip title="Bulleted list">
          <span>
            <BlockButton format={BULLETED_LIST} icon={<BulletedListIcon />} horizontalToolbar />
          </span>
        </Tooltip>
        <Tooltip title="Numbered list">
          <span>
            <PropertiesSelect
              dropdownType={DROPDOWN_TYPES.NUMBERED_LIST_VIEW}
              formats={NUMBERED_LIST_VIEW}
              icon={<NumberedListIcon />}
              horizontalToolbar
            />
          </span>
        </Tooltip>
      </div>
      <div className="d-flex justify-content-around w-100">
        <div className="d-flex align-items-center">
          <span className="row-title">Form:</span>
        </div>
        <Tooltip title="Text">
          <span>
            <TextFieldButton format={TEXT_FIELD} icon={<InputFieldIcon />} />
          </span>
        </Tooltip>
        <Tooltip title="Question">
          <span>
            <QuestionFieldButton format={QUESTION_FIELD} icon={<QuestionMark />} />
          </span>
        </Tooltip>
        <Tooltip title="Checkbox">
          <span>
            <CheckboxButton
              format={SELECT_FIELD}
              icon={<CheckboxIcon />}
              selectVariant={SELECT_FIELD_TYPE_CHECKBOX}
            />
          </span>
        </Tooltip>
        <Tooltip title="Text area">
          <span>
            <TextAreaButton format={TEXTAREA_FIELD} icon={<InsertTextIcon />} />
          </span>
        </Tooltip>
        <Tooltip title="Attachment">
          <span>
            <TextFieldButton format={ATTACHMENT_FIELD} icon={<AttachIcon />} />
          </span>
        </Tooltip>
        <Tooltip title="Signing">
          <span>
            <SigningFieldButton format={SIGNING_FIELD} icon={<SignatureIcon />} />
          </span>
        </Tooltip>
        <Tooltip title="Date">
          <span>
            <TextFieldButton format={DATE_FIELD} icon={<CalendarIcon />} />
          </span>
        </Tooltip>
        <Tooltip title="Select">
          <span>
            <SelectFieldButton format={SELECT_FIELD} icon={<DropdownIcon />} />
          </span>
        </Tooltip>
        <Tooltip title="Table Question">
          {
            permissionUsingTableField ? (
              <span>
                <TableQuestionButton format={TABLE_QUESTION} icon={<TableIcon />} />
              </span>
            ) : (
              <span className="btn-toolbar-editor horizontal-toolbar-icon">
                <TableIcon fill={vars.mediumGray} />
              </span>
            )
          }
        </Tooltip>
      </div>
    </div>
  );
};

export default HorizontalToolbar;