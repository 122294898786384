import { FC } from 'react';

import AttachmentElement from 'components/PdfEditor/Fields/AttachmentEditable';
import CheckboxEditable from 'components/PdfEditor/Fields/CheckboxEditable';
import DateElement from 'components/PdfEditor/Fields/DateEditable';
import InputElement from 'components/PdfEditor/Fields/InputEditable';
import QuestionEditable from 'components/PdfEditor/Fields/QuestionEditable';
import SelectElement from 'components/PdfEditor/Fields/SelectEditable';
import SelectRadioElement from 'components/PdfEditor/Fields/SelectRadioEditable';
import SigningElement from 'components/PdfEditor/Fields/SigningEditable';
import TextareaElement from 'components/PdfEditor/Fields/TextareaEditable';
import {
  ATTACHMENT_FIELD,
  CHECKBOX_FIELD,
  DATE_FIELD,
  QUESTION_FIELD,
  SELECT_FIELD,
  SIGNING_FIELD,
  TEXT_FIELD,
  TEXTAREA_FIELD,
} from 'constants/editors';
import { PDFPropsType } from 'types/PdfTemplates';
import { getIsRadioOrCheckbox } from 'utils/Fields/checkboxRadioHelpers';

const PdfField: FC<PDFPropsType> = (props) => {
  const {
    field: { type, selectFieldType },
    isReadOnlyMode,
    isPublicPage,
  } = props;

  switch (type) {
    case TEXT_FIELD:
      return <InputElement {...props} isReadOnlyMode={isReadOnlyMode} />;
    case DATE_FIELD:
      return <DateElement {...props} isReadOnlyMode={isReadOnlyMode} />;
    case TEXTAREA_FIELD:
      return <TextareaElement {...props} isReadOnlyMode={isReadOnlyMode} />;
    case SELECT_FIELD:
      if (selectFieldType && getIsRadioOrCheckbox(selectFieldType)) {
        return <SelectRadioElement {...props} isPublicPage={isPublicPage} isReadOnlyMode={isReadOnlyMode} />;
      }
      return <SelectElement {...props} isReadOnlyMode={isReadOnlyMode} />;
    case SIGNING_FIELD:
      return <SigningElement {...props} isReadOnlyMode={isReadOnlyMode} />;
    case ATTACHMENT_FIELD:
      return <AttachmentElement {...props} isReadOnlyMode={isReadOnlyMode} />;
    case QUESTION_FIELD:
      return <QuestionEditable {...props} isReadOnlyMode={isReadOnlyMode} />;
    case CHECKBOX_FIELD:
      return <CheckboxEditable {...props} isReadOnlyMode={isReadOnlyMode} />;
    default:
      return (
        <div>
          Wrong field type!
        </div>
      );
  }
};

export default PdfField;