import { FC, useMemo } from 'react';

import ShareLinkAccordionItem from 'components/Modals/ModalWrapper/ShareLinkAccordionItem';
import ShareLinkCard from 'components/Modals/ModalWrapper/ShareLinkCard';
import { RECIPIENT_ASSIGNMENT } from 'constants/editors';
import { URL_PUBLIC_COLLECTION } from 'constants/general';
import ROUTES from 'constants/routes';
import { AssignmentType } from 'types/Editor';
import { isManagerAssignment, splitAssignmentsArray } from 'utils/assignmentsHelpers';

interface IShareLinksAccordion {
  result: AssignmentType[];
  publicRoute: string;
  getFullPublicLink: (item: AssignmentType) => string;
  copyHandler: (link: string) => void;
}

const ShareLinksAccordion: FC<IShareLinksAccordion> = ({
  result,
  publicRoute,
  getFullPublicLink,
  copyHandler,
}) => {
  const { firstRecipient, restOfArray } = useMemo(() => splitAssignmentsArray(result), [result]);

  return result
    ? (
      <>
        {
          firstRecipient && (
            <ShareLinkCard
              key={RECIPIENT_ASSIGNMENT}
              item={firstRecipient}
              fullLink={getFullPublicLink(firstRecipient)}
              copyHandler={copyHandler}
            />
          )
        }
        <div className="accordion">
          {
            restOfArray.map((item: AssignmentType, index: number) => {
              if (
                [URL_PUBLIC_COLLECTION, ROUTES.PUBLIC_COLLECTION_EXECUTED].includes(publicRoute)
                && isManagerAssignment(item.type)
              ) return null;
              return (
                <ShareLinkAccordionItem
                  key={`shareLinkAccordionItem${index + 1}`}
                  index={index}
                  item={item}
                  getFullPublicLink={getFullPublicLink}
                  copyHandler={copyHandler}
                />
              );
            })
          }
        </div>
      </>
    ) : (<div className="border border-dark rounded-3 p-3 my-3 overflow-hidden">{result}</div>);
};

export default ShareLinksAccordion;