import { COLLECTION_TYPE } from 'constants/general';
import ResourcesStoreParts from 'constants/serverSideEvents';
import {
  ADD_FIELD_TO_FORM_BUILDER,
  ADD_SECTIONS_TO_FORM_BUILDER,
  ADD_SIGNNOW_DOC_SENT_CURRENT_MONTH,
  AUTHENTICATE_USER,
  AUTOREFRESH_PROCESS,
  CHANGE_TEMPLATE_IN_COLLECTION,
  CLEAR_PAGE_SETTING_VALUE_FROM_NEW_DOCUMENT,
  DELETE_MULTI_TEMPLATES_SUCCESS,
  GET_MULTI_TEMPLATES_SUCCESS,
  REMOVE_SECTIONS_FROM_FORM_BUILDER,
  SET_ALL_COLLECTIONS_EXECUTED,
  SET_COLLECTION_EXECUTED_DETAILS,
  SET_DOCTYPES,
  SET_DOCUMENT_DETAILS,
  SET_DOCUMENTS,
  SET_LAST_CREATED_SECTION,
  SET_METADATA_RESOURCES,
  SET_MONDAY_COM_INTEGRATION_SETTINGS_INTO_COLLECTION,
  SET_MONDAY_COM_INTEGRATION_SETTINGS_INTO_DOCUMENT,
  SET_MULTI_TEMPLATE_DETAILS,
  SET_NOTIFICATION_COUNT,
  SET_PAGE_SETTING_VALUE_FOR_DOCUMENT,
  SET_PAGE_SETTING_VALUE_FOR_NEW_DOCUMENT,
  SET_PAGE_SETTING_VALUE_FOR_TEMPLATE,
  SET_RESOURCES,
  SET_SECTION_DETAILS,
  SET_SECTIONS,
  SET_STATES,
  SET_STATUSES,
  SET_TEMPLATE_DETAILS,
  SET_TEMPLATES,
  SSE_UPDATE_RESOURCES_BY_TYPE,
  UPDATE_DOCUMENTS_COUNT,
  UPDATE_FORM_BUILDER,
  UPDATE_FORM_BUILDER_SECTIONS,
  UPDATE_FORM_BUILDER_SECTIONS_ORDER,
  UPDATE_PARAGRAPH_VALUE,
} from 'store/actions/actionTypes';
import { IDocumentDetails } from 'types/Documents';
import { ICollectionTemplate } from 'types/MultiTemplate';
import { Action } from 'types/redux';
import { IFormBuilderSection } from 'types/Sections';
import { ITemplateDetails } from 'types/Templates';
import { arraySortByDate } from 'utils/arraySortByDate';
import {
  addFieldToFormBuilder,
  getFilteredCollectionFormBuilder,
  updateParagraphValue,
} from 'utils/FormBuilder/formBuilderHelpers';
import {
  getCommonDetails,
  updateFormBuilderStructure,
} from 'utils/FormBuilder/store';
import { getAuthInStorage } from 'utils/localStorage';

export interface IUserState {
  isAuthenticated: any | boolean;
  documents: any[];
  documentsExecuted: any[];
  documentsCount: any | null;
  autoRefreshProcess: boolean;
  collections: any[];
  collectionsExecuted: any[];
  sections: any[];
  states: any[];
  doctypes: any[];
  statuses: any[];
  piiList: any[];
  lastCreatedSection: any | null;
  sectionDetails: any | null;
  templateDetails: ITemplateDetails | null;
  documentDetails: IDocumentDetails | null;
  newDocumentDetails: Pick<ITemplateDetails, 'page_settings'> | null;
  collectionDetails: any | null;
  collectionExecutedDetails: any | null;
  notificationsCount: number;
  signnowDocumentsSentCurrentMonth: number | null;
}

const initialState: IUserState = {
  isAuthenticated: getAuthInStorage() || false,
  documents: [],
  documentsExecuted: [],
  documentsCount: null,
  autoRefreshProcess: false,
  collections: [],
  collectionsExecuted: [],
  sections: [],
  states: [],
  doctypes: [],
  statuses: [],
  piiList: [],
  lastCreatedSection: null,
  templateDetails: null,
  sectionDetails: null,
  documentDetails: null,
  collectionDetails: null,
  collectionExecutedDetails: null,
  newDocumentDetails: null,
  notificationsCount: 0,
  signnowDocumentsSentCurrentMonth: null,
};

// TODO: We have a problem with the return type `IUserState`. Need resolve it.
// eslint-disable-next-line default-param-last
const userData = (state: IUserState = initialState, action: Action) => {
  switch (action.type) {
    case SSE_UPDATE_RESOURCES_BY_TYPE: {
      const typeToUpdate: ResourcesStoreParts = action.payload.resourceType;
      return {
        ...state,
        [typeToUpdate]: arraySortByDate([...state[typeToUpdate], ...action.payload.newResource]),
      };
    }
    case UPDATE_DOCUMENTS_COUNT:
      return {
        ...state,
        documentsCount: action.payload,
      };
    case SET_RESOURCES:
      return {
        ...state,
        documents: action.payload.documents,
        documentsExecuted: action.payload.documentsExecuted,
        collections: action.payload.collections,
        collectionsExecuted: action.payload.collectionsExecuted,
        documentsCount: action.payload.documentsCount,
        signnowDocumentsSentCurrentMonth: action.payload.signnowDocumentsSentCurrentMonth,
      };
    case SET_METADATA_RESOURCES:
      return {
        ...state,
        states: action.payload.states,
        doctypes: action.payload.doctypes,
        statuses: action.payload.statuses,
        piiList: action.payload.piiList,
      };
    case SET_MULTI_TEMPLATE_DETAILS:
      return {
        ...state,
        collectionDetails: action.payload,
      };
    case GET_MULTI_TEMPLATES_SUCCESS:
      return {
        ...state,
        collections: action.payload.data,
      };
    case DELETE_MULTI_TEMPLATES_SUCCESS: {
      const keyToUpdate = action.payload.resourceType === COLLECTION_TYPE ? 'collections' : 'collectionsExecuted';
      return {
        ...state,
        [keyToUpdate]: action.payload.data,
      };
    }
    case SET_DOCUMENTS:
      return {
        ...state,
        documentsExecuted: action.payload,
      };
    case AUTOREFRESH_PROCESS:
      return {
        ...state,
        autoRefreshProcess: action.payload,
      };
    case SET_TEMPLATES:
      return {
        ...state,
        documents: action.payload,
      };
    case SET_SECTIONS:
      return {
        ...state,
        sections: action.payload,
      };
    case SET_STATES:
      return {
        ...state,
        states: action.payload,
      };
    case SET_DOCTYPES:
      return {
        ...state,
        doctypes: action.payload,
      };
    case SET_STATUSES:
      return {
        ...state,
        statuses: action.payload,
      };
    case SET_LAST_CREATED_SECTION:
      return {
        ...state,
        lastCreatedSection: action.payload,
      };
    case SET_TEMPLATE_DETAILS:
      return {
        ...state,
        templateDetails: action.payload,
      };
    case SET_DOCUMENT_DETAILS:
      return {
        ...state,
        documentDetails: action.payload,
      };
    case SET_SECTION_DETAILS:
      return {
        ...state,
        sectionDetails: action.payload,
      };
    case AUTHENTICATE_USER:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case SET_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationsCount: action.payload,
      };
    case UPDATE_FORM_BUILDER: {
      if (!action.payload.key) {
        return updateFormBuilderStructure(state, action.payload.formBuilderType, action.payload.formBuilder);
      }

      const { currentDetails } = getCommonDetails(state, action.payload.formBuilderType);
      const formBuilder: IFormBuilderSection[] = currentDetails.form_builder_structure.map(
        (formBuilderSection: IFormBuilderSection) => {
          if (formBuilderSection.key === action.payload.key) {
            return {
              ...formBuilderSection,
              ...action.payload.formBuilder,
            };
          }
          return formBuilderSection;
        },
      );

      return updateFormBuilderStructure(state, action.payload.formBuilderType, formBuilder);
    }
    case ADD_SECTIONS_TO_FORM_BUILDER: {
      const { currentDetails } = getCommonDetails(state, action.payload.formBuilderType);
      const formBuilder = [
        ...currentDetails?.form_builder_structure ?? [],
        ...action.payload.formBuilderSections,
      ];

      return updateFormBuilderStructure(state, action.payload.formBuilderType, formBuilder);
    }
    case REMOVE_SECTIONS_FROM_FORM_BUILDER: {
      // Deleting section from collection form builder
      const { currentDetails } = getCommonDetails(state, action.payload.formBuilderType);
      const filteredFormBuilder: IFormBuilderSection[] | undefined = getFilteredCollectionFormBuilder(
        currentDetails,
        action.payload.documentId,
      );

      if (filteredFormBuilder) {
        return updateFormBuilderStructure(state, action.payload.formBuilderType, filteredFormBuilder);
      }
      return state;
    }
    case CHANGE_TEMPLATE_IN_COLLECTION:
      return {
        ...state,
        collectionDetails: {
          ...state.collectionDetails,
          templates: state.collectionDetails.templates.map((templateObj: ICollectionTemplate) => {
            if (templateObj.template_id === action.payload.oldTemplateId) {
              const templateCopy = { ...templateObj };
              templateCopy.template = action.payload.template;
              templateCopy.template_id = action.payload.template.id;
              return templateCopy;
            }
            return templateObj;
          }),
        },
      };
    case UPDATE_FORM_BUILDER_SECTIONS_ORDER:
    case UPDATE_FORM_BUILDER_SECTIONS: {
      return updateFormBuilderStructure(state, action.payload.formBuilderType, action.payload.formBuilder);
    }
    case ADD_FIELD_TO_FORM_BUILDER: {
      const { currentDetails } = getCommonDetails(state, action.payload.formBuilderType);
      const updatedFormBuilder: IFormBuilderSection[] = addFieldToFormBuilder(
        currentDetails.form_builder_structure,
        action.payload.sectionKey,
        action.payload.fieldPosition,
        action.payload.field,
      );

      return updateFormBuilderStructure(state, action.payload.formBuilderType, updatedFormBuilder);
    }
    case UPDATE_PARAGRAPH_VALUE: {
      const { currentDetails } = getCommonDetails(state, action.payload.formBuilderType);
      const updatedFormBuilder = updateParagraphValue(
        currentDetails.form_builder_structure,
        action.payload.sectionKey,
        action.payload.fieldKey,
        action.payload.value,
      );

      return updateFormBuilderStructure(state, action.payload.formBuilderType, updatedFormBuilder);
    }
    case SET_PAGE_SETTING_VALUE_FOR_TEMPLATE: {
      const { key, value } = action.payload;
      return {
        ...state,
        templateDetails: {
          ...state.templateDetails,
          page_settings: {
            ...state.templateDetails?.page_settings,
            [key]: value,
          },
        },
      };
    }
    case SET_PAGE_SETTING_VALUE_FOR_DOCUMENT: {
      const { key, value } = action.payload;
      return {
        ...state,
        documentDetails: {
          ...state.documentDetails,
          page_settings: {
            ...state.documentDetails?.page_settings,
            [key]: value,
          },
        },
      };
    }
    case SET_PAGE_SETTING_VALUE_FOR_NEW_DOCUMENT: {
      const { key, value } = action.payload;
      return {
        ...state,
        newDocumentDetails: {
          ...state.newDocumentDetails,
          page_settings: {
            ...state.newDocumentDetails?.page_settings,
            [key]: value,
          },
        },
      };
    }
    case CLEAR_PAGE_SETTING_VALUE_FROM_NEW_DOCUMENT: {
      return {
        ...state,
        newDocumentDetails: null,
      };
    }
    case SET_MONDAY_COM_INTEGRATION_SETTINGS_INTO_DOCUMENT: {
      return {
        ...state,
        templateDetails: {
          ...state.templateDetails,
          monday_com_integration: action.payload,
        },
      };
    }
    case SET_MONDAY_COM_INTEGRATION_SETTINGS_INTO_COLLECTION: {
      return {
        ...state,
        collectionDetails: {
          ...state.collectionDetails,
          monday_com_integration: action.payload,
        },
      };
    }
    case ADD_SIGNNOW_DOC_SENT_CURRENT_MONTH: {
      return {
        ...state,
        signnowDocumentsSentCurrentMonth: state.signnowDocumentsSentCurrentMonth !== null
          ? state.signnowDocumentsSentCurrentMonth + 1
          : null,
      };
    }
    case SET_COLLECTION_EXECUTED_DETAILS:
      return {
        ...state,
        collectionExecutedDetails: action.payload,
      };
    case SET_ALL_COLLECTIONS_EXECUTED:
      return {
        ...state,
        collectionsExecuted: action.payload,
      };
    default:
      return state;
  }
};

export default userData;