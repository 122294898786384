export const enum ShareViewModes {
  SHARE_MODE_FORM = 'form',
  SHARE_MODE_DOCUMENT = 'document',
  SHARE_MODE_BOTH = 'both',
  SHARE_MODE_FORM_BUILDER = 'form_builder',
  SHARE_MODE_DOCUMENT_AND_FORM_BUILDER = 'document_and_form_builder',
  SHARE_MODE_READ_ONLY = 'read_only',
}

export const SHARE_VIEW_MODES = [
  { label: 'Both views', value: ShareViewModes.SHARE_MODE_BOTH },
  { label: 'Form view', value: ShareViewModes.SHARE_MODE_FORM },
  { label: 'Document view', value: ShareViewModes.SHARE_MODE_DOCUMENT },
  { label: 'Read only view', value: ShareViewModes.SHARE_MODE_READ_ONLY },
];

export const FORMS_SHARE_VIEW_MODES = [
  { label: 'Document view', value: ShareViewModes.SHARE_MODE_DOCUMENT },
  { label: 'Read only view', value: ShareViewModes.SHARE_MODE_READ_ONLY },
];

export const SHARE_VIEW_FORM_BUILDER_MODE = {
  label: 'Form Builder view',
  value: ShareViewModes.SHARE_MODE_FORM_BUILDER,
};

export const SHARE_VIEW_DOCUMENT_AND_FORM_BUILDER = {
  label: 'Document & Form Builder view',
  value: ShareViewModes.SHARE_MODE_DOCUMENT_AND_FORM_BUILDER,
};
