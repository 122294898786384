import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ReactSignatureCanvas from 'react-signature-canvas';
import { toast } from 'react-toastify';
import { Modal } from 'reactstrap';

import BodySignModal from 'components/Modals/SignatureModal/BodySignatureModal';
import FooterSignModal from 'components/Modals/SignatureModal/FooterSignatureModal';
import HeaderSignModal from 'components/Modals/SignatureModal/HeaderSignatureModal';
import { RECIPIENT_ASSIGNMENT } from 'constants/editors';
import { EXECUTED_DOCUMENTS_ROUTES } from 'constants/general';
import {
  INITIAL_SIGNATURE_MAX_LENGTH,
  INITIAL_SIGNATURE_MAX_LENGTH_VALIDATION_MESSAGE,
  MIN_IMAGE_DIMENSION,
  NOT_EXISTED_SIGNATURE_IMAGE,
  RESIZING_INDEX,
  SIGN_FILE_SIZE,
  SIGN_FILE_TYPES_VALIDATION,
  SIGNATURE_FIELD_DEFAULT_VARIATION,
  SIGNATURE_TABS,
} from 'constants/signatures';
import usePublicPageRouteMatch from 'hooks/usePublicPageRouteMatch';
import useSingleDocument from 'hooks/useSingleDocument';
import useTrackingLogEvents from 'hooks/useTrackingLogEvents';
import { deletePartOfHistoryLog } from 'store/actions/publicPages';
import {
  updateSaveSignatureProperty,
  updateSignaturePathProperty,
  updateUserPersonalData,
} from 'store/actions/userPersonalData';
import { RootStateType } from 'store/reducers';
import { HISTORY_ACTIONS } from 'types/DocumentsHistory';
import { IPublicPageDocumentStructure, IPublicPageRouteMatch } from 'types/PublicPage';
import { ISigningModal } from 'types/Signatures';
import { isManagerAssignment, isRecipientAssignment } from 'utils/assignmentsHelpers';
import { saveHistoryRecord } from 'utils/documentsHistory';
import { isBase64String } from 'utils/editorFieldHelpers';
import { getSignatureModalTitle } from 'utils/modalHelpers';
import { toShowBlock } from 'utils/routeHelpers';

const SigningModal: FC<ISigningModal> = ({
  existingValue,
  showModal,
  onCloseModal,
  onSave,
  assignment = RECIPIENT_ASSIGNMENT,
  currentTab = SIGNATURE_TABS.DRAW_TAB,
  fieldKey = 0,
  signatureFieldVariation = SIGNATURE_FIELD_DEFAULT_VARIATION,
  shouldApplySignatureAutosaving = false,
}) => {
  const dispatch = useDispatch();
  const currentPublicDocument: IPublicPageDocumentStructure = useSelector(
    (state: RootStateType) => state.publicPages.currentDocument,
  );

  const { save_signature: saveSignature } = useSelector((state: RootStateType) => state.profile?.personalData ?? {});
  const { documentDetails } = useSelector((state: RootStateType) => state.user);
  const docExecutedId = documentDetails?.id;

  const { pageType }: IPublicPageRouteMatch = usePublicPageRouteMatch();
  const [signaturePad, setSignaturePad] = useState<ReactSignatureCanvas | null>(null);
  const [typedSignature, setTypedSignature] = useState<string>(
    currentTab === SIGNATURE_TABS.TYPE_TAB ? existingValue ?? '' : '',
  );
  const [signatureTab, setSignatureTab] = useState<SIGNATURE_TABS>(currentTab);
  const [uploadImg, setUploadImg] = useState<string>('');
  const [uploadImageError, setUploadImageError] = useState<string>('');
  const [initialsSignatureError, setInitialsSignatureError] = useState<string>('');
  const [touchSignaturePad, setTouchSignaturePad] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const isExecutedDocument = EXECUTED_DOCUMENTS_ROUTES.includes(pageType);
  const isSingleDocument = useSingleDocument();

  const { pathname } = useLocation();
  const isPublicURL = toShowBlock(pathname, 'public');

  const callSigningTrackingEvents = useTrackingLogEvents(Number(fieldKey), assignment);
  const isPublicRecipientPage = isPublicURL && isRecipientAssignment(assignment);
  const isManagerField = isManagerAssignment(assignment);

  useEffect(() => {
    if (Boolean(existingValue) && existingValue === NOT_EXISTED_SIGNATURE_IMAGE) {
      setSignatureTab(SIGNATURE_TABS.DRAW_TAB);
      if (showModal) {
        toast.warn('An error has occurred. Please recreate your signature or contact a manager.');
      }
    }
  }, [existingValue, showModal]);

  useEffect(() => {
    if (currentTab !== signatureTab) {
      setSignatureTab(currentTab);
    }
  }, [currentTab]);

  const handlerClear = (clearValue = false) => {
    setTouchSignaturePad(false);
    setUploadImageError('');
    setUploadImg('');
    setTypedSignature('');
    setInitialsSignatureError('');
    if (clearValue) {
      onSave('', signatureTab);

      if (existingValue && !isSingleDocument) {
        if (isPublicRecipientPage) {
          if (isExecutedDocument) {
            saveHistoryRecord(HISTORY_ACTIONS.DELETED_SIGNATURE, currentPublicDocument.id);
          } else {
            dispatch(deletePartOfHistoryLog(`${HISTORY_ACTIONS.SIGNED}_${fieldKey}`));
          }
        }
        // TODO The second case: manager deletes signature from recipient's field
        if (isManagerField && isExecutedDocument) {
          saveHistoryRecord(HISTORY_ACTIONS.DELETED_SIGNATURE, docExecutedId || currentPublicDocument.id);
        }
      }
    }
    if (signaturePad) {
      signaturePad.clear();
    }
  };

  const changeTab = (tab: SIGNATURE_TABS) => {
    handlerClear();
    setSignatureTab(tab);
  };

  const changeTypedSignature = (typedSignature: string): void => {
    if (typedSignature && typedSignature.length <= INITIAL_SIGNATURE_MAX_LENGTH) {
      setInitialsSignatureError('');
    }

    setTypedSignature(typedSignature);
  };

  const onBeginDraw = () => {
    setUploadImageError('');
    setTouchSignaturePad(true);
  };

  const saveSignatureToProfile = useCallback((base64String: string) => {
    if (shouldApplySignatureAutosaving) {
      const signatureData = {
        save_signature: saveSignature,
      };
      if (saveSignature) {
        dispatch(updateSignaturePathProperty({ ...signatureData, image_data: base64String }));
      } else {
        dispatch(updateUserPersonalData(signatureData));
      }
    }
  }, [shouldApplySignatureAutosaving, saveSignature]);

  const saveDataAndCloseModal = (uploadData: string) => {
    onSave(uploadData, signatureTab);
    onCloseModal(false);

    saveSignatureToProfile(uploadData);
    callSigningTrackingEvents();
  };

  const handlerSave = () => {
    if (signaturePad) {
      if (!touchSignaturePad) {
        return setUploadImageError('Signature pad is empty. You have to draw your signature');
      }
      setTouchSignaturePad(false);
      setUploadImageError('');
      const base64String = signaturePad.getCanvas().toDataURL('image/png');
      saveDataAndCloseModal(base64String);
    } else if (uploadImg) {
      saveDataAndCloseModal(uploadImg);
      setUploadImageError('');
    } else if (typedSignature) {
      if (signatureFieldVariation === SIGNATURE_FIELD_DEFAULT_VARIATION) {
        saveDataAndCloseModal(typedSignature);
      } else {
        if (typedSignature.length > INITIAL_SIGNATURE_MAX_LENGTH) {
          setInitialsSignatureError(INITIAL_SIGNATURE_MAX_LENGTH_VALIDATION_MESSAGE);
          return;
        }
        setInitialsSignatureError('');
        saveDataAndCloseModal(typedSignature);
      }
    }
  };

  const handlerCloseModal = () => {
    if (!existingValue) {
      dispatch(updateSaveSignatureProperty(false));
    }
    handlerClear(false);
    onCloseModal(false);
  };

  const validateSignatureUpload = (value: File, width: number, height: number) => {
    if (value === null || typeof (value) === 'undefined') return 'Bad request';
    if (!(SIGN_FILE_TYPES_VALIDATION.includes(value.type))) return 'Wrong file format, please try .jpeg, .jpg or .png';
    if (((value.size) / 1024 / 1024) > SIGN_FILE_SIZE) return `File size should not be bigger than ${SIGN_FILE_SIZE}Mb`;
    const validSize = width < MIN_IMAGE_DIMENSION || height < MIN_IMAGE_DIMENSION;
    if (validSize) return `Very small picture. Width and height have to be bigger than ${MIN_IMAGE_DIMENSION}px`;
    if (width / height > 3.5) return 'This image is too wide and will not fit. Please crop and upload again';
  };

  const handleUploadImage = (event: React.FormEvent<HTMLInputElement>) => {
    if (!event.currentTarget || !event.currentTarget.files) return;
    const file = event.currentTarget.files[0];
    setUploadImageError('');

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (readerEvent) => {
      const image: HTMLImageElement = new Image();
      image.src = String(readerEvent.target?.result) || '';
      image.onload = () => {
        const error = validateSignatureUpload(file, image.width, image.height);
        if (error) {
          return setUploadImageError(error);
        }

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const resizingIndex = image.height / RESIZING_INDEX;

        canvas.width = image.width / resizingIndex;
        canvas.height = image.height / resizingIndex;
        context?.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);
        const base64String = canvas.toDataURL();
        setUploadImg(base64String);
      };
    };
  };

  const isDisabledSignButton = (!uploadImg && signatureTab === SIGNATURE_TABS.UPLOAD_TAB)
    || (signatureTab === SIGNATURE_TABS.DRAW_TAB && !touchSignaturePad)
    || (signatureTab === SIGNATURE_TABS.TYPE_TAB && !typedSignature);

  const isShownCanvas = signatureTab === SIGNATURE_TABS.DRAW_TAB
    || (signatureTab === SIGNATURE_TABS.UPLOAD_TAB && (Boolean(uploadImg) || isBase64String(existingValue)));

  return (
    <Modal
      centered
      isOpen={showModal}
      className="signature-modal"
      contentClassName="signature-content"
    >
      <HeaderSignModal
        signatureTab={signatureTab}
        handlerCloseModal={handlerCloseModal}
        changeCurrentTab={changeTab}
        title={getSignatureModalTitle(signatureFieldVariation)}
      />
      <BodySignModal
        fileInputRef={fileInputRef}
        isShownCanvas={isShownCanvas}
        setSignaturePad={setSignaturePad}
        handleUploadImage={handleUploadImage}
        handlerClear={handlerClear}
        existingValue={uploadImg || existingValue}
        onBeginDraw={onBeginDraw}
        signatureTab={signatureTab}
        typedSignature={typedSignature}
        changeTypedSignature={changeTypedSignature}
        signatureFieldVariation={signatureFieldVariation}
        shouldApplySignatureAutosaving={shouldApplySignatureAutosaving}
      />
      <FooterSignModal
        error={uploadImageError || initialsSignatureError}
        isDisabledButton={isDisabledSignButton}
        handlerSave={handlerSave}
      />
    </Modal>
  );
};

export default SigningModal;