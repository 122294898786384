import {
  DELETE_DEFAULT_SIGNATURE_DATA,
  GET_SIGNING_CERTIFICATES,
  GET_USER_PII_DATA,
  RESET_USER_PII_DATA,
  SET_USER_PERSONAL_DATA_PROPERTY,
  SET_USER_PII_DATA,
  UPDATE_USER_PERSONAL_DATA,
  UPDATE_USER_PERSONAL_SIGNATURE,
  UPDATE_USER_SIGNING_CERTIFICATE,
} from 'store/actions/actionTypes';
import { IUserPersonalDataObject, IUserPersonalSignatureObject, PIIDataType } from 'types/userProfile';

export const updateSaveSignatureProperty = (isSaveSignature: boolean) => ({
  type: SET_USER_PERSONAL_DATA_PROPERTY,
  payload: { property: 'save_signature', value: isSaveSignature },
});

export const updateSignaturePathProperty = (signatureData: IUserPersonalSignatureObject) => ({
  type: UPDATE_USER_PERSONAL_SIGNATURE,
  payload: signatureData,
});

export const updateUserPersonalData = (personalData: Partial<IUserPersonalDataObject>) => ({
  type: UPDATE_USER_PERSONAL_DATA,
  payload: personalData,
});

export const updateUserPIIData = (piiData: PIIDataType[]) => ({
  type: SET_USER_PII_DATA,
  payload: piiData,
});

export const resetUserPIIData = (piiData: PIIDataType[]) => ({
  type: RESET_USER_PII_DATA,
  payload: piiData,
});

export const getUserPIIData = () => ({
  type: GET_USER_PII_DATA,
});

export const deleteDefaultSignature = () => ({
  type: DELETE_DEFAULT_SIGNATURE_DATA,
});

export const getUserSigningCertificate = () => ({
  type: GET_SIGNING_CERTIFICATES,
});

export const updateUserSigningCertificate = (formData: FormData) => ({
  type: UPDATE_USER_SIGNING_CERTIFICATE,
  payload: formData,
});