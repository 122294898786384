import { FC, useState } from 'react';

import SidePanelToggleButton from 'components/PublicPage/SidePanelToggleButton';

import classes from 'scss/components/PublicEditor/PublicEditor.module.scss';

interface IOutlinePublicPanel {
  title: string;
  reverse: boolean;
}

const OutlinePublicPanel: FC<IOutlinePublicPanel> = ({
  title,
  reverse,
  children,
}) => {
  const [isActiveToggle, setIsActiveToggle] = useState<boolean>(true);
  const situationalClasses = `${
    !isActiveToggle ? classes.OutlinePanelInactive : ''
  }`;

  return (
    <div
      className={
        `w-100 d-flex h-100 flex-row justify-content-${reverse ? 'end' : 'start'}`
      }
    >
      <div
        className={
          `${classes.PublicOutlinePanel} ${situationalClasses}`
        }
      >
        <div className={`d-flex flex-row justify-content-${isActiveToggle ? 'between' : 'center'}`}>
          {
            isActiveToggle ? (
              <div className={`${classes.OutlinePanelText} ${classes.OutlineHeader}`}>
                {title}
              </div>
            ) : null
          }
          <SidePanelToggleButton
            toggleHandler={setIsActiveToggle}
            isActive={isActiveToggle}
            isReverseMode={reverse}
          />
        </div>
        <div className="mt-3 overflow-auto h-100">
          {isActiveToggle && children}
        </div>
      </div>
    </div>
  );
};

export default OutlinePublicPanel;