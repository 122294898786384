import { FC } from 'react';

import AutocompleteField from 'components/Modals/MondayComModalWindow/AutocompleteField';
import { MondayComColumnType, MondayComItemDocumentFileType } from 'types/mondayCom/mondayComBoard';

interface IMondayComItemDocumentFileColumnSelect {
  boardId: string;
  columns: MondayComColumnType[];
  documentFileSettingsKey: MondayComItemDocumentFileType;
  currentColumn: MondayComColumnType | null;
  onChangeItemAttachmentSettingsHandler: (
    boardId: string,
    documentFileSettingsKey: MondayComItemDocumentFileType,
    targetColumn?: MondayComColumnType
  ) => void;
}

const MondayComItemDocumentFileColumnSelect: FC<IMondayComItemDocumentFileColumnSelect> = ({
  boardId,
  columns,
  documentFileSettingsKey,
  currentColumn,
  onChangeItemAttachmentSettingsHandler,
}) => {
  const changeItemDocumentFileColumnHandler = (value: string | null) => {
    const targetColumn = columns.find((column) => column.title === value);
    onChangeItemAttachmentSettingsHandler(boardId, documentFileSettingsKey, targetColumn);
  };

  return (
    <AutocompleteField
      options={columns.map((column: MondayComColumnType) => column.title)}
      currentValue={currentColumn ? currentColumn.title : ''}
      onChangeHandler={changeItemDocumentFileColumnHandler}
      inputLabel="Select Column"
    />
  );
};

export default MondayComItemDocumentFileColumnSelect;