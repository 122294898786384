import { Descendant } from 'slate';

import { DEFAULT_SECTION, PARAGRAPH, SECTION } from 'constants/editors';
import { createField, generateNumber } from 'utils/editorFieldHelpers';
import { replaceAllKeysAndValues } from 'utils/editorHelpers';

export const FORM_ACTIVE_SECTION_CLASS = 'form-section__active';

export const INITIAL_FORM_SECTION_VALUE: Descendant[] = [
  {
    type: SECTION,
    key: 0,
    position: 0,
    children: [{
      type: PARAGRAPH,
      key: generateNumber(),
      children: [
        {
          ...createField('text-field', true),
          children: [
            { text: 'New Field' },
          ],
        },
      ],
    }],
  },
];

export const INITIAL_CONTENT_DATA = [
  {
    ...INITIAL_FORM_SECTION_VALUE[0],
    key: DEFAULT_SECTION.key,
    children: replaceAllKeysAndValues(INITIAL_FORM_SECTION_VALUE[0].children || []),
  },
];