import { FC } from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

import SimpleSpinner from 'components/Base/SimpleSpinner';
import { IMondayComSelectBoards } from 'types/mondayCom/mondayComModalWindow';

const MondayComSelectBoards: FC<IMondayComSelectBoards> = ({
  boards,
  templateIntegrationData,
  onSelectBoardsHandler,
  boardsExistInAccount,
}) => {
  if (!boards) {
    return (<SimpleSpinner />);
  }

  return (
    boardsExistInAccount
      ? (
        <FormGroup>
          {
            boards.map((board) => (
              <FormControlLabel
                key={board.id}
                control={
                  (
                    <Checkbox
                      checked={
                        templateIntegrationData?.map(
                          (boardObject) => boardObject.board.id,
                        ).includes(board.id) || false
                      }
                      onChange={(event) => onSelectBoardsHandler(event, board.id)}
                    />
                  )
                }
                label={board.name}
              />
            ))
          }
        </FormGroup>
      )
      : (
        <div className="text-center">There are no boards in your Monday.com account.</div>
      )
  );
};

export default MondayComSelectBoards;