import { FC, ReactElement } from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import ForwardIcon from '@mui/icons-material/Forward';

import Tooltip from 'components/Tooltip';
import useReadOnlyMode from 'hooks/useReadOnlyMode';
import { RootStateType } from 'store/reducers';
import { ICompanyBranding } from 'types/CompanyBranding';
import { ICustomElement } from 'types/Editor';
import shouldShowBrandingInRoutes from 'utils/CompanyBranding/shouldShowBrandingInRoutes';

import vars from 'variables.module.scss';

interface INavigationArrows {
  children: ReactElement;
  field: ICustomElement;
  filledInFields?: Array<{ key: number; isFilledIn: boolean; }>
  isPublicPage?: boolean;
}

const NavigationArrows: FC<INavigationArrows> = ({
  field: {
    requiredField: isRequiredField,
    value,
    key,
  },
  isPublicPage = false,
  children,
  filledInFields,
}) => {
  const location = useLocation();
  const isReadOnlyMode = useReadOnlyMode();
  const isBrandingAvailable = shouldShowBrandingInRoutes(location.pathname);
  const { styleJSON }: ICompanyBranding = useSelector((state: RootStateType) => state.companyBranding);

  if (!filledInFields) {
    return children;
  }

  const fieldIndexForPointingIn: number = filledInFields.findIndex(({ isFilledIn }) => !isFilledIn);

  const shouldShowNavigationArrow = isPublicPage
    && !isReadOnlyMode
    && !value
    && filledInFields[fieldIndexForPointingIn]
    && key === filledInFields[fieldIndexForPointingIn].key;

  const getBackgroundColor = (): string => {
    if (isRequiredField) {
      if (isBrandingAvailable) {
        return styleJSON?.mainBrandColor ?? vars.brandPrimary;
      }
    }

    return vars.white;
  };

  return (
    <>
      {
        shouldShowNavigationArrow && (
          <span className="navigation-arrow-wrapper_icon">
            <Tooltip title={isRequiredField ? 'Required' : 'Optional'}>
              <ForwardIcon
                htmlColor={getBackgroundColor()}
                stroke={isBrandingAvailable ? styleJSON?.mainBrandColor ?? vars.brandPrimary : vars.brandPrimary}
              />
            </Tooltip>
          </span>
        )
      }
      {children}
    </>
  );
};

export default NavigationArrows;