import { FC, useCallback, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Input, InputGroup, InputGroupText, Label } from 'reactstrap';

import SearchIcon from '@mui/icons-material/Search';
import { Box, ClickAwayListener, IconButton } from '@mui/material';
import isHotkey from 'is-hotkey';
import { Descendant, Element as SlateElement } from 'slate';
import { useSlate } from 'slate-react';

import { FIELD_SEARCH_GROUPED_KEY, FIELD_TYPE, KEY_CODE } from 'constants/editors';
import { RootStateType } from 'store/reducers';
import { CustomEditorType, ICustomElement } from 'types/Editor';
import { searchAllSimilarGroupedFieldsByName, setSelectedFieldState } from 'utils/editorFieldHelpers';
import { filterNodes } from 'utils/editorHelpers';

import 'scss/components/_fieldProperties.scss';

interface ISearchFieldIconAndDropDownProperties {
  fieldList: ICustomElement[];
  contentJSON?: Descendant[];
  disabled?: boolean;
  showLabel?: boolean;
}

const SearchFieldIconAndDropDown: FC<ISearchFieldIconAndDropDownProperties> = ({
  fieldList,
  contentJSON = null,
  disabled = false,
  showLabel = false,
}) => {
  const dispatch = useDispatch();

  const [isResultOpened, setIsResultOpened] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<{ [key: number]: string }[]>([]);

  const { sections } = useSelector((state: RootStateType) => state.template);
  const { selectedField: slateFieldKey } = useSelector((state: RootStateType) => state.editorSlate);
  const {
    pdfTemplateKey,
    pdfTemplateFields,
    selectedFieldKey: pdfFieldKey,
  } = useSelector((state: RootStateType) => state.pdfTemplates);

  const isPDF = Boolean(pdfTemplateKey);
  const selectedFieldKey = isPDF ? pdfFieldKey : slateFieldKey;
  const slateContent = contentJSON ? ({ children: contentJSON } as CustomEditorType) : useSlate();
  const editor = isPDF ? undefined : slateContent;

  const allFieldElements: ICustomElement[] | null = (contentJSON && editor)
    ? filterNodes(editor.children, (node) => (
      SlateElement.isElement(node) && FIELD_TYPE.includes(node.type)
    ))
    : (isPDF && pdfTemplateFields && Object.values(pdfTemplateFields)) || fieldList;

  const searchGroupedRef = useRef<HTMLInputElement>(null);

  const searchFieldsHandler = useCallback((name: string) => {
    if (name) {
      return searchAllSimilarGroupedFieldsByName(
        allFieldElements,
        name,
        editor,
        sections,
        true,
      );
    }
    return [];
  }, [allFieldElements, editor?.forms, sections]);

  const onClickSearchHandler = () => {
    const inputElement = searchGroupedRef?.current;
    if (inputElement) {
      const results = searchFieldsHandler(inputElement.value || '');
      setSearchResults(results || []);
      setIsResultOpened(true);
    }
  };

  const onPressingEnterHandler = (event: React.KeyboardEvent<HTMLElement>) => {
    if (isHotkey(KEY_CODE.ENTER, event)) {
      const inputElement = searchGroupedRef?.current;
      if (inputElement && inputElement.value.length) {
        onClickSearchHandler();
      }
    }
  };

  const goToFieldByKeyHandler = (currentKey: number) => {
    setSelectedFieldState(selectedFieldKey, currentKey, dispatch, isPDF);
  };

  const onClickAwayHandler = () => {
    if (isResultOpened) {
      setIsResultOpened(false);
    }
  };

  return (
    <FormGroup>
      {showLabel && <Label for={FIELD_SEARCH_GROUPED_KEY} className="mb-0">Search</Label>}
      <ClickAwayListener onClickAway={onClickAwayHandler}>
        <div>
          <InputGroup>
            <Input
              id={FIELD_SEARCH_GROUPED_KEY}
              innerRef={searchGroupedRef}
              placeholder="Find Field"
              type="text"
              onChange={onClickSearchHandler}
              onKeyDown={onPressingEnterHandler}
              disabled={disabled}
            />
            <InputGroupText className="p-0">
              <IconButton
                aria-label="Find"
                onClick={onClickSearchHandler}
                className="search-field-button"
              >
                <SearchIcon />
              </IconButton>
            </InputGroupText>
          </InputGroup>
          <div className="position-relative">
            {
              isResultOpened ? (
                <Box className="form-control search-field-results">
                  {
                    searchResults.map((rowData) => {
                      const rowKey = Number(Object.keys(rowData)[0]);
                      const rowText = rowData[rowKey];
                      return (
                        <div
                          key={`searchFieldKey_${rowKey}`}
                          onClick={() => goToFieldByKeyHandler(rowKey)}
                          role="button"
                          tabIndex={0}
                          className={`search-field-results-row${selectedFieldKey === rowKey ? ' active' : ''}`}
                        >
                          {rowText}
                        </div>
                      );
                    })
                  }
                </Box>
              ) : null
            }
          </div>
        </div>
      </ClickAwayListener>
    </FormGroup>
  );
};

export default SearchFieldIconAndDropDown;