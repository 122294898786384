import { FC } from 'react';

import CheckboxSetting from 'components/PageSettings/CheckboxSetting';
import ContainerSetting from 'components/PageSettings/ContainerSetting';
import InputSetting from 'components/PageSettings/InputSetting';
import { IPageSettingProps, PageSettingsElementTypesEnum } from 'types/PageSettingsType';

const PageSetting: FC<IPageSettingProps> = ({ setting }) => {
  switch (setting.type) {
    case PageSettingsElementTypesEnum.Container:
      return <ContainerSetting setting={setting} />;
    case PageSettingsElementTypesEnum.Number:
      return <InputSetting setting={setting} />;
    case PageSettingsElementTypesEnum.Checkbox:
      return <CheckboxSetting setting={setting} />;
    default:
      return null;
  }
};

export default PageSetting;