import { FC, SVGAttributes } from 'react';

const MondayComIcon: FC<SVGAttributes<any>> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
    <g transform="matrix(3.208255 0 0 3.208255 -35.559129 -63.587202)">
      <path d="M13.513 35.76a2.433 2.433 0 0 1-2.059-3.723l4.377-6.99a2.432 2.432 0 1 1 4.123 2.582l-4.378 6.99a2.43 2.43 0 0 1-2.063 1.141z" fill="#ff3d57" />
      <path d="M21.056 35.76a2.433 2.433 0 0 1-2.063-3.723l4.38-6.99a2.432 2.432 0 1 1 4.117 2.582l-4.372 6.99a2.43 2.43 0 0 1-2.063 1.141z" fill="#ffcb00" />
      <ellipse ry="2.375" rx="2.436" cy="33.384" cx="28.597" fill="#00d647" />
    </g>
  </svg>
);

export default MondayComIcon;
