import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Descendant, Element as SlateElement } from 'slate';

import AttachmentsUploader from 'components/AttachmentsUploader';
import DocumentNavigator from 'components/DocumentNavigator';
import Editor from 'components/Editor';
import EditorContainer from 'components/Editor/EditorContainer';
import EditorWrapper from 'components/Editor/EditorWrapper';
import EditorHeader from 'components/EditorHeader/EditorHeader';
import ModalContentWindow from 'components/Modals/ModalContentWindow';
import ShareLinkModal from 'components/Modals/ModalWrapper';
import SearchFieldAndContent from 'components/Sidebar/SearchFieldAndContent';
import SectionsOutline from 'components/Sidebar/SectionsOutline';
import { MANAGER_ASSIGNMENT } from 'constants/editors';
import { INITIAL_CONTENT_DATA } from 'constants/formEditor';
import {
  DOCUMENT_EXECUTED_TYPE,
  DOCUMENT_NAME_LABEL,
  DOCUMENT_TYPE,
  FORM_TYPE,
  RedirectTypeEnum,
  URL_PUBLIC_TEMPLATE,
} from 'constants/general';
import { SOMETHING_WENT_WRONG } from 'constants/generalErrors';
import ROUTES from 'constants/routes';
import useSetDefaultReminderSettings from 'hooks/pageSettings/useSetDefaultReminderSettings';
import useSingleDocument from 'hooks/useSingleDocument';
import { changeSelectedField, changeSelectedSection } from 'store/actions/editorSlate';
import {
  clearAttachments,
  createTemplatePublicLink,
  deleteTemplateLinkFromStore,
  setCreatedDocumentId,
  setDocumentType,
} from 'store/actions/publicPages';
import { clearSections, sortFormDocumentSections, updateTemplateSections } from 'store/actions/template';
import {
  createTemplateAndSection,
  editTemplate,
  getTemplateDetails,
  setTemplateDetails,
} from 'store/actions/userData';
import { RootStateType } from 'store/reducers';
import { IMetadataId } from 'types/Metadata';
import { MatchParams } from 'types/Route';
import { IDocumentSection } from 'types/Sections';
import { IErrorField } from 'types/validation';
import { trimHTMLValue } from 'utils/descriptionHelper';
import { createSectionWithField, filterFormFieldsFromContent } from 'utils/editorFieldHelpers';
import { addSectionsDocument, cleanUpCopiedSectionData } from 'utils/editorHelpers';
import setDefaultPageValuesHelper from 'utils/reduxHelpers';
import { getEditorPageLink } from 'utils/routeHelpers';
import { errorSectionsName, validationForm } from 'utils/validation';

const FormDetails = () => {
  const dispatch = useDispatch();
  const isSingleDocument = useSingleDocument();
  const history = useHistory();
  const params: MatchParams = useParams();
  const id: string = params.id;

  const { templateDetails, doctypes } = useSelector((state: RootStateType) => state.user);
  const { templatePublicLink, documentId } = useSelector((state: RootStateType) => state.publicPages);
  const { isLoading } = useSelector((state: RootStateType) => state.errorLoading);
  const { sections, sortMode } = useSelector((state: RootStateType) => state.template);
  const { selectedSection, assignments } = useSelector((state: RootStateType) => state.editorSlate);

  const [previewMode, setPreviewMode] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<IErrorField>({ template_name: null, title: null });
  const [templateName, setTemplateName] = useState<string>('');
  const [templateDescription, setTemplateDescription] = useState<string>('');
  const [templateState, setTemplateState] = useState<IMetadataId[]>([{ id: 1 }]);
  const [templateDoctype, setTemplateDoctype] = useState<IMetadataId | null>(null);
  const [showShareLinkModal, setShowShareLinkModal] = useState<boolean>(false);
  const [isShowFillSignButton, setIsShowFillSignButton] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [templateContent, setTemplateContent] = useState<Descendant[]>([]);

  const [searchString, setSearchString] = useState<string>('');
  const [redirectType, setRedirectType] = useState<RedirectTypeEnum>(RedirectTypeEnum.Create);

  // Set default reminders settings
  useSetDefaultReminderSettings();

  useEffect(() => {
    dispatch(setDocumentType(isSingleDocument ? DOCUMENT_EXECUTED_TYPE : DOCUMENT_TYPE));
    if (id) {
      dispatch(getTemplateDetails(id));
    }
    return () => {
      setDefaultPageValuesHelper(dispatch, true);
      dispatch(setTemplateDetails(null));
      dispatch(changeSelectedSection(null));
      dispatch(changeSelectedField(null));
      dispatch(setDocumentType(null));
      dispatch(clearSections());
      if (redirectType !== RedirectTypeEnum.Single) {
        dispatch(setCreatedDocumentId(0));
      }
      dispatch(clearAttachments());
    };
  }, [id]);

  useEffect(() => {
    if (templateDetails) {
      if (templateDetails.type !== FORM_TYPE) {
        history.push(getEditorPageLink(id, templateDetails.type));
        return;
      }

      setTemplateState([{ id: templateDetails.states[0].id }]);
      setTemplateDoctype({ id: templateDetails.doctype.id });
      setTemplateName(templateDetails.name);
      setTemplateDescription(templateDetails.description);

      const newSections = templateDetails.sections.map((section: IDocumentSection) => ({
        name: section.section.name,
        description: section.section.description,
        key: section.section.id,
        section_id: section.section.id,
        position: section.position,
      }));
      const sortedSections = templateDetails.sections
        .sort((a: IDocumentSection, b: IDocumentSection) => a.position - b.position)
        .map((sect: IDocumentSection) => ({ ...sect.section, position: sect.position }));

      setTemplateContent(addSectionsDocument(sortedSections, false));
      dispatch(updateTemplateSections(newSections));
    }
  }, [
    templateDetails?.type,
    templateDetails?.name,
    templateDetails?.description,
    templateDetails?.states,
    templateDetails?.doctype.id,
    templateDetails?.sections,
    dispatch,
  ]);

  useEffect(() => {
    if (!templateDoctype && doctypes.length) {
      const defaultDoctype = doctypes[0];
      setTemplateDoctype(defaultDoctype);
    }
  }, [doctypes, templateDoctype]);

  useEffect(() => {
    if (sortMode) {
      // Fires when we sort sections or fields
      const updatedContent = sections.map((section) => {
        const currentSection = templateContent.find((el) => section.key === el.key);
        const children = (section as Partial<SlateElement>).children || currentSection?.children || [];
        return {
          ...currentSection,
          position: section.position,
          children,
        };
      });
      setTemplateContent(updatedContent as Descendant[]);
      dispatch(sortFormDocumentSections(false));
    }
  }, [sortMode]);

  useEffect(() => {
    if (id) {
      dispatch(changeSelectedField(null));
    }
  }, [sections.length, id, isLoading]);

  useEffect(() => {
    /**
     * Sorting order of useEffects matters:
     * This useEffect should be before the next one
     * for actual content value while we creating fields and then copy sections
     */
    if (!templateContent.length) {
      setTemplateContent(INITIAL_CONTENT_DATA);
      return;
    }
    setIsShowFillSignButton(
      Boolean(id) && !!filterFormFieldsFromContent(templateContent, MANAGER_ASSIGNMENT).length,
    );
  }, [templateContent, id]);

  useEffect(() => {
    // Fires when we change section content: add, copy remove section or change fields, name, description inside section
    const updatedContent = sections
      .sort((a, b) => Number(a.position) - Number(b.position))
      .map((section, index) => {
        const sectionInEditor = templateContent.find((el) => el.key === section.key);
        if (sectionInEditor) {
          return {
            ...sectionInEditor,
            name: section.name,
            description: section.description || '',
            position: index,
          };
        }

        // it is a new section: created or copied
        const childrenContent = cleanUpCopiedSectionData(section, selectedSection, templateContent);
        return createSectionWithField(section.key as number, index, section.name, childrenContent);
      });
    setTemplateContent(updatedContent);
    errorSectionsName(sections, true);
  }, [sections]);

  const validateTemplateName = (templateName: string) => {
    const validateResult = validationForm([{
      titleField: DOCUMENT_NAME_LABEL,
      nameField: 'template_name',
      valueField: templateName,
      required: true,
    }]);
    setFormErrors((prev) => ({ ...prev, ...validateResult.validationFields }));
    return validateResult;
  };

  const onChangeTemplateName: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    setTemplateName(target.value);
  };

  const saveTemplateHandler = (
    openManagerPage: boolean = false,
    redirectType: RedirectTypeEnum | undefined = undefined,
    redirectPath: string | undefined = undefined,
  ) => {
    const validateNameResult = validateTemplateName(templateName);
    if (validateNameResult.isError) return null;
    if (!templateDoctype) return null;
    if (errorSectionsName(sections, true)) return null;

    const selectedStates = templateState.map((state) => ({ id: state.id }));
    const templateInfo = {
      name: templateName.trim(),
      description: trimHTMLValue(templateDescription),
      template_title: '',
      assignments,
    };

    const updatedSections = sections.map((section, index) => {
      const sectionInEditor = templateContent.find((el) => el.key === section.key) || templateContent[index];
      let sectionDataToUpdate = {};

      // Sort all fields before saving
      const sortedSectionFields = sectionInEditor.children?.map((paragraphData, index) => ({
        ...paragraphData,
        children: paragraphData.children?.map((field: Partial<SlateElement>) => {
          const fieldPosition = field.assignment ? { position: index } : {};
          return {
            ...field,
            ...fieldPosition,
          };
        }) || [],
      }));

      const sectionData = {
        name: section.name.trim(),
        description: trimHTMLValue(section.description),
        content_json: sortedSectionFields || sectionInEditor.children,
        states: selectedStates,
      };
      if (id) {
        sectionDataToUpdate = {
          section_id: section.section_id || null,
        };
      }
      return { ...sectionData, ...sectionDataToUpdate };
    });

    if (id) {
      const templateData = {
        ...templateInfo,
        sections: updatedSections,
      };
      if (redirectType) {
        setRedirectType(redirectType);
      } else {
        setRedirectType(openManagerPage ? RedirectTypeEnum.FillAndSign : RedirectTypeEnum.Update);
      }
      return dispatch(editTemplate({
        id,
        template: templateData,
        ...(openManagerPage && { path: `${ROUTES.MANAGER_TEMPLATE}/${id}` }),
        ...(redirectType && { path: redirectPath }),
      }));
    }

    const templateDataToCreate = {
      sections: updatedSections,
      template: {
        ...templateInfo,
        doctype_id: templateDoctype.id,
        states: selectedStates,
        sections: [],
        type: FORM_TYPE,
        is_single: isSingleDocument,
      },
    };
    if (isSingleDocument) {
      setRedirectType(RedirectTypeEnum.Single);
    } else {
      setRedirectType(RedirectTypeEnum.Create);
    }
    return dispatch(createTemplateAndSection(templateDataToCreate));
  };

  const saveTemplateAndShowManagerPage = () => {
    dispatch(setDocumentType(DOCUMENT_TYPE));
    saveTemplateHandler(true);
  };

  const handlerShareLinkModal = () => {
    const save = saveTemplateHandler(false);
    if (!save) {
      setShowErrorModal(true);
    } else {
      dispatch(createTemplatePublicLink({ template_id: templateDetails.id, assignments }));
      setShowShareLinkModal(true);
    }
  };

  const onFinishUploading = () => {
    switch (redirectType) {
      case RedirectTypeEnum.Create:
        history.push(`${ROUTES.FORM_EDITOR}/${documentId}`);
        break;
      case RedirectTypeEnum.FillAndSign:
        history.push(`${ROUTES.MANAGER_TEMPLATE}/${id}`);
        break;
      case RedirectTypeEnum.Send:
        history.push(ROUTES.SEND_DOCUMENTS);
        break;
      case RedirectTypeEnum.Single:
        history.push(`${ROUTES.FORM_EXECUTED_EDITOR}/${documentId}`);
        break;
      case RedirectTypeEnum.Update:
      default:
        break;
    }
  };

  return (
    <>
      <EditorWrapper>
        <EditorHeader
          previewMode={previewMode}
          setPreviewMode={setPreviewMode}
          documentName={templateName}
          onChangeDocumentName={onChangeTemplateName}
          errorText={formErrors.template_name}
          saveDocument={(
            redirectType: RedirectTypeEnum | undefined,
            redirectPath: string | undefined,
          ) => saveTemplateHandler(false, redirectType, redirectPath)}
          description={templateDescription}
          setDescription={setTemplateDescription}
          showShareLinkModal={showShareLinkModal}
          isShareButtonAvailable={Boolean(id)}
          handlerShareLinkModal={handlerShareLinkModal}
          openManagerPageHandler={isShowFillSignButton ? saveTemplateAndShowManagerPage : undefined}
        />
        <EditorContainer>
          <DocumentNavigator
            panelTitle="Outline"
            renderChildrenUnderSidebar={(
              <SearchFieldAndContent
                content={templateContent}
                setContent={setTemplateContent}
                setString={setSearchString}
                showSearch
              />
            )}
          >
            <SectionsOutline setSortMode />
          </DocumentNavigator>
          <Editor
            search={searchString}
            content={templateContent}
            onChange={setTemplateContent}
            previewMode={previewMode}
            styledSections
            viewForms
            isTemplate
          />
        </EditorContainer>
      </EditorWrapper>
      <ShareLinkModal
        publicRoute={URL_PUBLIC_TEMPLATE}
        result={templatePublicLink.assignments}
        resourceGuid={templatePublicLink.origin}
        showModal={!isLoading && showShareLinkModal}
        onCloseModal={setShowShareLinkModal}
        deleteLinkFromStore={() => dispatch(deleteTemplateLinkFromStore())}
      />
      <AttachmentsUploader onFinishUploading={onFinishUploading} />
      <ModalContentWindow
        showModal={Boolean(showErrorModal)}
        onCloseModal={setShowErrorModal}
        titleText={SOMETHING_WENT_WRONG}
        showDefaultText={false}
      >
        <div>Please fill out all required fields.</div>
      </ModalContentWindow>
    </>
  );
};

export default FormDetails;