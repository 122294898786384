import { FC } from 'react';

import ModalContentWindow from 'components/Modals/ModalContentWindow';
import SyncLoader from 'components/SyncLoader';

interface IPdfLoaderMessageProps {
  isModal?: boolean;
  modalActions?: {
    isShownModal: boolean;
    onCloseModalHandler: () => void;
  }
  title?: string;
}

const PdfLoaderMessage: FC<IPdfLoaderMessageProps> = ({
  isModal = false,
  modalActions = {
    isShownModal: false,
    onCloseModalHandler: () => null,
  },
  title = 'Loading the PDF...',
}) => {
  const loaderMessageContent = (
    <div className="pdf-loader-message-content">
      <div><SyncLoader small /></div>
      <div>{isModal ? <br /> : title}</div>
      <div className="py-3">
        If you see this message too long - check your internet connection<br />or contact the admin.
      </div>
    </div>
  );

  if (isModal) {
    return (
      <ModalContentWindow
        showModal={modalActions?.isShownModal}
        onCloseModal={modalActions?.onCloseModalHandler}
        titleText={title}
        showDefaultText={false}
      >
        {loaderMessageContent}
      </ModalContentWindow>
    );
  }

  return loaderMessageContent;
};

export default PdfLoaderMessage;