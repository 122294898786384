import { useEffect, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { URL_PUBLIC_COLLECTION } from 'constants/general';
import { setPublicPageStartLink } from 'store/actions/publicPages';

interface ReadOnlyURLSearchParams extends URLSearchParams {
  append: never;
  set: never;
  delete: never;
  sort: never;
}

const useSearchParams = () => {
  const { search } = useLocation();

  return useMemo(
    () => new URLSearchParams(search) as ReadOnlyURLSearchParams,
    [search],
  );
};

export const useWholeURL = () => {
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  // TODO: remove the URL_PUBLIC_COLLECTION check after doing the User Story 3142
  const toSetStartLink = search.length > 0 || pathname.includes(URL_PUBLIC_COLLECTION);

  useEffect(() => {
    dispatch(setPublicPageStartLink(`${pathname}${search}`));
  }, []);

  if (toSetStartLink) {
    return `${pathname}${search}`;
  }
  return null;
};

export default useSearchParams;