import { FC } from 'react';

import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { IPoliciesModalData } from 'types/Misc';

interface IModalComponentProps {
  isOpen: boolean;
  handlerOnCloseModal: (mainModal: boolean) => void;
  modalContent: IPoliciesModalData | null;
}

const PolicyModalComponent: FC<IModalComponentProps> = ({
  isOpen,
  handlerOnCloseModal,
  modalContent,
}) => (
  <Modal isOpen={isOpen}>
    <ModalHeader toggle={() => handlerOnCloseModal(false)}>
      {modalContent ? modalContent.modalHeader : 'UNICORNFORMS Policy'}
    </ModalHeader>
    <ModalBody>
      {
        modalContent
          ? (
            <div
              className="container privacy-policy-content"
              dangerouslySetInnerHTML={{ __html: modalContent.modalText }} // eslint-disable-line
            />
          )
          : (
            <div className="container privacy-policy-content">
              Something went wrong - check your Internet connection
            </div>
          )
      }
    </ModalBody>
  </Modal>
);

export default PolicyModalComponent;