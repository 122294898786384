import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Button from 'components/Base/Button';
import Logo from 'components/Intro/Logo';
import useMondayComPublicPageModalSimilarRecordsExist from 'hooks/MondayCom/useMondayComPublicPageModalSimilarRecordsExist';
import useRememberPIIUserData from 'hooks/useRememberPIIUserData';
import useStyleSchema, { CustomStylesEnum } from 'hooks/useStylesFromSchema';

import classes from 'scss/components/Modal/ConfirmationModal.module.scss';

const RememberPIIUserDataModal = () => {
  const {
    isModalOpen,
    closeModalHandler,
    closeAndSendEmailWithPasswordlessLink,
  } = useRememberPIIUserData();
  const {
    waitToPasswordlessLinkResponse,
    isModalSimilarRecordsOpen,
  } = useMondayComPublicPageModalSimilarRecordsExist();

  const styleSchema = useStyleSchema<
    CustomStylesEnum.button
    | CustomStylesEnum.outlinedBorderButton
    | CustomStylesEnum.title
    | CustomStylesEnum.description
  >([
    CustomStylesEnum.button,
    CustomStylesEnum.outlinedBorderButton,
    CustomStylesEnum.title,
    CustomStylesEnum.description,
  ]);

  return (
    <Modal
      centered
      isOpen={isModalOpen && !isModalSimilarRecordsOpen && !waitToPasswordlessLinkResponse}
      modalClassName="restore-data-modal"
    >
      <ModalHeader toggle={closeModalHandler}>
        <span className={classes.Logo}>
          <Logo />
        </span>
        <span
          className={classes.Title}
          style={styleSchema.title}
        >
          We detect your previously provided data
        </span>
      </ModalHeader>
      <ModalBody>
        <div className={classes.Body}>
          <div
            className="text-center"
            style={styleSchema.description}
          >
            <p>
              Would you like to restore your data?
            </p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex align-items-center justify-content-center w-100 gap-4">
          <Button
            onClick={closeAndSendEmailWithPasswordlessLink}
            style={styleSchema.button}
          >
            Yes
          </Button>
          <Button
            classes="outline outlined-with-border"
            onClick={closeModalHandler}
            style={styleSchema.outlinedBorderButton}
          >
            No
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default RememberPIIUserDataModal;