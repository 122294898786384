/* eslint-disable no-param-reassign */
import { Editor, Range, Text, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { LIST_ITEM_TEXT, PARAGRAPH } from 'constants/editors';

const withDeleteParagraphForward = (editor: Editor) => {
  const { deleteForward } = editor;

  editor.deleteForward = ((unit) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [node, path] = Editor.node(editor, selection);
      const [nodeParent] = Editor.parent(editor, path);

      const parentPath = ReactEditor.findPath(editor, nodeParent);

      const nextNodeEntry = Editor.next(editor, { at: selection });

      if (!nextNodeEntry) {
        deleteForward(unit);
        return;
      }
      const [, nextNodePath] = nextNodeEntry;

      const [nextNodeParent] = Editor.parent(editor, nextNodePath);

      if (
        Editor.isBlock(editor, nextNodeParent)
        && Editor.isBlock(editor, nodeParent)
        && nodeParent.type === PARAGRAPH
        && Text.isText(node)
        && node.text.trim().length === 0
        && nextNodeParent.type === LIST_ITEM_TEXT
      ) {
        Transforms.delete(editor, { at: parentPath });
        return;
      }
    }

    deleteForward(unit);
  });

  return editor;
};

export default withDeleteParagraphForward;