import { FieldDataValueType, IParsedData } from 'types/BatchSendViaCSV';

export const getHeadersAndValuesData = (dataArray: IParsedData['data'], filterRecipientData = false) => {
  const headersData: (string | number)[] = dataArray?.length ? dataArray[0] : [];
  const rowsData: (string | number)[][] = dataArray?.slice(1) || [];

  const recipientHeaders = filterRecipientData
    ? headersData.slice(0, 3).map((element) => String(element))
    : [];
  const recipientData = filterRecipientData ? rowsData.map((rowData) => rowData.slice(0, 3)) : [];

  return { headersData, rowsData, recipientHeaders, recipientData };
};

export const getFieldValueObjects = (headersData: (string | number)[], rowsData: (string | number)[][]) => (
  rowsData.map((rowValues) => {
    const fieldData: FieldDataValueType[] = [];
    rowValues.forEach((rowData, fieldIndex) => {
      if (headersData[fieldIndex]) {
        fieldData.push({
          name: String(headersData[fieldIndex]).trim(),
          value: String(rowData).trim(),
        });
      }
    });
    return fieldData;
  })
);