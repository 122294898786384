import { FC, SyntheticEvent, useEffect, useState } from 'react';

import { Autocomplete } from '@mui/material';

import { StyledTextField } from 'components/Editor/FormView/Fields/PublicFieldView';
import {
  PROPERTIES,
  UNDERLINE_SYMBOL,
} from 'constants/editors';
import { SelectFieldOptionType } from 'types/Editor';
import {
  SubtypeDifferentValuesArrayType,
  SubtypeDifferentValuesType,
} from 'types/Subtypes';
import { capitalizeString } from 'utils/stringsHelpers';

interface ISubtypeField {
  options: SubtypeDifferentValuesArrayType;
  name: string;
  onChange: (value: SubtypeDifferentValuesType) => void;
  propertyName?: string;
}

const MultipleSubtypeField: FC<ISubtypeField> = ({
  options,
  name,
  onChange,
  propertyName,
}) => {
  const isOptionsTypeProperty = propertyName === PROPERTIES.OPTIONS;
  const [value, setValue] = useState<string>('');
  const [correctOptions, setCorrectOptions] = useState<string[]>([]);

  const onChangeValue = (value: string) => {
    const index = Number(value.split(':')[0]);
    setValue(value);
    onChange((options as SubtypeDifferentValuesType[])[index - 1]);
  };

  useEffect(() => {
    if (isOptionsTypeProperty) {
      const optionsLabels = options.map((option, index) => (
        `${index + 1}: ${(option as SelectFieldOptionType[]).map((item) => item.label).join(', ')}`
      ));
      setCorrectOptions(optionsLabels);
      onChangeValue(optionsLabels[0]);
    } else {
      setCorrectOptions(options as string[]);
    }
  }, [options, propertyName]);

  const onChangeHandler = (_: SyntheticEvent, value: string | null) => {
    if (value === null) return;
    onChangeValue(value);
  };

  return (
    <Autocomplete
      disablePortal
      id={name}
      value={value}
      onChange={onChangeHandler}
      options={correctOptions}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          autoComplete="chrome-off"
          type="text"
          variant="outlined"
          size="small"
          label={capitalizeString(name, UNDERLINE_SYMBOL)}
          fullWidth
          name="value"
          required
        />
      )}
    />
  );
};

export default MultipleSubtypeField;