import { FC, memo, RefObject, TouchEvent, useState } from 'react';

import PDFPage from 'components/Editor/components/pdfRenderer/PDFPage';
import DragAndDropContainer from 'components/PdfEditor/DragAndDropContainer';
import { DEFAULT_PAGE_WIDTH } from 'constants/PDF';
import { PDFFieldType } from 'types/PdfTemplates';

import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

interface ITouch {
  identifier: number;
  target: EventTarget;
  screenX: number;
  screenY: number;
  clientX: number;
  clientY: number;
  pageX: number;
  pageY: number;
}

interface IPDFPageWrapperParams {
  numPages: number | null;
  zoomIn: () => void;
  zoomOut: () => void;
  isPDFTemplate: boolean;
  isReadOnlyMode: boolean;
  isPublicPage: boolean;
  isCollectionPage: boolean;
  isFormBuilderOpened: boolean;
  pdfTemplateId: number;
  customFields: PDFFieldType[] | null;
  pdfTemplateWidth: number;
  wrapperRef: RefObject<HTMLDivElement>
}

const PDFPageWrapper: FC<IPDFPageWrapperParams> = ({
  numPages,
  zoomIn,
  zoomOut,
  isPDFTemplate,
  isReadOnlyMode,
  isPublicPage,
  isCollectionPage,
  isFormBuilderOpened,
  pdfTemplateId,
  customFields,
  pdfTemplateWidth,
  wrapperRef,
}) => {
  const [zoomCoords, setZoomCoords] = useState<number>(0);
  const [pageHeight, setPageHeight] = useState<number>(0);
  const numberPageArray = Array.from(new Array(numPages));

  const calculateDistance = (touchOne: ITouch, touchTwo: ITouch) => (
    Math.sqrt(((touchOne.pageX - touchTwo.pageX) ** 2) + ((touchOne.pageY - touchTwo.pageY) ** 2))
  );

  const onDocumentTouchStart = (event: TouchEvent) => {
    if (event?.touches.length === 2) {
      event.preventDefault();
      const distance = calculateDistance(event.touches[0], event.touches[1]);
      setZoomCoords(distance);
    }
  };

  const onDocumentTouchMove = (event: TouchEvent) => {
    if (event?.touches.length === 2) {
      event.preventDefault();
      const distanceNew = calculateDistance(event.touches[0], event.touches[1]);
      if (zoomCoords < distanceNew) {
        zoomIn();
      } else if (zoomCoords > distanceNew) {
        zoomOut();
      }
      setZoomCoords(distanceNew);
    }
  };

  const touchAttributes = isPublicPage && isPDFTemplate && {
    onTouchStart: onDocumentTouchStart,
    onTouchMove: onDocumentTouchMove,
  };

  const renderPagesWithDragAndDrop = (_: number | null, index: number) => (
    <DragAndDropContainer
      key={`page_${index + 1}`}
      readOnlyMode={isReadOnlyMode}
      isPublicPage={isPublicPage}
      pageNumber={index}
      pageHeight={pageHeight}
      isMultiTemplate={isCollectionPage}
      pdfTemplateId={pdfTemplateId}
      customFields={customFields}
      isFormBuilderOpened={isFormBuilderOpened}
    >
      <PDFPage
        index={index}
        pdfTemplateWidth={pdfTemplateWidth || DEFAULT_PAGE_WIDTH}
        onRenderSuccess={(height: number) => setPageHeight(height)}
      />
    </DragAndDropContainer>
  );

  return (
    <div {...touchAttributes} ref={wrapperRef} className="pdf-pages-wrapper">
      {
        isPDFTemplate
          ? numberPageArray.map(renderPagesWithDragAndDrop)
          : numberPageArray.map((element, index) => (
            <PDFPage
              key={element}
              index={index}
              pdfTemplateWidth={pdfTemplateWidth || DEFAULT_PAGE_WIDTH}
              onRenderSuccess={(height: number) => setPageHeight(height)}
            />
          ))
      }
    </div>
  );
};

export default memo(PDFPageWrapper);