import ENDPOINT from 'constants/endpoints';
import { DOCUMENT_TYPE } from 'constants/general';
import { IResponseOfRequest } from 'types/Api';
import { MondayComIntegrationObjectType } from 'types/mondayCom/mondayComBoard';
import { axiosInstance } from 'utils/axiosInstance';

export const saveMondayComAccessTokenToDB = (
  tokenObject: Record<string, string>,
): Promise<IResponseOfRequest<Record<string, string>>> => (
  axiosInstance.post(ENDPOINT.MONDAY_COM_ACCESS_TOKEN, tokenObject)
);

export const deleteMondayComAccessTokenFromDB = (): Promise<IResponseOfRequest<Record<string, string>>> => (
  axiosInstance.delete(ENDPOINT.MONDAY_COM_ACCESS_TOKEN)
);

export const getAllMondayComBoards = (): Promise<IResponseOfRequest<Record<string, string>>> => (
  axiosInstance.get(ENDPOINT.MONDAY_COM_BOARD)
);

export const saveMondayComIntegrationSettings = (
  resourceId: number,
  board: MondayComIntegrationObjectType,
  resourceType: string,
): Promise<IResponseOfRequest<Record<string, string>>> => (
  axiosInstance.post(`${ENDPOINT.MONDAY_COM_DOCUMENT_INTEGRATION}/${resourceId}`, { board, resourceType })
);

export const sendUserEmailAndCheckMondayComRecords = (
  userEmail: string,
  documentId: number,
  mondayComBoard: string,
  resourceType = DOCUMENT_TYPE,
): Promise<IResponseOfRequest<Record<string, string>>> => (
  axiosInstance.post(
    `${ENDPOINT.MONDAY_COM_DOCUMENT_MULTIPLE_APPLICATIONS}/${documentId}`,
    {
      user_email: userEmail,
      monday_com_board: mondayComBoard,
      resource_type: resourceType,
    },
  )
);

export const sendEmaiWithPasswordlessTokenMultiApplications = (
  userEmail: string,
  startLink: string,
  docSenderEmail: string,
  documentId: number,
  resourceType = DOCUMENT_TYPE,
  documentUUID = null,
): Promise<IResponseOfRequest<Record<string, string>>> => (
  axiosInstance.post(
    ENDPOINT.MONDAY_COM_SEND_EMAIL_MULTIPLE_APPLICATIONS,
    {
      user_email: userEmail,
      start_link: startLink,
      doc_sender_email: docSenderEmail,
      document_id: documentId,
      resource_type: resourceType,
      document_uuid: documentUUID,
    },
  )
);