import { FC, memo, ReactNode, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import cn from 'classnames';
import TableQuestionPropertiesTab from 'components/Editor/components/Fields/TableQuestion/TableQuestionPropertiesTab';
import OpenCloseButton from 'components/Sidebar/OpenCloseButton';
import { RootStateType } from 'store/reducers';
import { ICustomElement } from 'types/Editor';
import { PDFFieldType } from 'types/PdfTemplates';
import { isWindowWidthLessXLSize } from 'utils/mobileDeviceHelper';

interface IEditorDevTools {
  fields: ICustomElement[] | PDFFieldType[];
  children: ReactNode;
}

const EditorDevTools: FC<IEditorDevTools> = ({
  fields,
  children,
}) => {
  const { selectedTableQuestionKey } = useSelector((state: RootStateType) => state.editorSlate);
  const [showDevTools, setShowDevTools] = useState<boolean>(true);

  const onShowDevTools = () => {
    if (fields.length > 0) {
      setShowDevTools(true);
    }
  };

  useEffect(() => {
    if (!showDevTools) {
      if (isWindowWidthLessXLSize(window.innerWidth)) {
        setShowDevTools(false);
        return;
      }
      setShowDevTools(fields.length > 0);
    } else if (!fields.length) {
      setShowDevTools(false);
    }
  }, [fields.length, window.innerWidth]);

  return (
    <>
      <div className={cn(
        'p-3 sidebar-panel',
        showDevTools ? 'd-block' : 'd-none',
      )}
      >
        <div className="p-3 editor-navigator">
          <div className="editor-navigator-header">
            <div className="editor-navigator-title">
              Properties
            </div>
            <div className="toggle-button">
              <OpenCloseButton
                isReverseMode
                isActive={showDevTools}
                toggleHandler={setShowDevTools}
              />
            </div>
          </div>
          <div className="h-100">
            {
              selectedTableQuestionKey
                ? <TableQuestionPropertiesTab />
                : children
            }
          </div>
        </div>
      </div>
      <div className={cn(showDevTools ? 'd-none' : 'd-block')}>
        <OpenCloseButton
          isReverseMode
          isActive={showDevTools}
          toggleHandler={onShowDevTools}
        />
      </div>
    </>
  );
};

export default memo(EditorDevTools);