import { ProfileInfoType } from 'types/userProfile';
import { UserLocation } from 'utils/amplitude/amplituteTypes';

export const AMPLITUDE_API_KEY: string = 'c0a35f27da71153962be5822eed9310c';

// Authorization actions
export const LOGIN_EVENT = 'Login';
export const LOGOUT_EVENT = 'Logout';

// Signle document actions
export const SIGNLE_DOCUMENT_WAS_DELETED = 'SIGNLE_DOCUMENT_WAS_DELETED';
export const SIGNLE_DOCUMENT_WAS_CREATED = 'SIGNLE_DOCUMENT_WAS_CREATED';
export const SIGNLE_DOCUMENT_WAS_SAVED = 'SIGNLE_DOCUMENT_WAS_SAVED';
export const SIGNLE_PDF_WAS_CREATED = 'SIGNLE_PDF_WAS_CREATED';
export const SIGNLE_PDF_WAS_SAVED = 'SIGNLE_PDF_WAS_SAVED';
export const SIGNLE_PDF_WAS_DELETED = 'SIGNLE_PDF_WAS_DELETED';
export const SIGNLE_FORM_WAS_CREATED = 'SIGNLE_FORM_WAS_CREATED';
export const SIGNLE_FORM_WAS_SAVED = 'SIGNLE_FORM_WAS_SAVED';
export const SIGNLE_FORM_WAS_DELETED = 'SIGNLE_FORM_WAS_DELETED';

// Template actions
export const DOCUMENT_TEMPLATE_WAS_DELETED = 'DOCUMENT_TEMPLATE_WAS_DELETED';
export const DOCUMENT_TEMPLATE_WAS_CREATED = 'DOCUMENT_TEMPLATE_WAS_CREATED';
export const DOCUMENT_TEMPLATE_WAS_SAVED = 'DOCUMENT_TEMPLATE_WAS_SAVED';
export const PDF_TEMPLATE_WAS_CREATED = 'PDF_TEMPLATE_WAS_CREATED';
export const PDF_TEMPLATE_WAS_SAVED = 'PDF_TEMPLATE_WAS_SAVED';
export const PDF_TEMPLATE_WAS_DELETED = 'PDF_TEMPLATE_WAS_DELETED';
export const FORM_TEMPLATE_WAS_CREATED = 'FORM_TEMPLATE_WAS_CREATED';
export const FORM_TEMPLATE_WAS_SAVED = 'FORM_TEMPLATE_WAS_SAVED';
export const FORM_TEMPLATE_WAS_DELETED = 'FORM_TEMPLATE_WAS_DELETED';
export const COLLECTION_TEMPLATE_WAS_CREATED = 'COLLECTION_TEMPLATE_WAS_CREATED';
export const COLLECTION_TEMPLATE_WAS_DELETED = 'COLLECTION_TEMPLATE_WAS_DELETED';
export const COLLECTION_TEMPLATE_WAS_SAVED = 'COLLECTION_TEMPLATE_WAS_SAVED';

// Executed documents actions
export const DOCUMENT_WAS_COMPLETED = 'DOCUMENT_WAS_COMPLETED';
export const PDF_DOCUMENT_WAS_COMPLETED = 'PDF_DOCUMENT_WAS_COMPLETED';
export const FORM_DOCUMENT_WAS_COMPLETED = 'FORM_DOCUMENT_WAS_COMPLETED';

// Manager actions
export const PDF_DOCUMENT_WAS_SIGNED_BY_MANAGER = 'PDF_DOCUMENT_WAS_SIGNED_BY_MANAGER';
export const DOCUMENT_WAS_SIGNED_BY_MANAGER = 'DOCUMENT_WAS_SIGNED_BY_MANAGER';
export const FORM_DOCUMENT_WAS_SIGNED_BY_MANAGER = 'FORM_DOCUMENT_WAS_SIGNED_BY_MANAGER';
export const PDF_DOCUMENT_WAS_VIEWED_BY_MANAGER = 'PDF_DOCUMENT_WAS_VIEWED_BY_MANAGER';
export const COLLECTION_WAS_VIEWED_BY_MANAGER = 'COLLECTION_WAS_VIEWED_BY_MANAGER';
export const DOCUMENT_WAS_VIEWED_BY_MANAGER = 'DOCUMENT_WAS_VIEWED_BY_MANAGER';
export const FORM_DOCUMENT_WAS_VIEWED_BY_MANAGER = 'FORM_DOCUMENT_WAS_VIEWED_BY_MANAGER';

// Recipient actions
export const PDF_DOCUMENT_WAS_SIGNED_BY_RECIPIENT = 'PDF_DOCUMENT_WAS_SIGNED_BY_RECIPIENT';
export const DOCUMENT_WAS_SIGNED_BY_RECIPIENT = 'DOCUMENT_WAS_SIGNED_BY_RECIPIENT';
export const FORM_DOCUMENT_WAS_SIGNED_BY_RECIPIENT = 'FORM_DOCUMENT_WAS_SIGNED_BY_RECIPIENT';
export const PDF_DOCUMENT_WAS_VIEWED_BY_RECIPIENT = 'PDF_DOCUMENT_WAS_VIEWED_BY_RECIPIENT';
export const COLLECTION_WAS_VIEWED_BY_RECIPIENT = 'COLLECTION_WAS_VIEWED_BY_RECIPIENT';
export const DOCUMENT_WAS_VIEWED_BY_RECIPIENT = 'DOCUMENT_WAS_VIEWED_BY_RECIPIENT';
export const FORM_DOCUMENT_WAS_VIEWED_BY_RECIPIENT = 'FORM_DOCUMENT_WAS_VIEWED_BY_RECIPIENT';

// Document actions
export const DOCUMENT_WAS_SENT = 'DOCUMENT_WAS_SENT';
export const FORM_DOCUMENT_WAS_SENT = 'FORM_DOCUMENT_WAS_SENT';
export const PDF_DOCUMENT_WAS_SENT = 'PDF_DOCUMENT_WAS_SENT';
export const COLLECTION_WAS_SENT = 'COLLECTION_WAS_SENT';

export const DEFAULT_USER_LOCATION: UserLocation = {
  city: 'uknown',
  country_code: 'uknown',
  IPv4: '$remote',
  latitude: 0,
  longitude: 0,
};

export const DEFAULT_USER_INFO: ProfileInfoType = {
  email: 'uknown email',
  first_name: 'uknown firstname',
  last_name: 'uknown lastname',
  company: {
    name: 'uknown company name',
  },
  active: false,
  company_id: 0,
  creation_date: new Date(),
  id: 0,
  modification_date: new Date(),
  roles: [],
  username: '',
  userRole: '',
};