import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Descendant, Element as SlateElement } from 'slate';

import AttachmentsUploader from 'components/AttachmentsUploader';
import DocumentNavigator from 'components/DocumentNavigator';
import Editor from 'components/Editor';
import EditorContainer from 'components/Editor/EditorContainer';
import EditorWrapper from 'components/Editor/EditorWrapper';
import EditorHeader from 'components/EditorHeader/EditorHeader';
import EditorHeaderReadOnly from 'components/EditorHeader/EditorHeaderReadOnly';
import ModalWindow from 'components/Modals';
import ModalContentWindow from 'components/Modals/ModalContentWindow';
import ShareLinkModal from 'components/Modals/ModalWrapper';
import SearchReplaceForm from 'components/Sidebar/SearchReplaceForm';
import SyncLoader from 'components/SyncLoader';
import { READ_ONLY_STATUSES, STATUSES, STATUSES_FOR_SHARING } from 'constants/documentStatuses';
import { EditorGridAxisCount, MANAGER_ASSIGNMENT, SECTION } from 'constants/editors';
import {
  DOCUMENT_EXECUTED_TYPE,
  DOCUMENT_NAME_LABEL,
  FORM_TYPE,
  RedirectTypeEnum,
  URL_MANAGER_DOCUMENT,
  URL_PUBLIC_DOCUMENT,
} from 'constants/general';
import { SOMETHING_WENT_WRONG } from 'constants/generalErrors';
import ROUTES from 'constants/routes';
import { useRolePermission } from 'hooks/useRolePermission';
import { saveDocumentExecutedLog } from 'services/api/serverSideEvents';
import { changeSelectedField, changeSelectedSection } from 'store/actions/editorSlate';
import {
  clearAttachments,
  createDocumentPublicLink,
  deleteDocumentLinkFromStore,
  setCreatedDocumentId,
  setDocumentType,
} from 'store/actions/publicPages';
import { sortFormDocumentSections, updateTemplateSections } from 'store/actions/template';
import { editDocumentDetails, getDocumentDetails, setDocumentDetails } from 'store/actions/userData';
import { RootStateType } from 'store/reducers';
import { ICreateDocumentRequestObj } from 'types/Documents';
import { HISTORY_ACTIONS } from 'types/DocumentsHistory';
import { IMetadataId } from 'types/Metadata';
import { ITemplateSection } from 'types/redux';
import { MatchParams } from 'types/Route';
import { ProfileInfoType } from 'types/userProfile';
import { IErrorField } from 'types/validation';
import { trackAmplitudeEvent } from 'utils/amplitude/amplitudeTrackingUtlis';
import { getAmplitudeEventName } from 'utils/amplitude/amplitudeUtils';
import { trimHTMLValue } from 'utils/descriptionHelper';
import { getUTCDate } from 'utils/documentsHistory';
import { wasStatusChanged } from 'utils/documentStatusHelpers';
import { createSectionWithField, filterFormFieldsFromContent } from 'utils/editorFieldHelpers';
import { cleanUpCopiedSectionData, drawSectionsInEditorContent } from 'utils/editorHelpers';
import { addHTMLandCSSifCompleted } from 'utils/fileLinkDownload';
import setDefaultPageValuesHelper from 'utils/reduxHelpers';
import { getEditorPageLink } from 'utils/routeHelpers';
import { isRequiredFieldArrayHasError, validationForm } from 'utils/validation';

const FormExecutedDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params: MatchParams = useParams();
  const id: string = params.id;

  const { documentDetails, doctypes, states, statuses } = useSelector((state: RootStateType) => state.user);
  const { documentPublicLink } = useSelector((state: RootStateType) => state.publicPages);
  const { isLoading } = useSelector((state: RootStateType) => state.errorLoading);
  const { sections, sortMode } = useSelector((state: RootStateType) => state.template);
  const userInfo: ProfileInfoType = useSelector((state: RootStateType) => state.profile.profileInfo);
  const { selectedSection, assignments } = useSelector((state: RootStateType) => state.editorSlate);

  const { isUserReadOnly } = useRolePermission();

  const [previewMode, setPreviewMode] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<IErrorField>({ document_name: null, title: null });
  const [documentName, setDocumentName] = useState<string>('');
  const [documentDescription, setDocumentDescription] = useState<string>('');
  const [documentState, setDocumentState] = useState<IMetadataId[]>([{ id: 1 }]);
  const [documentDoctype, setDocumentDoctype] = useState<IMetadataId | null>(null);
  const [showShareLinkModal, setShowShareLinkModal] = useState<boolean>(false);
  const [isShowFillSignButton, setIsShowFillSignButton] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [documentContent, setDocumentContent] = useState<Descendant[]>([]);
  const [documentStatus, setDocumentStatus] = useState<string>('');
  const [readOnlyMode, setReadOnlyMode] = useState<boolean>(false);
  const [completedMode, setCompletedMode] = useState<boolean>(false);
  const [showManagerFieldsModal, setShowManagerFieldsModal] = useState<boolean>(false);

  const [searchString, setSearchString] = useState<string>('');
  const [redirectType, setRedirectType] = useState<RedirectTypeEnum>(RedirectTypeEnum.Create);

  // Executed document history log: manager's action 'viewed'
  useEffect(() => {
    if (id) {
      saveDocumentExecutedLog(
        Number(id),
        { [HISTORY_ACTIONS.VIEWED]: getUTCDate() },
      );
    }
  }, []);

  useEffect(() => {
    if (id && documentDetails) {
      trackAmplitudeEvent({
        eventName: getAmplitudeEventName({ data: documentDetails }).viewedByManager,
        userInfo,
      });
    }
  }, [documentDetails?.id]);

  useEffect(() => {
    dispatch(setDocumentType(DOCUMENT_EXECUTED_TYPE));
    if (id) {
      dispatch(getDocumentDetails(id));
    }
    return () => {
      setDefaultPageValuesHelper(dispatch, true);
      dispatch(setDocumentDetails(null));
      dispatch(changeSelectedSection(null));
      dispatch(changeSelectedField(null));
      dispatch(clearAttachments());
      dispatch(setDocumentType(null));
      dispatch(setCreatedDocumentId(0));
    };
  }, [id]);

  useEffect(() => {
    if (documentDetails && documentDetails.template) {
      const templateDetails = documentDetails.template;

      if (documentDetails.state && states) {
        setDocumentState([documentDetails.state]);
      } else if (!documentDetails.state && templateDetails.states) {
        setDocumentState(templateDetails.states);
      }
      setDocumentDoctype(documentDetails.doctype || templateDetails.doctype || null);
    }
  }, [documentDetails, states]);

  useEffect(() => {
    if (!documentDetails) return;
    if (documentDetails.type !== FORM_TYPE) {
      history.push(getEditorPageLink(id, documentDetails.type, true));
      return;
    }

    setDocumentName(documentDetails.name);
    setDocumentDescription(documentDetails.description);
    if (documentDetails.content_json) {
      setDocumentContent(documentDetails.content_json);
    }
    if (documentDetails?.status) {
      setDocumentStatus(documentDetails.status);
      setReadOnlyMode(READ_ONLY_STATUSES.includes(documentDetails.status));
    }

    const newSections = documentDetails.content_json?.map((section: ITemplateSection, index: number) => ({
      name: section.name,
      description: section.description,
      key: section.key,
      position: section.position || index,
    }));
    dispatch(updateTemplateSections(newSections || []));
  }, [documentDetails]);

  useEffect(() => {
    if (sortMode) {
      // Fires when we sort sections or fields
      const updatedContent = sections.map((section) => {
        const currentSection = documentContent.find((el) => section.key === el.key);
        const children = (section as Partial<SlateElement>).children || currentSection?.children || [];
        return {
          ...currentSection,
          position: section.position,
          children,
        };
      });
      setDocumentContent(updatedContent as Descendant[]);
      dispatch(sortFormDocumentSections(false));
    }
  }, [sortMode]);

  useEffect(() => {
    if (id) {
      dispatch(changeSelectedField(null));
    }
  }, [sections.length, id, isLoading]);

  useEffect(() => {
    if (sections.length && !documentContent.length) {
      setDocumentContent(drawSectionsInEditorContent(sections, documentContent));
    }
  }, [sections]);

  useEffect(() => {
    if (documentContent.length === sections.length) return;

    // Fires when we change section count: add, copy or remove section
    const updatedContent = sections
      .sort((a, b) => Number(a.position) - Number(b.position))
      .map((section, index) => {
        const sectionInEditor = documentContent.find((el) => el.key === section.key);
        if (sectionInEditor) {
          return {
            ...sectionInEditor,
            name: section.name,
            description: section.description || '',
            position: index,
          };
        }

        // it is a new section: created or copied
        const childrenContent = cleanUpCopiedSectionData(section, selectedSection, documentContent);
        return createSectionWithField(section.key as number, index, section.name, childrenContent);
      });
    setDocumentContent(updatedContent);
  }, [sections]);

  useEffect(() => {
    setIsShowFillSignButton(
      Boolean(id) && !!filterFormFieldsFromContent(documentContent, MANAGER_ASSIGNMENT).length,
    );
  }, [documentContent, id]);

  useEffect(() => {
    if (documentStatus) {
      setCompletedMode(documentStatus === STATUSES.completed);
    }
  }, [documentStatus, documentPublicLink]);

  const validateDocumentName = (documentName: string) => {
    const validateResult = validationForm([{
      titleField: DOCUMENT_NAME_LABEL,
      nameField: 'document_name',
      valueField: documentName,
      required: true,
    }]);
    setFormErrors((prev) => ({ ...prev, ...validateResult.validationFields }));
    return validateResult;
  };

  const onChangeDocumentName: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    setDocumentName(target.value);
  };

  const getEditedDocumentData = (newStatus: string = '') => {
    const validateNameResult = validateDocumentName(documentName);
    if (validateNameResult.isError) return null;
    if (!documentDoctype) return null;

    const contentDataToSave = documentContent.map((element, index) => {
      const sectionInStore = sections.find((el) => el.key === element.key);
      return {
        children: element.children,
        key: element.key,
        position: sectionInStore?.position || index,
        name: sectionInStore?.name.trim() || '',
        description: trimHTMLValue(sectionInStore?.description || ''),
        type: SECTION,
      };
    });

    const editedDocument: ICreateDocumentRequestObj = {
      name: documentName.trim(),
      description: trimHTMLValue(documentDescription),
      doctype_id: documentDoctype?.id || 0,
      state_id: documentState[0].id,
      content_json: contentDataToSave as Descendant[],
      assignments,
    };

    if (
      newStatus
      && wasStatusChanged(documentStatus, newStatus)
      && statuses.includes(newStatus)
    ) {
      if (newStatus === STATUSES.completed) {
        const hasError = isRequiredFieldArrayHasError({
          contentData: contentDataToSave as Descendant[],
          assignment: MANAGER_ASSIGNMENT,
        });
        if (hasError) {
          setShowManagerFieldsModal(true);
          return null;
        }

        const hasResipientError = isRequiredFieldArrayHasError({ contentData: contentDataToSave as Descendant[] });
        if (hasResipientError) {
          toast.warning('There are empty recipient required fields.');
        }
      }

      editedDocument.status = newStatus;
      setDocumentStatus(newStatus);
      setReadOnlyMode(READ_ONLY_STATUSES.includes(newStatus));
    }

    return editedDocument;
  };

  const saveDocumentHandler = async (
    newStatus: boolean | string = '',
    redirectType: RedirectTypeEnum | undefined = undefined,
    redirectPath: string | undefined = undefined,
  ) => {
    let data = getEditedDocumentData(newStatus as string);
    if (data !== null) {
      const isCompleteFlow: boolean = data.status === STATUSES.completed;
      if (isCompleteFlow) {
        data = await addHTMLandCSSifCompleted(data);
      }

      if (redirectType) {
        setRedirectType(redirectType);
      }

      const editedDocument = {
        id,
        document: data,
        completeFlow: isCompleteFlow,
        ...(redirectType && { path: redirectPath }),
      };

      dispatch(editDocumentDetails(editedDocument));
    }
    return data;
  };

  const saveDocumentAndShowManagerPage = () => {
    const data = getEditedDocumentData();
    if (data !== null) {
      setRedirectType(RedirectTypeEnum.FillAndSign);
      dispatch(editDocumentDetails({
        id,
        document: data,
        path: `/${URL_MANAGER_DOCUMENT}/${id}`,
      }));
    }
  };

  const handlerShareLinkModal = () => {
    const save = saveDocumentHandler('');
    if (!save) {
      setShowErrorModal(true);
    } else {
      dispatch(createDocumentPublicLink({ document_id: documentDetails.id, assignments }));
      setShowShareLinkModal(true);
      if (documentStatus !== STATUSES.completed) {
        setDocumentStatus(STATUSES.waiting);
      }
      setReadOnlyMode(true);
    }
  };

  const changeStatusAndSaveDocument = (newStatus: string) => {
    saveDocumentHandler(newStatus);
  };

  if (!statuses.length || !doctypes.length || !documentStatus || !documentContent.length) {
    return <SyncLoader />;
  }

  if (!id) {
    return null;
  }

  const statusObject = statuses && {
    statuses,
    selectedStatus: documentStatus,
    onSelectStatus: changeStatusAndSaveDocument,
  };

  const onFinishUploading = () => {
    switch (redirectType) {
      case RedirectTypeEnum.FillAndSign:
        history.push(`/${URL_MANAGER_DOCUMENT}/${id}`);
        break;
      case RedirectTypeEnum.Send:
        history.push(ROUTES.SEND_DOCUMENTS);
        break;
      case RedirectTypeEnum.Update:
      default:
        break;
    }
  };

  return (
    <>
      <EditorWrapper>
        {
          !isUserReadOnly ? (
            <EditorHeader
              previewMode={previewMode}
              setPreviewMode={setPreviewMode}
              documentName={documentName}
              onChangeDocumentName={onChangeDocumentName}
              errorText={formErrors.document_name}
              saveDocument={(
                redirectType: RedirectTypeEnum | undefined,
                redirectPath: string | undefined,
              ) => saveDocumentHandler('', redirectType, redirectPath)}
              description={documentDescription}
              setDescription={setDocumentDescription}
              showShareLinkModal={showShareLinkModal}
              handlerShareLinkModal={handlerShareLinkModal}
              openManagerPageHandler={isShowFillSignButton ? saveDocumentAndShowManagerPage : undefined}
              isShareButtonAvailable={STATUSES_FOR_SHARING.includes(documentStatus)}
              readOnly={readOnlyMode}
              statusObject={statusObject}
              showDocumentHistoryButton
            />
          ) : (<EditorHeaderReadOnly documentName={documentName} />)
        }
        <EditorContainer columns={isUserReadOnly ? EditorGridAxisCount.One : EditorGridAxisCount.Three}>
          <DocumentNavigator panelTitle="Search">
            <div>
              <SearchReplaceForm
                content={documentContent}
                setContent={setDocumentContent}
                setString={setSearchString}
                isReadOnly={readOnlyMode}
                showSearch
              />
            </div>
          </DocumentNavigator>
          <Editor
            search={searchString}
            visibleEditorDevTools={!readOnlyMode && !isUserReadOnly}
            readOnlyMode={readOnlyMode || isUserReadOnly}
            content={documentContent}
            onChange={setDocumentContent}
            previewMode={previewMode}
            styledSections
            viewForms
          />
        </EditorContainer>
      </EditorWrapper>
      <AttachmentsUploader onFinishUploading={onFinishUploading} />
      <ShareLinkModal
        publicRoute={URL_PUBLIC_DOCUMENT}
        result={documentPublicLink.assignments}
        resourceGuid={documentPublicLink.origin}
        showModal={!isLoading && showShareLinkModal}
        onCloseModal={setShowShareLinkModal}
        deleteLinkFromStore={() => dispatch(deleteDocumentLinkFromStore())}
        showEmbedScript={false}
        readOnlyMode={completedMode}
      />
      <ModalContentWindow
        showModal={Boolean(showErrorModal)}
        onCloseModal={setShowErrorModal}
        titleText={SOMETHING_WENT_WRONG}
        showDefaultText={false}
      >
        <div>Please fill out all required fields.</div>
      </ModalContentWindow>
      <ModalWindow
        isOpen={showManagerFieldsModal}
        onClose={() => setShowManagerFieldsModal(false)}
        onButtonClick={saveDocumentAndShowManagerPage}
        title={SOMETHING_WENT_WRONG}
        buttonTitle="Fill & Sign"
      >
        <div>There are empty required fields.<br />You cannot complete this document until they are filled in.</div>
      </ModalWindow>
    </>
  );
};

export default FormExecutedDetails;