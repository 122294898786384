import { Editor, Element as SlateElement, Transforms } from 'slate';

import type { ListFormatType } from '../../withLists';
import type { ListsEditor } from '../types';

/**
 * Sets "type" and "style" of all "list" nodes in the tree
 */
export const setGeneralListType = (
  editor: ListsEditor,
  listFormat: ListFormatType,
  listStyle = {},
  switchListFormat: ListFormatType | null = null,
): void => {
  if (!editor.selection) {
    return;
  }

  const topLevelListNode = Editor.above(editor, {
    match: (node) => editor.isListNode(node),
    mode: 'highest',
  });
  const pathToApplyChanges = topLevelListNode ? { at: topLevelListNode[1] } : {};

  Transforms.setNodes(
    editor,
    { type: listFormat, ...listStyle },
    {
      ...pathToApplyChanges,
      match: (node) => (
        !Editor.isEditor(node)
        && SlateElement.isElement(node)
        && node.type === (switchListFormat ?? listFormat)
      ),
      mode: 'all',
    },
  );
};

export default setGeneralListType;