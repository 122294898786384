/* eslint-disable */
import React, { useEffect, useState } from "react";
import AutoSuggest, { OnSuggestionSelected } from "react-autosuggest";

import { ISection } from "types/Sections";
import { ITemplate } from "types/Templates";

interface ISortableItemProps {
  onSelectSection: any;
  sections: any[] | undefined;
  placeholderText?: string;
  previousTemplate?: ITemplate | undefined;
}

const AutocompleteSections: React.FC<ISortableItemProps> = (props) => {
  const {
    onSelectSection,
    sections,
    placeholderText = '',
    previousTemplate = undefined,
  } = props;

  const [value, setValue] = useState<string>('');
  const [sectionsList, setSectionsList] = useState<ISection[]>([]);
  const [suggestions, setSuggestions] = useState<ISection[]>([]);

  useEffect(() => {
    if (sections?.length) setSectionsList(sections);
  }, [sections]);

  useEffect(() => {
    if (previousTemplate) setValue(previousTemplate.name);
  }, [previousTemplate]);

  const getSuggestions = (value: string) => {
    if (value) {
      return sectionsList.filter((section) =>
        [section.name, section.description].some((searchValue) => {
          return searchValue && searchValue.toLowerCase().includes(value.trim().toLowerCase())
        })
      );
    }
  };

  const fetchRequestedSuggestions = ({ value }: { value: string }) => {
    setValue(value);
    const suggestions = getSuggestions(value);
    setSuggestions(suggestions || []);
  };

  const selectSuggestion: OnSuggestionSelected<ISection> = (
    event,
    { suggestion }
  ) => {
    onSelectSection(suggestion);
  };

  const clearSuggestions = () => {
    setSuggestions([]);
  };

  const renderSuggestion = (suggestion: ISection) => (
    <div>
      <b>{suggestion.name}</b>
      <div>{suggestion.description?.replace(/<[^>]+>/g, '')}</div>
    </div>
  );

  const getSuggestionValue = (suggestion: ISection) => suggestion.name;

  const handleChange = (
    event: React.FormEvent<HTMLElement>,
    { newValue }: { newValue: string }
  ) => {
    setValue(newValue);
    if (newValue.length === 0) {
      onSelectSection(null);
    }
  };

  return (
    <AutoSuggest
      suggestions={suggestions}
      onSuggestionsClearRequested={clearSuggestions}
      onSuggestionsFetchRequested={fetchRequestedSuggestions}
      onSuggestionSelected={selectSuggestion}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={{
        placeholder: placeholderText || 'Type to search sections...',
        className: 'form-control',
        value: value,
        onChange: handleChange,
      }}
      highlightFirstSuggestion={true}
    />
  );
};

export default AutocompleteSections;