import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';

import { SUCCESS_CODE } from 'constants/generalErrors';
import { updateSendgridSettings } from 'services/api/emailNotifications';
import {
  UPDATE_SENDGRID_SETTINGS,
} from 'store/actions/actionTypes';
import { setSendgridSettings } from 'store/actions/emailNotifications';
import { safe } from 'store/sagas/errorHandlerSaga';
import { SendgridSettingsType } from 'types/AccountSettings';
import { IResponseOfRequest } from 'types/Api';
import { Action } from 'types/redux';

const updateCompanySendgridSettings = function* ({ payload }: Action<SendgridSettingsType>) {
  const result: IResponseOfRequest<{ message: string }> = yield call(updateSendgridSettings, payload);
  if (result.status === SUCCESS_CODE) {
    yield put(setSendgridSettings(payload));
    yield toast.success(result.data.message);
  }
};

export default [
  takeLatest(UPDATE_SENDGRID_SETTINGS, safe(updateCompanySendgridSettings)),
];