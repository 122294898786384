import { Dispatch as ReactDispatch, ReactNode, SetStateAction } from 'react';

import { DisplayData, MUIDataTableColumn } from 'mui-datatables';

import { HandlersValueType } from 'components/KebabMenu';
import { DocumentStatusType, DocumentTypesType } from 'types/Documents';

export type TableRowsType = (HandlersValueType[] & string[])[];
export type UserListRowsType = (string | boolean | {id: string, isUserActive: boolean})[][];
export type GroupListRowsType = (number | string | boolean | {id: string})[][];

export type SelectedRowsType = {
  data: {
    index: number;
    dataIndex: number;
  }[];
  lookup: {
    [key: number]: boolean;
  };
}

export type CustomSelectHeaderType = (
  value: {
    selectedRows: SelectedRowsType,
    displayData: DisplayData,
  }
) => ReactNode;

export interface IMUITable {
  columns: MUIDataTableColumn[];
  // Internal arrays always contain strings and one object for kebab menu
  data: TableRowsType;
  deleteRows: (idArray: HandlersValueType[]) => void;
  resetSelectedRows?: boolean;
  isLoading?: boolean;
  isAllDocumentsTab?: boolean;
  isCollectionPage?: boolean;
  onChangeColumn?: (changedColumn: string, action: string) => void;
  templateNameForFilter?: string;
  setTemplateNameForFilter?: ReactDispatch<SetStateAction<string | undefined>>;
  setActiveStatuses?: ReactDispatch<SetStateAction<DocumentStatusType>>;
  activeStatuses?: DocumentStatusType;
}

export const enum ListingViewsTabs {
  DASHBOARD = 'dashboard',
  DOCUMENTS = 'document',
  PDFS = 'pdf',
  FORMS = 'form',
  COLLECTIONS = 'collection',
  COLLECTIONS_EXECUTED = 'collectionExecuted',
  SECTIONS = 'section'
}

export interface IDocumentsIdsArray {
  id: number;
  type: DocumentTypesType;
}