import { FC } from 'react';

import usePageSettingsValueUpdate from 'hooks/pageSettings/usePageSettingsValueUpdate';
import { IPageSettingProps } from 'types/PageSettingsType';

const CheckboxSetting: FC<IPageSettingProps> = ({ setting }) => {
  const value = setting.value as boolean;
  const setValue = usePageSettingsValueUpdate(setting.type, setting.key);

  return (
    <div>
      <label>
        <input
          type="checkbox"
          defaultChecked={value}
          onChange={setValue}
          disabled={setting.disabled}
          className="me-2"
        />
        {setting.title}
      </label>
    </div>
  );
};

export default CheckboxSetting;