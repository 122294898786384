import ENDPOINT from 'constants/endpoints';
import { GROUP_MANAGEMENT_USER_ACTIONS } from 'constants/general';
import { axiosInstance } from 'utils/axiosInstance';

export const getGroups = async () => (
  axiosInstance.get(`${ENDPOINT.GROUPS}`)
);

export const deleteGroup = async (groupId: string) => (
  axiosInstance.delete(`${ENDPOINT.GROUPS}/${groupId}`)
);

export const createGroup = async (data: { name: string }) => (
  axiosInstance.post(`${ENDPOINT.GROUPS}`, data)
);

export const updateGroup = async (
  data: { name: string } | { userId: string; action: GROUP_MANAGEMENT_USER_ACTIONS },
  groupId: string,
) => (
  axiosInstance.patch(`${ENDPOINT.GROUPS}/${groupId}`, data)
);

export const getGroup = async (groupId: string) => (
  axiosInstance.get(`${ENDPOINT.GROUPS}/${groupId}`)
);