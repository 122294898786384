import { FC, SyntheticEvent, useEffect, useState } from 'react';

import { Autocomplete } from '@mui/material';

import { StyledTextField } from 'components/Editor/FormView/Fields/PublicFieldView';
import {
  MAX_FIELD_VALUE_LENGTH,
  MIN_FIELD_VALUE_LENGTH,
  PROPERTIES,
  SELECT_FIELD_TYPE_CHECKBOX,
  SELECT_FIELD_TYPE_RADIO,
  SELECT_FIELD_TYPE_SELECT,
  UNDERLINE_SYMBOL,
} from 'constants/editors';
import { SubtypeDifferentValuesType } from 'types/Subtypes';
import { capitalizeString } from 'utils/stringsHelpers';

interface ISubtypeField {
  options: string[];
  name: string;
  onChange: (value: SubtypeDifferentValuesType) => void;
  propertyName?: string;
  isShowErrorMessage?: boolean;
  errorMessage?: string;
}

const SubtypeField: FC<ISubtypeField> = ({
  options,
  name,
  onChange,
  propertyName,
  isShowErrorMessage = false,
  errorMessage = '',
}) => {
  const isMaxLengthProperty = propertyName === PROPERTIES.MAX_LENGTH;
  const isRequiredProperty = propertyName === PROPERTIES.REQUIRED_FIELD;
  const isSelectTypeProperty = propertyName === PROPERTIES.SELECT_FIELD_TYPE;
  const [value, setValue] = useState<string | null>('');
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    if (isRequiredProperty) {
      setValue(options[0] ?? 'false');
    }
    if (isSelectTypeProperty) {
      setValue(options[0]);
    }
  }, [isSelectTypeProperty, isRequiredProperty]);

  const onChangeHandler = (_: SyntheticEvent, newValue: string | null) => {
    if (newValue === null || !isSelectTypeProperty) {
      setValue('');
      onChange('');
      return;
    }
    if (isRequiredProperty && ['true', 'false'].includes(newValue)) {
      onChange(JSON.parse(newValue));
      setValue(newValue);
      return;
    }
    if (isMaxLengthProperty) {
      onChange(Number(newValue));
      setValue(newValue);
      return;
    }
    if (isSelectTypeProperty) {
      if (newValue) {
        setValue(newValue);
        onChange(newValue);
        return;
      }
      setValue(SELECT_FIELD_TYPE_SELECT);
      onChange(SELECT_FIELD_TYPE_SELECT);
    }
    setValue(newValue);
    onChange(newValue);
  };

  const onInputChangeHandler = (_: SyntheticEvent, newInputValue: string) => {
    if (isMaxLengthProperty) {
      let currentValue: number | string = Number(newInputValue);
      if (currentValue >= MAX_FIELD_VALUE_LENGTH) {
        currentValue = MAX_FIELD_VALUE_LENGTH;
      }
      if (currentValue < MIN_FIELD_VALUE_LENGTH) {
        currentValue = '';
      }
      setInputValue(String(currentValue));
      onChange(currentValue);
      return;
    }
    if (isRequiredProperty && ['true', 'false'].includes(newInputValue)) {
      onChange(JSON.parse(newInputValue));
      setInputValue(newInputValue);
      return;
    }
    if (isSelectTypeProperty) {
      if (newInputValue.length && [
        SELECT_FIELD_TYPE_SELECT,
        SELECT_FIELD_TYPE_RADIO,
        SELECT_FIELD_TYPE_CHECKBOX,
      ].includes(newInputValue)) {
        setInputValue(newInputValue);
        onChange(newInputValue);
        return;
      }
      setInputValue(SELECT_FIELD_TYPE_SELECT);
      onChange(SELECT_FIELD_TYPE_SELECT);
    }
    setInputValue(newInputValue);
    onChange(newInputValue);
  };

  return (
    <Autocomplete
      disablePortal
      id={name}
      value={value}
      onChange={onChangeHandler}
      inputValue={String(inputValue)}
      onInputChange={onInputChangeHandler}
      options={options}
      freeSolo={!(isSelectTypeProperty || isRequiredProperty)}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          autoComplete="chrome-off"
          type={isMaxLengthProperty ? 'number' : 'text'}
          variant="outlined"
          size="small"
          value={value}
          label={capitalizeString(name, UNDERLINE_SYMBOL)}
          fullWidth
          name="value"
          required
          error={isShowErrorMessage && !!errorMessage.length}
          helperText={isShowErrorMessage ? errorMessage : ''}
        />
      )}
    />
  );
};

export default SubtypeField;