/* eslint-disable */
import React, { useState } from 'react';
import { BaseSelection as SlateBaseSelection, Transforms } from 'slate';
import { useSlate } from 'slate-react';

interface IMentionToolbarProps {
  toolbarRef: React.MutableRefObject<HTMLDivElement | null>;
  toolbarInputRef: React.MutableRefObject<HTMLInputElement | null>;
  lastEditorSelection: SlateBaseSelection | null;
}

const MentionToolbar: React.FC<IMentionToolbarProps> = (props) => {

  const {
    toolbarRef,
    toolbarInputRef,
    lastEditorSelection,
  } = props;

  const editor = useSlate();
  const [inputFilter, setInputFilter] = useState<string>('');

  const onInputFilter: React.FocusEventHandler<HTMLInputElement> = () => {
    if(lastEditorSelection) Transforms.select(editor, lastEditorSelection);
  }

  const onChangeInput: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setInputFilter(event.target.value);
  }

  return (
    <>
      <div className="toolbar" ref={toolbarRef}>
        <div>
            <h3 className="m-2">Mention other docs or people</h3>
            <input
                ref={toolbarInputRef}
                className="input-toolbar-filter w-100"
                placeholder="Keep typing to filter, or escape to exit"
                value={inputFilter}
                onFocus={onInputFilter}
                onBlur={onInputFilter}
                onChange={onChangeInput}
            />
        </div>
        <p className="p-2">
          RECENT DOCS
        </p>
        <p className="p-2">
          PEOPLE
        </p>
      </div>
    </>
  )
}

export default MentionToolbar;