import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import DocumentNavigation from 'components/DocumentNavigation/DocumentNavigation';
import DocumentsSubHeader from 'components/DocumentsSubHeader';
import GeneralMUITableWrapper from 'components/GeneralMUITableWrapper';
import { DocumentNavigationTitles } from 'constants/documentsNavigation';
import { FORM_TYPE } from 'constants/general';
import ROUTES from 'constants/routes';
import useDocumentsStatuses from 'hooks/useDocumentsStatuses';
import { RootStateType } from 'store/reducers';
import { ListingViewsTabs, TableRowsType } from 'types/Mui';
import { createTableData } from 'utils/dataColumns';

export const Forms = () => {
  const { documents, documentsExecuted } = useSelector((state: RootStateType) => state.user);
  const [formsRows, setFormsRows] = useState<TableRowsType>([]);

  const {
    isUserReadOnly,
    activeStatuses,
    setActiveStatuses,
  } = useDocumentsStatuses();

  useEffect(() => {
    if (activeStatuses.length > 0) {
      setFormsRows(createTableData(documentsExecuted, FORM_TYPE, activeStatuses));
    } else {
      setFormsRows(createTableData(documents, FORM_TYPE));
    }
  }, [documents, activeStatuses, documentsExecuted]);

  return (
    <>
      <DocumentsSubHeader
        title="Forms"
        buttonText="Create Form Template"
        buttonLink={ROUTES.FORM_EDITOR}
        secondButtonText="Single Form"
        secondButtonLink={ROUTES.FORM_EXECUTED_EDITOR_SINGLE}
      />
      <DocumentNavigation
        activeTab={activeStatuses}
        setActiveTab={setActiveStatuses}
        firstTabTitle={DocumentNavigationTitles.ALL_FORMS}
        isUserReadOnly={isUserReadOnly}
      />
      <div className="w-75 mx-auto pb-5">
        <GeneralMUITableWrapper
          tableRows={formsRows}
          isAllDocumentsTab={!activeStatuses.length}
          currentTableTab={ListingViewsTabs.FORMS}
          setActiveTab={setActiveStatuses}
          activeStatuses={activeStatuses}
        />
      </div>
    </>
  );
};

export default Forms;