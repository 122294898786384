import { CSSProperties, FC, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import CancelOutlined from '@mui/icons-material/CancelOutlined';

import cn from 'classnames';
import NotUploadedFilesListRender from 'components/AttachmentsListComponents/NotUploadedFilesListRender';
import UploadedFilesListRender from 'components/AttachmentsListComponents/UploadedFilesListRender';
import { ATTACHMENT_NAME_MAX_LENGTH } from 'constants/editors';
import { NOT_FOUND_CODE } from 'constants/generalErrors';
import { ATTACHMENTS_TAB_ID } from 'constants/tabs';
import { deleteAttachment, downloadAttachment } from 'services/api';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { setCurrentDocumentActiveTab } from 'store/actions/publicPages';
import { RootStateType } from 'store/reducers';
import { IFiles } from 'types/PublicPage';
import { toShowBlock } from 'utils/routeHelpers';
import { getLastSplittedPartByFragment } from 'utils/stringsHelpers';

import 'style/_attachments.scss';

const AttachmentsList: FC<{
  fieldValue: string | undefined;
  fieldKey: number;
  documentView?: boolean;
  pdfDocumentView?: boolean;
  readOnlyMode?: boolean;
  isPdfFormField?: boolean;
  inlineView?: boolean;
  onRemoveValue?: (valueToRemove: string, oldValue: string) => void;
  style?: CSSProperties;
}> = ({
  fieldValue,
  fieldKey,
  documentView = false,
  pdfDocumentView = false,
  readOnlyMode = false,
  isPdfFormField = false,
  inlineView = false,
  onRemoveValue = () => null,
  style = {},
}) => {
  const location = useLocation();
  const isPublicPage = toShowBlock(location.pathname, 'public');

  const dispatch = useDispatch();
  const currentAttachments: IFiles[] = useSelector((state: RootStateType) => state.publicPages.attachments[fieldKey]);
  const [valuesArray, setValuesArray] = useState<string[] | undefined>([]);

  useEffect(() => {
    setValuesArray((fieldValue && fieldValue.split(',')) || []);
  }, [fieldValue]);

  const handleDownloadFile = async (fileUrl: string) => {
    const response = await apiErrorHandler(
      downloadAttachment,
      { path: fileUrl, returnErrorResult: true },
    );
    if (response && response.status === NOT_FOUND_CODE) {
      onRemoveValue(fileUrl, fieldValue || '');
    }
  };

  const handleDeleteFile = async (fileUrl: string) => {
    const resultMessage = await apiErrorHandler(deleteAttachment, { path: fileUrl, isPdfDocument: isPdfFormField });
    if (resultMessage) {
      toast.success(resultMessage);
      onRemoveValue(fileUrl, fieldValue || '');
    }
  };

  const getFileExt = (fileUrl: string): string => {
    let fileExt = getLastSplittedPartByFragment(fileUrl, '.');
    if (fileExt === fileUrl) {
      fileExt = '';
    }
    return fileExt;
  };

  const getFileName = (fileUrl: string): string => {
    const fileExtension = getFileExt(fileUrl);
    const fileName = getLastSplittedPartByFragment(fileUrl);
    const maxFileNameLength = ATTACHMENT_NAME_MAX_LENGTH - fileExtension.length;
    if (fileName.length > maxFileNameLength) {
      const dots = '...';
      return fileName.slice(0, maxFileNameLength - fileExtension.length - dots.length).concat(dots, fileExtension);
    }
    return fileName;
  };

  const areAttachmentsEmpty = (valuesData?: string[]) => (
    valuesData ? valuesData.length === 0 : true
  );

  const changeActiveTab = (tabIndex: number) => {
    dispatch(setCurrentDocumentActiveTab(tabIndex));
  };

  const fileNameClass = pdfDocumentView ? 'attachments-file-name' : 'dzm-name';

  if (pdfDocumentView) {
    if (!valuesArray?.length && !currentAttachments?.length) {
      return (
        <span
          role="button"
          tabIndex={-1}
          className={`${fileNameClass} py-1`}
          onClick={!readOnlyMode && isPublicPage ? () => changeActiveTab(ATTACHMENTS_TAB_ID) : () => null}
        >
          Please upload attachments
        </span>
      );
    }
    return (
      <div
        role="button"
        tabIndex={-1}
        onClick={!readOnlyMode && isPublicPage ? () => changeActiveTab(ATTACHMENTS_TAB_ID) : () => null}
      >
        <UploadedFilesListRender
          isPDFDocument
          values={valuesArray}
          fileNameClass={fileNameClass}
          style={style}
          handleDownloadFile={handleDownloadFile}
          getFileName={getFileName}
        />
        <NotUploadedFilesListRender
          isPDFDocument
          currentAttachments={currentAttachments}
          fileNameClass={fileNameClass}
          style={style}
        />
      </div>
    );
  }

  if (inlineView) {
    return (
      <div
        className="dropzone-previews d-flex flex-column"
        data-attachments-empty={documentView && areAttachmentsEmpty(valuesArray)}
      >
        <UploadedFilesListRender
          values={valuesArray}
          fileNameClass={cn(
            fileNameClass,
            'slate-field-attachment-name',
          )}
          style={style}
          handleDownloadFile={handleDownloadFile}
          getFileName={getFileName}
        />
      </div>
    );
  }

  const fileList = valuesArray && valuesArray.map((fileUrl: string) => {
    const fileExtension = getFileExt(fileUrl);
    const fileName = getFileName(fileUrl);
    return fileExtension ? (
      <span key={fileUrl} className="dz-preview d-block position-relative">
        <span
          className="dz-message d-block mx-0 py-0"
          onClick={() => handleDownloadFile(fileUrl)}
          role="button"
          tabIndex={-1}
          title="Download the file"
        >
          <span
            className={cn(
              fileNameClass,
              'slate-field-attachment-name',
            )}
            style={style}
          >
            {fileName}
          </span>
        </span>
        {
          (!readOnlyMode && !documentView) && (
            <span
              className="attachments-delete-option"
              onClick={() => handleDeleteFile(fileUrl)}
              role="button"
              tabIndex={-1}
            >
              <CancelOutlined />
            </span>
          )
        }
      </span>
    ) : null;
  });

  const isEmptyAttachmentsList = !currentAttachments?.length && (fileList ? !fileList?.length : true);

  return (
    <span
      role="button"
      tabIndex={0}
      className={cn(
        'attachments-list',
        {
          'document-attachments slate-attachments-list': documentView,
          'p-attachments': fileList && !documentView,
        },
      )}
      contentEditable={false}
      data-attachments-empty={isEmptyAttachmentsList}
    >
      {
        isEmptyAttachmentsList && (
          <button
            type="button"
            className="upload-button"
            onClick={!readOnlyMode ? () => changeActiveTab(ATTACHMENTS_TAB_ID) : () => null}
          >
            Please upload attachments
          </button>
        )
      }
      {fileList}
      <NotUploadedFilesListRender
        isPDFDocument
        currentAttachments={currentAttachments}
        fileNameClass={fileNameClass}
        style={style}
      />
    </span>
  );
};

export default AttachmentsList;