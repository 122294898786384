import {
  BOLD,
  DROPDOWN_TYPES,
  HEADLINE_FIVE,
  HEADLINE_FOUR,
  HEADLINE_ONE,
  HEADLINE_SIX,
  HEADLINE_THREE,
  HEADLINE_TWO,
  ITALIC,
  NUMBERED_LIST,
  STRIKE_THROUGH,
  UNDERLINE,
} from 'constants/editors';
import {
  AlignCenterIcon,
  AlignJustifyIcon,
  AlignmentIcon,
  AlignRightIcon,
  BoldIcon,
  CursiveIcon,
  HeaderFiveIcon,
  HeaderFourIcon,
  HeaderOneIcon,
  HeaderSixIcon,
  HeaderThreeIcon,
  HeaderTwoIcon,
  NumberedListIcon,
  StrikethroughIcon,
  UnderlineIcon,
} from 'svg/FieldsToolbarsIcons';
import { BlockFormatType, BlockTextAlignType, CustomEditorType, MarkFormatType } from 'types/Editor';
import { toggleBlock } from 'utils/editorBlockHelpers';
import { addPropertyFontType, changeListItemFontSize } from 'utils/editorFontTypeHelpers';
import { toggleMark } from 'utils/editorMarkHelpers';
import { addPropertyTextAlign } from 'utils/editorTextAlignHelpers';

export const getDropdownIcon = (format: string) => ({
  'text-left': AlignmentIcon(),
  'text-center': AlignCenterIcon(),
  'text-right': AlignRightIcon(),
  'text-justify': AlignJustifyIcon(),
  [HEADLINE_ONE]: HeaderOneIcon(),
  [HEADLINE_TWO]: HeaderTwoIcon(),
  [HEADLINE_THREE]: HeaderThreeIcon(),
  [HEADLINE_FOUR]: HeaderFourIcon(),
  [HEADLINE_FIVE]: HeaderFiveIcon(),
  [HEADLINE_SIX]: HeaderSixIcon(),
  [UNDERLINE]: UnderlineIcon(),
  [ITALIC]: CursiveIcon(),
  [BOLD]: BoldIcon(),
  [STRIKE_THROUGH]: StrikethroughIcon(),
  [NUMBERED_LIST]: NumberedListIcon(),
  // TODO: different icons for numbered list styles like LIST_STYLE_DECIMAL_POINTS
}[format] || null);

export const addPropertiesToText = ({
  editor,
  format,
  type,
  dropdownType,
}: {
  editor: CustomEditorType;
  format: BlockFormatType | BlockTextAlignType | MarkFormatType;
  type: string;
  dropdownType: DROPDOWN_TYPES;
}) => {
  switch (dropdownType) {
    case DROPDOWN_TYPES.FONT_TYPE:
      return addPropertyFontType(editor, format as BlockFormatType, type);
    case DROPDOWN_TYPES.TEXT_ALIGNMENT:
      return addPropertyTextAlign(editor, format as BlockTextAlignType);
    case DROPDOWN_TYPES.TEXT_FORMAT:
      return toggleMark(editor, format as MarkFormatType);
    case DROPDOWN_TYPES.TITLE_FORMAT:
      return toggleBlock(editor, format as BlockFormatType);
    case DROPDOWN_TYPES.TEXT_SIZE:
      changeListItemFontSize(editor, format as BlockFormatType, type);
      return toggleMark(editor, format as MarkFormatType, type);
    case DROPDOWN_TYPES.NUMBERED_LIST_VIEW:
      return toggleBlock(editor, format as BlockFormatType, type);
    default:
      return null;
  }
};