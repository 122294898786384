import { ChangeEventHandler, FC, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Descendant } from 'slate';

import DocumentNavigator from 'components/DocumentNavigator';
import Editor from 'components/Editor';
import EditorContainer from 'components/Editor/EditorContainer';
import EditorWrapper from 'components/Editor/EditorWrapper';
import EditorHeader from 'components/EditorHeader/EditorHeader';
import EditorHeaderReadOnly from 'components/EditorHeader/EditorHeaderReadOnly';
import ModalWindow from 'components/Modals';
import ModalContentWindow from 'components/Modals/ModalContentWindow';
import ShareLinkModal from 'components/Modals/ModalWrapper';
import SearchReplaceForm from 'components/Sidebar/SearchReplaceForm';
import SyncLoader from 'components/SyncLoader';
import { READ_ONLY_STATUSES, STATUSES, STATUSES_FOR_SHARING } from 'constants/documentStatuses';
import { EditorGridAxisCount, INITIAL_EDITOR_VALUE, MANAGER_ASSIGNMENT } from 'constants/editors';
import { DOCUMENT_NAME_LABEL, RedirectTypeEnum, URL_MANAGER_DOCUMENT, URL_PUBLIC_DOCUMENT } from 'constants/general';
import { SOMETHING_WENT_WRONG } from 'constants/generalErrors';
import { useRolePermission } from 'hooks/useRolePermission';
import { saveDocumentExecutedLog } from 'services/api/serverSideEvents';
import { setSelectedTableQuestion } from 'store/actions/editorSlate';
import {
  createDocumentPublicLink,
  deleteDocumentLinkFromStore,
} from 'store/actions/publicPages';
import { updateTemplateSections } from 'store/actions/template';
import {
  editDocumentDetails,
  getDocumentDetails,
  setDocumentDetails,
} from 'store/actions/userData';
import { RootStateType } from 'store/reducers';
import { ICreateDocumentRequestObj } from 'types/Documents';
import { HISTORY_ACTIONS } from 'types/DocumentsHistory';
import { IMetadataId } from 'types/Metadata';
import { ITemplateSection } from 'types/redux';
import { MatchParams } from 'types/Route';
import { ProfileInfoType } from 'types/userProfile';
import { IErrorField } from 'types/validation';
import { trackAmplitudeEvent } from 'utils/amplitude/amplitudeTrackingUtlis';
import { getAmplitudeEventName } from 'utils/amplitude/amplitudeUtils';
import { trimHTMLValue } from 'utils/descriptionHelper';
import { getUTCDate } from 'utils/documentsHistory';
import { wasStatusChanged } from 'utils/documentStatusHelpers';
import { filterFormFieldsFromContent } from 'utils/editorFieldHelpers';
import { drawSectionsInEditorContent } from 'utils/editorHelpers';
import { addHTMLandCSSifCompleted } from 'utils/fileLinkDownload';
import { getIsPDFDocument } from 'utils/PublicPage/documentTypeChecker';
import setDefaultPageValuesHelper from 'utils/reduxHelpers';
import { getEditorPageLink } from 'utils/routeHelpers';
import { isRequiredFieldArrayHasError, validationForm } from 'utils/validation';

const EditDocumentExecuted: FC = () => {
  const { id }: MatchParams = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { states, statuses, doctypes, documentDetails } = useSelector((state: RootStateType) => state.user);
  const { documentPublicLink } = useSelector((state: RootStateType) => state.publicPages);
  const { isLoading } = useSelector((state: RootStateType) => state.errorLoading);
  const { sections } = useSelector((state: RootStateType) => state.template);
  const userInfo: ProfileInfoType = useSelector((state: RootStateType) => state.profile.profileInfo);
  const { assignments } = useSelector((state: RootStateType) => state.editorSlate);

  const { permissionUsingSectionsLight, isUserReadOnly } = useRolePermission();

  const [previewMode, setPreviewMode] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<IErrorField>({ document_name: null, title: null });
  const [documentName, setDocumentName] = useState<string>('');
  const [documentTitle, setDocumentTitle] = useState<string>('');
  const [documentDescription, setDocumentDescription] = useState<string>('');
  const [documentStatus, setDocumentStatus] = useState<string>('');
  const [documentState, setDocumentState] = useState<IMetadataId[]>([{ id: 1 }]);
  const [documentDoctype, setDocumentDoctype] = useState<IMetadataId | null>(null);
  const [documentContent, setDocumentContent] = useState<Descendant[]>(INITIAL_EDITOR_VALUE);
  const [readOnlyMode, setReadOnlyMode] = useState<boolean>(false);
  const [completedMode, setCompletedMode] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [showShareLinkModal, setShowShareLinkModal] = useState<boolean>(false);
  const [isShowManagerButton, setIsShowManagerButton] = useState<boolean>(false);
  const [showManagerFieldsModal, setShowManagerFieldsModal] = useState<boolean>(false);

  const [searchString, setSearchString] = useState<string>('');

  // Executed document history log: manager's action 'viewed'
  useEffect(() => {
    if (id) {
      saveDocumentExecutedLog(
        Number(id),
        { [HISTORY_ACTIONS.VIEWED]: getUTCDate() },
      );
    }
  }, []);

  useEffect(() => {
    if (id && documentDetails) {
      trackAmplitudeEvent({
        eventName: getAmplitudeEventName({ data: documentDetails }).viewedByManager,
        userInfo,
      });
    }
  }, [documentDetails?.id]);

  useEffect(() => {
    if (id) {
      dispatch(getDocumentDetails(id));
    }
    return () => {
      dispatch(setDocumentDetails(null));
      setDefaultPageValuesHelper(dispatch, permissionUsingSectionsLight);
      dispatch(setSelectedTableQuestion(null));
    };
  }, [id, permissionUsingSectionsLight]);

  useEffect(() => {
    if (
      !permissionUsingSectionsLight
      && sections.length === 0
      && INITIAL_EDITOR_VALUE !== documentContent
    ) {
      return setDocumentContent(INITIAL_EDITOR_VALUE);
    }
    setDocumentContent(drawSectionsInEditorContent(sections, documentContent));
  }, [permissionUsingSectionsLight, sections]);

  useEffect(() => {
    if (!documentDetails) return;
    if (getIsPDFDocument(documentDetails.type)) {
      history.push(getEditorPageLink(id, documentDetails.type, true));
      return;
    }

    setDocumentTitle(documentDetails.document_title);
    setDocumentDescription(documentDetails.description);
    setDocumentName(documentDetails.name);
    if (documentDetails?.content_json) {
      setDocumentContent(documentDetails?.content_json);
    }
    if (documentDetails?.status) {
      setDocumentStatus(documentDetails.status);
      setReadOnlyMode(READ_ONLY_STATUSES.includes(documentDetails.status));
    }

    const newSections = documentDetails?.content_json?.map((section: ITemplateSection) => ({
      name: section.name,
      description: section.description,
      key: section.key,
      position: section.position,
    }));
    dispatch(updateTemplateSections(newSections || []));
  }, [documentDetails]);

  useEffect(() => {
    setIsShowManagerButton(!!filterFormFieldsFromContent(documentContent, MANAGER_ASSIGNMENT).length);
  }, [documentContent]);

  useEffect(() => {
    if (!documentDetails) return;

    if (documentDetails.doctype && doctypes) {
      setDocumentDoctype(documentDetails.doctype);
    }
    if (documentDetails.state && states) {
      setDocumentState([documentDetails.state]);
    }

    if (!documentDetails.template) return;
    const templateDetails = documentDetails.template;

    if (!documentDetails.doctype && templateDetails.doctype) {
      setDocumentDoctype(templateDetails.doctype);
    }
    if (!documentDetails.state && templateDetails.states) {
      setDocumentState(templateDetails.states);
    }
  }, [states, doctypes, documentDetails]);

  useEffect(() => {
    if (documentStatus) {
      setCompletedMode(documentStatus === STATUSES.completed);
    }
  }, [documentStatus, documentPublicLink]);

  const validateFieldsNameTitle = (documentName: string | null) => {
    const validateArray = [];
    // TODO: refactor this: titleField can be generated from nameField, we can not pass here incoming strings, etc.
    if (documentName !== null) {
      validateArray.push({
        titleField: DOCUMENT_NAME_LABEL, nameField: 'document_name', valueField: documentName, required: true,
      });
    }
    const validateResult = validationForm(validateArray);
    setFormErrors((prev) => ({ ...prev, ...validateResult.validationFields }));
    return validateResult;
  };

  const onChangeDocumentName: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    setDocumentName(target.value);
    if (!documentTitle || documentTitle === documentName) {
      setDocumentTitle(target.value);
    }
  };

  const getEditedDocumentData = (newStatus: string = '') => {
    const validateNameResult = validateFieldsNameTitle(documentName);
    if (validateNameResult.isError) return null;
    if (!documentDoctype) return null;

    const contentDataToSave = permissionUsingSectionsLight
      ? (
        documentContent.map((element: any) => {
          const sectionInStore: ITemplateSection | undefined = sections.find((el) => el.key === element.key);
          return {
            children: element.children,
            key: element.key,
            position: sectionInStore?.position || 0,
            name: sectionInStore?.name.trim() || '',
            description: trimHTMLValue(sectionInStore?.description || ''),
            type: element.type,
          };
        })
      ) : documentContent;

    const editedDocument: ICreateDocumentRequestObj = {
      name: documentName.trim(),
      document_title: documentTitle.trim(),
      description: trimHTMLValue(documentDescription),
      doctype_id: documentDoctype.id,
      state_id: documentState[0].id,
      content_json: contentDataToSave,
      assignments,
    };

    if (
      newStatus
      && wasStatusChanged(documentStatus, newStatus)
      && statuses.includes(newStatus)
    ) {
      if (newStatus === STATUSES.completed) {
        // validate required manager fields and deny completing the document
        const hasError = isRequiredFieldArrayHasError({
          contentData: contentDataToSave,
          assignment: MANAGER_ASSIGNMENT,
        });
        if (hasError) {
          setShowManagerFieldsModal(true);
          return null;
        }

        // validate resipient fields and show a warning
        const hasResipientError = isRequiredFieldArrayHasError({ contentData: contentDataToSave });
        if (hasResipientError) {
          toast.warning('There are empty recipient required fields.');
        }
      }

      editedDocument.status = newStatus;
      setDocumentStatus(newStatus);
      setReadOnlyMode(READ_ONLY_STATUSES.includes(newStatus));
    }

    return editedDocument;
  };

  const saveDocument = async (newStatus: boolean | string = '') => {
    let data = getEditedDocumentData(newStatus as string);
    if (data !== null) {
      const isCompleteFlow: boolean = data.status === STATUSES.completed;
      if (isCompleteFlow) {
        data = await addHTMLandCSSifCompleted(data);
      }

      const editedDocument = {
        id,
        document: data,
        completeFlow: isCompleteFlow,
      };

      dispatch(editDocumentDetails(editedDocument));
    }
    return data;
  };

  const openManagerPageHandler = () => {
    const data = getEditedDocumentData();
    if (data !== null) {
      dispatch(editDocumentDetails({
        id,
        document: data,
        path: `/${URL_MANAGER_DOCUMENT}/${id}`,
      }));
    }
  };

  const handlerShareLinkModal = async () => {
    const saved = await saveDocument();
    if (!saved) {
      setShowErrorModal(true);
    } else {
      dispatch(createDocumentPublicLink({ document_id: documentDetails?.id, assignments }));
      setShowShareLinkModal(true);
      if (documentStatus !== STATUSES.completed) {
        setDocumentStatus(STATUSES.waiting);
      }
      setReadOnlyMode(true);
    }
  };

  const deleteDocumentLinkHandler = () => {
    dispatch(deleteDocumentLinkFromStore());
  };

  const changeStatusAndSaveDocument = (newStatus: string) => {
    saveDocument(newStatus);
  };

  if (!statuses.length || !doctypes.length || !documentStatus) {
    return <SyncLoader />;
  }

  const statusObject = statuses && {
    statuses,
    selectedStatus: documentStatus,
    onSelectStatus: changeStatusAndSaveDocument,
  };

  const saveDocumentController = async (
    redirectType: RedirectTypeEnum | undefined = undefined,
    redirectPath: string | undefined = undefined,
  ) => {
    await saveDocument();
    if (redirectType && redirectPath) {
      history.push(redirectPath);
    }
  };

  return (
    <>
      <EditorWrapper>
        {
          !isUserReadOnly ? (
            <EditorHeader
              documentName={documentName}
              onChangeDocumentName={onChangeDocumentName}
              errorText={formErrors.document_name}
              saveDocument={(
                redirectType: RedirectTypeEnum | undefined,
                redirectPath: string | undefined,
              ) => saveDocumentController(redirectType, redirectPath)}
              previewMode={previewMode}
              setPreviewMode={setPreviewMode}
              description={documentDescription}
              setDescription={setDocumentDescription}
              showShareLinkModal={showShareLinkModal}
              handlerShareLinkModal={handlerShareLinkModal}
              isShareButtonAvailable={STATUSES_FOR_SHARING.includes(documentStatus)}
              readOnly={readOnlyMode}
              openManagerPageHandler={isShowManagerButton ? openManagerPageHandler : undefined}
              statusObject={statusObject}
              showDocumentHistoryButton
            />
          ) : (<EditorHeaderReadOnly documentName={documentName} />)
        }
        <EditorContainer columns={isUserReadOnly ? EditorGridAxisCount.One : EditorGridAxisCount.Three}>
          <DocumentNavigator panelTitle="Search">
            <div>
              <SearchReplaceForm
                content={documentContent}
                setContent={setDocumentContent}
                setString={setSearchString}
                isReadOnly={readOnlyMode}
                showSearch
              />
            </div>
          </DocumentNavigator>
          <Editor
            search={searchString}
            visibleEditorDevTools={!readOnlyMode && !isUserReadOnly}
            readOnlyMode={readOnlyMode || isUserReadOnly}
            content={documentContent}
            onChange={setDocumentContent}
            previewMode={previewMode}
            styledSections={permissionUsingSectionsLight}
          />
        </EditorContainer>
      </EditorWrapper>
      <ShareLinkModal
        publicRoute={URL_PUBLIC_DOCUMENT}
        result={documentPublicLink.assignments}
        resourceGuid={documentPublicLink.origin}
        showModal={!isLoading && showShareLinkModal}
        onCloseModal={setShowShareLinkModal}
        deleteLinkFromStore={deleteDocumentLinkHandler}
        showEmbedScript={false}
        readOnlyMode={completedMode}
      />
      <ModalContentWindow
        showModal={showErrorModal}
        onCloseModal={setShowErrorModal}
        titleText={SOMETHING_WENT_WRONG}
        showDefaultText={false}
      >
        <div>Please fill out all required fields.</div>
      </ModalContentWindow>
      <ModalWindow
        isOpen={showManagerFieldsModal}
        onClose={() => setShowManagerFieldsModal(false)}
        onButtonClick={openManagerPageHandler}
        title={SOMETHING_WENT_WRONG}
        buttonTitle="Fill & Sign"
      >
        <div>There are empty required fields.<br />You cannot complete this document until they are filled in.</div>
      </ModalWindow>
    </>
  );
};

export default EditDocumentExecuted;