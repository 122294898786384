import { FC, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { SwiperSlide } from 'swiper/react';

import PublicFieldView from 'components/Editor/FormView/Fields/PublicFieldView';
import FormsSlider from 'components/Editor/FormView/FormsSlider';
import TitleAndDescription from 'components/Editor/FormView/TitleAndDescription';
import useFieldsHandler from 'hooks/useFieldsHandler';
import useShowFieldsFilledInMessage from 'hooks/useShowFieldsFilledInMessage';
import { changeFieldInFocusStatus, deleteSelectFieldErrors, setPublicFormValidation } from 'store/actions/editorSlate';
import { RootStateType } from 'store/reducers';
import FocusIcon from 'svg/FocusIcon';
import { PDFFieldType } from 'types/PdfTemplates';
import { IValidated } from 'types/validation';
import {
  getCurrentDate,
  getFieldIDsToSelect,
  isFieldHasSubtype,
} from 'utils/editorFieldHelpers';
import {
  filterSortedFormFieldsFromJSONObject as filterFromJSON,
} from 'utils/pdfTemplatesHelpers';

interface IPdfFormViewProps {
  readOnlyMode?: boolean;
  isPublicPage?: boolean;
  isMultiTemplate?: boolean;
  pdfTemplateId?: number;
}

const PdfFormView: FC<IPdfFormViewProps> = ({
  readOnlyMode = false,
  isPublicPage = false,
  isMultiTemplate = false,
  pdfTemplateId = 0,
}) => {
  const dispatch = useDispatch();
  // TODO: can remove isPublicPage prop if we use the component only for Public pages
  const [formValidationResults, setFormValidationResults] = useState<IValidated>({
    validationFields: {},
    isError: false,
  });

  const fieldInFocus = useSelector((state: RootStateType) => state.editorSlate.fieldInFocus);

  useEffect(() => () => {
    dispatch(setPublicFormValidation({
      validationFields: {},
      isError: false,
    }));
    dispatch(deleteSelectFieldErrors());
    dispatch(changeFieldInFocusStatus(false));
  }, [dispatch]);

  const pdfPartsOfMultiTemplate = useSelector((state: RootStateType) => state.multiTemplates.pdfPartsOfMultiTemplate);
  const pdfTemplateInfoMultiTemplate = pdfPartsOfMultiTemplate && pdfPartsOfMultiTemplate[pdfTemplateId];
  const pdfTemplateSimple = useSelector((state: RootStateType) => state.pdfTemplates);
  const { pdfTemplateFields, pdfTemplateInfo } = isMultiTemplate ? pdfTemplateInfoMultiTemplate : pdfTemplateSimple;
  const { templateName, templateTitle, templateDescription } = pdfTemplateInfo;

  const { publicFormValidationResults } = useSelector((state: RootStateType) => state.editorSlate);
  const { main } = useSelector((state: RootStateType) => state.publicPages.structure);

  const sortedFields: PDFFieldType[] = filterFromJSON(pdfTemplateFields, isPublicPage, main.mainAssignment);

  useShowFieldsFilledInMessage(sortedFields, readOnlyMode);

  const { updateFieldsHandler } = useFieldsHandler({ editor: null });

  const setDefaultCurrentDate = (field: PDFFieldType) => {
    if (field.isTodaysDate) {
      const currentDate = getCurrentDate();
      const updatedIsTodaysDate = {
        isTodaysDate: false,
        value: currentDate,
      };
      updateFieldsHandler(field, updatedIsTodaysDate);
    }
  };

  useEffect(() => {
    if (isPublicPage) {
      setFormValidationResults(publicFormValidationResults);
    }
  }, [isPublicPage, publicFormValidationResults]);

  const formFieldRender = (field: PDFFieldType) => {
    const fieldMessage = (field.key && formValidationResults.validationFields[field.key]) || '';
    if (isPublicPage) {
      setDefaultCurrentDate(field);
    }
    const idsToSelect = isPublicPage ? [] : getFieldIDsToSelect(
      field,
      field.key || null,
      sortedFields,
      undefined,
      isFieldHasSubtype(field.subtype),
    );

    return (
      <PublicFieldView
        key={field.key}
        field={field}
        options={field.options}
        onChangeValue={updateFieldsHandler}
        validateField={isPublicPage}
        validationMessage={fieldMessage}
        readOnlyMode={readOnlyMode}
        idsToSelect={idsToSelect}
        isPdfFormField
      />
    );
  };

  if (pdfTemplateId !== 0) {
    return (
      <>
        <TitleAndDescription
          title={pdfTemplateInfo?.templateTitle}
          description={pdfTemplateInfo?.templateDescription}
          isReadOnlyMode
          isPublicPage={isPublicPage}
        />
        {sortedFields.map((field: PDFFieldType) => formFieldRender(field))}
      </>
    );
  }
  if (isPublicPage) {
    return !readOnlyMode ? (
      <>
        {
          fieldInFocus
          && (
            <button
              className="focus-icon-button"
              type="button"
              onClick={() => dispatch(changeFieldInFocusStatus(false))}
            >
              <FocusIcon />
            </button>
          )
        }
        <FormsSlider>
          <SwiperSlide key="template-info-slide" data-first-slide="true">
            <ol className="list-group">
              <div className="d-flex flex-column align-self-center max-w-450">
                <TitleAndDescription
                  title={templateTitle || templateName}
                  description={templateDescription}
                  isReadOnlyMode
                  isPublicPage={isPublicPage}
                />
                {
                  sortedFields.map((field: PDFFieldType) => formFieldRender(field))
                }
              </div>
            </ol>
          </SwiperSlide>
        </FormsSlider>
      </>
    ) : null;
  }

  return (
    <div>This component is not used any more for Manager interface</div>
  );
};

export default PdfFormView;