import { FC } from 'react';

import AutocompleteField from 'components/Modals/MondayComModalWindow/AutocompleteField';
import {
  FilterColumnsIdsObjectType,
  MondayComFilterKeys,
  MondayComFilterKeysType,
  MondayComIntegrationObjectType,
} from 'types/mondayCom/mondayComBoard';
import { excludeNotImplementedColumns } from 'utils/MondayComIntegration/mondayComHelpers';

interface IMondayComSelectKeyFilterColumns {
  currentBoardObject: MondayComIntegrationObjectType | null,
  onChangeKeyFilterColumnsHandler: (
    filterColumnsIdsObject: FilterColumnsIdsObjectType,
    isItemDocumentFlow: boolean,
  ) => void;
  isItemDocumentFlow?: boolean;
  enabledSMSValidation?: boolean;
  blockClosePopupButton?: boolean;
}

const MondayComSelectKeyFilterColumns: FC<IMondayComSelectKeyFilterColumns> = ({
  currentBoardObject,
  onChangeKeyFilterColumnsHandler,
  isItemDocumentFlow = false,
  enabledSMSValidation = false,
  blockClosePopupButton = false,
}) => {
  const onChangeKeyFilterColumns = (columnTitle: string | null, filterKey: MondayComFilterKeysType) => {
    const targetColumn = currentBoardObject?.board.columns.find((column) => column.title === columnTitle);
    onChangeKeyFilterColumnsHandler(
      {
        boardId: currentBoardObject?.board.id ?? '',
        fiterType: filterKey,
        columnTitle,
        columnId: targetColumn?.id,
      },
      isItemDocumentFlow,
    );
  };

  const getCurrentValue = (filterKey: MondayComFilterKeysType) => {
    if (currentBoardObject) {
      const currentPartOfStore = isItemDocumentFlow
        ? currentBoardObject.itemDocumentFileSettings
        : currentBoardObject.filterColumnsIds;
      const targetColumnId = currentPartOfStore ? currentPartOfStore[filterKey] : '';
      const targetColumn = currentBoardObject?.board.columns.find((column) => column.id === targetColumnId);
      return targetColumn ? targetColumn.title : '';
    }
    return '';
  };

  return (
    <div>
      <div className="d-flex justify-content-center my-3 monday-com-board-name">
        {
          !isItemDocumentFlow
            ? <span>Email column and Display Column are required</span>
            : (
              <span>
                Select Email column and Display Column if you want to upload completed document to an existing item
              </span>
            )
        }
      </div>
      <div className="d-flex mb-2">
        <div className="w-50 d-flex align-items-center">
          Email Column
        </div>
        <div className="w-50">
          <AutocompleteField
            options={currentBoardObject
              ? excludeNotImplementedColumns(currentBoardObject.board.columns).map((column) => column.title)
              : []}
            currentValue={getCurrentValue(MondayComFilterKeys.EMAIL_COLUMN_ID)}
            onChangeHandler={(value) => onChangeKeyFilterColumns(value, MondayComFilterKeys.EMAIL_COLUMN_ID)}
            inputLabel="Select Email Column"
          />
        </div>
      </div>
      <div className="d-flex mb-2">
        <div className="w-50 d-flex align-items-center">
          Display Column
        </div>
        <div className="w-50">
          <AutocompleteField
            options={currentBoardObject
              ? excludeNotImplementedColumns(currentBoardObject.board.columns).map((column) => column.title)
              : []}
            currentValue={getCurrentValue(MondayComFilterKeys.FILTER_COLUMN_ID)}
            onChangeHandler={(value) => onChangeKeyFilterColumns(value, MondayComFilterKeys.FILTER_COLUMN_ID)}
            inputLabel="Select Display Column"
          />
        </div>
      </div>
      {
        !isItemDocumentFlow && enabledSMSValidation && (
          <>
            <div
              className={`d-flex justify-content-center my-3 monday-com-board-name${blockClosePopupButton ? '-error' : ''}`}
            >
              <span>Phone Number Column is reqired if a document reqires SMS validation</span>
            </div>
            <div className="d-flex mb-2">
              <div className="w-50 d-flex align-items-center">
                Phone Number Column
              </div>
              <div className="w-50">
                <AutocompleteField
                  options={currentBoardObject
                    ? excludeNotImplementedColumns(currentBoardObject.board.columns).map((column) => column.title)
                    : []}
                  currentValue={getCurrentValue(MondayComFilterKeys.PHONE_NUMBER_COLUMN_ID)}
                  onChangeHandler={
                    (value) => onChangeKeyFilterColumns(value, MondayComFilterKeys.PHONE_NUMBER_COLUMN_ID)
                  }
                  inputLabel="Select Phone Number Column"
                />
              </div>
            </div>
          </>

        )
      }
    </div>
  );
};

export default MondayComSelectKeyFilterColumns;