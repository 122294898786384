import ENDPOINT from 'constants/endpoints';
import { IResponseDeleteObj, IResponseOfRequest } from 'types/Api';
import { DocumentTypesType } from 'types/Documents';
import { ICollectionDetails, IMultiTemplate, IMultiTemplateRequestObj } from 'types/MultiTemplate';
import { axiosInstance } from 'utils/axiosInstance';

export const getMultiTemplatesList = (): Promise<IResponseOfRequest<IMultiTemplate[]>> => (
  axiosInstance.get(ENDPOINT.COLLECTION)
);

export const createMultiTemplate = (
  body: IMultiTemplateRequestObj,
): Promise<IResponseOfRequest<ICollectionDetails>> => (
  axiosInstance.post(ENDPOINT.COLLECTION, body)
);

export const getMultiTemplateById = (id: string): Promise<IResponseOfRequest<ICollectionDetails>> => (
  axiosInstance.get(`${ENDPOINT.COLLECTION}/${id}`)
);

export const editMultiTemplate = (
  id: string,
  body: IMultiTemplateRequestObj,
): Promise<IResponseOfRequest<ICollectionDetails>> => {
  const requestBody = body;
  if (requestBody.id) {
    delete requestBody.id;
  }
  return axiosInstance.patch(`${ENDPOINT.COLLECTION}/${id}`, requestBody);
};

export const deleteMultiTemplates = (
  ids: number[],
  resourceType: DocumentTypesType,
): Promise<IResponseOfRequest<IResponseDeleteObj>> => (
  axiosInstance.delete(ENDPOINT.COLLECTION, { params: [resourceType, ...ids] })
);