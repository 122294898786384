import { FC } from 'react';

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Button from 'components/Base/Button';
import { CANCEL, SAVE } from 'constants/general';

interface IManagementModal {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  saveButtonText?: string;
  withCancelButton?: boolean;
  disableSaveButton?: boolean;
}

const ManagementModal: FC<IManagementModal> = ({
  title,
  isOpen,
  onSave,
  onClose,
  saveButtonText = SAVE,
  withCancelButton = true,
  disableSaveButton = false,
  children,
}) => (
  <Modal isOpen={isOpen}>
    <ModalHeader toggle={onClose}>
      {title}
    </ModalHeader>
    <ModalBody>
      {children}
    </ModalBody>
    <ModalFooter>
      {withCancelButton && (<Button classes="outline" onClick={onClose}>{CANCEL}</Button>)}
      <Button onClick={onSave} disabled={disableSaveButton}>
        {saveButtonText}
      </Button>
    </ModalFooter>
  </Modal>
);

export default ManagementModal;