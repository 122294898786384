import { useLocation } from 'react-router-dom';

import { SINGLE } from 'constants/routes';

const useSingleDocument = (): boolean => {
  const location = useLocation();
  const regex = new RegExp(`${SINGLE}`);
  return regex.test(location.pathname);
};

export default useSingleDocument;