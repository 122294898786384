import { FC } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { AddCircle as AddCircleIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import { createTemplateSection } from 'store/actions/template';
import { RootStateType } from 'store/reducers';
import { generateNumber } from 'utils/editorFieldHelpers';

const AddSectionButton: FC<{ onClick: () => void, isFormBuilder?: boolean }> = ({
  onClick,
  isFormBuilder = false,
}) => {
  const dispatch = useDispatch();
  const { sections } = useSelector((state: RootStateType) => state.template);

  const onAddNewSectionHandler = () => {
    if (!isFormBuilder) {
      dispatch(createTemplateSection({
        name: '',
        key: generateNumber(),
        position: sections.length,
        section_id: null,
      }));
    }
    onClick();
  };

  return (
    <div className="form-section__add-new-button pb-4 mx-2">
      <IconButton aria-label="Add section" size="small" className="py-1 px-2" onClick={onAddNewSectionHandler}>
        <AddCircleIcon className="me-1" />
        <span>Add section</span>
      </IconButton>
    </div>
  );
};

export default AddSectionButton;