import { track } from '@amplitude/analytics-browser';

import { USER_LOCATION } from 'constants/general';
import { ROLES } from 'constants/roles';
import { DEFAULT_USER_INFO, DEFAULT_USER_LOCATION } from 'utils/amplitude/amplituteConstants';
import { AmplituteEventArgs, UserInfoPayload, UserLocation } from 'utils/amplitude/amplituteTypes';

export const trackAmplitudeEvent = ({
  eventName,
  userInfo = DEFAULT_USER_INFO,
}: AmplituteEventArgs) => {
  const copyUserInfo = { ...userInfo };
  if (copyUserInfo.userRole === ROLES.READ_ONLY) {
    copyUserInfo.company = { name: 'Read only user' };
  }
  const {
    email,
    first_name: firstName,
    last_name: lastName,
    company: {
      name,
    },
  } = copyUserInfo;

  const {
    IPv4,
    city,
    country_code: countryCode,
    latitude,
    longitude,
  }: UserLocation = JSON.parse(localStorage.getItem(USER_LOCATION) as string) || DEFAULT_USER_LOCATION;

  const userInfoPayload: UserInfoPayload = {
    id: email,
    firstName,
    lastName,
    companyName: name,
  };

  const userLocationPayload: Omit<UserLocation, 'IPv4'> & { ip: string; device_manufacturer: string } = {
    ip: IPv4,
    city,
    country_code: countryCode,
    latitude,
    longitude,
    device_manufacturer: navigator.vendor ?? 'uknown',
  };

  track(eventName, userInfoPayload, userLocationPayload);
};

export default undefined;