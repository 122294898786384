import { CSSProperties, memo, MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { Descendant, Element as SlateElement } from 'slate';
import { RenderElementProps, useSlate } from 'slate-react';

import {
  DEFAULT_TABLE_QUESTION_CELL_HEIGHT,
  DEFAULT_TABLE_QUESTION_CELL_WIDTH,
  DEFAULT_TABLE_QUESTION_RESIZE_BAR_COEFFICIENT,
  MIN_TABLE_QUESTION_CELL_WIDTH,
} from 'constants/tableQuestion';
import { toShowBlock } from 'utils/routeHelpers';
import { updateColumnCells, updateRowCells } from 'utils/TableQuestion/resizeTableQuestionHelpers';

interface TableQuestionResizeBarProps extends RenderElementProps {
  isVertical?: boolean;
}

const TableQuestionResizeBar = ({ isVertical = false, ...props }: TableQuestionResizeBarProps) => {
  const editor = useSlate();
  const location = useLocation();
  const isPublicPage = toShowBlock(location.pathname, 'public');
  const { attributes, element } = props;
  const [dimension, setDimension] = useState(0);
  const [position, setPosition] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState(0);

  useEffect(() => {
    if (element) {
      const width = element?.width ?? DEFAULT_TABLE_QUESTION_CELL_WIDTH;
      const height = element?.height ?? DEFAULT_TABLE_QUESTION_CELL_HEIGHT;
      const size = isVertical ? width : height;
      setDimension(size);
      setPosition(size - DEFAULT_TABLE_QUESTION_RESIZE_BAR_COEFFICIENT);
    }
  }, [element, isVertical]);

  const onMouseMoveHandler = useCallback((event) => {
    if (!isDragging) return;
    const mouseEvent = event as MouseEvent;
    const x = (isVertical ? mouseEvent.pageX : mouseEvent.pageY) - dragOffset;
    const maxDimension = Math.max(x, MIN_TABLE_QUESTION_CELL_WIDTH);
    setPosition(maxDimension - DEFAULT_TABLE_QUESTION_RESIZE_BAR_COEFFICIENT);
    setDimension(maxDimension);
  }, [isDragging, dragOffset, isVertical]);

  const onMouseUpHandler = useCallback(() => {
    if (isDragging) {
      setIsDragging(false);
      editor.findNodesAndUpdate((node: Descendant) => {
        if (SlateElement.isElement(node) && node?.key === element.key) {
          return {
            ...node,
            ...(isVertical ? { width: dimension } : { height: dimension }),
          };
        }
        return node;
      });
      if (isVertical) {
        updateColumnCells(editor, element, {
          width: dimension,
        });
      } else {
        updateRowCells(editor, element, {
          height: dimension,
        });
      }
    }
  }, [isDragging, editor, element, dimension, isVertical]);

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMoveHandler);
    document.addEventListener('mouseup', onMouseUpHandler);

    return () => {
      document.removeEventListener('mousemove', onMouseMoveHandler);
      document.removeEventListener('mouseup', onMouseUpHandler);
    };
  }, [onMouseMoveHandler, onMouseUpHandler]);

  const onMouseDownHandler: MouseEventHandler<HTMLDivElement> = (event) => {
    setIsDragging(true);
    const offsetX = (isVertical ? event.pageX : event.pageY) - position;
    setDragOffset(offsetX);
  };

  const style: CSSProperties = useMemo(() => (
    isVertical
      ? { width: `${dimension}px` }
      : { height: `${dimension}px` }
  ), [isVertical, dimension]);

  const innerStyle: CSSProperties = useMemo(() => (
    isVertical ? { left: `${position}px` } : { top: `${position}px` }
  ), [isVertical, position]);

  const className = isVertical
    ? 'table-question-vertical-resize-bar'
    : 'table-question-horizontal-resize-bar';

  const innerClassName = isVertical
    ? 'table-question-vertical-resize-bar__bar'
    : 'table-question-horizontal-resize-bar__bar';

  return (
    <td
      {...attributes}
      contentEditable={false}
      style={{
        userSelect: 'none',
        ...style,
      }}
      className={className}
    >
      {
        !isPublicPage
        && (
          <div
            role="button"
            tabIndex={-1}
            contentEditable={false}
            style={{
              userSelect: 'none',
              ...innerStyle,
            }}
            className={innerClassName}
            onMouseDown={onMouseDownHandler}
          />
        )
      }
    </td>
  );
};

export default memo(TableQuestionResizeBar);