import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  SIGNATURE_FIELD_DEFAULT_VARIATION,
  SIGNATURE_FIELD_INITIALS_VARIATION,
  SIGNATURE_TABS,
} from 'constants/signatures';
import { RootStateType } from 'store/reducers';
import { toShowBlock } from 'utils/routeHelpers';

const PUBLIC_USER_DRAW_SIGNATURE = 'publicUserDrawSignature';
const PUBLIC_USER_DRAW_SIGNATURE_TAB = 'publicUserDrawSignatureTab';
const PUBLIC_USER_DRAW_SIGNATURE_TYPE = 'publicUserDrawSignatureType';

const PUBLIC_USER_INITIALS_SIGNATURE = 'publicUserInitialsSignature';
const PUBLIC_USER_INITIALS_SIGNATURE_TAB = 'publicUserInitialsSignatureTab';
const PUBLIC_USER_INITIALS_SIGNATURE_TYPE = 'publicUserInitialsSignatureType';

export const RETURNED_SIGNATURE_VALUE = 'returnedSignatureValue';
export const RETURNED_SIGNATURE_TAB = 'returnedSignatureTab';
export const RETURNED_SIGNATURE_TYPE = 'returnedSignatureType';

const useDefaultSignatureUnauthenticatedUser = () => {
  const location = useLocation();
  const isPublicPage = toShowBlock(location.pathname, 'public');
  const isAuthenticated: boolean = useSelector((state: RootStateType) => state.user.isAuthenticated);
  const [unauthenticatedPublicUser, setUnauthenticatedPublicUser] = useState<boolean>(false);

  useEffect(() => {
    setUnauthenticatedPublicUser(isPublicPage && !isAuthenticated);
  }, [isPublicPage, isAuthenticated]);

  const saveSignatureDataToSessionStorage = (
    signatureData: string,
    signatureTab: SIGNATURE_TABS,
    signatureFieldVariation: string | undefined = undefined,
  ) => {
    if (signatureFieldVariation === SIGNATURE_FIELD_INITIALS_VARIATION) {
      sessionStorage.setItem(PUBLIC_USER_INITIALS_SIGNATURE, signatureData);
      sessionStorage.setItem(PUBLIC_USER_INITIALS_SIGNATURE_TAB, signatureTab);
      sessionStorage.setItem(PUBLIC_USER_INITIALS_SIGNATURE_TYPE, SIGNATURE_FIELD_INITIALS_VARIATION);
    } else {
      sessionStorage.setItem(PUBLIC_USER_DRAW_SIGNATURE, signatureData);
      sessionStorage.setItem(PUBLIC_USER_DRAW_SIGNATURE_TAB, signatureTab);
      sessionStorage.setItem(PUBLIC_USER_DRAW_SIGNATURE_TYPE, SIGNATURE_FIELD_DEFAULT_VARIATION);
    }
  };

  const getSignatureDataFromSessionStorage = (signatureFieldVariation: string | undefined = undefined) => {
    if (signatureFieldVariation === SIGNATURE_FIELD_INITIALS_VARIATION) {
      return {
        [RETURNED_SIGNATURE_VALUE]: sessionStorage.getItem(PUBLIC_USER_INITIALS_SIGNATURE),
        [RETURNED_SIGNATURE_TAB]: sessionStorage.getItem(PUBLIC_USER_INITIALS_SIGNATURE_TAB) as SIGNATURE_TABS,
        [RETURNED_SIGNATURE_TYPE]: sessionStorage.getItem(PUBLIC_USER_INITIALS_SIGNATURE_TYPE),
      };
    }
    return {
      [RETURNED_SIGNATURE_VALUE]: sessionStorage.getItem(PUBLIC_USER_DRAW_SIGNATURE),
      [RETURNED_SIGNATURE_TAB]: sessionStorage.getItem(PUBLIC_USER_DRAW_SIGNATURE_TAB) as SIGNATURE_TABS,
      [RETURNED_SIGNATURE_TYPE]: sessionStorage.getItem(PUBLIC_USER_DRAW_SIGNATURE_TYPE),
    };
  };

  return {
    unauthenticatedPublicUser,
    saveSignatureDataToSessionStorage,
    getSignatureDataFromSessionStorage,
  };
};

export default useDefaultSignatureUnauthenticatedUser;