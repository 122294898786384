import { FC } from 'react';

import { Page, PageProps } from 'react-pdf';

interface IPDFPageProps {
  index: number;
  pdfTemplateWidth: number;
  onRenderSuccess: (height: number) => void;
}

const PDFPage: FC<IPDFPageProps> = ({
  index,
  pdfTemplateWidth,
  onRenderSuccess,
}) => (
  <Page
    key={`renderedPage_${index + 1}`}
    className="pdf__Page"
    loading="Loading..."
    pageNumber={index + 1}
    width={pdfTemplateWidth}
    onRenderSuccess={(page: PageProps) => onRenderSuccess(page.height ?? 1046)}
  />
);

export default PDFPage;