import {
  CLEAR_PDF_TEMPLATE_DATA,
  DELETE_PDF_FIELD,
  SET_ALL_PDF_FIELDS,
  SET_ALL_PDF_FIELDS_AND_INFO,
  SET_ALL_PDF_TEMPLATE_INFO,
  SET_PDF_FORM_FIELDS,
  SET_PDF_TEMPLATE_FILE_KEY,
  SET_PDF_TEMPLATE_FILE_LINK,
  SET_PDF_TEMPLATE_INFO,
  SET_PDF_TEMPLATE_WIDTH,
  SET_SELECTED_FIELD_KEY,
  UPDATE_FIELD_BY_KEY,
} from 'store/actions/actionTypes';
import { PDFFieldType } from 'types/PdfTemplates';

export const setPdfTemplateWidth = (width: number | null) => ({
  type: SET_PDF_TEMPLATE_WIDTH,
  payload: width,
});

export const setPdfFormFiedls = (pdfFormFields: PDFFieldType[]) => ({
  type: SET_PDF_FORM_FIELDS,
  payload: pdfFormFields,
});

export const setAllPdfFields = (pdfFields: Record<number, PDFFieldType>) => ({
  type: SET_ALL_PDF_FIELDS,
  payload: pdfFields,
});

export const setPdfTemplateFileLink = (link: string) => ({
  type: SET_PDF_TEMPLATE_FILE_LINK,
  payload: link,
});

export const setPdfTemplateKey = (fileKey: string) => ({
  type: SET_PDF_TEMPLATE_FILE_KEY,
  payload: fileKey,
});

export const setSelectedFieldKey = (fieldKey: number | null) => ({
  type: SET_SELECTED_FIELD_KEY,
  payload: fieldKey,
});

export const updateFieldByKey = (key: number, field: PDFFieldType) => ({
  type: UPDATE_FIELD_BY_KEY,
  payload: {
    key,
    field,
  },
});

export const deletePdfField = (fieldKey: number) => ({
  type: DELETE_PDF_FIELD,
  payload: fieldKey,
});

export const setAllPdfFieldsAndInfo = (pdfData: any) => ({
  type: SET_ALL_PDF_FIELDS_AND_INFO,
  payload: pdfData,
});

export const clearPdfTemplateData = () => ({
  type: CLEAR_PDF_TEMPLATE_DATA,
});

export const setPdfTemplateInfo = (infoKey: string, value: string) => ({
  type: SET_PDF_TEMPLATE_INFO,
  payload: {
    infoKey,
    value,
  },
});

export const setAllInfoAboutTemplate = (templateInfo: any) => ({
  type: SET_ALL_PDF_TEMPLATE_INFO,
  payload: templateInfo,
});