/* eslint-disable no-param-reassign */
import { Editor, Element, Path } from 'slate';

import { TABLE_QUESTION_CAPTION, TABLE_QUESTION_CELL } from 'constants/editors';
import { findField } from 'hooks/useSortedSections';
import { ICustomElement } from 'types/Editor';
import { findNodesAndUpdate } from 'utils/editorHelpers';
import {
  findTableQuestionCol,
  findTableQuestionRow,
  getIsTableQuestion,
} from 'utils/TableQuestion/tableQuestionHelpers';

const UPDATE_FIELD_NAME_TYPE = {
  ROW: 'row',
  COL: 'col',
};

type UpdateFieldNameType = typeof UPDATE_FIELD_NAME_TYPE.ROW | typeof UPDATE_FIELD_NAME_TYPE.COL;

const updateFieldName = (
  oldName: string,
  newNamePart: string,
  type: UpdateFieldNameType = UPDATE_FIELD_NAME_TYPE.ROW,
): string => {
  const splitName = oldName.split(':');
  return type === UPDATE_FIELD_NAME_TYPE.ROW
    ? `${newNamePart?.trim()}: ${splitName[1]?.trim()}`
    : `${splitName[0]?.trim()}: ${newNamePart?.trim()}`;
};

const getFieldNodeKeys = (cellNodes: ICustomElement[]): number[] => {
  const fieldNodeKeys: number[] = [];

  cellNodes.forEach((cell) => {
    findField(cell, (node) => {
      if (Element.isElement(node) && node.type) {
        fieldNodeKeys.push(node.key || 0);
      }
    });
  });

  return fieldNodeKeys;
};

const updateEditorCells = (
  editor: Editor,
  fieldNodeKeys: number[],
  cellName: string,
  type: UpdateFieldNameType,
) => {
  editor.children = findNodesAndUpdate(editor.children, (node) => {
    if (fieldNodeKeys.includes(node.key || 0)) {
      if (!node.children) return node;
      const oldName = node.children[0].text || '';
      const readableName = updateFieldName(oldName, cellName, type);
      const fieldName = readableName.toUpperCase().split(' ').join('_');
      return {
        ...node,
        name: fieldName,
        children: [{ text: readableName }],
      };
    }
    return node;
  });
};

const updateTableQuestionCaption = (
  editor: Editor,
  nodeKey: number,
) => {
  editor.children = findNodesAndUpdate(editor.children, (node) => {
    if (node.key === nodeKey) {
      if (!node.children?.length) return node;
      const child = node.children[0];

      return {
        ...node,
        value: child.text,
      };
    }
    return node;
  });
};

const updateTableQuestionCaptionIfNeeded = (editor: Editor, parentNode: ICustomElement) => {
  if (parentNode.type === TABLE_QUESTION_CAPTION) {
    updateTableQuestionCaption(editor, parentNode.key ?? 0);
  }
};

const updateEditorCellsIfNeeded = (editor: Editor, parentNode: ICustomElement, path: Path) => {
  /**
   * We get path for table(select) 2 and 3(from the end)
   * it is the position of our cursor inside the table.
   * [path.length - 3] - this is current row;
   * [path.length - 2] - this is current column;
   */
  const rowIndex = path[path.length - 3];
  const colIndex = path[path.length - 2];

  /**
   * Here we choose the current index and check if that index equal
   * to 1 to understand whether this is first col or row or not
   */
  const isCol = colIndex === 1;
  const isRow = rowIndex === 1;

  if (parentNode.type === TABLE_QUESTION_CELL && (isCol || isRow)) {
    const cells: ICustomElement[] | undefined = rowIndex === 1
      ? findTableQuestionCol(editor, colIndex)
      : findTableQuestionRow(editor);

    if (!cells) return;

    const titleCell = cells[1];
    const titleCellTextNode = titleCell.children[0];
    const cellName = titleCellTextNode.text || '';

    const fieldNodeKeys = getFieldNodeKeys(cells);

    const updateNameType = isRow ? UPDATE_FIELD_NAME_TYPE.COL : UPDATE_FIELD_NAME_TYPE.ROW;
    updateEditorCells(editor, fieldNodeKeys, cellName, updateNameType);
  }
};

const deleteIfNeeded = (
  editor: Editor,
  deleteCallback: () => void,
) => {
  deleteCallback();

  if (!getIsTableQuestion(editor)) return;
  const { selection } = editor;
  if (!selection) return;

  const path = selection.anchor.path;
  const pathLength = path.length;

  const [node] = Editor.node(editor, path.slice(0, pathLength - 1));
  const parentNode = node as ICustomElement;

  updateTableQuestionCaptionIfNeeded(editor, parentNode);
};

const withTableQuestion = (editor: Editor): Editor => {
  const { insertText, deleteBackward, deleteForward, deleteFragment } = editor;

  editor.insertText = (text) => {
    insertText(text);
    if (!getIsTableQuestion(editor)) return;

    const { selection } = editor;
    if (!selection) return;

    const path = selection.anchor.path;
    const pathLength = path.length;

    const [node] = Editor.node(editor, path.slice(0, pathLength - 1));
    const parentNode = node as ICustomElement;

    updateTableQuestionCaptionIfNeeded(editor, parentNode);
    updateEditorCellsIfNeeded(editor, parentNode, path);
  };

  editor.deleteForward = (char) => deleteIfNeeded(editor, () => deleteForward(char));
  editor.deleteBackward = (char) => deleteIfNeeded(editor, () => deleteBackward(char));
  editor.deleteFragment = (direction) => deleteIfNeeded(editor, () => deleteFragment(direction));

  return editor;
};

export default withTableQuestion;