/* eslint-disable */
import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Form, FormFeedback, FormGroup, Input } from 'reactstrap';

import { Descendant } from 'slate';

import Editor from 'components/Editor';
import Footer from 'components/Footer';
import { INITIAL_EDITOR_VALUE } from 'constants/editors';
import { SECTION_NAME_LABEL } from 'constants/general';
import { useRolePermission } from 'hooks/useRolePermission';
import { IStateList } from 'types/Metadata';
import { IEditSectionProps } from 'types/Sections';
import { IErrorField } from 'types/validation';
import { IApiVars } from 'utils/createApiVars';
import setDefaultPageValuesHelper from 'utils/reduxHelpers';
import { validationForm } from 'utils/validation';

const EditSection: React.FC<IEditSectionProps> = (props) => {
  const { states, sectionDetails, getSectionDetails, setSectionDetails, editSection, match } = props;
  const dispatch = useDispatch();

  const [formErrors, setFormErrors] = useState<IErrorField>({
    section_name: null,
  });
  const [api_vars, setApiVars] = useState<IApiVars[] | null>(null);
  const [sectionName, setSectionName] = useState<string>('');
  const [sectionContent, setSectionContent] = useState<Descendant[]>(INITIAL_EDITOR_VALUE);
  const [sectionStates, setSectionStates] = useState<IStateList[]>([]);
  const { permissionUsingStates } = useRolePermission();

  useEffect(() => {
    getSectionDetails(match.params.id);
    return () => {
      setDefaultPageValuesHelper(dispatch);
      setSectionDetails(null);
    };
  }, [getSectionDetails, setSectionDetails, match.params.id]);

  useEffect(() => {
    if (!sectionDetails) return;
    setSectionName(sectionDetails.name);
    setSectionStates(
      sectionDetails.states.map((state: any) => ({
        label: state.abbreviation,
        value: state.id,
      }))
    );
    if (!sectionDetails?.content_json) return;
    setSectionContent(sectionDetails.content_json);
  }, [sectionDetails]);

  const validateSectionName = (sectionName: string) => {
    const validateResult = validationForm([
      { titleField: SECTION_NAME_LABEL, nameField: 'section_name', valueField: sectionName, required: true }
    ]);
    setFormErrors((prev) => ({ ...prev, ...validateResult.validationFields }));
    return validateResult;
  }

  const onChangeSectionName: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    validateSectionName(target.value);
    setSectionName(target.value);
  }

  const onChangeSectionContent = (content: Descendant[]) => {
    setSectionContent(content);
  };

  const onChangeApiVars = (api_vars: IApiVars[]) => {
    setApiVars(api_vars);
  };

  const saveEditedSection = () => {
    const validateResult = validateSectionName(sectionName);
    if (validateResult.isError) return;

    const editedSection = {
      name: sectionName.trim(),
      description: '',
      content_json: sectionContent,
      states: sectionStates.map((state) => ({ id: state.value })),
    };
    editSection({ section: editedSection, id: match.params.id });
  };

  const labelArray = [sectionDetails?.creation_date, sectionDetails?.modification_date];

  return (
    <div className="w-100 mx-auto">
      <Form>
        <FormGroup className="mb-4">
          <Input
            name="section_name"
            value={sectionName}
            onChange={onChangeSectionName}
            required
            invalid={!!formErrors.section_name}
          />
          <FormFeedback>{formErrors.section_name}</FormFeedback>
        </FormGroup>
        <Editor
          visibleEditorDevTools
          content={sectionContent}
          viewFormsTitle={sectionName}
          onChange={onChangeSectionContent}
          onChangeApiVars={onChangeApiVars}
          showDescription={false}
        />
      </Form>
      <Footer
        multipleState={true}
        to={'/sections'}
        saveDoc={saveEditedSection}
        states={permissionUsingStates ? states : undefined}
        selectState={setSectionStates}
        selectedStateList={sectionStates}
        labelArray={labelArray}
      />
    </div>
  );
};

export default EditSection;