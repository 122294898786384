import { FC, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Swiper } from 'swiper/react';

import { setPublicPageTotalIndexSlides } from 'store/actions/publicPages';
import { RootStateType } from 'store/reducers';
import { EffectFade, Pagination } from 'swiper';
import formSwiperAutoScroll from 'utils/PublicPage/swiperAutoScroll';

type IFormsSliderProps = {
  sliderDisabled?: boolean;
  isFormView?: boolean;
};

const FormsSlider: FC<IFormsSliderProps> = ({
  children,
  sliderDisabled = false,
  isFormView = false,
}) => {
  const dispatch = useDispatch();
  const currentDocument = useSelector((state: RootStateType) => state.publicPages.currentDocument);
  const isPublicPage = currentDocument !== null; // can be also collection for manager
  const currentSlideIndex = currentDocument?.currentSlideIndex || 1;
  const totalIndexSlides = currentDocument?.totalIndexSlides || 0;

  useEffect(() => {
    if (Array.isArray(children)) {
      if (isFormView) {
        const slidesArray = children.filter((child) => child !== null);
        if (totalIndexSlides !== slidesArray.length) {
          dispatch(setPublicPageTotalIndexSlides(slidesArray.length));
        }
      }
    }
  }, [children, isFormView]);

  if (!children) {
    return (
      <>This slider is empty. Please pass some children element</>
    );
  }

  if (sliderDisabled) {
    return (
      <div className="sections-swiper">
        {children}
      </div>
    );
  }

  return (
    <Swiper
      id="formSwiperScrollable"
      className={isFormView ? 'form-view-swiper' : 'sections-swiper'}
      data-scroll-wrapper
      modules={[Pagination, EffectFade]}
      autoHeight={isPublicPage}
      loop={false}
      noSwiping={isPublicPage}
      noSwipingClass="swiper-slide"
      observer
      observeParents
      watchOverflow={false}
      simulateTouch={false}
      effect="fade"
      fadeEffect={{ crossFade: true }}
      slidesPerView={1}
      spaceBetween={50}
      pagination={
        !isFormView
          ? {
            clickable: true,
            renderBullet: (index: number, className: string) => (
              `<span class="${className}">${(index + 1)}</span>`
            ),
          }
          : false
      }
      onInit={(swiper) => {
        if (isFormView) swiper.slideTo(currentSlideIndex);
      }}
      onAfterInit={(swiper) => {
        const intervalID = setInterval((swiper) => {
          const templateInfo: HTMLElement | null = document.querySelector('[data-first-slide="true"]');
          if (templateInfo) {
            if (swiper.height < templateInfo.offsetHeight) {
              swiper.update();
            }
          }
          clearInterval(intervalID);
        }, 500, swiper);
      }}
      onSlideChange={() => {
        formSwiperAutoScroll();
      }}
    >
      {children}
    </Swiper>
  );
};

export default FormsSlider;