import { FC, memo } from 'react';

import { FormGroup, Input, Label } from 'reactstrap';

import FieldWithButtonWrapper from 'components/Editor/EditorDevTools/FieldWithButtonWrapper';
import { IRenderField } from 'types/Properties';
import { createNameConstant } from 'utils/createNameConstant';

import 'scss/components/_propertyTab.scss';

const SelectOptionOtherCheckbox: FC<IRenderField<HTMLInputElement>> = ({
  type,
  label,
  checked,
  disabled = false,
  onChangeHandler,
}) => {
  const propertyName = createNameConstant(label);

  return (
    <FormGroup
      check
      className="form-group flex-column-reverse"
    >
      <Label check for={propertyName} className="mb-0">{label}</Label>
      <FieldWithButtonWrapper withButton={false}>
        <Input
          id={propertyName}
          type={type}
          checked={checked}
          disabled={disabled}
          onChange={onChangeHandler}
        />
      </FieldWithButtonWrapper>
    </FormGroup>
  );
};

export default memo(SelectOptionOtherCheckbox);