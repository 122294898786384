import { Editor, Element, Range, Transforms } from 'slate';

import { TEXT_SIZE } from 'constants/editors';
import { ICustomElementWithTextSize } from 'types/Editor';

import isListTypeActive from '../lib/isListTypeActive';
import type { ListsEditor, ListType } from '../types';
import unwrapList from './unwrapList';

/**
 * All nodes matching `isConvertibleToListTextNode()` in the current selection
 * will be converted to list items and then wrapped in lists.
 *
 * @see ListsEditor.isConvertibleToListTextNode()
 */
export const wrapInList = (editor: ListsEditor, listType: ListType, listProps = {}): void => {
  if (!editor.selection) {
    return;
  }

  const nonListEntries = Array.from(
    Editor.nodes(editor, {
      at: editor.selection,
      match: (node) => (
        Element.isElement(node)
        && !editor.isListNode(node)
        && !editor.isListItemNode(node)
        && !editor.isListItemTextNode(node)
        && (
          editor.isConvertibleToListTextNode(node)
          || editor.isConvertibleToListBlockNode(node)
        )
      ),
    }),
  ).filter(([node, path]) => {
    // do not wrap if selected only whitespaces in node
    const startPosition = (Range.isRange(editor.selection) && Range.start(editor.selection).offset) || 0;
    let selectedText = Editor.string(editor, path).trim();

    if (editor.isConvertibleToListTextNode(node) && node.children && node.children.length > 1) {
      // get children and parse only selected children text/field in paragraph
      const startPath = (Range.isRange(editor.selection) && Range.start(editor.selection).path) || [];
      const focusedChildren = [...startPath].reduce((element: unknown, pathPosition) => {
        if (!element) return editor.children[pathPosition];
        return Element.isElement(element) && element.children[pathPosition];
      }, null);
      if (focusedChildren && Element.isElement(focusedChildren)) {
        selectedText = (focusedChildren.text || '').trim();
      }
    }

    return selectedText.length && selectedText.slice(startPosition).length;
  });

  if (!nonListEntries?.length) {
    const newListElementProps = (listProps as Element);
    const isActive = isListTypeActive(editor, newListElementProps?.type || '', newListElementProps?.listStyle || '');
    if (isActive) {
      unwrapList(editor);
      return;
    }
  }

  const refs = nonListEntries.map(([node, path]) => ({
    node,
    ref: Editor.pathRef(editor, path),
  }));

  refs.forEach(({ node, ref }) => {
    const path = ref.current;
    const item = node as ICustomElementWithTextSize;
    const listItemProps = {
      ...(item[TEXT_SIZE] && { [TEXT_SIZE]: item[TEXT_SIZE] }),
    };

    if (path) {
      Editor.withoutNormalizing(editor, () => {
        Transforms.setNodes(editor, editor.createListItemTextNode(), { at: path });
        Transforms.wrapNodes(editor, editor.createListItemNode(listItemProps), { at: path });
        Transforms.wrapNodes(editor, editor.createListNode(listType, listProps), { at: path });
      });
    }
    ref.unref();
  });
};

export default wrapInList;