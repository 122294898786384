import { memo, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { DEFAULT_LANGUAGE, GOOGLE_TRANSLATE_ELEMENT, LANGUAGES } from 'constants/googleTranslate';

import { TABLET_SCREEN_WIDTH } from 'constants/general';
import useScreenSize from 'hooks/useScreenSize';
// import TranslateIcon from 'svg/TranslateIcon';
import shouldShowBrandingInRoutes from 'utils/CompanyBranding/shouldShowBrandingInRoutes';
import { changeLanguage, setGoogleTranslateCookieToNone } from 'utils/GoogleTranslate/googleTranslateHelpers';

const GoogleTranslateMenu = () => {
  const location = useLocation();
  const [screenWidth] = useScreenSize();
  const isAvailableTranslate = shouldShowBrandingInRoutes(location.pathname);

  const [selectedLanguage, setSelectedLanguage] = useState<string>(DEFAULT_LANGUAGE);

  useEffect(() => {
    const googleTranslateElement = document.getElementById(GOOGLE_TRANSLATE_ELEMENT) as HTMLElement;
    const select = googleTranslateElement?.getElementsByTagName('select')[0];
    if (select) {
      const language = localStorage.getItem('selectedLanguage') || DEFAULT_LANGUAGE;
      setGoogleTranslateCookieToNone(language);
      setSelectedLanguage(language);
      changeLanguage(language);
    }
  }, []);

  useEffect(() => {
    if (!isAvailableTranslate) {
      setSelectedLanguage(DEFAULT_LANGUAGE);
    } else {
      setSelectedLanguage(localStorage.getItem('selectedLanguage') ?? DEFAULT_LANGUAGE);
    }
  }, [location.pathname]);

  const handleLanguageChange = ({ target }: SelectChangeEvent) => {
    const { value } = target;
    setGoogleTranslateCookieToNone(value);
    localStorage.setItem('selectedLanguage', value);
    setSelectedLanguage(value);
  };

  useEffect(() => {
    if (selectedLanguage) {
      changeLanguage(selectedLanguage);
    }
  }, [selectedLanguage]);

  return (
    <label
      className="google-translate"
      style={{
        ...(!isAvailableTranslate && { display: 'none' }),
      }}
    >
      {/* <TranslateIcon /> */}
      <FormControl fullWidth>
        {/* <InputLabel id="google-translate-select-label notranslate">Change language</InputLabel> */}
        <Select
          labelId="google-translate-select-label"
          id="google-translate-select"
          value={screenWidth < TABLET_SCREEN_WIDTH ? '' : selectedLanguage}
          label="Change language"
          className="notranslate"
          onChange={handleLanguageChange}
        >
          {LANGUAGES.map((language) => (
            <MenuItem
              key={language.value}
              value={language.value}
              className="notranslate gt-menu-item"
            >
              {language.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </label>
  );
};

export default memo(GoogleTranslateMenu);