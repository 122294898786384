import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Descendant } from 'slate';

import DocumentNavigator from 'components/DocumentNavigator';
import Editor from 'components/Editor';
import EditorContainer from 'components/Editor/EditorContainer';
import EditorWrapper from 'components/Editor/EditorWrapper';
import EditorHeader from 'components/EditorHeader/EditorHeader';
import ModalContentWindow from 'components/Modals/ModalContentWindow';
import ShareLinkModal from 'components/Modals/ModalWrapper';
import SearchFieldAndContent from 'components/Sidebar/SearchFieldAndContent';
import SectionsOutline from 'components/Sidebar/SectionsOutline';
import SortableList from 'components/SortableList';
import { INITIAL_EDITOR_VALUE, MANAGER_ASSIGNMENT } from 'constants/editors';
import { DOCUMENT_NAME_LABEL, RedirectTypeEnum, URL_PUBLIC_TEMPLATE } from 'constants/general';
import { SOMETHING_WENT_WRONG } from 'constants/generalErrors';
import useSetDefaultReminderSettings from 'hooks/pageSettings/useSetDefaultReminderSettings';
import { useRolePermission } from 'hooks/useRolePermission';
import { setSelectedTableQuestion } from 'store/actions/editorSlate';
import {
  createTemplatePublicLink,
  deleteTemplateLinkFromStore,
  setCreatedDocumentId,
} from 'store/actions/publicPages';
import { clearSections, updateTemplateSections } from 'store/actions/template';
import {
  editSections,
  editTemplate,
  getTemplateDetails,
  setLastCreatedSection,
  setTemplateDetails,
} from 'store/actions/userData';
import { RootStateType } from 'store/reducers';
import { IMetadataId } from 'types/Metadata';
import { MatchParams } from 'types/Route';
import { IDocumentSection, ISection } from 'types/Sections';
import { IErrorField } from 'types/validation';
import { trimHTMLValue } from 'utils/descriptionHelper';
import { filterFormFieldsFromContent } from 'utils/editorFieldHelpers';
import { addSectionsDocument, drawSectionsInEditorContent, parseSections } from 'utils/editorHelpers';
import { getIsPDFDocument } from 'utils/PublicPage/documentTypeChecker';
import setDefaultPageValuesHelper from 'utils/reduxHelpers';
import { getEditorPageLink } from 'utils/routeHelpers';
import { errorSectionsName, validationForm } from 'utils/validation';

const EditDocument = () => {
  const { id }: MatchParams = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { templateDetails, lastCreatedSection } = useSelector((state: RootStateType) => state.user);
  const { templatePublicLink } = useSelector((state: RootStateType) => state.publicPages);
  const { isLoading } = useSelector((state: RootStateType) => state.errorLoading);
  const { sections } = useSelector((state: RootStateType) => state.template);
  const { assignments } = useSelector((state: RootStateType) => state.editorSlate);

  const { permissionUsingSections, permissionUsingSectionsLight } = useRolePermission();

  const [sectionsAddModal, setSectionsAddModal] = useState<boolean>(false);
  const [previewMode, setPreviewMode] = useState<boolean>(false);
  const [templateName, setTemplateName] = useState<string>('');
  const [templateTitle, setTemplateTitle] = useState<string>('');
  const [templateDescription, setTemplateDescription] = useState<string>('');
  const [formErrors, setFormErrors] = useState<IErrorField>({ template_name: null, title: null });
  const [templateState, setTemplateState] = useState<IMetadataId[]>([{ id: 1 }]);
  const [templateDoctype, setTemplateDoctype] = useState<IMetadataId | null>(null);

  const [templateSections, setTemplateSections] = useState<ISection[]>([]);
  const [templateContent, setTemplateContent] = useState<Descendant[]>(INITIAL_EDITOR_VALUE);
  const [isShowManagerButton, setIsShowManagerButton] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const [showShareLinkModal, setShowShareLinkModal] = useState<boolean>(false);

  const [searchString, setSearchString] = useState<string>('');

  // Set default reminders settings
  useSetDefaultReminderSettings();

  useEffect(() => {
    if (id) {
      dispatch(getTemplateDetails(id));
    }
    return () => {
      setDefaultPageValuesHelper(dispatch, permissionUsingSectionsLight);
      dispatch(setTemplateDetails(null));
      dispatch(setLastCreatedSection(null));
      dispatch(setCreatedDocumentId(0));
      dispatch(setSelectedTableQuestion(null));
      dispatch(clearSections());
    };
  }, [id]);

  const updateSectionAndContent = (sections: ISection[]): void => {
    setTemplateSections(sections);
    setTemplateContent(addSectionsDocument(sections, !permissionUsingSectionsLight));
  };

  useEffect(() => {
    if (!templateDetails) return;
    if (getIsPDFDocument(templateDetails.type)) {
      history.push(getEditorPageLink(id, templateDetails.type));
      return;
    }

    setTemplateName(templateDetails.name);
    setTemplateTitle(templateDetails.template_title);
    setTemplateDescription(templateDetails.description);
    setTemplateState([{ id: templateDetails.states ? templateDetails.states[0].id : 1 }]);
    setTemplateDoctype({ id: templateDetails.doctype.id });

    const newSections = templateDetails.sections.map((section: IDocumentSection) => ({
      name: section.section.name,
      description: section.section.description,
      key: section.section.id,
      section_id: section.section.id,
      position: section.position,
    }));

    const sections = templateDetails.sections
      .sort((a: IDocumentSection, b: IDocumentSection) => a.position - b.position)
      .map((sect: IDocumentSection) => ({ ...sect.section }));

    updateSectionAndContent(sections);
    dispatch(updateTemplateSections(newSections));
  }, [
    templateDetails?.type,
    templateDetails?.name,
    templateDetails?.template_title,
    templateDetails?.description,
    templateDetails?.states,
    templateDetails?.doctype.id,
    templateDetails?.sections,
    dispatch,
  ]);

  useEffect(() => {
    if (!lastCreatedSection) return;
    updateSectionAndContent([...templateSections, lastCreatedSection]);
  }, [lastCreatedSection]);

  useEffect(() => {
    if (
      !permissionUsingSectionsLight
      && sections.length === 0
      && INITIAL_EDITOR_VALUE !== templateContent
    ) {
      return setTemplateContent(INITIAL_EDITOR_VALUE);
    }
    setTemplateContent(drawSectionsInEditorContent(sections, templateContent));
  }, [sections]);

  useEffect(() => {
    setTemplateSections(parseSections(templateContent, templateSections));
    setIsShowManagerButton(!!filterFormFieldsFromContent(templateContent, MANAGER_ASSIGNMENT).length);
  }, [templateContent]);

  const addSection = (section: ISection) => {
    updateSectionAndContent([...templateSections, section]);
  };

  const removeSection = (id: number) => {
    updateSectionAndContent(templateSections.filter((sect) => sect.id !== id));
  };

  const onUpdateOrder = (sections: ISection[]) => {
    updateSectionAndContent(sections);
  };

  const validateFieldsNameTitle = (documentName: string | null) => {
    const validateArray = [];
    if (documentName !== null) {
      validateArray.push({
        titleField: DOCUMENT_NAME_LABEL, nameField: 'template_name', valueField: templateName, required: true,
      });
    }
    const validateResult = validationForm(validateArray);
    setFormErrors((prev) => ({ ...prev, ...validateResult.validationFields }));
    return validateResult;
  };

  const onChangeTemplateName: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    setTemplateName(target.value);
  };

  const getEditedTemplateData = () => {
    const validateNameResult = validateFieldsNameTitle(templateName);

    if (validateNameResult.isError) return null;
    if (errorSectionsName(sections)) return null;
    if (!templateDoctype) return null;

    const updatedSections = permissionUsingSectionsLight
      ? sections.map((section) => {
        const sectionInEditor = templateContent.find((el) => el.key === section.key);
        return {
          name: section.name.trim(),
          section_id: section.section_id || null,
          content_json: sectionInEditor?.children || [],
          description: trimHTMLValue(section.description),
          position: section.position,
        };
      })
      : {
        sections: templateSections.map((section) => ({
          id: section.id,
          name: section.name.trim(),
          content_json: section.content_json,
          description: '',
        })),
      };

    if (!permissionUsingSectionsLight) {
      dispatch(editSections(updatedSections));
    }

    return {
      description: trimHTMLValue(templateDescription),
      template_title: templateTitle.trim(),
      name: templateName.trim(),
      doctype_id: templateDoctype.id,
      states: templateState,
      api_vars: null,
      sections: permissionUsingSectionsLight
        ? updatedSections
        : templateSections.map((section, index) => ({
          section_id: section.id,
          position: index,
        })),
      assignments,
    };
  };

  const saveTemplateHandler = () => {
    const data = getEditedTemplateData();
    if (data !== null) {
      dispatch(editTemplate({
        id,
        template: data,
      }));
    }
    return data;
  };

  const handlerShareLinkModal = () => {
    const save = saveTemplateHandler();
    if (!save) {
      setShowErrorModal(true);
    } else {
      dispatch(createTemplatePublicLink({ template_id: templateDetails.id, assignments }));
      setShowShareLinkModal(true);
    }
  };

  const openManagerPageHandler = () => {
    const data = getEditedTemplateData();
    if (data !== null) {
      dispatch(editTemplate({
        id,
        template: data,
        path: `/manager-template/${templateDetails.id}`,
      }));
    }
  };

  const saveTemplateController = (
    redirectType: RedirectTypeEnum | undefined = undefined,
    redirectPath: string | undefined = undefined,
  ) => {
    saveTemplateHandler();
    if (redirectType && redirectPath) {
      history.push(redirectPath);
    }
  };

  return (
    <>
      <EditorWrapper>
        <EditorHeader
          documentName={templateName}
          onChangeDocumentName={onChangeTemplateName}
          errorText={formErrors.template_name}
          saveDocument={(
            redirectType: RedirectTypeEnum | undefined,
            redirectPath: string | undefined,
          ) => saveTemplateController(redirectType, redirectPath)}
          previewMode={previewMode}
          setPreviewMode={setPreviewMode}
          description={templateDescription}
          setDescription={setTemplateDescription}
          showShareLinkModal={showShareLinkModal}
          handlerShareLinkModal={handlerShareLinkModal}
          openManagerPageHandler={isShowManagerButton ? openManagerPageHandler : undefined}
        />
        <EditorContainer>
          <DocumentNavigator
            sections={templateSections}
            addSection={addSection}
            sectionsAddModal={sectionsAddModal}
            setSectionsAddModal={setSectionsAddModal}
            addTemplate={() => null}
            renderChildrenUnderSidebar={(
              <SearchFieldAndContent
                content={templateContent}
                setContent={setTemplateContent}
                setString={setSearchString}
                showSearch
              />
            )}
          >
            {
              permissionUsingSectionsLight
                ? (<SectionsOutline />)
                : (
                  <SortableList
                    showModal={setSectionsAddModal}
                    sections={templateSections}
                    onUpdateOrder={onUpdateOrder}
                    removeSection={removeSection}
                  />
                )
            }
          </DocumentNavigator>
          <Editor
            search={searchString}
            content={templateContent}
            onChange={setTemplateContent}
            previewMode={previewMode}
            styledSections={permissionUsingSections || permissionUsingSectionsLight}
          />
        </EditorContainer>
      </EditorWrapper>
      <ShareLinkModal
        publicRoute={URL_PUBLIC_TEMPLATE}
        result={templatePublicLink.assignments}
        resourceGuid={templatePublicLink.origin}
        showModal={!isLoading && showShareLinkModal}
        onCloseModal={setShowShareLinkModal}
        deleteLinkFromStore={() => dispatch(deleteTemplateLinkFromStore())}
        showEmbedScript
      />
      <ModalContentWindow
        showModal={Boolean(showErrorModal)}
        onCloseModal={setShowErrorModal}
        titleText={SOMETHING_WENT_WRONG}
        showDefaultText={false}
      >
        <div>Please fill out all required fields.</div>
      </ModalContentWindow>
    </>
  );
};

export default EditDocument;