import React, { ChangeEventHandler } from 'react';

import { Element as SlateElement, Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useReadOnly, useSlateStatic } from 'slate-react';

import { CHECK_LIST_ITEM } from 'constants/editors';

export interface IRenderElementProps extends RenderElementProps {
  readOnly?: boolean;
}

const CheckListItemElement: React.FC<IRenderElementProps> = (props) => {
  const {
    element,
    children,
    attributes,
    readOnly = false,
  } = props;
  const editor = useSlateStatic();
  const readOnlyText = useReadOnly();
  const { checked } = element;

  const onChangeCheckboxField: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!readOnly) {
      const path = ReactEditor.findPath(editor, element);
      const newProperties: Partial<SlateElement> = {
        checked: event.target.checked,
      };
      Transforms.setNodes(editor, newProperties, { at: path });
    }
  };

  return (
    <div {...attributes} className="d-flex flex-row">
      <span contentEditable={false} className="me-1">
        <input
          type="checkbox"
          checked={checked}
          disabled={readOnly}
          onChange={onChangeCheckboxField}
          tabIndex={0}
        />
      </span>
      <span
        data-element-type={CHECK_LIST_ITEM}
        contentEditable={!readOnlyText}
        suppressContentEditableWarning
        className={`check-list-editable ${checked ? 'check-list-checked' : ''}`}
      >
        {children}
      </span>
    </div>
  );
};

export default CheckListItemElement;