import { toast } from 'react-toastify';
import { put, select, takeLatest } from 'redux-saga/effects';

import { COLLECTION_TYPE, DOCUMENT_TYPE } from 'constants/general';
import { SUCCESS_CODE } from 'constants/generalErrors';
import {
  deleteMondayComAccessTokenFromDB,
  getAllMondayComBoards,
  saveMondayComAccessTokenToDB,
  saveMondayComIntegrationSettings,
  sendEmaiWithPasswordlessTokenMultiApplications,
  sendUserEmailAndCheckMondayComRecords,
} from 'services/api/mondayComIntegration';
import {
  CHECK_IF_USER_ON_PUBLIC_PAGE_HAS_MONDAYCOM_RECORDS,
  DELETE_MONDAY_COM_ACCESS_TOKEN,
  GET_MONDAY_COM_BOARDS,
  SAVE_MONDAY_COM_ACCESS_TOKEN,
  SAVE_MONDAY_COM_INTEGRATION_SETTINGS,
  SEND_EMAIL_IN_MONDAY_COM_MULTI_APPLICATIONS_FLOW,
  SET_MONDAY_COM_ACCESS_TOKEN,
  SET_MONDAY_COM_BOARDS,
  SET_MONDAY_COM_INTEGRATION_SETTINGS_INTO_COLLECTION,
  SET_MONDAY_COM_INTEGRATION_SETTINGS_INTO_DOCUMENT,
} from 'store/actions/actionTypes';
import {
  setShowSimilarRecordsExistModalWindow,
  setWaitToPasswordlessLinkResponse,
} from 'store/actions/mondayComIntegration';
import { RootStateType } from 'store/reducers';
import { safe } from 'store/sagas/errorHandlerSaga';
import { IResponseOfRequest } from 'types/Api';
import { MondayComIntegrationObjectType } from 'types/mondayCom/mondayComBoard';
import { PIIDataType } from 'types/userProfile';
import { getUserEmailFromPII } from 'utils/PIIAndGrouping/groupedAndPIIFieldsHelper';

const saveMondayComAccessTokenSaga = function* ({ payload }: Record<string, string>) {
  const response: IResponseOfRequest<Record<string, string>> = yield saveMondayComAccessTokenToDB({ token: payload });
  if (response && response.status === SUCCESS_CODE) {
    if (response.data.token) {
      toast.success(response.data.message);
      yield put({ type: SET_MONDAY_COM_ACCESS_TOKEN, payload: response.data.token });
    } else {
      toast.warn(response.data.message);
    }
  }
};

const deleteMondayComAccessTokenSaga = function* () {
  const response: IResponseOfRequest<Record<string, string>> = yield deleteMondayComAccessTokenFromDB();
  if (response && response.status === SUCCESS_CODE) {
    toast.success(response.data.message);
    yield put({ type: SET_MONDAY_COM_ACCESS_TOKEN, payload: null });
  }
};

const getMondayComBoardsSaga = function* () {
  const response: IResponseOfRequest<Record<string, string>> = yield getAllMondayComBoards();
  if (response && response.status === SUCCESS_CODE) {
    yield put({ type: SET_MONDAY_COM_BOARDS, payload: response.data });
  }
};

const saveMondayComIntegrationSettingsSaga = function* (
  {
    payload,
  }: {
    payload: {
      resourceId: number,
      board: MondayComIntegrationObjectType,
      resourceType: string,
    }},
) {
  const response: IResponseOfRequest<Record<string, string>> = yield saveMondayComIntegrationSettings(
    payload.resourceId,
    payload.board,
    payload.resourceType,
  );
  if (response && response.status === SUCCESS_CODE) {
    toast.success(response.data.message);
    if (payload.resourceType === DOCUMENT_TYPE) {
      yield put({ type: SET_MONDAY_COM_INTEGRATION_SETTINGS_INTO_DOCUMENT, payload: payload.board });
    } else {
      yield put({ type: SET_MONDAY_COM_INTEGRATION_SETTINGS_INTO_COLLECTION, payload: payload.board });
    }
  }
};

const checkIfUserOnPublicPageHasMondayComRecords = function* () {
  yield put(setWaitToPasswordlessLinkResponse(true));
  const piiData: PIIDataType[] = yield select((state: RootStateType) => state.profile.piiData);
  const userEmail: string = getUserEmailFromPII(piiData);
  const { data, mondayComBoard } = yield select((state: RootStateType) => state.publicPages);
  const resourceType = data.type === COLLECTION_TYPE ? COLLECTION_TYPE : DOCUMENT_TYPE;
  const response: IResponseOfRequest<{ response: boolean }> = yield sendUserEmailAndCheckMondayComRecords(
    userEmail,
    data.id,
    mondayComBoard,
    resourceType,
  );
  if (response && response.status === SUCCESS_CODE && response.data?.response) {
    yield put(setShowSimilarRecordsExistModalWindow(true));
    yield put(setWaitToPasswordlessLinkResponse(false));
  }
};

const sendEmailWithPasswordlessLinkMondayMultiApplications = function* () {
  const piiData: PIIDataType[] = yield select((state: RootStateType) => state.profile.piiData);
  const {
    startLink,
    documentUUID,
  } = yield select((state: RootStateType) => state.publicPages);
  const { sender: documentSender, id: documentId, type: documentType }: {
    sender: Record<string, string>,
    id: number,
    type: string,
  } = yield select((state: RootStateType) => state.publicPages.data);
  const resourceType = documentType === COLLECTION_TYPE ? COLLECTION_TYPE : DOCUMENT_TYPE;
  const userEmail: string = getUserEmailFromPII(piiData);
  const response: IResponseOfRequest<{ response: boolean }> = yield sendEmaiWithPasswordlessTokenMultiApplications(
    userEmail,
    startLink,
    documentSender.email,
    documentId,
    resourceType,
    documentUUID,
  );
  if (response && response.status === SUCCESS_CODE && response.data?.response) {
    yield put(setWaitToPasswordlessLinkResponse(false));
  }
};

export default [
  takeLatest(SAVE_MONDAY_COM_ACCESS_TOKEN, safe(saveMondayComAccessTokenSaga, false, true)),
  takeLatest(DELETE_MONDAY_COM_ACCESS_TOKEN, safe(deleteMondayComAccessTokenSaga, false, true)),
  takeLatest(GET_MONDAY_COM_BOARDS, safe(getMondayComBoardsSaga, false, true)),
  takeLatest(SAVE_MONDAY_COM_INTEGRATION_SETTINGS, safe(saveMondayComIntegrationSettingsSaga, false, true)),
  takeLatest(
    CHECK_IF_USER_ON_PUBLIC_PAGE_HAS_MONDAYCOM_RECORDS,
    safe(checkIfUserOnPublicPageHasMondayComRecords, false, true),
  ),
  takeLatest(
    SEND_EMAIL_IN_MONDAY_COM_MULTI_APPLICATIONS_FLOW,
    safe(sendEmailWithPasswordlessLinkMondayMultiApplications, false, true),
  ),
];