import { FC } from 'react';

import AccountSettingsWrapper from 'components/AccountSettings/AccountSettingsWrapper';
import ReferralLinkSection from 'components/AccountSettings/ReferralLinkSection';
import SettingsLayout from 'components/AccountSettings/SettingsLayout';

const ReferralLink: FC = () => (
  <AccountSettingsWrapper>
    <SettingsLayout title="Referral Link">
      <ReferralLinkSection />
    </SettingsLayout>
  </AccountSettingsWrapper>
);

export default ReferralLink;