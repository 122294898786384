const PdfIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_831_14778)">
      <path
        d="M48.7229 0.993652H5.61068V12.9162H4.61762C1.87963 12.9162 -0.347656 15.1446 -0.347656
        17.884V35.7678C-0.347656 38.5072 1.87963 40.7356 4.61762 40.7356H5.61068V62.5937H61.2218V13.4987L48.7229
        0.993652ZM13.5551 40.7356V60.6066H11.569V40.7356H13.5551ZM15.5412 40.7356H34.4093C37.1473 40.7356
        39.3746 38.5072 39.3746 35.7678V25.8324C39.3746 23.093 37.1473 20.8646 34.4093
        20.8646H15.5412V2.98075H47.319V14.9033H59.2357V50.7222C56.2374 50.9635 54.0758 52.2533 51.9873
        53.5152C49.4502 55.0472 46.8265 56.6329 42.3537 56.6329C37.3982 56.6329 33.7213 55.3759 30.4779
        54.2675C27.9527 53.4039 25.7706 52.6587 23.4858 52.6587C20.1431 52.6587 17.2632 54.275 15.5413
        55.52L15.5412 40.7356ZM11.569 20.8646V2.98075H13.5551V20.8646H11.569ZM49.3051
        12.9162V4.38546L57.8317 12.9162H49.3051ZM9.5829 2.98075V20.8646H7.59679V2.98075H9.5829ZM4.61762
        14.9033H5.61068V20.8646H4.61762C2.97505 20.8646 1.63845 19.5274 1.63845 17.884C1.63845 16.2406
        2.97505 14.9033 4.61762 14.9033ZM1.63845 35.7678V21.8559C2.46881 22.4804 3.50041 22.8512
        4.61762 22.8512H34.4093C36.0519 22.8512 37.3885 24.1885 37.3885 25.8319V35.7673C37.3885 37.4107
        36.0519 38.748 34.4093 38.748H4.61762C2.97505 38.748
        1.63845 37.4107 1.63845 35.7673V35.7678ZM7.59679 40.7356H9.5829V60.6066H7.59679V40.7356ZM15.5412
        60.6066V58.0619C16.4917 57.2227 19.7139 54.6453 23.4857 54.6453C25.4411 54.6453 27.4779 55.3407
        29.8354 56.1475C33.0576 57.2484 37.0675 58.6195 42.3536 58.6195C47.3796 58.6195 50.3725 56.8111
        53.0139 55.2156C55.0231 54.0017 56.7924 52.9384 59.2356 52.7078V60.6064L15.5412 60.6066Z"
        fill="#2F3947"
      />
      <path
        d="M9.58355 24.8389H5.61133V36.7614H7.59744V32.7873H9.58355C11.7741 32.7873 13.5558 31.0047 13.5558
        28.8131C13.5558 26.6215 11.7741 24.8389 9.58355 24.8389ZM9.58355 30.8002H7.59744V26.826H9.58355C10.679 26.826
        11.5697 27.7171 11.5697 28.8131C11.5697 29.9091 10.679 30.8002 9.58355 30.8002Z"
        fill="#2F3947"
      />
      <path
        d="M19.5132 24.8389H15.541V36.7614H19.5132C22.7992 36.7614 25.4716 34.0877 25.4716 30.8002C25.4716 27.5126
        22.7992 24.8389 19.5132 24.8389ZM19.5132 34.7744H17.5271V26.826H19.5132C21.7037 26.826 23.4855 28.6086
        23.4855 30.8002C23.4855 32.9918 21.7037 34.7744 19.5132 34.7744Z"
        fill="#2F3947"
      />
      <path
        d="M27.4572 36.7614H29.4433V32.7873H33.4155V30.8002H29.4433V26.826H33.4155V24.8389H27.4572V36.7614Z"
        fill="#2F3947"
      />
      <path
        d="M29.444 42.7227H17.5273V50.671H29.444V42.7227ZM27.4579 48.6839H19.5135V44.7098H27.4579V48.6839Z"
        fill="#2F3947"
      />
      <path d="M31.4316 46.6968H57.2511V48.6839H31.4316V46.6968Z" fill="#2F3947" />
      <path d="M31.4316 43H57.2511V44.9871H31.4316V43Z" fill="#2F3947" />
      <path
        d="M45.3322 4.96777H29.4434V18.8775H45.3322V4.96777ZM43.3461 16.8904H31.4295V6.95487H43.3461V16.8904Z"
        fill="#2F3947"
      />
      <path d="M17.5273 8.94214H27.4579V10.9292H17.5273V8.94214Z" fill="#2F3947" />
      <path d="M17.5273 12.9163H27.4579V14.9034H17.5273V12.9163Z" fill="#2F3947" />
      <path d="M17.5273 16.8904H27.4579V18.8775H17.5273V16.8904Z" fill="#2F3947" />
      <path d="M41 26H56.8889V27.9871H41V26Z" fill="#2F3947" />
      <path d="M41 30.1614H52V32.1614H41V30.1614Z" fill="#2F3947" />
    </g>
    <defs>
      <clipPath id="clip0_831_14778">
        <rect width="64" height="64" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PdfIcon;