import { Dispatch as ReactDispatch, SetStateAction, useState } from 'react';

import { URL_ZEN_DESK_HELP } from 'constants/general';

const useHelpMenuButtonModalWindow = (setMenuAnchorEl: ReactDispatch<SetStateAction<null>>) => {
  const [isHelpButtonModalWindowOpen, setIsHelpButtonModalWindowOpen] = useState<boolean>(false);

  const onOpenHelpButtonHandler = () => {
    setIsHelpButtonModalWindowOpen(true);
    setMenuAnchorEl(null);
  };

  const onConfirmHelpButtonHandler = () => {
    window.open(URL_ZEN_DESK_HELP, '_blank');
    setIsHelpButtonModalWindowOpen(false);
  };

  const onCancelHelpButtonHandler = () => {
    setIsHelpButtonModalWindowOpen(false);
  };

  return {
    isHelpButtonModalWindowOpen,
    onOpenHelpButtonHandler,
    onConfirmHelpButtonHandler,
    onCancelHelpButtonHandler,
  };
};

export default useHelpMenuButtonModalWindow;