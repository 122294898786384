import {
  SET_ACTIVE_STRIPE_PRODUCT_ID,
  SET_ACTIVE_STRIPE_SUBSCRIPTION,
} from 'store/actions/actionTypes';
import { ISetActiveStripeProductIDPayload } from 'types/payment/Payment';
import { IStripeSubscription } from 'types/payment/Stripe';

export const setActiveStripeProductID = (payload: ISetActiveStripeProductIDPayload) => ({
  type: SET_ACTIVE_STRIPE_PRODUCT_ID,
  payload,
});

export const setActiveStripeSubscription = (payload: IStripeSubscription) => ({
  type: SET_ACTIVE_STRIPE_SUBSCRIPTION,
  payload,
});

export default undefined;