import { ChangeEvent, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { deleteMondayComAccessToken, saveMondayComAccessToken } from 'store/actions/mondayComIntegration';
import { RootStateType } from 'store/reducers';

const useMondayComAccessToken = () => {
  const dispatch = useDispatch();
  const [localToken, setLocalToken] = useState<string>('');
  const { monday_com_access_token: mondayComAccessToken } = useSelector(
    (state: RootStateType) => state.profile.profileInfo.company,
  );

  useEffect(() => {
    if (mondayComAccessToken) {
      setLocalToken(mondayComAccessToken.access_token);
    } else {
      setLocalToken('');
    }
  }, [mondayComAccessToken]);

  const onChangeTokenHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setLocalToken(event.target.value);
  };

  const saveToken = () => {
    dispatch(saveMondayComAccessToken(localToken));
  };

  const deleteToken = () => {
    dispatch(deleteMondayComAccessToken());
  };

  return {
    localToken,
    tokenFromStorage: mondayComAccessToken?.access_token,
    onChangeTokenHandler,
    saveToken,
    deleteToken,
  };
};

export default useMondayComAccessToken;