import {
  Editor as SlateEditor,
  Element as SlateElement,
  Node as SlateNode,
  Text as SlateText,
  Transforms,
} from 'slate';

import { FONT_TYPE, LIST_ITEM_TEXT, LIST_ITEM_TYPES, LIST_TYPES } from 'constants/editors';
import { BlockFormatType } from 'types/Editor';

export const isFontTypeActive = (editor: SlateEditor, value: string) => {
  const match = Array.from(SlateEditor.nodes(editor, {
    match: (node: SlateNode) => (
      (
        (SlateEditor.isBlock(editor, node) && node.type !== LIST_ITEM_TEXT)
        || (SlateElement.isElement(node) && LIST_TYPES.includes(node.type))
      )
      && node[FONT_TYPE] === value
    ),
  }));

  return !!match.length;
};

export const addPropertyFontType = (editor: SlateEditor, format: BlockFormatType, value: string) => {
  Transforms.setNodes(editor, { [format]: value }, {
    match: (node) => (
      (SlateEditor.isBlock(editor, node) && node.type !== LIST_ITEM_TEXT)
      || (SlateElement.isElement(node) && LIST_TYPES.includes(node.type))
    ),
  });
};

export const changeListItemFontSize = (editor: SlateEditor, format: string, value: string) => {
  if (editor.selection) {
    const textNodes = Array.from(
      SlateEditor.nodes(editor, {
        at: editor.selection,
        match: SlateText.isText,
      }),
    );

    textNodes.forEach(([, path]) => {
      const liNodeEntry = SlateEditor.above(editor, {
        at: path,
        match: (n) => SlateElement.isElement(n) && LIST_ITEM_TYPES.includes(n.type),
      });

      if (liNodeEntry) {
        const [, liPath] = liNodeEntry;
        Transforms.setNodes(editor, { [format]: value }, { at: liPath });
      }
    });
  }
};
