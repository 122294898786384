import PageSetting from 'components/PageSettings/PageSetting';
import usePageSettingsStructure from 'hooks/pageSettings/usePageSettingsStructure';

const PageSettingsComponent = () => {
  const settings = usePageSettingsStructure();
  return (
    <>
      {settings.map((setting) => (
        <PageSetting key={setting.key} setting={setting} />
      ))}
    </>
  );
};

export default PageSettingsComponent;