import { useSelector } from 'react-redux';

import EditorDevTools from 'components/Editor/EditorDevTools';
import PdfFieldProperties from 'components/PdfEditor/PdfEditorDevTools/PdfFieldProperties';
import { RootStateType } from 'store/reducers';
import { PDFFieldType } from 'types/PdfTemplates';
import { getSortedPdfFields } from 'utils/pdfTemplatesHelpers';

const PDFEditorDevTools = () => {
  const { pdfTemplateFields } = useSelector((state: RootStateType) => state.pdfTemplates);
  const fields: PDFFieldType[] = getSortedPdfFields(pdfTemplateFields);

  return (
    <EditorDevTools fields={fields}>
      <PdfFieldProperties pdfTemplateFieldsArray={fields} />
    </EditorDevTools>
  );
};

export default PDFEditorDevTools;