import { FC, ReactNode } from 'react';

interface IHelpTextWrapper {
  helpText: string;
  children: ReactNode;
}

const HelpTextWrapper: FC<IHelpTextWrapper> = ({
  helpText,
  children,
}) => (
  <>
    <div className="d-flex justify-content-center monday-com-board-name my-2">
      {helpText}
    </div>
    {children}
  </>
);

export default HelpTextWrapper;