import { FC, SetStateAction } from 'react';

import SignaturePad from 'react-signature-canvas';

interface IDrawTab {
  handlerClear: (clearValue: boolean) => void;
  existingValue: string | undefined;
  onBeginDraw: () => void;
  setSignaturePad: (value: SetStateAction<SignaturePad | null>) => void;
}

const DrawTab: FC<IDrawTab> = ({
  existingValue,
  handlerClear,
  onBeginDraw,
  setSignaturePad,
}) => (
  <>
    <div className="position-absolute end-0 text-end mx-4 p-2">
      <button type="button" className="btn-clear mr-2" onClick={() => handlerClear(true)}>Clear</button>
    </div>
    <div className="text-center w-100 form-signature-wrapper form-signature-wrapper-draw">
      <div className="text-center signature-canvas-wrapper">
        {
        existingValue
          ? (
            <img
              className="signature-canvas image-inside-modal"
              src={existingValue}
              alt="signature"
            />
          )
          : (
            <SignaturePad
              onBegin={onBeginDraw}
              minDistance={0}
              throttle={0}
              velocityFilterWeight={0}
              canvasProps={{ className: 'signature-canvas no-image-canvas' }}
              ref={(ref) => setSignaturePad(ref)}
            />
          )
      }
      </div>
      <hr className="line-signature-canvas d-inline-flex mt-0 mb-4" />
    </div>
  </>
);

export default DrawTab;