import { ChangeEvent, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Col } from 'reactstrap';
import { InputType } from 'reactstrap/types/lib/Input';

import { useSlate } from 'slate-react';

import FieldProperties from 'components/Editor/EditorDevTools/FieldProperties';
import { TABLE_QUESTION_CAPTION } from 'constants/editors';
import {
  TABLE_QUESTION_CAPTION_KEY,
  TABLE_QUESTION_CAPTION_SIDE_KEY,
  TABLE_QUESTION_CAPTION_SIDE_OPTIONS,
  TableQuestionSide,
} from 'constants/tableQuestion';
import { RootStateType } from 'store/reducers';
import { ICustomElement } from 'types/Editor';
import { updateNodeByKey } from 'utils/editorHelpers';
import { findNodesByParam } from 'utils/TableQuestion/tableQuestionHelpers';

const TableQuestionPropertiesTab = () => {
  const editor = useSlate();

  const selectedTableQuestionKey = useSelector((state: RootStateType) => (
    state.editorSlate.selectedTableQuestionKey
  ));
  const tableFromEditor = findNodesByParam(editor, { key: selectedTableQuestionKey });

  const [currentCaption, setCurrentCaption] = useState<ICustomElement | null>(null);
  const [caption, setCaption] = useState<string | undefined>('');
  const [captionSide, setCaptionSide] = useState<string | undefined>('');

  useEffect(() => {
    if (!selectedTableQuestionKey || !Array.isArray(tableFromEditor) || !tableFromEditor.length) return;

    const table = tableFromEditor[0];

    if (!table) return;

    const tableCaptionNodes = findNodesByParam(table, { type: TABLE_QUESTION_CAPTION });

    if (!tableCaptionNodes) return;
    const tableCaption = tableCaptionNodes[0];

    setCurrentCaption(tableCaption);
    setCaptionSide(tableCaption.captionSide);
    setCaption(tableCaption.value);
  }, [tableFromEditor]);

  const updateCaption = (event: ChangeEvent<HTMLInputElement>) => {
    if (!currentCaption) return;
    const value = event.target.value;
    setCaption(value);

    updateNodeByKey(editor, currentCaption.key ?? 0, {
      children: [{ text: value }],
      value,
    });
  };

  const updateCaptionSide = (event: ChangeEvent<HTMLSelectElement>) => {
    if (!currentCaption) return;
    const value = event.target.value as TableQuestionSide;
    setCaptionSide(value);

    updateNodeByKey(editor, currentCaption.key ?? 0, {
      captionSide: value,
    });
  };

  const TABLE_QUESTION_PROPERTIES = [
    {
      key: TABLE_QUESTION_CAPTION_KEY,
      label: 'Table Caption',
      type: 'text' as InputType,
      value: caption,
      onChangeHandler: updateCaption,
    },
    {
      key: TABLE_QUESTION_CAPTION_SIDE_KEY,
      label: 'Table Caption Side',
      type: 'select' as InputType,
      value: captionSide,
      options: TABLE_QUESTION_CAPTION_SIDE_OPTIONS,
      onChangeHandler: updateCaptionSide,
    },
  ];

  return (
    <Col>
      {
        TABLE_QUESTION_PROPERTIES.map(({
          key,
          label,
          type,
          value,
          onChangeHandler,
          options,
        }) => (
          <div key={`FieldPropertiesWrapper_${label}`}>
            <FieldProperties
              key={key}
              label={label}
              type={type}
              value={value}
              options={options}
              onChangeHandler={onChangeHandler}
            />
          </div>
        ))
      }
    </Col>
  );
};

export default TableQuestionPropertiesTab;