import { ChangeEventHandler, FC } from 'react';

import { useSelector } from 'react-redux';

import SectionFormContent from 'components/Editor/FormView/SectionFormContent';
import TitleAndDescription from 'components/Editor/FormView/TitleAndDescription';
import PoliciesModal from 'components/Modals/Policies/PoliciesModal';
import Slider from 'components/Slider';
import { FORM_TYPE } from 'constants/general';
import { ShareViewModes } from 'constants/shareViewModes';
import { TITLE_MAX_LEN } from 'constants/validation';
import { RootStateType } from 'store/reducers';
import { IPublicPageState } from 'types/PublicPage';
import { ITemplateSection } from 'types/redux';
import { IErrorField } from 'types/validation';

import 'style/_swiper.scss';
import 'style/_quill-editor.scss';

interface IFormViewProps {
  name: string;
  title?: string;
  changeFormTitle?: ChangeEventHandler<HTMLInputElement>;
  description?: string;
  changeFormDescription?: (value: string) => void;
  readOnlyMode?: boolean;
  showDescription?: boolean;
  formErrors?: IErrorField;
  isPublicPage?: boolean;
  isFormsView?: boolean;
}

/**
 * Form view for common Public pages, and Manager interface with Light Sections enabled
 */
const SLFormView: FC<IFormViewProps> = ({
  name,
  title,
  changeFormTitle,
  description,
  changeFormDescription,
  readOnlyMode = false,
  showDescription = true,
  formErrors,
  isPublicPage = true,
  isFormsView = true,
}) => {
  const sections: ITemplateSection[] = useSelector((state: RootStateType) => state.template.sections);
  const { currentDocument, data }: IPublicPageState = useSelector((state: RootStateType) => (
    state.publicPages
  ));

  const currentSections = isPublicPage ? currentDocument?.slides : sections;
  const slides = currentSections?.map((section: ITemplateSection, index: number) => (
    <div key={section.key ?? section.section_id} className="p-3">
      <SectionFormContent
        section={section}
        sectionIndex={index}
        readOnlyMode={readOnlyMode}
        isPublicPage={isPublicPage}
        isFormsView={isFormsView}
        isFormTypeDocument={currentDocument?.documentType === FORM_TYPE}
      />
      {
        (
          isPublicPage
          && index === 0
          && [
            ShareViewModes.SHARE_MODE_FORM,
            ShareViewModes.SHARE_MODE_FORM_BUILDER,
          ].includes(data?.view_mode ?? ShareViewModes.SHARE_MODE_BOTH))
          && <PoliciesModal />
      }
    </div>
  ));

  return (
    <>
      {
        !isPublicPage && (
          <div className="template-info">
            {name && !showDescription && <h1 className="mb-3">{name}</h1>}
            {showDescription && (
              <TitleAndDescription
                changeFormDescription={changeFormDescription}
                changeFormTitle={changeFormTitle}
                description={description || ''}
                errorMessage={formErrors?.title || 'Error! Something went wrong.'}
                isError={!!formErrors?.title}
                isReadOnlyMode={isPublicPage || readOnlyMode}
                isPublicPage={isPublicPage}
                title={title || name || ''}
                titleMaxLength={TITLE_MAX_LEN}
                titlePlaceholder="Please add a template title..."
              />
            )}
          </div>
        )
      }
      <Slider isSliderDisabled={!isPublicPage}>
        {slides}
      </Slider>
    </>
  );
};

export default SLFormView;