import { FC, MouseEventHandler } from 'react';

import { FormControlLabel, FormGroup, Radio, RadioGroup } from '@mui/material';

import { SignerFlow } from 'constants/signNow';
import { IReadonlySignerFlowConfig, ISignerFlowConfigItem } from 'types/signNow/selectSigners';

interface ISignerFlowProps {
  currentSignerFlow: SignerFlow,
  config: IReadonlySignerFlowConfig,
  onChange: MouseEventHandler<HTMLButtonElement>,
}

const SignerFlowComponent: FC<ISignerFlowProps> = ({
  currentSignerFlow,
  config,
  onChange,
}) => (
  <FormGroup>
    <RadioGroup
      aria-labelledby="radio-buttons-signer-flow"
      name="signer-flow"
      value={currentSignerFlow}
    >
      {
        Object.values(config).map(({ key, label, isHidden }: ISignerFlowConfigItem) => (
          <FormControlLabel
            key={key}
            hidden={isHidden}
            value={key}
            label={label}
            control={<Radio onClick={onChange} />}
          />
        ))
      }
    </RadioGroup>
  </FormGroup>
);

export default SignerFlowComponent;