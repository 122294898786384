import { useSelector } from 'react-redux';

import CurrentTitle from 'components/Editor/FormView/CurrentTitle';
import SliderFraction from 'components/Editor/FormView/SliderFraction';
import { ShareViewModes } from 'constants/shareViewModes';
import { RootStateType } from 'store/reducers';
import { getIsDocumentWithFormBuilder, getIsFormBuilder, getIsPDFDocument } from 'utils/PublicPage/documentTypeChecker';

const SimpleTitle = ({
  isPublicPage,
  title,
}: {
  isPublicPage: boolean,
  title: string,
}) => {
  const { documentType, viewMode } = useSelector((state: RootStateType) => (
    state.publicPages.currentDocument ?? {}
  ));

  const isVisibleSliderFraction = !getIsPDFDocument(documentType)
    || getIsFormBuilder(viewMode as ShareViewModes)
    || getIsDocumentWithFormBuilder(viewMode as ShareViewModes);

  return (
    isPublicPage && isVisibleSliderFraction
      ? (
        <SliderFraction>
          <CurrentTitle title={title} />
        </SliderFraction>
      )
      : (
        <div className="form-title-public form-title-simple">
          <CurrentTitle title={title} />
        </div>
      )
  );
};

export default SimpleTitle;