import { FC, useCallback, useEffect, useState } from 'react';

import { useCSVReader } from 'react-papaparse';
import { useSelector } from 'react-redux';

import { Alert, Checkbox, FormControlLabel } from '@mui/material';
import { Descendant } from 'slate';

import Button from 'components/Base/Button';
import CSVDataTable from 'components/CSVReader/CSVDataTable';
import CSVReaderFileArea from 'components/CSVReader/CSVReaderFileArea';
import DocumentPreviewEditor from 'components/DocumentPreviewEditor';
import RadioButtonsGroup from 'components/RadioButtonsGroup';
import { SUBTYPE_EMAIL } from 'constants/editors';
import { SUCCESS_CREATE_CODE } from 'constants/generalErrors';
import { SHARE_VIEW_MODES, ShareViewModes } from 'constants/shareViewModes';
import { batchSendDocument } from 'services/api';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { RootStateType } from 'store/reducers';
import { ICSVReaderChildrenProps, IParsedData, IRejectedData, IValidationResults } from 'types/BatchSendViaCSV';
import { ICustomElement, ISubtypesFromFieldsGetter } from 'types/Editor';
import { IDocumentSection } from 'types/Sections';
import { IField } from 'types/validation';
import { handleCancelRequest } from 'utils/axiosInstance';
import { addSectionsDocument, updateFoundFieldValuesByAssignment } from 'utils/editorHelpers';
import { getAllFieldsFromTemplate } from 'utils/Fields/getFormsForFields';
import { getFieldValueObjects, getHeadersAndValuesData } from 'utils/ManagerEditor/BatchSendViaCSVHelpers';
import { getSubtypeFieldsfromArray } from 'utils/Subtypes/subtypeHelpers';
import { validateFieldSubtype, validateFieldWithSubtype } from 'utils/validation';

import 'scss/components/_csvReaderBatchSend.scss';

const FILE_INVALID_TYPE = 'file-invalid-type';

const CSVReader: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { CSVReader } = useCSVReader();

  const { templateDetails } = useSelector((state: RootStateType) => state.user);
  const templateName = templateDetails?.name;
  const isPDF = Boolean(templateDetails?.pdf_fields_json);
  const templateFields = templateDetails ? getAllFieldsFromTemplate(
    templateDetails.pdf_fields_json ?? templateDetails.sections,
    isPDF,
  ) : [];
  const templateSubtypeFields = getSubtypeFieldsfromArray(templateFields);

  const [parsedData, setParsedData] = useState<IParsedData>({ data: [], errors: [], meta: {} });
  const [validationResults, setValidationResults] = useState<IValidationResults>({ warnings: [], errors: [] });
  const [documentData, setDocumentData] = useState<Descendant[]>([]);
  const [recipientsData, setRecipientsData] = useState<string[][]>([]);
  const [isShowDataStep, setIsShowDataStep] = useState<boolean>(false);
  const [isValidationStep, setIsValidationStep] = useState<boolean>(false);
  const [isPreviewStep, setIsPreviewStep] = useState<boolean>(false);
  const [isConfirmationStep, setIsConfirmationStep] = useState<boolean>(false);
  const [createdDocumentsCount, setCreatedDocumentsCount] = useState<number | null>(null);
  const [linkViewMode, setLinkViewMode] = useState<ShareViewModes>(ShareViewModes.SHARE_MODE_BOTH);

  useEffect(() => () => handleCancelRequest(), []);

  useEffect(() => {
    if (templateDetails) {
      if (isPDF) {
        setDocumentData(Object.values(templateDetails.pdf_fields_json));
      } else if (templateDetails.sections) {
        const sectionList = templateDetails.sections.map((sectionData: IDocumentSection) => (
          sectionData.section
        ));
        const content = addSectionsDocument(sectionList, false);
        setDocumentData(content);
      }
    }
  }, [isPDF, templateDetails]);

  const onRejectedFile = (fileResultObject: IRejectedData) => {
    const errors = fileResultObject.errors.map((errorObject) => (
      errorObject.code === FILE_INVALID_TYPE
        ? { ...errorObject, message: 'Please upload a valid CSV file' }
        : { ...errorObject }
    ));

    if (errors.length) {
      setParsedData((prevData) => ({
        ...prevData,
        errors,
      }));
    } else {
      setParsedData((prevData) => ({
        ...prevData,
        errors: [],
      }));
    }
  };

  const validateRecipientsData = (headers: (string | number)[], values: (string | number)[][]) => {
    const requiredColumnNames = ['FIRST_NAME', 'LAST_NAME', 'EMAIL'];
    const errorList: string[] = [];

    requiredColumnNames.forEach((requiredColumnName) => {
      const requiredMessage = `${requiredColumnName} is a required field`;
      const matchCount = headers.filter((columnName) => String(columnName).trim() === requiredColumnName).length;
      if (matchCount < 1) {
        errorList.push(requiredMessage);
      } else if (matchCount > 1) {
        errorList.push(`The file has duplicated ${requiredColumnName} field`);
      } else {
        const columnIndex = headers.findIndex((element) => element === requiredColumnName);
        const validationResult: string[] = values.map((rowData) => {
          const fieldValue = rowData.length < columnIndex ? '' : String(rowData[columnIndex]);
          const [isError, validationMessage] = validateFieldWithSubtype(requiredColumnName, fieldValue);
          return isError ? validationMessage : '';
        }).filter((message) => (message || '').trim().length > 0);

        errorList.push(...validationResult);
      }
    });

    return errorList;
  };

  const sendRequestEmailsHandler = async (dataArray: IParsedData['data']) => {
    if (!templateDetails?.id) {
      return setCreatedDocumentsCount(0);
    }

    const { headersData, rowsData, recipientHeaders, recipientData } = getHeadersAndValuesData(dataArray, true);

    /**
     * user_data: [
     *  0: { subtype: "first_name", value: "" },
     *  1: { subtype: "last_name", value: "" },
     *  2: { subtype: "email", value: "" } - required
     * ]
     * field_list_data: [
     *  0: [ "name": "CONCATENATED_FIELD_NAME", value: ""],
     *  1: [ "name": "FIELD_NAME", value: ""],
     *  ...
     * ]
     */
    const userListData = recipientData.map((recipientRow) => {
      const userData: Partial<ISubtypesFromFieldsGetter>[] = [];
      recipientRow.forEach((rowData, fieldIndex) => {
        if (recipientHeaders[fieldIndex]) {
          userData.push({
            subtype: String(recipientHeaders[fieldIndex]).toLowerCase().trim(),
            value: String(rowData).trim(),
          });
        }
      });
      return userData;
    });

    const fieldListData = getFieldValueObjects(headersData, rowsData);

    const response = await apiErrorHandler(
      batchSendDocument,
      {
        returnErrorResult: true,
        id: templateDetails.id,
        content: {
          user_list_data: userListData,
          field_list_data: fieldListData,
          view_mode: linkViewMode,
        },
      },
    );

    if (response) {
      const { data, status } = response;
      if (status === SUCCESS_CREATE_CODE) {
        return setCreatedDocumentsCount(data.length || 0);
      }
      setCreatedDocumentsCount(0);
    }
  };

  const validateParsedData = (dataArray: IParsedData['data']) => {
    const errorList: string[] = [];

    if (dataArray.length < 2) {
      errorList.push('There are no field values in the file');
      return errorList;
    }

    const { headersData, rowsData, recipientHeaders, recipientData } = getHeadersAndValuesData(dataArray, true);

    const recipientDataErrors = validateRecipientsData(recipientHeaders, recipientData);
    errorList.push(...recipientDataErrors);
    if (errorList.length > 0) {
      return errorList;
    }

    if (templateSubtypeFields.length > 0 && dataArray) {
      templateSubtypeFields.forEach((field) => {
        const fieldName = field.name || '';
        const requiredMessage = `${fieldName} is a required field`;
        const fieldIndex = headersData.findIndex((element) => element === fieldName);

        if (fieldIndex === -1) {
          errorList.push(requiredMessage);
        } else {
          rowsData.map((rowData) => {
            const fieldValue = rowData.length < fieldIndex ? '' : String(rowData[fieldIndex]);
            if (fieldValue.trim() === '') {
              errorList.push(requiredMessage);
              return true;
            }

            const message = validateFieldSubtype(
              {
                ...field,
                value: fieldValue,
                title: fieldName,
                required: field.subtype === SUBTYPE_EMAIL || field.requiredField,
              } as IField,
            );
            if (message) {
              errorList.push(message);
              return true;
            }
            return false;
          });
        }
      });

      return errorList;
    }

    return errorList;
  };

  const getRecipientDataForEmails = (dataArray: IParsedData['data']) => {
    const { recipientHeaders, recipientData } = getHeadersAndValuesData(dataArray, true);

    const recipientsTableValues = recipientData.map((rowData) => {
      const rowValues: string[] = [];
      recipientHeaders.forEach((_, fieldIndex) => {
        rowValues.push(rowData[fieldIndex] ? String(rowData[fieldIndex]) : '');
      });
      return rowValues;
    });

    return { headers: recipientHeaders, values: recipientsTableValues };
  };

  const onReadAcceptedFile = (results: IParsedData) => {
    setCreatedDocumentsCount(null);
    setParsedData(results);
    setIsShowDataStep(true);
  };

  const onValidateData = useCallback(() => {
    const errors = validateParsedData(parsedData.data);
    if (errors.length) {
      setValidationResults((prevData) => ({
        ...prevData,
        errors,
      }));
    }

    setIsShowDataStep(false);
    setIsValidationStep(true);
  }, [parsedData.data.length]);

  const onSetDataAndPreviewDocument = () => {
    const { headersData, rowsData } = getHeadersAndValuesData(parsedData.data);
    if (rowsData.length) {
      const fieldListData = getFieldValueObjects(headersData, [rowsData.shift() || []])[0];

      if (documentData.length) {
        const updatedContent = updateFoundFieldValuesByAssignment(documentData, fieldListData, isPDF);
        setDocumentData(updatedContent);
      }
    }

    setIsValidationStep(false);
    setIsPreviewStep(true);
  };

  const onConfirmRecipientList = useCallback(() => {
    const { headers, values } = getRecipientDataForEmails(parsedData.data);
    if (values.length) {
      const recipientsDataArrays = [headers].concat(values);
      setRecipientsData(recipientsDataArrays);
    }

    setIsPreviewStep(false);
    setIsConfirmationStep(true);
  }, [parsedData.data.length]);

  const onSendEmails = () => {
    setIsConfirmationStep(false);
    sendRequestEmailsHandler(parsedData.data);
  };

  const changeViewModeHandler = (modeValue: string) => {
    setLinkViewMode(modeValue as ShareViewModes);
  };

  return (
    <div className="csv-reader-wrapper">
      <CSVReader
        onUploadAccepted={(results: IParsedData) => onReadAcceptedFile(results)}
        onUploadRejected={(results: IRejectedData[]) => results?.length && onRejectedFile(results[0])}
        config={{ header: false, skipEmptyLines: true }}
      >
        {(readerProps: ICSVReaderChildrenProps) => (
          <CSVReaderFileArea {...readerProps} errors={parsedData.errors} />
        )}
      </CSVReader>
      {
        parsedData.data?.length > 1
          ? (
            <>
              {isShowDataStep && (
                <>
                  <div className="py-2">
                    <CSVDataTable data={parsedData.data} />
                  </div>
                  <div className="d-flex flex-row justify-content-center pt-2">
                    <Button
                      classes="button-contained-pink"
                      onClick={onValidateData}
                    >
                      Preview
                    </Button>
                    <div className="d-inline-flex flex-row ms-4">
                      <FormControlLabel disabled control={<Checkbox className="p-0" />} label="Validation" />
                    </div>
                  </div>
                </>
              )}
              {isValidationStep && (
                <>
                  <div className="py-2">
                    {validationResults.errors.length ? (
                      <>
                        <div className="py-4">Found the following errors:</div>
                        {
                          validationResults.errors.map((errorText, index) => (
                            <Alert
                              severity="error"
                              className="my-1 mx-2"
                              key={`errorText_${index + 1}`}
                            >
                              {errorText}
                            </Alert>
                          ))
                        }
                      </>
                    ) : null}
                    {validationResults.warnings.length ? (
                      <>
                        <div className="py-4">Warning: The following issues were found:</div>
                        {
                          validationResults.warnings.map((warningText, index) => (
                            <Alert
                              severity="warning"
                              className="my-1 mx-2"
                              key={`warningText_${index + 1}`}
                            >
                              {warningText}
                            </Alert>
                          ))
                        }
                      </>
                    ) : null}
                  </div>
                  {
                    validationResults.errors.length
                      ? (
                        <div className="py-4"><b>Please fix the CSV file and upload again.</b></div>
                      )
                      : (
                        <>
                          <div className="text-center h4 py-4"><b>You may proceed to preview and send.</b></div>
                          <div className="text-center pt-2">
                            <Button
                              classes="button-contained-pink"
                              onClick={onSetDataAndPreviewDocument}
                            >
                              Preview
                            </Button>
                          </div>
                        </>
                      )
                  }
                </>
              )}
              {isPreviewStep && documentData ? (
                <>
                  <DocumentPreviewEditor
                    pdfFields={(isPDF && documentData) as ICustomElement[] || null}
                    type={templateDetails.type}
                    content={documentData}
                    onChange={setDocumentData}
                  />
                  <div className="text-center pt-2">
                    <Button
                      classes="button-contained-pink"
                      onClick={onConfirmRecipientList}
                    >
                      Confirm
                    </Button>
                  </div>
                </>
              ) : null}
              {isConfirmationStep && (
                <>
                  <div className="py-2">
                    <div>Documents view mode:</div>
                    <RadioButtonsGroup
                      radiosArray={SHARE_VIEW_MODES}
                      onChangeHandler={changeViewModeHandler}
                      defaultValue={ShareViewModes.SHARE_MODE_BOTH}
                    />
                  </div>
                  <div className="py-2">We will send <b>{templateName}</b> to the following recipients:</div>
                  <div className="py-2">
                    <CSVDataTable data={recipientsData} />
                  </div>
                  <div className="text-center pt-2">
                    <Button
                      classes="button-contained-pink"
                      onClick={onSendEmails}
                    >
                      Send
                    </Button>
                  </div>
                </>
              )}
              {(createdDocumentsCount !== null) && (
                <>
                  <div className="text-center h4 py-4">
                    <b>Great! We successfully sent {createdDocumentsCount} emails.</b>
                  </div>
                  <div className="text-center pt-4">
                    <Button
                      classes="button-contained-pink"
                      onClick={onClose}
                    >
                      Done
                    </Button>
                  </div>
                </>
              )}
            </>
          )
          : null
      }
    </div>
  );
};

export default CSVReader;