import { MutableRefObject, useEffect, useRef, useState } from 'react';

import { BaseSelection as SlateBaseSelection } from 'slate';
import { useSlate } from 'slate-react';

import HorizontalToolbar from 'components/Editor/components/HorizontalToolbar';
import MentionToolbar from 'components/Editor/components/MentionToolbar';
import VerticalToolbar from 'components/Editor/components/VerticalToolbar';
import { useOutsideAlerter } from 'hooks/useOutsideAlerter';
import { useOutsideToolbar } from 'hooks/useOutsideToolbar';
import { useRolePermission } from 'hooks/useRolePermission';
import useToolbar from 'hooks/useToolbar';
import { USING_TOOLBAR } from 'types/Editor';
import {
  calculateLeftPosition,
  calculateMentionToolbarTop,
  calculateTopPosition,
  closeToolbar,
  getRect,
  openToolbar,
} from 'utils/editorToolbarHelpers';
import { isSafariBrowser } from 'utils/getBrowserName';

const Toolbar = () => {
  const { permissionUsingTableField } = useRolePermission();
  const editor = useSlate();
  const usingToolbar = useToolbar();
  const [horizontalToolbar, setHorizontalToolbar] = useState<boolean>(false);
  const [verticalToolbar, setVerticalToolbar] = useState<boolean>(false);
  const [mentionToolbar, setMentionToolbar] = useState<boolean>(false);
  const [lastEditorSelection, setLastEditorSelection] = useState<SlateBaseSelection | null>(null);

  const horizontalToolbarRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

  const verticalToolbarRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const verticalToolbarInputRef: MutableRefObject<HTMLInputElement | null> = useRef(null);

  const mentionToolbarRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const mentionToolbarInputRef: MutableRefObject<HTMLInputElement | null> = useRef(null);

  useOutsideAlerter(horizontalToolbarRef, setHorizontalToolbar);

  const outsideVerticalToolbar = useOutsideToolbar(
    verticalToolbarRef,
    verticalToolbarInputRef,
    true,
  );

  const outsideMentionToolbar = useOutsideToolbar(
    mentionToolbarRef,
    mentionToolbarInputRef,
    true,
  );

  const hasFocusVerticalToolbarInput = verticalToolbarInputRef.current?.hasAttribute('data-focus');
  const hasFocusMentionToolbarInput = mentionToolbarInputRef.current?.hasAttribute('data-focus');

  const editorSelection = editor.selection;

  useEffect(() => {
    setVerticalToolbar(!outsideVerticalToolbar);
    setMentionToolbar(!outsideMentionToolbar);
  }, [outsideVerticalToolbar, outsideMentionToolbar]);

  useEffect(() => {
    if (usingToolbar === USING_TOOLBAR.HORIZONTAL_TOOLBAR) return setHorizontalToolbar(true);
    if (usingToolbar === USING_TOOLBAR.VERTICAL_TOOLBAR) return setVerticalToolbar(true);
    if (usingToolbar === USING_TOOLBAR.MENTION_TOOLBAR) return setMentionToolbar(true);

    if (usingToolbar !== USING_TOOLBAR.HORIZONTAL_TOOLBAR) {
      setHorizontalToolbar(false);
    }

    if (!usingToolbar && !hasFocusVerticalToolbarInput) {
      setVerticalToolbar(false);
    }

    if (!usingToolbar && !hasFocusMentionToolbarInput) {
      setMentionToolbar(false);
    }
  }, [usingToolbar, hasFocusVerticalToolbarInput, hasFocusMentionToolbarInput]);

  useEffect(() => {
    if (!usingToolbar) return;

    const rect = getRect();

    if (
      !horizontalToolbarRef.current
      || !verticalToolbarRef.current
      || !mentionToolbarRef.current || !rect
    ) return;

    if (horizontalToolbar) {
      const toolbarHeight = 98;
      const toolbarWidth = 410;
      openToolbar(horizontalToolbarRef, {
        top: rect.top - toolbarHeight,
        left: calculateLeftPosition(rect, toolbarWidth),
      });
    }

    if (verticalToolbar) {
      const toolbarHeight = 375;
      const toolbarWidth = 280;
      openToolbar(verticalToolbarRef, {
        top: calculateTopPosition(rect, toolbarHeight),
        left: calculateLeftPosition(rect, toolbarWidth),
      });
    }

    if (mentionToolbar) {
      const toolbarHeight = 180;
      const toolbarWidth = 280;
      openToolbar(mentionToolbarRef, {
        top: calculateMentionToolbarTop(rect, toolbarHeight),
        left: calculateLeftPosition(rect, toolbarWidth),
      });
    }
  }, [
    usingToolbar, horizontalToolbar, verticalToolbar,
    mentionToolbar, editor.selection,
  ]);

  useEffect(() => {
    if (isSafariBrowser()) return;
    if (
      !horizontalToolbarRef.current
      || !verticalToolbarRef.current
      || !mentionToolbarRef.current
    ) return;

    if (!horizontalToolbar) {
      closeToolbar(horizontalToolbarRef);
    }

    if (!verticalToolbar && outsideVerticalToolbar) {
      closeToolbar(verticalToolbarRef);
    }

    if (!mentionToolbar && outsideMentionToolbar) {
      closeToolbar(mentionToolbarRef);
    }
  }, [
    horizontalToolbar, verticalToolbar, mentionToolbar,
    outsideVerticalToolbar, outsideMentionToolbar,
  ]);

  useEffect(() => {
    if (editorSelection) setLastEditorSelection(editorSelection);
  }, [editorSelection]);

  return (
    <>
      <HorizontalToolbar
        toolbarRef={horizontalToolbarRef}
        permissionUsingTableField={permissionUsingTableField}
        closeToolbar={closeToolbar}
      />
      <VerticalToolbar
        toolbarRef={verticalToolbarRef}
        toolbarInputRef={verticalToolbarInputRef}
        lastEditorSelection={lastEditorSelection}
      />
      <MentionToolbar
        toolbarRef={mentionToolbarRef}
        toolbarInputRef={mentionToolbarInputRef}
        lastEditorSelection={lastEditorSelection}
      />
    </>
  );
};

export default Toolbar;