import { FC, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { SelectableGroup } from 'react-selectable-fast';
import { TSelectableItem } from 'react-selectable-fast/lib/Selectable.types';

import { clearFormBuilderSelectedFieldsKeys, setFormBuilderSelectedFieldsKeys } from 'store/actions/formBuilder';
import { RootStateType } from 'store/reducers';

interface SelectableFieldsWrapperProps {
  disabled?: boolean;
}

const SelectableFieldsWrapper: FC<SelectableFieldsWrapperProps> = ({
  children,
  disabled = false,
}) => {
  const dispatch = useDispatch();
  const selectedFormBuilderFieldsKeys = useSelector((state: RootStateType) => (
    state.formBuilder.selectedFormBuilderFieldsKeys
  ));

  const [selectedFields, setSelectedFields] = useState<string[]>(selectedFormBuilderFieldsKeys);
  const onSelectionFinish = (items: TSelectableItem[]) => {
    const selectedFieldsKeys: string[] = items.map((item) => (
      item.node?.getAttribute('data-key')
    )).map(String);

    if (!selectedFieldsKeys.length) return;
    setSelectedFields(selectedFieldsKeys);
    dispatch(setFormBuilderSelectedFieldsKeys(selectedFieldsKeys));
  };

  const onSelectionClear = () => {
    if (!selectedFields.length) return;
    setSelectedFields([]);
    dispatch(clearFormBuilderSelectedFieldsKeys());
  };

  return (
    <SelectableGroup
      disabled={disabled}
      className={`selectable-group${disabled ? ' disabled' : ''}`}
      enableDeselect
      resetOnStart
      allowClickWithoutSelected={false}
      deselectOnEsc
      ignoreList={['.field_wrapper', '.sort_button', '.drag-and-drop-cursor', 'input', '.ql-editor']}
      selectOnClick={false}
      onSelectionFinish={onSelectionFinish}
      onSelectionClear={onSelectionClear}
      selectionModeClass="selection-mode"
    >
      {children}
    </SelectableGroup>
  );
};

export default SelectableFieldsWrapper;