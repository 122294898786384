import { RenderElementProps } from 'slate-react';

const TableQuestionBody = (props: RenderElementProps) => {
  const { attributes, children } = props;

  return (
    <tbody
      {...attributes}
      className="table-question-body"
    >
      {children}
    </tbody>
  );
};

export default TableQuestionBody;