import { ChangeEvent, FC, memo, useEffect, useRef, useState } from 'react';

import BaseInputField from 'components/Base/InputField';
import useDebounce from 'hooks/useDebounce';
import { IUserManagementFields } from 'types/userProfile';
import { modalFormValidation } from 'utils/validation';

interface IInputField {
  field: IUserManagementFields;
  onChange: (value: string, errorMessage: string, key: string) => void;
}

const ModalInputField: FC<IInputField> = ({
  field,
  onChange,
}) => {
  const isInitialMount = useRef(true);
  const [value, setValue] = useState(field.value);
  const debouncedValue = useDebounce<string>(value, 300);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      const currentField: IUserManagementFields = {
        required: field.required,
        label: field.label,
        value: debouncedValue,
        key: field.key,
        type: field.type,
      };
      const errorMessage = modalFormValidation(currentField);
      onChange(debouncedValue, errorMessage.join('; '), field.key);
    }
  }, [debouncedValue]);

  const changeHandler = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setValue(target.value);
  };

  return (
    <BaseInputField
      type={field.type}
      label={field.label}
      value={value}
      required={field.required}
      showError={!!field.errorMessage}
      helperText={field.errorMessage}
      onChange={changeHandler}
    />
  );
};

export default memo(ModalInputField);