import { ChangeEvent, MutableRefObject, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { NO_BOARDS } from 'constants/mondayComIntegration';
import useSendUniqueCode from 'hooks/SMSValidation/useSendUniqueCode';
import { checkMondayComRecordsForCurrentUser } from 'store/actions/mondayComIntegration';
import {
  sendCodeForVerification,
  sendSMSValidationCodeError,
  setShowSMSValidationModal,
} from 'store/actions/publicPages';
import { RootStateType } from 'store/reducers';

export const SMS_CODE_LENGHT = 6;

const useVerifyUniqueCode = () => {
  const dispatch = useDispatch();
  const { sendUniqueCode } = useSendUniqueCode();
  const {
    smsValidationModalState,
    smsValidationError,
    data,
    mondayComBoard,
  } = useSelector((state: RootStateType) => state.publicPages);
  const [localCode, setLocalcode] = useState<string[]>(
    Array.from({ length: SMS_CODE_LENGHT }).map((item) => {
      if (!item) {
        return '';
      }
      return '';
    }),
  );
  const [references, setReferences] = useState<MutableRefObject<any>[]>(Array.from({ length: SMS_CODE_LENGHT }));

  const applyUniqueCodeHandler = () => {
    dispatch(sendCodeForVerification(localCode.join('')));
  };

  const sendCodeAgainHandler = () => {
    sendUniqueCode();
  };

  const sendEmailWithPasswordlessLink = () => {
    if (data.monday_com_subitem && mondayComBoard && mondayComBoard !== NO_BOARDS) {
      dispatch(setShowSMSValidationModal(false, ''));
      dispatch(checkMondayComRecordsForCurrentUser());
    }
  };

  const addDigitToBox = (value: string, index: number) => {
    setLocalcode((prevState) => prevState.map((item, itemIndex) => {
      if (itemIndex === index) {
        return value;
      }
      return item;
    }));
    references[index + 1]?.current.focus();
  };

  const removeDigitFromBox = (index: number) => {
    setLocalcode((prevState) => prevState.map((item, itemIndex) => {
      if (itemIndex === index) {
        return '';
      }
      return item;
    }));
    references[index - 1]?.current.focus();
  };

  const changeCodeBoxHandler = ({ target }: ChangeEvent<HTMLInputElement>, index: number) => {
    if (smsValidationError) {
      dispatch(sendSMSValidationCodeError(''));
    }
    const value = target.value;
    if (value.length === 1) {
      addDigitToBox(value, index);
    }
    if (!value.length) {
      removeDigitFromBox(index);
    }
    if (value.length > 1) {
      let localIndex = index;
      value.split('').forEach((letter: string) => {
        addDigitToBox(letter, localIndex);
        localIndex += 1;
      });
    }
  };

  return {
    references,
    setReferences,
    localCode,
    smsValidationError,
    changeCodeBoxHandler,
    showSMSValidationModal: smsValidationModalState?.showSMSValidationModal,
    userPhoneNumber: smsValidationModalState?.userPhoneNumber,
    smsValidationModalState,
    applyUniqueCodeHandler,
    sendCodeAgainHandler,
    sendEmailWithPasswordlessLink,
  };
};

export default useVerifyUniqueCode;