import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Element as SlateElement, Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useSlate } from 'slate-react';

import { FIELD_TYPE, TABLE_CELL_DISPLAY_STYLE, TABLE_QUESTION_FIELD_CELL } from 'constants/editors';
import { changeSelectedField, setSelectedTableQuestion } from 'store/actions/editorSlate';
import { toShowBlock } from 'utils/routeHelpers';
import { getResizeStyleHeight, getResizeStyleWidth } from 'utils/TableQuestion/resizeTableQuestionHelpers';

const TableQuestionCell = (props: RenderElementProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { attributes, children, element } = props;
  const isPublicPage = toShowBlock(location.pathname, 'public');
  const editor = useSlate();

  const selectCell = () => {
    if (isPublicPage) return;
    const field = element.children.find((node) => (
      SlateElement.isElement(node) && FIELD_TYPE.includes(node.type)
    ));

    if (field) {
      dispatch(changeSelectedField(field.key ?? 0));
      dispatch(setSelectedTableQuestion(null));
    }

    const path = ReactEditor.findPath(editor, element);
    Transforms.select(editor, path);
    Transforms.collapse(editor, { edge: 'end' });
  };

  if (element.type === TABLE_QUESTION_FIELD_CELL) {
    return (
      <td
        onClick={selectCell}
        {...attributes}
        className="table-question-cell"
        contentEditable={false}
        style={{
          display: TABLE_CELL_DISPLAY_STYLE,
          ...getResizeStyleWidth(element.width),
          ...getResizeStyleHeight(element.height),
          userSelect: 'none',
        }}
      >
        <span
          className="table-question-cell-text"
          contentEditable={false}
          style={{
            ...getResizeStyleWidth(element.width),
            ...getResizeStyleHeight(element.height),
            userSelect: 'none',
          }}
        >
          {children}
        </span>
      </td>
    );
  }

  return (
    <td
      {...attributes}
      className="table-question-cell"
      style={{
        display: TABLE_CELL_DISPLAY_STYLE,
        ...getResizeStyleWidth(element.width),
        ...getResizeStyleHeight(element.height),
      }}
    >
      <span
        className="table-question-cell-text table-question-cell-text--text"
        style={{
          ...getResizeStyleWidth(element.width),
          ...getResizeStyleHeight(element.height),
        }}
      >
        {children}
      </span>
    </td>
  );
};

export default TableQuestionCell;