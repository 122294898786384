import { createContext, Dispatch, FC, SetStateAction, useContext, useMemo, useState } from 'react';

interface IStepHandlerContext {
  onNext: () => void;
  setOnNext: Dispatch<SetStateAction<() => void>>;
  isNextButtonDisabled: boolean;
  setIsNextButtonDisabled: Dispatch<SetStateAction<boolean>>;
}

const StepHandlerContext = createContext<IStepHandlerContext>({
  onNext: () => {
    throw new Error('Function onNext must be implemented');
  },
  setOnNext: () => {
    throw new Error('Function setOnNext must be implemented');
  },
  isNextButtonDisabled: false,
  setIsNextButtonDisabled: () => {
    throw new Error('Function setIsDisabledButton must be implemented');
  },
});

export const useStepHandler = () => useContext(StepHandlerContext);

export const StepHandlerProvider: FC = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const [onNext, setOnNext] = useState(() => () => {});
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState<boolean>(false);

  const value = useMemo(() => ({
    onNext,
    setOnNext,
    isNextButtonDisabled,
    setIsNextButtonDisabled,
  }), [onNext, setOnNext, isNextButtonDisabled, setIsNextButtonDisabled]);

  return (
    <StepHandlerContext.Provider value={value}>
      {children}
    </StepHandlerContext.Provider>
  );
};