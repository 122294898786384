/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import AutocompleteSections from 'components/AutocompleteSections';
import AddNewSectionModal from 'components/Modals/AddNewSectionModal';
import { ISection, ISectionRequestObj, SectionListType } from 'types/Sections';
import { ITemplate } from 'types/Templates';
import { ADD, CANCEL } from 'constants/general';
import { SUCCESS_CODE } from 'constants/generalErrors';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { getSectionDetails } from 'services/api';

const LIMITED_TABS = [
  { label: "New Section", value: "new" },
  { label: "Existing Section", value: "existing" },
];

const EXTENDED_TABS = [
  { label: "New Section", value: "new" },
  { label: "Existing Section", value: "existing" },
  { label: "Existing Template", value: "existingTemlate" }
];

const ONLY_TEMPLATE_TABS = [
  { label: "Existing Template", value: "existingTemlate" }
];

const SectionEditorFormModal: React.FC<{
  create_docs: boolean;
  permissionUsingSections: boolean;
  templateSections?: SectionListType[];
  addSection: (section: ISection) => void;
  addTemplate: (template: ITemplate) => void;
  closeModal: (show: boolean) => void;
  showModal: boolean;
  sections?: ISection[];
  templates?: ITemplate[];
  createSection: (section: ISectionRequestObj) => void;
  states: any[];
}> = ({
  create_docs,
  permissionUsingSections,
  addSection,
  addTemplate,
  closeModal,
  sections,
  templates,
  createSection,
  states,
  templateSections,
  showModal,
}) => {
  const [tabs, setTabs] = useState(LIMITED_TABS);
  const [selectedSection, setSelectedSection] = useState<ISection | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<ITemplate | null>(null);
  const [selectedTab, setSelectedTab] = useState<string>(tabs[0].value);
  const [filteredSections, setFilteredSections] = useState<any[]>([]);
  const [filteredTemplates, setFilteredTemplates] = useState<any[]>([]);

  const addTemplateToDocs = () => {
    if (selectedTemplate) {
      addTemplate(selectedTemplate);
      closeModal(false);
    }
  };

  const getNewSectionRequest = async (elementId: string) => {
    const result = await apiErrorHandler(getSectionDetails, elementId);

    if (result && result.status === SUCCESS_CODE && result.data) {
      return result.data;
    }
    return null;
  };

  const addSectionToList = async () => {
    closeModal(false);
    if (selectedSection && selectedSection.id) {
      const section = await getNewSectionRequest(String(selectedSection.id));
      if (section) addSection(section);
    }
  };

  useEffect(() => {
    if(create_docs) {
      const availableTabs = permissionUsingSections ? EXTENDED_TABS : ONLY_TEMPLATE_TABS;
      setTabs(availableTabs);
    }
  }, [permissionUsingSections, create_docs]);

  useEffect(() => setSelectedTab(tabs[0].value), [tabs]);

  useEffect(() => {
    if (!templateSections?.length && sections) {
      setFilteredSections(sections);
    } else if (templateSections?.length && sections?.length)
      setFilteredSections(
        sections.filter(
          (sect: any) => !templateSections?.find((s: any) => s.id === sect.id)
        )
      );
  }, [templateSections, sections]);

  useEffect(() => {
    if (templates) {
      setFilteredTemplates(templates);
    }
  }, [templates]);

  return (
    <Modal isOpen={showModal}>
      <ModalHeader toggle={() => closeModal(!showModal)}>Section Editor</ModalHeader>
      <ModalBody>
        <ul className="nav nav-tabs mb-4">
          {tabs.map((tab, index) => (
            <li
              key={index}
              className={`nav-item nav-link ${
                selectedTab === tab.value && "active"
              }`}
              onClick={() => setSelectedTab(tab.value)}
            >
              {tab.label}
            </li>
          ))}
        </ul>
        {
          ((): JSX.Element => {
            switch(selectedTab) {
              case "existingTemlate":
                return (
                  <>
                    <AutocompleteSections
                      sections={filteredTemplates}
                      onSelectSection={setSelectedTemplate}
                      placeholderText="Type to search templates..."
                    />
                    <ModalFooter className="justify-content-center">
                      <Button
                        color="primary"
                        className="mr-3"
                        onClick={addTemplateToDocs}
                      >
                        {ADD}
                      </Button>
                      <Button
                        color="secondary"
                        outline
                        onClick={() => closeModal(!showModal)}
                      >
                        {CANCEL}
                      </Button>
                    </ModalFooter>
                  </>
                )
              case "new":
                return (
                  <>
                    <AddNewSectionModal
                      createSection={createSection}
                      closeModal={closeModal}
                      states={states}
                    />
                  </>
                )
              default:
                return (
                  <>
                    <AutocompleteSections
                      sections={filteredSections}
                      onSelectSection={setSelectedSection}
                    />
                    <ModalFooter className="justify-content-center">
                      <Button
                        color="primary"
                        className="mr-3"
                        onClick={addSectionToList}
                      >
                        {ADD}
                      </Button>
                      <Button
                        color="secondary"
                        outline
                        onClick={() => closeModal(!showModal)}
                      >
                        {CANCEL}
                      </Button>
                    </ModalFooter>
                  </>
                )
            }
          })()
        }
      </ModalBody>
    </Modal>
  );
};

export default SectionEditorFormModal;
