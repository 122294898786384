import { init } from '@amplitude/analytics-browser';

import { ProfileInfoType } from 'types/userProfile';
import { AMPLITUDE_API_KEY } from 'utils/amplitude/amplituteConstants';

export const amplitudeInitialize = ({ id: userId }: ProfileInfoType): void => {
  /**
   *  minIdLenght - according to amplitute documentation deviceId and userId should be 5 characters or more,
   *  but using this option we can set our custom minIdLength
   *  https://www.docs.developers.amplitude.com/data/sdks/typescript-browser/#configuration
   */
  const options = {
    minIdLength: 1,
    disableCookies: true,
  };

  init(AMPLITUDE_API_KEY, String(userId), options);
};

export default undefined;