/* eslint-disable */
import { RenderElementProps, useFocused, useSelected } from 'slate-react';

const ImageElement: React.FC<RenderElementProps> = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();

  return (
    <div {...attributes} className="image-center-align">
      {children}
      <img
        src={element.url}
        alt={element.alt}
        className="d-inline-block mw-100 mh-20"
        style={{ boxShadow: selected && focused ? '0 0 0 3px #B4D5FF' : 'none' }}
      />
      <div>{children}</div>
    </div>
  )
}

export default ImageElement;