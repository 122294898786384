import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';

import {
  DELETE_PERSISTENT_STORAGE_DATA_BY_KEY,
  SET_PERSISTENT_STORAGE_FIELD_DATA,
  SET_RESTORE_DATA_MODAL_VISIBILITY,
} from 'store/actions/actionTypes';
import {
  IPersistentStoreStorage,
  PersistentStorageFieldValueType,
  StorePersistentStorageDataType,
} from 'types/persistentStorage';
import { Action } from 'types/redux';

const initialStata: IPersistentStoreStorage = {
  data: {},
  restoreDataModalWasVisible: false,
};

const encryptor = encryptTransform({
  secretKey: 'SecretReduxPersistentStorageKeyForEncryptingData',
});

export const storagePersistConfig = {
  key: 'persistentStorage',
  storage,
  whitelist: ['data'],
  transforms: [encryptor],
};

const persistentReduxStorage = (
  // eslint-disable-next-line default-param-last
  state: IPersistentStoreStorage = initialStata,
  action: Action,
): IPersistentStoreStorage => {
  switch (action.type) {
    case SET_PERSISTENT_STORAGE_FIELD_DATA: {
      const storageKey: string = action.payload.storageKey;
      const fieldKey: number = action.payload.fieldKey;
      const fieldValue: PersistentStorageFieldValueType = action.payload.fieldValue;

      const currentFieldData: PersistentStorageFieldValueType = state.data[storageKey]?.[fieldKey] || {};

      const updatedFieldData: PersistentStorageFieldValueType = {
        ...currentFieldData,
        ...fieldValue,
      };

      return {
        ...state,
        data: {
          ...state.data,
          [storageKey]: {
            ...state.data[storageKey],
            [fieldKey]: updatedFieldData,
          },
        },
      };
    }
    case DELETE_PERSISTENT_STORAGE_DATA_BY_KEY: {
      return {
        ...state,
        data: Object.keys(state.data)
          .filter((key) => key !== action.payload)
          .reduce((newObj: StorePersistentStorageDataType, key) => ({
            ...newObj,
            [key]: state.data[key],
          }), {}),
      };
    }
    case SET_RESTORE_DATA_MODAL_VISIBILITY:
      return {
        ...state,
        restoreDataModalWasVisible: action.payload,
      };
    default:
      return state;
  }
};

export default persistentReduxStorage;