import {
  FIELD_GROUPING_VALUE,
  SUBTYPE_EMAIL,
  SUBTYPE_FIRST_NAME,
  SUBTYPE_LAST_NAME,
  SUBTYPE_PHONE_MOBILE,
} from 'constants/editors';
import { ICustomElement, ISubtypesFromFieldsGetter } from 'types/Editor';
import { PDFFieldType } from 'types/PdfTemplates';
import { AggregatedFieldsStructureType } from 'types/PublicPage';
import { PIIDataType, PIIUserDataType } from 'types/userProfile';

export const getUserEmailFromPII = (piiData: PIIDataType[]): string => (
  piiData
    ?.find((data) => (
      data.subtype === SUBTYPE_EMAIL && data.groupBy !== FIELD_GROUPING_VALUE.BY_FILTER
    ))
    ?.value
  ?? ''
);

export const getUserInfoFromPII = (
  piiData: PIIDataType[],
  groupedFieldsStructure?: AggregatedFieldsStructureType[],
): PIIUserDataType => {
  const groupedFieldPhoneNumber = groupedFieldsStructure?.find(
    (groupedField) => groupedField.subtype === SUBTYPE_PHONE_MOBILE,
  );
  const piiFieldPhoneNumber = piiData?.find(
    (piiField) => piiField.subtype === SUBTYPE_PHONE_MOBILE,
  );
  if (groupedFieldPhoneNumber && !piiFieldPhoneNumber) {
    piiData.push(groupedFieldPhoneNumber);
  }

  return piiData.reduce((previousValue, currentValue: PIIDataType): PIIUserDataType => {
    if (
      currentValue.subtype
      && [SUBTYPE_EMAIL, SUBTYPE_FIRST_NAME, SUBTYPE_LAST_NAME, SUBTYPE_PHONE_MOBILE].includes(currentValue.subtype)
      && currentValue.groupBy !== FIELD_GROUPING_VALUE.BY_FILTER) {
      return {
        ...previousValue,
        [currentValue.subtype]: currentValue.value,
      };
    }
    return previousValue;
  }, {});
};

export const getUserPIIInfoArray = (userData: PIIUserDataType): Partial<ISubtypesFromFieldsGetter>[] => (
  Object.entries(userData).map((([subtype, value]) => ({ subtype, value })))
);

export const findPIIMatch = (
  field: AggregatedFieldsStructureType,
  PIIUserData: PIIDataType[],
): PIIDataType[] => {
  if (field.subtype === undefined) {
    return [];
  }

  return PIIUserData.filter((pii: PIIDataType) => (
    field.groupBy === pii.groupBy
    && field.filterName === pii.filterName
    && field.subtype === pii.subtype
  ));
};

export const findGroupMatch = (
  field: AggregatedFieldsStructureType,
  PIIUserData: PIIDataType[],
): PIIDataType[] => {
  if (field.subtype === undefined) {
    return [];
  }

  return PIIUserData.filter((pii: PIIDataType) => (
    pii.groupBy === FIELD_GROUPING_VALUE.BY_FILTER
    && field.filterName === pii.filterName
    && field.filterName !== ''
  ));
};

export const mergeValueFromPIIDataToGropedFieldsStructure = (
  groupedFields: AggregatedFieldsStructureType[],
  PIIUserData: PIIDataType[],
): AggregatedFieldsStructureType[] => (
  groupedFields.map((field: AggregatedFieldsStructureType): AggregatedFieldsStructureType => {
    if (field.subtype === undefined) return field;

    const matchedPII: PIIDataType[] = findPIIMatch(field, PIIUserData);

    if (matchedPII.length) {
      return { ...field, value: matchedPII[0].value };
    }

    return field;
  })
);

// TODO: Match data with correct group_by_key
export const extractFieldsByKeys = (
  groupedFields: AggregatedFieldsStructureType[],
  PIIUserData: PIIDataType[],
): Partial<ICustomElement | PDFFieldType>[] => {
  const result: Partial<ICustomElement | PDFFieldType>[] = [];
  groupedFields.forEach((field) => {
    const matchedPII = findPIIMatch(field, PIIUserData);

    if (matchedPII.length) {
      field.keys.forEach((key) => {
        result.push({
          key,
          value: matchedPII[0].value,
          ...(matchedPII[0].groupByKey && { groupByKey: matchedPII[0].groupByKey }),
        });
      });
    }
  });
  return result;
};