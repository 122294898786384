import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { SUCCESS } from 'constants/api';
import { STRIPE_INTERVAL_PRIORITY } from 'constants/payment';
import { getStripeProducts } from 'services/api/payment';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { RootStateType } from 'store/reducers';
import { IStripeProductsResponse, StripeProductsByIntervalType } from 'types/payment/Stripe';

const useStripeProducts = () => {
  const { activePriceID } = useSelector((state: RootStateType) => state.payment.stripe);
  const [products, setProducts] = useState<StripeProductsByIntervalType>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeInterval, setActiveInterval] = useState<number>(0);

  useEffect(() => {
    const getProducts = async (): Promise<void> => {
      setIsLoading(true);
      const response = await apiErrorHandler<IStripeProductsResponse>(getStripeProducts);
      if (response.code === SUCCESS) {
        const groupedByInterval = response.data.products
          .reduce<StripeProductsByIntervalType>((accumulator, product) => {
            const { interval, intervalCount, id, intervalTitle } = product;
            const priority = (STRIPE_INTERVAL_PRIORITY[interval] ?? 999) * intervalCount;
            if (!accumulator[priority]) {
              accumulator[priority] = {
                prices: [],
                priority,
                title: intervalTitle,
              };
            }
            if (id === activePriceID) {
              setActiveInterval(priority);
            }
            accumulator[priority].prices.push(product);
            return accumulator;
          }, {});
        setProducts(groupedByInterval);
      } else {
        toast.error('Something went wrong. Can\'t get Plans');
      }
      setIsLoading(false);
    };

    getProducts();
  }, []);

  return {
    activeInterval,
    isLoading,
    products,
  };
};

export default useStripeProducts;