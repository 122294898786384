import { ChangeEvent, useEffect, useState } from 'react';

import { Timeout } from 'react-number-format/types/types';

import { HTMLFieldElement } from 'types/PdfTemplates';

const useDebounceInputField = (
  initialValue: string,
  onChangeHandler: (value: string) => void,
) => {
  const [localValue, setLocalValue] = useState<string>(initialValue);
  const [localTimeout, setLocalTimeout] = useState<Timeout>();

  useEffect(() => {
    setLocalValue(initialValue);
  }, [initialValue]);

  const debounce = (
    callback: (value: string) => void,
    wait = 500,
  ) => (event: ChangeEvent<HTMLFieldElement>) => {
    const newValue = event.target.value;
    setLocalValue(newValue);
    if (localTimeout) {
      clearTimeout(localTimeout);
    }
    const timeoutId = setTimeout(() => callback(newValue), wait);
    setLocalTimeout(timeoutId);
  };

  const processChangeWithDebounce = debounce(onChangeHandler);
  return {
    localValue,
    processChangeWithDebounce,
  };
};

export default useDebounceInputField;