export const SOMETHING_WENT_WRONG = 'Something went wrong';
export const SOMETHING_WENT_WRONG_MESSAGE = 'Something went wrong. Please, try again.';
export const SERVER_ERROR_MESSAGE = 'Internal server error, please contact administrator';
export const RESOURCE_NOT_EXISTS = 'The requested resource does not exist';
export const INVALID_USERNAME_OR_PASSWORD = 'Invalid username or password';

// status codes
export const SUCCESS_CODE = 200;
export const SUCCESS_CREATE_CODE = 201;
export const ACCEPTED_CODE = 202;
export const MULTI_STATUS_CODE = 207;

// Errors codes
export const BAD_REQUEST_CODE = 400;
export const UNAUTHORIZED_CODE = 401;
export const FORBIDDEN_CODE = 403;
export const NOT_FOUND_CODE = 404;
export const CONFLICT_CODE = 409;
export const UNPROCESSABLE_ENTITY_CODE = 422;
export const SERVER_ERROR_CODE = 500;