/* eslint-disable */
import { useEffect, MutableRefObject } from 'react';

export type UseOutsideAlerterType = <AlerterRefType>(refs: MutableRefObject<AlerterRefType | null>, setModal: any) => void;

export const useOutsideAlerter: UseOutsideAlerterType = (ref, setModal) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current instanceof HTMLDivElement && event.target instanceof Node) {
        if (ref.current && !ref.current.contains(event.target)) {
          setModal(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setModal]);
};