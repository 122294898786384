import { FC, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import AutocompleteField from 'components/Modals/MondayComModalWindow/AutocompleteField';
import {
  setColumnNameAndFieldToUpdateFieldsMapping,
  setFieldTypeCheckerModalOpen,
  setLaunchUpdateFieldsMapping,
} from 'store/actions/mondayComIntegration';
import { RootStateType } from 'store/reducers';
import { ICustomElement } from 'types/Editor';
import { FieldsMappingObjectType, MondayComColumnType, MondayComColumnTypes } from 'types/mondayCom/mondayComBoard';
import { PDFFieldType } from 'types/PdfTemplates';
import {
  createOptionsArrayFromStatusColumn,
  excludeNotImplementedColumns,
} from 'utils/MondayComIntegration/mondayComHelpers';

interface IMondayComMappingColumnSelect {
  boardId: string;
  field: Partial<ICustomElement> | PDFFieldType;
  columns: MondayComColumnType[];
  currentColumn: MondayComColumnType | null;
  onChangeFieldsMappingHandler: (fieldMappingObject: FieldsMappingObjectType) => void;
}

const MondayComMappingColumnSelect: FC<IMondayComMappingColumnSelect> = ({
  boardId,
  field,
  columns,
  currentColumn,
  onChangeFieldsMappingHandler,
}) => {
  const dispatch = useDispatch();
  const {
    columnNameAndFieldToUpdateFieldsMapping,
    launchUpdateFieldsMapping,
  } = useSelector((state: RootStateType) => state.mondayCom);

  useEffect(() => {
    /**
     * In order to change field options, we need to do that inside Slate component.
     * Modal window with this functionality exists in Slate component.
     * If a user confirm that, it launches upgrading fields mapping here.
     */
    if (launchUpdateFieldsMapping && columnNameAndFieldToUpdateFieldsMapping?.field.key === field.key) {
      onChangeFieldsMappingHandler({
        boardId,
        fieldKey: field.key ?? 0,
        columnName: columnNameAndFieldToUpdateFieldsMapping?.columnName ?? '',
      });
      dispatch(setColumnNameAndFieldToUpdateFieldsMapping(null));
      dispatch(setLaunchUpdateFieldsMapping(false));
    }
  }, [columnNameAndFieldToUpdateFieldsMapping, launchUpdateFieldsMapping]);

  const fieldTypeCheckerHandler = (value: string | null) => {
    if (value) {
      const targetColumn = columns.find((column) => column.title === value);
      if (targetColumn) {
        if (targetColumn.type === MondayComColumnTypes.STATUS) {
          const newOptions = createOptionsArrayFromStatusColumn(targetColumn.settings_str);
          dispatch(setFieldTypeCheckerModalOpen(true));
          dispatch(setColumnNameAndFieldToUpdateFieldsMapping({ field, columnName: value, newOptions }));
        } else {
          onChangeFieldsMappingHandler({ boardId, fieldKey: field.key ?? 0, columnName: value });
        }
      }
    } else {
      onChangeFieldsMappingHandler({ boardId, fieldKey: field.key ?? 0, columnName: '' });
    }
  };

  return (
    <AutocompleteField
      options={excludeNotImplementedColumns(columns).map((column: MondayComColumnType) => column.title)}
      currentValue={currentColumn ? currentColumn.title : ''}
      onChangeHandler={fieldTypeCheckerHandler}
      inputLabel="Select Column"
    />

  );
};

export default MondayComMappingColumnSelect;