export const SINGLE = '/single';

const ROUTES = {
  LOGIN: '/login',
  RESET_PASSWORD: '/reset-password',
  REGISTER: '/register',
  DASHBOARD: '/dashboard',
  DOCUMENTS: '/documents',
  DOCUMENTS_EDITOR: '/documents/editor',
  DOCUMENTS_EXECUTED: '/documents-executed',
  DOCUMENTS_EXECUTED_EDITOR: '/documents-executed/editor',
  DOCUMENTS_EXECUTED_EDITOR_SINGLE: `/documents-executed/editor/${SINGLE}`,
  FORMS: '/forms',
  FORM_EDITOR: '/forms/editor',
  FORM_EXECUTED: '/forms-executed',
  FORM_EXECUTED_EDITOR: '/forms-executed/editor',
  FORM_EXECUTED_EDITOR_SINGLE: `/forms-executed/editor/${SINGLE}`,
  PDF_LIST: '/PDFs',
  PDF_EDITOR: '/PDFs/editor',
  PDFS_EXECUTED: '/PDFs-executed',
  PDF_EXECUTED_EDITOR: '/PDFs-executed/editor',
  PDF_EXECUTED_EDITOR_SINGLE: `/PDFs-executed/editor/${SINGLE}`,
  COLLECTIONS: '/collections',
  COLLECTIONS_EDITOR: '/collections/editor',
  COLLECTIONS_EXECUTED_EDITOR: '/collections-executed/editor',
  PUBLIC_COLLECTION_EXECUTED: 'public-collection-executed',
  SECTIONS: '/sections',
  SECTIONS_EDITOR: '/sections/editor',
  SEND_DOCUMENTS: '/send-documents',
  USER_MANAGEMENT: '/user-management',
  GROUP_MANAGEMENT: '/group-management',
  PROFILE: '/profile',
  ORGANIZATION: '/organization',
  BRANDING: '/branding',
  NOTIFICATIONS: '/notifications',
  MANAGER_TEMPLATE: '/manager-template',
  BILLING: '/billing',
  SECURITY_SETTINGS: '/security-settings',
  REFERRAL_LINK: '/referral-link',
  INTEGRATIONS: '/integrations',
  SIGN_NOW: '/sign-now',
};

export default ROUTES;