import { Element as SlateElement } from 'slate';

import { FIELD_TYPE } from 'constants/editors';
import { CustomEditorType } from 'types/Editor';
import { PDFFieldType, PDFFieldTypeObject } from 'types/PdfTemplates';
import { IDocumentSection } from 'types/Sections';
import { filterNodes } from 'utils/editorHelpers';
import { filterSortedFormFieldsFromJSONObject } from 'utils/pdfTemplatesHelpers';

export const getFormsForFields = (editor: CustomEditorType) => (
  filterNodes(editor.children, (node) => {
    if (!SlateElement.isElement(node)) return false;
    return FIELD_TYPE.includes(node.type);
  })
);

export const getAllFieldsFromTemplate = (
  templateData: IDocumentSection[] | PDFFieldTypeObject,
  isPdf = false,
): PDFFieldType[] | SlateElement[] => {
  if (isPdf) {
    return filterSortedFormFieldsFromJSONObject(templateData as PDFFieldTypeObject);
  }

  return (templateData as IDocumentSection[]).map((sectionElement) => (
    filterNodes(sectionElement?.section.content_json || [], (node) => (
      SlateElement.isElement(node) && FIELD_TYPE.includes(node.type)
    )) || []
  )).reduce((acc, currentValue) => acc.concat(currentValue), []);
};