import { ChangeEvent, FC, RefObject, useState } from 'react';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import { styled } from '@mui/material/styles';
import vars from 'variables.module.scss';

interface IInputField {
  fullWidth?: boolean;
  name?: string;
  type?: string;
  variant?: 'outlined' | 'standard' | 'filled' | undefined;
  size?: 'small' | 'medium' | undefined;
  label?: string;
  value?: string;
  required?: boolean;
  disabled?: boolean;
  showError?: boolean;
  helperText?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  multiline?: boolean;
  maxRows?: number;
  inputProps?: { maxLength: number };
  inputRef?: RefObject<HTMLInputElement | HTMLTextAreaElement>;
  enableLabelShrink?: boolean;
}

const StyledInput = styled(TextField)({
  '& label.Mui-focused': {
    color: vars.brandPrimaryFocused,
    borderWidth: '1px',
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: vars.white,
    '&.Mui-focused fieldset': {
      borderColor: vars.brandPrimaryFocused,
      borderWidth: '1px',
    },
  },
});

const InputField: FC<IInputField> = ({
  multiline = false,
  fullWidth = true,
  name = 'name',
  type = 'text',
  variant = 'outlined',
  size = 'small',
  label = 'Label',
  value = '',
  required = false,
  disabled = false,
  showError = false,
  helperText = '',
  onChange = () => null,
  maxRows = 1,
  inputProps = {},
  inputRef = null,
  enableLabelShrink = false,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <StyledInput
      InputLabelProps={enableLabelShrink ? { shrink: enableLabelShrink } : {}}
      InputProps={type === 'password'
        ? {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowPassword((prevState) => !prevState)}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        } : {}}
      inputRef={inputRef}
      name={name}
      fullWidth={fullWidth}
      type={showPassword ? 'text' : type}
      variant={variant}
      size={size}
      label={label}
      value={value}
      onChange={onChange}
      required={required}
      disabled={disabled}
      error={showError}
      helperText={helperText}
      multiline={multiline}
      maxRows={maxRows}
      inputProps={inputProps} // eslint-disable-line react/jsx-no-duplicate-props
    />
  );
};

export default InputField;