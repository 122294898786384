import { SUBTYPE_EMAIL, SUBTYPE_FIRST_NAME, SUBTYPE_LAST_NAME, SUBTYPE_PHONE_MOBILE } from 'constants/editors';
import { DELETE_CONFIRMATION_MODAL_TEXT } from 'constants/general';
import {
  SIGNATURE_FIELD_DEFAULT_TITLE,
  SIGNATURE_FIELD_DEFAULT_VARIATION,
  SIGNATURE_FIELD_INITIALS_TITLE,
} from 'constants/signatures';
import { IUserManagementFields, ModalUserType, USER_MANAGEMENT_MODAL_STATUS } from 'types/userProfile';

export const modalUserManagementFieldsGenerator = (
  user: ModalUserType,
  status: USER_MANAGEMENT_MODAL_STATUS,
  addPhoneNumberField: boolean = false,
): IUserManagementFields[] => {
  const basicUserFields: IUserManagementFields[] = [
    {
      key: SUBTYPE_FIRST_NAME,
      label: 'First name',
      type: 'text',
      value: user?.first_name || '',
      required: true,
      errorMessage: '',
    },
    {
      key: SUBTYPE_LAST_NAME,
      label: 'Last name',
      type: 'text',
      value: user?.last_name || '',
      required: true,
      errorMessage: '',
    },
    {
      key: SUBTYPE_EMAIL,
      label: 'Email',
      type: 'email',
      value: user?.email || '',
      required: true,
      errorMessage: '',
    },
  ];

  if (addPhoneNumberField) {
    basicUserFields.push({
      key: SUBTYPE_PHONE_MOBILE,
      label: 'Cell Phone',
      type: 'tel',
      value: user?.phone_number || '',
      required: true,
      errorMessage: '',
    });
  }

  switch (status) {
    case USER_MANAGEMENT_MODAL_STATUS.EDIT:
    case USER_MANAGEMENT_MODAL_STATUS.ADD:
      return basicUserFields;
    default:
      return [];
  }
};

export const groupManagementModalField: IUserManagementFields = {
  key: 'group_name',
  label: 'Type group name here...',
  type: 'text',
  value: '',
  required: true,
  errorMessage: '',
};

export const getText = (sectionName: string): string => `${DELETE_CONFIRMATION_MODAL_TEXT} ${sectionName}`;

export const deleteDocumentModalText: string = 'Are you sure you want to delete? This action is permanent';

export const getSignatureModalTitle = (signatureVariation: string): string => (
  signatureVariation === SIGNATURE_FIELD_DEFAULT_VARIATION
    ? SIGNATURE_FIELD_DEFAULT_TITLE
    : SIGNATURE_FIELD_INITIALS_TITLE
);

export default undefined;