import { Editor, Element, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import {
  TABLE_QUESTION_BODY, TABLE_QUESTION_ROW,
} from 'constants/editors';
import {
  DEFAULT_TABLE_QUESTION_CELL_HEIGHT,
  DEFAULT_TABLE_QUESTION_CELL_WIDTH,
  TABLE_QUESTION_CELL_LIST,
} from 'constants/tableQuestion';
import { ICustomElement } from 'types/Editor';
import { findEditorNodesByPosition } from 'utils/TableQuestion/tableQuestionHelpers';

export const updateColumnCells = (editor: Editor, element: ICustomElement, newValues: Partial<ICustomElement>) => {
  const path = ReactEditor.findPath(editor, element);
  if (!path) return;
  const columnIndex = path[path.length - 1];
  const tableBody = findEditorNodesByPosition(editor, path, [TABLE_QUESTION_BODY]);
  if (!tableBody) return;
  const tableRows = findEditorNodesByPosition(editor, tableBody[0][1], [TABLE_QUESTION_ROW]);
  if (!tableRows) return;
  tableRows.forEach(([rowNode]) => {
    const cellNode = rowNode.children[columnIndex];
    if (cellNode) {
      const cellPath = ReactEditor.findPath(editor, cellNode);
      Transforms.setNodes(
        editor,
        newValues,
        { at: cellPath },
      );
    }
  });
};

export const updateRowCells = (editor: Editor, element: ICustomElement, newValues: Partial<ICustomElement>) => {
  const path = ReactEditor.findPath(editor, element);
  if (!path) return;
  const tableRows = findEditorNodesByPosition(editor, path, [TABLE_QUESTION_ROW]);
  if (!tableRows) return;
  const cells = tableRows[0][0].children;
  cells.forEach((cell) => {
    if (Element.isElement(cell) && TABLE_QUESTION_CELL_LIST.includes(cell.type)) {
      const cellPath = ReactEditor.findPath(editor, cell);
      Transforms.setNodes(
        editor,
        newValues,
        { at: cellPath },
      );
    }
  });
};

export const getResizeStyleWidth = (width: number | undefined) => ({
  minWidth: `${width ?? DEFAULT_TABLE_QUESTION_CELL_WIDTH}px`,
  maxWidth: `${width ?? DEFAULT_TABLE_QUESTION_CELL_WIDTH}px`,
  width: `${width ?? DEFAULT_TABLE_QUESTION_CELL_WIDTH}px`,
});

export const getResizeStyleHeight = (height: number | undefined) => ({
  minHeight: `${height ?? DEFAULT_TABLE_QUESTION_CELL_HEIGHT}px`,
  maxHeight: `${height ?? DEFAULT_TABLE_QUESTION_CELL_HEIGHT}px`,
  height: `${height ?? DEFAULT_TABLE_QUESTION_CELL_HEIGHT}px`,
});