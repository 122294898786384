import { FC, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { FormFeedback, Input } from 'reactstrap';

import { Check, Replay } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import AutocompleteSections from 'components/AutocompleteSections';
import Outline from 'components/Sidebar/Outline';
import SortableItem from 'components/SortableItem';
import { RootStateType } from 'store/reducers';
import { ITemplate } from 'types/Templates';
import toggleShowSections from 'utils/collectionsNavigatorHelpers';

interface INavigatorProps {
  outlineElements?: ITemplate[];
  updateElementsOrder?: (sortedNavList: ITemplate[]) => void;
  updateElements?: (oldElementId: number, newElementId: number, isAddNewMode?: boolean) => void;
}

const MultiTemplatesNavigator: FC<INavigatorProps> = ({
  outlineElements = [],
  updateElementsOrder = () => null,
  updateElements = () => null,
}) => {
  const [isAddNewMode, setAddNewMode] = useState<boolean>(false);
  const [editElementId, setEditElementId] = useState<number | undefined>(undefined);
  const [selectedTemplate, setSelectedTemplate] = useState<ITemplate | null>(null);
  const [previousTemplate, setPreviousTemplate] = useState<ITemplate | undefined>(undefined);
  const [filteredTemplates, setFilteredTemplates] = useState<ITemplate[]>([]);

  const documents: ITemplate[] = useSelector((state: RootStateType) => state.user.documents);

  useEffect(() => {
    if (documents) {
      setFilteredTemplates(documents.filter(
        (document: ITemplate) => !outlineElements.find((element: ITemplate) => element.id === document.id),
      ));
    }
  }, [outlineElements, documents]);

  const setEditableStatesToDefault = () => {
    setSelectedTemplate(null);
    setEditElementId(undefined);
    setAddNewMode(false);
    setPreviousTemplate(undefined);
  };

  const onUpdateItem = (save = false) => {
    if (!previousTemplate && !save) {
      updateElements(0, 0, isAddNewMode);
      setEditableStatesToDefault();
      return;
    }

    const newElementId = selectedTemplate ? selectedTemplate.id : 0;
    if (selectedTemplate || isAddNewMode) {
      const elementId = editElementId || 0;
      updateElements(elementId, newElementId, isAddNewMode);
    }
    setEditableStatesToDefault();
  };

  const createElementHandler = () => {
    if (!isAddNewMode && !(editElementId && editElementId > 0)) {
      setAddNewMode(true);
      setEditElementId(0);
      updateElements(0, 0);
    } else {
      onUpdateItem(true);
    }
  };

  const onEditItem = (id: number) => {
    const previousChoosenTemplate = documents.filter((document) => document.id === id)[0];
    setPreviousTemplate(previousChoosenTemplate);
    setEditElementId(id);
  };

  const onDeleteItem = (id: number) => {
    if (id) {
      updateElements(id, 0);
    }
    setEditableStatesToDefault();
  };

  const onSortEndHandler = (sortedNavList: ITemplate[]) => {
    updateElementsOrder(sortedNavList);
  };

  const renderOnEditCheckButton = () => {
    if (selectedTemplate && (isAddNewMode || editElementId)) {
      return <Check className="settings-icon close" onClick={() => onUpdateItem(true)} />;
    }
    if (!selectedTemplate) {
      return <Check className="settings-icon" color="disabled" />;
    }
  };

  const renderOnEditRevertButton = () => {
    const justForNewMode = isAddNewMode && editElementId === 0;
    const onClick = justForNewMode ? () => onUpdateItem() : setEditableStatesToDefault;
    return <Replay className="delete-icon close" onClick={onClick} />;
  };

  const renderEditElement = (id: number = 0) => (
    <span className="d-block w-100">
      <div className="p-0">
        <AutocompleteSections
          sections={filteredTemplates}
          onSelectSection={setSelectedTemplate}
          placeholderText="Type to search templates..."
          previousTemplate={previousTemplate}
        />
      </div>
      { renderOnEditCheckButton() }
      { renderOnEditRevertButton() }
      <Input type="hidden" invalid={id === 0} />
      <FormFeedback className="mt-0">
        <small>Please, confirm the selection for saving, or clean up the row.</small>
      </FormFeedback>
    </span>
  );

  const renderElementContent = (element: any) => {
    if (element.id === editElementId) {
      return renderEditElement(element.id);
    }
    return (
      <div className="collection-outline-element">
        <div className="d-flex">
          <span className="text-truncate">{element.name}</span>
          {
            element.sections && element.sections.length > 1 ? (
              <button
                type="button"
                id={`toggle_${element.id}_${element.key}`}
                className="toggle-button-show-sections"
                onClick={() => toggleShowSections(element)}
                style={{ transform: 'rotate(0.5turn)' }}
              >
                <ExpandMoreIcon />
              </button>
            ) : null
          }
        </div>
        <div id={`sections_${element.id}_${element.key}`} style={{ display: 'block' }}>
          {
            element.sections.map((section: any, index: number) => (
              <section
                key={`Element_${index + 1}`}
                className="py-1"
              >
                {section.name}
              </section>
            ))
          }
        </div>
      </div>
    );
  };

  const itemElements = outlineElements && outlineElements.map((element: ITemplate, index: number) => (
    <SortableItem
      index={index}
      key={element.id}
      id={element.id}
      value={renderElementContent(element)}
      removeItem={onDeleteItem}
      isEditingItem={editElementId ? editElementId > 0 : false}
      showRemoveButton={false}
      editItem={onEditItem}
      readonly={isAddNewMode}
    />
  ));

  return (
    <Outline
      elements={outlineElements}
      createElementHandler={createElementHandler}
      onSortEndHandler={onSortEndHandler}
      customButtonTitle="Add document"
    >
      {itemElements}
    </Outline>
  );
};

export default MultiTemplatesNavigator;