/* eslint-disable */
import React, { useState } from 'react';
import { Button, Form, FormFeedback, FormGroup, Label, Input, ModalFooter } from 'reactstrap';
import { Descendant } from 'slate';
import StateDropdown from 'components/StateDropdown';
import Editor from 'components/Editor';
import { INITIAL_EDITOR_VALUE } from 'constants/editors';
import { SECTION_NAME_LABEL } from 'constants/general';
import { useRolePermission } from 'hooks/useRolePermission';
import { IStateList } from 'types/Metadata';
import { IAddNewSectionModalProps, ISectionRequestObj } from 'types/Sections';
import { IErrorField } from 'types/validation';
import { validationForm } from 'utils/validation';

const AddNewSectionModal: React.FC<IAddNewSectionModalProps> = (props) => {

  const {
    states,
    createSection,
    closeModal
  } = props;

  const [ formErrors, setFormErrors ] = useState<IErrorField>({
    section_name: null
  });
  const [sectionName, setSectionName] = useState<string>("");
  const [sectionContent, setSectionContent] = useState<Descendant[]>(INITIAL_EDITOR_VALUE);
  const [selectedStates, setSelectedStates] = useState<IStateList[]>([]);
  const { permissionUsingStates } = useRolePermission();

  const validateSectionName = (sectionName: string) => {
    const validateResult = validationForm([
      { titleField: SECTION_NAME_LABEL, nameField: 'section_name', valueField: sectionName, required: true }
    ]);
    setFormErrors((prev) => ({ ...prev, ...validateResult.validationFields }));
    return validateResult;
  }

  const onChangeSectionName: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    validateSectionName(target.value);
    setSectionName(target.value);
  }

  const onCloseModal: React.MouseEventHandler<HTMLButtonElement> = () => {
    closeModal(false);
  }

  const createNewSection = () => {
    const validateResult = validateSectionName(sectionName);
    if (validateResult.isError) return;

    const newSection: ISectionRequestObj = {
      name: sectionName.trim(),
      description: '',
      content_json: sectionContent,
      states: selectedStates.map((state) => ({ id: state.value })),
    };

    createSection(newSection);
    closeModal(false);
  };

  return (
    <>
      {permissionUsingStates && (
        <div className="d-flex justify-content-end mb-2">
          <div className="w-50">
            <StateDropdown
              setSelectedStates={setSelectedStates}
              states={states}
              selectedStates={selectedStates}
            />
          </div>
        </div>
      )}
      <Form>
        <FormGroup className="mb-4">
          <Label>{SECTION_NAME_LABEL}</Label>
          <Input
            name="section_name"
            value={sectionName}
            onChange={onChangeSectionName}
            invalid={formErrors.section_name ? true : false}
          />
          <FormFeedback>{formErrors.section_name}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>Content</Label>
          <Editor
            content={sectionContent}
            onChange={setSectionContent}
            modal={true}
          />
        </FormGroup>
      </Form>
      <ModalFooter className="justify-content-center">
        <Button color="primary" className="mr-3" onClick={createNewSection}>
          Add
        </Button>
        <Button color="secondary" outline onClick={onCloseModal}>
          Cancel
        </Button>
      </ModalFooter>
    </>
  );
};

export default AddNewSectionModal;
