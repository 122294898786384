import { Editor, Range, Transforms } from 'slate';

import { LABEL_CONFIGURATION } from 'types/Editor';

const withFields = (editor: Editor) => {
  const { deleteForward, deleteBackward } = editor;

  // eslint-disable-next-line no-param-reassign
  editor.deleteBackward = (...args) => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const node = Editor.node(editor, Editor.path(editor, selection));
      const previous = Editor.previous(editor, { at: node[1] });

      if (
        previous?.length
        && previous[0]
        && 'labelConfiguration' in previous[0]
        && previous[0].labelConfiguration === LABEL_CONFIGURATION.NONE
      ) {
        if (selection.anchor.offset === 0) {
          const nameLength = previous[0].name?.length;
          Transforms.move(editor, { distance: nameLength, unit: 'character', reverse: true });
        }
      }
    }

    deleteBackward(...args);
  };

  // eslint-disable-next-line no-param-reassign
  editor.deleteForward = (...args) => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const node = Editor.node(editor, Editor.path(editor, selection));
      const next = Editor.next(editor, { at: node[1] });

      if (
        next?.length
        && next[0]
        && 'labelConfiguration' in next[0]
        && next[0].labelConfiguration === LABEL_CONFIGURATION.NONE
      ) {
        if (selection.anchor.offset === Editor.end(editor, node[1]).offset) {
          const nameLength = next[0].name?.length;
          Transforms.move(editor, { distance: nameLength, unit: 'character', reverse: false });
        }
      }
    }

    deleteForward(...args);
  };

  return editor;
};

export default withFields;