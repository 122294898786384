/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-use-before-define */
/* eslint-disable no-case-declarations */
/* eslint-disable no-param-reassign */
import { Dispatch as ReduxDispatch } from 'redux';

import moment from 'moment';
import { Element as SlateElement } from 'slate';
import { jsx } from 'slate-hyperscript';

import { ANY_EDGE_SPACES_REGEX, NEW_LINE_REGEX } from 'constants/copyPaste';
import {
  ATTACHMENT_FIELD,
  CHECK_LIST_ITEM,
  CHECK_LIST_ITEM_CHECKED,
  DATE_FIELD,
  DROPDOWN_TYPES,
  ELEMENT_TAGS,
  FIELD_TYPE,
  HEADERS_MARKS,
  LIST_ITEM,
  LIST_ITEM_TYPES,
  LIST_STYLE_DECIMAL_CHAPTER,
  LIST_STYLE_DECIMAL_LEGAL,
  LIST_TYPES,
  NEW_LINE,
  NO_BREAK_SPACE,
  NUMBERED_LIST,
  NUMBERED_LIST_ITEM,
  PARAGRAPH,
  QUESTION_ANSWER_SELECT,
  QUESTION_ANSWER_STRING,
  QUESTION_FIELD,
  RECIPIENT_ASSIGNMENT,
  SELECT_FIELD,
  SELECT_FIELD_TYPE_CHECKBOX,
  SELECT_FIELD_TYPE_RADIO,
  SELECT_FIELD_TYPE_SELECT,
  SIGNING_FIELD,
  TABLE_TAG_NAMES,
  TABULATION_STEP,
  TEXT_FIELD,
  TEXT_TAGS,
  TEXTAREA_FIELD,
  WHITE_SPACE,
} from 'constants/editors';
import { FIELD_MASK_CUSTOM_TEXT_DEFAULT, TEXT_FIELD_MASKS } from 'constants/fieldPropertiesTab';
import { TEXTAREA_MAX_LEN } from 'constants/validation';
import { addFieldToAssignments } from 'store/actions/editorSlate';
import { BlockFormatType, ICustomElement, ListStyleViewType, SelectFieldOptionType } from 'types/Editor';
import { PDFFieldType } from 'types/PdfTemplates';
import { getAssignmentsMainPartInStore } from 'utils/assignmentsHelpers';
import { getFieldLabelFromChildren } from 'utils/createNameConstant';
import { addPropertiesByType, generateNumber } from 'utils/editorFieldHelpers';
import { setEditorNodeFont } from 'utils/editorTextStyleHelpers';
import { filterFontTypeByAvailable } from 'utils/reduceFontType';
import { isTopLevelTextNode } from 'utils/slateEditorUtils';

const WORD_DOCUMENT = 'Word.Document';
const WEB_MSO_LIST_MARKER = 'MSOList';
// eslint-disable-next-line max-len
const CSS_LIST_RULES_REGEX = /(?<css>@list [\s\S]*?(?<listID>l[\d]*)((:level)(?<level>[\d]*))?(?<levelRule>[\s\S]*?}))/gi;

type CSSRuleType = {
  selector: string;
  listID: string;
  level: string;
  levelRule: string;
  styles: string;
};

/**
 * '#comment' - MS Word helper nodes, like: <!--[if !supportLists]--><span ...>...</span><!--[endif]-->
 * 'O:P' - is MS Word Node separator, like: <o:p></o:p> or <o:p>&nbsp;</o:p>
 * @param nodeName - a HTML element node name, like A, SPAN, DIV, etc.
 * @returns true if it is the node name we need to insert
 */
const isValidWordNode = (nodeName: string): boolean => (
  !['#comment', 'O:P'].includes(nodeName)
);

// MS office 365 Online doc lists begin with "NumberListStyle" or "BulletListStyle".
const isMsoWebList = (node: SlateElement) => (
  LIST_TYPES.includes(node.type) && !!(node.className?.match(WEB_MSO_LIST_MARKER))
);

// Docx lists begin with "MsoListParagraph" or <P> element contains "mso-list:l(number) style"
const isMsoList = (el: Element): boolean => {
  const elementClass = el.attributes?.getNamedItem('class')?.value || '';
  return !!(
    elementClass.match(/MsoListParagraph/g)
    || (
      el.nodeName === 'P' && (el.attributes?.getNamedItem('style')?.value || '').match(/mso-list:l(\d+)/)
    )
  );
};

const isNumberedListType = (nodeName: string = ''): boolean => nodeName === 'OL';

export const getStylesFromWordDocument = (parsedHtmlHeader: HTMLHeadElement): CSSRuleType[] => {
  const isWordSourse = parsedHtmlHeader && parsedHtmlHeader
    .querySelector('meta[name="ProgId"]')?.getAttribute('content') === WORD_DOCUMENT;

  const cssRules: CSSRuleType[] = [];

  if (isWordSourse) {
    const styleString = (parsedHtmlHeader.querySelector('style')?.innerHTML || '').replace(/[\n|\t]*/g, '');
    const listDefinitionsGenerator = styleString.matchAll(CSS_LIST_RULES_REGEX);
    const listDefinitions = Array.from(listDefinitionsGenerator);
    listDefinitions?.forEach((element) => {
      const { groups } = element;
      cssRules.push({
        selector: '@list ',
        listID: groups?.listID || '',
        level: groups?.level || '',
        levelRule: groups?.levelRule || '',
        styles: groups?.css || '',
      });
    });
  }
  return cssRules;
};

const getListTemplate = (element: HTMLElement, cssRules: CSSRuleType[] | null) => {
  let listTemplate: ListStyleViewType | undefined;
  const { dataset } = element;

  if (dataset && cssRules) {
    // parse list templates from MS Word Desktop - from HTML buffer head
    const listId = `l${dataset.listId}`;
    const currentListRule = cssRules
      .find((rule) => rule.listID === listId && ['', '1'].includes(rule.level));
    if (currentListRule && currentListRule.levelRule) {
      // MS Word list repeated markers for "Legal" template: decimal, alpha-lower, roman-lower.
      const wordListLegalMarkers = '67698703 67698713 67698715';
      const isHybridList = currentListRule.levelRule.includes(wordListLegalMarkers);

      if (isHybridList) {
        listTemplate = LIST_STYLE_DECIMAL_LEGAL;
      } else {
        const ruleMatch = currentListRule.levelRule.matchAll(/mso-list-template-ids:-?(?<listTemplate>\d+?);/gi);
        const listDefinitions: any[] = Array.from(ruleMatch);
        listDefinitions?.forEach((element) => {
          // MS Word list mark template ID in exported document
          const wordListLegalMarkID = '67698717';
          const wordListChapterMarkID = '67698719';

          const groupsListTemplate = element.groups?.listTemplate || '';

          switch (groupsListTemplate) {
            case wordListLegalMarkID:
              listTemplate = LIST_STYLE_DECIMAL_LEGAL;
              break;
            case wordListChapterMarkID:
              listTemplate = LIST_STYLE_DECIMAL_CHAPTER;
              break;
            default:
              break;
          }
        });
      }
    }
  } else if (dataset?.listDefnProps) {
    /**
     * 'Legal' list items, e.g.: '1. a. b. 2. a. b.' - have the next parameter value:
     * data-list-defn-props="{"335552541":0,"335559684":-1,"335559685":720,"335559991":360,"469769242":[65533,0],
     * "469777803":"left","469777804":"%1.","469777815":"hybridMultilevel"}"
     *
     * 'Chapter' list items, e.g.: '1. 1.1. 1.2.' - have the next parameter value:
     * data-list-defn-props="{"335552541":0,"335559684":-1,"335559685":720,"335559991":360,"469769242":[65533,0],
     * "469777803":"left","469777804":"%1.", "469777815":"multilevel","469778510":"default"}"
     *
     */
    const listDefnProps = dataset.listDefnProps;
    if (listDefnProps.includes('"469777815":"hybridMultilevel"')) {
      listTemplate = LIST_STYLE_DECIMAL_LEGAL;
    } else if (listDefnProps.includes('"469777815":"multilevel"')) {
      listTemplate = LIST_STYLE_DECIMAL_CHAPTER;
    }
  }

  return listTemplate;
};

export const makeDeserializer = () => {
  const TEXT_NODE_TYPE = 3;
  const ELEMENT_NODE_TYPE = 1;
  let cssRules: CSSRuleType[] | null = null;

  const deserializeWebMSOList = (listElements: ICustomElement[], listStyle: ListStyleViewType = '') => {
    const listWrapper = listElements[0];
    const listType = listWrapper?.type;

    const childrenItems: ICustomElement[] = [];
    listElements.forEach((listElement) => {
      if (listElement.children?.length) {
        listElement.children.forEach((listItem) => {
          childrenItems.push(
            jsx('element', { ...listItem, className: listElement.className }, listItem.children || []),
          );
        });
      }
      return listElement;
    });

    const children = childrenItems.filter((element) => element !== null);
    if (children?.length) {
      return jsx('element', { ...listWrapper }, parseListTree(listType, children, listStyle));
    }
    return null;
  };

  const deserialize = (el: HTMLElement | any, ...additionalProps: any) => {
    if (el?.attributes?.getNamedItem('class')?.value.match(/done/g)) {
      return null;
    }

    if (additionalProps && additionalProps[0]?.cssRules) {
      cssRules = additionalProps[0].cssRules;
    }

    if (isMsoList(el)) {
      return deserializeList(el);
    }

    return deserializeContentElements(el, deserializeWebMSOList);
  };

  const getWrappedList = (childElementList: ICustomElement[], listType: string, listStyle: ListStyleViewType = '') => (
    childElementList.map((element) => {
      if (element.type !== LIST_ITEM && element.type !== NUMBERED_LIST_ITEM) {
        return { ...element };
      }
      const startChilren = element.children as ICustomElement[];

      const firstListItemPosition = startChilren?.findIndex((child) => (
        LIST_ITEM_TYPES.includes(child.type)
      ));
      if (firstListItemPosition >= 0) {
        const listStyleMark = listType === NUMBERED_LIST ? { listStyle } : {};
        const nodeToInsert: ICustomElement = {
          type: listType as BlockFormatType,
          children: getWrappedList(startChilren.slice(firstListItemPosition) || [], listType, listStyle),
          ...listStyleMark,
        };
        return { ...element, children: [...startChilren.slice(0, firstListItemPosition), nodeToInsert] };
      }
      return { ...element };
    })
  );

  const parseListTree = (listType: string, startList: ICustomElement[], listStyle: ListStyleViewType = '') => {
    let newListOfChildren = startList;

    const levelsArray: number[] = [];
    startList.forEach((childItem) => {
      const currentLevel = parseInt(
        ((childItem.className || '').match(/level(\d+)/)?.[0] || '').replace('level', ''),
      );
      if (currentLevel) {
        levelsArray.push(currentLevel);
      }
    });

    const isLevel = (className: string, level: number) => ((className || '').split(' ')).includes(`level${level}`);

    const isCurrentLevelFound = (startLevel: number): boolean => {
      let foundLevel = false;
      const firstCurrentLevelPosition = newListOfChildren.findIndex((child) => (
        LIST_ITEM_TYPES.includes(child.type) && isLevel(child.className || '', startLevel)
      ));
      if (firstCurrentLevelPosition > 0 && newListOfChildren[firstCurrentLevelPosition - 1]) {
        foundLevel = true;
        newListOfChildren[firstCurrentLevelPosition - 1].children = [
          ...newListOfChildren[firstCurrentLevelPosition - 1].children,
          newListOfChildren[firstCurrentLevelPosition],
        ];
        newListOfChildren = newListOfChildren.filter((element, index) => index !== firstCurrentLevelPosition);
      }
      return foundLevel;
    };

    const maxLevel = Math.max(...levelsArray);
    const minLevel = Math.min(...levelsArray);
    const endPoint = (minLevel > 0) ? (minLevel - 1) : 0;
    for (let startLevel = maxLevel; startLevel > endPoint; startLevel--) {
      // we use 'iter' variable to prevent an infinite loop
      let iter = 0;
      while (iter < 300 && isCurrentLevelFound(startLevel)) {
        iter++;
      }

      if (startLevel > endPoint + 1) {
        newListOfChildren = newListOfChildren.filter((childItem) => !isLevel(childItem.className || '', startLevel));
      }
    }

    return getWrappedList(newListOfChildren, listType, listStyle);
  };

  const deserializeList = (el: HTMLElement) => {
    const siblings = getSiblings(el);
    const type = getListType(el);
    const listWrapper = document.createElement(type);
    for (let i = 0; i < siblings.length; i++) {
      listWrapper.appendChild(siblings[i]);
    }

    const listTemplate = getListTemplate(el, cssRules);
    if (listTemplate && [LIST_STYLE_DECIMAL_CHAPTER, LIST_STYLE_DECIMAL_LEGAL].includes(listTemplate)) {
      listWrapper.setAttribute('data-list-style', listTemplate);
    }

    const attrs = ELEMENT_TAGS[type](listWrapper);
    const children = Array.from(listWrapper.childNodes)
      .map((child) => deserializeListItem(child as HTMLElement, attrs.type))
      .flat();
    return jsx('element', attrs, parseListTree(attrs.type, children, listTemplate));
  };

  const deserializeListItem = (el: HTMLElement, listType: string = ''): ICustomElement => {
    const listItemType = (listType === NUMBERED_LIST) ? NUMBERED_LIST_ITEM : LIST_ITEM;
    const level = el.getAttribute('style') || '';

    const childNodes = el.childNodes;
    const content = Array.from(childNodes).map(deserialize).flat();
    const children = Array.from(childNodes)
      .filter((child) => isValidWordNode(child.nodeName))
      .filter((child) => (TEXT_TAGS[child.nodeName] || child.nodeName === '#text'));
    // Note: children[0] - is a list item marker
    const firstContentChildren = (children.length > 1) && children[1] as HTMLElement;
    const elementFont = firstContentChildren ? setEditorNodeFont(firstContentChildren.style) : {};

    return jsx(
      'element',
      { type: listItemType, className: 'level'.concat(level), ...elementFont },
      content,
    );
  };

  const cleanUpTextContent = (element: HTMLElement) => {
    const { parentNode, textContent } = element;
    if (parentNode) {
      if (parentNode.nodeName === 'O:P' && parentNode.parentNode?.nodeName === 'P') {
        return textContent;
      }
    }

    // fix for whitespaces while copying from Web pages
    if (textContent && textContent.length === 1 && textContent === NO_BREAK_SPACE) {
      return element.textContent;
    }

    if (
      !textContent
      || [
        NEW_LINE + NEW_LINE,
        NEW_LINE + NEW_LINE + NEW_LINE,
      ].includes(textContent)
    ) {
      return null;
    }

    if (textContent.match(ANY_EDGE_SPACES_REGEX)) {
      const cleanedText = textContent && textContent.replace(ANY_EDGE_SPACES_REGEX, NO_BREAK_SPACE);
      if (cleanedText === NO_BREAK_SPACE) return cleanedText;
      if (!cleanedText?.trim().length) return null;
    }

    // sometimes Word adds line breaks when pasting
    const regex = /\n(?!\n)/g;
    element.textContent = textContent.replace(regex, WHITE_SPACE);
    return element.textContent;
  };

  const cleanUpTextChildren = (nodeName: string, element: HTMLElement, children: any[]) => {
    const attrs = TEXT_TAGS[nodeName](element);

    const newChildren = children.map((child) => {
      if (child?.type) {
        return child;
      }

      if (child?.text && (child?.italic || child?.strikethrough || child?.bold || child?.underline)) {
        const textSize = (attrs.size && !child.size)
          ? { size: attrs.size, [DROPDOWN_TYPES.TEXT_SIZE]: attrs[DROPDOWN_TYPES.TEXT_SIZE] }
          : {};
        const childAttrs = {
          italic: child?.italic || attrs?.italic || false,
          strikethrough: child?.strikethrough || attrs?.strikethrough || false,
          bold: child?.bold || attrs?.bold || false,
          underline: child?.underline || attrs?.underline || false,
          ...textSize,
        };
        child.text = child.text.replace(NEW_LINE_REGEX, WHITE_SPACE);
        return jsx('text', childAttrs, child);
      }

      return jsx('text', attrs, child);
    });

    return newChildren;
  };

  const deserializeContentElements = (element: HTMLElement, afterBodyMethod?: (arg: any) => unknown): any => {
    if (element.nodeType === ELEMENT_NODE_TYPE && (
      !isValidWordNode(element.nodeName)
      || String(element.attributes?.getNamedItem('style')?.value).match(/mso-list:Ignore/g)
    )) {
      return null;
    }

    if (element.nodeType === TEXT_NODE_TYPE) {
      return cleanUpTextContent(element);
    }
    if (element.nodeType !== ELEMENT_NODE_TYPE || element.nodeName === 'META') {
      return null;
    }
    if (element.nodeName === 'BR') {
      return NEW_LINE;
    }

    const { nodeName, className, dataset, parentNode, attributes } = element;
    let parent: HTMLElement = element;

    if (attributes && attributes.getNamedItem('class')) {
      const elementClassValue = attributes.getNamedItem('class')?.value || '';
      // word comment anchor and text annotations
      if (elementClassValue.match(/msocomanchor|MsoCommentText/g)) {
        return null;
      }
      // docs.google tabulation white space
      if (elementClassValue.match(/Apple-tab-span/g)) {
        return TABULATION_STEP;
      }
    }

    if (nodeName === 'SPAN') {
      if (dataset.fieldKey) {
        return deserializeFormFields(element);
      }

      if (dataset.elementType === CHECK_LIST_ITEM) {
        const text = element.firstChild?.textContent || '';
        const attrs = {
          type: CHECK_LIST_ITEM,
          checked: className.split(WHITE_SPACE).includes(CHECK_LIST_ITEM_CHECKED),
        };
        return jsx('element', attrs, [{ text }]);
      }
    }

    if (['INPUT', 'SELECT', 'TEXTAREA'].includes(nodeName)) {
      return deserializeFormFields(element);
    }

    if (TABLE_TAG_NAMES.includes(nodeName) && element.childNodes.length > 1) {
      element.childNodes.forEach((childrenElement: ChildNode) => {
        if (childrenElement && ['#text', 'COLGROUP'].includes(childrenElement.nodeName)) {
          element.removeChild(childrenElement);
        }
      });
    }

    if (
      nodeName === 'PRE'
      && element.childNodes[0]?.nodeName === 'CODE'
    ) {
      parent = element.childNodes[0] as HTMLElement;
    }

    let children = Array.from(parent.childNodes).map(deserialize).flat();
    if (children.length === 0) {
      children = [{ text: '' }];
    }

    if (nodeName === 'BODY') {
      // Remove empty nodes
      const startChildren = children.filter(
        (child, index) => child !== null && (
          index === 0 || (!child.text && child !== NEW_LINE) || (child.text && child.text !== NEW_LINE)
        ),
      );
      // Fix text elements while copying from MS Word - apply top level nodes for them
      const topLevelChildren = unwrapList(transformToTopLevelNodes(startChildren, afterBodyMethod));
      if (topLevelChildren.length && [...LIST_TYPES, ...HEADERS_MARKS].includes(topLevelChildren[0].type || '')) {
        // Apply first list level if list is first Node at buffer
        const filler = jsx('text', { text: NO_BREAK_SPACE }, []) as ICustomElement;
        topLevelChildren.unshift(filler);
      }
      return jsx('fragment', { text: '' }, topLevelChildren);
    }

    if (ELEMENT_TAGS[nodeName]) {
      const parentElement = element.childNodes[0] as HTMLElement;

      if (parentElement && parentElement.style?.fontFamily) {
        const currentFontType = filterFontTypeByAvailable(parentElement.style.fontFamily);
        if (currentFontType) {
          element.style.fontFamily = currentFontType;
        }
      }

      if (nodeName === 'LI') {
        const parentIsList = parentNode && ['OL', 'UL'].includes(parentNode.nodeName);
        if (parentIsList) {
          const newChildren = children.map((child) => {
            if (child?.type === PARAGRAPH) {
              return child.children;
            }
            return child;
          });
          const listItemType = isNumberedListType(parentNode?.nodeName) ? 'OLLI' : nodeName;
          const attrs = ELEMENT_TAGS[listItemType](element);
          return jsx('element', attrs, newChildren);
        }

        const listItemType = dataset?.type || '';
        if (LIST_ITEM_TYPES.includes(listItemType)) {
          return jsx('element', { type: listItemType }, children);
        }
      }

      if (nodeName === 'P') {
        children = children.map((child) => {
          if (typeof child === 'string') {
            return child.replace(NEW_LINE_REGEX, WHITE_SPACE);
          }
          return child;
        });
      }

      const attrs = ELEMENT_TAGS[nodeName](element);
      if (element.role === 'list' && ['OL', 'UL'].includes(nodeName) && parentElement?.role === 'listitem') {
        const ariaLevel = parentElement.dataset?.ariaLevel;
        const listID = parentElement.dataset?.listid;
        if (ariaLevel && listID) {
          attrs.className = `${WEB_MSO_LIST_MARKER} level`.concat(ariaLevel).concat(' listid', listID, ' ');
          if (isNumberedListType(nodeName)) {
            attrs.listStyle = getListTemplate(parentElement, null) || '';
          }
        }
      }
      return jsx('element', attrs, children);
    }

    if (TEXT_TAGS[nodeName]) {
      const newChildren = cleanUpTextChildren(nodeName, element, children);

      // Wrap into the top level node with styles if it is text without parent block element
      if (parentNode?.nodeName === 'BODY') {
        return jsx('element', ELEMENT_TAGS.P(element), newChildren);
      }

      return newChildren;
    }

    return children;
  };

  /**
   * Field type nodes are copying with next properties:
   * + TEXT_FIELD - type, label, label configuration, assignment, PII, required, help text, validation text, font size, value
   * + DATE_FIELD - type, label, label configuration, assignment, PII, required, isTodaysDate, help text, validation text, font size, value
   * + SELECT_FIELD - type, label, label configuration, assignment, required, help text, validation text, font size, options, value
   * + TEXTAREA_FIELD - assignment, type, label, label configuration, PII, required, help text, validation text, font size, value
   * + SIGNING_FIELD - type, label, label configuration, assignment, required, help text, validation text, font size
   * + ATTACHMENT_FIELD - type, label, assignment, required, help text, validation text, font size
   * + QUESTION_FIELD - type, assignment, text/select according to the answerType, label, font size, value, and options
   * - CHECKBOX_FIELD - copying as a separate element outside the function
   * We do not copy other field properties yet.
   * ---
   * Is able to copy "input", "select", "textarea" Nodes from Web with the next properties:
   * + name - as a label
   * + options - for select
   * + type - transformed to our field types
   *   value - only for textarea
   */
  const deserializeFormFields = (element: HTMLElement | any): any => {
    if (element.nodeType === TEXT_NODE_TYPE) {
      return null;
    }
    if (element.nodeType !== ELEMENT_NODE_TYPE) {
      return deserializeContentElements(element);
    }

    const children = Array.from(element.childNodes).map(deserializeFormFields);
    const fieldMainNode = element.closest('[data-field-key]');
    const fieldLabel = fieldMainNode?.dataset?.fieldLabel || element.getAttribute('name') || '';
    const fieldType = fieldMainNode?.dataset?.elementType || '';
    const fieldAssignment = fieldMainNode?.dataset?.fieldAssignment || RECIPIENT_ASSIGNMENT;
    const nodeName = (fieldType.length && fieldType !== SELECT_FIELD_TYPE_SELECT) ? fieldType : element.nodeName;

    /**
     * Field custom settings. They might be applied to all field types.
     */
    const fieldLabelConfiguration = fieldMainNode?.dataset?.fieldLabelConfiguration;
    const fieldSubtype = fieldMainNode?.dataset?.fieldSubtype;
    const fieldFontSize = fieldMainNode?.dataset?.fieldFontSize;
    const fieldRequired = fieldMainNode?.dataset?.fieldRequired;
    const fieldHelpText = fieldMainNode?.dataset?.fieldHelpText;
    const fieldValidationText = fieldMainNode?.dataset?.fieldValidationText;
    const fieldTodaysDate = fieldMainNode?.dataset?.fieldTodaysDate;

    const customFieldSettings = {
      ...(fieldLabelConfiguration ? { labelConfiguration: fieldLabelConfiguration } : {}),
      ...(fieldSubtype ? { subtype: fieldSubtype } : {}),
      ...(fieldFontSize ? { fontSize: Number(fieldFontSize) } : {}),
      ...(fieldRequired ? { requiredField: Boolean(fieldRequired) } : {}),
      ...(fieldHelpText ? { helpText: fieldHelpText } : {}),
      ...(fieldValidationText ? { validationText: fieldValidationText } : {}),
      ...(fieldTodaysDate ? { isTodaysDate: Boolean(fieldTodaysDate) } : {}),
    };

    /**
     * This variable contains field label and font size if it exists.
     */
    const fieldChildrenArray = [{
      text: fieldLabel,
      ...(fieldFontSize ? { 'text-size': true, size: fieldFontSize } : {}),
    }];

    const additionalAttributes = {
      name: element.getAttribute('name') || '',
      value: element.getAttribute('value') || '',
      assignment: fieldAssignment,
    };

    const getSelectFieldAttributes = (dataset: DOMStringMap) => {
      const selectedOtherOption = dataset?.selectedOtherOption === 'true';
      const addedOtherOption = dataset?.addedOtherOption === 'true';
      return { selectedOtherOption, addedOtherOption };
    };
    const selectOtherAttributes = getSelectFieldAttributes(element.dataset);

    switch (nodeName) {
      case 'BODY':
        return jsx('fragment', {}, children);
      case TEXT_FIELD:
        const textChildNode = fieldMainNode?.querySelector('input');
        const textInputData = {
          name: textChildNode?.getAttribute('name') || '',
          value: textChildNode?.getAttribute('value') || '',
          assignment: fieldAssignment,
          ...customFieldSettings,
        };
        return jsx(
          'element',
          {
            type: fieldType,
            key: generateNumber(),
            ...textInputData,
          },
          fieldChildrenArray,
        );
      case SELECT_FIELD_TYPE_SELECT:
        // We copy only select, see: nodeName = SELECT
        return jsx('fragment', {}, [{ text: '' }]);
      case SELECT_FIELD_TYPE_RADIO:
      case SELECT_FIELD_TYPE_CHECKBOX:
        let fieldValue = element.getAttribute('data-value') || '';
        if (selectOtherAttributes.selectedOtherOption) {
          const otherOptionInput: HTMLInputElement = element.querySelector('input[type="text"]');
          fieldValue = otherOptionInput?.getAttribute('value') || '';
        }
        const newSelectOptions: SelectFieldOptionType[] = [];
        const selectOptionNodes: HTMLInputElement[] = element.querySelectorAll(`input[type="${nodeName}"]`);
        selectOptionNodes?.forEach((el) => {
          const isSelectOtherOption = el.dataset?.isOtherOption === 'true';
          if (el.value) {
            newSelectOptions.push({
              id: generateNumber(),
              label: el.value,
              checked: fieldValue === el.value || (selectOtherAttributes.selectedOtherOption && isSelectOtherOption),
              isSelectOtherOption,
            });
          }
        });

        return jsx(
          'element',
          {
            key: generateNumber(),
            type: SELECT_FIELD,
            selectFieldType: nodeName,
            ...additionalAttributes,
            ...selectOtherAttributes,
            value: fieldValue,
            options: newSelectOptions,
            ...customFieldSettings,
          },
          fieldChildrenArray,
        );
      case 'INPUT':
      case SIGNING_FIELD:
      case ATTACHMENT_FIELD:
        const attachmentProperties = nodeName === ATTACHMENT_FIELD ? addPropertiesByType({}, ATTACHMENT_FIELD) : {};
        const signatureProperties = nodeName === SIGNING_FIELD ? addPropertiesByType({}, SIGNING_FIELD) : {};
        return jsx(
          'element',
          {
            type: (fieldType.length && fieldType) || `${element.getAttribute('type')}-field`,
            key: generateNumber(),
            ...additionalAttributes,
            ...attachmentProperties,
            ...signatureProperties,
            ...customFieldSettings,
          },
          fieldChildrenArray,
        );
      case DATE_FIELD:
        const fieldChildNode = fieldMainNode?.querySelector('input');
        const dateValue = fieldChildNode && fieldChildNode.getAttribute('value')
          ? moment(fieldChildNode.getAttribute('value')).format('YYYY-MM-DD')
          : '';
        const dateInputData = {
          name: fieldChildNode?.getAttribute('name') || '',
          value: dateValue,
          assignment: fieldAssignment,
          ...addPropertiesByType({}, DATE_FIELD),
          ...customFieldSettings,
        };
        return jsx(
          'element',
          {
            type: fieldType,
            key: generateNumber(),
            ...dateInputData,
          },
          fieldChildrenArray,
        );
      case TEXTAREA_FIELD:
        const textareaChildNode = fieldMainNode?.querySelector('textarea');
        const textareaData = {
          name: textareaChildNode?.getAttribute('name') || '',
          value: textareaChildNode?.value || '',
          assignment: fieldAssignment,
          ...customFieldSettings,
        };

        return jsx(
          'element',
          {
            key: generateNumber(),
            type: TEXTAREA_FIELD,
            ...textareaData,
          },
          fieldChildrenArray,
        );
      case 'TEXTAREA':
        return jsx(
          'element',
          {
            key: generateNumber(),
            type: TEXTAREA_FIELD,
            ...additionalAttributes,
            value: element.textContent?.replace(/(<([^>]+)>)/ig, '')?.slice(0, TEXTAREA_MAX_LEN) || '',
          },
          fieldChildrenArray,
        );
      case 'SELECT':
        let dataValue = element.getAttribute('data-value') || '';
        if (selectOtherAttributes.selectedOtherOption) {
          const otherOptionInput: HTMLInputElement = element.parentNode?.querySelector('input[type="text"]');
          dataValue = otherOptionInput?.getAttribute('value') || '';
        }
        const newOptions: SelectFieldOptionType[] = [];
        const oldOptions: HTMLOptionElement[] = Object.values(element.options);
        oldOptions.forEach((el) => {
          const isSelectOtherOption = el.dataset?.isOtherOption === 'true';
          if (el.value) {
            newOptions.push({ id: generateNumber(), label: el.value, isSelectOtherOption });
          }
        });

        return jsx(
          'element',
          {
            key: generateNumber(),
            type: SELECT_FIELD,
            selectFieldType: SELECT_FIELD_TYPE_SELECT,
            ...additionalAttributes,
            ...(dataValue.length && { value: dataValue }),
            ...selectOtherAttributes,
            options: newOptions,
            ...customFieldSettings,
          },
          fieldChildrenArray,
        );
      case QUESTION_FIELD:
        let fieldData = {};
        const childNode = fieldMainNode?.querySelector('input');
        if (childNode) {
          fieldData = {
            assignment: fieldAssignment,
            answerType: QUESTION_ANSWER_STRING,
            name: childNode?.getAttribute('name') || '',
            value: childNode?.getAttribute('value') || '',
          };
        } else {
          const childNode = fieldMainNode?.querySelector('select');
          if (childNode) {
            const newOptions: SelectFieldOptionType[] = [];
            const oldOptions = Object.values(childNode.options);
            oldOptions.forEach((el: any) => {
              if (el.value) {
                newOptions.push({ id: generateNumber(), label: el.value });
              }
            });
            fieldData = {
              assignment: fieldAssignment,
              answerType: QUESTION_ANSWER_SELECT,
              name: childNode?.getAttribute('name') || '',
              value: childNode?.getAttribute('data-value') || '',
              options: newOptions,
              ...customFieldSettings,
            };
          }
        }
        return jsx(
          'element',
          {
            type: fieldType,
            key: generateNumber(),
            ...fieldData,
          },
          fieldChildrenArray,
        );
      default:
        return children;
    }
  };

  const isListInChildren = (element: SlateElement[]): boolean => {
    const children = element[0].children;
    if (children) {
      return children.some((child) => (
        SlateElement.isElement(child) && [...LIST_TYPES].includes(child.type || '')
      ));
    }

    return false;
  };

  const unwrapList = (children: SlateElement[]): SlateElement[] => {
    if (
      children.length === 1
      && children[0].type === PARAGRAPH
      && isListInChildren(children)
    ) {
      return [...children[0].children] as SlateElement[];
    }

    return children;
  };

  return deserialize;
};

const getTextFieldMaskDataAttr = (field: ICustomElement | PDFFieldType) => {
  switch (field.textFieldMask) {
    case TEXT_FIELD_MASKS.LAST_FOUR:
      return `•••••••• ${field.value?.slice(-4)}`;
    case TEXT_FIELD_MASKS.LAST_SIX:
      return `•••••••• ${field.value?.slice(-6)}`;
    case TEXT_FIELD_MASKS.CUSTOM_TEXT:
      return field.textFieldMaskCustomText ?? FIELD_MASK_CUSTOM_TEXT_DEFAULT;
    default:
      return '';
  }
};

/**
 * Method creates fields data attributes if certain field settings exist.
 * Data attributes are used for field copy/paste feature in order to paste
 * a field with the same settings as origin field has.
 */
export const createFieldDataAttributes = ({
  field,
  attributes,
  activeTab,
  selectFieldViewMode,
}: {
  field: ICustomElement | PDFFieldType;
  attributes?: Record<string, string | boolean>;
  activeTab?: number;
  selectFieldViewMode?: string;
}) => {
  const dataAttributes = {
    'data-field': true,
    'data-field-key': field.key,
    'data-field-label': getFieldLabelFromChildren((field as ICustomElement).children),
    'data-field-assignment': field.assignment,
    'data-element-type': field.type === SELECT_FIELD ? selectFieldViewMode : field.type,
    'data-field-label-configuration': (field as ICustomElement).labelConfiguration,
    ...(field.subtype ? { 'data-field-subtype': field.subtype } : {}),
    ...(activeTab ? { 'data-active-tab': activeTab } : {}),
    ...(attributes ?? {}),
    ...(field.addedOtherOption ? { 'data-added-other-option': field.addedOtherOption } : {}),
    ...(field.selectedOtherOption ? { 'data-selected-other-option': field.selectedOtherOption } : {}),
    ...(field.fontSize ? { 'data-field-font-size': field.fontSize } : {}),
    ...(field.requiredField ? { 'data-field-required': field.requiredField } : {}),
    ...(field.helpText ? { 'data-field-help-text': field.helpText } : {}),
    ...(field.validationText ? { 'data-field-validation-text': field.validationText } : {}),
    ...(field.isTodaysDate ? { 'data-field-todays-date': field.isTodaysDate } : {}),
    ...(field.textFieldMask ? {
      'data-field-mask': field.textFieldMask,
      'data-mask-custom-text': getTextFieldMaskDataAttr(field),
    } : {}),
  };
  return dataAttributes;
};

const getSiblings = (el: Element | null): Element[] => {
  const siblings = [];

  while (el && isMsoList(el)) {
    const elementStyleString = el.attributes.getNamedItem('style')?.value || '';
    const elementListId = elementStyleString.match(/mso-list:l(\d+)/);
    const elementLevelMatch = elementStyleString.match(/level(\d+)/);

    if (elementListId) {
      el.setAttribute('data-list-id', elementListId[1]);
    }
    if (elementLevelMatch) {
      const level = elementLevelMatch[1];
      el.setAttribute('class', 'done'); // we set this attribute to avoid getting stuck in an infinite loop
      el.setAttribute('style', level);
      siblings.push(el);
      el = el.nextElementSibling;
    } else {
      // to avoid the MS Word issue when a text element recognized as list element
      el.setAttribute('class', 'done');
      el.setAttribute('style', '1');
    }
  }
  return siblings;
};

const getListType = (el: HTMLElement) => {
  const value = (el.textContent && el.textContent[0]) || '';
  const regex = /^[\d\w]+$/;
  return regex.test(value) ? 'OL' : 'UL';
};

export const getTextListType = (text: string, withNoSpace = false) => {
  const getSwitchedType = (matchedValue: string) => {
    switch (matchedValue.replace(/\s/gm, '')) {
      case '*':
      case '•':
      case 'o':
        return LIST_ITEM;
      default:
        return NUMBERED_LIST_ITEM;
    }
  };
  const cleanText = text.replace(/^( +|\t)/gm, '');
  const found = cleanText.match(/^\d+\.\s|^\*\s|^•/g);
  if (withNoSpace && !found) {
    const foundNoSpaced = cleanText.match(/^\d+(?:\.)?(?:\d+)?|^\*|^•/g);
    if (!foundNoSpaced) return null;
    return getSwitchedType(foundNoSpaced[0]);
  }
  if (!found) return null;

  return getSwitchedType(found[0]);
};

export const transformToTopLevelNodes = (
  elementList: SlateElement[],
  afterBodyMethod?: (...arg: unknown[]) => any,
): SlateElement[] => {
  if (elementList.length) {
    const listIDs: string[] = [];
    return elementList.map((node) => {
      if (!isTopLevelTextNode(node.type)) {
        if (LIST_ITEM_TYPES.includes(node.type)) {
          const listType = node.type === LIST_ITEM ? 'UL' : 'OL';
          const attrs = ELEMENT_TAGS[listType](node);
          return jsx('element', attrs, [node]);
        }

        const attrs = ELEMENT_TAGS.P(node);
        return jsx('element', { ...attrs, key: generateNumber() }, node);
      }

      /**
       * Deserialize Multilevel lists parsed from MS Office 365 Online
       * We need to start parsing on the top BODY level because list elements can be in separate DIV Nodes and wrappers
       * It means Web Word has not linear structure
       */
      if (isMsoWebList(node)) {
        const listid = node.className?.match(/listid(\d+)/);
        if (listid && !listIDs.includes(listid[0])) {
          listIDs.push(listid[0]);
          if (afterBodyMethod && typeof afterBodyMethod !== undefined) {
            const listElements = elementList.filter((node) => (node.className || '').split(' ').includes(listid[0]));
            // call deserializeWebMSOList() serializer
            return afterBodyMethod(listElements, node.listStyle);
          }
        } else return null;
      }

      return node;
    }).filter((node) => node !== null);
  }
  return elementList;
};

/**
 * Recursion function is used in order to fill empty children nodes.
 * It might couses slate error in some cases.
 */
export const updateEmptyChildrenArrays = (nodeArray: any) => {
  nodeArray.forEach((node: any) => {
    if (node.children) {
      if (node.children.length === 0) {
        node.children.push({ text: '' });
      } else {
        updateEmptyChildrenArrays(node.children);
      }
    }
  });
  return nodeArray;
};

export const checkFragmentAndUpdateAssignments = (fragment: ICustomElement[], dispatch?: ReduxDispatch) => {
  if (dispatch) {
    fragment.forEach((node) => {
      if (node.children && node.children.length > 0) {
        node.children.forEach((innerItem) => {
          if (SlateElement.isElement(innerItem) && FIELD_TYPE.includes(innerItem.type)) {
            const assignmentsType = getAssignmentsMainPartInStore(innerItem.assignment);
            dispatch(addFieldToAssignments(
              innerItem.key || 0,
              innerItem.assignment || RECIPIENT_ASSIGNMENT,
              assignmentsType,
            ));
          }
        });
      }
    });
  }
};