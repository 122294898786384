import { FC } from 'react';

import { Form, FormGroup } from 'reactstrap';

import ManagementModal from 'components/Modals/UserManagementModal/ManagementModal';
import ModalInputField from 'components/Modals/UserManagementModal/ModalInputField';
import { SAVE } from 'constants/general';
import { groupManagementModalField } from 'utils/modalHelpers';

interface IGroupManagementModal {
  isModalOpen: boolean;
  onClose: () => void;
  setGroup: ({ name }: { name: string }) => void;
  onSave: () => void;
  title: string;
  currentGroupName?: string;
  errorMessage: string;
}

const GroupManagementModal: FC<IGroupManagementModal> = ({
  isModalOpen,
  onClose,
  setGroup,
  onSave,
  title,
  currentGroupName,
  errorMessage,
}) => (
  <ManagementModal
    isOpen={isModalOpen}
    onClose={onClose}
    onSave={onSave}
    title={title}
    saveButtonText={SAVE}
    withCancelButton
  >
    <Form autoComplete="off">
      <FormGroup className="user-management-modal__group">
        <ModalInputField
          field={{
            ...groupManagementModalField,
            ...(currentGroupName && { value: currentGroupName }),
            errorMessage,
          }}
          onChange={(value: string) => setGroup({ name: value })}
        />
      </FormGroup>
    </Form>
  </ManagementModal>
);

export default GroupManagementModal;