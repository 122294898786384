import { toast } from 'react-toastify';

import { BaseSelection, Editor, Element, NodeEntry, Transforms } from 'slate';

import {
  TABLE_QUESTION_ROW,
} from 'constants/editors';
import {
  COLUMNS_INSERTING_MESSAGE,
  DEFAULT_TABLE_QUESTION_CELL_HEIGHT,
  DEFAULT_TABLE_QUESTION_CELL_WIDTH,
  MAX_TABLE_QUESTION_COLUMN_COUNT,
  TABLE_QUESTION_CELL_LIST,
  TABLE_QUESTION_INSERT_POSITION,
} from 'constants/tableQuestion';
import { ICustomElement } from 'types/Editor';
import { TableQuestionInsertColumnPositionType } from 'types/TableQuestion';
import {
  createTableQuestionCell,
  createTableQuestionFieldCell,
  createTableQuestionVerticalResizeBar,
  tableNodeGenerator,
} from 'utils/TableQuestion/createTableQuestionHelpers';
import { findEditorNodesByPosition } from 'utils/TableQuestion/tableQuestionHelpers';

interface IInsertTableQuestionColumnProps {
  editor: Editor,
  tableBody: NodeEntry<ICustomElement> | undefined,
  selection: BaseSelection,
  position?: TableQuestionInsertColumnPositionType;
}

const getCallback = (index: number) => {
  switch (index) {
    case 0:
      return createTableQuestionVerticalResizeBar;
    case 1:
      return createTableQuestionCell;
    default:
      return createTableQuestionFieldCell;
  }
};

const insertTableQuestionColumn = ({
  editor,
  tableBody,
  selection,
  position = TABLE_QUESTION_INSERT_POSITION.RIGHT,
}: IInsertTableQuestionColumnProps) => {
  if (!tableBody || !selection) {
    toast.warning(COLUMNS_INSERTING_MESSAGE.CANT_INSERT_HERE);
    return;
  }

  const cellNodes = findEditorNodesByPosition(editor, selection, TABLE_QUESTION_CELL_LIST);
  if (!cellNodes || !Array.isArray(cellNodes)) return;

  const [[, startPath]] = cellNodes;
  const columnIndex = startPath[startPath.length - 1];
  const isLeftPosition = position === TABLE_QUESTION_INSERT_POSITION.LEFT;

  if ((columnIndex === 0 || columnIndex === 1) && isLeftPosition) {
    toast.warning(COLUMNS_INSERTING_MESSAGE.CANT_INSERT_LEFT);
    return;
  }
  if (columnIndex === 0 && !isLeftPosition) return;

  const targetIndex = isLeftPosition ? columnIndex : columnIndex + 1;

  const tableRows = findEditorNodesByPosition(editor, tableBody[1], [TABLE_QUESTION_ROW]);
  if (!tableRows || !Array.isArray(tableRows)) return;
  if ((tableRows?.[0]?.[0]?.children?.length ?? 0) >= MAX_TABLE_QUESTION_COLUMN_COUNT) {
    toast.warning(COLUMNS_INSERTING_MESSAGE.CANT_INSERT_MORE);
    return;
  }

  tableRows.forEach(([rowNode, rowPath], index) => {
    const cellNode = rowNode.children[1];
    const title = cellNode.children?.length ? cellNode.children[0].text : '';
    const newCell = Array.from(
      tableNodeGenerator({
        count: 1,
        callback: getCallback(index),
        args: {
          options: [
            {
              name: title,
              children: [{ text: title ?? '' }],
            },
          ],
        },
      }),
    );

    if (TABLE_QUESTION_CELL_LIST.includes(newCell[0].type)) {
      const childNode = rowNode.children[0];
      newCell[0].height = Element.isElement(childNode) ? childNode.height : DEFAULT_TABLE_QUESTION_CELL_HEIGHT;
      newCell[0].width = DEFAULT_TABLE_QUESTION_CELL_WIDTH;
    }

    Transforms.insertNodes(
      editor,
      newCell,
      { at: [...rowPath, targetIndex] },
    );
  });
};

export default insertTableQuestionColumn;