import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Descendant } from 'slate';

import { INITIAL_EDITOR_VALUE, RECIPIENT_ASSIGNMENT } from 'constants/editors';
import {
  setAllPdfFieldsAndInfo,
  setPdfTemplateFileLink,
  setPdfTemplateKey,
  setPdfTemplateWidth,
} from 'store/actions/pdfTemplates';
import { updateTemplateSections } from 'store/actions/template';
import { RootStateType } from 'store/reducers';
import { ICollectionExecutedDetails, ICollectionExecutedDocument } from 'types/CollectionExecuted';
import { ICollectionDetails, ICollectionTemplate } from 'types/MultiTemplate';
import { IPublicPageDocumentStructure, IPublicPageState, PublicPageDataType } from 'types/PublicPage';
import { ITemplateSection } from 'types/redux';
import { ISection } from 'types/Sections';
import { addSectionsDocument } from 'utils/editorHelpers';
import { isEmptyObject } from 'utils/isEmptyObject';
import { getPdfUrlAndWidth } from 'utils/pdfTemplatesHelpers';
import { getIsCollectionFormBuilder, isPDFDocument } from 'utils/PublicPage/documentTypeChecker';
import getPDFData from 'utils/PublicPage/getPDFData';
import { getDocumentSectionsInfo, getSortedSections, getTemplateSectionsInfo } from 'utils/sectionUtils';
import { isCollectionExecutedType, isCollectionType, isDocumentType, isTemplateType } from 'utils/typeChecker';

const getCurrentDataFromCollection = (
  data: ICollectionDetails | ICollectionExecutedDetails,
  currentDocument: IPublicPageDocumentStructure | null,
) => {
  if (getIsCollectionFormBuilder(data)) {
    return data;
  }
  if (isCollectionExecutedType(data)) {
    return data.documents?.find((document: ICollectionExecutedDocument) => (
      document.document_id === currentDocument?.id
    ))?.document || null;
  }
  return data.templates?.find((template: ICollectionTemplate) => (
    template.template_id === currentDocument?.id
  ))?.template || null;
};

const getCurrentData = (
  data: PublicPageDataType | null,
  currentDocument: IPublicPageDocumentStructure | null,
): PublicPageDataType | null => {
  if (data === null) return null;
  return isCollectionType(data) || isCollectionExecutedType(data)
    ? getCurrentDataFromCollection(data, currentDocument)
    : data;
};

const useEditorContent = (): [Descendant[], Dispatch<SetStateAction<Descendant[]>>] => {
  const dispatch = useDispatch();

  const { data: documentData, currentDocument, structure }: IPublicPageState = useSelector((state: RootStateType) => (
    state.publicPages
  ));
  const [content, setContent] = useState<Descendant[]>(INITIAL_EDITOR_VALUE);

  const assignment: string = structure.main.mainAssignment || RECIPIENT_ASSIGNMENT;
  const data: PublicPageDataType | null = getCurrentData(documentData, currentDocument);

  useEffect((): void => {
    if (isEmptyObject(data) && !data) return;

    if (isCollectionType(data) && getIsCollectionFormBuilder(data)) {
      setContent([]);
      return;
    }

    if (isTemplateType(data)) {
      const sortedSections: ISection[] = getSortedSections(data.sections);
      setContent(addSectionsDocument(sortedSections, false));

      if (data.pdf_file_url) {
        const [pdfFileUrl, pdfFileWidth] = getPdfUrlAndWidth(data.pdf_file_url);
        dispatch(setAllPdfFieldsAndInfo(getPDFData({
          fields: data.pdf_fields_json,
          name: data.name,
          title: data.template_title,
          description: data.description,
        })));
        dispatch(setPdfTemplateKey(pdfFileUrl));
        dispatch(setPdfTemplateWidth(parseInt(pdfFileWidth)));
        dispatch(setPdfTemplateFileLink(data.pdf_full_link));
        return;
      }

      const titleSection: ITemplateSection = {
        name: data.template_title,
        description: data.description,
        key: `template-${data.id}`,
      };

      const newTemplateSections: ITemplateSection[] = getTemplateSectionsInfo(sortedSections, assignment);
      const resultArray: ITemplateSection[] = [...newTemplateSections];

      if (data.description) {
        resultArray.unshift(titleSection);
      }
      dispatch(updateTemplateSections(resultArray));
    }

    if (isDocumentType(data)) {
      if (isPDFDocument(data)) {
        setContent([]);
        dispatch(setAllPdfFieldsAndInfo(getPDFData({
          fields: data.content_json,
          name: data.name,
          title: data.document_title,
          description: data.description,
        })));
        if (data.pdf_file_width) {
          dispatch(setPdfTemplateWidth(data.pdf_file_width));
        }
        if (data?.pdf_file_url) {
          const [pdfFileUrl, pdfFileWidth] = getPdfUrlAndWidth(data.pdf_file_url);
          dispatch(setPdfTemplateWidth(parseInt(pdfFileWidth)));
          dispatch(setPdfTemplateKey(pdfFileUrl));
        }
        dispatch(setPdfTemplateFileLink(data?.pdf_full_link || ''));
        return;
      }

      setContent(data.content_json as Descendant[]);

      const newSections = getDocumentSectionsInfo(data.content_json as Descendant[], assignment);

      const titleSection: ITemplateSection = {
        name: data.document_title,
        description: data.description,
        key: `document-${data.id}`,
      };

      const resultArray: ITemplateSection[] = [...newSections];

      if (data.description) {
        resultArray.unshift(titleSection);
      }

      dispatch(updateTemplateSections(resultArray));
    }
  }, [assignment, data]);

  return [content, setContent];
};

export default useEditorContent;