import { toast } from 'react-toastify';
import { put, select, takeLatest } from 'redux-saga/effects';

import { SUBTYPE_EMAIL, SUBTYPE_PHONE_MOBILE } from 'constants/editors';
import { COLLECTION_TYPE } from 'constants/general';
import { ACCEPTED_CODE, SUCCESS_CODE } from 'constants/generalErrors';
import { NO_BOARDS } from 'constants/mondayComIntegration';
import { sendCodeForVerification, sendUniqueSMSCodeToUser } from 'services/api/smsValidation';
import {
  SEND_CODE_FOR_VERIFICATION,
  SEND_UNIQUE_CODE_TO_USER,
} from 'store/actions/actionTypes';
import { checkMondayComRecordsForCurrentUser } from 'store/actions/mondayComIntegration';
import { sendSMSValidationCodeError, setShowSMSValidationModal } from 'store/actions/publicPages';
import { RootStateType } from 'store/reducers';
import { safe } from 'store/sagas/errorHandlerSaga';
import { IResponseOfRequest } from 'types/Api';
import { PIIDataType } from 'types/userProfile';
import disableCloseEvent from 'utils/BlockCloseButtonHelpers';

const sentUniqueCodeToUserSaga = function* () {
  const userPIIData: PIIDataType[] = yield select((state: RootStateType) => state.profile.piiData);
  const {
    monday_com_subitem: isMondayComSubitem,
    id: resourceId,
    type: resourceType,
  } = yield select((state: RootStateType) => state.publicPages.data);
  const mondayComBoard: string = yield select((state: RootStateType) => state.publicPages.mondayComBoard);
  const userEmailObject = userPIIData.find((piiObject) => piiObject.subtype === SUBTYPE_EMAIL);
  const userPhoneObject = userPIIData.find((piiObject) => piiObject.subtype === SUBTYPE_PHONE_MOBILE);
  if (userEmailObject && (userPhoneObject || isMondayComSubitem)) {
    const sendCodeResponse: IResponseOfRequest<any> = yield sendUniqueSMSCodeToUser({
      user_email: userEmailObject.value,
      user_phone_number: userPhoneObject?.value,
      is_monday_com_subitem: isMondayComSubitem,
      monday_com_board: mondayComBoard,
      resource_id: resourceId,
      is_collection: resourceType === COLLECTION_TYPE,
    });
    if (sendCodeResponse && sendCodeResponse.status === SUCCESS_CODE) {
      toast.success('Code has been sent');
      yield put(setShowSMSValidationModal(true, sendCodeResponse.data.phone_number));
    }
    if (sendCodeResponse && sendCodeResponse.status === ACCEPTED_CODE) {
      if (isMondayComSubitem && mondayComBoard && mondayComBoard !== NO_BOARDS) {
        yield put(checkMondayComRecordsForCurrentUser());
      }
    }
  }
};

const sendCodeForVerificationSaga = function* ({ payload }: { payload: string }) {
  const userPIIData: PIIDataType[] = yield select((state: RootStateType) => state.profile.piiData);
  const { startLink, documentUUID } = yield select((state: RootStateType) => state.publicPages);
  const userEmailObject = userPIIData.find((piiObject) => piiObject.subtype === SUBTYPE_EMAIL);
  if (userEmailObject) {
    const sendCodeForVerificationResponse: IResponseOfRequest<any> = yield sendCodeForVerification({
      user_email: userEmailObject.value,
      unique_code: payload,
      start_link: startLink,
      document_uuid: documentUUID,
    });

    if (sendCodeForVerificationResponse) {
      if (sendCodeForVerificationResponse.status === ACCEPTED_CODE) {
        yield put(setShowSMSValidationModal(false, ''));
        window.removeEventListener('beforeunload', disableCloseEvent);
        window.location.replace(sendCodeForVerificationResponse.data.full_link);
      }
      if (sendCodeForVerificationResponse.status === SUCCESS_CODE) {
        yield put(sendSMSValidationCodeError((sendCodeForVerificationResponse.errors as any)?.message));
      }
    }
  }
};

export default [
  takeLatest(SEND_UNIQUE_CODE_TO_USER, safe(sentUniqueCodeToUserSaga, false, true)),
  takeLatest(SEND_CODE_FOR_VERIFICATION, safe(sendCodeForVerificationSaga, false, true)),
];