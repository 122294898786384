import { useState } from 'react';

import { useSelector } from 'react-redux';

import Button from 'components/Base/Button';
import SimpleSpinner from 'components/Base/SimpleSpinner';
import { SUCCESS } from 'constants/api';
import { STRIPE_INTERVAL_PRIORITY } from 'constants/payment';
import { useStripeProducts } from 'hooks/payment/stripe';
import { createCheckoutSession } from 'services/api/payment';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { RootStateType } from 'store/reducers';
import { ICheckoutSessionRequestParams, ICheckoutSessionResponse } from 'types/payment/Stripe';
import { isEmptyObject } from 'utils/isEmptyObject';

const StripeSubscriptions = () => {
  const { activeProductID, activePriceID } = useSelector((state: RootStateType) => state.payment.stripe);
  const { activeInterval, products, isLoading } = useStripeProducts();

  const [selectedInterval, setSelectedInterval] = useState<number>(STRIPE_INTERVAL_PRIORITY.month);

  const checkoutHandler = async (priceID: string, productID: string): Promise<void> => {
    const response = await apiErrorHandler<
      ICheckoutSessionResponse,
      ICheckoutSessionRequestParams
    >(createCheckoutSession, {
      price_id: priceID,
      product_id: productID,
    });
    if (response.code === SUCCESS) {
      window.location.replace(response.data.url);
    }
  };

  if (isLoading) {
    return <SimpleSpinner />;
  }

  if (isEmptyObject(products)) {
    return <div className="d-flex justify-content-center m-4">The product list is empty</div>;
  }

  return (
    <>
      <div className="d-flex gap-3 justify-content-center mb-4 flex-wrap">
        {
          Object.entries(products)
            .sort(([, a], [, b]) => a.priority - b.priority)
            .map(([intervalKey, { title }]) => (
              <Button
                type="button"
                classes={`button-simple ${Number(intervalKey) !== selectedInterval
                  ? 'outline outlined-with-border' : 'button-contained-pink '}`}
                key={Number(intervalKey)}
                onClick={() => setSelectedInterval(Number(intervalKey))}
              >
                {title}
              </Button>
            ))
        }
      </div>
      <div className="d-flex gap-4 justify-content-center flex-wrap">
        {
          products[selectedInterval].prices
            .sort((a, b) => a.price - b.price)
            .filter((price) => !price.isHidden)
            .map((price) => (
              <div className="stripe-subscription-card" key={price.name}>
                {
                  price.id === activePriceID
                  && (
                    <span className="stripe-subscription-card__tag">Current Plan</span>
                  )
                }
                <div className="mb-5">
                  <h3>
                    {price.name}
                  </h3>
                  {price.description && (<p>{price.description}</p>)}
                </div>
                <div className="mb-5">
                  <p className="stripe-subscription-card__price">
                    {price.price}.0 {price.currency.toUpperCase()}
                  </p>
                  <span className="stripe-subscription-card__interval">
                    per {price.intervalTitle}
                  </span>
                </div>
                {
                  price.productID !== activeProductID
                  || (price.productID === activeProductID && activeInterval < selectedInterval)
                    ? (
                      <Button
                        type="button"
                        classes="w-100 d-block"
                        onClick={() => checkoutHandler(price.id, price.productID)}
                      >
                        {price.isTrialAvailable ? '14-Day Free Trial' : 'Checkout'}
                      </Button>
                    )
                    : (
                      <div className="stripe-subscription-card__current">Active</div>
                    )
                }
              </div>
            ))
        }
      </div>
    </>
  );
};

export default StripeSubscriptions;