export type PageSettingsType = Record<string, string | number | boolean | []>;

export const enum PageSettingsElementTypesEnum {
  Container = 'container',
  Number = 'number',
  Checkbox = 'checkbox',
}

export type PageSettingsValueType = string | number | boolean;

export type PageSettingsKeyType = keyof PageSettingsType;

export interface IPageSettingsSchemaElement {
  key: PageSettingsKeyType;
  title: string;
  type: PageSettingsElementTypesEnum;
  value: IPageSettingsSchemaElement | IPageSettingsSchemaElement[] | PageSettingsValueType;
  isAvailable?: boolean;
  disabled?: boolean;
}

export interface IPageSettingProps {
  setting: IPageSettingsSchemaElement;
}
