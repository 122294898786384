import { FC } from 'react';

import { PaymentMethodEnum } from 'constants/payment';
import amex from 'svg/Payment/amex.png';
import diners from 'svg/Payment/diners.png';
import discover from 'svg/Payment/discover.png';
import maestro from 'svg/Payment/maestro.png';
import mastercard from 'svg/Payment/mastercard.png';
import paypal from 'svg/Payment/paypal.png';
import visa from 'svg/Payment/visa.png';
import { StripePaymentMethodType } from 'types/payment/Stripe';

interface IStripePaymentIconParams {
  type: StripePaymentMethodType;
}

const getStripePaymentIconSrc = (type: StripePaymentMethodType) => {
  switch (type) {
    case PaymentMethodEnum.AMERICAN_EXPRESS:
      return amex;
    case PaymentMethodEnum.DINERS:
      return diners;
    case PaymentMethodEnum.DISCOVER:
      return discover;
    case PaymentMethodEnum.MAESTRO:
      return maestro;
    case PaymentMethodEnum.MASTERCARD:
      return mastercard;
    case PaymentMethodEnum.PAYPAL:
      return paypal;
    case PaymentMethodEnum.VISA:
      return visa;
    default:
      return null;
  }
};

const StripePaymentIcon: FC<IStripePaymentIconParams> = ({ type }) => {
  const src = getStripePaymentIconSrc(type);
  if (!src) return null;

  return (
    <img className="payment-icon" src={src} alt={type} />
  );
};

export default StripePaymentIcon;