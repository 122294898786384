import { useState } from 'react';

import Button from 'components/Base/Button';
import SigningModal from 'components/Modals/SignatureModal/SigningModal';
import { DELETE, SAVE } from 'constants/general';
import { SIGNATURE_TABS } from 'constants/signatures';
import { PROFILE_SECTIONS } from 'constants/UserMenu';
import useDefaultSignature from 'hooks/useDefaultSignature';

const DefaultSignatureSection = () => {
  const {
    signatureData,
    signatureTab,
    savedSignature,
    onDeleteSignatureHandler,
    onSaveSignatureHandler,
    localSignatureSaver,
  } = useDefaultSignature();
  const [showSigningModal, setShowSigningModal] = useState<boolean>(false);

  return (
    <div className="account-settings">
      <div className="account-settings__header">
        <h2 className="account-settings__title">{PROFILE_SECTIONS.DEFAULT_SIGNATURE}</h2>
      </div>
      {
        signatureData
          ? (
            <>
              {
                signatureTab === SIGNATURE_TABS.TYPE_TAB
                  ? (
                    <div className="mb-3">
                      <input
                        className="form-signature-wrapper-typed_input"
                        value={signatureData}
                        onChange={() => null}
                      />
                    </div>
                  ) : (
                    <div className="mb-3 signature-canvas-wrapper text-center">
                      <img
                        className="image-inside-modal h-100"
                        src={signatureData}
                        alt="signature"
                      />
                    </div>
                  )
              }
              <div className="account-settings__buttons">
                <Button
                  classes="me-3"
                  onClick={() => onSaveSignatureHandler(signatureData)}
                  disabled={!signatureData}
                >
                  {SAVE}
                </Button>
                <Button
                  onClick={onDeleteSignatureHandler}
                  disabled={!savedSignature}
                >
                  {DELETE}
                </Button>
              </div>
            </>
          ) : (
            <Button classes="mb-3" onClick={() => setShowSigningModal(true)}>
              Set your signature
            </Button>
          )
      }
      <SigningModal
        showModal={showSigningModal}
        onCloseModal={setShowSigningModal}
        existingValue={signatureData}
        currentTab={signatureTab}
        onSave={localSignatureSaver}
      />
    </div>
  );
};

export default DefaultSignatureSection;