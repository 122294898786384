import { Descendant } from 'slate';

import { PDFFieldType, PDFFieldTypeObject } from 'types/PdfTemplates';
import { PersistentStorageFieldType } from 'types/persistentStorage';
import { PublicPageDataType } from 'types/PublicPage';
import { IDocumentSection } from 'types/Sections';
import { findNodesAndUpdate } from 'utils/editorHelpers';
import { isPDFDocument } from 'utils/PublicPage/documentTypeChecker';
import { isDocumentType, isTemplateType } from 'utils/typeChecker';

const updateFieldsInContentJSONFromStorage = (
  contentJSON: Descendant[],
  fieldKeys: number[],
  storedFields: PersistentStorageFieldType,
): Descendant[] => (
  findNodesAndUpdate(contentJSON, (node): Descendant => {
    if (node.key && fieldKeys.includes(node.key)) {
      const field: Descendant = storedFields[node.key] as Descendant;
      return {
        ...node,
        ...field,
      };
    }
    return node;
  })
);

const updatePDFFieldsFromStorage = (
  PDFFieldsJSON: PDFFieldTypeObject,
  storedFields: PersistentStorageFieldType,
): PDFFieldTypeObject => (
  Object.fromEntries(
    Object.entries(PDFFieldsJSON)
      .map(([key, field]) => {
        if (storedFields[Number(key)]) {
          return [
            key,
            {
              ...field,
              ...storedFields[Number(key)] as PDFFieldType,
            },
          ];
        }
        return [key, field];
      }),
  )
);

export const getUpdatedDataFromPersistentStorage = (
  pageData: PublicPageDataType,
  storedFields: PersistentStorageFieldType,
): PublicPageDataType => {
  const isPDF = isPDFDocument(pageData);
  const isTemplate = isTemplateType(pageData);
  const isDocument = isDocumentType(pageData);
  const fieldsKeys = Object.keys(storedFields).map(Number);

  if (isPDF) {
    if (isTemplate) {
      const updatedPDFFields: PDFFieldTypeObject = updatePDFFieldsFromStorage(pageData.pdf_fields_json, storedFields);

      return {
        ...pageData,
        pdf_fields_json: {
          ...updatedPDFFields,
        },
      };
    }
    if (isDocument) {
      const updatedPDFFields = updatePDFFieldsFromStorage(pageData.content_json as PDFFieldTypeObject, storedFields);

      return {
        ...pageData,
        content_json: updatedPDFFields,
      };
    }
  }
  if (!isPDF) {
    if (isTemplate) {
      return {
        ...pageData,
        sections: pageData.sections.map((section: IDocumentSection): IDocumentSection => {
          const updatedContentJSON = updateFieldsInContentJSONFromStorage(
            section.section.content_json || [],
            fieldsKeys,
            storedFields,
          );
          return {
            ...section,
            section: {
              ...section.section,
              content_json: updatedContentJSON,
            },
          };
        }),
      };
    }
    if (isDocument) {
      const updatedContentJSON = updateFieldsInContentJSONFromStorage(
        pageData.content_json as Descendant[],
        fieldsKeys,
        storedFields,
      );

      return {
        ...pageData,
        content_json: updatedContentJSON,
      };
    }
  }

  return pageData;
};

export default undefined;