import {
  DELETE_MULTI_TEMPLATES_START,
  DELETE_PDF_PARTS_MULTI_TEMPLATE,
  GET_MULTI_TEMPLATES_START,
  SET_PDF_FIELD_BY_IDS_MULTI_TEMPLATE,
  SET_PDF_PART_MULTI_TEMPLATE,
} from 'store/actions/actionTypes';
import { MultiTemplateStateType } from 'types/MultiTemplate';
import { Action } from 'types/redux';
import { getPdfUrlAndWidth } from 'utils/pdfTemplatesHelpers';

const initialState: MultiTemplateStateType = {
  isSuccess: false,
  isError: false,
  isLoading: false,
  pdfPartsOfMultiTemplate: {},
};

// Eslint error  - Default parameters should be last, it's impossible to fix it quickly
// eslint-disable-next-line
const multiTemplates = (state: MultiTemplateStateType = initialState, { type, payload }: Action) => {
  switch (type) {
    case GET_MULTI_TEMPLATES_START:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_MULTI_TEMPLATES_START:
      return {
        ...state,
        isError: false,
        isLoading: true,
      };
    case SET_PDF_PART_MULTI_TEMPLATE: {
      const { pdfTemplate, fields } = payload;
      const [pdfTemplateKey, pdfTemplateWidth] = getPdfUrlAndWidth(pdfTemplate.pdf_file_url);
      return {
        ...state,
        pdfPartsOfMultiTemplate: {
          ...state.pdfPartsOfMultiTemplate,
          [pdfTemplate.id]: {
            id: pdfTemplate.id,
            pdfTemplateWidth: parseInt(pdfTemplateWidth),
            pdfTemplateLink: pdfTemplate.pdf_full_link,
            pdfTemplateKey,
            pdfTemplateInfo: {
              templateName: pdfTemplate.name,
              templateTitle: pdfTemplate.template_title,
              templateDescription: pdfTemplate.description,
            },
            pdfTemplateFields: fields ?? pdfTemplate.pdf_fields_json,
          },
        },
      };
    }
    case SET_PDF_FIELD_BY_IDS_MULTI_TEMPLATE:
      return {
        ...state,
        pdfPartsOfMultiTemplate: {
          ...state.pdfPartsOfMultiTemplate,
          [payload.templateId]: {
            ...state.pdfPartsOfMultiTemplate[payload.templateId],
            pdfTemplateFields: {
              ...state.pdfPartsOfMultiTemplate[payload.templateId].pdfTemplateFields,
              [payload.fieldKey]: payload.field,
            },
          },
        },
      };
    case DELETE_PDF_PARTS_MULTI_TEMPLATE:
      return {
        ...state,
        pdfPartsOfMultiTemplate: {},
      };
    default:
      return state;
  }
};

export default multiTemplates;