import { FC, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  ContentCopy as ContentCopyIcon,
  DeleteOutline as DeleteIcon,
} from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Element as SlateElement } from 'slate';
import { useSlate } from 'slate-react';

import ElementSortButton from 'components/FormConstructor/Buttons/ElementSortButton';
import ModalConfirmDialog from 'components/Modals/ModalConfirmDialog';
import Tooltip from 'components/Tooltip';
import { changeSelectedSection } from 'store/actions/editorSlate';
import { createTemplateSection, deleteTemplateSection } from 'store/actions/template';
import { RootStateType } from 'store/reducers';
import { generateNumber } from 'utils/editorFieldHelpers';
import { getText } from 'utils/modalHelpers';

const ManageSectionButtons: FC<{sectionKey: number}> = ({ sectionKey }) => {
  const editor = useSlate();
  const dispatch = useDispatch();
  const { sections } = useSelector((state: RootStateType) => state.template);
  const isNotOnlyOneSection = sections.length > 1;
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState<boolean>(false);

  const activeSection = sections.find((el) => el.key === sectionKey);

  const onCopySectionHandler = () => {
    const indexOfSourceSection: number = sections.findIndex((el) => el.key === sectionKey);
    const data = activeSection as Partial<SlateElement>;
    if (data.children) delete data.children;

    if (data) {
      dispatch(createTemplateSection({
        ...data,
        section_id: null,
        key: generateNumber(),
        position: data.position || 0,
        name: `Copy of ${data.name}`,
      }, indexOfSourceSection));
    }
  };

  const deleteSection = (): void => {
    editor.selection = null;
    dispatch(changeSelectedSection(null));
    dispatch(deleteTemplateSection(sectionKey));
  };

  const handleModalVisibility = (): void => {
    setIsConfirmModalOpened((prevState: boolean) => !prevState);
  };

  return (
    <>
      <div className="form-section__buttons start-100">
        {
        isNotOnlyOneSection && (
          <Tooltip title="Move section">
            <IconButton aria-label="Move section" size="small" className="p-2">
              <ElementSortButton />
            </IconButton>
          </Tooltip>
        )
      }
        <Tooltip title="Copy section">
          <IconButton aria-label="Copy section" size="small" className="p-2" onMouseUp={onCopySectionHandler}>
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
        {
        isNotOnlyOneSection && (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete" size="small" className="p-2" onClick={handleModalVisibility}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )
      }
      </div>
      <ModalConfirmDialog
        onClose={handleModalVisibility}
        onConfirm={deleteSection}
        showDialog={isConfirmModalOpened}
        messageText={getText(activeSection?.name ?? '')}
      />
    </>
  );
};

export default ManageSectionButtons;