import { FC } from 'react';

import PropertiesList from 'components/Modals/SubtypesModal/PropertiesList';
import { UNDERLINE_SYMBOL } from 'constants/editors';
import { ISubtypeDifferentValues, SubtypeDifferentValuesType } from 'types/Subtypes';
import { capitalizeString } from 'utils/stringsHelpers';

interface IDifferentSubtypeValuesFormProps {
  subtypes: ISubtypeDifferentValues;
  onChangeProperty: (value: SubtypeDifferentValuesType, subtypeName: string, propertyName: string) => void;
}

const DifferentSubtypeValuesForm: FC<IDifferentSubtypeValuesFormProps> = ({
  subtypes,
  onChangeProperty,
}) => (
  <>
    {
      Object.entries(subtypes).map(([name, properties]) => (
        <div key={name} className="mb-3">
          <h3>Field Name: {capitalizeString(name, UNDERLINE_SYMBOL)}</h3>
          <PropertiesList
            properties={properties}
            onChangeProperty={(
              value: SubtypeDifferentValuesType,
              propertyName: string,
            ) => onChangeProperty(value, name, propertyName)}
          />
        </div>
      ))
    }
  </>
);

export default DifferentSubtypeValuesForm;