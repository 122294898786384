import { FC } from 'react';

import { Button } from 'reactstrap';

import { RECIPIENT_ASSIGNMENT } from 'constants/editors';
import { COPY } from 'constants/general';
import { AssignmentType } from 'types/Editor';

interface IShareLinkCard {
  item: AssignmentType;
  fullLink: string;
  copyHandler: (link: string) => void;
}

const ShareLinkCard: FC<IShareLinkCard> = ({
  item,
  fullLink,
  copyHandler,
}) => (
  <div
    key={item.label}
    className="border border-dark rounded-3 p-3 my-3 overflow-hidden d-flex flex-row justify-content-between align-items-center"
  >
    <div className="w-75">
      {
        item.type === RECIPIENT_ASSIGNMENT && (
          <div className="text-capitalize">{item.label}</div>
        )
      }
      <div>{fullLink}</div>
    </div>
    <Button
      disabled={!fullLink}
      color="primary"
      outline
      className="btn-sm h-50"
      onClick={() => copyHandler(fullLink)}
    >
      {COPY}
    </Button>
  </div>
);

export default ShareLinkCard;