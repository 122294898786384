import { ChangeEvent, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { MANAGER_ASSIGNMENT } from 'constants/editors';
import {
  addAssignment,
  changeAssignmentLabel,
  changeAssignmentType,
  removeAssignment,
  setAssignmentDeletionInfo,
  setOpenAssignmentsSettingsModal,
} from 'store/actions/editorSlate';
import { RootStateType } from 'store/reducers';
import { AssignmentsDropdownLabels, AssignmentsMainParts, AssignmentType } from 'types/Editor';
import { isManagerAssignment, isRecipientAssignment } from 'utils/assignmentsHelpers';
import { getUnitedOrderedAssignments } from 'utils/signNow/assignmentsHelper';
import { capitalizeString } from 'utils/stringsHelpers';

const useAssignmentsSettings = () => {
  const dispatch = useDispatch();
  const { openAssignmentsSettingsModal, assignments } = useSelector((state: RootStateType) => state.editorSlate);
  const company = useSelector((state: RootStateType) => state.profile.profileInfo?.company);

  const managersAssignments = assignments[AssignmentsMainParts.MANAGERS];
  const recipientsAssignments = assignments[AssignmentsMainParts.RECIPIENTS];

  useEffect(() => {
    managersAssignments.forEach((assignment) => {
      if (assignment && assignment?.label?.toLowerCase() === `${MANAGER_ASSIGNMENT} 1`) {
        dispatch(changeAssignmentLabel(assignment.type, company?.name));
      }
    });
    recipientsAssignments.forEach((assignment) => {
      if (isRecipientAssignment(assignment.label)) {
        dispatch(changeAssignmentLabel(assignment.type, capitalizeString(assignment.label)));
      }
    });
  }, [
    managersAssignments.length,
    recipientsAssignments.length,
    company?.name,
    dispatch,
  ]);

  const closeAssignmentsSettingsModalHandler = () => {
    dispatch(setOpenAssignmentsSettingsModal(false));
  };

  const unitedSortedAssignmentsArray = getUnitedOrderedAssignments(assignments);
  const isClosedButtonDisabled = unitedSortedAssignmentsArray.some((assignment) => !assignment.label);

  const addAssignmentHandler = () => {
    dispatch(addAssignment(
      AssignmentsMainParts.RECIPIENTS,
      recipientsAssignments.length,
      unitedSortedAssignmentsArray.length,
    ));
  };

  const deleteAssignmentHandler = (assignment: AssignmentType) => {
    if (assignment.fieldsKeys?.length) {
      dispatch(setAssignmentDeletionInfo(true, assignment.type));
    } else {
      dispatch(removeAssignment(assignment.type));
    }
  };

  const changeAssignmentLabelHandler = (event: ChangeEvent<HTMLInputElement>, assignmentType: string) => {
    const newLabel = event.target.value;
    dispatch(changeAssignmentLabel(assignmentType, newLabel));
  };

  const changeAssignmentTypeHandler = (
    assignmentLabel: AssignmentsDropdownLabels | null,
    currentAssignment: AssignmentType,
  ) => {
    if (!assignmentLabel) return;

    const previousMainType = isManagerAssignment(currentAssignment.type)
      ? AssignmentsMainParts.MANAGERS
      : AssignmentsMainParts.RECIPIENTS;
    const newMainType = assignmentLabel === AssignmentsDropdownLabels.INTERNAL_USER
      ? AssignmentsMainParts.MANAGERS
      : AssignmentsMainParts.RECIPIENTS;
    if (previousMainType === newMainType) return;

    dispatch(changeAssignmentType(currentAssignment.type, previousMainType, newMainType));
  };

  return {
    isClosedButtonDisabled,
    openAssignmentsSettingsModal,
    closeAssignmentsSettingsModalHandler,
    unitedSortedAssignmentsArray,
    addAssignmentHandler,
    deleteAssignmentHandler,
    changeAssignmentLabelHandler,
    changeAssignmentTypeHandler,
  };
};

export default useAssignmentsSettings;