import { FC, useState } from 'react';

import DescriptionEditor from 'components/Editor/FormView/DescriptionEditor';
import { KEY_CODE } from 'constants/editors';
import { PARAGRAPH_QUILL_FORMATS, PARAGRAPH_QUILL_MODULES } from 'constants/quillEditor';

interface IFormBulderDescription {
  description: string;
  setDescription: (sectionDescription: string) => void;
}

const FormBuilderDescription: FC<IFormBulderDescription> = ({
  description,
  setDescription,
}) => {
  const [isReadOnlyMode, setIsReadOnlyMode] = useState<boolean>(true);
  const [sectionDescription, setSectionDescription] = useState<string>(description);

  const onSaveDescription = (): void => {
    setIsReadOnlyMode(!isReadOnlyMode);
    setDescription(sectionDescription);
  };

  const changeReadOnlyMode = () => {
    setIsReadOnlyMode(!isReadOnlyMode);
  };

  return (
    <div
      role="button"
      tabIndex={0}
      className="form-builder_section_description"
      onKeyDown={(event) => {
        if (event.key === KEY_CODE.ENTER) {
          onSaveDescription();
        }
      }}
    >
      {isReadOnlyMode
        ? (
          <div>
            {description ? (
              <p
                className="text"
                onClick={changeReadOnlyMode}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: description }}
              />
            ) : (
              <p onClick={changeReadOnlyMode} className="text">Description goes here</p>
            )}
          </div>
        )
        : (
          <div onMouseLeave={onSaveDescription}>
            <DescriptionEditor
              isReadOnly={false}
              value={sectionDescription}
              onChange={setSectionDescription}
              quillFormats={PARAGRAPH_QUILL_FORMATS}
              quillModules={PARAGRAPH_QUILL_MODULES}
            />
          </div>
        )}
    </div>
  );
};

export default FormBuilderDescription;