import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { URL_PUBLIC_FILL_OUT } from 'constants/general';
import usePublicPageRouteMatch from 'hooks/usePublicPageRouteMatch';
import { setDismissIntroduceModal, setPublicPageCurrentDocument } from 'store/actions/publicPages';
import { RootStateType } from 'store/reducers';
import { IPublicPageGeneralStructure, IPublicPageRouteMatch } from 'types/PublicPage';

const useRedirectToPublicPage = (
  id: number | null = null,
  path = `/${URL_PUBLIC_FILL_OUT}`,
): () => void => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();

  const { main }: IPublicPageGeneralStructure = useSelector((state: RootStateType) => state.publicPages.structure);
  const { isAuthenticated } = useSelector((state: RootStateType) => state.user);
  const isSendLinkFlow = useSelector((state: RootStateType) => state.publicPages.isSendLinkFlow);
  const piiData = useSelector((state: RootStateType) => state.profile.piiData);

  const dismissIntroduceModal = isAuthenticated || (isSendLinkFlow && isAuthenticated) || !!piiData.length;

  const { pageType, id: matchId }: IPublicPageRouteMatch = usePublicPageRouteMatch();

  useEffect(() => {
    dispatch(setDismissIntroduceModal(dismissIntroduceModal));
  }, [dismissIntroduceModal]);

  if (!main.pageType || !main.id) {
    return () => {
      history.push(`/${pageType}/${matchId}${search}`);
    };
  }

  return () => {
    if (id) {
      /**
       * The side effect here can be unexpected.
       * Hook name useRedirectToPublicPage.
       * But here we have `dispatch setPublicPageCurrentDocument`.
       * This `dispatch` can be unexpected behavior.
       */
      dispatch(setPublicPageCurrentDocument(id));
    }
    history.push(`/${main.pageType}${path}/${main.id}${search}`);
  };
};

export default useRedirectToPublicPage;