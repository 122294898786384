import { DocumentNavigationTitles } from 'constants/documentsNavigation';
import {
  ALL_STATUSES,
  COMPLETED_TAB_STATUSES,
  DRAFT_TAB_STATUSES,
  IN_PROGRESS_TAB_STATUSES,
  NEW_TAB_STATUSES,
  SENT_TAB_STATUSES,
} from 'constants/documentStatuses';
import { QueryParameters } from 'constants/queryParametersManager';
import { DocumentStatusType } from 'types/Documents';
import { capitalizeString } from 'utils/stringsHelpers';

export const getCurrentTabByStatuses = (activeStatuses?: DocumentStatusType): DocumentNavigationTitles => {
  if (activeStatuses) {
    switch (activeStatuses) {
      case []:
        return DocumentNavigationTitles.ALL_DOCUMENTS;
      case ALL_STATUSES:
        return DocumentNavigationTitles.INBOX;
      case NEW_TAB_STATUSES:
        return DocumentNavigationTitles.NEW;
      case IN_PROGRESS_TAB_STATUSES:
        return DocumentNavigationTitles.IN_PROGRESS;
      case SENT_TAB_STATUSES:
        return DocumentNavigationTitles.SENT;
      case DRAFT_TAB_STATUSES:
        return DocumentNavigationTitles.DRAFTS;
      case COMPLETED_TAB_STATUSES:
        return DocumentNavigationTitles.COMPLETED;
      default:
        return DocumentNavigationTitles.ALL_DOCUMENTS;
    }
  }
  return DocumentNavigationTitles.ALL_DOCUMENTS;
};

export const getCurrentActiveStatusesByQueryParam = (previousTabQueryParam: string): DocumentStatusType => {
  const tabQueryParam = capitalizeString(previousTabQueryParam.replaceAll('-', ' '));
  switch (tabQueryParam) {
    case DocumentNavigationTitles.ALL_DOCUMENTS:
      return [];
    case DocumentNavigationTitles.INBOX:
      return ALL_STATUSES;
    case DocumentNavigationTitles.NEW:
      return NEW_TAB_STATUSES;
    case DocumentNavigationTitles.IN_PROGRESS:
      return IN_PROGRESS_TAB_STATUSES;
    case DocumentNavigationTitles.SENT:
      return SENT_TAB_STATUSES;
    case DocumentNavigationTitles.DRAFTS:
      return DRAFT_TAB_STATUSES;
    case DocumentNavigationTitles.COMPLETED:
      return COMPLETED_TAB_STATUSES;
    default:
      return [];
  }
};

export const replaceTabQueryParam = (urlParams: URLSearchParams) => {
  const tempUrl = `${window.location.pathname}?${urlParams.toString()}`;
  window.history.replaceState({ path: tempUrl }, '', tempUrl);
};

export const createQueryParametersObject = (urlParams: URLSearchParams) => {
  const result: Record<string, string> = {};
  Object.values(QueryParameters).forEach((queryParamName: string) => {
    const queryValue = urlParams.get(queryParamName);
    if (queryValue) {
      result[queryParamName] = queryValue;
    }
  });
  return result;
};