import { FC } from 'react';
import StatusIcon from 'components/Base/StatusIcon';
import { PUBLIC_PAGE_STATUS } from 'constants/publicPage';
import classes from 'scss/components/Icons/StatusIcon.module.scss';
import ReadyIcon from 'svg/DocumentStatuses/ReadyIcon';
import { IIconObject } from 'types/PublicPage';

interface IStatusIcon {
  status: PUBLIC_PAGE_STATUS;
}

const StatusIconWrapper: FC<IStatusIcon> = ({ status }) => {
  const icon: IIconObject = {
    [PUBLIC_PAGE_STATUS.NOT_OPEN]: null,
    [PUBLIC_PAGE_STATUS.STARTED]: <StatusIcon statusClass={classes.Quarter} />,
    [PUBLIC_PAGE_STATUS.IN_PROGRESS]: <StatusIcon statusClass={classes.Half} />,
    [PUBLIC_PAGE_STATUS.ALMOST_DONE]: <StatusIcon statusClass={classes.ThreeQuarters} />,
    [PUBLIC_PAGE_STATUS.DONE]: <ReadyIcon />,
  };

  return icon[status];
};

export default StatusIconWrapper;