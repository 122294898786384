import { Fragment } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import SimpleSpinner from 'components/Base/SimpleSpinner';
import StripeActionButton from 'components/Payment/Stripe/StripeActionButton';
import { useStripeInvoices } from 'hooks/payment/stripe';

const StripeInvoices = () => {
  const {
    invoices,
    isLoading,
    isMoreButtonIsVisible,
    getAllInvoices,
  } = useStripeInvoices();

  if (isLoading && invoices.length === 0) {
    return <SimpleSpinner />;
  }

  if (invoices.length === 0) {
    return <div className="d-flex justify-content-center m-4">Invoices history is empty</div>;
  }

  return (
    <div className="stripe-invoice">
      <div className="stripe-invoice__grid">
        {
          invoices.map((invoice) => (
            <Fragment key={invoice.id}>
              <a
                className="stripe-invoice__link"
                href={invoice.url}
                target="_blank"
                rel="noreferrer"
              >
                {invoice.date}
                <span className="stripe-invoice__link-icon">
                  <OpenInNewIcon />
                </span>
              </a>
              <span>
                {invoice.totalPrice}
              </span>
              <span className={`stripe-invoice__status ${`stripe-invoice__status--${invoice.status}`}`}>
                {invoice.status}
              </span>
              <span>
                {invoice.productName}
              </span>
            </Fragment>
          ))
        }
      </div>
      <StripeActionButton
        onClick={getAllInvoices}
        isLoading={isLoading}
        isVisible={isMoreButtonIsVisible}
      >
        <ExpandMoreIcon />
        View more
      </StripeActionButton>
    </div>
  );
};

export default StripeInvoices;