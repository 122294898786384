import {
  ChangeEvent,
  DragEvent,
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { DraggableData } from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';

import { NumberSize } from 're-resizable';

import DragAndDropElement from 'components/PdfEditor/DragAndDropElement';
import {
  DEFAULT_PDF_FIELD_HEIGHT,
  DEFAULT_PDF_FIELD_WIDTH,
  RECIPIENT_ASSIGNMENT,
  SELECT_FIELD,
  TEXT_FIELD,
} from 'constants/editors';
import {
  SPACE_BETWEEN_DRAG_COEFFICIENT,
  SPACE_BETWEEN_PAGES,
  SPACE_BETWEEN_PAGES_WITHOUT_PADDINGS,
} from 'constants/PDF';
import useFieldsHandler from 'hooks/useFieldsHandler';
import { setSelectedFieldKey, updateFieldByKey } from 'store/actions/pdfTemplates';
import { RootStateType } from 'store/reducers';
import { IDocumentDetails } from 'types/Documents';
import {
  FieldAssignmetsType,
  PDFFieldRadioCheckboxUpdateHandler,
} from 'types/Editor';
import {
  FieldsCoords,
  FieldTypeOption,
  HTMLFieldElement,
  PDFFieldType,
  PDFFieldTypeObject,
  SelectFieldType,
} from 'types/PdfTemplates';
import {
  generateNumber,
  getGroupedFieldKeys,
  updateSelectFieldOptionsHandler,
} from 'utils/editorFieldHelpers';
import { getIsSelectedOtherOption, getSelectValuesToUpdate } from 'utils/editorSelectFieldHelpers';
import updateValueWithMaxLength from 'utils/Fields/updateValueWithMaxLength';
import { isEmptyObject, isNotEmptyObject } from 'utils/isEmptyObject';
import {
  createdNewField,
  getEnableResize,
  getFieldCoords,
  isCheckboxFieldType,
  isRadioSelectFieldType,
  sortFieldsByCoords,
  updatePDFGroupedByParamFieldValues,
} from 'utils/pdfTemplatesHelpers';
import {
  getDefaultSignerFromAssignments,
  getSignersPositionsFromAssignments,
} from 'utils/signNow/assignmentsHelper';

type DragAndDropContainerProps = {
  pageNumber: number;
  pageHeight: number;
  isPublicPage?: boolean;
  readOnlyMode?: boolean;
  isMultiTemplate?: boolean;
  pdfTemplateId?: number;
  customFields?: PDFFieldType[] | null;
  isFormBuilderOpened?: boolean;
};

const DragAndDropContainer: FC<DragAndDropContainerProps> = ({
  children,
  pageNumber,
  pageHeight,
  isPublicPage = false,
  readOnlyMode = false,
  isMultiTemplate = false,
  pdfTemplateId = 0,
  customFields = null,
  isFormBuilderOpened = false,
}) => {
  const dispatch = useDispatch();

  const { main } = useSelector((state: RootStateType) => state.publicPages.structure);
  const { pdfTemplateWidth, selectedFieldKey } = useSelector((state: RootStateType) => state.pdfTemplates);
  const pdfPartsOfMultiTemplate = useSelector((state: RootStateType) => state.multiTemplates.pdfPartsOfMultiTemplate);
  const fieldsFromPdfMultiTemplate = pdfPartsOfMultiTemplate
    && pdfPartsOfMultiTemplate[pdfTemplateId]?.pdfTemplateFields;
  const fieldsFromPdfTemplate = useSelector((state: RootStateType) => state.pdfTemplates.pdfTemplateFields);
  const pdfTemplateFields: PDFFieldTypeObject = isMultiTemplate ? fieldsFromPdfMultiTemplate : fieldsFromPdfTemplate;
  const documentDetails: IDocumentDetails | null = useSelector((state: RootStateType) => state.user.documentDetails);
  const assignments = useSelector((state: RootStateType) => state.editorSlate.assignments);
  const currentSigner = useSelector((state: RootStateType) => state.signNow.currentSigner);

  const { updateFieldsHandler } = useFieldsHandler({ editor: null });

  const pageRef = useRef<HTMLDivElement>(null);

  const [pageInnerHeight, setPageInnerHeight] = useState<number>(0);
  const [hideValueStateObject, setHideValueStateObject] = useState<Record<number, Record<string, boolean>>>({});

  useEffect(() => {
    Object.values(pdfTemplateFields).forEach((field: PDFFieldType) => {
      if (field.textFieldMask && field.textFieldMask !== 'none') {
        setHideValueStateObject((prevState) => ({ ...prevState, [field.key]: { mask: true, hide: true } }));
      }
    });
  }, []);

  useEffect(() => {
    setPageInnerHeight(pageHeight - SPACE_BETWEEN_PAGES);
  }, [pageHeight]);

  const onDragOver = (event: DragEvent<HTMLDivElement>): boolean => {
    if (event.preventDefault) event.preventDefault();
    return false;
  };

  const updatePdfGroupedFields = (
    field: PDFFieldType,
    value: string,
    selectFieldProps: Partial<PDFFieldType>,
  ): void => {
    updatePDFGroupedByParamFieldValues({
      allFields: Object.values(pdfTemplateFields),
      element: {
        assignment: field.assignment,
        name: field.name || '',
        key: field.key,
        type: field.type,
      },
      newValue: value,
      dispatch,
      filterGroupBy: field.groupBy,
      pdfTemplateId,
      isPublicPage,
      selectFieldProps,
      storageDocumentKey: main.id,
    });
  };

  useEffect(() => {
    if (isPublicPage) {
      Object.values(pdfTemplateFields).forEach((field) => {
        if (field.groupBy) {
          updatePdfGroupedFields(field, field.value, {});
        }
      });
    }
  }, []);

  const onDrop = (event: DragEvent<HTMLDivElement>): void => {
    if (event.preventDefault) event.preventDefault();
    if (event.stopPropagation) event.stopPropagation();

    const newField: SelectFieldType = JSON.parse(event.dataTransfer.getData('field'));

    if (!isEmptyObject(newField)) {
      const { currentTarget } = event;
      if (currentTarget instanceof HTMLElement) {
        const coords: FieldsCoords = getFieldCoords(event, '[data-pdf-node="pdf-wrapper"]');
        const position: number = Object.keys(pdfTemplateFields).length;
        let assignment: FieldAssignmetsType = currentSigner as FieldAssignmetsType ?? RECIPIENT_ASSIGNMENT;
        if (documentDetails && documentDetails.is_signnow_document && documentDetails.assignments && !currentSigner) {
          const defaultSigner = getDefaultSignerFromAssignments(documentDetails.assignments);
          assignment = defaultSigner.type as FieldAssignmetsType;
        }
        const field: PDFFieldType = createdNewField({
          type: newField.type,
          typeVariant: newField.typeVariant,
          position,
          coords,
          pageNumber,
          extendedField: { key: generateNumber() },
          assignment,
        });
        dispatch(updateFieldByKey(field.key, field));
        dispatch(setSelectedFieldKey(field.key));
      }
    }
  };

  const changeRadioSelectValueAndUpdateStore: PDFFieldRadioCheckboxUpdateHandler = useCallback((event, field) => {
    const target = event.target as HTMLInputElement;
    const { id } = target.dataset;
    const { updatedOptions, value } = updateSelectFieldOptionsHandler<FieldTypeOption>({
      options: field.options || [],
      viewMode: field.selectFieldType,
      id: Number(id),
      selectedIndex: 0,
    });

    const previousValue = field.selectedOtherOption ? field.value : '';
    const updatedProperties = getSelectValuesToUpdate({
      fieldValue: value,
      previousValue,
      updatedOptions,
      fieldView: field.selectFieldType,
    }) as Partial<PDFFieldType>;

    updateFieldsHandler(field, { ...updatedProperties });
  }, [updateFieldsHandler]);

  const changeFieldValueAndUpdateStore = useCallback((
    event: ChangeEvent<HTMLFieldElement> | null,
    field: PDFFieldType,
    newValue: string = '',
  ) => {
    // event exists for all field types except Date
    const target = (event as ChangeEvent<HTMLInputElement>)?.target;
    const value = target?.value ?? '';
    const isCheckboxField = isCheckboxFieldType(field.type);
    const isRadioSelect = isRadioSelectFieldType(field.type, field.selectFieldType);

    let valueToUpdate = {};

    if (isCheckboxField) {
      valueToUpdate = { checked: target?.checked ?? false };
    } else if (field.type === SELECT_FIELD && !isRadioSelect) {
      const { selectedIndex } = (event as ChangeEvent<HTMLSelectElement>).target;
      const updatedOptions = field.options?.map((option, index) => ({
        ...option,
        checked: index === selectedIndex - 1,
      }));
      const selectedOtherOption = getIsSelectedOtherOption(updatedOptions);
      valueToUpdate = {
        options: updatedOptions,
        value: selectedOtherOption ? '' : value,
        selectedOtherOption: isNotEmptyObject(selectedOtherOption ?? {}),
      };
    } else if (field.type === TEXT_FIELD) {
      const resultValue = updateValueWithMaxLength(value, field.maxLength);
      valueToUpdate = { value: resultValue };
    } else {
      valueToUpdate = { value: value || newValue };
    }

    updateFieldsHandler(field, valueToUpdate);
  }, [updateFieldsHandler]);

  const onChangeHandlerOtherOption = useCallback((
    event: ChangeEvent<HTMLFieldElement>,
    field: PDFFieldType,
  ) => {
    const value: string = event.target.value;

    updateFieldsHandler(field, { value });
  }, [updateFieldsHandler]);

  const updateFieldCoords = useCallback((data: DraggableData, field: PDFFieldType) => {
    if (data.x === field.coords.x && data.y === field.coords.y) {
      return;
    }
    const pageOuterHeight = pageInnerHeight + SPACE_BETWEEN_PAGES_WITHOUT_PADDINGS;
    const isPlusDelta = data.y > 0;

    const delta = Math.floor(data.y / (isPlusDelta ? pageOuterHeight : pageInnerHeight));
    const pageNumber = field.pageNumber as number + delta;

    const pageHeightCoefficient = pageOuterHeight
      + SPACE_BETWEEN_PAGES_WITHOUT_PADDINGS - SPACE_BETWEEN_DRAG_COEFFICIENT;
    const correctY = data.y - delta * pageHeightCoefficient;

    const updatedField = {
      ...field,
      coords: {
        x: data.x,
        y: correctY,
      },
      ...(delta && { pageNumber }),
    };
    dispatch(updateFieldByKey(field.key, updatedField));
  }, [pageInnerHeight]);

  const updateFieldSize = useCallback((delta: NumberSize, field: PDFFieldType) => {
    const updatedField = {
      ...field,
      size: {
        width: (field.size?.width || DEFAULT_PDF_FIELD_WIDTH) + delta.width,
        height: (field.size?.height || DEFAULT_PDF_FIELD_HEIGHT) + delta.height,
      },
    };
    dispatch(updateFieldByKey(field.key, updatedField));
  }, []);

  const isDisabledField = useCallback((element: PDFFieldType) => {
    if (!isPublicPage) {
      return readOnlyMode;
    }
    if (readOnlyMode) return true;
    return element.assignment !== main.mainAssignment;
  }, [isPublicPage, readOnlyMode, main.mainAssignment]);

  const getFieldAndChangeIndex = useCallback(({ target }: MouseEvent<HTMLDivElement>, key: number, zIndex: string) => {
    if (target instanceof HTMLElement || target instanceof SVGElement) {
      // Note: this data attribute is used on the backend side for Print view
      const wrapperElement = target.closest(`[data-element-wrapper="${key}"]`);
      if (wrapperElement && wrapperElement instanceof HTMLElement) {
        wrapperElement.style.zIndex = zIndex;
      }
    }
  }, []);

  const hideRevealFieldValueHandler = useCallback((
    fieldKey: number,
    newValue: boolean,
    stateType: 'mask' | 'hide' = 'mask',
  ) => {
    if (hideValueStateObject[fieldKey]?.[stateType] !== newValue) {
      setHideValueStateObject((prevState) => ({
        ...prevState,
        [fieldKey]: { ...prevState[fieldKey], [stateType]: newValue },
      }));
    }
  }, [hideValueStateObject]);

  const sortedFields = Object.values(pdfTemplateFields).sort(sortFieldsByCoords);
  const resizeBoundParent = document.querySelector(
    `.drag-and-drop-container[data-container-number="${pageNumber}"]`,
  ) as HTMLElement;
  const signersPositions = getSignersPositionsFromAssignments(assignments);
  const isResizable: boolean = !isPublicPage && !readOnlyMode && !isFormBuilderOpened;
  const enabledResize = getEnableResize(isResizable);
  const idsToSelect = getGroupedFieldKeys(Object.values(pdfTemplateFields), selectedFieldKey);

  return (
    <div
      className="drag-and-drop-container"
      onDragOver={onDragOver}
      onDrop={onDrop}
      data-container-number={pageNumber}
      data-drag-and-drop-container="true"
      data-pdf-id={pdfTemplateId}
      ref={pageRef}
    >
      {
        sortedFields.map((element: PDFFieldType, index) => (
          <DragAndDropElement
            key={`DragAndDropElementKey${index + 1}`}
            idsToSelect={idsToSelect}
            element={element}
            signersPositions={signersPositions}
            pageNumber={pageNumber}
            index={index}
            pdfTemplateWidth={pdfTemplateWidth}
            customFields={customFields}
            resizeBoundParent={resizeBoundParent}
            isMultiTemplate={isMultiTemplate}
            pdfTemplateId={pdfTemplateId}
            hideValueStateObject={hideValueStateObject}
            pdfTemplateFields={pdfTemplateFields}
            isDisabledField={isDisabledField}
            updateFieldCoords={updateFieldCoords}
            getFieldAndChangeIndex={getFieldAndChangeIndex}
            hideRevealFieldValueHandler={hideRevealFieldValueHandler}
            updateFieldSize={updateFieldSize}
            changeFieldValueAndUpdateStore={changeFieldValueAndUpdateStore}
            changeRadioSelectValueAndUpdateStore={changeRadioSelectValueAndUpdateStore}
            onChangeHandlerOtherOption={onChangeHandlerOtherOption}
            isResizable={isResizable}
            enabledResize={enabledResize}
            readOnlyMode={readOnlyMode}
            isPublicPage={isPublicPage}
            isFormBuilderOpened={isFormBuilderOpened}
          />
        ))
      }
      {children}
    </div>
  );
};

export default DragAndDropContainer;