import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { ContentEditableEvent } from 'react-contenteditable';

import useDebounce from 'hooks/useDebounce';
import updateValueWithMaxLength from 'utils/Fields/updateValueWithMaxLength';

interface IUseInputFieldProps {
  initValue: string;
  onChangeInitValue: (value: string) => void;
  maxLength: number | string;
}

const useInputField = ({ initValue, onChangeInitValue, maxLength }: IUseInputFieldProps) => {
  const [value, setValue] = useState(initValue);

  const debouncedValue = useDebounce(value, 500);

  useEffect(() => {
    if (initValue !== value) {
      setValue(initValue);
    }
  }, [initValue]);

  useEffect(() => {
    if (debouncedValue !== initValue) {
      onChangeInitValue(debouncedValue);
    }
  }, [debouncedValue]);

  const onChangeValue = useCallback(({
    target,
  }: ContentEditableEvent | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const updatedValue = updateValueWithMaxLength(target.value, maxLength);
    setValue(updatedValue);
  }, [maxLength]);

  return {
    value,
    onChangeValue,
  };
};

export default useInputField;