import { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from 'components/Base/Button';
import DocumentList from 'components/Intro/DocumentList';
import DocumentSenderInfo from 'components/Intro/DocumentSenderInfo';
import Intro from 'components/Intro/Intro';
import AttachmentLoadingModal from 'components/Modals/AttachmentLoadingModal';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import ModalContentWindow from 'components/Modals/ModalContentWindow';
import PoliciesModal from 'components/Modals/Policies/PoliciesModal';
import MondayComSelectExistingRecordForSubitemModal from 'components/PublicPage/MondayComSelectExistingRecordForSubitemModal';
import PublicFooter from 'components/PublicPage/PublicFooter';
import { REVIEW_DOCUMENT, START_SIGNING } from 'constants/general';
import { SELECT_APPLICATION_HEADER, SELECT_APPLICATION_MODAL_TEXT } from 'constants/mondayComIntegration';
import ROUTES from 'constants/routes';
import usePublicPageData from 'hooks/usePublicPageData';
import useRedirectToPublicPage from 'hooks/useRedirectToPublicPage';
import useSaveDocumentHandler from 'hooks/useSaveDocumentHandler';
import { useWholeURL } from 'hooks/useSearchParams';
import useStyleSchema, { CustomStylesEnum } from 'hooks/useStylesFromSchema';
import { deleteCurrentIdFromData, setPublicPageStructure } from 'store/actions/publicPages';
import { RootStateType } from 'store/reducers';
import ArrowRightIcon from 'svg/ArrowIcons/ArrowRightIcon';
import { IPublicPageGeneralStructure, IPublicPageState } from 'types/PublicPage';
import { isManagerAssignment } from 'utils/assignmentsHelpers';
import getAssignmentByUrl from 'utils/getAssignmentByUrl';
import getCorrectDocumentIDToSign from 'utils/PublicPage/getCorrectDocumentIDToSign';
import getIsEveryDocumentsDone from 'utils/PublicPage/getIsEveryDocumentsDone';
import { getPlural } from 'utils/replaceString';

import classes from 'scss/components/PublicFooter/PublicFooter.module.scss';

const GreetingPage = () => {
  usePublicPageData();
  useWholeURL();
  const dispatch = useDispatch();
  const history = useHistory();
  const { structure }: IPublicPageState = useSelector((state: RootStateType) => state.publicPages);
  const { documents, main }: IPublicPageGeneralStructure = structure;
  const styleSchema = useStyleSchema<CustomStylesEnum.button | CustomStylesEnum.description>(
    [CustomStylesEnum.button, CustomStylesEnum.description],
  );

  const pageAssignment = getAssignmentByUrl(main.pageType);
  const isManagerPage = isManagerAssignment(pageAssignment);
  const [isSaveButtonVisible, setSaveButtonVisibility] = useState<boolean>(false);
  const [isDocumentsLoading, setIsDocumentsLoading] = useState<boolean>(true);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const {
    isReadOnlyDocument,
    isShowConfirmationModal,
    setIsShowConfirmationModal,
    isDisabledModalButton,
    documentsCount,
    saveDocumentHandler,
    isLoaderVisible,
    onFinishRedirectHandler,
  } = useSaveDocumentHandler();

  const blockHistory = useCallback(() => {
    if (isLoaderVisible) {
      window.history.pushState(null, '', window.location.href);
    }
  }, [isLoaderVisible]);

  window.addEventListener('popstate', blockHistory);

  useEffect(() => {
    if (isLoaderVisible) {
      blockHistory();
    }
    return () => {
      window.removeEventListener('popstate', blockHistory);
    };
  }, [blockHistory, isLoaderVisible]);

  if (!isDocumentsLoading && !main.id && !main.pageType) {
    history.push(ROUTES.LOGIN);
  }

  useEffect(() => {
    const thereArentManagerFields = isManagerAssignment(pageAssignment) && documents[0]?.emptyDocument;
    if (thereArentManagerFields && !isReadOnlyDocument) {
      setShowErrorModal(true);
    }
    return () => {
      if (thereArentManagerFields) {
        dispatch(deleteCurrentIdFromData());
        dispatch(setPublicPageStructure({
          documents: [],
          main: { id: '', pageType: '' },
          formBuilderFields: {},
          groupedFieldsStructure: [],
        }));
      }
    };
  }, [dispatch, documents, isReadOnlyDocument, main.pageType, pageAssignment]);

  useEffect(() => {
    // isComponentUnmounted variable is used for prevent invoking setState after unmounting component (memory leak)
    let isComponentUnmounted = false;
    if (documents.length) {
      if (!isComponentUnmounted) {
        const isEveryDocumentDone = getIsEveryDocumentsDone(documents);
        setIsShowConfirmationModal(isEveryDocumentDone);
        setSaveButtonVisibility(isEveryDocumentDone);
        setIsDocumentsLoading(false);
      }
    }
    return () => {
      isComponentUnmounted = true;
    };
  }, [documents]);

  const redirectToFillOutPage = useRedirectToPublicPage(getCorrectDocumentIDToSign(documents));

  if (!documentsCount) return null;

  const title = isSaveButtonVisible ? 'Please Review/Confirm' : 'Let\'s Get Started!';
  const description = isSaveButtonVisible
    ? `The ${getPlural('document', documentsCount)} ${documentsCount > 1 ? 'are' : 'is'} ready for submission`
    : 'Please review and sign the following';
  const pluralDocument = getPlural('document', documentsCount);
  const hasMoreThanOneDocument = documentsCount > 1;

  return (
    <>
      <Intro
        title={title}
        description={description}
        descriptionWidth="large"
        justifyContent="center"
      >
        <DocumentList documents={documents} />
        {!isManagerPage && <DocumentSenderInfo />}
        <AttachmentLoadingModal
          isVisible={isLoaderVisible}
          onFinish={onFinishRedirectHandler}
        />
      </Intro>
      <PublicFooter>
        {
          isSaveButtonVisible
            ? (
              <Button
                onClick={isReadOnlyDocument ? redirectToFillOutPage : saveDocumentHandler}
                disabled={isDisabledModalButton}
                classes={classes.PublicButton}
                style={styleSchema.button}
              >
                {isReadOnlyDocument
                  ? REVIEW_DOCUMENT
                  : `Send ${documentsCount} ${getPlural('Document', documentsCount)}`}
              </Button>
            )
            : (
              <Button
                classes={classes.PublicButton}
                endIcon={<ArrowRightIcon />}
                onClick={redirectToFillOutPage}
                style={styleSchema.button}
              >
                { isReadOnlyDocument ? REVIEW_DOCUMENT : START_SIGNING }
              </Button>
            )
        }
      </PublicFooter>
      <ModalContentWindow
        centered
        goBack
        showDefaultText={false}
        showModal={showErrorModal}
        onCloseModal={setShowErrorModal}
        titleText="There are no manager's fields"
      >
        <div>
          There are no manager&apos;s fields. You will be redirected after clicking on close button.<br />
        </div>
      </ModalContentWindow>
      <MondayComSelectExistingRecordForSubitemModal
        offerToSelectApplicants
        headerText={SELECT_APPLICATION_HEADER}
        bodyText={SELECT_APPLICATION_MODAL_TEXT}
      />
      {
        !isReadOnlyDocument && (
          <ConfirmationModal
            withLogo
            withBranding
            showSpinnerIfDisabled
            isOpen={isShowConfirmationModal}
            isDisabled={isDisabledModalButton}
            onCancel={() => redirectToFillOutPage()}
            onConfirm={isReadOnlyDocument ? redirectToFillOutPage : saveDocumentHandler}
            cancelButtonText="Go Back to Editing"
            confirmButtonText="I agree"
            description={(
              <>
                <p className="mb-1">
                  The {pluralDocument} {hasMoreThanOneDocument ? 'are' : 'is'} ready for submission.
                </p>
                <p>
                  I agree to
                  sign {hasMoreThanOneDocument ? 'these' : 'this'} {pluralDocument} electronically
                  and agree to the <PoliciesModal showInline />.
                </p>
              </>
            )}
          />
        )
      }
    </>
  );
};

export default GreetingPage;