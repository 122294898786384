const toggleShowSections = (element: any) => {
  const sectionsBlock = document.getElementById(`sections_${element.id}_${element.key}`);
  const toggleButton = document.getElementById(`toggle_${element.id}_${element.key}`);
  if (sectionsBlock && toggleButton) {
    if (sectionsBlock.style.display === 'block') {
      sectionsBlock.style.display = 'none';
      toggleButton.style.transform = 'none';
    } else {
      sectionsBlock.style.display = 'block';
      toggleButton.style.transform = 'rotate(0.5turn)';
    }
  }
};

export default toggleShowSections;