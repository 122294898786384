import { useState } from 'react';

import { useSelector } from 'react-redux';
import { Input, Label } from 'reactstrap';

import PdfLoaderMessage from 'components/Editor/components/pdfRenderer/PdfLoaderMessage';
import DownloadDocument from 'components/EditorHeader/DownloadDocument';
import ModalConfirmDialog from 'components/Modals/ModalConfirmDialog';
import { STATUSES } from 'constants/documentStatuses';
import { DOWNLOAD } from 'constants/general';
import { RootStateType } from 'store/reducers';
import { handleCancelRequest } from 'utils/axiosInstance';

interface ISavePDFButton {
  isPreviewMode?: boolean;
  isCombinePdfOptionEnabled?: boolean;
  combinePdfOptionToggler?: () => void;
  isCollectionPage?: boolean;
  isCollectionExecutedPage?: boolean;
}

const SavePdfButton = ({
  isPreviewMode = false,
  isCombinePdfOptionEnabled = false,
  combinePdfOptionToggler = () => null,
  isCollectionPage = false,
  isCollectionExecutedPage = false,
}: ISavePDFButton) => {
  const { documentDetails, collectionExecutedDetails } = useSelector((state: RootStateType) => state.user);

  const [disableDownloadButton, setDisableDownloadButton] = useState<boolean>(false);
  const [addAuditLogPage, setAddAuditLogPage] = useState<boolean>(Boolean(documentDetails));
  const [showConfirmDownloadModal, setShowConfirmDownloadModal] = useState<boolean>(false);

  const cancelDownloadingHandler = () => {
    handleCancelRequest();
    setDisableDownloadButton(false);
  };

  const onConfirmDownloadModal = (downloadCallback: () => void) => {
    setShowConfirmDownloadModal(false);
    downloadCallback();
  };

  const onClickDownloadHandler = (downloadCallback: () => void) => {
    if (
      (!documentDetails || documentDetails?.status === STATUSES.completed)
      && (!collectionExecutedDetails || collectionExecutedDetails?.status === STATUSES.completed)
    ) {
      downloadCallback();
    } else {
      setShowConfirmDownloadModal(true);
    }
  };

  return (
    <div className="d-flex flex-column pb-0">
      <DownloadDocument
        document={documentDetails}
        addAuditLogPage={addAuditLogPage}
        isCombinePdfOptionEnabled={isCombinePdfOptionEnabled}
        isPreviewMode={isPreviewMode}
        isCollectionPage={isCollectionPage}
        isCollectionExecutedPage={isCollectionExecutedPage}
        render={(downloadDocumentHandler: () => void, isDisabled: boolean) => (
          <>
            <button
              type="button"
              className="dropdown-item editor-header-dropdown-item"
              onClick={() => onClickDownloadHandler(downloadDocumentHandler)}
              disabled={isDisabled}
            >
              {DOWNLOAD}
            </button>
            <ModalConfirmDialog
              messageText="Downloading this document will not be locked or digitally signed because it's status is not completed. Are you sure?"
              confirmText="Yes, Download Anyways"
              declineText="No, Go Back"
              showDialog={showConfirmDownloadModal}
              onClose={() => setShowConfirmDownloadModal(false)}
              onConfirm={() => onConfirmDownloadModal(downloadDocumentHandler)}
            />
          </>
        )}
      />
      {
        documentDetails && (
          <div className="ms-2">
            <div className="dropdown-item editor-header-dropdown-item pt-0">
              <Label check className="me-2">Audit Log</Label>
              <Input
                type="checkbox"
                checked={addAuditLogPage}
                onChange={() => setAddAuditLogPage((prevState: boolean) => !prevState)}
              />
            </div>
            <div className="dropdown-item editor-header-dropdown-item pt-0">
              <Label check className="me-2">Combine PDF</Label>
              <Input
                type="checkbox"
                checked={isCombinePdfOptionEnabled}
                onChange={combinePdfOptionToggler}
              />
            </div>
          </div>
        )
      }
      <PdfLoaderMessage
        isModal
        modalActions={{ isShownModal: disableDownloadButton, onCloseModalHandler: cancelDownloadingHandler }}
        title="Preparing the PDF for downloading..."
      />
    </div>
  );
};

export default SavePdfButton;