import { FC } from 'react';

import { Button } from 'reactstrap';

import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

interface IButtonGeneralProps {
  buttonText: string;
  additionalClassName?: string;
  AppendIcon?: OverridableComponent<SvgIconTypeMap<Record<string, never>, 'svg'>>;

  color?: string;
  outline?: boolean;
  block?: boolean;
  onClickCallback: CallableFunction;
  disabled?: boolean;

  buttonStyles?: React.CSSProperties;
}

const ButtonGeneral: FC<IButtonGeneralProps> = ({
  buttonText,
  additionalClassName = '',
  AppendIcon,

  color = 'primary',
  outline = false,
  block = false,
  onClickCallback,
  disabled = false,
  buttonStyles = {},
}) => {
  const filterMouseClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    callback: CallableFunction,
  ) => {
    // prevent the button stuck on the active state after click
    event.preventDefault();
    callback();
  };

  return (
    <Button
      block={block}
      disabled={disabled}
      outline={outline}
      color={color}
      style={buttonStyles}
      className={`${additionalClassName} button-main`}
      onMouseDown={(e) => filterMouseClick(e, onClickCallback)}
    >
      {buttonText} {AppendIcon ? <AppendIcon /> : undefined}
    </Button>
  );
};

export default ButtonGeneral;