import { FC, useRef } from 'react';

import DocumentItem from 'components/Intro/DocumentItem';
import { IPublicPageDocumentStructure } from 'types/PublicPage';

import classes from 'scss/components/Intro/DocumentList.module.scss';

type IDocumentList = {
  documents: IPublicPageDocumentStructure[];
}

const DocumentList: FC<IDocumentList> = ({
  documents,
}) => {
  const listElement = useRef<HTMLDivElement | null>(null);

  return (
    <div className={`${classes.List}`} data-parent="">
      <div className={classes.InnerWrapper} ref={listElement}>
        {
          documents.map((document: IPublicPageDocumentStructure, idx: number) => (
            <div className={classes.Document} key={document.id}>
              <DocumentItem document={document} />
              {(documents.length > 1 && idx !== documents.length - 1) && (
                <hr className={classes.Divider} />
              )}
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default DocumentList;