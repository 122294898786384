import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DragHandleIcon from 'svg/DragHandleIcon';

export interface ISortableItemProps {
  value: JSX.Element | React.ReactNode;
  id: number;
  removeItem: (id: number) => void;
  editItem?: (id: number) => void;
  isEditingItem?: boolean;
  showRemoveButton? : boolean | undefined;
  readonly?: boolean;
  withPaddings?: boolean;
}

const SortableItem = SortableElement((props: ISortableItemProps) => {
  const {
    value,
    id,
    removeItem,
    editItem,
    isEditingItem,
    showRemoveButton = true,
    readonly = false,
    withPaddings = true,
  } = props;

  const onDeleteItem: React.MouseEventHandler<SVGSVGElement> = (event) => {
    event.stopPropagation();
    removeItem(id);
  };

  const onEditItem: React.MouseEventHandler<SVGSVGElement> = (event) => {
    event.stopPropagation();
    if (editItem) {
      editItem(id);
    }
  };

  const renderItemButtons = (): JSX.Element | undefined => {
    const removeButton = <DeleteOutlineIcon className="delete-icon close" onClick={onDeleteItem} />;

    if (!isEditingItem) {
      return (
        <>
          <EditIcon className="settings-icon close" onClick={onEditItem} />
          { removeButton }
        </>
      );
    }
    if (showRemoveButton) return removeButton;
  };

  const paddingsClass = withPaddings ? 'paddings' : 'custom';
  const listClasses = `list-group-item list-group-item-${paddingsClass} border-0 border-bottom d-flex`;

  return (
    <li
      className={listClasses}
      style={{ cursor: 'grab' }}
    >
      <span><DragHandleIcon /></span>
      {value}
      {!readonly && renderItemButtons()}
    </li>
  );
});

export default SortableItem;