import { FC, useState } from 'react';

import { Descendant } from 'slate';

import ButtonFindOutline from 'components/Sidebar/ButtonFindOutline';
import SearchReplaceForm from 'components/Sidebar/SearchReplaceForm';

interface ISearchFieldAndContentProps {
  content: Descendant[];
  setContent: (data: Descendant[]) => void;
  setString: (value: string) => void;
  showSearch?: boolean;
  isReadOnly?: boolean;
}

const SearchFieldAndContent: FC<ISearchFieldAndContentProps> = ({
  content,
  setContent,
  setString,
  showSearch = false,
  isReadOnly = false,
}) => {
  const [isSearchVisible, setIsSearchVisible] = useState<boolean>(false);

  const toggleSearchForm = () => {
    setIsSearchVisible((prevStateValue) => !prevStateValue);
  };

  return (
    <div className="search-form">
      {
        isSearchVisible
          ? (
            <div className="search-form__layot">
              <SearchReplaceForm
                content={content}
                setContent={setContent}
                setString={setString}
                isReadOnly={isReadOnly}
                showSearch={showSearch}
              />
            </div>
          )
          : null
      }
      <div className="search-form__button">
        <ButtonFindOutline onClick={toggleSearchForm} isActive={isSearchVisible} />
      </div>
    </div>
  );
};

export default SearchFieldAndContent;