import { Dispatch, FC, SetStateAction } from 'react';

import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import Button from 'components/Base/Button';
import InputField from 'components/Base/InputField';
import useMondayComAccessToken from 'hooks/MondayCom/useMondayComAccessToken';

import 'scss/components/TabNavigation/TabNavigation.scss';

interface IMondayComAccessTokenModal {
  showMondayComAccessTokenModal: boolean;
  setShowMondayComAccessTokenModal: Dispatch<SetStateAction<boolean>>;
}

const MondayComAccessTokenModal: FC<IMondayComAccessTokenModal> = ({
  showMondayComAccessTokenModal,
  setShowMondayComAccessTokenModal,
}) => {
  const {
    localToken,
    tokenFromStorage,
    onChangeTokenHandler,
    saveToken,
    deleteToken,
  } = useMondayComAccessToken();

  const onCloseModalHandler = () => {
    setShowMondayComAccessTokenModal(false);
  };

  return (
    <Modal
      centered
      isOpen={showMondayComAccessTokenModal}
      contentClassName="mx-auto"
    >
      <ModalHeader className="modal-header border-0" toggle={onCloseModalHandler}>
        Please, Provide Monday.com Access Token
      </ModalHeader>
      <ModalBody className="p-4">
        <div className="mb-3">
          <InputField
            type="password"
            name="mondayComAccessToken"
            label="Monday.com access token"
            value={localToken}
            onChange={(e) => onChangeTokenHandler(e)}
          />
        </div>
        <div className="d-inline me-3">
          <Button
            onClick={() => {
              saveToken();
              onCloseModalHandler();
            }}
            disabled={!localToken}
          >
            Save access token
          </Button>
        </div>
        <Button
          onClick={() => {
            deleteToken();
            onCloseModalHandler();
          }}
          disabled={!tokenFromStorage}
        >
          Delete access token
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default MondayComAccessTokenModal;