import { FC, ReactElement, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { combinePdfOptionWarning } from 'constants/documentsNavigation';
import useDownloadCollection from 'hooks/DownloadCollection/useDownloadCollection';
import { downloadAttachment } from 'services/api';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { RootStateType } from 'store/reducers';
import { PageSettingsType } from 'types/PageSettingsType';
import { doesDocumentIncludeDocAttachments } from 'utils/documentsHelper';
import { createCSSString, generateHtmlResource } from 'utils/fileLinkDownload';
import { getMarginsForPageInInches, getPageSettings } from 'utils/PageSettings';

interface IDownloadDocument {
  render: (downloadDocumentHandler: () => void, isDisabled: boolean) => ReactElement;
  // TODO - write interface for currentDocumentExecuted on rootState level
  document: any;
  isCombinePdfOptionEnabled?: boolean;
  addAuditLogPage?: boolean;
  isPreviewMode?: boolean;
  isCollectionPage?: boolean;
  isCollectionExecutedPage?: boolean;
}

const DownloadDocument: FC<IDownloadDocument> = ({
  render,
  document: appDocument,
  addAuditLogPage = false,
  isCombinePdfOptionEnabled = false,
  isPreviewMode = false,
  isCollectionPage = false,
  isCollectionExecutedPage = false,
}) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const { pdfTemplateKey, pdfTemplateFields } = useSelector((state: RootStateType) => state.pdfTemplates);
  const { pdfPartsOfMultiTemplate } = useSelector((state: RootStateType) => state.multiTemplates);
  const {
    getCollectionRequestObject,
  } = useDownloadCollection(isCollectionPage, isCollectionExecutedPage);
  const pageSettings: PageSettingsType | null = useSelector((state: RootStateType) => getPageSettings(state));
  const sectionPadding = getMarginsForPageInInches(pageSettings);

  const editorHtmlElement: Element | null = useMemo(() => (
    document.querySelector('[data-html-source="true"]')
  ), [isPreviewMode]);

  const downloadDocumentHandler = async () => {
    if (isCombinePdfOptionEnabled && doesDocumentIncludeDocAttachments(appDocument)) {
      toast.warn(combinePdfOptionWarning);
    }

    setIsDisabled(true);

    let globalCssString = '';
    if (!isPreviewMode || editorHtmlElement) {
      globalCssString = await createCSSString();
    }

    const isCollection = !!document.querySelector('.swiper-slide');
    const documentHTMLElement = document.querySelector('[data-html-source="true"]');
    const pdfHTML = document.querySelectorAll('[data-drag-and-drop-container]');

    let documentHTML = '';
    let editorHtmlString = '';
    if (documentHTMLElement) {
      const documentHTMLElementClone = documentHTMLElement?.cloneNode(true);
      (documentHTMLElementClone as any).style.padding = 0;
      documentHTML = (documentHTMLElementClone as any)?.outerHTML;
    }
    if (editorHtmlElement) {
      const editorHtmlElementClone = editorHtmlElement?.cloneNode(true);
      (editorHtmlElementClone as any).style.padding = 0;
      editorHtmlString = (editorHtmlElementClone as any)?.innerHTML;
    }

    if (isCollection) {
      await apiErrorHandler(downloadAttachment, await getCollectionRequestObject());
    } else {
      if (pdfHTML.length) {
        const pdfDocumentID = Number(pdfHTML[0].getAttribute('data-pdf-id')) || 0;
        const htmlResource = generateHtmlResource(pdfDocumentID);
        await apiErrorHandler(downloadAttachment, {
          isPdfTemplate: true,
          pdfTemplateFields: Object.values(pdfTemplateFields),
          html: htmlResource,
          css: globalCssString,
          pdfBasePath: pdfDocumentID
            ? pdfPartsOfMultiTemplate[pdfDocumentID].pdfTemplateKey
            : pdfTemplateKey, // pdfTemplateKey is not present on public pages
          executedDocId: appDocument?.id || null,
          addLogPage: addAuditLogPage,
          combinePDF: isCombinePdfOptionEnabled,
        });
      }
      if ((documentHTML || editorHtmlString) || isPreviewMode) {
        await apiErrorHandler(downloadAttachment, {
          pdf: true,
          html: documentHTML || editorHtmlString,
          css: globalCssString,
          executedDocId: appDocument?.id || null,
          addLogPage: addAuditLogPage,
          combinePDF: isCombinePdfOptionEnabled,
          pageMargins: sectionPadding,
        });
      }
    }
    setIsDisabled(false);
  };

  return render(downloadDocumentHandler, isDisabled);
};

export default DownloadDocument;