import { useState } from 'react';

import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { getFieldNameFromSnakeCase } from 'utils/createNameConstant';

import classes from 'scss/components/PublicFooter/PublicFooter.module.scss';

const AutoFillValueField = ({
  filterName,
  options,
  onChangeSelectValueHandler,
}: {
  filterName: string,
  options: string[],
  onChangeSelectValueHandler: (label: string) => void,
}) => {
  const [value, setValue] = useState<string | null>(null);

  const onChangeSelectValue = (label: string | null) => {
    setValue(label);
    onChangeSelectValueHandler(label ?? '');
  };

  return (
    <Autocomplete
      disablePortal
      freeSolo
      blurOnSelect
      options={Array.from(new Set(options))}
      isOptionEqualToValue={(option, value) => (option === value as string)}
      value={value}
      onChange={(_, value) => onChangeSelectValue(value)}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          onChange={() => null}
          label={getFieldNameFromSnakeCase(filterName ?? '')}
        />
      )}
      classes={classes}
    />
  );
};

export default AutoFillValueField;