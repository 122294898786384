import { ChangeEvent, KeyboardEvent } from 'react';

import { BACKSPACE_HOTKEY, MAX_FIELD_VALUE_LENGTH, MIN_FIELD_VALUE_LENGTH } from 'constants/editors';
import { NUMBERS_REGEX } from 'constants/validation';

export const onKeyDownMaxLengthField = (event: KeyboardEvent<HTMLInputElement>): void => {
  if (event.key !== BACKSPACE_HOTKEY && !NUMBERS_REGEX.test(event.key)) {
    event.preventDefault();
  }
};

export const getLength = (event: ChangeEvent<HTMLInputElement>): number => {
  const value: number = Number.parseInt(event.target.value, 10);
  if (!Number.isNaN(value)) {
    // eslint-disable-next-line no-param-reassign
    event.target.value = String(value);
  }
  const maxLength: number = Math.min(value, MAX_FIELD_VALUE_LENGTH);
  return maxLength >= MIN_FIELD_VALUE_LENGTH ? maxLength : MIN_FIELD_VALUE_LENGTH;
};

export default undefined;