import { Descendant, Editor, Node as SlateNode, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { NEW_LINE, PARAGRAPH } from 'constants/editors';
import { ICustomElement } from 'types/Editor';
import { createTableQuestion } from 'utils/TableQuestion/createTableQuestionHelpers';

const insertTableQuestion = (editor: Editor) => {
  const { selection } = editor;
  if (!selection || !ReactEditor.isFocused(editor)) return;

  const headerFragment: Descendant[] = Editor.fragment(editor, selection);
  const captionText: string = headerFragment.map((n) => SlateNode.string(n)).join(NEW_LINE);

  Transforms.delete(editor, {
    at: selection,
  });

  const table: ICustomElement = createTableQuestion(captionText);

  Transforms.insertNodes(
    editor,
    [table, { type: PARAGRAPH, children: [{ text: '' }] }],
    {
      at: selection.focus,
      select: true,
    },
  );
};

export default insertTableQuestion;