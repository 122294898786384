import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'reactstrap';

import ShowSignatureValue from 'components/Modals/SignatureModal/ShowSignatureValue';
import SigningModal from 'components/Modals/SignatureModal/SigningModal';
import { SIGNED_BY } from 'constants/general';
import {
  DEFAULT_FONT_SIZE_TYPED_SIGNATURE,
  SIGNATURE_FIELD_DEFAULT_VARIATION,
  SIGNATURE_TABS,
} from 'constants/signatures';
import useDefaultSignatureUnauthenticatedUser, {
  RETURNED_SIGNATURE_TAB,
  RETURNED_SIGNATURE_TYPE,
  RETURNED_SIGNATURE_VALUE,
} from 'hooks/useDefaultSignatureUnauthenticatedUser';
import useTrackingLogEvents from 'hooks/useTrackingLogEvents';
import { setPdfFieldByIdsMultiTemplate } from 'store/actions/collections';
import { updateFieldByKey } from 'store/actions/pdfTemplates';
import { RootStateType } from 'store/reducers';
import { PDFPropsType } from 'types/PdfTemplates';
import { getMainTypeAssignment } from 'utils/assignmentsHelpers';
import { createNameConstant } from 'utils/createNameConstant';
import { onFieldCustomKeyDown } from 'utils/hotkeysHelpers';
import { getSignatureValueAndActiveTab } from 'utils/userPersonalData/personalDataHelpers';

const SigningEditable: React.FC<PDFPropsType> = ({
  field,
  isReadOnlyMode,
  fieldStyle,
  isMultiTemplate = false,
  pdfTemplateId = 0,
}) => {
  const dispatch = useDispatch();
  const callSigningTrackingEvents = useTrackingLogEvents(Number(field.key), field.assignment);

  const {
    save_signature: saveSignature,
    signature_path: signaturePath,
  } = useSelector((state: RootStateType) => state.profile?.personalData ?? {});
  const {
    unauthenticatedPublicUser,
    saveSignatureDataToSessionStorage,
    getSignatureDataFromSessionStorage,
  } = useDefaultSignatureUnauthenticatedUser();

  const [showSigningModal, setShowSigningModal] = useState<boolean>(false);
  const [isFieldFirstClick, setIsFieldFirstClick] = useState<boolean>(true);
  const [signatureTab, setSignatureTab] = useState<SIGNATURE_TABS>(field.signatureTab ?? SIGNATURE_TABS.DRAW_TAB);
  const [localFontSize, setLocalFontSize] = useState<number>(DEFAULT_FONT_SIZE_TYPED_SIGNATURE);

  useEffect(() => {
    /**
     * Correct font size appears only if a field has value. It means after onSaveSignatureHandler invocation.
     */
    dispatch(updateFieldByKey(field.key, { ...field, fontSize: localFontSize }));
  }, [localFontSize]);

  useEffect(() => {
    const tabToShow = field.signatureTab ?? SIGNATURE_TABS.DRAW_TAB;
    if (tabToShow !== signatureTab) {
      setSignatureTab(tabToShow);
    }
  }, [field.signatureTab]);

  const onSaveSignatureHandler = (base64String: string, currentTab: SIGNATURE_TABS, startingHash: string = '') => {
    if (!isReadOnlyMode) {
      if (unauthenticatedPublicUser) {
        saveSignatureDataToSessionStorage(base64String, currentTab, field.signatureFieldVariation);
      }
      setSignatureTab(currentTab);

      const updatedField = {
        ...field,
        value: base64String,
        signatureTab: currentTab,
        starting_hash: startingHash,
      };
      if (isMultiTemplate) {
        dispatch(setPdfFieldByIdsMultiTemplate({
          templateId: pdfTemplateId,
          field: updatedField,
          fieldKey: field.key,
        }));
      } else {
        dispatch(updateFieldByKey(field.key, updatedField));
      }
    }
  };

  const onOpenWindow = async () => {
    if (!isReadOnlyMode) {
      if (saveSignature && signaturePath && isFieldFirstClick) {
        setIsFieldFirstClick(false);
        const { signatureValue, signatureHash, tabToShow } = await getSignatureValueAndActiveTab();
        onSaveSignatureHandler(signatureValue, tabToShow, signatureHash);
        callSigningTrackingEvents();
      } else if (unauthenticatedPublicUser) {
        /**
         * This flow is used for unauthenticated users on public pages
         */
        const {
          [RETURNED_SIGNATURE_VALUE]: userSignature,
          [RETURNED_SIGNATURE_TAB]: userSignatureTab,
          [RETURNED_SIGNATURE_TYPE]: userSignatureType,
        } = getSignatureDataFromSessionStorage(field.signatureFieldVariation);
        if (
          userSignature
          && userSignatureTab
          && !field.value
          && (
            (field.signatureFieldVariation === userSignatureType)
            || (!field.signatureFieldVariation && userSignatureType === SIGNATURE_FIELD_DEFAULT_VARIATION)
          )
        ) {
          onSaveSignatureHandler(userSignature, userSignatureTab);
          callSigningTrackingEvents();
        } else {
          setShowSigningModal(true);
        }
      } else {
        setIsFieldFirstClick(true);
        setShowSigningModal(true);
      }
    }
  };

  const onKeyDownHandler = (event: React.KeyboardEvent) => {
    onFieldCustomKeyDown(event, isReadOnlyMode ? undefined : onOpenWindow);
  };

  return (
    <>
      {
        !field.value
          ? (
            <Input
              autoComplete="off"
              name={createNameConstant(field.fieldName)}
              placeholder={field.fieldName}
              type="text"
              value={field.value}
              onClick={onOpenWindow}
              onChange={() => null}
              tabIndex={isReadOnlyMode ? -1 : 0}
              onKeyDown={onKeyDownHandler}
              disabled={isReadOnlyMode}
              style={fieldStyle}
              className={`pdf-field-${getMainTypeAssignment(field.assignment)}`}
              data-pdf-field
            />
          )
          : (
            <>
              <div className="title-signed-by">{SIGNED_BY}</div>
              <button
                type="button"
                className={
                  `signature-pdf-field-button form-control pdf-field-${getMainTypeAssignment(field.assignment)}`
                }
                disabled={isReadOnlyMode}
                onClick={onOpenWindow}
                style={fieldStyle}
                tabIndex={isReadOnlyMode ? -1 : 0}
                onKeyDown={onKeyDownHandler}
              >
                <ShowSignatureValue
                  isPdf
                  imageValueProps={{
                    className: `signature-pdf-field-image ${isReadOnlyMode ? 'img-disabled' : ''}`,
                    draggable: false,
                  }}
                  textValueProps={{
                    className: 'signature-pdf-field-typed',
                  }}
                  value={field.value}
                  setLocalFontSize={setLocalFontSize}
                />
              </button>
              {
                field.starting_hash && (
                  <div className="text-center signature-hash">
                    {field.starting_hash}...
                  </div>
                )
              }
            </>
          )
      }
      <SigningModal
        fieldKey={field.key}
        existingValue={field.value}
        currentTab={signatureTab}
        showModal={showSigningModal}
        onSave={onSaveSignatureHandler}
        onCloseModal={setShowSigningModal}
        assignment={field.assignment}
        signatureFieldVariation={field.signatureFieldVariation}
      />
    </>
  );
};

export default SigningEditable;