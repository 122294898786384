import { ChangeEvent, CSSProperties } from 'react';

import { Element as SlateElement, Text } from 'slate';

import { IRenderElementProps } from 'components/Editor/components/RenderElement';
import { FIELD_VALUE_FONT } from 'constants/editors';

export const getFontSizeValue = (event: ChangeEvent<HTMLInputElement>): number | string => {
  const fontSizeValue: number = Math.max(
    Math.min(Number.parseInt(event.target.value), FIELD_VALUE_FONT.maxFontSize),
    FIELD_VALUE_FONT.minFontSize,
  );
  const valueToUpdate = Number.isNaN(fontSizeValue) ? '' : fontSizeValue;

  // eslint-disable-next-line no-param-reassign
  event.target.value = String(valueToUpdate);
  return valueToUpdate;
};

export const setFieldLabelFontSize = (fieldChildren: Text[], fontSize: number | string): Text[] => {
  const fieldLabelProperties = { 'text-size': fontSize > 0, size: String(fontSize) };
  return fieldChildren.map((textNode) => {
    if (fontSize > 0) {
      return { ...textNode, ...fieldLabelProperties };
    }
    const updatedTextNode = { ...textNode };
    if ('size' in updatedTextNode) {
      delete updatedTextNode.size;
    }
    if ('text-size' in updatedTextNode) {
      delete updatedTextNode['text-size'];
    }
    return updatedTextNode;
  });
};

export const getFieldFontProperties = (
  elementChildren: (SlateElement | Text)[],
  elementFontSize: SlateElement['fontSize'],
) => {
  let fontSizeProperty = {};
  (elementChildren as Text[]).forEach(({ size }, index) => {
    if (index === 0 && size) {
      fontSizeProperty = { fontSize: Number(size) };
    }
  });

  return {
    fontSizeProperty,
    isDifferent: 'fontSize' in fontSizeProperty && elementFontSize !== fontSizeProperty.fontSize,
  };
};

export const getFieldFontStyles = (
  children: IRenderElementProps['children'] | undefined,
  fontSizeValue: number | string | undefined,
): CSSProperties => {
  const fontSize = Number.parseInt(String(fontSizeValue));
  if (!Number.isNaN(fontSize)) {
    return { fontSize: `${fontSize}px` };
  }

  const leaf = children && children[0].props.text;
  return (leaf && leaf['text-size'] && leaf.size)
    ? { fontSize: `${leaf.size}px` }
    : {};
};