import { KeyboardEvent } from 'react';

import isHotkey from 'is-hotkey';
import {
  Editor as SlateEditor,
  Element as SlateElement,
  Range,
} from 'slate';

import { ListsEditor, onKeyDown as onKeyDownInList } from 'components/Editor/editor-custom-plugins/lists';
import { isInList } from 'components/Editor/editor-custom-plugins/lists/lib';
import {
  ARROW_LEFT_HOTKEY,
  ARROW_RIGHT_HOTKEY,
  BACKSPACE_HOTKEY,
  BULLETED_LIST,
  BULLETED_LIST_HOTKEY,
  DELETE_HOTKEY,
  HOT_KEY_SETTINGS,
  INDENT_DECREASE_FORMAT,
  INDENT_INCREASE_FORMAT,
  LINE_BREAK_SETTINGS,
  PARAGRAPH_BREAK_HOTKEY,
  TAB_KEY_SETTINGS,
  WHITE_SPACE,
} from 'constants/editors';
import { TEXT_TABLE_QUESTION_ELEMENTS } from 'constants/tableQuestion';
import { FormSelectUpdateEvent, IHandleTableKeyProps, MarkFormatType, SelectFieldOptionType } from 'types/Editor';
import { toggleBlock } from 'utils/editorBlockHelpers';
import { insertImageBreakLine } from 'utils/editorImageHelpers';
import { addPropertyIndent } from 'utils/editorIndentHelpers';
import { createListFromTextByMarkers } from 'utils/editorListHelpers';
import { insertTableRowBelow, isTableActive } from 'utils/editorTableHelpers';
import { getIsSlateFieldName } from 'utils/Fields/fieldNamesHelper';
import { isSelectionAcrossCells } from 'utils/TableQuestion/hotkeyTableQuestionHelpers';
import { getIsTableQuestion } from 'utils/TableQuestion/tableQuestionHelpers';

export const toggleMark = (editor: SlateEditor, format: MarkFormatType): void => {
  const marks = SlateEditor.marks(editor);
  const isActive = marks ? marks[format] : false;
  if (isActive) {
    editor.removeMark(format);
  } else {
    editor.addMark(format, true);
  }
};

const handleTableKey = ({
  editor,
  event,
  isDelete,
}: IHandleTableKeyProps): void => {
  const [cell] = Array.from(
    SlateEditor.nodes(editor, {
      match: (n) => SlateElement.isElement(n) && TEXT_TABLE_QUESTION_ELEMENTS.includes(n.type),
    }),
  );
  const { selection } = editor;
  if (!selection) return;
  if (cell && Range.isCollapsed(selection)) {
    const position = cell[1];
    const end = SlateEditor[isDelete ? 'end' : 'start'](editor, position);
    const range = { anchor: selection.anchor, focus: end };
    if (Range.equals(selection, range)) {
      event.preventDefault();
    }
  } else if (isSelectionAcrossCells(editor, selection)) {
    event.preventDefault();
  }
};

export const onEditorKeyDown = (editor: SlateEditor, event: KeyboardEvent<HTMLElement>): void => {
  const hotkey = HOT_KEY_SETTINGS.find((key) => isHotkey(key.value, event));
  if (hotkey) {
    event.preventDefault();
    const mark = hotkey.style;
    toggleMark(editor, mark);
  }
  if (isInList(editor as ListsEditor)) {
    return onKeyDownInList(editor as ListsEditor, event);
  }
  if (isHotkey(DELETE_HOTKEY, event) || isHotkey(ARROW_RIGHT_HOTKEY, event)) {
    handleTableKey({ editor, event, isDelete: true });
  }
  if (isHotkey(ARROW_LEFT_HOTKEY, event)) {
    handleTableKey({ editor, event, isDelete: false });
  }
  if (isHotkey(BACKSPACE_HOTKEY, event)) {
    handleTableKey({ editor, event, isDelete: false });
    addPropertyIndent(editor, INDENT_DECREASE_FORMAT, event);
  }
  if (isHotkey(BULLETED_LIST_HOTKEY, event)) {
    toggleBlock(editor, BULLETED_LIST);
  }
  if (isHotkey(TAB_KEY_SETTINGS.tabKey, event)) {
    handleTableKey({ editor, event, isDelete: false });
    event.preventDefault();
    const tableActive = isTableActive(editor);
    if (tableActive) {
      insertTableRowBelow(editor);
    } else {
      addPropertyIndent(editor, INDENT_INCREASE_FORMAT);
    }
    return;
  }
  if (isHotkey(LINE_BREAK_SETTINGS.key, event)) {
    event.preventDefault();
    // This check finds Slate field names and blocks shift+enter hotkey for them.
    const isSlateFieldName = getIsSlateFieldName(editor);
    if (isSlateFieldName) return;
    const isCreated = insertImageBreakLine(editor);
    if (isCreated) return;
    return editor.insertText(LINE_BREAK_SETTINGS.indent);
  }
  if (isHotkey(PARAGRAPH_BREAK_HOTKEY, event)) {
    const isCreated = insertImageBreakLine(editor);
    const isTableQuestion = getIsTableQuestion(editor);
    if (isCreated || isTableQuestion) {
      return event.preventDefault();
    }
  }
  if (isHotkey(WHITE_SPACE, event)) {
    handleTableKey({ editor, event, isDelete: false });
    createListFromTextByMarkers(editor, event);
  }
};

export const onSelectFieldKeyDown = (
  event: KeyboardEvent,
  onChangeCallback: (event: FormSelectUpdateEvent, newValue: string | null) => void,
  options?: SelectFieldOptionType[],
) => {
  if (isHotkey(PARAGRAPH_BREAK_HOTKEY, event)) {
    const target = event.target as HTMLSelectElement;
    const fieldValue = target?.value;
    const selectedOption = options?.find((option) => option.label === fieldValue);
    if (fieldValue && selectedOption) {
      onChangeCallback(event, fieldValue || null);
    }
  }
};

export const onFieldCustomKeyDown = (
  event: KeyboardEvent,
  onEventCallback: () => void = () => null,
) => {
  if (isHotkey(PARAGRAPH_BREAK_HOTKEY, event)) {
    onEventCallback();
  }
};