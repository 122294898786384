import { Descendant } from 'slate';

import { DOCUMENT_TYPE, FORM_TYPE } from 'constants/general';
import { IDocumentDetails } from 'types/Documents';
import { PublicPageDataType } from 'types/PublicPage';
import { ITemplateSection } from 'types/redux';
import { ISection } from 'types/Sections';
import { ITemplateDetails } from 'types/Templates';
import { addSectionsDocument } from 'utils/editorHelpers';
import { isPDFDocument } from 'utils/PublicPage/documentTypeChecker';
import { getDocumentSectionsInfo, getSortedSections } from 'utils/sectionUtils';

const getSectionsFromContentJson = (data: PublicPageDataType, assignment?: string): ITemplateSection[] => {
  const template = (data as ITemplateDetails);
  const document = (data as IDocumentDetails);

  let contentJson: Descendant[] = [];
  if (template.sections) {
    const newSections: ISection[] = getSortedSections(template.sections);
    contentJson = addSectionsDocument(newSections, false);
  }

  if (document.content_json && [DOCUMENT_TYPE, FORM_TYPE].includes(document.type || '')) {
    contentJson = document.content_json as Descendant[];
  }

  const descriptionSection: ITemplateSection = {
    name: 'Description',
    description: data.description,
    key: `public-page-key-${data.id}`,
  };

  const resultArray: ITemplateSection[] = [...getDocumentSectionsInfo(contentJson, assignment)];

  if (data.description || isPDFDocument(data)) {
    resultArray.unshift(descriptionSection);
  }

  return resultArray;
};

export default getSectionsFromContentJson;