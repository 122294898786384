import { Editor as SlateEditor, Element as SlateElement, NodeEntry, Range } from 'slate';

import { FIELD_TYPE, SECTION } from 'constants/editors';
import { filterNodes } from 'utils/editorHelpers';

export const getSelectedSections = (editor: SlateEditor): NodeEntry[] => {
  const { selection } = editor;

  if (selection) {
    const selectedSections = Array.from(
      SlateEditor.nodes(editor, {
        match: (n) => SlateElement.isElement(n) && n.type === SECTION,
      }),
    );

    if (selection && Range.isExpanded(selection)) {
      return selectedSections;
    }
  }
  return [];
};

export const getSectionsWithFieldIDs = (
  editor: SlateEditor,
): { key: number, name: string; fieldKeys: number[]; }[] => {
  const filterChildren = filterNodes(
    editor.children,
    (node) => (SlateElement.isElement(node) && node.type === SECTION),
  )?.map((section) => {
    const fieldKeys = filterNodes(
      section.children,
      (node) => SlateElement.isElement(node) && FIELD_TYPE.includes(node.type),
    )?.map((field) => field.key || 0);
    return { key: section.key || 0, name: section.name || '', fieldKeys: fieldKeys || [] };
  }) || [];

  return filterChildren || [];
};