import { Editor, Node as SlateNode, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { ICustomElement } from 'types/Editor';
import { IRemoveTableQuestionProps } from 'types/TableQuestion';
import { findTableQuestion, getTextFromNode } from 'utils/TableQuestion/tableQuestionHelpers';

export const removeTableQuestion = ({
  editor,
  selection,
}: IRemoveTableQuestionProps) => {
  if (!selection || !ReactEditor.isFocused(editor)) return;

  const tableEntry = findTableQuestion(editor);

  if (tableEntry) {
    const [, tablePath] = tableEntry;
    const captionNode = SlateNode.get(editor, [...tablePath, 0]);
    const captionText = getTextFromNode(captionNode as ICustomElement);
    Editor.withoutNormalizing(editor, () => {
      Transforms.removeNodes(editor, { at: tablePath });
      Editor.insertText(editor, captionText);
    });
  }
};

export default removeTableQuestion;