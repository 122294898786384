import { FC } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import SidebarOutline from 'components/Sidebar/Outline';
import SortableItem from 'components/Sidebar/SortableItem';
import { FORM_BUILDER } from 'constants/formBuilder';
import { addSectionsToFormBuilder, updateFormBuilderSectionsOrder } from 'store/actions/userData';
import { RootStateType } from 'store/reducers';
import { FormBuilderType } from 'types/FormBuilder';
import { IFormBuilderSection } from 'types/Sections';
import { createFormBuilderSection } from 'utils/FormBuilder/formBuilderHelpers';
import { getFormBuilderByType } from 'utils/FormBuilder/store';

interface IFormBuilderOutlineProps {
  formBuilderType: FormBuilderType;
}

const FormBuilderOutline: FC<IFormBuilderOutlineProps> = ({
  formBuilderType = FORM_BUILDER.COLLECTION,
}) => {
  const dispatch = useDispatch();
  const structure: IFormBuilderSection[] = useSelector((state: RootStateType) => (
    getFormBuilderByType(state, formBuilderType)
  ));

  const onSortEnd = (formBuilder: IFormBuilderSection[]) => dispatch(updateFormBuilderSectionsOrder(
    formBuilder,
    formBuilderType,
  ));

  const createSidebarSectionHandler = () => {
    dispatch(addSectionsToFormBuilder([createFormBuilderSection({ name: '' })], formBuilderType));
  };

  return structure
    ? (
      <SidebarOutline
        elements={structure}
        createElementHandler={createSidebarSectionHandler}
        onSortEndHandler={onSortEnd}
      >
        {
          structure.map((element: IFormBuilderSection, index: number) => (
            <SortableItem
              isFormBuilder
              key={element.key}
              id={Number(element.key)}
              index={index}
              value={element.name}
              sectionLength={structure.length}
              formBuilderType={formBuilderType}
              formBuilderSectionFields={element.fields}
            />
          ))
        }
      </SidebarOutline>
    )
    : null;
};

export default FormBuilderOutline;