import { FC, ReactNode } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace';
import { useSlate } from 'slate-react';

import { EDITOR_PAGE_TABS } from 'constants/tabs';
import useSliderValidation from 'hooks/useSliderValidation';
import { setPublicPageCurrentSlideIndex } from 'store/actions/publicPages';
import { RootStateType } from 'store/reducers';
import { IPublicPageDocumentStructure } from 'types/PublicPage';

const SliderFraction: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { totalIndexSlides, currentSlideIndex } = useSelector((state: RootStateType) => (
    state.publicPages.currentDocument
  ));

  const dispatch = useDispatch();
  const currentDocument: IPublicPageDocumentStructure | null = useSelector(
    (state: RootStateType) => state.publicPages.currentDocument,
  );
  const isFormsView = currentDocument?.activeEditorTab === EDITOR_PAGE_TABS[1].id;
  const editor = useSlate();

  const { changeCurrentSlideIndex } = useSliderValidation(
    isFormsView,
    editor.children,
  );

  const goBackSlideIndex = () => {
    dispatch(setPublicPageCurrentSlideIndex(currentSlideIndex - 1));
  };

  const goNextSlideIndex = () => {
    changeCurrentSlideIndex();
  };

  return (
    <>
      <div className="form-title-public">
        { children }
      </div>
      {
        totalIndexSlides !== 1
          ? (
            <div className="swiper-fraction">
              <button
                type="button"
                className="swiper-prev-slide-button"
                onClick={goBackSlideIndex}
                disabled={currentSlideIndex === 1}
              >
                <KeyboardBackspace />
              </button>
              <span>
                <strong>{currentSlideIndex}</strong> / {totalIndexSlides}
              </span>
              <button
                disabled={currentSlideIndex === totalIndexSlides}
                type="button"
                className="swiper-next-slide-button"
                onClick={goNextSlideIndex}
              >
                <KeyboardBackspace />
              </button>
            </div>
          )
          : null
      }
    </>
  );
};

export default SliderFraction;