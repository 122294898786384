/* eslint-disable */
import React, { useState, useEffect } from "react";
import MultiSelect from "react-multi-select-component";
import { SelectOption } from "types/Misc";

type Props = {
  selectedStates?: any;
  states: any[];
  setSelectedStates: any;
  grayTheme?: boolean;
};

const themeStyles = {
  primary: "#5A9EFF",
  hover: "#F2F7FF",
  border: "#5A9EFF",
  gray: "#5A9EFF",
  borderRadius: "0.25rem",
  height: "1.875rem",
};

const grayThemeStyles = {
  primary: "#495057",
  hover: "#F2F7FF",
  border: "#ced4da",
  heading: "#495057",
  gray: "#495057",
  borderRadius: "0.25rem",
  height: "1.875rem",
};

const overrideStrings = {
  selectSomeItems: "Select States...",
  allItemsAreSelected: "All States are selected",
};

const StateDropdown: React.FC<Props> = (props) => {

  const {
    selectedStates,
    states,
    setSelectedStates,
    grayTheme = false,
  } = props;

  const [options, setOptions] = useState<{ label: string; value: number }[]>(
    []
  );

  const selectStates = (states: SelectOption[]) => {
    setSelectedStates(states);
  };

  const themeStyle = grayTheme ? grayThemeStyles : themeStyles;

  useEffect(() => {
    if (!states) return;
    if (states.length) {
      const options = states.map((item) => ({
        label: item.abbreviation,
        value: item.id,
      }));

      setOptions(options);
    }
  }, [states]);

  return (
    <MultiSelect
      className="dropdown-multi-select"
      options={options}
      value={selectedStates}
      onChange={selectStates}
      labelledBy={"Select"}
      overrideStrings={overrideStrings}
    />
  );
};

export default StateDropdown;
