import { FC, SVGAttributes } from 'react';

const AttachmentsTabIcon: FC<SVGAttributes<any>> = ({ fill = '#2F3947' }) => (
  <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.1668 6.25004V17.2709C15.1668 19.3888 13.526 21.1042 11.5002 21.1042C9.47433
      21.1042 7.8335 19.3888 7.8335 17.2709V5.29171C7.8335 3.96921 8.86016 2.89587
      10.1252 2.89587C11.3902 2.89587 12.4168 3.96921 12.4168 5.29171V15.3542C12.4168
      15.8813 12.0043 16.3125 11.5002 16.3125C10.996 16.3125 10.5835 15.8813 10.5835
      15.3542V6.25004H9.2085V15.3542C9.2085 16.6767 10.2352 17.75 11.5002 17.75C12.7652
      17.75 13.7918 16.6767 13.7918 15.3542V5.29171C13.7918 3.17379 12.151 1.45837
      10.1252 1.45837C8.09933 1.45837 6.4585 3.17379 6.4585 5.29171V17.2709C6.4585
      20.1842 8.7135 22.5417 11.5002 22.5417C14.2868 22.5417 16.5418 20.1842 16.5418
      17.2709V6.25004H15.1668Z"
      fill={fill}
    />
  </svg>
);

export default AttachmentsTabIcon;