import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import AccountSettingsWrapper from 'components/AccountSettings/AccountSettingsWrapper';
import Button from 'components/Base/Button';
import KebabMenu, { HandlersValueType } from 'components/KebabMenu';
import AddGroupMembersModal from 'components/Modals/AddGroupMembersModal';
import GroupManagementModal from 'components/Modals/GroupManagementModal';
import MUITableManagement from 'components/MuiTables/MUITableManagement';
import { GROUP_MANAGEMENT_MODAL_VARIANT } from 'constants/general';
import { GROUP_MANAGEMENT_MENU, setKebabColumnHeading } from 'constants/KebabMenus';
import {
  createGroup,
  deleteGroup,
  deleteGroupsBatch,
  getGroup,
  getGroups,
  updateGroup,
} from 'store/actions/userProfile';
import { RootStateType } from 'store/reducers';
import { SelectedRowsType } from 'types/Mui';
import { GroupType } from 'types/userProfile';
import { groupColumns, transformGroupsToTableRows } from 'utils/dataColumns';
import { validateGroupName } from 'utils/validation';

import 'scss/components/_managementSettings.scss';

const GroupManagement = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalVariant, setModalVariant] = useState<GROUP_MANAGEMENT_MODAL_VARIANT | string>('');
  const [groupFields, setGroupFields] = useState<{ name: string }>({ name: '' });
  const [idGroupToUpdate, setIdGroupToUpdate] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isAddMembersModalOpen, setIsAddMembersModalOpen] = useState<boolean>(false);

  const groups: GroupType[] = useSelector((state: RootStateType) => state.profile.groups) ?? [];

  useEffect(() => {
    if (!groups.length) {
      dispatch(getGroups());
    }
  }, []);

  const onCloseModalWindow = (): void => {
    setGroupFields({ name: '' });
    setIdGroupToUpdate('');
    setIsModalOpen(false);
    setModalVariant('');
    setErrorMessage('');
  };

  const onOpenCreateGroupModal = (): void => {
    setModalVariant(GROUP_MANAGEMENT_MODAL_VARIANT.CREATE);
    setIsModalOpen(true);
  };

  const onOpenUpdateGroupModal = (groupId: string): void => {
    setModalVariant(GROUP_MANAGEMENT_MODAL_VARIANT.UPDATE);
    setIdGroupToUpdate(groupId);
    setIsModalOpen(true);
  };

  const validateField = (): string | null => {
    const error = validateGroupName(groupFields.name);

    if (error) {
      setErrorMessage(error);
      return error;
    }

    return null;
  };

  const onSaveUpdateGroup = (): void => {
    if (validateField()) {
      return;
    }

    dispatch(updateGroup({ name: groupFields.name }, idGroupToUpdate));
    onCloseModalWindow();
  };

  const onSaveCreateGroup = (): void => {
    if (validateField()) {
      return;
    }

    dispatch(createGroup(groupFields));
    onCloseModalWindow();
  };

  const kebabColRenderGroupManagement = (value: HandlersValueType): JSX.Element => (
    <KebabMenu
      value={value}
      menuOptions={GROUP_MANAGEMENT_MENU}
      methods={{
        onChangeGroupName: (kebabValue: HandlersValueType) => onOpenUpdateGroupModal(kebabValue.id),
        onDeleteGroup: (kebabValue: HandlersValueType) => dispatch(deleteGroup(kebabValue.id)),
        onAddGroupMembers: (kebabValue: HandlersValueType) => {
          // Note: If we wanna add member to group from group listing page, we have to fetch this group first
          dispatch(getGroup(kebabValue.id));
          setIsAddMembersModalOpen(true);
        },
      }}
    />
  );

  const kebabColumn = setKebabColumnHeading(kebabColRenderGroupManagement);
  const tableColumns = transformGroupsToTableRows(groups);
  const isCreateModalType = modalVariant === GROUP_MANAGEMENT_MODAL_VARIANT.CREATE;
  const currentGroupName = groups.find((group: GroupType) => group.id.toString() === idGroupToUpdate)?.name;

  const onRowsDelete = (rowsDeleted: SelectedRowsType) => {
    const rowsIndexes: number[] = rowsDeleted.data.map((row) => row.index);
    const groupIdsToDelete: string[] = groups.map((group: GroupType, index: number) => {
      if (rowsIndexes.includes(index)) {
        return group.id.toString();
      }
      return '';
    }).filter(Boolean);

    if (groupIdsToDelete.length > 0) {
      dispatch(deleteGroupsBatch(groupIdsToDelete));
    }
  };

  return (
    <AccountSettingsWrapper fluidWrapper>
      <div className="management-settings">
        <h2 className="account-settings__title mb-3">Group Management</h2>
        <div className="management-controls d-flex justify-content-between align-items-center">
          <Button onClick={onOpenCreateGroupModal}>
            Create group
          </Button>
        </div>
        <div className="mt-4">
          <MUITableManagement
            tableColumns={[
              ...groupColumns,
              ...kebabColumn,
            ]}
            documentsRows={tableColumns}
            onRowsDelete={onRowsDelete}
          />
        </div>
      </div>
      <GroupManagementModal
        isModalOpen={isModalOpen && Boolean(modalVariant)}
        onClose={onCloseModalWindow}
        setGroup={({ name }) => {
          setGroupFields({ name });

          const error = validateGroupName(name);

          setErrorMessage(error ?? '');
        }}
        onSave={
        isCreateModalType
          ? onSaveCreateGroup
          : onSaveUpdateGroup
        }
        title={
        isCreateModalType
          ? 'Create group'
          : 'Update group'
        }
        currentGroupName={currentGroupName}
        errorMessage={errorMessage}
      />
      <AddGroupMembersModal
        isModalOpen={isAddMembersModalOpen}
        onCloseModal={() => {
          setIsAddMembersModalOpen(false);
        }}
      />
    </AccountSettingsWrapper>
  );
};

export default GroupManagement;