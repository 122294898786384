import {
  CLEAR_FORM_BUILDER_SELECTED_FIELDS_KEYS,
  CLEAR_OUTLINE_ACTIVE_SECTION_KEY,
  SET_FORM_BUILDER_GROUPED_FIELDS_KEYS,
  SET_FORM_BUILDER_SELECTED_FIELDS_KEYS,
  SET_IS_DRAG_START_STATUS,
  SET_IS_DRAG_STOP_STATUS,
  SET_OUTLINE_ACTIVE_SECTION_KEY,
} from 'store/actions/actionTypes';

export const setFormBuilderSelectedFieldsKeys = (keys: string[]) => ({
  type: SET_FORM_BUILDER_SELECTED_FIELDS_KEYS,
  payload: keys,
});

export const clearFormBuilderSelectedFieldsKeys = () => ({
  type: CLEAR_FORM_BUILDER_SELECTED_FIELDS_KEYS,
});

export const setIsDragStartStatus = () => ({
  type: SET_IS_DRAG_START_STATUS,
});

export const setIsDragStopStatus = () => ({
  type: SET_IS_DRAG_STOP_STATUS,
});

export const setOutlineActiveSectionKey = (key: number) => ({
  type: SET_OUTLINE_ACTIVE_SECTION_KEY,
  payload: key,
});

export const clearOutlineActiveSectionKey = () => ({
  type: CLEAR_OUTLINE_ACTIVE_SECTION_KEY,
});

export const setFormBuilderGroupedFieldsKeys = (groupedFieldsKeys: Record<number, string[]> | null) => ({
  type: SET_FORM_BUILDER_GROUPED_FIELDS_KEYS,
  payload: groupedFieldsKeys,
});