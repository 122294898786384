import { FC, MouseEvent, ReactElement, ReactNode, useState } from 'react';

import { Menu, MenuItem } from '@mui/material';

interface IDropDownButtonParams {
  buttonNode: ReactNode;
  buttonClassName: string;
  menuItems: ReactElement[];
}

const DropDownButton: FC<IDropDownButtonParams> = ({
  buttonNode,
  buttonClassName,
  menuItems,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <button className={buttonClassName} type="button" onClick={handleClick}>
        {buttonNode}
      </button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {
          menuItems.map((node) => (
            <MenuItem
              key={node.key}
              onClick={handleClose}
              style={{ padding: 0 }}
            >
              <span className="dropdown-menu-button-item">
                {node}
              </span>
            </MenuItem>
          ))
        }
      </Menu>
    </>
  );
};

export default DropDownButton;