/* eslint-disable */
import { useState, useEffect } from 'react';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { ToolbarListArrayType } from 'constants/toolbarList';

export interface IToolbarListProps {
  selectedTab: string;
  toolbarList: ToolbarListArrayType;
}

const ToolbarCommandList: React.FC<IToolbarListProps> = (props) => {

  const { selectedTab, toolbarList } = props;

  const [localToolbarList, setLocalToolbarList] = useState<any[] | null>(null);

  useEffect(() => {
    const newToolbarList = toolbarList.filter(({ category }) => (
      category === selectedTab
    ));

    setLocalToolbarList(newToolbarList);
  }, [selectedTab, toolbarList]);

  return (
    <ul className="toolbar-list">
      {
        localToolbarList && localToolbarList.map(({ Component, id, format, formats, title, icon }) => {
          if(format) {
            return (
              <Component
                  key={id}
                  format={format}
                  title={title}
                  icon={icon}
              />
            )
          }

          return (
            <Component
                key={id}
                formats={formats}
                title={title}
                icon={icon}
            />
          )
        })
      }
      {
        selectedTab === "ALL" && (
          <li
            key={selectedTab}
            className={`item-toolbar-editor text-dark`}
          >
            <div className="d-flex" >
              <div className="icon command-horizontal">
                <MoreHorizIcon color="inherit" />
              </div>
              <div>
                <div className="text-dark">Horizontal divider</div>
                <div className="command-description text-muted">Shortcut 4 + &#8984; or type - + space</div>
              </div>
            </div>
          </li>
        )
      }
    </ul>
  )
}

export default ToolbarCommandList;