import { DOCUMENT_TYPE } from 'constants/general';
import {
  CHECK_IF_USER_ON_PUBLIC_PAGE_HAS_MONDAYCOM_RECORDS,
  DELETE_MONDAY_COM_ACCESS_TOKEN,
  GET_MONDAY_COM_BOARDS,
  LAUNCH_UPDATE_FIELDS_MAPPING,
  SAVE_MONDAY_COM_ACCESS_TOKEN,
  SAVE_MONDAY_COM_INTEGRATION_SETTINGS,
  SET_AUTOFILL_PII_MONDAY_ITEM,
  SET_COLUMN_NAME_AND_FIELD_TO_UPDATE_MAPPING,
  SET_FIELD_TYPE_CHECKER_MODAL_OPEN,
  SET_MONDAY_COM_ITEM_ID_TO_FILLOUT_SUBITEM,
  SET_OPEN_POPUP_BECAUSE_OF_SMS_VALIDATION,
  SET_WAIT_TO_PASSWORDLESS_LINK_RESPONSE,
  SHOW_MONDAY_COM_SIMILAR_RECORDS_EXIST_MODAL_WINDOW,
} from 'store/actions/actionTypes';
import { ColumnNameAndFieldType, MondayComIntegrationObjectType } from 'types/mondayCom/mondayComBoard';

export const saveMondayComAccessToken = (token: string) => ({
  type: SAVE_MONDAY_COM_ACCESS_TOKEN,
  payload: token,
});

export const deleteMondayComAccessToken = () => ({
  type: DELETE_MONDAY_COM_ACCESS_TOKEN,
});

export const getMondayComBoards = () => ({
  type: GET_MONDAY_COM_BOARDS,
});

export const saveMondayComIntegrationSettings = (
  resourceId: number,
  board: MondayComIntegrationObjectType[] | null,
  resourceType: string = DOCUMENT_TYPE,
) => ({
  type: SAVE_MONDAY_COM_INTEGRATION_SETTINGS,
  payload: {
    resourceId,
    board,
    resourceType,
  },
});

export const setFieldTypeCheckerModalOpen = (isModalOpen: boolean) => ({
  type: SET_FIELD_TYPE_CHECKER_MODAL_OPEN,
  payload: isModalOpen,
});

export const setColumnNameAndFieldToUpdateFieldsMapping = (
  columnNameAndField: ColumnNameAndFieldType | null,
) => ({
  type: SET_COLUMN_NAME_AND_FIELD_TO_UPDATE_MAPPING,
  payload: columnNameAndField,
});

export const setLaunchUpdateFieldsMapping = (columnName: boolean) => ({
  type: LAUNCH_UPDATE_FIELDS_MAPPING,
  payload: columnName,
});

export const checkMondayComRecordsForCurrentUser = () => ({
  type: CHECK_IF_USER_ON_PUBLIC_PAGE_HAS_MONDAYCOM_RECORDS,
});

export const setShowSimilarRecordsExistModalWindow = (showModal: boolean) => ({
  type: SHOW_MONDAY_COM_SIMILAR_RECORDS_EXIST_MODAL_WINDOW,
  payload: showModal,
});

export const setMondayComItemIdToFilloutSubitem = (itemId: string) => ({
  type: SET_MONDAY_COM_ITEM_ID_TO_FILLOUT_SUBITEM,
  payload: itemId,
});

export const setAutoFillPiiMondayItem = (mondayItem: Record<string, string>) => ({
  type: SET_AUTOFILL_PII_MONDAY_ITEM,
  payload: mondayItem,
});

export const setWaitToPasswordlessLinkResponse = (waitParameter: boolean) => ({
  type: SET_WAIT_TO_PASSWORDLESS_LINK_RESPONSE,
  payload: waitParameter,
});
export const setOpenPopupBecauseOfSmsValidation = (openPopup: boolean) => ({
  type: SET_OPEN_POPUP_BECAUSE_OF_SMS_VALIDATION,
  payload: openPopup,
});