import { useState } from 'react';

import { useSelector } from 'react-redux';

import Button from 'components/Base/Button';
import MondayComAccessTokenModal from 'components/Modals/MondayComModalWindow/MondayComAccessTokenModal';
import { RootStateType } from 'store/reducers';
import MondayComIcon from 'svg/SettingIcons/MondayComIcon';

const MondayComSection = () => {
  const { monday_com_access_token: mondayComAccessToken } = useSelector(
    (state: RootStateType) => state.profile.profileInfo.company,
  );
  const [showMondayComAccessTokenModal, setShowMondayComAccessTokenModal] = useState<boolean>(false);

  return (
    <div className="account-settings">
      <MondayComIcon />
      <span className="ms-3">
        Monday.com
      </span>
      <div className="d-inline ms-3">
        <Button onClick={() => setShowMondayComAccessTokenModal(true)}>
          {mondayComAccessToken ? 'Edit' : 'Select'}
        </Button>
      </div>
      <MondayComAccessTokenModal
        showMondayComAccessTokenModal={showMondayComAccessTokenModal}
        setShowMondayComAccessTokenModal={setShowMondayComAccessTokenModal}
      />
    </div>
  );
};

export default MondayComSection;