import {
  ARRAY_PAGES_WITH_COPYRIGHTS,
  ARRAY_PAGES_WITHOUT_HEADER,
  ARRAY_PUBLIC_MANAGER_PAGES,
  ARRAY_PUBLIC_RECIPIENT_PAGES,
  COLLECTION_EXECUTED_TYPE,
  DOCUMENT_TYPE,
  FORM_TYPE,
  PDF_TYPE,
  ROUTES_FOR_CLOSE_BUTTON_BLOCKER,
  URL_PUBLIC_SUCCESS_SUMMARY_PAGE,
} from 'constants/general';
import ROUTES from 'constants/routes';
import { COLUMN_EXECUTED } from 'utils/dataColumns';

export const applyCloseButtonBlocker = (path: string) => {
  if (!path.length || path === `/${URL_PUBLIC_SUCCESS_SUMMARY_PAGE}`) {
    return false;
  }
  const pathArray = path.replace('-', '/').split('/');
  return pathArray.some((item) => ROUTES_FOR_CLOSE_BUTTON_BLOCKER.includes(item));
};

export const toShowBlock = (path: string, variant: 'header' | 'copyright' | 'public' | 'manager'): boolean => {
  if (!path.length) {
    return false;
  }
  const pathArray = path.split('/');
  const route = pathArray[1];

  switch (variant) {
    case 'copyright':
      return pathArray.length < 3 && ARRAY_PAGES_WITH_COPYRIGHTS.includes(route);
    case 'header':
      return !ARRAY_PAGES_WITHOUT_HEADER.includes(route);
    case 'public':
      return ARRAY_PUBLIC_RECIPIENT_PAGES.includes(route);
    case 'manager':
      return ARRAY_PUBLIC_MANAGER_PAGES.includes(route);
    default:
      return false;
  }
};

export const getEditorPageLink = (id: string, docType = DOCUMENT_TYPE, documentsExecuted = false): string => {
  switch (docType) {
    case DOCUMENT_TYPE:
      return `${documentsExecuted ? ROUTES.DOCUMENTS_EXECUTED_EDITOR : ROUTES.DOCUMENTS_EDITOR}/${id}`;
    case FORM_TYPE:
      return `${documentsExecuted ? ROUTES.FORM_EXECUTED_EDITOR : ROUTES.FORM_EDITOR}/${id}`;
    case PDF_TYPE:
      return `${documentsExecuted ? ROUTES.PDF_EXECUTED_EDITOR : ROUTES.PDF_EDITOR}/${id}`;
    case COLLECTION_EXECUTED_TYPE:
      return `${ROUTES.COLLECTIONS_EXECUTED_EDITOR}/${id}`;
    default:
      return `${ROUTES.COLLECTIONS_EDITOR}/${id}`;
  }
};

export const getEditorPageLinkForTable = (rowData: string[] | any[]): string => {
  const docType = rowData[rowData.length - 1]?.type;
  const isExecutedDoc = rowData.includes(COLUMN_EXECUTED.name);
  return getEditorPageLink(rowData[0], docType, isExecutedDoc);
};

const isParentSection = (sectionURL: string, isPDF = false, isForm = false, isCollection = false): boolean => {
  const cleanSectionURL = sectionURL.replace('\'', '');
  if (
    (cleanSectionURL === ROUTES.DOCUMENTS.replace('/', '') && !isPDF && !isForm && !isCollection)
    || (cleanSectionURL === ROUTES.FORMS.replace('/', '') && isForm)
    || (cleanSectionURL === ROUTES.PDF_LIST.replace('/', '') && isPDF)
    || (cleanSectionURL === ROUTES.COLLECTIONS.replace('/', '') && isCollection)
  ) {
    return true;
  }
  return false;
};

export const isNavItemActive = (
  value: string,
  routerMatch: unknown,
  location: any, // eslint-disable-line @typescript-eslint/no-explicit-any
) => {
  if (!routerMatch && location) {
    if (location.pathname.includes(ROUTES.DOCUMENTS_EXECUTED_EDITOR)) {
      return isParentSection(value);
    }
    if (
      location.pathname.includes(ROUTES.PDF_EXECUTED_EDITOR)
      || location.pathname.includes(ROUTES.FORM_EXECUTED_EDITOR)
      || location.pathname.includes(ROUTES.COLLECTIONS_EXECUTED_EDITOR)
    ) {
      return isParentSection(
        value,
        location.pathname.includes(ROUTES.PDF_EXECUTED_EDITOR),
        location.pathname.includes(ROUTES.FORM_EXECUTED_EDITOR),
        location.pathname.includes(ROUTES.COLLECTIONS_EXECUTED_EDITOR),
      );
    }
    return false;
  }
  return true;
};

export const isFormListingPage = (path: string): boolean => path === ROUTES.FORMS;
export const isDocumentListingPage = (path: string): boolean => path === ROUTES.DOCUMENTS;
export const isCollectionPagePath = (path: string): boolean => path.startsWith(ROUTES.COLLECTIONS);
export const isPDFTemplateEditorOrListPagePath = (path: string): boolean => (
  path.startsWith(ROUTES.PDF_EDITOR) || path.startsWith(ROUTES.PDF_LIST)
);
export const deleteSlashFromRoute = (route: string) => route.replace('/', '');
export const isNewSlateDocumentPage = (path: string): boolean => path === ROUTES.DOCUMENTS_EDITOR;
export const isNewPDFDocumentPage = (path: string): boolean => path === ROUTES.PDF_EDITOR;
export const isNewFormDocumentPage = (path: string): boolean => path === ROUTES.FORM_EDITOR;