/* eslint-disable no-param-reassign */
import { FC } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import ModalConfirmDialog from 'components/Modals/ModalConfirmDialog';
import { RECIPIENT_ASSIGNMENT } from 'constants/editors';
import { removeAssignment, setAssignmentDeletionInfo } from 'store/actions/editorSlate';
import { updateFieldByKey } from 'store/actions/pdfTemplates';
import { RootStateType } from 'store/reducers';
import { AssignmentsMainParts, CustomEditorType } from 'types/Editor';
import { PDFFieldType } from 'types/PdfTemplates';
import { findNodesAndUpdate } from 'utils/editorHelpers';

interface IAssignmentsDeletionConfirmationModal {
  editor?: CustomEditorType;
  pdfFields?: PDFFieldType[];
}

const AssignmentsDeletionConfirmationModal: FC<IAssignmentsDeletionConfirmationModal> = ({
  editor = undefined,
  pdfFields = undefined,
}) => {
  const dispatch = useDispatch();
  const {
    assignments,
    openAssignmentDeletionConfirmationModal,
    assignmentTypeForDeletion,
  } = useSelector((state: RootStateType) => state.editorSlate);

  const closeConfirmationModal = () => {
    dispatch(setAssignmentDeletionInfo(false, null));
  };

  const handlerConfirmDeleteAssignment = () => {
    const unitedSortedAssignmentsArray = assignments[AssignmentsMainParts.RECIPIENTS]
      .concat(assignments[AssignmentsMainParts.MANAGERS]);
    const assignmentForDeletion = unitedSortedAssignmentsArray.find(
      (assignment) => assignment.type === assignmentTypeForDeletion,
    );
    if (assignmentForDeletion) {
      if (editor) {
        editor.children = findNodesAndUpdate(editor.children, (node) => {
          if (assignmentForDeletion.fieldsKeys?.includes(node.key || 0)) {
            return {
              ...node,
              assignment: RECIPIENT_ASSIGNMENT,
            };
          }
          return node;
        });
      } else {
        pdfFields?.forEach((pdfField: PDFFieldType) => {
          if (assignmentForDeletion.fieldsKeys?.includes(pdfField.key)) {
            dispatch(updateFieldByKey(pdfField.key, { ...pdfField, assignment: RECIPIENT_ASSIGNMENT }));
          }
        });
      }
    }

    dispatch(removeAssignment(assignmentTypeForDeletion));
    closeConfirmationModal();
  };

  return (
    <ModalConfirmDialog
      showDialog={openAssignmentDeletionConfirmationModal}
      onClose={closeConfirmationModal}
      onConfirm={handlerConfirmDeleteAssignment}
      messageText="You are about to delete assignment, but it is used in fields. Assignment will be changed to default for those fields. Are you sure?"
    />
  );
};

export default AssignmentsDeletionConfirmationModal;