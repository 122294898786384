import { Element as SlateElement } from 'slate';

const getFieldName = (field: Partial<SlateElement>, isPDF: boolean): string => {
  if (isPDF || field.fieldName) {
    return field.fieldName || '';
  }
  return (field?.children && field.children
    .reduce((acc: string, el: Partial<SlateElement>) => `${acc}${el.text}`, ''))
    || '';
};

export default getFieldName;