import { toast } from 'react-toastify';

import { BaseSelection, Editor, NodeEntry, Transforms } from 'slate';

import { TABLE_QUESTION_ROW } from 'constants/editors';
import { MIN_TABLE_QUESTION_ROW_COUNT, ROWS_REMOVING_MESSAGE } from 'constants/tableQuestion';
import { ICustomElement } from 'types/Editor';
import { findEditorNodesByPosition } from 'utils/TableQuestion/tableQuestionHelpers';

interface IRemoveTableQuestionRowProps {
  editor: Editor,
  tableBody: NodeEntry<ICustomElement> | undefined,
  selection: BaseSelection,
}

const removeTableQuestionRow = ({
  editor,
  tableBody,
  selection,
}: IRemoveTableQuestionRowProps) => {
  if (!tableBody || !selection) {
    toast.warning(ROWS_REMOVING_MESSAGE.CANT_REMOVE_HERE);
    return;
  }

  const rowNodes = findEditorNodesByPosition(editor, selection, [TABLE_QUESTION_ROW]);
  if (!rowNodes || !Array.isArray(rowNodes)) return;

  const [row] = rowNodes;

  const [, startPath] = row;
  const rowIndex = startPath[startPath.length - 1];

  if (
    rowIndex === 0
    || rowIndex === 1
    || !row
    || !Array.isArray(row)
  ) {
    toast.warning(ROWS_REMOVING_MESSAGE.CANT_INSERT_THIS);
    return;
  }

  if (tableBody[0].children.length <= MIN_TABLE_QUESTION_ROW_COUNT) {
    toast.warning(ROWS_REMOVING_MESSAGE.CANT_INSERT_MORE);
    return;
  }

  Transforms.removeNodes(editor, { at: startPath });
};

export default removeTableQuestionRow;