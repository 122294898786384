import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { STATUSES } from 'constants/documentStatuses';
import { ShareViewModes } from 'constants/shareViewModes';
import { RootStateType } from 'store/reducers';
import { IDocumentDetails } from 'types/Documents';
import { PublicPageDataType } from 'types/PublicPage';
import { isNotEmptyObject } from 'utils/isEmptyObject';
import { isDocumentType, isTemplateType } from 'utils/typeChecker';

const useReadOnlyMode = (): boolean => {
  const data: PublicPageDataType = useSelector((state: RootStateType) => state.publicPages.data);
  const [readOnlyMode, setReadOnlyMode] = useState<boolean>(false);

  useEffect(() => {
    if (isNotEmptyObject(data) && (isDocumentType(data) || isTemplateType(data))) {
      setReadOnlyMode(
        ((data as IDocumentDetails).status === STATUSES.completed)
        || (data.view_mode === ShareViewModes.SHARE_MODE_READ_ONLY),
      );
    }
  }, [data]);

  return readOnlyMode;
};

export default useReadOnlyMode;