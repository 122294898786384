/* eslint-disable */
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import KebabMenu from "components/KebabMenu";
import MUITable from "components/MUITable";
import { MOMENT_DATE_FORMAT } from "constants/dateField";
import { setKebabColumnHeading, SECTIONS_KEBAB_MENU } from "constants/KebabMenus";
import { useRolePermission } from "hooks/useRolePermission";
import { ISectionsProps } from "types/Sections";
import { sectionColumns, stateColumn } from "utils/dataColumns";
import sortDocumentsByDate from "utils/sortDocumentsByDate";
import { MUIDataTableColumn } from "mui-datatables";

const Sections: React.FC<ISectionsProps> = (props) => {

  const {
    sections,
    deleteSections,
  } = props;

  const [ sectionsRows, setSectionsRows ] = useState<any[]>([]);
  const { permissionUsingStates } = useRolePermission();
  const [ resetSelectedRows, setResetSelectedRows ] = useState<boolean>(false);
  let allColumns = permissionUsingStates ? sectionColumns.concat(stateColumn) : sectionColumns;

  const onDeleteSection = (itemId: number) => {
    deleteSections([itemId]);
    setResetSelectedRows(true);
  }

  const kebabColRender = (value: any, tableMeta: any, methods: any) => (
    <KebabMenu value={value} menuOptions={SECTIONS_KEBAB_MENU} methods={{
      ...methods,
      onDeleteItem: onDeleteSection,
    }} />
  );
  const kebabColumn = setKebabColumnHeading(kebabColRender);
  allColumns = allColumns.concat(kebabColumn);

  useEffect(() => {
    if (sections.length) {
      setSectionsRows(createSectionsData(sections));
    }
  }, [sections]);

  const createSectionsData = (sections: any) => {
    return sections.sort(sortDocumentsByDate).map((row: any) => {
      if (!row) return [];
      const kebabMenu = [{id: row?.id.toString()}];
      const statesColumnData = permissionUsingStates
        ? [row?.states?.map((state: any) => state.abbreviation || "").join(", ")]
        : [];

      return [
        row?.id.toString(),
        moment(row?.creation_date).format(MOMENT_DATE_FORMAT),
        row?.creator ? `${row.creator.first_name} ${row.creator.last_name}` : "",
        row?.name,
        moment(row?.modification_date).format(MOMENT_DATE_FORMAT),
        row?.last_modified_by ? `${row.last_modified_by.first_name} ${row.last_modified_by.last_name}` : "",
      ]
      .concat(statesColumnData)
      .concat(kebabMenu);
    });
  };

  return (
    <div className="w-75 mx-auto pb-5">
      <div className="d-flex justify-content-between">
        <h1>Sections</h1>
        <Link className="ml-2 icon-link green" to="/sections/editor">
          <AddCircleOutlineIcon className="material-icons-add" />
          Add New
        </Link>
      </div>
      <MUITable
        data={sectionsRows}
        columns={allColumns as MUIDataTableColumn[]}
        deleteRows={deleteSections}
        resetSelectedRows={resetSelectedRows}
      />
    </div>
  );
};

export default Sections;
