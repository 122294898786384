import { FC } from 'react';

import { Button } from 'reactstrap';

import { COPY, EMBED_SCRIPT_LINK } from 'constants/general';

interface IModalEmbed {
  documentId: string;
  copyHandler: (T: string) => void;
}

const ModalEmbedScript: FC<IModalEmbed> = ({ documentId, copyHandler }) => {
  const codeEmbedLink = `<script src="${EMBED_SCRIPT_LINK}" defer></script>`;
  const codePublicDiv = `<div id="${documentId}" data-unicornforms></div>`;

  return (
    <>
      <h5>Embed script</h5>
      <div>
        <p className="mb-2">
          1. Copy this part of the code.
          <Button
            color="primary"
            outline
            className="btn-sm ms-3"
            onClick={() => copyHandler(codePublicDiv)}
          >
            {COPY}
          </Button>
        </p>
        <div className="border border-dark rounded-3 p-3 mb-3 overflow-hidden">{codePublicDiv}</div>
        <p className="mb-2">
          2. Choose a place on your website (for example tag &lt;div class=&ldquo;unicornform&rdquo;&gt;) and
          paste it to your html code.
        </p>
        <p className="mb-2">
          3. Copy this part of the code.
          <Button
            color="primary"
            outline
            className="btn-sm ms-3"
            onClick={() => copyHandler(codeEmbedLink)}
          >
            {COPY}
          </Button>
        </p>
        <div className="border border-dark rounded-3 p-3 mb-3 overflow-hidden">
          {codeEmbedLink}
        </div>
        <p className="mb-2">
          4. Paste it to your html code before end tag &lt;/body&gt;.
        </p>
        <p className="mb-2">
          5. Write to our support to add your website domain to WHITELIST.<br />
          Pay attention! The form does not work without this point.
        </p>
      </div>
    </>
  );
};

export default ModalEmbedScript;