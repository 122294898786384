import { FC } from 'react';

import { Input } from 'reactstrap';

import ErrorValidation from 'components/Editor/components/ErrorValidation';
import { TEXTAREA_ROWS_COUNT } from 'constants/editors';
import { TEXTAREA_MAX_LEN } from 'constants/validation';
import useInputField from 'hooks/useInputField';

interface ISlateTextAreaFieldProps {
  name: string;
  initValue: string;
  onChangeInitValue: (value: string) => void;
  isError: boolean;
  validationErrorText: string | null;
  maxLength?: number | string;
  isReadOnly?: boolean;
}

const SlateTextAreaField: FC<ISlateTextAreaFieldProps> = ({
  name,
  initValue,
  onChangeInitValue,
  isError,
  validationErrorText,
  maxLength = TEXTAREA_MAX_LEN,
  isReadOnly = false,
}) => {
  const { value, onChangeValue } = useInputField({
    initValue,
    onChangeInitValue,
    maxLength,
  });

  return (
    <span
      data-field="true"
      contentEditable={false}
      style={{
        userSelect: 'none',
        position: 'relative',
      }}
      className="me-1 d-block"
    >
      <Input
        type="textarea"
        rows={TEXTAREA_ROWS_COUNT}
        name={name}
        value={value || ''}
        maxLength={TEXTAREA_MAX_LEN}
        className="w-100 text-field-editable text-area-field"
        autoComplete="off"
        onChange={onChangeValue}
        disabled={isReadOnly}
        draggable={false}
        tabIndex={isReadOnly ? -1 : 0}
      />
      <ErrorValidation isError={isError} errorText={validationErrorText} />
    </span>
  );
};

export default SlateTextAreaField;