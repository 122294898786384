import ENDPOINT from 'constants/endpoints';
import { IResponseOfRequest } from 'types/Api';
import { AggregatedFieldsStructureType } from 'types/PublicPage';
import { IUserPersonalDataObject, IUserPersonalSignatureObject, PIIDataType } from 'types/userProfile';
import { axiosInstance } from 'utils/axiosInstance';

// It's not used right now
export const getPersonalData = async () => (
  axiosInstance.get(ENDPOINT.USER_PERSONAL_DATA)
);

export const updatePersonalData = async (
  personalData: Partial<IUserPersonalDataObject>,
): Promise<IResponseOfRequest<IUserPersonalDataObject>> => (
  axiosInstance.post(ENDPOINT.USER_PERSONAL_DATA, personalData)
);

export const updatePersonalSignatureData = async (signatureData: IUserPersonalSignatureObject) => (
  axiosInstance.post(`${ENDPOINT.USER_PERSONAL_DATA}/signature`, signatureData)
);

export const getPersonalSignatureData = async () => (
  axiosInstance.get(`${ENDPOINT.USER_PERSONAL_DATA}/signature`)
);

export const deletePersonalSignatureData = async () => (
  axiosInstance.delete(`${ENDPOINT.USER_PERSONAL_DATA}/signature`)
);

export const getPIIUserData = async (): Promise<IResponseOfRequest<PIIDataType>> => (
  axiosInstance.get(`${ENDPOINT.USER_PERSONAL_DATA}/pii-data`)
);

// TODO: to use in the task 3199 - not used yet
export const updatePIIUserData = async (piiData: IUserPersonalDataObject): Promise<IResponseOfRequest<unknown>> => (
  axiosInstance.post(`${ENDPOINT.USER_PERSONAL_DATA}/pii-data`, piiData)
);

export const getUserSigningCertificates = async (): Promise<IResponseOfRequest<unknown>> => (
  axiosInstance.get(`${ENDPOINT.USER_PERSONAL_DATA}/signing-certificates`)
);

export const updateUserSigningCertificates = async (formData: FormData): Promise<IResponseOfRequest<unknown>> => (
  axiosInstance.patch(`${ENDPOINT.USER_PERSONAL_DATA}/signing-certificates`, formData)
);

// Remember user PII data and recall this data afger authorization flows
export const saveTemporaryPIIData = async (
  { userEmail, docCreatorEmail, piiData }: {
    userEmail: string,
    docCreatorEmail: string,
    piiData: { subtype: string, value: string }[],
  },
) => (
  axiosInstance.patch(`${ENDPOINT.USER_PERSONAL_DATA}/pii-data/temporary`, {
    user_email: userEmail,
    doc_creator_email: docCreatorEmail,
    pii_data: piiData,
  })
);

export const checkPIIDataInDatabasePerUser = async (
  userEmail: string,
  groupedFieldsStructure: AggregatedFieldsStructureType[],
) => (
  axiosInstance.post(`${ENDPOINT.USER_PERSONAL_DATA}/pii-data/temporary`, {
    user_email: userEmail,
    grouped_fields: groupedFieldsStructure,
  })
);

export const registerUserAndSendEmailInPIIRecallFlow = async (
  userData: Record<string, string>,
  startLink: string,
  docSenderEmail: string,
  documentInfo: {
    id: number,
    type: string,
  },
) => (
  axiosInstance.post(`${ENDPOINT.USER_PERSONAL_DATA}/pii-data/recall`, {
    user_data: userData,
    start_link: startLink,
    doc_sender_email: docSenderEmail,
    document_info: documentInfo,
  })
);