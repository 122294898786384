import {
  ClipboardEvent,
  FC,
  KeyboardEvent,
  memo,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import ContentEditable from 'react-contenteditable';

import ErrorValidation from 'components/Editor/components/ErrorValidation';
import {
  BACKSPACE_HOTKEY,
  DELETE_HOTKEY,
  MAX_FIELD_VALUE_LENGTH,
  PARAGRAPH_BREAK_HOTKEY,
} from 'constants/editors';
import { TEXT_FIELD_MASKS } from 'constants/fieldPropertiesTab';
import useFieldMaskValue from 'hooks/useFieldMaskValue';
import useInputField from 'hooks/useInputField';
import { getInputSpanWidthStyle } from 'utils/editorFieldHelpers';

interface ISlateInputFieldProps {
  name: string;
  initValue: string;
  onChangeInitValue: (value: string) => void;
  isError: boolean;
  validationErrorText: string | null;
  maxLength?: number | string;
  isReadOnly?: boolean;
  editorRef?: RefObject<HTMLDivElement>;
  hideValue?: boolean;
  fieldMaskType?: TEXT_FIELD_MASKS;
}

const SlateInputField: FC<ISlateInputFieldProps> = ({
  name,
  initValue,
  onChangeInitValue,
  maxLength = MAX_FIELD_VALUE_LENGTH,
  isReadOnly = false,
  isError = false,
  validationErrorText = null,
  editorRef = null,
  hideValue = true,
  fieldMaskType = TEXT_FIELD_MASKS.NONE,
}) => {
  const [width, setWidth] = useState<number | string>('');
  const [isInline, setIsInline] = useState<boolean>(false);

  const { value, onChangeValue } = useInputField({
    initValue,
    onChangeInitValue,
    maxLength,
  });

  const { hiddenValue, onChangeValueWithMask } = useFieldMaskValue({
    fieldMaskType,
    hideValue,
    realValue: initValue,
    onChangeRealValue: onChangeValue,
  });

  useEffect(() => {
    setIsInline(initValue.length >= 1);
  }, [initValue]);

  useEffect(() => {
    setWidth(getInputSpanWidthStyle(maxLength));
  }, [maxLength]);

  const onPaste = useCallback((event: ClipboardEvent<HTMLSpanElement>) => {
    event.preventDefault();
    const text = event.clipboardData.getData('text/plain');
    document.execCommand('insertText', false, text);
  }, []);

  const maxLengthController = useCallback((event: KeyboardEvent<HTMLSpanElement>) => {
    const value: string = (event.target as HTMLSpanElement).textContent || '';
    setIsInline(Boolean(value.length));
    let isPreventDefault;

    switch (typeof maxLength) {
      case 'number':
        isPreventDefault = value.length >= maxLength;
        break;
      case 'string':
      default:
        isPreventDefault = value.length >= MAX_FIELD_VALUE_LENGTH;
        break;
    }

    if (
      (isPreventDefault && !(event.key === BACKSPACE_HOTKEY || event.key === DELETE_HOTKEY))
      || event.key.toLowerCase() === PARAGRAPH_BREAK_HOTKEY
    ) {
      event.preventDefault();
    }
  }, [maxLength]);

  const isInlineBlock = useMemo(() => (
    editorRef
    && editorRef.current
    && getInputSpanWidthStyle(maxLength) > editorRef.current.clientWidth
    && !isInline
  ), [isInline, maxLength]);

  const contentEditableStyles = useMemo(() => ({
    userSelect: 'all',
    whiteSpace: 'pre-wrap',
    ...(!isInline && { width: `${width}px` }),
    display: `${isInline ? 'inline' : 'inline-block'}`,
  }), [width, isInline]);

  return (
    <span
      data-field="true"
      contentEditable={false}
      style={{
        userSelect: 'none',
        display: `${isInlineBlock ? 'inline-block' : 'inline'}`,
        position: 'relative',
      }}
      className={`me-1 field-editable inline-input-span ${isReadOnly ? 'disable' : ''}`}
    >
      <ContentEditable
        style={contentEditableStyles}
        className="editor-input-span-field notranslate"
        data-slate-node="value"
        data-slate-editor="true"
        data-field="true"
        html={!hideValue ? value : hiddenValue}
        onChange={!hideValue ? onChangeValue : onChangeValueWithMask}
        onKeyDown={maxLengthController}
        onPaste={onPaste}
        tagName="span"
        disabled={isReadOnly}
        tabIndex={isReadOnly ? -1 : 0}
      />
      <input
        type="hidden"
        name={name}
        value={value}
        style={{ userSelect: 'none', display: 'none' }}
        tabIndex={-1}
      />
      <ErrorValidation isError={isError} errorText={validationErrorText} />
    </span>
  );
};

export default memo(SlateInputField);