import React from 'react';

export interface ISelectValueProps {
  title?: string;
  values: string[] | null;
  selectedValue: string;
  onChangeValue: React.ChangeEventHandler<HTMLSelectElement>;
  showKeys?: boolean;
  showTitle?: boolean;
}

const Select: React.FC<ISelectValueProps> = (props) => {
  const {
    values,
    selectedValue,
    onChangeValue,
    title = 'Select',
    showKeys = false,
    showTitle = true,
  } = props;

  return values ? (
    <>
      {showTitle && <p className="label ml-4 mb-1 d-inline-block">{title}:</p>}
      <select
        className="w-auto d-inline-block ml-2 custom-select"
        value={selectedValue}
        onChange={onChangeValue}
      >
        {showKeys && <option key="empty" value="">&nbsp;</option>}
        {
          Object.values(values).map((value) => (
            <option key={value} value={value}>{value}</option>
          ))
        }
      </select>
    </>
  ) : null;
};

export default Select;