/* eslint-disable max-len */

export const AlignLeftIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 12V14H14V12H4ZM0 18H18V16H0V18ZM0 10H18V8H0V10ZM4 4V6H14V4H4ZM0 0V2H18V0H0Z" fill="#2F3947" />
  </svg>
);

export const AlignRightIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 18H18V16H0V18ZM6 14H18V12H6V14ZM0 10H18V8H0V10ZM6 6H18V4H6V6ZM0 0V2H18V0H0Z" fill="#2F3947" />
  </svg>
);

export const AlignCenterIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 12V14H14V12H4ZM0 18H18V16H0V18ZM0 10H18V8H0V10ZM4 4V6H14V4H4ZM0 0V2H18V0H0Z" fill="#2F3947" />
  </svg>
);

export const AlignJustifyIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 18H18V16H0V18ZM0 14H18V12H0V14ZM0 10H18V8H0V10ZM0 6H18V4H0V6ZM0 0V2H18V0H0Z" fill="#2F3947" />
  </svg>
);

export const ToolbarHeadersIcon = () => (
  <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.291193 14V0.909091H2.66264V6.45099H8.72869V0.909091H11.1065V14H8.72869V8.43892H2.66264V14H0.291193ZM18.8825 0.909091V14H16.511V3.21662H16.4343L13.3725 5.17259V2.99929L16.6261 0.909091H18.8825Z" fill="#2F3947" />
  </svg>
);

export const HeaderOneIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 4v7H4V4H2v16h2v-7h6v7h2V4h-2Zm10 16V8h-1.5l-2.5.67v2.07l2-.536V20h2Z" fill="#2F3947" />
  </svg>
);

export const HeaderTwoIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 4v7H4V4H2v16h2v-7h6v7h2V4h-2Zm12 7.75C22 9.679 20.21 8 18 8s-4 1.679-4 3.75h2.133l.007-.144C16.218 10.707 17.02 10 18 10c1.03 0 1.867.784 1.867 1.75c0 .439-.173.841-.459 1.148L14 18.444V20h8v-2h-4.497l3.516-3.79l.158-.18A3.59 3.59 0 0 0 22 11.75Z" fill="#2F3947" />
  </svg>
);

export const HeaderThreeIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 4v7H4V4H2v16h2v-7h6v7h2V4h-2Zm11.729 6l.002-2H14.5v2h4.378l-3.176 3.283l1.407 1.515c.256-.118.546-.186.854-.186c1.04 0 1.884.768 1.884 1.714c0 .947-.844 1.715-1.884 1.715c-.917 0-1.681-.597-1.849-1.386L14 17.029C14.36 18.722 15.998 20 17.963 20C20.193 20 22 18.355 22 16.326c0-1.691-1.256-3.117-2.968-3.543L21.73 10Z" fill="#2F3947" />
  </svg>
);

export const HeaderFourIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 4v7H4V4H2v16h2v-7h6v7h2V4h-2Zm12 13.96V16h-1v-3.08h-2V16h-3l3.5-8h-2.08L14 16v1.96h5V20h2v-2.04h1Z" fill="#2F3947" />
  </svg>
);

export const HeaderFiveIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 4v7H4V4H2v16h2v-7h6v7h2V4h-2Zm11.745 5.92V8h-6.118l-1.142 6.09l1.846.868a2.087 2.087 0 0 1 1.59-.718c1.127 0 2.04.86 2.04 1.92s-.913 1.92-2.04 1.92c-.93 0-1.715-.587-1.96-1.39L14 17.219C14.488 18.825 16.059 20 17.922 20C20.175 20 22 18.282 22 16.16s-1.825-3.84-4.078-3.84c-.367 0-.721.045-1.058.13l.473-2.53h4.408Z" fill="#2F3947" />
  </svg>
);

export const HeaderSixIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 4v7H4V4H2v16h2v-7h6v7h2V4h-2Zm7.999 8.32L20.597 8h-2.309l-3.742 6.222A3.71 3.71 0 0 0 14 16.16c0 2.122 1.79 3.84 4 3.84s4-1.718 4-3.84s-1.791-3.84-4.001-3.84ZM16 16.16c0-1.06.895-1.92 2-1.92s2 .86 2 1.92s-.895 1.92-2 1.92s-2-.86-2-1.92Z" fill="#2F3947" />
  </svg>
);

export const BoldIcon = () => (
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.225 6.79C10.195 6.12 10.875 5.02 10.875 4C10.875 1.74 9.125 0 6.875 0H0.625V14H7.665C9.755 14 11.375 12.3 11.375 10.21C11.375 8.69 10.515 7.39 9.225 6.79ZM3.625 2.5H6.625C7.455 2.5 8.125 3.17 8.125 4C8.125 4.83 7.455 5.5 6.625 5.5H3.625V2.5ZM7.125 11.5H3.625V8.5H7.125C7.955 8.5 8.625 9.17 8.625 10C8.625 10.83 7.955 11.5 7.125 11.5Z" fill="#2F3947" />
  </svg>
);

export const CursiveIcon = () => (
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 0V3H6.21L2.79 11H0V14H8V11H5.79L9.21 3H12V0H4Z" fill="#2F3947" />
  </svg>
);

export const UnderlineIcon = () => (
  <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 14C10.31 14 13 11.31 13 8V0H10.5V8C10.5 9.93 8.93 11.5 7 11.5C5.07 11.5 3.5 9.93 3.5 8V0H1V8C1 11.31 3.69 14 7 14ZM0 16V18H14V16H0Z" fill="#2F3947" />
  </svg>
);

export const StrikethroughIcon = () => (
  <svg width="14" height="20" viewBox="6 2 14 20" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd">
      <path d="M15.6 8.5c-.5-.7-1-1.1-1.3-1.3-.6-.4-1.3-.6-2-.6-2.7 0-2.8 1.7-2.8 2.1 0 1.6 1.8 2 3.2 2.3 4.4.9 4.6 2.8 4.6 3.9 0 1.4-.7 4.1-5 4.1A6.2 6.2 0 0 1 7 16.4l1.5-1.1c.4.6 1.6 2 3.7 2 1.6 0 2.5-.4 3-1.2.4-.8.3-2-.8-2.6-.7-.4-1.6-.7-2.9-1-1-.2-3.9-.8-3.9-3.6C7.6 6 10.3 5 12.4 5c2.9 0 4.2 1.6 4.7 2.4l-1.5 1.1Z" />
      <path d="M5 11h14a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" fillRule="nonzero" />
    </g>
  </svg>
);

export const FontTypeVariantIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.17 13.5H12.81L13.95 16.5H16.04L10.93 3.5H9.07L3.96 16.5H6.05L7.17 13.5ZM10 5.98L12.07 11.5H7.93L10 5.98ZM18 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.9 20 2 20H18C19.1 20 20 19.1 20 18V2C20 0.9 19.1 0 18 0ZM18 18H2V2H18V18Z" fill="#2F3947" />
  </svg>
);

export const TextSizeIcon = () => (
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.5 0.5V3.5H5.5V15.5H8.5V3.5H13.5V0.5H0.5ZM19.5 5.5H10.5V8.5H13.5V15.5H16.5V8.5H19.5V5.5Z" fill="#2F3947" />
  </svg>
);

export const FontStyleVariantIcon = () => (
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.225 6.79C10.195 6.12 10.875 5.02 10.875 4C10.875 1.74 9.125 0 6.875 0H0.625V14H7.665C9.755 14 11.375 12.3 11.375 10.21C11.375 8.69 10.515 7.39 9.225 6.79ZM3.625 2.5H6.625C7.455 2.5 8.125 3.17 8.125 4C8.125 4.83 7.455 5.5 6.625 5.5H3.625V2.5ZM7.125 11.5H3.625V8.5H7.125C7.955 8.5 8.625 9.17 8.625 10C8.625 10.83 7.955 11.5 7.125 11.5Z" fill="#2F3947" />
  </svg>
);

export const LinkIcon = () => (
  <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.00015 10H6.21015C4.10415 10 2.20415 8.465 2.01715 6.366C1.80415 3.996 3.67315 2 6.00015 2H8.00015C8.55215 2 9.00015 1.553 9.00015 1C9.00015 0.447 8.55215 0 8.00015 0H6.20915C3.16015 0 0.439151 2.187 0.051151 5.211C-0.417849 8.869 2.43215 12 6.00015 12H8.00015C8.55215 12 9.00015 11.553 9.00015 11C9.00015 10.447 8.55215 10 8.00015 10ZM7.00015 6C7.00015 6.553 7.44815 7 8.00015 7H14.0002C14.5522 7 15.0002 6.553 15.0002 6C15.0002 5.447 14.5522 5 14.0002 5H8.00015C7.44815 5 7.00015 5.447 7.00015 6ZM15.7593 0.0002C18.8263 0.0002 21.5783 2.1972 21.9533 5.2422C22.4023 8.8882 19.5583 12.0002 16.0003 12.0002H14.0003C13.4483 12.0002 13.0003 11.5532 13.0003 11.0002C13.0003 10.4472 13.4483 10.0002 14.0003 10.0002H16.0003C18.3273 10.0002 20.1963 8.0042 19.9832 5.6342C19.7963 3.5352 17.8963 2.0002 15.7893 2.0002H14.0003C13.4483 2.0002 13.0003 1.5532 13.0003 1.0002C13.0003 0.4472 13.4433 0.0002 14.2493 0.0002H15.7593Z" fill="#2F3947" />
  </svg>
);

export const ImageIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 8.1L4 6.1L9.5 11.6L13 8.1L16 11.1V2H2V8.1ZM1 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM12.5 7C12.1022 7 11.7206 6.84196 11.4393 6.56066C11.158 6.27936 11 5.89782 11 5.5C11 5.10218 11.158 4.72064 11.4393 4.43934C11.7206 4.15804 12.1022 4 12.5 4C12.8978 4 13.2794 4.15804 13.5607 4.43934C13.842 4.72064 14 5.10218 14 5.5C14 5.89782 13.842 6.27936 13.5607 6.56066C13.2794 6.84196 12.8978 7 12.5 7Z" fill="#2F3947" />
  </svg>
);

export const BulletedListIcon = () => (
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.25 6.5C1.42 6.5 0.75 7.17 0.75 8C0.75 8.83 1.42 9.5 2.25 9.5C3.08 9.5 3.75 8.83 3.75 8C3.75 7.17 3.08 6.5 2.25 6.5ZM2.25 0.5C1.42 0.5 0.75 1.17 0.75 2C0.75 2.83 1.42 3.5 2.25 3.5C3.08 3.5 3.75 2.83 3.75 2C3.75 1.17 3.08 0.5 2.25 0.5ZM2.25 12.5C1.42 12.5 0.75 13.18 0.75 14C0.75 14.82 1.43 15.5 2.25 15.5C3.07 15.5 3.75 14.82 3.75 14C3.75 13.18 3.08 12.5 2.25 12.5ZM5.25 15H19.25V13H5.25V15ZM5.25 9H19.25V7H5.25V9ZM5.25 1V3H19.25V1H5.25Z" fill="#2F3947" />
  </svg>
);

export const NumberedListIcon = () => (
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.5 13H2.5V13.5H1.5V14.5H2.5V15H0.5V16H3.5V12H0.5V13ZM1.5 4H2.5V0H0.5V1H1.5V4ZM0.5 7H2.3L0.5 9.1V10H3.5V9H1.7L3.5 6.9V6H0.5V7ZM5.5 1V3H19.5V1H5.5ZM5.5 15H19.5V13H5.5V15ZM5.5 9H19.5V7H5.5V9Z" fill="#2F3947" />
  </svg>
);

export const InputFieldIcon = () => (
  <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.7076 13.5002H11.9168C11.4108 13.5002 11.0002 13.0979 11.0002 12.6003C11.0002 12.1026 11.4108 11.7003 11.9168 11.7003H19.7084C19.9605 11.7003 20.1668 11.4978 20.1668 11.2494V6.75116C20.1668 6.5027 19.9605 6.30022 19.7075 6.30022H11.9167C11.4107 6.30022 11 5.89703 11 5.40026C11 4.9035 11.4107 4.50031 11.9167 4.50031H19.7075C20.9715 4.50031 22 5.51011 22 6.75116V11.2494C22 12.4905 20.9715 13.5002 19.7075 13.5002H19.7076ZM4.58326 13.5002H2.29253C1.02847 13.5002 0 12.4904 0 11.2494V6.75116C0 5.51008 1.02851 4.50031 2.29253 4.50031H4.58326C5.08923 4.50031 5.49988 4.9035 5.49988 5.40026C5.49988 5.89703 5.08923 6.30022 4.58326 6.30022H2.29253C2.03948 6.30022 1.83324 6.50272 1.83324 6.75116V11.2494C1.83324 11.4978 2.03949 11.7003 2.29253 11.7003H4.58326C5.08923 11.7003 5.49988 12.1026 5.49988 12.6003C5.49988 13.0979 5.08923 13.5002 4.58326 13.5002ZM8.2499 18C7.74394 18 7.33328 17.5977 7.33328 17.1V0.899954C7.33328 0.403189 7.74394 0 8.2499 0C8.75587 0 9.16652 0.403189 9.16652 0.899954V17.1C9.16652 17.5977 8.75587 18 8.2499 18ZM10.9999 1.79991H5.49988C4.99301 1.79991 4.58326 1.39672 4.58326 0.899954C4.58326 0.403189 4.99392 0 5.49988 0H10.9999C11.5059 0 11.9165 0.403189 11.9165 0.899954C11.9165 1.39672 11.5059 1.79991 10.9999 1.79991ZM10.9999 18H5.49988C4.99392 18 4.58326 17.5977 4.58326 17.1C4.58326 16.6024 4.99392 16.2001 5.49988 16.2001H10.9999C11.5059 16.2001 11.9165 16.6024 11.9165 17.1C11.9165 17.5977 11.5059 18 10.9999 18Z" fill="currentColor" />
  </svg>
);

export const RadioButtonCheckedIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z" fill="#BABABA" />
    <path d="M10 15C12.7614 15 15 12.7614 15 10C15 7.23858 12.7614 5 10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15Z" fill="#BABABA" />
  </svg>
);

export const CheckboxIcon = ({ fill }: { fill?: string }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM16 16H2V2H16V16ZM14.99 6L13.58 4.58L6.99 11.17L4.41 8.6L2.99 10.01L6.99 14L14.99 6Z" fill={fill ?? 'currentColor'} />
  </svg>
);

export const TableIcon = ({ fill }: { fill?: string }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 7V11H16V7H10ZM8 7H2V11H8V7ZM10 16H16V13H10V16ZM8 16V13H2V16H8ZM10 2V5H16V2H10ZM8 2H2V5H8V2ZM1 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0Z" fill={fill ?? '#2F3947'} />
  </svg>
);

export const InsertTextIcon = () => (
  <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 5.01L0 5V7H18V5.01ZM0 10H12V12H0V10ZM18 0H0V2.01L18 2V0Z" fill="currentColor" />
  </svg>
);

export const AttachIcon = () => (
  <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 5V16.5C10 18.71 8.21 20.5 6 20.5C3.79 20.5 2 18.71 2 16.5V4C2 2.62 3.12 1.5 4.5 1.5C5.88 1.5 7 2.62 7 4V14.5C7 15.05 6.55 15.5 6 15.5C5.45 15.5 5 15.05 5 14.5V5H3.5V14.5C3.5 15.88 4.62 17 6 17C7.38 17 8.5 15.88 8.5 14.5V4C8.5 1.79 6.71 0 4.5 0C2.29 0 0.5 1.79 0.5 4V16.5C0.5 19.54 2.96 22 6 22C9.04 22 11.5 19.54 11.5 16.5V5H10Z" fill="currentColor" />
  </svg>
);

export const SignatureIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.1024 7.39L17.1624 6.33C17.9424 5.55 17.9424 4.28 17.1624 3.5L15.7524 2.09C14.9724 1.31 13.7024 1.31 12.9224 2.09L11.8624 3.15L16.1024 7.39ZM11.8624 8.81L4.66244 16H3.25244V14.59L10.4424 7.4L11.8624 8.81ZM10.4424 4.56L1.25244 13.76V18H5.49244L14.6824 8.81L10.4424 4.56ZM16.2524 14.5C16.2524 16.69 13.7124 18 11.2524 18C10.7024 18 10.2524 17.55 10.2524 17C10.2524 16.45 10.7024 16 11.2524 16C12.7924 16 14.2524 15.27 14.2524 14.5C14.2524 14.03 13.7724 13.63 13.0224 13.3L14.5024 11.82C15.5724 12.45 16.2524 13.29 16.2524 14.5ZM1.83244 10.35C0.862441 9.79 0.252441 9.06 0.252441 8C0.252441 6.2 2.14244 5.37 3.81244 4.64C4.84244 4.18 6.25244 3.56 6.25244 3C6.25244 2.59 5.47244 2 4.25244 2C2.99244 2 2.45244 2.61 2.42244 2.64C2.07244 3.05 1.44244 3.1 1.02244 2.76C0.612441 2.42 0.532441 1.81 0.872441 1.38C0.982441 1.24 2.01244 0 4.25244 0C6.49244 0 8.25244 1.32 8.25244 3C8.25244 4.87 6.32244 5.72 4.61244 6.47C3.67244 6.88 2.25244 7.5 2.25244 8C2.25244 8.31 2.68244 8.6 3.32244 8.86L1.83244 10.35Z" fill="currentColor" />
  </svg>
);

export const CalendarIcon = () => (
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 2H15V0H13V2H5V0H3V2H2C0.89 2 0.00999999 2.9 0.00999999 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 18H2V8H16V18ZM16 6H2V4H16V6ZM6 12H4V10H6V12ZM10 12H8V10H10V12ZM14 12H12V10H14V12ZM6 16H4V14H6V16ZM10 16H8V14H10V16ZM14 16H12V14H14V16Z" fill="currentColor" />
  </svg>
);

export const DropdownIcon = () => (
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0.400391 7.55541C0.400391 7.29278 0.607579 7.07983 0.875968 7.07983H2.80481C3.06745 7.07983 3.28039 7.28702 3.28039 7.55541V9.48426C3.28039 9.74689 3.0732 9.95983 2.80481 9.95983H0.875968C0.613332 9.95983 0.400391 9.75265 0.400391 9.48426V7.55541ZM1.36039 8.03983H2.32039V8.99983H1.36039V8.03983Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0.400391 11.3955C0.400391 11.1329 0.607579 10.9199 0.875968 10.9199H2.80481C3.06745 10.9199 3.28039 11.1271 3.28039 11.3955V13.3243C3.28039 13.587 3.0732 13.7999 2.80481 13.7999H0.875968C0.613332 13.7999 0.400391 13.5927 0.400391 13.3243V11.3955ZM1.36039 11.8799H2.32039V12.8399H1.36039V11.8799Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0.400391 15.2353C0.400391 14.9727 0.607579 14.7598 0.875968 14.7598H2.80481C3.06745 14.7598 3.28039 14.967 3.28039 15.2353V17.1642C3.28039 17.4268 3.0732 17.6398 2.80481 17.6398H0.875968C0.613332 17.6398 0.400391 17.4326 0.400391 17.1642V15.2353ZM1.36039 15.7198H2.32039V16.6798H1.36039V15.7198Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.7396 2.75977L17.7086 3.79903H15.7598L16.7396 2.75977Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.24023 8.03979H13.8402V8.99979H4.24023V8.03979Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.24023 11.8799H13.8402V12.8399H4.24023V11.8799Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.24023 15.72H13.8402V16.68H4.24023V15.72Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0.400391 1.31102C0.400391 0.78576 0.837533 0.359863 1.3525 0.359863H18.6483C19.1741 0.359863 19.6004 0.792858 19.6004 1.31102V5.16885C19.6004 5.6941 19.1632 6.12 18.6483 6.12H1.3525C0.826699 6.12 0.400391 5.68701 0.400391 5.16885V1.31102ZM1.36039 1.31986H13.8404V5.15986H1.36039V1.31986ZM14.8004 1.31986H18.6404V5.15986H14.8004V1.31986Z" fill="currentColor" />
  </svg>
);

export const AlignmentIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 12H0V14H12V12ZM12 4H0V6H12V4ZM0 10H18V8H0V10ZM0 18H18V16H0V18ZM0 0V2H18V0H0Z" fill="#2F3947" />
  </svg>
);

export const UnlinkIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.39 9.06505L14 10.675V9.06505H12.39ZM15 5.06505H11V6.96505H15C16.71 6.96505 18.1 8.35505 18.1 10.065C18.1 11.335 17.33 12.435 16.23 12.905L17.63 14.305C19.05 13.425 20 11.855 20 10.065C20 7.30505 17.76 5.06505 15 5.06505ZM0 2.33505L3.11 5.44505C1.29 6.18505 0 7.97505 0 10.065C0 12.825 2.24 15.065 5 15.065H9V13.165H5C3.29 13.165 1.9 11.775 1.9 10.065C1.9 8.47505 3.11 7.16505 4.66 6.99505L6.73 9.06505H6V11.065H8.73L11 13.335V15.065H12.73L16.74 19.075L18.15 17.665L1.41 0.925049L0 2.33505Z" fill="#2F3947" />
  </svg>
);