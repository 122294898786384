import { PropsWithChildren } from 'react';

import { createSelectable, TSelectableItemProps } from 'react-selectable-fast';

const SelectableItem = createSelectable(({
  selectableRef,
  isSelected,
  isSelecting,
  children,
  fieldKey,
}: PropsWithChildren<TSelectableItemProps & { fieldKey: number | undefined }>) => (
  <div
    ref={selectableRef}
    className={`selectable-item${isSelected ? ' selected' : ''}${isSelecting ? ' selecting' : ''}`}
    data-key={fieldKey}
  >
    {children}
  </div>
));

export default SelectableItem;