/* eslint-disable */
import { HTMLAttributes } from 'react';
import { IconButton } from '@material-ui/core';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import './cardbarStyles.css';

interface CardbarProps extends HTMLAttributes<HTMLDivElement> {
  delete?: () => void;
}

const exec = (func: Function, ...args: any[]) => (e?: React.MouseEvent) => {
  e && e.preventDefault();
  return func(...args);
};

export const Cardbar: React.FC<CardbarProps> = props => {
  return (
    <div className={`cardbar ${props.className}`}>
      {props.children}
      {props.delete && (
        <IconButton onMouseDown={exec(props.delete)}>
          <DeleteOutline />
        </IconButton>
      )}
    </div>
  );
};