/* eslint-disable */
import { CSSProperties } from 'react';

export const options = {
  defaultWidth: 50 as number,
  defaultHeight: 22 as number,
  tableStyle: {
    borderSpacing: 0,
    Layout: 'fixed',
    wordBreak: 'break-word',
  } as CSSProperties,
};
