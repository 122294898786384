import ENDPOINT from 'constants/endpoints';
import { IResponseOfRequest } from 'types/Api';
import { axiosInstance } from 'utils/axiosInstance';

// User Notification bell count event and list
export const getNotificationCount = () => (
  axiosInstance.get(ENDPOINT.NOTIFICATION_COUNT)
);

export const getNotificationList = (): Promise<IResponseOfRequest<object[]>> => (
  axiosInstance.get(ENDPOINT.NOTIFICATION_LIST)
);

export const setSeenNotificationList = (ids: number[]): Promise<IResponseOfRequest<object[]>> => (
  axiosInstance.patch(ENDPOINT.NOTIFICATION_LIST, ids)
);