/* eslint-disable prefer-regex-literals */
export const START_INTENDANTS_REGEX = new RegExp(/^( |\t)/gm);
export const START_NUMBER_POINT_REGEX = new RegExp(/^(\d+\.)+(\r\n|\r|\t| )/gm);
export const START_BULLETED_REGEX = new RegExp(/^•+(\r\n|\r|\t| )/gm);
export const INTENDANTS_REGEX = new RegExp(/\t/gm);

export const NEW_LINE_REGEX = new RegExp(/\r\n|\r|\n/gm);
export const ANY_EDGE_SPACES_REGEX = /^[\s]*$/gm;

/**
 * The filler DROP_BUFFER_FILLER needs for resolving copy/paste buffer issue,
 * which has different behavior with empty buffer. So we need any recognizing element to be sure
 * pasted element is from the drag&drop event.
 */
export const DROP_BUFFER_FILLER = '-//-';