const UploadIcon: React.FC<{ color?: string }> = (props) => {
  const {
    color = '#4C95FC',
  } = props;

  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.9583 3.04167V19.9583H3.04167V3.04167H19.9583ZM19.9583 0.625H3.04167C1.7125 0.625
        0.625 1.7125 0.625 3.04167V19.9583C0.625 21.2875 1.7125 22.375 3.04167 22.375H19.9583C21.2875
        22.375 22.375 21.2875 22.375 19.9583V3.04167C22.375 1.7125 21.2875 0.625 19.9583 0.625ZM14.0858
        11.3308L10.4608 16.0071L7.875 12.8775L4.25 17.5417H18.75L14.0858 11.3308Z"
        fill={color}
      />
    </svg>
  );
};

export default UploadIcon;