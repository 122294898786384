/* eslint-disable */
import { Editor, Transforms, Element as SlateElement } from 'slate';
import imageExtensions from 'image-extensions';
import isUrl from 'is-url';
import { BlockFormatType, ImageOptionsType } from 'types/Editor';
import { PARAGRAPH } from 'constants/editors';
import { isElementInEditor } from 'utils/editorHelpers';

export const insertImage = (editor: Editor, format: BlockFormatType, options: ImageOptionsType) => {
  const { selection } = editor;

  const text = { text: '' };
  const image: SlateElement = {
    type: format,
    url: options.url,
    alt: options.alt,
    children: [text]
  };

  const paragraph: SlateElement = {
    type: PARAGRAPH,
    children: [text]
  };

  if (!selection) {
    Transforms.move(editor, { distance: 1, unit: 'character', reverse: true });
    Transforms.insertNodes(editor, image);
  } else {
    Transforms.insertNodes(editor, image);
  }
  Transforms.insertNodes(editor, paragraph);
}

export const insertImageBreakLine = (editor: Editor): boolean => {
  const { selection } = editor;

  if (isElementInEditor(editor, 'image') && selection) {
    const paragraph: SlateElement = {
      type: PARAGRAPH,
      children: [{ text: '' }]
    };

    const currentPath = selection.focus.path;

    if (currentPath.length && currentPath[currentPath.length - 2] === 0) {
      let pathToInsert: number[] = [];
      switch (currentPath.length) {
        case 2:
          pathToInsert = [0];
          break;
        case 3:
          pathToInsert = [0, 0];
          break;
        case 4:
          pathToInsert = [0, 1, 0];
          break;
      }
      if (pathToInsert.length > 0) {
        Transforms.insertNodes(editor, paragraph, {at: pathToInsert});
      } else {
        Transforms.insertNodes(editor, paragraph);
      }
      return true;
    }
  }

  return false;
}

export const isImageUrl = (url: string): boolean => {
  if (!url) return false;
  if (!isUrl(url)) return false;
  const ext = new URL(url).pathname.split('.').pop();
  if (!ext) return false;
  return imageExtensions.includes(ext);
}