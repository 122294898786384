import { FC, SVGAttributes } from 'react';
import { useDispatch } from 'react-redux';
import { logoutUser } from 'store/actions/auth';

interface ILogoutButtonProps {
  title: string;
  icon?: FC<SVGAttributes<any>>;
}

const LogoutButton: FC<ILogoutButtonProps> = ({ icon: IconComponent, title }) => {
  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(logoutUser());
  };
  return (
    <button type="button" onClick={logoutHandler}>
      {IconComponent && <IconComponent />}
      <span className="title">{title}</span>
    </button>
  );
};

export default LogoutButton;