import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  FORMS_SHARE_VIEW_MODES,
  SHARE_VIEW_DOCUMENT_AND_FORM_BUILDER,
  SHARE_VIEW_FORM_BUILDER_MODE,
  SHARE_VIEW_MODES,
} from 'constants/shareViewModes';
import { useRolePermission } from 'hooks/useRolePermission';
import { RootStateType } from 'store/reducers';
import {
  isCollectionPagePath,
  isPDFTemplateEditorOrListPagePath,
} from 'utils/routeHelpers';

const useShareLinkViewModes = () => {
  const location = useLocation();
  const {
    isFormDocumentShareLink,
    collectionExecutedPublicLink,
  } = useSelector((state: RootStateType) => state.publicPages);
  const { permissionUsingFormBuilder, permissionUsingPDFFormBuilder } = useRolePermission();
  if (isFormDocumentShareLink) {
    return FORMS_SHARE_VIEW_MODES;
  }
  if (collectionExecutedPublicLink) {
    return SHARE_VIEW_MODES;
  }

  const isCollectionPage = isCollectionPagePath(location.pathname);
  const isPDFTemplateEditor = isPDFTemplateEditorOrListPagePath(location.pathname);

  const canUseForCollection = isCollectionPage && permissionUsingFormBuilder;
  const canUseForPDFDocument = isPDFTemplateEditor && permissionUsingPDFFormBuilder;

  const modes = canUseForCollection || canUseForPDFDocument
    ? SHARE_VIEW_MODES.concat(SHARE_VIEW_FORM_BUILDER_MODE, SHARE_VIEW_DOCUMENT_AND_FORM_BUILDER)
    : SHARE_VIEW_MODES;

  return modes;
};

export default useShareLinkViewModes;