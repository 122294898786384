/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEventHandler, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { SwiperSlide } from 'swiper/react';

import DocumentNavigator from 'components/DocumentNavigator';
import Editor from 'components/Editor';
import EditorContainer from 'components/Editor/EditorContainer';
import EditorDevTools from 'components/Editor/EditorDevTools';
import EditorWrapper from 'components/Editor/EditorWrapper';
import FormsSlider from 'components/Editor/FormView/FormsSlider';
import EditorHeader from 'components/EditorHeader/EditorHeader';
import FormBuilder from 'components/FormBuilder';
import FormBuilderOutline from 'components/FormBuilder/FormBuilderOutline';
import ModalContentWindow from 'components/Modals/ModalContentWindow';
import ShareLinkModal from 'components/Modals/ModalWrapper';
import EditorSubtypesModal from 'components/Modals/SubtypesModal/EditorSubtypesModal';
import MultiTemplatesNavigator from 'components/MultiTemplatesNavigator';
import PdfEditor from 'components/PdfEditor/PdfEditor';
import PdfFieldProperties from 'components/PdfEditor/PdfEditorDevTools/PdfFieldProperties';
import {
  DEFAULT_SECTION,
  DEFAULT_TEMPLATES_LIST,
  EditorGridAxisCount,
  FIELD_GROUPING_VALUE,
  GROUP_FIELDS_DEPENDENCIES_ARRAY,
  INITIAL_EDITOR_VALUE,
} from 'constants/editors';
import { FORM_BUILDER } from 'constants/formBuilder';
import { RedirectTypeEnum, URL_PUBLIC_COLLECTION } from 'constants/general';
import { SOMETHING_WENT_WRONG, SUCCESS_CODE } from 'constants/generalErrors';
import { useRolePermission } from 'hooks/useRolePermission';
import { getTemplateDetails } from 'services/api';
import { apiErrorHandler } from 'services/apiErrorHandler';
import {
  createMultiTemplateDetails,
  deletePdfPartsMultiTemplate,
  getMultiTemplateDetails,
  setMultiTemplateDetails,
  setPdfPartOfMultiTemplate,
  updateMultiTemplateDetails,
} from 'store/actions/collections';
import { setAllAssignments } from 'store/actions/editorSlate';
import { createMultiTemplatePublicLink, deleteMultiTemplateLinkFromStore } from 'store/actions/publicPages';
import { updateTemplateSections } from 'store/actions/template';
import {
  addSectionsToFormBuilder,
  changeTemplateInCollection,
  removeSectionsFromFormBuilder,
} from 'store/actions/userData';
import { RootStateType } from 'store/reducers';
import { initialSigners } from 'store/reducers/editorSlate';
import { AssignmentsMainParts, AssignmentsObjectType } from 'types/Editor';
import {
  ICollectionDetails,
  IMultiTemplateRequestObj,
  ITemplateForMultiTemplate,
  MultiTemplateStateType,
} from 'types/MultiTemplate';
import { PDFFieldType } from 'types/PdfTemplates';
import { PublicPageDataType } from 'types/PublicPage';
import { ITemplateSection } from 'types/redux';
import { IFormBuilderSection } from 'types/Sections';
import {
  IEditorModalSubtypesFieldsWithAssignments,
  IPublicPageSubtypesDataWithAssignments,
  ISubtypeDifferentValuesWithAssignments,
  ISubtypeKeysValues,
  ISubtypesFieldsKeysAndAssignments,
} from 'types/Subtypes';
import { ITemplateDetails } from 'types/Templates';
import { ProfileInfoType } from 'types/userProfile';
import { trackAmplitudeEvent } from 'utils/amplitude/amplitudeTrackingUtlis';
import { COLLECTION_WAS_VIEWED_BY_MANAGER } from 'utils/amplitude/amplituteConstants';
import { getTemplatesToSave, getUnitedAssignmentsArray } from 'utils/collectionsHelpers';
import { addSectionsDocument } from 'utils/editorHelpers';
import {
  getAllFieldsFromFormBuilder,
  getFormBuilderSectionsFromTemplates,
  setFormBuilderGroupedFields,
  updateFormBuilder,
} from 'utils/FormBuilder/formBuilderHelpers';
import { isNotEmptyObjectInList } from 'utils/isEmptyObject';
import getGroupedFieldsFromTemplateArray from 'utils/ManagerEditor/getGroupedFieldsFromTemplateArray';
import { updateSectionsProperties } from 'utils/PublicPage/updatePublicPageStoreHelper';
import { getTemplateSectionsInfo } from 'utils/sectionUtils';
import { combineKeysAndValues, getFieldKeys, getSubtypeDifferentValues } from 'utils/Subtypes/subtypeHelpers';
import { validateFormField } from 'utils/validation';

const CollectionDetails = () => {
  const { id } = useParams<{ id?: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { permissionUsingSectionsLight, permissionUsingFormBuilder } = useRolePermission();
  const [templatesList, setTemplatesList] = useState<Partial<ITemplateForMultiTemplate>[]>([]);
  const [multiTemplateName, setMultiTemplateName] = useState<string>('');
  const [collectionDescription, setCollectionDescription] = useState<string>('');
  const [fieldNameError, setFieldNameError] = useState<string>('');
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [showShareLinkModal, setShowShareLinkModal] = useState<boolean>(false);
  const [isVisibleSubtypeModal, setSubtypeModalVisibility] = useState(false);
  const [userSubtypesFields, setUserSubtypesFields] = useState<ISubtypeDifferentValuesWithAssignments>({});
  const [managerSubtypesFields, setManagerSubtypesFields] = useState<ISubtypeDifferentValuesWithAssignments>({});
  const [userGroupedFields, setUserGroupedFields] = useState<ISubtypeDifferentValuesWithAssignments>({});
  const [managerGroupedFields, setManagerGroupedFields] = useState<ISubtypeDifferentValuesWithAssignments>({});
  const [userSubtypesFieldKeys, setUserSubtypesFieldKeys] = useState<ISubtypesFieldsKeysAndAssignments>({});
  const [managerSubtypesFieldKeys, setManagerSubtypesFieldKeys] = useState<ISubtypesFieldsKeysAndAssignments>({});
  const [userGroupedFieldKeys, setUserGroupedFieldKeys] = useState<ISubtypesFieldsKeysAndAssignments>({});
  const [managerGroupedFieldKeys, setManagerGroupedFieldKeys] = useState<ISubtypesFieldsKeysAndAssignments>({});
  const [isFormBuilderOpened, setIsFormBuilderOpened] = useState<boolean>(false);
  const [pathToRedirect, setPathToRedirect] = useState<string>('');

  const [collectionAssignments, setCollectionAssignments] = useState<AssignmentsObjectType>(initialSigners);
  const { multiTemplatePublicLink } = useSelector((state: RootStateType) => state.publicPages);
  const { isLoading }: MultiTemplateStateType = useSelector((state: RootStateType) => state.errorLoading);
  const collectionDetails: ICollectionDetails = useSelector((state: RootStateType) => state.user.collectionDetails);
  const userInfo: ProfileInfoType = useSelector((state: RootStateType) => state.profile.profileInfo);

  const managersAssignmentsLength = collectionAssignments[AssignmentsMainParts.MANAGERS].length;
  const recipientsAssignmentsLength = collectionAssignments[AssignmentsMainParts.MANAGERS].length;

  useEffect(() => {
    dispatch(setAllAssignments(collectionAssignments));
  }, [managersAssignmentsLength, recipientsAssignmentsLength]);

  useEffect(() => {
    if (id) {
      dispatch(getMultiTemplateDetails(Number(id), permissionUsingFormBuilder));
    }
    return () => {
      setMultiTemplateName('');
      setTemplatesList([]);
      dispatch(deletePdfPartsMultiTemplate());
      dispatch(setMultiTemplateDetails(null));
      if (permissionUsingSectionsLight) {
        dispatch(updateTemplateSections([DEFAULT_SECTION]));
      }
    };
  }, [dispatch, id, permissionUsingSectionsLight]);

  useEffect(() => {
    if (id && collectionDetails) {
      trackAmplitudeEvent({
        eventName: COLLECTION_WAS_VIEWED_BY_MANAGER,
        userInfo,
      });
    }
  }, [collectionDetails?.id]);

  useEffect(() => {
    if (!collectionDetails) return;
    const templates = collectionDetails.templates && collectionDetails.templates.map(
      (templateData: any) => {
        if (templateData.template.pdf_file_url) {
          dispatch(setPdfPartOfMultiTemplate(templateData.template));
        }
        const sections = templateData.template && templateData.template.sections.map((sectionElement: any) => (
          { ...sectionElement.section }
        ));
        return {
          ...templateData.template,
          position: templateData.position,
          content: addSectionsDocument(sections, false) || INITIAL_EDITOR_VALUE,
        };
      },
    ).sort((a: any, b: any) => a.position - b.position);
    setCollectionAssignments(getUnitedAssignmentsArray(templates));
    setTemplatesList(templates || []);
    setMultiTemplateName(collectionDetails.name || '');
    setCollectionDescription(collectionDetails.description || '');
  }, [collectionDetails?.templates]);

  useEffect(() => {
    let infoSections: ITemplateSection[] = [];
    templatesList.forEach((template: Partial<ITemplateForMultiTemplate>) => {
      const sections = template.sections && template.sections.map((sectionElement: any) => (
        { ...sectionElement.section }
      ));
      const info: ITemplateSection[] = getTemplateSectionsInfo(sections || []);
      infoSections = infoSections.concat(...info);
    });
    dispatch(updateTemplateSections(infoSections));
  }, [dispatch, templatesList]);

  const onChangeName: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    setMultiTemplateName(target.value);
  };

  const getNewTemplateRequest = async (elementId: number) => {
    const result = await apiErrorHandler(getTemplateDetails, elementId);

    if (result && result.status === SUCCESS_CODE && result.data) {
      toast.warn('Document is added, please, save the changes');
      const sections = result.data.sections && result.data.sections.map((sectionElement: any) => (
        { ...sectionElement.section }
      ));
      return {
        ...result.data,
        content: addSectionsDocument(sections, false) || INITIAL_EDITOR_VALUE,
      };
    }
    return null;
  };

  const setFilteredTemplateListById = (idToChange: number, templateToReplace: ITemplateDetails | null = null) => {
    if (templateToReplace) {
      if (templateToReplace.pdf_file_url) {
        dispatch(setPdfPartOfMultiTemplate(templateToReplace));
      }
      const updatedTemplateList = templatesList.map((template: Partial<ITemplateForMultiTemplate>) => (
        (template.id === idToChange) ? templateToReplace : template
      ));
      setCollectionAssignments(getUnitedAssignmentsArray(updatedTemplateList));
      return setTemplatesList(updatedTemplateList);
    }

    const updatedTemplateList = templatesList.filter(
      (template: Partial<ITemplateForMultiTemplate>) => template.id !== idToChange,
    );
    setCollectionAssignments(getUnitedAssignmentsArray(updatedTemplateList));
    return setTemplatesList(updatedTemplateList);
  };

  const updateElementsHandler = async (oldElementId: number, newElementId: number, isAddNewMode: boolean = false) => {
    if (!oldElementId) {
      if (newElementId) {
        // add a new template
        const newTemplate = await getNewTemplateRequest(newElementId);

        if (collectionDetails) {
          const formBuilderSections: IFormBuilderSection[] = getFormBuilderSectionsFromTemplates([newTemplate]);

          dispatch(addSectionsToFormBuilder(formBuilderSections));
        }
        return setFilteredTemplateListById(oldElementId, newTemplate);
      }

      if (isAddNewMode) {
        // remove an empty search string
        return setFilteredTemplateListById(oldElementId);
      }
      // add a search node
      return setTemplatesList([...templatesList].concat(DEFAULT_TEMPLATES_LIST));
    }

    if (!newElementId) {
      // delete template
      const oldTemplate = await getNewTemplateRequest(oldElementId);
      dispatch(removeSectionsFromFormBuilder(oldTemplate.id, FORM_BUILDER.COLLECTION));

      return setTimeout(() => setFilteredTemplateListById(oldElementId), 0);
    }

    // update template
    dispatch(removeSectionsFromFormBuilder(oldElementId, FORM_BUILDER.COLLECTION));
    const newTemplate = await getNewTemplateRequest(newElementId);
    if (collectionDetails) {
      dispatch(addSectionsToFormBuilder(getFormBuilderSectionsFromTemplates([newTemplate])));
      dispatch(changeTemplateInCollection(newTemplate, oldElementId));
    }
    return newTemplate && setFilteredTemplateListById(oldElementId, newTemplate);
  };

  const updateElementsOrderHandler = (sortedNavList: Partial<ITemplateForMultiTemplate>[]) => {
    const sortedTemplatesList = templatesList.map((template: Partial<ITemplateForMultiTemplate>) => {
      const sortedTemplate = sortedNavList.find((el) => el.id === template.id);
      if (sortedTemplate) {
        template.position = sortedTemplate.position; // eslint-disable-line
      }
      return template;
    }).sort((a: any, b: any) => a.position - b.position);
    setTemplatesList(sortedTemplatesList);
  };

  const saveCollectionHandler = (templates: Partial<ITemplateForMultiTemplate>[], isUpdate: boolean = false) => {
    const templatesToSave = getTemplatesToSave(templates, isUpdate);
    const formBuilderStructure: IFormBuilderSection[] = getFormBuilderSectionsFromTemplates(
      templates as PublicPageDataType[],
    );

    const multiTemplateData: IMultiTemplateRequestObj = {
      name: multiTemplateName,
      description: collectionDescription,
      templates: templatesToSave,
      form_builder_structure: setFormBuilderGroupedFields(
        collectionDetails?.form_builder_structure ?? formBuilderStructure,
      ),
    };

    if (id) {
      multiTemplateData.id = Number(id);
      const updatedFormBuilderStructure = updateFormBuilder(
        multiTemplateData.form_builder_structure ?? [],
        templatesList as PublicPageDataType[],
      );
      multiTemplateData.form_builder_structure = updatedFormBuilderStructure;
      return dispatch(updateMultiTemplateDetails(multiTemplateData));
    }
    return dispatch(createMultiTemplateDetails(multiTemplateData));
  };

  // TODO: add useCallback ?
  const saveMultiTemplateHandler = (redirectTo: string = '') => {
    const errorMessage = validateFormField({
      name: 'form_name',
      value: multiTemplateName,
      required: true,
    });
    setFieldNameError(errorMessage);
    if (errorMessage) return false;

    const userSubtypesData: IPublicPageSubtypesDataWithAssignments = getGroupedFieldsFromTemplateArray({
      templates: templatesList,
      collectionAssignments,
      dependenciesArray: GROUP_FIELDS_DEPENDENCIES_ARRAY,
    });

    const managerSubtypesData: IPublicPageSubtypesDataWithAssignments = getGroupedFieldsFromTemplateArray({
      templates: templatesList,
      collectionAssignments,
      dependenciesArray: GROUP_FIELDS_DEPENDENCIES_ARRAY,
      assignmentsMainPart: AssignmentsMainParts.MANAGERS,
    });

    const userGroupedData: IPublicPageSubtypesDataWithAssignments = getGroupedFieldsFromTemplateArray({
      templates: templatesList,
      collectionAssignments,
      dependenciesArray: GROUP_FIELDS_DEPENDENCIES_ARRAY,
      groupedBy: FIELD_GROUPING_VALUE.BY_ASSIGNEE,
    });

    const managerGroupedData: IPublicPageSubtypesDataWithAssignments = getGroupedFieldsFromTemplateArray({
      templates: templatesList,
      collectionAssignments,
      dependenciesArray: GROUP_FIELDS_DEPENDENCIES_ARRAY,
      assignmentsMainPart: AssignmentsMainParts.MANAGERS,
      groupedBy: FIELD_GROUPING_VALUE.BY_ASSIGNEE,
    });

    if (
      isNotEmptyObjectInList([
        userGroupedData,
        managerGroupedData,
        userSubtypesData,
        managerSubtypesData,
      ])
    ) {
      const differentUserValues: ISubtypeDifferentValuesWithAssignments = getSubtypeDifferentValues(
        userSubtypesData,
        GROUP_FIELDS_DEPENDENCIES_ARRAY,
      );

      const differentManagerValues: ISubtypeDifferentValuesWithAssignments = getSubtypeDifferentValues(
        managerSubtypesData,
        GROUP_FIELDS_DEPENDENCIES_ARRAY,
      );

      if (isNotEmptyObjectInList(Object.values(differentUserValues))) {
        setUserSubtypesFields(differentUserValues);
        setUserSubtypesFieldKeys(getFieldKeys(userSubtypesData));
      }
      if (isNotEmptyObjectInList(Object.values(differentManagerValues))) {
        setManagerSubtypesFields(differentManagerValues);
        setManagerSubtypesFieldKeys(getFieldKeys(managerSubtypesData));
      }

      const differentUserGroupedValues: ISubtypeDifferentValuesWithAssignments = getSubtypeDifferentValues(
        userGroupedData,
        GROUP_FIELDS_DEPENDENCIES_ARRAY,
      );
      const differentManagerGroupedValues: ISubtypeDifferentValuesWithAssignments = getSubtypeDifferentValues(
        managerGroupedData,
        GROUP_FIELDS_DEPENDENCIES_ARRAY,
      );

      if (isNotEmptyObjectInList(Object.values(differentUserGroupedValues))) {
        setUserGroupedFields(differentUserGroupedValues);
        setUserGroupedFieldKeys(getFieldKeys(userGroupedData));
      }
      if (isNotEmptyObjectInList(Object.values(differentManagerGroupedValues))) {
        setManagerGroupedFields(differentManagerGroupedValues);
        setManagerGroupedFieldKeys(getFieldKeys(managerGroupedData));
      }

      if (
        isNotEmptyObjectInList([
          ...Object.values(differentUserValues),
          ...Object.values(differentManagerValues),
          ...Object.values(differentUserGroupedValues),
          ...Object.values(differentManagerGroupedValues),
        ])
      ) {
        setPathToRedirect(redirectTo);
        setSubtypeModalVisibility(true);
        return false;
      }
    }
    return saveCollectionHandler(templatesList);
  };

  const openFormBuilderHandler = (isFormBuilderOpened: boolean) => {
    saveMultiTemplateHandler();
    setIsFormBuilderOpened(isFormBuilderOpened);
  };

  const saveCollectionWithSubtypesHandler = (
    userSubtypes: IEditorModalSubtypesFieldsWithAssignments,
    managerSubtypes: IEditorModalSubtypesFieldsWithAssignments,
    groupedFields: {
      user: IEditorModalSubtypesFieldsWithAssignments,
      manager: IEditorModalSubtypesFieldsWithAssignments,
    },
  ) => {
    const entries: ISubtypeKeysValues = {
      ...combineKeysAndValues(userSubtypes, userSubtypesFieldKeys),
      ...combineKeysAndValues(managerSubtypes, managerSubtypesFieldKeys),
      ...combineKeysAndValues(groupedFields.user, userGroupedFieldKeys),
      ...combineKeysAndValues(groupedFields.manager, managerGroupedFieldKeys),
    };

    const updatedTemplateList = templatesList.map((template) => (
      updateSectionsProperties(template as ITemplateDetails, entries)
    ));
    setTemplatesList(updatedTemplateList);
    setShowErrorModal(false);
    setSubtypeModalVisibility(false);
    saveCollectionHandler(updatedTemplateList, true);
    if (pathToRedirect) {
      history.push(pathToRedirect);
      setPathToRedirect('');
    }
  };

  const handlerShareLinkModal = () => {
    const save = saveMultiTemplateHandler();
    if (save && id) {
      dispatch(createMultiTemplatePublicLink({ multi_template_id: id, assignments: collectionAssignments }));
      setShowShareLinkModal(true);
    } else {
      setShowErrorModal(true);
    }
  };

  const saveMultiTemplateController = (
    redirectType: RedirectTypeEnum | undefined = undefined,
    redirectPath: string | undefined = undefined,
  ) => {
    const result = saveMultiTemplateHandler(redirectPath);
    if (redirectType && redirectPath && result) {
      history.push(redirectPath);
    }
  };

  const navigatorElements: any[] = templatesList && templatesList.map((template: any, index: number) => ({
    id: template.id,
    key: template.id,
    name: template.name,
    position: index,
    sections: template.sections && template.sections.map((sectionElement: any) => (
      { ...sectionElement.section }
    )),
  }));

  const formBuilderFields: PDFFieldType[] = getAllFieldsFromFormBuilder(
    collectionDetails?.form_builder_structure ?? [],
  ) as PDFFieldType[];

  return (
    <>
      <EditorWrapper>
        <EditorHeader
          // TODO When we create preview functionality for collection, we'll enable preview button
          showPreviewButton={false}
          documentName={multiTemplateName}
          onChangeDocumentName={onChangeName}
          errorText={fieldNameError}
          saveDocument={(
            redirectType: RedirectTypeEnum | undefined,
            redirectPath: string | undefined,
          ) => saveMultiTemplateController(redirectType, redirectPath)}
          description={collectionDescription}
          setDescription={setCollectionDescription}
          showShareLinkModal={showShareLinkModal}
          handlerShareLinkModal={handlerShareLinkModal}
          isShareButtonAvailable={!!id}
          documentsInCollection={templatesList}
          isFormBuilderAvailable={permissionUsingFormBuilder && Boolean(collectionDetails)}
          isFormBuilderOpened={isFormBuilderOpened}
          onOpenFormBuilder={openFormBuilderHandler}
          isCollectionPage
        />
        <EditorContainer>
          {
            isFormBuilderOpened
              ? (
                <DocumentNavigator panelTitle="Outline">
                  <FormBuilderOutline formBuilderType={FORM_BUILDER.COLLECTION} />
                </DocumentNavigator>
              )
              : (
                <DocumentNavigator panelTitle="Documents">
                  <MultiTemplatesNavigator
                    outlineElements={navigatorElements}
                    updateElements={updateElementsHandler}
                    updateElementsOrder={updateElementsOrderHandler}
                  />
                </DocumentNavigator>
              )
          }
          {
            isFormBuilderOpened
              ? (
                <>
                  <FormBuilder formBuilderType={FORM_BUILDER.COLLECTION} />
                  <div className="editor-navigator-wrapper right-0">
                    <EditorDevTools fields={formBuilderFields}>
                      <PdfFieldProperties
                        pdfTemplateFieldsArray={formBuilderFields}
                        readOnly
                        collectionAssignments={collectionAssignments}
                      />
                    </EditorDevTools>
                  </div>
                </>
              )
              : (
                <div className="collection-slider">
                  <FormsSlider>
                    {
                      templatesList.map((template: any) => (
                        <SwiperSlide key={template.id}>
                          <EditorContainer columns={EditorGridAxisCount.One}>
                            {
                              template.pdf_file_url
                                ? (
                                  <PdfEditor
                                    key={template.id}
                                    isPublicPage={false}
                                    readOnlyMode
                                    visibleEditorDevTools={false}
                                    isMultiTemplate
                                    pdfTemplateId={template.id}
                                    isCollectionPage
                                  />
                                )
                                : (
                                  <Editor
                                    key={template.id}
                                    viewFormsName={template.name}
                                    viewFormsTitle={template.template_title}
                                    viewFormsDescription={template.description}
                                    content={template.content}
                                    onChange={() => null}
                                    readOnlyMode
                                    visibleEditorDevTools={false}
                                    isCollectionPage
                                  />
                                )
                            }
                          </EditorContainer>
                        </SwiperSlide>
                      ))
                    }
                  </FormsSlider>
                </div>
              )
            }
        </EditorContainer>
      </EditorWrapper>
      <ShareLinkModal
        publicRoute={URL_PUBLIC_COLLECTION}
        result={multiTemplatePublicLink.assignments}
        resourceGuid={multiTemplatePublicLink.origin}
        showModal={!isLoading && showShareLinkModal}
        onCloseModal={setShowShareLinkModal}
        deleteLinkFromStore={() => dispatch(deleteMultiTemplateLinkFromStore())}
      />
      <ModalContentWindow
        showModal={Boolean(showErrorModal)}
        onCloseModal={setShowErrorModal}
        titleText={SOMETHING_WENT_WRONG}
        showDefaultText={false}
      >
        <div>Please fill out all required fields.</div>
      </ModalContentWindow>
      {
        isVisibleSubtypeModal
        && (
          <EditorSubtypesModal
            userSubtypes={userSubtypesFields}
            managerSubtypes={managerSubtypesFields}
            groupedFields={{ user: userGroupedFields, manager: managerGroupedFields }}
            isVisibleModal={isVisibleSubtypeModal}
            setModalVisibility={setSubtypeModalVisibility}
            onSaveSubtypes={saveCollectionWithSubtypesHandler}
          />
        )
      }
    </>
  );
};

export default CollectionDetails;