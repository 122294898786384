import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { Element as SlateElement } from 'slate';
import { useSlate } from 'slate-react';

import {
  FIELD_TYPE,
  LIST_TYPES,
  PARAGRAPH,
  RECIPIENT_ASSIGNMENT,
  SECTION,
} from 'constants/editors';
import { useRolePermission } from 'hooks/useRolePermission';
import { INewSection, UseSortedSectionsHookType } from 'types/Editor';
import { generateNumber } from 'utils/editorFieldHelpers';
import { toShowBlock } from 'utils/routeHelpers';
import { isTopLevelTextNode } from 'utils/slateEditorUtils';

export function findField(
  node: Partial<SlateElement>,
  callback: (el: Partial<SlateElement>) => void,
  showTextNodes = false,
  parentNode: Partial<SlateElement> | null = null,
) {
  if (node.type && FIELD_TYPE.includes(node.type)) return callback(node);
  if (node?.children) {
    let isSimpleText = false;
    node.children.forEach((child: Partial<SlateElement>) => {
      if (child?.children) return findField(child, callback, showTextNodes, node);
      if (node.type && FIELD_TYPE.includes(child.type || '')) return callback(child);
      isSimpleText = true;
    });

    if (
      showTextNodes
      && isTopLevelTextNode(node.type)
      && node.children?.length
      && (isSimpleText || LIST_TYPES.includes(node.type || ''))
    ) {
      if (parentNode === null) {
        return callback(node);
      }
      if (parentNode.type === PARAGRAPH) {
        return callback(parentNode);
      }
    }
  }
}

function formParser(
  sections: SlateElement[],
  isPublicPage: boolean,
  isPublicURL: boolean,
  userRoleAssignments: string,
  isFormTypeDocument: boolean,
  currentDocAssignment: string,
) {
  if (sections && sections[0]?.type !== SECTION) return [];

  return sections.map((section: SlateElement, index: number) => {
    let currentForm: Partial<SlateElement>[] = [];

    const fields = section.children || [];
    fields.forEach((node: Partial<SlateElement>, nodeIndex: number) => {
      let currentNode = node;
      if (isFormTypeDocument && node.position === undefined) {
        currentNode = {
          ...node,
          position: nodeIndex,
          key: node.key || generateNumber(),
        };
      }
      findField(currentNode, (el) => currentForm.push(el), isFormTypeDocument);
    });

    if (isPublicPage) {
      currentForm = currentForm.filter((field) => {
        if (userRoleAssignments === RECIPIENT_ASSIGNMENT || isPublicURL) {
          return field.assignment === currentDocAssignment || !field.assignment;
        }
        return field.assignment === currentDocAssignment;
      });
    }

    return {
      key: section.key,
      position: section.position || index,
      fields: currentForm.sort((a, b) => (
        (a?.position || 0) - (b?.position || 0)
      )),
    };
  });
}

export const useSortedSections: UseSortedSectionsHookType = ({
  isFormsView = true,
  isPublicPage = false,
  isFormPage = false,
  currentDocAssignment = RECIPIENT_ASSIGNMENT,
}) => {
  const editor = useSlate();
  const { userRoleAssignments } = useRolePermission();
  const { pathname } = useLocation();
  const isPublicURL = toShowBlock(pathname, 'public');

  useEffect(() => {
    editor.formPage = isFormPage;
    editor.publicPage = isPublicPage;
    editor.sections = formParser(
      editor.children as SlateElement[],
      isPublicPage,
      isPublicURL,
      userRoleAssignments,
      isFormPage,
      currentDocAssignment,
    ) as INewSection[];
    editor.sortedSections = [...(editor.sections || [])];
    if (isFormsView) {
      editor.onChange();
    }
  }, [editor, editor.children, isPublicPage, isPublicURL, currentDocAssignment]);

  return editor.sortedSections || [];
};

export default useSortedSections;