import { FC, HTMLAttributes, ImgHTMLAttributes } from 'react';

import { Textfit } from 'react-textfit';

import { isBase64String } from 'utils/editorFieldHelpers';

interface IShowSignatureValue {
  imageValueProps?: ImgHTMLAttributes<HTMLImageElement>;
  textValueProps?: HTMLAttributes<HTMLSpanElement>;
  isPdf?: boolean;
  value?: string;
  setLocalFontSize?: React.Dispatch<React.SetStateAction<number>>;
}

const ShowSignatureValue: FC<IShowSignatureValue> = ({
  imageValueProps = {},
  textValueProps = {},
  isPdf = false,
  value = '',
  setLocalFontSize = () => null,
}) => {
  const {
    alt = 'signature',
    className = 'signature-field-image',
    ...restImageValueProps
  } = imageValueProps;

  const {
    className: textValueClassName = 'typed-signature',
    ...restTextValueProps
  } = textValueProps;

  if (isBase64String(value)) {
    return (
      <img {...restImageValueProps} src={value} alt={alt} className={className} />
    );
  }

  if (isPdf) {
    return (
      <Textfit
        mode="multi"
        className="textfit-box"
        onReady={(fontSize: number) => setLocalFontSize(fontSize)}
      >
        <span
          {...restTextValueProps}
          className={textValueClassName}
        >
          {value}
        </span>
      </Textfit>
    );
  }

  return (
    <span {...restTextValueProps} className={textValueClassName}>{value}</span>
  );
};

export default ShowSignatureValue;