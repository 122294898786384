import { SignerFlow } from 'constants/signNow';
import {
  CLEAR_SIGN_NOW_STORE,
  REMOVE_SIGN_NOW_FILE,
  SET_CURRENT_SIGNER,
  SET_CURRENT_SIGNER_FLOW_TO_STORE,
  SET_IS_REMIND_DOCUMENT_ACTIVE,
  SET_IS_REMIND_FOR_ALL_SIGNERS,
  SET_IS_SIGN_NOW_ACTIVE,
  SET_SIGN_NOW_DOCUMENT_INFO,
  SET_SIGN_NOW_FILE_URL,
  SET_SIGN_NOW_PDF_FORM_FIELDS,
  SET_SIGNERS_LIST,
} from 'store/actions/actionTypes';
import { PDFFieldType } from 'types/PdfTemplates';
import { ActionPayload } from 'types/redux';
import {
  IPDFFileInfo,
  ISignerListActionProps,
  ISignNowDocument,
} from 'types/signNow/store';

export const setFileUrl = ({ fileUrl, fileKey, pdfWidth }: IPDFFileInfo): ActionPayload<IPDFFileInfo> => ({
  type: SET_SIGN_NOW_FILE_URL,
  payload: {
    fileUrl,
    fileKey,
    pdfWidth,
  },
});

export const setSignNowPdfFormFields = (pdfFormFields: PDFFieldType[]): ActionPayload<PDFFieldType[]> => ({
  type: SET_SIGN_NOW_PDF_FORM_FIELDS,
  payload: pdfFormFields,
});

export const removeSignNowFile = () => ({
  type: REMOVE_SIGN_NOW_FILE,
});

export const setSignersList = ({ record, signers }: ISignerListActionProps): ActionPayload<ISignerListActionProps> => ({
  type: SET_SIGNERS_LIST,
  payload: {
    record,
    signers,
  },
});

export const setCurrentSignerFlowToStore = (currentSignerFlow: SignerFlow): ActionPayload<SignerFlow> => ({
  type: SET_CURRENT_SIGNER_FLOW_TO_STORE,
  payload: currentSignerFlow,
});

export const setCurrentSigner = (
  currentSigner: string,
): ActionPayload<string> => ({
  type: SET_CURRENT_SIGNER,
  payload: currentSigner,
});

export const setIsSignNowActive = () => ({
  type: SET_IS_SIGN_NOW_ACTIVE,
});

export const clearSignNowStore = (isClearFullStore: boolean = true): ActionPayload<boolean> => ({
  type: CLEAR_SIGN_NOW_STORE,
  payload: isClearFullStore,
});

export const setIsRemindDocumentActive = () => ({
  type: SET_IS_REMIND_DOCUMENT_ACTIVE,
});

export const setIsRemindForAllSigners = (payload: boolean): ActionPayload<boolean> => ({
  type: SET_IS_REMIND_FOR_ALL_SIGNERS,
  payload,
});

export const setSignNowDocumentInfo = (
  documentInfo: Partial<ISignNowDocument>,
): ActionPayload<Partial<ISignNowDocument>> => ({
  type: SET_SIGN_NOW_DOCUMENT_INFO,
  payload: documentInfo,
});