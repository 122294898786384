import { AggregatedFieldsStructureType, UpdatePublicPageFieldsEntriesType } from 'types/PublicPage';

const getGroupedFieldsEntriesData = (
  groupedFieldsStructure: AggregatedFieldsStructureType[],
): UpdatePublicPageFieldsEntriesType => (
  groupedFieldsStructure
    .reduce((entries: UpdatePublicPageFieldsEntriesType, field) => {
      const result: UpdatePublicPageFieldsEntriesType = {};
      field.keys.forEach((key) => {
        result[key] = field.value;
      });
      return {
        ...entries,
        ...result,
      };
    }, {})
);

export default getGroupedFieldsEntriesData;