import { FC } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Outline from 'components/Sidebar/Outline';
import SortableItem from 'components/Sidebar/SortableItem';
import { createTemplateSection, sortFormDocumentSections, updateTemplateSections } from 'store/actions/template';
import { RootStateType } from 'store/reducers';
import { ITemplateSection } from 'types/redux';
import { generateNumber } from 'utils/editorFieldHelpers';

const SectionsOutline: FC<{ setSortMode?: boolean }> = ({ setSortMode = false }) => {
  const dispatch = useDispatch();
  const { sections } = useSelector((state: RootStateType) => state.template);

  const onSortEnd = (sortedNavList: ITemplateSection[]) => {
    dispatch(updateTemplateSections(sortedNavList));
    if (setSortMode) {
      dispatch(sortFormDocumentSections(true));
    }
  };

  const createSidebarSectionHandler = () => {
    const sectionWithoutName = sections.filter((section: ITemplateSection) => section.name === '') || [];
    if (!sectionWithoutName.length) {
      dispatch(createTemplateSection({
        name: '',
        key: generateNumber(),
        position: sections.length,
      }));
    }
  };

  return (
    <Outline
      elements={sections}
      createElementHandler={createSidebarSectionHandler}
      onSortEndHandler={onSortEnd}
    >
      {
        sections.map((element: ITemplateSection, index: number) => (
          <SortableItem
            key={element.key}
            id={Number(element.key)}
            index={index}
            value={element.name}
            sectionLength={sections.length}
          />
        ))
      }
    </Outline>
  );
};

export default SectionsOutline;