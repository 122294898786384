import { FC, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { SLIDER_SCROLLABLE_ID } from 'constants/general';
import { RootStateType } from 'store/reducers';
import formSwiperAutoScroll from 'utils/PublicPage/swiperAutoScroll';

import 'style/_slider.scss';

interface ISlider {
  children: JSX.Element[] | undefined;
  isSliderDisabled: boolean;
}

const Slider: FC<ISlider> = ({
  children,
  isSliderDisabled,
}) => {
  const { currentSlideIndex } = useSelector((state: RootStateType) => state.publicPages.currentDocument);

  useEffect(() => {
    formSwiperAutoScroll(SLIDER_SCROLLABLE_ID);
  }, [currentSlideIndex]);

  if (!children) {
    return null;
  }

  if (isSliderDisabled) {
    return (
      <div>
        {children}
      </div>
    );
  }

  return (
    <div
      id={SLIDER_SCROLLABLE_ID}
      className="slider"
      data-scroll-wrapper=""
    >
      {children[currentSlideIndex - 1]}
    </div>
  );
};

export default Slider;