import { AggregatedFieldsStructureType } from 'types/PublicPage';
import { PIIDataType } from 'types/userProfile';
import { findPIIMatch } from 'utils/PIIAndGrouping/groupedAndPIIFieldsHelper';

export const updatePIIFieldData = (
  groupedFieldsStructure: AggregatedFieldsStructureType[],
  piiUserData: PIIDataType[],
  filterName: string,
  label: string,
) => {
  const aggregatedFieldByFilter = groupedFieldsStructure.find((item) => (
    item.name === filterName
  ));

  if (!aggregatedFieldByFilter) return piiUserData;

  const filterFieldTarget: PIIDataType[] = findPIIMatch(
    aggregatedFieldByFilter as AggregatedFieldsStructureType,
    piiUserData,
  );

  return piiUserData.map((field) => {
    if (
      aggregatedFieldByFilter
      && filterFieldTarget.length
      && filterFieldTarget[0].groupByKey === field.groupByKey
      && filterFieldTarget[0].groupBy === field.groupBy
      && filterFieldTarget[0].filterName === field.filterName
      && filterFieldTarget[0].subtype === field.subtype
    ) {
      return {
        ...field,
        value: label,
      };
    }
    const fieldMatch = findPIIMatch(field as AggregatedFieldsStructureType, piiUserData);
    const currentField = fieldMatch.find((item) => item.groupByKey === label);
    if (field.filterName === filterName) {
      return {
        ...field,
        value: currentField ? currentField.value : '',
        ...(currentField && currentField.groupByKey && { groupByKey: currentField.groupByKey }),
      };
    }
    return field;
  });
};

export default undefined;