import { FC, SVGAttributes } from 'react';

const ArrowRightIcon: FC<SVGAttributes<any>> = ({ className, fill = '#FFFFFF' }) => (
  <svg
    className={className}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.5 5L14.09 6.41L18.67 11H2.5V13H18.67L14.08 17.59L15.5 19L22.5 12L15.5 5Z" fill={fill} />
  </svg>
);

export default ArrowRightIcon;