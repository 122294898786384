import { FC, SyntheticEvent } from 'react';

import { useDispatch } from 'react-redux';

import { useSlate } from 'slate-react';

import { changeSelectedField } from 'store/actions/editorSlate';
import { IBlockButtonProps } from 'types/Editor';
import { isBlockActive } from 'utils/editorBlockHelpers';
import { removeSlateSelectedFieldError } from 'utils/editorFieldHelpers';
import { toggleSelectField } from 'utils/editorSelectFieldHelpers';

const SelectFieldButton: FC<IBlockButtonProps> = ({ format, icon }) => {
  const editor = useSlate();
  const active: boolean = isBlockActive(editor, format);
  const dispatch = useDispatch();

  const onMouseDownButton = (event: SyntheticEvent) => {
    event.preventDefault();
    removeSlateSelectedFieldError(editor, format, active, dispatch);
    const selectFieldKey = toggleSelectField(editor, format, []);

    dispatch(changeSelectedField(selectFieldKey));
  };

  return (
    <span
      className={`btn-toolbar-editor ${active && 'text-dark'} horizontal-toolbar-icon`}
      onMouseDown={onMouseDownButton}
      role="button"
      tabIndex={-1}
    >
      {icon}
    </span>
  );
};

export default SelectFieldButton;