import { ScrollbarProps, Scrollbars } from 'react-custom-scrollbars-2';

interface ICustomScrollbar {
  children: React.ReactNode;
}

const CustomScrollbar = ({
  children,
  style,
  ...props
}: ScrollbarProps & ICustomScrollbar) => (
  <Scrollbars {...props} style={style}>
    {children}
  </Scrollbars>
);

export default CustomScrollbar;