/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'react-toastify';

import { AxiosError } from 'axios';

import { SUCCESS } from 'constants/api';
import { PLEASE_RE_LOGIN } from 'constants/general';
import {
  ACCEPTED_CODE,
  BAD_REQUEST_CODE,
  NOT_FOUND_CODE,
  SERVER_ERROR_CODE,
  SUCCESS_CODE,
  UNAUTHORIZED_CODE,
  UNPROCESSABLE_ENTITY_CODE,
} from 'constants/generalErrors';
import ROUTES from 'constants/routes';
import { logout, refreshToken } from 'services/api';
import { history } from 'services/history';
import requestHandler from 'services/requestHandler';
import { IResponseOfRequest } from 'types/Api';
import { ILoginResponse } from 'types/Auth';
import { BaseRequestType, BaseResponseType } from 'types/RequestHandler';
import { ACCESS_TOKEN } from 'utils/axiosInstance';
import createErrorMessage from 'utils/errorsHelpers';
import { deleteAuthInStorage, setTokensToLocalStorage } from 'utils/localStorage';

const tryRefreshToken = async () => {
  localStorage.removeItem(ACCESS_TOKEN);
  const response: IResponseOfRequest<ILoginResponse> = await refreshToken();
  if (response && response.status === SUCCESS_CODE) {
    setTokensToLocalStorage(response.data);
    return true;
  }
  if (response && response.status === ACCEPTED_CODE) {
    return true;
  }
  if (response && response.status === UNAUTHORIZED_CODE) {
    /*
    If refreshToken gets unauthorized error, it means that someone has already used refresh token.
    So a user must re-login.
    */
    deleteAuthInStorage();
    await logout();
    return false;
  }
};

export async function apiErrorHandler(func: any, ...params: any[]): Promise<any>;
export async function apiErrorHandler<R, P = void, E = AxiosError>(
  func: BaseRequestType<R, P>,
  ...params: P[]
): Promise<BaseResponseType<R, E>>;
export async function apiErrorHandler<Response = any, Params = any, Error = AxiosError>(
  func: any,
  ...params: any[]
): Promise<any> {
  const returnErrorResult = params[0]?.returnErrorResult || false;
  if (params && returnErrorResult) {
    // eslint-disable-next-line no-param-reassign
    delete params[0].returnErrorResult;
  }

  const response = await requestHandler<Response, Params, Error>(func)(...params);

  if (response.code === SUCCESS) {
    return response;
  }

  switch (response.status) {
    /*
    UNPROCESSABLE_ENTITY_CODE means that access token is corrupted or there isn't token at all.
    For example if a user exits from system in another tab.
    Anyway, a user needs to relogin in the system because of incorrect token format (or token doesn't exist).
    */
    case UNPROCESSABLE_ENTITY_CODE:
    case UNAUTHORIZED_CODE: {
      const refreshTokenResult = await tryRefreshToken();
      if (refreshTokenResult) {
        await apiErrorHandler(func, ...params);
      }
      toast.warning(PLEASE_RE_LOGIN);
      history.push(ROUTES.LOGIN);
      return response;
    }
    case BAD_REQUEST_CODE:
      toast.warning(createErrorMessage(response));
      return response;
    case NOT_FOUND_CODE:
    case SERVER_ERROR_CODE:
      if (returnErrorResult) {
        return response;
      }
      toast.error(createErrorMessage(response));
      return response;
    default:
      // We can do something in this case
      console.log(response);
      toast.error(createErrorMessage(response));
      return response;
  }
}

export default null;