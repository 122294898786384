import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from 'components/Base/Button';
import Intro from 'components/Intro/Intro';
import SignerOnFinalPage from 'components/SendDocuments/SignerOnFinalPage';
import ROUTES from 'constants/routes';
import { SignerFlow } from 'constants/signNow';
import { RootStateType } from 'store/reducers';
import sendDocumentsFinalLogo from 'svg/sendDocumentsFinalLogo.png';
import { SignersInfoType } from 'types/SendDocuments';
import { getManagerLink } from 'utils/sendDocumentsHelpers';
import { getManagerPositionFromSignersInfo } from 'utils/signNow/assignmentsHelper';

const SendDocumentsFinal = () => {
  const history = useHistory();
  const managerEmail = useSelector((state: RootStateType) => state.profile.profileInfo.email);
  const {
    initialLocation,
    signersInfo,
    executedDocId,
  } = useSelector((state: RootStateType) => state.sendDocuments);
  const {
    isSignNow,
    currentFlow,
    isRemindDocumentActive,
    isRemindForAllSigners,
  } = useSelector((state: RootStateType) => state.signNow);

  const handlerCloseButton = () => {
    if (isSignNow && signersInfo.length) {
      const managerPosition = getManagerPositionFromSignersInfo(signersInfo, managerEmail);
      if ((currentFlow === SignerFlow.NoOrder && managerPosition !== null) || managerPosition === 0) {
        return history.push(getManagerLink(executedDocId));
      }
      return history.push(initialLocation || ROUTES.DASHBOARD);
    }
    if (executedDocId) {
      history.push(getManagerLink(executedDocId));
    } else {
      history.push(initialLocation || ROUTES.DASHBOARD);
    }
  };

  return (
    <Intro
      logoSource={sendDocumentsFinalLogo}
      title="Congratulations!"
      description="You have sucessfully sent the document(s) to"
      descriptionWidth="large"
      styles={{ logo: { width: '115px', height: '157px' } }}
    >
      <div className="send-documents-final">
        <div className="mb-4 pt-2">
          {
            signersInfo.map((signer: SignersInfoType, index: number) => (
              (isSignNow && isRemindDocumentActive && !isRemindForAllSigners && signer.signed)
                ? null
                : (
                  <SignerOnFinalPage
                    key={`SignerOnFinalPage${index + 1}`}
                    signer={signer}
                  />
                )
            ))
          }
        </div>
        <div className="d-flex justify-content-center">
          <Button
            classes="button-contained-pink"
            onClick={handlerCloseButton}
          >
            Go to document{executedDocId ? '' : 's'}
          </Button>
        </div>
      </div>
    </Intro>
  );
};

export default SendDocumentsFinal;