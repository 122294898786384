import { Dispatch, FC, SetStateAction } from 'react';

import {
  documentNavigationConfig,
  DocumentNavigationTitles,
  IDocumentNavigationConfig,
} from 'constants/documentsNavigation';
import { DocumentStatusType } from 'types/Documents';

interface IDocumentNavigation {
  activeTab: DocumentStatusType;
  setActiveTab: Dispatch<SetStateAction<DocumentStatusType>>;
  isDashboard?: boolean;
  firstTabTitle?: DocumentNavigationTitles;
  isUserReadOnly?: boolean;
}

const DocumentNavigation: FC<IDocumentNavigation> = ({
  activeTab,
  setActiveTab,
  isDashboard = false,
  firstTabTitle = DocumentNavigationTitles.ALL_DOCUMENTS,
  isUserReadOnly = false,
}: IDocumentNavigation) => (
  <div className="document-navigation">
    <div className="container px-0">
      <div className={`row ${isDashboard ? 'w-100 mx-auto' : 'w-75 mx-auto justify-content-center'}`}>
        <div className="col-12 px-0 d-flex document-navigation-container">
          <div className="col-12 d-flex justify-content-between justify-content-md-start overflow-auto w-100">
            {documentNavigationConfig.map(({
              title, statuses,
            }: IDocumentNavigationConfig, index: number) => {
              if (
                isUserReadOnly && (
                  [DocumentNavigationTitles.ALL_DOCUMENTS, DocumentNavigationTitles.DRAFTS].includes(title)
                )
              ) {
                return null;
              }
              return (
                <button
                  key={title}
                  type="button"
                  className={`document-navigation-container_link ${activeTab === statuses ? 'active-tab' : ''}`}
                  onClick={() => setActiveTab(statuses)}
                >
                  {
                    (index === 0 && title !== firstTabTitle) ? firstTabTitle : title
                  }
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default DocumentNavigation;