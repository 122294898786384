import { Element as SlateElement, Node } from 'slate';

import { ListType, withLists } from 'components/Editor/editor-custom-plugins/lists';
import { BULLETED_LIST, HEADERS_MARKS, NUMBERED_LIST } from 'constants/editors';

export type ListFormatType = typeof NUMBERED_LIST | typeof BULLETED_LIST;

enum NodeType {
  PARAGRAPH = 'paragraph',
  ORDERED_LIST = 'numbered-list',
  UNORDERED_LIST = 'bulleted-list',
  LIST_ITEM = 'list-item',
  NUMBERED_LIST_ITEM = 'numbered-list-item',
  LIST_ITEM_TEXT = 'list-item-text',
}

const isElementType = (
  element: any,
  elementParamVal: string,
  elementParamKey: string = 'type',
) => {
  const param = elementParamKey in element && element[elementParamKey];
  return SlateElement.isElement(element) && param === elementParamVal;
};

export const withListsPlugin = withLists({
  isConvertibleToListTextNode(node: Node) {
    return isElementType(node, NodeType.PARAGRAPH);
  },
  isConvertibleToListBlockNode(node: Node) {
    return SlateElement.isElement(node) && HEADERS_MARKS.includes(node.type);
  },
  isDefaultTextNode(node: Node) {
    return isElementType(node, NodeType.PARAGRAPH);
  },
  isListNode(node: Node, type?: ListType) {
    if (type) {
      return isElementType(node, type);
    }
    return (
      isElementType(node, NodeType.ORDERED_LIST) || isElementType(node, NodeType.UNORDERED_LIST)
    );
  },
  isListItemNode(node: Node) {
    return isElementType(node, NodeType.LIST_ITEM) || isElementType(node, NodeType.NUMBERED_LIST_ITEM);
  },
  isListItemTextNode(node: Node) {
    return isElementType(node, NodeType.LIST_ITEM_TEXT);
  },
  createDefaultTextNode(props = {}) {
    return { children: [{ text: '' }], ...props, type: NodeType.PARAGRAPH };
  },
  createListNode(type: ListType = ListType.UNORDERED, props = {}) {
    const nodeType = type === ListType.ORDERED ? NodeType.ORDERED_LIST : NodeType.UNORDERED_LIST;
    return { children: [{ text: '' }], ...props, type: nodeType };
  },
  createListItemNode(props = {}) {
    const nodeType = props?.type === NodeType.NUMBERED_LIST_ITEM ? NodeType.NUMBERED_LIST_ITEM : NodeType.LIST_ITEM;
    return { children: [{ text: '' }], ...props, type: nodeType };
  },
  createListItemTextNode(props = {}) {
    return { children: [{ text: '' }], ...props, type: NodeType.LIST_ITEM_TEXT };
  },
});