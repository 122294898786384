import SimpleSpinner from 'components/Base/SimpleSpinner';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import PaymentItem from 'components/Payment/Stripe/PaymentMethods/PaymentItem';
import { usePaymentMethods } from 'hooks/payment/stripe';

const StripePaymentMethods = () => {
  const {
    paymentMethods,
    isLoading,
    isMethodUpdating,
    isConfirmModalOpen,
    onChangeDefaultPaymentMethod,
    onDeletePaymentMethod,
    onCancelDeleteModalHandler,
    onConfirmDeleteModalHandler,
  } = usePaymentMethods();

  if (isLoading) {
    return <SimpleSpinner />;
  }

  if (paymentMethods.length === 0) {
    return <div className="d-flex justify-content-center m-4">Payment methods are empty</div>;
  }

  return (
    <div className="stripe-payment-methods">
      <ul className="stripe-payment-methods__list">
        {
          paymentMethods.map((paymentMethod) => (
            <PaymentItem
              key={paymentMethod.id}
              isMethodUpdating={isMethodUpdating}
              payment={paymentMethod}
              onDelete={onDeletePaymentMethod}
              onMakeDefault={onChangeDefaultPaymentMethod}
            />
          ))
        }
      </ul>

      <ConfirmationModal
        description={(
          <>
            <p>Attention, your payment method will be deleted immediately.</p>
            <p>
              Do you agree to delete it?
            </p>
          </>
        )}
        isOpen={isConfirmModalOpen}
        onCancel={onCancelDeleteModalHandler}
        onConfirm={onConfirmDeleteModalHandler}
      />
    </div>
  );
};

export default StripePaymentMethods;