import { ChangeEvent, FC, useEffect, useState } from 'react';

import { Form, FormFeedback, Input } from 'reactstrap';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import Button from 'components/Base/Button';
import { CANCEL, HIDDEN_PASSWORD, UPDATE } from 'constants/general';
import { PROFILE_SECTIONS } from 'constants/UserMenu';
import { ISettingsField } from 'types/AccountSettings';

interface IAccountSettingsProps {
  title: PROFILE_SECTIONS;
  fields: ISettingsField[],
  cancelClickHandler: () => void;
  error?: string;
  updateClickHandler?: () => boolean;
  isEditable?: boolean;
  handleChangeData?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSelectData?: (event: ChangeEvent<HTMLSelectElement>) => void;
  canEditFields?: boolean;
}

const AccountSettings: FC<IAccountSettingsProps> = ({
  title,
  updateClickHandler,
  cancelClickHandler,
  fields,
  handleChangeData,
  handleSelectData,
  error = '',
  isEditable = false,
  canEditFields = true,
}) => {
  const [editable, setEditable] = useState<boolean>(false);

  useEffect(() => {
    setEditable(isEditable);
  }, [isEditable]);

  const onClickUpdateHandler = () => {
    const isUpdated = updateClickHandler && updateClickHandler();
    if (isUpdated) {
      if (!error && title === PROFILE_SECTIONS.CHANGE_PASSWORD) {
        cancelClickHandler();
      }
      setEditable(false);
    }
  };

  const onClickCancelHandler = () => {
    cancelClickHandler();
    setEditable(false);
  };

  return (
    <div className="account-settings">
      <div className="account-settings__header">
        <h2 className="account-settings__title">{title}</h2>
        {
          (canEditFields && !editable) && (
            <button
              type="button"
              className="account-settings__edit-button"
              onClick={() => setEditable(true)}
            >
              Edit
            </button>
          )
        }
      </div>
      {
        editable
          ? (
            <Form>
              {
                fields.map(({
                  label,
                  value,
                  fieldError,
                  name,
                  options,
                  disabled,
                  type,
                  onChangeHandler,
                }) => (
                  <div key={name} className="row mb-3 pb-3 d-flex align-items-center account-settings__row">
                    <label className="col-12 col-lg-3 account-settings__label" htmlFor={name}>{label}</label>
                    <div className="col-12 col-lg">
                      {
                        options?.length
                          ? (
                            <select
                              value={value}
                              name={name}
                              id={name}
                              className="account-settings__text account-settings__input form-control custom-select"
                              onChange={(event) => handleSelectData && handleSelectData(event)}
                            >
                              {
                                options.map((option) => (
                                  <option id={option.id} key={option.id}>{option.name}</option>
                                ))
                              }
                            </select>
                          )
                          : (
                            <Input
                              id={name}
                              className="account-settings__text account-settings__input"
                              value={value}
                              type={type}
                              name={name}
                              disabled={disabled}
                              onChange={(event) => (onChangeHandler
                                ? onChangeHandler(event)
                                : handleChangeData && handleChangeData(event))}
                              required
                              invalid={!!fieldError}
                            />
                          )
                      }
                      <FormFeedback>{fieldError}</FormFeedback>
                    </div>
                  </div>
                ))
              }
              {
                error && (
                  <div className="account-settings__error">
                    <ErrorOutlineOutlinedIcon color="error" />
                    <span className="ms-2">{error}</span>
                  </div>
                )
              }
              <div className="account-settings__buttons">
                <Button classes="me-3" onClick={onClickUpdateHandler}>
                  {UPDATE}
                </Button>
                <Button classes="outline" onClick={onClickCancelHandler}>
                  {CANCEL}
                </Button>
              </div>
            </Form>
          )
          : (
            <div>
              {
                fields.map(({
                  label,
                  value,
                  type,
                  name,
                  hiddenForRead,
                }) => (
                  !hiddenForRead && (
                    <div key={name} className="row mb-3 pb-3 d-flex align-items-center account-settings__row">
                      <div className="col-12 col-lg-3 account-settings__label">{label}</div>
                      <div className="col-12 col-lg">
                        <div className="account-settings__text">
                          {type === 'password' ? HIDDEN_PASSWORD : value}
                        </div>
                      </div>
                    </div>
                  )
                ))
              }
            </div>
          )
      }
    </div>
  );
};

export default AccountSettings;