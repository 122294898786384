import { Dispatch as ReactDispatch, FC, SetStateAction, useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import useShareLInkInMUITable from 'hooks/muiTable/useShareLInkInMUITablets';
import { MUIDataTableColumn, MUIDataTableColumnDef } from 'mui-datatables';

import KebabMenu, { HandlersValueType } from 'components/KebabMenu';
import ModalWindow from 'components/Modals';
import CompleteDocumentModal from 'components/Modals/CompleteDocumentModal';
import ShareLinkModal from 'components/Modals/ModalWrapper';
import MUITable from 'components/MUITable';
import { ALL_STATUSES, READ_ONLY_STATUSES, STATUSES } from 'constants/documentStatuses';
import { MANAGER_ASSIGNMENT } from 'constants/editors';
import {
  COLLECTION_EXECUTED_TYPE,
  COLLECTION_TYPE,
  DOCUMENT_EXECUTED_TYPE,
  DOCUMENT_TYPE,
  FORM_TYPE,
} from 'constants/general';
import { DOCUMENTS_KEBAB_MENU, setKebabColumnHeading, TEMPLATES_KEBAB_MENU } from 'constants/KebabMenus';
import ROUTES from 'constants/routes';
import { SharingResources } from 'constants/sendDocuments';
import { useRolePermission } from 'hooks/useRolePermission';
import { deleteMultiTemplateStart } from 'store/actions/collections';
import { createCollectionExecutedPublicLink, updateCollectionExecutedDetails } from 'store/actions/collectionsExecuted';
import {
  createDocumentPublicLink,
  createMultiTemplatePublicLink,
  createTemplatePublicLink,
  deleteCurrentIdFromData,
} from 'store/actions/publicPages';
import {
  copyTemplate,
  deleteDocuments,
  deleteTemplates,
  editDocumentDetails,
  editTemplate,
  getDocumentDetails,
  setDocumentDetails,
  setTemplateDetails,
} from 'store/actions/userData';
import { RootStateType } from 'store/reducers';
import { DocumentStatusType, ICreateDocumentRequestObj, IDocumentDetails } from 'types/Documents';
import { ListingViewsTabs, TableRowsType } from 'types/Mui';
import { ICollectionDetails, ICollectionTemplate, ITemplateForMultiTemplate } from 'types/MultiTemplate';
import { getUnitedAssignmentsArray } from 'utils/collectionsHelpers';
import {
  COLUMN_DOCUMENT_CATEGORY,
  dataColumns,
  dataColumnsCollectionExecuted,
  dataColumnsExecuted,
} from 'utils/dataColumns';
import { addHTMLandCSSifCompleted } from 'utils/fileLinkDownload';
import { getIsPDFDocument } from 'utils/PublicPage/documentTypeChecker';
import { isCollectionPagePath, isDocumentListingPage, isFormListingPage } from 'utils/routeHelpers';
import { isRequiredFieldArrayHasError } from 'utils/validation';

interface IGeneralMUITableWrapper {
  tableRows: TableRowsType;
  isAllDocumentsTab?: boolean;
  currentTableTab?: ListingViewsTabs;
  setActiveTab?: ReactDispatch<SetStateAction<DocumentStatusType>>;
  activeStatuses?: DocumentStatusType;
}

const GeneralMUITableWrapper: FC<IGeneralMUITableWrapper> = ({
  tableRows,
  isAllDocumentsTab = true,
  currentTableTab = ListingViewsTabs.DASHBOARD,
  setActiveTab = undefined,
  activeStatuses = undefined,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const isFormListing = isFormListingPage(location.pathname);
  const isDocumentListing = isDocumentListingPage(location.pathname);
  const convertWayText = isFormListing ? 'Form into a Document' : 'Document into a Form';

  const {
    documents,
    documentsExecuted,
    documentDetails,
    collections,
    collectionsExecuted,
  } = useSelector((state: RootStateType) => state.user);
  const { isLoading } = useSelector((state: RootStateType) => state.errorLoading);
  const {
    permissionUsingSectionsLight,
    permissionUsingForms,
    permissionUsingEmbed,
    permissionUsingShareLink,
  } = useRolePermission();

  const [resetSelectedRows, setResetSelectedRows] = useState<boolean>(false);
  const [showCompleteDocumentModal, setCompleteDocumentModal] = useState<boolean>(false);
  const [getCompleteDocumentId, setCompleteDocumentId] = useState<string>('');
  const [completedMode, setCompletedMode] = useState<boolean>(false);
  const [shareLinkResource, setShareLinkResource] = useState<SharingResources>(SharingResources.EMPTY_STRING);
  const [convertDocumentId, setConvertDocumentId] = useState<number>(0);
  const [templateNameForFilter, setTemplateNameForFilter] = useState<string | undefined>(undefined);

  const isCollectionPage = isCollectionPagePath(location.pathname);

  const {
    publicRoute,
    result,
    resourceGuid,
    showShareLinkModal,
    setShowShareLinkModal,
  } = useShareLInkInMUITable(shareLinkResource);

  const onCopyTemplate = (kebabValue: HandlersValueType): void => {
    dispatch(copyTemplate(kebabValue.id, permissionUsingSectionsLight));
  };

  const onCloseModal = () => {
    if (showShareLinkModal) {
      setShowShareLinkModal(false);
      setShareLinkResource(SharingResources.EMPTY_STRING);
      return;
    }
    if (showCompleteDocumentModal) {
      setCompleteDocumentModal(false);
      dispatch(setDocumentDetails(null));
      setTemplateDetails(null);
    }
  };

  // Sharing actions
  const onShareLinkDocumentExecuted = (kebabValue: HandlersValueType) => {
    const itemId = Number(kebabValue.id);
    if (kebabValue.type === COLLECTION_EXECUTED_TYPE) {
      /**
       * Every collection executed includes some documents. It's necessary to find them,
       * gather their assignments and create united assignments object.
       * We can create collection share links using it.
       */
      const currentCollection = collectionsExecuted.find((item: any) => item.id === itemId);
      const documentsInCollection: any[] = [];
      currentCollection.documents.forEach((docObject: any) => {
        documentsInCollection.push(docObject.document);
      });
      dispatch(createCollectionExecutedPublicLink({
        collection_executed_id: itemId,
        assignments: getUnitedAssignmentsArray(documentsInCollection),
      }));
      setShareLinkResource(SharingResources.COLLECTION_EXECUTED);
    } else {
      const documentExecuted: IDocumentDetails | undefined = documentsExecuted.find(
        (element: IDocumentDetails) => element.id === itemId,
      );
      setCompletedMode(documentExecuted?.status === STATUSES.completed);
      dispatch(createDocumentPublicLink({
        document_id: itemId,
        assignments: documentExecuted?.assignments ? documentExecuted.assignments : {},
      }));
      setShareLinkResource(SharingResources.DOCUMENTS_EXECUTED);
    }
    setShowShareLinkModal(true);
  };

  const onShareLinkDocument = (kebabValue: HandlersValueType) => {
    const itemId = Number(kebabValue.id);
    if (kebabValue.type === COLLECTION_TYPE) {
      /**
       * Every collection includes some templates. It's necessary to find them,
       * gather their assignments and create united assignments object.
       * We can create collection share links using it.
       */
      const currentCollection = collections.find((item: ICollectionDetails) => item.id === itemId);
      const documentsInCollection: Partial<ITemplateForMultiTemplate>[] = [];
      currentCollection.templates.forEach((template: ICollectionTemplate) => {
        const docInCollection = documents.find((doc: IDocumentDetails) => doc.id === template.template_id);
        if (docInCollection) {
          documentsInCollection.push(docInCollection);
        }
      });
      dispatch(createMultiTemplatePublicLink({
        multi_template_id: itemId,
        assignments: getUnitedAssignmentsArray(documentsInCollection),
      }));
      setShareLinkResource(SharingResources.COLLECTION);
    } else {
      const currentDoc = documents.find((doc: IDocumentDetails) => doc.id === itemId);
      dispatch(createTemplatePublicLink({
        template_id: itemId,
        assignments: currentDoc?.assignments ? currentDoc.assignments : {},
      }));
      setShareLinkResource(SharingResources.DOCUMENT);
    }
    setShowShareLinkModal(true);
  };

  // Deleting items
  const onDeleteItems = (valuesArray: HandlersValueType[]) => {
    const collectionIds: number[] = [];
    const collectionExecutedIds: number[] = [];
    const otherIds: number[] = [];
    valuesArray.forEach((item: HandlersValueType) => {
      switch (item.type) {
        case COLLECTION_TYPE:
          collectionIds.push(Number(item.id));
          break;
        case COLLECTION_EXECUTED_TYPE:
          collectionExecutedIds.push(Number(item.id));
          break;
        default:
          otherIds.push(Number(item.id));
          break;
      }
    });
    if (collectionIds.length) {
      dispatch(deleteMultiTemplateStart(collectionIds, COLLECTION_TYPE));
    }
    if (collectionExecutedIds.length) {
      dispatch(deleteMultiTemplateStart(collectionExecutedIds, COLLECTION_EXECUTED_TYPE));
    }
    if (otherIds.length) {
      dispatch(isAllDocumentsTab ? deleteTemplates(otherIds) : deleteDocuments(otherIds));
    }
  };

  const onDeleteDocument = (kebabValue: HandlersValueType) => {
    if (kebabValue.type === COLLECTION_TYPE) {
      dispatch(deleteMultiTemplateStart([Number(kebabValue.id)], COLLECTION_TYPE));
    } else {
      dispatch(deleteTemplates([Number(kebabValue.id)]));
    }
    setResetSelectedRows(true);
  };

  const onDeleteDocumentExecuted = (kebabValue: HandlersValueType) => {
    if (kebabValue.type === COLLECTION_EXECUTED_TYPE) {
      dispatch(deleteMultiTemplateStart([Number(kebabValue.id)], COLLECTION_EXECUTED_TYPE));
    } else {
      dispatch(deleteDocuments([Number(kebabValue.id)]));
    }
    setResetSelectedRows(true);
  };

  const areIncorrectRequiredFields = (isPDF: boolean) => {
    // validate required manager fields and deny completing the document
    const hasError = isRequiredFieldArrayHasError({
      contentData: documentDetails?.content_json,
      assignment: MANAGER_ASSIGNMENT,
      isPDF,
    });
    if (hasError) {
      toast.error('There are empty required fields. You cannot complete this document until they are filled in.');
      return true;
    }

    // validate resipient fields and just show a warning
    const hasResipientError = isRequiredFieldArrayHasError({
      contentData: documentDetails?.content_json,
      isPDF,
    });
    if (hasResipientError) {
      toast.warning('There are empty recipient required fields.');
    }
    return false;
  };

  // Changing statuses
  const onChangeDocumentStatus = async (
    itemId: string,
    status: string,
    completeFlow: boolean = false,
    type = DOCUMENT_EXECUTED_TYPE,
  ) => {
    if (type === DOCUMENT_EXECUTED_TYPE) {
      const documentExecutedObj = documentsExecuted.find((doc: IDocumentDetails) => doc.id === parseInt(itemId));
      if (documentExecutedObj) {
        let editedDocumentObj: ICreateDocumentRequestObj = {
          name: documentExecutedObj.name,
          status,
        };

        if (status === STATUSES.completed) {
          const isPdf = getIsPDFDocument(documentExecutedObj.type);
          if (areIncorrectRequiredFields(isPdf)) {
            return null;
          }

          editedDocumentObj = await addHTMLandCSSifCompleted(editedDocumentObj, isPdf);
          if (isPdf) {
            editedDocumentObj.pdf_fields = Object.values(documentDetails.content_json);
          }
        }
        dispatch(editDocumentDetails({
          id: String(itemId),
          document: editedDocumentObj,
          completeFlow,
          completeDocModalWindow: true,
        }));
      }
    } else if (type === COLLECTION_EXECUTED_TYPE) {
      const targetCollectionExecuted = collectionsExecuted.find(
        (collection: any) => collection.id === parseInt(itemId),
      );
      dispatch(updateCollectionExecutedDetails({
        ...targetCollectionExecuted,
        status,
      }));
    }
  };

  const onCancelAction = (kebabValue: HandlersValueType) => {
    onChangeDocumentStatus(kebabValue.id, STATUSES.cancelled, false, kebabValue.type);
  };

  const onCompleteAction = (kebabValue: HandlersValueType) => {
    dispatch(getDocumentDetails(kebabValue.id));
    setCompleteDocumentId(kebabValue.id);
    setCompleteDocumentModal(true);
  };

  const onSetInProgress = (kebabValue: HandlersValueType) => {
    onChangeDocumentStatus(kebabValue.id, STATUSES.inProgress, false, kebabValue.type);
  };

  const onFillSign = (kebabValue: HandlersValueType) => {
    /* If there is document id in the store and document was updated,
    data on fill&sign page won't be updated without next line */
    dispatch(deleteCurrentIdFromData());
    history.push(`/manager-${kebabValue.status ? 'document' : 'template'}/${kebabValue.id}`);
  };

  const onConvertTemplateToForm = (kebabValue: HandlersValueType) => {
    const currentDocumentId = kebabValue?.id;
    if (!currentDocumentId) return;
    setConvertDocumentId(parseInt(currentDocumentId));
  };

  const onCloseConvertDocumentModal = () => {
    setConvertDocumentId(0);
  };

  const onConfirmConvertDocument = useCallback(() => {
    if (!convertDocumentId) return;
    const currentTemplateData = documents?.find((doc: IDocumentDetails) => doc.id === convertDocumentId);
    if (currentTemplateData) {
      const toType = isFormListing ? DOCUMENT_TYPE : FORM_TYPE;
      const toRoute = isFormListing ? ROUTES.DOCUMENTS_EDITOR : ROUTES.FORM_EDITOR;
      dispatch(editTemplate({
        id: convertDocumentId,
        template: { name: currentTemplateData.name, type: toType },
        path: `${toRoute}/${convertDocumentId}`,
      }));
    }
    setConvertDocumentId(0);
  }, [convertDocumentId, isFormListing, documents]);

  const filterTemplatesKebabMenuByType = (documentType: string) => {
    const documentKebabExcludes: string[] = [];

    switch (documentType) {
      case DOCUMENT_TYPE:
        if (!permissionUsingForms) {
          documentKebabExcludes.push('onConvertToForm');
        }
        documentKebabExcludes.push('onConvertToDocument');
        break;
      case FORM_TYPE:
        documentKebabExcludes.push('onConvertToForm');
        break;
      default:
        documentKebabExcludes.push('onConvertToDocument');
        documentKebabExcludes.push('onConvertToForm');
    }

    return TEMPLATES_KEBAB_MENU.filter((menuItem) => {
      if (menuItem.clickEvent === 'onShareLink' && !permissionUsingShareLink) return;
      return !menuItem.clickEvent || !documentKebabExcludes.includes(menuItem.clickEvent);
    });
  };

  const filterDocumentKebabMenuByStatus = (status: string | undefined) => {
    if (status && READ_ONLY_STATUSES.includes(status)) {
      return DOCUMENTS_KEBAB_MENU.filter(
        (menuItem) => !menuItem.clickEvent || menuItem.clickEvent !== 'onFillSign',
      );
    }
    return DOCUMENTS_KEBAB_MENU;
  };

  const onViewSubmissions = (kebabValue: HandlersValueType) => {
    const currentDocument = documents.find((document: any) => String(document.id) === kebabValue.id);
    setTemplateNameForFilter(currentDocument.name);
    if (setActiveTab) {
      setActiveTab(ALL_STATUSES);
    }
  };

  const kebabColRenderDocuments = (value: HandlersValueType, methods: object) => (
    <KebabMenu
      value={value}
      menuOptions={filterTemplatesKebabMenuByType(value?.type)}
      methods={{
        ...methods,
        onConvertToDocument: onConvertTemplateToForm,
        onConvertToForm: onConvertTemplateToForm,
        onCopyTemplate,
        onFillSign,
        onShareLink: onShareLinkDocument,
        onDeleteItem: onDeleteDocument,
        onViewSubmissions,
      }}
    />
  );

  const kebabColRenderDocumentsExecuted = (value: HandlersValueType, methods: object) => (
    <KebabMenu
      value={value}
      menuOptions={filterDocumentKebabMenuByStatus(value?.status)}
      methods={{
        ...methods,
        onCancelAction,
        onCompleteAction,
        onSetInProgress,
        onFillSign,
        onShareLink: onShareLinkDocumentExecuted,
        onDeleteItem: onDeleteDocumentExecuted,
      }}
      isExecutedCollection={isCollectionPage && Boolean(activeStatuses?.length)}
    />
  );
  const kebabColumn = setKebabColumnHeading(
    isAllDocumentsTab ? kebabColRenderDocuments : kebabColRenderDocumentsExecuted,
  );

  const getColumnHeaders = (columnType: ListingViewsTabs): MUIDataTableColumn[] | MUIDataTableColumnDef[] => {
    const dataHeaders = isAllDocumentsTab ? dataColumns : dataColumnsExecuted;
    dataHeaders.map((column: MUIDataTableColumn | MUIDataTableColumnDef) => {
      const columnCopy = { ...column as MUIDataTableColumn };
      if (columnCopy.options?.filterList) {
        columnCopy.options.filterList = [];
        return columnCopy;
      }
      return columnCopy;
    });
    /**
     * TODO: Property 'name' does not exist on type 'MUIDataTableColumnDef'
     * - extend the type for using it instead of any
     */
    switch (columnType) {
      case ListingViewsTabs.COLLECTIONS_EXECUTED:
        return dataColumnsCollectionExecuted.concat(kebabColumn);
      case ListingViewsTabs.COLLECTIONS:
        return dataHeaders
          .filter((column: any) => column.name !== COLUMN_DOCUMENT_CATEGORY.name)
          .concat(kebabColumn);
      case ListingViewsTabs.SECTIONS:
      default:
        return dataHeaders.concat(kebabColumn);
    }
  };

  let tableColumns = getColumnHeaders(currentTableTab);

  const onChangeColumnHandler = (changedColumn: string, action: string) => {
    tableColumns = tableColumns.map((column: MUIDataTableColumn | MUIDataTableColumnDef) => {
      const columnCopy = { ...column as MUIDataTableColumn };
      if (columnCopy.name === changedColumn && columnCopy.options) {
        columnCopy.options.display = action === 'add';
        return columnCopy;
      }
      return columnCopy;
    });
  };

  return (
    <>
      <MUITable
        data={tableRows}
        columns={tableColumns as MUIDataTableColumn[]}
        deleteRows={onDeleteItems}
        resetSelectedRows={resetSelectedRows}
        isAllDocumentsTab={isAllDocumentsTab}
        isCollectionPage={isCollectionPage}
        onChangeColumn={onChangeColumnHandler}
        templateNameForFilter={templateNameForFilter}
        setTemplateNameForFilter={setTemplateNameForFilter}
        setActiveStatuses={setActiveTab}
        activeStatuses={activeStatuses}
      />
      <ShareLinkModal
        showModal={!isLoading && showShareLinkModal}
        publicRoute={publicRoute}
        result={result}
        resourceGuid={resourceGuid}
        readOnlyMode={completedMode}
        onCloseModal={onCloseModal}
        showEmbedScript={permissionUsingEmbed && isDocumentListing && isAllDocumentsTab}
      />
      <CompleteDocumentModal
        showModal={showCompleteDocumentModal}
        documentId={getCompleteDocumentId}
        documentDetails={documentDetails}
        onCloseModal={onCloseModal}
        onChangeDocumentStatus={onChangeDocumentStatus}
      />
      <ModalWindow
        isOpen={convertDocumentId > 0}
        onClose={onCloseConvertDocumentModal}
        onButtonClick={onConfirmConvertDocument}
        title={`Convert ${convertWayText}`}
        buttonTitle="Continue"
      >
        <div>
          This action will convert this {convertWayText} and cannot be reversed.<br />
          <b>Are you sure?</b>
        </div>
      </ModalWindow>
    </>
  );
};

export default GeneralMUITableWrapper;