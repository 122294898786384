import React, { BaseSyntheticEvent, useRef, useState } from 'react';

import { Alert, Button, Input, InputGroup } from 'reactstrap';

import { useSlateStatic } from 'slate-react';

import { useOutsideAlerter } from 'hooks/useOutsideAlerter';
import { IBlockButtonProps } from 'types/Editor';
import { insertImage, isImageUrl } from 'utils/editorImageHelpers';

const ImageButton: React.FC<IBlockButtonProps> = (props) => {
  const {
    key,
    format,
    icon,
    title,
    horizontalToolbar,
  } = props;

  const editor = useSlateStatic();
  const modalRef = useRef(null);
  const [modal, setModal] = useState<boolean>(false);
  const [alt, setAlt] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  useOutsideAlerter(modalRef, setModal);

  const onSubmit = (event: BaseSyntheticEvent) => {
    event.preventDefault();
    if (!url.length) return;
    if (url && !isImageUrl(url)) return setError('URL is not an image');
    insertImage(editor, format, { url, alt });
    setAlt('');
    setUrl('');
    setModal(false);
  };

  const onModal: React.MouseEventHandler<HTMLDivElement | HTMLLIElement> = (event: any) => {
    if (event.target.tagName === 'INPUT') return setModal(true);

    setModal(!modal);
  };
  const onChangeAlt = (event: BaseSyntheticEvent) => setAlt(event.target.value);
  const onChangeUrl = (event: BaseSyntheticEvent) => setUrl(event.target.value);

  const renderImageEditor = () => {
    const divClass = horizontalToolbar
      ? 'position-absolute modal-toolbar-editor w-auto'
      : 'modal-vertical-toolbar-editor image-settings bg-white';
    return (
      <div className={divClass} data-toolbar-id={horizontalToolbar ? 'horizontal-toolbar-modal' : ''}>
        <div className="d-flex flex-column">
          <div className="m-1">
            <label>
              <span>alt</span>
              <InputGroup size="sm">
                <Input
                  type="text"
                  required
                  value={alt}
                  className="toolbar-input"
                  onChange={onChangeAlt}
                />
              </InputGroup>
            </label>
          </div>
          <div className="m-1">
            <label>
              <span>url</span>
              <InputGroup size="sm">
                <Input
                  type="url"
                  required
                  value={url}
                  className="toolbar-input"
                  onChange={onChangeUrl}
                />
              </InputGroup>
              {error && <Alert color="danger" className="mt-1">{error}</Alert>}
            </label>
          </div>
          <div className="d-flex justify-content-end m-1">
            <Button
              className="w-100"
              size="sm"
              type="button"
              onClick={onSubmit}
            >
              Create Image
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {
        horizontalToolbar ? (
          <div
            ref={modalRef}
            className="d-inline-block"
            onClick={onModal}
            tabIndex={-1}
            role="button"
          >
            <span className="btn-toolbar-editor horizontal-toolbar-icon">{icon}</span>
            {modal && renderImageEditor()}
          </div>
        ) : (
          <li
            key={key}
            ref={modalRef}
            className="item-toolbar-editor"
            onClick={onModal}
          >
            <div className="d-flex">
              <div className="icon border-0 text-muted btn-toolbar-editor bg-white">
                {icon}
              </div>
              <div>
                <div className="text-dark">{title}</div>
                <div className="command-description text-muted">Shortcut 4 + &#8984; or type - + space</div>
              </div>
            </div>
            {renderImageEditor()}
          </li>
        )
      }
    </div>
  );
};

export default ImageButton;