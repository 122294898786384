import { FC, useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';

import { ZoomIn, ZoomOut } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';

import DocumentWrapper from 'components/Editor/components/pdfRenderer/DocumentWrapper';
import PDFPageWrapper from 'components/Editor/components/pdfRenderer/PDFPageWrapper';
import SelectableFieldsWrapper from 'components/FormBuilder/SelectableFieldsWrapper';
import SortableListWrapper from 'components/FormBuilder/SortableListWrapper';
import { FORM_BUILDER } from 'constants/formBuilder';
import { DEFAULT_PAGE_WIDTH } from 'constants/PDF';
import { DEFAULT_ZOOM_SCALE, MAX_ZOOM_SCALE, MIN_ZOOM_SCALE, ZOOM_STEP } from 'constants/pdfScaling';
import useFormBuilderGroupedFields from 'hooks/useFormBuilderGroupedFields';
import { deletePdfFileLink } from 'store/actions/editorSlate';
import { PDFFieldType } from 'types/PdfTemplates';
import { isMobileDevice } from 'utils/mobileDeviceHelper';

interface IPdfRenderer {
  fileLink: string;
  isPDFTemplate?: boolean;
  isPublicPage?: boolean;
  isReadOnlyMode?: boolean;
  showZoomButtons?: boolean;
  pdfTemplateWidth?: number;
  isCollectionPage?: boolean;
  pdfTemplateId?: number;
  startScale?: number;
  customFields?: PDFFieldType[] | null;
  isFormBuilderOpened?: boolean;
}

const PdfRenderer: FC<IPdfRenderer> = ({
  fileLink,
  isPDFTemplate = false,
  showZoomButtons = false,
  isPublicPage = false,
  isReadOnlyMode = false,
  pdfTemplateWidth = DEFAULT_PAGE_WIDTH,
  isCollectionPage = false,
  pdfTemplateId = 0,
  startScale = 0,
  customFields = null,
  isFormBuilderOpened = false,
}) => {
  useFormBuilderGroupedFields(isFormBuilderOpened);
  const isMobile = isMobileDevice();
  const dispatch = useDispatch();

  const [numPages, setNumPages] = useState<number | null>(null);
  const [scaleValue, setScaleValue] = useState<number>(DEFAULT_ZOOM_SCALE);

  useEffect(() => () => {
    dispatch(deletePdfFileLink());
  }, [dispatch]);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const wrapper = wrapperRef?.current;

  useEffect(() => {
    if (isPublicPage && wrapper) {
      setScaleValue(startScale);
    }
  }, [isPublicPage, wrapper, startScale]);

  useEffect(() => {
    if (isPublicPage && wrapper) {
      if (isMobile && wrapper.parentElement) {
        const contentLeftRightPadding = 30;
        const paddingBetweenPages = 12;
        const documentCurrentWidthWithPadding = (pdfTemplateWidth || DEFAULT_PAGE_WIDTH)
          * scaleValue + contentLeftRightPadding;
        wrapper.parentElement.style.minWidth = `${documentCurrentWidthWithPadding}px`;
        const onePageHeight = paddingBetweenPages
          + Math.max(wrapper.lastElementChild?.clientHeight || 1, window.screen.height)
          * scaleValue;
        const documentCurrentHeightWithPadding = onePageHeight * (wrapper.childElementCount || 1);
        wrapper.parentElement.style.maxHeight = `${documentCurrentHeightWithPadding}px`;

        if (scaleValue > DEFAULT_ZOOM_SCALE) {
          wrapper.style.transformOrigin = '50% 0';
        } else {
          wrapper.style.transformOrigin = '0 0';
        }
      }

      wrapper.style.transform = `scale(${scaleValue})`;
    }
  }, [scaleValue]);

  const zoomIn = () => {
    setScaleValue((prevState) => {
      if (prevState < MAX_ZOOM_SCALE) {
        return prevState + ZOOM_STEP;
      }
      return prevState;
    });
  };

  const zoomOut = () => {
    setScaleValue((prevState) => {
      if (prevState > MIN_ZOOM_SCALE) {
        return prevState - ZOOM_STEP;
      }
      return prevState;
    });
  };

  return (
    <SelectableFieldsWrapper disabled={!isFormBuilderOpened || isMobile}>
      <SortableListWrapper
        formBuilderType={FORM_BUILDER.PDF}
        isFormBuilderOpened={isFormBuilderOpened}
      >
        <>
          {showZoomButtons && (
            <div className="pdf-pages-zoom-buttons">
              <IconButton color="primary" onClick={zoomIn}><ZoomIn className="cursor-pointer" /></IconButton>
              <IconButton color="primary" onClick={zoomOut}><ZoomOut className="cursor-pointer" /></IconButton>
            </div>
          )}
          <DocumentWrapper
            fileLink={fileLink}
            setNumPages={setNumPages}
            isCollectionPage={isCollectionPage}
          >
            <PDFPageWrapper
              isPublicPage={isPublicPage}
              isCollectionPage={isCollectionPage}
              isFormBuilderOpened={isFormBuilderOpened}
              isReadOnlyMode={isReadOnlyMode}
              isPDFTemplate={isPDFTemplate}
              numPages={numPages}
              zoomIn={zoomIn}
              zoomOut={zoomOut}
              wrapperRef={wrapperRef}
              pdfTemplateId={pdfTemplateId}
              pdfTemplateWidth={pdfTemplateWidth}
              customFields={customFields}
            />
          </DocumentWrapper>
        </>
      </SortableListWrapper>
    </SelectableFieldsWrapper>
  );
};

export default PdfRenderer;