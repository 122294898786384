/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-extraneous-dependencies */
import { FC, useState } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Divider, Menu, MenuItem } from '@material-ui/core';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Complete from '@mui/icons-material/Check';
import Clear from '@mui/icons-material/Clear';
import Delete from '@mui/icons-material/Delete';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import EditIcon from '@mui/icons-material/Edit';
import FileCopy from '@mui/icons-material/FileCopy';
import MoreVert from '@mui/icons-material/MoreVert';
import Share from '@mui/icons-material/Share';

import ModalConfirmDialog from 'components/Modals/ModalConfirmDialog';
import Tooltip from 'components/Tooltip';
import {
  FORBIDDEN_DELETE_STATUSES,
  STATUSES,
  STATUSES_FOR_SHARING,
} from 'constants/documentStatuses';
import {
  COLLECTION_EXECUTED_TYPE,
  COLLECTION_TYPE,
  DOCUMENT_TYPE,
  FORM_TYPE,
  PDF_TYPE,
} from 'constants/general';
import { ADMIN_ROLES, ROLES } from 'constants/roles';
import { RootStateType } from 'store/reducers';
import { DocumentTypesType } from 'types/Documents';
import { IKebabMenuItem } from 'types/KebabMenu';
import { deleteDocumentModalText } from 'utils/modalHelpers';

export type HandlersValueType = {
  id: string;
  type: DocumentTypesType;
  status?: string;
  isUserActive?: boolean;
  groupId?: string;
}

interface IKebabMenu {
  value: HandlersValueType;
  menuOptions: IKebabMenuItem[];
  methods: any;
  isExecutedCollection?: boolean;
}

const KebabMenu: FC<IKebabMenu> = ({ value, menuOptions, methods, isExecutedCollection }) => {
  const userRole = useSelector((state: RootStateType) => state.profile.profileInfo?.userRole);
  const [anchorEl, setAnchorEl] = useState(null);
  const [disableScrollLock, setDisableScrollLock] = useState<boolean>(true);
  const [showConfirmDeletingModal, setShowConfirmDeletingModal] = useState<boolean>(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setDisableScrollLock(false);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleModalVisibility = (): void => {
    setShowConfirmDeletingModal((prevState: boolean) => !prevState);
  };

  const setItemIcon = (iconName: string) => {
    const className = 'material-icons';
    switch (iconName) {
      case 'Submissions':
        return <AssignmentTurnedInOutlinedIcon className={className} />;
      case 'Convert':
        return <DynamicFormIcon className={className} />;
      case 'Copy':
        return <FileCopy className={className} />;
      case 'Share':
        return <Share className={className} />;
      case 'Delete':
        return <Delete className={className} />;
      case 'Cancel':
        return <Clear className={className} />;
      case 'Complete':
        return <Complete className={className} />;
      case 'Fill & sign':
        return <BorderColorIcon className={className} />;
      case STATUSES.inProgress:
        return <AutorenewIcon className={className} />;
      case 'Edit':
        return <EditIcon className={className} />;
      case 'Add':
        return <AddCircleIcon className={className} />;
      default:
        return null;
    }
  };

  const handlerMenuItemClick = (onClickHandler: (value: HandlersValueType) => void) => {
    onClickHandler(value);
    setAnchorEl(null);
  };

  const setClickEvent = (eventName: string): void | null => {
    switch (eventName) {
      case 'onViewSubmissions':
        return methods.onViewSubmissions && handlerMenuItemClick(methods.onViewSubmissions);
      case 'onConvertToDocument':
        return methods.onConvertToDocument && handlerMenuItemClick(methods.onConvertToDocument);
      case 'onConvertToForm':
        return methods.onConvertToForm && handlerMenuItemClick(methods.onConvertToForm);
      case 'onCopyTemplate':
        return methods.onCopyTemplate && handlerMenuItemClick(methods.onCopyTemplate);
      case 'onShareLink':
        return methods.onShareLink && handlerMenuItemClick(methods.onShareLink);
      case 'onCancelAction':
        return methods.onCancelAction && handlerMenuItemClick(methods.onCancelAction);
      case 'onCompleteAction':
        return methods.onCompleteAction && handlerMenuItemClick(methods.onCompleteAction);
      case 'onDeleteItem':
        handleModalVisibility();
        return;
      case 'onSetInProgress':
        return methods.onSetInProgress && handlerMenuItemClick(methods.onSetInProgress);
      case 'onFillSign':
        return methods.onFillSign && handlerMenuItemClick(methods.onFillSign);
      case 'onEditUser':
        return methods.onEditUser && handlerMenuItemClick(methods.onEditUser);
      case 'onDisableUser':
      case 'onActivateUser':
        return methods.onActivateDisableUser && handlerMenuItemClick(methods.onActivateDisableUser);
      case 'onChangeGroupName':
        return methods.onChangeGroupName && handlerMenuItemClick(methods.onChangeGroupName);
      case 'onDeleteGroup':
        return methods.onDeleteGroup && handlerMenuItemClick(methods.onDeleteGroup);
      case 'onAddGroupMembers':
        return methods.onAddGroupMembers && handlerMenuItemClick(methods.onAddGroupMembers);
      case 'onRemoveGroupMember':
        return methods.onRemoveGroupMember && handlerMenuItemClick(methods.onRemoveGroupMember);
      default:
        return null;
    }
  };

  const setIsDisabled = (eventName: string) => {
    const status = value?.status;
    const isCollectionExecuted = value?.type === COLLECTION_EXECUTED_TYPE;
    switch (eventName) {
      case 'onViewSubmissions':
        return ![FORM_TYPE, PDF_TYPE, DOCUMENT_TYPE].includes(value?.type);
      case 'onConvertToDocument':
        return value?.type !== FORM_TYPE;
      case 'onConvertToForm':
        return value?.type !== DOCUMENT_TYPE;
      case 'onCopyTemplate':
        return [COLLECTION_TYPE].includes(value?.type);
      case 'onShareLink':
        return (status && !STATUSES_FOR_SHARING.includes(status));
      case 'onCancelAction':
        return (status && [STATUSES.cancelled, STATUSES.completed].includes(status));
      case 'onCompleteAction':
        return (status && status === STATUSES.completed);
      case 'onDeleteItem':
        return status && FORBIDDEN_DELETE_STATUSES.includes(status);
      case 'onSetInProgress':
        return (status && [STATUSES.inProgress, STATUSES.completed].includes(status));
      case 'onFillSign':
        return value?.type === COLLECTION_TYPE || isCollectionExecuted;
      case 'onEditUser':
      case 'onDisableUser':
      case 'onActivateUser':
      case 'onChangeGroupName':
      case 'onDeleteGroup':
      case 'onAddGroupMembers':
      case 'onRemoveGroupMember':
        return !ADMIN_ROLES.includes(userRole);
      default:
        return true;
    }
  };

  const setIsVisible = (eventName: string) => {
    const isUserActive = value?.isUserActive;
    switch (eventName) {
      case 'onEditUser':
      case 'onDisableUser':
        return isUserActive;
      case 'onActivateUser':
        return !isUserActive;
      default:
        return true;
    }
  };

  const kebabItemHelper = (item: IKebabMenuItem, icon: JSX.Element | null) => (
    item.title !== '-' ? (<>{icon}{item.title}</>) : <Divider />
  );

  return (
    <div
      className={userRole === ROLES.READ_ONLY ? 'd-none' : ''}
      onBlur={() => {
        setDisableScrollLock(false);
        setAnchorEl(null);
      }}
    >
      <div
        className="kebab-menu-icon"
        aria-controls="kebabMenu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVert className="material-icons" />
      </div>
      <Menu
        id="kebabMenu"
        anchorEl={anchorEl}
        keepMounted
        disableRestoreFocus
        open={!!anchorEl}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
        disableScrollLock={disableScrollLock}
        disableAutoFocusItem
      >
        {
          menuOptions.map((item, index) => {
            const onClickEvent = item.clickEvent ? () => setClickEvent(String(item.clickEvent)) : handleClose;
            const isDisabled = Boolean(setIsDisabled(String(item.clickEvent)));
            const isVisible = Boolean(setIsVisible(String(item.clickEvent)));
            const setIcon = item.icon ? setItemIcon(item.icon) : null;
            const isCompleteButtonForExecutedCollection = isExecutedCollection && item.title === 'Complete';

            return (
              isVisible && (
                <Tooltip
                  key={`kebabMenuItem${index + 1}`}
                  title={
                    isCompleteButtonForExecutedCollection ? 'Please, complete a collection from detailed page' : ''
                  }
                >
                  <MenuItem
                    onClick={isCompleteButtonForExecutedCollection ? () => null : onClickEvent}
                    className="kebab-menu-item"
                    disabled={isDisabled}
                  >
                    {
                      item.path
                        ? (<Link to={item.path}>{setIcon}{item.title}</Link>)
                        : kebabItemHelper(item, setIcon)
                    }
                  </MenuItem>
                </Tooltip>
              )
            );
          })
        }
      </Menu>
      <ModalConfirmDialog
        showDialog={showConfirmDeletingModal}
        onClose={handleModalVisibility}
        onConfirm={() => {
          handleModalVisibility();
          return methods.onDeleteItem && handlerMenuItemClick(methods.onDeleteItem);
        }}
        messageText={deleteDocumentModalText}
      />
    </div>
  );
};

export default KebabMenu;