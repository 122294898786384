/* eslint-disable */
import { Editor, Range, Element as SlateElement, Transforms, Point } from 'slate';
import { isInTable } from './table/utils';
import './table/table.scss';

export const withTables = (editor: Editor) => {
  const { deleteBackward, deleteForward, deleteFragment } = editor;

  editor.deleteFragment = (...args) => {
    const { selection } = editor;

    const selectedCells = Array.from(
      Editor.nodes(editor, {
        match: n => SlateElement.isElement(n) && n.type === 'table-cell',
      })
    );

    if (selection && Range.isExpanded(selection) && isInTable(editor)) {
      if (selectedCells.length === 1) {
        return deleteFragment(...args);
      }
      return;
    } else if (selectedCells.length) {
      return;
    }

    deleteFragment(...args);
  };

  editor.deleteBackward = (...args) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      if (isInTable(editor)) {
        const [cell]: any = Editor.nodes(editor, {
          match: (node) =>
            !Editor.isEditor(node) &&
            SlateElement.isElement(node) &&
            node.type === 'table-cell',
        });
        if (cell) {
          const [, cellPath] = cell;
          const start = Editor.start(editor, cellPath);
          if (Point.equals(selection.anchor, start)) {
            return;
          }
        }
      } else {
        const contentBefore = Editor.before(editor, selection.anchor, { unit: 'line' });
        if (contentBefore) {
          Transforms.move(editor, { distance: 1, unit: 'character', reverse: true, });
          const shouldDenyDeleting = isInTable(editor);
          Transforms.move(editor, { distance: 1, unit: 'character', });
          if (shouldDenyDeleting) return;
        }
      }
    }

    deleteBackward(...args);
  };

  editor.deleteForward = (...args) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      if (isInTable(editor)) {
        const [cell]: any = Editor.nodes(editor, {
          match: (node) =>
            !Editor.isEditor(node) &&
            SlateElement.isElement(node) &&
            node.type === 'table-cell',
        });

        if (cell) {
          const [, cellPath] = cell;
          const end = Editor.end(editor, cellPath);
          if (Point.equals(selection.anchor, end)) {
            return;
          }
        }
      } else {
        const contentAfter = Editor.after(editor, selection.anchor, { unit: 'line' });
        if (contentAfter) {
          Transforms.move(editor, { distance: 1, unit: 'character', });
          const shouldDenyDeleting = isInTable(editor);
          Transforms.move(editor, { distance: 1, unit: 'character', reverse: true, });
          if (shouldDenyDeleting) return;
        }
      }
    }

    deleteForward(...args);
  };

  return editor;
};