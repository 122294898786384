import { FC, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import LoginForm from 'components/LoginForm';
import ModalContentWindow from 'components/Modals/ModalContentWindow';
import ResetPasswordModal from 'components/Modals/ResetPasswordModal';
import { PROFILE_SECTIONS } from 'constants/UserMenu';
import useLogin from 'hooks/useLogin';
import { RootStateType } from 'store/reducers';
import { trackAmplitudeEvent } from 'utils/amplitude/amplitudeTrackingUtlis';
import { LOGOUT_EVENT } from 'utils/amplitude/amplituteConstants';

const Login: FC = () => {
  const {
    user,
    changeUserHandler,
    error,
    forgotPasswordHandler,
    onLoginUserHandler,
    isButtonClicked,
    disableLogin,
    showModal,
    setShowModal,
  } = useLogin();

  const userInfo = useSelector((state: RootStateType) => state.profile.profileInfo);
  const location = useLocation<{ from?: string; }>();
  const history = useHistory();

  useEffect(() => {
    if (userInfo && location.state?.from === PROFILE_SECTIONS.LOGOUT) {
      trackAmplitudeEvent({
        eventName: LOGOUT_EVENT,
        userInfo,
      });
      history.replace({ ...history.location, state: { ...(history.location.state as object), from: null } });
    }
  }, [userInfo]);

  return (
    <>
      <LoginForm
        user={user}
        changeUserHandler={changeUserHandler}
        error={error}
        forgotPasswordHandler={forgotPasswordHandler}
        onLoginUserHandler={onLoginUserHandler}
        isButtonClicked={isButtonClicked}
        disableLogin={disableLogin}
      />
      <ModalContentWindow
        centered
        showModal={showModal}
        onCloseModal={setShowModal}
        titleText="Reset Password"
        showDefaultText={false}
      >
        <ResetPasswordModal setShowModal={setShowModal} />
      </ModalContentWindow>
    </>
  );
};

export default Login;