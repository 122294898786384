import { Dispatch, FC, SetStateAction } from 'react';

import CloseOpenSidebarIcon from 'svg/CloseOpenSidebarIcon';

interface IOpenCloseButton {
  toggleHandler: Dispatch<SetStateAction<boolean>>;
  isActive: boolean;
  isReverseMode?: boolean;
}

const OpenCloseButton: FC<IOpenCloseButton> = ({
  toggleHandler,
  isActive,
  isReverseMode = false,
}) => {
  const classIsActive = !isActive ? 'toggle-button-inactive' : '';
  const classIsReverse = isReverseMode ? 'toggle-button-reverse' : '';
  const classActiveReverse = !isActive && isReverseMode ? 'toggle-button-inactive-reverse' : '';

  return (
    <div className={isReverseMode ? 'd-flex justify-content-end' : ''}>
      <button
        type="button"
        className={`toggle-button ${classIsActive} ${classIsReverse} ${classActiveReverse}`}
        onClick={() => toggleHandler(!isActive)}
      >
        <CloseOpenSidebarIcon />
      </button>
    </div>
  );
};

export default OpenCloseButton;