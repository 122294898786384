import { useState } from 'react';

import { documentNavigationConfig } from 'constants/documentsNavigation';
import { useRolePermission } from 'hooks/useRolePermission';
import { DocumentStatusType } from 'types/Documents';

const useDocumentsStatuses = () => {
  const { isUserReadOnly } = useRolePermission();

  const [activeStatuses, setActiveStatuses] = useState<DocumentStatusType>(
    !isUserReadOnly ? documentNavigationConfig[0].statuses : documentNavigationConfig[1].statuses,
  );

  return {
    isUserReadOnly,
    activeStatuses,
    setActiveStatuses,
  };
};

export default useDocumentsStatuses;