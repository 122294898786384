import { Descendant } from 'slate';

import { PDFFieldTypeObject } from 'types/PdfTemplates';
import { PublicPageDataType } from 'types/PublicPage';
import { IDocumentSection } from 'types/Sections';
import { ITemplateDetails } from 'types/Templates';
import { isPDFDocument } from 'utils/PublicPage/documentTypeChecker';
import { isDocumentType, isTemplateType } from 'utils/typeChecker';

const findSection = (content: Descendant[], id: number): Descendant => (
  content.filter((section: Descendant) => section.key === id)[0]
);

const updateData = (data: PublicPageDataType, content: Descendant[], pdfFields: PDFFieldTypeObject) => {
  const isPDF = isPDFDocument(data);
  if (isDocumentType(data)) {
    return {
      ...data,
      content_json: isPDF ? pdfFields : content,
    };
  }
  if (isTemplateType(data) && isPDF) {
    return {
      ...data,
      pdf_fields_json: {
        ...data.pdf_fields_json,
        ...pdfFields,
      },
    };
  }
  if (isTemplateType(data) && !isPDF) {
    const updatedTemplate: ITemplateDetails = {
      ...data,
      sections: data.sections.map((section: IDocumentSection): IDocumentSection => {
        const currentSection: Descendant = findSection(content, section.section.id);
        return {
          ...section,
          section: {
            ...section.section,
            content_json: currentSection.children,
          },
        };
      }),
    };

    return updatedTemplate;
  }
  return data;
};

export default updateData;