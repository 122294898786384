import { FC, MouseEventHandler, useCallback, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import ModalEmbedScript from 'components/Modals/ModalWrapper/ModalEmbedScript';
import ModalShareLink from 'components/Modals/ModalWrapper/ModalShareLink';
import NavigationShareModal from 'components/Modals/ModalWrapper/NavigationShareModal';
import { MANAGER_ASSIGNMENT } from 'constants/editors';
import { URL_PUBLIC_TEMPLATE } from 'constants/general';
import ROUTES from 'constants/routes';
import { EMBED_TAB, LINK_TAB, LINK_TAB_ID } from 'constants/tabs';
import { useRolePermission } from 'hooks/useRolePermission';
import { changeShareLinkViewMode } from 'services/api';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { AssignmentType } from 'types/Editor';
import { ITab } from 'types/Tabs';
import createPublicLink, { getShareLinkUrl } from 'utils/shareModalWindow';

interface IShareLinkModal {
  result: string | AssignmentType[];
  showModal: boolean;
  onCloseModal: (show: boolean) => void;
  publicRoute?: string;
  readOnlyMode?: boolean;
  resourceGuid?: string;
  isSubmitForm?: boolean;
  showEmbedScript?: boolean;
  deleteLinkFromStore?: () => void;
}

const ShareLinkModal: FC<IShareLinkModal> = ({
  result,
  showModal,
  onCloseModal,
  publicRoute = URL_PUBLIC_TEMPLATE,
  readOnlyMode = false,
  resourceGuid = '',
  isSubmitForm = false,
  showEmbedScript = false,
  deleteLinkFromStore = () => null,
}) => {
  const history = useHistory();
  const { userRoleAssignments, permissionUsingEmbed } = useRolePermission();
  const [selectedTab, setSelectedTab] = useState<number>(LINK_TAB_ID);
  const isManager: boolean = userRoleAssignments === MANAGER_ASSIGNMENT;

  const handlerCopyToClipboard = useCallback((text: string): void => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text);
      toast.success('Text copied');
    }
  }, []);

  const handlerCloseModal = (): void => {
    onCloseModal(false);
    setSelectedTab(1);
    if (deleteLinkFromStore) {
      deleteLinkFromStore();
    }
    if (isSubmitForm) {
      const goToURL = isManager ? ROUTES.DOCUMENTS : ROUTES.LOGIN;
      history.push(goToURL);
    }
  };

  const onChangeSelectedTab = (id: number): MouseEventHandler<HTMLLIElement> => () => {
    setSelectedTab(id);
  };

  const changeViewModeHandler = async (value: string) => {
    await apiErrorHandler(
      changeShareLinkViewMode,
      {
        resource: getShareLinkUrl(createPublicLink(resourceGuid, publicRoute)),
        guid: resourceGuid,
        mode: value,
      },
    );
  };

  const shareModalTabs: ITab[] = [];
  if (!isSubmitForm) {
    shareModalTabs.push(LINK_TAB);
  }
  if (!isSubmitForm && permissionUsingEmbed && resourceGuid && showEmbedScript) {
    shareModalTabs.push(EMBED_TAB);
  }

  const accessToEmbedScript = permissionUsingEmbed && showEmbedScript
    ? 'Share link or copy Embed script'
    : 'Share link';

  return (
    <Modal isOpen={showModal}>
      <ModalHeader toggle={handlerCloseModal}>
        { isSubmitForm ? 'Great job!' : accessToEmbedScript }
      </ModalHeader>
      {
        shareModalTabs.length !== 0
          && (
            <NavigationShareModal
              tabs={shareModalTabs}
              onChangeSelectedTab={onChangeSelectedTab}
              selectedTab={selectedTab}
            />
          )
      }
      <ModalBody>
        { (!permissionUsingEmbed || isSubmitForm)
          && (typeof result === 'string')
          && (<div className="mb-3">{result}</div>) }
        {
          shareModalTabs.length !== 0
            && (
              selectedTab === LINK_TAB_ID
                ? (
                  <ModalShareLink
                    publicRoute={publicRoute}
                    readOnlyMode={readOnlyMode}
                    result={result}
                    copyHandler={handlerCopyToClipboard}
                    changeViewModeHandler={changeViewModeHandler}
                  />
                )
                : (
                  <ModalEmbedScript
                    documentId={resourceGuid}
                    copyHandler={handlerCopyToClipboard}
                  />
                )
            )
        }
      </ModalBody>
    </Modal>
  );
};

export default ShareLinkModal;