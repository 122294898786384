import { memo } from 'react';

import { FormControl, MenuItem, Select } from '@mui/material';

import { useSetSignerSelectMenu } from 'hooks/signNow';
import { AssignmentSigner } from 'types/Editor';
import { getHexColorFromIndex } from 'utils/colorAssignmentHelper';

import 'scss/components/SignNow/_setSigner.scss';

const SelectSignerMenu = () => {
  const {
    signer,
    handleChange,
    signerAssignments,
  } = useSetSignerSelectMenu();

  return (
    <div className="set-signer px-3">
      <FormControl fullWidth className="set-signer-select">
        <Select
          value={signer}
          onChange={handleChange}
        >
          {
            signerAssignments.map((assignment: AssignmentSigner, index: number) => (
              <MenuItem
                value={assignment.type}
                key={assignment.type}
              >
                <div
                  className="set-signer-menu-item"
                >
                  <span
                    className="set-signer-icon"
                    style={{
                      background: getHexColorFromIndex(index),
                    }}
                  />
                  <span>
                    {assignment.label}
                  </span>
                  <span>
                    (Signer {index + 1})
                  </span>
                </div>
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </div>
  );
};

export default memo(SelectSignerMenu);