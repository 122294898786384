import { FC } from 'react';

import { Input } from 'reactstrap';

import CHECKBOX_MARK_BASE64 from 'svg/CheckboxMark';
import { PDFPropsType } from 'types/PdfTemplates';
import { getMainTypeAssignment } from 'utils/assignmentsHelpers';

const CheckboxEditable: FC<PDFPropsType> = ({
  field,
  isReadOnlyMode,
  onChangeField,
  fieldStyle,
}) => {
  const { checked } = field;

  return (
    <div className="drag-and-drop-checkbox-wrapper" style={fieldStyle}>
      <Input
        type="checkbox"
        checked={checked}
        onChange={(e) => onChangeField(e, field)}
        disabled={isReadOnlyMode}
        className={`pdf-field-${getMainTypeAssignment(field.assignment)}`}
        key={`checkbox_${field.key}`} // is important for PyPDF2 on the backend side
        data-pdf-field
      />
      {checked && (
        <div className="drag-and-drop-checkbox-checked">
          <img src={CHECKBOX_MARK_BASE64} alt="" />
        </div>
      )}
    </div>
  );
};

export default CheckboxEditable;