import { FC, useCallback, useState } from 'react';

import { useSelector } from 'react-redux';
import { FormGroup, Input, Label } from 'reactstrap';

import ShareLinksAccordion from 'components/Modals/ModalWrapper/ShareLinksAccordion';
import MondayComBoardsDropdown from 'components/Modals/MondayComModalWindow/MondayComBoardsDropdown';
import RadioButtonsGroup from 'components/RadioButtonsGroup';
import { URL_PUBLIC_COLLECTION, URL_PUBLIC_TEMPLATE } from 'constants/general';
import { NO_BOARDS } from 'constants/mondayComIntegration';
import useShareLinkViewModes from 'hooks/useShareLinkViewModes';
import { RootStateType } from 'store/reducers';
import { AssignmentType } from 'types/Editor';
import { isRecipientAssignment } from 'utils/assignmentsHelpers';
import createPublicLink, { getMainRoute, getTargetMondayComBoardId } from 'utils/shareModalWindow';

interface IModalShareLink {
  result: string | AssignmentType[];
  publicRoute?: string;
  readOnlyMode?: boolean;
  copyHandler: (link: string) => void;
  changeViewModeHandler?: (viewMode: string) => void;
}

const ModalShareLink: FC<IModalShareLink> = ({
  result,
  publicRoute = URL_PUBLIC_TEMPLATE,
  copyHandler,
  readOnlyMode = false,
  changeViewModeHandler = () => null,
}) => {
  const modes = useShareLinkViewModes();
  const {
    publicViewMode,
    templatePublicLink,
    multiTemplatePublicLink,
  } = useSelector((state: RootStateType) => state.publicPages);
  const [boardQueryParameter, setBoardQueryParameter] = useState<string | null>(NO_BOARDS);
  const currentMondaycomIntegration = publicRoute === URL_PUBLIC_TEMPLATE
    ? templatePublicLink.monday_com_integration
    : multiTemplatePublicLink.monday_com_integration;

  const getFullPublicLink = useCallback((item: AssignmentType) => {
    const fullLink = createPublicLink(
      item.link || '',
      getMainRoute(publicRoute, item.type),
      currentMondaycomIntegration
        ? getTargetMondayComBoardId(currentMondaycomIntegration, boardQueryParameter)
        : null,
      isRecipientAssignment(item.type),
    );
    return fullLink;
  }, [boardQueryParameter, publicRoute, currentMondaycomIntegration]);

  return (
    <>
      {
        [URL_PUBLIC_TEMPLATE, URL_PUBLIC_COLLECTION].includes(publicRoute) && (
          <MondayComBoardsDropdown
            mondayComIntegration={currentMondaycomIntegration}
            boardQueryParameter={boardQueryParameter}
            onChangeMondayComBoardQueryParameter={setBoardQueryParameter}
          />
        )
      }
      {
        !readOnlyMode && (
          <RadioButtonsGroup
            radiosArray={modes}
            onChangeHandler={changeViewModeHandler}
            defaultValue={publicViewMode}
          />
        )
      }
      <div>
        {
          readOnlyMode ? (
            <FormGroup className="ms-2" check inline>
              <Input type="checkbox" disabled checked={readOnlyMode} />
              <Label check>Read Only</Label>
            </FormGroup>
          ) : null
        }
        <ShareLinksAccordion
          result={result as AssignmentType[]}
          publicRoute={publicRoute}
          getFullPublicLink={getFullPublicLink}
          copyHandler={copyHandler}
        />
      </div>
    </>
  );
};

export default ModalShareLink;