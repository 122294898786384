// Snake case is used in this case because we send this data directly to backend
// It's easier to handle with it in this way
export enum HISTORY_ACTIONS {
  SENT_EMAIL = 'sent_email',
  EMAIL_OPENED = 'opened_email',
  VIEWED = 'viewed',
  SIGNED = 'signed',
  DELETED_SIGNATURE = 'deleted_signature',
  CHANGE_STATUS = 'change_status',
  PASSWORDLESS_AUTH = 'passwordless_auth',
  SMS_VERIFIED = 'sms_verified',
}

export type HistoryRecordType = {
  action_type: HISTORY_ACTIONS;
  action_date: string;
  name?: string;
  email?: string;
  ip_address?: string;
  from_status?: string;
  to_status?: string;
  recipient_name?: string;
  recipient_email?: string;
  user_phone?: string;
}

export type HistorylogObject = {
  [key: string]: string;
}

export type DocumentHistoryType = {
  isLoading: boolean;
  history: HistoryRecordType[];
}