import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import DocumentNavigator from 'components/DocumentNavigator';
import EditorContainer from 'components/Editor/EditorContainer';
import EditorWrapper from 'components/Editor/EditorWrapper';
import EditorHeader from 'components/EditorHeader/EditorHeader';
import FormBuilder from 'components/FormBuilder';
import FormBuilderOutline from 'components/FormBuilder/FormBuilderOutline';
import ModalContentWindow from 'components/Modals/ModalContentWindow';
import ShareLinkModal from 'components/Modals/ModalWrapper';
import PdfEditor from 'components/PdfEditor/PdfEditor';
import PdfFieldsSidebar from 'components/PdfEditor/PdfFieldsSidebar';
import SearchFieldAndContent from 'components/Sidebar/SearchFieldAndContent';
import { MANAGER_ASSIGNMENT } from 'constants/editors';
import { FORM_BUILDER } from 'constants/formBuilder';
import {
  DOCUMENT_NAME_LABEL,
  PDF_TYPE,
  RedirectTypeEnum,
  URL_PUBLIC_TEMPLATE,
} from 'constants/general';
import { SOMETHING_WENT_WRONG } from 'constants/generalErrors';
import ROUTES from 'constants/routes';
import useSetDefaultReminderSettings from 'hooks/pageSettings/useSetDefaultReminderSettings';
import { useRolePermission } from 'hooks/useRolePermission';
import useSingleDocument from 'hooks/useSingleDocument';
import { clearFormBuilderSelectedFieldsKeys } from 'store/actions/formBuilder';
import {
  clearPdfTemplateData,
  setAllPdfFieldsAndInfo,
  setPdfTemplateFileLink,
  setPdfTemplateInfo,
  setPdfTemplateKey,
  setPdfTemplateWidth,
} from 'store/actions/pdfTemplates';
import {
  createTemplatePublicLink,
  deleteTemplateLinkFromStore,
  setCreatedDocumentId,
} from 'store/actions/publicPages';
import { createTemplate, editTemplate, getTemplateDetails, setTemplateDetails } from 'store/actions/userData';
import { RootStateType } from 'store/reducers';
import { IMetadataId } from 'types/Metadata';
import { MatchParams } from 'types/Route';
import { ITemplateRequestObj } from 'types/Templates';
import { IErrorField } from 'types/validation';
import { trimHTMLValue } from 'utils/descriptionHelper';
import {
  setFormBuilderGroupedFields,
  updateFormBuilder,
} from 'utils/FormBuilder/formBuilderHelpers';
import {
  createFieldsFromPdfFormfields,
  filterSortedFormFieldsFromJSONObject,
  getPdfUrlAndWidth,
} from 'utils/pdfTemplatesHelpers';
import { getIsDocumentType } from 'utils/PublicPage/documentTypeChecker';
import setDefaultPageValuesHelper from 'utils/reduxHelpers';
import { getEditorPageLink } from 'utils/routeHelpers';
import { validationForm } from 'utils/validation';

const PdfDocumentDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params: MatchParams = useParams();
  const id: string = params.id;
  const isSingleDocument = useSingleDocument();

  const {
    pdfTemplateFields,
    pdfTemplateKey,
    pdfTemplateInfo,
    pdfTemplateWidth,
    pdfFormFields,
  } = useSelector((state: RootStateType) => state.pdfTemplates);
  const { assignments } = useSelector((state: RootStateType) => state.editorSlate);
  const { templateName, templateTitle, templateDescription } = pdfTemplateInfo;
  const { templateDetails, doctypes } = useSelector((state: RootStateType) => state.user);
  const { templatePublicLink } = useSelector((state: RootStateType) => state.publicPages);
  const { isLoading } = useSelector((state: RootStateType) => state.errorLoading);
  // TODO: Template title is not used any more
  const [formErrors, setFormErrors] = useState<IErrorField>({ template_name: null, title: null });
  const [templateState, setTemplateState] = useState<IMetadataId[]>([{ id: 1 }]);
  const [templateDoctype, setTemplateDoctype] = useState<IMetadataId | null>(null);
  const [showShareLinkModal, setShowShareLinkModal] = useState<boolean>(false);
  const [isShowFillSignButton, setIsShowFillSignButton] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [isFormBuilderOpened, setIsFormBuilderOpened] = useState<boolean>(false);
  const [isFormBuilderViewActive, setIsFormBuilderViewActive] = useState<boolean>(true);

  const { permissionUsingPDFFormBuilder } = useRolePermission();
  // Set default reminders settings
  useSetDefaultReminderSettings();

  const pdfFormFieldsLength = Object.keys(pdfFormFields).length;

  useEffect(() => {
    if (id) {
      dispatch(getTemplateDetails(id));
    }
    return () => {
      setDefaultPageValuesHelper(dispatch);
      dispatch(setTemplateDetails(null));
      dispatch(clearPdfTemplateData());
      dispatch(setCreatedDocumentId(0));
    };
  }, [dispatch]);

  useEffect(() => {
    if (pdfFormFields.length) {
      createFieldsFromPdfFormfields(pdfFormFields, dispatch);
    }
  }, [pdfFormFieldsLength]);

  useEffect(() => {
    if (templateDetails) {
      if (getIsDocumentType(templateDetails.type)) {
        history.push(getEditorPageLink(id));
        return;
      }

      const [pdfFileUrl, pdfFileWidth] = getPdfUrlAndWidth(templateDetails.pdf_file_url);
      dispatch(setAllPdfFieldsAndInfo({
        fields: templateDetails.pdf_fields_json,
        info: {
          templateName: templateDetails.name,
          templateTitle: templateDetails.template_title,
          templateDescription: templateDetails.description,
        },
      }));
      dispatch(setPdfTemplateKey(pdfFileUrl));
      dispatch(setPdfTemplateWidth(parseInt(pdfFileWidth)));
      dispatch(setPdfTemplateFileLink(templateDetails.pdf_full_link));
      setTemplateState([{ id: templateDetails.states[0].id }]);
      setTemplateDoctype({ id: templateDetails.doctype.id });
    }
  }, [
    /**
     * It helps us to update redux store if it's really necessary.
     */
    templateDetails?.type,
    templateDetails?.pdf_file_url,
    templateDetails?.pdf_full_link,
    templateDetails?.pdf_fields_json,
    templateDetails?.name,
    templateDetails?.template_title,
    templateDetails?.description,
    templateDetails?.states,
    templateDetails?.doctype,
  ]);

  useEffect(() => {
    setIsShowFillSignButton(
      Boolean(id) && filterSortedFormFieldsFromJSONObject(pdfTemplateFields, true, MANAGER_ASSIGNMENT, true).length > 0,
    );
  }, [pdfTemplateFields, id]);

  useEffect(() => {
    if (!templateDoctype && doctypes.length) {
      const defaultDoctype = doctypes[0];
      setTemplateDoctype(defaultDoctype);
    }
  }, [doctypes, templateDoctype]);

  const validateFieldsNameTitle = (templateName: string) => {
    const validateArray = [
      { titleField: DOCUMENT_NAME_LABEL, nameField: 'template_name', valueField: templateName, required: true },
    ];
    const validateResult = validationForm(validateArray);
    setFormErrors((prev) => ({ ...prev, ...validateResult.validationFields }));
    return validateResult;
  };

  const onChangeTemplateName: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    dispatch(setPdfTemplateInfo('templateName', target.value));
    if (!templateTitle || templateTitle === templateName) {
      dispatch(setPdfTemplateInfo('templateTitle', target.value));
    }
  };

  const savePdfTemplateHandler = (openManagerPage: boolean = false) => {
    const validateNameResult = validateFieldsNameTitle(templateName);

    if (validateNameResult.isError) return null;
    if (!templateDoctype) return null;
    const selectedStates = templateState.map((state) => ({ id: state.id }));

    const updatedTemplateDetails = templateDetails ? {
      ...templateDetails,
      pdf_fields_json: pdfTemplateFields,
    } : {};

    const formBuilderStructure = templateDetails
      ? setFormBuilderGroupedFields(
        updateFormBuilder(templateDetails?.form_builder_structure, [updatedTemplateDetails]),
      )
      : null;

    const templateData: ITemplateRequestObj = {
      name: templateName.trim(),
      template_title: templateTitle.trim(),
      description: trimHTMLValue(templateDescription),
      doctype_id: templateDoctype.id,
      states: selectedStates,
      pdf_fields_json: pdfTemplateFields,
      pdf_file_url: `${pdfTemplateKey}?${pdfTemplateWidth}`,
      type: PDF_TYPE,
      is_single: isSingleDocument,
      ...(templateDetails && { form_builder_structure: formBuilderStructure }),
      assignments,
    };

    if (id) {
      return dispatch(editTemplate({
        id,
        template: templateData,
        ...(openManagerPage && { path: `${ROUTES.MANAGER_TEMPLATE}/${id}` }),
      }));
    }
    dispatch(createTemplate(templateData));
  };

  const savePdfTemplateAndShowManagerPage = () => {
    savePdfTemplateHandler(true);
  };

  const wrapperSavePdfTemplate = (
    redirectType: RedirectTypeEnum | undefined = undefined,
    redirectPath: string | undefined = undefined,
  ) => {
    savePdfTemplateHandler(false);
    if (redirectType && redirectPath) {
      history.push(redirectPath);
    }
  };

  const handlerShareLinkModal = () => {
    const save = savePdfTemplateHandler(false);
    if (!save) {
      setShowErrorModal(true);
    } else {
      dispatch(createTemplatePublicLink({ template_id: templateDetails.id, assignments }));
      setShowShareLinkModal(true);
    }
  };

  const onChangeDocumentDescription = (description: string) => {
    dispatch(setPdfTemplateInfo('templateDescription', description));
  };

  const openFormBuilderHandler = (isFormBuilderOpened: boolean) => {
    savePdfTemplateHandler(false);
    setIsFormBuilderOpened(isFormBuilderOpened);
  };

  const changeFormBuilderView = (isActive: boolean) => {
    setIsFormBuilderViewActive(isActive);
    dispatch(clearFormBuilderSelectedFieldsKeys());
  };

  return (
    <>
      <EditorWrapper>
        <EditorHeader
          showPreviewButton={false}
          documentName={templateName}
          onChangeDocumentName={onChangeTemplateName}
          errorText={formErrors.template_name}
          saveDocument={(
            redirectType: RedirectTypeEnum | undefined,
            redirectPath: string | undefined,
          ) => wrapperSavePdfTemplate(redirectType, redirectPath)}
          description={templateDescription}
          setDescription={onChangeDocumentDescription}
          showShareLinkModal={showShareLinkModal}
          isShareButtonAvailable={Boolean(id)}
          handlerShareLinkModal={handlerShareLinkModal}
          openManagerPageHandler={isShowFillSignButton ? savePdfTemplateAndShowManagerPage : undefined}
          isPdfDocument
          isFormBuilderAvailable={templateDetails && permissionUsingPDFFormBuilder}
          isFormBuilderOpened={isFormBuilderOpened}
          onOpenFormBuilder={openFormBuilderHandler}
        />
        {
          isFormBuilderOpened && (
            <div className="form-builder-view-switcher">
              <button
                type="button"
                onClick={() => changeFormBuilderView(false)}
                className={`form-builder-view-switcher__button ${!isFormBuilderViewActive ? ' active' : ''}`}
              >
                Document View
              </button>
              <button
                type="button"
                onClick={() => changeFormBuilderView(true)}
                className={`form-builder-view-switcher__button ${isFormBuilderViewActive ? ' active' : ''}`}
              >
                Form View
              </button>
            </div>
          )
        }
        <EditorContainer>
          {
            isFormBuilderOpened
              ? (
                <DocumentNavigator panelTitle="Form Builder Outline">
                  <FormBuilderOutline formBuilderType={FORM_BUILDER.PDF} />
                </DocumentNavigator>
              )
              : (
                <DocumentNavigator
                  panelTitle="Fields"
                  showPanelDefault={Boolean(pdfTemplateKey)}
                  renderChildrenUnderSidebar={(
                    <SearchFieldAndContent
                      content={[]}
                      setContent={() => null}
                      setString={() => null}
                    />
                  )}
                >
                  <PdfFieldsSidebar />
                </DocumentNavigator>
              )
          }
          {
            isFormBuilderOpened && isFormBuilderViewActive
              ? (
                <FormBuilder formBuilderType={FORM_BUILDER.PDF} />
              )
              : (
                <PdfEditor
                  visibleEditorDevTools={!isFormBuilderOpened}
                  isFormBuilderOpened={isFormBuilderOpened}
                />
              )
          }
        </EditorContainer>
      </EditorWrapper>
      <ShareLinkModal
        publicRoute={URL_PUBLIC_TEMPLATE}
        result={templatePublicLink.assignments}
        resourceGuid={templatePublicLink.origin}
        showModal={!isLoading && showShareLinkModal}
        onCloseModal={setShowShareLinkModal}
        deleteLinkFromStore={() => dispatch(deleteTemplateLinkFromStore())}
      />
      <ModalContentWindow
        showModal={Boolean(showErrorModal)}
        onCloseModal={setShowErrorModal}
        titleText={SOMETHING_WENT_WRONG}
        showDefaultText={false}
      >
        <div>Please fill out all required fields.</div>
      </ModalContentWindow>
    </>
  );
};

export default PdfDocumentDetails;