import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { FormFeedback, FormGroup, Input } from 'reactstrap';

import { Descendant } from 'slate';

import Editor from 'components/Editor';
import Footer from 'components/Footer';
import { INITIAL_EDITOR_VALUE } from 'constants/editors';
import { SECTION_NAME_LABEL } from 'constants/general';
import ROUTES from 'constants/routes';
import { useRolePermission } from 'hooks/useRolePermission';
import { SelectOption } from 'types/Misc';
import { ICreateSectionProps } from 'types/Sections';
import { IErrorField } from 'types/validation';
import { IApiVars } from 'utils/createApiVars';
import setDefaultPageValuesHelper from 'utils/reduxHelpers';
import { validationForm } from 'utils/validation';

const CreateSection: React.FC<ICreateSectionProps> = (props) => {
  const { states, createSection } = props;
  const dispatch = useDispatch();

  const [formErrors, setFormErrors] = useState<IErrorField>({
    section_name: null,
  });
  const [api_vars, setApiVars] = useState<IApiVars[] | null>(null); // eslint-disable-line
  const [sectionName, setSectionName] = useState<string>('');
  const [sectionContent, setSectionContent] = useState<Descendant[]>(INITIAL_EDITOR_VALUE);
  const [sectionStates, setSectionStates] = useState<SelectOption[]>([]);
  const { permissionUsingStates } = useRolePermission();

  useEffect(() => () => {
    setDefaultPageValuesHelper(dispatch);
  }, []);

  const validateSectionName = (sectionName: string) => {
    const validateResult = validationForm([
      { titleField: SECTION_NAME_LABEL, nameField: 'section_name', valueField: sectionName, required: true },
    ]);
    setFormErrors((prev) => ({ ...prev, ...validateResult.validationFields }));
    return validateResult;
  };

  const onChangeSectionName: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    validateSectionName(target.value);
    setSectionName(target.value);
  };

  const onChangeSectionContent = (content: Descendant[]) => {
    setSectionContent(content);
  };

  const onChangeApiVars = (api_vars: IApiVars[]) => { // eslint-disable-line
    setApiVars(api_vars);
  };

  const createNewSection = () => {
    const validateResult = validateSectionName(sectionName);
    if (validateResult.isError) return;

    const newSection = {
      name: sectionName.trim(),
      description: '',
      content_json: sectionContent,
      states: sectionStates.map((state) => ({ id: state.value })),
    };
    createSection(newSection);
  };

  return (
    <div className="w-100 mx-auto">
      <FormGroup className="mb-4">
        <Input
          name="section_name"
          value={sectionName}
          onChange={onChangeSectionName}
          invalid={!!formErrors.section_name}
        />
        <FormFeedback>{formErrors.section_name}</FormFeedback>
      </FormGroup>
      <div className="mb-3">
        <Editor
          visibleEditorDevTools
          content={sectionContent}
          viewFormsTitle={sectionName}
          onChange={onChangeSectionContent}
          onChangeApiVars={onChangeApiVars}
          showDescription={false}
        />
      </div>
      <Footer
        multipleState
        states={permissionUsingStates ? states : undefined}
        selectState={setSectionStates}
        selectedStateList={sectionStates}
        to={ROUTES.SECTIONS}
        saveDoc={createNewSection}
      />
    </div>
  );
};

export default CreateSection;