import { Editor as SlateEditor, Element as SlateElement, Range } from 'slate';

import { ListsEditor } from 'components/Editor/editor-custom-plugins/lists';
import { isInList } from 'components/Editor/editor-custom-plugins/lists/lib';
import {
  BULLETED_LIST,
  LIST_ITEM,
  NUMBERED_LIST,
  PARAGRAPH,
  WHITE_SPACE,
} from 'constants/editors';
import { toggleBlock } from 'utils/editorBlockHelpers';
import { getTextListType } from 'utils/editorCopyPasteHelpers';

export const createListFromTextByMarkers = (editor: SlateEditor, event: React.KeyboardEvent): boolean => {
  if (!editor.selection || Range.isExpanded(editor.selection) || isInList(editor as ListsEditor)) {
    return false;
  }

  const parentNode = SlateEditor.above(editor, {
    match: (n) => SlateElement.isElement(n) && n.type === PARAGRAPH,
  });

  if (parentNode) {
    const listItemType = getTextListType(parentNode[0].children[0].text + WHITE_SPACE || '');
    const isStartOfRow = editor.selection.anchor.offset === (listItemType === LIST_ITEM ? 1 : 2);
    if (isStartOfRow && listItemType) {
      const listType = listItemType === LIST_ITEM ? BULLETED_LIST : NUMBERED_LIST;

      editor.deleteBackward('character');
      if (listType === NUMBERED_LIST) {
        editor.deleteBackward('character');
      }

      toggleBlock(editor, listType);
      event.preventDefault();
      return true;
    }
  }

  return false;
};

export const setEditorListMarkStyle = (element: HTMLElement): object => {
  const { dataset } = element;
  if (!dataset || !dataset.listStyle) {
    // A parsed element from Web, MS Word, etc.
    const listItemText = element.firstChild?.textContent || '';
    if (listItemText.length) {
      // The element has a defined CSS style 'list-style' or 'list-style-type'
      if (element.style?.listStyle && ['decimal'].includes(element.style?.listStyle)) {
        // Use the default numbered list style we support
        return { type: NUMBERED_LIST, listStyle: '' };
      }
      const itemType = getTextListType(listItemText, true);
      if (itemType?.includes(NUMBERED_LIST)) {
        // If the element has a numbered list marker as a part of the content, we set the list type
        return { type: NUMBERED_LIST, listStyle: '' };
      }
    }

    return {};
  }

  // It must be our own Editor element, which have the attribute 'data-list-type'
  return { listStyle: dataset.listStyle };
};

export default null;