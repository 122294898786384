import { Dispatch } from 'redux';

import { setCreatedDocumentId } from 'store/actions/publicPages';
import { CollectionAttachments, CollectionDocumentResponseObj } from 'types/MultiTemplate';

const { REACT_APP_API_URL } = process.env;

export const getSubmitButton = () => document.getElementById('attachments-loading');

export const runAttachmentsLoading = (documentId: string) => {
  const myDropZoneBtn = getSubmitButton();
  if (!myDropZoneBtn) return;

  myDropZoneBtn.setAttribute('document-id', documentId);
  myDropZoneBtn.click();
};

export const setMultiAttachmentNodeOptions = (
  dispatch: Dispatch,
  createdDocuments: CollectionDocumentResponseObj[],
  documentsAttachments: CollectionAttachments[],
  executedCollection: boolean = false,
) => {
  const myDropZoneBtn = getSubmitButton();
  if (!myDropZoneBtn) return;

  myDropZoneBtn.setAttribute('multitemplates', 'true');
  createdDocuments.forEach((documentData: CollectionDocumentResponseObj) => {
    let attachments: CollectionAttachments | undefined;
    if (executedCollection) {
      attachments = documentsAttachments.find((attachments: CollectionAttachments) => (
        attachments.templateId === documentData.template_id
      ));
    } else {
      attachments = documentsAttachments.find((attachments: CollectionAttachments) => (
        attachments.templateId === documentData.template_id
      ));
    }
    if (attachments) {
      attachments.fieldIds.forEach((fieldKey: number) => {
        dispatch(setCreatedDocumentId(Number(documentData.document_id)));
        const currentNode: any = document.querySelector(`.dropzone.attachment-field-${fieldKey}`);
        const currentDropzone = currentNode?.dropzone;
        if (currentDropzone) {
          currentDropzone.options.customParams.allNodes = attachments?.fieldIds;
          currentDropzone.options.postUrl = `${REACT_APP_API_URL}/document/${documentData.document_id}/files`;
          currentDropzone.options.url = `${REACT_APP_API_URL}/document/${documentData.document_id}/files`;
          currentDropzone.options.documentId = documentData.document_id;
        }
      });
    }
  });
  myDropZoneBtn.click();
};

export const removeAllFilesFromDropzone = (fieldKey: number): void => {
  const dropzoneElement: HTMLElement | null = document?.querySelector(`.dropzone.attachment-field-${fieldKey}`);
  const dropzone = dropzoneElement?.dropzone;
  if (dropzone) {
    dropzone.removeAllFiles(true);
    dropzone.destroy();
  }
};