import { FC } from 'react';

import PageSetting from 'components/PageSettings/PageSetting';
import { IPageSettingProps, IPageSettingsSchemaElement } from 'types/PageSettingsType';

const ContainerSetting: FC<IPageSettingProps> = ({ setting }) => {
  const values = setting.value as IPageSettingsSchemaElement[];

  return (
    <div className="page-settings-modal__container">
      {values.map((childSetting) => (
        <PageSetting key={childSetting.key} setting={childSetting} />
      ))}
    </div>
  );
};

export default ContainerSetting;