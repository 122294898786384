import { PDFFieldType } from 'types/PdfTemplates';

export const compareSelectFieldsOptions = (
  firstGroupedField: PDFFieldType | null,
  updatedField: PDFFieldType,
) => {
  const oldOptionsLabels = firstGroupedField?.options?.map((option) => option.label);
  const newOptionsLabels = updatedField.options?.map((option) => option.label);
  return oldOptionsLabels?.length === newOptionsLabels?.length
    && oldOptionsLabels?.every((label) => newOptionsLabels?.includes(label));
};

export const getPDFOptionsObject = ({
  updateSelectOptions,
  areOptionsTheSame,
  updatedField,
  firstGroupedField,
  pdfField,
}: {
  updateSelectOptions: boolean;
  areOptionsTheSame?: boolean;
  updatedField: any;
  firstGroupedField: any;
  pdfField: any;
}) => {
  const currentOptions = pdfField.key === updatedField.key ? firstGroupedField?.options : pdfField.options;
  if (updateSelectOptions) return { options: updatedField.options };
  if (firstGroupedField) {
    return { options: areOptionsTheSame ? pdfField.options : currentOptions };
  }
  return {};
};

export const getSlateOptionsObject = ({
  updateSelectOptions,
  existingGroupedField,
  updatedField,
}: {
  updateSelectOptions: boolean;
  existingGroupedField: any;
  updatedField: any;
}) => {
  if (updateSelectOptions) return { options: updatedField.options };
  return existingGroupedField ? { options: existingGroupedField.options } : {};
};