import { useSelector } from 'react-redux';

import { RootStateType } from 'store/reducers';
import { IPublicPageState } from 'types/PublicPage';

import classes from 'scss/components/Intro/DocumentInfo.module.scss';

const DocumentSenderInfo = () => {
  const { data }: IPublicPageState = useSelector((state: RootStateType) => state.publicPages);

  if (data?.is_sender_hide) return null;

  const {
    first_name: firstName,
    last_name: lastName,
    email,
  } = data?.sender || {};

  const companyName = data?.company_name;

  return (
    <div className={classes.DocumentInfo}>
      <h3>
        Sender
      </h3>
      <p>
        {firstName} {lastName} {email && `<${email}>`}
      </p>
      <h3>
        Company
      </h3>
      <p>
        {companyName}
      </p>
    </div>
  );
};

export default DocumentSenderInfo;