import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Descendant } from 'slate';

import { PARAGRAPH } from 'constants/editors';
import { updateTemplateSections } from 'store/actions/template';
import { RootStateType } from 'store/reducers';
import { ITemplateSection } from 'types/redux';
import { arrayFieldMove } from 'utils/arrayMove';
import { generateNumber } from 'utils/editorFieldHelpers';

interface ISortEndHandlerProps {
  oldIndex: number;
  newIndex: number;
  allSections: Descendant[];
  sectionKey: number;
}

type SortEndHandlerType = (props: ISortEndHandlerProps) => void;

const useSortFields = () => {
  const dispatch = useDispatch();
  const { activeHoveredSectionKey } = useSelector((state: RootStateType) => state.editorSlate);

  const onSortEndHandler: SortEndHandlerType = useCallback(({
    oldIndex,
    newIndex,
    allSections,
    sectionKey,
  }) => {
    const sectionInContent = allSections.find((section) => section.key === sectionKey);
    if (!(sectionInContent && sectionInContent.children)) return null;
    if (sectionKey === activeHoveredSectionKey) {
      const sortedFields = arrayFieldMove(sectionInContent.children, oldIndex, newIndex);
      const sortedSections: unknown[] = allSections.map((section) => {
        if (section.key === sectionKey) return { ...section, children: sortedFields };
        return { ...section };
      });
      dispatch(updateTemplateSections(sortedSections as ITemplateSection[]));
    } else {
      const newSectionForDropField = allSections
        .find((section: Descendant): boolean => section.key === activeHoveredSectionKey);
      if (!(newSectionForDropField && newSectionForDropField.children)) return null;

      const currentChild = sectionInContent.children.at(oldIndex);
      if (!currentChild) return null;

      const updatedChildren = sectionInContent.children.filter((_, index) => index !== oldIndex);

      const sortedSections: unknown[] = allSections.map((section) => {
        if (section.key === sectionKey) {
          return {
            ...section,
            children: updatedChildren.length
              ? updatedChildren
              : [{
                type: PARAGRAPH,
                key: generateNumber(),
                children: [
                  { text: '' },
                ],
              }],
          };
        }
        if (section.key === activeHoveredSectionKey) {
          return {
            ...section,
            children: section.children
              ? [
                ...section.children,
                currentChild,
              ]
              : [currentChild],
          };
        }
        return { ...section };
      });

      dispatch(updateTemplateSections(sortedSections as ITemplateSection[]));
    }
  }, [activeHoveredSectionKey, dispatch]);

  return onSortEndHandler;
};

export default useSortFields;