import { useEffect, useState } from 'react';

const getWindowSize = (): [number, number] => {
  const { innerWidth, innerHeight } = window;
  return [innerWidth, innerHeight];
};

const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState<[number, number]>(getWindowSize());
  useEffect(() => {
    const handleWindowResize = () => setScreenSize(getWindowSize());
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  return screenSize;
};

export default useScreenSize;