import { FC } from 'react';

import AccountSettings from 'components/AccountSettings/AccountSettings';
import AccountSettingsWrapper from 'components/AccountSettings/AccountSettingsWrapper';
import RadioInputGroup from 'components/AccountSettings/RadioInputGroup';
import SettingsLayout from 'components/AccountSettings/SettingsLayout';
import Button from 'components/Base/Button';
import SimpleSpinner from 'components/Base/SimpleSpinner';
import { RESET, UPDATE } from 'constants/general';
import { EmailNotificationSettingsOption, NOTIFICATION_OPTIONS } from 'constants/notifications';
import { PROFILE_SECTIONS } from 'constants/UserMenu';
import useEmailNotificationsSettingsHandler from 'hooks/Settings/useCompanyNotificationHandler';
import useRadioButtonsGroupHandler from 'hooks/Settings/useRadioButtonsGroupHandler';
import useSendgridSettings from 'hooks/Settings/useSendgridSettings';

const Notifications: FC = () => {
  const {
    permissionUsingWlSendgrid,
    error,
    sendgridSettingsData,
    handlerSendgridSettingsSubmit,
    resetSendgridSettingsChanges,
    handleChangeSendgridSettings,
  } = useSendgridSettings();

  const {
    onClickUpdateHandler,
    onClickResetHandler,
    isLoading,
    notificationValue,
  } = useEmailNotificationsSettingsHandler();

  const {
    value,
    defaultValue,
    handleChange,
  } = useRadioButtonsGroupHandler<EmailNotificationSettingsOption>({
    options: NOTIFICATION_OPTIONS,
    defaultValue: notificationValue,
  });

  return (
    <AccountSettingsWrapper>
      {
        permissionUsingWlSendgrid && (
          <AccountSettings
            error={error}
            fields={sendgridSettingsData}
            title={PROFILE_SECTIONS.SENDGRID_SETTINGS}
            updateClickHandler={handlerSendgridSettingsSubmit}
            cancelClickHandler={resetSendgridSettingsChanges}
            handleChangeData={handleChangeSendgridSettings}
          />
        )
      }
      {
        isLoading
          ? <SimpleSpinner />
          : (
            <SettingsLayout title="Notifications">
              <div className="mb-3">
                <RadioInputGroup<EmailNotificationSettingsOption>
                  name="notifications"
                  options={NOTIFICATION_OPTIONS}
                  value={value}
                  defaultValue={defaultValue}
                  handleChange={handleChange}
                />
              </div>
              <Button classes="me-3" onClick={() => onClickUpdateHandler(value)}>
                {UPDATE}
              </Button>
              <Button classes="outline" onClick={onClickResetHandler}>
                {RESET}
              </Button>
            </SettingsLayout>
          )
      }
    </AccountSettingsWrapper>
  );
};

export default Notifications;