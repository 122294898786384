import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSwiper } from 'swiper/react';
import { RootStateType } from 'store/reducers';

type PropsType = {
  isPublicPage?: boolean;
}

const SlideWrapper: FC<PropsType> = ({
  children,
  isPublicPage = false,
}) => {
  const swiper = useSwiper();
  const { isButtonSubmit } = useSelector((state: RootStateType) => state.validation);
  const currentDocument = useSelector((state: RootStateType) => state.publicPages.currentDocument);
  const { publicFormValidationResults } = useSelector((state: RootStateType) => state.editorSlate);
  const currentSlideIndex = currentDocument?.currentSlideIndex || 1;

  useEffect(() => {
    if (isPublicPage && swiper) {
      setTimeout(() => { swiper.update(); }, 0);
    }
  }, [isButtonSubmit, publicFormValidationResults]);

  useEffect(() => {
    if (isPublicPage && swiper && swiper.activeIndex !== currentSlideIndex - 1) {
      swiper.slideTo(currentSlideIndex - 1);
    }
  }, [currentSlideIndex]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      { children }
    </>
  );
};

export default SlideWrapper;