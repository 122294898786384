import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Element as SlateElement } from 'slate';

import { COLLECTION_TYPE, FORM_TYPE } from 'constants/general';
import { ShareViewModes } from 'constants/shareViewModes';
import {
  ATTACHMENTS_TAB_ID,
  EDITOR_PAGE_TABS,
  FORM_TAB_ELEMENT,
  FORM_TAB_ID,
} from 'constants/tabs';
import { setCurrentDocumentActiveTab } from 'store/actions/publicPages';
import { RootStateType } from 'store/reducers';
import { ITab, PublicPageNavigation } from 'types/Tabs';

const usePublicPageNavigation = (): PublicPageNavigation => {
  const dispatch = useDispatch();
  const { main } = useSelector((state: RootStateType) => state.publicPages.structure);
  const { data, currentDocument } = useSelector((state: RootStateType) => state.publicPages);

  const {
    attachmentFields,
    viewMode,
    documentType,
    activeEditorTab,
  } = currentDocument;

  const [navigationTabs, setNavigationTabs] = useState<PublicPageNavigation>(EDITOR_PAGE_TABS);

  useEffect(() => {
    let tabsCopy = EDITOR_PAGE_TABS;
    if (!attachmentFields?.some((field: Partial<SlateElement>) => field.assignment === main.mainAssignment)) {
      tabsCopy = tabsCopy.filter((item: ITab) => item.id !== ATTACHMENTS_TAB_ID);
    }

    if (
      (documentType === COLLECTION_TYPE || data.type === COLLECTION_TYPE)
      && data.view_mode === ShareViewModes.SHARE_MODE_DOCUMENT_AND_FORM_BUILDER
    ) {
      switch (documentType) {
        case COLLECTION_TYPE:
          setNavigationTabs([FORM_TAB_ELEMENT]);
          break;
        case FORM_TYPE:
          tabsCopy = tabsCopy.filter((item: ITab) => item.id === FORM_TAB_ID);
          setNavigationTabs(tabsCopy);
          break;
        default:
          tabsCopy = tabsCopy.filter((item: ITab) => item.id !== FORM_TAB_ID);
          setNavigationTabs(tabsCopy);
      }
    } else {
      switch (viewMode) {
        case ShareViewModes.SHARE_MODE_DOCUMENT:
          tabsCopy = tabsCopy.filter((item: ITab) => item.id !== FORM_TAB_ID);
          setNavigationTabs(tabsCopy);
          break;
        case ShareViewModes.SHARE_MODE_FORM:
          tabsCopy = tabsCopy.filter((item: ITab) => item.id === FORM_TAB_ID);
          if (activeEditorTab !== FORM_TAB_ID) {
            dispatch(setCurrentDocumentActiveTab(FORM_TAB_ID));
          }
          setNavigationTabs(tabsCopy);
          break;
        case ShareViewModes.SHARE_MODE_DOCUMENT_AND_FORM_BUILDER:
          setNavigationTabs(tabsCopy);
          break;
        case ShareViewModes.SHARE_MODE_BOTH:
        default:
          setNavigationTabs(tabsCopy);
      }
    }
  }, [attachmentFields, currentDocument]);

  return navigationTabs;
};

export default usePublicPageNavigation;