import { FC, memo, useCallback, useState } from 'react';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { SxProps, Theme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import { StyledTextField } from 'components/Editor/FormView/Fields/PublicFieldView';
import { FORM_TEXTAREA_ROWS_COUNT } from 'constants/editors';
import { TEXT_FIELD_MASKS } from 'constants/fieldPropertiesTab';
import { STRING_INPUT_MAX_LEN, TEXTAREA_MAX_LEN } from 'constants/validation';
import useFieldMaskValue from 'hooks/useFieldMaskValue';
import useInputField from 'hooks/useInputField';
import { CssStyleObjectType } from 'types/BrandingColorSchema';

interface IInputField {
  name: string;
  label: string;
  initValue: string;
  onChangeInitValue: (value: string) => void;
  onFocus: () => void;
  maxLength: string | number;
  styles: CssStyleObjectType | undefined;
  isRequired: boolean;
  isError: boolean;
  isReadOnly: boolean;
  isQuestion: boolean;
  isPDF: boolean;
  errorMessage: string;
  helpMessage: string;
  borderFieldStyle: SxProps<Theme> | null;
  errorIcon: JSX.Element | null;
  isTextArea?: boolean;
  fieldMaskType?: TEXT_FIELD_MASKS;
}

const FormInputField: FC<IInputField> = ({
  name,
  label,
  initValue,
  onChangeInitValue,
  onFocus,
  maxLength,
  styles,
  isRequired,
  isError,
  isReadOnly,
  isQuestion,
  isPDF,
  errorMessage,
  helpMessage,
  borderFieldStyle,
  errorIcon,
  isTextArea = false,
  fieldMaskType = TEXT_FIELD_MASKS.NONE,
}) => {
  const existingFieldMask = Boolean(fieldMaskType) && fieldMaskType !== 'none';
  const [hideValue, setHideValue] = useState<boolean>(existingFieldMask);
  const { value, onChangeValue } = useInputField({ initValue, onChangeInitValue, maxLength });

  const { hiddenValue, onChangeValueWithMask } = useFieldMaskValue({
    fieldMaskType,
    hideValue,
    realValue: initValue,
    onChangeRealValue: onChangeValue,
  });

  const getEndAdornment = useCallback(() => {
    if (isError) {
      return errorIcon;
    }
    if (existingFieldMask) {
      return (
        <InputAdornment position="end">
          <IconButton
            onClick={() => setHideValue((prevState) => !prevState)}
            edge="end"
          >
            {hideValue ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      );
    }
    return null;
  }, [isError, errorIcon, existingFieldMask, hideValue]);

  return (
    <StyledTextField
      type={isTextArea ? 'textarea' : 'text'}
      value={!hideValue ? value : hiddenValue}
      variant="outlined"
      multiline={isTextArea}
      size="small"
      onChange={!hideValue ? onChangeValue : onChangeValueWithMask}
      name={name}
      label={label}
      fullWidth
      onFocus={onFocus}
      style={styles}
      required={isRequired}
      error={isError}
      disabled={isReadOnly}
      sx={isQuestion ? null : borderFieldStyle}
      helperText={isError ? errorMessage : helpMessage}
      InputProps={{
        endAdornment: getEndAdornment(),
        inputProps: {
          ...(isPDF && !isQuestion && { maxLength: STRING_INPUT_MAX_LEN }),
          ...(isTextArea && { maxLength: TEXTAREA_MAX_LEN }),
        },
      }}
      rows={FORM_TEXTAREA_ROWS_COUNT}
    />
  );
};

export default memo(FormInputField);