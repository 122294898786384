import {
  AUTHENTICATE_USER,
  CHECK_RESET_PASSWORD,
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  RESET_PASSWORD,
  RESET_PASSWORD_LINK,
  SET_ERROR,
  SET_LOADED,
} from 'store/actions/actionTypes';

export const loginUser = (user: any) => ({
  type: LOGIN_USER,
  payload: user,
});

export const registerUser = (user: any) => ({
  type: REGISTER_USER,
  payload: user,
});

export const authenticateUser = (payload: any) => ({
  type: AUTHENTICATE_USER,
  payload,
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
});

export const setError = (error: any) => ({
  type: SET_ERROR,
  payload: error,
});

export const resetPasswordLink = (data: { email: string }) => ({
  type: RESET_PASSWORD_LINK,
  payload: data,
});

export const checkResetPassword = (linkId: string) => ({
  type: CHECK_RESET_PASSWORD,
  payload: linkId,
});

export const resetPassword = (linkId: string, password: string) => ({
  type: RESET_PASSWORD,
  payload: {
    linkId,
    password,
  },
});

export const setIsLoaded = (loaded: boolean) => ({
  type: SET_LOADED,
  payload: loaded,
});