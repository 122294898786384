/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import { Editor as SlateEditor, Element as SlateElement, Path, Text, Transforms } from 'slate';

import {
  DROP_BUFFER_FILLER,
  INTENDANTS_REGEX,
  NEW_LINE_REGEX,
  START_BULLETED_REGEX,
  START_INTENDANTS_REGEX,
  START_NUMBER_POINT_REGEX,
} from 'constants/copyPaste';
import {
  BULLETED_LIST,
  FIELD_TYPE,
  IMAGE_TYPE,
  LIST_ITEM,
  LIST_ITEM_TYPES,
  LIST_TYPES,
  NUMBERED_LIST,
  PARAGRAPH,
  SECTION,
} from 'constants/editors';
import {
  checkFragmentAndUpdateAssignments,
  getStylesFromWordDocument,
  getTextListType,
  makeDeserializer,
  transformToTopLevelNodes,
  updateEmptyChildrenArrays,
} from 'utils/editorCopyPasteHelpers';

export const withCopyPaste = (editor: SlateEditor) => {
  const { insertData, isInline, isVoid, normalizeNode } = editor;
  const deserializeContent = makeDeserializer();

  editor.isInline = (element) => (
    FIELD_TYPE.includes(element.type) || element.type === 'link' ? true : isInline(element)
  );

  editor.isVoid = (element) => (element.type === IMAGE_TYPE ? true : isVoid(element));

  editor.insertData = (data) => {
    const html = data.getData('text/html');
    const slateFragment = data.getData('application/x-slate-fragment');
    const text = data.getData('text/plain');

    if (html) {
      const parsedHtml = new DOMParser().parseFromString(html, 'text/html');
      const cssRules = getStylesFromWordDocument(parsedHtml.head);
      const fragment = deserializeContent(parsedHtml.body, { cssRules });
      checkFragmentAndUpdateAssignments(fragment, editor.dispatch);
      const prettyNodeArray = transformToTopLevelNodes(fragment);
      const prettyNodeArrayWithoutEmptyNodes = updateEmptyChildrenArrays(prettyNodeArray);
      SlateEditor.normalize(editor, { force: true });
      Transforms.insertFragment(editor, prettyNodeArrayWithoutEmptyNodes);
      return;
    }

    if (!slateFragment && text) {
      const lines = text.split(NEW_LINE_REGEX);
      const newLines: string[] = [];

      Object.values(lines).forEach((line) => {
        const itemType = getTextListType(line, true);
        if (itemType) {
          const type = itemType === LIST_ITEM ? 'ul' : 'ol';
          const lineTextNoBullets = line
            .replace(START_INTENDANTS_REGEX, '')
            .replace(START_NUMBER_POINT_REGEX, '')
            .replace(START_BULLETED_REGEX, '')
            .replace(INTENDANTS_REGEX, '');
          newLines.push(`<${type}><li>${lineTextNoBullets}</li></${type}>`);
        } else if (line !== DROP_BUFFER_FILLER) {
          newLines.push(`<p>${line}</p>`);
        }
      });
      const newLinesString = newLines.join('').replace(/(<\/ul><ul>|<\/ol><ol>)/gi, '');
      const parsed: Document = new DOMParser()
        .parseFromString(`<body>${newLinesString}</body>`, 'text/html');
      const fragment = deserializeContent(parsed.body);
      if (fragment) {
        Transforms.insertFragment(editor, fragment);
        return;
      }
    }

    insertData(data);
  };

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    const parentListNode = SlateEditor.above(editor, {
      match: (n) => SlateElement.isElement(n) && LIST_TYPES.includes(n.type),
      at: path,
    });

    if (SlateElement.isElement(node)) {
      // Wrap into <ul> or <ol> if there are <li> nodes without wrapper
      if (LIST_ITEM_TYPES.includes(node.type)) {
        if (!parentListNode && path.length === 2) {
          const listType = node.type === LIST_ITEM ? BULLETED_LIST : NUMBERED_LIST;
          Transforms.wrapNodes(editor, { type: listType, children: [] }, { at: path, mode: 'lowest' });
          return;
        }
      }

      // Disable <p> inside <ul> or <ol> - allowed only <div> and text
      if (node.type === PARAGRAPH && parentListNode) {
        Transforms.unwrapNodes(editor, { at: parentListNode[1] });
        return;
      }

      // -TODO: remove? --------------------------Disable variants: ul ul, ol ol
      if (LIST_TYPES.includes(node.type)) {
        if (path.length === 2 && path[0] !== 0 && path[1] !== 0) {
          const contentBefore = node.type === NUMBERED_LIST && Array.from(SlateEditor.nodes(editor, {
            match: (n) => SlateElement.isElement(n) && n.type === NUMBERED_LIST,
            at: Path.previous(path),
          }));
          if (contentBefore && contentBefore[0]) {
            Transforms.mergeNodes(editor, {
              match: (n) => SlateElement.isElement(n) && n.type === NUMBERED_LIST,
              at: path,
            });
          }
        }
      }

      if (node?.type === PARAGRAPH && node.children?.length === 1 && node.key) {
        return;
      }
    }

    if (Text.isText(node)) {
      const parentListItem = SlateEditor.above(editor, {
        match: (n) => SlateElement.isElement(n) && LIST_ITEM_TYPES.includes(n.type),
        at: path,
      });

      if (node.text && !parentListItem) {
        const listItemType = getTextListType(node.text);
        if (listItemType) {
          // When we type Enter - keep the text as usual
          const parentNode = SlateEditor.above(editor, {
            match: (n) => SlateElement.isElement(n),
            at: path,
          });
          const parentElement = parentNode && parentNode[0];
          if (parentElement?.type === PARAGRAPH) {
            return;
          }
        }
      }

      // deny to type simple text inside editor with no wrapper element
      if (node.text.length === 0) {
        const parentWrapper = SlateEditor.above(editor, {
          match: (n) => SlateElement.isElement(n) && n.type !== SECTION,
          at: path,
        });
        if (!parentWrapper) {
          Transforms.wrapNodes(editor, { type: PARAGRAPH, children: [] }, { at: path });
        }
      }
    }

    // Fall back to the original `normalizeNode` to enforce other constraints.
    normalizeNode(entry);
  };

  return editor;
};