const AddTableColumnLeftIcon = () => (
  <svg
    style={{ transform: 'rotate(180deg)' }}
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-column-insert-right"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M6 4h4a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1z" />
    <line x1="15" y1="12" x2="19" y2="12" />
    <line x1="17" y1="10" x2="17" y2="14" />
  </svg>
);

export default AddTableColumnLeftIcon;