import axios from 'axios';
import moment from 'moment';

import { URL_API_IPIFY } from 'constants/general';
import { SUCCESS_CODE } from 'constants/generalErrors';
import { saveDocumentExecutedLog } from 'services/api/serverSideEvents';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { HISTORY_ACTIONS, HistoryRecordType } from 'types/DocumentsHistory';

const createMessage = (record: HistoryRecordType) => {
  switch (record.action_type) {
    case HISTORY_ACTIONS.SENT_EMAIL:
      return `TZ: Sent for signature to ${record.recipient_name} <${record.recipient_email}> from`;
    case HISTORY_ACTIONS.EMAIL_OPENED:
      return 'TZ: Email is opened by';
    case HISTORY_ACTIONS.VIEWED:
      return 'TZ: Viewed by';
    case HISTORY_ACTIONS.DELETED_SIGNATURE:
      return 'TZ: Deleted signature by';
    case HISTORY_ACTIONS.PASSWORDLESS_AUTH:
      return 'TZ: Passwordless authentication';
    case HISTORY_ACTIONS.SMS_VERIFIED:
      return `TZ: SMS verified: ${record.user_phone}`;
    case HISTORY_ACTIONS.CHANGE_STATUS:
      return `TZ: Changed status from '${record.from_status}' to '${record.to_status}' by`;
    default:
      if (record.action_type.startsWith(HISTORY_ACTIONS.SIGNED)) {
        return 'TZ: Signed by';
      }
      return 'TZ: Action by';
  }
};

export const getUTCDate = () => (
  new Date().toISOString()
);

const formatDate = (dateString: string) => (
  moment(dateString).format('YYYY/MM/DD HH:mm Z')
);

export const getCurrentIPAdrress = async () => {
  try {
    const response = await axios.get(URL_API_IPIFY);
    if (response.status === SUCCESS_CODE) {
      return response.data.ip;
    }
    return 'unknown';
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(error);
    return 'unknown';
  }
};

export const createActionMessage = (record: HistoryRecordType) => (
  `${formatDate(record.action_date)}${createMessage(record)} ${record.name} <${record.email}> ${record.ip_address}`
);

export const saveHistoryRecord = (action: HISTORY_ACTIONS | string, documentExecutedId: number) => {
  apiErrorHandler(
    saveDocumentExecutedLog,
    documentExecutedId,
    { [action]: getUTCDate() },
  );
};