import { ChangeEvent, FC } from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

import {
  MondayComBoardType,
  MondayComIntegrationObjectType,
  MondayComWorkspaceType,
} from 'types/mondayCom/mondayComBoard';

interface IBoardsAccordion {
  workspaceId: string;
  workspace: MondayComWorkspaceType;
  boardsInWorkspace: MondayComBoardType[];
  onSelectBoardsHandler: (event: ChangeEvent<HTMLInputElement>, boardId: string) => void;
  templateIntegrationData: MondayComIntegrationObjectType[] | null;
}

const BoardsAccordion: FC<IBoardsAccordion> = ({
  workspaceId,
  workspace,
  boardsInWorkspace,
  onSelectBoardsHandler,
  templateIntegrationData,
}) => (
  <div className="accordion">
    <div className="accordion-item">
      <h2 className="accordion-header" id={`heading${workspaceId}`}>
        <button
          className="accordion-button collapsed text-capitalize"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapse${workspaceId}`}
          aria-expanded="true"
          aria-controls={`collapse${workspaceId}`}
        >
          {workspace.name}
        </button>
      </h2>
      <div
        id={`collapse${workspaceId}`}
        className="accordion-collapse collapse"
        aria-labelledby={`heading${workspaceId}`}
      >
        <div className="accordion-body">
          <FormGroup>
            {
              boardsInWorkspace.map((board) => (
                <FormControlLabel
                  key={board.id}
                  control={
                    (
                      <Checkbox
                        checked={
                          templateIntegrationData?.map(
                            (boardObject) => boardObject.board.id,
                          ).includes(board.id) || false
                        }
                        onChange={(event) => onSelectBoardsHandler(event, board.id)}
                      />
                    )
                  }
                  label={board.name}
                />
              ))
            }
          </FormGroup>
        </div>
      </div>
    </div>
  </div>
);

export default BoardsAccordion;