import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { URL_PUBLIC_COLLECTION, URL_PUBLIC_DOCUMENT, URL_PUBLIC_TEMPLATE } from 'constants/general';
import { NO_BOARDS } from 'constants/mondayComIntegration';
import usePublicPageRouteMatch from 'hooks/usePublicPageRouteMatch';
import useSearchParams from 'hooks/useSearchParams';
import {
  getPublicPageData,
  setDocumentUUID,
  setHistoryLogCurrentDocument,
  setIsSendLinkFlow,
  setMondayComBoard,
} from 'store/actions/publicPages';
import { updateTemplateSections } from 'store/actions/template';
import { RootStateType } from 'store/reducers';
import { HISTORY_ACTIONS } from 'types/DocumentsHistory';
import { FieldAssignmetsType } from 'types/Editor';
import { IPublicPageRouteMatch, PublicPageDataType } from 'types/PublicPage';
import { getMainTypeAssignment, isManagerAssignment } from 'utils/assignmentsHelpers';
import { getUTCDate, saveHistoryRecord } from 'utils/documentsHistory';
import getAssignmentByUrl from 'utils/getAssignmentByUrl';
import getSectionsFromContentJson from 'utils/getSectionsFromContentJson';
import { isNotEmptyObject } from 'utils/isEmptyObject';
import getPublicPageRequestURL from 'utils/PublicPage/getPublicPageRequestURL';

const usePublicPageData = () => {
  const dispatch = useDispatch();
  const { url, pageType, id }: IPublicPageRouteMatch = usePublicPageRouteMatch();
  const mainPageType: FieldAssignmetsType = getAssignmentByUrl(pageType);

  const queryParams = useSearchParams();
  const salt = queryParams.get('salt');
  const authToken = queryParams.get('auth-token');
  const targetBoard = queryParams.get('board');
  const smsValidation = queryParams.get('sms-validation');
  const documentUUID = queryParams.get('document-uuid');

  const requestUrl = getPublicPageRequestURL({ type: pageType, url, authToken, targetBoard });
  const data: PublicPageDataType = useSelector((state: RootStateType) => state.publicPages.data);
  const { mondayComBoard }: { mondayComBoard: string } = useSelector((state: RootStateType) => state.publicPages);

  const isSendFlowLink = Boolean(salt);

  // If there isn't 'assignment' query parameter in the url, default assignment is defined according to page type
  const assignmentFromUrl = queryParams.get('assignment') ?? window.btoa(getMainTypeAssignment(mainPageType));
  const mondayComBoardFromUrl = queryParams.get('board') ?? window.btoa(NO_BOARDS);
  const decodedAssignment = window.atob(assignmentFromUrl);
  const decodedMondayComBoard = window.atob(mondayComBoardFromUrl);

  useEffect(() => {
    if (smsValidation) {
      if (pageType === URL_PUBLIC_DOCUMENT && data?.id) {
        saveHistoryRecord(HISTORY_ACTIONS.SMS_VERIFIED, data.id);
      } else if ([URL_PUBLIC_TEMPLATE, URL_PUBLIC_COLLECTION].includes(pageType)) {
        dispatch(setHistoryLogCurrentDocument({
          target: HISTORY_ACTIONS.SMS_VERIFIED,
          value: getUTCDate(),
        }));
      }
    }
    if (documentUUID) {
      dispatch(setDocumentUUID(documentUUID));
    }
  }, [dispatch, smsValidation, data?.id, pageType, documentUUID]);

  useEffect(() => {
    if (!mondayComBoard) {
      dispatch(setMondayComBoard(decodedMondayComBoard));
    }
  }, [decodedMondayComBoard, mondayComBoard, dispatch]);

  useEffect(() => {
    /**
     * Note: data.id !== id when we change document (fill and sign).
     * For example if we open one document for fill and sign. We get data and save this data to Store.
     * After that, we want to open new document and get data from server. And here id will not equal to data.id.
     */
    if (!data || (isManagerAssignment(mainPageType) && data.id !== Number(id))) {
      dispatch(getPublicPageData({ url: requestUrl, type: pageType, id, salt, decodedAssignment }));
    }
  }, [requestUrl, data]);

  /**
   * The side effect here can be unexpected.
   * Hook name usePublicPageData.
   * But here we have `dispatch updateTemplateSections`.
   * This `dispatch` can be unexpected behavior.
   */
  useEffect(() => {
    if (isNotEmptyObject(data)) {
      dispatch(updateTemplateSections(getSectionsFromContentJson(data, decodedAssignment)));
    }
  }, [data]);

  useEffect(() => {
    dispatch(setIsSendLinkFlow(isSendFlowLink));
  }, [isSendFlowLink]);

  return {
    pageType,
    isSendFlowLink,
  };
};

export default usePublicPageData;