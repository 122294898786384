import { Dispatch, SetStateAction, useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { Editor as SlateEditor } from 'slate';

import { ICustomElement } from 'types/Editor';
import { setSelectedFieldState } from 'utils/editorFieldHelpers';
import { cleanTableQuestionSelection } from 'utils/TableQuestion/fieldsTableQuestionHelpers';

interface IUseSlateFieldHandlersProps {
  selectedField: number | null;
  selectedTableQuestionKey: number | null;
  element: ICustomElement;
  editor: SlateEditor;
  isReadOnly: boolean;
  callback: Dispatch<SetStateAction<boolean>>;
}

const useSlateFieldHandlers = ({
  selectedField,
  selectedTableQuestionKey,
  element,
  editor,
  isReadOnly,
  callback,
}: IUseSlateFieldHandlersProps) => {
  const dispatch = useDispatch();

  const clickFieldHandler = useCallback(() => {
    setSelectedFieldState(selectedField, element.key, dispatch);
    cleanTableQuestionSelection(dispatch, element?.isTableField, selectedTableQuestionKey);
  }, [element?.isTableField, element.key, selectedField, selectedTableQuestionKey]);

  const hoverFieldHandler = useCallback(() => {
    if (!editor.publicPage && !isReadOnly && !element?.isTableField) {
      callback(true);
    }
  }, [editor.publicPage, element?.isTableField, isReadOnly]);

  const leaveFieldHandler = useCallback(() => {
    callback(false);
  }, []);

  return {
    clickFieldHandler,
    hoverFieldHandler,
    leaveFieldHandler,
  };
};

export default useSlateFieldHandlers;