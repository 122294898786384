import { FC, memo } from 'react';

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Button from 'components/Base/Button';
import Logo from 'components/Intro/Logo';
import AutocompleteField from 'components/Modals/MondayComModalWindow/AutocompleteField';
import { PASSWORDLESS_EMAIL_SENT_MODAL_TEXT } from 'constants/mondayComIntegration';
import useMondayComOfferToSelectApplicants from 'hooks/MondayCom/useMondayComOfferToSelectApplicants';
import useMondayComPublicPageModalSimilarRecordsExist from 'hooks/MondayCom/useMondayComPublicPageModalSimilarRecordsExist';
import useStyleSchema, { CustomStylesEnum } from 'hooks/useStylesFromSchema';

import classes from 'scss/components/Modal/ConfirmationModal.module.scss';

interface IMondayComSelectExistingRecordForSubitemModal {
  offerToSelectApplicants: boolean;
  headerText: string;
  bodyText: string;
}

const MondayComSelectExistingRecordForSubitemModal: FC<IMondayComSelectExistingRecordForSubitemModal> = ({
  offerToSelectApplicants,
  headerText,
  bodyText,
}) => {
  const {
    isModalSimilarRecordsOpen,
    isPasswordlessLinkSent,
    closeAndSendEmailWithPasswordlessLink,
  } = useMondayComPublicPageModalSimilarRecordsExist();
  const {
    mondayComItems,
    isModalOfferToSelectApplicantsOpen,
    closeAndSaveMondayComItemId,
    targetFilterName,
    setTargetFilterName,
  } = useMondayComOfferToSelectApplicants();

  const styleSchema = useStyleSchema<
    CustomStylesEnum.button
    | CustomStylesEnum.outlinedBorderButton
    | CustomStylesEnum.title
    | CustomStylesEnum.description
  >([
    CustomStylesEnum.button,
    CustomStylesEnum.outlinedBorderButton,
    CustomStylesEnum.title,
    CustomStylesEnum.description,
  ]);

  const isModalOpen = offerToSelectApplicants ? isModalOfferToSelectApplicantsOpen : isModalSimilarRecordsOpen;
  const acceptModalHandler = offerToSelectApplicants
    ? closeAndSaveMondayComItemId
    : closeAndSendEmailWithPasswordlessLink;

  const optionsArray = mondayComItems?.map((item: any) => item.filter_name);

  return (
    <Modal isOpen={isModalOpen} centered modalClassName="restore-data-modal">
      <ModalHeader>
        <span className={classes.Logo}>
          <Logo />
        </span>
        <span
          className={classes.Title}
          style={styleSchema.title}
        >
          {headerText}
        </span>
      </ModalHeader>
      <ModalBody>
        <div className={classes.Body}>
          <div
            className="text-center"
            style={styleSchema.description}
          >
            <p>{!isPasswordlessLinkSent ? bodyText : PASSWORDLESS_EMAIL_SENT_MODAL_TEXT}</p>
          </div>
          {
            offerToSelectApplicants && (
              <AutocompleteField
                options={optionsArray?.filter((value: string, index: number) => optionsArray.indexOf(value) === index)}
                currentValue={targetFilterName}
                onChangeHandler={(value) => setTargetFilterName(value)}
                inputLabel="Select item"
                classes={{ endAdornment: 'endAdornment-top-upset' }}
              />
            )
          }
        </div>
      </ModalBody>
      {
        !isPasswordlessLinkSent && (
          <ModalFooter>
            <div className="d-flex align-items-center justify-content-center w-100 gap-4">
              <Button
                onClick={acceptModalHandler}
                style={styleSchema.button}
                disabled={offerToSelectApplicants && !targetFilterName}
              >
                OK
              </Button>
            </div>
          </ModalFooter>
        )
      }
    </Modal>
  );
};

export default memo(MondayComSelectExistingRecordForSubitemModal);