import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Descendant } from 'slate';

import PublicEditor from 'components/Editor/components/PublicEditor';
import { PATH_TO_NOT_FOUND_PAGE } from 'constants/general';
import { NOT_FOUND_CODE } from 'constants/generalErrors';
import { RootStateType } from 'store/reducers';

interface IPublicEditorWrapper {
  content: Descendant[];
  setContent: (value: Descendant[]) => void;
  isFormsView?: boolean;
  readOnlyMode?: boolean;
}

const PublicEditorWrapper: React.FC<IPublicEditorWrapper> = ({
  content,
  setContent,
  isFormsView = true,
  readOnlyMode = false,
}) => {
  const history = useHistory();
  const error = useSelector((state: RootStateType) => state.errorLoading.error);

  useEffect(() => {
    if (error?.status === NOT_FOUND_CODE) {
      history.push(PATH_TO_NOT_FOUND_PAGE);
    }
  }, [error]);

  return (
    <PublicEditor
      content={content}
      onChange={setContent}
      isFormsView={isFormsView}
      readOnlyMode={readOnlyMode}
    />
  );
};

export default PublicEditorWrapper;