import { ChangeEventHandler, FC, SyntheticEvent, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import cn from 'classnames';
import { SignersInfoType } from 'types/SendDocuments';
import { UserListGetterType } from 'types/userProfile';

import 'scss/components/_email-autocomplete.scss';

interface ISearchManagerField {
  signer: SignersInfoType;
  disabled: boolean;
  useDisabledAutocomplete?: boolean;
  onSelectChange: (event: SyntheticEvent<Element, Event>, newValue: string | null) => void;
  usersArray: UserListGetterType[];
  onInputChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  freeSolo?: boolean;
}

const SearchEmailField: FC<ISearchManagerField> = ({
  signer,
  disabled,
  onSelectChange,
  onInputChange,
  usersArray,
  useDisabledAutocomplete = false,
  freeSolo = false,
}) => {
  const [isAutocompleteActive, setIsAutocompleteActive] = useState(!useDisabledAutocomplete);

  const handleClickShowPassword = () => setIsAutocompleteActive((isActive: boolean) => !isActive);

  return (
    <Autocomplete
      disablePortal
      freeSolo={freeSolo}
      blurOnSelect
      clearOnEscape
      options={isAutocompleteActive ? usersArray?.map((user) => user.email) || [] : []}
      isOptionEqualToValue={(option, value) => (option === value as string)}
      value={signer.email}
      onChange={onSelectChange}
      disabled={disabled}
      readOnly={disabled}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          onChange={onInputChange}
          label="Select email"
          error={signer.emailError}
          helperText={signer.emailErrorText}
          className={cn({
            'email-autocomplete': useDisabledAutocomplete,
          })}
          InputProps={{
            ...params.InputProps,
            className: cn({
              'email-autocomplete-input': useDisabledAutocomplete,
            }),
            ...(useDisabledAutocomplete && {
              startAdornment: (
                <InputAdornment
                  className={cn({
                    'email-autocomplete-search-icon': useDisabledAutocomplete,
                  })}
                  position="start"
                >
                  <IconButton
                    aria-label="toggle visibility"
                    onClick={handleClickShowPassword}
                    className={cn({
                      'email-autocomplete-search-button': useDisabledAutocomplete,
                    })}
                  >
                    {isAutocompleteActive ? <SearchOffIcon /> : <SearchIcon /> }
                  </IconButton>
                </InputAdornment>
              ),
            }),
          }}
        />
      )}
    />
  );
};

export default SearchEmailField;