import { FC, memo, ReactNode, useRef } from 'react';

import { Descendant } from 'slate';

import BasePageTitle from 'components/Base/BasePageTitle';
import EditorWrapper from 'components/Editor/EditorWrapper';
import PublicEditor from 'components/PublicPage/PublicEditor';
import PublicFooterWrapper from 'components/PublicPage/PublicFooterWrapper';
import { EditorGridAxisCount } from 'constants/editors';
import { PUBLIC_PAGE_STATUS } from 'constants/publicPage';
import usePublicPageState from 'hooks/usePublicPageState';

interface IPublicPage {
  onSave: (status: PUBLIC_PAGE_STATUS, redirect?: boolean) => void;
  content: Descendant[];
  setContent: (value: Descendant[]) => void;
  children: ReactNode;
}

const PublicPage: FC<IPublicPage> = ({
  children,
  onSave,
  content,
  setContent,
}) => {
  const titleRef = useRef<HTMLDivElement>(null);

  const {
    isPDFDocument,
    isFormDocument,
    isFormsView,
    isReadOnlyMode,
    shouldShowPublicTabNavigation,
  } = usePublicPageState();

  return (
    <EditorWrapper rows={EditorGridAxisCount.Three}>
      <BasePageTitle
        ref={titleRef}
        isPublicPage
        showPublicTabNavigation={shouldShowPublicTabNavigation}
        redirectTo={onSave}
      />
      <PublicEditor
        content={content}
        setContent={setContent}
        onSaveCallback={onSave}
        isPDFEditor={isPDFDocument}
        isFormsView={isFormDocument || isFormsView}
        isReadOnlyMode={isReadOnlyMode}
      />
      <PublicFooterWrapper
        onSave={onSave}
        content={content}
      />
      {children}
    </EditorWrapper>
  );
};

export default memo(PublicPage);