import { MANAGER_ASSIGNMENT, RECIPIENT_ASSIGNMENT } from 'constants/editors';
import { ARRAY_PUBLIC_MANAGER_PAGES } from 'constants/general';
import { FieldAssignmetsType } from 'types/Editor';

const getAssignmentByUrl = (pageType: string): FieldAssignmetsType => (
  ARRAY_PUBLIC_MANAGER_PAGES.includes(pageType)
    ? MANAGER_ASSIGNMENT
    : RECIPIENT_ASSIGNMENT
);

export default getAssignmentByUrl;