import { DragEvent, FC } from 'react';

import FieldIconWrapper from 'components/PdfEditor/PdfFieldsSidebar/FieldIconWrapper';
import { CHECKBOX_FIELD_TYPE, FIELD_TYPE_VARIANTS } from 'constants/editors';
import { SelectFieldType } from 'types/PdfTemplates';

interface IPDFFieldsSidebar {
  fields?: SelectFieldType[];
  hasDescription?: boolean;
}

const PdfFieldsSidebar: FC<IPDFFieldsSidebar> = ({
  fields = [...FIELD_TYPE_VARIANTS, CHECKBOX_FIELD_TYPE],
  hasDescription = true,
}) => {
  const onDragStart = (event: DragEvent<HTMLDivElement>, type: SelectFieldType): void => {
    event.dataTransfer.effectAllowed = 'copy'; // eslint-disable-line
    event.dataTransfer.setData('field', JSON.stringify(type));
  };

  return (
    <div>
      {
        fields.map((item: SelectFieldType, index: number) => (
          <div
            key={`PdfField_${index + 1}`}
            draggable
            onDragStart={(e) => onDragStart(e, item)}
            className="drag-and-drop-button-wrapper"
          >
            <div className="drag-and-drop-icon">
              <FieldIconWrapper type={item.type} />
            </div>
            <div className="drag-and-drop-button">
              {item.label}
            </div>
          </div>
        ))
      }
      {
        hasDescription && (
          <p className="drag-and-drop-sidebar-info">
            Drag and Drop these fields in the Editor view to create a field.
          </p>
        )
      }
    </div>
  );
};

export default PdfFieldsSidebar;