import { CSSProperties } from 'react';

const UploadedFilesListRender = ({
  values,
  fileNameClass,
  style,
  handleDownloadFile,
  getFileName,
  isPDFDocument = false,
}: {
  values: string[] | undefined;
  fileNameClass: string;
  style: CSSProperties;
  handleDownloadFile: (fileUrl: string) => Promise<void>;
  getFileName: (fileUrl: string) => string;
  isPDFDocument?: boolean;
}) => {
  if (!values?.length) return null;

  return (
    <>
      {
        values.map((fileUrl) => (
          <div className="dz-preview" key={fileUrl}>
            <div
              className={`dz-message${isPDFDocument ? '' : ' mx-2 py-1'}`}
              onClick={() => handleDownloadFile(fileUrl)}
              role="button"
              tabIndex={-1}
              title="Download the file"
            >
              <span className={fileNameClass} style={style}>{getFileName(fileUrl)}</span>
            </div>
          </div>
        ))
      }
    </>
  );
};

export default UploadedFilesListRender;