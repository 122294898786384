import ENDPOINT from 'constants/endpoints';
import { HistorylogObject } from 'types/DocumentsHistory';
import { GetNewResourcePropsType } from 'types/ServerSideEvents';
import { axiosInstance } from 'utils/axiosInstance';

export const getResourceCount = (resource: string) => (
  // 'resource' parameter must looks like '/documents' or '/templates'
  axiosInstance.get(`${ENDPOINT.SERVER_SIDE_EVENTS}${resource}`)
);

// Document executed history
export const getDocumentExecutedHistory = (docId: number) => (
  axiosInstance.get(`${ENDPOINT.DOCUMENT_HISTORY}/${docId}`)
);

export const saveDocumentExecutedLog = (docId: number, data: HistorylogObject) => (
  axiosInstance.post(`${ENDPOINT.DOCUMENT_HISTORY}/save/${docId}`, data)
);

export const getDocumentVersions = (documentId: number) => (
  axiosInstance.get(`${ENDPOINT.DOCUMENT_HISTORY}/versions/${documentId}`)
);

export const getNewResource = ({ resourceType, resourceId }: GetNewResourcePropsType) => (
  // 'resource' parameter must looks like 'document', 'document-executed', 'collection', 'collection-executed'
  axiosInstance.get(`${ENDPOINT.SERVER_SIDE_EVENTS}/${resourceType}/${resourceId}`)
);