import { FC, LegacyRef, MutableRefObject } from 'react';

import ReactQuill from 'react-quill';

import { DEFAULT_QUILL_FORMATS, DEFAULT_QUILL_MODULES, QUILL_EDITOR_THEMES } from 'constants/quillEditor';

interface IDescriptionEditor {
  isReadOnly: boolean,
  onChange?: ((value: string) => void) | undefined;
  value: string,
  showEditorArea?: boolean;
  onBlur?: () => void;
  quillModules?: { [key: string]: any };
  quillFormats?: string[];
  theme?: QUILL_EDITOR_THEMES;
  descriptionInputRef?: MutableRefObject<ReactQuill | undefined>;
}

const DescriptionEditor: FC<IDescriptionEditor> = ({
  isReadOnly,
  onChange,
  value,
  showEditorArea = false,
  onBlur = () => null,
  quillFormats = DEFAULT_QUILL_FORMATS,
  quillModules = DEFAULT_QUILL_MODULES,
  theme,
  descriptionInputRef,
}) => {
  const showEditor = !isReadOnly || (isReadOnly && value?.length);
  const editorVariativeClasses = isReadOnly ? 'max-w-450 mt-3 form-description-public' : 'mw-100';
  return (showEditor || showEditorArea)
    ? (
      <ReactQuill
        ref={descriptionInputRef as LegacyRef<ReactQuill> | undefined}
        className={`border-0 form-description ${editorVariativeClasses}`}
        theme={theme ?? isReadOnly ? QUILL_EDITOR_THEMES.BUBBLE : QUILL_EDITOR_THEMES.SNOW}
        readOnly={isReadOnly}
        placeholder={showEditorArea && isReadOnly ? 'The description is empty...' : 'Write a description here...'}
        modules={quillModules}
        formats={quillFormats}
        value={value || ''}
        onChange={(!isReadOnly && onChange) || undefined}
        onBlur={onBlur}
      />
    )
    : null;
};

export default DescriptionEditor;