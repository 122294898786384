/* eslint-disable */
export interface IToolbarNavTabsProps {
  toolbarTabs: any[];
  selectedTab: string;
  setSelectedTab: (type: string) => void;
}

const ToolbarNavTabs: React.FC<IToolbarNavTabsProps> = (props) => {

  const {
    toolbarTabs,
    selectedTab,
    setSelectedTab,
  } = props;

  return (
    <ul
      className="nav-tabs-toolbar w-100 m-2"
    >
      {
        toolbarTabs.map(({ type, label, }, index) => (
            <li
                key={index}
                className={selectedTab === type ? "active" : ""}
                onClick={() => setSelectedTab(type)}
            >
                {label}
            </li>
        ))
      }
    </ul>
  )
};

export default ToolbarNavTabs;