import { FC, useState } from 'react';

import AccountSettingsWrapper from 'components/AccountSettings/AccountSettingsWrapper';
import SettingsLayout from 'components/AccountSettings/SettingsLayout';
import Button from 'components/Base/Button';
import {
  StripeCurrentPlan,
  StripeInvoices,
  StripePaymentMethods,
  StripeSubscriptions,
} from 'components/Payment/Stripe';
import { usePaymentNotifications } from 'hooks/payment/stripe';

const Billing: FC = () => {
  usePaymentNotifications();
  const [isUpdateSubscriptionVisible, setIsUpdateSubscriptionVisible] = useState<boolean>(false);

  return (
    <AccountSettingsWrapper>
      <SettingsLayout title="Current Plan">
        <StripeCurrentPlan />
        <Button type="button" onClick={() => setIsUpdateSubscriptionVisible((prev) => !prev)}>
          {
            isUpdateSubscriptionVisible
              ? 'Close'
              : 'Update Plan'
          }
        </Button>
      </SettingsLayout>
      {
        isUpdateSubscriptionVisible
        && (
          <SettingsLayout title="Subscriptions">
            <StripeSubscriptions />
          </SettingsLayout>
        )
      }
      <SettingsLayout title="Payment Methods">
        <StripePaymentMethods />
      </SettingsLayout>
      <SettingsLayout title="Invoice History">
        <StripeInvoices />
      </SettingsLayout>
    </AccountSettingsWrapper>
  );
};

export default Billing;