import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { SIGNATURE_TABS } from 'constants/signatures';
import { GET_DEFAULT_SIGNATURE_DATA } from 'store/actions/actionTypes';
import { deleteDefaultSignature, updateSignaturePathProperty } from 'store/actions/userPersonalData';
import { RootStateType } from 'store/reducers';

const useDefaultSignature = () => {
  const dispatch = useDispatch();
  const {
    personalData,
    defaultSignatureData,
  } = useSelector((state: RootStateType) => state.profile ?? {});
  const { save_signature: savedSignature, signature_path: signaturePath } = personalData ?? {};
  const { signatureValue, signatureHash, tabToShow } = defaultSignatureData ?? {};

  const [signatureData, setSignatureData] = useState<string>(signatureValue);
  const [signatureTab, setSignatureTab] = useState<SIGNATURE_TABS>(tabToShow);

  useEffect(() => {
    if (signatureValue && tabToShow) {
      setSignatureData(signatureValue);
      setSignatureTab(tabToShow);
    }
  }, [signatureValue, tabToShow]);

  useEffect(() => {
    if (savedSignature && signaturePath) {
      dispatch({ type: GET_DEFAULT_SIGNATURE_DATA });
    }
  }, [savedSignature, signaturePath, dispatch]);

  const localSignatureSaver = (signature: string, signatureTab: SIGNATURE_TABS) => {
    setSignatureData(signature);
    setSignatureTab(signatureTab);
  };

  const onSaveSignatureHandler = (siganture: string) => {
    dispatch(updateSignaturePathProperty({ save_signature: true, image_data: siganture }));
  };

  const onDeleteSignatureHandler = () => {
    if (savedSignature && signaturePath) {
      dispatch(deleteDefaultSignature());
      setSignatureTab(SIGNATURE_TABS.DRAW_TAB);
      setSignatureData('');
    }
  };

  return {
    signatureHash,
    signatureData,
    signatureTab,
    savedSignature,
    onDeleteSignatureHandler,
    onSaveSignatureHandler,
    localSignatureSaver,
  };
};

export default useDefaultSignature;