/* eslint-disable @typescript-eslint/no-explicit-any */
import { FORM_BUILDER } from 'constants/formBuilder';
import {
  ADD_FIELD_TO_FORM_BUILDER,
  ADD_SECTION,
  ADD_SECTIONS_TO_FORM_BUILDER,
  CHANGE_TEMPLATE_IN_COLLECTION,
  COPY_TEMPLATE,
  CREATE_DOCUMENT,
  CREATE_SECTION,
  CREATE_TEMPLATE,
  CREATE_TEMPLATE_AND_SECTION,
  DELETE_DOCUMENTS,
  DELETE_SECTIONS,
  DELETE_TEMPLATES,
  EDIT_DOCUMENT_DETAILS,
  EDIT_SECTION,
  EDIT_SECTIONS,
  EDIT_TEMPLATE,
  GET_DOCUMENT_DETAILS,
  GET_DOCUMENTS_COUNT,
  GET_NOTIFICATION_COUNT,
  GET_SECTION_DETAILS,
  GET_START_DATA,
  GET_STATES,
  GET_TEMPLATE_DETAILS,
  GET_TEMPLATES,
  REMOVE_SECTIONS_FROM_FORM_BUILDER,
  SET_DOCUMENT_DETAILS,
  SET_LAST_CREATED_SECTION,
  SET_NOTIFICATION_COUNT,
  SET_SECTION_DETAILS,
  SET_TEMPLATE_DETAILS,
  UPDATE_FORM_BUILDER,
  UPDATE_FORM_BUILDER_SECTIONS,
  UPDATE_FORM_BUILDER_SECTIONS_ORDER,
  UPDATE_PARAGRAPH_VALUE,
} from 'store/actions/actionTypes';
import { ICustomElement } from 'types/Editor';
import { FormBuilderType } from 'types/FormBuilder';
import { ActionPayload } from 'types/redux';
import { IFormBuilderSection, ISectionRequestObj } from 'types/Sections';
import { ICopyTemplatePayload, ITemplateDetails, ITemplateRequestObj } from 'types/Templates';

export const getDocumentDetails = (id: any) => ({
  type: GET_DOCUMENT_DETAILS,
  payload: id,
});

export const setDocumentDetails = (data: any) => ({
  type: SET_DOCUMENT_DETAILS,
  payload: data,
});

export const createDocument = (data: any) => ({
  type: CREATE_DOCUMENT,
  payload: data,
});

export const editDocumentDetails = (document: any) => ({
  type: EDIT_DOCUMENT_DETAILS,
  payload: document,
});

export const deleteDocuments = (idArray: any) => ({
  type: DELETE_DOCUMENTS,
  payload: idArray,
});

export const getTemplates = () => ({
  type: GET_TEMPLATES,
});

export const getStartData = () => ({
  type: GET_START_DATA,
});

export const getStates = () => ({
  type: GET_STATES,
});

export const copyTemplate = (id: string, isLightSectionTemplate: boolean): ActionPayload<ICopyTemplatePayload> => ({
  type: COPY_TEMPLATE,
  payload: {
    id,
    isLightSectionTemplate,
  },
});

export const deleteTemplates = (idArray: any) => ({
  type: DELETE_TEMPLATES,
  payload: idArray,
});

export const addSection = (section: ISectionRequestObj) => ({
  type: ADD_SECTION,
  payload: section,
});

export const createTemplate = (
  template: ITemplateRequestObj,
  withSignNow?: boolean,
) => ({
  type: CREATE_TEMPLATE,
  payload: {
    template,
    withSignNow,
  },
});

export const createTemplateAndSection = (templateData: any) => ({
  type: CREATE_TEMPLATE_AND_SECTION,
  payload: templateData,
});

export const editTemplate = (template: any) => ({
  type: EDIT_TEMPLATE,
  payload: template,
});

export const getTemplateDetails = (id: any) => ({
  type: GET_TEMPLATE_DETAILS,
  payload: id,
});

export const setTemplateDetails = (template: any) => ({
  type: SET_TEMPLATE_DETAILS,
  payload: template,
});

export const editSections = (sections: any) => ({
  type: EDIT_SECTIONS,
  payload: sections,
});

export const setLastCreatedSection = (section: any) => ({
  type: SET_LAST_CREATED_SECTION,
  payload: section,
});

export const deleteSections = (idArray: any) => ({
  type: DELETE_SECTIONS,
  payload: idArray,
});

export const createSection = (section: any) => ({
  type: CREATE_SECTION,
  payload: section,
});

export const getSectionDetails = (id: any) => ({
  type: GET_SECTION_DETAILS,
  payload: id,
});

export const setSectionDetails = (section: any) => ({
  type: SET_SECTION_DETAILS,
  payload: section,
});

export const editSection = (section: any) => ({
  type: EDIT_SECTION,
  payload: section,
});

export const getCountOfDocuments = (resourcePath: string) => ({
  type: GET_DOCUMENTS_COUNT,
  payload: {
    resourcePath,
  },
});

export const getNotificationCount = () => ({
  type: GET_NOTIFICATION_COUNT,
});

export const setNotificationCount = (count: number) => ({
  type: SET_NOTIFICATION_COUNT,
  payload: count,
});

export const updateFormBuilderSection = ({
  formBuilder,
  key,
  formBuilderType = FORM_BUILDER.COLLECTION,
}: {
  formBuilder: Partial<IFormBuilderSection> | IFormBuilderSection[];
  key?: number;
  formBuilderType?: FormBuilderType;
}) => ({
  type: UPDATE_FORM_BUILDER,
  payload: {
    formBuilder,
    formBuilderType,
    key,
  },
});

export const addSectionsToFormBuilder = (
  formBuilderSections: IFormBuilderSection[],
  formBuilderType: FormBuilderType = FORM_BUILDER.COLLECTION,
) => ({
  type: ADD_SECTIONS_TO_FORM_BUILDER,
  payload: {
    formBuilderSections,
    formBuilderType,
  },
});

export const removeSectionsFromFormBuilder = (documentId: number, formBuilderType: FormBuilderType) => ({
  type: REMOVE_SECTIONS_FROM_FORM_BUILDER,
  payload: {
    documentId,
    formBuilderType,
  },
});

export const updateFormBuilderSectionsOrder = (
  formBuilder: IFormBuilderSection[],
  formBuilderType: FormBuilderType = FORM_BUILDER.COLLECTION,
) => ({
  type: UPDATE_FORM_BUILDER_SECTIONS_ORDER,
  payload: {
    formBuilder,
    formBuilderType,
  },
});

export const addFieldToFormBuilder = (
  field: Partial<ICustomElement>,
  sectionKey: number,
  fieldPosition: number,
  formBuilderType: FormBuilderType,
) => ({
  type: ADD_FIELD_TO_FORM_BUILDER,
  payload: {
    field,
    sectionKey,
    fieldPosition,
    formBuilderType,
  },
});

export const updateParagraphValue = (
  value: string,
  sectionKey: number,
  fieldKey: number,
  formBuilderType: FormBuilderType,
) => ({
  type: UPDATE_PARAGRAPH_VALUE,
  payload: {
    value,
    sectionKey,
    fieldKey,
    formBuilderType,
  },
});

export const updateFormBuilderSections = (formBuilder: IFormBuilderSection[], formBuilderType: FormBuilderType) => ({
  type: UPDATE_FORM_BUILDER_SECTIONS,
  payload: {
    formBuilder,
    formBuilderType,
  },
});

export const changeTemplateInCollection = (template: ITemplateDetails, oldTemplateId: number) => ({
  type: CHANGE_TEMPLATE_IN_COLLECTION,
  payload: { template, oldTemplateId },
});