import { AssignmentsMainParts, AssignmentsObjectType, AssignmentType } from 'types/Editor';
import { ITemplateForMultiTemplate } from 'types/MultiTemplate';
import { isPDFDocument } from 'utils/PublicPage/documentTypeChecker';

export const getTemplatesToSave = (templates: Partial<ITemplateForMultiTemplate>[], isUpdate: boolean = false) => (
  templates
    .filter((template: Partial<ITemplateForMultiTemplate>) => template.id !== 0)
    .map((template: any) => {
      if (isUpdate) {
        const isPDFTemplate = isPDFDocument(template);
        return {
          id: template.id,
          is_pdf: isPDFTemplate,
          content: isPDFTemplate
            ? template.pdf_fields_json
            : template.sections.map((section: any) => ({
              section_id: section.section_id,
              content_json: section.section.content_json,
              name: section.section.name,
            })),
        };
      }
      return { id: template.id };
    })
);

export const getUnitedAssignmentsArray = (templates?: Partial<ITemplateForMultiTemplate>[]) => {
  const assignments: AssignmentsObjectType = {
    [AssignmentsMainParts.MANAGERS]: [],
    [AssignmentsMainParts.RECIPIENTS]: [],
  };
  templates?.forEach((template: Partial<ITemplateForMultiTemplate>) => {
    template.assignments?.[AssignmentsMainParts.MANAGERS].forEach((assignmentManager: AssignmentType) => {
      if (
        !assignments[AssignmentsMainParts.MANAGERS]
          .some((item: AssignmentType) => item.type === assignmentManager.type)
      ) {
        assignments[AssignmentsMainParts.MANAGERS].push(assignmentManager);
      }
    });
    template.assignments?.[AssignmentsMainParts.RECIPIENTS].forEach((assignmentRecipient: AssignmentType) => {
      if (
        !assignments[AssignmentsMainParts.RECIPIENTS]
          .some((item: AssignmentType) => item.type === assignmentRecipient.type)
      ) {
        assignments[AssignmentsMainParts.RECIPIENTS].push(assignmentRecipient);
      }
    });
  });
  return assignments;
};