import { IRadioGroupOptions } from 'types/AccountSettings';

export const enum EmailNotificationSettingsOption {
  AllTeam = 'all_team_notifications',
  OnlyMy = 'only_my_notifications',
  NoNotifications = 'no_notifications',
}

export const NOTIFICATION_OPTIONS: IRadioGroupOptions<EmailNotificationSettingsOption>[] = [
  {
    label: 'I only want to receive notifications for documents I send',
    value: EmailNotificationSettingsOption.OnlyMy,
    isDefault: true,
  },
  {
    label: 'I want to receive all notifications for my team',
    value: EmailNotificationSettingsOption.AllTeam,
  },
  {
    label: 'I do not want to receive any email notifications',
    value: EmailNotificationSettingsOption.NoNotifications,
  },
];