/* eslint-disable */
import { DEFAULT_FONT_FAMILY, FONT_TYPE_FORMAT, SECOND_DEFAULT_FONT_FAMILY } from "constants/editors";

export const reduceFontType = (accumulator: any, currentValue: any, index: number) => {

  const currentFontTypes = currentValue.type.split(', ');
  const currentType = currentFontTypes.slice(currentFontTypes.length - 1).join();

  if(!accumulator) {
    return [
      { format: 'font-type-list', type: currentType },
      currentValue,
    ];
  }

  const accumFontTypes = accumulator.slice(-1)[0].type.split(', ');
  const accumType = accumFontTypes.slice(accumFontTypes.length - 1).join();

  if(accumType !== currentType) {
    return [
      ...accumulator,
      { format: 'font-type-list', type: currentType },
      currentValue,
    ]
  }

  return [
    ...accumulator,
    currentValue,
  ]
}

export const filterFontTypeByAvailable = (currentFontTypes: string): string => {
  if (currentFontTypes) {
    const currentFontType: any[] = FONT_TYPE_FORMAT.filter(({ format, type }, index) => {
      const label = type.split(', ')?.slice(0, 1);
      const fontFamily = (currentFontTypes).replaceAll('"', '').split(', ')?.slice(0, 1);
      return label && label[0] === fontFamily[0];
    });

    if (currentFontType.length) {
      return currentFontType[0].type;
    }
  }
  return '';
}

// Not used any more
export const printFontType = (currentFontType: string, secondDefault: boolean = false): string => {
  const filteredFontType = filterFontTypeByAvailable(currentFontType);
  return (currentFontType !== 'inherit' && filteredFontType)
    || (secondDefault ? SECOND_DEFAULT_FONT_FAMILY : DEFAULT_FONT_FAMILY);
}