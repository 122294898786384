import { PDF_TYPE } from 'constants/general';
import { AssignmentsObjectType } from 'types/Editor';
import { ITemplateRequestObj } from 'types/Templates';

interface ICreateSignNowDocumentProps {
  fileName: string;
  assignments: AssignmentsObjectType;
  doctypeID: number;
  pdfFileLink: string;
}

export const getSignNowTemplateData = ({
  fileName,
  assignments,
  doctypeID,
  pdfFileLink,
}: ICreateSignNowDocumentProps): ITemplateRequestObj => ({
  name: fileName,
  template_title: fileName,
  description: '',
  doctype_id: doctypeID,
  states: [{ id: 1 }],
  pdf_fields_json: {},
  pdf_file_url: pdfFileLink,
  type: PDF_TYPE,
  is_single: true,
  assignments,
});

export default undefined;