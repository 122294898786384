import { FC } from 'react';

import AttachmentsList from 'components/AttachmentsListComponents/AttachmentsList';
import { PDFPropsType } from 'types/PdfTemplates';
import { getMainTypeAssignment } from 'utils/assignmentsHelpers';

const AttachmentEditable: FC<PDFPropsType> = ({
  field,
  isReadOnlyMode,
  fieldStyle,
}) => {
  const filesCount = ((field.value && field.value.split(',')) || []).length;
  const readOnlyClass = isReadOnlyMode ? 'disable-pdf-attachments' : '';
  const valueStyles = field.fontSize ? { fontSize: field.fontSize } : {};

  return (
    <div
      aria-disabled={isReadOnlyMode}
      style={fieldStyle}
      className={`form-control pdf-attachments pdf-field-${getMainTypeAssignment(field.assignment)} ${readOnlyClass}`}
      data-attachments-empty={!filesCount}
      role="button"
      tabIndex={isReadOnlyMode ? -1 : 0}
      data-pdf-field
    >
      <AttachmentsList
        pdfDocumentView
        isPdfFormField
        fieldValue={field.value}
        fieldKey={field.key}
        readOnlyMode={isReadOnlyMode}
        style={valueStyles}
      />
    </div>
  );
};

export default AttachmentEditable;