import { FC } from 'react';

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Button from 'components/Base/Button';
import { CANCEL, SAVE } from 'constants/general';

interface IModalWindowProps {
  isOpen: boolean;
  onClose: () => void;
  onButtonClick: () => void;
  onCancel?: () => void;
  title?: string;
  description?: string;
  classes?: string;
  contentClasses?: string;
  buttonTitle?: string;
  cancelTitle?: string;
}

const ModalWindow: FC<IModalWindowProps> = ({
  isOpen,
  onClose,
  onCancel,
  onButtonClick,
  title = '',
  description = '',
  classes = '',
  contentClasses = '',
  buttonTitle = SAVE,
  cancelTitle = CANCEL,
  children,
}) => (
  <Modal centered isOpen={isOpen} className={classes} contentClassName={contentClasses}>
    <ModalHeader toggle={onClose}>{title}</ModalHeader>
    {
      description
        ? (
          <div className="mb-1">{description}</div>
        )
        : null
    }
    <ModalBody>{children}</ModalBody>
    <ModalFooter>
      <Button classes="outline" onClick={onCancel || onClose}>
        {cancelTitle}
      </Button>
      <Button onClick={onButtonClick}>
        {buttonTitle}
      </Button>
    </ModalFooter>
  </Modal>
);

export default ModalWindow;