export const getElementSize = (element: Element, list: string[]) => {
  const style = window.getComputedStyle(element);
  return list
    .map((selector: string) => parseInt(style.getPropertyValue(selector), 10))
    .reduce((total, current) => total + current, 0);
};

const getElementsHeight = (element: Element | Element[]) => {
  const list = [
    'margin-top',
    'margin-bottom',
    'border-top',
    'border-bottom',
    'padding-top',
    'padding-bottom',
    'height',
  ];

  if (Array.isArray(element)) {
    return element.reduce((total, current) => total + getElementSize(current, list), 0);
  }
  return getElementSize(element, list);
};

export default getElementsHeight;