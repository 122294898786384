import { FC, SyntheticEvent } from 'react';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { Autocomplete, AutocompleteRenderInputParams, TextField } from '@mui/material';

import { SEARCH_INPUT_LIMIT } from 'constants/general';
import { SearchInputValue } from 'types/Misc';

interface ISearchInput {
  data: Array<SearchInputValue>
  value: SearchInputValue;
  onChange: (value: SearchInputValue) => void;
  isError: boolean;
}

const SearchInput: FC<ISearchInput> = ({
  data,
  value,
  onChange,
  isError,
}) => (
  <Autocomplete
    freeSolo
    options={data}
    filterOptions={createFilterOptions({
      limit: SEARCH_INPUT_LIMIT,
    })}
    renderInput={
      (params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          label="Select user"
          error={isError}
        />
      )
    }
    fullWidth
    value={value}
    onChange={(
      _event: SyntheticEvent<Element, Event>,
      newValue: SearchInputValue | string | null,
    ) => onChange(newValue as SearchInputValue)}
  />
);

export default SearchInput;