import { Descendant, Element as SlateElement } from 'slate';

import { RECIPIENT_ASSIGNMENT } from 'constants/editors';
import { DocumentTypesType } from 'types/Documents';
import { HistorylogObject } from 'types/DocumentsHistory';
import { FieldAssignmetsType, ISubtypesFromFieldsGetter } from 'types/Editor';
import { PDFFieldType, PDFFieldTypeObject } from 'types/PdfTemplates';
import { ISection } from 'types/Sections';
import { ITemplateDetails } from 'types/Templates';
import { PIIDataType } from 'types/userProfile';
import {
  filterFormFieldsFromContent,
  getPIIFieldWithValueFromDocument,
} from 'utils/editorFieldHelpers';
import { addSectionsDocument } from 'utils/editorHelpers';
import { filterSortedFormFieldsFromJSONObject } from 'utils/pdfTemplatesHelpers';
import { getUserInfoFromPII, getUserPIIInfoArray } from 'utils/PIIAndGrouping/groupedAndPIIFieldsHelper';
import { getIsPDFDocument } from 'utils/PublicPage/documentTypeChecker';
import { getSortedSections } from 'utils/sectionUtils';

interface INewData {
  name: string;
  content_json: PDFFieldTypeObject | Descendant[];
  description: string;
  document_title: string;
  template_id: number;
  type?: DocumentTypesType;
  user_data?: Partial<ISubtypesFromFieldsGetter>[];
  history_log?: HistorylogObject;
  ip_address?: string;
  identifier?: string | null;
  document_assignment?: string;
  monday_com_board?: string;
  monday_com_item_id?: string[];
  user_sms_consent?: boolean;
  documentUUID?: string;
}

const getNewDocumentDataFromTemplate = ({
  data,
  ipAddress,
  withUserData = true,
  assignment = RECIPIENT_ASSIGNMENT,
  mainAssignment = RECIPIENT_ASSIGNMENT,
  historyLog = {},
  identifier = '',
  mondayComBoard = undefined,
  mondayComItemIdToFilloutSubitem = undefined,
}: {
  data: ITemplateDetails,
  ipAddress?: string,
  withUserData?: boolean,
  assignment: FieldAssignmetsType,
  mainAssignment?: string,
  historyLog?: HistorylogObject,
  identifier?: string | null,
  mondayComBoard?: string;
  mondayComItemIdToFilloutSubitem?: string[];
}) => {
  const documentType = data?.type;
  const isPDFDocument = getIsPDFDocument(documentType);
  const sortedSections: ISection[] = getSortedSections(data.sections);
  const content = addSectionsDocument(sortedSections, false);
  const currentForm: PDFFieldType[] | SlateElement[] = isPDFDocument
    ? filterSortedFormFieldsFromJSONObject(data.pdf_fields_json, true, assignment)
    : filterFormFieldsFromContent(content, assignment);
  if (!currentForm.length) return null;

  const usedFieldSubtypes: PIIDataType[] = getPIIFieldWithValueFromDocument(currentForm, mainAssignment);
  const userPIIData: Partial<ISubtypesFromFieldsGetter>[] = getUserPIIInfoArray(getUserInfoFromPII(usedFieldSubtypes));

  const newData: INewData = {
    name: data.name.trim(),
    content_json: isPDFDocument
      ? data.pdf_fields_json
      : content.map((element: any) => ({
        children: element.children,
        description: element.description,
        key: element.key,
        name: element.name,
        type: element.type,
        position: element.position,
      })),
    description: data.description.trim(),
    document_title: data.template_title.trim(),
    type: documentType,
    template_id: data.id,
    history_log: historyLog,
    ip_address: ipAddress,
    document_assignment: mainAssignment,
    identifier,
  };

  if (withUserData) {
    newData.user_data = userPIIData;
  }
  if (mondayComBoard) {
    newData.monday_com_board = mondayComBoard;
  }
  if (mondayComItemIdToFilloutSubitem) {
    newData.monday_com_item_id = mondayComItemIdToFilloutSubitem;
  }

  return newData;
};

export default getNewDocumentDataFromTemplate;