/* eslint-disable no-new */
import { memo, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { DEFAULT_LANGUAGE, GOOGLE_TRANSLATE_ELEMENT } from 'constants/googleTranslate';

import shouldShowBrandingInRoutes from 'utils/CompanyBranding/shouldShowBrandingInRoutes';
import { changeLanguage } from 'utils/GoogleTranslate/googleTranslateHelpers';

const GoogleTranslate = () => {
  const location = useLocation();
  const isAvailableTranslate = shouldShowBrandingInRoutes(location.pathname);
  const [googleTranslateScriptLoaded, setGoogleTranslateScriptLoaded] = useState(false);

  useEffect(() => {
    const scriptInDom = document.getElementById('google-translate-script');

    if (isAvailableTranslate && !scriptInDom) {
      const script = document.createElement('script');
      script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      script.async = false;
      script.defer = false;
      script.id = 'google-translate-script';
      document.body.appendChild(script);

      script.onload = () => {
        setGoogleTranslateScriptLoaded(true);
      };
    }

    if (!isAvailableTranslate && scriptInDom) {
      changeLanguage(DEFAULT_LANGUAGE);
    }
  }, [isAvailableTranslate]);

  useEffect(() => {
    if (googleTranslateScriptLoaded) {
      window.googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement({}, GOOGLE_TRANSLATE_ELEMENT);
      };
    }
  }, [googleTranslateScriptLoaded]);

  return (<div id="google-translate-element" />);
};

export default memo(GoogleTranslate);