import { CSSProperties } from 'react';

import { Descendant } from 'slate';

import { SIGNATURE_FIELD_INITIALS_VARIATION, SIGNATURE_TABS } from 'constants/signatures';
import { IFontTypeFormat } from 'types/BrandingColorSchema';
import {
  FieldAssignmetsType,
  IDropdownItem,
  IFontSize,
  IHotkeySetting,
  IMonthNames,
  ITextAlign,
  LABEL_CONFIGURATION,
} from 'types/Editor';
import { ITemplateForMultiTemplate } from 'types/MultiTemplate';
import {
  PDFFieldType,
  PDFFieldTypesVariantType,
  SelectFieldType,
  SelectQuestionAnswerType,
  SelectSelectType,
} from 'types/PdfTemplates';
import { ISelectFieldType } from 'types/Properties';
import { generateNumber } from 'utils/editorFieldHelpers';
import { setEditorListMarkStyle } from 'utils/editorListHelpers';
import { createCellBody, createRowBody } from 'utils/editorTableHelpers';
import { setEditorNodeFont, setEditorNodeStyles } from 'utils/editorTextStyleHelpers';
import { capitalizeString } from 'utils/stringsHelpers';

import vars from 'variables.module.scss';

export const DEFAULT_FONT_FAMILY = '"Inter", sans-serif';
export const SECOND_DEFAULT_FONT_FAMILY = 'Arial, sans-serif';
export const FONT_TYPE = 'font-type';
export const TEXT_SIZE = 'text-size';
export const PDF_FIELD_FONT_SIZE = vars.pdfFieldValuesFontSize;
export const FIELD_COLOR_RECIPIENT = vars.recipientFieldColor;
export const FIELD_COLOR_MANAGER = vars.managerFieldColor;

export const NEW_LINE = '\n';
export const TABULATION = ' ';
export const TABULATION_STEP = '    ';
export const DASH_SPACE = '-';
export const WHITE_SPACE = ' ';
export const UNDERLINE_SYMBOL = '_';
// It's not white space, it's "no break space"(code: alt+255)
export const NO_BREAK_SPACE = ' ';
export const TEXTAREA_ROWS_COUNT = 3;
export const FORM_TEXTAREA_ROWS_COUNT = 2; // https://github.com/mui/material-ui/issues/33060

// Keyboard event keys
export const DOT = '.';
export const PLUS = '+';
export const MINUS = '-';

// Input validation constants
export const SYMBOLS_FOR_IGNORE = [DOT, PLUS, MINUS];

export const HEADLINE_ONE = 'headline-one';
export const HEADLINE_TWO = 'headline-two';
export const HEADLINE_THREE = 'headline-three';
export const HEADLINE_FOUR = 'headline-four';
export const HEADLINE_FIVE = 'headline-five';
export const HEADLINE_SIX = 'headline-six';
export const SECTION = 'section';
export const PARAGRAPH = 'paragraph';
export const NUMBERED_LIST = 'numbered-list';
export const BULLETED_LIST = 'bulleted-list';
export const LIST_ITEM = 'list-item';
export const NUMBERED_LIST_ITEM = 'numbered-list-item';
export const LIST_ITEM_TEXT = 'list-item-text';
export const CHECK_LIST_ITEM = 'check-list-item';
export const CHECK_LIST_ITEM_CHECKED = 'check-list-checked';
export const TEXT_FIELD = 'text-field';
export const TEXT_FIRST_NAME_FIELD = 'first-name';
export const TEXT_LAST_NAME_FIELD = 'last-name';
export const TEXT_EMAIL_FIELD = 'email';
export const TEXT_COMPANY_NAME_FIELD = 'company-name';
export const TEXT_TITLE_FIELD = 'title';
export const DATE_FIELD = 'date-field';
export const DATE_SIGNED_FIELD = 'date-signed';
export const DATE_TODAY_DATE_FIELD = 'today\'s-date';
export const SELECT_FIELD = 'select-field';
export const SELECT_FIELD_OTHER_OPTION = 'Other';
export const SIGNING_FIELD = 'signing-field';
export const SIGNING_SIGNATURE_FIELD = 'signature';
export const SIGNING_INITIALS_FIELD = 'initials';
export const TEXTAREA_FIELD = 'textarea-field';
export const ATTACHMENT_FIELD = 'attachment-field';
export const QUESTION_FIELD = 'question-field';
export const CHECKBOX_FIELD = 'checkbox-field';
export const TABLE_QUESTION = 'table-question';
export const TABLE_QUESTION_CAPTION = 'table-question-caption';
export const TABLE_QUESTION_VERTICAL_RESIZE_BAR = 'table-question-vertical-resize-bar';
export const TABLE_QUESTION_HORIZONTAL_RESIZE_BAR = 'table-question-horizontal-resize-bar';
export const TABLE_QUESTION_BODY = 'table-question-body';
export const TABLE_QUESTION_ROW = 'table-question-row';
export const TABLE_QUESTION_CELL = 'table-question-cell';
export const TABLE_QUESTION_FIELD_CELL = 'table-question-field-cell';
export const GROUPED_FIELDS = [TEXT_FIELD, DATE_FIELD, TEXTAREA_FIELD, SELECT_FIELD];
export const OPTION = 'option';
export const BOLD = 'bold';
export const ITALIC = 'italic';
export const NORMAL = 'normal';
export const UNDERLINE = 'underline';
export const LINE_THROUGH = 'line-through';
export const UNDERLINE_LINE_THROUGH = 'underline line-through';
export const STRIKE_THROUGH = 'strikethrough';
export const TABLE_CONTENT = 'table-content';
export const IMAGE_TYPE = 'image';
export const LINK_TYPE = 'link';
export const FORM_BUILDER_PARAGRAPH = 'form-builder-paragraph';

export const DEFAULT_PDF_CHECKBOX_WIDTH = 30;
export const DEFAULT_PDF_CHECKBOX_HEIGHT = 30;
export const DEFAULT_PDF_FIELD_WIDTH = 150;
export const DEFAULT_PDF_FIELD_HEIGHT = 20;
export const DEFAULT_PDF_SIGNATURE_FIELD_HEIGHT = 50;
export const DEFAULT_PDF_FIELD_ICON_WIDTH = 35;
export const DEFAULT_PDF_FIELD_ICON_HEIGHT = 30;
export const DEFAULT_PDF_FIELD_WIDTH_WITH_ICON = DEFAULT_PDF_FIELD_WIDTH + (DEFAULT_PDF_FIELD_ICON_WIDTH / 2);
export const PDF_COORDS_Y_COEFFICIENT = 30;
export const MIN_FIELD_HEIGHT = 20;
export const MIN_FIELD_WIDTH = 35;
export const MIN_CHECKBOX_FIELD_WIDTH = 30;
export const STRING_INPUT_MD_MIN_LENGTH = 30;
export const STRING_INPUT_MD_MAX_LENGTH = 70;
export const STRING_INPUT_SM_MIN_LENGTH = 71;
export const STRING_INPUT_SM_MAX_LENGTH = 120;
export const STRING_INPUT_XS_MIN_LENGTH = 121;
export const STRING_INPUT_XS_MAX_LENGTH = 200;
export const PUBLIC_FORM_MAX_WIDTH = 450;

export const checkboxDefaultStyles: CSSProperties = {
  marginTop: 0,
  padding: 0,
};
export const paddingForWrapperSize = 10;

export const PDF_TEXT_ALIGN: ITextAlign = {
  'text-left': 'left',
  'text-center': 'center',
  'text-right': 'right',
  'text-justify': 'justify',
};

export const PDF_MONTH_NAMES: IMonthNames = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};

export const PDF_FONT_SIZE: IFontSize = {
  'headline-one': 18,
  'headline-two': 14,
  'headline-three': 11,
};

export const INITIAL_EDITOR_VALUE: Descendant[] = [
  {
    type: PARAGRAPH,
    children: [{ text: '' }],
  },
];

export const INITIAL_SECTION_VALUE: Descendant[] = [
  {
    type: SECTION,
    key: 0,
    position: 0,
    children: [...INITIAL_EDITOR_VALUE],
  },
];

export const DEFAULT_SECTION = {
  name: 'Section name here',
  description: '',
  key: generateNumber(),
  position: 0,
};

export const DEFAULT_TEMPLATES_LIST: Partial<ITemplateForMultiTemplate>[] = [
  {
    id: 0,
    name: '',
    title: '',
    description: '',
    sections: [],
    content: INITIAL_EDITOR_VALUE,
  },
];

export const CREATE_SIMPLE_TABLE_BODY: Descendant[] = [
  {
    type: 'table-body',
    children: [
      {
        type: 'table-row',
        children: [
          {
            type: 'table-cell',
            children: [{ text: '' }],
          },
          {
            type: 'table-cell',
            children: [{ text: '' }],
          },
        ],
      },
      {
        type: 'table-row',
        children: [
          {
            type: 'table-cell',
            children: [{ text: '' }],
          },
          {
            type: 'table-cell',
            children: [{ text: '' }],
          },
        ],
      },
    ],
  },
];

export const HEADERS_MARKS = [HEADLINE_ONE, HEADLINE_TWO, HEADLINE_THREE, HEADLINE_FOUR, HEADLINE_FIVE, HEADLINE_SIX];

const MIN_TEXT_FONT_SIZE = '9';
const MAX_TEXT_FONT_SIZE = '38';

export const TEXT_SIZE_FORMATS: IDropdownItem[] = [
  {
    format: 'text-size',
    type: MIN_TEXT_FONT_SIZE,
  },
  {
    format: 'text-size',
    type: '10',
  },
  {
    format: 'text-size',
    type: '11',
  },
  {
    format: 'text-size',
    type: '12',
  },
  {
    format: 'text-size',
    type: '14',
  },
  {
    format: 'text-size',
    type: '16',
  },
  {
    format: 'text-size',
    type: '18',
  },
  {
    format: 'text-size',
    type: '24',
  },
  {
    format: 'text-size',
    type: '28',
  },
  {
    format: 'text-size',
    type: '32',
  },
  {
    format: 'text-size',
    type: MAX_TEXT_FONT_SIZE,
  },
];

export const FIELD_VALUE_FONT_OPTIONS = [
  {
    id: 'fontSize_default',
    type: '',
    label: '',
  },
].concat(
  TEXT_SIZE_FORMATS.map((sizeElement, index) => ({
    id: `fontSize_${index + 1}`,
    type: sizeElement.type,
    label: `${sizeElement.type}px`,
  })),
);

export const FIELD_VALUE_FONT = {
  minFontSize: Number(MIN_TEXT_FONT_SIZE),
  maxFontSize: Number(MAX_TEXT_FONT_SIZE),
};

export const FONT_TYPE_FORMAT: IFontTypeFormat[] = [
  {
    format: FONT_TYPE,
    type: 'inherit',
    label: 'inherit (Default)',
  },
  {
    format: FONT_TYPE,
    type: 'Andale Mono, monospace',
    label: 'Andale Mono',
  },
  {
    format: FONT_TYPE,
    type: 'Arial, sans-serif',
    label: 'Arial',
  },
  {
    format: FONT_TYPE,
    type: 'Arial Narrow, sans-serif',
    label: 'Arial Narrow',
  },
  {
    format: FONT_TYPE,
    type: 'Bradley Hand, cursive',
    label: 'Bradley Hand',
  },
  {
    format: FONT_TYPE,
    type: 'Calibri, Arial, Verdana, sans-serif',
    label: 'Calibri',
  },
  {
    format: FONT_TYPE,
    type: 'Courier, monospace',
    label: 'Courier',
  },
  {
    format: FONT_TYPE,
    type: 'Courier New, monospace',
    label: 'Courier New',
  },
  {
    format: FONT_TYPE,
    type: 'DejaVu Sans Mono, monospace',
    label: 'DejaVu Sans Mono',
  },
  {
    format: FONT_TYPE,
    type: 'FreeMono, monospace',
    label: 'FreeMono',
  },
  {
    format: FONT_TYPE,
    type: 'Georgia, serif',
    label: 'Georgia',
  },
  {
    format: FONT_TYPE,
    type: 'Gill Sans, sans-serif',
    label: 'Gill Sans',
  },
  {
    format: FONT_TYPE,
    type: 'Helvetica, sans-serif',
    label: 'Helvetica',
  },
  {
    format: FONT_TYPE,
    type: 'Impact, fantasy',
    label: 'Impact',
  },
  {
    format: FONT_TYPE,
    type: 'Luminari, fantasy',
    label: 'Luminari',
  },
  {
    format: FONT_TYPE,
    type: 'Marker Felt, fantasy',
    label: 'Marker Felt',
  },
  {
    format: FONT_TYPE,
    type: 'Noto Sans, sans-serif',
    label: 'Noto Sans',
  },
  {
    format: FONT_TYPE,
    type: 'Snell Roundhand, cursive',
    label: 'Snell Roundhand',
  },
  {
    format: FONT_TYPE,
    type: 'Times, Georgia, Garamond, serif',
    label: 'Times',
  },
  {
    format: FONT_TYPE,
    type: 'Times New Roman, Georgia, Garamond, serif',
    label: 'Times New Roman',
  },
  {
    format: FONT_TYPE,
    type: 'Trattatello, fantasy',
    label: 'Trattatello',
  },
  {
    format: FONT_TYPE,
    type: 'Trebuchet MS, sans-serif',
    label: 'Trebuchet',
  },
  {
    format: FONT_TYPE,
    type: 'Verdana, sans-serif',
    label: 'Verdana',
  },
];

export const TEXT_ALIGN: ITextAlign = {
  'text-left': 'start',
  'text-right': 'end',
  'text-center': 'center',
  'text-justify': 'justify',
};

export const LIST_TYPES: string[] = [
  NUMBERED_LIST,
  BULLETED_LIST,
];

export const LIST_ITEM_TYPES: string[] = [
  NUMBERED_LIST_ITEM,
  LIST_ITEM,
];

export const LIST_STYLE_DECIMAL_CHAPTER = 'decimal-chapter';
export const LIST_STYLE_DECIMAL_LEGAL = 'decimal-legal';
export const NUMBERED_LIST_VARIANTS = [
  '',
  LIST_STYLE_DECIMAL_CHAPTER,
  LIST_STYLE_DECIMAL_LEGAL,
];

export const NUMBERED_LIST_VIEW = [
  {
    type: '',
    format: NUMBERED_LIST,
    label: 'Default (decimal)',
  },
  {
    type: LIST_STYLE_DECIMAL_CHAPTER,
    format: NUMBERED_LIST,
    label: 'Multilevel Chapter',
  },
  {
    type: LIST_STYLE_DECIMAL_LEGAL,
    format: NUMBERED_LIST,
    label: 'Multilevel Legal',
  },
];

export const FIELD_TYPE = [
  TEXT_FIELD,
  DATE_FIELD,
  SELECT_FIELD,
  SIGNING_FIELD,
  TEXTAREA_FIELD,
  ATTACHMENT_FIELD,
  QUESTION_FIELD,
  CHECKBOX_FIELD,
];

const TEXT_FIELD_TYPE: SelectFieldType = {
  id: 1,
  type: TEXT_FIELD,
  label: 'Text',
};

const DATE_FIELD_TYPE: SelectFieldType = {
  id: 2,
  type: DATE_FIELD,
  label: 'Date',
};

const SELECT_FIELD_TYPE: SelectFieldType = {
  id: 3,
  type: SELECT_FIELD,
  label: 'Select',
};

const SIGNING_FIELD_TYPE: SelectFieldType = {
  id: 4,
  type: SIGNING_FIELD,
  label: 'Signature',
};

const TEXTAREA_FIELD_TYPE: SelectFieldType = {
  id: 5,
  type: TEXTAREA_FIELD,
  label: 'Text Area',
};

const ATTACHMENT_FIELD_TYPE: SelectFieldType = {
  id: 6,
  type: ATTACHMENT_FIELD,
  label: 'Attachment',
};

export const CHECKBOX_FIELD_TYPE: SelectFieldType = {
  id: 8,
  type: CHECKBOX_FIELD,
  label: 'Checkbox',
};

export const SIGN_NOW_SIGNATURE_TYPE: SelectFieldType = {
  id: 7,
  type: SIGNING_FIELD,
  typeVariant: SIGNING_SIGNATURE_FIELD,
  label: 'Signature',
};

export const SIGNATURE_INITIALS_TYPE: SelectFieldType = {
  id: 9,
  type: SIGNING_FIELD,
  typeVariant: SIGNING_INITIALS_FIELD,
  label: 'Initials',
};

export const TEXT_FIRST_NAME_FIELD_TYPE: SelectFieldType = {
  id: 10,
  type: TEXT_FIELD,
  typeVariant: TEXT_FIRST_NAME_FIELD,
  label: 'First Name',
};

export const TEXT_LAST_NAME_FIELD_TYPE: SelectFieldType = {
  id: 11,
  type: TEXT_FIELD,
  typeVariant: TEXT_LAST_NAME_FIELD,
  label: 'Last Name',
};

export const TEXT_EMAIL_FIELD_TYPE: SelectFieldType = {
  id: 12,
  type: TEXT_FIELD,
  typeVariant: TEXT_EMAIL_FIELD,
  label: 'Email',
};

export const DATE_SIGNED_FIELD_TYPE: SelectFieldType = {
  id: 13,
  type: DATE_FIELD,
  typeVariant: DATE_SIGNED_FIELD,
  label: 'Date Signed',
};

export const TEXT_COMPANY_NAME_FIELD_TYPE: SelectFieldType = {
  id: 14,
  type: TEXT_FIELD,
  typeVariant: TEXT_COMPANY_NAME_FIELD,
  label: 'Company Name',
};

export const TEXT_TITLE_FIELD_TYPE: SelectFieldType = {
  id: 15,
  type: TEXT_FIELD,
  typeVariant: TEXT_TITLE_FIELD,
  label: 'Title',
};

export const DATE_TODAY_DATE_FIELD_TYPE: SelectFieldType = {
  id: 16,
  type: DATE_FIELD,
  typeVariant: DATE_TODAY_DATE_FIELD,
  label: 'Today\'s Date',
};

export const FIELD_TYPE_VARIANTS: SelectFieldType[] = [
  TEXT_FIELD_TYPE,
  DATE_FIELD_TYPE,
  SELECT_FIELD_TYPE,
  SIGNING_FIELD_TYPE,
  TEXTAREA_FIELD_TYPE,
  ATTACHMENT_FIELD_TYPE,
];

export const SIGN_NOW_SIGNATURE_FIELDS: SelectFieldType[] = [
  SIGN_NOW_SIGNATURE_TYPE,
  SIGNATURE_INITIALS_TYPE,
  DATE_SIGNED_FIELD_TYPE,
];

export const SIGN_NOW_MOST_COMMON_FIELDS: SelectFieldType[] = [
  TEXT_FIRST_NAME_FIELD_TYPE,
  TEXT_LAST_NAME_FIELD_TYPE,
  TEXT_EMAIL_FIELD_TYPE,
  DATE_TODAY_DATE_FIELD_TYPE,
  TEXT_COMPANY_NAME_FIELD_TYPE,
  TEXT_TITLE_FIELD_TYPE,
];

export const TABLE_FIELD_TYPE_VARIANTS: SelectFieldType[] = [
  TEXT_FIELD_TYPE,
  DATE_FIELD_TYPE,
  SELECT_FIELD_TYPE,
  SIGNING_FIELD_TYPE,
  TEXTAREA_FIELD_TYPE,
  ATTACHMENT_FIELD_TYPE,
];

export const SUBTYPE_EMAIL = 'email';
export const SUBTYPE_FIRST_NAME = 'first_name';
export const SUBTYPE_LAST_NAME = 'last_name';
export const SUBTYPE_PHONE_MOBILE = 'phone__mobile';

export enum FIELD_GROUPING_VALUE {
  BY_ASSIGNEE = 'by_assignee',
  BY_SECTION = 'by_section',
  BY_DOCUMENT = 'by_document',
  BY_SUBTYPE = 'by_subtype',
  BY_FILTER = 'by_filter',
}

export const FIELD_TYPE_VARIANT_PROPERTIES: Record<PDFFieldTypesVariantType, Partial<PDFFieldType>> = {
  [SIGNING_SIGNATURE_FIELD]: {
    requiredField: true,
  },
  [SIGNING_INITIALS_FIELD]: {
    requiredField: true,
    signatureFieldVariation: SIGNATURE_FIELD_INITIALS_VARIATION,
    signatureTab: SIGNATURE_TABS.TYPE_TAB,
  },
  [DATE_SIGNED_FIELD]: {
    requiredField: true,
    isTodaysDate: true,
    isReadOnly: true,
  },
  [DATE_TODAY_DATE_FIELD]: {
    isTodaysDate: true,
  },
  [TEXT_FIRST_NAME_FIELD]: {
    subtype: SUBTYPE_FIRST_NAME,
    groupBy: FIELD_GROUPING_VALUE.BY_ASSIGNEE,
  },
  [TEXT_LAST_NAME_FIELD]: {
    subtype: SUBTYPE_LAST_NAME,
    groupBy: FIELD_GROUPING_VALUE.BY_ASSIGNEE,
  },
  [TEXT_EMAIL_FIELD]: {
    subtype: SUBTYPE_EMAIL,
    groupBy: FIELD_GROUPING_VALUE.BY_ASSIGNEE,
  },
  [TEXT_COMPANY_NAME_FIELD]: {
    groupBy: FIELD_GROUPING_VALUE.BY_ASSIGNEE,
  },
  [TEXT_TITLE_FIELD]: {
    groupBy: FIELD_GROUPING_VALUE.BY_ASSIGNEE,
  },
};

export const FIELD_GROUP_BY: ISelectFieldType[] = [
  {
    id: 1,
    type: '',
    label: 'None',
  },
  {
    id: 2,
    type: FIELD_GROUPING_VALUE.BY_ASSIGNEE,
    label: 'Group by Assignee',
  },
  {
    id: 3,
    type: FIELD_GROUPING_VALUE.BY_SECTION,
    label: 'Group by Section',
  },
  {
    id: 5,
    type: FIELD_GROUPING_VALUE.BY_FILTER,
    label: 'Group by Filter',
  },
];

export const LABEL_CONFIGURATION_VARIANTS = [
  {
    id: 1,
    type: LABEL_CONFIGURATION.LEFT,
    label: capitalizeString(LABEL_CONFIGURATION.LEFT),
  },
  {
    id: 2,
    type: LABEL_CONFIGURATION.RIGHT,
    label: capitalizeString(LABEL_CONFIGURATION.RIGHT),
  },
  {
    id: 3,
    type: LABEL_CONFIGURATION.LEGAL,
    label: capitalizeString(LABEL_CONFIGURATION.LEGAL),
  },
  {
    id: 4,
    type: LABEL_CONFIGURATION.NONE,
    label: capitalizeString(LABEL_CONFIGURATION.NONE),
  },
];

export const TEXTAREA_LABEL_CONFIGURATION_VARIANTS = [
  {
    id: 1,
    type: LABEL_CONFIGURATION.TOP,
    label: capitalizeString(LABEL_CONFIGURATION.TOP),
  },
  {
    id: 2,
    type: LABEL_CONFIGURATION.NONE,
    label: capitalizeString(LABEL_CONFIGURATION.NONE),
  },
];

export const QUESTION_ANSWER_STRING = 'string';
export const QUESTION_ANSWER_SELECT = 'select';
export const SELECT_QUESTION_ANSWER: SelectQuestionAnswerType[] = [
  {
    id: 1,
    type: QUESTION_ANSWER_STRING,
    label: 'Text',
  },
  {
    id: 2,
    type: QUESTION_ANSWER_SELECT,
    label: 'Select',
  },
];

export const SELECT_FIELD_TYPE_SELECT = 'select';
export const SELECT_FIELD_TYPE_RADIO = 'radio';
export const SELECT_FIELD_TYPE_CHECKBOX = 'checkbox';

export const SELECT_FIELD_TYPE_RADIO_CHECKBOX = [SELECT_FIELD_TYPE_RADIO, SELECT_FIELD_TYPE_CHECKBOX];

export const SELECT_FIELD_SELECT_OPTION: SelectSelectType[] = [
  {
    id: 1,
    type: SELECT_FIELD_TYPE_SELECT,
    label: 'Dropdown Select',
  },
  {
    id: 2,
    type: SELECT_FIELD_TYPE_RADIO,
    label: 'Radio Button Select',
  },
  {
    id: 3,
    type: SELECT_FIELD_TYPE_CHECKBOX,
    label: 'Checkbox Select',
  },
];

// Properties fields keys
export const FIELD_NAME_KEY = 'field_name';
export const FIELD_API_INFO_KEY = 'field_api_information';
export const MONDAY_COM_INTEGRATION_MODAL = 'monday_com_integration_modal';
export const LABEL_CONFIGURATION_KEY = 'label_configuration';
export const SHORT_NAME_KEY = 'short_name';
export const FIELD_TYPE_KEY = 'field_type';
export const DATE_MASK_KEY = 'date_mask';
export const FIELD_SUBTYPE_KEY = 'field_subtype';
export const FIELD_REMEMBER_KEY = 'field_remember';
export const TEXT_FIELD_MASK_KEY = 'text_field_mask';
export const SELECT_FIELD_TYPE_KEY = 'select_field_type';
export const FIELD_ASSIGNMENT_KEY = 'field_assignment';
export const ANSWER_TYPE_KEY = 'answer_type';
export const FIELD_REQUIRED_KEY = 'field_required';
export const READ_ONLY_KEY = 'field_read_only';
export const GROUP_BY_KEY = 'group_by';
export const GROUP_BY_FILTER_FIELD_KEY = 'group_by_filter_field_key';
export const FIELD_SEARCH_GROUPED_KEY = 'search_grouped_fields';
export const MAX_LENGTH_KEY = 'max_length';
export const SIGNATURE_VARIATION_TYPE_KEY = 'signature_variation';
export const OPAQUE_BACKGROUND_KEY = 'opaque_background_key';
export const HIDE_LABELS_KEY = 'hide_labels_key';

/**
 * Temporarily disabled select field from reuse data feature.
 */
// export const SUBTYPES_APPLICABLE_FIELD_TYPES = [TEXT_FIELD, TEXTAREA_FIELD, DATE_FIELD, SELECT_FIELD];
export const SUBTYPES_APPLICABLE_FIELD_TYPES = [TEXT_FIELD, TEXTAREA_FIELD, DATE_FIELD];

export const ATTACHMENT_DEFAULT_PARAMS = {
  count: 0,
  limit: 1,
  maxLimit: 10,
  maxMBLimit: 20,
};
export const ATTACHMENT_NAME_MAX_LENGTH = 255;

export const TWENTY_MB_IN_BYTES = 20971520;

// Field assignments
export const RECIPIENT_ASSIGNMENT: FieldAssignmetsType = 'recipient';
export const MANAGER_ASSIGNMENT: FieldAssignmetsType = 'manager';
export const READ_ONLY_ASSIGNMENT: FieldAssignmetsType = 'readOnly';
export const FIELD_ASSIGNMENTS = [RECIPIENT_ASSIGNMENT, MANAGER_ASSIGNMENT];

export const INDENT_STEP_MARGIN = 15;
export const MAX_INDENT_STEP_MARGIN = 240;
export const INDENT_INCREASE_FORMAT = 'indent-increase';
export const INDENT_DECREASE_FORMAT = 'indent-decrease';

export const HOT_KEY_SETTINGS: IHotkeySetting[] = [
  {
    style: 'bold',
    value: 'mod+b',
  },
  {
    style: 'italic',
    value: 'mod+i',
  },
  {
    style: 'underline',
    value: 'mod+u',
  },
];

export const TAB_KEY_SETTINGS = {
  tabKey: 'Tab',
  tabIndent: TABULATION,
};

export const LINE_BREAK_SETTINGS = {
  key: 'shift+enter',
  indent: NEW_LINE,
};

// Keycode names
export enum KEY_CODE {
  ENTER = 'Enter',
}

export const PARAGRAPH_BREAK_HOTKEY = 'enter';
export const BACKSPACE_HOTKEY = 'Backspace';
export const DELETE_HOTKEY = 'Delete';
export const ARROW_LEFT_HOTKEY = 'ArrowLeft';
export const ARROW_RIGHT_HOTKEY = 'ArrowRight';
export const BULLETED_LIST_HOTKEY = 'mod+shift+8';

export const TABLE_ELEMENTS = ['table', 'table-head', 'table-body', 'table-row', 'table-cell'];
export const TABLE_TAG_NAMES = ['TABLE', 'TBODY', 'TR', 'TD'];
export const BOLD_FONT_VARIANTS = [BOLD, '500', '600', '700'];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ELEMENT_TAGS: any = {
  A: (element: HTMLElement) => ({ type: 'link', url: element.getAttribute('href') }),
  BLOCKQUOTE: () => ({ type: 'block-quote' }),
  H1: (element: HTMLElement) => ({ type: HEADLINE_ONE, ...setEditorNodeStyles(element.style) }),
  H2: (element: HTMLElement) => ({ type: HEADLINE_TWO, ...setEditorNodeStyles(element.style) }),
  H3: (element: HTMLElement) => ({ type: HEADLINE_THREE, ...setEditorNodeStyles(element.style) }),
  H4: (element: HTMLElement) => ({ type: HEADLINE_FOUR, ...setEditorNodeStyles(element.style) }),
  H5: (element: HTMLElement) => ({ type: HEADLINE_FIVE, ...setEditorNodeStyles(element.style) }),
  H6: (element: HTMLElement) => ({ type: HEADLINE_SIX, ...setEditorNodeStyles(element.style) }),
  IMG: (element: HTMLElement) => ({ type: IMAGE_TYPE, url: element.getAttribute('src') }),
  OL: (element: HTMLElement) => ({ type: NUMBERED_LIST, ...setEditorListMarkStyle(element) }),
  UL: (element: HTMLElement) => ({ type: BULLETED_LIST, ...setEditorListMarkStyle(element) }),
  DL: () => ({ type: BULLETED_LIST }),
  LI: (element: HTMLElement) => ({ type: LIST_ITEM, ...setEditorNodeFont(element.style) }),
  OLLI: (element: HTMLElement) => ({ type: NUMBERED_LIST_ITEM, ...setEditorNodeFont(element.style) }),
  DT: (element: HTMLElement) => ({ type: LIST_ITEM, ...setEditorNodeFont(element.style) }),
  DD: (element: HTMLElement) => ({ type: LIST_ITEM, ...setEditorNodeFont(element.style) }),
  PRE: () => ({ type: 'code' }),
  P: (element: HTMLElement) => ({
    type: PARAGRAPH,
    ...setEditorNodeFont(element.style),
    ...setEditorNodeStyles(element.style),
  }),
  TABLE: () => ({ type: 'table' }),
  TBODY: () => ({ type: 'table-body' }),
  TR: () => createRowBody(0),
  TH: () => createCellBody(),
  TD: () => createCellBody(),
};

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TEXT_TAGS: any = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  B: (element: HTMLElement) => ({
    bold: BOLD_FONT_VARIANTS.includes(element.style?.fontWeight || BOLD),
  }),
  U: () => ({ underline: true }),
  SPAN: (element: HTMLElement) => ({
    bold: BOLD_FONT_VARIANTS.includes(element.style.fontWeight),
    italic: element.style.fontStyle === ITALIC,
    underline: element.style.textDecoration === UNDERLINE,
    strikethrough: element.style.textDecoration === LINE_THROUGH,
    'text-size': Boolean(element.style.fontSize),
    size: parseInt(element.style.fontSize),
  }),
};

export const TEXT_ALIGMENT_FORMAT: IDropdownItem[] = [
  {
    type: 'Text Align Left',
    format: 'text-left',
    label: 'Text Align Left',
  },
  {
    type: 'Text Align Center',
    format: 'text-center',
    label: 'Text Align Center',
  },
  {
    type: 'Text Align Right',
    format: 'text-right',
    label: 'Text Align Right',
  },
  {
    type: 'Text Align Justify',
    format: 'text-justify',
    label: 'Text Align Justify',
  },
];

export const TITLE_FORMAT: IDropdownItem[] = [
  {
    type: 'Header 1',
    format: HEADLINE_ONE,
    label: 'Header 1',
  },
  {
    type: 'Header 2',
    format: HEADLINE_TWO,
    label: 'Header 2',
  },
  {
    type: 'Header 3',
    format: HEADLINE_THREE,
    label: 'Header 3',
  },
  {
    type: 'Header 4',
    format: HEADLINE_FOUR,
    label: 'Header 4',
  },
  {
    type: 'Header 5',
    format: HEADLINE_FIVE,
    label: 'Header 5',
  },
  {
    type: 'Header 6',
    format: HEADLINE_SIX,
    label: 'Header 6',
  },
];
export const TEXT_FORMAT: IDropdownItem[] = [
  {
    type: 'Bold',
    format: BOLD,
    label: 'Bold',
  },
  {
    type: 'Cursive',
    format: ITALIC,
    label: 'Italic',
  },
  {
    type: 'Underline',
    format: UNDERLINE,
    label: 'Underline',
  },
  {
    type: 'Strikethrough',
    format: STRIKE_THROUGH,
    label: 'Strikethrough',
  },
];

export enum DROPDOWN_TYPES {
  FONT_TYPE = 'font-type',
  TEXT_ALIGNMENT = 'text-alignment',
  TITLE_FORMAT = 'title-format',
  TEXT_FORMAT = 'text-format',
  TEXT_SIZE = 'text-size',
  NUMBERED_LIST_VIEW = 'numbered-list-view',
}

export const DEFAULT_FIELD_VALUE_LENGTH: number = 20;
export const MIN_FIELD_VALUE_LENGTH: number = 1;
export const MAX_FIELD_VALUE_LENGTH: number = 200;
export const MAX_SYMBOL_SIZE: number = 10;
export const NONE_FIELD_VALUE_LENGTH: string = '';
export const FIELD_VALUE_LENGTH = {
  MIN_LIMIT: MIN_FIELD_VALUE_LENGTH,
  MAX_LIMIT: MAX_FIELD_VALUE_LENGTH,
};

export const PROPERTIES = {
  VALUE: 'value',
  MAX_LENGTH: 'maxLength',
  REQUIRED_FIELD: 'requiredField',
  VALIDATION_TEXT: 'validationText',
  HELP_TEXT: 'helpText',
  OPTIONS: 'options',
  SELECT_FIELD_TYPE: 'selectFieldType',
};

export const GROUP_FIELDS_DEPENDENCIES_ARRAY = Object.values(PROPERTIES);
export const GROUP_FIELDS_MULTIPLE_DEPENDENCIES_ARRAY = [PROPERTIES.OPTIONS];

export const TABLE_CELL_DISPLAY_STYLE = 'table-cell';

export const enum EditorGridAxisCount {
  One = 1,
  Two = 2,
  Three = 3,
}

export const DISABLED_FIELD_COLOR = '#ccc';