import { FC, SVGAttributes } from 'react';

const MoveIcon: FC<SVGAttributes<unknown>> = ({ fill = 'inherit' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1008_4211)">
      <path d="M10 13.75L11.25 12.5V16.25H13.75L10 20L6.25 16.25H8.75V12.5L10 13.75Z" fill={fill} />
      <path d="M10 6.28875L8.75 7.5V3.75H6.25L10 0L13.75 3.75H11.25V7.5L10 6.28875Z" fill={fill} />
      <path d="M6.25 10L7.5 11.25H3.75V13.75L0 10L3.75 6.25V8.75H7.5L6.25 10Z" fill={fill} />
      <path d="M13.7112 10L12.5 8.75H16.25V6.25L20 10L16.25 13.75V11.25H12.5L13.7112 10Z" fill={fill} />
    </g>
    <defs>
      <clipPath id="clip0_1008_4211">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default MoveIcon;
