import { FC } from 'react';

import ReviewAndSendComponent from 'components/SendDocuments/ReviewAndSend';
import { useReviewAndSend } from 'hooks/signNow';
import { IStepManagerChildComponentProps } from 'types/signNow/base';

const ReviewAndSend: FC<IStepManagerChildComponentProps> = ({
  onNextHandler = () => null,
}) => {
  useReviewAndSend({
    onNextHandler,
  });

  return (
    <div className="step-manager-child">
      <ReviewAndSendComponent />
    </div>
  );
};

export default ReviewAndSend;