import { useState } from 'react';

import { useHistory } from 'react-router-dom';

import ROUTES from 'constants/routes';
import { UseBreadcrumbsHookType } from 'types/signNow/base';

const useBreadcrumbs: UseBreadcrumbsHookType = () => {
  const history = useHistory();
  const [isOpenConfirmationPopup, setIsOpenConfirmationPopup] = useState<boolean>(false);

  const onClosePageHandler = (): void => {
    setIsOpenConfirmationPopup(true);
  };

  const onConfirmHandler = (): void => {
    setIsOpenConfirmationPopup(false);
    history.push(ROUTES.DASHBOARD);
  };

  const onCancelHandler = (): void => {
    setIsOpenConfirmationPopup(false);
  };

  return {
    isOpenConfirmationPopup,
    onClosePageHandler,
    onConfirmHandler,
    onCancelHandler,
  };
};

export default useBreadcrumbs;