import { ChangeEventHandler } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import HeaderDocumentName from 'components/EditorHeader/HeaderDocumentName';
import SetSigner from 'components/SignNow/SelectSignersMenu';
import { EMPTY_DOCUMENT_TITLE_ERROR } from 'constants/signNow';
import { setSignNowDocumentInfo } from 'store/actions/signNow';
import { RootStateType } from 'store/reducers';
import { ISignNowDocument } from 'types/signNow/store';

import 'scss/components/SignNow/_setSigner.scss';

const SetSignersAndDocumentTitleHeader = () => {
  const dispatch = useDispatch();
  const document: ISignNowDocument = useSelector((state: RootStateType) => state.signNow.document);

  const onChangeDocumentTitle: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    dispatch(setSignNowDocumentInfo({
      title: target.value,
      isError: !target.value,
    }));
  };

  return (
    <div className="set-signers-header">
      <SetSigner />
      <div className="d-flex flex-column align-items-center p-3">
        <HeaderDocumentName
          documentName={document.title ?? ''}
          onChangeDocumentName={onChangeDocumentTitle}
          errorText={document.isError ? EMPTY_DOCUMENT_TITLE_ERROR : ''}
        />
      </div>
    </div>
  );
};

export default SetSignersAndDocumentTitleHeader;