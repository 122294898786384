import { Dispatch } from 'redux';

import { FORM_BUILDER_PARAGRAPH, RECIPIENT_ASSIGNMENT } from 'constants/editors';
import { clearFormBuilderSelectedFieldsKeys } from 'store/actions/formBuilder';
import { updateFormBuilderSection, updateFormBuilderSections } from 'store/actions/userData';
import { ICustomElement } from 'types/Editor';
import { FormBuilderType } from 'types/FormBuilder';
import { ICollectionTemplate } from 'types/MultiTemplate';
import { PDFFieldType } from 'types/PdfTemplates';
import { PublicPageDataType } from 'types/PublicPage';
import { IDocumentSection, IFormBuilderSection } from 'types/Sections';
import { ITemplateDetails } from 'types/Templates';
import { arrayInsert } from 'utils/arrayInsert';
import {
  generateNumber,
  getGroupedFieldKeys,
  isFieldsGrouped,
} from 'utils/editorFieldHelpers';
import { getIsPDFDocument, isPDFDocument } from 'utils/PublicPage/documentTypeChecker';
import getFieldsFromData, { getFieldsFromChildren } from 'utils/PublicPage/getFieldsFromData';

export const createFormBuilderSection = ({
  name = 'Section name',
  description = '',
  fields = [],
  documentId = 0,
}: {
  name?: string;
  description?: string;
  fields?: Partial<ICustomElement>[];
  documentId?: number;
}): IFormBuilderSection => ({
  name,
  description,
  documentId,
  key: generateNumber(),
  fields,
});

export const setFieldPositions = (fields: Partial<ICustomElement>[]) => (
  fields.map((field: Partial<ICustomElement>, idx: number) => ({ ...field, position: idx }))
);

export const formBuilderArrayMove = (
  fields: Partial<ICustomElement>[],
  oldIndex: number,
  newIndex: number,
): Partial<ICustomElement>[] => {
  const copyOfFieldsArray: Partial<ICustomElement>[] = [...fields];
  const [item] = copyOfFieldsArray.splice(oldIndex, 1);

  copyOfFieldsArray.splice(newIndex, 0, item);

  return setFieldPositions(copyOfFieldsArray);
};

export const getFormBuilderSectionsFromTemplates = (templates: PublicPageDataType[]) => {
  const sections: IFormBuilderSection[] = [];

  templates.forEach((template: PublicPageDataType) => {
    const isPdfTemplate: boolean = isPDFDocument(template);
    if (isPdfTemplate) {
      const fields: Partial<ICustomElement>[] = getFieldsFromData({
        data: template,
        isPDF: true,
        shouldFilterByAssignment: false,
      });
      sections.push(
        createFormBuilderSection({
          name: template.name,
          description: template.description,
          fields: setFieldPositions(fields),
          documentId: template.id,
        }),
      );
    } else {
      (template as any).sections.forEach((section: IDocumentSection) => {
        const currentSectionFields: Partial<ICustomElement>[] = [];

        const children = section.section.content_json || [];
        getFieldsFromChildren(children, (el: Partial<ICustomElement>) => {
          currentSectionFields.push(el);
        });

        sections.push(
          createFormBuilderSection({
            name: section.section.name,
            documentId: section.document_id,
            description: section.section.description,
            fields: currentSectionFields,
          }),
        );
      });
    }
  });

  return sections;
};

export const updateFormBuilder = (
  formBuilderStructure: IFormBuilderSection[] | null,
  templates: PublicPageDataType[],
): IFormBuilderSection[] => {
  if (!formBuilderStructure || formBuilderStructure.length === 0) {
    return getFormBuilderSectionsFromTemplates(templates);
  }

  const fields = templates.flatMap((template) => (
    getFieldsFromData({
      data: template,
      isPDF: getIsPDFDocument(template.type),
      shouldFilterByAssignment: false,
    })
  ));
  const formBuilderFields = formBuilderStructure.flatMap((section) => section.fields);

  const newFields: Partial<ICustomElement>[] = [];

  fields.forEach((field) => {
    const groupedFieldInFormBuilder = formBuilderFields.find(
      (formBuilderField) => isFieldsGrouped(field, formBuilderField),
    );
    if (groupedFieldInFormBuilder) return false;
    const fieldInFormBuilder = formBuilderFields.find(
      (formBuilderField) => formBuilderField.key === field.key,
    );
    if (!fieldInFormBuilder) {
      newFields.push(field);
    }
  });

  const updatedFormBuilder = formBuilderStructure.map((section) => {
    const sectionFields = section.fields
      .filter((sectionField) => fields.find((field) => (
        field.key === sectionField.key || sectionField.type === FORM_BUILDER_PARAGRAPH
      )))
      .map((sectionField) => {
        const updatedField = fields.find((field) => field.key === sectionField.key);
        if (updatedField) return updatedField;
        return sectionField;
      });

    return { ...section, fields: sectionFields };
  });

  if (newFields.length > 0) {
    const newSection = createFormBuilderSection({
      name: 'New fields from the Document',
      description: '',
      fields: setFieldPositions(newFields),
    });

    updatedFormBuilder.push(newSection);
  }

  return updatedFormBuilder;
};

export const getFieldsToShow = (
  fields: Partial<ICustomElement>[],
  isPublicPage: boolean = false,
  mainAssignment: string = RECIPIENT_ASSIGNMENT,
): {
  fieldsToShow: Partial<ICustomElement>[],
  fieldKeysToFill: { [key: number]: number[] },
} => {
  const fieldsToShow: Partial<ICustomElement>[] = [];
  const fieldKeysToFill: { [key: number]: number[] } = {};

  fields.forEach((field: Partial<ICustomElement>) => {
    const {
      key,
      name,
      assignment,
      type,
    } = field;

    if (type === FORM_BUILDER_PARAGRAPH) {
      fieldsToShow.push({
        ...field,
      });
      return;
    }

    const fieldKey = key as number;

    const similarField: Partial<ICustomElement> | undefined = fieldsToShow.find((field: Partial<ICustomElement>) => {
      if (!field.groupBy || !field.subtype) return undefined;
      return (field.name ? field.name : field.fieldName) === name && field.assignment === assignment;
    });

    if (!similarField) {
      if (isPublicPage) {
        if (field.assignment === mainAssignment) {
          fieldsToShow.push({
            ...field,
          });
          fieldKeysToFill[fieldKey] = [];
        }
        return;
      }
      fieldsToShow.push({
        ...field,
      });
      fieldKeysToFill[fieldKey] = [];
      return;
    }

    fieldKeysToFill[similarField.key as number].push(fieldKey);
  });

  return {
    fieldsToShow,
    fieldKeysToFill,
  };
};

export const getAllFieldsFromFormBuilder = (formBuilder: IFormBuilderSection[]): Partial<ICustomElement>[] => {
  const fields: Partial<ICustomElement>[] = [];
  formBuilder.forEach((formBuilderSection: IFormBuilderSection) => {
    fields.push(...formBuilderSection.fields);
  });
  return fields;
};

const fieldExistsInSection = (
  fieldKey: string,
  section: IFormBuilderSection,
): boolean => section.fields.some((field) => String(field?.key) === fieldKey);

export const arrayMoveFieldBetweenSections = ({
  sectionKey,
  hoveredSectionKey,
  oldIndex,
  newIndex,
  formBuilder,
  dispatch,
  formBuilderType,
  selectedFormBuilderFieldsKeys,
  fieldsToShow,
  groupedFieldsKeys,
}: {
  sectionKey: number | undefined;
  hoveredSectionKey: number;
  oldIndex: number;
  newIndex: number;
  formBuilder: IFormBuilderSection[];
  dispatch: Dispatch;
  formBuilderType: FormBuilderType;
  selectedFormBuilderFieldsKeys: string[];
  fieldsToShow?: Partial<ICustomElement>[];
  groupedFieldsKeys?: Record<number, string[]> | null;
}) => {
  if (sectionKey === hoveredSectionKey && !selectedFormBuilderFieldsKeys.length) {
    if (!fieldsToShow) return;
    const sortedFields: Partial<ICustomElement>[] = formBuilderArrayMove(fieldsToShow, oldIndex, newIndex);
    dispatch(updateFormBuilderSection({
      formBuilder: { fields: sortedFields },
      key: sectionKey,
      formBuilderType,
    }));
    return;
  }
  const hoveringSection: IFormBuilderSection | undefined = formBuilder.find(
    (formBuilderSection: IFormBuilderSection) => (formBuilderSection.key === hoveredSectionKey),
  );
  if (hoveringSection) {
    if (!selectedFormBuilderFieldsKeys.length) {
      if (!fieldsToShow) return;
      const draggableField: Partial<ICustomElement> = fieldsToShow[oldIndex];

      const updatedSection: IFormBuilderSection = {
        ...hoveringSection,
        fields: [
          ...hoveringSection.fields,
          { ...draggableField, position: hoveringSection.fields.length },
        ],
      };

      const sectionWithRemovedField: IFormBuilderSection = {
        ...formBuilder.find(
          (formBuilderSection: IFormBuilderSection) => formBuilderSection.key === sectionKey,
        )
        ?? { name: '', description: '', documentId: 0, key: 0 },
        fields: fieldsToShow.filter((field: Partial<ICustomElement>) => field.key !== draggableField.key),
      };

      const keysToUpdate: number[] = [sectionKey ?? 0, hoveredSectionKey];
      const updatedFormBuilderSection: IFormBuilderSection[] = [
        sectionWithRemovedField,
        updatedSection,
      ];

      const updatedFormBuilder: IFormBuilderSection[] = formBuilder.map(
        (formBuilderSection: IFormBuilderSection) => {
          if (!keysToUpdate.includes(formBuilderSection.key)) {
            return formBuilderSection;
          }

          return updatedFormBuilderSection.find(({ key }: IFormBuilderSection) => (
            formBuilderSection.key === key
          )) ?? formBuilderSection;
        },
      );

      dispatch(updateFormBuilderSections(updatedFormBuilder, formBuilderType));
    } else {
      const dragedFieldsKeys: string[] = [];
      const draggableFields: Partial<ICustomElement>[] = [];
      formBuilder.forEach((section: IFormBuilderSection) => {
        section.fields.forEach((field: Partial<ICustomElement>) => {
          if (selectedFormBuilderFieldsKeys.includes(String(field?.key))) {
            draggableFields.push(field);
          } else if (groupedFieldsKeys) {
            /**
             * By default we add only one grouped field from every field group, it's something like main field in group.
             * It's case when a user drags grouped field but this field doesn't exist in form builder,
             * we need to detect that and add main group field to 'draggableFields' array.
             * It means that user drags the whole group.
             */
            Object.entries(groupedFieldsKeys).forEach(([fieldKey, keysArray]) => {
              selectedFormBuilderFieldsKeys.forEach((selectedKey) => {
                if (keysArray.includes(selectedKey) && Number(fieldKey) === field.key) {
                  draggableFields.push(field);
                  dragedFieldsKeys.push(String(fieldKey));
                }
              });
            });
          }
        });
      });

      dragedFieldsKeys.forEach((key) => selectedFormBuilderFieldsKeys.push(key));

      if (sectionKey === hoveredSectionKey) {
        const fieldsWithoutDraggable = hoveringSection.fields.filter((field: Partial<ICustomElement>) => (
          !selectedFormBuilderFieldsKeys.includes(String(field?.key))
        ));

        const removedBeforeNewIndex = hoveringSection.fields
          .slice(0, newIndex)
          .filter((field: Partial<ICustomElement>) => (
            selectedFormBuilderFieldsKeys.includes(String(field?.key))
          )).length;
        const adjustedIndex = (oldIndex < newIndex) ? newIndex - removedBeforeNewIndex + 1 : newIndex;

        const fieldsBefore = fieldsWithoutDraggable.slice(0, adjustedIndex);
        const fieldsAfter = fieldsWithoutDraggable.slice(adjustedIndex);

        hoveringSection.fields = [...fieldsBefore, ...draggableFields, ...fieldsAfter];
      } else {
        const uniqueDraggableFields = draggableFields.filter(
          (draggableField) => !fieldExistsInSection(String(draggableField?.key), hoveringSection),
        );

        hoveringSection.fields = [
          ...hoveringSection.fields,
          ...uniqueDraggableFields,
        ];
        dispatch(clearFormBuilderSelectedFieldsKeys());
      }

      const updatedFormBuilder = formBuilder.map((section: IFormBuilderSection) => {
        if (section.key === hoveredSectionKey) {
          return hoveringSection;
        }

        return {
          ...section,
          fields: section.fields.filter((field: Partial<ICustomElement>) => (
            !selectedFormBuilderFieldsKeys.includes(String(field?.key))
          )),
        };
      });
      dispatch(updateFormBuilderSections(updatedFormBuilder, formBuilderType));
    }
  }
};

export const addFieldToFormBuilder = (
  formBuilder: IFormBuilderSection[],
  sectionKey: number,
  fieldPosition: number,
  field: ICustomElement,
): IFormBuilderSection[] => formBuilder.map(
  (formBuilderSection: IFormBuilderSection) => {
    if (formBuilderSection.key !== sectionKey) {
      return formBuilderSection;
    }

    return {
      ...formBuilderSection,
      fields: setFieldPositions(arrayInsert({
        array: formBuilderSection.fields,
        insertionPosition: fieldPosition + 1,
        itemToInsert: field,
      })),
    };
  },
);

export const updateParagraphValue = (
  formBuilder: IFormBuilderSection[],
  sectionKey: number,
  fieldKey: number,
  value: string,
) => {
  const sectionToUpdate: IFormBuilderSection | undefined = formBuilder.find((
    section: IFormBuilderSection,
  ) => (
    section.key === sectionKey
  ));

  if (!sectionToUpdate) {
    return formBuilder;
  }

  const updatedParagraph: Partial<ICustomElement>[] = sectionToUpdate?.fields.map(
    (field: Partial<ICustomElement>) => {
      if (field.key !== fieldKey) {
        return field;
      }

      return {
        ...field,
        value,
      };
    },
  );

  const sectionToInsert: IFormBuilderSection = {
    ...sectionToUpdate,
    fields: updatedParagraph,
  };

  return formBuilder.map(
    (formBuilderSection: IFormBuilderSection) => {
      if (formBuilderSection.key !== sectionToInsert.key) {
        return formBuilderSection;
      }

      return sectionToInsert;
    },
  );
};

export const setFormBuilderGroupedFields = (formBuilder: IFormBuilderSection[]): IFormBuilderSection[] => {
  if (formBuilder.length) {
    const allFields = getAllFieldsFromFormBuilder(formBuilder);
    const keys: number[] = [];

    return formBuilder.map((section) => {
      const fields: Partial<ICustomElement>[] = [];

      section.fields.forEach((field) => {
        if (field.key) {
          const groupedFieldKeys = getGroupedFieldKeys(
            allFields as (ICustomElement | PDFFieldType)[],
            field.key,
          );
          if (!keys.includes(field.key)) {
            fields.push(field);
          }
          keys.push(...groupedFieldKeys);
        }
      });

      return {
        ...section,
        fields,
      };
    });
  }

  return [];
};

export const getFieldsFromDeletedTemplate = (template: ITemplateDetails) => {
  if (isPDFDocument(template)) {
    const fields: Partial<ICustomElement>[] = getFieldsFromData({
      data: template,
      isPDF: true,
      shouldFilterByAssignment: false,
    });
    return fields;
  }
  const currentTemplateFields: Partial<ICustomElement>[] = [];
  template.sections.forEach((section: IDocumentSection) => {
    const children = section.section.content_json || [];
    getFieldsFromChildren(children, (el: Partial<ICustomElement>) => {
      currentTemplateFields.push(el);
    });
  });
  return currentTemplateFields;
};

export const getFilteredCollectionFormBuilder = (currentCollectionDetails: any, templateId: number) => {
  if (currentCollectionDetails) {
    const deletedTemplateObject = currentCollectionDetails.templates?.find(
      (template: ICollectionTemplate) => template.template_id === templateId,
    );

    const currentFields = deletedTemplateObject ? getFieldsFromDeletedTemplate(deletedTemplateObject.template) : [];
    const fieldsKeysArray: (number | undefined)[] = currentFields.map((field) => field.key);
    const formBuilder: IFormBuilderSection[] = currentCollectionDetails.form_builder_structure.map(
      (formBuilderSection: IFormBuilderSection) => {
        const sectionCopy = { ...formBuilderSection };
        sectionCopy.fields = formBuilderSection.fields.filter((field) => !fieldsKeysArray.includes(field.key));
        return sectionCopy;
      },
    );

    return formBuilder.filter(
      (formBuilderSection: IFormBuilderSection) => formBuilderSection.fields.length,
    );
  }
};