import {
  ALL_STATUSES,
  COMPLETED_TAB_STATUSES,
  DRAFT_TAB_STATUSES,
  IN_PROGRESS_TAB_STATUSES,
  NEW_TAB_STATUSES,
  NOT_COMPLITTED_TAB_STATUSES,
  SENT_TAB_STATUSES,
} from 'constants/documentStatuses';
import { DocumentStatusType } from 'types/Documents';

export const enum DocumentNavigationTitles {
  ALL_DOCUMENTS = 'Templates',
  ALL_FORMS = 'Templates',
  ALL_PDFS = 'Templates',
  INBOX = 'Inbox',
  NEW = 'New',
  IN_PROGRESS = 'In Progress',
  SENT = 'Sent',
  DRAFTS = 'Drafts',
  COMPLETED = 'Completed',
  NOT_SUBMITTED = 'Not Submitted',
  // ARCHIVED = 'Archived'
}

export interface IDocumentNavigationConfig {
  title: DocumentNavigationTitles;
  statuses: DocumentStatusType;
}

export const documentNavigationConfig: IDocumentNavigationConfig[] = [
  {
    title: DocumentNavigationTitles.ALL_DOCUMENTS,
    statuses: [],
  },
  {
    title: DocumentNavigationTitles.INBOX,
    statuses: ALL_STATUSES,
  },
  {
    title: DocumentNavigationTitles.NEW,
    statuses: NEW_TAB_STATUSES,
  },
  {
    title: DocumentNavigationTitles.IN_PROGRESS,
    statuses: IN_PROGRESS_TAB_STATUSES,
  },
  {
    title: DocumentNavigationTitles.SENT,
    statuses: SENT_TAB_STATUSES,
  },
  {
    title: DocumentNavigationTitles.DRAFTS,
    statuses: DRAFT_TAB_STATUSES,
  },
  {
    title: DocumentNavigationTitles.COMPLETED,
    statuses: COMPLETED_TAB_STATUSES,
  },
  {
    title: DocumentNavigationTitles.NOT_SUBMITTED,
    statuses: NOT_COMPLITTED_TAB_STATUSES,
  },
  // TODO - Will be implemented in future
  // {
  //   title: DocumentNavigationTitles.ARCHIVED,
  //   statuses: []
  // }
];

export const combinePdfOptionWarning = `We currently do not support the .𝐃𝐎𝐂 file format.
Please convert your file to one of these accepted ones: (.pdf, .jpeg, .png, .docx)`;