import { FC, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Input, Label } from 'reactstrap';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { setSelectFieldError } from 'store/actions/editorSlate';
import { RootStateType } from 'store/reducers';
import { SelectFieldOptionType } from 'types/Editor';
import { ISelectFieldOptions } from 'types/Properties';

const SelectFieldOptions: FC<ISelectFieldOptions> = ({
  isRequiredField,
  options,
  changeOptionHandler,
  removeOptionHandler,
  addOptionHandler,
  isPdfEditor = false,
}) => {
  const dispatch = useDispatch();
  // TODO - We have to join state.pdfTemplates and state.editorSlate in one state. e.g state.editor for bouth
  const { selectedField: slateSelectedFieldKey, isSelectFieldError } = useSelector((state: RootStateType) => (
    state.editorSlate
  ));
  const { selectedFieldKey: pdfSelectedFieldKey } = useSelector((state: RootStateType) => state.pdfTemplates);

  const selectedField: number = isPdfEditor ? pdfSelectedFieldKey : slateSelectedFieldKey;

  useEffect(() => {
    if (isRequiredField) {
      const isError = options.length === 0 || Boolean(options.filter((option) => option.label === '').length);
      dispatch(setSelectFieldError(isError, selectedField));
    } else {
      dispatch(setSelectFieldError(false, selectedField));
    }
  }, [options, isRequiredField, dispatch]);

  return (
    <FormGroup>
      {
        selectedField && isSelectFieldError && isSelectFieldError[selectedField] && (
          <Label className="text-danger">
            You have to add at least one not empty option.
          </Label>
        )
      }
      <Label className="d-flex justify-content-between">
        Select Options:
        <AddCircleOutlineIcon
          style={{ cursor: 'pointer' }}
          onClick={() => addOptionHandler()}
        />
      </Label>
      {
        options
          ? options.map(({ id, label, isSelectOtherOption }: SelectFieldOptionType) => (
            <FormGroup
              key={id}
              className="d-flex justify-content-between align-items-center"
            >
              <Input
                name={String(id)}
                onChange={changeOptionHandler(id)}
                value={label}
                type="text"
              />
              {
                !isSelectOtherOption && (
                  <HighlightOffIcon
                    className="ml-1 cursor-pointer"
                    onClick={removeOptionHandler(id)}
                  />
                )
              }
            </FormGroup>
          ))
          : (
            <>No options</>
          )
      }
    </FormGroup>
  );
};

export default SelectFieldOptions;