import { useSelector } from 'react-redux';

import { ADMIN_ROLES, ROLES } from 'constants/roles';
import { RootStateType } from 'store/reducers';
import { isManagerAssignment, isRecipientAssignment } from 'utils/assignmentsHelpers';

const useSignatureAutosaving = (fieldAssignment: string | undefined): boolean => {
  const isAuthenticated = useSelector((state: RootStateType) => state.user.isAuthenticated);
  const { userRole } = useSelector((state: RootStateType) => state.profile.profileInfo ?? {});

  const shouldApplySignatureAutosaving = (
    fieldAssingment: string | undefined,
    userRole: ROLES,
    isAuthenticated: boolean,
  ) => (
    isAuthenticated && (
      (isManagerAssignment(fieldAssingment) && [...ADMIN_ROLES, ROLES.USER].includes(userRole))
      || (isRecipientAssignment(fieldAssingment) && userRole === ROLES.READ_ONLY)
    )
  );

  return shouldApplySignatureAutosaving(fieldAssignment, userRole, isAuthenticated);
};

export default useSignatureAutosaving;