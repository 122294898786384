import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { RootStateType } from 'store/reducers';
import { IPublicPageState } from 'types/PublicPage';

const useAttachmentsUploader = (): boolean => {
  const [isVisibleAttachmentLoaderManager, setAttachmentLoaderVisibilityManager] = useState<boolean>(false);
  const {
    totalAttachedFilesCounter,
    documentId,
    attachmentsQueueCompleted,
    attachments,
  }: IPublicPageState = useSelector((state: RootStateType) => state.publicPages);

  useEffect(() => {
    if (Boolean(totalAttachedFilesCounter) && Boolean(documentId)) {
      setAttachmentLoaderVisibilityManager(true);
    }
  }, [totalAttachedFilesCounter, documentId]);

  useEffect(() => {
    if (attachmentsQueueCompleted === Object.values(attachments).length) {
      setAttachmentLoaderVisibilityManager(false);
    }
  }, [attachmentsQueueCompleted]);

  return isVisibleAttachmentLoaderManager;
};

export default useAttachmentsUploader;