const FormIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M50 3H14C12.8954 3 12 3.89543 12 5V59C12 60.1046 12.8954 61 14 61H50C51.1046 61 52
      60.1046 52 59V5C52 3.89543 51.1046 3 50 3Z"
      stroke="#2F3947"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12 15H52" stroke="#2F3947" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M18 11C19.1046 11 20 10.1046 20 9C20 7.89543 19.1046 7 18 7C16.8954 7 16
      7.89543 16 9C16 10.1046 16.8954 11 18 11Z"
      stroke="#2F3947"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M24 9H40" stroke="#2F3947" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M45 7H48" stroke="#2F3947" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M45 10H48" stroke="#2F3947" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16 19H26" stroke="#2F3947" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M47 23H17C16.4477 23 16 23.4477 16 24V26C16 26.5523 16.4477 27 17 27H47C47.5523 27 48
      26.5523 48 26V24C48 23.4477 47.5523 23 47 23Z"
      stroke="#2F3947"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M16 31H26" stroke="#2F3947" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M47 35H17C16.4477 35 16 35.4477 16 36V48C16 48.5523 16.4477 49 17 49H47C47.5523 49 48 48.5523
      48 48V36C48 35.4477 47.5523 35 47 35Z"
      stroke="#2F3947"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39 53H25C23.8954 53 23 53.8954 23 55C23 56.1046 23.8954 57 25 57H39C40.1046 57 41
      56.1046 41 55C41 53.8954 40.1046 53 39 53Z"
      stroke="#2F3947"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FormIcon;