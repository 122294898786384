import { Descendant, Element as SlateElement } from 'slate';

import { findField } from 'hooks/useSortedSections';
import { BlockFormatType, FilterCallbackType, ICustomElement } from 'types/Editor';
import { PDFFieldType, PDFFieldTypeObject } from 'types/PdfTemplates';
import { PublicPageDataType } from 'types/PublicPage';
import { IDocumentSection } from 'types/Sections';
import { isDocumentType, isTemplateType } from 'utils/typeChecker';

export const getFieldsFromChildren = (children: Descendant[], callback: (el: Partial<SlateElement>) => void) => {
  children.forEach((el: Descendant) => {
    const fields = el.children || [];
    fields.forEach((node: Descendant) => {
      findField(node, (el: Partial<SlateElement>) => {
        callback(el);
      });
    });
  });
};

export const getFieldsFromChildrenAndUpdate = (
  nodes: Descendant[],
  callback: FilterCallbackType<Descendant>,
): Descendant[] => nodes.map((node) => {
  const newNode = callback(node);
  if (!newNode.children) return newNode;
  return {
    ...newNode,
    children: getFieldsFromChildrenAndUpdate(newNode.children, callback),
  };
});

interface IFieldsGetter {
  data: PublicPageDataType | null,
  assignment?: string,
  isPDF: boolean,
  fieldType?: BlockFormatType,
  isOnlyWithSubtypes?: boolean,
  customFilterHandler?: (array: Partial<SlateElement>[]) => Partial<SlateElement>[];
  shouldFilterByAssignment?: boolean;
}

const getFieldsFromPDFData = (contentData: PDFFieldTypeObject | Descendant[], assignment?: string) => {
  const filteredArray: Partial<SlateElement>[] = [];
  const fieldsArray = Object.values(contentData)
    .sort((a, b) => (a.position || 0) - (b.position || 0));
  if (assignment) {
    filteredArray.push(...fieldsArray.filter((field: Partial<SlateElement>) => field.assignment === assignment));
  } else {
    filteredArray.push(...fieldsArray);
  }
  return filteredArray;
};

export const getFieldsFromContentByAssingment = ({
  content,
  assignment,
  isPDF,
}: {
  content: Descendant[] | PDFFieldTypeObject,
  assignment: string,
  isPDF: boolean
}) => {
  const array: Partial<SlateElement>[] = [];
  if (isPDF) {
    const filteredFields = getFieldsFromPDFData(content, assignment);
    array.push(...filteredFields);
  } else {
    (content as Descendant[]).forEach((section: Descendant) => {
      const children = section.children || [];
      getFieldsFromChildren(children, (el: Partial<SlateElement>) => {
        if (el.assignment === assignment) {
          array.push(el);
        }
      });
    });
  }
  return array;
};

const getFieldsFromData = ({
  data,
  assignment,
  isPDF,
  fieldType,
  isOnlyWithSubtypes = false,
  customFilterHandler,
  shouldFilterByAssignment = true,
}: IFieldsGetter): Partial<ICustomElement | PDFFieldType>[] => {
  const array: Partial<ICustomElement | PDFFieldType>[] = [];

  if (isTemplateType(data)) {
    if (isPDF) {
      const filteredFields = getFieldsFromPDFData(
        data.pdf_fields_json,
        (shouldFilterByAssignment && assignment) || undefined,
      );
      array.push(...filteredFields);
    } else {
      data.sections.forEach((section: IDocumentSection) => {
        const children = section.section.content_json || [];
        getFieldsFromChildren(children, (el: Partial<SlateElement>) => {
          if (assignment) {
            if (el.assignment === assignment) {
              array.push(el);
            }
          } else {
            array.push(el);
          }
        });
      });
    }
  }
  if (isDocumentType(data)) {
    if (isPDF) {
      const filteredFields = getFieldsFromPDFData(
        data.content_json,
        assignment,
      );
      array.push(...filteredFields);
    } else {
      (data.content_json as Descendant[]).forEach((section: Descendant) => {
        const children = section.children || [];
        getFieldsFromChildren(children, (el: Partial<SlateElement>) => {
          if (assignment) {
            if (el.assignment === assignment) {
              array.push(el);
            }
          } else {
            array.push(el);
          }
        });
      });
    }
  }

  if (fieldType) {
    return array.filter((el) => el.type === fieldType);
  }

  if (isOnlyWithSubtypes) {
    return array.filter((el) => el.subtype);
  }

  if (customFilterHandler) {
    return customFilterHandler(array);
  }

  return array;
};

export default getFieldsFromData;