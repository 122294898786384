import { Descendant, Element as SlateElement } from 'slate';

import { ATTACHMENT_FIELD, SELECT_FIELD_TYPE_SELECT } from 'constants/editors';
import { PDFFieldType } from 'types/PdfTemplates';
import { isCheckboxSelectView } from 'utils/Fields/checkboxRadioHelpers';
import { getIsSelectField } from 'utils/Fields/fieldsTypeChecker';
import { getFieldsFromChildren } from 'utils/PublicPage/getFieldsFromData';

const getRequiredFields = <T extends Partial<SlateElement> | PDFFieldType>(fields: T[]): T[] => (
  fields.filter((field: T) => field.requiredField)
);

const areAllFieldsFilledIn = <T extends Partial<SlateElement> | PDFFieldType>(fields: T[]): boolean => (
  fields.every((field: T) => {
    if (
      getIsSelectField(field.type)
      && isCheckboxSelectView(field.selectFieldType ?? SELECT_FIELD_TYPE_SELECT)
    ) {
      return field.options?.some(({ checked }) => checked);
    }

    return Boolean(field.value);
  })
);

export const areAllRequiredFieldsFilledIn = <T extends Partial<SlateElement> | PDFFieldType>(
  fields: T[],
  isError: boolean,
): boolean => (
    areAllFieldsFilledIn(getRequiredFields(fields)) && !isError
  );

export const extractFieldsFromChildren = (children: Descendant[]): Partial<SlateElement>[] => {
  const fields: Partial<SlateElement>[] = [];
  getFieldsFromChildren(children, (field: Partial<SlateElement>) => fields.push(field));
  return fields;
};

export const getMappedFieldKeyValues = <T extends Partial<SlateElement> | PDFFieldType>(
  fields: T[],
  mainAssignment: string,
): Array<{ key: number; isFilledIn: boolean }> => (
    fields
      .filter(({ assignment, type }) => (
        assignment === mainAssignment && type !== ATTACHMENT_FIELD
      ))
      .map(({ key, value }: T) => ({
        key: key ?? 0,
        isFilledIn: Boolean(value),
      }))
  );