import { Dispatch, FC, SetStateAction, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import ButtonGeneral from 'components/ButtonGeneral';
import SearchInput from 'components/SearchInput';
import { GROUP_MANAGEMENT_USER_ACTIONS } from 'constants/general';
import useUserListLoader from 'hooks/useUserListLoader';
import { updateGroupMember } from 'store/actions/userProfile';
import { RootStateType } from 'store/reducers';
import { SearchInputValue } from 'types/Misc';
import { GroupListingType, UserListStoreType } from 'types/userProfile';
import { getAvaliableToSelectUsers } from 'utils/groupHelpers';

interface IAddGroupMembersModal {
  isModalOpen: boolean;
  onCloseModal: Dispatch<SetStateAction<boolean>>;
  groupId?: string;
}

const AddGroupMembersModal: FC<IAddGroupMembersModal> = ({
  isModalOpen,
  onCloseModal,
  groupId,
}) => {
  const [value, setValue] = useState<SearchInputValue>({
    id: '',
    label: '',
  });
  const [isError, setIsError] = useState<boolean>(false);
  const dispatch = useDispatch();
  const group: GroupListingType = useSelector((state: RootStateType) => state.profile.group);

  const { users: appUsers }: UserListStoreType = useUserListLoader();

  const searchOptions: SearchInputValue[] = getAvaliableToSelectUsers({ appUsers, groupUsers: group?.users ?? [] });

  const getIsUserExists = (selectedUser: SearchInputValue): boolean => (
    Boolean(
      searchOptions.find(({ id, label }: SearchInputValue) => selectedUser?.id === id && selectedUser?.label === label),
    )
  );

  const onAddMemberHandler = () => {
    const isUserExists: boolean = getIsUserExists(value);

    if (!isUserExists) {
      setIsError(true);
      return;
    }

    dispatch(updateGroupMember(value.id, groupId ?? String(group?.id), GROUP_MANAGEMENT_USER_ACTIONS.ADD));

    setValue({ id: '', label: '' });
    setIsError(false);
    onCloseModal(false);
  };

  const onChangeHandler = (value: SearchInputValue): void => {
    const isUserExists: boolean = getIsUserExists(value);
    setIsError(isUserExists && !value);

    setValue(value);
  };

  return (
    <Modal isOpen={isModalOpen}>
      <ModalHeader toggle={() => {
        setValue({ id: '', label: '' });
        setIsError(false);
        onCloseModal(false);
      }}
      >
        Add group member
      </ModalHeader>
      <ModalBody>
        <SearchInput data={searchOptions} value={value} onChange={onChangeHandler} isError={isError} />
      </ModalBody>
      <ModalFooter>
        <ButtonGeneral
          buttonText="Add group member"
          onClickCallback={onAddMemberHandler}
          buttonStyles={{
            margin: '0 auto',
          }}
        />
      </ModalFooter>
    </Modal>
  );
};

export default AddGroupMembersModal;