import { FC, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Button from 'components/Base/Button';
import Logo from 'components/Intro/Logo';
import { ShareViewModes } from 'constants/shareViewModes';
import useStyleSchema, { CustomStylesEnum } from 'hooks/useStylesFromSchema';
import {
  deletePersistentStorageDataByKey,
  restoreDataFromPersistentStorage,
  setRestoreDataModalVisibility,
} from 'store/actions/persistentReduxStorage';
import { RootStateType } from 'store/reducers';

import classes from 'scss/components/Modal/ConfirmationModal.module.scss';

const RestoreDataModal: FC = () => {
  const dispatch = useDispatch();
  const key = useSelector((state: RootStateType) => state.publicPages.structure.main.id);
  const { data, restoreDataModalWasVisible } = useSelector((state: RootStateType) => state.persistentStorage);
  const viewMode = useSelector((state: RootStateType) => state.publicPages.currentDocument.viewMode);
  const isAuthenticated: boolean = useSelector((state: RootStateType) => state.user.isAuthenticated);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const styleSchema = useStyleSchema<
    CustomStylesEnum.button
    | CustomStylesEnum.outlinedBorderButton
    | CustomStylesEnum.title
    | CustomStylesEnum.description
  >([
    CustomStylesEnum.button,
    CustomStylesEnum.outlinedBorderButton,
    CustomStylesEnum.title,
    CustomStylesEnum.description,
  ]);

  useEffect(() => {
    if (
      !restoreDataModalWasVisible
      && Object.keys(data).includes(key)
      && viewMode !== ShareViewModes.SHARE_MODE_FORM_BUILDER
    ) {
      setIsOpen(true);
    } else {
      dispatch(setRestoreDataModalVisibility(true));
    }
  }, [key, restoreDataModalWasVisible, data, isAuthenticated, viewMode]);

  if (!key) return null;

  const closeModalHandler = () => {
    setIsOpen(false);
    dispatch(setRestoreDataModalVisibility(true));
    dispatch(deletePersistentStorageDataByKey(key));
  };

  const restoreDataFromStorageHandler = () => {
    dispatch(restoreDataFromPersistentStorage(key));
    setIsOpen(false);
    dispatch(setRestoreDataModalVisibility(true));
  };

  return (
    <Modal isOpen={isOpen} centered modalClassName="restore-data-modal">
      <ModalHeader toggle={closeModalHandler}>
          <span className={classes.Logo}>
            <Logo />
          </span>
        <span
          className={classes.Title}
          style={styleSchema.title}
        >
          We detect your device.
        </span>
      </ModalHeader>
      <ModalBody>
        <div className={classes.Body}>
          <div
            className="text-center"
            style={styleSchema.description}
          >
            <p>
              Would you like to restore your progress?
            </p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex align-items-center justify-content-center w-100 gap-4">
          <Button
            onClick={restoreDataFromStorageHandler}
            style={styleSchema.button}
          >
            Yes
          </Button>
          <Button
            classes="outline outlined-with-border"
            onClick={closeModalHandler}
            style={styleSchema.outlinedBorderButton}
          >
            No
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default RestoreDataModal;