import StepManager from 'components/SignNow/StepManager';
import { SIGN_NOW_COMPONENT_MAP, SIGN_NOW_STEP_CONFIG } from 'constants/signNow';
import { useSignNowPage } from 'hooks/signNow';

const SignNowPage = () => {
  const { initStep } = useSignNowPage();

  return (
    <StepManager
      withBreadcrumbs
      initStep={initStep}
      config={SIGN_NOW_STEP_CONFIG}
      componentMap={SIGN_NOW_COMPONENT_MAP}
    />
  );
};

export default SignNowPage;