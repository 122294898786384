import { FC, ChangeEventHandler, useMemo } from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import InfoSection from 'components/InfoSection';
import SavePdfButton from 'components/EditorHeader/SavePdfButton';
import Select from 'components/Select';
import SelectDoctype from 'components/SelectDoctype';
import SelectState from 'components/SelectState';
import StateDropdown from 'components/StateDropdown';

import { FORM_TAB_ID } from 'constants/tabs';
import { CANCEL, SAVE } from 'constants/general';
import { RootStateType } from 'store/reducers';

import {
  IDoctype,
  IMetadataId,
  IState,
  IStateList,
} from 'types/Metadata';

import { createLabels } from 'utils/labelCreator';

export interface IFooterProps {
  to: string;
  saveDoc: () => void;
  multipleState?: boolean;
  labelArray?: (string | undefined)[];
  states?: IState[];
  selectedState?: IMetadataId[] | string;
  selectedStateList?: IStateList[];
  selectState?: (state: any) => void;
  doctypes?: IDoctype[];
  selectedDoctype?: IMetadataId | null;
  selectDoctype?: (doctype: any) => void;
  readOnlyMode?: boolean;
  showShareLinkModal?: boolean;
  handlerShareLinkModal?: () => void;
  statusObject?: {
    statuses?: string[];
    selectedStatus?: string;
    selectStatus?: (status: any) => void;
    showKeys?: boolean;
  };
  isShareButtonAvailable?: boolean;
  openManagerPageHandler?: () => void;
  showDownloadPdfButton?: boolean;
}

const Footer: FC<IFooterProps> = (props) => {
  const {
    to,
    saveDoc,
    labelArray,
    states,
    selectState,
    selectedState,
    selectedStateList,
    doctypes,
    selectDoctype,
    selectedDoctype,
    multipleState = false,
    statusObject,
    readOnlyMode = false,
    showShareLinkModal,
    handlerShareLinkModal,
    isShareButtonAvailable = true,
    openManagerPageHandler,
    showDownloadPdfButton = false,
  } = props;

  const { activeEditorTab, isSelectFieldError } = useSelector((state: RootStateType) => state.editorSlate);
  const disableSaveButton = isSelectFieldError && Object.values(isSelectFieldError).find((el) => el);

  const onChangeDoctype: ChangeEventHandler<HTMLSelectElement> = (event) => {
    if (!readOnlyMode) {
      if (!selectDoctype) return;
      selectDoctype({ id: event.target.selectedOptions[0].id });
    }
  };

  const onChangeState: ChangeEventHandler<HTMLSelectElement> = (event) => {
    if (!readOnlyMode) {
      if (!selectState) return;
      selectState([{ id: event.target.selectedOptions[0].id }]);
    }
  };

  const onChangeStatus: ChangeEventHandler<HTMLSelectElement> = async (event) => {
    if (!statusObject?.selectStatus) return;
    statusObject.selectStatus(event.target.selectedOptions[0].value);
  };

  const valueSelectDoctype = useMemo(() => {
    if (!doctypes || !selectedDoctype) return;
    return doctypes.find((doctype) => doctype.id === selectedDoctype.id)?.name;
  }, [doctypes, selectedDoctype]);

  const valueSelectState = useMemo(() => {
    if (!states || !selectedState) return;
    if (typeof selectedState === 'string') return;
    return states.find((state) => state.id === selectedState[0].id)?.abbreviation;
  }, [states, selectedState]);

  const classWrapper = useMemo(() => (
    multipleState ? 'col-xl-7 col-md-6 col-sm-3 col-4' : 'w-auto'
  ), [multipleState]);

  return (
    <footer className="footer me-auto d-flex align-items-center">
      {
        labelArray && (
        <div className="me-3 d-none d-xl-block">
          {
            createLabels(labelArray).map((label) => (
              <InfoSection
                label={label.label}
                text={label.text}
                key={label.label}
              />
            ))
          }
        </div>
        )
      }
      <div className={classWrapper}>
        <SelectDoctype
          doctypes={doctypes}
          selectedDoctype={selectedDoctype}
          valueSelectDoctype={valueSelectDoctype}
          onChangeDoctype={onChangeDoctype}
          readOnlyMode={readOnlyMode}
        />
        {states && (
          multipleState ? (
            <>
              <p className="label mw-2 mb-1 d-inline-block">State:</p>
              <div className="w-50 d-inline-block me-2 mb-0">
                <StateDropdown
                  selectedStates={selectedStateList}
                  states={states}
                  setSelectedStates={selectState}
                  grayTheme
                />
              </div>
            </>
          ) : (
            <SelectState
              states={states}
              selectedState={selectedState}
              valueSelectState={valueSelectState}
              onChangeState={onChangeState}
              readOnlyMode={readOnlyMode}
            />
          )
        )}
        {statusObject && statusObject.statuses && (
          <Select
            title="Status"
            values={statusObject.statuses}
            selectedValue={statusObject.selectedStatus || ''}
            onChangeValue={onChangeStatus}
            showKeys={statusObject.showKeys}
          />
        )}
      </div>
      <div className="footer__buttons me-2 mt-1 mb-1">
        {
          (typeof openManagerPageHandler !== 'undefined' && !readOnlyMode) && (
            <Button
              className="me-2 pe-2 pw-2 btn-big"
              color="primary"
              onClick={openManagerPageHandler}
              disabled={disableSaveButton}
            >
              Fill & Sign
            </Button>
          )
        }
        {
          // "showShareLinkModal" will be true or false. We show this button in both variants
          showShareLinkModal !== undefined && isShareButtonAvailable && (
            <Button
              className="me-2"
              color="primary"
              onClick={handlerShareLinkModal}
              disabled={disableSaveButton}
            >
              Share
            </Button>
          )
        }
        {
          showDownloadPdfButton && activeEditorTab !== FORM_TAB_ID && (<SavePdfButton />)
        }
        <Button
          className="pw-2 pe-2"
          color="primary"
          onClick={saveDoc}
          disabled={disableSaveButton}
        >
          {SAVE}
        </Button>
        <div className="text-center">
          <Link to={to} className="text-link">
            <Button color="secondary" className="pw-2 pe-2 mx-2" outline>
              {CANCEL}
            </Button>
          </Link>
        </div>
        <Button className="hidden attachments-loading" id="attachments-loading" />
      </div>
    </footer>
  );
};

export default Footer;