import { FC, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import ROUTES from 'constants/routes';
import { IStepManagerChildComponentProps } from 'types/signNow/base';

const Upload: FC<IStepManagerChildComponentProps> = () => {
  // TODO: Develop Upload Component
  const history = useHistory();

  useEffect(() => {
    history.push(ROUTES.DASHBOARD);
  }, [history]);

  return null;
};

export default Upload;