import { ChangeEventHandler, FC, memo, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import NavigationArrows from 'components/Editor/components/DocumentNavigationArrows/NavigationArrows';
import ErrorValidation from 'components/Editor/components/ErrorValidation';
import { IRenderElementPropsWithValidation } from 'components/Editor/components/RenderElement';
import { QUESTION_ANSWER_STRING } from 'constants/editors';
import { DOCUMENT_TAB_ID } from 'constants/tabs';
import { useAssignmentFieldColor } from 'hooks/signNow';
import { updateFormBuilderField } from 'store/actions/publicPages';
import { RootStateType } from 'store/reducers';
import { PublicPageDataType } from 'types/PublicPage';
import { SignerType } from 'types/SendDocuments';
import { getFieldLabelFromChildren } from 'utils/createNameConstant';
import { getSelectedFieldClass, setSelectedFieldState } from 'utils/editorFieldHelpers';
import { getFieldFontStyles } from 'utils/Fields/fieldFontSizeHelpers';
import { getIsDocumentWithFormBuilder } from 'utils/PublicPage/documentTypeChecker';
import { cleanTableQuestionSelection } from 'utils/TableQuestion/fieldsTableQuestionHelpers';
import { updateFieldNameAndProps } from 'utils/updateNode';
import { validateFieldHelper } from 'utils/validation';

const QuestionEditable: FC<IRenderElementPropsWithValidation> = ({
  attributes,
  children,
  element,
  readOnlyMode = false,
  isError = false,
  validationErrorText = null,
  filledInFields = undefined,
  isPublicPage = undefined,
}) => {
  const editor = useSlateStatic();
  const dispatch = useDispatch();
  const { selectedField, selectedTableQuestionKey } = useSelector((state: RootStateType) => state.editorSlate);
  const [value, setValue] = useState(element.value);
  const [showToolbar, setShowToolbar] = useState<boolean>(false);

  const publicPagesData: PublicPageDataType | null = useSelector((state: RootStateType) => state.publicPages.data);
  const isDocumentWithFormBuilderView = getIsDocumentWithFormBuilder(publicPagesData?.view_mode);

  useEffect(() => {
    updateFieldNameAndProps(editor, element);
  }, [element.children]);

  const onChangeHandler: ChangeEventHandler<HTMLSelectElement | HTMLInputElement> = (event) => {
    if (!readOnlyMode) {
      validateFieldHelper({
        field: element,
        prevValue: value || '',
        value: event.target.value,
        dispatch,
      });
      setValue(event.target.value);
      const path = ReactEditor.findPath(editor, element);
      Transforms.setNodes(editor, { value: event.target.value }, { at: path });
      if (isDocumentWithFormBuilderView) {
        dispatch(updateFormBuilderField([element.key ?? 0], { value: event.target.value }));
      }
    }
  };

  const clickFieldHandler = () => {
    setSelectedFieldState(selectedField, element.key, dispatch);
    cleanTableQuestionSelection(dispatch, element?.isTableField, selectedTableQuestionKey);
  };

  const hoverFieldHandler = () => {
    if (!editor.publicPage && !readOnlyMode) {
      setShowToolbar(true);
    }
  };

  const leaveFieldHandler = () => {
    setShowToolbar(false);
  };

  const selectedFieldClass = getSelectedFieldClass([Number(selectedField)], element.key);
  const assignmentColor = useAssignmentFieldColor(
    element.assignment ?? SignerType.RECIPIENT,
    readOnlyMode,
  );

  return (
    <span
      className={`textarea-wrap ${showToolbar ? 'blue-bg' : ''}${selectedFieldClass}`}
      data-field-key={element.key}
      data-active-tab={DOCUMENT_TAB_ID}
      data-field-label={getFieldLabelFromChildren(element.children)}
      data-element-type={element.type}
      data-field-assignment={element.assignment}
      onClick={clickFieldHandler}
      onFocus={hoverFieldHandler}
      onMouseOver={hoverFieldHandler}
      onBlur={leaveFieldHandler}
      onMouseLeave={leaveFieldHandler}
      role="textbox"
      tabIndex={-1}
      style={getFieldFontStyles(children, element.fontSize)}
    >
      <NavigationArrows field={element} isPublicPage={isPublicPage} filledInFields={filledInFields}>
        <>
          <span
            {...attributes}
            className="label-text-field-editable label-text-field-editable__after"
            style={{ color: assignmentColor }}
          >
            {children}
          </span>
          <span
            {...attributes}
            contentEditable={false}
            style={{
              userSelect: 'none',
              position: 'relative',
            }}
            className="me-1 d-block"
          >
            {
              element.answerType === QUESTION_ANSWER_STRING ? (
                <input
                  type="text"
                  name={element.name}
                  value={value}
                  className="w-100 text-field-editable"
                  autoComplete="off"
                  onChange={onChangeHandler}
                  disabled={readOnlyMode}
                  tabIndex={readOnlyMode ? -1 : 0}
                />
              ) : (
                <select
                  className="w-100 select-field-editable"
                  name={element.name}
                  data-value={value || ''}
                  value={value || ''}
                  onChange={onChangeHandler}
                  disabled={readOnlyMode}
                  tabIndex={readOnlyMode ? -1 : 0}
                >
                  <option value="" />
                  {
                    element?.options?.map(({ id, label }) => (
                      <option
                        key={id}
                        value={label}
                        data-id={id}
                      >
                        {label}
                      </option>
                    ))
                  }
                </select>
              )
            }
            <ErrorValidation isError={isError} errorText={validationErrorText} />
          </span>
        </>
      </NavigationArrows>
    </span>
  );
};

export default memo(QuestionEditable);