import { Dispatch, FC, SetStateAction } from 'react';

import { Link } from 'react-router-dom';

import { DASH_SPACE, WHITE_SPACE } from 'constants/editors';
import { PoliciesEnumType, PRIVACY_HEADER, TERMS_OF_USE_HEADER } from 'constants/policies';
import { getHTMLFromStaticDir } from 'services/api';
import { IPoliciesModalData } from 'types/Misc';

interface IPoliciesLink {
  type: PoliciesEnumType;
  setModalContent?: Dispatch<SetStateAction<IPoliciesModalData | null>>;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
}

const convertToLink = (string: string) => (
  string.split(WHITE_SPACE).join(DASH_SPACE).toLowerCase()
);

const PoliciesLink: FC<IPoliciesLink> = ({
  type,
  setModalContent,
  setIsOpen,
}) => {
  const onDocumentLinkClick = async (policyName: PoliciesEnumType) => {
    const modalHeader = policyName === PoliciesEnumType.PRIVACY_POLICY ? PRIVACY_HEADER : TERMS_OF_USE_HEADER;
    const modalText = await getHTMLFromStaticDir(
      policyName === PoliciesEnumType.TERMS_OF_SERVICES
        ? convertToLink(PoliciesEnumType.TERMS_OF_USE)
        : convertToLink(policyName),
    );

    if (modalText && setModalContent) {
      setModalContent({ modalHeader, modalText });
    }
    if (setIsOpen) {
      setIsOpen(true);
    }
  };

  return (
    <Link
      to="#"
      color="link"
      onClick={() => onDocumentLinkClick(type)}
    >
      {type}
    </Link>
  );
};

export default PoliciesLink;