import { FC, memo, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useSlateStatic } from 'slate-react';

import NavigationArrows from 'components/Editor/components/DocumentNavigationArrows/NavigationArrows';
import SlateTextAreaField from 'components/Editor/components/Fields/SlateTextAreaField';
import { IRenderElementPropsWithValidation } from 'components/Editor/components/RenderElement';
import FieldToolbar from 'components/Editor/components/Toolbars/FieldToolbar';
import { DOCUMENT_TAB_ID } from 'constants/tabs';
import { useAssignmentFieldColor } from 'hooks/signNow';
import useFieldsHandler from 'hooks/useFieldsHandler';
import { useFormFields } from 'hooks/useFormFields';
import useSlateFieldHandlers from 'hooks/useSlateFieldHandlers';
import { RootStateType } from 'store/reducers';
import { LABEL_CONFIGURATION } from 'types/Editor';
import { SignerType } from 'types/SendDocuments';
import { createFieldDataAttributes } from 'utils/editorCopyPasteHelpers';
import {
  getGroupedFieldKeys,
  getSelectedFieldClass,
  isFieldHasSubtype,
  labelConfigurationHandler,
} from 'utils/editorFieldHelpers';
import { getFieldFontStyles } from 'utils/Fields/fieldFontSizeHelpers';
import { updateFieldNameAndProps } from 'utils/updateNode';
import { validateFieldHelper } from 'utils/validation';

const TextAreaEditable: FC<IRenderElementPropsWithValidation> = ({
  attributes,
  children,
  element,
  readOnlyMode = false,
  isError = false,
  validationErrorText = null,
  filledInFields = undefined,
  isPublicPage = undefined,
}) => {
  const editor = useSlateStatic();
  const dispatch = useDispatch();
  const { selectedField, selectedTableQuestionKey } = useSelector((state: RootStateType) => state.editorSlate);
  const [showToolbar, setShowToolbar] = useState<boolean>(false);

  const { updateFieldsHandler } = useFieldsHandler({ editor });

  const {
    clickFieldHandler,
    hoverFieldHandler,
    leaveFieldHandler,
  } = useSlateFieldHandlers({
    selectedField,
    selectedTableQuestionKey,
    element,
    editor,
    isReadOnly: readOnlyMode,
    callback: setShowToolbar,
  });

  const forms = useFormFields(true, false);

  useEffect(() => {
    updateFieldNameAndProps(editor, element);
  }, [element.children]);

  const updateTextareaField = (fieldValue: string) => {
    if (!readOnlyMode) {
      validateFieldHelper({
        field: element,
        prevValue: element.value || '',
        value: fieldValue,
        dispatch,
      });
      updateFieldsHandler(element, { value: fieldValue });
    }
  };

  const onTrimFieldValue = (value: string) => {
    updateTextareaField(value.trim());
  };

  const hasSubtype = !editor.publicPage && isFieldHasSubtype(element.subtype);
  const idsToSelect = getGroupedFieldKeys(forms || [], selectedField ?? 0);
  const selectedFieldClass = getSelectedFieldClass(idsToSelect, element.key, hasSubtype);

  const {
    isTopLabel,
  } = labelConfigurationHandler(element.labelConfiguration ?? LABEL_CONFIGURATION.TOP);
  const assignmentColor = useAssignmentFieldColor(
    element.assignment ?? SignerType.RECIPIENT,
    readOnlyMode,
  );

  return (
    <span
      className={`custom-subtype-highlight textarea-wrap ${showToolbar ? 'blue-bg' : ''}${selectedFieldClass}`}
      {...(createFieldDataAttributes({
        field: element,
        activeTab: DOCUMENT_TAB_ID,
      }))}
      onClick={clickFieldHandler}
      onFocus={hoverFieldHandler}
      onMouseOver={hoverFieldHandler}
      onBlur={leaveFieldHandler}
      onMouseLeave={leaveFieldHandler}
      role="textbox"
      tabIndex={-1}
      style={getFieldFontStyles(children, element.fontSize)}
    >
      <NavigationArrows field={element} isPublicPage={isPublicPage} filledInFields={filledInFields}>
        <>
          {showToolbar && <FieldToolbar fieldKey={element.key} />}
          {
            isTopLabel
            && (
              <span
                {...attributes}
                className="label-text-field-editable label-text-field-editable__after"
                style={{ color: assignmentColor }}
              >
                {children}
              </span>
            )
          }
          <SlateTextAreaField
            name={element.name ?? ''}
            initValue={element.value ?? ''}
            onChangeInitValue={onTrimFieldValue}
            isError={isError}
            validationErrorText={validationErrorText}
            isReadOnly={readOnlyMode}
          />
        </>
      </NavigationArrows>
    </span>
  );
};

export default memo(TextAreaEditable);