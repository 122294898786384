import React, { useRef } from 'react';

import DragIndicator from '@mui/icons-material/DragIndicator';
import { Element as SlateElement, Path, Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';

import { DROP_BUFFER_FILLER } from 'constants/copyPaste';
import { SECTION } from 'constants/editors';

interface IFieldToolbarProps {
  fieldKey?: number;
}

const FieldToolbar: React.FC<IFieldToolbarProps> = ({ fieldKey = 0 }) => {
  const editor = useSlate();
  const toolbarRef = useRef<HTMLDivElement>(null);
  const showToolbarStyle = fieldKey ? '' : 'd-none';

  const onDragStartHandler = (event: React.DragEvent) => {
    if (fieldKey) {
      Transforms.deselect(editor);
      event.dataTransfer?.setData('text/plain', DROP_BUFFER_FILLER);
    }
  };

  const onDragEndHandler = (event: React.DragEvent) => {
    event.preventDefault();
    // TODO: get this element without query selector while doing the next tasks
    const fieldNode = document.querySelector<HTMLElement>(`[data-field-key="${fieldKey}"]`);

    if (fieldNode) {
      const { selection } = editor;
      const path = ReactEditor.findPath(editor, ReactEditor.toSlateNode(editor, fieldNode));
      const parentPath = selection && Path.parent(selection.anchor.path);

      if (!selection?.anchor.path) return;

      Transforms.moveNodes(editor, {
        match: (node) => (
          SlateElement.isElement(node) && node.type !== SECTION && node.key === fieldKey
        ),
        mode: 'all',
        at: path,
        to: parentPath ? [...parentPath, 1] : selection.anchor.path,
      });
    }
  };

  return (
    <span
      className={`draggable-toolbar-menu ${showToolbarStyle}`}
      draggable
      onDragStart={onDragStartHandler}
      onDragEnd={onDragEndHandler}
      ref={toolbarRef}
    >
      <span
        className="draggable-toolbar-wrapper field-selectable"
        contentEditable={false}
        key={`toolbar_${fieldKey}`}
      >
        <span
          className="draggable-toolbar-button"
          role="button"
          tabIndex={-1}
        >
          <DragIndicator />
        </span>
      </span>
    </span>
  );
};

export default FieldToolbar;