import { Dispatch, FC, SetStateAction } from 'react';

import { useParams } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import CSVReader from 'components/CSVReader';
import { MatchParams } from 'types/Route';

interface IDocumentBatchSendCSVModal {
  showBatchSendModal: boolean;
  setShowBatchSendModal: Dispatch<SetStateAction<boolean>>;
}

const DocumentBatchSendCSVModal: FC<IDocumentBatchSendCSVModal> = ({
  showBatchSendModal,
  setShowBatchSendModal,
}) => {
  const { id }: MatchParams = useParams();

  return id
    ? (
      <Modal
        scrollable
        centered
        size="xl"
        isOpen={showBatchSendModal}
        className="batch-send-CSV-modal"
      >
        <ModalHeader className="modal-header border-0" toggle={() => setShowBatchSendModal(false)}>
          <div className="description-title text-center">
            Batch Send
          </div>
        </ModalHeader>
        <ModalBody className="p-0">
          <div className="container">
            {id && <CSVReader onClose={() => setShowBatchSendModal(false)} />}
          </div>
        </ModalBody>
        <ModalFooter className="modal-footer border-0" />
      </Modal>
    )
    : null;
};

export default DocumentBatchSendCSVModal;