import { CSSProperties, FC, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { Descendant } from 'slate';

import EditorContainer from 'components/Editor/EditorContainer';
import PdfEditor from 'components/PdfEditor/PdfEditor';
import PublicEditorWrapper from 'components/PublicEditorWrapper';
import DocumentsForSignaturePanel from 'components/PublicPage/DocumentsForSignaturePanel';
import SectionsPanel from 'components/PublicPage/SectionsPanel';
import { EditorGridAxisCount } from 'constants/editors';
import PUBLIC_PAGE_STATUS from 'constants/publicPage';
import { ShareViewModes } from 'constants/shareViewModes';
import useBodyOverflow from 'hooks/useBodyOverflow';
import useRootStyles from 'hooks/useRootStyles';
import { RootStateType } from 'store/reducers';
import { IPublicPageState } from 'types/PublicPage';
import { isMobileDevice } from 'utils/mobileDeviceHelper';
import { getIsFormBuilder } from 'utils/PublicPage/documentTypeChecker';

import 'scss/components/PublicEditor/PublicEditor.scss';
import 'scss/components/_fieldInFocus.scss';

interface IPublicEditor {
  content: Descendant[];
  setContent: (value: Descendant[]) => void;
  isFormsView: boolean;
  isPDFEditor: boolean;
  isReadOnlyMode: boolean;
  onSaveCallback: (status: PUBLIC_PAGE_STATUS, redirect: boolean) => void;
}

const PublicEditor: FC<IPublicEditor> = ({
  content,
  setContent,
  isFormsView,
  isPDFEditor,
  isReadOnlyMode,
  onSaveCallback,
}) => {
  const isMobile = isMobileDevice();
  const { structure, currentDocument }: IPublicPageState = useSelector((state: RootStateType) => state.publicPages);

  useBodyOverflow('hidden');
  const styles: CSSProperties = useMemo(() => ({
    'overflow-y': 'hidden',
    display: 'grid',
    'grid-template-rows': 'auto 1fr',
    height: '100vh',
  }), []);
  useRootStyles(styles);

  const isFormBuilder = getIsFormBuilder(currentDocument?.viewMode as ShareViewModes);

  const isLeftSidebarVisible = (!isMobile && currentDocument?.slides && currentDocument?.slides.length > 1) || false;
  const isRightSidebarVisible = (!isMobile && structure.documents.length > 1) || false;
  const editorContainerColumnsCount = isRightSidebarVisible || isLeftSidebarVisible
    ? EditorGridAxisCount.Three
    : EditorGridAxisCount.One;

  return (
    <EditorContainer columns={editorContainerColumnsCount} isPublicPage>
      {
        isLeftSidebarVisible
          ? <SectionsPanel />
          : ((!isMobile && (!isLeftSidebarVisible && isRightSidebarVisible)) && <div />)
      }
      {
        (isPDFEditor && !isFormBuilder)
          ? (
            <PdfEditor
              visibleEditorDevTools={false}
              isPublicPage
              isFormsView={isFormsView}
              readOnlyMode={isReadOnlyMode}
              isCollectionPage={structure.documents.length > 1}
            />
          )
          : (
            <PublicEditorWrapper
              content={content}
              setContent={setContent}
              isFormsView={isFormsView}
              readOnlyMode={isReadOnlyMode}
            />
          )
      }
      {
        isRightSidebarVisible && (
          <DocumentsForSignaturePanel
            onSaveDocument={onSaveCallback}
          />
        )
      }
    </EditorContainer>
  );
};

export default PublicEditor;