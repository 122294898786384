import { SSE_UPDATE_RESOURCE, SSE_UPDATE_RESOURCES_BY_TYPE } from 'store/actions/actionTypes';
import { GetNewResourcePropsType, UpdateResourceByTypeActionType } from 'types/ServerSideEvents';

export const updateResource = (document: GetNewResourcePropsType) => ({
  type: SSE_UPDATE_RESOURCE,
  payload: document,
});

export const updateResourceByType = (updateObject: UpdateResourceByTypeActionType) => ({
  type: SSE_UPDATE_RESOURCES_BY_TYPE,
  payload: updateObject,
});