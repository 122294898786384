import {
  TABLE_QUESTION,
  TABLE_QUESTION_BODY,
  TABLE_QUESTION_CAPTION,
  TABLE_QUESTION_CELL,
  TABLE_QUESTION_FIELD_CELL,
  TABLE_QUESTION_HORIZONTAL_RESIZE_BAR,
  TABLE_QUESTION_ROW,
  TABLE_QUESTION_VERTICAL_RESIZE_BAR,
  TEXT_FIELD,
} from 'constants/editors';
import {
  DEFAULT_TABLE_QUESTION_CELL_HEIGHT,
  DEFAULT_TABLE_QUESTION_CELL_WIDTH,
  DEFAULT_TABLE_QUESTION_COLUMN_COUNT,
  DEFAULT_TABLE_QUESTION_ROW_COUNT,
  TableQuestionSide,
} from 'constants/tableQuestion';
import { ICustomElement, LABEL_CONFIGURATION } from 'types/Editor';
import {
  ITableNodeGeneratorArgsProps,
  ITableNodeGeneratorProps,
  NodeGeneratorCallback,
  NodeGeneratorCallbackWithArgs,
} from 'types/TableQuestion';
import { createField, generateNumber } from 'utils/editorFieldHelpers';
import { isEmptyObject } from 'utils/isEmptyObject';

export function* tableNodeGenerator({
  count,
  callback,
  args,
}: ITableNodeGeneratorProps): Generator<ICustomElement, void, unknown> {
  for (let i = 0; i < count; i++) {
    if (isEmptyObject(args ?? null)) {
      yield (callback as NodeGeneratorCallback)();
    } else {
      const option = args?.options?.[i];
      yield (callback as NodeGeneratorCallbackWithArgs)({ ...args, option });
    }
  }
}

export const createTableQuestionCell = (): ICustomElement => ({
  type: TABLE_QUESTION_CELL,
  children: [{ text: '' }],
  width: DEFAULT_TABLE_QUESTION_CELL_WIDTH,
  height: DEFAULT_TABLE_QUESTION_CELL_HEIGHT,
});

export const createTableQuestionHorizontalResizeBar = (): ICustomElement => ({
  type: TABLE_QUESTION_HORIZONTAL_RESIZE_BAR,
  key: generateNumber(),
  children: [],
});

export const createTableQuestionVerticalResizeBar = (): ICustomElement => ({
  type: TABLE_QUESTION_VERTICAL_RESIZE_BAR,
  key: generateNumber(),
  children: [],
});

export const createTableQuestionFieldCell = (args: ITableNodeGeneratorArgsProps): ICustomElement => {
  const field = createField(TEXT_FIELD, true);

  const tableDefaultField: ICustomElement = {
    ...field,
    labelConfiguration: LABEL_CONFIGURATION.NONE,
    name: 'DEFAULT_TABLE_FIELD',
    children: [{ text: 'Default Table Field' }],
    isTableField: true,
    ...(args && { ...args.option }),
  };

  return {
    type: TABLE_QUESTION_FIELD_CELL,
    children: [tableDefaultField],
    width: DEFAULT_TABLE_QUESTION_CELL_WIDTH,
    height: DEFAULT_TABLE_QUESTION_CELL_HEIGHT,
  };
};

export const generateVerticalResizeBarNodes = (): ICustomElement[] => (
  Array.from(
    tableNodeGenerator({
      count: 1,
      callback: createTableQuestionHorizontalResizeBar,
    }),
  )
);

export const createTableQuestionResizeBarRow = ({
  columns = 0,
}: ITableNodeGeneratorArgsProps): ICustomElement => {
  const verticalResizeBarNodes: ICustomElement[] = generateVerticalResizeBarNodes();
  const horizontalResizeBarNodes: ICustomElement[] = Array.from(
    tableNodeGenerator({
      count: columns,
      callback: createTableQuestionVerticalResizeBar,
    }),
  );

  return {
    type: TABLE_QUESTION_ROW,
    children: [
      ...verticalResizeBarNodes,
      ...horizontalResizeBarNodes,
    ],
  };
};

export const createTableQuestionHeadRow = ({ columns = 0 }: ITableNodeGeneratorArgsProps): ICustomElement => {
  const verticalResizeBarNodes: ICustomElement[] = generateVerticalResizeBarNodes();
  const cellNodes: ICustomElement[] = Array.from(
    tableNodeGenerator({
      count: columns,
      callback: createTableQuestionCell,
    }),
  );

  return {
    type: TABLE_QUESTION_ROW,
    children: [
      ...verticalResizeBarNodes,
      ...cellNodes,
    ],
  };
};

export const createTableQuestionBodyRow = ({ columns = 0, options }: ITableNodeGeneratorArgsProps): ICustomElement => {
  const verticalResizeBarNodes: ICustomElement[] = generateVerticalResizeBarNodes();
  const cellNodes: ICustomElement[] = Array.from(
    tableNodeGenerator({
      count: 1,
      callback: createTableQuestionCell,
    }),
  );
  const fieldCellNodes: ICustomElement[] = Array.from(
    tableNodeGenerator({
      count: columns - 1,
      callback: createTableQuestionFieldCell,
      args: { options },
    }),
  );

  return {
    type: TABLE_QUESTION_ROW,
    children: [
      ...verticalResizeBarNodes,
      ...cellNodes,
      ...fieldCellNodes,
    ],
  };
};

export const createTableQuestion = (
  captionText: string,
  columns: number = DEFAULT_TABLE_QUESTION_COLUMN_COUNT,
  rows: number = DEFAULT_TABLE_QUESTION_ROW_COUNT,
): ICustomElement => {
  const verticalResizeBarRowNodes = Array.from(
    tableNodeGenerator({
      count: 1,
      callback: createTableQuestionResizeBarRow,
      args: { columns },
    }),
  );
  const headRowNodes = Array.from(
    tableNodeGenerator({
      count: 1,
      callback: createTableQuestionHeadRow,
      args: { columns },
    }),
  );
  const bodyRowNodes = Array.from(
    tableNodeGenerator({
      count: rows - 1,
      callback: createTableQuestionBodyRow,
      args: { columns },
    }),
  );

  return {
    type: TABLE_QUESTION,
    key: generateNumber(),
    children: [
      {
        type: TABLE_QUESTION_CAPTION,
        key: generateNumber(),
        captionSide: TableQuestionSide.TOP_LEFT,
        children: [{ text: captionText }],
        value: captionText,
      },
      {
        type: TABLE_QUESTION_BODY,
        key: generateNumber(),
        children: [
          ...verticalResizeBarRowNodes,
          ...headRowNodes,
          ...bodyRowNodes,
        ],
      },
    ],
  };
};