import { useState } from 'react';

import { useSelector } from 'react-redux';

import { useRolePermission } from 'hooks/useRolePermission';
import { RootStateType } from 'store/reducers';

const useMondayComIntegrationInFieldPropsTab = () => {
  const { permissionUsingMondayComIntegration } = useRolePermission();
  const templateDetails = useSelector((state: RootStateType) => state.user.templateDetails);
  const [showMondayComModal, setShowMondayComModal] = useState<boolean>(false);

  return {
    isMondayComButtonAvailable: Boolean(templateDetails) && permissionUsingMondayComIntegration,
    showMondayComModal,
    setShowMondayComModal,
  };
};

export default useMondayComIntegrationInFieldPropsTab;