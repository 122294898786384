/* eslint-disable */
import { Editor, Element as SlateElement, Range, Transforms } from "slate";
import { BlockFormatType, LinkOptionsType } from "../types/Editor";

export const insertLink = (editor: Editor, format: BlockFormatType, options: LinkOptionsType) => {
  if (editor.selection) {
    wrapLink(editor, format, options)
  }
}

export const isLinkActive = (editor: Editor, format: BlockFormatType) => {
  const [link]: any = Editor.nodes(editor, {
    match: (node) => (
      !Editor.isEditor(node) &&
      SlateElement.isElement(node) &&
      node.type === format
    )
  })
  return !!link
}

export const unwrapLink = (editor: Editor, format: BlockFormatType) => {
  Transforms.unwrapNodes(editor, {
    match: (node) => (
      !Editor.isEditor(node) &&
      SlateElement.isElement(node) &&
      node.type === format
    )
  })
}

export const wrapLink = (editor: Editor, format: BlockFormatType, options: LinkOptionsType) => {
  if (isLinkActive(editor, format)) {
    unwrapLink(editor, format)
  }

  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const link: SlateElement = {
    type: format,
    ...options,
    children: isCollapsed ? [{ text: options.url }] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}