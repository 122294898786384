import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import useRedirectToPublicPage from 'hooks/useRedirectToPublicPage';
import {
  setAutoFillPiiMondayItem,
  setMondayComItemIdToFilloutSubitem,
} from 'store/actions/mondayComIntegration';
import { RootStateType } from 'store/reducers';
import { IPublicPageGeneralStructure } from 'types/PublicPage';
import { createNameConstant } from 'utils/createNameConstant';
import getCorrectDocumentIDToSign from 'utils/PublicPage/getCorrectDocumentIDToSign';

const useMondayComOfferToSelectApplicants = () => {
  const dispatch = useDispatch();
  const {
    data,
    mondayComBoardItemIdToFillout,
    mondayComItemIdToFilloutSubitem,
  } = useSelector((state: RootStateType) => state.publicPages);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [targetFilterName, setTargetFilterName] = useState<string | null>('');
  const { documents }: IPublicPageGeneralStructure = useSelector(
    (state: RootStateType) => state.publicPages.structure,
  );

  useEffect(() => {
    if (
      data
      && (data.monday_com_subitem || data.monday_com_item_document_file)
      && data.monday_com_items
      && Boolean(data.monday_com_items.length)
      && !mondayComBoardItemIdToFillout
      && !mondayComItemIdToFilloutSubitem
    ) {
      setTargetFilterName(data.monday_com_items[0].filter_name);
      setIsModalOpen(true);
    }
  }, [
    data?.monday_com_subitem,
    data?.monday_com_item_document_file,
    data?.monday_com_items,
    mondayComBoardItemIdToFillout,
    mondayComItemIdToFilloutSubitem,
  ]);

  const redirectToFillOutPage = useRedirectToPublicPage(getCorrectDocumentIDToSign(documents));

  const closeAndSaveMondayComItemId = () => {
    const targetItems = data.monday_com_items.filter((item: any) => item.filter_name === targetFilterName);
    if (targetItems.length) {
      dispatch(setMondayComItemIdToFilloutSubitem(targetItems.map((item: any) => item.id)));
      const targetItem = targetItems[0];
      dispatch(setAutoFillPiiMondayItem({
        title: createNameConstant(targetItem.title),
        value: targetItem.filter_name,
      }));
      redirectToFillOutPage();
    }
    setIsModalOpen(false);
  };

  return {
    mondayComItems: data?.monday_com_items,
    isModalOfferToSelectApplicantsOpen: isModalOpen,
    closeAndSaveMondayComItemId,
    targetFilterName,
    setTargetFilterName,
  };
};

export default useMondayComOfferToSelectApplicants;