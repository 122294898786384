/* eslint-disable */
import { Editor, Element as SlateElement, Transforms, Descendant, Node, NodeEntry } from 'slate';
import { CREATE_SIMPLE_TABLE_BODY } from 'constants/editors';
import { BlockFormatType } from 'types/Editor';
import { Cell, Row, TableContent } from 'types/EditorTable';
import { insertBelow } from 'components/Editor/editor-custom-plugins/table/commands';
import { generateNumber } from 'utils/editorFieldHelpers';

export const createTableBody = (columns: number=2, rows: number=2): Descendant[] => {
  const rowNodes = [...new Array(rows)].map(() => createRowBody(columns));
  return [{
    type: 'table-body',
    children: rowNodes,
  }];
}

export const createRowBody = (columns: number): Row => {
  const cellNodes = columns ? [...new Array(columns)].map(() => createCellBody()) : [];
  const key = generateNumber();

  return {
    type: 'table-row',
    key: key,
    uuid: `row_${key}`,
    children: cellNodes,
  };
}

export const createCellBody = ({
  elements,
  colspan=1,
  rowspan=1,
  height,
  width,
}: {
  elements?: Node[];
  height?: number;
  width?: number;
  colspan?: number;
  rowspan?: number;
} = {}): Cell => {
  const content = createContentBody(elements);
  const key = generateNumber();

  return {
    type: 'table-cell',
    key: key,
    uuid: `cell_${key}`,
    children: [content],
    width: width,
    height: height,
    colspan: colspan,
    rowspan: rowspan,
  };
}

export const createContentBody = (elements?: Node[]): TableContent => {
  return {
    type: 'table-content',
    children: elements || [{ text: '' }],
  };
}

export const insertTable = (editor: Editor, format: BlockFormatType, body: Descendant[] = CREATE_SIMPLE_TABLE_BODY) => {

  const table: SlateElement = {
    type: format,
    children: body,
  }

  Transforms.move(editor, { distance: 1, unit: 'character', reverse: true });
  Transforms.insertNodes(editor, table);
}

export const insertTableRowBelow = (editor: Editor) => {
  const [table]: NodeEntry[] = Array.from(
    Editor.nodes(editor, {
      match: (node) => (
        !Editor.isEditor(node) &&
        SlateElement.isElement(node) &&
        node.type === 'table'
      )
    })
  );

  insertBelow(table, editor);
}

export const isTableActive = (editor: Editor) => {
  const [table]: any = Editor.nodes(editor, {
    match: (node) => (
      !Editor.isEditor(node) &&
      SlateElement.isElement(node) &&
      node.type === 'table'
    ),
  });
  if (table) {
    return true;
  }
  return false;
}