import { FC, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import cn from 'classnames';
import NavigationArrows from 'components/Editor/components/DocumentNavigationArrows/NavigationArrows';
import ErrorValidation from 'components/Editor/components/ErrorValidation';
import FieldWrapper from 'components/Editor/components/FieldWrapper';
import { IRenderElementPropsWithValidation } from 'components/Editor/components/RenderElement';
import SlateFieldFiller from 'components/Editor/components/SlateFieldFiller';
import FieldToolbar from 'components/Editor/components/Toolbars/FieldToolbar';
import ShowSignatureValue from 'components/Modals/SignatureModal/ShowSignatureValue';
import SigningModal from 'components/Modals/SignatureModal/SigningModal';
import { SIGNED_BY } from 'constants/general';
import { SIGNATURE_FIELD_DEFAULT_VARIATION, SIGNATURE_TABS } from 'constants/signatures';
import { DOCUMENT_TAB_ID } from 'constants/tabs';
import useDefaultSignatureUnauthenticatedUser, {
  RETURNED_SIGNATURE_TAB,
  RETURNED_SIGNATURE_TYPE,
  RETURNED_SIGNATURE_VALUE,
} from 'hooks/useDefaultSignatureUnauthenticatedUser';
import useSignatureAutosaving from 'hooks/useSignatureAutosaving';
import useTrackingLogEvents from 'hooks/useTrackingLogEvents';
import { updateFormBuilderField } from 'store/actions/publicPages';
import { RootStateType } from 'store/reducers';
import { createFieldDataAttributes } from 'utils/editorCopyPasteHelpers';
import { getSelectedFieldClass, setSelectedFieldState } from 'utils/editorFieldHelpers';
import { getFieldFontStyles } from 'utils/Fields/fieldFontSizeHelpers';
import { onFieldCustomKeyDown } from 'utils/hotkeysHelpers';
import { getIsDocumentWithFormBuilder } from 'utils/PublicPage/documentTypeChecker';
import { cleanTableQuestionSelection } from 'utils/TableQuestion/fieldsTableQuestionHelpers';
import { updateFieldNameAndProps } from 'utils/updateNode';
import { getSignatureValueAndActiveTab } from 'utils/userPersonalData/personalDataHelpers';
import { validateFieldHelper } from 'utils/validation';

const SigningEditable: FC<IRenderElementPropsWithValidation> = ({
  attributes,
  children,
  element,
  readOnlyMode = false,
  isError = false,
  validationErrorText = null,
  filledInFields = undefined,
  isPublicPage = undefined,
}) => {
  const editor = useSlateStatic();
  const dispatch = useDispatch();
  const callSigningTrackingEvents = useTrackingLogEvents(Number(element.key), element.assignment);
  const shouldApplySignatureAutosaving = useSignatureAutosaving(element.assignment);
  const publicPagesData = useSelector((state: RootStateType) => state.publicPages.data);
  const {
    unauthenticatedPublicUser,
    saveSignatureDataToSessionStorage,
    getSignatureDataFromSessionStorage,
  } = useDefaultSignatureUnauthenticatedUser();

  const isDocumentWithFormBuilderView = getIsDocumentWithFormBuilder(publicPagesData?.view_mode);

  const {
    save_signature: saveSignature,
    signature_path: signaturePath,
  } = useSelector((state: RootStateType) => state.profile?.personalData ?? {});
  const { selectedField, selectedTableQuestionKey } = useSelector((state: RootStateType) => state.editorSlate);

  const [showSigningModal, setShowSigningModal] = useState<boolean>(false);
  const [showToolbar, setShowToolbar] = useState<boolean>(false);
  const [signatureTab, setSignatureTab] = useState<SIGNATURE_TABS>(element.signatureTab ?? SIGNATURE_TABS.DRAW_TAB);

  useEffect(() => {
    updateFieldNameAndProps(editor, element);
  }, [element.children]);

  useEffect(() => {
    const tabToShow = element.signatureTab ?? SIGNATURE_TABS.DRAW_TAB;
    if (tabToShow !== signatureTab) {
      setSignatureTab(tabToShow);
    }
  }, [element.signatureTab]);

  const onSaveSignatureHandler = (base64String: string, currentTab: SIGNATURE_TABS, startingHash: string = '') => {
    if (!readOnlyMode) {
      if (unauthenticatedPublicUser) {
        saveSignatureDataToSessionStorage(
          base64String,
          currentTab,
          element.signatureFieldVariation,
        );
      }
      setSignatureTab(currentTab);

      validateFieldHelper({
        field: element,
        prevValue: element.value || '',
        value: base64String,
        dispatch,
      });
      const path = ReactEditor.findPath(editor, element);
      Transforms.setNodes(
        editor,
        { value: base64String, signatureTab: currentTab, starting_hash: startingHash },
        { at: path },
      );
      if (isDocumentWithFormBuilderView) {
        dispatch(updateFormBuilderField([element.key ?? 0], {
          value: base64String,
          signatureTab: currentTab,
          starting_hash: startingHash,
        }));
      }
    }
  };

  const onOpenWindow = async () => {
    if (!readOnlyMode) {
      if (shouldApplySignatureAutosaving && saveSignature && signaturePath && !element.value) {
        const { signatureValue, signatureHash, tabToShow } = await getSignatureValueAndActiveTab();
        onSaveSignatureHandler(signatureValue, tabToShow, signatureHash);
        callSigningTrackingEvents();
      } else if (unauthenticatedPublicUser) {
        /**
         * This flow is used for unauthenticated users on public pages
         */
        const {
          [RETURNED_SIGNATURE_VALUE]: userSignature,
          [RETURNED_SIGNATURE_TAB]: userSignatureTab,
          [RETURNED_SIGNATURE_TYPE]: userSignatureType,
        } = getSignatureDataFromSessionStorage(element.signatureFieldVariation);
        if (
          userSignature
          && userSignatureTab
          && !element.value
          && (
            (element.signatureFieldVariation === userSignatureType)
            || (!element.signatureFieldVariation && userSignatureType === SIGNATURE_FIELD_DEFAULT_VARIATION)
          )
        ) {
          onSaveSignatureHandler(userSignature, userSignatureTab);
          callSigningTrackingEvents();
        } else {
          setShowSigningModal(true);
        }
      } else {
        setShowSigningModal(true);
      }
    }
  };

  const onKeyDownHandler = (event: React.KeyboardEvent) => {
    onFieldCustomKeyDown(event, readOnlyMode ? undefined : onOpenWindow);
  };

  const clickFieldHandler = () => {
    if (!editor.publicPage) {
      setSelectedFieldState(selectedField, element.key, dispatch);
    }
    cleanTableQuestionSelection(dispatch, element?.isTableField, selectedTableQuestionKey);
  };

  const hoverFieldHandler = () => {
    if (!editor.publicPage && !readOnlyMode && !element?.isTableField) {
      setShowToolbar(true);
    }
  };

  const leaveFieldHandler = () => {
    setShowToolbar(false);
  };

  const selectedFieldClass = getSelectedFieldClass([Number(selectedField)], element.key);

  return (
    <span
      className={cn(
        'inline-field-wrapper align-items-end',
        selectedFieldClass,
        {
          'blue-bg': showToolbar,
          readonly: readOnlyMode,
        },
      )}
      {...(createFieldDataAttributes({
        field: element,
        attributes,
        activeTab: DOCUMENT_TAB_ID,
      }))}
      onClick={clickFieldHandler}
      onFocus={hoverFieldHandler}
      onMouseOver={hoverFieldHandler}
      onBlur={leaveFieldHandler}
      onMouseLeave={leaveFieldHandler}
      role="textbox"
      tabIndex={-1}
      style={getFieldFontStyles(children, element.fontSize)}
    >
      <NavigationArrows field={element} isPublicPage={isPublicPage} filledInFields={filledInFields}>
        <>
          {showToolbar && <FieldToolbar fieldKey={element.key} />}
          <SlateFieldFiller inline />
          <FieldWrapper
            assignment={element.assignment}
            fieldChildren={children}
            labelConfiguration={element.labelConfiguration}
            isReadOnly={readOnlyMode}
            element={element}
            isPublicPage={isPublicPage}
          >
            <span
              contentEditable={false}
              style={{
                userSelect: 'none',
                position: 'relative',
                marginBottom: '12px',
              }}
              className="me-1 field-editable signature-wrapper"
            >
              {
                !element.value ? (
                  <input
                    type="text"
                    name={element.name}
                    value={element.value || ''}
                    className="text-field-editable"
                    autoComplete="off"
                    onClick={onOpenWindow}
                    onChange={() => undefined}
                    onKeyDown={onKeyDownHandler}
                    disabled={readOnlyMode}
                    tabIndex={readOnlyMode ? -1 : 0}
                    readOnly
                  />
                ) : (
                  <>
                    <span className="title-signed-by title-signed-by-slate">{SIGNED_BY}</span>
                    <button
                      className="manager-signature-field-button"
                      onClick={onOpenWindow}
                      disabled={readOnlyMode}
                      type="button"
                      tabIndex={readOnlyMode ? -1 : 0}
                    >
                      <ShowSignatureValue
                        imageValueProps={{
                          className: `manager-signature-field-image ${readOnlyMode ? 'img-disabled' : ''}`,
                          draggable: false,
                        }}
                        value={element.value}
                      />
                    </button>
                    {
                      element.starting_hash && (
                        <span className="signature-hash signature-hash-slate">
                          {element.starting_hash}...
                        </span>
                      )
                    }
                  </>
                )
              }
              <ErrorValidation isError={isError} errorText={validationErrorText} />
            </span>
            <SigningModal
              fieldKey={element.key}
              existingValue={element.value}
              currentTab={signatureTab}
              showModal={showSigningModal}
              onSave={onSaveSignatureHandler}
              onCloseModal={setShowSigningModal}
              assignment={element.assignment}
              signatureFieldVariation={element.signatureFieldVariation}
              shouldApplySignatureAutosaving={shouldApplySignatureAutosaving}
            />
          </FieldWrapper>
        </>
      </NavigationArrows>
    </span>
  );
};

export default SigningEditable;