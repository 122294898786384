import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { deleteAttachment } from 'services/api';
import { CLEAR_SIGN_NOW_FILE_FROM_STORE, REMOVE_SIGN_NOW_FILE } from 'store/actions/actionTypes';
import { RootStateType } from 'store/reducers';
import { safe } from 'store/sagas/errorHandlerSaga';
import { IPDFFileInfo } from 'types/signNow/store';

const removeSignNowFileSaga = function* () {
  const file: IPDFFileInfo | null = yield select((state: RootStateType) => state.signNow.file);

  if (!file) return;

  const data: string = yield call(
    deleteAttachment,
    { path: file?.fileKey, isPdfDocument: true, isSignNow: true },
  );

  if (data) {
    toast.success(data);
    yield put({ type: CLEAR_SIGN_NOW_FILE_FROM_STORE });
  }
};

export default [
  takeLatest(REMOVE_SIGN_NOW_FILE, safe(removeSignNowFileSaga, false, false)),
];