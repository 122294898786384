import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { setAutoFillPIIData } from 'store/actions/publicPages';
import { updateUserPIIData } from 'store/actions/userPersonalData';
import { toShowBlock } from 'utils/routeHelpers';

const usePIIUserDataCleanUp = (isAuthenticated = false) => {
  const dispatch = useDispatch();
  const location = useLocation<{ from?: string; }>();

  const isPublicPage = toShowBlock(location.pathname, 'public');
  const isManagerPublicPage = toShowBlock(location.pathname, 'manager');

  useEffect(() => () => {
    if (isAuthenticated && !isManagerPublicPage && !isPublicPage) {
      dispatch(updateUserPIIData([]));
      dispatch(setAutoFillPIIData(true));
    }
  }, [isAuthenticated, isManagerPublicPage, isPublicPage]);
};

export default usePIIUserDataCleanUp;