import { FC } from 'react';

import { OutlinedInput, styled, TextFieldProps } from '@mui/material';

import DateCalendar from 'components/Base/DateCalendar';
import { PDFPropsType } from 'types/PdfTemplates';
import { getMainTypeAssignment } from 'utils/assignmentsHelpers';
import { getDefaultDateMask } from 'utils/editorHelpers';

export const StyledInputField = styled(OutlinedInput)({
  overflow: 'hidden',
  borderRadius: 6,
  lineHeight: 'normal',
  minHeight: 20,

  '& input': {
    padding: '0 5px',
    cursor: 'pointer',
    zIndex: 1,
    fontSize: 'inherit',
    borderRadius: 6,

    '&::placeholder': {
      fontFamily: 'inherit',
    },
  },
  '& fieldset': {
    top: 0,
    display: 'none',
  },
  '&:hover fieldset': {
    display: 'none',
  },
  '&.Mui-focused fieldset': {
    display: 'none',
  },
});

const DateEditable: FC<PDFPropsType> = ({
  field,
  isReadOnlyMode,
  onChangeField,
  fieldStyle,
}) => {
  const onChangeHandler = (value: string) => {
    if (!isReadOnlyMode || !field.isReadOnly) {
      onChangeField(null, field, value);
    }
  };

  const renderInputComponent = ({ InputProps, inputProps, inputRef, label }: TextFieldProps) => {
    const inputParams = {
      inputProps: { ...inputProps, 'data-pdf-field': true, className: 'date-field-editable' },
      ref: inputRef,
      label,
      ...InputProps,
    };
    return (
      <StyledInputField
        {...inputParams}
        name={field.name}
        autoComplete="off"
        size="small"
        fullWidth
        required={false}
        error={false}
        placeholder={field.fieldName}
        style={{ ...fieldStyle, padding: '0' }}
        classes={{ input: 'form-control input-editable-field' }}
        disabled={field.isReadOnly}
        className={`pdf-field-${getMainTypeAssignment(field.assignment)}`}
      />
    );
  };

  return (
    <DateCalendar
      fieldValue={field.value}
      dateMask={getDefaultDateMask(field.dateMask)}
      isReadOnlyMode={isReadOnlyMode || field.isReadOnly}
      onChangeDateValue={onChangeHandler}
      inputComponent={renderInputComponent}
      isCustomOpening
    />
  );
};

export default DateEditable;