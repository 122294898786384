import { FC } from 'react';
import classes from 'scss/components/Icons/StatusIcon.module.scss';

interface IStatusIcon {
  statusClass: string;
}

const StatusIcon: FC<IStatusIcon> = ({ statusClass }) => (
  <div className={`some ${classes.Icon} ${statusClass}`}>
    <span />
  </div>
);

export default StatusIcon;