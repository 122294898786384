import { FC } from 'react';

interface IErrorValidationProps {
  isError: boolean;
  errorText: string | null;
}

const ErrorValidation: FC<IErrorValidationProps> = ({
  isError,
  errorText,
}) => {
  if (isError && errorText) {
    return (
      <span className="field-error-message">
        {errorText}
      </span>
    );
  }

  return null;
};

export default ErrorValidation;