import { FC } from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { RootStateType } from 'store/reducers';
import elvesLogo from 'svg/elves-logo.png';
import { ICompanyBranding } from 'types/CompanyBranding';
import shouldShowBrandingInRoutes from 'utils/CompanyBranding/shouldShowBrandingInRoutes';

import classes from 'scss/components/Intro/Logo.module.scss';

interface ILogoProps {
  logoSource?: string;
}

const Logo: FC<ILogoProps> = ({
  logoSource = elvesLogo,
}) => {
  const location = useLocation();
  const isBrandingAvailable = shouldShowBrandingInRoutes(location.pathname);
  const { logoPath }: ICompanyBranding = useSelector((state: RootStateType) => state.companyBranding);

  return (
    <img
      src={(isBrandingAvailable && logoPath) || logoSource}
      className={classes.Logo}
      alt="Company Logo"
    />
  );
};

export default Logo;