/* eslint-disable */
import { PDF_TYPE } from 'constants/general';
import { ICollectionExecutedDetails } from 'types/CollectionExecuted';
import { ICreateDocumentRequestObj } from 'types/Documents';
import { ICollectionDetails } from 'types/MultiTemplate';
import disableCloseEvent from 'utils/BlockCloseButtonHelpers';

export const fileLinkDownloadHelper = (filesLink: string[]) => {
  /*
  Removing and adding listener are invoked in order to avoid appearing
  popup window with confirmation 'close' and 'refresh' actions.
  */
  const downloadFile = (fileLink: string, index: number) => {
    setTimeout(() => {
      window.removeEventListener('beforeunload', disableCloseEvent);
      const link = document.createElement('a');
      link.href = String(fileLink);
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      window.addEventListener('beforeunload', disableCloseEvent);
    }, index * 1000);
  };

  filesLink.forEach((fileLink, index) => {
    downloadFile(fileLink, index);
  });
};

export const createCSSString = async () => {
  // Collecting css styles on all environments from compiled sass file list

  let globalCSSstring = '';
  const allStylesLinks = [
    '/static/printing.module.css',
  ];

  const fetchPromises: any[] = [];
  allStylesLinks.forEach(async (itemLink) => {
    fetchPromises.push(fetch(itemLink));
  });
  const fetchResults = await Promise.all(fetchPromises);
  for (const fetchItem of fetchResults) {
    const reader = await fetchItem?.body?.getReader();

    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      }
      const localCSSString = new TextDecoder().decode(value, { stream: true });
      globalCSSstring += localCSSString;
    }
  }

  return globalCSSstring;
};

export const generateHtmlResource = (pdfDocumentID: number = 0, htmlNode: Element | undefined = undefined) => {
  const htmlBaseCopy: any = [];
  const htmlBase: NodeListOf<Element> = htmlNode
    ? htmlNode.querySelectorAll(`[data-drag-and-drop-container][data-pdf-id="${pdfDocumentID}"]`)
    : document.querySelectorAll(`[data-drag-and-drop-container][data-pdf-id="${pdfDocumentID}"]`);
  htmlBase.forEach((node, index) => {
    htmlBaseCopy.push({
      page: index,
      html: node.cloneNode(true),
    });
  });
  return htmlBaseCopy.map((fieldsContainer: any) => {
    // TODO: react-pdf__Page css class could be changed
    const pdfPage = fieldsContainer.html.querySelector('.react-pdf__Page');
    pdfPage.remove();
    fieldsContainer.html = fieldsContainer.html.outerHTML;
    return fieldsContainer;
  });
};

export const addHTMLandCSSifCompleted = async (
  editedDocument: ICreateDocumentRequestObj,
  isPdf = false,
  htmlNode: Element | undefined = undefined
) => {
  const addHtmlAndCss = async (innerHtml: string) => {
    editedDocument['html'] = innerHtml;
    editedDocument['css'] = await createCSSString();
    return editedDocument;
  };

  const editorEl = htmlNode
    ? htmlNode.querySelector('[data-html-source="true"]')
    : document.querySelector('[data-html-source="true"]');
  if (!isPdf && editorEl) {
    return await addHtmlAndCss(editorEl.outerHTML);
  }
  const htmlResource = generateHtmlResource(editedDocument.template_id as number, htmlNode);
  return await addHtmlAndCss(JSON.stringify(htmlResource));
};

export const getHtmlAndCssForCollectionDocuments = async (collection: ICollectionExecutedDetails & ICollectionDetails) => {
  const htmlSlides: NodeListOf<Element> = document.querySelectorAll('.swiper-slide');
  let documentsAsPromises: Promise<any>[] = [];
  if (collection?.documents) {
    documentsAsPromises = collection?.documents?.map(async (documentObject: any, index: number) => {
      const updatedDocument = await addHTMLandCSSifCompleted(
        documentObject.document,
        documentObject.document === PDF_TYPE,
        htmlSlides[index],
      );
      documentObject.document = updatedDocument;
      return documentObject;
    });
  } else {
    documentsAsPromises = collection?.templates!.map(async (temlateObject: any, index: number) => {
      const updatedDocument = await addHTMLandCSSifCompleted(
        temlateObject,
        temlateObject.template === PDF_TYPE,
        htmlSlides[index],
      );
      temlateObject.document = updatedDocument;
      return temlateObject;
    });
  }
  const waitedPromises = await Promise.all(documentsAsPromises);
  return waitedPromises;
};