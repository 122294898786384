import { FC } from 'react';

import { formatFileSize } from 'react-papaparse';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { FormHelperText } from '@mui/material';

import AttachmentFileIcon from 'svg/AttachmentFileIcon';
import { ICSVReaderChildrenProps, IParsedData } from 'types/BatchSendViaCSV';

import vars from 'variables.module.scss';

interface ICSVReaderFileAreaProps extends ICSVReaderChildrenProps {
  errors: IParsedData['errors'];
}

const CSVReaderFileArea: FC<ICSVReaderFileAreaProps> = ({
  getRootProps,
  acceptedFile,
  ProgressBar,
  errors = [],
}) => (
  <div>
    {acceptedFile ? (
      <div className="accepted-file-csv">
        <div className="d-flex flex-row align-items-center">
          <span className="accepted-file-csv-name">Processing... {acceptedFile.name}</span>
          <span className="accepted-file-csv-size">({formatFileSize(acceptedFile.size)})</span>
        </div>
        <div className="accepted-file-csv-progress-bar">
          <ProgressBar />
        </div>
      </div>
    ) : (
      errors?.map((errorObject, index) => (
        <div key={`error_row_${index + 1}`} className="d-flex flex-column align-items-center">
          <FormHelperText error variant="outlined">
            <ErrorOutlineOutlinedIcon color="error" />
            <span className="px-1">{errorObject.message}</span>
          </FormHelperText>
        </div>
      ))
    )}
    <div
      {...getRootProps()}
      className={`drop-zone-area ${acceptedFile ? 'd-none' : ''}`}
    >
      <span>
        <AttachmentFileIcon fill={vars.brandSecondary} />
        Drop CSV files here to upload
      </span>
    </div>
  </div>
);

export default CSVReaderFileArea;