import { TABLE_QUESTION_CAPTION, TABLE_QUESTION_CELL, TABLE_QUESTION_FIELD_CELL } from 'constants/editors';
import { BlockFormatType } from 'types/Editor';
import { ISelectTableQuestionCaptionPosition } from 'types/TableQuestion';

export const enum TABLE_QUESTION_INSERT_POSITION {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  ABOVE = 'ABOVE',
  BELOW = 'BELOW',
}

export const MIN_TABLE_QUESTION_ROW_COUNT = 3;
export const MAX_TABLE_QUESTION_COLUMN_COUNT = 9;
export const MAX_TABLE_QUESTION_ROW_COUNT = 21;
export const DEFAULT_TABLE_QUESTION_ROW_COUNT = 3;
export const DEFAULT_TABLE_QUESTION_COLUMN_COUNT = 3;
export const DEFAULT_TABLE_QUESTION_CELL_WIDTH = 200;
export const DEFAULT_TABLE_QUESTION_CELL_HEIGHT = 50;
export const MIN_TABLE_QUESTION_CELL_WIDTH = 50;
export const TABLE_QUESTION_CAPTION_KEY = 'table_question_caption';
export const TABLE_QUESTION_CAPTION_SIDE_KEY = 'table_question_caption_side';

export const enum TableQuestionSide {
  TOP_LEFT = 'top_left',
  TOP_CENTER = 'top_center',
  TOP_RIGHT = 'top_right',
  BOTTOM_LEFT = 'bottom_left',
  BOTTOM_CENTER = 'bottom_center',
  BOTTOM_RIGHT = 'bottom_right',
}

export const TABLE_QUESTION_CAPTION_SIDE_OPTIONS: ISelectTableQuestionCaptionPosition[] = [
  {
    id: 1,
    type: TableQuestionSide.TOP_LEFT,
    label: 'Top Left',
  },
  {
    id: 2,
    type: TableQuestionSide.TOP_CENTER,
    label: 'Top Center',
  },
  {
    id: 3,
    type: TableQuestionSide.TOP_RIGHT,
    label: 'Top Right',
  },
  {
    id: 4,
    type: TableQuestionSide.BOTTOM_LEFT,
    label: 'Bottom Left',
  },
  {
    id: 5,
    type: TableQuestionSide.BOTTOM_CENTER,
    label: 'Bottom Center',
  },
  {
    id: 6,
    type: TableQuestionSide.BOTTOM_RIGHT,
    label: 'Bottom Right',
  },
];

export const TABLE_QUESTION_CELL_LIST: BlockFormatType[] = [TABLE_QUESTION_CELL, TABLE_QUESTION_FIELD_CELL];
export const TEXT_TABLE_QUESTION_ELEMENTS: BlockFormatType[] = [...TABLE_QUESTION_CELL_LIST, TABLE_QUESTION_CAPTION];

// Resize
export const DEFAULT_TABLE_QUESTION_RESIZE_BAR_COEFFICIENT = 3;

// Notification messages
export const ROWS_INSERTING_MESSAGE: Record<string, string> = {
  CANT_INSERT_HERE: 'You can\'t insert a row here. Please choose the correct cell.',
  CANT_INSERT_MORE: 'You can\'t insert more rows.',
  CANT_INSERT_ABOVE: 'You can\'t insert row above this row.',
};

export const COLUMNS_INSERTING_MESSAGE: Record<string, string> = {
  CANT_INSERT_HERE: 'You can\'t insert a column here. Please choose the correct cell.',
  CANT_INSERT_MORE: 'You can\'t insert more columns.',
  CANT_INSERT_LEFT: 'You can\'t insert columns left of this column.',
};

export const COLUMNS_REMOVING_MESSAGE: Record<string, string> = {
  CANT_REMOVE_HERE: 'You can\'t remove a column here. Please choose the correct cell.',
  CANT_INSERT_MORE: 'You can\'t remove more columns.',
  CANT_INSERT_THIS: 'You can\'t remove this column.',
};

export const ROWS_REMOVING_MESSAGE: Record<string, string> = {
  CANT_REMOVE_HERE: 'You can\'t remove a row here. Please choose the correct cell.',
  CANT_INSERT_MORE: 'You can\'t remove more rows.',
  CANT_INSERT_THIS: 'You can\'t remove this row.',
};
