import { RenderElementProps } from 'slate-react';

const TableQuestionRow = (props: RenderElementProps) => {
  const { attributes, children } = props;

  return (
    <tr {...attributes}>
      {children}
    </tr>
  );
};

export default TableQuestionRow;