import { FC, useState } from 'react';

import { useDispatch } from 'react-redux';

import { AddCircleOutline as AddCircleIcon, DeleteOutline as DeleteIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import Tooltip from 'components/Tooltip';
import { FORM_BUILDER_PARAGRAPH } from 'constants/editors';
import { addFieldToFormBuilder } from 'store/actions/userData';
import { FormBuilderType } from 'types/FormBuilder';
import { generateNumber } from 'utils/editorFieldHelpers';

interface IFormBuilderFieldActions {
  sectionKey: number;
  shouldDisableActions: boolean;
  fieldPosition: number;
  formBuilderType: FormBuilderType;
  fieldKey: number;
  onDeleteParagraphField?: (fieldKey: number) => void;
}

const FormBuilderFieldActions: FC<IFormBuilderFieldActions> = ({
  children,
  sectionKey,
  shouldDisableActions,
  fieldPosition,
  formBuilderType,
  fieldKey,
  onDeleteParagraphField = () => null,
}) => {
  const [isFieldActionsVisible, setIsFieldActionsVisible] = useState<boolean>(false);

  const dispatch = useDispatch();

  const onAddNewHandler = () => {
    dispatch(addFieldToFormBuilder(
      {
        type: FORM_BUILDER_PARAGRAPH,
        key: generateNumber(),
        value: '',
      },
      sectionKey,
      fieldPosition,
      formBuilderType,
    ));
  };

  return (
    <div
      onMouseEnter={() => setIsFieldActionsVisible(true)}
      onMouseLeave={() => setIsFieldActionsVisible(false)}
      className="d-flex align-items-center"
    >
      {children}
      <div className={`${isFieldActionsVisible && !shouldDisableActions ? 'visible' : 'invisible'}`}>
        <Tooltip title="Add description">
          <IconButton
            aria-label="Add"
            size="small"
            className="p-1"
            onClick={onAddNewHandler}
          >
            <AddCircleIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
      {
        shouldDisableActions && (
          <div className={`${isFieldActionsVisible && shouldDisableActions ? 'visible' : 'invisible'}`}>
            <Tooltip title="Delete">
              <IconButton
                aria-label="Delete"
                size="small"
                className="p-1"
                onClick={() => onDeleteParagraphField(fieldKey)}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        )
      }
    </div>
  );
};

export default FormBuilderFieldActions;