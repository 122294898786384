import { Dispatch as ReactDispatch, FC, SetStateAction } from 'react';

import { Nav, NavItem } from 'reactstrap';

import cn from 'classnames';
import { MONDAY_COM_MODAL_SECTIONS } from 'constants/mondayComIntegration';

interface IMondayComModalNavigation {
  activeTab: number;
  setActiveTab: ReactDispatch<SetStateAction<number>>;
  disabledTabId?: number;
  isCollectionPage?: boolean;
}

const MondayComModalNavigation: FC<IMondayComModalNavigation> = ({
  activeTab,
  setActiveTab,
  disabledTabId,
  isCollectionPage,
}) => (
  <Nav className="tab-navigation border-0">
    {
      MONDAY_COM_MODAL_SECTIONS.map((tab) => {
        if (
          isCollectionPage
          && ['Item Settings', 'Attachment Settings'].includes(tab.label)
        ) return null;
        return (
          <NavItem
            key={tab.componentId}
            className={cn(
              'tab-navigation__tab',
              {
                'tab-navigation__tab--active': activeTab === tab.componentId,
                'tab-navigation__tab--disabled': disabledTabId === tab.componentId,
              },
            )}
            onClick={tab.componentId === disabledTabId ? () => null : () => setActiveTab(tab.componentId)}
          >
            <span className="ps-1">{tab.label}</span>
          </NavItem>
        );
      })
    }
  </Nav>
);

export default MondayComModalNavigation;