import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { EXECUTED_DOCUMENTS_ROUTES } from 'constants/general';
import usePublicPageRouteMatch from 'hooks/usePublicPageRouteMatch';
import useSingleDocument from 'hooks/useSingleDocument';
import { setHistoryLogCurrentDocument } from 'store/actions/publicPages';
import { RootStateType } from 'store/reducers';
import { HISTORY_ACTIONS } from 'types/DocumentsHistory';
import { FieldAssignmetsType } from 'types/Editor';
import { ProfileInfoType } from 'types/userProfile';
import { trackAmplitudeEvent } from 'utils/amplitude/amplitudeTrackingUtlis';
import { getAmplitudeEventName } from 'utils/amplitude/amplitudeUtils';
import { isManagerAssignment, isRecipientAssignment } from 'utils/assignmentsHelpers';
import { getUTCDate, saveHistoryRecord } from 'utils/documentsHistory';
import { toShowBlock } from 'utils/routeHelpers';

/**
 * Signing document History Log Record and Amplitude Event
 */
const useTrackingLogEvents = (fieldKey: number, pageAssignment?: FieldAssignmetsType): () => void => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isSingleDocument = useSingleDocument();
  const { pageType } = usePublicPageRouteMatch();

  const { documentDetails } = useSelector((state: RootStateType) => state.user);
  const userInfo: ProfileInfoType = useSelector((state: RootStateType) => state.profile.profileInfo);
  const { data, currentDocument: currentPublicDocument } = useSelector((state: RootStateType) => state.publicPages);

  const isExecutedDocument = EXECUTED_DOCUMENTS_ROUTES.includes(pageType);
  const isPublicURL = toShowBlock(pathname, 'public');
  const isPublicRecipientPage = isPublicURL && isRecipientAssignment(pageAssignment);
  const isManagerPage = isManagerAssignment(pageAssignment);

  const saveSigningActionToHistory = () => {
    if (isExecutedDocument) {
      if (isPublicRecipientPage || isManagerPage) {
        const documentId = isPublicRecipientPage
          ? currentPublicDocument.id
          : (documentDetails?.id || currentPublicDocument.id);
        saveHistoryRecord(`${HISTORY_ACTIONS.SIGNED}_${fieldKey}`, documentId);
      }
      return;
    }

    if (isPublicRecipientPage || isManagerPage) {
      dispatch(setHistoryLogCurrentDocument({
        target: `${HISTORY_ACTIONS.SIGNED}_${fieldKey}`,
        value: getUTCDate(),
      }));
    }
  };

  const addSigningAmplitudeEvent = () => {
    const documentData = isPublicRecipientPage ? data : (documentDetails || currentPublicDocument);

    if (documentData) {
      if (isPublicRecipientPage) {
        trackAmplitudeEvent({
          eventName: getAmplitudeEventName({ data: documentData }).signedByRecipient,
        });
      } else if (isExecutedDocument && isManagerPage) {
        trackAmplitudeEvent({
          eventName: getAmplitudeEventName({ data: documentData }).signedByManager,
          userInfo,
        });
      }
    }
  };

  /**
   * Signing document History Log Record and Amplitude Event
   */
  const callSigningTrackingEvents = () => {
    if (isSingleDocument) return;

    saveSigningActionToHistory();
    addSigningAmplitudeEvent();
  };

  return callSigningTrackingEvents;
};

export default useTrackingLogEvents;