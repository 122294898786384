import { MAX_FIELD_VALUE_LENGTH } from 'constants/editors';

const updateValueWithMaxLength = (value: string, maxLength: string | number | undefined): string => {
  switch (typeof maxLength) {
    case 'number':
      return value.length >= maxLength ? value.slice(0, maxLength) : value;
    case 'string':
    default:
      return value.length >= MAX_FIELD_VALUE_LENGTH ? value.slice(0, MAX_FIELD_VALUE_LENGTH) : value;
  }
};

export default updateValueWithMaxLength;