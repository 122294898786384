import { FC, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import ButtonGeneral from 'components/ButtonGeneral';
import PoliciesLink from 'components/Modals/Policies/PoliciesLink';
import PolicyModalComponent from 'components/Modals/Policies/PolicyModalComponent';
import { UNICORNS_ACCEPTANCE_TERMS_OF_USE } from 'constants/general';
import { PoliciesEnumType } from 'constants/policies';
import { RootStateType } from 'store/reducers';
import { ICompanyBranding } from 'types/CompanyBranding';
import { IPoliciesModalData } from 'types/Misc';
import { createAccepteranceTermsCookies } from 'utils/localStorage';

import vars from 'variables.module.scss';

interface IPoliciesModalProps {
  showOnlyLink?: boolean;
  showInline?: boolean;
  isPublicPage?: boolean;
  showInlineFullText?: boolean;
  isIntroduceUserForm?: boolean;
}

const PoliciesModal: FC<IPoliciesModalProps> = ({
  showOnlyLink = false,
  showInline = false,
  isPublicPage = false,
  showInlineFullText = false,
  isIntroduceUserForm = false,
}) => {
  const history = useHistory();
  const { styleJSON }: ICompanyBranding = useSelector((state: RootStateType) => state.companyBranding);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isMainOpen, setMainIsOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<IPoliciesModalData | null>(null);

  useEffect(() => {
    if (isIntroduceUserForm) return;
    const cookiesArray = document.cookie.split(';').map((item: string) => item.replaceAll(' ', ''));
    if (!cookiesArray.some((item) => item.startsWith(UNICORNS_ACCEPTANCE_TERMS_OF_USE))) {
      setMainIsOpen(true);
    }
  }, [isIntroduceUserForm]);

  const handlerOnAcceptAction = () => {
    document.cookie = createAccepteranceTermsCookies(new Date());
    setMainIsOpen(false);
  };

  const handlerOnCloseModal = (mainModal = true) => {
    history.goBack();
    if (mainModal) {
      setMainIsOpen(false);
    } else {
      setIsOpen(false);
    }
  };

  if (showInline || showInlineFullText) {
    return (
      <div className="d-inline" style={showInlineFullText ? { fontSize: vars.smallFontSize } : {}}>
        {
          showInlineFullText
          && (
            <span>
              Our privacy policy describes how we use the personal information we receive about you
              and our terms of use govern your access to and use of the services.
              By continuing and submitting this form, you agree to
              our&nbsp;
            </span>
          )
        }
        <span>
          <PoliciesLink
            type={PoliciesEnumType.TERMS_OF_SERVICES}
            setIsOpen={setIsOpen}
            setModalContent={setModalContent}
          />
        </span>
        <span> and </span>
        <span>
          <PoliciesLink
            type={PoliciesEnumType.PRIVACY_POLICY}
            setIsOpen={setIsOpen}
            setModalContent={setModalContent}
          />
        </span>
        <PolicyModalComponent
          isOpen={isOpen}
          handlerOnCloseModal={handlerOnCloseModal}
          modalContent={modalContent}
        />
      </div>
    );
  }

  if (showOnlyLink) {
    return (
      <div
        className="d-flex justify-content-between align-center position-fixed bottom-0 py-2 px-3 w-100 policies-info"
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 d-none d-md-flex justify-content-center justify-content-md-start">
              <p className="m-0 text-center">
                Copyright &copy; {new Date().getFullYear()} UNICORNFORMS, INC. All rights reserved.
              </p>
            </div>
            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end align-center">
              <div>
                <PoliciesLink
                  type={PoliciesEnumType.PRIVACY_POLICY}
                  setIsOpen={setIsOpen}
                  setModalContent={setModalContent}
                />
              </div>
              <span className="px-2">|</span>
              <div>
                <PoliciesLink
                  type={PoliciesEnumType.TERMS_OF_USE}
                  setIsOpen={setIsOpen}
                  setModalContent={setModalContent}
                />
              </div>
            </div>
          </div>
          <PolicyModalComponent
            isOpen={isOpen}
            handlerOnCloseModal={handlerOnCloseModal}
            modalContent={modalContent}
          />
        </div>
      </div>
    );
  }

  if (isPublicPage) {
    return (
      <div
        className="d-flex justify-content-between align-center mt-1 mt-md-auto mb-0 w-100 policies-info"
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 d-none d-lg-flex justify-content-start">
              <p className="m-0 text-md-center">
                {
                  !styleJSON?.removeBrandingActive
                    ? <>Copyright &copy; {new Date().getFullYear()} UNICORNFORMS, INC. All rights reserved.</>
                    : ''
                }
              </p>
            </div>
            <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end align-center">
              <div>
                <PoliciesLink
                  type={PoliciesEnumType.PRIVACY_POLICY}
                  setIsOpen={setIsOpen}
                  setModalContent={setModalContent}
                />
              </div>
              <span className="px-2">|</span>
              <div>
                <PoliciesLink
                  type={PoliciesEnumType.TERMS_OF_USE}
                  setIsOpen={setIsOpen}
                  setModalContent={setModalContent}
                />
              </div>
            </div>
          </div>
          <PolicyModalComponent
            isOpen={isOpen}
            handlerOnCloseModal={handlerOnCloseModal}
            modalContent={modalContent}
          />
        </div>
      </div>
    );
  }

  return (
    <Modal contentClassName="policies-modal" centered isOpen={isMainOpen}>
      <ModalHeader className="policies-modal modal-header" toggle={() => handlerOnCloseModal()}>
        {PoliciesEnumType.TERMS_OF_USE}
      </ModalHeader>

      <ModalBody className="py-0">
        <div className="container-lg small text-justify">
          Our privacy policy describes how we use the personal information we receive about you
          and our terms of use govern your access to and use of the services.
          By continuing and submitting this form, you agree to
          our <PoliciesLink
            type={PoliciesEnumType.PRIVACY_POLICY}
            setIsOpen={setIsOpen}
            setModalContent={setModalContent}
          /> and <PoliciesLink
            type={PoliciesEnumType.TERMS_OF_USE}
            setIsOpen={setIsOpen}
            setModalContent={setModalContent}
          />.
        </div>
        <PolicyModalComponent
          isOpen={isOpen}
          handlerOnCloseModal={handlerOnCloseModal}
          modalContent={modalContent}
        />
      </ModalBody>

      <ModalFooter className="policies-modal modal-footer">
        <ButtonGeneral
          buttonText="Okay, continue"
          onClickCallback={handlerOnAcceptAction}
          additionalClassName="align-self-center policies-button"
          block
          buttonStyles={styleJSON?.mainBrandColor ? { backgroundColor: styleJSON.mainBrandColor } : {}}
        />
      </ModalFooter>
    </Modal>
  );
};

export default PoliciesModal;