export const STATUS_NEW = 'New';
export const STATUS_WAITING = 'Waiting';
export const STATUS_IN_PROGRESS = 'In Progress';
export const STATUS_DRAFT = 'Draft';
export const STATUS_COMPLETED = 'Completed';
export const STATUS_CANCELLED = 'Cancelled';
export const STATUS_NOT_SUBMITTED = 'Not Submitted';

export const ALL_STATUSES = [
  STATUS_NEW,
  STATUS_WAITING,
  STATUS_IN_PROGRESS,
  STATUS_DRAFT,
  STATUS_COMPLETED,
  STATUS_CANCELLED,
  STATUS_NOT_SUBMITTED,
];
export const NEW_TAB_STATUSES = [STATUS_NEW];
export const IN_PROGRESS_TAB_STATUSES = [STATUS_IN_PROGRESS];
export const SENT_TAB_STATUSES = [STATUS_WAITING];
export const DRAFT_TAB_STATUSES = [STATUS_DRAFT];
export const COMPLETED_TAB_STATUSES = [STATUS_COMPLETED, STATUS_CANCELLED];
export const NOT_COMPLITTED_TAB_STATUSES = [STATUS_NOT_SUBMITTED];

export const STATUSES = {
  new: STATUS_NEW,
  waiting: STATUS_WAITING,
  inProgress: STATUS_IN_PROGRESS,
  draft: STATUS_DRAFT,
  completed: STATUS_COMPLETED,
  cancelled: STATUS_CANCELLED,
  notSubmitted: STATUS_NOT_SUBMITTED,
};

export const STATUSES_TO_SHOW: {[key: string]: string} = {
  Draft: STATUS_DRAFT,
  Complete: STATUS_COMPLETED,
  Cancel: STATUS_CANCELLED,
};

export const READ_ONLY_STATUSES = [STATUS_WAITING, STATUS_COMPLETED, STATUS_CANCELLED];
export const STATUSES_FOR_SHARING = [
  STATUS_NEW,
  STATUS_WAITING,
  STATUS_IN_PROGRESS,
  STATUS_COMPLETED,
  STATUS_NOT_SUBMITTED,
];
// INFO: Cleared forbidden delete status array and left checking.
export const FORBIDDEN_DELETE_STATUSES: string[] = [];