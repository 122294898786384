import { ComponentType, useCallback, useEffect, useState } from 'react';

import {
  IStepManagerChildComponentProps,
  IStepManagerConfigItem,
  IUseStepManager,
  UseStepManagerHookType,
} from 'types/signNow/base';

const useStepManager: UseStepManagerHookType = ({
  componentMap,
  config,
  initStep = 0,
}): IUseStepManager => {
  const [currentStep, setCurrentStep] = useState<number>(initStep);
  const [isNextButtonVisible, setIsNextButtonVisible] = useState<boolean>(false);

  const goToStep = (step: number): void => {
    setCurrentStep(step);
  };

  useEffect(() => {
    setCurrentStep(initStep);
  }, [initStep]);

  const goToNextStep = useCallback((): void => {
    if (currentStep < config.length - 1) {
      setCurrentStep((prevState) => prevState + 1);
    }
  }, [config.length, currentStep]);

  const goToPrevStep = useCallback((): void => {
    if (currentStep > 0) {
      setCurrentStep((prevState) => prevState - 1);
    }
  }, [currentStep]);

  const CurrentStepComponent = useCallback((): ComponentType<IStepManagerChildComponentProps> => {
    const currentItem: IStepManagerConfigItem = config[currentStep];
    const stepName: string = currentItem.name;
    const isNextStepButtonVisible: boolean = currentItem.isNextButtonVisible ?? false;
    if (isNextStepButtonVisible !== isNextButtonVisible) setIsNextButtonVisible(isNextStepButtonVisible);
    return componentMap[stepName];
  }, [componentMap, config, currentStep, isNextButtonVisible]);

  return {
    currentStep,
    isNextButtonVisible,
    goToStep,
    goToNextStep,
    goToPrevStep,
    CurrentStepComponent,
  };
};

export default useStepManager;