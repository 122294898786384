import { FC } from 'react';

import { useSelector } from 'react-redux';
import { ModalFooter } from 'reactstrap';

import ErrorMessage from 'components/ErrorMessage';
import { SIGN } from 'constants/general';
import { RootStateType } from 'store/reducers';
import { ICompanyBranding } from 'types/CompanyBranding';

export interface IFooterSignModal {
  handlerSave: () => void;
  isDisabledButton: boolean;
  error: string;
}

const FooterSignModal: FC<IFooterSignModal> = ({
  handlerSave,
  isDisabledButton,
  error,
}) => {
  const { styleJSON }: ICompanyBranding = useSelector((state: RootStateType) => state.companyBranding);

  return (
    <ModalFooter className="justify-content-center signature-footer">
      {
        error && (
          <div className="text-center signature-error">
            <ErrorMessage error={error} />
          </div>
        )
      }
      <div className="p-1 footer-message">
        I agree that this signature or initial will be the electronic representation of my
        signature or initial for all purposes when I (or my agent) see them on documents including legally
        binding contracts - just the same as a pen-and-paper signature or initial.
      </div>
      <button
        type="button"
        disabled={isDisabledButton}
        className="btn-sign p-2 mt-3"
        onClick={handlerSave}
        style={styleJSON?.mainBrandColor ? { backgroundColor: styleJSON.mainBrandColor } : {}}
      >
        {SIGN}
      </button>
    </ModalFooter>
  );
};

export default FooterSignModal;