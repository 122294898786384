import { FC } from 'react';

type ErrorPropsType = {
  error: string | null;
};

const ErrorMessage: FC<ErrorPropsType> = (props) => {
  const { error } = props;
  if (!error) return null;

  return (
    <p className="text-danger my-1">{error}</p>
  );
};

export default ErrorMessage;