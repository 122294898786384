import { FC } from 'react';

import { useSelector } from 'react-redux';

import ButtonGeneral from 'components/ButtonGeneral';
import DownloadDocument from 'components/EditorHeader/DownloadDocument';
import HeaderBackButton from 'components/EditorHeader/HeaderBackButton';
import HeaderDocumentName from 'components/EditorHeader/HeaderDocumentName';
import { STATUSES } from 'constants/documentStatuses';
import { DOWNLOAD } from 'constants/general';
import { RootStateType } from 'store/reducers';

interface IEditorHeaderReadOnly {
  documentName?: string;
}

const EditorHeaderReadOnly: FC<IEditorHeaderReadOnly> = ({
  documentName = '',
}) => {
  const { documentDetails } = useSelector((state: RootStateType) => state.user);
  return (
    <div className="p-2 editor-header">
      <div className="col-6 col-xxl-4 d-flex justify-content-start">
        <HeaderBackButton />
      </div>
      <div className="col col-md-8 mx-auto pt-2 order-3 order-xxl-2 col-xxl-3">
        <HeaderDocumentName
          readOnly
          documentName={documentName}
        />
      </div>
      <div className="col-6 order-2 order-xxl-3 col-xxl-4 editor-header-endblock">
        {
          documentDetails?.status === STATUSES.completed && (
            <DownloadDocument
              document={documentDetails}
              render={(downloadDocumentHandler: () => void, isDisabled: boolean) => (
                <ButtonGeneral
                  buttonText={DOWNLOAD}
                  disabled={isDisabled}
                  block
                  onClickCallback={downloadDocumentHandler}
                  additionalClassName="read-only-download"
                />
              )}
            />
          )
        }
      </div>
    </div>
  );
};

export default EditorHeaderReadOnly;