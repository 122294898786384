import { FC, useState } from 'react';

import Pagination from '@mui/material/Pagination';

import MondayComMappingColumnSelect from 'components/Modals/MondayComModalWindow/MondayComMappingColumnSelect';
import { ICustomElement } from 'types/Editor';
import { IMondayComFieldsMapping } from 'types/mondayCom/mondayComModalWindow';
import { PDFFieldType } from 'types/PdfTemplates';
import { filterUsedColumns } from 'utils/MondayComIntegration/mondayComHelpers';
import getFieldName from 'utils/PublicPage/getFieldName';

const MondayComFieldsMapping: FC<IMondayComFieldsMapping> = ({
  templateFields,
  templateIntegrationData,
  onChangeFieldsMappingHandler,
}) => {
  const [boardPage, setBoardPage] = useState(0);
  const currentBoardObject = templateIntegrationData ? templateIntegrationData[boardPage] : null;

  if (!templateIntegrationData) {
    return (
      <div className="d-flex justify-content-center mb-1">
        There aren&apos;t selected boards.
      </div>
    );
  }

  return (
    <>
      <div className="d-flex justify-content-center mb-2">
        {
          templateIntegrationData.length > 1 && (
            <Pagination
              color="primary"
              count={templateIntegrationData.length}
              onChange={(event, page) => setBoardPage(page - 1)}
            />
          )
        }
      </div>
      <div className="d-flex justify-content-center mb-2 monday-com-board-name">
        {currentBoardObject?.board.name}
      </div>
      {
        templateFields.map((field: Partial<ICustomElement> | PDFFieldType) => (
          <div key={field.key} className="d-flex mb-1">
            <div className="w-50 d-flex align-items-center">
              {field.fieldName ?? getFieldName(field, false)}
            </div>
            <div className="w-50">
              <MondayComMappingColumnSelect
                key={currentBoardObject?.board.id}
                boardId={currentBoardObject?.board.id ?? ''}
                field={field}
                columns={filterUsedColumns(currentBoardObject) ?? []}
                currentColumn={
                  (currentBoardObject && currentBoardObject.fieldsMapping)
                    ? currentBoardObject.fieldsMapping[field.key ?? 0]
                    : null
                }
                onChangeFieldsMappingHandler={onChangeFieldsMappingHandler}
              />
            </div>
          </div>
        ))
      }
    </>
  );
};

export default MondayComFieldsMapping;