import { FC, memo, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';

import { Link } from '@mui/material';

import Button from 'components/Base/Button';
import Intro from 'components/Intro/Intro';
import LoginForm from 'components/LoginForm';
import ModalContentWindow from 'components/Modals/ModalContentWindow';
import PoliciesModal from 'components/Modals/Policies/PoliciesModal';
import ModalInputField from 'components/Modals/UserManagementModal/ModalInputField';
import { FIELD_GROUPING_VALUE } from 'constants/editors';
import { REVIEW_DOCUMENT, START_SIGNING } from 'constants/general';
import { NO_BOARDS } from 'constants/mondayComIntegration';
import usePhoneNumberUserIdentity from 'hooks/SMSValidation/usePhoneNumberUserIdentity';
import useSendUniqueCode from 'hooks/SMSValidation/useSendUniqueCode';
import useLogin from 'hooks/useLogin';
import usePIIUserData from 'hooks/usePIIUserData';
import useReadOnlyMode from 'hooks/useReadOnlyMode';
import useStyleSchema, { CustomStylesEnum } from 'hooks/useStylesFromSchema';
import { checkMondayComRecordsForCurrentUser } from 'store/actions/mondayComIntegration';
import {
  checkRememberedPIIDataInDatabase,
  saveTemporaryPIIUserDataInDatabase,
} from 'store/actions/publicPages';
import { updateUserPIIData } from 'store/actions/userPersonalData';
import { RootStateType } from 'store/reducers';
import ArrowRightIcon from 'svg/ArrowIcons/ArrowRightIcon';
import { IPublicPageGeneralStructure } from 'types/PublicPage';
import { CreateUserType, IUserManagementFields, PIIDataType, USER_MANAGEMENT_MODAL_STATUS } from 'types/userProfile';
import { modalUserManagementFieldsGenerator } from 'utils/modalHelpers';
import { modalFormValidation } from 'utils/validation';

import classes from 'scss/components/PublicFooter/PublicFooter.module.scss';

interface IIntroduceUserFormProps {
  autoFillPIIFieldsInContentHandler: (piiData: PIIDataType[]) => void;
}

const INIT_USER_SUBTYPE_DATA_OBJECT = {
  email: '',
  first_name: '',
  last_name: '',
};

export const IntroduceUserForm: FC<IIntroduceUserFormProps> = ({
  autoFillPIIFieldsInContentHandler,
}) => {
  const dispatch = useDispatch();
  const {
    user,
    changeUserHandler,
    error,
    forgotPasswordHandler,
    onLoginUserHandler,
    isButtonClicked,
    disableLogin,
  } = useLogin(false);

  const {
    dismissIntroduceStep,
    structure,
    data,
    mondayComBoard,
  } = useSelector((state: RootStateType) => state.publicPages);
  const {
    smsConsent,
    isVerifyIdentityViaSms,
    isMondayComSubitem,
    changeSmsConsenthandler,
  } = usePhoneNumberUserIdentity();
  const { sendUniqueCode } = useSendUniqueCode();

  const { documents }: IPublicPageGeneralStructure = structure;

  const styleSchema = useStyleSchema<CustomStylesEnum.button>([CustomStylesEnum.button]);

  const isReadOnlyMode: boolean = useReadOnlyMode();
  const documentsCount: number = documents?.length || 0;
  const isReadOnlyDocument = (documentsCount === 1 && documents?.[0].emptyDocument) || isReadOnlyMode;
  const userPIIData: PIIDataType[] = usePIIUserData();
  const formFieldList = modalUserManagementFieldsGenerator(
    null,
    USER_MANAGEMENT_MODAL_STATUS.ADD,
    isVerifyIdentityViaSms && !isMondayComSubitem,
  );

  const [showIntroduceModal, setShowIntroduceModal] = useState<boolean>(!isReadOnlyDocument && !dismissIntroduceStep);
  const [userData, setUserData] = useState<PIIDataType[]>([]);
  const [userDataErrors, setUserDataErrors] = useState<CreateUserType>(INIT_USER_SUBTYPE_DATA_OBJECT);
  const [openLoginForm, setOpenLoginForm] = useState<boolean>(false);
  const [smsConsentCheckboxError, setSmsConsentCheckboxError] = useState<boolean>(false);

  useEffect(() => {
    if (!isReadOnlyDocument && !dismissIntroduceStep && !Object.keys(userPIIData ?? {}).length) {
      setShowIntroduceModal(true);
    }
  }, [isReadOnlyDocument, dismissIntroduceStep]);

  const validateUserDataForm = () => {
    const invalidFields = formFieldList.map((field) => {
      const errorMessage = modalFormValidation({
        ...field,
        value: userData.find((item) => (
          item.subtype === field.key && item.groupBy === FIELD_GROUPING_VALUE.BY_ASSIGNEE
        ))?.value ?? '',
      }).join('; ');

      setUserDataErrors((prevValue) => ({
        ...prevValue,
        [field.key]: errorMessage,
      }));

      return !!errorMessage;
    }).filter((value) => value);

    return Boolean(invalidFields.length);
  };

  const onSaveIntroduceDataHandler = () => {
    const hasErrors = validateUserDataForm();
    if (hasErrors) return;
    if (isVerifyIdentityViaSms && !smsConsent) {
      setSmsConsentCheckboxError(true);
      return;
    }

    dispatch(updateUserPIIData(userData));
    dispatch(saveTemporaryPIIUserDataInDatabase(userData));

    if (isVerifyIdentityViaSms) {
      sendUniqueCode();
    } else {
      if (data.monday_com_subitem && mondayComBoard && mondayComBoard !== NO_BOARDS) {
        dispatch(checkMondayComRecordsForCurrentUser());
      }
      if (data.monday_com_item_document_file && mondayComBoard !== NO_BOARDS) {
        dispatch(checkMondayComRecordsForCurrentUser());
      }
      if (data.sender?.enable_data_reuse) {
        dispatch(checkRememberedPIIDataInDatabase());
      }
    }

    autoFillPIIFieldsInContentHandler(userData);
    setShowIntroduceModal(false);
  };

  const isExistingPIIDataByAssignee = (item: PIIDataType, subtype: string): boolean => (
    item.subtype === subtype
    && item.groupBy === FIELD_GROUPING_VALUE.BY_ASSIGNEE
    && (item.filterName === undefined || item.filterName === '')
    && (item.groupByKey === undefined || item.groupByKey === '')
  );

  const onChangeUserDataHandler = (
    value: string,
    errorMessage: string,
    subtype: string,
  ): void => {
    setUserData((prevState: PIIDataType[]): PIIDataType[] => {
      const userDataExistingItem: PIIDataType | undefined = userData.find((item) => (
        isExistingPIIDataByAssignee(item, subtype)
      ));
      if (!userDataExistingItem) {
        return prevState.concat({
          groupBy: FIELD_GROUPING_VALUE.BY_ASSIGNEE,
          subtype,
          value,
        });
      }
      return prevState.map((item) => {
        if (isExistingPIIDataByAssignee(item, subtype)) {
          return {
            ...item,
            value,
          };
        }
        return item;
      });
    });

    const updatedUserDataErrors: CreateUserType = {
      ...userDataErrors,
      [subtype]: errorMessage,
    };

    setUserDataErrors(updatedUserDataErrors);
  };

  return (
    <ModalContentWindow
      centered
      showDefaultText={false}
      showModal={showIntroduceModal}
      onCloseModal={() => setShowIntroduceModal(false)}
      titleText=""
      showCloseButton={false}
    >
      {
        openLoginForm ? (
          <>
            <div className="d-flex justify-content-center">
              <Link component="button" onClick={() => setOpenLoginForm((prevState) => !prevState)}>Go back</Link>
            </div>
            <LoginForm
              user={user}
              changeUserHandler={changeUserHandler}
              error={error}
              forgotPasswordHandler={forgotPasswordHandler}
              onLoginUserHandler={onLoginUserHandler}
              isButtonClicked={isButtonClicked}
              disableLogin={disableLogin}
              showForgotPasswordLink={false}
            />
          </>
        ) : (
          <Intro
            title="Let's Get Started!"
            description={(
              <>
                To get started tell us about yourself. If you have an account please&nbsp;
                <Link component="button" onClick={() => setOpenLoginForm((prevState) => !prevState)}>sign in</Link>;
              </>
            )}
            descriptionWidth="large"
            justifyContent="center"
          >
            <Form autoComplete="on">
              <div className="m-auto max-width-300">
                {
                  formFieldList.map((field: IUserManagementFields) => (
                    <FormGroup className="user-management-modal__group" key={field.key}>
                      <ModalInputField
                        field={{
                          ...field,
                          errorMessage: userDataErrors[field.key],
                        }}
                        onChange={onChangeUserDataHandler}
                      />
                    </FormGroup>
                  ))
                }
                {
                  isVerifyIdentityViaSms && (
                    <>
                      <Input
                        type="checkbox"
                        checked={smsConsent}
                        onChange={changeSmsConsenthandler}
                        invalid={smsConsentCheckboxError}
                      />
                      <Label className="d-inline ms-2">
                        By checking this box, I agree to receive texts from UnicornForms, Inc. at this mobile number. Message and data rates may apply.
                      </Label>
                      <FormFeedback>This checkbox is required.</FormFeedback>
                    </>
                  )
                }
              </div>
              <div className="text-center max-w-700 mb-3">
                <PoliciesModal isIntroduceUserForm showInlineFullText />
              </div>
              <div className="text-center">
                <Button
                  classes={classes.PublicButton}
                  endIcon={<ArrowRightIcon />}
                  onClick={onSaveIntroduceDataHandler}
                  style={styleSchema.button}
                >
                  {isReadOnlyDocument ? REVIEW_DOCUMENT : START_SIGNING}
                </Button>
              </div>
            </Form>
          </Intro>
        )
      }
    </ModalContentWindow>
  );
};

export default memo(IntroduceUserForm);