import { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import ENDPOINT from 'constants/endpoints';
import { updateResource } from 'store/actions/serverSideEvents';
import { RootStateType } from 'store/reducers';
import { getUniqueTabId } from 'utils/localStorage';

const { REACT_APP_API_URL } = process.env;

const useServerSideEvents = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state: RootStateType) => state.profile.profileInfo);
  const isUserAuthenticated = useSelector((state: RootStateType) => state.user.isAuthenticated);
  const [localEventSource, setLocalEventSource] = useState<EventSource | null>(null);

  const documentFunction = useCallback((event: MessageEvent) => {
    const parsedData = JSON.parse(event.data);
    dispatch(updateResource({
      resourceType: parsedData.type,
      resourceId: parsedData.id,
    }));
  }, [dispatch]);

  const errorFunction = useCallback((
    companyId: number,
    userId: number,
    eventSourceObject: EventSource | null,
    reconnectFunc?: (companyId: number, userId: number) => EventSource,
  ) => {
    if (eventSourceObject) {
      eventSourceObject.removeEventListener('resourcesToUpdateUnicornforms', documentFunction);
      eventSourceObject.removeEventListener(
        'error',
        () => errorFunction(companyId, userId, eventSourceObject),
      );
      eventSourceObject.close();
      setLocalEventSource(null);
    }
    if (reconnectFunc) {
      setLocalEventSource(reconnectFunc(companyId, userId));
    }
  }, [documentFunction]);

  const setupEventSource = useCallback((companyId: number, userId: number) => {
    const eventSource = new EventSource(`${REACT_APP_API_URL}${ENDPOINT.SERVER_SIDE_EVENTS}/listen/${companyId}/${userId}/${getUniqueTabId()}`);
    eventSource.addEventListener('resourcesToUpdateUnicornforms', documentFunction, false);
    eventSource.addEventListener('error', () => errorFunction(companyId, userId, eventSource, setupEventSource), false);
    return eventSource;
  }, [documentFunction]);

  useEffect(() => {
    if (isUserAuthenticated && userInfo?.company_id && userInfo?.id) {
      if (!localEventSource) {
        setLocalEventSource(setupEventSource(userInfo.company_id, userInfo.id));
      }
    }

    return () => {
      if (localEventSource) {
        localEventSource.removeEventListener('resourcesToUpdateUnicornforms', documentFunction);
        localEventSource.removeEventListener(
          'error',
          () => errorFunction(userInfo.company_id, userInfo.id, localEventSource),
        );
        localEventSource.close();
        setLocalEventSource(null);
      }
    };
  }, [isUserAuthenticated, userInfo?.company_id, userInfo?.id, setupEventSource]);
};

export default useServerSideEvents;