/* eslint-disable */
import { Editor, Element as SlateElement, Transforms, NodeEntry } from 'slate';

export const removeTable = (table: NodeEntry, editor: Editor) => {
  if (editor && table) {
    Transforms.removeNodes(editor, {
      match: node => SlateElement.isElement(node) && node.type === 'table',
      at: table[1],
    });
  }
}