import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import { IRadioGroupOptions } from 'types/AccountSettings';

interface IUseRadioButtonsGroupHandlerProps<Value> {
  options: IRadioGroupOptions<Value>[];
  defaultValue?: Value;
}

interface IUseRadioButtonsGroupHandlerReturns<Value> {
  value: Value;
  defaultValue: Value;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const useRadioButtonsGroupHandler = <Value>({
  options,
  defaultValue,
}: IUseRadioButtonsGroupHandlerProps<Value>): IUseRadioButtonsGroupHandlerReturns<Value> => {
  const currentDefaultValue: Value = useMemo(() => {
    if (defaultValue) return defaultValue;

    const defaultOption: IRadioGroupOptions<Value> | undefined = options.find((option) => option.isDefault);

    if (!defaultOption) return options[0].value;
    return defaultOption.value;
  }, [options, defaultValue]);

  const [value, setValue] = useState<Value>(currentDefaultValue);

  useEffect(() => {
    if (currentDefaultValue !== value) {
      setValue(currentDefaultValue);
    }
  }, [currentDefaultValue]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value as Value);
  };

  return {
    value,
    defaultValue: currentDefaultValue,
    handleChange,
  };
};

export default useRadioButtonsGroupHandler;