import { Editor as SlateEditor, Element as SlateElement, Path, Range, Selection } from 'slate';

import { TEXT_TABLE_QUESTION_ELEMENTS } from 'constants/tableQuestion';

export const isSelectionAcrossCells = (editor: SlateEditor, selection: Selection): boolean => {
  if (!selection || Range.isCollapsed(selection)) return false;

  const [startCell] = Array.from(
    SlateEditor.nodes(editor, {
      at: selection.anchor.path,
      match: (n) => SlateElement.isElement(n) && TEXT_TABLE_QUESTION_ELEMENTS.includes(n.type),
    }),
  );

  const [endCell] = Array.from(
    SlateEditor.nodes(editor, {
      at: selection.focus.path,
      match: (n) => SlateElement.isElement(n) && TEXT_TABLE_QUESTION_ELEMENTS.includes(n.type),
    }),
  );

  if (startCell && endCell) {
    return !Path.equals(startCell[1], endCell[1]);
  }

  return false;
};

export default undefined;