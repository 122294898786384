/* eslint-disable */
import { connect } from "react-redux";
import { ISectionRequestObj } from "types/Sections";
import SectionEditorFormModal from "../pages/SectionEditorFormModal";
import { addSection } from "../store/actions/userData";

const mapStateToProps = ({user: { templates, sections, states } }: any) => ({
  templates,
  sections,
  states
});

const mapDispatchToProps = (dispatch: any) => ({
  createSection: (section: ISectionRequestObj) => dispatch(addSection(section)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SectionEditorFormModal);