/* eslint-disable */
import { Editor, Range, Element as SlateElement, Transforms, Point } from 'slate';
import { SECTION } from 'constants/editors';
import { isElementInEditor } from 'utils/editorHelpers';
import { getSelectedSections } from 'utils/editorSectionHelpers';

export const withSections = (editor: Editor) => {
  const { deleteBackward, deleteForward, deleteFragment, getFragment, insertBreak, insertText } = editor;

  editor.insertText = (text: string) => {
    const selectedSections = getSelectedSections(editor);
    if (selectedSections.length > 1) {
      return null;
    }

    insertText(text);
  };

  editor.insertBreak = () => {
    const selectedSections = getSelectedSections(editor);
    if (selectedSections.length > 1) {
      selectedSections.map((section: any) => {
        return Transforms.removeNodes(editor, {
          match: (node) => (
            !Editor.isEditor(node)
            && SlateElement.isElement(node)
            && node?.type !== SECTION
          ),
          at: section[1],
        });
      });
      return null;
    }

    insertBreak();
  };

  editor.deleteFragment = (...args) => {
    if (getFragment().length > 1) {
      return null;
    }

    deleteFragment(...args);
  };

  editor.deleteBackward = (...args) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      if (isElementInEditor(editor, SECTION)) {
        const [cell]: any = Editor.nodes(editor, {
          match: (node) => (
            !Editor.isEditor(node)
            && SlateElement.isElement(node)
            && node.type === SECTION
          ),
        });
        if (cell) {
          const [, cellPath] = cell;
          const start = Editor.start(editor, cellPath);
          if (Point.equals(selection.anchor, start)) {
            return;
          }
        }

        // Deny to delete field before paragraph for Forms
        const contentBefore = Editor.before(editor, selection.anchor, { unit: 'line' });
        if (contentBefore) {
          Transforms.move(editor, { distance: 1, unit: 'character', reverse: true });
          const [fieldCell]: any = Editor.nodes(editor, {
            match: (node) => (
              !Editor.isEditor(node)
              && SlateElement.isElement(node)
              && Boolean(node.key)
              && Boolean(node.assignment)
            ),
          });
          Transforms.move(editor, { distance: 1, unit: 'character' });
          if (contentBefore.offset !== 0 && fieldCell) return;
        }
      } else {
        const contentBefore = Editor.before(editor, selection.anchor, { unit: 'line' });
        if (contentBefore) {
          Transforms.move(editor, { distance: 1, unit: 'character', reverse: true });
          const shouldDenyDeleting = isElementInEditor(editor, SECTION);
          Transforms.move(editor, { distance: 1, unit: 'character' });
          if (shouldDenyDeleting) return;
        }
      }
    }

    deleteBackward(...args);
  };

  editor.deleteForward = (...args) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      if (isElementInEditor(editor, SECTION)) {
        const [cell]: any = Editor.nodes(editor, {
          match: (node) => (
            !Editor.isEditor(node)
            && SlateElement.isElement(node)
            && node.type === SECTION
          ),
        });

        if (cell) {
          const [, cellPath] = cell;
          const end = Editor.end(editor, cellPath);
          if (Point.equals(selection.anchor, end)) {
            return;
          }
        }

        // Deny to delete field after paragraph for Forms
        const contentAfter = Editor.after(editor, selection.anchor, { unit: 'line' });
        if (contentAfter) {
          Transforms.move(editor, { distance: 1, unit: 'character' });
          const [fieldCell]: any = Editor.nodes(editor, {
            match: (node) => (
              !Editor.isEditor(node)
              && SlateElement.isElement(node)
              && Boolean(node.key)
              && Boolean(node.assignment)
            ),
          });
          Transforms.move(editor, { distance: 1, unit: 'character', reverse: true });
          if (contentAfter.offset === 0 && fieldCell) return;
        }
      } else {
        const contentAfter = Editor.after(editor, selection.anchor, { unit: 'line' });
        if (contentAfter) {
          Transforms.move(editor, { distance: 1, unit: 'character' });
          const shouldDenyDeleting = isElementInEditor(editor, SECTION);
          Transforms.move(editor, { distance: 1, unit: 'character', reverse: true });
          if (shouldDenyDeleting) return;
        }
      }
    }

    deleteForward(...args);
  };

  return editor;
};