import { FC, useState } from 'react';

import Pagination from '@mui/material/Pagination';

import SimpleSpinner from 'components/Base/SimpleSpinner';
import MondayComSelectKeyFilterColumns from 'components/Modals/MondayComModalWindow/MondayComSelectKeyFilterColumns';
import MondayComSutitemSelect from 'components/Modals/MondayComModalWindow/MondayComSutitemSelect';
import {
  MondayComColumnType,
  MondayComIntegrationObjectType,
  MondayComSubitemBoardType,
} from 'types/mondayCom/mondayComBoard';
import { IMondayComUploadDocumentToSubitem } from 'types/mondayCom/mondayComModalWindow';

const MondayComUploadDocumentToSubitem: FC<IMondayComUploadDocumentToSubitem> = ({
  boards,
  subitemsBoards,
  templateIntegrationData,
  onChangeSubitemsMappingHandler,
  onChangeKeyFilterColumnsHandler,
  enabledSMSValidation,
  blockClosePopupButton,
}) => {
  const [boardPage, setBoardPage] = useState(0);
  const currentBoardObject: MondayComIntegrationObjectType | null = templateIntegrationData
    ? templateIntegrationData[boardPage]
    : null;
  const currentSubitemsBoard: MondayComSubitemBoardType | undefined = subitemsBoards.find(
    (subitemBoard: MondayComSubitemBoardType) => subitemBoard.name.endsWith(currentBoardObject?.board.name ?? ''),
  );

  if (!boards) {
    return (<SimpleSpinner />);
  }

  if (!templateIntegrationData) {
    return (
      <div className="d-flex justify-content-center mb-1">
        There aren&apos;t selected boards.
      </div>
    );
  }

  const columnsArray = currentSubitemsBoard
    ? currentSubitemsBoard.columns.filter((column) => column.type === 'file')
    : [];

  return (
    <>
      <div className="d-flex justify-content-center mb-2">
        {
          templateIntegrationData.length > 1 && (
            <Pagination
              color="primary"
              count={templateIntegrationData.length}
              onChange={(event, page) => setBoardPage(page - 1)}
            />
          )
        }
      </div>
      <div className="d-flex justify-content-center mb-2 monday-com-board-name">
        {currentBoardObject?.board.name}
      </div>
      {
        Boolean(columnsArray.length) && (
          <div className="d-flex mb-1">
            <div className="w-50">
              Available columns
            </div>
            <div className="w-50">
              Select subitem
            </div>
          </div>
        )
      }
      {
        columnsArray.length
          ? columnsArray.map((column: MondayComColumnType) => (
            <div key={column.id} className="d-flex mb-1">
              <div className="w-50 d-flex align-items-center">
                {column.title}
              </div>
              <div className="w-50">
                <MondayComSutitemSelect
                  columnId={column.id}
                  currentSubitemsBoard={currentSubitemsBoard}
                  currentBoardObject={currentBoardObject}
                  onChangeSubitemsMappingHandler={onChangeSubitemsMappingHandler}
                />
              </div>
            </div>
          ))
          : (<div className="text-center">There aren&apos;t columns with &apos;file&apos; type</div>)
      }
      {
        Boolean(columnsArray.length)
        && currentBoardObject?.subitemMapping
        && Boolean(Object.keys(currentBoardObject?.subitemMapping).length)
        && (
          <MondayComSelectKeyFilterColumns
            currentBoardObject={currentBoardObject}
            onChangeKeyFilterColumnsHandler={onChangeKeyFilterColumnsHandler}
            enabledSMSValidation={enabledSMSValidation}
            blockClosePopupButton={blockClosePopupButton}
          />
        )
      }
    </>
  );
};

export default MondayComUploadDocumentToSubitem;