import { Editor as SlateEditor, Range as SlateRange } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';

import { UseToolbarType, USING_TOOLBAR } from 'types/Editor';
import { isEnterCommandMentionToolbar, isEnterCommandVerticalToolbar } from 'utils/editorToolbarHelpers';

const useToolbar: UseToolbarType = () => {
  const editor = useSlate();
  const toolbarModalNode = document.querySelector('[data-toolbar-id="horizontal-toolbar-modal"]');
  if (toolbarModalNode) return USING_TOOLBAR.HORIZONTAL_TOOLBAR;
  const { selection } = editor;
  if (!selection) return null;

  const isFocusEditor = (
    selection
    && ReactEditor.isFocused(editor)
    && !SlateRange.isCollapsed(selection)
    && SlateEditor.string(editor, selection).trim() !== ''
  );

  const isEnteredCommandMentionToolbar = isEnterCommandMentionToolbar(editor);
  const isEnteredCommandVerticalToolbar = isEnterCommandVerticalToolbar(editor);

  if (
    isFocusEditor
    && !isEnteredCommandMentionToolbar
    && !isEnteredCommandVerticalToolbar
  ) {
    return USING_TOOLBAR.HORIZONTAL_TOOLBAR;
  }

  if (isEnteredCommandMentionToolbar) {
    return USING_TOOLBAR.MENTION_TOOLBAR;
  }

  if (isEnteredCommandVerticalToolbar) {
    return USING_TOOLBAR.VERTICAL_TOOLBAR;
  }

  return null;
};

export default useToolbar;