import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { getUserPIIData } from 'store/actions/userPersonalData';
import { RootStateType } from 'store/reducers';
import { PIIDataType } from 'types/userProfile';

const usePIIUserData = (): PIIDataType[] => {
  const dispatch = useDispatch();

  const { isAuthenticated } = useSelector((state: RootStateType) => state.user);
  const piiData: PIIDataType[] = useSelector((state: RootStateType) => state.profile.piiData);
  const shouldGetUserData = isAuthenticated && !piiData.length;

  const [isGetDataMode, setIsGetDataMode] = useState<boolean>(true);

  useEffect(() => {
    if (isGetDataMode && shouldGetUserData) {
      dispatch(getUserPIIData());
      setIsGetDataMode(false);
    }
  }, [isGetDataMode, shouldGetUserData]);

  return piiData;
};

export default usePIIUserData;