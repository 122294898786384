import { FC, useCallback } from 'react';

import { useSlate } from 'slate-react';

import { IBlockButtonProps } from 'types/Editor';
import insertTableQuestion from 'utils/TableQuestion/commands/insertTableQuestion';

const TableQuestionButton: FC<IBlockButtonProps> = ({ icon }) => {
  const editor = useSlate();

  const insertTableHandler = useCallback(() => {
    insertTableQuestion(editor);
  }, [editor]);

  return (
    <span
      className="btn-toolbar-editor horizontal-toolbar-icon"
      onMouseDown={insertTableHandler}
      role="button"
      tabIndex={-1}
    >
      {icon}
    </span>
  );
};

export default TableQuestionButton;