import MondayComBoardsPerWorkspace from 'components/Modals/MondayComModalWindow/MondayComBoardsPerWorkspace';
import MondayComFieldsMapping from 'components/Modals/MondayComModalWindow/MondayComFieldsMapping';
import MondayComItemAttachmentSettings from 'components/Modals/MondayComModalWindow/MondayComItemAttachmentSettings';
import MondayComSelectBoards from 'components/Modals/MondayComModalWindow/MondayComSelectBoards';
import MondayComUploadDocumentToSubitem from 'components/Modals/MondayComModalWindow/MondayComUploadDocumentToSubitem';
import { MondayComModalSectionsType } from 'types/mondayCom/mondayComModalWindow';

export const NO_BOARDS = 'No Boards';
export const ALL_BOARDS = 'All Boards';
export const CONNECT_BOARDS_HELP_TEXT = 'Select which Monday Board this Template needs to connect with.';
export const ITEM_SETTINGS_HELP_TEXT = 'If this Template populates a new Item in a Monday Board, please map the fields in UnicornForms to the Monday columns.';
export const SUBITEM_SETTINGS_HELP_TEXT = 'If this Template populates a Subitem in a Monday Board, we will sync the completed file, workflow status and share link.';
export const ITEM_ATTACHMENT_SETTINGS_HELP_TEXT = 'Item Attachment Settings Help Text';
export const BOARDS_PER_WORKSPACE_HELP_TEXT = 'Boards Per Workspace Help Text';

export const EMAIL_VALIDATION_HEADER = 'Email Validation';
export const EMAIL_VALIDATION_MODAL_TEXT = 'We recognize your email. For your security, we have sent a fresh link to your inbox. Please check and return with the new link.';
export const PASSWORDLESS_EMAIL_SENT_MODAL_TEXT = 'For your security, we have sent an email to your email address. To proceed, please close this tab or window and click on the link in your email';
export const SELECT_APPLICATION_HEADER = 'Select Application';
export const SELECT_APPLICATION_MODAL_TEXT = 'We recognize your application. Please select the correct record below before continuing.';

export const MONDAY_COM_TABS_IDS = {
  CONNECT_BOARDS_TAB_ID: 1,
  ITEM_SETTINGS_TAB_ID: 2,
  SUBITEM_SETTINGS_TAB_ID: 3,
  ITEM_ATTACHMENT_SETTINGS_TAB_ID: 4,
  BOARDS_BY_WORKSPACE_TAB_ID: 5,
} as const;

export type MondayComTabsIdsType = typeof MONDAY_COM_TABS_IDS[keyof typeof MONDAY_COM_TABS_IDS]

export const MONDAY_COM_MODAL_SECTIONS: MondayComModalSectionsType[] = [
  {
    componentId: MONDAY_COM_TABS_IDS.CONNECT_BOARDS_TAB_ID,
    label: 'Connect Boards',
    helpText: CONNECT_BOARDS_HELP_TEXT,
    ReactComponent: MondayComSelectBoards,
  },
  {
    componentId: MONDAY_COM_TABS_IDS.BOARDS_BY_WORKSPACE_TAB_ID,
    label: 'Boards Per Workspace',
    helpText: BOARDS_PER_WORKSPACE_HELP_TEXT,
    ReactComponent: MondayComBoardsPerWorkspace,
  },
  {
    componentId: MONDAY_COM_TABS_IDS.ITEM_SETTINGS_TAB_ID,
    label: 'Item Settings',
    helpText: ITEM_SETTINGS_HELP_TEXT,
    ReactComponent: MondayComFieldsMapping,
  },
  {
    componentId: MONDAY_COM_TABS_IDS.SUBITEM_SETTINGS_TAB_ID,
    label: 'Subitem Settings',
    helpText: SUBITEM_SETTINGS_HELP_TEXT,
    ReactComponent: MondayComUploadDocumentToSubitem,
  },
  {
    componentId: MONDAY_COM_TABS_IDS.ITEM_ATTACHMENT_SETTINGS_TAB_ID,
    label: 'Attachment Settings',
    helpText: ITEM_ATTACHMENT_SETTINGS_HELP_TEXT,
    ReactComponent: MondayComItemAttachmentSettings,
  },
];

export const TECHNICAL_COLUMNS = ['subitems', 'creation_log'];