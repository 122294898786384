import { ChangeEvent, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { REGISTER_FREE_TIER_ACCOUNT_LINK } from 'constants/accountSettings';
import { REFERRAL_CODE_REGEX } from 'constants/validation';
import { changeReferralCode, generateReferralCode } from 'store/actions/userProfile';
import { RootStateType } from 'store/reducers';

const useReferralLink = () => {
  const dispatch = useDispatch();
  const {
    referral_code_data: referralCodeData,
    settings,
  } = useSelector((state: RootStateType) => state.profile.profileInfo.company ?? {});
  const { message } = useSelector((state: RootStateType) => state.errorLoading.error.data ?? {});

  const referralCode = referralCodeData?.referral_code || null;
  const maxTemplates = settings.max_templates;

  const [localReferalCode, setLocalReferralCode] = useState<string>(referralCode);
  const [localReferalCodeError, setLocalReferralCodeError] = useState<string>(message ?? '');

  useEffect(() => {
    if (referralCode) {
      setLocalReferralCode(referralCode);
    }
    if (message) {
      setLocalReferralCodeError(message);
    }
  }, [referralCode, message]);

  const createReferralCode = () => {
    if (!referralCode) {
      dispatch(generateReferralCode());
    }
  };

  const handlerCopyToClipboard = (text: string): void => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text);
      toast.success('Link copied');
    }
  };

  const onChangeLocalCode = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setLocalReferralCodeError('');
    const value = event.target.value;
    if (REFERRAL_CODE_REGEX.test(value)) {
      setLocalReferralCode(value);
    }
  };

  const updateReferralCode = () => {
    if (localReferalCode.length < 6) {
      setLocalReferralCodeError('Code must be at least 6 characters');
    } else if (localReferalCode === referralCode) {
      setLocalReferralCodeError('It\'s the same code, please try another one');
    } else {
      dispatch(changeReferralCode(localReferalCode));
    }
  };

  return {
    localReferalCode,
    localReferalCodeError,
    onChangeLocalCode,
    referralCode,
    referralLink: referralCode
      ? `${REGISTER_FREE_TIER_ACCOUNT_LINK}?referralLink=${referralCode}`
      : null,
    maxTemplates,
    createReferralCode,
    handlerCopyToClipboard,
    updateReferralCode,
  };
};

export default useReferralLink;