import { ChangeEvent } from 'react';

import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

import { IRadioGroupOptions } from 'types/AccountSettings';

interface IRadioGroupProps<Value> {
  name: string;
  options: IRadioGroupOptions<Value>[];
  value: Value;
  defaultValue: Value;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const RadioInputGroup = <Value extends string>({
  name,
  options,
  value,
  defaultValue,
  handleChange,
}: IRadioGroupProps<Value>) => (
  <FormControl>
    <RadioGroup
      aria-labelledby={`${name}-radio-buttons-group-label`}
      name={name}
      value={value}
      defaultValue={defaultValue}
      onChange={handleChange}
    >
      {
        options.map(({ value, label }) => (
          <FormControlLabel key={value} value={value} control={<Radio />} label={label} />
        ))
      }
    </RadioGroup>
  </FormControl>
  );

export default RadioInputGroup;