import { Dispatch } from 'redux';

import { Descendant, Element as SlateElement, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { setPersistentStorageFieldData } from 'store/actions/persistentReduxStorage';
import { CustomEditorType, ICustomElement } from 'types/Editor';
import { createNameConstant } from 'utils/createNameConstant';
import {
  getSimilarSubtypeElementsByKey,
  getSubtypesFromFields,
  isFindField,
  updateGroupedByParamFieldValues,
} from 'utils/editorFieldHelpers';
import { isSingleCheckboxField } from 'utils/Fields/checkboxRadioHelpers';
import { getFieldFontProperties } from 'utils/Fields/fieldFontSizeHelpers';
import { isNotEmptyObject } from 'utils/isEmptyObject';

export const updateNodeWithoutSubtypes = (
  editor: CustomEditorType,
  value: Partial<SlateElement>,
  key: number | undefined,
  // field: ICustomElement | undefined = undefined,
) => {
  // It doesn't work with chrome autocomplete
  // if (field) {
  //   const path = ReactEditor.findPath(editor, field);
  //   Transforms.setNodes(editor, { ...value }, { at: path });
  //   return;
  // }
  editor.findNodesAndUpdate((node: Descendant) => {
    if (!isFindField(node, key)) {
      return node;
    }

    return { ...node, ...value };
  });
};

export const updateNode = (
  editor: CustomEditorType,
  key: number | undefined,
  value: string,
  formFields: Partial<SlateElement>[] = [],
  dispatch: Dispatch | undefined = undefined,
  selectFieldProps: Partial<ICustomElement> = {},
  storageDocumentKey: string | undefined = undefined,
) => {
  updateNodeWithoutSubtypes(
    editor,
    selectFieldProps && isNotEmptyObject(selectFieldProps)
      ? selectFieldProps
      : { value },
    key,
  );

  if (dispatch && storageDocumentKey && key) {
    dispatch(setPersistentStorageFieldData(storageDocumentKey, key, { value }));
  }

  if (formFields.length === 0) {
    return true;
  }

  const currentElement = formFields.find((field) => field.key === key);
  if (key && currentElement && currentElement.groupBy) {
    updateGroupedByParamFieldValues({
      groupBy: currentElement.groupBy,
      formElements: formFields as SlateElement[],
      element: {
        assignment: currentElement.assignment,
        name: currentElement.name || '',
        key,
        type: (currentElement as ICustomElement).type,
        subtype: currentElement.subtype,
        groupByKey: currentElement.groupByKey,
        filterName: currentElement.filterName,
      },
      newValue: value,
      editor,
      updateNodeHandler: true,
      dispatch,
      selectFieldProps,
      storageDocumentKey,
    });
  }

  if (currentElement && currentElement.subtype) {
    const usedFieldSubtypes = getSubtypesFromFields(
      formFields,
      currentElement.assignment,
      currentElement.subtype || '',
    );

    if (key && usedFieldSubtypes.length > 0) {
      const subtypeFormElements = getSimilarSubtypeElementsByKey(key, usedFieldSubtypes, formFields);
      const newProperties: Partial<SlateElement> = { value };

      subtypeFormElements.forEach((field: Partial<SlateElement>) => {
        updateNodeWithoutSubtypes(editor, newProperties, field.key);
        if (dispatch && storageDocumentKey && key) {
          dispatch(setPersistentStorageFieldData(storageDocumentKey, key, newProperties));
        }
      });
    }
  }
};

export const onChangeDateValue = (
  editor: CustomEditorType,
  fieldKey: number,
  value: string,
  readOnlyMode: boolean = false,
) => {
  if (fieldKey && !readOnlyMode) {
    updateNode(editor, fieldKey, value);
  }
};

export const updateFieldNameAndProps = (editor: CustomEditorType, element: SlateElement) => {
  if (element?.children) {
    const { fontSizeProperty, isDifferent } = getFieldFontProperties(element.children, element.fontSize);
    const arrayText = element.children.map(({ text }) => text);
    const name = createNameConstant(arrayText);

    if (element.name !== name || isDifferent) {
      const path = ReactEditor.findPath(editor, element);
      if (isSingleCheckboxField(element)) {
        Transforms.setNodes(editor, {
          options: element.options?.map((option) => ({
            ...option,
            label: arrayText.join(' '),
          })),
        }, { at: path });
      }
      const additionalProperty = element.fontSize !== undefined ? fontSizeProperty : {};
      Transforms.setNodes(editor, { name, ...additionalProperty }, { at: path });
    }
  }
};