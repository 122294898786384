import { FC } from 'react';

import { HistoryRecordType } from 'types/DocumentsHistory';
import { createActionMessage } from 'utils/documentsHistory';

interface IDocumentHistoryRow {
  record: HistoryRecordType;
}

const DocumentHistoryRow: FC<IDocumentHistoryRow> = ({
  record,
}) => (
  <div className="row document-info-row">
    <div className="col-12 p-0">
      {createActionMessage(record)}
    </div>
  </div>
);

export default DocumentHistoryRow;