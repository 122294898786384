import { SearchInputValue } from 'types/Misc';
import { GroupUserType, UserListGetterType } from 'types/userProfile';

export const getAvaliableToSelectUsers = ({
  appUsers,
  groupUsers,
}: {
  appUsers: UserListGetterType[];
  groupUsers: GroupUserType[];
}): SearchInputValue[] => {
  const groupMembersIds: number[] = groupUsers.map((groupMember: GroupUserType) => groupMember.user_id);

  const selectableUsers: UserListGetterType[] = appUsers.filter(
    ({ id }: UserListGetterType) => !groupMembersIds.includes(id),
  );

  return selectableUsers.map(({
    first_name: firstname, last_name: lastname, email, id,
  }: UserListGetterType) => ({
    label: `${firstname} ${lastname} - <${email}>`,
    id: id.toString(),
  }));
};

export default undefined;