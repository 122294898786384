import ENDPOINT from 'constants/endpoints';
import { EmailNotificationSettingsOption } from 'constants/notifications';
import { SendgridSettingsType } from 'types/AccountSettings';
import { IEmailNotificationsSettingsRequestParams } from 'types/emailNotificationsSettings';
import { BaseRequestType } from 'types/RequestHandler';
import axiosInstance from 'utils/axiosInstance';

export const updateSendgridSettings = async (sendgridSettings: SendgridSettingsType) => (
  axiosInstance.patch(`${ENDPOINT.EMAILS_SETTINGS}/sendgrid`, {
    sendgrid_api_key: sendgridSettings.sendgridApiKey,
    sendgrid_email_from: sendgridSettings.sendgridEmail,
  })
);

export const getEmailNotificationsSettings: BaseRequestType<EmailNotificationSettingsOption> = () => (
  axiosInstance.get(`${ENDPOINT.EMAILS_SETTINGS}/user`)
);

export const updateEmailNotificationsSettings: BaseRequestType<
  EmailNotificationSettingsOption,
  IEmailNotificationsSettingsRequestParams
> = (
  params,
) => (
  axiosInstance.post(`${ENDPOINT.EMAILS_SETTINGS}/user`, params)
);
