import mainLogo from 'svg/main-logo-pink-white.png';

import vars from 'variables.module.scss';

const MainCompanyLogo = () => (
  <div style={{ height: vars.headerHeight }}>
    <img
      className="main-company-logo"
      src={mainLogo}
      alt="Company Logo"
    />
  </div>
);

export default MainCompanyLogo;