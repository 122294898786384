import { FC, memo } from 'react';

import { FormGroup } from 'reactstrap';

import ModalInputField from 'components/Modals/UserManagementModal/ModalInputField';
import { IUserManagementFields, ModalUserType } from 'types/userProfile';

interface IEditUserModal {
  user: ModalUserType;
  onChangeUser: (user: ModalUserType) => void;
  fieldsList: IUserManagementFields[];
  onChangeFieldsList: (fields: IUserManagementFields[]) => void;
}

const UserModal: FC<IEditUserModal> = ({
  user,
  onChangeUser,
  fieldsList,
  onChangeFieldsList,
}) => {
  const changeCurrentUser = (value: string, errorMessage: string, key: string) => {
    if (user) {
      const updatedUserData: ModalUserType = {
        ...user,
        [key]: value,
      };
      const updatedFieldsList = fieldsList.map((field) => {
        if (field.key === key) {
          return {
            ...field,
            errorMessage,
          };
        }
        return field;
      });
      onChangeFieldsList(updatedFieldsList);
      onChangeUser(updatedUserData);
    }
  };

  if (!user) return null;

  return (
    <>
      {
        fieldsList.map((field: IUserManagementFields) => (
          <FormGroup className="user-management-modal__group" key={field.key}>
            <ModalInputField field={field} onChange={changeCurrentUser} />
          </FormGroup>
        ))
      }
    </>
  );
};

export default memo(UserModal);