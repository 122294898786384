import type { KeyboardEvent } from 'react';

import { isHotkey } from 'is-hotkey';
import { Editor } from 'slate';
import { ReactEditor } from 'slate-react';

import {
  BACKSPACE_HOTKEY,
  BULLETED_LIST,
  BULLETED_LIST_HOTKEY,
  LINE_BREAK_SETTINGS,
  PARAGRAPH_BREAK_HOTKEY,
  TAB_KEY_SETTINGS,
} from 'constants/editors';
import { toggleBlock } from 'utils/editorBlockHelpers';

import { canDeleteBackward, getListItemsInRange, isCursorAtStartOfListItem, isCursorInEmptyListItem } from './lib';
import { decreaseDepth, increaseDepth, splitListItem } from './transformations';
import type { ListsEditor } from './types';

export const onKeyDown = (editor: Editor & ListsEditor, event: KeyboardEvent) => {
  const listItemsInSelection = getListItemsInRange(editor, editor.selection);

  /**
   * Since we're overriding the default Tab key behavior
   * we need to bring back the possibility to blur the editor with keyboard.
   */
  if (isHotkey('esc', event.nativeEvent)) {
    event.preventDefault();
    ReactEditor.blur(editor);
  }

  if (isHotkey(TAB_KEY_SETTINGS.tabKey, event.nativeEvent)) {
    event.preventDefault();
    if (isCursorAtStartOfListItem(editor)) {
      increaseDepth(editor);
    } else {
      editor.insertText(TAB_KEY_SETTINGS.tabIndent);
    }
  }

  if (isHotkey('shift+tab', event.nativeEvent)) {
    event.preventDefault();
    decreaseDepth(editor);
  }

  if (isHotkey(BACKSPACE_HOTKEY, event.nativeEvent) && !canDeleteBackward(editor)) {
    event.preventDefault();
    decreaseDepth(editor);
  }

  if (isHotkey(PARAGRAPH_BREAK_HOTKEY, event.nativeEvent)) {
    if (isCursorInEmptyListItem(editor)) {
      event.preventDefault();
      decreaseDepth(editor);
    } else if (listItemsInSelection.length > 0) {
      event.preventDefault();
      splitListItem(editor);
    }
  }

  if (isHotkey(LINE_BREAK_SETTINGS.key, event.nativeEvent)) {
    event.preventDefault();
    editor.insertText(LINE_BREAK_SETTINGS.indent);
  }

  if (isHotkey(BULLETED_LIST_HOTKEY, event.nativeEvent)) {
    toggleBlock(editor, BULLETED_LIST);
  }

  /**
   * Slate does not always trigger normalization when one would expect it to.
   * So we want to force it after we perform lists operations, as it fixes
   * many unexpected behaviors.
   * https://github.com/ianstormtaylor/slate/issues/3758
   */
  Editor.normalize(editor, { force: true });
};

export default onKeyDown;