import { FC } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ROUTES from 'constants/routes';
import { setError } from 'store/actions/auth';

const Error404: FC = () => {
  const { isAuthenticated } = useSelector((state: any) => state.user);
  const dispatch = useDispatch();

  const onClinkHandler = () => {
    dispatch(setError(''));
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center my-5">
      <h1>Error 404</h1>
      <h2>Page not found</h2>
      {
        isAuthenticated
          ? (<Link to={ROUTES.DASHBOARD} onClick={onClinkHandler}>Back to dashboard</Link>)
          : (<Link to={ROUTES.LOGIN} onClick={onClinkHandler}>Back to login</Link>)
      }
    </div>
  );
};

export default Error404;