import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ButtonGeneral from 'components/ButtonGeneral';
import Intro from 'components/Intro/Intro';
import PublicFooter from 'components/PublicPage/PublicFooter';
import { LOGIN, START_OVER } from 'constants/general';
import ROUTES from 'constants/routes';
import useStartOverButton from 'hooks/useStartOverButton';
import useStyleSchema, { CustomStylesEnum } from 'hooks/useStylesFromSchema';
import { logoutUser } from 'store/actions/auth';
import {
  setPublicPageCurrentDocument,
  setPublicPageStartLink,
  setPublicPageStructure,
} from 'store/actions/publicPages';
import { RootStateType } from 'store/reducers';
import CheckedCircleIcon from 'svg/CheckedCircleIcon';
import { IPublicPageDocumentStructure, IPublicPageGeneralStructure, IPublicPageState } from 'types/PublicPage';

import classes from 'scss/components/PublicSuccessPage/PublicSuccessComponent.module.scss';

const PublicSuccessComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    structure,
  }: IPublicPageState = useSelector((state: RootStateType) => state.publicPages);
  const userConfirmedAtDate: string | null = useSelector(
    (state: RootStateType) => state.profile.profileInfo?.confirmed_at,
  );

  const {
    allowMultipleSubmissions,
    allowStarOverButton,
    redirectAndReloadToStart,
  } = useStartOverButton();

  const { documents }: IPublicPageGeneralStructure = structure;
  const titles = documents.map((document: IPublicPageDocumentStructure) => document.name);

  if (!titles.length) {
    if (!userConfirmedAtDate) {
      dispatch(logoutUser());
    }
    history.push(ROUTES.LOGIN);
  }

  const styleSchema = useStyleSchema<CustomStylesEnum.button>([CustomStylesEnum.button]);
  const styleSchemaOutline = useStyleSchema<CustomStylesEnum.outlinedBorderButton>(
    [CustomStylesEnum.outlinedBorderButton],
  );

  useEffect(() => {
    dispatch(setPublicPageCurrentDocument(null));
    if (!allowMultipleSubmissions) {
      dispatch(setPublicPageStructure({
        documents,
        main: { id: '', pageType: '' },
        formBuilderFields: {},
        groupedFieldsStructure: [],
      }));
      dispatch(setPublicPageStartLink(''));
    }
  }, []);

  return (
    <Intro
      title="Great!"
      description="You have signed and sent the following documents:"
      descriptionWidth="large"
      descriptionMobileWidth="small"
      justifyContent="center"
      styles={{
        description: { margin: '0px', textAlign: 'center' },
      }}
    >
      <div className={classes.PublicSuccessComponent}>
        <div className={classes.ItemWrapper}>
          {titles.map((title: string) => (
            <div className="d-flex align-items-center my-4" key={title}>
              <div className={classes.Icon}>
                <CheckedCircleIcon />
              </div>
              <p className={classes.Text}>{title}</p>
            </div>
          ))}
        </div>
        <div className="d-flex flex-column align-items-center">
          <p className={classes.Tip}>Please login to review your documents.</p>
          <div className={`${classes.ButtonWrapper} d-md-flex flex-row align-items-center d-none`}>
            <ButtonGeneral
              outline={allowStarOverButton}
              buttonText={LOGIN}
              onClickCallback={() => history.push(ROUTES.LOGIN)}
              block
              additionalClassName={`${classes.LoginButton} py-3 me-3`}
              buttonStyles={{
                height: '3.5rem',
                ...(allowStarOverButton ? styleSchemaOutline.outlinedBorderButton : styleSchema.button),
              }}
            />
            {
              allowStarOverButton && (
                <ButtonGeneral
                  buttonText={START_OVER}
                  onClickCallback={redirectAndReloadToStart}
                  block
                  additionalClassName={`${classes.LoginButton} py-3`}
                  buttonStyles={{
                    height: '3.5rem',
                    ...styleSchema.button,
                  }}
                />
              )
            }
          </div>
          <div className="d-block d-md-none">
            <PublicFooter>
              <div className={`${classes.ButtonWrapper} d-flex w-100`}>
                <ButtonGeneral
                  buttonText={LOGIN}
                  onClickCallback={() => history.push(ROUTES.LOGIN)}
                  block
                  additionalClassName={`${classes.LoginButton} py-3 me-3 d-flex align-items-center justify-content-center min-width`}
                  buttonStyles={{
                    height: '3rem',
                    ...(allowStarOverButton ? styleSchemaOutline.outlinedBorderButton : styleSchema.button),
                  }}
                />
                {
                  allowStarOverButton && (
                    <ButtonGeneral
                      buttonText={START_OVER}
                      onClickCallback={redirectAndReloadToStart}
                      block
                      additionalClassName={`${classes.LoginButton} py-3 d-flex align-items-center justify-content-center min-width`}
                      buttonStyles={{
                        height: '3rem',
                        ...styleSchema.button,
                      }}
                    />
                  )
                }
              </div>
            </PublicFooter>
          </div>
        </div>
      </div>
    </Intro>
  );
};

export default PublicSuccessComponent;