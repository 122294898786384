import {
  DOCUMENT_TEMPLATE_WAS_CREATED,
  DOCUMENT_TEMPLATE_WAS_DELETED,
  DOCUMENT_TEMPLATE_WAS_SAVED,
  DOCUMENT_WAS_COMPLETED,
  DOCUMENT_WAS_SENT,
  DOCUMENT_WAS_SIGNED_BY_MANAGER,
  DOCUMENT_WAS_SIGNED_BY_RECIPIENT,
  DOCUMENT_WAS_VIEWED_BY_MANAGER,
  DOCUMENT_WAS_VIEWED_BY_RECIPIENT,
  FORM_DOCUMENT_WAS_COMPLETED,
  FORM_DOCUMENT_WAS_SENT,
  FORM_DOCUMENT_WAS_SIGNED_BY_MANAGER,
  FORM_DOCUMENT_WAS_SIGNED_BY_RECIPIENT,
  FORM_DOCUMENT_WAS_VIEWED_BY_MANAGER,
  FORM_DOCUMENT_WAS_VIEWED_BY_RECIPIENT,
  FORM_TEMPLATE_WAS_CREATED,
  FORM_TEMPLATE_WAS_DELETED,
  FORM_TEMPLATE_WAS_SAVED,
  PDF_DOCUMENT_WAS_COMPLETED,
  PDF_DOCUMENT_WAS_SENT,
  PDF_DOCUMENT_WAS_SIGNED_BY_MANAGER,
  PDF_DOCUMENT_WAS_SIGNED_BY_RECIPIENT,
  PDF_DOCUMENT_WAS_VIEWED_BY_MANAGER,
  PDF_DOCUMENT_WAS_VIEWED_BY_RECIPIENT,
  PDF_TEMPLATE_WAS_CREATED,
  PDF_TEMPLATE_WAS_DELETED,
  PDF_TEMPLATE_WAS_SAVED,
  SIGNLE_DOCUMENT_WAS_CREATED,
  SIGNLE_DOCUMENT_WAS_DELETED,
  SIGNLE_DOCUMENT_WAS_SAVED,
  SIGNLE_FORM_WAS_CREATED,
  SIGNLE_FORM_WAS_DELETED,
  SIGNLE_FORM_WAS_SAVED,
  SIGNLE_PDF_WAS_CREATED,
  SIGNLE_PDF_WAS_DELETED,
  SIGNLE_PDF_WAS_SAVED,
} from 'utils/amplitude/amplituteConstants';
import { GetAmplitudeEventNameArgs, GetAmplitudeEventNameReturnType } from 'utils/amplitude/amplituteTypes';
import { isFormDocument, isPDFDocument } from 'utils/PublicPage/documentTypeChecker';

export const getAmplitudeEventName = ({
  data,
  singleFlow = false,
}: GetAmplitudeEventNameArgs): GetAmplitudeEventNameReturnType => {
  if (isPDFDocument(data)) {
    return {
      created: singleFlow ? SIGNLE_PDF_WAS_CREATED : PDF_TEMPLATE_WAS_CREATED,
      deleted: singleFlow ? SIGNLE_PDF_WAS_DELETED : PDF_TEMPLATE_WAS_DELETED,
      saved: singleFlow ? SIGNLE_PDF_WAS_SAVED : PDF_TEMPLATE_WAS_SAVED,
      completed: PDF_DOCUMENT_WAS_COMPLETED,
      signedByManager: PDF_DOCUMENT_WAS_SIGNED_BY_MANAGER,
      viewedByManager: PDF_DOCUMENT_WAS_VIEWED_BY_MANAGER,
      signedByRecipient: PDF_DOCUMENT_WAS_SIGNED_BY_RECIPIENT,
      viewedByRecipient: PDF_DOCUMENT_WAS_VIEWED_BY_RECIPIENT,
      wasSent: PDF_DOCUMENT_WAS_SENT,
    };
  }

  if (isFormDocument(data)) {
    return {
      created: singleFlow ? SIGNLE_FORM_WAS_CREATED : FORM_TEMPLATE_WAS_CREATED,
      deleted: singleFlow ? SIGNLE_FORM_WAS_DELETED : FORM_TEMPLATE_WAS_DELETED,
      saved: singleFlow ? SIGNLE_FORM_WAS_SAVED : FORM_TEMPLATE_WAS_SAVED,
      completed: FORM_DOCUMENT_WAS_COMPLETED,
      signedByManager: FORM_DOCUMENT_WAS_SIGNED_BY_MANAGER,
      viewedByManager: FORM_DOCUMENT_WAS_VIEWED_BY_MANAGER,
      signedByRecipient: FORM_DOCUMENT_WAS_SIGNED_BY_RECIPIENT,
      viewedByRecipient: FORM_DOCUMENT_WAS_VIEWED_BY_RECIPIENT,
      wasSent: FORM_DOCUMENT_WAS_SENT,
    };
  }

  return {
    created: singleFlow ? SIGNLE_DOCUMENT_WAS_CREATED : DOCUMENT_TEMPLATE_WAS_CREATED,
    deleted: singleFlow ? SIGNLE_DOCUMENT_WAS_DELETED : DOCUMENT_TEMPLATE_WAS_DELETED,
    saved: singleFlow ? SIGNLE_DOCUMENT_WAS_SAVED : DOCUMENT_TEMPLATE_WAS_SAVED,
    completed: DOCUMENT_WAS_COMPLETED,
    signedByManager: DOCUMENT_WAS_SIGNED_BY_MANAGER,
    viewedByManager: DOCUMENT_WAS_VIEWED_BY_MANAGER,
    signedByRecipient: DOCUMENT_WAS_SIGNED_BY_RECIPIENT,
    viewedByRecipient: DOCUMENT_WAS_VIEWED_BY_RECIPIENT,
    wasSent: DOCUMENT_WAS_SENT,
  };
};

export default undefined;