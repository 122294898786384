import { DocumentVersionRowType } from 'components/Modals/DocumentInfoModalWindow/DocumentInfoModal';
import DocumentVersionRow from 'components/Modals/DocumentInfoModalWindow/DocumentVersionRow';

const DocumentVersionsTab = ({
  docVersions,
}: {
  docVersions: DocumentVersionRowType[];
}) => (
  <div className="container">
    {
      docVersions.map((item) => (
        <DocumentVersionRow key={item.key} versionInfo={item} />
      ))
    }
  </div>
);

export default DocumentVersionsTab;