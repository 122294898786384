import { FC, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';

import AttachmentLoadingModal from 'components/Modals/AttachmentLoadingModal';
import useAttachmentsUploader from 'hooks/useAttachmentsUploader';
import { clearAttachments } from 'store/actions/publicPages';

interface IAttachmentsUploaderProps {
  onFinishUploading?: () => void;
}

const AttachmentsUploader: FC<IAttachmentsUploaderProps> = ({
  onFinishUploading = () => undefined,
}) => {
  const dispatch = useDispatch();
  /**
   * If a share button is clicked, we don't need to redirect a user.
   * There is a bug(related, I think, to form rerender), when modal window isn't closed.
   * It's the easiest way to close this modal window without redirection.
   * It works for templates and documents.
   */
  const [shouldCloseModalWindow, setShouldCloseModalWindow] = useState(true);
  const isVisibleAttachmentLoader = useAttachmentsUploader();

  const onFinishLoading = () => {
    const interval = setInterval(() => {
      if (!isVisibleAttachmentLoader) {
        dispatch(clearAttachments());
        setShouldCloseModalWindow(false);
        if (onFinishUploading) onFinishUploading();
        clearInterval(interval);
      }
    }, 200);
  };

  return (
    <>
      <Button className="hidden attachments-loading" id="attachments-loading" />
      <AttachmentLoadingModal
        isVisible={isVisibleAttachmentLoader && shouldCloseModalWindow}
        onFinish={onFinishLoading}
      />
    </>
  );
};

export default AttachmentsUploader;