/* eslint-disable */
export const parseJSON = (json: string) => {
  try {
    return {
      parse: JSON.parse(json),
      error: null
    }
  } catch {
    return {
      error: "JSON format is invalid",
      parse: null
    }
  }
}