import React from 'react';
import { IMetadataId, IState } from 'types/Metadata';

export interface ISelectStateProps {
  states?: IState[];
  valueSelectState?: string;
  selectedState?: string | IMetadataId[];
  onChangeState: React.ChangeEventHandler<HTMLSelectElement>;
  readOnlyMode?: boolean;
}

const SelectState: React.FC<ISelectStateProps> = (props) => {
  const {
    states,
    selectedState,
    valueSelectState,
    onChangeState,
    readOnlyMode = false,
  } = props;

  return (
    <>
      {
        selectedState || states ? (
          <p className="label me-2 mb-1 d-inline-block">State:</p>
        ) : null
      }
      {
        (typeof selectedState === 'string' && !states) ? (
          <select
            className="w-auto d-inline-block ml-2 custom-select"
            defaultValue={selectedState}
            disabled={readOnlyMode}
          >
            <option value={selectedState}>
              {selectedState}
            </option>
          </select>
        ) : null
      }
      {
        (Array.isArray(selectedState) && states) ? (
          <select
            className="w-auto d-inline-block ml-2 custom-select"
            value={valueSelectState}
            onChange={onChangeState}
            disabled={readOnlyMode}
          >
            {
              states.map((state) => (
                <option
                  key={state.id}
                  id={state.id.toString()}
                  value={state.abbreviation}
                >
                  {state.abbreviation}
                </option>
              ))
            }
          </select>
        ) : null
      }
    </>
  );
};

export default SelectState;