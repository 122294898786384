import { Dispatch, SetStateAction } from 'react';

import { SharingResources } from 'constants/sendDocuments';
import { ShareViewModes } from 'constants/shareViewModes';
import { SignerFlow } from 'constants/signNow';
import { DocumentTypesType } from 'types/Documents';

export type SendDocumentInfoType = {
  id?: number;
  type?: DocumentTypesType;
  position?: number;
  name?: string;
}

export enum SignerType {
  MANAGER = 'manager',
  RECIPIENT = 'recipient',
  CC_RECIPIENT = 'cc_recipient',
}

export type SignersInfoType = {
  type: string;
  firstName: string;
  firstNameError?: boolean;
  firstNameErrorText?: string;
  lastName: string;
  email: string;
  emailError?: boolean;
  emailErrorText?: string;
  position?: number;
  label?: string;
  signed?: boolean;
}

export type SendDocumentsReducerType = {
  initialLocation: string | null;
  documentsInfo: SendDocumentInfoType[];
  signersInfo: SignersInfoType[];
  emailSubject: string;
  emailMessage: string;
  resourceType: SharingResources;
  onlyCollection: null | SendDocumentInfoType;
  executedDocId: string;
  linkViewMode: ShareViewModes;
  isDocumentCompleted: boolean;
  isRemind?: boolean;
  isRemindForAllSigners?: boolean;
  signingOrderFlow?: SignerFlow;
  carbonCopyUsersInfo: SignersInfoType[];
}

export enum INNER_PAGES {
  FIRST_PAGE = 1,
  SECOND_PAGE = 2,
  THIRD_PAGE = 3,
}

export enum SIGNER_INFO {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  TYPE = 'type',
}

export interface ISelectSigners {
  signersArray: SignersInfoType[];
  setSignersArray: Dispatch<SetStateAction<SignersInfoType[]>>;
  canDeleteCard?: boolean;
  isOrderVisible?: boolean;
  isDisabled?: boolean;
  isSignNow?: boolean;
}

export interface IInnerComponentsWrapper {
  innerPage: INNER_PAGES;
  signersArray: SignersInfoType[];
  setSignersArray: Dispatch<SetStateAction<SignersInfoType[]>>
}

export interface ISignerCard {
  title?: string | null;
  signer: SignersInfoType;
  indexPosition: number;
  onChange?: (index: number, updatedUser: Partial<SignersInfoType>) => void;
  onDelete?: (index: number) => void;
  isDisabled?: boolean;
  isSignNow?: boolean;
  canDeleteCard?: boolean;
}

export interface ISignerOnFinalPage {
  signer: SignersInfoType;
}

export interface IDocumentsInfoList {
  documentsInfo: SendDocumentInfoType[];
}

export interface IDocumentSortableItem {
  value: SendDocumentInfoType;
}