import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import useSearchParams from 'hooks/useSearchParams';
import { loginUser, setError, setIsLoaded } from 'store/actions/auth';
import { RootStateType } from 'store/reducers';
import { ILoginRequestObj } from 'types/Auth';

const initialUserState: ILoginRequestObj = {
  username: '',
  password: '',
};

const useLogin = (redirectAfterLogin: boolean = true) => {
  const dispatch = useDispatch();
  const searchParams = useSearchParams();
  const { error } = useSelector((state: RootStateType) => state.errorLoading);
  const [user, setUser] = useState<ILoginRequestObj>(initialUserState);
  const [disableLogin, setDisableLogin] = useState<boolean>(true);
  const [isButtonClicked, setIsButtonClicked] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    setDisableLogin(!user.username.length || !user.password.length);
  }, [user.username, user.password]);

  const onLoginUserHandler = () => {
    setIsButtonClicked(true);
    dispatch(setError(''));

    const backToLink = searchParams.get('backTo');
    dispatch(loginUser({ ...user, backToLink, redirectAfterLogin }));
  };

  const changeUserHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUser((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const forgotPasswordHandler = () => {
    setIsButtonClicked(false);
    setUser(initialUserState);
    dispatch(setError(''));
    dispatch(setIsLoaded(false));
    setShowModal(true);
  };

  return {
    user,
    changeUserHandler,
    error,
    forgotPasswordHandler,
    onLoginUserHandler,
    isButtonClicked,
    disableLogin,
    showModal,
    setShowModal,
  };
};

export default useLogin;