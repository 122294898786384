import { BaseSelection, Editor, Element as SlateElement, Location, Node as SlateNode, NodeEntry, Path } from 'slate';
import { ReactEditor } from 'slate-react';

import { TABLE_QUESTION, TABLE_QUESTION_BODY, TABLE_QUESTION_FIELD_CELL, TABLE_QUESTION_ROW } from 'constants/editors';
import { BlockFormatType, ICustomElement } from 'types/Editor';
import { filterNodes } from 'utils/editorHelpers';
import { parseFields } from 'utils/templateSagasHelpers';

import { CaptionSide, TextAlign } from 'types/cssProperties';

export const getTextFromNode = (node: ICustomElement) => {
  if (!node) return '';
  return node.children.map((n) => SlateNode.string(n)).join('');
};

export const findTableQuestion = (editor: Editor): NodeEntry<ICustomElement> | undefined => (
  Editor.above(editor, {
    match: (n) => SlateElement.isElement(n) && n.type === TABLE_QUESTION,
  })
);

export const findNodesByParam = (
  editor: Editor | ICustomElement,
  param: { key?: number | null; type?: BlockFormatType } = {},
) => {
  const { key, type } = param;

  return filterNodes(editor.children, (node) => (
    SlateElement.isElement(node) && ((key === null || node.key === key) || (type === null || node.type === type))
  ));
};

export const getIsTableQuestion = (editor: Editor): boolean => !!findTableQuestion(editor);

export const findTableQuestionBody = (editor: Editor): NodeEntry<ICustomElement> | undefined => (
  Editor.above(editor, {
    match: (n) => SlateElement.isElement(n) && n.type === TABLE_QUESTION_BODY,
  })
);

export const findTableQuestionRow = (editor: Editor): ICustomElement[] | undefined => {
  const rows: NodeEntry<ICustomElement> | undefined = Editor.above(editor, {
    match: (n) => SlateElement.isElement(n) && n.type === TABLE_QUESTION_ROW,
  });
  if (!rows) return;
  const [row] = rows;
  if (!row) return;
  const children = row.children.filter((cell) => SlateElement.isElement(cell) && cell.type);
  return children as ICustomElement[];
};

export const findTableQuestionCol = (
  editor: Editor,
  colIndex: number,
): ICustomElement[] | undefined => {
  const bodyEntry = findTableQuestionBody(editor);
  if (!bodyEntry) return;

  const [bodyNode] = bodyEntry;

  const column: ICustomElement[] = [];

  bodyNode.children.forEach((row) => {
    if (!row.children) return;
    const cellNode = row.children[colIndex] as ICustomElement;
    if (cellNode) {
      column.push(cellNode);
    }
  });

  return column;
};

export const findEditorNodesByPosition = (
  editor: Editor,
  positionAt: BaseSelection | Path,
  typeMatch: BlockFormatType[] | BlockFormatType,
): NodeEntry<ICustomElement>[] | undefined => (
  Array.from(Editor.nodes(editor, {
    at: positionAt as Location,
    match: (n) => {
      if (!SlateElement.isElement(n)) return false;
      return typeMatch.includes(n.type);
    },
  }))
);

export const getTableQuestionCaptionPosition = (
  element: SlateElement,
): [CaptionSide | undefined, TextAlign | undefined] => {
  if (element.captionSide) {
    const [captionSide, textAlign] = element.captionSide.split('_');
    return [captionSide as CaptionSide, textAlign as TextAlign];
  }

  return ['top', 'left'];
};

const findParentTableRow = (editor: Editor, nodeElement: ICustomElement): NodeEntry<SlateNode> | null => {
  const nodePath = ReactEditor.findPath(editor, nodeElement);

  const parent = Editor.above(editor, {
    at: nodePath,
    match: (n) => SlateElement.isElement(n) && n.type === TABLE_QUESTION_ROW,
  });

  if (!parent) return null;
  const [node, path] = parent;

  return node ? [node, path] : null;
};

const findFieldInRow = (row: ICustomElement, key: number): number => (
  row.children.findIndex((cell: SlateNode) => (
    SlateElement.isElement(cell)
    && cell.type === TABLE_QUESTION_FIELD_CELL
    && (cell as ICustomElement).children.some((n) => (
      SlateElement.isElement(n) && n.key === key
    ))
  ))
);

export const findColumnFieldIndexes = (editor: Editor, field: ICustomElement): number[] | undefined => {
  const tableRow = findParentTableRow(editor, field);
  if (tableRow) {
    const tableBody = findTableQuestionBody(editor);

    if (!tableBody) return;
    const tableRows = findEditorNodesByPosition(editor, tableBody[1], [TABLE_QUESTION_ROW]);

    const columnIndex = findFieldInRow(tableRow[0] as ICustomElement, (field as ICustomElement).key ?? 0);
    if (!tableRows) return;

    const fieldKeys: number[] = [];
    const cells: Partial<ICustomElement> = {
      children: [],
    };

    tableRows.forEach(([rowNode]) => {
      const cellNode = rowNode.children && rowNode.children[columnIndex];
      if (!SlateElement.isElement(cellNode)) return;
      cells.children?.push(cellNode);
    });

    parseFields(cells, (node: ICustomElement) => {
      fieldKeys.push(node.key ?? 0);
    });
    return fieldKeys;
  }
};