import { FC, ReactNode } from 'react';

interface ISettingsLayoutProps {
  title: string;
  children?: ReactNode;
}

const SettingsLayout: FC<ISettingsLayoutProps> = ({
  title,
  children,
}) => (
  <div className="account-settings">
    <div className="account-settings__header">
      <h2 className="account-settings__title">{title}</h2>
    </div>
    <div className="account-settings__body">
      {children}
    </div>
  </div>
);

export default SettingsLayout;