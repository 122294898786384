import { useState } from 'react';

import { useSelector } from 'react-redux';

import {
  URL_PUBLIC_COLLECTION,
  URL_PUBLIC_DOCUMENT,
  URL_PUBLIC_TEMPLATE,
} from 'constants/general';
import ROUTES from 'constants/routes';
import { SharingResources } from 'constants/sendDocuments';
import { RootStateType } from 'store/reducers';

const useShareLInkInMUITable = (shareLinkResource: SharingResources) => {
  const {
    templatePublicLink,
    documentPublicLink,
    multiTemplatePublicLink,
    collectionExecutedPublicLink,
  } = useSelector((state: RootStateType) => state.publicPages);
  const [showShareLinkModal, setShowShareLinkModal] = useState<boolean>(false);

  const getResultObject = () => {
    switch (shareLinkResource) {
      case SharingResources.DOCUMENT:
        return templatePublicLink.assignments;
      case SharingResources.DOCUMENTS_EXECUTED:
        return documentPublicLink.assignments;
      case SharingResources.COLLECTION:
        return multiTemplatePublicLink.assignments;
      case SharingResources.COLLECTION_EXECUTED:
        return collectionExecutedPublicLink.assignments;
      default:
        return SharingResources.EMPTY_STRING;
    }
  };

  const getMainPublicRoute = () => {
    switch (shareLinkResource) {
      case SharingResources.DOCUMENT:
        return URL_PUBLIC_TEMPLATE;
      case SharingResources.DOCUMENTS_EXECUTED:
        return URL_PUBLIC_DOCUMENT;
      case SharingResources.COLLECTION:
        return URL_PUBLIC_COLLECTION;
      case SharingResources.COLLECTION_EXECUTED:
        return ROUTES.PUBLIC_COLLECTION_EXECUTED;
      default:
        return SharingResources.EMPTY_STRING;
    }
  };

  const getTargetResourceGuid = () => {
    switch (shareLinkResource) {
      case templatePublicLink.origin:
        return URL_PUBLIC_TEMPLATE;
      case SharingResources.DOCUMENTS_EXECUTED:
        return documentPublicLink.origin;
      case SharingResources.COLLECTION:
        return multiTemplatePublicLink.origin;
      case SharingResources.COLLECTION_EXECUTED:
        return collectionExecutedPublicLink.origin;
      default:
        return SharingResources.EMPTY_STRING;
    }
  };

  return {
    publicRoute: getMainPublicRoute(),
    result: getResultObject(),
    resourceGuid: getTargetResourceGuid(),
    showShareLinkModal,
    setShowShareLinkModal,
  };
};

export default useShareLInkInMUITable;