import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Descendant, Element as SlateElement } from 'slate';

import { ATTACHMENT_FIELD, RECIPIENT_ASSIGNMENT } from 'constants/editors';
import { LOCAL_USER_IP_LABEL, URL_PUBLIC_DOCUMENT } from 'constants/general';
import { SUCCESS_CODE, SUCCESS_CREATE_CODE } from 'constants/generalErrors';
import useSendBeacon from 'hooks/SendBeacon/useSendBeacon';
import { createCollection, createDocumentPublic, editPublicDocument, updateExecutedCollection } from 'services/api';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { setCreatedDocumentId } from 'store/actions/publicPages';
import { RootStateType } from 'store/reducers';
import { ICreateDocumentRequestObj } from 'types/Documents';
import { CollectionAttachments, CollectionDocumentResponseObj } from 'types/MultiTemplate';
import { IPublicPageGeneralStructure, IPublicPageState, PublicPageDataType } from 'types/PublicPage';
import { ITemplateDetails } from 'types/Templates';
import { runAttachmentsLoading, setMultiAttachmentNodeOptions } from 'utils/attachmentHelpers';
import createErrorMessage from 'utils/errorsHelpers';
import { getUserInfoFromPII, getUserPIIInfoArray } from 'utils/PIIAndGrouping/groupedAndPIIFieldsHelper';
import { getIsPDFDocument } from 'utils/PublicPage/documentTypeChecker';
import getFieldsFromData from 'utils/PublicPage/getFieldsFromData';
import {
  getIsEmptyDocumentsObject,
  getNewCollectionData,
  getUpdatedCollectionData,
} from 'utils/PublicPage/getNewCollectionData';
import getNewDocumentDataFromTemplate from 'utils/PublicPage/getNewDocumentDataFromTemplate';
import { updatePIIData } from 'utils/Subtypes/subtypeHelpers';
import { isCollectionExecutedType, isCollectionType, isDocumentType, isTemplateType } from 'utils/typeChecker';

const { REACT_APP_API_URL } = process.env;

interface IRequestType {
  data: {
    id?: number;
    ids?: CollectionDocumentResponseObj[];
    message: string;
  };
  errors: string;
  status: number;
}

const useSavePublicPageHandler = (): [
  (
    data: PublicPageDataType | null,
    id: string,
    isUnfinished?: boolean,
    documentUUID?: string | null,
    updatedData?: PublicPageDataType | null
  ) => void,
  boolean
] => {
  const dispatch = useDispatch();
  const [isVisibleAttachmentLoader, setAttachmentLoaderVisibility] = useState(false);
  const {
    totalAttachedFilesCounter,
    sentFilesCounter,
    structure,
    currentDocument,
    mondayComBoard,
    mondayComItemIdToFilloutSubitem,
    userSmsConsent,
    documentUUID: documentUUIDFromStore,
  }: IPublicPageState = useSelector((state: RootStateType) => state.publicPages);
  const { documents, main }: IPublicPageGeneralStructure = structure;
  const { piiData } = useSelector((state: RootStateType) => state.profile);
  const { sendDocumentDataUsingBeacon } = useSendBeacon();
  const documentAssignment = main.mainAssignment || RECIPIENT_ASSIGNMENT;

  const savePublicPage = async (
    data: PublicPageDataType | null,
    id: string,
    isUnfinished?: boolean,
    documentUUID?: string | null,
    updatedData?: ITemplateDetails | any,
  ) => {
    const ipAddress = localStorage.getItem(LOCAL_USER_IP_LABEL) as string;
    const isUnfinishedFlow = typeof isUnfinished === 'boolean' && isUnfinished;

    let updatedResult: IRequestType | null = null;
    if (isTemplateType(data)) {
      const newDocumentData = getNewDocumentDataFromTemplate({
        data: isUnfinishedFlow ? updatedData : data,
        ipAddress,
        assignment: RECIPIENT_ASSIGNMENT,
        mainAssignment: documentAssignment,
        historyLog: documents[0].historyLog,
        identifier: documents[0].identifier,
        mondayComBoard,
        mondayComItemIdToFilloutSubitem,
      });
      if (newDocumentData) {
        const userPIIData = getUserPIIInfoArray(getUserInfoFromPII(
          piiData,
          structure.groupedFieldsStructure,
        ));
        newDocumentData.user_data = updatePIIData(
          userPIIData,
          newDocumentData.user_data || [],
        );
        newDocumentData.user_sms_consent = userSmsConsent;
        if (documentUUIDFromStore) {
          newDocumentData.documentUUID = documentUUIDFromStore;
        }

        if (isUnfinishedFlow) {
          sendDocumentDataUsingBeacon(
            `${REACT_APP_API_URL}/${URL_PUBLIC_DOCUMENT}/${id}?type=unfinished`,
            newDocumentData,
            documentUUID,
          );
        } else {
          updatedResult = await apiErrorHandler(createDocumentPublic, {
            id,
            body: newDocumentData,
            returnErrorResult: true,
          });
        }
      }
    }
    if (isDocumentType(data)) {
      const userPIIData = getUserPIIInfoArray(getUserInfoFromPII(piiData));
      const editedDocument: ICreateDocumentRequestObj = {
        name: data.name,
        content_json: data.content_json as Descendant[],
        type: data.type,
        historyLog: documents[0].historyLog,
        identifier: documents[0].identifier,
        user_data: userPIIData,
        document_assignment: documentAssignment,
      };
      if (documentUUIDFromStore) {
        editedDocument.documentUUID = documentUUIDFromStore;
      }
      updatedResult = await apiErrorHandler(editPublicDocument, {
        id,
        document: editedDocument,
        returnErrorResult: true,
      });
    }
    if (isCollectionType(data)) {
      const isEmptyDocumentsObject = getIsEmptyDocumentsObject(documents);
      const collectionData = getNewCollectionData(
        data,
        isEmptyDocumentsObject,
        documents,
        ipAddress,
        currentDocument,
        piiData,
      );

      const newCollectionData = {
        documents: collectionData,
        monday_com_item_id: mondayComItemIdToFilloutSubitem,
        monday_com_board: mondayComBoard,
      };

      updatedResult = await apiErrorHandler(createCollection, {
        id,
        body: newCollectionData,
        returnErrorResult: true,
      });
    }
    if (isCollectionExecutedType(data)) {
      const executedCollection = getUpdatedCollectionData(
        data,
        documents,
        ipAddress,
        currentDocument,
        piiData,
      );

      updatedResult = await apiErrorHandler(updateExecutedCollection, {
        id,
        body: executedCollection,
        returnErrorResult: true,
      });
    }

    if (updatedResult && ![SUCCESS_CREATE_CODE, SUCCESS_CODE].includes(updatedResult.status)) {
      // ToDo - create interface for error responce and set them to one view on BE-FE sides
      return toast.error(createErrorMessage(updatedResult as any));
    }

    if (typeof isUnfinished !== 'boolean' || (typeof isUnfinished === 'boolean' && !isUnfinished)) {
      setAttachmentLoaderVisibility(true);
    }
    if (
      updatedResult
      && [SUCCESS_CREATE_CODE, SUCCESS_CODE].includes(updatedResult.status)
      && (updatedResult.data?.id || updatedResult.data?.ids)
    ) {
      if (totalAttachedFilesCounter === sentFilesCounter) {
        setAttachmentLoaderVisibility(false);
      } else {
        if (updatedResult?.data.id) {
          dispatch(setCreatedDocumentId(Number(updatedResult?.data.id)));
          runAttachmentsLoading(String(updatedResult?.data.id));
        }
        // TODO Attachments for executed collections
        if (isCollectionType(data) && updatedResult?.data.ids?.length) {
          const collectionAttachments: CollectionAttachments[] = [];
          data.templates?.forEach(({ template }) => {
            const attachmentFields: Partial<SlateElement>[] = getFieldsFromData({
              data: template,
              assignment: RECIPIENT_ASSIGNMENT,
              isPDF: getIsPDFDocument(template.type),
              fieldType: ATTACHMENT_FIELD,
            });
            const ids: number[] = attachmentFields.map((field) => field.key || 0);
            collectionAttachments.push({
              templateId: template.id,
              fieldIds: ids,
            });
          });
          setMultiAttachmentNodeOptions(dispatch, updatedResult?.data.ids, collectionAttachments);
        }
        if (isCollectionExecutedType(data) && updatedResult?.data.ids?.length) {
          const collectionAttachments: CollectionAttachments[] = [];
          data.documents?.forEach(({ document }) => {
            const attachmentFields: Partial<SlateElement>[] = getFieldsFromData({
              data: document,
              assignment: RECIPIENT_ASSIGNMENT,
              isPDF: getIsPDFDocument(document.type),
              fieldType: ATTACHMENT_FIELD,
            });
            const ids: number[] = attachmentFields.map((field) => field.key || 0);
            collectionAttachments.push({
              documentId: document.id,
              fieldIds: ids,
            });
          });
          setMultiAttachmentNodeOptions(
            dispatch,
            updatedResult?.data.ids,
            collectionAttachments,
            true,
          );
        }
      }
    }
  };

  return [savePublicPage, isVisibleAttachmentLoader];
};

export default useSavePublicPageHandler;