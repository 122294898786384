import { CSSProperties, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useSelector } from 'react-redux';

import { createEditor } from 'slate';
import { Slate } from 'slate-react';

import cn from 'classnames';
import PdfRenderer from 'components/Editor/components/pdfRenderer/PdfRenderer';
import PDFEditorDevTools from 'components/Editor/EditorDevTools/PDFEditorDevTools';
import PdfAttachmentZone from 'components/PdfEditor/PdfAttachmentZone';
import PdfFormView from 'components/PdfEditor/PdfFormView';
import AttachmentsView from 'components/PublicPage/AttachmentsView';
import PublicFormView from 'components/PublicPage/PublicFormView';
import { DEFAULT_ZOOM_SCALE, MIN_ZOOM_SCALE, SCREEN_WIDTH_BREAKPOINT } from 'constants/pdfScaling';
import { ShareViewModes } from 'constants/shareViewModes';
import { ATTACHMENTS_TAB_ID, DOCUMENT_TAB_ID, FORM_TAB_ID } from 'constants/tabs';
import useBodyOverflow from 'hooks/useBodyOverflow';
import useRootStyles from 'hooks/useRootStyles';
import { RootStateType } from 'store/reducers';
import { IPdfEditorProps } from 'types/Editor';
import { IPublicPageDocumentStructure } from 'types/PublicPage';
import { getIsDocumentWithFormBuilder } from 'utils/PublicPage/documentTypeChecker';

const PdfEditor: FC<IPdfEditorProps> = ({
  visibleEditorDevTools = true,
  isPublicPage = false,
  isFormsView = true,
  modal = false,
  readOnlyMode = false,
  isMultiTemplate = false,
  pdfTemplateId = 0,
  customFields = null,
  isFormBuilderOpened = false,
}) => {
  useBodyOverflow('hidden');
  const styles: CSSProperties = useMemo(() => ({
    'overflow-y': 'hidden',
    display: 'grid',
    'grid-template-rows': 'auto 1fr',
    height: '100vh',
  }), []);
  useRootStyles(styles);

  const pdfTemplateInfoMultiTemplate = useSelector((state: RootStateType) => (
    state.multiTemplates.pdfPartsOfMultiTemplate[pdfTemplateId]
  ));
  const pdfTemplateSimple = useSelector((state: RootStateType) => state.pdfTemplates);
  const currentDocument: IPublicPageDocumentStructure | null = useSelector(
    (state: RootStateType) => state.publicPages.currentDocument,
  );
  const activeTab = currentDocument ? currentDocument.activeEditorTab : DOCUMENT_TAB_ID;
  const { pdfTemplateLink, pdfTemplateWidth } = isMultiTemplate ? pdfTemplateInfoMultiTemplate : pdfTemplateSimple;

  const isDocumentWithFormBuilderView = getIsDocumentWithFormBuilder(
    currentDocument?.viewMode as ShareViewModes,
  );

  const [addedScale, setAddedScale] = useState<number>(DEFAULT_ZOOM_SCALE);
  const [currentWidth, setCurrentWidth] = useState<number>(window.screen.width);

  const editorClass = isPublicPage ? '' : 'editor-size';

  const wrapperRef = useRef<HTMLDivElement>(null);
  const wrapper = wrapperRef.current;

  const onChangeOrientationHandler = useCallback((currentWidth: number) => {
    if (!isFormsView && isPublicPage) {
      if (currentWidth <= SCREEN_WIDTH_BREAKPOINT) {
        let currentScale = Math.ceil((currentWidth / (SCREEN_WIDTH_BREAKPOINT)) * 100) / 100;
        if (currentScale < MIN_ZOOM_SCALE) {
          currentScale = MIN_ZOOM_SCALE;
        }
        setAddedScale(currentScale);
      } else {
        setAddedScale(DEFAULT_ZOOM_SCALE);
      }
    }
  }, [isFormsView, isPublicPage]);

  const eventListenerWrapper = useCallback(() => {
    if (isPublicPage) {
      const screenWidth = window.screen.width;
      setCurrentWidth((prevState: number) => (
        prevState !== screenWidth ? screenWidth : prevState
      ));
      onChangeOrientationHandler(screenWidth);
    }
  }, [isPublicPage, onChangeOrientationHandler]);

  window.addEventListener('orientationchange', eventListenerWrapper);

  useEffect(() => {
    onChangeOrientationHandler(currentWidth);
    return () => {
      window.removeEventListener('orientationchange', eventListenerWrapper);
    };
  }, [wrapper, isFormsView, isPublicPage, currentWidth]);

  const slateEditor = useMemo(() => createEditor(), []);

  return (
    <>
      <div
        ref={wrapperRef}
        className="py-3"
      >
        {
          (!isPublicPage && !isMultiTemplate && !pdfTemplateLink) ? (
            <div className={`w-100 editor-editable overflow-auto ${modal ? '' : editorClass}`}>
              <PdfAttachmentZone />
            </div>
          ) : (
            <>
              <div
                className={cn(
                  'w-100',
                  activeTab === DOCUMENT_TAB_ID ? '' : 'd-none',
                )}
              >
                <PdfRenderer
                  fileLink={pdfTemplateLink}
                  isPDFTemplate
                  isReadOnlyMode={readOnlyMode}
                  isPublicPage={isPublicPage}
                  pdfTemplateWidth={pdfTemplateWidth}
                  isCollectionPage={isMultiTemplate}
                  pdfTemplateId={pdfTemplateId}
                  startScale={addedScale}
                  customFields={customFields}
                  isFormBuilderOpened={isFormBuilderOpened}
                />
              </div>
              {
                 activeTab === ATTACHMENTS_TAB_ID
                   ? <AttachmentsView />
                   : null
              }
              {
                isPublicPage && !isMultiTemplate && !isDocumentWithFormBuilderView && activeTab === FORM_TAB_ID
                  ? (
                    <div
                      className="w-100 px-3"
                    >
                      <PdfFormView
                        readOnlyMode={readOnlyMode}
                        isPublicPage
                        isMultiTemplate={isMultiTemplate}
                        pdfTemplateId={pdfTemplateId}
                      />
                    </div>
                  )
                  : null
              }
              {
                isPublicPage && isDocumentWithFormBuilderView && activeTab === FORM_TAB_ID
                  ? (
                    <div
                      className="w-100"
                    >
                      <Slate editor={slateEditor} value={[]} onChange={() => undefined}>
                        <PublicFormView isReadOnly={readOnlyMode} isFormsView={isFormsView} />
                      </Slate>
                    </div>
                  )
                  : null
              }
            </>
          )
        }
      </div>
      {
        visibleEditorDevTools && !readOnlyMode ? (
          <div className={`editor-navigator-wrapper right-0 ${visibleEditorDevTools ? '' : ' inactive'}`}>
            <PDFEditorDevTools />
          </div>
        ) : null
      }
    </>
  );
};

export default PdfEditor;