import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { ThemeProvider } from '@mui/material';

import App from 'App';
import ErrorBoundary from 'pages/ErrorBoundary';
import { history } from 'services/history';
import * as serviceWorker from 'serviceWorker';
import { persistor, store } from 'store';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'styles.scss';
import theme from 'scss/MuiTheme';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <ErrorBoundary>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </ErrorBoundary>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
