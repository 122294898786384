import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Button from 'components/Base/Button';
import DifferentSubtypeValuesForm from 'components/Modals/SubtypesModal/DifferentSubtypeValuesForm';
import { PROPERTIES, SELECT_FIELD_TYPE_SELECT } from 'constants/editors';
import { SAVE } from 'constants/general';
import {
  IEditorModalSubtypesFields,
  IEditorModalSubtypesFieldsWithAssignments,
  ISubtypeDifferentValuesWithAssignments,
  SubtypeDifferentValuesType,
} from 'types/Subtypes';
import { isEmptyObject, isNotEmptyObject } from 'utils/isEmptyObject';
import { getModalSubtypesFields } from 'utils/Subtypes/subtypeHelpers';

interface IEditorSubtypesModalProp {
  isVisibleModal: boolean;
  userSubtypes: ISubtypeDifferentValuesWithAssignments;
  managerSubtypes: ISubtypeDifferentValuesWithAssignments;
  groupedFields: {
    user: ISubtypeDifferentValuesWithAssignments;
    manager: ISubtypeDifferentValuesWithAssignments;
  };
  onSaveSubtypes: (
    updatedFields: IEditorModalSubtypesFieldsWithAssignments,
    updatedManagerFields: IEditorModalSubtypesFieldsWithAssignments,
    groupedFields: {
      user: IEditorModalSubtypesFieldsWithAssignments,
      manager: IEditorModalSubtypesFieldsWithAssignments,
    },
  ) => void;
  setModalVisibility: Dispatch<SetStateAction<boolean>>;
}

const EditorSubtypesModal: FC<IEditorSubtypesModalProp> = ({
  isVisibleModal,
  userSubtypes,
  managerSubtypes,
  groupedFields,
  setModalVisibility,
  onSaveSubtypes,
}) => {
  const [isVisible, setVisibility] = useState(false);
  const [userSubtypesFields, setUserSubtypesFields] = useState<IEditorModalSubtypesFieldsWithAssignments>({});
  const [managerSubtypesFields, setManagerSubtypesFields] = useState<IEditorModalSubtypesFieldsWithAssignments>({});
  const [userGroupedFields, setUserGroupedFields] = useState<IEditorModalSubtypesFieldsWithAssignments>({});
  const [managerGroupedFields, setManagerGroupedFields] = useState<IEditorModalSubtypesFieldsWithAssignments>({});

  useEffect(() => {
    if (isEmptyObject(userSubtypesFields)) {
      setUserSubtypesFields(getModalSubtypesFields(userSubtypes));
    }
  }, [userSubtypes]);

  useEffect(() => {
    if (isEmptyObject(managerSubtypesFields)) {
      setManagerSubtypesFields(getModalSubtypesFields(managerSubtypes));
    }
  }, [managerSubtypes]);

  useEffect(() => {
    if (isEmptyObject(userGroupedFields) && isNotEmptyObject(groupedFields.user)) {
      setUserGroupedFields(getModalSubtypesFields(groupedFields.user));
    }
    if (isEmptyObject(managerGroupedFields) && isNotEmptyObject(groupedFields.manager)) {
      setManagerGroupedFields(getModalSubtypesFields(groupedFields.manager));
    }
  }, [groupedFields.user, groupedFields.manager]);

  useEffect(() => {
    setVisibility(isVisibleModal);
  }, [isVisibleModal]);

  const changeProperty = ({
    value,
    subtypeName,
    propertyName,
    fields,
    callback,
    mainAssignment,
  }: {
    value: SubtypeDifferentValuesType;
    subtypeName: string;
    propertyName: string;
    fields: IEditorModalSubtypesFields[];
    callback: Dispatch<SetStateAction<IEditorModalSubtypesFieldsWithAssignments>>;
    mainAssignment: string;
  }) => {
    const updatedProperty: IEditorModalSubtypesFields[] = fields.map((field) => {
      if (field.name === subtypeName) {
        let updatedValue = value;
        if (propertyName === PROPERTIES.REQUIRED_FIELD && value === '') {
          updatedValue = false;
        }
        if (propertyName === PROPERTIES.SELECT_FIELD_TYPE && value === '') {
          updatedValue = SELECT_FIELD_TYPE_SELECT;
        }
        return {
          ...field,
          properties: {
            ...field.properties,
            [propertyName]: updatedValue,
          },
        };
      }
      return field;
    });

    callback((prevState) => {
      const updatedPrevState: IEditorModalSubtypesFieldsWithAssignments = {};
      updatedPrevState[mainAssignment] = prevState[mainAssignment].map((field) => {
        if (field.name === subtypeName) {
          return updatedProperty.find((field) => field.name === subtypeName) || field;
        }
        return field;
      });
      return {
        ...prevState,
        ...updatedPrevState,
      };
    });
  };

  const closeModalHandler = () => {
    onSaveSubtypes(
      userSubtypesFields,
      managerSubtypesFields,
      {
        user: userGroupedFields,
        manager: managerGroupedFields,
      },
    );
    setVisibility(false);
    setModalVisibility(false);
  };

  return (
    <Modal centered isOpen={isVisible} contentClassName="subtypes-modal">
      <ModalHeader>
        Main Fields Value
      </ModalHeader>

      <ModalBody>
        {
          isNotEmptyObject(userSubtypesFields) ? (
            Object.keys(userSubtypesFields).map((mainAssignment: string, index: number) => (
              <div key={mainAssignment} className="mb-1">
                <h2>
                  Recipient {index + 1} Subtypes Fields
                </h2>
                <DifferentSubtypeValuesForm
                  subtypes={userSubtypes[mainAssignment]}
                  onChangeProperty={(
                    value,
                    subtypeName,
                    propertyName,
                  ) => changeProperty({
                    value,
                    subtypeName,
                    propertyName,
                    fields: userSubtypesFields[mainAssignment],
                    callback: setUserSubtypesFields,
                    mainAssignment,
                  })}
                />
              </div>
            ))) : null
        }
        {
          isNotEmptyObject(managerSubtypesFields) ? (
            Object.keys(managerSubtypesFields).map((mainAssignment: string, index: number) => (
              <div key={mainAssignment} className="mb-1">
                <h2>
                  Manager {index + 1} Subtypes Fields
                </h2>
                <DifferentSubtypeValuesForm
                  subtypes={managerSubtypes[mainAssignment]}
                  onChangeProperty={(
                    value,
                    subtypeName,
                    propertyName,
                  ) => changeProperty({
                    value,
                    subtypeName,
                    propertyName,
                    fields: managerSubtypesFields[mainAssignment],
                    callback: setManagerSubtypesFields,
                    mainAssignment,
                  })}
                />
              </div>
            ))) : null
        }
        {
          isNotEmptyObject(userGroupedFields) ? (
            Object.keys(userGroupedFields).map((mainAssignment: string, index: number) => (
              <div key={mainAssignment} className="mb-1">
                <h2>
                  Recipient {index + 1} Grouped Fields
                </h2>
                <DifferentSubtypeValuesForm
                  subtypes={groupedFields.user[mainAssignment]}
                  onChangeProperty={(
                    value,
                    subtypeName,
                    propertyName,
                  ) => changeProperty({
                    value,
                    subtypeName,
                    propertyName,
                    fields: userGroupedFields[mainAssignment],
                    callback: setUserGroupedFields,
                    mainAssignment,
                  })}
                />
              </div>
            ))) : null
        }
        {
          isNotEmptyObject(managerGroupedFields) ? (
            Object.keys(managerGroupedFields).map((mainAssignment: string, index: number) => (
              <div key={mainAssignment} className="mb-1">
                <h2>
                  Manager {index + 1} Grouped Fields
                </h2>
                <DifferentSubtypeValuesForm
                  subtypes={groupedFields.manager[mainAssignment]}
                  onChangeProperty={(
                    value,
                    subtypeName,
                    propertyName,
                  ) => changeProperty({
                    value,
                    subtypeName,
                    propertyName,
                    fields: managerGroupedFields[mainAssignment],
                    callback: setManagerGroupedFields,
                    mainAssignment,
                  })}
                />
              </div>
            ))) : null
        }
        <p className="subtypes-modal-attention">
          Warning: <b>Multiple templates</b> contain differing defaults.
          These may be overwritten if new values are entered.
        </p>
      </ModalBody>

      <ModalFooter className="justify-content-center">
        <Button onClick={closeModalHandler}>
          {SAVE}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditorSubtypesModal;