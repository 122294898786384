/* eslint-disable no-param-reassign */
import { Dispatch } from 'redux';

import { Editor } from 'slate';

import { PluginsType } from 'types/Editor';

const composePlugins = (plugins: PluginsType[], editor: Editor, dispatch?: Dispatch) => {
  if (dispatch) {
    editor.dispatch = dispatch;
  }
  return plugins.reduceRight((previousValue, currentValue) => (
    currentValue(previousValue)
  ), editor);
};

export default composePlugins;