import { FC, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form, FormGroup } from 'reactstrap';

import Button from 'components/Base/Button';
import InputField from 'components/Base/InputField';
import Intro from 'components/Intro/Intro';
import { NOT_FOUND_CODE } from 'constants/generalErrors';
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from 'constants/validation';
import { checkResetPassword, resetPassword } from 'store/actions/auth';
import { RootStateType } from 'store/reducers';
import { MatchParams } from 'types/Route';
import { isPasswordValidInLength, isSurroundedByEmptySpaces } from 'utils/validation';

type ResetPasswordType = {
  password: '',
  confirmPassword: '',
}

const ResetPassword: FC = () => {
  const dispatch = useDispatch();
  const { id }: MatchParams = useParams();
  const { error } = useSelector((state: RootStateType) => state.errorLoading);
  const [passwordObj, setPasswordObj] = useState<ResetPasswordType>({ password: '', confirmPassword: '' });
  const [passwordError, setPasswordError] = useState<string>('');

  useEffect(() => {
    if (id) {
      dispatch(checkResetPassword(id));
    }
  }, [id]);

  const onResetPasswordHandler = () => {
    setPasswordError('');
    const password = passwordObj.password;
    const confirmPassword = passwordObj.confirmPassword;
    if (!password.trim().length || !confirmPassword.trim().length) {
      setPasswordError('The password field couldn\'t be empty');
      return false;
    }
    if (password !== confirmPassword) {
      setPasswordError('Passwords don\'t match');
      return false;
    }

    if (isSurroundedByEmptySpaces(password) || isSurroundedByEmptySpaces(confirmPassword)) {
      setPasswordError('Your password can\'t start or end with a blank space');
      return false;
    }

    if (!isPasswordValidInLength(password) || !isPasswordValidInLength(confirmPassword)) {
      setPasswordError(
        `Your password has to be more or equal than
        ${PASSWORD_MIN_LENGTH} and less or equal than
        ${PASSWORD_MAX_LENGTH} characters`,
      );
      return false;
    }

    dispatch(resetPassword(id, password));
  };

  const onChandgePasswords = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordObj((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  if (error.status === NOT_FOUND_CODE) {
    return (
      <Intro
        title="Reset password"
        description="Link doesn't exist"
      />
    );
  }

  return (
    <Intro
      title="Reset password"
      description=""
    >
      <Form className="col-12 col-sm-6 col-md-3 mx-auto">
        <FormGroup>
          <InputField
            required
            type="password"
            name="password"
            label="Enter new password"
            value={passwordObj.password}
            onChange={onChandgePasswords}
            showError={Boolean(passwordError)}
          />
        </FormGroup>
        <FormGroup>
          <InputField
            required
            type="password"
            name="confirmPassword"
            label="Please confirm"
            value={passwordObj.confirmPassword}
            onChange={onChandgePasswords}
            showError={Boolean(passwordError)}
            helperText={passwordError}
          />
        </FormGroup>
        <div className="mt-4">
          <Button
            classes="w-100"
            onClick={onResetPasswordHandler}
          >
            Reset password
          </Button>
        </div>
      </Form>
    </Intro>
  );
};

export default ResetPassword;