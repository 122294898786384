import { FC } from 'react';

import MultipleSubtypeField from 'components/Modals/SubtypesModal/MultipleSubtypeField';
import SubtypeField from 'components/Modals/SubtypesModal/SubtypeField';
import { GROUP_FIELDS_MULTIPLE_DEPENDENCIES_ARRAY } from 'constants/editors';
import {
  SubtypeDifferentValuesArrayType,
  SubtypeDifferentValuesType,
} from 'types/Subtypes';
import { titleCaseFromCamelCase } from 'utils/stringsHelpers';

interface IPropertiesListProps {
  properties: {[p: string]: SubtypeDifferentValuesArrayType};
  onChangeProperty: (value: SubtypeDifferentValuesType, propertyName: string) => void;
}

const PropertiesList: FC<IPropertiesListProps> = ({
  properties,
  onChangeProperty,
}) => (
  <>
    {
      Object.entries(properties).map(([propertyName, propertyValues]) => {
        const options = propertyValues.map((value) => String(value));
        const isMultipleSubtype = GROUP_FIELDS_MULTIPLE_DEPENDENCIES_ARRAY.includes(propertyName);
        return (
          <div key={propertyName}>
            <h4>Property: {titleCaseFromCamelCase(propertyName)}</h4>
            {
              isMultipleSubtype
                ? (
                  <MultipleSubtypeField
                    options={propertyValues}
                    name={titleCaseFromCamelCase(propertyName)}
                    onChange={(value: SubtypeDifferentValuesType) => onChangeProperty(value, propertyName)}
                    propertyName={propertyName}
                  />
                )
                : (
                  <SubtypeField
                    options={options}
                    name={titleCaseFromCamelCase(propertyName)}
                    onChange={(value: SubtypeDifferentValuesType) => onChangeProperty(value, propertyName)}
                    propertyName={propertyName}
                  />
                )
            }
          </div>
        );
      })
    }
  </>
);

export default PropertiesList;