import { FC } from 'react';

import { SortableHandle } from 'react-sortable-hoc';

import DragHandleIcon from 'svg/DragHandleIcon';

interface IElementSortButtonProps {
  className?: string;
}

const ElementSortButton: FC<IElementSortButtonProps> = ({ className = '' }) => (
  <span className={className} style={{ cursor: 'grab' }}>
    <DragHandleIcon />
  </span>
);

export default SortableHandle(ElementSortButton);