/* eslint-disable */
import React from 'react';
import { Input } from 'reactstrap';
import { QUESTION_ANSWER_STRING } from 'constants/editors';
import { PDFPropsType } from 'types/PdfTemplates';
import { createNameConstant } from 'utils/createNameConstant';
import { getMainTypeAssignment } from 'utils/assignmentsHelpers';

const QuestionEditable: React.FC<PDFPropsType> = ({
  field,
  isReadOnlyMode,
  onChangeField,
  fieldStyle,
}) => {
  return field.answerType === QUESTION_ANSWER_STRING
    ? (<Input
        autoComplete="off"
        name={createNameConstant(field.fieldName)}
        placeholder={field.fieldName}
        type="text"
        value={field.value}
        onChange={(e) => onChangeField(e, field)}
        disabled={isReadOnlyMode}
        style={fieldStyle}
        className={`pdf-field-${getMainTypeAssignment(field.assignment)}`}
        data-pdf-field
      />)
    : (<Input
      autoComplete="off"
      type="select"
      data-value={field.value || ""}
      name={createNameConstant(field.fieldName)}
      placeholder={field.fieldName}
      onChange={(e) => onChangeField(e, field)}
      style={fieldStyle}
      disabled={isReadOnlyMode}
      value={field.value}
      className={`pdf-field-${getMainTypeAssignment(field.assignment)}`}
      data-pdf-field
    >
      <option value="" />
      {
        field?.options?.map(({ id, label }) => {
          return (
            <option
              key={id}
              value={label}
              data-id={id}
            >
              {label}
            </option>
          )
        })
      }
    </Input>)
};

export default QuestionEditable;