import { useEffect } from 'react';

import { Element as SlateElement } from 'slate';
import { useSlate } from 'slate-react';

import { FIELD_TYPE } from 'constants/editors';
import { filterNodes } from 'utils/editorHelpers';

export const useFormFields = (isPublicPage: boolean = false, viewForms: boolean = false): SlateElement[] => {
  const editor = useSlate();

  useEffect(() => {
    if (isPublicPage && editor.forms !== undefined) return;
    editor.formPage = viewForms;
    editor.publicPage = isPublicPage;
    editor.forms = filterNodes(editor.children, (node) => {
      if (!SlateElement.isElement(node)) return false;
      if (!FIELD_TYPE.includes(node.type)) return false;
      return true;
    });
    editor.sortedForms = [...(editor.forms || [])];
    editor.onChange();
  }, [isPublicPage, viewForms, editor.children]);

  return editor.forms || [];
};

export default undefined;