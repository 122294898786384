import { Editor as SlateEditor, Node as SlateNode, Text as TextNode } from 'slate';

import { MarkFormatType } from 'types/Editor';

export const isMarkActive = (editor: SlateEditor, format: MarkFormatType, size?: string): boolean => {
  const { selection, children } = editor;

  if (selection === null || selection.anchor === null) return false;

  const { anchor } = selection;
  const { path } = anchor;
  if (children.length === 0 || !path) return false;

  const nodeElement = SlateNode.get(editor, path);
  if (!nodeElement || !TextNode.isText(nodeElement)) {
    return false;
  }

  const marks = SlateEditor.marks(editor);
  if (size) {
    return !!marks && !!marks[format] && marks.size === size;
  }

  return !!(marks && marks[format]);
};

export const toggleMark = (editor: SlateEditor, format: MarkFormatType, size?: string) => {
  const isActive = isMarkActive(editor, format, size);

  if (isActive && !size) {
    SlateEditor.removeMark(editor, format);
  } else if (isActive && size) {
    SlateEditor.removeMark(editor, format);
    SlateEditor.removeMark(editor, 'size');
  } else if (size) {
    SlateEditor.addMark(editor, format, true);
    SlateEditor.addMark(editor, 'size', size);
  } else {
    SlateEditor.addMark(editor, format, true);
  }
};