/* eslint-disable */
import { Element as SlateElement, Transforms, NodeEntry, Editor } from 'slate';

export const removeRow = (table: NodeEntry, editor: Editor) => {
  const { selection } = editor;
  if (!selection || !table) return;

  let [rows] = Array.from(
    Editor.nodes(editor, {
      match: n => SlateElement.isElement(n) && n.type === 'table-row',
    })
  );

  if (rows) {
    const currentRow: any = rows[0];

    if (currentRow) {
      Transforms.removeNodes(editor, {
        at: table[1],
        match: n => {
          if (SlateElement.isElement(n) && n.type === 'table-row' && n.key === currentRow.key) {
            return true;
          }
          return false;
        },
      });
    }
  }

  [rows] = Array.from(
    Editor.nodes(editor, {
      match: n => SlateElement.isElement(n) && n.type === 'table-row',
      at: table[1],
    })
  );

  if(!rows) {
    Transforms.removeNodes(editor, {
      at: table[1],
    });
  }

}