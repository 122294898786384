import { FC } from 'react';

interface IFieldWithButtonWrapperProps {
  withButton: boolean;
}

const FieldWithButtonWrapper: FC<IFieldWithButtonWrapperProps> = ({
  withButton,
  children,
}) => {
  if (withButton) {
    return (
      <div className="d-flex">
        {children}
      </div>
    );
  }

  return (<> {children}</>);
};

export default FieldWithButtonWrapper;