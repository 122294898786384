/* eslint-disable */
import { FC, ChangeEventHandler } from 'react';
import { useSelector } from 'react-redux';
import { Alert } from 'reactstrap';
import { RootStateType } from 'store/reducers';
import { IDoctype, IMetadataId } from 'types/Metadata';

export interface ISelectDoctypeProps {
  doctypes?: IDoctype[];
  valueSelectDoctype?: string;
  selectedDoctype?: null | IMetadataId;
  onChangeDoctype: ChangeEventHandler<HTMLSelectElement>;
  readOnlyMode?: boolean;
}

const SelectDoctype: FC<ISelectDoctypeProps> = (props) => {
  const {
    doctypes,
    selectedDoctype,
    valueSelectDoctype,
    onChangeDoctype,
    readOnlyMode = false,
  } = props;

  const storeDoctypes = useSelector((state: RootStateType) => state.user.doctypes);

  return (
    <>
      {
        selectedDoctype && doctypes ? (
        <>
          <p className="label me-2 mb-1 d-inline-block">Document type:</p>
          <select
            className="w-auto d-inline-block me-2 custom-select"
            value={valueSelectDoctype}
            onChange={onChangeDoctype}
            disabled={readOnlyMode}
          >
            {
              doctypes.map((doctype) => (
                <option
                  key={doctype.id}
                  value={doctype.name}
                  id={doctype.id.toString()}
                >
                  {doctype.name}
                </option>
              ))
            }
          </select>
        </>
        ) : !readOnlyMode && !storeDoctypes?.length && (
          <Alert color="secondary" className="me-2">
            It is impossible to create templates and documents without doctypes. Please, contact the site administrator.
          </Alert>
        )
      }
    </>
  );
};

export default SelectDoctype;