import {
  DELETE_COMPANY_LOGO,
  GET_COMPANY_BRANDING,
  SET_COMPANY_BRANDING,
  SET_COMPANY_LOGO,
  UPDATE_COMPANY_STYLE_SCHEMA,
  UPLOAD_COMPANY_LOGO,
} from 'store/actions/actionTypes';
import { StyleSchemaType } from 'types/BrandingColorSchema';
import { ICompanyBrandingResponse, ICompanyLogoResponse } from 'types/CompanyBranding';

export const getCompanyBranding = () => ({
  type: GET_COMPANY_BRANDING,
});

export const setCompanyBranding = (data: ICompanyBrandingResponse) => ({
  type: SET_COMPANY_BRANDING,
  payload: {
    logoPath: data.logo_path,
    styleJSON: data.style_json,
  },
});

export const updateCompanyColorSchema = (schema: StyleSchemaType) => ({
  type: UPDATE_COMPANY_STYLE_SCHEMA,
  payload: {
    style_json: schema,
  },
});

export const uploadCompanyLogo = (file: File) => ({
  type: UPLOAD_COMPANY_LOGO,
  payload: { file },
});

export const setCompanyLogo = (data: ICompanyLogoResponse) => ({
  type: SET_COMPANY_LOGO,
  payload: {
    logoPath: data.logo_path,
  },
});

export const deleteCompanyLogo = () => ({
  type: DELETE_COMPANY_LOGO,
});