import { ChangeEventHandler, FC, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import AccountSettings from 'components/AccountSettings/AccountSettings';
import AccountSettingsWrapper from 'components/AccountSettings/AccountSettingsWrapper';
import { PROFILE_SECTIONS } from 'constants/UserMenu';
import { CHANGE_ORGANIZATION_TITLES } from 'constants/validation';
import { useRolePermission } from 'hooks/useRolePermission';
import { getUserCompanyData, updateUserCompanyData } from 'store/actions/userProfile';
import { RootStateType } from 'store/reducers';
import { ISettingsField, OrganizationInfoType } from 'types/AccountSettings';
import { IErrorField } from 'types/validation';
import { createArrayForValidation, validationForm } from 'utils/validation';

const Organization: FC = () => {
  const { banChangeOrganization } = useRolePermission();
  const dispatch = useDispatch();
  const companyData = useSelector((state: RootStateType) => state.profile.companyData);
  const states = useSelector((state: RootStateType) => state.user.states);
  const { data: errorsData } = useSelector((state: RootStateType) => state.errorLoading?.error);

  const initialFormErrors = {
    name: null,
    address: null,
    city: null,
    zip: null,
  };

  const [formErrors, setFormErrors] = useState<IErrorField>(initialFormErrors);
  const [organizationState, setOrganizationState] = useState<string>('');
  const [organizationInfo, setOrganizationInfo] = useState<OrganizationInfoType>({
    name: '',
    address: '',
    city: '',
    zip: '',
  });

  useEffect(() => {
    if (errorsData?.errors && errorsData.errors.company_name) {
      setFormErrors((prevState) => ({
        ...prevState,
        name: errorsData.errors.company_name,
      }));
    }
  }, [errorsData?.errors]);

  const setDefaultOrganizationInfo = () => {
    setOrganizationInfo({
      name: companyData.name,
      address: companyData.address,
      city: companyData.city,
      zip: companyData.zip,
    });
    setOrganizationState(companyData.state?.id.toString());
  };

  useEffect(() => {
    if (!companyData) {
      dispatch(getUserCompanyData());
    } else {
      setDefaultOrganizationInfo();
    }
  }, [companyData]);

  const validateForm = (organizationInfo: OrganizationInfoType) => {
    const arrayForValidation = createArrayForValidation(organizationInfo, CHANGE_ORGANIZATION_TITLES);
    const validateResult = validationForm(arrayForValidation);
    setFormErrors(validateResult.validationFields);
    return validateResult;
  };

  const handleChangeData: ChangeEventHandler<HTMLInputElement> = (event) => {
    const targetName = event.target.name;
    const updatedOrganizationInfoValue = { ...organizationInfo, [targetName]: event.target?.value };
    setOrganizationInfo(updatedOrganizationInfoValue);
    validateForm(updatedOrganizationInfoValue);
  };

  const handleSelectData: ChangeEventHandler<HTMLSelectElement> = (event) => {
    setOrganizationState(event.target.selectedOptions[0].id);
  };

  const updateCompanyData = () => {
    const formValidate = validateForm(organizationInfo);
    if (formValidate.isError) {
      return false;
    }

    const trimmedOrganizationInfo = Object.entries(organizationInfo).reduce((accumulator, [key, value]) => {
      if (typeof value === 'string') {
        return {
          ...accumulator,
          [key]: value.trim(),
        };
      }
      return {
        ...accumulator,
        [key]: value,
      };
    }, {});

    const updatedCompany = { ...trimmedOrganizationInfo, state_id: organizationState || null };
    dispatch(updateUserCompanyData(updatedCompany));
    return true;
  };

  const resetCompanyData = () => {
    setDefaultOrganizationInfo();
    setFormErrors(initialFormErrors);
  };

  const organizationData: ISettingsField[] = [
    {
      label: CHANGE_ORGANIZATION_TITLES.name,
      value: organizationInfo.name,
      name: 'name',
      fieldError: formErrors.name,
    },
    {
      label: CHANGE_ORGANIZATION_TITLES.address,
      value: organizationInfo.address,
      name: 'address',
      fieldError: formErrors.address,
    },
    {
      label: CHANGE_ORGANIZATION_TITLES.city,
      value: organizationInfo.city,
      name: 'city',
      fieldError: formErrors.city,
    },
    {
      label: CHANGE_ORGANIZATION_TITLES.zip,
      value: organizationInfo.zip,
      name: 'zip',
      fieldError: formErrors.zip,
    },
    {
      label: 'State',
      value: (states?.length && states.find((state: any) => state?.id?.toString() === organizationState)?.name)
        || companyData?.state_id === null,
      name: 'state',
      options: states.map((state: any) => ({
        id: state.id.toString(),
        name: state.name,
      })),
    },
  ];

  return (
    <AccountSettingsWrapper>
      <AccountSettings
        fields={organizationData}
        title={PROFILE_SECTIONS.ORGANIZATION}
        canEditFields={!banChangeOrganization}
        isEditable={errorsData?.errors?.company_name}
        updateClickHandler={updateCompanyData}
        cancelClickHandler={resetCompanyData}
        handleChangeData={(event) => handleChangeData(event)}
        handleSelectData={(event) => handleSelectData(event)}
      />
    </AccountSettingsWrapper>
  );
};

export default Organization;