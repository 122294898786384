import { TECHNICAL_COLUMNS } from 'constants/mondayComIntegration';
import { SelectFieldOptionType } from 'types/Editor';
import { MondayComColumnType, MondayComIntegrationObjectType } from 'types/mondayCom/mondayComBoard';
import { FieldTypeOption } from 'types/PdfTemplates';

export const filterUsedColumns = (currentBoardObject: MondayComIntegrationObjectType | null) => {
  if (currentBoardObject) {
    return currentBoardObject.board.columns.filter(
      (column) => !Object.values(currentBoardObject.fieldsMapping ?? []).map((item) => item.id).includes(column.id),
    );
  }
  return null;
};

export const excludeNotImplementedColumns = (columns: MondayComColumnType[]) => (
  columns.filter((column) => !TECHNICAL_COLUMNS.includes(column.id) && !column.id.startsWith('file'))
);

export const createOptionsArrayFromStatusColumn = (
  settingsStr: string,
): SelectFieldOptionType[] | FieldTypeOption[] => {
  const labelsFromColumn: Record<string, string> = JSON.parse(settingsStr).labels;
  return Object.values(labelsFromColumn).map((label, index) => ({
    id: Date.now() + index, label,
  }));
};