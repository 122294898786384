import { FONT_TYPE, INDENT_STEP_MARGIN, MAX_INDENT_STEP_MARGIN } from 'constants/editors';
import { filterFontTypeByAvailable } from 'utils/reduceFontType';

const PICA_IN_PIXELS = 16;
const POINT_IN_PIXELS = 1.3;
const INCH_IN_PIXELS = 96;
const CENTIMETER_IN_PIXELS = 38;
const MILLIMETER_IN_PIXELS = 4;

const calculateMarginsInPixels = (value: string): number => {
  let valueInPixels = Math.round(parseFloat(value) * 10) / 10;
  switch (true) {
    case value.includes('px'):
      break;
    case value.includes('pt'):
      valueInPixels *= POINT_IN_PIXELS;
      break;
    case value.includes('p'):
    case value.includes('em'):
      valueInPixels *= PICA_IN_PIXELS;
      break;
    case value.includes('in'):
      valueInPixels *= INCH_IN_PIXELS;
      break;
    case value.includes('cm'):
      valueInPixels *= CENTIMETER_IN_PIXELS;
      break;
    case value.includes('mm'):
      valueInPixels *= MILLIMETER_IN_PIXELS;
      break;
    default:
      break;
  }
  const partInPoints = Math.round(valueInPixels / INDENT_STEP_MARGIN);
  return Math.min(partInPoints * INDENT_STEP_MARGIN, MAX_INDENT_STEP_MARGIN) || 0;
};

export const setEditorNodeStyles = (styles: CSSStyleDeclaration): object => {
  if (!styles) {
    return {};
  }
  const marginLeft = (parseInt(styles.marginLeft || '') > 0)
    ? styles.marginLeft
    : styles.paddingLeft || '';

  const marginLeftInPixels = calculateMarginsInPixels(marginLeft);
  const textIndentInPixels = calculateMarginsInPixels(styles.textIndent);

  return {
    textAlign: `text-${(styles.textAlign !== 'start' && styles.textAlign) || 'left'}`,
    margin: marginLeftInPixels,
    textIndent: textIndentInPixels,
  };
};

export const setEditorNodeFont = (styles: CSSStyleDeclaration): object => {
  if (!styles) return {};

  const currentFontType = filterFontTypeByAvailable(styles.fontFamily);
  if (!currentFontType.length) return {};

  return { [FONT_TYPE]: currentFontType };
};

export default setEditorNodeStyles;