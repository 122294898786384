import { ChangeEvent, FC } from 'react';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import InputField from 'components/Base/InputField';
import { MANAGER_ASSIGNMENT, RECIPIENT_ASSIGNMENT } from 'constants/editors';
import { AssignmentsDropdownLabels, AssignmentType, FieldAssignmetsType } from 'types/Editor';
import { isManagerAssignment } from 'utils/assignmentsHelpers';

interface IAssignmentsSettingsRow {
  assignment: AssignmentType;
  changeAssignmentLabelHandler: (event: ChangeEvent<HTMLInputElement>, assignmentType: string) => void;
  changeAssignmentTypeHandler: (assignmentLabel: AssignmentsDropdownLabels | null, assignment: AssignmentType) => void;
  deleteAssignmentHandler: (assignment: AssignmentType) => void;
}

const AssignmentsSettingsRow: FC<IAssignmentsSettingsRow> = ({
  assignment,
  changeAssignmentLabelHandler,
  changeAssignmentTypeHandler,
  deleteAssignmentHandler,
}) => (
  <div className="d-flex mb-3">
    <div className="w-50 me-3">
      <InputField
        enableLabelShrink
        required
        value={assignment.label}
        onChange={(event) => changeAssignmentLabelHandler(event, assignment.type)}
      />
      {
        !assignment.label && (
          <div className="account-settings__error m-0">
            <ErrorOutlineOutlinedIcon color="error" />
            <span className="ms-2">Assignment label is required.</span>
          </div>
        )
      }
    </div>
    <div className="d-flex w-50">
      <div style={{ width: '90%' }}>
        <Autocomplete
          disabled={[RECIPIENT_ASSIGNMENT, MANAGER_ASSIGNMENT].includes(assignment.type as FieldAssignmetsType)}
          size="small"
          disablePortal
          blurOnSelect
          clearOnEscape
          options={[AssignmentsDropdownLabels.INTERNAL_USER, AssignmentsDropdownLabels.EXTERNAL_RECIPIENT]}
          isOptionEqualToValue={(option, value) => (option === value as string)}
          value={
            isManagerAssignment(assignment.type)
              ? AssignmentsDropdownLabels.INTERNAL_USER
              : AssignmentsDropdownLabels.EXTERNAL_RECIPIENT
          }
          onChange={(_, value) => changeAssignmentTypeHandler(value, assignment)}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              onChange={() => null}
              label=""
            />
          )}
        />
      </div>
      {
        ![RECIPIENT_ASSIGNMENT, MANAGER_ASSIGNMENT].includes(assignment.type as FieldAssignmetsType) && (
          <div style={{ width: '10%' }}>
            <IconButton
              color="primary"
              onClick={() => deleteAssignmentHandler(assignment)}
              disabled={false}
            >
              <RemoveCircleIcon />
            </IconButton>
          </div>
        )
      }
    </div>
  </div>
);

export default AssignmentsSettingsRow;