import { ChangeEvent, ChangeEventHandler, FC, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import AccountSettings from 'components/AccountSettings/AccountSettings';
import AccountSettingsWrapper from 'components/AccountSettings/AccountSettingsWrapper';
import DefaultSignatureSection from 'components/AccountSettings/DefaultSignatureSection';
import { PROFILE_SECTIONS } from 'constants/UserMenu';
import { CHANGE_PROFILE_TITLES, PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from 'constants/validation';
import {
  changeUserPassword,
  updateUserProfileData,
} from 'store/actions/userProfile';
import { RootStateType } from 'store/reducers';
import { ISettingsField, ProfileInfoType } from 'types/AccountSettings';
import { IErrorField } from 'types/validation';
import {
  createArrayForValidation,
  isPasswordValidInLength,
  isSurroundedByEmptySpaces,
  validationForm,
} from 'utils/validation';

const Profile: FC = () => {
  const dispatch = useDispatch();
  const profileInfo = useSelector((state: RootStateType) => state.profile.profileInfo);
  const errorLoading = useSelector((state: RootStateType) => state.errorLoading);
  const initialProfileFormErrors = {
    first_name: null,
    last_name: null,
    email: null,
  };

  const [formErrors, setFormErrors] = useState<IErrorField>(initialProfileFormErrors);
  const [profileFieldValues, setProfileFieldValues] = useState<ProfileInfoType>({
    username: '',
    first_name: '',
    last_name: '',
    email: '',
  });
  const [password, setPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [isProfileEditable, setIsProfileEditable] = useState<boolean>(false);
  const [isPasswordEditable, setIsPasswordEditable] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const profileData: ISettingsField[] = [
    {
      name: 'username',
      label: 'Username',
      value: profileFieldValues.username,
      fieldError: formErrors.username,
      disabled: true,
    },
    {
      name: 'first_name',
      label: CHANGE_PROFILE_TITLES.first_name,
      value: profileFieldValues.first_name,
      fieldError: formErrors.first_name,
    },
    {
      name: 'last_name',
      label: CHANGE_PROFILE_TITLES.last_name,
      value: profileFieldValues.last_name,
      fieldError: formErrors.last_name,
    },
    {
      name: 'email',
      label: CHANGE_PROFILE_TITLES.email,
      value: profileFieldValues.email,
      fieldError: formErrors.email,
    },
  ];
  const passwordData: ISettingsField[] = [
    {
      type: 'password',
      label: 'Current Password',
      value: password,
      name: 'current_password',
      onChangeHandler: (e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value),
    },
    {
      type: 'password',
      label: 'New Password',
      value: newPassword,
      name: 'new_password',
      onChangeHandler: (e: ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value),
      hiddenForRead: true,
    },
    {
      type: 'password',
      label: 'Confirm Password',
      value: confirmPassword,
      name: 'confirm_password',
      onChangeHandler: (e: ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value),
      hiddenForRead: true,
    },
  ];

  const setDefaultProfileFieldValues = () => {
    setProfileFieldValues({
      username: profileInfo.username,
      first_name: profileInfo.first_name,
      last_name: profileInfo.last_name,
      email: profileInfo.email,
    });
  };

  useEffect(() => {
    if (profileInfo) {
      setDefaultProfileFieldValues();
    }
  }, [profileInfo, setProfileFieldValues]);

  useEffect(() => {
    if (errorLoading.error?.data) {
      const errorsData = errorLoading.error.data;
      if (errorsData.message) {
        setError(errorsData.message);
        setIsPasswordEditable(true);
      } else {
        setFormErrors((prevState) => ({
          ...prevState,
          ...errorsData.errors,
        }));
        setIsProfileEditable(true);
        setProfileFieldValues((prevState) => ({
          ...prevState,
          email: profileInfo.email,
        }));
      }
    }
  }, [errorLoading.error?.data]);

  const validateForm = (profileFieldValues: ProfileInfoType) => {
    const arrayForValidation = createArrayForValidation(profileFieldValues, CHANGE_PROFILE_TITLES);
    const validateResult = validationForm(arrayForValidation);
    setFormErrors(validateResult.validationFields);
    return validateResult;
  };

  const handleChangeProfileData: ChangeEventHandler<HTMLInputElement> = (event) => {
    const targetName = event.target.name;
    const updatedProfileFieldsValue = { ...profileFieldValues, [targetName]: event.target?.value };
    setProfileFieldValues(updatedProfileFieldsValue);
    validateForm(updatedProfileFieldsValue);
  };

  const handlerProfileSubmit = () => {
    const formValidate = validateForm(profileFieldValues);
    if (formValidate.isError) {
      return false;
    }
    const editedProfile = {
      first_name: profileFieldValues.first_name.trim(),
      last_name: profileFieldValues.last_name.trim(),
      email: profileFieldValues.email.trim(),
    };
    dispatch(updateUserProfileData(editedProfile));
    return true;
  };

  const resetProfileFieldValues = () => {
    setDefaultProfileFieldValues();
    setFormErrors(initialProfileFormErrors);
  };

  const resetChangePassword = () => {
    setPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setError('');
  };

  const changePassword = () => {
    setError('');
    if (!password.trim().length
      || !newPassword.trim().length
      || !confirmPassword.trim().length
    ) {
      setError('The password field couldn\'t be empty');
      return false;
    }
    if (newPassword !== confirmPassword) {
      setError('Passwords don\'t match');
      return false;
    }

    if (isSurroundedByEmptySpaces(newPassword) || isSurroundedByEmptySpaces(confirmPassword)) {
      setError('Your password can\'t start or end with a blank space');
      return false;
    }

    if (!isPasswordValidInLength(newPassword) || !isPasswordValidInLength(confirmPassword)
    ) {
      setError(
        `Your password has to be more or equal than
        ${PASSWORD_MIN_LENGTH} and less or equal than
        ${PASSWORD_MAX_LENGTH} characters`,
      );
      return false;
    }

    dispatch(changeUserPassword({ password, new_password: newPassword }));
    resetChangePassword();
    return true;
  };

  return (
    <AccountSettingsWrapper>
      <AccountSettings
        isEditable={isProfileEditable}
        fields={profileData}
        title={PROFILE_SECTIONS.PROFILE}
        updateClickHandler={handlerProfileSubmit}
        cancelClickHandler={resetProfileFieldValues}
        handleChangeData={handleChangeProfileData}
      />
      <AccountSettings
        isEditable={isPasswordEditable}
        error={error}
        fields={passwordData}
        title={PROFILE_SECTIONS.CHANGE_PASSWORD}
        updateClickHandler={changePassword}
        cancelClickHandler={resetChangePassword}
      />
      <DefaultSignatureSection />
    </AccountSettingsWrapper>
  );
};

export default Profile;
