/* eslint-disable */
import React, { SyntheticEvent } from "react";
import { useSlate } from "slate-react";
import { isMarkActive, toggleMark } from "utils/editorMarkHelpers";
import { IMarkButtonProps } from "types/Editor";

const MarkButton: React.FC<IMarkButtonProps> = (props) => {

  const {
    key,
    format,
    title,
    icon,
    size,
    horizontalToolbar=false,
  } = props;

  const editor = useSlate();

  const active: boolean = isMarkActive(editor, format, size);

  const onMouseDownButton = (event: SyntheticEvent) => {
    event.preventDefault();
    if(size) return toggleMark(editor, format, size);
    toggleMark(editor, format);
  }

  return (
    <>
      {
        horizontalToolbar ? (
          <span
            className={`btn-toolbar-editor ${active ? "text-dark" : ""}`}
            style={{ fill: active ? "black" : "#ccc" }}
            onMouseDown={onMouseDownButton}
          >
            {icon}
          </span>
        ) : (
          <li
            key={key}
            className={`item-toolbar-editor ${active && "text-dark"}`}
            style={{ fill: active ? "#343a40" : "#ccc" }}
            onMouseDown={onMouseDownButton}
          >
            <div className="d-flex" >
              <div className="icon">{icon}</div>
              <div>
                <div className="text-dark">{title}</div>
                <div className="command-description text-muted">Shortcut 4 + &#8984; or type - + space</div>
              </div>
            </div>
          </li>
        )
      }
    </>
  );
};

export default MarkButton;