import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { SEND_EMAIL_IN_MONDAY_COM_MULTI_APPLICATIONS_FLOW } from 'store/actions/actionTypes';
import { setShowSimilarRecordsExistModalWindow } from 'store/actions/mondayComIntegration';
import { RootStateType } from 'store/reducers';

const useMondayComPublicPageModalSimilarRecordsExist = () => {
  const dispatch = useDispatch();
  const { showMondayComSimilarRecordsExistModalWindow, data } = useSelector(
    (state: RootStateType) => state.publicPages,
  );
  const waitToPasswordlessLinkResponse = useSelector(
    (state: RootStateType) => state.mondayCom.waitToPasswordlessLinkResponse,
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isPasswordlessLinkSent, setIsPasswordlessLinkSent] = useState<boolean>(false);

  useEffect(() => {
    if (
      showMondayComSimilarRecordsExistModalWindow
      && (data.monday_com_subitem || data.monday_com_item_document_file)
    ) {
      setIsModalOpen(showMondayComSimilarRecordsExistModalWindow);
    }
  }, [showMondayComSimilarRecordsExistModalWindow, data]);

  const closeAndSendEmailWithPasswordlessLink = () => {
    dispatch({ type: SEND_EMAIL_IN_MONDAY_COM_MULTI_APPLICATIONS_FLOW });
    setIsPasswordlessLinkSent(true);
    dispatch(setShowSimilarRecordsExistModalWindow(false));
  };

  return {
    waitToPasswordlessLinkResponse,
    isModalSimilarRecordsOpen: isModalOpen,
    isPasswordlessLinkSent,
    closeAndSendEmailWithPasswordlessLink,
  };
};

export default useMondayComPublicPageModalSimilarRecordsExist;