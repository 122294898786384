import { FC, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { Descendant } from 'slate';

import DocumentPreviewEditor from 'components/DocumentPreviewEditor';
import SyncLoader from 'components/SyncLoader';
import { STATUSES } from 'constants/documentStatuses';
import { DEFAULT_SECTION, INITIAL_EDITOR_VALUE } from 'constants/editors';
import { useRolePermission } from 'hooks/useRolePermission';
import {
  clearPdfTemplateData,
  setAllPdfFieldsAndInfo,
  setPdfTemplateFileLink,
  setPdfTemplateWidth,
} from 'store/actions/pdfTemplates';
import { updateTemplateSections } from 'store/actions/template';
import { setDocumentDetails } from 'store/actions/userData';
import { IDocumentDetails } from 'types/Documents';
import { ITemplateSection } from 'types/redux';
import { getPdfUrlAndWidth } from 'utils/pdfTemplatesHelpers';
import { getIsFormDocument, getIsPDFDocument } from 'utils/PublicPage/documentTypeChecker';

interface ICompleteDocumentModal {
  showModal: boolean;
  documentId: string;
  onCloseModal: () => void;
  onChangeDocumentStatus: (itemId: string, status: string, completeFlow: boolean) => void;
  documentDetails: IDocumentDetails | null;
}

const CompleteDocumentModal: FC<ICompleteDocumentModal> = ({
  showModal,
  documentId,
  onCloseModal,
  onChangeDocumentStatus,
  documentDetails,
}) => {
  const dispatch = useDispatch();
  const { permissionUsingSectionsLight } = useRolePermission();
  const [documentContent, setDocumentContent] = useState<Descendant[]>(INITIAL_EDITOR_VALUE);

  useEffect(() => {
    if (!documentDetails) return;
    if (getIsPDFDocument(documentDetails.type)) {
      const [, pdfFileWidth] = getPdfUrlAndWidth(String(documentDetails.template?.pdf_file_url));
      dispatch(setPdfTemplateWidth(parseInt(pdfFileWidth)));

      const pdfFieldsAndInfo = {
        fields: documentDetails.content_json,
        info: {
          templateName: documentDetails.name,
          templateTitle: documentDetails.document_title,
          templateDescription: documentDetails.description,
        },
      };
      dispatch(setAllPdfFieldsAndInfo(pdfFieldsAndInfo));
      if (documentDetails.pdf_full_link) {
        dispatch(setPdfTemplateFileLink(documentDetails.pdf_full_link));
      }
    } else {
      setDocumentContent(documentDetails.content_json as Descendant[]);
      if (getIsFormDocument(documentDetails.type)) {
        const newSections = (documentDetails.content_json as Descendant[])?.map(
          (section: Partial<ITemplateSection>, index: number) => ({
            name: section.name,
            description: section.description,
            key: section.key,
            position: section.position || index,
          }),
        );
        dispatch(updateTemplateSections(newSections as ITemplateSection[] || []));
      }
    }

    return () => {
      dispatch(setDocumentDetails(null));
      dispatch(clearPdfTemplateData());
      if (permissionUsingSectionsLight) {
        dispatch(updateTemplateSections([DEFAULT_SECTION]));
      }
    };
  }, [documentDetails, dispatch, permissionUsingSectionsLight]);

  const handlerCompleteButton = () => {
    onChangeDocumentStatus(documentId, STATUSES.completed, true);
    onCloseModal();
  };

  return (
    <Modal
      isOpen={showModal}
      className="complete-document-modal"
    >
      <ModalHeader toggle={onCloseModal}>
        Verify your document before completion
      </ModalHeader>
      <ModalBody className="complete-document-modal-body">
        {
          documentDetails
            ? (
              <DocumentPreviewEditor
                type={documentDetails.type}
                content={documentContent}
                onChange={setDocumentContent}
              />
            )
            : <SyncLoader small />
        }
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          className="mr-1"
          onClick={handlerCompleteButton}
        >
          Complete
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CompleteDocumentModal;