import { FC, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import SLFormView from 'components/Editor/FormView/SLFormView';
import { changeFieldInFocusStatus } from 'store/actions/editorSlate';
import { RootStateType } from 'store/reducers';
import FocusIcon from 'svg/FocusIcon';
import { IPublicPageState } from 'types/PublicPage';
import { isDocumentType, isTemplateType } from 'utils/typeChecker';

interface IPublicFormView {
  isReadOnly: boolean;
  isFormsView: boolean;
}

const PublicFormView: FC<IPublicFormView> = ({
  isReadOnly,
  isFormsView,
}) => {
  const dispatch = useDispatch();
  const { data }: IPublicPageState = useSelector((state: RootStateType) => state.publicPages);
  const fieldInFocus = useSelector((state: RootStateType) => state.editorSlate.fieldInFocus);

  const [title, setTitle] = useState('');

  useEffect(() => {
    if (isTemplateType(data)) {
      setTitle(data.template_title);
    } else if (isDocumentType(data)) {
      setTitle(data.document_title);
    }
  }, [data]);

  useEffect(() => () => {
    dispatch(changeFieldInFocusStatus(false));
  }, []);

  return (
    <>
      {
        fieldInFocus
        && (
          <button
            className="focus-icon-button"
            type="button"
            onClick={() => dispatch(changeFieldInFocusStatus(false))}
          >
            <FocusIcon />
          </button>
        )
      }
      <SLFormView
        name={data?.name || ''}
        title={title}
        description={data?.description || ''}
        readOnlyMode={isReadOnly}
        showDescription
        isPublicPage
        isFormsView={isFormsView}
      />
    </>
  );
};

export default PublicFormView;