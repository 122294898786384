import { FC, MouseEvent, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import LensIcon from '@mui/icons-material/Lens';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { popoverClasses } from '@mui/material/Popover';
import moment from 'moment';

import SyncLoader from 'components/SyncLoader';
import { SUCCESS_CODE } from 'constants/generalErrors';
import { getNotificationList, setSeenNotificationList } from 'services/api/userNotifications';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { getNotificationCount, setNotificationCount } from 'store/actions/userData';
import { RootStateType } from 'store/reducers';
import { IDocumentDetails } from 'types/Documents';
import { getEditorPageLink } from 'utils/routeHelpers';

import 'scss/components/_notificationDropDown.scss';

type UserNotificationType = {
  document: Partial<IDocumentDetails>;
  event_template: string;
  id: number;
  mark_seen: boolean;
  user_id: number;
  creation_date: string;
}

const NotificationDropDown: FC = () => {
  const dispatch = useDispatch();
  const notificationsCount = useSelector((state: RootStateType) => state.user.notificationsCount);

  const [loadingEffect, setLoadingEffect] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [notificationList, setNotificationList] = useState<UserNotificationType[]>([]);
  const isOpened = Boolean(anchorEl);
  const menuDropDownID = 'notifications-drop-down';

  const getTransformedNotificationText = (text: string, isUnread: boolean) => {
    const firstTextPart = text.split('-')[0];
    return (
      <>
        <strong>
          {isUnread && <LensIcon className="unread-message-dot" />}
          {firstTextPart}
        </strong>
        {text.replace(firstTextPart, '')}
      </>
    );
  };

  const getNotificationData = async (target: EventTarget & HTMLElement | null = null) => {
    if (target) {
      setLoadingEffect(true);
      setAnchorEl(target);
    }
    const result = await apiErrorHandler(getNotificationList, { returnErrorResult: true });

    const notificationsToShow: UserNotificationType[] = result?.data.filter(
      (notification: UserNotificationType) => (
        !(moment(Date.now()).diff(moment(notification.creation_date), 'days') > 60)),
    );

    setNotificationList(notificationsToShow);
    if (target) {
      setLoadingEffect(false);
    }
    return notificationsToShow;
  };

  const setSeenNotificationData = async (notificationIds: number[]) => {
    const result = await apiErrorHandler(setSeenNotificationList, { ids: notificationIds, returnErrorResult: true });
    if (result?.status === SUCCESS_CODE) {
      dispatch(setNotificationCount(0));
    }
  };

  const openNotificationListHandler = async (event: MouseEvent<HTMLElement> | null = null) => {
    const notifications = await getNotificationData(event?.currentTarget || null);
    const filteredNotifications = event
      ? notifications
      : notifications.filter((element: UserNotificationType) => !element.mark_seen);

    const notificationIds = filteredNotifications.map((element: UserNotificationType) => element.id);
    setTimeout(() => {
      setSeenNotificationData(notificationIds);
      const readedNotifications = filteredNotifications.map(
        (notification: UserNotificationType) => (
          { ...notification, mark_seen: true }),
      );
      setNotificationList(readedNotifications);
    }, 5000); // 5 seconds
  };

  const closeNotificationListHandler = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const interval: NodeJS.Timeout = setInterval(() => {
      dispatch(getNotificationCount());
    }, 60000); // 1 minute

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (notificationsCount && isOpened) {
      openNotificationListHandler();
    }
  }, [notificationsCount]);

  return (
    <div className="position-relative">
      <div className="mx-3 notification-icon-wrapper">
        <IconButton
          className="notification-icon text-white cursor-pointer menu-icon"
          onClick={openNotificationListHandler}
          size="small"
          aria-controls={isOpened ? menuDropDownID : undefined}
          aria-haspopup="true"
          aria-expanded={isOpened ? 'true' : undefined}
        >
          <NotificationsIcon />
          {
            notificationsCount
              ? (
                <span className="notification-counter">{notificationsCount}</span>
              )
              : null
          }
        </IconButton>
      </div>
      <Menu
        anchorEl={anchorEl}
        id={menuDropDownID}
        onClose={closeNotificationListHandler}
        onClick={closeNotificationListHandler}
        open={isOpened}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        sx={{
          [`& .${popoverClasses.paper}`]: {
            maxHeight: '300px',
            minWidth: '300px',
            overflowY: 'auto',
          },
        }}
        className="notification-list"
      >
        {
          (loadingEffect || !notificationList.length)
            ? (
              <MenuItem
                key="NoNotificationItems"
                className="notification-list-item empty-item"
                disableGutters
              >
                {loadingEffect && <div className="margin-auto"><SyncLoader small /></div>}
                {!notificationList.length && !loadingEffect && 'No unseen notifications'}
              </MenuItem>
            )
            : null
        }
        {
          !loadingEffect && notificationList.map((notificationItem) => (
            <MenuItem
              key={`NotificationItem_${notificationItem.id}`}
              className="notification-list-item"
              onClick={closeNotificationListHandler}
              disableGutters
            >
              <Link
                to={getEditorPageLink(String(notificationItem.document.id), notificationItem.document.type, true)}
                className="menu-link"
              >
                {getTransformedNotificationText(notificationItem.event_template, !notificationItem.mark_seen)}
              </Link>
            </MenuItem>
          ))
        }
      </Menu>
    </div>
  );
};

export default NotificationDropDown;