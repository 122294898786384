import { FC, useState } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PersonIcon from '@mui/icons-material/Person';
import { Menu, MenuItem } from '@mui/material';

import ConfirmationModal from 'components/Modals/ConfirmationModal';
import { URL_ZEN_DESK_HELP } from 'constants/general';
import ROUTES from 'constants/routes';
import USER_MENU_CONFIG, { PROFILE_SECTIONS } from 'constants/UserMenu';
import useHelpMenuButtonModalWindow from 'hooks/useHelpMenuButtonModalWindow';
import { useRolePermission } from 'hooks/useRolePermission';
import { RootStateType } from 'store/reducers';

const UserMenu: FC<{ logout: any }> = ({ logout }) => {
  const {
    permissionUsingGroupManagement,
    isUserReadOnly,
    permissionUsingCompanyBranding,
  } = useRolePermission();
  const userRole = useSelector((state: RootStateType) => state.profile.profileInfo?.userRole);
  const [anchorEl, setAnchorEl] = useState(null);

  const {
    isHelpButtonModalWindowOpen,
    onOpenHelpButtonHandler,
    onConfirmHelpButtonHandler,
    onCancelHelpButtonHandler,
  } = useHelpMenuButtonModalWindow(setAnchorEl);

  const handleOpenMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenuClick = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        id="accountIcon"
        aria-controls="userMenu"
        aria-haspopup="true"
        aria-expanded="false"
        className="d-flex m-0 justify-content-center align-items-center"
        onClick={handleOpenMenuClick}
        role="button"
        tabIndex={0}
      >
        <PersonIcon className="material-icons" />
      </div>
      <Menu
        id="userMenu"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleCloseMenuClick}
        className="user-menu-list"
      >
        {
          USER_MENU_CONFIG.map((item) => {
            if (
              isUserReadOnly
              && (
                item.path !== ROUTES.PROFILE
                && ![PROFILE_SECTIONS.HELP, PROFILE_SECTIONS.LOGOUT].includes(item.name as PROFILE_SECTIONS)
              )
            ) {
              return null;
            }
            if (!!item.permissions?.length && !item.permissions.includes(userRole)) return null;
            if (item.path === ROUTES.GROUP_MANAGEMENT && !permissionUsingGroupManagement) return null;
            if (item.path === ROUTES.BRANDING && !permissionUsingCompanyBranding) return null;
            if (item.isHelpButton) {
              return (
                <MenuItem
                  key={`UserMenuItem_${item.path}`}
                  onClick={onOpenHelpButtonHandler}
                  disableGutters
                >
                  <div className="d-flex pe-2">
                    <span className="menu-link">
                      {item.name}
                    </span>
                    <OpenInNewIcon />
                  </div>
                </MenuItem>
              );
            }
            if (item.path) {
              return (
                <MenuItem
                  key={`UserMenuItem_${item.path}`}
                  onClick={handleCloseMenuClick}
                  disableGutters
                >
                  <Link
                    to={item.path}
                    className="menu-link"
                    target={item.isHelpButton ? '_blank' : '_self'}
                  >
                    {item.name}
                  </Link>
                </MenuItem>
              );
            }

            return (
              <MenuItem
                key={`UserMenuItem_${item.name}`}
                onClick={logout}
                disableGutters
              >
                <span className="menu-link">
                  {item.name}
                </span>
              </MenuItem>
            );
          })
        }
      </Menu>
      <div onClick={handleOpenMenuClick} role="button" tabIndex={0}>
        <ExpandMoreIcon className="material-icons expand-more-icon" />
      </div>
      <ConfirmationModal
        isOpen={isHelpButtonModalWindowOpen}
        onCancel={onCancelHelpButtonHandler}
        onConfirm={onConfirmHelpButtonHandler}
        confirmButtonText="Continue"
        cancelButtonText="Cancel"
        description={(
          <>
            You are being redirected to an external web page in a new tab:&nbsp;
            <a href={URL_ZEN_DESK_HELP} target="_blank" rel="noreferrer">
              help.unicornforms.com
            </a>
          </>
        )}
      />
    </>
  );
};

export default UserMenu;