import { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react';

import Tooltip from 'components/Tooltip';

interface ITableQuestionToolbarButton extends DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement
> {
  title: string;
}

export const TableQuestionToolbarButton: FC<ITableQuestionToolbarButton> = ({
  title,
  children,
  ...props
}) => (
  <Tooltip title={title}>
    <button
      className="table-question-toolbar-button"
      style={{ userSelect: 'none' }}
      contentEditable={false}
      type="button"
      {...props}
    >
      {children}
    </button>
  </Tooltip>
);

export default TableQuestionToolbarButton;