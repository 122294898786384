import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { REMINDERS_PROPERTIES } from 'constants/pageSettings';
import { useRolePermission } from 'hooks/useRolePermission';
import { setPageSettingValueByKey } from 'store/actions/pageSettings';
import { RootStateType } from 'store/reducers';
import { PageSettingsType } from 'types/PageSettingsType';
import { getPageSettings } from 'utils/PageSettings';

const useSetDefaultReminderSettings = () => {
  const dispatch = useDispatch();
  const { permissionUsingReminders } = useRolePermission();
  const pageSettings: PageSettingsType | null = useSelector((state: RootStateType) => getPageSettings(state));

  useEffect(() => {
    if (permissionUsingReminders) {
      if (typeof pageSettings?.enable_email_reminder !== 'boolean' && !pageSettings?.enable_email_reminder) {
        dispatch(setPageSettingValueByKey({
          key: REMINDERS_PROPERTIES.ENABLE_EMAIL_REMINDER,
          value: true,
        }));
      }
      if (typeof pageSettings?.enable_email_reminder !== 'boolean' && !pageSettings?.send_email_delay_days) {
        dispatch(setPageSettingValueByKey({
          key: REMINDERS_PROPERTIES.SEND_EMAIL_DELAY_DAYS,
          value: 3,
        }));
      }
      if (typeof pageSettings?.enable_email_reminder !== 'boolean' && !pageSettings?.send_sms_delay_days) {
        dispatch(setPageSettingValueByKey({
          key: REMINDERS_PROPERTIES.SEND_SMS_DELAY_DAYS,
          value: 4,
        }));
      }
    }
  }, [
    dispatch,
    permissionUsingReminders,
    pageSettings?.enable_email_reminder,
    pageSettings?.send_email_delay_days,
    pageSettings?.send_sms_delay_days,
  ]);
};

export default useSetDefaultReminderSettings;