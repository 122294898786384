/* eslint-disable */
import { connect } from "react-redux";
import Sections from "../pages/Sections";
import { deleteSections  } from "../store/actions/userData";

const mapStateToProps = ({user: { sections } }: any) => ({
  sections
});

const mapDispatchToProps = (dispatch: any) => ({
  deleteSections: (idArray: any) => dispatch(deleteSections(idArray))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sections);