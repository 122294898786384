import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { setFormBuilderGroupedFieldsKeys } from 'store/actions/formBuilder';
import { RootStateType } from 'store/reducers';
import { FormBuilderType } from 'types/FormBuilder';
import { PDFFieldType } from 'types/PdfTemplates';
import { IFormBuilderSection } from 'types/Sections';
import { getFormBuilderByType } from 'utils/FormBuilder/store';

const useFormBuilderGroupedFields = (isFormBuilderOpened: boolean, formBuilderType: FormBuilderType = 'pdf') => {
  const dispatch = useDispatch();
  const pdfFields = useSelector((state: RootStateType) => state.pdfTemplates.pdfTemplateFields);
  const fieldsArray: PDFFieldType[] = Object.values(pdfFields);

  const formBuilderFromStore: IFormBuilderSection[] = useSelector((state: RootStateType) => (
    getFormBuilderByType(state, formBuilderType)
  ));

  const getGroupedFieldsKeysObject = () => {
    const groupedFieldsKeysObject: Record<number, string[]> = {};
    formBuilderFromStore.forEach((section) => {
      section.fields.forEach((sectionField) => {
        if (sectionField.groupBy) {
          const currentGroupedFieldsKeys = fieldsArray
            .filter((field) => field.groupBy === sectionField.groupBy && field.fieldName === sectionField.fieldName)
            .map((field) => String(field.key));
          groupedFieldsKeysObject[sectionField.key ?? 0] = currentGroupedFieldsKeys;
        }
      });
    });
    return groupedFieldsKeysObject;
  };

  useEffect(() => {
    if (isFormBuilderOpened) {
      dispatch(setFormBuilderGroupedFieldsKeys(getGroupedFieldsKeysObject()));
    } else {
      dispatch(setFormBuilderGroupedFieldsKeys(null));
    }
  }, [isFormBuilderOpened]);
};

export default useFormBuilderGroupedFields;