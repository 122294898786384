import { put, select, takeLatest } from 'redux-saga/effects';

import { COLLECTION, DOCUMENT, DOCUMENT_EXECUTED } from 'constants/documentTypes';
import {
  SET_PAGE_SETTING_VALUE_BY_KEY,
  SET_PAGE_SETTING_VALUE_FOR_DOCUMENT,
  SET_PAGE_SETTING_VALUE_FOR_NEW_DOCUMENT,
  SET_PAGE_SETTING_VALUE_FOR_TEMPLATE,
} from 'store/actions/actionTypes';
import { RootStateType } from 'store/reducers';
import { safe } from 'store/sagas/errorHandlerSaga';
import { PageSettingsKeyType, PageSettingsValueType } from 'types/PageSettingsType';
import { ActionPayload } from 'types/redux';
import { getDocumentTypeForPageSettingsFromStorePath } from 'utils/PageSettings';

const setPageSettingValueByKeySaga = function* ({
  payload,
}: ActionPayload<{ key: PageSettingsKeyType, value: PageSettingsValueType }>) {
  const type: string = yield select((state: RootStateType) => getDocumentTypeForPageSettingsFromStorePath(state));

  switch (type) {
    case DOCUMENT:
      yield put({ type: SET_PAGE_SETTING_VALUE_FOR_TEMPLATE, payload });
      break;
    case DOCUMENT_EXECUTED:
      yield put({ type: SET_PAGE_SETTING_VALUE_FOR_DOCUMENT, payload });
      break;
    case COLLECTION:
      // This functionality will be done later.
      break;
    default:
      yield put({ type: SET_PAGE_SETTING_VALUE_FOR_NEW_DOCUMENT, payload });
      break;
  }
};

export default [
  takeLatest(SET_PAGE_SETTING_VALUE_BY_KEY, safe(setPageSettingValueByKeySaga, false, false)),
];