import { MANAGER_ASSIGNMENT, RECIPIENT_ASSIGNMENT } from 'constants/editors';
import { EDITOR_PAGE_TABS } from 'constants/tabs';
import {
  ADD_ASSIGNMENT,
  ADD_CUSTOM_ASSIGNMENT,
  ADD_FIELD_TO_ASSIGNMENTS,
  CHANGE_ASSIGNMENT_LABEL,
  CHANGE_ASSIGNMENT_TYPE,
  CHANGE_FIELD_IN_FOCUS_STATUS,
  CLEAN_ASSIGNMENTS,
  DELETE_PDF_FILE_LINK,
  REMOVE_ASSIGNMENT,
  REMOVE_SELECT_FIELD_ERROR,
  REMOVE_SELECT_FIELD_ERRORS,
  SET_ACTIVE_EDITOR_TAB,
  SET_ACTIVE_HOVERED_SECTION_KEY,
  SET_ALL_ASSIGNMENTS,
  SET_ASSIGNMENT_DELETION_INFO,
  SET_OPEN_ASSIGNMENTS_SETTINGS_MODAL,
  SET_PDF_FILE_LINK,
  SET_PUBLIC_FIELD_VALIDATION,
  SET_PUBLIC_FORM_VALIDATION,
  SET_SELECT_FIELD_ERROR,
  SET_SELECTED_FIELD,
  SET_SELECTED_SECTION,
  SET_SELECTED_TABLE_QUESTION,
} from 'store/actions/actionTypes';
import { AssignmentsMainParts, AssignmentType, ISlateEditor } from 'types/Editor';
import { Action } from 'types/redux';
import { isManagerAssignment, updateAssignmentStorePart } from 'utils/assignmentsHelpers';
import { getAssignmentsWithCorrectTypes, removeAssignmentAndChangePositions } from 'utils/signNow/assignmentsHelper';

export const initialSigners = {
  managers: [
    { type: MANAGER_ASSIGNMENT, label: `${MANAGER_ASSIGNMENT} 1`, fieldsKeys: [], position: 0 },
  ],
  recipients: [
    { type: RECIPIENT_ASSIGNMENT, label: `${RECIPIENT_ASSIGNMENT} 1`, fieldsKeys: [], position: 1 },
  ],
};

const initialState: ISlateEditor = {
  selectedField: null,
  selectedSection: null,
  activeEditorTab: EDITOR_PAGE_TABS[0].id,
  isSelectFieldError: {},
  pdfFileLink: '',
  publicFormValidationResults: {
    validationFields: {},
    isError: false,
  },
  fieldInFocus: false,
  assignments: initialSigners,
  selectedTableQuestionKey: null,
  activeHoveredSectionKey: null,
  openAssignmentsSettingsModal: false,
  openAssignmentDeletionConfirmationModal: false,
  assignmentTypeForDeletion: null,
};

// eslint-disable-next-line default-param-last
export default (state: ISlateEditor = initialState, action: Action): ISlateEditor => {
  switch (action.type) {
    case SET_SELECTED_FIELD:
      return {
        ...state,
        selectedField: action.payload,
      };
    case SET_SELECTED_SECTION:
      return {
        ...state,
        selectedSection: action.payload,
      };
    case SET_PUBLIC_FORM_VALIDATION:
      return {
        ...state,
        publicFormValidationResults: action.payload,
      };
    case SET_PUBLIC_FIELD_VALIDATION: {
      return {
        ...state,
        publicFormValidationResults: {
          ...state.publicFormValidationResults,
          validationFields: {
            ...state.publicFormValidationResults.validationFields,
            [action.payload.fieldKey]: action.payload.validationMessage,
          },
        },
      };
    }
    case SET_PDF_FILE_LINK:
      return {
        ...state,
        pdfFileLink: action.payload,
      };
    case DELETE_PDF_FILE_LINK:
      return {
        ...state,
        pdfFileLink: '',
      };
    case SET_ACTIVE_EDITOR_TAB:
      return {
        ...state,
        activeEditorTab: action.payload,
      };
    case SET_SELECT_FIELD_ERROR:
      return {
        ...state,
        isSelectFieldError: {
          ...state.isSelectFieldError,
          [action.payload.fieldKey]: action.payload.isError,
        },
      };
    case REMOVE_SELECT_FIELD_ERROR: {
      const stateCopy = JSON.parse(JSON.stringify(state));
      delete stateCopy.isSelectFieldError[action.payload];
      return stateCopy;
    }
    case REMOVE_SELECT_FIELD_ERRORS:
      return {
        ...state,
        isSelectFieldError: {},
      };
    case CHANGE_FIELD_IN_FOCUS_STATUS:
      return {
        ...state,
        fieldInFocus: action.payload.fieldInFocus,
      };
    case ADD_ASSIGNMENT: {
      const typeToUpdate = action.payload.typeToUpdate as AssignmentsMainParts;
      const length: number = action.payload.length + 1;
      const position: number = action.payload.position;
      const assignmentType = typeToUpdate === AssignmentsMainParts.MANAGERS
        ? MANAGER_ASSIGNMENT
        : RECIPIENT_ASSIGNMENT;
      return {
        ...state,
        assignments: {
          ...state.assignments,
          [typeToUpdate]: [
            ...state.assignments[typeToUpdate],
            {
              type: `${assignmentType}${length}`,
              label: `${assignmentType} ${length}`,
              fieldsKeys: [],
              position,
            },
          ],
        },
      };
    }
    case ADD_CUSTOM_ASSIGNMENT: {
      return {
        ...state,
        assignments: action.payload,
      };
    }
    case CLEAN_ASSIGNMENTS:
      return {
        ...state,
        assignments: initialSigners,
      };
    case SET_ALL_ASSIGNMENTS:
      return {
        ...state,
        assignments: action.payload,
      };
    case ADD_FIELD_TO_ASSIGNMENTS: {
      const typeToUpdate = action.payload.typeToUpdate as AssignmentsMainParts;
      return {
        ...state,
        assignments: {
          ...state.assignments,
          [typeToUpdate]: updateAssignmentStorePart(
            state.assignments[typeToUpdate],
            action.payload.assignment,
            action.payload.key,
          ),
        },
      };
    }
    case SET_SELECTED_TABLE_QUESTION:
      return {
        ...state,
        selectedTableQuestionKey: action.payload ?? null,
      };
    case SET_ACTIVE_HOVERED_SECTION_KEY:
      return {
        ...state,
        activeHoveredSectionKey: action.payload ?? null,
      };
    case SET_OPEN_ASSIGNMENTS_SETTINGS_MODAL:
      return {
        ...state,
        openAssignmentsSettingsModal: action.payload,
      };
    case CHANGE_ASSIGNMENT_LABEL: {
      const assignmentType = action.payload.assignmentType;
      const mainTypeToUpdate = isManagerAssignment(assignmentType)
        ? AssignmentsMainParts.MANAGERS
        : AssignmentsMainParts.RECIPIENTS;
      const newLabel = action.payload.newLabel;
      return {
        ...state,
        assignments: {
          ...state.assignments,
          [mainTypeToUpdate]: state.assignments[mainTypeToUpdate].map((assignment) => {
            if (assignment.type === assignmentType) {
              return {
                ...assignment,
                label: newLabel,
              };
            }
            return assignment;
          }),
        },
      };
    }
    case CHANGE_ASSIGNMENT_TYPE: {
      const previousAssignmentType = action.payload.previousAssignmentType;
      const previousMainType: AssignmentsMainParts = action.payload.previousMainType;
      const newMainType: AssignmentsMainParts = action.payload.newMainType;
      const currentAssignment = state.assignments[previousMainType].find(
        (assignment) => assignment.type === previousAssignmentType,
      );
      const assignmentType = newMainType === AssignmentsMainParts.MANAGERS
        ? MANAGER_ASSIGNMENT
        : RECIPIENT_ASSIGNMENT;
      return {
        ...state,
        assignments: {
          ...state.assignments,
          [previousMainType]: state.assignments[previousMainType].filter(
            (assignment) => assignment.type !== previousAssignmentType,
          ),
          [newMainType]: getAssignmentsWithCorrectTypes(
            state.assignments[newMainType],
            currentAssignment as AssignmentType,
            assignmentType,
          ),
        },
      };
    }
    case SET_ASSIGNMENT_DELETION_INFO:
      return {
        ...state,
        openAssignmentDeletionConfirmationModal: action.payload.openConfirmationModal,
        assignmentTypeForDeletion: action.payload.assignmentTypeForDeletion,
      };
    case REMOVE_ASSIGNMENT:
      return {
        ...state,
        assignments: removeAssignmentAndChangePositions(action.payload, state.assignments),
      };
    default:
      return state;
  }
};
