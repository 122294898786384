import { FC } from 'react';

import { Descendant } from 'slate';

import Editor from 'components/Editor';
import PublicEditor from 'components/Editor/components/PublicEditor';
import PdfEditor from 'components/PdfEditor/PdfEditor';
import { DOCUMENT_TYPE, FORM_TYPE, PDF_TYPE } from 'constants/general';
import { ICustomElement } from 'types/Editor';
import { PDFFieldType } from 'types/PdfTemplates';

const DocumentPreviewEditor: FC<{
  content: Descendant[];
  onChange: React.Dispatch<React.SetStateAction<Descendant[]>>;
  type: string;
  pdfFields?: ICustomElement[] | null;
}> = (props) => {
  const {
    content,
    onChange,
    pdfFields = null,
    type = DOCUMENT_TYPE,
  } = props;

  switch (type) {
    case PDF_TYPE:
      return (
        <PdfEditor
          visibleEditorDevTools={false}
          customFields={pdfFields as PDFFieldType[] | null}
          isPublicPage
          readOnlyMode
        />
      );
    case FORM_TYPE:
      return (
        <Editor
          isCompletedModal
          readOnlyMode
          viewForms
          styledSections
          content={content}
          onChange={onChange}
          visibleEditorDevTools={false}
          previewMode={false}
        />
      );
    case DOCUMENT_TYPE:
    default:
      return (
        <PublicEditor
          content={content}
          onChange={onChange}
          readOnlyMode
          showOnlyEditor
        />
      );
  }
};

export default DocumentPreviewEditor;