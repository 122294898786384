import { FC } from 'react';

import cn from 'classnames';
import Button from 'components/Base/Button';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import BreadcrumbItem from 'components/SignNow/StepManager/BreadcrumbItem';
import { useStepHandler } from 'components/SignNow/StepManager/StepHandlerContext';
import { CANCEL, NEXT } from 'constants/general';
import { useBreadcrumbs } from 'hooks/signNow';
import CloseIcon from 'svg/CloseIcon';
import {
  IReadonlyStepManagerConfig,
  IStepManagerChildComponentProps,
  IUseBreadcrumbsReturns,
} from 'types/signNow/base';

import 'scss/components/StepManager/index.scss';

interface IBreadcrumbsProps {
  currentStep: number;
  isNextButtonVisible: boolean;
  list: IReadonlyStepManagerConfig;
  isButtonsDisabled?: boolean;
}

const Breadcrumbs: FC<IBreadcrumbsProps & IStepManagerChildComponentProps> = ({
  currentStep,
  isNextButtonVisible,
  list,
  goToStep,
  isButtonsDisabled = false,
}) => {
  const {
    isOpenConfirmationPopup,
    onClosePageHandler,
    onConfirmHandler,
    onCancelHandler,
  }: IUseBreadcrumbsReturns = useBreadcrumbs();

  const { onNext, isNextButtonDisabled } = useStepHandler();

  return (
    <div className={cn(
      'breadcrumbs editor-header',
      'd-flex flex-column-reverse flex-lg-row align-items-stretch align-items-lg-center',
    )}
    >
      <ul className="col col-lg-10 breadcrumbs__list">
        {
          list.map((item) => (
            !item.isHidden
              ? (
                <BreadcrumbItem
                  key={item.step}
                  item={item}
                  goToStep={goToStep}
                  currentStep={currentStep}
                  isDisabled={isButtonsDisabled}
                />
              )
              : null
          ))
        }
      </ul>
      <div className="col col-lg-2 d-flex justify-content-end p-0 gap-2">
        {
          onNext && isNextButtonVisible
            && (
              <Button onClick={onNext} disabled={isNextButtonDisabled}>
                {list[currentStep].nextButtonTitle ?? NEXT}
              </Button>
            )
        }
        <Button
          classes="button-simple"
          variant="text"
          size="small"
          startIcon={<CloseIcon />}
          onClick={onClosePageHandler}
        >
          {CANCEL}
        </Button>
      </div>
      <ConfirmationModal
        description="Are you sure you want to cancel working on this document?"
        isOpen={isOpenConfirmationPopup}
        onCancel={onCancelHandler}
        onConfirm={onConfirmHandler}
      />
    </div>
  );
};

export default Breadcrumbs;