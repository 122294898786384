import { FC, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import NavigationTabIcon from 'components/Base/NavigationTabIcon';
import OutlinePublicPanel from 'components/PublicPage/OutlinePublicPanel';
import PUBLIC_PAGE_STATUS from 'constants/publicPage';
import { ShareViewModes } from 'constants/shareViewModes';
import { DOCUMENT_TAB, FORM_TAB } from 'constants/tabs';
import useReadOnlyMode from 'hooks/useReadOnlyMode';
import { setPublicPageCurrentDocument } from 'store/actions/publicPages';
import { RootStateType } from 'store/reducers';
import { IPublicPageState } from 'types/PublicPage';
import getPageStatus from 'utils/PublicPage/getPageStatus';

import classes from 'scss/components/PublicEditor/PublicEditor.module.scss';

interface IDocumentsForSignaturePanel {
  onSaveDocument: (status: PUBLIC_PAGE_STATUS, redirect: boolean) => void;
}

const DocumentsForSignaturePanel: FC<IDocumentsForSignaturePanel> = ({
  onSaveDocument,
}) => {
  const dispatch = useDispatch();
  const {
    data,
    structure,
    currentDocument,
  }: IPublicPageState = useSelector((state: RootStateType) => state.publicPages);
  const [activeId, setActiveId] = useState<number>(currentDocument?.id || structure.documents[0].id);
  const readOnlyMode = useReadOnlyMode();

  const handlerOnDocumentClick = (documentId: number) => {
    if (currentDocument) {
      const { currentSlideIndex, totalIndexSlides, emptyDocument } = currentDocument;
      const status = getPageStatus(currentSlideIndex, totalIndexSlides, emptyDocument, readOnlyMode);
      onSaveDocument(status, false);
    }
    dispatch(setPublicPageCurrentDocument(documentId));
    setActiveId(documentId);
  };

  return (
    <div className="m-0 position-sticky top-0" style={{ height: 'auto' }}>
      <OutlinePublicPanel
        reverse
        title="Documents for signature"
      >
        {structure.documents.map((document, index) => (
          <div
            key={`DocumentForSignaturePanel_${index + 1}`}
            className={`${classes.OutlinePanelText} ${classes.OutlineElement} ${
              document.id === activeId ? classes.ActiveOutlineElement : ''
            }`}
            role="button"
            tabIndex={-1}
            onClick={() => handlerOnDocumentClick(document.id)}
          >
            {
              data?.view_mode === ShareViewModes.SHARE_MODE_DOCUMENT_AND_FORM_BUILDER
                && (
                  <div>
                    <NavigationTabIcon
                      type={document.viewMode === ShareViewModes.SHARE_MODE_FORM_BUILDER ? FORM_TAB : DOCUMENT_TAB}
                    />
                  </div>
                )
            }
            {document.name}
          </div>
        ))}
      </OutlinePublicPanel>
    </div>
  );
};

export default DocumentsForSignaturePanel;