const DragHandleIcon = ({ disabled = false }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-disabled={disabled}
  >
    <path d="M20 9H4V11H20V9ZM4 15H20V13H4V15Z" fill="#2F3947" data-disabled={disabled} />
  </svg>
);

export default DragHandleIcon;