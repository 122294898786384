import { FC, SVGAttributes } from 'react';

import { ROLES } from 'constants/roles';
import { PROFILE_SECTIONS } from 'constants/UserMenu';

export interface IUserMenuItem {
  name: PROFILE_SECTIONS;
  path?: string;
  IconComponent?: FC<SVGAttributes<any>>;
  permissions?: Array<ROLES>;
  isHelpButton?: boolean;
}

export const enum INTEGRATIONS_SECTIONS {
  MONDAY_COM = 'Monday.com',
}

export type IntegrationSettingsType = {
  integrationPermission: boolean;
  title: INTEGRATIONS_SECTIONS;
  IntegrationComponent: () => JSX.Element;
}