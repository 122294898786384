import { Dispatch } from 'redux';

import { Element as SlateElement } from 'slate';

import { PROPERTIES } from 'constants/editors';
import { FieldAssignmetsType, ISubtypesFromFieldsGetter, SelectFieldOptionType } from 'types/Editor';
import { PDFFieldType, PDFFieldTypeObject } from 'types/PdfTemplates';
import {
  IEditorModalSubtypesFieldsWithAssignments,
  IPublicPageSubtypesDataWithAssignments,
  ISubtypeDifferentValues,
  ISubtypeDifferentValuesWithAssignments,
  ISubtypeKeysValues,
  ISubtypesFieldsKeys,
  ISubtypesFieldsKeysAndAssignments,
} from 'types/Subtypes';
import { IUserData } from 'types/userProfile';
import { isRecipientAssignment } from 'utils/assignmentsHelpers';
import { updatePdfField } from 'utils/pdfTemplatesHelpers';

export const getModalSubtypesFields = (subtypes: ISubtypeDifferentValuesWithAssignments) => {
  const modalSubtypesFieldsWithAssignments: IEditorModalSubtypesFieldsWithAssignments = {};
  Object.keys(subtypes).forEach((mainAssignment: string) => {
    modalSubtypesFieldsWithAssignments[mainAssignment] = Object.entries(subtypes[mainAssignment])
      .map(([key, value]) => ({
        name: key,
        properties: {
          ...Object.keys(value).reduce((object, item: string) => ({
            ...object,
            [item]: '',
          }), {}),
        },
      }));
  });
  return modalSubtypesFieldsWithAssignments;
};

export const getSubtypeDifferentValues = (
  subtypesDataWithAssignments: IPublicPageSubtypesDataWithAssignments,
  dependenciesArray: string[] = [PROPERTIES.VALUE],
): ISubtypeDifferentValuesWithAssignments => {
  const subtypeFieldsWithAssignments: ISubtypeDifferentValuesWithAssignments = {};
  Object.keys(subtypesDataWithAssignments).forEach((mainAssignment: string) => {
    const subtypeFields = Object.entries(subtypesDataWithAssignments[mainAssignment]);
    const resultObject: ISubtypeDifferentValues = {};
    if (!subtypeFields.length) return resultObject;
    /* O(n^3): We have array with properties that we need to compare in all grouped fields:
     * First For: Properties array; ['value', 'maxLength'];
     * Second For: Current subtypes(or grouped) fields;
     * Third For (map): Field values getter;
     * Third For (new Set): Different values comparator;
     */
    for (let i = 0; i < dependenciesArray.length; i++) {
      const key = dependenciesArray[i];
      for (let j = 0; j < subtypeFields.length; j++) {
        const [fieldsName, fieldsValues] = subtypeFields[j];
        const valuesByDependencies = Object
          .values(fieldsValues)
          .map((field) => field[key]);
        const valuesByDependenciesSet = new Set(valuesByDependencies);
        if (key !== PROPERTIES.OPTIONS) {
          if (valuesByDependenciesSet.size > 1) {
            resultObject[fieldsName] = {
              ...resultObject[fieldsName],
              [key]: Array.from(valuesByDependenciesSet),
            };
          }
        } else {
          const unitedOptions: string[] = [];
          valuesByDependencies.forEach((item) => {
            if (Array.isArray(item)) {
              item.forEach((innerItem: SelectFieldOptionType) => {
                if (!unitedOptions.includes(innerItem.label)) {
                  unitedOptions.push(innerItem.label);
                }
              });
            }
          });
          if (unitedOptions.length > (valuesByDependencies as SelectFieldOptionType[][])[0].length) {
            resultObject[fieldsName] = {
              ...resultObject[fieldsName],
              [key]: Array.from(valuesByDependenciesSet),
            };
          }
        }
      }
    }

    subtypeFieldsWithAssignments[mainAssignment] = resultObject;
  });
  return subtypeFieldsWithAssignments;
};

export const getFieldKeys = (fieldsWithAssignments: IPublicPageSubtypesDataWithAssignments) => {
  const objectsKeysAndAssignments: ISubtypesFieldsKeysAndAssignments = {};
  Object.keys(fieldsWithAssignments).forEach((mainAssignment: string) => {
    const mainAssingmentPart = Object.keys(fieldsWithAssignments[mainAssignment]);
    const resultObject: ISubtypesFieldsKeys = {};
    mainAssingmentPart?.forEach((key: string) => {
      resultObject[key] = Object.keys(fieldsWithAssignments[mainAssignment][key]);
    });
    objectsKeysAndAssignments[mainAssignment] = resultObject;
  });
  return objectsKeysAndAssignments;
};

export const combineKeysAndValues = (
  fieldsWithAssignments: IEditorModalSubtypesFieldsWithAssignments,
  keysWithAssignments: ISubtypesFieldsKeysAndAssignments,
): ISubtypeKeysValues => {
  const resultObject: ISubtypeKeysValues = {};
  Object.keys(fieldsWithAssignments).forEach((mainAssignment: string) => {
    fieldsWithAssignments[mainAssignment].forEach((field) => {
      keysWithAssignments[mainAssignment][field.name].forEach((key: string) => {
        resultObject[key] = field.properties;
      });
    });
  });
  return resultObject;
};

export const getSubtypeFieldsfromArray = (
  fieldsArray: (PDFFieldType | SlateElement)[],
) => (
  fieldsArray.filter((field) => field.subtype && isRecipientAssignment(field.assignment))
);

export const getUniqueSubtypeFields = (
  subtypeFields: ISubtypesFromFieldsGetter[],
): Partial<ISubtypesFromFieldsGetter>[] => subtypeFields
  .filter((field, index, self) => self.findIndex((element) => element.subtype === field.subtype) === index)
  .map(({ subtype, value }) => ({ subtype, value }));

export const concatSubtypeUniqueDataSources = (
  personalSubtypeData: Partial<ISubtypesFromFieldsGetter>[] | IUserData,
  contentSubtypeData: Partial<ISubtypesFromFieldsGetter>[] = [],
) => {
  const personalDataUniqueSubtypes = (personalSubtypeData as Partial<ISubtypesFromFieldsGetter>[]).filter(
    (personalDataItem) => !contentSubtypeData.find(({ subtype }) => subtype === personalDataItem.subtype),
  );
  const filteredUnitedSubtypesArray = contentSubtypeData.concat(personalDataUniqueSubtypes).filter(
    (subtypeObject) => subtypeObject.value,
  );
  return filteredUnitedSubtypesArray;
};

export const updatePIIData = (
  userPIIData: Partial<ISubtypesFromFieldsGetter>[],
  updatedPIIData: Partial<ISubtypesFromFieldsGetter>[],
) => (
  userPIIData.map((item) => {
    const update = updatedPIIData.find((data) => data.subtype === item.subtype);
    if (update) {
      return {
        ...item,
        value: update.value,
      };
    }
    return item;
  }));

export const filterAndUpdateAllSimilarPDFSubtypes = ({
  fieldsObject,
  assignment,
  subtype,
  value,
  dispatch,
}: {
  fieldsObject: PDFFieldTypeObject;
  assignment: FieldAssignmetsType;
  subtype: string;
  value: string;
  dispatch: Dispatch;
}) => {
  const subtypeFormElements = Object.values(fieldsObject)
    .filter((element) => element.subtype === subtype && element.assignment === assignment);

  subtypeFormElements.forEach((fieldElement) => {
    const fieldData = { ...fieldElement, value };
    dispatch(updatePdfField(fieldData));
  });
};