import { useDispatch, useSelector } from 'react-redux';

import { VERIFY_IDENTITY_VIA_SMS } from 'constants/pageSettings';
import { setOpenPopupBecauseOfSmsValidation } from 'store/actions/mondayComIntegration';
import { RootStateType } from 'store/reducers';
import { MondayComIntegrationObjectType } from 'types/mondayCom/mondayComBoard';
import { PageSettingsKeyType } from 'types/PageSettingsType';

const useSmsValidationPhoneNumberFieldForMondaycom = () => {
  const dispatch = useDispatch();
  const shouldOpenPopupBecauseOfSmsValidation: boolean = useSelector(
    (state: RootStateType) => state.mondayCom.shouldOpenPopupBecauseOfSmsValidation,
  );
  const { monday_com_integration: mondayComIntegration } = useSelector(
    (state: RootStateType) => state.user.templateDetails ?? {},
  );

  const checkAndOpenMondayComModalWindow = (key: PageSettingsKeyType, value: boolean | string) => {
    if (
      key === VERIFY_IDENTITY_VIA_SMS
      && value
      && mondayComIntegration
      && mondayComIntegration.some(
        (boardObject: MondayComIntegrationObjectType) => boardObject.subitemMapping,
      )
      && mondayComIntegration.some(
        (boardObject: MondayComIntegrationObjectType) => !boardObject.filterColumnsIds?.phoneNumberColumnId,
      )
    ) {
      dispatch(setOpenPopupBecauseOfSmsValidation(true));
    }
  };

  return {
    shouldOpenPopupBecauseOfSmsValidation,
    checkAndOpenMondayComModalWindow,
  };
};

export default useSmsValidationPhoneNumberFieldForMondaycom;