import { FC } from 'react';

import { RenderElementProps } from 'slate-react';

import CheckListItemElement from 'components/Editor/components/CheckListEditable';
import ImageElement from 'components/Editor/components/ImageEditable';
import WrapperBlockEditable from 'components/Editor/components/WrapperBlockEditable';
import { Table } from 'components/Editor/editor-custom-plugins/table/elements';
import FormInputEditable from 'components/FormConstructor/Elements/FormInputEditable';
import FormSectionEditable from 'components/FormConstructor/Elements/FormSectionEditable';
import FormSelectEditable from 'components/FormConstructor/Elements/FormSelectEditable';
import SortableParagraph from 'components/FormConstructor/Elements/SortableParagraph';
import {
  ATTACHMENT_FIELD,
  BULLETED_LIST,
  CHECK_LIST_ITEM,
  DATE_FIELD,
  HEADLINE_FIVE,
  HEADLINE_FOUR,
  HEADLINE_ONE,
  HEADLINE_SIX,
  HEADLINE_THREE,
  HEADLINE_TWO,
  IMAGE_TYPE,
  LIST_ITEM,
  LIST_ITEM_TEXT,
  NUMBERED_LIST,
  NUMBERED_LIST_ITEM,
  QUESTION_FIELD,
  SECTION,
  SELECT_FIELD,
  SIGNING_FIELD,
  TEXT_FIELD,
  TEXTAREA_FIELD,
} from 'constants/editors';

interface IFormTypeElementProps extends RenderElementProps {
  readOnlyMode?: boolean;
  isPublicPage?: boolean;
  visibleButtons?: boolean;
}

const FormTypeElement: FC<IFormTypeElementProps> = (props) => {
  const {
    attributes,
    children,
    element,
    isPublicPage = false,
    readOnlyMode = false,
    visibleButtons = true,
  } = props;

  switch (element.type) {
    case 'table':
    case 'table-row':
    case 'table-cell':
      return <Table {...props} />;
    case 'table-body':
      return <tbody slate-table-element="tbody">{children}</tbody>; // eslint-disable-line react/no-unknown-property
    case 'table-content':
      return (
        <WrapperBlockEditable element={element}>
          <p {...attributes} className="table-content">{children}</p>
        </WrapperBlockEditable>
      );
    case 'table-head':
      return <thead {...attributes}>{children}</thead>;
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    // TODO: add supporting headers inside paragraphs in src\utils\editorBlockHelpers.ts
    case HEADLINE_ONE:
      return (
        <WrapperBlockEditable element={element}>
          <h1 {...attributes} className="py-2 mb-1 text-left">{children}</h1>
        </WrapperBlockEditable>
      );
    case HEADLINE_TWO:
      return (
        <WrapperBlockEditable element={element}>
          <h2 {...attributes} className="py-2">{children}</h2>
        </WrapperBlockEditable>
      );
    case HEADLINE_THREE:
      return (
        <WrapperBlockEditable element={element}>
          <h3 {...attributes} className="py-2">{children}</h3>
        </WrapperBlockEditable>
      );
    case HEADLINE_FOUR:
      return (
        <WrapperBlockEditable element={element}>
          <h4 {...attributes} className="py-2">{children}</h4>
        </WrapperBlockEditable>
      );
    case HEADLINE_FIVE:
      return (
        <WrapperBlockEditable element={element}>
          <h5 {...attributes} className="py-2">{children}</h5>
        </WrapperBlockEditable>
      );
    case HEADLINE_SIX:
      return (
        <WrapperBlockEditable element={element}>
          <h6 {...attributes} className="py-2">{children}</h6>
        </WrapperBlockEditable>
      );
    case SECTION:
      return (
        <FormSectionEditable
          {...props}
          readOnly={readOnlyMode}
          showButtons={visibleButtons}
          index={element.position || 0}
        >
          {children}
        </FormSectionEditable>
      );
    case IMAGE_TYPE:
      return <ImageElement {...props} />;
    case 'link':
      return (
        <a {...attributes} href={element.url} target={element.blank ? '_blank' : '_self'} rel="noreferrer">
          {children}
        </a>
      );
    case TEXT_FIELD:
    case DATE_FIELD:
    case TEXTAREA_FIELD:
      return <FormInputEditable {...props} readOnly={readOnlyMode} />;
    case SELECT_FIELD:
    case QUESTION_FIELD:
    case ATTACHMENT_FIELD:
    case SIGNING_FIELD:
      return <FormSelectEditable {...props} readOnly={readOnlyMode} />;
    case CHECK_LIST_ITEM:
      return <CheckListItemElement {...props} readOnly={readOnlyMode || isPublicPage} />;
    case BULLETED_LIST:
      return (
        <SortableParagraph {...props} readOnly={readOnlyMode}>
          <ul>{children}</ul>
        </SortableParagraph>
      );
    case NUMBERED_LIST:
      return (
        <SortableParagraph {...props} readOnly={readOnlyMode}>
          <ol>{children}</ol>
        </SortableParagraph>
      );
    case LIST_ITEM:
    case NUMBERED_LIST_ITEM:
      return <li {...attributes} data-type={element.type}>{children}</li>;
    case LIST_ITEM_TEXT:
      return <div {...attributes}>{children}</div>;
    default:
      return (
        <SortableParagraph {...props} readOnly={readOnlyMode}>
          {children}
        </SortableParagraph>
      );
  }
};

export default FormTypeElement;