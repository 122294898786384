import { FC, memo } from 'react';

import { Descendant, Element as SlateElement } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import Leaf from 'components/Editor/components/RenderLeaf';
import FormTypeElement from 'components/FormConstructor/Elements/RenderFormTypeElement';
import { ICustomElement } from 'types/Editor';
import { isTopLevelTextNode } from 'utils/slateEditorUtils';

interface IRenderElementProps {
  element: Partial<ICustomElement>;
}

const ReadableParagraph: FC<IRenderElementProps> = ({
  element,
}) => {
  const editor = useSlateStatic();

  if (
    !element.type
    || !isTopLevelTextNode(element.type)
    || !element.children
  ) return null;

  const renderChildrenNodes = (node: ICustomElement) => {
    const children = [];
    for (let i = 0; i < node.children.length; i++) {
      const n = node.children[i] as Descendant;
      const key = ReactEditor.findKey(editor, n);

      if (SlateElement.isElement(n)) {
        children.push(
          <FormTypeElement
            key={key.id}
            attributes={{ 'data-slate-node': 'element', ref: null }}
            element={n}
            readOnlyMode
            visibleButtons={false}
          >
            {renderChildrenNodes(n)}
          </FormTypeElement>,
        );
      } else if (n.text) {
        children.push(
          <Leaf key={key.id} leaf={n} text={n} attributes={{ 'data-slate-leaf': true }}>{n.text}</Leaf>,
        );
      }
    }
    return children;
  };
  const currentChildren = renderChildrenNodes(element as ICustomElement);

  return (
    <FormTypeElement
      key={element.key}
      attributes={{ 'data-slate-node': 'element', ref: null }}
      element={{ ...element, type: element.type, children: element.children || [] }}
      readOnlyMode
      visibleButtons={false}
    >
      {currentChildren}
    </FormTypeElement>
  );
};

export default memo(ReadableParagraph);