import { ShareViewModes } from 'constants/shareViewModes';
import { IPublicPageDocumentStructure, IStructureDocumentsGetter } from 'types/PublicPage';
import getDocumentData from 'utils/PublicPage/getDocumentData';
import { isCollectionExecutedType, isCollectionType } from 'utils/typeChecker';

const getStructureDocuments = ({
  data,
  salt,
  currentDocAssignment,
}: IStructureDocumentsGetter): IPublicPageDocumentStructure[] => {
  const documents: IPublicPageDocumentStructure[] = [];

  if (isCollectionType(data)) {
    if (data.view_mode === ShareViewModes.SHARE_MODE_FORM_BUILDER) {
      documents.push(getDocumentData({ data, assignment: currentDocAssignment, salt }));
    } else if (data.view_mode === ShareViewModes.SHARE_MODE_DOCUMENT_AND_FORM_BUILDER) {
      documents.push(getDocumentData({
        data,
        assignment: currentDocAssignment,
        salt,
        viewMode: ShareViewModes.SHARE_MODE_FORM_BUILDER,
      }));
      data.templates?.forEach(({ template }) => {
        documents.push(getDocumentData({ data: template, assignment: currentDocAssignment, salt }));
      });
    } else {
      data.templates?.forEach(({ template }) => {
        documents.push(getDocumentData({ data: template, assignment: currentDocAssignment, salt }));
      });
    }
  } else if (isCollectionExecutedType(data)) {
    // TODO FORM BUILDER SHARE VIEW MODES
    data.documents?.forEach(({ document }) => {
      documents.push(getDocumentData({ data: document, assignment: currentDocAssignment, salt }));
    });
  } else {
    documents.push(getDocumentData({ data, assignment: currentDocAssignment, salt }));
  }

  return documents;
};

export default getStructureDocuments;