import { put, select, takeLatest } from 'redux-saga/effects';

import { DOCUMENT_TAB_ID, FORM_TAB_ID } from 'constants/tabs';
import { UPDATE_FIELD_BY_KEY, UPDATE_PUBLIC_PAGE_FORM_BUILDER_FIELD } from 'store/actions/actionTypes';
import { updateFieldByKey } from 'store/actions/pdfTemplates';
import { updateFormBuilderField } from 'store/actions/publicPages';
import { RootStateType } from 'store/reducers';
import { safe } from 'store/sagas/errorHandlerSaga';
import { ICustomElement } from 'types/Editor';
import { PDFFieldType } from 'types/PdfTemplates';
import { IPublicPageDocumentStructure, PublicPageDataType } from 'types/PublicPage';
import { Action } from 'types/redux';
import { getIsDocumentWithFormBuilder } from 'utils/PublicPage/documentTypeChecker';

const updateFormBuilderAfterUpdatingPDFField = function* ({
  payload: { key, field },
}: Action<{ key: number, field: PDFFieldType }>) {
  const data: PublicPageDataType = yield select((state: RootStateType) => state.publicPages.data);
  const currentDocument: IPublicPageDocumentStructure | null = yield select((state: RootStateType) => (
    state.publicPages.currentDocument
  ));
  if (data && currentDocument && key) {
    const isDocumentWithFormBuilderView = getIsDocumentWithFormBuilder(data.view_mode);
    if (isDocumentWithFormBuilderView && currentDocument.activeEditorTab === DOCUMENT_TAB_ID) {
      yield put(updateFormBuilderField([key], field));
    }
  }
};

const updatePDFFieldAfterUpdatingFormBuilder = function* ({
  payload: { keys, value },
}: Action<{ keys: number[], value: Partial<ICustomElement> | string }>) {
  const data: PublicPageDataType = yield select((state: RootStateType) => state.publicPages.data);
  const currentDocument: IPublicPageDocumentStructure | null = yield select((state: RootStateType) => (
    state.publicPages.currentDocument
  ));
  if (data && currentDocument && keys.length) {
    const isDocumentWithFormBuilderView = getIsDocumentWithFormBuilder(data.view_mode);
    if (isDocumentWithFormBuilderView && currentDocument.activeEditorTab === FORM_TAB_ID) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key of keys) {
        yield put(updateFieldByKey(key, value as PDFFieldType));
      }
    }
  }
  yield null;
};

export default [
  takeLatest(UPDATE_FIELD_BY_KEY, safe(updateFormBuilderAfterUpdatingPDFField, false, true)),
  takeLatest(UPDATE_PUBLIC_PAGE_FORM_BUILDER_FIELD, safe(updatePDFFieldAfterUpdatingFormBuilder, false, true)),
];