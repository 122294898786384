import ENDPOINT from 'constants/endpoints';
import requestHandler from 'services/requestHandler';
import { ICardData } from 'types/payment/AddPayment';
import {
  ICheckoutSessionRequestParams,
  ICheckoutSessionResponse,
  IStripeInvoicesResponse,
  IStripeProductsResponse,
  IStripeSubscriptionDeleteResponse,
  IStripeSubscriptionResponse,
} from 'types/payment/Stripe';
import { BaseRequestType } from 'types/RequestHandler';
import { axiosInstance } from 'utils/axiosInstance';

export const createCheckoutSession: BaseRequestType<
  ICheckoutSessionResponse,
  ICheckoutSessionRequestParams
> = ((params) => (
  axiosInstance.post(ENDPOINT.STRIPE_CHECKOUT, params)
));

export const getStripeSubscriptions: BaseRequestType<IStripeSubscriptionResponse> = () => (
  axiosInstance.get(ENDPOINT.STRIPE_SUBSCRIPTIONS)
);

export const getStripeProducts: BaseRequestType<IStripeProductsResponse> = requestHandler(() => (
  axiosInstance.get(ENDPOINT.STRIPE_PRODUCTS)
));

export const deleteStripeSubscription: BaseRequestType<IStripeSubscriptionDeleteResponse, string> = (id) => (
  axiosInstance.delete(`${ENDPOINT.STRIPE_SUBSCRIPTION}/${id}`)
);

export const renewStripeSubscription: BaseRequestType<IStripeSubscriptionDeleteResponse, string> = (id) => (
  axiosInstance.patch(`${ENDPOINT.STRIPE_SUBSCRIPTION}/${id}`)
);

export const getStripeInvoiceHistory: BaseRequestType<
  IStripeInvoicesResponse,
  number
> = ((limit) => (
  axiosInstance.get(`${ENDPOINT.STRIPE_INVOICES}?limit=${limit}`)
));

export const getStripePaymentMethods = async () => (
  axiosInstance.get(ENDPOINT.STRIPE_PAYMENT_METHODS_LIST)
);

export const addStripePaymentMethod = async (cardData: ICardData) => (
  axiosInstance.post(ENDPOINT.STRIPE_PAYMENT_METHODS_LIST, cardData)
);

export const changeStripePaymentMethod = async (id: string) => (
  axiosInstance.patch(`${ENDPOINT.STRIPE_PAYMENT_METHOD}/${id}`)
);

export const deleteStripePaymentMethod = async (id:string) => (
  axiosInstance.delete(`${ENDPOINT.STRIPE_PAYMENT_METHOD}/${id}`)
);