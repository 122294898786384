import { useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { DocumentVersionRowType } from 'components/Modals/DocumentInfoModalWindow/DocumentInfoModal';
import { STATUSES } from 'constants/documentStatuses';
import { COLLECTION_TYPE } from 'constants/general';
import { getDocumentVersions } from 'services/api/serverSideEvents';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { RootStateType } from 'store/reducers';
import { MatchParams } from 'types/Route';

export const DOCUMENT_INFO_TAB_ID = 1;
export const DOCUMENT_VERSIONS_TAB_ID = 2;

const DOCUMENT_INFO_MODAL_TABS = [{
  id: DOCUMENT_INFO_TAB_ID,
  label: 'Document Information',
}];

const useDocumentInfoAndVersionsModal = () => {
  const { id }: MatchParams = useParams();
  const {
    templateDetails,
    documentDetails,
    collectionDetails,
    collectionExecutedDetails,
  } = useSelector((state: RootStateType) => state.user);
  const currentDocument = templateDetails || documentDetails || collectionDetails || collectionExecutedDetails;
  const [activeTab, setActiveTab] = useState<number>(DOCUMENT_INFO_TAB_ID);
  const [docVersions, setDocVersions] = useState<{ isLoading: boolean, versions: DocumentVersionRowType[] }>(
    { isLoading: false, versions: [] },
  );
  const isCompletedDocumentExecuted = documentDetails && documentDetails.status === STATUSES.completed;

  const getCurrentDocumentType = (currentDocument: any) => {
    if (currentDocument) {
      if (currentDocument.templates) return COLLECTION_TYPE;
      if (currentDocument.documents) return 'Executed Collection';
      return currentDocument.type;
    }
    return '';
  };

  const getCompletedDocumentVersions = useCallback(async () => {
    setDocVersions({ isLoading: true, versions: [] });
    const documentVersions = await apiErrorHandler(getDocumentVersions, id);
    if (documentVersions) {
      setDocVersions({ isLoading: false, versions: documentVersions.data });
    }
  }, [id]);

  useEffect(() => {
    if (isCompletedDocumentExecuted) {
      getCompletedDocumentVersions();
    }
  }, [isCompletedDocumentExecuted, getCompletedDocumentVersions]);

  return {
    currentDocument,
    doctype: getCurrentDocumentType(currentDocument),
    modalTabs: !isCompletedDocumentExecuted ? DOCUMENT_INFO_MODAL_TABS : DOCUMENT_INFO_MODAL_TABS.concat([{
      id: DOCUMENT_VERSIONS_TAB_ID,
      label: 'Document Versions',
    }]),
    docVersions,
    activeTab,
    setActiveTab,
  };
};

export default useDocumentInfoAndVersionsModal;