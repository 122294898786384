import { FC } from 'react';

import SearchIcon from '@mui/icons-material/Search';

import Tooltip from 'components/Tooltip';

interface IButtonFindOutlineProps {
  onClick: () => void;
  isActive?: boolean;
}

const ButtonFindOutline: FC<IButtonFindOutlineProps> = ({
  onClick,
  isActive = false,
}) => (
  <button
    type="button"
    className={`d-flex align-items-center find-button${isActive ? ' active' : ''}`}
    onClick={onClick}
  >
    <Tooltip title="Search"><SearchIcon /></Tooltip>
    <div className="ms-2 d-none d-md-inline">Search</div>
  </button>
);

export default ButtonFindOutline;