import { ChangeEventHandler, FC, memo, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react';

import EditorFieldView from 'components/Editor/FormView/Fields/EditorFieldView';
import { QUESTION_ANSWER_SELECT } from 'constants/editors';
import { SIGNATURE_TABS } from 'constants/signatures';
import { DOCUMENT_TAB_ID } from 'constants/tabs';
import useFieldsHandler from 'hooks/useFieldsHandler';
import { RootStateType } from 'store/reducers';
import { FieldViewPropsChangeValueHandler, ICustomElement } from 'types/Editor';
import { createFieldNameFromDescendant } from 'utils/createNameConstant';
import {
  getSelectedFieldClassName,
  setSelectedFieldState,
} from 'utils/editorFieldHelpers';
import { getIsSelectField, getIsSignatureField } from 'utils/Fields/fieldsTypeChecker';
import { updateNodeWithoutSubtypes } from 'utils/updateNode';

interface IFormSelectEditableProps extends RenderElementProps {
  readOnly?: boolean;
}

const FormSelectEditable: FC<IFormSelectEditableProps> = ({
  children,
  element,
  readOnly = false,
}) => {
  const dispatch = useDispatch();
  const editor = useSlateStatic();

  const { selectedField } = useSelector((state: RootStateType) => state.editorSlate);

  const [value, setValue] = useState('');

  const { updateFieldsHandler } = useFieldsHandler({ editor });

  useEffect(() => {
    if (element.value !== value) {
      setValue(element.value || '');
    }
  }, [element.value]);

  useEffect(() => {
    const name = createFieldNameFromDescendant(element.children);
    if (element.name !== name) {
      const path = ReactEditor.findPath(editor, element);
      Transforms.setNodes(editor, { name }, { at: path });
    }
  }, [element.children]);

  const onChangeSelectFieldHandler = (newFieldData: Partial<ICustomElement>) => {
    if (readOnly) return;
    if (newFieldData.value) setValue(newFieldData.value);
    updateFieldsHandler(element, newFieldData);
  };

  const onChangeStringValueField: FieldViewPropsChangeValueHandler = (
    newValue: string,
    fieldKey: number,
    trimValue?: boolean,
    currentTab?: SIGNATURE_TABS,
  ) => {
    if (!readOnly) {
      setValue(newValue);
      const path = ReactEditor.findPath(editor, element);
      Transforms.setNodes(editor, { value: newValue, signatureTab: currentTab }, { at: path });
    }
  };

  const onChangeField: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { target } = event;
    if (!readOnly && target) {
      setValue(target.value);
      const path = ReactEditor.findPath(editor, element);
      Transforms.setNodes(editor, { value: target.value }, { at: path });
    }
  };

  const onChangeAttachmentField = (field: Partial<ICustomElement>) => {
    updateNodeWithoutSubtypes(editor, field, element.key);
  };

  const onChangeValueHandler: FieldViewPropsChangeValueHandler = (event, fieldKey, trim, currentTab) => {
    if (getIsSelectField(element.type)) {
      return onChangeSelectFieldHandler(event);
    }
    if (element.answerType === QUESTION_ANSWER_SELECT) {
      return onChangeStringValueField(event.value, fieldKey);
    }
    if (getIsSignatureField(element.type)) {
      return onChangeStringValueField(event, fieldKey, trim, currentTab);
    }
    return onChangeField(event);
  };

  const clickFieldHandler = () => {
    setSelectedFieldState(selectedField, element.key, dispatch);
  };

  return (
    <div
      data-field-key={element.key}
      data-active-tab={DOCUMENT_TAB_ID}
      onClick={clickFieldHandler}
      role="textbox"
      tabIndex={-1}
      contentEditable={false}
      className={
        'form-section__field-label user-select-none align-items-center d-flex position-relative py-2'
        + `${getSelectedFieldClassName(element, selectedField, editor)}`
      }
    >
      <EditorFieldView
        field={element}
        options={element.options || []}
        onChangeValue={onChangeValueHandler}
        onChangeAttachmentField={onChangeAttachmentField}
        readOnlyMode={readOnly}
        showButtons
        styledLabelColors
      />
      <span className="d-none">{children}</span>
    </div>
  );
};

export default memo(FormSelectEditable);