import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import {
  COLLECTION_EXECUTED_TYPE,
  COLLECTION_TYPE,
  DOCUMENT_TYPE,
  FORM_TYPE,
  PDF_TYPE,
} from 'constants/general';
import { RootStateType } from 'store/reducers';
import { ICollectionExecutedDocument } from 'types/CollectionExecuted';
import { ICollectionTemplate } from 'types/MultiTemplate';

const useEnableSmsValidationParameter = (isPublicPage = false) => {
  const { templateDetails, collectionDetails } = useSelector((state: RootStateType) => state.user);
  const { data } = useSelector((state: RootStateType) => state.publicPages);

  const enabledSMSValidation = useMemo(() => {
    const publicTemplate = [DOCUMENT_TYPE, FORM_TYPE, PDF_TYPE].includes(data?.type) ? data : null;
    const publicCollection = [COLLECTION_TYPE, COLLECTION_EXECUTED_TYPE].includes(data?.type) ? data : null;
    const targetTemplate = isPublicPage ? publicTemplate : templateDetails;
    const targetCollection = isPublicPage ? publicCollection : collectionDetails;
    let verifyIdentityParameter = false;
    if (targetTemplate) {
      verifyIdentityParameter = targetTemplate?.page_settings?.verify_identity_via_sms;
    }
    if (targetCollection) {
      if (targetCollection.templates) {
        targetCollection.templates.forEach((templateObject: ICollectionTemplate) => {
          if (templateObject.template.page_settings?.verify_identity_via_sms) {
            verifyIdentityParameter = true;
          }
        });
      } else if (targetCollection.documents) {
        targetCollection.documents.forEach((documentObject: ICollectionExecutedDocument) => {
          if (documentObject.document.page_settings?.verify_identity_via_sms) {
            verifyIdentityParameter = true;
          }
        });
      }
    }

    return verifyIdentityParameter;
  }, [collectionDetails, templateDetails, data, isPublicPage]);

  return enabledSMSValidation;
};

export default useEnableSmsValidationParameter;