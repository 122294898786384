import { URL_ZEN_DESK_SNIPPET, ZEN_DESK_IFRAME_ID, ZEN_DESK_SNIPPET_ID } from 'constants/general';
import { ProfileInfoType } from 'types/userProfile';

export const getPendoInitializingObject = (userInfo: ProfileInfoType) => ({
  visitor: {
    id: userInfo.id,
    email: userInfo.email,
    full_name: `${userInfo.first_name} ${userInfo.last_name}`,
  },

  account: {
    id: userInfo.company?.id,
    name: userInfo.company?.name,
  },
  /* If you know that you are not going to be using the Guides product at all
    (no in-app messages to your end-users), you can set the disableGuides:true */
  disableGuides: false,
  // Do not use cookies for storing any values, defaulting to in-memory storage only.
  disableCookies: true,
});

export const initializeZenDesk = () => {
  const existingZenDesk = document.getElementById(ZEN_DESK_SNIPPET_ID);
  if (!existingZenDesk) {
    const bodyElement = document.querySelector('body');
    const zenDeskScript = document.createElement('script');
    zenDeskScript.src = URL_ZEN_DESK_SNIPPET;
    zenDeskScript.id = ZEN_DESK_SNIPPET_ID;
    bodyElement?.appendChild(zenDeskScript);
  }
  /**
   * We need to wait a little bit while ZenDesk component will be created.
   * Margins can be applied only after that.
   */
  const checkerInterval = setInterval(() => {
    const iframeZenDesk = document.getElementById(ZEN_DESK_IFRAME_ID);
    if (iframeZenDesk) {
      clearInterval(checkerInterval);
      iframeZenDesk.style.display = 'block';
      iframeZenDesk.style.marginBottom = '30px';
      iframeZenDesk.style.marginRight = '26px';
    }
  }, 500);
};

export const removeZenDesk = () => {
  const iframeZenDesk = document.getElementById(ZEN_DESK_IFRAME_ID);
  if (iframeZenDesk) {
    /**
     * Actually we don't remove script and iframe physically.
     * We just hide them and show them if a user relogins in the system.
     */
    iframeZenDesk.style.display = 'none';
  }
};