import { IDocumentIntroAttachments } from 'types/Documents';
import { ICustomElement } from 'types/Editor';

const updateDocumentAttachments = (
  attachmentFields: Partial<ICustomElement>[],
  fieldKey: number,
  newValueArray: string[],
): IDocumentIntroAttachments => {
  const attachments: IDocumentIntroAttachments = {};
  attachmentFields.forEach(({ key, value }) => {
    if (!key) return;
    if (key === fieldKey) {
      attachments[key] = newValueArray;
    } else {
      attachments[key] = value?.split(',') || [];
    }
  });
  return attachments;
};

export default updateDocumentAttachments;