import { Button } from 'reactstrap';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';

const ModalConfirmDialog: React.FC<{
  showDialog: boolean;
  onClose: () => void;
  onConfirm: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  messageText?: string;
  confirmText?: string;
  declineText?: string;
}> = ({
  showDialog,
  onClose = () => null,
  onConfirm = () => null,
  messageText = 'Are you sure? You will not be able to restore this field.',
  confirmText = 'Yes',
  declineText = 'No',
}) => (
  <Dialog
    open={showDialog}
    onClose={onClose}
    aria-labelledby="confirm-dialog"
  >
    <DialogContent>{messageText}</DialogContent>
    <DialogActions>
      <Button color="primary" className="btn-sm w-auto" onClick={onConfirm}>{confirmText}</Button>
      <Button color="primary" outline className="btn-sm" onClick={onClose}>{declineText}</Button>
    </DialogActions>
  </Dialog>
);

export default ModalConfirmDialog;