import { COLLECTION_TYPE, DOCUMENT_TYPE, FORM_TYPE, PDF_TYPE } from 'constants/general';
import { ShareViewModes } from 'constants/shareViewModes';
import { DocumentTypesType } from 'types/Documents';
import { PublicPageDataType } from 'types/PublicPage';
import { isCollectionType } from 'utils/typeChecker';

export const isPDFDocument = (data: PublicPageDataType): boolean => (data?.type === PDF_TYPE);

export const isFormDocument = (data: PublicPageDataType): boolean => (data?.type === FORM_TYPE);

export const getIsCollectionFormBuilder = (data: PublicPageDataType | null): boolean => (
  isCollectionType(data)
  && !!data.form_builder_structure?.length
  && data.view_mode === ShareViewModes.SHARE_MODE_FORM_BUILDER
);

export const getIsDocumentType = (type: DocumentTypesType | undefined): boolean => (type === DOCUMENT_TYPE);

export const getIsPDFDocument = (type: DocumentTypesType | undefined): boolean => (type === PDF_TYPE);

export const getIsFormDocument = (type: DocumentTypesType | undefined): boolean => (type === FORM_TYPE);

export const getIsCollectionDocument = (type: DocumentTypesType | undefined): boolean => (type === COLLECTION_TYPE);

export const getIsFormBuilder = (viewMode: ShareViewModes | undefined) => (
  viewMode === ShareViewModes.SHARE_MODE_FORM_BUILDER
);

export const getIsDocumentWithFormBuilder = (viewMode: ShareViewModes | undefined) => (
  viewMode === ShareViewModes.SHARE_MODE_DOCUMENT_AND_FORM_BUILDER
);

export const getIsCollectionWithDocumentAndFormBuilderView = (
  documentType: DocumentTypesType,
  viewMode: ShareViewModes,
) => (
  documentType === COLLECTION_TYPE && viewMode === ShareViewModes.SHARE_MODE_DOCUMENT_AND_FORM_BUILDER
);