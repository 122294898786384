import { FC } from 'react';

import AutocompleteField from 'components/Modals/MondayComModalWindow/AutocompleteField';
import {
  MondayComIntegrationObjectType,
  MondayComSubitemBoardType,
  SubitemsMappingObjectType,
} from 'types/mondayCom/mondayComBoard';

interface IMondayComSutitemSelect {
  columnId: string;
  currentSubitemsBoard: MondayComSubitemBoardType | undefined,
  currentBoardObject: MondayComIntegrationObjectType | null,
  onChangeSubitemsMappingHandler: (subitemMappingObject: SubitemsMappingObjectType) => void;
}

const MondayComSutitemSelect: FC<IMondayComSutitemSelect> = ({
  columnId,
  currentSubitemsBoard,
  currentBoardObject,
  onChangeSubitemsMappingHandler,
}) => {
  const onChangeSubitemHandler = (value: string | null) => {
    onChangeSubitemsMappingHandler({
      boardId: currentBoardObject?.board.id ?? '',
      columnId,
      subitemName: value,
    });
  };

  return (
    <AutocompleteField
      options={currentSubitemsBoard ? currentSubitemsBoard.items_page.items : []}
      currentValue={
        currentBoardObject?.subitemMapping
          ? currentBoardObject.subitemMapping[columnId]
          : ''
      }
      onChangeHandler={onChangeSubitemHandler}
      inputLabel="Select Subitem"
    />
  );
};

export default MondayComSutitemSelect;