import { Editor, Element } from 'slate';

import type { ListsEditor } from '../types';

/**
 * Returns true if given selected node has the same type/format and style as we check.
 */
export const isListTypeActive = (editor: ListsEditor, format: string, listStyle: string = ''): boolean => {
  if (!editor.selection) {
    return false;
  }

  const matchedCurrentLists = Array.from(Editor.nodes(editor, {
    at: editor.selection,
    match: (node) => (
      Element.isElement(node)
      && node.type === format
      && (node.listStyle || '') === listStyle
    ),
  }));

  return !!matchedCurrentLists.length;
};

export default isListTypeActive;