import { FC } from 'react';

import SimpleSpinner from 'components/Base/SimpleSpinner';

interface IViewMoreButtonParams {
  onClick: () => void;
  isLoading?: boolean;
  isVisible?: boolean;
}

const StripeActionButton: FC<IViewMoreButtonParams> = ({
  onClick,
  children,
  isLoading = false,
  isVisible = true,
}) => {
  if (!isVisible) return null;

  return (
    <button
      type="button"
      onClick={onClick}
      className="stripe-action-button"
      disabled={isLoading}
    >
      {
        isLoading
          ? (
            <SimpleSpinner />
          )
          : children
      }
    </button>
  );
};

export default StripeActionButton;