import {
  RESET_USER_PII_DATA,
  SET_DEFAULT_SIGNATURE_DATA,
  SET_GROUP,
  SET_GROUPS,
  SET_MONDAY_COM_ACCESS_TOKEN,
  SET_REFERRAL_CODE,
  SET_SENDGRID_SETTINGS,
  SET_USER_COMPANY_DATA,
  SET_USER_LIST_DATA,
  SET_USER_LIST_DATA_LOADER,
  SET_USER_PERSONAL_DATA,
  SET_USER_PERSONAL_DATA_PROPERTY,
  SET_USER_PII_DATA,
  SET_USER_PROFILE_DATA,
  SET_USER_SIGNING_CERTIFICATE,
} from 'store/actions/actionTypes';
import { Action } from 'types/redux';
import {
  GroupType,
  PIIDataType,
  ProfileInfoType,
  UserListStoreType,
} from 'types/userProfile';

interface IUserProfileState {
  profileInfo: ProfileInfoType | null;
  companyData: any;
  userList: UserListStoreType;
  groups: GroupType[];
  group: GroupType | null;
  personalData: {
    save_signature: boolean;
    signature_path?: string;
  } | null;
  piiData: PIIDataType[];
  defaultSignatureData: Record<string, string> | null;
  signingCertificates: {
    certificateKeyPath: string | null;
    certificatePath: string | null;
  }
}

const initialState: IUserProfileState = {
  profileInfo: null,
  companyData: null,
  userList: {
    users: [],
    usedSeats: 0,
    totalUsersSeats: 0,
    loading: {
      wasLoaded: false,
    },
  },
  groups: [],
  group: null,
  personalData: null,
  piiData: [],
  defaultSignatureData: null,
  signingCertificates: {
    certificateKeyPath: null,
    certificatePath: null,
  },
};

// eslint-disable-next-line default-param-last
export default (state: IUserProfileState = initialState, { type, payload }: Action) => {
  const updatedData = { ...payload };
  switch (type) {
    case SET_USER_PROFILE_DATA:
      if (updatedData.personal_data) {
        delete updatedData.personal_data;
      }
      return {
        ...state,
        profileInfo: updatedData,
      };
    case SET_USER_COMPANY_DATA:
      return {
        ...state,
        companyData: payload,
      };
    case SET_USER_LIST_DATA:
      return {
        ...state,
        userList: {
          ...state.userList,
          ...payload,
        },
      };
    case SET_USER_LIST_DATA_LOADER:
      return {
        ...state,
        userList: {
          ...state.userList,
          loading: {
            wasLoaded: payload.wasLoaded,
          },
        },
      };
    case SET_GROUPS:
      return {
        ...state,
        groups: payload.groups,
      };
    case SET_GROUP:
      return {
        ...state,
        group: payload.group,
        groups: state.groups.map((group: GroupType) => {
          if (group.id === payload.group.id) {
            return {
              id: payload.group.id,
              company_id: payload.group.company_id,
              creation_date: payload.group.creation_date,
              modification_date: payload.group.modification_date,
              name: payload.group.name,
              users_count: payload.group.users.length,
            };
          }
          return group;
        }),
      };
    case SET_USER_PERSONAL_DATA:
      return {
        ...state,
        personalData: payload,
      };
    case SET_USER_PERSONAL_DATA_PROPERTY:
      return {
        ...state,
        personalData: payload.property ? {
          ...state.personalData,
          [payload.property]: payload.value,
        } : payload,
      };
    case SET_USER_PII_DATA:
      return {
        ...state,
        piiData: payload !== null ? [...state.piiData, ...payload] : payload,
      };
    case RESET_USER_PII_DATA:
      return {
        ...state,
        piiData: payload,
      };
    case SET_SENDGRID_SETTINGS:
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          company: {
            ...state.profileInfo?.company,
            sendgrid_api_key: payload.sendgridApiKey ? payload.sendgridApiKey : null,
            sendgrid_email_from: payload.sendgridEmail ? payload.sendgridEmail : null,
          },
        },
      };
    case SET_DEFAULT_SIGNATURE_DATA:
      return {
        ...state,
        defaultSignatureData: payload,
      };
    case SET_USER_SIGNING_CERTIFICATE:
      return {
        ...state,
        signingCertificates: {
          certificateKeyPath: payload.key_path,
          certificatePath: payload.certificate_path,
        },
      };
    case SET_REFERRAL_CODE:
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          company: {
            ...state.profileInfo?.company,
            referral_code_data: {
              ...state.profileInfo?.company.referral_code_data,
              referral_code: payload,
            },
          },
        },
      };
    case SET_MONDAY_COM_ACCESS_TOKEN:
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          company: {
            ...state.profileInfo?.company,
            monday_com_access_token: payload,
          },
        },
      };
    default:
      return state;
  }
};
