/* eslint-disable */
import { Editor, Element as SlateElement, Transforms, Path, NodeEntry } from 'slate';
import { splitedTable } from '../selection';
import { createRowBody } from 'utils/editorTableHelpers';
import { Col } from 'types/EditorTable';

export const insertBelow = (table: NodeEntry, editor: Editor) => {
  const { selection } = editor;
  if (!selection || !table) return;

  const yIndex = table[1].length;

  const { gridTable, getCol } = splitedTable(editor, table);

  const [startCell]: any = Array.from(Editor.nodes(editor, {
    match: n => SlateElement.isElement(n) && n.type === 'table-cell',
    at: Editor.path(editor, selection),
  }));

  const [insertPositionCol] = getCol(
    (c: Col) => startCell && c.cell.key === startCell[0].key && c.isReal
  );

  let checkInsertEnable = true;
  const insertCols = new Map<string, Col>();

  const y = insertPositionCol.path[yIndex] + (insertPositionCol.cell.rowspan || 1) - 1;

  gridTable[y].forEach((col: Col) => {
    const [originCol] = getCol(
      (n: any) => n.isReal && n.cell.key === col.cell.key
    );

    const { cell, path } = originCol;

    if (!gridTable[y + 1]) {
      insertCols.set(String(cell.key), originCol);
    } else if (path[yIndex] + (cell.rowspan || 1) - 1 === y) {
      insertCols.set(String(cell.key), originCol);
    } else {
      checkInsertEnable = false;
      return;
    }
  });

  if (!checkInsertEnable || !insertCols.size) {
    return;
  }

  let rows: NodeEntry | null = null;
  [rows] = Array.from(
    Editor.nodes(editor, {
      match: n => SlateElement.isElement(n) && n.type === 'table-row',
      at: Editor.path(editor, selection),
    })
  );
  const currentRow: any = rows[0];
  const newRow = createRowBody((currentRow.children).length);

  const [[, path]] = Array.from(Editor.nodes(editor, {
    match: n => SlateElement.isElement(n) && n.type === 'table-row',
    at: Editor.path(editor, selection),
  }));

  for (let i = 1; i < startCell[0].rowspan; i++) {
    path[yIndex] += 1;
  }

  Transforms.insertNodes(editor, newRow, {
    at: Path.next(path),
  });
}