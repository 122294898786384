import { COLLECTION, DOCUMENT, DOCUMENT_EXECUTED } from 'constants/documentTypes';
import { COLLECTION_TYPE, DOCUMENT_TYPE, FORM_TYPE, PDF_TYPE } from 'constants/general';
import {
  DEFAULT_MARGIN_X_IN_INCHES,
  DEFAULT_MARGIN_Y_IN_INCHES,
  PAGE_SETTINGS_MARGIN_KEY,
  PAGE_SETTINGS_MARGINS,
} from 'constants/pageSettings';
import ROUTES from 'constants/routes';
import { RootStateType } from 'store/reducers';
import { DocumentTypesType } from 'types/Documents';
import { PageSettingsType } from 'types/PageSettingsType';

export const getPageSettings = (state: RootStateType): PageSettingsType | null => {
  if (state.user.templateDetails !== null) {
    return state.user.templateDetails?.page_settings ?? null;
  }
  if (state.user.documentDetails !== null) {
    return state.user.documentDetails?.page_settings ?? null;
  }
  if (state.publicPages.currentDocument !== null) {
    return state.publicPages.currentDocument.pageSettings ?? null;
  }
  if (state.user.newDocumentDetails !== null) {
    return state.user.newDocumentDetails?.page_settings ?? null;
  }
  return null;
};

export const getCurrentDocumentTypeByStore = (state: RootStateType): DocumentTypesType | null => {
  if (state.user.templateDetails !== null) {
    return state.user.templateDetails?.type ?? null;
  }
  if (state.user.documentDetails !== null) {
    return state.user.documentDetails?.type ?? null;
  }
  if (state.user.collectionDetails !== null) {
    return COLLECTION_TYPE;
  }
  return null;
};

export const getCurrentDocumentTypeByRoute = (pathname: string): DocumentTypesType | null => {
  switch (pathname) {
    case ROUTES.FORM_EDITOR:
    case ROUTES.FORM_EXECUTED_EDITOR_SINGLE:
      return FORM_TYPE;
    case ROUTES.DOCUMENTS_EDITOR:
    case ROUTES.DOCUMENTS_EXECUTED_EDITOR_SINGLE:
      return DOCUMENT_TYPE;
    case ROUTES.PDF_EDITOR:
    case ROUTES.PDF_EXECUTED_EDITOR_SINGLE:
      return PDF_TYPE;
    case ROUTES.COLLECTIONS_EDITOR:
      return COLLECTION_TYPE;
    default:
      return null;
  }
};

export const getDocumentTypeForPageSettingsFromStorePath = (
  state: RootStateType,
): typeof DOCUMENT | typeof DOCUMENT_EXECUTED | typeof COLLECTION | null => {
  if (state.user.templateDetails !== null) {
    return DOCUMENT;
  }
  if (state.user.documentDetails !== null) {
    return DOCUMENT_EXECUTED;
  }
  if (state.user.collectionDetails !== null) {
    return COLLECTION;
  }
  return null;
};

export const getMarginsForPageInInches = (pageSettings: PageSettingsType | null) => {
  const margins: string[] = PAGE_SETTINGS_MARGIN_KEY.map((key) => {
    if (pageSettings && key in pageSettings) {
      return `${pageSettings[key]}in`;
    }
    if (key === PAGE_SETTINGS_MARGINS.Top || key === PAGE_SETTINGS_MARGINS.Bottom) {
      return `${DEFAULT_MARGIN_Y_IN_INCHES}in`;
    }
    return `${DEFAULT_MARGIN_X_IN_INCHES}in`;
  });

  return margins.join(' ');
};

export const getPageSettingByKey = (pageSettings: PageSettingsType | null, key: string) => {
  if (!pageSettings || !(key in pageSettings)) return null;
  return pageSettings[key];
};