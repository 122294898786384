import { ADMIN_ROLES, ROLES } from 'constants/roles';
import ROUTES from 'constants/routes';
import BillingIcon from 'svg/SettingIcons/BillingIcon';
import GroupIcon from 'svg/SettingIcons/GroupIcon';
import IntegrationIcon from 'svg/SettingIcons/IntegrationIcon';
import LinkIcon from 'svg/SettingIcons/LinkIcon';
import LogoutIcon from 'svg/SettingIcons/LogoutIcon';
import NotificationsIcon from 'svg/SettingIcons/NotificationsIcon';
import ProfileIcon from 'svg/SettingIcons/ProfileIcon';
import UserManagementIcon from 'svg/SettingIcons/UserManagementIcon';
import { IUserMenuItem } from 'types/UserMenu';

export const enum PROFILE_SECTIONS {
  PROFILE = 'Profile',
  CHANGE_PASSWORD = 'Change Password',
  DEFAULT_SIGNATURE = 'Default Signature',
  ORGANIZATION = 'Organization',
  BRANDING = 'Branding',
  USER_MANAGEMENT = 'User Management',
  GROUP_MANAGEMENT = 'Group Management',
  LOGOUT = 'Logout',
  HELP = 'Help',
  NOTIFICATIONS = 'Notifications',
  SENDGRID_SETTINGS = 'SendGrid Settings',
  BILLING = 'Billing',
  SECURITY = 'Security',
  REFERRAl_LINK = 'Referral Link',
  INTEGRATIONS = 'Integrations'
}

const USER_MENU_CONFIG: IUserMenuItem[] = [
  {
    name: PROFILE_SECTIONS.HELP,
    isHelpButton: true,
  },
  {
    name: PROFILE_SECTIONS.REFERRAl_LINK,
    path: ROUTES.REFERRAL_LINK,
    IconComponent: LinkIcon,
    permissions: ADMIN_ROLES,
  },
  {
    name: PROFILE_SECTIONS.PROFILE,
    path: ROUTES.PROFILE,
    IconComponent: ProfileIcon,
  },
  {
    name: PROFILE_SECTIONS.ORGANIZATION,
    path: ROUTES.ORGANIZATION,
    IconComponent: GroupIcon,
  },
  {
    name: PROFILE_SECTIONS.USER_MANAGEMENT,
    path: ROUTES.USER_MANAGEMENT,
    IconComponent: UserManagementIcon,
  },
  {
    name: PROFILE_SECTIONS.GROUP_MANAGEMENT,
    path: ROUTES.GROUP_MANAGEMENT,
    IconComponent: GroupIcon,
    permissions: ADMIN_ROLES,
  },
  {
    name: PROFILE_SECTIONS.BRANDING,
    path: ROUTES.BRANDING,
    IconComponent: GroupIcon,
    permissions: ADMIN_ROLES,
  },
  {
    name: PROFILE_SECTIONS.NOTIFICATIONS,
    path: ROUTES.NOTIFICATIONS,
    IconComponent: NotificationsIcon,
    permissions: ADMIN_ROLES.concat(ROLES.USER),
  },
  {
    name: PROFILE_SECTIONS.BILLING,
    path: ROUTES.BILLING,
    IconComponent: BillingIcon,
    permissions: ADMIN_ROLES,
  },
  {
    name: PROFILE_SECTIONS.INTEGRATIONS,
    path: ROUTES.INTEGRATIONS,
    IconComponent: IntegrationIcon,
    permissions: ADMIN_ROLES,
  },
  // It's hided because there is nothing to show inside it for now.
  // {
  //   name: PROFILE_SECTIONS.SECURITY,
  //   path: ROUTES.SECURITY_SETTINGS,
  //   IconComponent: ShieldIcon,
  // },
  {
    name: PROFILE_SECTIONS.LOGOUT,
    IconComponent: LogoutIcon,
  },
];

export default USER_MENU_CONFIG;