import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';

import Button from 'components/Base/Button';
import AssignmentsSettingsRow from 'components/Modals/AssignmentsSettingsModal/AssignmentsSettingsRow';
import { CLOSE } from 'constants/general';
import useAssignmentsSettings from 'hooks/AssignmentsSettings/useAssignmentsSettings';
import { AssignmentType } from 'types/Editor';

import 'scss/components/TabNavigation/TabNavigation.scss';

const AssignmentsSettingsModal = () => {
  const {
    isClosedButtonDisabled,
    openAssignmentsSettingsModal,
    closeAssignmentsSettingsModalHandler,
    unitedSortedAssignmentsArray,
    addAssignmentHandler,
    deleteAssignmentHandler,
    changeAssignmentLabelHandler,
    changeAssignmentTypeHandler,
  } = useAssignmentsSettings();

  return (
    <Modal
      centered
      isOpen={openAssignmentsSettingsModal}
      contentClassName="mx-auto"
    >
      <ModalHeader
        className="modal-header border-0"
        toggle={isClosedButtonDisabled ? undefined : closeAssignmentsSettingsModalHandler}
      >
        Customize Assignees
      </ModalHeader>
      <ModalBody className="p-4 pt-0">
        {
          unitedSortedAssignmentsArray.map((assignment: AssignmentType) => (
            <AssignmentsSettingsRow
              key={`AssignmentSettings_${assignment.type}`}
              assignment={assignment}
              changeAssignmentLabelHandler={changeAssignmentLabelHandler}
              changeAssignmentTypeHandler={changeAssignmentTypeHandler}
              deleteAssignmentHandler={deleteAssignmentHandler}
            />
          ))
        }
        <IconButton
          color="primary"
          onClick={addAssignmentHandler}
        >
          <AddCircleIcon />
        </IconButton>
        Add assignment
      </ModalBody>
      <ModalFooter>
        <div className="d-flex align-items-center justify-content-center w-100 gap-4">
          <Button
            disabled={isClosedButtonDisabled}
            classes="outline outlined-with-border"
            onClick={closeAssignmentsSettingsModalHandler}
          >
            {CLOSE}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AssignmentsSettingsModal;