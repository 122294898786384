import { ITab, PublicPageNavigation } from 'types/Tabs';

export const HEADER_TABS_DASHBOARD: string[] = ['dashboard'];
export const HEADER_TABS_DOCUMENTS: string[] = ['documents'];
export const HEADER_TABS_PDFS: string[] = ['PDFs'];
export const HEADER_TABS_SECTIONS: string[] = ['sections'];
export const HEADER_TABS_FORMS: string[] = ['forms'];
export const HEADER_TABS_COLLECTIONS: string[] = ['collections'];

// Public pages constants
export const PUBLIC_HEADER_TABS = [];
export const DOCUMENT_TAB = 'Document';
export const FORM_TAB = 'Form';
export const ATTACHMENTS_TAB = 'Attachments';

export const DOCUMENT_TAB_ID = 1;
export const FORM_TAB_ID = 2;
export const ATTACHMENTS_TAB_ID = 3;

export const DOCUMENT_TAB_ELEMENT: ITab = {
  id: DOCUMENT_TAB_ID,
  label: DOCUMENT_TAB,
};

export const FORM_TAB_ELEMENT: ITab = {
  id: FORM_TAB_ID,
  label: FORM_TAB,
};

export const EDITOR_PAGE_TABS: PublicPageNavigation = [
  DOCUMENT_TAB_ELEMENT,
  {
    id: ATTACHMENTS_TAB_ID,
    label: ATTACHMENTS_TAB,
  },
  FORM_TAB_ELEMENT,
];

export const LINK_TAB: ITab = { id: 1, label: 'Link' };
export const EMBED_TAB: ITab = { id: 2, label: 'Embed' };
export const LINK_TAB_ID = 1;