import { ChangeEvent, useEffect, useState } from 'react';

import { FormGroup, Input, Label } from 'reactstrap';

import { FIELD_MASK_CUSTOM_TEXT_DEFAULT, TEXT_FIELD_MASKS } from 'constants/fieldPropertiesTab';
import { ICustomElement } from 'types/Editor';
import { PDFFieldType } from 'types/PdfTemplates';

const useTextFieldMask = (
  currentField: Partial<ICustomElement | PDFFieldType>,
  updateFieldCallBack: (props: { updatedField: Partial<ICustomElement | PDFFieldType> }) => void,
) => {
  const [textFieldMask, setTextFieldMask] = useState<string>(currentField?.textFieldMask || TEXT_FIELD_MASKS.NONE);
  const [
    textFieldMaskCustomText,
    setTextFieldMaskCustomText,
  ] = useState<string>(currentField?.textFieldMaskCustomText || FIELD_MASK_CUSTOM_TEXT_DEFAULT);

  useEffect(() => {
    if (currentField && currentField.textFieldMask) {
      setTextFieldMask(currentField.textFieldMask);
    }
    if (currentField && currentField.textFieldMaskCustomText) {
      setTextFieldMaskCustomText(currentField.textFieldMaskCustomText);
    }
    return () => {
      setTextFieldMask(TEXT_FIELD_MASKS.NONE);
      setTextFieldMaskCustomText(FIELD_MASK_CUSTOM_TEXT_DEFAULT);
    };
  }, [currentField?.key]);

  const updateTextFieldMask = (event: ChangeEvent<HTMLSelectElement>): void => {
    const maskValue = event.target.value as TEXT_FIELD_MASKS;
    setTextFieldMask(maskValue);
    const updatedField = {
      ...currentField,
      textFieldMask: maskValue,
      ...(maskValue === TEXT_FIELD_MASKS.CUSTOM_TEXT ? { textFieldMaskCustomText } : {}),
    };
    updateFieldCallBack({ updatedField });
  };

  const updateTextFieldMaskCustomText = (event: ChangeEvent<HTMLInputElement>): void => {
    const maskCustomTextValue = event.target.value;
    setTextFieldMaskCustomText(maskCustomTextValue);
    const updatedField = {
      ...currentField,
      textFieldMaskCustomText: maskCustomTextValue,
    };
    updateFieldCallBack({ updatedField });
  };

  const getTextFieldCustomInput = () => (
    <FormGroup>
      <Label for="customMaskText" className="mb-0">Custom Mask Text</Label>
      <Input
        type="text"
        name="customMaskText"
        value={textFieldMaskCustomText}
        onChange={updateTextFieldMaskCustomText}
        placeholder="Type a custom mask text"
      />
    </FormGroup>
  );

  return {
    textFieldMask,
    updateTextFieldMask,
    getTextFieldCustomInput,
  };
};

export default useTextFieldMask;