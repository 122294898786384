import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import { SUCCESS } from 'constants/api';
import { getStripeInvoiceHistory } from 'services/api/payment';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { IStripeInvoice, IStripeInvoicesResponse } from 'types/payment/Stripe';

const useStripeInvoices = () => {
  const [invoices, setInvoices] = useState<IStripeInvoice[]>([]);
  const [isMoreButtonIsVisible, setIsMoreButtonIsVisible] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getInvoiceHistory = async (limit: number = 3, callback: () => void = () => undefined) => {
    setIsLoading(true);
    const response = await apiErrorHandler<
      IStripeInvoicesResponse,
      number
    >(getStripeInvoiceHistory, limit);
    if (response.code === SUCCESS) {
      setInvoices(response.data.invoices);
      callback();
    } else {
      toast.error('Something went wrong. Can\'t get Invoices');
    }
    setIsLoading(false);
  };

  const getAllInvoices = () => {
    getInvoiceHistory(100, () => {
      setIsMoreButtonIsVisible(false);
    });
  };

  useEffect(() => {
    getInvoiceHistory();
  }, []);

  return {
    invoices,
    isLoading,
    isMoreButtonIsVisible,
    getAllInvoices,
  };
};

export default useStripeInvoices;