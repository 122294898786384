/* eslint-disable */
import { Editor, Element as SlateElement, Transforms, Editor as SlateEditor } from 'slate';
import { HEADERS_MARKS, PARAGRAPH, SECTION } from 'constants/editors';

export const withHeaders = (editor: SlateEditor) => {
  const { insertBreak } = editor;

  editor.insertBreak = () => {
    const { selection } = editor;

    if (selection) {
      const [header]: any = Editor.nodes(editor, {
        match: (node) =>
          !Editor.isEditor(node) &&
          SlateElement.isElement(node) &&
          HEADERS_MARKS.includes(node.type)
      });

      if (header) {
        insertBreak();
        Transforms.setNodes(editor, { type: PARAGRAPH }, {
          mode: 'all',
          match: (node) =>
            !Editor.isEditor(node) &&
            SlateElement.isElement(node) &&
            node.type !== SECTION
        });
        return;
      }
    }
    insertBreak();
  }

  return editor;
}