import { FC, ReactNode } from 'react';

import { Button as MuiButton, ButtonProps } from '@mui/material';

import styled from '@emotion/styled';

interface ISystemButton extends ButtonProps {
  classes?: string;
  style?: Record<string, string>;
  variant?: 'text' | 'contained' | 'outlined' | undefined;
  size?: 'small' | 'medium' | 'large' | undefined;
  endIcon?: ReactNode;
  startIcon?: ReactNode;
  onClick: () => void;
  disabled?: boolean;
}

const StyledButton = styled(MuiButton)<ISystemButton>`
  &.button {
    &:hover {
      box-shadow: ${(props) => props.style?.hoverBoxShadow || ''}
    }
  }
`;

const Button: FC<ISystemButton> = ({
  classes = '',
  style = {},
  variant = 'contained',
  size = 'medium',
  endIcon = null,
  startIcon = null,
  children,
  onClick,
  disabled = false,
}) => (
  <StyledButton
    className={`button ${classes}`}
    variant={variant}
    size={size}
    endIcon={endIcon}
    startIcon={startIcon}
    onClick={onClick}
    disabled={disabled}
    style={style}
  >
    {children}
  </StyledButton>
);

export default Button;