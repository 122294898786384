import { FC } from 'react';

import Menu from 'components/AccountSettings/Menu';

interface IAccountSettingsWrapperProps {
  fluidWrapper?: boolean;
}

const AccountSettingsWrapper: FC<IAccountSettingsWrapperProps> = ({
  children,
  fluidWrapper,
}) => (
  <div className="container-fluid py-4 settings-page">
    <div className="row h-100">
      <div className="d-none d-md-block col-4 col-xl-2">
        <Menu />
      </div>
      <div className="col-12 col-md-8 col-xl-8 pb-5">
        <div className={`settings-wrapper mx-auto ${fluidWrapper ? 'settings-wrapper--fluid' : ''}`}>
          {children}
        </div>
      </div>
    </div>
  </div>
);

export default AccountSettingsWrapper;