import { toast } from 'react-toastify';
import { Dispatch } from 'redux';

import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { LOCAL_USER_IP_LABEL, TIMEZONE, USER_IP_LABEL, USER_LOCATION } from 'constants/general';
import { AUTHENTICATE_USER } from 'store/actions/actionTypes';
import { setAuthInStorage } from 'utils/localStorage';

const { REACT_APP_API_URL } = process.env;
export const ACCESS_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';

let cancelTokenSource = axios.CancelToken.source();

export const handleCancelRequest = () => {
  cancelTokenSource.cancel();
  cancelTokenSource = axios.CancelToken.source();
};

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: REACT_APP_API_URL,
  cancelToken: cancelTokenSource.token,
});

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  const configCopy = { ...config };
  configCopy.headers[USER_IP_LABEL] = localStorage.getItem(LOCAL_USER_IP_LABEL);
  const userLocation = localStorage.getItem(USER_LOCATION);
  configCopy.headers[TIMEZONE] = userLocation ? JSON.parse(userLocation)?.localTimeZone : '';
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  // If we get 401 error, we delete expired access token from store, send request refresh endpoint with refresh token
  configCopy.headers.Authorization = `Bearer ${accessToken ?? localStorage.getItem(REFRESH_TOKEN)}`;
  cancelTokenSource = axios.CancelToken.source();
  configCopy.cancelToken = cancelTokenSource.token;
  return configCopy;
});

export const setupAxiosInterceptors = (dispatch: Dispatch) => {
  axiosInstance.interceptors.response.use(
    (res) => {
      /**
       * If there is object with tokens, it means that a user uses passwordless authentication.
       * We need to login a user automatically.
      */
      const resposeData = res.data?.data ?? {};
      if (resposeData.auth) {
        if (resposeData.auth.login) {
          setAuthInStorage(resposeData.auth);
          dispatch({ type: AUTHENTICATE_USER, payload: true });
        } else {
          toast.info(resposeData.auth.message);
          if (resposeData.auth.dispatch_action) {
            setTimeout(() => {
              dispatch({ type: resposeData.auth.dispatch_action });
            }, 0);
          }
        }
      }
      return res.data;
    },
    (err) => {
      console.log(err);
      if (axios.isCancel(err)) {
        console.log(err);
      } else {
        return Promise.reject(err?.response);
      }
    },
  );
};

export default axiosInstance;