import { useEffect, useRef } from 'react';

const usePreviousValue = (value: any, initialValue: any) => {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default usePreviousValue;
