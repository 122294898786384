import { FC } from 'react';

import { Element } from 'slate';

import { SelectUpdateEventHandler } from 'types/Editor';

interface ISelectElementProps {
  element: Element;
  value?: string;
  onChangeSelectField: SelectUpdateEventHandler;
  isReadOnly: boolean;
}

const SelectElement: FC<ISelectElementProps> = ({
  element,
  value,
  onChangeSelectField,
  isReadOnly,
}) => (
  <select
    className="select-field-editable"
    name={element.name}
    data-value={value || ''}
    data-field="true"
    value={value || ''}
    onChange={onChangeSelectField}
    disabled={isReadOnly}
    data-added-other-option={element.addedOtherOption}
    data-selected-other-option={element.selectedOtherOption}
    tabIndex={isReadOnly ? -1 : 0}
  >
    <option value="" />
    {
      element.options?.map(({ id, label, isSelectOtherOption }) => (
        <option
          key={id}
          value={label}
          data-id={id}
          data-is-other-option={isSelectOtherOption}
        >
          {label}
        </option>
      ))
    }
  </select>
);

export default SelectElement;