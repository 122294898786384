import { ElementType, FC, forwardRef, memo, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import CalendarMonth from '@mui/icons-material/CalendarMonth';
import { Input, InputAdornment, TextFieldProps } from '@mui/material';
import { useSlateStatic } from 'slate-react';

import DateCalendar from 'components/Base/DateCalendar';
import NavigationArrows from 'components/Editor/components/DocumentNavigationArrows/NavigationArrows';
import ErrorValidation from 'components/Editor/components/ErrorValidation';
import FieldWrapper from 'components/Editor/components/FieldWrapper';
import { IRenderElementPropsWithValidation } from 'components/Editor/components/RenderElement';
import SlateFieldFiller from 'components/Editor/components/SlateFieldFiller';
import FieldToolbar from 'components/Editor/components/Toolbars/FieldToolbar';
import { DOCUMENT_TAB_ID } from 'constants/tabs';
import useFieldsHandler from 'hooks/useFieldsHandler';
import { useFormFields } from 'hooks/useFormFields';
import { RootStateType } from 'store/reducers';
import { createFieldDataAttributes } from 'utils/editorCopyPasteHelpers';
import {
  getCurrentDate,
  getGroupedFieldKeys,
  getSelectedFieldClass,
  isFieldHasSubtype,
  setSelectedFieldState,
  shouldApplyTodaysDate,
} from 'utils/editorFieldHelpers';
import { getDefaultDateMask } from 'utils/editorHelpers';
import { getFieldFontStyles } from 'utils/Fields/fieldFontSizeHelpers';
import { cleanTableQuestionSelection } from 'utils/TableQuestion/fieldsTableQuestionHelpers';
import { updateFieldNameAndProps } from 'utils/updateNode';
import { validateFieldHelper } from 'utils/validation';

const InputWrapper: ElementType = forwardRef((props, ref) => {
  const { children, ...params } = props;
  const newParams = { ...params };
  if (newParams.ownerState) {
    delete newParams.ownerState;
  }
  if (newParams.classes) {
    delete newParams.classes;
  }
  return <span {...newParams} ref={ref} tabIndex={-1}>{children}</span>;
});

const DateEditable: FC<IRenderElementPropsWithValidation> = ({
  attributes,
  children,
  element,
  readOnlyMode = false,
  isError = false,
  validationErrorText = null,
  filledInFields = undefined,
  isPublicPage = undefined,
}) => {
  const editor = useSlateStatic();
  const dispatch = useDispatch();
  const { selectedField, selectedTableQuestionKey } = useSelector((state: RootStateType) => state.editorSlate);
  const { main } = useSelector((state: RootStateType) => state.publicPages.structure);
  const [value, setValue] = useState<string>(element.value || '');
  const [dateMask, setDateMask] = useState<string>(getDefaultDateMask(element.dateMask));
  const [showToolbar, setShowToolbar] = useState<boolean>(false);

  const { updateFieldsHandler } = useFieldsHandler({ editor });

  const forms = useFormFields(true, false);

  useEffect(() => {
    if (element.value !== value) {
      setValue(element.value || '');
    }
  }, [element.value]);

  useEffect(() => {
    if (element.dateMask !== dateMask) {
      setDateMask(getDefaultDateMask(element.dateMask));
    }
  }, [element.dateMask]);

  useEffect(() => {
    if (
      editor.publicPage
      && element.isTodaysDate
      && !element.value
      && shouldApplyTodaysDate(element.assignment, main?.mainAssignment)
    ) {
      const todayValue = getCurrentDate();
      setValue(todayValue);
      if (element.value !== todayValue) {
        setTimeout(() => {
          updateFieldsHandler(element, { value: todayValue, isTodaysDate: false });
        }, 0);
      }
    }
  }, [element, editor]);

  useEffect(() => {
    updateFieldNameAndProps(editor, element);
  }, [element.children]);

  const onChangeDateField = (newValue: string) => {
    if (!readOnlyMode || !element.isReadOnly) {
      validateFieldHelper({
        field: element,
        prevValue: value || '',
        value: newValue,
        dispatch,
      });
      setValue(newValue);

      updateFieldsHandler(element, { value: newValue });
    }
  };

  const clickFieldHandler = () => {
    setSelectedFieldState(selectedField, element.key, dispatch);
    cleanTableQuestionSelection(dispatch, element?.isTableField, selectedTableQuestionKey);
  };

  const hoverFieldHandler = () => {
    if (!editor.publicPage && (!readOnlyMode || !element.isReadOnly) && !element?.isTableField) {
      setShowToolbar(true);
    }
  };

  const leaveFieldHandler = () => {
    setShowToolbar(false);
  };

  const renderInputComponent = ({ disabled, inputProps, inputRef, label, ref }: TextFieldProps) => {
    const inputParams = { disabled, inputProps, inputRef, label, ref };
    return (
      <Input
        {...inputParams}
        ref={inputParams.inputRef}
        name={element.name}
        type="text"
        autoComplete="off"
        required={false}
        error={false}
        readOnly
        className="text-field-editable date-field"
        components={{
          Root: InputWrapper,
        }}
        endAdornment={(
          <InputAdornment component="span" position="end">
            <CalendarMonth />
          </InputAdornment>
        )}
        tabIndex={readOnlyMode || element.isReadOnly ? -1 : 0}
      />
    );
  };

  const hasSubtype = !editor.publicPage && isFieldHasSubtype(element.subtype);
  const idsToSelect = getGroupedFieldKeys(forms, selectedField);
  const selectedFieldClass = getSelectedFieldClass(idsToSelect, element.key, hasSubtype);

  return (
    <span
      className={`custom-subtype-highlight inline-field-wrapper ${showToolbar ? 'blue-bg' : ''}${selectedFieldClass}`}
      {...(createFieldDataAttributes({
        field: element,
        attributes,
        activeTab: DOCUMENT_TAB_ID,
      }))}
      onClick={clickFieldHandler}
      onFocus={hoverFieldHandler}
      onMouseOver={hoverFieldHandler}
      onBlur={leaveFieldHandler}
      onMouseLeave={leaveFieldHandler}
      role="textbox"
      tabIndex={-1}
      style={getFieldFontStyles(children, element.fontSize)}
    >
      <NavigationArrows field={element} isPublicPage={isPublicPage} filledInFields={filledInFields}>
        <>
          {showToolbar && <FieldToolbar fieldKey={element.key} />}
          <SlateFieldFiller inline />
          <FieldWrapper
            assignment={element.assignment}
            fieldChildren={children}
            labelConfiguration={element.labelConfiguration}
            isReadOnly={readOnlyMode}
            element={element}
            isPublicPage={isPublicPage}
          >
            <span
              contentEditable={false}
              style={{
                userSelect: 'none',
                position: 'relative',
              }}
              className="me-1 field-editable"
            >
              <DateCalendar
                fieldValue={value}
                dateMask={dateMask}
                isReadOnlyMode={readOnlyMode || element.isReadOnly}
                onChangeDateValue={onChangeDateField}
                inputComponent={renderInputComponent}
              />
              <ErrorValidation isError={isError} errorText={validationErrorText} />
            </span>
          </FieldWrapper>
        </>
      </NavigationArrows>
    </span>
  );
};

export default memo(DateEditable);