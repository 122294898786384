import { all, AllEffect, ForkEffect } from 'redux-saga/effects';

import authSagas from 'store/sagas/auth';
import collectionsExecuted from 'store/sagas/collectionsExecuted';
import companyBrandingSagas from 'store/sagas/companyBranding';
import emailsNotificationsSagas from 'store/sagas/emailsNotifications';
import mondayComIntegrationSagas from 'store/sagas/mondayComIntegration';
import multiTemplatesSagas from 'store/sagas/multiTemplates';
import pageSettings from 'store/sagas/pageSettings';
import persistentReduxStorageSagas from 'store/sagas/persistentReduxStorage';
import publicPagesSagas from 'store/sagas/publicPages';
import publicPageViewer from 'store/sagas/publicPageViewer';
import sendDocumentsSagas from 'store/sagas/sendDocuments';
import serverSideEventsSaga from 'store/sagas/serverSideEventsSaga';
import signNowSagas from 'store/sagas/signNow';
import smsValidation from 'store/sagas/smsValidation';
import userDataSagas from 'store/sagas/userData';
import userPersonalDataSagas from 'store/sagas/userPersonalData';
import userProfileSagas from 'store/sagas/userProfile';

function* rootSaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
  yield all([
    ...authSagas,
    ...userDataSagas,
    ...userPersonalDataSagas,
    ...userProfileSagas,
    ...publicPagesSagas,
    ...multiTemplatesSagas,
    ...sendDocumentsSagas,
    ...companyBrandingSagas,
    ...emailsNotificationsSagas,
    ...persistentReduxStorageSagas,
    ...publicPageViewer,
    ...pageSettings,
    ...mondayComIntegrationSagas,
    ...signNowSagas,
    ...serverSideEventsSaga,
    ...collectionsExecuted,
    ...smsValidation,
  ]);
}

export default rootSaga;