import { FC } from 'react';

import { CSVLink } from 'react-csv';

import { CSVDataType, CSVHeaderType } from 'hooks/useCSVData';

interface IExportCSVButton {
  headers: CSVHeaderType[];
  data: CSVDataType[];
  attachmentsList: string[];
  downloadAttachmentsZIP: (attachmentsList: string[]) => Promise<void>;
  getNameForGeneratedFiles: () => void;
}

const ExportCSVButton: FC<IExportCSVButton> = ({
  headers,
  data,
  attachmentsList,
  downloadAttachmentsZIP,
  getNameForGeneratedFiles,
}) => (
  <button
    type="button"
    className="dropdown-item editor-header-dropdown-item"
  >
    <CSVLink
      className="editor-header-download-csv"
      data={data}
      headers={headers}
      filename={`${getNameForGeneratedFiles()}.csv`}
      asyncOnClick
      onClick={(event, done) => {
        downloadAttachmentsZIP(attachmentsList);
        done();
      }}
    >
      Export to Excel
    </CSVLink>
  </button>
);

export default ExportCSVButton;