/* eslint-disable */
import { useState, useEffect, MutableRefObject } from "react";

export const useOutsideToolbar = (
  toolbarRef: MutableRefObject<HTMLDivElement | null>,
  toolbarInputRef: MutableRefObject<HTMLInputElement | null>,
  initialOutside: boolean,
) => {

  const [oustsideToolbar, setOutsideToolbar] = useState<boolean>(initialOutside);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (toolbarRef.current && !toolbarRef.current.contains(event.target)) {
        toolbarInputRef.current?.removeAttribute("data-focus");
        return setOutsideToolbar(true);
      }

      event.preventDefault();
      setOutsideToolbar(false);

      if(event.target.tagName === "INPUT") event.target.focus();
      else toolbarInputRef.current?.blur();
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toolbarRef, toolbarInputRef]);

  useEffect(() => setOutsideToolbar(initialOutside), [initialOutside]);

  return oustsideToolbar;
}