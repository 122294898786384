import { Dispatch, FC, memo, ReactNode, SetStateAction } from 'react';

import { Document, pdfjs } from 'react-pdf';

import PdfLoaderMessage from 'components/Editor/components/pdfRenderer/PdfLoaderMessage';
import SyncLoader from 'components/SyncLoader';

interface IDocumentWrapperParams {
  fileLink: string;
  setNumPages: Dispatch<SetStateAction<number | null>>;
  isCollectionPage: boolean;
  children: ReactNode;
}

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/static/js/pdf.worker.min.js`;

const DocumentWrapper: FC<IDocumentWrapperParams> = ({
  fileLink,
  setNumPages,
  children,
  isCollectionPage,
}) => {
  const loaderMessage = (isCreating = true) => {
    if (isCollectionPage) {
      return isCreating ? <span /> : <SyncLoader small />;
    }

    return (
      <PdfLoaderMessage title="Loading the PDF..." />
    );
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number | null }) => {
    setNumPages(numPages);
  };

  const loadingMessage = loaderMessage();
  const noDataMessage = loaderMessage(true);

  return (
    <Document
      file={fileLink}
      onLoadSuccess={onDocumentLoadSuccess}
      loading={loadingMessage}
      noData={noDataMessage}
    >
      {children}
    </Document>
  );
};

export default memo(DocumentWrapper);