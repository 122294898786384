import { Dispatch } from 'redux';

import { DEFAULT_SECTION } from 'constants/editors';
import { EDITOR_PAGE_TABS } from 'constants/tabs';
import { cleanAssignments, setActiveEditorTab } from 'store/actions/editorSlate';
import { updateTemplateSections } from 'store/actions/template';

const setDefaultPageValuesHelper = (
  dispatch: Dispatch,
  isSectionLightOn: boolean = false,
) => {
  dispatch(cleanAssignments());
  dispatch(setActiveEditorTab(EDITOR_PAGE_TABS[0].id));
  if (isSectionLightOn) {
    dispatch(updateTemplateSections([DEFAULT_SECTION]));
  }
};

export default setDefaultPageValuesHelper;