import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import AccountSettingsWrapper from 'components/AccountSettings/AccountSettingsWrapper';
import LogoInput from 'components/AccountSettings/Branding/LogoInput';
import SettingsLayout from 'components/AccountSettings/SettingsLayout';
import Button from 'components/Base/Button';
import { DEFAULT_COLOR_SCHEMA } from 'constants/brandingColorSchema';
import { RESET, UPDATE } from 'constants/general';
import useStyleSchemaInputs from 'hooks/useStyleSchemaInputs';
import { getCompanyBranding, updateCompanyColorSchema } from 'store/actions/companyBranding';
import { RootStateType } from 'store/reducers';
import { UseStyleSchemaInputsReturnsType } from 'types/BrandingColorSchema';
import { ICompanyBranding } from 'types/CompanyBranding';

import 'cropperjs/dist/cropper.css';

const CompanyBranding = () => {
  const dispatch = useDispatch();

  const { logoPath, styleJSON }: ICompanyBranding = useSelector((state: RootStateType) => state.companyBranding);

  useEffect(() => {
    if (!logoPath || !styleJSON) {
      dispatch(getCompanyBranding());
    }
  }, []);

  const [
    colorSchema,
    inputs,
  ]: UseStyleSchemaInputsReturnsType = useStyleSchemaInputs(styleJSON ?? DEFAULT_COLOR_SCHEMA);

  const onClickUpdateHandler = async () => {
    dispatch(updateCompanyColorSchema(colorSchema));
  };

  const onClickResetHandler = async () => {
    dispatch(updateCompanyColorSchema(DEFAULT_COLOR_SCHEMA));
  };

  return (
    <AccountSettingsWrapper>
      <SettingsLayout title="Company Logo">
        <LogoInput logoPath={logoPath} />
      </SettingsLayout>
      <SettingsLayout title="Color Schema">
        {inputs.map((Input: JSX.Element | null) => Input || null)}
      </SettingsLayout>
      <Button classes="me-3" onClick={onClickUpdateHandler}>
        {UPDATE}
      </Button>
      <Button classes="outline" onClick={onClickResetHandler}>
        {RESET}
      </Button>
    </AccountSettingsWrapper>
  );
};

export default CompanyBranding;
