import { FC } from 'react';

import moment from 'moment';

import Button from 'components/Base/Button';
import { downloadAttachment } from 'services/api';
import { apiErrorHandler } from 'services/apiErrorHandler';

interface IDocumentVersionRow {
  versionInfo: {
    key: string;
    last_modified: string;
  }
}

const DocumentVersionRow: FC<IDocumentVersionRow> = ({
  versionInfo,
}) => {
  const getFileNameFromKey = (fileKey: string) => {
    const keyArray = fileKey.split('/');
    return keyArray[keyArray.length - 1];
  };

  const handleDownloadFile = async (fileUrl: string) => {
    await apiErrorHandler(downloadAttachment, { path: fileUrl, returnErrorResult: true });
  };

  return versionInfo.key ? (
    <div className="row document-info-row">
      <div className="col-6 p-0">
        <Button classes="button-simple d-block" onClick={() => handleDownloadFile(versionInfo.key)}>
          {getFileNameFromKey(versionInfo.key)}
        </Button>
      </div>
      <div className="col-6">
        {moment(versionInfo.last_modified).format('LL')}
      </div>
    </div>
  ) : null;
};

export default DocumentVersionRow;