import { FC, useCallback, useMemo } from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Editor } from 'slate';

import TableQuestionToolbarButton from 'components/Editor/components/Fields/TableQuestion/TableQuestionToolbarButton';
import { TABLE_QUESTION_INSERT_POSITION } from 'constants/tableQuestion';
import AddTableColumnLeftIcon from 'svg/AddTableColumnLeftIcon';
import AddTableColumnRightIcon from 'svg/AddTableColumnRightIcon';
import AddTableRowAboveIcon from 'svg/AddTableRowAboveIcon';
import AddTableRowBelowIcon from 'svg/AddTableRowBelowIcon';
import TableRemoveColumnIcon from 'svg/TableRemoveColumnIcon';
import TableRemoveRowIcon from 'svg/TableRemoveRowIcon';
import { TableQuestionInsertColumnPositionType, TableQuestionInsertRowPositionType } from 'types/TableQuestion';
import insertTableQuestionColumn from 'utils/TableQuestion/commands/insertTableQuestionColumn';
import insertTableQuestionRow from 'utils/TableQuestion/commands/insertTableQuestionRow';
import removeTableQuestion from 'utils/TableQuestion/commands/removeTableQuestion';
import removeTableQuestionColumn from 'utils/TableQuestion/commands/removeTableQuestionColumn';
import removeTableQuestionRow from 'utils/TableQuestion/commands/removeTableQuestionRow';
import { findTableQuestionBody } from 'utils/TableQuestion/tableQuestionHelpers';

interface ITableQuestionToolbar {
  editor: Editor;
}

interface ITableQuestionToolbarButton {
  id: number;
  title: string;
  icon: JSX.Element;
  onClick: () => void;
}

const TableQuestionToolbar: FC<ITableQuestionToolbar> = ({ editor }) => {
  const { selection } = editor;
  const tableBody = findTableQuestionBody(editor);

  const handleRemoveTableQuestion = useCallback(() => {
    removeTableQuestion({ editor, selection });
  }, [editor, selection]);

  const handleInsertTableQuestionRow = useCallback((position?: TableQuestionInsertRowPositionType) => {
    insertTableQuestionRow({ editor, tableBody, selection, position });
  }, [editor, selection, tableBody]);

  const handleRemoveTableQuestionRow = useCallback(() => {
    removeTableQuestionRow({ editor, tableBody, selection });
  }, [editor, selection, tableBody]);

  const handleInsertTableQuestionColumn = useCallback((position?: TableQuestionInsertColumnPositionType) => {
    insertTableQuestionColumn({ editor, tableBody, selection, position });
  }, [editor, selection, tableBody]);

  const handleRemoveTableQuestionColumn = useCallback(() => {
    removeTableQuestionColumn({ editor, tableBody, selection });
  }, [editor, selection, tableBody]);

  const TABLE_QUESTION_TOOLBAR: ITableQuestionToolbarButton[] = useMemo(() => [
    {
      id: 1,
      title: 'Insert Row Below',
      onClick: handleInsertTableQuestionRow,
      icon: <AddTableRowBelowIcon />,
    },
    {
      id: 2,
      title: 'Insert Row Above',
      onClick: () => handleInsertTableQuestionRow(TABLE_QUESTION_INSERT_POSITION.ABOVE),
      icon: <AddTableRowAboveIcon />,
    },
    {
      id: 3,
      title: 'Remove Row',
      onClick: handleRemoveTableQuestionRow,
      icon: <TableRemoveRowIcon />,
    },
    {
      id: 4,
      title: 'Insert Column Left',
      onClick: () => handleInsertTableQuestionColumn(TABLE_QUESTION_INSERT_POSITION.LEFT),
      icon: <AddTableColumnLeftIcon />,
    },
    {
      id: 5,
      title: 'Insert Column Right',
      onClick: handleInsertTableQuestionColumn,
      icon: <AddTableColumnRightIcon />,
    },
    {
      id: 6,
      title: 'Remove Column',
      onClick: handleRemoveTableQuestionColumn,
      icon: <TableRemoveColumnIcon />,
    },
    {
      id: 7,
      title: 'Remove Table',
      onClick: handleRemoveTableQuestion,
      icon: <DeleteOutlineIcon />,
    },
  ], [
    handleRemoveTableQuestion,
    handleInsertTableQuestionRow,
    handleRemoveTableQuestionRow,
    handleInsertTableQuestionColumn,
    handleRemoveTableQuestionColumn,
  ]);

  return (
    <div
      className="table-question-toolbar"
      style={{ userSelect: 'none' }}
      contentEditable={false}
    >
      {TABLE_QUESTION_TOOLBAR.map(({ id, title, onClick, icon }) => (
        <TableQuestionToolbarButton
          key={id}
          title={title}
          onClick={onClick}
        >
          {icon}
        </TableQuestionToolbarButton>
      ))}
    </div>
  );
};

export default TableQuestionToolbar;