import { FC, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import 'bootstrap/dist/js/bootstrap.bundle';

import DropdownButton from 'components/EditorHeader/DropdownButton';
import ExportCSVButton from 'components/EditorHeader/ExportCSVButton';
import SavePdfButton from 'components/EditorHeader/SavePdfButton';
import AssignmentsSettingsModal from 'components/Modals/AssignmentsSettingsModal/AssignmentsSettingsModal';
import DocumentBatchSendCSVModal from 'components/Modals/DocumentBatchSendCSVModal';
import DocumentHistoryModal from 'components/Modals/DocumentHistoryModal';
import DocumentInfoModal from 'components/Modals/DocumentInfoModalWindow/DocumentInfoModal';
import MondayComModal from 'components/Modals/MondayComModalWindow/MondayComModal';
import PageSettingsModal from 'components/Modals/PageSettingsModal';
import { DOCUMENT, DOCUMENT_EXECUTED } from 'constants/documentTypes';
import { RedirectTypeEnum } from 'constants/general';
import useCSVData from 'hooks/useCSVData';
import { useRolePermission } from 'hooks/useRolePermission';
import { setOpenAssignmentsSettingsModal } from 'store/actions/editorSlate';
import { deleteCurrentIdFromData, setCreatedDocumentId } from 'store/actions/publicPages';
import { RootStateType } from 'store/reducers';
import { MatchParams } from 'types/Route';
import { getDocumentTypeForPageSettingsFromStorePath } from 'utils/PageSettings';

interface IEditorHeaderDropdown {
  saveDocument: (redirectType?: RedirectTypeEnum, redirectPath?: string) => void;
  disableSaveButton?: boolean;
  handlerShareLinkModal?: () => void;
  openManagerPageHandler?: () => void;
  showShareLinkModal?: boolean | undefined;
  isShareButtonAvailable?: boolean;
  isReadOnlyMode?: boolean;
  showDocumentInformation?: boolean;
  showDocumentHistoryButton?: boolean;
  isPreviewMode?: boolean;
  isCombinePdfOptionEnabled?: boolean;
  combinePdfOptionToggler?: () => void;
  isCollectionPage?: boolean;
  isCollectionExecutedPage?: boolean;
}

type DropdownButtonType = {
  title: string;
  onClickHandler: () => void | undefined;
  isAvailable?: boolean;
  isDisabled?: boolean;
}

const EditorHeaderDropdown: FC<IEditorHeaderDropdown> = ({
  saveDocument,
  disableSaveButton = false,
  handlerShareLinkModal = () => null,
  openManagerPageHandler = undefined,
  showShareLinkModal = undefined,
  isShareButtonAvailable = true,
  isReadOnlyMode = false,
  showDocumentInformation = false,
  showDocumentHistoryButton = false,
  isPreviewMode = false,
  combinePdfOptionToggler = () => null,
  isCombinePdfOptionEnabled = false,
  isCollectionPage = false,
  isCollectionExecutedPage = false,
}) => {
  const dispatch = useDispatch();
  const { id }: MatchParams = useParams();

  const { permissionUsingBatchCSVSend, permissionUsingMondayComIntegration } = useRolePermission();
  const { headers, data, attachments, downloadAttachmentsZIP, getNameForGeneratedFiles } = useCSVData();

  const { templateDetails } = useSelector((state: RootStateType) => state.user);
  const resourceType: string | null = useSelector(
    (state: RootStateType) => getDocumentTypeForPageSettingsFromStorePath(state),
  );

  const [showDocInfoModal, setShowDocInfoModal] = useState<boolean>(false);
  const [showDocHistoryModal, setShowDocHistoryModal] = useState<boolean>(false);
  const [showBatchSendModal, setShowBatchSendModal] = useState<boolean>(false);
  const [showPageSettingsModal, setShowPageSettingsModal] = useState<boolean>(false);
  const [showMondayComModal, setShowMondayComModal] = useState<boolean>(false);

  // Batch Send only for templates
  const allowBatchSendButton = templateDetails?.id > 0;

  const onFillSignHandler = () => {
    dispatch(deleteCurrentIdFromData());
    dispatch(setCreatedDocumentId(0));
    if (openManagerPageHandler) {
      openManagerPageHandler();
    }
  };

  const dropdownButtons: DropdownButtonType[] = [
    {
      title: 'Batch Send',
      onClickHandler: () => {
        saveDocument();
        setShowBatchSendModal(true);
      },
      isAvailable: !!id && permissionUsingBatchCSVSend && allowBatchSendButton,
    },
    {
      title: 'Document Information',
      onClickHandler: () => setShowDocInfoModal(true),
      isAvailable: showDocumentInformation,
    },
    {
      title: 'Document History',
      onClickHandler: () => setShowDocHistoryModal(true),
      isAvailable: showDocumentHistoryButton,
    },
    {
      title: 'Share',
      onClickHandler: handlerShareLinkModal,
      isAvailable: showShareLinkModal !== undefined && isShareButtonAvailable,
      isDisabled: disableSaveButton,
    },
    {
      title: 'Fill & Sign',
      onClickHandler: onFillSignHandler,
      isAvailable: typeof openManagerPageHandler !== 'undefined' && !isReadOnlyMode,
      isDisabled: disableSaveButton,
    },
    {
      title: 'Page Settings',
      onClickHandler: () => setShowPageSettingsModal(true),
      isAvailable: [DOCUMENT, DOCUMENT_EXECUTED].includes(resourceType ?? ''),
    },
    {
      title: 'Customize Assignees',
      onClickHandler: () => {
        dispatch(setOpenAssignmentsSettingsModal(true));
      },
      isAvailable: !isCollectionPage,
    },
    {
      title: 'Monday.com',
      onClickHandler: () => setShowMondayComModal(true),
      isAvailable: permissionUsingMondayComIntegration && !!id && !showDocumentHistoryButton && !isCollectionExecutedPage,
    },
  ];

  return (
    <div className="dropdown">
      <button
        className="btn dropdown-toggle w-100 editor-header-dropdown"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span className="d-none d-xl-inline">
          More options
        </span>
      </button>
      <ul className="dropdown-menu editor-header-dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li>
          <SavePdfButton
            isPreviewMode={isPreviewMode}
            combinePdfOptionToggler={combinePdfOptionToggler}
            isCombinePdfOptionEnabled={isCombinePdfOptionEnabled}
            isCollectionPage={isCollectionPage}
            isCollectionExecutedPage={isCollectionExecutedPage}
          />
        </li>
        {
          dropdownButtons.map(({
            title,
            onClickHandler,
            isAvailable = true,
            isDisabled = false,
          }) => (
            isAvailable
              ? (
                <li key={title}>
                  <DropdownButton
                    title={title}
                    onClickHandler={onClickHandler}
                    isDisabled={isDisabled}
                  />
                </li>
              )
              : null
          ))
        }
        {
          headers.length !== 0 && (
            <li>
              <ExportCSVButton
                headers={headers}
                data={data}
                attachmentsList={attachments}
                downloadAttachmentsZIP={downloadAttachmentsZIP}
                getNameForGeneratedFiles={getNameForGeneratedFiles}
              />
            </li>
          )
        }
      </ul>
      <DocumentInfoModal
        showDocInfoModal={showDocInfoModal}
        setShowDocInfoModal={setShowDocInfoModal}
      />
      <DocumentHistoryModal
        showDocHistoryModal={showDocHistoryModal}
        setShowDocHistoryModal={setShowDocHistoryModal}
      />
      <DocumentBatchSendCSVModal
        showBatchSendModal={showBatchSendModal}
        setShowBatchSendModal={setShowBatchSendModal}
      />
      <PageSettingsModal
        showPageSettingsModal={showPageSettingsModal}
        setShowPageSettingsModal={setShowPageSettingsModal}
      />
      <MondayComModal
        showMondayComModal={showMondayComModal}
        setShowMondayComModal={setShowMondayComModal}
        isCollectionPage={isCollectionPage}
      />
      <AssignmentsSettingsModal />
    </div>
  );
};

export default EditorHeaderDropdown;