import { MouseEvent } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { RenderElementProps, useSelected, useSlate } from 'slate-react';

import TableQuestionToolbar from 'components/Editor/components/Fields/TableQuestion/TableQuestionToolbar';
import { setSelectedTableQuestion } from 'store/actions/editorSlate';
import { RootStateType } from 'store/reducers';

const TableQuestion = (props: RenderElementProps) => {
  const dispatch = useDispatch();
  const { selectedTableQuestionKey } = useSelector((state: RootStateType) => state.editorSlate);
  const { attributes, children, element } = props;
  const selected = useSelected();
  const editor = useSlate();

  const onMouseDownHandler = (event: MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const { field } = target.dataset;
    if (field) return;
    if (selectedTableQuestionKey === element?.key) return;
    dispatch(setSelectedTableQuestion(element?.key ?? null));
  };

  return (
    <div
      role="table"
      className="table-question"
      {...attributes}
      onMouseDown={onMouseDownHandler}
    >
      <table
        {...attributes}
      >
        {children}
      </table>
      {
        selected && (
          <TableQuestionToolbar editor={editor} />
        )
      }
    </div>
  );
};

export default TableQuestion;