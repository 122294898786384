import { StyleSchemaKeysEnum } from 'constants/brandingColorSchema';
import { useRolePermission } from 'hooks/useRolePermission';

const useStyleSchemaPermissions = () => {
  const { permissionUsingRemoveBranding } = useRolePermission();

  const styleSchemaPermissions: Record<StyleSchemaKeysEnum, boolean> = {
    [StyleSchemaKeysEnum.HEADER_TEXT_FONT_FAMILY]: true,
    [StyleSchemaKeysEnum.PARAGRAPH_TEXT_FONT_FAMILY]: true,
    [StyleSchemaKeysEnum.MAIN_BRAND_COLOR]: true,
    [StyleSchemaKeysEnum.IS_REMOVE_BRANDING_ACTIVE]: permissionUsingRemoveBranding,
  };

  return {
    styleSchemaPermissions,
  };
};

export default useStyleSchemaPermissions;