import { toast } from 'react-toastify';
import { fork, put, select } from 'redux-saga/effects';

import { PLEASE_RE_LOGIN } from 'constants/general';
import {
  ACCEPTED_CODE,
  BAD_REQUEST_CODE,
  CONFLICT_CODE,
  FORBIDDEN_CODE,
  INVALID_USERNAME_OR_PASSWORD,
  NOT_FOUND_CODE,
  SERVER_ERROR_CODE,
  SUCCESS_CODE,
  UNAUTHORIZED_CODE,
  UNPROCESSABLE_ENTITY_CODE,
} from 'constants/generalErrors';
import ROUTES from 'constants/routes';
import { PROFILE_SECTIONS } from 'constants/UserMenu';
import { logout, refreshToken } from 'services/api';
import { history } from 'services/history';
import {
  AUTHENTICATE_USER,
  SET_ERROR,
  SET_LOADED,
  SET_LOADING,
} from 'store/actions/actionTypes';
import { IResponseOfRequest } from 'types/Api';
import { ILoginResponse } from 'types/Auth';
import { ACCESS_TOKEN } from 'utils/axiosInstance';
import createErrorMessage from 'utils/errorsHelpers';
import { deleteAuthInStorage, setTokensToLocalStorage } from 'utils/localStorage';

type SafeWrapperSettingsType = {
  withLoader: boolean;
  withoutAdditionalAction: boolean;
  isLoginFlow: boolean;
  isLogoutFlow: boolean;
}

const tryRefreshToken = function* () {
  try {
    localStorage.removeItem(ACCESS_TOKEN);
    const response: IResponseOfRequest<ILoginResponse> = yield refreshToken();
    if (response && response.status === SUCCESS_CODE) {
      setTokensToLocalStorage(response.data);
    } else if (response && response.status === ACCEPTED_CODE) {
      return null;
    } else if (response && response.status === UNAUTHORIZED_CODE) {
      /*
      If refreshToken gets unauthorized error, it means that someone has already used refresh token.
      So a user must re-login.
      */
      yield put({ type: AUTHENTICATE_USER, payload: false });
      yield logout();
      yield deleteAuthInStorage();
    }
  } catch (e) {
    yield deleteAuthInStorage();
    yield put({ type: AUTHENTICATE_USER, payload: false });
  }
};

const safeWrapper = function* (
  saga: any,
  func: any,
  settingsObject: SafeWrapperSettingsType,
) {
  try {
    if (!settingsObject.withoutAdditionalAction) {
      yield put({ type: SET_ERROR, payload: '' });
    }
    if (settingsObject.withLoader) {
      yield put({ type: SET_LOADING, payload: true });
    }
    yield saga(func);
  } catch (err: any) {
    switch (err.status) {
      /*
      UNPROCESSABLE_ENTITY_CODE means that access token is corrupted or there isn't token at all.
      For example if a user exits from system in another tab.
      Anyway, a user needs to relogin in the system because of incorrect token format (or token doesn't exist).
      */
      case UNPROCESSABLE_ENTITY_CODE:
      case UNAUTHORIZED_CODE: {
        if (settingsObject.isLoginFlow) {
          yield put({ type: SET_ERROR, payload: INVALID_USERNAME_OR_PASSWORD });
          return;
        }
        yield tryRefreshToken();
        const isAuthenticated: boolean = yield select((state) => state.user.isAuthenticated);
        if (isAuthenticated) {
          yield saga(func);
          if (settingsObject.isLogoutFlow) {
            yield put({ type: AUTHENTICATE_USER, payload: false });
            yield deleteAuthInStorage();
            yield history.push(ROUTES.LOGIN, { from: PROFILE_SECTIONS.LOGOUT });
          }
        } else {
          toast.warning(PLEASE_RE_LOGIN);
          yield history.push(ROUTES.LOGIN);
        }
        return;
      }
      case FORBIDDEN_CODE:
        yield put({
          type: SET_ERROR,
          payload: settingsObject.isLoginFlow ? err?.data?.message : (err?.data?.msg || err),
        });
        if (settingsObject.isLoginFlow) {
          yield put({ type: SET_LOADED, payload: true });
        }
        toast.error(err?.data?.errors || err?.data?.message);
        return;
      case NOT_FOUND_CODE:
      case SERVER_ERROR_CODE:
        yield put({ type: SET_ERROR, payload: err?.data?.msg || err });
        toast.error(createErrorMessage(err));
        return;
      case CONFLICT_CODE:
        yield put({ type: SET_ERROR, payload: err?.data?.msg || err });
        yield put({ type: SET_LOADED, payload: true });
        return;
      case BAD_REQUEST_CODE:
        // When user enters email on reset password page which is not present in db,
        // system doesn't have to show him that email is not in db
        yield put({ type: SET_LOADED, payload: true });
        return;
      default:
        yield put({ type: SET_ERROR, payload: err?.data?.msg || err });
        return;
    }
  } finally {
    if (settingsObject.withLoader) {
      yield put({ type: SET_LOADING, payload: false });
    }
  }
};

export const safe = (
  saga: any,
  withLoader: boolean = true,
  withoutAdditionalAction = false,
  isLoginFlow = false,
  isLogoutFlow = false,
) => function* (prop: any) {
  yield fork(safeWrapper, saga, prop, { withLoader, withoutAdditionalAction, isLoginFlow, isLogoutFlow });
};

export default null;