/* eslint-disable */
import { Editor, Element as SlateElement } from 'slate';

export const isInTable = (editor: Editor): boolean => {

  const isInTable = Editor.above(editor, {
    match: n => SlateElement.isElement(n) && n.type === 'table',
  });

  if (isInTable) {
    return true;
  }
  return false;
}