import { Button as ReactStrapButton, FormFeedback, Input } from 'reactstrap';

import Button from 'components/Base/Button';
import { MAX_TEMPLATES_FOR_FREE_TIER, UNICORNFORMS_BILLING_LINK } from 'constants/accountSettings';
import { COPY } from 'constants/general';
import useReferralLink from 'hooks/useReferralLink';

const ReferralLinkSection = () => {
  const {
    localReferalCode,
    localReferalCodeError,
    onChangeLocalCode,
    referralCode,
    referralLink,
    maxTemplates,
    createReferralCode,
    handlerCopyToClipboard,
    updateReferralCode,
  } = useReferralLink();

  return (
    <div className="account-settings">
      <div className="mb-3">
        <Button
          onClick={createReferralCode}
          disabled={Boolean(referralLink)}
        >
          Generate referal link
        </Button>
      </div>
      {
        referralLink && maxTemplates < MAX_TEMPLATES_FOR_FREE_TIER && (
          <div
            className="border border-dark rounded-3 p-3 my-3 overflow-hidden d-flex flex-row justify-content-between align-items-center"
          >
            <div className="w-75">
              <div>{referralLink}</div>
            </div>
            <ReactStrapButton
              color="primary"
              outline
              className="btn-sm h-50"
              onClick={() => handlerCopyToClipboard(referralLink)}
            >
              {COPY}
            </ReactStrapButton>
          </div>
        )
      }
      {
        maxTemplates >= MAX_TEMPLATES_FOR_FREE_TIER && (
          <div className="mb-3">
            We love that you love our product! For unlimited templates, please&nbsp;
            <a href={UNICORNFORMS_BILLING_LINK} target="_blank" rel="noreferrer">purchase a plan.</a>
          </div>
        )
      }
      {
        referralLink && maxTemplates < MAX_TEMPLATES_FOR_FREE_TIER && (
          <div className="d-flex align-items-start">
            <div className="me-3">
              <Input
                maxLength={6}
                id="testid"
                className="account-settings__text account-settings__input text-uppercase"
                value={localReferalCode}
                type="text"
                name="referralCode"
                disabled={!referralCode}
                onChange={onChangeLocalCode}
                required
                invalid={!!localReferalCodeError}
              />
              <FormFeedback>{localReferalCodeError}</FormFeedback>
            </div>
            <Button
              onClick={updateReferralCode}
              disabled={Boolean(localReferalCodeError)}
            >
              Change Code
            </Button>
          </div>
        )
      }
    </div>
  );
};

export default ReferralLinkSection;