/* eslint-disable */
import {
  Descendant,
  Editor as SlateEditor,
  Editor,
  Element as SlateElement,
  Node as SlateNode,
  NodeEntry,
  Range as SlateRange,
  Text as SlateText,
  Transforms
} from 'slate';
import {
  ATTACHMENT_FIELD,
  CHECKBOX_FIELD,
  FIELD_TYPE,
  HEADLINE_SIX,
  INITIAL_EDITOR_VALUE,
  PARAGRAPH,
  RECIPIENT_ASSIGNMENT,
  SECTION,
  SELECT_FIELD,
  SIGNING_FIELD,
  STRING_INPUT_MD_MAX_LENGTH,
  STRING_INPUT_MD_MIN_LENGTH,
  STRING_INPUT_SM_MAX_LENGTH,
  STRING_INPUT_SM_MIN_LENGTH,
  STRING_INPUT_XS_MAX_LENGTH,
  STRING_INPUT_XS_MIN_LENGTH,
} from 'constants/editors';
import { BlockFormatType, FilterCallbackType, ICustomElement, ITypeKey } from 'types/Editor';
import { ITemplateSection } from 'types/redux';
import { ISection, SectionListType } from 'types/Sections';
import { generateNumber } from 'utils/editorFieldHelpers';
import { isBetween } from 'utils/math';
import { getIsAttachmentField } from 'utils/Fields/fieldsTypeChecker';
import { FieldDataValueType } from 'types/BatchSendViaCSV';
import { DEFAULT_DATE_FORMAT } from 'constants/dateField';

export const removeNodes = (editor: SlateEditor, format: BlockFormatType) => {
  Transforms.removeNodes(editor, {
    match: (node) => (
      !SlateEditor.isEditor(node)
      && SlateElement.isElement(node)
      && node.type === format
    ),
  });
};

export const filterNodes = (
  nodes: Descendant[],
  callback: FilterCallbackType<SlateElement | SlateText, boolean>,
): SlateElement[] | null => {
  let filter: SlateElement[] | null = null;

  nodes.forEach((node) => {

    if (!SlateElement.isElement(node)) return callback(node);

    const filtered = callback(node);

    if (filtered) {
      filter = [
        ...(filter ? filter : []),
        node,
      ];
    }

    const filterChildren = filterNodes(node.children, callback);

    if (filterChildren) {
      filter = [
        ...(filter ? filter : []),
        ...filterChildren,
      ];
    }
  });

  return filter;
};

export const findNodesAndUpdate = (nodes: Descendant[], callback: FilterCallbackType<Descendant>): Descendant[] => {
  return nodes.map((node) => {

    const newNode = callback(node);

    if (SlateText.isText(newNode)) return newNode;

    return {
      ...newNode,
      children: findNodesAndUpdate(newNode.children, callback)
    };
  });
};

export const updateNodeByKey = (editor: Editor, key: number, properties: Partial<ICustomElement>) => {
  editor.findNodesAndUpdate((node) => {
    if (SlateElement.isElement(node) && node.key === key) {
      return {
        ...node,
        ...properties,
      };
    }
    return node;
  });
};

export const findNodesByType = (node: SlateElement, type: BlockFormatType): SlateElement | undefined => {
  if (!SlateElement.isElement(node)) return;

  let foundNodes = node.children.find((node) => (
    SlateElement.isElement(node) && node.type === type
  ));

  if (!foundNodes || !SlateElement.isElement(foundNodes)) return;
  if (foundNodes) return foundNodes;

  node.children.forEach((node) => {
    if (!SlateElement.isElement(node)) return;

    foundNodes = findNodesByType(node, type);
  });

  return foundNodes;
};

// TODO: remove in the future - not used currently
export const cloneNodes = (tableElement: SlateElement | SlateText): any => {

  if (!SlateElement.isElement(tableElement)) return { text: '' };

  return {
    ...tableElement,
    children: tableElement.children.map((element) => cloneNodes(element)),
  };
};

export const foundNodes = (editor: SlateEditor, format: BlockFormatType): SlateElement | null => {
  const table: Generator<NodeEntry<SlateElement>> = SlateEditor.nodes(editor, {
    match: (node) => (
      !SlateEditor.isEditor(node) &&
      SlateElement.isElement(node) &&
      node.type === format
    )
  });

  // @ts-ignore
  return table && table[0];
};

export const decorate = (search?: string) => ([node, path]: NodeEntry<SlateNode>) => {
  const ranges: SlateRange[] = [];

  if (search && SlateText.isText(node)) {
    const { text } = node;
    const parts = text.split(search);
    let offset = 0;

    parts.forEach((part, index) => {
      if (index) {
        ranges.push({
          anchor: { path, offset: offset - search.length},
          focus: { path, offset },
          highlight: true,
        });
      }

      offset = offset + part.length + search.length;
    });
  }

  return ranges;
};

export const replaceText = (content: Descendant[], search: string, replace: string): Descendant[] => {
  const newContent = findNodesAndUpdate(content, (node) => {
    if (SlateText.isText(node) && node.text.includes(search)) {
      return {
        ...node,
        text: node.text.replaceAll(search, replace),
      };
    }

    return node;
  });

  return newContent;
};

export const replaceAllKeysAndValues = (content: Descendant[]): Descendant[] => {
  const updatedContent = findNodesAndUpdate(content, (node: any) => {
    if (!SlateText.isText(node) && node.type) {
      const attachmentProps = getIsAttachmentField(node.type) ? {properties: {...node.properties, count: 0}} : {};
      return {
        ...node,
        value: [SIGNING_FIELD, ATTACHMENT_FIELD].includes(node.type) ? '' : node.value,
        ...attachmentProps,
        key: generateNumber(),
      };
    }
    return node;
  });

  return updatedContent;
};

export const updateFoundFieldValuesByAssignment = (
  content: Descendant[],
  dataArrayToUpdate: FieldDataValueType[],
  isPDF = false,
) => {
  const callbackMethod = (node: Descendant) => {
    const notEditableValueFields = [ATTACHMENT_FIELD, CHECKBOX_FIELD, SIGNING_FIELD];

    if ('type' in node && node.type) {
      const foundData = dataArrayToUpdate.find((data) => node.name === data.name.trim());

      if (foundData) {
        const checkboxData = node.type === CHECKBOX_FIELD ? { checked: foundData.value === 'Y' } : {};
        const selectData = (node.type === SELECT_FIELD && node.options)
        ? {
          options: node.options.map((option) => {
            const isCheckedOption = (node.addedOtherOption && node.selectedOtherOption && option.isSelectOtherOption)
              || (!node.selectedOtherOption && option.label === foundData.value);
            return {
              ...option,
              checked: isCheckedOption,
            };
          })
        } : {};

        return {
          ...node,
          ...checkboxData,
          ...selectData,
          value: (notEditableValueFields.includes(node.type)) ? node.value : foundData.value,
        };
      }
    }
    return node;
  };

  if (isPDF) {
    return content.map((node) => callbackMethod(node));
  }

  return findNodesAndUpdate(content, callbackMethod);
};

export const cleanUpCopiedSectionData = (
  section: ITemplateSection,
  parentSectionKey: number | null,
  templateContent: Descendant[],
) => {
  let childrenContent: Descendant[] = [];
  if (!section.section_id && section.name.indexOf('Copy of ') !== -1) {
    const parentSectionInEditor = templateContent.find(
      (el) => SlateElement.isElement(el)
        && `Copy of ${el.name}` === section.name
        && el.key === parentSectionKey,
    );
    childrenContent = replaceAllKeysAndValues(parentSectionInEditor?.children || []);
  }
  return childrenContent;
};

export const addSectionsDocument = (sections: SectionListType[], showTitle: boolean): Descendant[] => {
  if (sections.length === 0 && showTitle) {
    return INITIAL_EDITOR_VALUE;
  }

  return sections && sections.map((section: any) => {
    const positionProperty = (section.position !== undefined) ? { position: section.position } : {};
    const typeKey: ITypeKey = {
      type: SECTION,
      key: section.id,
      templateId: section.templateId,
      description: section.description,
      name: section.name,
      ...positionProperty,
    };

    if (!showTitle) {
      if (section?.content_json[0]?.type === SECTION) {
        return {
          templateId: section.templateId,
          type: SECTION,
          key: section.id,
          position: section.content_json[0].position,
          children: [...section.content_json[0].children],
          description: section.description,
          name: section.name,
        };
      }
      return {...typeKey, children: [...section.content_json]};
    } else {
      // old sections
      if (section?.content_json && section.content_json.length) {
        const contentNodes = section.content_json[0]?.type === SECTION
          ? section.content_json[0].children
          : section.content_json;
        let childrenNode = [...contentNodes];
        if (section.content_json[0]?.type === HEADLINE_SIX) {
          childrenNode = [...section.content_json];
        }
        return {...typeKey, children: childrenNode};
      }
      return {...typeKey, children: [INITIAL_EDITOR_VALUE]};
    }
  });
};

export const getSectionName = (sectionChildren: Descendant[]) => {
  const sectionName = sectionChildren.find(item => (
    SlateElement.isElement(item)
    && item.type === HEADLINE_SIX
  ));

  return SlateElement.isElement(sectionName) ? sectionName.children[0].text : '';
};

export const parseSections = (content: Descendant[], oldSections: ISection[]): ISection[] => {
  const newSections: ISection[] = [];
  content.forEach((element: any) => {
    if (!SlateElement.isElement(element)) return;
    if (element.type !== SECTION) return;

    const section = oldSections.find(section => (
      section.id === element.key
    ));

    if (!section) return;

    newSections.push({
      ...section,
      id: element.key || section.id,
      name: (element?.children && getSectionName(element.children)) || '',
      content_json: element.children,
    });
  });

  return newSections;
};

export const drawSectionsInEditorContent = (sections: ITemplateSection[], templateContent: Descendant[]): Descendant[] => {
  const newContent: Descendant[] = [];

  sections.forEach((section) => {
    const sectionInEditor = templateContent.find((el) => el.key === section.key);
    if (sectionInEditor) {
      return newContent.push({
        ...sectionInEditor,
        position: section.position,
      });
    }
    const key = typeof section.key === 'string' ? Number(section.key.split('-')[1]) : section.key;

    // children: INITIAL_EDITOR_VALUE generates a bug while the Descendant text it not editable
    newContent.push({
      type: SECTION,
      key: key,
      position: section.position,
      children: [{
        type: PARAGRAPH,
        children: [{ text: '' }]
      }],
    });
  });

  return newContent;
};

export const isElementInEditor = (editor: SlateEditor, element: string): boolean => {
  const isInSection = SlateEditor.above(editor, {
    match: n => SlateElement.isElement(n) && n.type === element,
  });

  return !!isInSection;
};

export const getSelectedFieldTypeNodes = (editor: SlateEditor): NodeEntry[] => {
  const selectedNodes = Array.from(
    SlateEditor.nodes(editor, {
      match: n => SlateElement.isElement(n) && FIELD_TYPE.includes(n.type),
    })
  );
  return selectedNodes;
};

export const getTextSizeClassName = (valueLength: number): string => {
  if (isBetween(valueLength, STRING_INPUT_MD_MIN_LENGTH, STRING_INPUT_MD_MAX_LENGTH)) {
    return 'md';
  }

  if (isBetween(valueLength, STRING_INPUT_SM_MIN_LENGTH, STRING_INPUT_SM_MAX_LENGTH)) {
    return 'sm';
  }

  if (isBetween(valueLength, STRING_INPUT_XS_MIN_LENGTH, STRING_INPUT_XS_MAX_LENGTH)) {
    return 'xs';
  }

  return '';
};

export const getDefaultDateMask = (dateMask: string | undefined): string => (
  dateMask ?? DEFAULT_DATE_FORMAT
)