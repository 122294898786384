/* eslint-disable */
import { connect } from "react-redux";
import CreateSection from "../pages/CreateSection";
import { createSection } from "../store/actions/userData";

const mapStateToProps = ({ user: { states } }: any) => ({
  states,
});

const mapDispatchToProps = (dispatch: any) => ({
  createSection: (section: any) => dispatch(createSection(section)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateSection);
