import { Dispatch as DispatchReact, SetStateAction } from 'react';

import { Dispatch as DispatchRedux } from 'redux';

import { DisplayData } from 'mui-datatables';

import { COLLECTION_TYPE, DOCUMENT_TYPE, FORM_TYPE, PDF_TYPE, URL_MANAGER_DOCUMENT } from 'constants/general';
import ROUTES from 'constants/routes';
import { EMPTY_SIGNER, SharingResources } from 'constants/sendDocuments';
import { EMAIL_REGEX } from 'constants/validation';
import { CSVDataType, CSVHeaderType } from 'hooks/useCSVData';
import { setAllAssignments } from 'store/actions/editorSlate';
import { setOnlyCollection, setSendDocumentsInfo } from 'store/actions/sendDocuments';
import { DocumentTypesType } from 'types/Documents';
import { AssignmentsObjectType, AssignmentType } from 'types/Editor';
import { IDocumentsIdsArray, SelectedRowsType } from 'types/Mui';
import { ICollectionDetails, ITemplateForMultiTemplate } from 'types/MultiTemplate';
import { SendDocumentInfoType, SignersInfoType } from 'types/SendDocuments';
import { ITemplateDetails } from 'types/Templates';
import { isEmptyObject } from 'utils/isEmptyObject';

export const generateSignersFromAssignments = (
  assignments: AssignmentsObjectType,
  defaultManager: SignersInfoType,
): SignersInfoType[] => {
  const copyAssignments = { ...assignments };
  const managersArray = copyAssignments.managers.map((manager: AssignmentType, index: number) => {
    if (index === 0) {
      return { ...defaultManager, ...manager };
    }
    return { ...EMPTY_SIGNER, ...manager };
  });
  const recipientsArray = copyAssignments.recipients.map((recipient: AssignmentType) => (
    { ...EMPTY_SIGNER, ...recipient }
  ));
  const resultSigners = managersArray.concat(recipientsArray);
  return resultSigners.map((item, index) => ({ ...item, position: index }));
};

export const validateSignersArray = (
  setSignersArray: DispatchReact<SetStateAction<SignersInfoType[]>>,
) => {
  let isSignerInfoValid = true;
  setSignersArray((prevState) => prevState.map((signer) => {
    const copySigner = { ...signer };
    const signerFirstName = signer.firstName;
    const signerEmail = signer.email;
    const isEmailValid = EMAIL_REGEX.test(signerEmail);
    copySigner.firstNameError = !signerFirstName;
    copySigner.firstNameErrorText = !signerFirstName ? 'Field First name is required' : '';

    copySigner.emailError = !signerEmail;
    copySigner.emailErrorText = !signerEmail ? 'Field Email is required' : '';

    if (signerEmail) {
      copySigner.emailError = !isEmailValid;
      copySigner.emailErrorText = !isEmailValid ? 'Email is invalid' : '';
    }

    isSignerInfoValid = [signerFirstName, signerEmail, isEmailValid].every((item) => item);

    return copySigner;
  }));
  return isSignerInfoValid;
};

export const clearValidationErrorsInSignersArray = (
  signers: SignersInfoType[],
) => (
  signers.map((signerInfoType) => ({
    ...signerInfoType,
    firstNameError: false,
    firstNameErrorText: '',
    emailError: false,
    emailErrorText: '',
  })));

export const createDocsIdsArray = (
  selectedRows: SelectedRowsType,
  rowsData: DisplayData,
): IDocumentsIdsArray[] => {
  const copyDocumentsArray = [...selectedRows.data];
  return copyDocumentsArray.map(({ index }: { index: number }) => {
    const currentData = rowsData[index].data;
    const documentInfo = currentData[currentData.length - 1].props.value;
    return { id: Number(documentInfo.id), type: documentInfo.type };
  });
};

export const createSendDocumentsArray = (
  updatedDocumentsArray: IDocumentsIdsArray[],
  dispatch: DispatchRedux,
  initialLocation: string,
  documents: ITemplateDetails[],
  collections: ICollectionDetails[],
) => {
  const docsWithNames: SendDocumentInfoType[] = [];
  if (updatedDocumentsArray.length === 1 && updatedDocumentsArray[0].type === COLLECTION_TYPE) {
    dispatch(setOnlyCollection(updatedDocumentsArray[0]));
  }

  updatedDocumentsArray.forEach((item) => {
    if ([DOCUMENT_TYPE, PDF_TYPE, FORM_TYPE].includes(item.type)) {
      const currentDocument = documents.find((document: ITemplateDetails) => document.id === item.id);
      if (currentDocument && (docsWithNames.findIndex((doc) => doc.id === currentDocument.id) === -1)) {
        docsWithNames.push({ ...item, name: currentDocument.name });
      }
    }
    if (item.type === COLLECTION_TYPE) {
      const currentCollection = collections.find((collection: ICollectionDetails) => collection.id === item.id);
      if (currentCollection && currentCollection.templates) {
        currentCollection.templates.map((template: Partial<ITemplateForMultiTemplate>) => ({
          ...template.template, type: DOCUMENT_TYPE as DocumentTypesType,
        })).forEach((item) => {
          if (docsWithNames.findIndex((doc) => doc.id === item.id) === -1) {
            docsWithNames.push(item);
          }
        });
      }
    }
  });
  const docsAddedPositions = docsWithNames.map((item, index) => ({ ...item, position: index }));
  if (docsAddedPositions.length === 1 && docsAddedPositions[0].type !== COLLECTION_TYPE) {
    const currentDoc = documents.find((doc) => doc.id === docsAddedPositions[0].id);
    if (currentDoc?.assignments) {
      dispatch(setAllAssignments(currentDoc?.assignments));
    }
  }
  dispatch(setSendDocumentsInfo(docsAddedPositions, initialLocation));
};

export const getFullSignerName = (signer: SignersInfoType) => {
  const fullName = `${signer.firstName} ${signer.lastName}`.trim();
  return fullName || signer.label;
};

const parseRoute = (route: string) => {
  if ([
    route.startsWith(ROUTES.DOCUMENTS_EDITOR),
    route.startsWith(ROUTES.FORM_EDITOR),
    route.startsWith(ROUTES.PDF_EDITOR),
  ].some((item: boolean) => item)) return SharingResources.DOCUMENT;
  if ([
    route.startsWith(ROUTES.DOCUMENTS_EXECUTED_EDITOR),
    route.startsWith(ROUTES.PDF_EXECUTED_EDITOR),
    route.startsWith(ROUTES.FORM_EXECUTED_EDITOR),
  ].some((item: boolean) => item)) return SharingResources.DOCUMENTS_EXECUTED;
};

export const createDataForSending = (
  documentName: string,
  id: number,
  dispatch: DispatchRedux,
  initialLocation: string,
  documentsInCollection: Partial<ITemplateForMultiTemplate>[] = [],
  isDocumentCompleted: boolean = false,
) => {
  let docsWithNames: SendDocumentInfoType[] = [];
  if (initialLocation.startsWith(ROUTES.COLLECTIONS_EDITOR)) {
    dispatch(setOnlyCollection({ id, type: COLLECTION_TYPE }));
    docsWithNames = documentsInCollection.map((document) => ({
      id: document.id, name: document.name, type: document.type,
    }));
  } else {
    docsWithNames.push({
      id,
      name: documentName,
      type: DOCUMENT_TYPE,
    });
  }
  const resourceType = parseRoute(initialLocation);
  const docsAddedPositions = docsWithNames.map((item, index) => ({ ...item, position: index }));
  dispatch(setSendDocumentsInfo(docsAddedPositions, initialLocation, resourceType, isDocumentCompleted));
};

export const getIsDataForExportEmpty = ({
  attachments_full_links: attachmentsFullLinks,
  data,
  headers,
}: {
  headers: CSVHeaderType[]
  attachments_full_links: string[]
  data: CSVDataType[]
}) => (
  Boolean(
    headers.length < 1
    && attachmentsFullLinks.length < 1
    && data.filter((item: CSVDataType) => !isEmptyObject(item)).length < 1,
  )
);

export const getManagerLink = (documentId: string) => `/${URL_MANAGER_DOCUMENT}/${documentId}`;

export const getSignersFromAssignments = (assignments: AssignmentsObjectType): SignersInfoType[] => (
  Object
    .values(assignments)
    .flat()
    .map((signer: AssignmentType, index: number): SignersInfoType => ({
      ...EMPTY_SIGNER,
      ...signer,
      position: signer.position ?? index,
    }))
    .sort((a, b) => (a.position as number) - (b.position as number))
);