import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { getUserListData } from 'store/actions/userProfile';
import { RootStateType } from 'store/reducers';
import { UserListStoreType } from 'types/userProfile';

const useUserListLoader = (): UserListStoreType => {
  const dispatch = useDispatch();
  const userList: UserListStoreType = useSelector((state: RootStateType) => state.profile.userList);
  useEffect(() => {
    dispatch(getUserListData());
  }, [dispatch, userList.users]);

  return userList;
};

export default useUserListLoader;