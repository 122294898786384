/* eslint-disable */
import { connect } from "react-redux";
import EditSection from "../pages/EditSection";
import { getSectionDetails, setSectionDetails, editSection } from "../store/actions/userData";

const mapStateToProps = ({ user: { states, sectionDetails } }: any) => ({
  states,
  sectionDetails
});

const mapDispatchToProps = (dispatch: any) => ({
  getSectionDetails: (id: any) => dispatch(getSectionDetails(id)),
  setSectionDetails: (section: any) => dispatch(setSectionDetails(section)),
  editSection: (section: any) => dispatch(editSection(section)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditSection);
