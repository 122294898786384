import { RenderElementProps } from 'slate-react';

import { getTableQuestionCaptionPosition } from 'utils/TableQuestion/tableQuestionHelpers';

const TableQuestionCaption = ({ attributes, children, element }: RenderElementProps) => {
  const [captionSide, textAlign] = getTableQuestionCaptionPosition(element);

  return (
    <caption
      {...attributes}
      style={{ captionSide, textAlign }}
    >
      {children}
    </caption>
  );
};

export default TableQuestionCaption;