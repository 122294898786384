import { Descendant } from 'slate';

import { UNDERLINE_SYMBOL, WHITE_SPACE } from 'constants/editors';

export const createNameConstant = (value: string | (string | undefined)[]): string => {
  if (Array.isArray(value)) {
    return value
      .join(WHITE_SPACE)
      .split(WHITE_SPACE)
      .map((chart: string) => (chart !== WHITE_SPACE ? chart.toUpperCase() : UNDERLINE_SYMBOL))
      .filter((chart) => !!chart)
      .join(UNDERLINE_SYMBOL);
  }

  return (value ?? '')
    .split(WHITE_SPACE)
    .map((chart: string) => (chart !== WHITE_SPACE ? chart.toUpperCase() : UNDERLINE_SYMBOL))
    .join(UNDERLINE_SYMBOL);
};

export const createFieldNameFromDescendant = (childNodes: Descendant[]) => {
  const arrayText = childNodes.map((child: Descendant) => child.text || 'New Field');
  return createNameConstant(arrayText);
};

export const getFieldLabelFromChildren = (value: Descendant[] = []) => {
  if (Array.isArray(value)) {
    return value.map(({ text }) => text).join('');
  }

  return value;
};

export const getFieldNameFromSnakeCase = (value: string) => {
  return (value ?? '')
    .toLowerCase()
    .split(UNDERLINE_SYMBOL)
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(WHITE_SPACE);
};