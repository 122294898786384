import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Button from 'components/Base/Button';
import HelpTextWrapper from 'components/Modals/MondayComModalWindow/HelpTextWrapper';
import MondayComModalNavigation from 'components/Modals/MondayComModalWindow/MondayComModalNavigation';
import {
  CLOSE,
  COLLECTION_TYPE,
  DOCUMENT_TYPE,
  SAVE,
} from 'constants/general';
import { MONDAY_COM_MODAL_SECTIONS, MONDAY_COM_TABS_IDS } from 'constants/mondayComIntegration';
import useMondayComIntegration from 'hooks/MondayCom/useMondayComIntegration';
import useSmsValidationPhoneNumberFieldForMondaycom from 'hooks/MondayCom/useSmsValidationPhoneNumberField';
import { setOpenPopupBecauseOfSmsValidation } from 'store/actions/mondayComIntegration';

import 'scss/components/TabNavigation/TabNavigation.scss';

interface IMondayComModal {
  showMondayComModal: boolean;
  setShowMondayComModal: Dispatch<SetStateAction<boolean>>;
  isCollectionPage?: boolean;
  isFieldPropertiesTab?: boolean;
}

const MondayComModal: FC<IMondayComModal> = ({
  showMondayComModal,
  setShowMondayComModal,
  isCollectionPage = false,
  isFieldPropertiesTab = false,
}) => {
  const dispatch = useDispatch();
  const {
    shouldOpenPopupBecauseOfSmsValidation,
  } = useSmsValidationPhoneNumberFieldForMondaycom();
  const [blockClosePopupButton, setBlockClosePopupButton] = useState<boolean>(false);

  const {
    isMondayComServiceAvailable,
    enabledSMSValidation,
    templateAttachmentFields,
    templateFields,
    resourceIntegrationData,
    boards,
    subitemsBoards,
    workspaces,
    onSelectBoardsHandler,
    onSaveIntegrationSettings,
    activeTab,
    setActiveTab,
    onChangeFieldsMappingHandler,
    onChangeSubitemsMappingHandler,
    onChangeKeyFilterColumnsHandler,
    onChangeItemAttachmentSettingsHandler,
    validateSaveButton,
    disabledTabId,
    onChangeItemDocumentFileSettingsHandler,
  } = useMondayComIntegration(showMondayComModal);

  useEffect(() => {
    if (shouldOpenPopupBecauseOfSmsValidation && !showMondayComModal && !isFieldPropertiesTab) {
      setBlockClosePopupButton(true);
      dispatch(setOpenPopupBecauseOfSmsValidation(false));
      setActiveTab(MONDAY_COM_TABS_IDS.SUBITEM_SETTINGS_TAB_ID);
      setShowMondayComModal(true);
    }
  }, [shouldOpenPopupBecauseOfSmsValidation, showMondayComModal]);

  const boardsExistInAccount = Array.isArray(boards) && Boolean(boards.length);

  return (
    <Modal
      centered
      isOpen={showMondayComModal}
      contentClassName="mx-auto"
    >
      <ModalHeader
        className="modal-header border-0"
        toggle={blockClosePopupButton ? undefined : () => setShowMondayComModal(false)}
      >
        {
          isMondayComServiceAvailable
            ? (
              <MondayComModalNavigation
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                disabledTabId={disabledTabId}
                isCollectionPage={isCollectionPage}
              />
            )
            : (
              <div>Please, provide Monday.com access token in settings menu in order to use integration.</div>
            )
        }
      </ModalHeader>
      {
        isMondayComServiceAvailable && (
          <ModalBody className="p-4 pt-0">
            {
              MONDAY_COM_MODAL_SECTIONS.map(({ componentId, helpText, ReactComponent }) => {
                if (activeTab === componentId) {
                  return (
                    <HelpTextWrapper key={`MondayComModalSection${componentId}`} helpText={helpText}>
                      <ReactComponent
                        templateIntegrationData={resourceIntegrationData}
                        boards={boards}
                        onSelectBoardsHandler={onSelectBoardsHandler}
                        boardsExistInAccount={boardsExistInAccount}
                        templateAttachmentFields={templateAttachmentFields}
                        templateFields={templateFields}
                        onChangeFieldsMappingHandler={onChangeFieldsMappingHandler}
                        subitemsBoards={subitemsBoards}
                        onChangeSubitemsMappingHandler={onChangeSubitemsMappingHandler}
                        onChangeKeyFilterColumnsHandler={onChangeKeyFilterColumnsHandler}
                        onChangeItemAttachmentSettingsHandler={onChangeItemAttachmentSettingsHandler}
                        workspaces={workspaces}
                        onChangeItemDocumentFileSettingsHandler={onChangeItemDocumentFileSettingsHandler}
                        enabledSMSValidation={enabledSMSValidation}
                        blockClosePopupButton={blockClosePopupButton}
                      />
                    </HelpTextWrapper>
                  );
                }
                return null;
              })
            }
          </ModalBody>
        )
      }
      <ModalFooter>
        <div className="d-flex align-items-center justify-content-center w-100 gap-4">
          <Button
            classes="outline outlined-with-border"
            onClick={() => setShowMondayComModal(false)}
            disabled={blockClosePopupButton}
          >
            {CLOSE}
          </Button>
          {
            isMondayComServiceAvailable && boardsExistInAccount && (
              <Button
                onClick={() => {
                  onSaveIntegrationSettings(isCollectionPage ? COLLECTION_TYPE : DOCUMENT_TYPE);
                  setShowMondayComModal(false);
                  setBlockClosePopupButton(false);
                }}
                disabled={validateSaveButton(resourceIntegrationData)}
              >
                {SAVE}
              </Button>
            )
          }
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default MondayComModal;