/* eslint-disable import/no-extraneous-dependencies */
import { ChangeEvent } from 'react';

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';

import SimpleSpinner from 'components/Base/SimpleSpinner';

interface IRadioButtonsGroup {
  showName?: boolean;
  groupName?: string;
  direction?: 'row' | 'column';
  radiosArray?: { label: string; value: string | number; }[];
  onChangeHandler?: (value: string) => void;
  defaultValue?: string;
}

const RadioButtonsGroup: React.FC<IRadioButtonsGroup> = ({
  showName = false,
  groupName = 'Radio buttons',
  direction = 'row',
  radiosArray = [{ label: '', value: '' }],
  onChangeHandler = () => null,
  defaultValue = '',
}) => {
  const handler = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeHandler(event.target.value);
  };

  return (
    <FormControl>
      {showName && <FormLabel id="demo-radio-buttons-group-label">{groupName}</FormLabel>}
      {
        defaultValue
          ? (
            <RadioGroup
              onChange={handler}
              row={direction === 'row'}
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              defaultValue={defaultValue}
            >
              {
                radiosArray.map((radio, index) => (
                  <FormControlLabel
                    key={`FormControlLabel_${index + 1}`}
                    control={<Radio />}
                    label={radio.label}
                    value={radio.value}
                  />
                ))
              }
            </RadioGroup>
          )
          : (<SimpleSpinner />)
      }
    </FormControl>
  );
};

export default RadioButtonsGroup;