/* eslint-disable */
import { Editor as SlateEditor } from "slate";
import { findNodesAndUpdate } from "utils/editorHelpers";

export const withUpdateNodes = (editor: SlateEditor) => {

  editor.findNodesAndUpdate = (callback) => {
    editor.children = findNodesAndUpdate(editor.children, callback);
    editor.onChange();
  }

  return editor
}