import { useDispatch, useSelector } from 'react-redux';
import { arrayMove, SortEndHandler } from 'react-sortable-hoc';

import DocumentSortableItem from 'components/SendDocuments/DocumentSortableItem';
import SortableListContainer from 'components/SortableListContainer';
import { setSendDocumentsInfo } from 'store/actions/sendDocuments';
import { RootStateType } from 'store/reducers';
import { SendDocumentInfoType } from 'types/SendDocuments';

const DocumentsInfoList = () => {
  const dispatch = useDispatch();
  const { initialLocation, documentsInfo } = useSelector((state: RootStateType) => state.sendDocuments);

  const onSortEnd: SortEndHandler = (sort) => {
    const updatedDocsList = arrayMove(documentsInfo as SendDocumentInfoType[], sort.oldIndex, sort.newIndex)
      .map((document, index) => ({ ...document, position: index }));
    dispatch(setSendDocumentsInfo(updatedDocsList, initialLocation));
  };

  return (
    <SortableListContainer
      lockAxis="y"
      onSortEnd={onSortEnd}
      distance={1}
    >
      <div className="d-flex mt-2 flex-column">
        {documentsInfo.map((document: SendDocumentInfoType, index: number) => (
          <DocumentSortableItem
            key={`DocumentSortableItem${document.id}`}
            value={document}
            index={index}
          />
        ))}
      </div>
    </SortableListContainer>
  );
};

export default DocumentsInfoList;