import { Descendant } from 'slate';

import { DATE_FIELD } from 'constants/editors';
import { PDFFieldTypeObject } from 'types/PdfTemplates';
import { getCurrentDate } from 'utils/editorFieldHelpers';

interface IPDFData {
  fields: PDFFieldTypeObject | Descendant[];
  name: string;
  title: string;
  description: string;
}

const getPDFData = ({ fields, name, title, description }: IPDFData) => {
  const updateFields: PDFFieldTypeObject = {};
  Object.entries(fields).forEach(([key, field]) => {
    const fieldCopy = { ...field };
    if (field.type === DATE_FIELD && field.isTodaysDate) {
      fieldCopy.isTodaysDate = false;
      fieldCopy.value = getCurrentDate();
    }
    updateFields[Number(key)] = fieldCopy;
  });

  return {
    fields: updateFields,
    info: {
      templateName: name,
      templateTitle: title,
      templateDescription: description,
    },
  };
};
export default getPDFData;