import { Descendant, Element as SlateElement } from 'slate';

import { ShareViewModes } from 'constants/shareViewModes';
import { ICollectionExecutedDocument } from 'types/CollectionExecuted';
import { IDocumentDetails } from 'types/Documents';
import { ICustomElement } from 'types/Editor';
import { ICollectionDetails, ICollectionTemplate } from 'types/MultiTemplate';
import { PDFFieldType, PDFFieldTypeObject } from 'types/PdfTemplates';
import {
  IPublicPageDocumentStructure,
  IPublicPageState,
  PublicPageDataType,
  UpdatePublicPageFieldsEntriesType,
} from 'types/PublicPage';
import { Action } from 'types/redux';
import { IDocumentSection } from 'types/Sections';
import { ISubtypeKeysValues } from 'types/Subtypes';
import { ITemplateDetails } from 'types/Templates';
import { isPDFDocument } from 'utils/PublicPage/documentTypeChecker';
import { getFieldsFromChildrenAndUpdate } from 'utils/PublicPage/getFieldsFromData';
import { isCollectionExecutedType, isCollectionType, isDocumentType, isTemplateType } from 'utils/typeChecker';

export const updatePublicPageIntroDocumentsAttachmentField = (state: IPublicPageState, action: Action) => (
  state.structure.documents.map((document: IPublicPageDocumentStructure) => {
    if (action.payload.documentId === document.id) {
      return {
        ...document,
        attachmentFields: document.attachmentFields.map((field: Partial<SlateElement>) => {
          if (action.payload.fieldKey === field.key) {
            return {
              ...field,
              value: action.payload.value,
            };
          }
          return field;
        }),
      };
    }
    return document;
  })
);

export const updatePublicPageDataAttachmentField = (state: IPublicPageState, action: Action) => {
  const { data } = state;
  const { fieldKey, value, newProperties } = action.payload;
  const isFDF = data && isPDFDocument(data);

  if (isFDF && isDocumentType(data)) {
    const oldProperty = data?.content_json[fieldKey] as Partial<PDFFieldType>;
    return {
      ...data,
      content_json: {
        ...data.content_json,
        [fieldKey]: {
          ...data.content_json[fieldKey],
          properties: {
            ...oldProperty.properties,
            ...newProperties,
          },
        },
      },
    };
  }

  const updateAttachmentNodeCallback = (node: Descendant) => {
    if (node.key === fieldKey) {
      const { properties } = node as Partial<ICustomElement>;
      return {
        ...node,
        value: value ?? '',
        properties: {
          ...properties,
          ...newProperties,
        },
      };
    }
    return node;
  };

  if (!isFDF && isTemplateType(data)) {
    return {
      ...data,
      sections: data.sections.map((sectionItem: IDocumentSection) => ({
        ...sectionItem,
        section: {
          ...sectionItem.section,
          content_json: getFieldsFromChildrenAndUpdate(
            sectionItem.section?.content_json || [],
            updateAttachmentNodeCallback,
          ),
        },
      })),
    };
  }
  return {
    ...data,
    content_json: getFieldsFromChildrenAndUpdate(
      (data as IDocumentDetails).content_json as Descendant[],
      updateAttachmentNodeCallback,
    ),
  };
};

export const updateFieldPropertiesByKey = (
  fields: PDFFieldTypeObject,
  entries: ISubtypeKeysValues,
): PDFFieldTypeObject => {
  const updatedFields = {
    ...fields,
  };

  const keys = Object.keys(fields);

  keys.forEach((key) => {
    const numberKey = Number(key);
    if (entries[key]) {
      updatedFields[numberKey] = {
        ...updatedFields[numberKey],
        ...entries[key],
      };
    }
  });

  return updatedFields;
};

export const updateFieldsByKey = (
  fields: PDFFieldTypeObject | Partial<ICustomElement>[],
  entries: UpdatePublicPageFieldsEntriesType,
): PDFFieldTypeObject | Partial<ICustomElement>[] => {
  const updatedFields = {
    ...fields,
  };

  const keys = Object.keys(fields).map((key: string) => Number(key));

  keys.forEach((key) => {
    if (entries[key] || entries[key] === '') {
      updatedFields[key] = {
        ...updatedFields[key],
        value: entries[key],
      };
    }
  });

  return updatedFields;
};

export const updateSectionsProperties = (
  template: ITemplateDetails,
  entries: ISubtypeKeysValues,
) => (
  isPDFDocument(template)
    ? {
      ...template,
      pdf_fields_json: {
        ...updateFieldPropertiesByKey(template.pdf_fields_json, entries),
      },
    }
    : {
      ...template,
      sections: template.sections?.map((sectionInfo) => ({
        ...sectionInfo,
        section: {
          ...sectionInfo.section,
          content_json: getFieldsFromChildrenAndUpdate(
            sectionInfo.section.content_json || [],
            (node) => {
              if (entries[node.key || 0]) {
                return {
                  ...node,
                  ...entries[node.key || 0],
                };
              }
              return node;
            },
          ),
        },
      })),
    }
);

export const updateSectionsContent = (
  data: ITemplateDetails | IDocumentDetails,
  entries: UpdatePublicPageFieldsEntriesType,
) => {
  if (isTemplateType(data)) {
    return isPDFDocument(data)
      ? {
        ...data,
        pdf_fields_json: {
          ...updateFieldsByKey(data.pdf_fields_json, entries),
        },
      }
      : {
        ...data,
        sections: data.sections?.map((sectionInfo) => ({
          ...sectionInfo,
          section: {
            ...sectionInfo.section,
            content_json: getFieldsFromChildrenAndUpdate(
              sectionInfo.section.content_json || [],
              (node) => {
                if (entries[node.key || 0] || entries[node.key || 0] === '') {
                  return {
                    ...node,
                    value: entries[node.key || 0] || '',
                  };
                }
                return node;
              },
            ),
          },
        })),
      };
  }
  if (isDocumentType(data)) {
    return isPDFDocument(data)
      ? {
        ...data,
        content_json: {
          ...updateFieldsByKey(data.content_json, entries),
        },
      }
      : {
        ...data,
        content_json: getFieldsFromChildrenAndUpdate(
          (data.content_json as Descendant[]) || [],
          (node) => {
            if (entries[node.key || 0] || entries[node.key || 0] === '') {
              return {
                ...node,
                value: entries[node.key || 0] || '',
              };
            }
            return node;
          },
        ),
      };
  }
};

export const getUpdateFieldsFromEntries = (
  fields: Partial<ICustomElement>[],
  keys: number[],
  entries: UpdatePublicPageFieldsEntriesType,
): Partial<ICustomElement>[] => (
  fields.map((field: Partial<ICustomElement>): Partial<ICustomElement> => {
    const fieldKey = field.key ?? 0;
    if (keys.includes(fieldKey)) {
      return {
        ...field,
        value: entries[fieldKey],
      };
    }
    return field;
  })
);

const getUpdatedFormBuilderStructure = (data: ICollectionDetails, entries: UpdatePublicPageFieldsEntriesType) => {
  const keys = Object.keys(entries).map(Number);
  return data.form_builder_structure?.map((section) => ({
    ...section,
    fields: getUpdateFieldsFromEntries(section.fields, keys, entries),
  }));
};

export const updatePublicPageFields = (
  data: PublicPageDataType | null,
  entries: UpdatePublicPageFieldsEntriesType,
) => {
  if (isCollectionType(data)) {
    return {
      ...data,
      templates: data.templates?.map((templateInfo: ICollectionTemplate) => (
        {
          ...templateInfo,
          template: updateSectionsContent(templateInfo.template, entries),
        }
      )),
      ...(data.view_mode === ShareViewModes.SHARE_MODE_DOCUMENT_AND_FORM_BUILDER && {
        form_builder_structure: getUpdatedFormBuilderStructure(data, entries),
      }),
    };
  }
  if (isCollectionExecutedType(data)) {
    return {
      ...data,
      documents: data.documents?.map((documentObject: ICollectionExecutedDocument) => (
        {
          ...documentObject,
          document: updateSectionsContent(documentObject.document, entries),
        }
      )),
    };
  }
  return data;
};

export const updatePublicPageIntroCurrentAttachmentFieldHelper = (state: IPublicPageState, action: Action) => (
  state?.currentDocument?.attachmentFields.map((field: Partial<SlateElement>) => {
    if (action.payload.fieldKey === field.key) {
      return {
        ...field,
        value: action.payload.value,
      };
    }
    return field;
  })
);

export const updateSectionAttachmentFieldProperties = (
  contentJson: Descendant[] | undefined,
  action: Action,
) => contentJson?.map((item: Descendant) => {
  const { children } = item;

  if (children) {
    const updatedChildren = children.map((child: Partial<SlateElement>) => {
      if (child.key && child.key === action.payload.fieldKey) {
        return {
          ...child,
          properties: {
            ...child.properties,
            ...action.payload.newProperties,
          },
        };
      }
      return child;
    });

    return {
      ...item,
      children: updatedChildren,
    };
  }

  return item;
});

export const updatePdfAttachmentProperties = (
  state: IPublicPageState,
  action: Action,
): Partial<PDFFieldType> | null => {
  const data: Partial<ITemplateDetails> | null = state.data;

  if (data && data.pdf_fields_json) {
    return {
      ...data.pdf_fields_json,
      [action.payload.fieldKey]: {
        ...data.pdf_fields_json[action.payload.fieldKey],
        properties: {
          ...data.pdf_fields_json[action.payload.fieldKey].properties,
          ...action.payload.newProperties,
        },
      },
    };
  }

  return null;
};