const SignatureIcon: React.FC<{ color?: string }> = (props) => {
  const {
    color = '#4C95FC',
  } = props;

  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6024 7.39L17.6624 6.33C18.4424 5.55 18.4424 4.28 17.6624 3.5L16.2524
        2.09C15.4724 1.31 14.2024 1.31 13.4224 2.09L12.3624 3.15L16.6024 7.39ZM12.3624 8.81L5.16244
        16H3.75244V14.59L10.9424 7.4L12.3624 8.81ZM10.9424 4.56L1.75244 13.76V18H5.99244L15.1824 8.81L10.9424
        4.56ZM16.7524 14.5C16.7524 16.69 14.2124 18 11.7524 18C11.2024 18 10.7524 17.55 10.7524 17C10.7524 16.45
        11.2024 16 11.7524 16C13.2924 16 14.7524 15.27 14.7524 14.5C14.7524 14.03 14.2724 13.63 13.5224 13.3L15.0024
        11.82C16.0724 12.45 16.7524 13.29 16.7524 14.5ZM2.33244 10.35C1.36244 9.79 0.752441 9.06 0.752441 8C0.752441
        6.2 2.64244 5.37
        4.31244 4.64C5.34244 4.18 6.75244 3.56 6.75244 3C6.75244 2.59 5.97244 2 4.75244 2C3.49244 2 2.95244 2.61 2.92244
        2.64C2.57244
        3.05 1.94244 3.1 1.52244 2.76C1.11244 2.42 1.03244 1.81 1.37244 1.38C1.48244 1.24 2.51244 0 4.75244 0C6.99244
        0 8.75244 1.32
        8.75244 3C8.75244 4.87 6.82244 5.72 5.11244 6.47C4.17244 6.88 2.75244 7.5 2.75244 8C2.75244 8.31 3.18244
        8.6 3.82244
        8.86L2.33244 10.35Z"
        fill={color}
      />
    </svg>
  );
};

export default SignatureIcon;