import { AxiosResponse } from 'axios';

import ENDPOINT from 'constants/endpoints';
import {
  BAD_REQUEST_CODE,
  NOT_FOUND_CODE,
  RESOURCE_NOT_EXISTS,
  SERVER_ERROR_CODE,
  SERVER_ERROR_MESSAGE,
  SOMETHING_WENT_WRONG,
} from 'constants/generalErrors';

const DELETE_METHOD = 'delete';
const GET_METHOD = 'get';
const STATUS_SERVER_ERROR = 'INTERNAL SERVER ERROR';

const getTargetResource = (originPath: string): string => {
  if (originPath.startsWith(ENDPOINT.DOCUMENT_EXECUTED)) {
    return 'executed document';
  }
  if (originPath.startsWith(ENDPOINT.DOCUMENT)) {
    return 'document';
  }
  if (originPath.startsWith(ENDPOINT.SECTION)) {
    return 'section';
  }

  return 'object';
};

const createErrorMessage = (errorObject: AxiosResponse): string => {
  const targetResource = getTargetResource(errorObject.config?.url || '');
  switch (errorObject.config?.method) {
    case DELETE_METHOD:
      return `You can't delete this ${targetResource} because document/template created from it exists`;
    case GET_METHOD:
      switch (errorObject.status) {
        case NOT_FOUND_CODE:
          return `The requested ${targetResource} doesn't exist`;
        case SERVER_ERROR_CODE:
          return SERVER_ERROR_MESSAGE;
        default:
          return SOMETHING_WENT_WRONG;
      }
    default:
      switch (errorObject.status) {
        case NOT_FOUND_CODE:
        case BAD_REQUEST_CODE:
          if (errorObject.data && errorObject.data.errors) {
            return errorObject.data.errors;
          }
          return RESOURCE_NOT_EXISTS;
        case SERVER_ERROR_CODE:
          if (errorObject.data && errorObject.data.errors) {
            return errorObject.data.errors;
          }
          return SERVER_ERROR_MESSAGE;
        default:
          return STATUS_SERVER_ERROR;
      }
  }
};

export default createErrorMessage;