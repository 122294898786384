import { ISelectFieldType } from 'types/Properties';

export const DATE_FIELD_FORMAT = 'MM/DD/yyyy';
export const MOMENT_DATE_FIELD_FORMAT = 'yyyy-MM-DD';
export const STRIPE_DATE_FIELD_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const MOMENT_DATE_FORMAT = 'L';
export const MOMENT_MANAGEMENT_FIELD_FORMAT = 'LLL';

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

export const DATE_FORMATS: ISelectFieldType[] = [
  { label: DEFAULT_DATE_FORMAT, type: DEFAULT_DATE_FORMAT, id: 0 },
  { label: 'DD-MON-YYYY', type: 'DD-MMM-YYYY', id: 100 },
  { label: 'MM/DD/YYYY', type: 'MM/DD/YYYY', id: 200 },
  { label: 'MONTH DD, YYYY', type: 'MMMM DD, YYYY', id: 300 },
  { label: 'DD/MM/YYYY', type: 'DD/MM/YYYY', id: 400 },
  { label: 'DD MONTH, YYYY', type: 'DD MMMM, YYYY', id: 500 },
  { label: 'MM/YYYY', type: 'MM/YYYY', id: 600 },
];