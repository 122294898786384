import { Action } from 'types/redux';
import { SET_ERROR, SET_LOADED, SET_LOADING } from 'store/actions/actionTypes';

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: '',
};

// eslint-disable-next-line default-param-last
export default (state: any = initialState, action: Action) => {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_LOADED:
      return {
        ...state,
        isLoaded: action.payload,
      };
    default:
      return state;
  }
};
