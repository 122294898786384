import axios from 'axios';

import { LOCAL_USER_IP_LABEL, URL_API_IPIFY, USER_LOCATION } from 'constants/general';
import { SUCCESS_CODE } from 'constants/generalErrors';
import { DEFAULT_USER_LOCATION } from 'utils/amplitude/amplituteConstants';

export const setUserLocationToLocalStorage = async () => {
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let userLocationValue = JSON.stringify({ ...DEFAULT_USER_LOCATION, localTimeZone });
  let ipAddress = 'uknown';
  try {
    const ipResponse = await axios.get(URL_API_IPIFY);
    if (ipResponse.status === SUCCESS_CODE) {
      ipAddress = ipResponse.data.ip;
      userLocationValue = JSON.stringify({ ...JSON.parse(userLocationValue), IPv4: ipAddress });
    }
    localStorage.setItem(USER_LOCATION, userLocationValue);
    localStorage.setItem(LOCAL_USER_IP_LABEL, ipAddress);
  } catch (error) {
    localStorage.setItem(USER_LOCATION, userLocationValue);
    localStorage.setItem(LOCAL_USER_IP_LABEL, ipAddress);
  }
};

export default undefined;