import { ReactNode } from 'react';

import { SortableContainer } from 'react-sortable-hoc';

export interface ISortableContainerProps {
  children: ReactNode;
}

const SortableListContainer = SortableContainer(({ children }: ISortableContainerProps) => (
  <ol className="list-group">{children}</ol>
));

export default SortableListContainer;