import { ChangeEventHandler, FC } from 'react';

import { OutlinedInput } from '@mui/material';

import { CssStyleObjectType } from 'types/BrandingColorSchema';

import styled from '@emotion/styled';
import vars from 'variables.module.scss';

interface IOtherOptionElement {
  onChangeHandler: ChangeEventHandler<HTMLInputElement>;
  name?: string;
  label?: string;
  value?: string;
  simpleInput?: boolean;
  addMargin?: boolean;
  disabled?: boolean;
  style?: CssStyleObjectType;
}

interface IStyledInputField {
  style?: CssStyleObjectType;
}

export const StyledInputField = styled(OutlinedInput)<IStyledInputField>`
  & input {
    padding: 12px 14px;
  }
  & fieldset {
    top: 0;
    legend {
      display: none;
    }
  }
  &.Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.style?.labelColor || vars.brandPrimaryFocused};
    border-width: 1px;
  }
`;

const OtherOptionElement: FC<IOtherOptionElement> = ({
  onChangeHandler,
  name = '',
  label = '',
  value = '',
  simpleInput = true,
  addMargin = false,
  disabled = false,
  style = undefined,
}) => (
  simpleInput ? (
    <input
      type="text"
      name={name}
      value={value}
      className={`w-100 text-field-editable ${addMargin ? 'mt-2' : ''}`}
      autoComplete="off"
      onChange={onChangeHandler}
      disabled={disabled}
      tabIndex={disabled ? -1 : 0}
      data-field="true"
    />
  ) : (
    <div className={`${addMargin ? 'mt-2' : ''}`}>
      <StyledInputField
        name={name}
        label={label}
        value={value}
        onChange={onChangeHandler}
        disabled={disabled}
        fullWidth
        style={style}
        tabIndex={-1}
        data-field="true"
      />
    </div>
  )
);

export default OtherOptionElement;