import { FC } from 'react';

import Tooltip from 'components/Tooltip';
import { IParsedData } from 'types/BatchSendViaCSV';
import { getHeadersAndValuesData } from 'utils/ManagerEditor/BatchSendViaCSVHelpers';

interface ICSVDataTableProps {
  data: IParsedData['data'];
}

const CSVDataTable: FC<ICSVDataTableProps> = ({ data }) => {
  const { headersData, rowsData } = getHeadersAndValuesData(data);
  const rowsCountToShow = 10;

  if (!data.length) return null;

  return (
    <>
      <div className="table-responsive csv-data-table">
        <table className="table table-striped table-hover" style={{ minWidth: 'min(100%, 800)' }}>
          <thead>
            <tr>
              {headersData.map((columnData, index) => (
                <Tooltip key={`head_${index + 1}`} title={columnData}>
                  <th>{columnData}</th>
                </Tooltip>
              ))}
            </tr>
          </thead>
          <tbody>
            {rowsData.map((rowData: (string | number)[], index) => index < rowsCountToShow && (
              <tr key={`row_${index + 1}`}>
                {rowData.map((columnData, columnIndex) => (
                  <Tooltip key={`column_${columnIndex + 1}`} title={columnData}>
                    <td>{columnData}</td>
                  </Tooltip>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              {rowsData.length > rowsCountToShow && (
                headersData.map((_, index) => <td key={`foot_${index + 1}`}>...</td>)
              )}
            </tr>
          </tfoot>
        </table>
      </div>
      <div>{rowsData.length} rows</div>
    </>
  );
};

export default CSVDataTable;