import { ChangeEventHandler, FC, memo, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react';

import EditorFieldView from 'components/Editor/FormView/Fields/EditorFieldView';
import { DOCUMENT_TAB_ID } from 'constants/tabs';
import useFieldsHandler from 'hooks/useFieldsHandler';
import { RootStateType } from 'store/reducers';
import { createFieldNameFromDescendant } from 'utils/createNameConstant';
import { createFieldDataAttributes } from 'utils/editorCopyPasteHelpers';
import {
  getSelectedFieldClassName,
  getValidDate,
  setSelectedFieldState,
} from 'utils/editorFieldHelpers';

export interface IRenderElementProps extends RenderElementProps {
  readOnly?: boolean;
}

const FormTypeInputEditable: FC<IRenderElementProps> = ({
  children,
  element,
  readOnly = false,
}) => {
  const editor = useSlateStatic();
  const dispatch = useDispatch();
  const { selectedField } = useSelector((state: RootStateType) => state.editorSlate);
  const [value, setValue] = useState<string>(element.value || '');

  const { updateFieldsHandler } = useFieldsHandler({ editor });

  useEffect(() => {
    if (element.value !== value) {
      setValue(element.value || '');
    }
  }, [element.value]);

  useEffect(() => {
    const name = createFieldNameFromDescendant(element.children);
    if (element.name !== name) {
      const path = ReactEditor.findPath(editor, element);
      Transforms.setNodes(editor, { name }, { at: path });
    }
  }, [element.children]);

  const onChangeTextField: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!readOnly) {
      const newValue = getValidDate(event.target);
      setValue(newValue);
      updateFieldsHandler(element, { value: newValue });
    }
  };

  const clickFieldHandler = () => {
    setSelectedFieldState(selectedField, element.key, dispatch);
  };

  return (
    <div
      {...(createFieldDataAttributes({
        field: element,
        activeTab: DOCUMENT_TAB_ID,
      }))}
      onClick={clickFieldHandler}
      role="textbox"
      tabIndex={-1}
      contentEditable={false}
      className={
        'form-section__field-label user-select-none align-items-center d-flex position-relative py-2'
        + `${getSelectedFieldClassName(element, selectedField, editor)}`
      }
    >
      <EditorFieldView
        field={element}
        onChangeValue={onChangeTextField}
        readOnlyMode={readOnly}
        showButtons
        styledLabelColors
      />
      <span className="d-none">{children}</span>
    </div>
  );
};

export default memo(FormTypeInputEditable);