/* eslint-disable */
import { STATUSES_TO_SHOW } from 'constants/documentStatuses';

export const isStatusShown = (status: string): boolean => {
  return Object.values(STATUSES_TO_SHOW).includes(status);
}

export const wasStatusChanged = (oldStatus: string, statusToCheck: string, checkShown: boolean = false): boolean => {
  if (statusToCheck.length < 1) {
    return false;
  }
  if (checkShown) {
    return isStatusShown(statusToCheck) && oldStatus !== statusToCheck;
  }
  return oldStatus !== statusToCheck;
}