import { FC } from 'react';

import { useHistory } from 'react-router-dom';

import Button from 'components/Base/Button';
import ArrowLeftIcon from 'svg/ArrowIcons/ArrowLeftIcon';

const HeaderBackButton: FC = () => {
  const history = useHistory();

  return (
    <Button
      classes="button-simple mobile-icon-button"
      variant="text"
      size="small"
      startIcon={<ArrowLeftIcon />}
      onClick={() => history.goBack()}
    >
      <span className="d-none d-xl-inline">
        Back
      </span>
    </Button>
  );
};

export default HeaderBackButton;