import { ComponentType, FC } from 'react';

import { CarbonCopyProvider } from 'components/SignNow/CarbonCopyContext';
import Breadcrumbs from 'components/SignNow/StepManager/Breadcrumbs';
import { StepHandlerProvider } from 'components/SignNow/StepManager/StepHandlerContext';
import { useStepManager } from 'hooks/signNow';
import { IStepManagerChildComponentProps, IStepManagerProps } from 'types/signNow/base';

import 'scss/components/StepManager/index.scss';

const StepManager: FC<IStepManagerProps> = ({
  componentMap,
  config,
  initStep = 0,
  withBreadcrumbs = false,
  children,
}) => {
  const {
    currentStep,
    isNextButtonVisible,
    goToStep,
    goToNextStep,
    goToPrevStep,
    CurrentStepComponent,
  } = useStepManager({
    componentMap,
    config,
    initStep,
  });

  const StepComponent: ComponentType<IStepManagerChildComponentProps> = CurrentStepComponent();

  return (
    <StepHandlerProvider>
      <CarbonCopyProvider>
        <div className="step-manager">
          {
            withBreadcrumbs
            && (
              <Breadcrumbs
                isButtonsDisabled
                isNextButtonVisible={isNextButtonVisible}
                currentStep={currentStep}
                list={config}
                goToStep={goToStep}
              />
            )
          }
          <div className="step-manager-component">
            <StepComponent onNextHandler={goToNextStep} onBackHandler={goToPrevStep} />
          </div>
          {children}
        </div>
      </CarbonCopyProvider>
    </StepHandlerProvider>
  );
};

export default StepManager;