import { FC, useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Element as SlateElement } from 'slate';

import AttachmentDropzone from 'components/AttachmentDropzone';
import FieldName from 'components/Editor/FormView/Fields/FieldName';
import { IDocumentItem } from 'components/Intro/DocumentItem';
import ShowMoreButton from 'components/ShowMoreButton';
import useReadOnlyMode from 'hooks/useReadOnlyMode';
import {
  updatePublicPageAttachmentProperties,
  updatePublicPageIntroAttachmentField,
} from 'store/actions/publicPages';
import setIsSubmitButton from 'store/actions/validation';
import { RootStateType } from 'store/reducers';
import { IDocumentIntroAttachments } from 'types/Documents';
import { IPublicPageGeneralStructure, PublicPageDataType } from 'types/PublicPage';
import { getIsCollectionDocument, getIsFormBuilder, getIsPDFDocument } from 'utils/PublicPage/documentTypeChecker';
import getFieldName from 'utils/PublicPage/getFieldName';
import updateDocumentAttachments from 'utils/PublicPage/updatePublicPageAttachments';

import classes from 'scss/components/Intro/DocumentItem.module.scss';

const IntroAttachmentsList: FC<IDocumentItem> = ({ document }) => {
  const dispatch = useDispatch();
  const [attachmentInvalidFields, setAttachmentInvalidFields] = useState<number[]>([]);
  const [documentAttachments, setDocumentAttachment] = useState<IDocumentIntroAttachments>({});

  const data: PublicPageDataType | null = useSelector((state: RootStateType) => state.publicPages.data);
  const { main }: IPublicPageGeneralStructure = useSelector((state: RootStateType) => state.publicPages.structure);

  const isFormBuilder = getIsFormBuilder(data?.view_mode);
  const isReadOnlyMode = useReadOnlyMode();

  useEffect(() => {
    dispatch(setIsSubmitButton(attachmentInvalidFields.length > 0));
  }, [attachmentInvalidFields.length]);

  const updateAttachmentValue = (newValueArray: string[], fieldKey: number | undefined) => {
    if (fieldKey) {
      dispatch(updatePublicPageIntroAttachmentField(document.id, fieldKey, newValueArray.join()));
    }
  };

  const handlerChangeAttachmentCount = (
    currentCount: number,
    fieldKey: number = 0,
    isRequired: boolean = false,
    newValueArray: string[] = [],
  ) => {
    // Updating fields properties (count) doesn't matter if field is required or not.
    dispatch(updatePublicPageAttachmentProperties(fieldKey, { count: currentCount }, newValueArray.join()));
    if (isRequired) {
      setAttachmentInvalidFields((prevResults) => (
        (currentCount > 0)
          ? prevResults.filter((key) => key !== fieldKey)
          : [...prevResults, fieldKey]
      ));
    }
  };

  const getValidationMessage = useCallback((fieldKey: number, isRequired: boolean = false, validationText = '') => (
    isRequired && attachmentInvalidFields.includes(fieldKey || 0)
      ? (validationText || 'Please upload attachment here')
      : ''
  ), [attachmentInvalidFields]);

  const isPDFDocument = getIsPDFDocument(document.documentType);
  const isCollectionDocument = getIsCollectionDocument(document.documentType);

  const getAttachmentItems = (): JSX.Element[] => {
    const attachmentFields: JSX.Element[] = [];
    document.attachmentFields.forEach((field: Partial<SlateElement>) => {
      if (field.assignment === main.mainAssignment) {
        const fieldName = getFieldName(field, isPDFDocument);
        attachmentFields.push(
          <label className={classes.AttachmentWrapper} key={`attachments_${field.key}`}>
            <FieldName
              name={fieldName || ''}
              isRequired={field.requiredField || false}
              additionalClassName="field-name"
            />
            <AttachmentDropzone
              fieldKey={field.key}
              fieldValue={field.value}
              helpText={field.helpText}
              properties={field.properties}
              onChangeCount={
                (currentCount: number) => {
                  handlerChangeAttachmentCount(
                    currentCount,
                    field.key,
                    field.requiredField,
                    documentAttachments[field.key || 0],
                  );
                }
              }
              onRemoveValue={
                (newValueArray: string[]) => {
                  if (!field.key) return;
                  handlerChangeAttachmentCount(
                    newValueArray.length,
                    field.key,
                    field.requiredField,
                    newValueArray,
                  );
                  updateAttachmentValue(newValueArray, field.key);
                  setDocumentAttachment(updateDocumentAttachments(document.attachmentFields, field.key, newValueArray));
                }
              }
              isValid={field.requiredField ? !attachmentInvalidFields.includes(field.key || 0) : true}
              errorMessage={getValidationMessage(field.key || 0, field.requiredField, field.validationText || '')}
              isPDFDocument={isPDFDocument}
              field={field}
              useType={undefined}
            />
          </label>,
        );
      }
    });
    return attachmentFields;
  };

  const shouldShowAttachments = () => (
    document.attachmentFields.some((field) => field.assignment === main.mainAssignment)
  );

  return (
    shouldShowAttachments()
    && document.attachmentFields.length
    && (!isCollectionDocument || (isCollectionDocument && isFormBuilder))
    && !isReadOnlyMode
      ? (
        <>
          <p className={classes.AttachmentHelper}>Please gather the following attachments:</p>
          <ShowMoreButton data={getAttachmentItems()} />
        </>
      )
      : null
  );
};

export default IntroAttachmentsList;