/* eslint-disable */
import React from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import DropzoneComponent from 'react-dropzone-component';
import { ATTACHMENT_DEFAULT_PARAMS, TWENTY_MB_IN_BYTES } from 'constants/editors';
import { CHUNK_SIZE } from 'constants/general';
import 'style/_attachments.scss';
import { FILE_NAME_REGEX } from 'constants/validation';
import { SUCCESS_CREATE_CODE } from 'constants/generalErrors';
import { setAllInfoAboutTemplate } from 'store/actions/pdfTemplates';
import { ACCESS_TOKEN } from 'utils/axiosInstance';

const { REACT_APP_API_URL } = process.env;

const PdfAttachmentZone = () => {
  const dispatch = useDispatch();
  let dropzone = null;
  const attachmentsLimit = ATTACHMENT_DEFAULT_PARAMS.limit;
  const timeStamp = new Date().getTime();
  const componentConfig = {
    iconFiletypes: '.pdf',
    showFiletypeIcon: false,
    postUrl: `${REACT_APP_API_URL}/pdf-template/files`,
  };

  const showSpinner = (toShow) => {
    const spinner = document.querySelector('[data-dz-spinner]');
    if (spinner) {
      spinner.style.display = toShow ? 'block' : 'none';
    }
  }

  const showNotification = (messageResponse, dropzone, file) => {
    showSpinner(false);
    dropzone.removeAllFiles();
    const errorMessage = document.querySelector('.error[data-dz-errormessage="true"]');
    if (errorMessage) {
      errorMessage.innerHTML = messageResponse;
    }

    return dropzone._errorProcessing(
      [file],
      messageResponse
    );
  }

  let djsConfig = {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
    },
    previewTemplate: ReactDOMServer.renderToStaticMarkup(
      <div className='d-flex justify-content-center p-2'>
        <div className="spinner-border text-primary" data-dz-spinner role="status"></div>
      </div>
    ),
    addRemoveLinks: false,
    autoProcessQueue: true,
    autoQueue: true,
    parallelChunkUploads: false, // important to load only in one thread
    forceChunking: true,
    chunkSize: CHUNK_SIZE, // bytes = 5 MBytes
    timeout: 300000, // 5 minutes
    retryChunks: false,
    maxFiles: attachmentsLimit,
    maxFilesize: ATTACHMENT_DEFAULT_PARAMS.maxMBLimit,
    dictFileTooBig: `File is too big. Max file size: ${ATTACHMENT_DEFAULT_PARAMS.maxMBLimit}MB`,
    acceptedFiles: '.pdf',
    uploadMultiple: false,
    parallelUploads: attachmentsLimit,
    chunking: true,
    customParams: {
      allNodes: [],
      fileList: [],
      chunked: false,
      dzuuid: '',
      file_id: '',
      file_parts: JSON.stringify([]),
      finished: false,
    },
  };

  const eventHandlers = {
    init: (passedDropzone) => {
      dropzone = passedDropzone;
    },
    addedfile: (file) => {
      document.querySelector('.error[data-dz-errormessage="true"]').innerHTML = '';
      if (dropzone.files.length > attachmentsLimit) {
        if (dropzone.files[0].status !== 'error') {
          return showNotification('Please, upload the only one file in PDF format', dropzone, file);
        }
        return showNotification('', dropzone, file);
      }
      if (dropzone.files.length === attachmentsLimit && file.type !== 'application/pdf') {
        return showNotification('Please, upload one file in PDF format', dropzone, file);
      }
      // TODO: pdf file name validation may be redundant here, as we're renaming each pdf document on the backend
      if (!FILE_NAME_REGEX.test(file.name)) {
        return showNotification('The file name should contain only latin letters, digits, underscore, dash, and dot symbols', dropzone, file);
      }
      if (file.size > TWENTY_MB_IN_BYTES) {
        return showNotification(`File is too big. Max file size: ${ATTACHMENT_DEFAULT_PARAMS.maxMBLimit}MB`, dropzone, file);
      }
      showSpinner(true);
    },
    error: (file, messageResponse) => {
      if (typeof messageResponse === "object" && messageResponse.errors?.message) {
        file.previewElement.querySelector('[data-dz-errormessage]').textContent = String(messageResponse.errors.message);
      }
      if (!file.accepted) {
        const newArray = dropzone.options.customParams.fileList.filter(element => element !== file.upload.uuid);
        dropzone.options.customParams.fileList = newArray;
      }
    },
    processing: (file, formData) => {
      if (formData) {
        formData.append('fieldId', 'file_path');
        formData.append('chunked', file.upload?.totalChunkCount > 1);
        formData.append('timeStamp', timeStamp);
      } else {
        dropzone.options.customParams.chunked = file.upload?.totalChunkCount > 1;
        dropzone.options.customParams.dzuuid = file.upload.uuid;
      }
      if (!dropzone.options.chunking) {
        dropzone.options.params = dropzone.options.customParams;
      }
    },
    sending: (file, xhr, formData) => {
      if (formData) {
        formData.append('fieldId', 'file_path');
        formData.append('chunked', file.upload?.totalChunkCount > 1);
        formData.append('timeStamp', timeStamp);
        if (dropzone.options.customParams.file_id) {
          formData.append('file_id', dropzone.options.customParams.file_id);
          formData.append('file_parts', dropzone.options.customParams.file_parts);
        }
      }
      xhr.onreadystatechange = function () {
        if (xhr.status === 200 && xhr.response) {
          let response = JSON.parse(xhr.response);
          if (response.file_id) {
            dropzone.options.customParams.file_id = response.file_id;
            dropzone.options.customParams.file_parts = JSON.stringify(response.file_parts);
            if (formData) {
              formData.append('file_id', response.file_id);
              formData.append('file_parts', JSON.stringify(response.file_parts));
            }
          } else {
            dropzone.options.customParams.file_id = '';
            dropzone.options.customParams.file_parts = JSON.stringify([]);
            if (formData) {
              formData.append('file_id', '');
              formData.append('file_parts', JSON.stringify([]));
            }
          }
        }
        if (xhr.status === SUCCESS_CREATE_CODE && xhr.response) {
          let response = JSON.parse(xhr.response);
          const templateName = file.name.slice(0, -4);
          dispatch(setAllInfoAboutTemplate({
            pdfTemplateLink: response.data.full_link,
            pdfTemplateKey: response.data.file_key,
            pdfTemplateWidth: response.data.pdf_width,
            pdfFormFields: response.data.pdf_form_fields,
            pdfTemplateInfo: {
              templateName: templateName,
              templateTitle: templateName,
              templateDescription: ''
            },
          }
          ));
        }
      };
    },
    success: () => {
      if (dropzone.options.chunking) {
        dropzone.options.customParams.file_id = '';
        dropzone.options.customParams.file_parts = JSON.stringify([]);
      }
    },
  }

  return (
    <div className="attachment-dropzone attachment-dropzone-wrapper p-3">
      <DropzoneComponent
        className="field-dropzone"
        config={componentConfig}
        eventHandlers={eventHandlers}
        djsConfig={djsConfig}
      />
      <div className="error text-center dz-error-message" data-dz-errormessage="true"></div>
    </div>
  );
};

PdfAttachmentZone.propTypes = {
  fieldKey: PropTypes.any,
  fieldValue: PropTypes.any,
  helpText: PropTypes.string
};

export default PdfAttachmentZone;