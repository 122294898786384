import type { Editor, Element, Node } from 'slate';
import { ICustomElementWithTextSize } from 'types/Editor';

export enum ListType {
  ORDERED = 'ol',
  UNORDERED = 'ul',
}

export interface ListsSchema {
  isConvertibleToListBlockNode(node: Node): boolean;
  isConvertibleToListTextNode(node: Node): boolean;
  isDefaultTextNode(node: Node): boolean;
  isListNode(node: Node, type?: ListType): boolean;
  isListItemNode(node: Node): boolean;
  isListItemTextNode(node: Node): boolean;
  createDefaultTextNode(props?: Partial<Element>): Element;
  createListNode(type?: ListType, props?: Partial<Element>): Element;
  createListItemNode(props?: Partial<ICustomElementWithTextSize>): Element;
  createListItemTextNode(props?: Partial<Element>): Element;
}

export interface ListsEditor extends ListsSchema, Editor {}