const AUTH_PREFIX = '/auth';
const STRIPE_PREFIX = '/stripe';
const MONDAY_COM_PREFIX = '/monday-com';
const SMS_VALIDATION = '/sms-validation';

const ENDPOINT = {
  RESOURCES: '/resources',
  ALL_DOCUMENTS: '/resources/documents',
  METADATA: '/metadata',
  DOCUMENT: '/document',
  DOCUMENTS: '/documents',
  DOCUMENT_EXECUTED: '/document-executed',
  COLLECTION: '/collection',
  // Collection Executed
  COLLECTION_EXECUTED: '/collection-executed',
  PUBLIC_COLLECTION_EXECUTED: '/public-collection-executed',
  PUBLIC_COLLECTION_EXECUTED_LINK: '/public-collection-executed-link',
  SECTION: '/section',
  SEND_DOCUMENTS: '/send-documents',
  FILES_URLS: '/files-urls',
  BATCH_EXCEL_EXPORT: '/batch-excel-export',
  DOCUMENT_HISTORY: '/document-history',
  SERVER_SIDE_EVENTS: '/server-side-events',
  USERS: '/users',
  USER: `${AUTH_PREFIX}/user`,
  GROUPS: '/groups',
  NOTIFICATION_COUNT: '/notifications/count',
  NOTIFICATION_LIST: '/notifications',
  SHARED_LINK: '/shared-link',
  RESET_PASSWORD: `${AUTH_PREFIX}/reset-password`,
  LOGIN: `${AUTH_PREFIX}/login`,
  REFRESH: `${AUTH_PREFIX}/refresh`,
  LOGOUT: `${AUTH_PREFIX}/logout`,
  COMPANY_BRANDING: '/company-branding',
  COMPANY_BRANDING_LOGO: '/company-branding/logo',
  USER_PERSONAL_DATA: '/personal-data',
  EMAILS_SETTINGS: '/emails-settings',
  REFERRAL_CODE: '/referral-code',
  // Stripe
  STRIPE_SUBSCRIPTION: `${STRIPE_PREFIX}/subscription`,
  STRIPE_SUBSCRIPTIONS: `${STRIPE_PREFIX}/subscriptions`,
  STRIPE_PRODUCTS: `${STRIPE_PREFIX}/products`,
  STRIPE_CHECKOUT: `${STRIPE_PREFIX}/checkout`,
  STRIPE_INVOICES: `${STRIPE_PREFIX}/invoices`,
  STRIPE_PAYMENT_METHODS_LIST: `${STRIPE_PREFIX}/payment-methods-list`,
  STRIPE_PAYMENT_METHOD: `${STRIPE_PREFIX}/payment-method`,
  // Monday.com Integration
  MONDAY_COM_ACCESS_TOKEN: `${MONDAY_COM_PREFIX}/access-token`,
  MONDAY_COM_BOARD: `${MONDAY_COM_PREFIX}/board`,
  MONDAY_COM_DOCUMENT_INTEGRATION: `${MONDAY_COM_PREFIX}/document-integration`,
  MONDAY_COM_DOCUMENT_MULTIPLE_APPLICATIONS: `${MONDAY_COM_PREFIX}/multiple-applications`,
  MONDAY_COM_SEND_EMAIL_MULTIPLE_APPLICATIONS: `${MONDAY_COM_PREFIX}/multiple-applications/send-email`,
  // SMS validation
  SMS_VALIDATION_SMS_UNIQUE_CODE: `${SMS_VALIDATION}/sms-unique-code`,
  SMS_VALIDATION_CODE_VERIFICATION: `${SMS_VALIDATION}/code-verification`,
};

export default ENDPOINT;