import { ChangeEvent } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useRolePermission } from 'hooks/useRolePermission';
import { setPageSettingValueByKey } from 'store/actions/pageSettings';
import { RootStateType } from 'store/reducers';
import { PageSettingsElementTypesEnum, PageSettingsType } from 'types/PageSettingsType';
import { getPageSettings } from 'utils/PageSettings';

const useRemindersSettingsStructure = () => {
  const dispatch = useDispatch();
  const { permissionUsingReminders } = useRolePermission();
  const pageSettings: PageSettingsType | null = useSelector((state: RootStateType) => getPageSettings(state));

  const onChangePropertyHandler = (
    type: PageSettingsElementTypesEnum,
    propKey: string,
    { target }: ChangeEvent<HTMLInputElement>,
  ) => {
    let value;
    if (type === PageSettingsElementTypesEnum.Checkbox) {
      value = target.checked;
    } else {
      value = Number(target.value);
    }
    dispatch(setPageSettingValueByKey({ key: propKey, value }));
  };

  return {
    permissionUsingReminders,
    pageSettings,
    onChangePropertyHandler,
  };
};

export default useRemindersSettingsStructure;