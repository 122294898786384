/* eslint-disable */
import { useEffect, useState } from 'react';

import { renderToStaticMarkup } from 'react-dom/server';
import DropzoneComponent from 'react-dropzone-component';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { FormHelperText } from '@mui/material';

import { STATUS_COMPLETED } from 'constants/documentStatuses';
import { ATTACHMENT_DEFAULT_PARAMS } from 'constants/editors';
import { CHUNK_SIZE, FORM_TYPE } from 'constants/general';
import { NOT_FOUND_CODE } from 'constants/generalErrors';
import { ACCEPTED_ATTACHMENT_TYPES } from 'constants/validation';
import { deleteAttachment, downloadAttachment } from 'services/api';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { setPublicFieldValidation } from 'store/actions/editorSlate';
import {
  addPublicPageAttachedFileToStore,
  deletePublicPageAttachedFileFromStore,
  setAttachmentsQueueCompleted,
  setSentFilesCounter,
} from 'store/actions/publicPages';
import AttachmentFileIcon from 'svg/AttachmentFileIcon';
import CloseIcon from 'svg/CloseIcon';
import { getSubmitButton } from 'utils/attachmentHelpers';
import { ACCESS_TOKEN } from 'utils/axiosInstance';
import { onFieldCustomKeyDown } from 'utils/hotkeysHelpers';
import { isNotEmptyObject } from 'utils/isEmptyObject';
import { getLastSplittedPartByFragment } from 'utils/stringsHelpers';
import { validateFormField } from 'utils/validation';

import 'scss/components/Dropzone/dropzone.scss';

const { REACT_APP_API_URL } = process.env;

class DropzoneWrapper {
  static instance = null;

  constructor() {
    if (DropzoneWrapper.instance) {
      return DropzoneWrapper.instance;
    }
    DropzoneWrapper.instance = this;
    this.dropzoneList = [];
  }

  setDropzone(dropzone) {
    this.dropzone = dropzone;
    this.dropzoneList.push(dropzone);
  }

  getDropzoneList() {
    return this.dropzoneList;
  }

  clearInstance() {
    DropzoneWrapper.instance = null;
  }
}

const AttachmentDropzone = ({
  fieldKey,
  fieldValue,
  helpText = '',
  properties,
  onChangeCount,
  isValid,
  errorMessage,
  onRemoveValue,
  isPDFDocument,
  field,
  useType,
}) => {
  const dispatch = useDispatch();
  let dropzone = null;
  const {
    documentId,
    attachments,
    currentDocument,
    data,
    documentType,
  } = useSelector((state) => state.publicPages);
  const { documentDetails, templateDetails } = useSelector((state) => state.user);
  const attachmentsLimit = properties?.limit || ATTACHMENT_DEFAULT_PARAMS.limit;
  const [attachedFilesValue, setAttachedFilesValue] = useState([]);
  const [validationFieldMessage, setValidationFieldMessage] = useState('');

  useEffect(() => {
    if (fieldValue) {
      const currentAttachedFilesArray = fieldValue ? fieldValue.split(',') : [];
      setAttachedFilesValue(currentAttachedFilesArray);
    }
    if (currentDocument && isNotEmptyObject(currentDocument) && currentDocument?.attachmentFields?.length) {
      const currentDropzone = currentDocument.attachmentFields?.find((field) => field.key === fieldKey);
      const newAttachedFilesArray = currentDropzone?.value ? currentDropzone?.value.split(',') : [];
      setAttachedFilesValue(newAttachedFilesArray);
    }
  }, [currentDocument, fieldValue]);

  const componentConfig = {
    iconFiletypes: ACCEPTED_ATTACHMENT_TYPES,
    showFiletypeIcon: false,
    postUrl: `${REACT_APP_API_URL}/document/${documentId}/files`,
  };

  const permissionsToUpdateAllNodes = [FORM_TYPE];
  const dropzoneWrapper = new DropzoneWrapper();

  const djsConfig = {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
    },
    autoProcessQueue: false,
    maxFiles: attachmentsLimit - attachedFilesValue.length || 0,
    maxFilesize: ATTACHMENT_DEFAULT_PARAMS.maxMBLimit,
    dictFileTooBig: `File is too big. Max file size: ${ATTACHMENT_DEFAULT_PARAMS.maxMBLimit}MB`,
    dictDefaultMessage: helpText || 'Drop files here to upload',
    acceptedFiles: ACCEPTED_ATTACHMENT_TYPES.join(','),
    customParams: {
      allNodes: [],
      fieldId: fieldKey || 0,
      fileList: [],
      chunked: false,
      dzuuid: '',
      file_id: '',
      file_parts: JSON.stringify([]),
      finished: false,
      attachedFilesValue,
    },
    chunking: true,
    uploadMultiple: false,
    parallelUploads: 1,
    autoQueue: true,
    parallelChunkUploads: false, // important to load only in one thread
    forceChunking: true,
    chunkSize: CHUNK_SIZE, // bytes = 5 MBytes
    timeout: 300000, // 5 minutes
    retryChunks: false,
    previewsContainer: `.dropzone-previews-new-${fieldKey}`,
    previewTemplate: renderToStaticMarkup(
      <div className="dz-preview">
        <div className="dzm-details">
          <div className="dzm-filename">
            <span className="dzm-file-icon">
              <AttachmentFileIcon />
            </span>
            <span className="dzm-name" data-dz-name="true" />
          </div>
          <a href="#" className="dzm-remove-file" data-dz-remove="true">
            <CloseIcon />
          </a>
        </div>
        <FormHelperText error variant="outlined" className="dzm-error-message-wrapper">
          <ErrorOutlineOutlinedIcon color="error" />
          <span className="px-1 dzm-error-message" data-dz-errormessage="true" />
        </FormHelperText>
      </div>,
    ),
  };

  const validateAttachmentField = ({
    addedFileFlow = false,
  }) => {
    if (isNotEmptyObject(field)) {
      const count = field.properties?.count ?? 0;
      const validationFieldMessage = validateFormField({
        ...field,
        properties: {
          ...field.properties,
          count: addedFileFlow
            ? count + 1
            : count > 0 ? count - 1 : count,
        },
      });
      dispatch(setPublicFieldValidation({
        fieldKey: field.key,
        validationMessage: validationFieldMessage,
      }));
      setValidationFieldMessage(validationFieldMessage);
    }
  };

  const setDocumentId = (currentDropzone) => {
    const submitButton = getSubmitButton();
    if (submitButton) {
      const multiTemplatesMode = submitButton.getAttribute('multitemplates');
      const documentId = submitButton.getAttribute('document-id');
      const currentFieldKey = submitButton.getAttribute('fieldkey');
      if (documentId || multiTemplatesMode) {
        const currentDocumentId = currentDropzone.options.documentId || documentId;
        currentDropzone.options.postUrl = `${REACT_APP_API_URL}/document/${currentDocumentId}/files`;
        currentDropzone.options.url = `${REACT_APP_API_URL}/document/${currentDocumentId}/files`;

        if (!currentFieldKey) {
          submitButton.setAttribute('fieldkey', fieldKey);
          return true;
        }
        if (Number(currentFieldKey) === currentDropzone.options.customParams.fieldId) {
          return true;
        }
      }
    }
    return false;
  };

  const runDropzoneProcess = (dropzone) => {
    const documentWasSet = setDocumentId(dropzone);
    if (documentWasSet) {
      if (dropzone.getQueuedFiles().length === 0) {
        updateDropzoneNodes(dropzone);
      } else {
        dropzone.processQueue();
      }
      return true;
    }
    return false;
  };

  const updateDropzoneNodes = (currentDropzone) => {
    const submitButton = getSubmitButton();
    const multiTemplatesMode = submitButton?.getAttribute('multitemplates');
    const newNodes = currentDropzone.options.customParams.allNodes.filter((id) => (
      id !== Number(currentDropzone.options.customParams.fieldId)
    ));

    if (newNodes.length > 0) {
      let nextDropzone = null;
      let nextId = 0;

      newNodes.some((id) => {
        const nextNode = document.querySelector(`.dropzone.attachment-field-${id}`);
        if (nextNode?.dropzone.getQueuedFiles().length > 0) {
          nextDropzone = nextNode;
          nextId = id;
          return true;
        }
        return false;
      });

      if (submitButton) {
        submitButton.setAttribute('fieldkey', nextId || newNodes[0]);
        dropzone = nextDropzone ? nextDropzone.dropzone : currentDropzone;
        dropzone.options.customParams.allNodes = newNodes;
        if (runDropzoneProcess(dropzone)) {
          return true;
        }
      }
    }

    currentDropzone.options.customParams.allNodes = newNodes;

    if (!multiTemplatesMode) {
      return false;
    }

    if (multiTemplatesMode && !currentDropzone.getUploadingFiles().length && !currentDropzone.getQueuedFiles().length) {
      currentDropzone.options.customParams.finished = true;

      const allNodeElements = document.querySelectorAll('.dropzone.field-dropzone');
      const nextNode = Array.from(allNodeElements).find((element) => !element?.dropzone.options.customParams.finished);
      if (nextNode && submitButton) {
        dropzone = nextNode.dropzone;
        submitButton.setAttribute('fieldkey', dropzone.options.customParams.fieldId);
        if (runDropzoneProcess(dropzone)) {
          return true;
        }
      }
    }
  };

  const eventHandlers = {
    init: (passedDropzone) => {
      setValidationFieldMessage('');
      dropzone = passedDropzone;
      const dropzoneID = dropzone.options.customParams.fieldId;
      const attachedFiles = attachments[dropzoneID] || [];

      const allNodeElements = document.querySelectorAll('.attachment-dropzone-wrapper');
      if (allNodeElements) {
        if (dropzone.options.customParams.allNodes.length === 0) {
          const allNodes = [];
          allNodeElements.forEach((element) => {
            allNodes.push(Number(element.getAttribute('fieldkey')));
          });
          dropzone.options.customParams.allNodes = allNodes;
        }
      }

      /**
       * Note: This functionality in theory should be available for all Dropzone types. But for fix bug
       * and not to change the logic of the application, we will limit this functionality only for "form" type.
       * It save us from the need to test all other types of Dropzone.
       */
      if (permissionsToUpdateAllNodes.includes(useType)) {
        dropzoneWrapper.setDropzone(passedDropzone);
        const dropzoneList = dropzoneWrapper.getDropzoneList();

        dropzoneList.forEach((dropzoneItem) => {
          dropzoneItem.options.customParams.allNodes = dropzone.options.customParams.allNodes;
        });
      }

      const submitButton = getSubmitButton();
      const fieldID = dropzone.options.customParams.fieldId;
      if (submitButton) {
        submitButton.addEventListener('click', (event) => {
          event.preventDefault();
          event.stopPropagation();
          const documentWasSet = setDocumentId(dropzone);
          if (documentWasSet) {
            const submitButtonFieldKey = Number(event.target.getAttribute('fieldkey'));
            if (dropzone.files.length > 0 && submitButtonFieldKey === dropzone.options.customParams.fieldId) {
              dropzone.processQueue();
              if (dropzone.options.customParams.fileList.length === 0) {
                updateDropzoneNodes(dropzone);
              }
              return true;
            }
            if (dropzone.getQueuedFiles().length === 0) {
              return updateDropzoneNodes(dropzone);
            }
          }
        });
      }

      if (attachedFiles.length) {
        attachedFiles.forEach((file) => {
          if (file.status !== 'error') {
            dropzone.files.push(file);
            dropzone.emit('addedfile', file);
            dropzone.options.customParams.fileList.push(file.upload.uuid);
          } else {
            dispatch(deletePublicPageAttachedFileFromStore(fieldID, file));
            dropzone.options.customParams.fileList.filter((element) => element !== file.upload.uuid);
          }
        });
      }
    },
    addedfile: (file) => {
      setTimeout(() => {
        if (file.status !== 'error') {
          const fileList = dropzone.options.customParams.fileList;
          fileList.push(file.upload.uuid);
          const fieldID = dropzone.options.customParams.fieldId;
          const currentFiles = dropzone.files.filter((dFile) => (
            dFile.lastModified.toString() === file.lastModified.toString()
            && dFile.name === file.name
            && dFile.size === file.size
          ));
          if (currentFiles.length > 1) {
            file.status = 'error';
            file.accepted = false;
            dropzone.emit('error', file, 'Duplicate of file');
          } else {
            dispatch(addPublicPageAttachedFileToStore(fieldID, file));
            onChangeCount && onChangeCount(
              attachedFilesValue.length + fileList.length,
              dropzone.options.customParams.attachedFilesValue,
            );
          }
        }
        validateAttachmentField({ addedFileFlow: true });
      }, 0);
    },
    error: (file, messageResponse) => {
      if (typeof messageResponse === 'object' && messageResponse.errors?.message) {
        file.previewElement.querySelector('[data-dz-errormessage]').textContent = String(messageResponse.errors.message);
      }
      if (!file.accepted) {
        const newArray = dropzone.options.customParams.fileList.filter((element) => element !== file.upload.uuid);
        dropzone.options.customParams.fileList = newArray;
        onChangeCount && onChangeCount(
          attachedFilesValue.length + newArray.length,
          dropzone.options.customParams.attachedFilesValue,
        );
      }
      // To continue the next file if an error returned from the backend side
      if (file.processing === true && file.status === 'error') {
        dropzone.processQueue();
      }
    },
    removedfile: (file) => {
      if (file.status !== 'error') {
        const newArray = dropzone.options.customParams.fileList.filter((element) => element !== file.upload.uuid);
        dropzone.options.customParams.fileList = newArray;
        onChangeCount && onChangeCount(
          attachedFilesValue.length + newArray.length,
          dropzone.options.customParams.attachedFilesValue,
        );

        const fieldID = dropzone.options.customParams.fieldId;
        setTimeout(() => {
          dispatch(deletePublicPageAttachedFileFromStore(fieldID, file));
          validateAttachmentField({ addedFileFlow: false });
        }, 0);
      }
      const rejectedFiles = dropzone.getRejectedFiles();
      if (rejectedFiles.length) {
        const newFile = Object.assign(rejectedFiles[0], { accepted: true, status: 'added' });
        dropzone.accept(newFile, (error) => {
          // Note: error === undefined when we have our custom error for file duplicates.
          if (!error || error === dropzone.options.dictMaxFilesExceeded || error === undefined) {
            // Note: dropzone.enqueueFile(newFile) needed for sending files after re-add. Without this we can't send files.
            dropzone.enqueueFile(newFile);
            newFile.previewElement.classList.remove('dz-error');
            newFile.previewElement.classList.add('d-none');
            dropzone.emit('addedfile', newFile);
          }
        });
      }
    },
    processing: (file, formData) => {
      if (formData) {
        formData.append('fieldId', fieldKey);
        formData.append('chunked', file.upload?.totalChunkCount > 1);
      } else {
        dropzone.options.customParams.chunked = file.upload?.totalChunkCount > 1;
        dropzone.options.customParams.dzuuid = file.upload.uuid;
      }
    },
    sending: (file, xhr, formData) => {
      if (formData) {
        formData.append('fieldId', fieldKey);
        formData.append('chunked', file.upload?.totalChunkCount > 1);
        formData.append('doc_type', documentType);
        if (dropzone.options.customParams.file_id) {
          formData.append('file_id', dropzone.options.customParams.file_id);
          formData.append('file_parts', dropzone.options.customParams.file_parts);
        }
      }
      xhr.onreadystatechange = () => {
        if (xhr.status === 200 && xhr.response) {
          const response = JSON.parse(xhr.response);
          if (response.file_id) {
            dropzone.options.customParams.file_id = response.file_id;
            dropzone.options.customParams.file_parts = JSON.stringify(response.file_parts);
            if (formData) {
              formData.append('file_id', response.file_id);
              formData.append('file_parts', JSON.stringify(response.file_parts));
            }
          } else {
            dropzone.options.customParams.file_id = '';
            dropzone.options.customParams.file_parts = JSON.stringify([]);
            if (formData) {
              formData.append('file_id', '');
              formData.append('file_parts', JSON.stringify([]));
            }
          }
        }
      };
      dispatch(setSentFilesCounter());
    },
    success: () => {
      if (dropzone.options.chunking) {
        dropzone.options.customParams.file_id = '';
        dropzone.options.customParams.file_parts = JSON.stringify([]);
        const documentWasSet = setDocumentId(dropzone);
        if (documentWasSet) {
          dropzone.processQueue();
        }
      }
    },
    queuecomplete: () => {
      updateDropzoneNodes(dropzone);
      // It's used on fill and sign pages in order to know if there are exist files for uploading
      dispatch(setAttachmentsQueueCompleted());
      dropzoneWrapper.clearInstance();
    },
  };

  const handleDownloadFile = async (fileUrl) => {
    const response = await apiErrorHandler(
      downloadAttachment,
      { path: fileUrl, returnErrorResult: true },
    );
    if (response && response.status === NOT_FOUND_CODE) {
      toast.error(response.data.errors);
    }
  };

  const handleDeleteFile = async (fileUrl) => {
    const resultMessage = await apiErrorHandler(
      deleteAttachment,
      {
        path: fileUrl,
        isPdfDocument: isPDFDocument,
        resourceType: documentType,
        resource_id: documentDetails?.id || templateDetails?.id,
      },
    );
    if (resultMessage) {
      toast.success(resultMessage);
      const newValueArray = attachedFilesValue.filter((file) => file !== fileUrl);
      setAttachedFilesValue(newValueArray);
      onRemoveValue(newValueArray);
      const dropzone = document.querySelector(`.dropzone.attachment-field-${fieldKey}`).dropzone;
      dropzone.options.customParams.attachedFilesValue = newValueArray;
    }
  };

  const handleFieldWrapperKeyDown = (event) => {
    onFieldCustomKeyDown(event, () => {
      const currentNode = document.querySelector(`.dropzone.attachment-field-${fieldKey}`);
      if (currentNode?.dropzone) {
        currentNode.click();
      }
    });
  };

  return (
    <div
      className="w-100 custom-attachment-wrapper attachment-dropzone-wrapper"
      fieldkey={fieldKey}
      tabIndex={0}
      role="button"
      onKeyDown={handleFieldWrapperKeyDown}
    >
      {
        data?.status !== STATUS_COMPLETED
          ? (
            <DropzoneComponent
              className={`field-dropzone attachment-field-${fieldKey} custom-attachment-field`}
              config={componentConfig}
              eventHandlers={eventHandlers}
              djsConfig={djsConfig}
            />
          )
          : null
      }
      <div className={`dropzone-previews dropzone-previews-${fieldKey}`}>
        {
        attachedFilesValue.map((fileUrl, index) => {
          const fileName = getLastSplittedPartByFragment(fileUrl);
          return (
            <div className="dz-preview" key={fileUrl + index}>
              <div className="dzm-details">
                <div
                  className="dzm-filename dz-message mx-2 py-1"
                  onClick={() => handleDownloadFile(fileUrl)}
                  role="button"
                  tabIndex={-1}
                  title="Download the file"
                >
                  <span className="dzm-name">
                    {fileName}
                  </span>
                </div>
                { data?.status !== STATUS_COMPLETED
                  ? (
                    <a
                      href="#"
                      className="dzm-remove-file"
                      onClick={() => handleDeleteFile(fileUrl)}
                    >
                      <CloseIcon />
                    </a>
                  )
                  : null}
              </div>
              <FormHelperText error variant="outlined" className="dzm-error-message-wrapper">
                <ErrorOutlineOutlinedIcon color="error" />
                <span className="px-1 dzm-error-message" data-dz-errormessage="true" />
              </FormHelperText>
            </div>
          );
        })
      }
      </div>
      <div className={`dropzone-previews dropzone-previews-new-${fieldKey}`} />
      {
        isValid && !validationFieldMessage
          ? null
          : (
            <FormHelperText error variant="outlined">
              <ErrorOutlineOutlinedIcon color="error" />
              <span className="px-1">{errorMessage || validationFieldMessage}</span>
            </FormHelperText>
          )
      }
    </div>
  );
};

export default AttachmentDropzone;