import { useDispatch } from 'react-redux';

import { sendUniqueCodeToUser } from 'store/actions/smsValidation';

const useSendUniqueCode = () => {
  const dispatch = useDispatch();

  const sendUniqueCode = () => {
    dispatch(sendUniqueCodeToUser());
  };

  return {
    sendUniqueCode,
  };
};

export default useSendUniqueCode;