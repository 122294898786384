import { forwardRef, ForwardRefRenderFunction, memo, ReactNode } from 'react';

import { Button } from 'reactstrap';

import cn from 'classnames';
import PoliciesModal from 'components/Modals/Policies/PoliciesModal';

import classes from 'scss/components/PublicFooter/PublicFooter.module.scss';

interface IPublicFooterProps {
  children: ReactNode;
}

const PublicFooter: ForwardRefRenderFunction<HTMLDivElement, IPublicFooterProps> = ({
  children,
}, ref) => (
  <div
    className={cn(
      `${classes.PublicFooter} public-footer`,
    )}
    ref={ref}
  >
    <div className={classes.PublicFooterChildren}>
      {children}
    </div>
    <PoliciesModal isPublicPage />
    <Button className="hidden attachments-loading" id="attachments-loading" />
  </div>
);

export default memo(forwardRef(PublicFooter));