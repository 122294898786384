import { RenderLeafProps } from 'slate-react';

const Leaf: React.FC<RenderLeafProps> = ({ attributes, children, leaf }) => {
  let currentChildren = children;
  if (leaf.highlight) {
    currentChildren = <span {...attributes} style={{ backgroundColor: '#ffeeba' }}>{currentChildren}</span>;
  }

  if (leaf.bold) {
    currentChildren = <strong>{currentChildren}</strong>;
  }

  if (leaf.strikethrough) {
    currentChildren = <del>{currentChildren}</del>;
  }

  if (leaf.superscript) {
    currentChildren = <sup>{currentChildren}</sup>;
  }

  if (leaf.subscript) {
    currentChildren = <sub>{currentChildren}</sub>;
  }

  if (leaf.code) {
    currentChildren = <code>{currentChildren}</code>;
  }

  if (leaf['text-size'] && leaf.size) {
    currentChildren = <span style={{ fontSize: `${leaf.size}px` }}>{currentChildren}</span>;
  }

  if (leaf.italic) {
    currentChildren = <em>{currentChildren}</em>;
  }

  if (leaf.underline) {
    currentChildren = <u>{currentChildren}</u>;
  }

  return <span {...attributes}>{currentChildren}</span>;
};

export default Leaf;