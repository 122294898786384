import { toast } from 'react-toastify';

import { BaseSelection, Editor, Element, NodeEntry, Transforms } from 'slate';

import { TABLE_QUESTION_BODY, TABLE_QUESTION_ROW } from 'constants/editors';
import {
  DEFAULT_TABLE_QUESTION_CELL_HEIGHT,
  DEFAULT_TABLE_QUESTION_CELL_WIDTH,
  MAX_TABLE_QUESTION_ROW_COUNT,
  ROWS_INSERTING_MESSAGE,
  TABLE_QUESTION_INSERT_POSITION,
} from 'constants/tableQuestion';
import { findField } from 'hooks/useSortedSections';
import { ICustomElement } from 'types/Editor';
import { TableQuestionInsertRowPositionType } from 'types/TableQuestion';
import { createTableQuestionBodyRow, tableNodeGenerator } from 'utils/TableQuestion/createTableQuestionHelpers';
import { findEditorNodesByPosition } from 'utils/TableQuestion/tableQuestionHelpers';

interface IInsertTableQuestionRowProps {
  editor: Editor,
  tableBody: NodeEntry<ICustomElement> | undefined,
  selection: BaseSelection,
  position?: TableQuestionInsertRowPositionType;
}

const insertTableQuestionRow = ({
  editor,
  tableBody,
  selection,
  position = TABLE_QUESTION_INSERT_POSITION.BELOW,
}: IInsertTableQuestionRowProps): void => {
  if (!tableBody || !selection) {
    toast.warning(ROWS_INSERTING_MESSAGE.CANT_INSERT_HERE);
    return;
  }

  const bodyNodes = findEditorNodesByPosition(editor, selection, [TABLE_QUESTION_BODY]);
  const rowNodes = findEditorNodesByPosition(editor, selection, [TABLE_QUESTION_ROW]);
  if (!rowNodes || !Array.isArray(rowNodes) || !bodyNodes) return;

  const [body] = bodyNodes;
  const [row] = rowNodes;

  if (body[0].children.length >= MAX_TABLE_QUESTION_ROW_COUNT) {
    toast.warning(ROWS_INSERTING_MESSAGE.CANT_INSERT_MORE);
    return;
  }

  if (!row || !Array.isArray(row)) return;

  const [tableRow] = row;

  const rowFields: ICustomElement[] = [];

  tableRow.children.forEach((cell) => {
    findField(cell, (node) => {
      if (Element.isElement(node) && node.type) {
        rowFields.push(node);
      }
    });
  });

  const getFieldTextName = (text: string, defaultText: string = 'DEFAULT_TABLE_FIELD:') => {
    const splitText = text.split(':');
    return `${defaultText}${splitText[splitText.length - 1]}`;
  };

  const options = rowFields.map((field) => ({
    type: field.type,
    name: getFieldTextName(field.name ?? ''),
    children: [{ text: getFieldTextName(field.children[0].text ?? '', 'Default Table Field:') }],
  }));

  const columns = row[0].children?.length || 0;
  const newRow = Array.from(
    tableNodeGenerator({
      count: 1,
      callback: createTableQuestionBodyRow,
      args: {
        columns: columns - 1,
        options,
      },
    }),
  );

  newRow[0].children = newRow[0].children.map((cell, index) => {
    const prevRowElement = tableRow.children[index];
    if (!Element.isElement(prevRowElement)) return cell;
    return {
      ...cell,
      width: prevRowElement.width ?? DEFAULT_TABLE_QUESTION_CELL_WIDTH,
      height: DEFAULT_TABLE_QUESTION_CELL_HEIGHT,
    };
  });

  const [, startPath] = row;
  const rowIndex = startPath[startPath.length - 1];

  const isAbovePosition = position === TABLE_QUESTION_INSERT_POSITION.ABOVE;

  if ((rowIndex === 0 || rowIndex === 1) && isAbovePosition) {
    toast.warning(ROWS_INSERTING_MESSAGE.CANT_INSERT_ABOVE);
    return;
  }
  if (rowIndex === 0 && !isAbovePosition) return;

  const targetIndex = isAbovePosition ? rowIndex : rowIndex + 1;

  Transforms.insertNodes(
    editor,
    newRow,
    { at: [...startPath.slice(0, -1), targetIndex] },
  );
};

export default insertTableQuestionRow;