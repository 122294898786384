import { FC } from 'react';

import cn from 'classnames';
import { EditorGridAxisCount } from 'constants/editors';

import 'scss/components/Editor/_editor.scss';

interface IEditorWrapperProps {
  rows?: EditorGridAxisCount;
}

const EditorWrapper: FC<IEditorWrapperProps> = ({
  rows = EditorGridAxisCount.Two,
  children,
}) => (
  <div
    className={cn(
      'editor-wrapper',
      {
        'one-row': rows === EditorGridAxisCount.One,
        'two-rows': rows === EditorGridAxisCount.Two,
        'three-rows': rows === EditorGridAxisCount.Three,
      },
    )}
  >
    {children}
  </div>
);

export default EditorWrapper;