import moment from 'moment';

export const createLabels = (labelArray: moment.MomentInput[] | undefined = []) => {
  const labels = [
    {
      label: 'Created On',
      text: labelArray[0] ? moment(labelArray[0]).format('MMMM DD, YYYY') : '',
    },
    {
      label: 'Last Modified',
      text: labelArray[1] ? moment(labelArray[1]).format('MMMM DD, YYYY') : '',
    },
  ];

  return labels;
};

export default null;