/* eslint-disable */
import React, {
  HTMLAttributes,
  useMemo,
  useRef,
} from 'react';
import { useSlate } from 'slate-react';
import { Editor, Element as SlateElement, NodeEntry, Transforms } from 'slate';
import { IconButton } from '@material-ui/core';
import { Cardbar } from './cardbar';
import {
  insertAbove,
  insertBelow,
  removeTable,
  //insertLeft,
  //insertRight,
  //removeColumn,
  removeRow,
} from './commands';
import AddTableRowAboveIcon from 'svg/AddTableRowAboveIcon';
import AddTableRowBelowIcon from 'svg/AddTableRowBelowIcon';
import AddTableColumnRightIcon from 'svg/AddTableColumnRightIcon';
import AddTableColumnLeftIcon from 'svg/AddTableColumnLeftIcon';
import TableRemoveColumnIcon from 'svg/TableRemoveColumnIcon';
import TableRemoveRowIcon from 'svg/TableRemoveRowIcon';
import './ui.css';

interface TableCardbarProps extends HTMLAttributes<HTMLDivElement> {}

export const TableCardbar: React.FC<TableCardbarProps> = props => {
  const editor = useSlate();

  const [table] = Array.from(
    Editor.nodes(editor, {
      match: n => SlateElement.isElement(n) && n.type === 'table',
    }),
  );

  const run = (action: (table: any, editor: Editor) => any) => () => {
    action(table, editor);

    const { selection } = editor;
    if (selection) {
      Transforms.deselect(editor);
    }
  }

  return (
    <Cardbar
      className={`${props.className} table-cardbar`}
      delete={run(removeTable)}
    >
      <IconButton onMouseDown={run(insertAbove)} title="Insert row above">
        <AddTableRowAboveIcon />
      </IconButton>
      <IconButton onMouseDown={run(insertBelow)} title="Insert row below">
        <AddTableRowBelowIcon />
      </IconButton>
      {/*
      <IconButton onMouseDown={run(insertLeft)} title="Insert column left">
        <AddTableColumnLeftIcon />
      </IconButton>
      <IconButton onMouseDown={run(insertRight)} title="Insert column right">
        <AddTableColumnRightIcon />
      </IconButton>
      <IconButton onMouseDown={run(removeColumn)} title="Remove current column">
        <TableRemoveColumnIcon />
      </IconButton>*/}
      <IconButton onMouseDown={run(removeRow)} title="Remove current row">
        <TableRemoveRowIcon />
      </IconButton>
    </Cardbar>
  );
};

export const HorizontalToolbar: React.FC<{
  table: HTMLElement;
  tableNode: NodeEntry;
}> = ({ table, tableNode }) => {
  const maxWidth = useMemo(() => table.offsetWidth, [table]);

  return (
    <div contentEditable={false} style={{ userSelect: "none" }} className="table-horizontal-toolbar">
      <div id="horizontal-dragger-item" className="table-dragger-item" style={{ maxWidth: `${maxWidth}px` }}>
        <div className="table-trigger"></div>
      </div>
    </div>
  );
};

export const VerticalToolbar: React.FC<{
  table: HTMLElement;
  tableNode: NodeEntry;
}> = ({ table, tableNode }) => {
  const maxHeight = useMemo(() => table.offsetHeight, [table]);

  return (
    <div contentEditable={false} style={{ userSelect: "none" }} className="table-vertical-toolbar">
      <div id="vertical-dragger-item" className="table-dragger-item" style={{ height: `${maxHeight}px` }}>
        <div className="table-trigger"></div>
      </div>
    </div>
  );
};
