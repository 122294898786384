import { useParams, useRouteMatch } from 'react-router-dom';

import { IPublicPageRouteMatch } from 'types/PublicPage';

const usePublicPageRouteMatch = (): IPublicPageRouteMatch => {
  const { url } = useRouteMatch();
  const { id }: { id: string } = useParams();
  const [pageType] = url.slice(1).split('/');

  return {
    url,
    pageType,
    id,
  };
};

export default usePublicPageRouteMatch;