import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { clearSignNowStore, setIsSignNowActive } from 'store/actions/signNow';
import { RootStateType } from 'store/reducers';
import { MatchParams } from 'types/Route';
import { UseSignNowPageHookType } from 'types/signNow/base';

const useSignNowPage: UseSignNowPageHookType = () => {
  const dispatch = useDispatch();
  const { id }: MatchParams = useParams();
  const {
    file,
    document,
    isRemindDocumentActive,
  } = useSelector((state: RootStateType) => state.signNow);
  const isSignNow = useSelector((state: RootStateType) => state.signNow.isSignNow);

  useEffect(() => {
    if (!isSignNow) {
      dispatch(setIsSignNowActive());
    }
  }, [dispatch, isSignNow]);

  useEffect(() => () => {
    dispatch(clearSignNowStore());
  }, [dispatch]);

  const [initStep, setInitStep] = useState(1);

  useEffect((): void => {
    if (isRemindDocumentActive) return setInitStep(3);
    if (document.id || id) return setInitStep(2);
    if (!file) return setInitStep(0);
  }, [document.id, file, id, isRemindDocumentActive]);

  return {
    initStep,
  };
};

export default useSignNowPage;