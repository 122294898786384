export const enum BrowserNameEnum {
  CHROME = 'Chrome',
  EDGE = 'Edge',
  FIREFOX = 'Firefox',
  SAFARI = 'Safari',
  OPERA = 'Opera',
  INTERNET_EXPLORER = 'Internet Explorer',
  UNKNOWN = 'Unknown'
}

export const getBrowserName = () => {
  const userAgent = navigator.userAgent;

  if (userAgent.match(/edg/i)) return BrowserNameEnum.EDGE;
  if (userAgent.match(/chrome|chromium|crios/i)) return BrowserNameEnum.CHROME;
  if (userAgent.match(/firefox|fxios/i)) return BrowserNameEnum.FIREFOX;
  if (userAgent.match(/safari/i)) return BrowserNameEnum.SAFARI;
  if (userAgent.match(/opr\//i)) return BrowserNameEnum.OPERA;
  if (userAgent.match(/msie|trident/i)) return BrowserNameEnum.INTERNET_EXPLORER;
  return BrowserNameEnum.UNKNOWN;
};

export const isSafariBrowser = () => (
  getBrowserName() === BrowserNameEnum.SAFARI
);