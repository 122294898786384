/* eslint-disable */
function findNode(node: Element, index: number, callback: (el: Element, index: number) => void) {
  if (node.tagName === 'IMG') return callback(node, index);
  const children = Array.from(node.children);
  if (children.length === 0 && node.innerHTML.length) {
    return callback(node, index);
  } else {
    children.forEach((child: Element) => {
      if (child.children.length === 0  && child.innerHTML.length) {
        return callback(child, index);
      } else {
        return findNode(child, index, callback);
      }
    })
  }
}

export const trimHTMLValue = (value: string): string => {
  let startIndex = -1;
  let endIndex = 0;

  const html = document.createElement('html');
  html.innerHTML = value;
  const body = html.querySelector('body');
  if (body) {
    const children = Array.from(body.children);
    if (children.length) {
      children.forEach((node: Element, index: number) => {
        findNode(node, index, (element: Element, i) => {
          if (startIndex === -1) {
            startIndex = i;
          }
          endIndex = i;
        });
      })
    }

    if (startIndex === -1) {
      return '';
    }

    const arr = children.slice(startIndex, endIndex + 1);
    return arr.map((element: Element) => element.outerHTML).join('');
  }

  return '';
}