import { useSelector } from 'react-redux';

import { Element as SlateElement } from 'slate';

import AttachmentDropzone from 'components/AttachmentDropzone';
import FieldName from 'components/Editor/FormView/Fields/FieldName';
import { RootStateType } from 'store/reducers';
import { IPublicPageDocumentStructure } from 'types/PublicPage';
import getFieldName from 'utils/PublicPage/getFieldName';

const AttachmentsView = () => {
  const { main } = useSelector((state: RootStateType) => state.publicPages.structure);
  const {
    attachmentFields,
  }: IPublicPageDocumentStructure = useSelector((state: RootStateType) => (
    state.publicPages.currentDocument
  ));

  const {
    validationFields,
  } = useSelector((state: RootStateType) => state.editorSlate.publicFormValidationResults);

  return (
    <div className="d-flex flex-column align-self-center max-w-450 mx-auto p-3">
      {
        attachmentFields.map((field: Partial<SlateElement>) => {
          if (field.assignment !== main.mainAssignment) return null;
          const errorMessages = validationFields[field.key || 0];
          return (
            <label key={field.key}>
              <FieldName
                name={getFieldName(field, false)}
                isRequired={!!field.requiredField}
                additionalClassName="field-name"
              />
              <AttachmentDropzone
                key={`attachments_${field.key}`}
                fieldKey={field.key}
                fieldValue={field.value}
                helpText={field.helpText}
                properties={field.properties}
                onChangeCount={() => undefined}
                onRemoveValue={() => undefined}
                isValid={!errorMessages}
                errorMessage={errorMessages}
                isPDFDocument={false}
                field={field}
                useType={undefined}
              />
            </label>
          );
        })
      }
    </div>
  );
};

export default AttachmentsView;