/* eslint-disable */
export const replacedString = (
  stringToReplace: string,
  searchFor: string,
  replacer: string
) => {
  //the extra parens in new Regexp() capture the value found.
  // the $1 in str.replace inserts the captured value
  const regExp = new RegExp("(" + searchFor + ")", "gi");
  return stringToReplace.replace(regExp, replacer);
};

export const replaceSearch = (stringToReplace: string = "") => {
  const openRegexp = /(<mark([^>]+)>)/gi;
  const closeRegexp = /(<\/mark>)/gi;
  return stringToReplace.replace(openRegexp, "").replace(closeRegexp, "");
};

export const foundMatch = (search: string, templateSections: any) => {
  if (!search) return templateSections;
  const match = templateSections?.map((sect: any) => ({
    ...sect,
    content: foundMatchToString(search, sect.content),
    name: foundMatchToString(search, sect.name),
  }));

  return match;
};

export const replaceAllMatches = (
  searchString: string,
  replaceString: string,
  templateSections: any
) => {
  const match = templateSections?.map((sect: any) => ({
    ...sect,
    content: replacedString(sect.content, searchString, replaceString),
    name: replacedString(sect.name, searchString, replaceString),
  }));

  return match;
};

export const deteleHTMLTagToString = (documentContentText: string): string[] => {
  const htmlRegex = /<[^<>]+>/g;
  return documentContentText
    .split(htmlRegex)
    .filter(item => item !== "");
}

export const replacedStringToArray = (
  searchString: string,
  replacer: string,
  documentContentArrayText: string[]
) => {
  return documentContentArrayText.map(item => {
    return replacedString(item, searchString, replacer)
  });
}

export const updatedDocumentContentText = (
  replacedStringToArray: string[],
  documentContentText: string[],
  documentContent: string
): string => {
  let newDocumentContent = "";

    documentContentText.forEach((item, index) => {
      if(newDocumentContent.length < 1) {
        const idx = documentContent.indexOf(item);

        newDocumentContent = `${documentContent.slice(0, idx)}${replacedStringToArray[index]}${documentContent.slice(idx + item.length)}`;
      } else {
        const idx = newDocumentContent.indexOf(item);

        newDocumentContent = `${newDocumentContent.slice(0, idx)}${replacedStringToArray[index]}${newDocumentContent.slice(idx + item.length)}`;
      }
    })

  return newDocumentContent;
}

export const foundMatchToString = (searchString: string, documentContent: string): string => {
  const foundSearch = documentContent.includes(searchString);

  if (!foundSearch || searchString === "") return documentContent;

  const documentContentText = deteleHTMLTagToString(documentContent);
  const replacer = '<mark class="searchMatch">$1</mark>';
  const replacedStr = replacedStringToArray(searchString, replacer, documentContentText);
  return updatedDocumentContentText(replacedStr, documentContentText, documentContent);
}

export const replaceAllMatchesToString = (
  searchString: string,
  replaceString: string,
  documentContent: string
) => {
  const foundSearch = documentContent.includes(searchString);

  if (searchString === ""
    && replaceString === ""
    && !foundSearch
  ) return documentContent;

  const documentContentText = deteleHTMLTagToString(documentContent);
  const replacedStr = replacedStringToArray(searchString, replaceString, documentContentText);
  return updatedDocumentContentText(replacedStr, documentContentText, documentContent);
};

export const getPlural = (word: string, count: number, ending='s'): string => (
  count === 1 ? word : `${word}${ending}`
);