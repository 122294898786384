/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEventHandler, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { SwiperSlide } from 'swiper/react';

import Editor from 'components/Editor';
import EditorContainer from 'components/Editor/EditorContainer';
import EditorWrapper from 'components/Editor/EditorWrapper';
import FormsSlider from 'components/Editor/FormView/FormsSlider';
import EditorHeader from 'components/EditorHeader/EditorHeader';
import ModalContentWindow from 'components/Modals/ModalContentWindow';
import ShareLinkModal from 'components/Modals/ModalWrapper';
import PdfEditor from 'components/PdfEditor/PdfEditor';
import { STATUSES } from 'constants/documentStatuses';
import {
  EditorGridAxisCount,
} from 'constants/editors';
import { SOMETHING_WENT_WRONG } from 'constants/generalErrors';
import ROUTES from 'constants/routes';
import {
  deletePdfPartsMultiTemplate,
  setPdfPartOfMultiTemplate,
} from 'store/actions/collections';
import {
  createCollectionExecutedPublicLink,
  getCollectionExecutedDetails,
  setCollectionExecutedDetails,
  setCollectionExecutedPublicLink,
  updateCollectionExecutedDetails,
} from 'store/actions/collectionsExecuted';
import { setAllAssignments } from 'store/actions/editorSlate';
import { RootStateType } from 'store/reducers';
import { initialSigners } from 'store/reducers/editorSlate';
import { ICollectionExecutedDetails } from 'types/CollectionExecuted';
import { AssignmentsMainParts, AssignmentsObjectType } from 'types/Editor';
import {
  MultiTemplateStateType,
} from 'types/MultiTemplate';
import { getUnitedAssignmentsArray } from 'utils/collectionsHelpers';
import { getHtmlAndCssForCollectionDocuments } from 'utils/fileLinkDownload';
import { validateFormField } from 'utils/validation';

const CollectionExecutedDetails = () => {
  const { id } = useParams<{ id?: string }>();
  const dispatch = useDispatch();
  const { collectionExecutedPublicLink } = useSelector((state: RootStateType) => state.publicPages);
  const { isLoading }: MultiTemplateStateType = useSelector((state: RootStateType) => state.errorLoading);
  const { statuses } = useSelector((state: RootStateType) => state.user);
  const collectionExecutedDetails: ICollectionExecutedDetails = useSelector(
    (state: RootStateType) => state.user.collectionExecutedDetails,
  );

  const [documentsList, setDocumentsList] = useState<Partial<any>[]>([]);
  const [collectionExecutedName, setCollectionExecutedName] = useState<string>('');
  const [collectionExecutedDescription, setCollectionExecutedDescription] = useState<string>('');
  const [collectionExecutedStatus, setCollectionExecutedStatus] = useState<string>('');

  const [fieldNameError, setFieldNameError] = useState<string>('');
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [showShareLinkModal, setShowShareLinkModal] = useState<boolean>(false);

  const [collectionAssignments, setCollectionAssignments] = useState<AssignmentsObjectType>(initialSigners);

  const managersAssignmentsLength = collectionAssignments[AssignmentsMainParts.MANAGERS].length;
  const recipientsAssignmentsLength = collectionAssignments[AssignmentsMainParts.MANAGERS].length;

  useEffect(() => {
    dispatch(setAllAssignments(collectionAssignments));
  }, [managersAssignmentsLength, recipientsAssignmentsLength]);

  useEffect(() => {
    if (id) {
      dispatch(getCollectionExecutedDetails(Number(id)));
    }
    return () => {
      setCollectionExecutedName('');
      setCollectionExecutedDescription('');
      setDocumentsList([]);
      dispatch(deletePdfPartsMultiTemplate());
      dispatch(setCollectionExecutedDetails(null));
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (!collectionExecutedDetails) return;

    const documents = collectionExecutedDetails.documents && collectionExecutedDetails.documents.map(
      (documentData: any) => {
        const document = documentData.document;
        if (document.template.pdf_file_url) {
          dispatch(setPdfPartOfMultiTemplate(document.template, document.content_json));
        }
        return {
          ...document,
          position: documentData.position,
        };
      },
    ).sort((a: any, b: any) => a.position - b.position);
    setCollectionAssignments(getUnitedAssignmentsArray(documents));
    setDocumentsList(documents || []);
    setCollectionExecutedName(collectionExecutedDetails.name || '');
    setCollectionExecutedDescription(collectionExecutedDetails.description || '');
    if (collectionExecutedDetails.status) {
      setCollectionExecutedStatus(collectionExecutedDetails.status);
    }
  }, [collectionExecutedDetails?.documents]);

  const onChangeName: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    const currentValue = target.value;
    if (currentValue) {
      setFieldNameError('');
    }
    setCollectionExecutedName(currentValue);
  };

  const saveCollectionHandler = async (newStatus?: string) => {
    const errorMessage = validateFormField({
      name: 'form_name',
      value: collectionExecutedName,
      required: true,
    });
    setFieldNameError(errorMessage);
    if (errorMessage) return false;

    let collectionDocuments = collectionExecutedDetails.documents;

    if (newStatus === STATUSES.completed) {
      collectionDocuments = await getHtmlAndCssForCollectionDocuments(collectionExecutedDetails);
    }

    const updatedCollectionExecuted: any = {
      ...collectionExecutedDetails,
      documents: collectionDocuments,
      name: collectionExecutedName,
      ...(newStatus ? { status: newStatus } : {}),
    };
    dispatch(updateCollectionExecutedDetails(updatedCollectionExecuted));
  };

  const handlerShareLinkModal = () => {
    saveCollectionHandler();
    if (id) {
      dispatch(createCollectionExecutedPublicLink({ collection_executed_id: id, assignments: collectionAssignments }));
      setShowShareLinkModal(true);
    }
  };

  const statusObject = {
    statuses,
    selectedStatus: collectionExecutedStatus,
    onSelectStatus: (newStatus: string) => saveCollectionHandler(newStatus),
  };

  return (
    <>
      <EditorWrapper>
        <EditorHeader
          showPreviewButton={false}
          documentName={collectionExecutedName}
          onChangeDocumentName={onChangeName}
          errorText={fieldNameError}
          saveDocument={saveCollectionHandler}
          description={collectionExecutedDescription}
          setDescription={setCollectionExecutedDescription}
          showShareLinkModal={showShareLinkModal}
          handlerShareLinkModal={handlerShareLinkModal}
          isShareButtonAvailable={!!id}
          statusObject={statusObject}
          isCollectionPage
          isCollectionExecutedPage
        />
        <EditorContainer columns={EditorGridAxisCount.One}>
          <div className="collection-slider">
            <FormsSlider>
              {
                documentsList.map((document: any) => (
                  <SwiperSlide key={document.id}>
                    <EditorContainer columns={EditorGridAxisCount.One}>
                      {
                        document.template.pdf_file_url
                          ? (
                            <PdfEditor
                              readOnlyMode
                              isMultiTemplate
                              isCollectionPage
                              key={document.id}
                              pdfTemplateId={document.template.id}
                              isPublicPage={false}
                              visibleEditorDevTools={false}
                            />
                          )
                          : (
                            <Editor
                              readOnlyMode
                              isCollectionPage
                              key={document.id}
                              viewFormsName={document.name}
                              viewFormsTitle={document.template_title}
                              viewFormsDescription={document.description}
                              content={document.content_json}
                              onChange={() => null}
                              visibleEditorDevTools={false}
                            />
                          )
                      }
                    </EditorContainer>
                  </SwiperSlide>
                ))
              }
            </FormsSlider>
          </div>
        </EditorContainer>
      </EditorWrapper>
      <ShareLinkModal
        publicRoute={ROUTES.PUBLIC_COLLECTION_EXECUTED}
        result={collectionExecutedPublicLink.assignments}
        resourceGuid={collectionExecutedPublicLink.origin}
        showModal={!isLoading && showShareLinkModal}
        onCloseModal={setShowShareLinkModal}
        deleteLinkFromStore={() => dispatch(setCollectionExecutedPublicLink({ result: '', mode: '' }))}
      />
      <ModalContentWindow
        showModal={Boolean(showErrorModal)}
        onCloseModal={setShowErrorModal}
        titleText={SOMETHING_WENT_WRONG}
        showDefaultText={false}
      >
        <div>Please fill out all required fields.</div>
      </ModalContentWindow>
    </>
  );
};

export default CollectionExecutedDetails;