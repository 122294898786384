import { FORM_BUILDER } from 'constants/formBuilder';
import { RootStateType } from 'store/reducers';
import { IUserState } from 'store/reducers/userData';
import { FormBuilderType } from 'types/FormBuilder';
import { IFormBuilderSection } from 'types/Sections';

export const getStoreSourceNameByType = (formBuilderType: FormBuilderType) => {
  switch (formBuilderType) {
    case FORM_BUILDER.PDF:
      return 'templateDetails';
    case FORM_BUILDER.COLLECTION:
    default:
      return 'collectionDetails';
  }
};

export const getCommonDetails = (state: IUserState, formBuilderType: FormBuilderType) => {
  const sourceName = getStoreSourceNameByType(formBuilderType);
  const currentDetails = state[sourceName];
  return { sourceName, currentDetails };
};

export const updateFormBuilderStructure = (state: IUserState, formBuilderType: FormBuilderType, formBuilder: any) => {
  const { sourceName, currentDetails } = getCommonDetails(state, formBuilderType);
  return {
    ...state,
    [sourceName]: {
      ...currentDetails,
      form_builder_structure: formBuilder,
    },
  };
};

export const getFormBuilderByType = (state: RootStateType, formBuilderType: FormBuilderType): IFormBuilderSection[] => (
  state.user[getStoreSourceNameByType(formBuilderType)]?.form_builder_structure
);