import { DocumentInfoRowType } from 'components/Modals/DocumentInfoModalWindow/DocumentInfoModal';
import DocumentInfoRow from 'components/Modals/DocumentInfoModalWindow/DocumentInfoRow';

const DocumentInfoTab = ({
  documentDetailsInfo,
}: {
  documentDetailsInfo: DocumentInfoRowType[];
}) => (
  <div className="container">
    {
      documentDetailsInfo.map((item) => (
        !item.isHidden
          ? <DocumentInfoRow key={item.title} rowInfo={item} />
          : null
      ))
    }
  </div>
);

export default DocumentInfoTab;
