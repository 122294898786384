import { useHistory } from 'react-router-dom';

const useRedirectToPage = (path = ''): () => void => {
  const history = useHistory();

  return () => {
    history.push(path);
  };
};

export default useRedirectToPage;