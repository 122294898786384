import { FC } from 'react';

import HubIcon from '@mui/icons-material/Hub';

import Tooltip from 'components/Tooltip';

const ConnectionIcon: FC<{ title?: string }> = ({ title = 'Grouped field' }) => (
  <div className="field-connection-icon">
    <Tooltip title={title}>
      <HubIcon />
    </Tooltip>
  </div>
);

export default ConnectionIcon;