export const arrayInsert = <T>({
  array,
  insertionPosition,
  itemToInsert,
}: {
  array: T[],
  insertionPosition: number,
  itemToInsert: T,
}): T[] => {
  const firstPartOfArray: T[] = array.slice(0, insertionPosition);
  const secondPartOfArray: T[] = array.slice(insertionPosition);

  return [
    ...firstPartOfArray,
    itemToInsert,
    ...secondPartOfArray,
  ];
};

export default undefined;