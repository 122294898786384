import { ChangeEventHandler, FC } from 'react';

import { Input } from 'reactstrap';

interface IHeaderDocumentName {
  documentName?: string;
  onChangeDocumentName?: ChangeEventHandler<HTMLInputElement>;
  errorText?: string | null;
  readOnly?: boolean;
}

const HeaderDocumentName: FC<IHeaderDocumentName> = ({
  documentName = '',
  onChangeDocumentName = () => null,
  errorText = null,
  readOnly = false,
}) => (
  <>
    <Input
      value={documentName}
      className="editor-header-title"
      onChange={onChangeDocumentName}
      placeholder="Enter document name..."
      readOnly={readOnly}
    />
    {
      errorText && <div className="editor-header-error">{errorText}</div>
    }
  </>
);

export default HeaderDocumentName;