import { Element as SlateElement } from 'slate';

import { SelectFieldOptionType } from 'types/Editor';
import { FieldTypeOption, PDFFieldType } from 'types/PdfTemplates';

export const MondayComColumnTypes = {
  STATUS: 'status',
  TEXT: 'text',
} as const;

export const MondayComFilterKeys = {
  EMAIL_COLUMN_ID: 'emailColumnId',
  FILTER_COLUMN_ID: 'filterColumnId',
  PHONE_NUMBER_COLUMN_ID: 'phoneNumberColumnId',
} as const;
export type MondayComFilterKeysType = typeof MondayComFilterKeys[keyof typeof MondayComFilterKeys];

export type MondayComColumnType = {
  id: string;
  title: string;
  type: string;
  settings_str: string;
}

export type MondayComItemType = {
  id: string;
  name: string;
}

export type MondayComBoardType = {
  id: string;
  name: string;
  workspace_id: string;
  columns: MondayComColumnType[];
}

export type MondayComWorkspaceType = {
  id: string;
  name: string;
}

export type MondayComSubitemBoardType = {
  id: string;
  name: string;
  columns: MondayComColumnType[];
  items_page: {
    items: string[];
  }
}

export const MondayComItemDocumentFile = {
  FILE: 'file',
  STATUS: 'status',
} as const;
export type MondayComItemDocumentFileType = typeof MondayComItemDocumentFile[keyof typeof MondayComItemDocumentFile];

export type MondayComFieldsMappingType = Record<string, MondayComColumnType>;
export type MondayComDocumentFileType = Record<string, MondayComColumnType>;
export type MondayComSubitemMappingType = Record<string, string>;
export type MondayComFilterColumnsIdsType = {
  [MondayComItemDocumentFile.FILE]?: MondayComColumnType;
  [MondayComItemDocumentFile.STATUS]?: MondayComColumnType;
  [MondayComFilterKeys.EMAIL_COLUMN_ID]?: string;
  [MondayComFilterKeys.FILTER_COLUMN_ID]?: string;
  [MondayComFilterKeys.PHONE_NUMBER_COLUMN_ID]?: string;
}

export type MondayComIntegrationObjectType = {
  board: MondayComBoardType;
  filterColumnsIds?: MondayComFilterColumnsIdsType;
  fieldsMapping?: MondayComFieldsMappingType;
  subitemMapping?: MondayComSubitemMappingType;
  itemAttachmentSettings?: MondayComFieldsMappingType;
  itemDocumentFileSettings?: MondayComFilterColumnsIdsType;
}

export type FieldsMappingObjectType = {
  boardId: string;
  fieldKey: number;
  columnName: string;
}

export type SubitemsMappingObjectType = {
  boardId: string;
  columnId: string;
  subitemName: string | null;
}

export type FilterColumnsIdsObjectType = {
  boardId: string;
  fiterType: MondayComFilterKeysType;
  columnTitle: string | null;
  columnId?: string;
}

export type ColumnNameAndFieldType = {
  field: Partial<SlateElement> | PDFFieldType;
  columnName: string | null;
  newOptions: SelectFieldOptionType[] | FieldTypeOption[];
}

export type MondayComReducerType = {
  boards: MondayComBoardType[] | null;
  subitemsBoards: MondayComSubitemBoardType[] | null;
  workspaces: MondayComWorkspaceType[] | null;
  fieldTypeCheckerModalOpen: boolean;
  columnNameAndFieldToUpdateFieldsMapping: ColumnNameAndFieldType | null;
  launchUpdateFieldsMapping: boolean;
  waitToPasswordlessLinkResponse: boolean;
  shouldOpenPopupBecauseOfSmsValidation: boolean;
}