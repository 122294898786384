import { Descendant } from 'slate';

import { PDF_TYPE } from 'constants/general';
import { ICollectionExecutedDetails } from 'types/CollectionExecuted';
import { ICollectionDetails } from 'types/MultiTemplate';
import { IPublicPageDocumentStructure } from 'types/PublicPage';
import { ISection } from 'types/Sections';
import { IUserData } from 'types/userProfile';
import { filterFormFieldsFromContent, getSubtypesFromFields } from 'utils/editorFieldHelpers';
import { addSectionsDocument } from 'utils/editorHelpers';
import { getIsPDFDocument } from 'utils/PublicPage/documentTypeChecker';
import getFieldsFromData from 'utils/PublicPage/getFieldsFromData';
import { getSortedSections } from 'utils/sectionUtils';
import { concatSubtypeUniqueDataSources, getUniqueSubtypeFields } from 'utils/Subtypes/subtypeHelpers';

export const getNewCollectionData = (
  data: ICollectionDetails,
  isEmptyDocumentsObject: { [key: number]: boolean },
  documents: IPublicPageDocumentStructure[],
  ipAddress: string,
  currentDocument: IPublicPageDocumentStructure | null,
  personalSubtypeData: IUserData,
) => {
  const collectionData: any = [];
  const allContent: Descendant[] = [];
  data.templates?.forEach(({ template }) => {
    const sortedSections: ISection[] = getSortedSections(template.sections);
    const newSection = sortedSections.map((section) => ({
      ...section,
      templateId: template.id,
    }));
    allContent.push(...addSectionsDocument(newSection, false));
  });
  const allFormFields = filterFormFieldsFromContent(allContent);
  const usedFieldSubtypes = getUniqueSubtypeFields(getSubtypesFromFields(allFormFields));
  const userData = concatSubtypeUniqueDataSources(personalSubtypeData, usedFieldSubtypes);

  data.templates?.forEach(({ template }, index: number) => {
    const historyLog = currentDocument?.historyLog ?? documents[index]?.historyLog;
    if (!isEmptyDocumentsObject[template.id]) {
      const generalTemplateInfo = {
        user_data: userData,
        name: template.name,
        description: template.description,
        document_title: template.template_title,
        template_id: template.id,
        history_log: historyLog,
        ip_address: ipAddress,
        identifier: documents[index]?.identifier,
      };
      if (template.pdf_fields_json) {
        const newDocument = {
          ...generalTemplateInfo,
          content_json: template.pdf_fields_json,
          type: PDF_TYPE,
        };
        collectionData.push(newDocument);
      } else {
        const currentSections = allContent.filter((content: any) => content.templateId === template.id);
        const newDocument = {
          ...generalTemplateInfo,
          content_json: currentSections.map((section: any) => ({
            children: section.children,
            key: section.key,
            position: section.position,
            name: section.name,
            description: section.description,
            type: section.type,
          })),
          type: template.type,
        };
        collectionData.push(newDocument);
      }
    }
  });
  return collectionData;
};

export const getUpdatedCollectionData = (
  data: ICollectionExecutedDetails,
  documents: IPublicPageDocumentStructure[],
  ipAddress: string,
  currentDocument: IPublicPageDocumentStructure | null,
  personalSubtypeData: IUserData,
) => {
  const executedCollectionData: any = [];
  const allFieldsInCollection: Descendant[] = [];
  data.documents?.forEach(({ document }) => {
    const documentFields = getFieldsFromData({
      data: document,
      isPDF: getIsPDFDocument(document.type),
    });
    allFieldsInCollection.concat(documentFields as Descendant[]);
  });
  const allFormFields = filterFormFieldsFromContent(allFieldsInCollection);
  const usedFieldSubtypes = getUniqueSubtypeFields(getSubtypesFromFields(allFormFields));
  const userData = concatSubtypeUniqueDataSources(personalSubtypeData, usedFieldSubtypes);

  data.documents?.forEach(({ document }, index: number) => {
    const historyLog = currentDocument?.historyLog ?? documents[index]?.historyLog;
    const updatedExecutedDocument = {
      id: document.id,
      user_data: userData,
      history_log: historyLog,
      ip_address: ipAddress,
      identifier: documents[index]?.identifier,
      content_json: document.content_json,
    };
    executedCollectionData.push(updatedExecutedDocument);
  });
  return executedCollectionData;
};

export const getIsEmptyDocumentsObject = (documents: IPublicPageDocumentStructure[]) => {
  let helperIdsObject: { [key: number]: boolean } = {};
  documents.forEach((item) => {
    helperIdsObject = ({ ...helperIdsObject, [item.id]: item.emptyDocument });
  });
  return helperIdsObject;
};