import { FC, memo, useState } from 'react';

import CalendarMonth from '@mui/icons-material/CalendarMonth';
import { InputAdornment, TextFieldProps } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

import { DEFAULT_DATE_FORMAT } from 'constants/dateField';

interface IDateCalendarProps {
  fieldValue: string;
  dateMask: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputComponent: (props: TextFieldProps) => React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  onChangeDateValue: (value: string) => void;
  isCustomOpening?: boolean;
  isReadOnlyMode?: boolean;
  showAdornment?: boolean;
}

const DateCalendar: FC<IDateCalendarProps> = ({
  fieldValue,
  dateMask,
  inputComponent,
  onChangeDateValue,
  isCustomOpening = false,
  isReadOnlyMode = false,
  showAdornment = false,
  ...fieldProps
}) => {
  const [openedCalendar, setOpenedCalendar] = useState<boolean>(false);

  const onChangeHandler = (newValue: Date | null) => {
    if (!isReadOnlyMode) {
      const dateMoment = moment(newValue);
      const date = dateMoment.format(dateMask);
      if (!newValue || date === 'Invalid date') {
        onChangeDateValue('');
        return;
      }

      if (String(dateMoment.get('year')).length < 4) {
        return;
      }
      /**
       * Date is written in the field in default format.
       * 'MobileDatePicker' component changes only format, not value, according to date mask.
       */
      onChangeDateValue(dateMoment.format(DEFAULT_DATE_FORMAT));
    }
  };

  const AdornmentProp = showAdornment
    ? { endAdornment: <InputAdornment position="end"><CalendarMonth /></InputAdornment> }
    : {};

  const openProps = isCustomOpening
    ? {
      open: openedCalendar,
      onOpen: () => setOpenedCalendar(true),
      onClose: () => setOpenedCalendar(false),
    }
    : {};

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} keyboardDate>
      <MobileDatePicker
        inputFormat={dateMask}
        value={fieldValue}
        onChange={onChangeHandler}
        closeOnSelect
        readOnly={isReadOnlyMode}
        disabled={isReadOnlyMode}
        label=""
        {...fieldProps}
        {...openProps}
        renderInput={inputComponent}
        componentsProps={{
          actionBar: { actions: ['clear', 'today', 'accept'] },
        }}
        InputProps={AdornmentProp}
      />
    </LocalizationProvider>
  );
};

export default memo(DateCalendar);