import { Range } from 'slate';

import type { ListsEditor } from '../types';
import { getListItemsInRange } from './getListItemsInRange';
import { isListItemContainingText } from './isListItemContainingText';

/**
 * Returns true when editor has collapsed selection and the cursor is in an empty "list-item".
 */
export const isCursorInEmptyListItem = (editor: ListsEditor): boolean => {
  if (!editor.selection || Range.isExpanded(editor.selection)) {
    return false;
  }

  const listItemsInSelection = getListItemsInRange(editor, editor.selection);

  if (listItemsInSelection.length !== 1) {
    return false;
  }

  const [[listItemNode]] = listItemsInSelection;

  return !isListItemContainingText(editor, listItemNode);
};

export default isCursorInEmptyListItem;