import { FC } from 'react';

import { Input } from 'reactstrap';

import { PDFPropsType } from 'types/PdfTemplates';
import { getMainTypeAssignment } from 'utils/assignmentsHelpers';
import { createNameConstant } from 'utils/createNameConstant';

const SelectEditable: FC<PDFPropsType> = ({
  field,
  isReadOnlyMode,
  onChangeField,
  onChangeHandlerOtherOption,
  fieldStyle,
}) => {
  const optionValue = field.selectedOtherOption
    ? field.options?.find((option) => option.isSelectOtherOption)?.label
    : field.value;

  return (
    <>
      <Input
        autoComplete="off"
        type="select"
        data-value={optionValue || ''}
        name={createNameConstant(field.fieldName)}
        placeholder={field.fieldName}
        onChange={(e) => onChangeField(e, field)}
        style={fieldStyle}
        disabled={isReadOnlyMode}
        value={optionValue || ''}
        className={`pdf-field-${getMainTypeAssignment(field.assignment)}`}
        data-pdf-field
      >
        <option value="" />
        {
          field?.options?.map(({ id, label }) => (
            <option
              key={id}
              value={label}
              data-id={id}
            >
              {label}
            </option>
          ))
        }
      </Input>
      {
        field.selectedOtherOption && (
          <Input
            type="text"
            style={fieldStyle} // important for print view
            value={field.value || ''} // important for print view
            data-id={`InputFieldOtherOption-${field.key}`}
            onChange={(event) => onChangeHandlerOtherOption(event, field)}
            disabled={isReadOnlyMode}
            className={`radio-select-${field.assignment} py-0 mt-1 h-auto`}
            data-pdf-field
          />
        )
      }
    </>
  );
};

export default SelectEditable;