import React from 'react';

import { TEXT_ALIGN } from 'constants/editors';
import { IWrapperBlockEditableProps } from 'types/Editor';

const WrapperBlockEditable: React.FC<IWrapperBlockEditableProps> = (props) => {
  const {
    children,
    element,
  } = props;

  const fontFamily: string = element['font-type'] ? element['font-type'] : 'inherit';
  const textAlign = element.textAlign ? TEXT_ALIGN[element.textAlign] : 'inherit';

  const textIndent = element.textIndent || 0;

  const lastMargin = children.ref?.current?.style?.margin;
  const lastMarginNumber = lastMargin && Number.parseInt(lastMargin);
  let margin = 0;

  if (element.margin && lastMarginNumber) {
    margin = element.margin + lastMarginNumber;
  }

  if (element.margin) {
    margin = element.margin;
  }

  if (lastMargin) {
    margin = +lastMargin;
  }

  return (
    {
      ...children,
      props: {
        ...children.props,
        style: {
          fontFamily,
          textAlign,
          marginLeft: `${margin}px`,
          textIndent: `${textIndent}px`,
        },
      },
    }
  );
};

export default WrapperBlockEditable;