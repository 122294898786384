import {
  URL_PUBLIC_COLLECTION,
  URL_PUBLIC_DOCUMENT,
  URL_PUBLIC_SUCCESS_SUMMARY_PAGE,
  URL_PUBLIC_TEMPLATE,
} from 'constants/general';

const shouldShowBrandingInRoutes = (pathname: string): boolean => {
  const availableRoutes = [
    URL_PUBLIC_TEMPLATE,
    URL_PUBLIC_DOCUMENT,
    URL_PUBLIC_COLLECTION,
    URL_PUBLIC_SUCCESS_SUMMARY_PAGE,
  ];
  return availableRoutes.some((route) => pathname.includes(route));
};

export default shouldShowBrandingInRoutes;