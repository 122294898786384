/* eslint-disable */
import {
  CREATE_TEMPLATE_SECTION,
  UPDATE_TEMPLATE_SECTION_NAME,
  DELETE_TEMPLATE_SECTION,
  UPDATE_TEMPLATE_SECTIONS,
  UPDATE_TEMPLATE_SECTION_DESCRIPTION,
  SORT_FORM_DOCUMENT_SECTION,
  CLEAR_SECTIONS,
} from 'store/actions/actionTypes';
import { Action } from 'types/redux';
import { DEFAULT_SECTION } from 'constants/editors';
import { ITemplateStore } from 'types/redux';
import { isNil } from 'utils/isNil';
import { arrayInsert } from 'utils/arrayInsert';

const initialState: ITemplateStore = {
  sections: [DEFAULT_SECTION],
  sortMode: false,
}

const template = (state: ITemplateStore = initialState, { type, payload }: Action): ITemplateStore => {
  switch (type) {
    case CREATE_TEMPLATE_SECTION:
      if (!isNil(payload.indexOfSourceSection)) {
        return {
          ...state,
          sections: arrayInsert(
            {
              array: state.sections,
              insertionPosition: payload.indexOfSourceSection + 1,
              itemToInsert: payload.section
            }
          )
        }
      }
      return {
        ...state,
        sections: [...state.sections, payload.section],
      };
    case UPDATE_TEMPLATE_SECTION_NAME:
      return {
        ...state,
        sections: state.sections.map((el) => {
          if (el.key === payload.key) {
            return {
              ...el,
              name: payload.section.name,
            }
          }
          return el;
        })
      };
    case UPDATE_TEMPLATE_SECTION_DESCRIPTION:
      return {
        ...state,
        sections: state.sections.map((el) => {
          if (el.key === payload.key) {
            return {
              ...el,
              description: payload.section.description,
            }
          }
          return el;
        })
      };
    case UPDATE_TEMPLATE_SECTIONS:
      return {
        ...state,
        sections: payload,
      };
    case DELETE_TEMPLATE_SECTION:
      return {
        ...state,
        sections: state.sections.filter((el) => el.key !== payload),
      };
    case SORT_FORM_DOCUMENT_SECTION:
      return {
        ...state,
        sortMode: payload,
      };
    case CLEAR_SECTIONS:
      return {
        ...state,
        sections: [DEFAULT_SECTION],
      };
    default:
      return state;
  }
}

export default template;