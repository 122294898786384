/* eslint-disable */
import React from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import arrayMove from "../utils/arrayMove";
import { ISection } from "types/Sections";
import SortableListContainer from "./SortableListContainer";
import SortableItem from "./SortableItem";

export interface ISortIndex {
    oldIndex: number;
    newIndex: number;
}

export interface ISortableListProps {
  sections: ISection[];
  showModal: (modal: boolean) => void;
  removeSection: (id: number) => void;
  onUpdateOrder: (sections: ISection[]) => void;
}

const SortableList: React.FC<ISortableListProps> = (props) => {

  const {
    sections,
    showModal,
    removeSection,
    onUpdateOrder,
  } = props;

  const onSortEnd = ({ oldIndex, newIndex }: ISortIndex) => {
    const sortedNavList: ISection[] = arrayMove<ISection[]>(sections, oldIndex, newIndex);
    onUpdateOrder(sortedNavList);
  };

  const onClickShowModal: React.MouseEventHandler<HTMLLIElement> = () => {
    showModal(true);
  };

  return (
    <SortableListContainer
      lockAxis="y"
      helperClass="document-editor__navigation-item-active shadow-sm"
      onSortEnd={onSortEnd}
      distance={1}
    >
      {
        sections.length ? (
          <>
            {
              sections.map((nav, index) => (
                <SortableItem
                  removeItem={removeSection}
                  id={nav.id}
                  index={index}
                  key={`item-${nav.id}${index}`}
                  value={`${index + 1}. ${nav?.name ? nav.name : "Title" }`}
                />
              ))
            }
          </>
        ) : null
      }
      <li
        className="d-flex align-items-center px-3 py-2 list-group-item-action border-0 document-editor__navigation__add-section"
        onClick={onClickShowModal}
      >
        <div className="dropdown-divider w-100" />
        <AddCircleOutlineIcon className="material-icons mx-2" />
        <div className="dropdown-divider w-100" />
      </li>
    </SortableListContainer>
  );
};

export default SortableList;
