import { SUBTYPE_EMAIL, SUBTYPE_FIRST_NAME, SUBTYPE_LAST_NAME } from 'constants/editors';

export const TEXT_FIELD_SUBTYPES = [
  {
    id: 99999910,
    type: '',
    label: '',
    disabled: false,
  },
  {
    id: 99999920,
    type: SUBTYPE_FIRST_NAME,
    label: 'Name - First Name',
    disabled: false,
  },
  {
    id: 99999930,
    type: SUBTYPE_LAST_NAME,
    label: 'Name - Last Name',
    disabled: false,
  },
  {
    id: 99999940,
    type: SUBTYPE_EMAIL,
    label: 'Email - Personal',
    disabled: false,
  },
  {
    id: 99999950,
    type: 'separator',
    label: '------------',
    disabled: true,
  },
];

export const FIELD_SUBTYPE_KEYS: string[] = TEXT_FIELD_SUBTYPES.map((subtype) => subtype.type);