import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FormGroup } from 'reactstrap';

import Button from 'components/Base/Button';
import InputField from 'components/Base/InputField';
import SimpleSpinner from 'components/Base/SimpleSpinner';
import { EMAIL_REGEX, USER_REGISTRATION_TITLES } from 'constants/validation';
import { resetPasswordLink } from 'store/actions/auth';
import { RootStateType } from 'store/reducers';

interface IResetPassword {
  setShowModal: Dispatch<SetStateAction<boolean>>;
}

const ResetPasswordModal: FC<IResetPassword> = ({
  setShowModal,
}) => {
  const dispatch = useDispatch();
  const { error, isLoaded } = useSelector((state: RootStateType) => state.errorLoading);
  const [isButtonClicked, setIsButtonClicked] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [errorObject, setErrorObject] = useState({
    isError: false,
    message: '',
  });

  useEffect(() => {
    if (isLoaded) {
      setShowModal(false);
      if (!error) {
        toast.success(
          'If we have an account matching your email address, you will receive an email with a link to reset your password.',
        );
      }
    }
  }, [error, isLoaded]);

  const onSendRequestHandler = () => {
    setIsButtonClicked(true);
    dispatch(resetPasswordLink({ email }));
  };

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    const isValid = EMAIL_REGEX.test(value);
    setErrorObject({ isError: !isValid, message: isValid ? '' : 'Email is invalid' });
  };

  return (
    <>
      <div className="mb-3">
        Enter the email associated with your account and we&apos;ll
        send an email with instructions to reset your password.
      </div>
      <FormGroup>
        <InputField
          required
          label={USER_REGISTRATION_TITLES.email}
          value={email}
          onChange={handleChangeEmail}
          showError={errorObject.isError}
          helperText={errorObject.message}
        />
      </FormGroup>
      <div className="mt-4">
        <Button
          classes="w-100"
          onClick={onSendRequestHandler}
          disabled={errorObject.isError || !email.length}
        >
          {
            isButtonClicked
              ? (<SimpleSpinner />)
              : (<div>Send instructions</div>)
          }
        </Button>
      </div>
    </>
  );
};

export default ResetPasswordModal;