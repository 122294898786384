import { DragEvent } from 'react';

import { Dispatch } from 'redux';

import {
  CHECKBOX_FIELD,
  DASH_SPACE,
  DEFAULT_PDF_CHECKBOX_HEIGHT,
  DEFAULT_PDF_CHECKBOX_WIDTH,
  DEFAULT_PDF_FIELD_HEIGHT,
  DEFAULT_PDF_FIELD_ICON_HEIGHT,
  DEFAULT_PDF_FIELD_ICON_WIDTH,
  DEFAULT_PDF_FIELD_WIDTH,
  DEFAULT_PDF_FIELD_WIDTH_WITH_ICON,
  DEFAULT_PDF_SIGNATURE_FIELD_HEIGHT,
  FIELD_TYPE_VARIANT_PROPERTIES,
  RECIPIENT_ASSIGNMENT,
  SELECT_FIELD,
  SELECT_FIELD_TYPE_CHECKBOX,
  SELECT_FIELD_TYPE_RADIO,
  SIGNING_FIELD,
  TEXT_FIELD,
  WHITE_SPACE,
} from 'constants/editors';
import { FORM_TAB_ID } from 'constants/tabs';
import { setPdfFieldByIdsMultiTemplate } from 'store/actions/collections';
import { setAllPdfFields, updateFieldByKey } from 'store/actions/pdfTemplates';
import { setPersistentStorageFieldData } from 'store/actions/persistentReduxStorage';
import { BlockFormatType, FieldAssignmetsType, ICustomElement, SelectFieldOptionsType } from 'types/Editor';
import {
  FieldsCoords,
  FieldTypeOption,
  PDFFieldType,
  PDFFieldTypeObject,
  PDFFieldTypesType,
  PDFFieldTypesVariantType,
  PdfTemplateStateType,
} from 'types/PdfTemplates';
import { IPublicPageDocumentStructure } from 'types/PublicPage';
import { isManagerAssignment } from 'utils/assignmentsHelpers';
import { createNameConstant } from 'utils/createNameConstant';
import {
  addPropertiesByType,
  generateNumber,
  getFieldsGroupedByProperty,
  getSimilarSubtypeElementsByKey,
  getSubtypesFromFields,
} from 'utils/editorFieldHelpers';
import { isNotEmptyObject } from 'utils/isEmptyObject';

export const getPdfUrlAndWidth = (param: string) => (
  param.split('?')
);

export const isCheckboxFieldType = (fieldType: string | undefined): boolean => (
  fieldType ? fieldType === CHECKBOX_FIELD : false
);

export const getIsPublicSelectFieldType = (
  fieldType: PDFFieldTypesType,
  selectFieldType: SelectFieldOptionsType | undefined,
  isPublicPage: boolean,
): boolean => {
  const isSelectCheckboxOrRadioField: boolean = (
    fieldType === SELECT_FIELD
    && selectFieldType
    && ([SELECT_FIELD_TYPE_RADIO, SELECT_FIELD_TYPE_CHECKBOX].includes(selectFieldType))
  ) ?? false;
  return isSelectCheckboxOrRadioField && isPublicPage;
};

export const isRadioSelectFieldType = (
  fieldType: PDFFieldTypesType,
  selectFieldType: SelectFieldOptionsType | undefined,
) => (fieldType === SELECT_FIELD && selectFieldType === SELECT_FIELD_TYPE_RADIO);

export const getSortedPdfFields = (
  originPdfFields: PDFFieldTypeObject,
): PDFFieldType[] => (
  Object.values(originPdfFields).sort((a, b) => a.position - b.position)
);

export const sortFieldsByCoords = (a: PDFFieldType, b: PDFFieldType) => {
  const aPosition = a.coords;
  const bPosition = b.coords;
  if (aPosition.y === bPosition.y) {
    return aPosition.x - bPosition.x;
  }
  return aPosition.y - bPosition.y;
};

const sortFieldsIncludingPages = (pdfFields: PDFFieldType[]) => {
  let resultFieldsArray: PDFFieldType[] = [];
  const pagesArray: number[] = [];
  pdfFields.forEach((field: PDFFieldType) => {
    if (!pagesArray.includes(field.pageNumber ?? 0)) {
      pagesArray.push(field.pageNumber ?? 0);
    }
  });
  pagesArray.sort((a: number, b: number) => a - b);
  pagesArray.forEach((pageNumber) => {
    const fieldsOnPage = pdfFields.filter((field: PDFFieldType) => field.pageNumber === pageNumber);
    resultFieldsArray = resultFieldsArray.concat(fieldsOnPage.sort(sortFieldsByCoords));
  });

  return resultFieldsArray;
};

export const filterSortedFormFieldsFromJSONObject = (
  objectData: PDFFieldTypeObject | undefined,
  isPublicPage: boolean = false,
  currentDocAssignment: FieldAssignmetsType | string = RECIPIENT_ASSIGNMENT,
  isDetailsPage: boolean = false,
): PDFFieldType[] => {
  if (objectData) {
    if (!isPublicPage) {
      return getSortedPdfFields(objectData);
    }
    if (isDetailsPage) {
      return Object.values(objectData).filter((field: PDFFieldType) => isManagerAssignment(field.assignment));
    }
    const filteredFieldsByAssignment = Object.values(objectData).filter(
      (field: PDFFieldType) => field.assignment === currentDocAssignment,
    );
    return sortFieldsIncludingPages(filteredFieldsByAssignment);
  }
  return [];
};

export const filterSimilarPdfSubtypeFields = (
  allFields: PDFFieldType[],
  field: PDFFieldType,
) => {
  const usedFieldSubtypes = getSubtypesFromFields(allFields, field?.assignment, field.subtype);
  return getSimilarSubtypeElementsByKey(
    field.key,
    usedFieldSubtypes,
    allFields as Partial<ICustomElement>[],
  ) as PDFFieldType[];
};

const generateDefaultFieldName = (type: string): string => (
  type
    .split(DASH_SPACE)
    .map((el: string) => el[0].toLocaleUpperCase() + el.slice(1).toLowerCase())
    .join(WHITE_SPACE)
);

export const fieldTypeVariantPropertiesGenerator = (variant?: PDFFieldTypesVariantType): Partial<PDFFieldType> => {
  if (!variant) return {};
  const fieldName = generateDefaultFieldName(variant);

  const properties = {
    fieldName,
    name: createNameConstant(fieldName),
  };

  return {
    ...properties,
    ...FIELD_TYPE_VARIANT_PROPERTIES[variant],
  };
};

export const getPdfFieldSize = (fieldType: PDFFieldTypesType, size?: { width: number; height: number; }) => {
  if (size) {
    return {
      width: size.width,
      height: size.height,
    };
  }
  switch (fieldType) {
    case CHECKBOX_FIELD:
      return {
        width: DEFAULT_PDF_CHECKBOX_WIDTH,
        height: DEFAULT_PDF_CHECKBOX_HEIGHT,
      };
    case SIGNING_FIELD:
      return {
        width: DEFAULT_PDF_FIELD_WIDTH,
        height: DEFAULT_PDF_SIGNATURE_FIELD_HEIGHT,
      };
    default:
      return {
        width: DEFAULT_PDF_FIELD_WIDTH,
        height: DEFAULT_PDF_FIELD_HEIGHT,
      };
  }
};

export const createdNewField = ({
  type,
  position,
  coords,
  pageNumber,
  extendedField,
  typeVariant,
  assignment = RECIPIENT_ASSIGNMENT,
}: {
  type: PDFFieldTypesType,
  position: number,
  coords: FieldsCoords,
  pageNumber: number,
  typeVariant?: PDFFieldTypesVariantType,
  extendedField?: Partial<PDFFieldType>,
  assignment?: FieldAssignmetsType,
}): PDFFieldType => {
  const { key } = extendedField as PDFFieldType;
  const isCheckboxField = isCheckboxFieldType(type);
  const defaultName = generateDefaultFieldName(type);
  const typeVariantProperties = fieldTypeVariantPropertiesGenerator(typeVariant);
  const field: PDFFieldType = {
    fieldName: defaultName,
    name: createNameConstant(defaultName),
    requiredField: false,
    assignment,
    ...extendedField,
    coords,
    key,
    position,
    type,
    value: '',
    pageNumber: pageNumber || 0,
    size: getPdfFieldSize(type),
    ...(isCheckboxField ? { checked: false } : {}),
  };
  return {
    ...addPropertiesByType(field, type),
    ...typeVariantProperties,
  };
};

export const getFieldCoords = (
  event: DragEvent<HTMLDivElement>,
  parentDataAttr: string,
): FieldsCoords => {
  const { currentTarget, pageX, pageY } = event;

  if (!currentTarget) return { x: 0, y: 0 };

  const wrapper = currentTarget.closest(parentDataAttr);
  if (wrapper) {
    const wrapperScrollTop = wrapper.scrollTop;
    const wrapperScrollLeft = wrapper.scrollLeft;
    const targetClientRect = currentTarget.getBoundingClientRect();
    const { offsetTop, offsetLeft } = currentTarget;
    const targetRightBorder = targetClientRect.x + targetClientRect.width - pageX;

    const targetLeftBorder = pageX - targetClientRect.x;
    const targetBottomBorder = targetClientRect.height - (wrapperScrollTop + (pageY - offsetTop));

    let deltaX = 0;
    let deltaY = 0;

    if (targetRightBorder < DEFAULT_PDF_FIELD_WIDTH_WITH_ICON) {
      deltaX = DEFAULT_PDF_FIELD_WIDTH_WITH_ICON - targetRightBorder;
    } else if (targetLeftBorder < DEFAULT_PDF_FIELD_ICON_WIDTH) {
      deltaX = targetLeftBorder - (DEFAULT_PDF_FIELD_ICON_WIDTH / 2);
    }
    if (targetBottomBorder < (DEFAULT_PDF_FIELD_ICON_HEIGHT / 2)) {
      deltaY = (DEFAULT_PDF_FIELD_ICON_HEIGHT / 2) - targetBottomBorder;
    }

    const currentX = wrapperScrollLeft + pageX - offsetLeft + (DEFAULT_PDF_FIELD_ICON_WIDTH / 2) - deltaX;
    const currentY = wrapperScrollTop + pageY - offsetTop - (DEFAULT_PDF_FIELD_ICON_HEIGHT / 2) - deltaY;

    return {
      x: currentX,
      y: currentY < 0 ? 0 : currentY,
    };
  }

  return { x: 0, y: 0 };
};

export const getIsEmptyPdfFields = (originPdfFields: PDFFieldTypeObject) => {
  if (originPdfFields) {
    return !Object.keys(originPdfFields).length;
  }
  return true;
};

export const updatePdfField = (field: PDFFieldType, pdfTemplateId: number = 0) => {
  if (pdfTemplateId !== 0) {
    return setPdfFieldByIdsMultiTemplate({
      templateId: pdfTemplateId,
      field,
      fieldKey: field.key,
    });
  }
  return updateFieldByKey(field.key, field);
};

export const changeMultiformSubtypeValues = (
  allTemplatesData: Partial<PdfTemplateStateType>,
  currentField: PDFFieldType,
  newValue: string,
  dispatch: Dispatch,
) => {
  const allTemplatesFields = Object.assign(
    {},
    ...Object.values(allTemplatesData).map((templateData: any) => (
      { [templateData.id]: Object.values(templateData.pdfTemplateFields) }
    )),
  );
  Object.entries(allTemplatesFields).map(([templateKey, templateFields]) => {
    const subtypeFormElements = filterSimilarPdfSubtypeFields(templateFields as PDFFieldType[], currentField);
    subtypeFormElements.length > 0 && subtypeFormElements.forEach((field: PDFFieldType) => {
      if (field.key !== currentField.key && templateKey && field.value !== newValue) {
        dispatch(updatePdfField({ ...field, value: newValue }, Number(templateKey)));
      }
    });
    return true;
  });
};

export const updatePDFGroupedByParamFieldValues = ({
  allFields,
  element,
  newValue,
  dispatch,
  storageDocumentKey,
  filterGroupBy = undefined,
  pdfTemplateId = 0,
  selectFieldProps = {},
}: {
  allFields: Partial<PDFFieldType>[] | null,
  element: { assignment: FieldAssignmetsType | undefined, name: string, key: number, type: BlockFormatType },
  newValue: string,
  dispatch: Dispatch,
  storageDocumentKey: string,
  filterGroupBy?: string,
  pdfTemplateId?: number,
  isPublicPage?: boolean,
  selectFieldProps?: Partial<PDFFieldType> | Partial<ICustomElement>,
}) => {
  if (allFields && allFields.length) {
    const usedGroupedFields = getFieldsGroupedByProperty({
      fields: allFields,
      filterName: element.name,
      filterType: element.type,
      assignmentType: element.assignment,
      findSimilar: false,
      filterGroupBy,
    });
    usedGroupedFields.forEach((field) => {
      if (element.key !== field.key) {
        const fieldData = {
          ...field,
          ...(selectFieldProps && isNotEmptyObject(selectFieldProps)
            ? { ...selectFieldProps }
            : { value: newValue }),
        } as PDFFieldType;
        dispatch(updatePdfField(fieldData, pdfTemplateId));
        if (field.key) {
          dispatch(setPersistentStorageFieldData(storageDocumentKey, field.key, {
            ...(selectFieldProps && isNotEmptyObject(selectFieldProps)
              ? { ...selectFieldProps }
              : { value: newValue }
            ),
          }));
        }
      }
    });
  }
};

export const getNewFieldsCoords = (currentField: PDFFieldType): FieldsCoords => {
  const currentCoords = currentField.coords;
  const currentSize = getPdfFieldSize(currentField.type, currentField.size);
  if ((currentCoords.y + currentSize.height) > 1000) {
    return { x: currentCoords.x, y: currentCoords.y - currentSize.height };
  }
  return { x: currentCoords.x, y: currentCoords.y + currentSize.height };
};

export const focusNewField = (originalField: PDFFieldType) => {
  const parrentElement = document.querySelector(`[data-element-wrapper='${originalField.key}']`);
  const elementForFocus: HTMLElement | null | undefined = parrentElement?.querySelector('[data-pdf-field]');
  if (elementForFocus) {
    elementForFocus.focus();
  }
};

export const getEnableResize = (isResizable: boolean) => ({
  top: isResizable,
  right: isResizable,
  bottom: isResizable,
  left: isResizable,
  topRight: isResizable,
  bottomRight: isResizable,
  bottomLeft: isResizable,
  topLeft: isResizable,
});

export const createFieldsFromPdfFormfields = (
  pdfFormFiedls: PDFFieldType[],
  dispatch: Dispatch,
  assignment: string | null = RECIPIENT_ASSIGNMENT,
) => {
  const fields: Record<number, PDFFieldType> = {};
  pdfFormFiedls.forEach((formField: Partial<PDFFieldType>) => {
    const fieldKey = generateNumber();
    const fieldType = formField.type ?? TEXT_FIELD;
    const newField = addPropertiesByType(
      {
        ...formField,
        key: fieldKey,
        requiredField: false,
        assignment,
      },
      fieldType,
    );
    if (formField.fieldName) {
      newField.name = createNameConstant(formField.fieldName);
    } else {
      const defaultName = generateDefaultFieldName(fieldType);
      newField.fieldName = defaultName;
      newField.name = createNameConstant(defaultName);
    }
    if (formField.options) {
      newField.options = formField.options.map((option: FieldTypeOption, index: number) => ({
        id: generateNumber(),
        label: option.label,
        coords: { x: 0, y: index * 30 },
      }));
    }
    fields[fieldKey] = newField;
  });
  dispatch(setAllPdfFields(fields));
};

export const getWrapperClassesForPDFEditor = ({
  isDocumentWithFormBuilderView,
  isPublicPage,
  isCollectionPage,
  isMobile,
  isMultiTemplate,
  isReadOnlyMode,
  activeTab,
  currentDocument,
}: {
  isDocumentWithFormBuilderView: boolean;
  isPublicPage: boolean;
  isCollectionPage: boolean;
  isMobile: boolean;
  isMultiTemplate: boolean;
  isReadOnlyMode: boolean;
  activeTab: number;
  currentDocument: IPublicPageDocumentStructure | null;

}) => {
  const wrapperClasses = [];

  const editorAreaColumns = (isPublicPage || isMultiTemplate || isReadOnlyMode) && activeTab !== FORM_TAB_ID
    ? 'col-8'
    : `${activeTab !== FORM_TAB_ID ? 'col-11 col-xl-10' : 'col-6'}`;

  if (isDocumentWithFormBuilderView && currentDocument?.slides?.length) {
    wrapperClasses.push('col-6 d-flex flex-column py-4');
  } else {
    wrapperClasses.push('mx-auto py-4');
    if ((!isPublicPage && isCollectionPage) || (isPublicPage && !isCollectionPage) || isMobile) {
      wrapperClasses.push('col-12');
    } else {
      wrapperClasses.push(`${editorAreaColumns} py-0`);
    }
  }

  return wrapperClasses;
};