import { FC, ReactNode } from 'react';

import { TEXT_SIZE } from 'constants/editors';
import { ICustomElement, ICustomElementWithTextSize, IRefEditable } from 'types/Editor';

import vars from 'variables.module.scss';

interface IListItemWrapperEditableProps {
  children: JSX.Element & object & IRefEditable & ReactNode;
  element: ICustomElement;
}

const ListItemWrapperEditable: FC<IListItemWrapperEditableProps> = ({
  children,
  element,
}) => {
  const fontSize: string | undefined = (element as ICustomElementWithTextSize)[TEXT_SIZE];

  return (
    {
      ...children,
      props: {
        ...children.props,
        style: {
          '--marker-font-size': fontSize ? `${fontSize}px` : `${vars.defaultFontSize}`,
        },
      },
    }
  );
};

export default ListItemWrapperEditable;