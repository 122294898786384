import { ChangeEvent, ChangeEventHandler, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { SENDGRID_SETTINGS_FIELDS_NAMES, SENDGRID_SETTINGS_TITLES } from 'constants/validation';
import { useRolePermission } from 'hooks/useRolePermission';
import { updateSendgridSettings } from 'store/actions/emailNotifications';
import { RootStateType } from 'store/reducers';
import { ISettingsField, SendgridSettingsType } from 'types/AccountSettings';
import { IErrorField } from 'types/validation';
import { createArrayForValidation, validationForm } from 'utils/validation';

interface IUseSendgridSettingsReturns {
  permissionUsingWlSendgrid: boolean;
  error: string;
  sendgridSettingsData: ISettingsField[];
  handlerSendgridSettingsSubmit: () => boolean;
  resetSendgridSettingsChanges: () => void;
  handleChangeSendgridSettings: (event: ChangeEvent<HTMLInputElement>) => void;
}

const useSendgridSettings = (): IUseSendgridSettingsReturns => {
  const dispatch = useDispatch();
  const { permissionUsingWlSendgrid } = useRolePermission();
  const {
    sendgrid_api_key: sendgridApiKey,
    sendgrid_email_from: sendgridEmail,
  } = useSelector((state: RootStateType) => state.profile.profileInfo.company);
  const initialSendgridSettingsErrors = {
    sendgridApiKey: null,
    sendgridEmail: null,
  };

  const [formErrors, setFormErrors] = useState<IErrorField>(initialSendgridSettingsErrors);
  const [sendgridSettings, setSendgridSettings] = useState<SendgridSettingsType>({ sendgridApiKey, sendgridEmail });
  const [error, setError] = useState<string>('');

  const sendgridSettingsData: ISettingsField[] = [
    {
      name: SENDGRID_SETTINGS_FIELDS_NAMES.sendgridApiKey,
      label: SENDGRID_SETTINGS_TITLES.sendgridApiKey,
      value: sendgridSettings.sendgridApiKey,
      fieldError: formErrors.sendgridApiKey,
    },
    {
      name: SENDGRID_SETTINGS_FIELDS_NAMES.sendgridEmail,
      label: SENDGRID_SETTINGS_TITLES.sendgridEmail,
      value: sendgridSettings.sendgridEmail,
      fieldError: formErrors.sendgridEmail,
    },
  ];

  const resetSendgridSettingsChanges = (): void => {
    setSendgridSettings({ sendgridApiKey, sendgridEmail });
    setError('');
    setFormErrors(initialSendgridSettingsErrors);
  };

  const validateForm = (sendgridSettingsValues: SendgridSettingsType) => {
    const arrayForValidation = createArrayForValidation(sendgridSettingsValues, SENDGRID_SETTINGS_TITLES, false);
    const validateResult = validationForm(arrayForValidation);
    setFormErrors(validateResult.validationFields);
    return validateResult;
  };

  const handleChangeSendgridSettings: ChangeEventHandler<HTMLInputElement> = (event) => {
    const updatedSettings = { ...sendgridSettings, [event.target.name]: event.target?.value };
    setSendgridSettings(updatedSettings);
    validateForm(updatedSettings);
  };

  const handlerSendgridSettingsSubmit = (): boolean => {
    const formValidate = validateForm(sendgridSettings);
    if (formValidate.isError) return false;
    const apiKey = sendgridSettings.sendgridApiKey ? sendgridSettings.sendgridApiKey.trim() : '';
    const email = sendgridSettings.sendgridEmail ? sendgridSettings.sendgridEmail.trim() : '';
    if ((apiKey && !email) || (!apiKey && email)) {
      setError('You need to provide API key and email or clear both of them');
      return false;
    }
    const editedSendgridSettings: SendgridSettingsType = {
      sendgridApiKey: apiKey,
      sendgridEmail: email,
    };
    setError('');
    dispatch(updateSendgridSettings(editedSendgridSettings));
    return true;
  };

  return {
    permissionUsingWlSendgrid,
    error,
    sendgridSettingsData,
    handlerSendgridSettingsSubmit,
    resetSendgridSettingsChanges,
    handleChangeSendgridSettings,
  };
};

export default useSendgridSettings;