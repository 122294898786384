import { Descendant } from 'slate';

import { RECIPIENT_ASSIGNMENT } from 'constants/editors';
import { ITemplateSection } from 'types/redux';
import { IDocumentSection, ISection } from 'types/Sections';
import { parseFields } from 'utils/templateSagasHelpers';

export const getSortedSections = (sections: IDocumentSection[]): ISection[] => sections
  .sort((a:IDocumentSection, b: IDocumentSection) => a.position - b.position)
  .map((section: IDocumentSection) => ({ ...section.section }));

const hasCurrentAssignmentTypeFields = (
  section: Descendant,
  assignmentType: string,
) => {
  const assignmentFields: Descendant[] = [];

  parseFields(section, (node: any) => {
    if (node.assignment === assignmentType) {
      assignmentFields.push(section);
    }
  });

  return Boolean(assignmentFields.length);
};

const getFilteredSections = (content: Descendant[], assignmentType: string) => (
  content.filter((section: Descendant) => {
    const hasAssignmentFields: boolean = hasCurrentAssignmentTypeFields(section, assignmentType);
    return hasAssignmentFields && section;
  })
);

export const getTemplateSectionsInfo = (
  sections: ISection[],
  assignmentType: string = RECIPIENT_ASSIGNMENT,
): ITemplateSection[] => sections.filter((section: ISection) => {
  const assignmentFields: Descendant[] = getFilteredSections(section.content_json || [], assignmentType);
  return assignmentFields.length && section;
}).map((section: ISection, index: number) => ({
  name: section.name,
  description: section.description,
  key: section.id,
  position: index,
}));

export const getDocumentSectionsInfo = (
  content: Descendant[],
  assignmentType: string = RECIPIENT_ASSIGNMENT,
) => getFilteredSections(content, assignmentType)
  .map((section: any) => ({
    name: section.name,
    description: section.description,
    key: section.key,
    position: section.position,
  }));