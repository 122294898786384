import { SET_SENDGRID_SETTINGS, UPDATE_SENDGRID_SETTINGS } from 'store/actions/actionTypes';
import { SendgridSettingsType } from 'types/AccountSettings';

export const updateSendgridSettings = (
  sendgridSettings: SendgridSettingsType,
) => ({
  type: UPDATE_SENDGRID_SETTINGS,
  payload: sendgridSettings,
});

export const setSendgridSettings = (payload: SendgridSettingsType) => ({
  type: SET_SENDGRID_SETTINGS,
  payload,
});

export default null;