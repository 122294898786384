/* eslint-disable */
import { Editor as SlateEditor, Range as SlateRange, Element as SlateElement, Transforms } from 'slate';
import { BlockFormatType, isBlockFormatType } from 'types/Editor';
import { isBlockActive } from './editorBlockHelpers';
import { createField, findAndChangeDuplicatedFieldKeys, isNodeTextNotEmpty } from './editorFieldHelpers';

export const toggleSigningField = (editor: SlateEditor, format: BlockFormatType): number | null => {
  const { selection, forms } = editor;

  Transforms.unwrapNodes(editor, {
    match: (node) => (
      !SlateEditor.isEditor(node) &&
      SlateElement.isElement(node) &&
      isBlockFormatType(node.type) &&
      node.type !== format
    )
  });

  const isActive = isBlockActive(editor, format);

  if (isActive) {
    Transforms.unwrapNodes(editor, {
      match: (node) => (
        !SlateEditor.isEditor(node) &&
        SlateElement.isElement(node) &&
        node.type === format
      )
    })
    return null;
  }

  const isCollapsed = selection && SlateRange.isCollapsed(selection);
  const signingField: SlateElement = createField(format, isCollapsed, forms?.length || 0);
  const fieldKey = signingField?.key || null;

  if (isCollapsed) {
    Transforms.insertNodes(editor, signingField, { match: (node) => isNodeTextNotEmpty(node) });
    findAndChangeDuplicatedFieldKeys(fieldKey || 0, editor);
  } else {
    Transforms.wrapNodes(editor, signingField, { split: true });
    findAndChangeDuplicatedFieldKeys(fieldKey || 0, editor);
    Transforms.collapse(editor, { edge: 'end' });
  }

  return fieldKey;
};