import { FORM_ACTIVE_SECTION_CLASS } from 'constants/formEditor';

export const setDraggableSectionStyles = () => {
  document.querySelector(`.${FORM_ACTIVE_SECTION_CLASS}`)?.setAttribute('style', 'opacity: 0.01');
};

export const removeDraggableSectionStyles = () => {
  document.querySelector(`.${FORM_ACTIVE_SECTION_CLASS}`)?.removeAttribute('style');
};

export const setDraggableParagraphStyles = (node: Element) => {
  node?.setAttribute('style', 'opacity: 0.01');
};

export const removeDraggableParagraphStyles = () => {
  document.querySelector('div[data-block-key][style]')?.removeAttribute('style');
};