import { useSelector } from 'react-redux';

import OutlinePublicPanel from 'components/PublicPage/OutlinePublicPanel';
import { RootStateType } from 'store/reducers';
import { IPublicPageState } from 'types/PublicPage';

import classes from 'scss/components/PublicEditor/PublicEditor.module.scss';

const SectionsPanel = () => {
  const { currentDocument }: IPublicPageState = useSelector((state: RootStateType) => state.publicPages);

  return (
    <div className="m-0 position-sticky top-0" style={{ height: 'auto' }}>
      <OutlinePublicPanel
        title="Outline"
        reverse={false}
      >
        <ul>
          {currentDocument?.slides.map((slide, index) => (
            <li
              key={`SlideInSectionPanel_${index + 1}`}
              // We will use onClick when we created navigation beetwen sections
              onClick={() => null}
              className={`${classes.OutlinePanelText} ${classes.OutlineElement}`}
            >
              {slide.name}
            </li>
          ))}
        </ul>
      </OutlinePublicPanel>
    </div>
  );
};

export default SectionsPanel;