const CollectionIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M37 12H27V14H37V12Z" fill="#2F3947" />
    <path d="M37 16H27V18H37V16Z" fill="#2F3947" />
    <path
      d="M57 54V46C57 45.556 56.707 45.165 56.28 45.04L33 38.25V26H40C40.553 26 41 25.552 41 25V9C41 8.735
      40.895 8.48 40.707 8.293L34.707 2.293C34.52 2.105 34.266 2 34 2H24C23.447 2 23 2.448 23 3V25C23
      25.552 23.447 26 24 26H31V38.25L7.72 45.04C7.293 45.165 7 45.556 7 46V54C7 54.444 7.293 54.835
      7.72 54.96L31.72 61.96C31.812 61.987 31.905 62 32 62C32.095 62 32.188 61.987 32.28 61.96L56.28
      54.96C56.707 54.835 57 54.444 57 54ZM35 5.414L37.586 8H35V5.414ZM25 4H33V9C33 9.552 33.447 10
      34 10H39V24H25V4ZM31 40.333V45.586L28.707 43.293L27.293 44.707L31.293 48.707C31.488 48.902 31.744 49
      32 49C32.256 49 32.512 48.902 32.707 48.707L36.707 44.707L35.293 43.293L33 45.586V40.333L52.429
      46L32 51.958L11.571 46L31 40.333ZM9 47.333L31 53.75V59.667L9 53.25V47.333ZM33 59.667V53.75L55
      47.333V53.25L33 59.667Z"
      fill="#2F3947"
    />
    <path d="M31 8H27V10H31V8Z" fill="#2F3947" />
    <path
      d="M3 26H10C10 28.757 12.243 31 15 31H18C19.654 31 21 32.346 21 34V35.586L18.707 33.293L17.293
      34.707L21.293 38.707C21.488 38.902 21.744 39 22 39C22.256 39 22.512 38.902 22.707 38.707L26.707
      34.707L25.293 33.293L23 35.586V34C23 31.243 20.757 29 18 29H15C13.346 29 12 27.654 12 26H19C19.553 26
      20 25.552 20 25V9C20 8.735 19.895 8.48 19.707 8.293L13.707 2.293C13.52 2.105 13.266 2 13 2H3C2.447
      2 2 2.448 2 3V25C2 25.552 2.447 26 3 26ZM14 5.414L16.586 8H14V5.414ZM4 4H12V9C12 9.552 12.447
      10 13 10H18V24H4V4Z"
      fill="#2F3947"
    />
    <path d="M16 12H6V14H16V12Z" fill="#2F3947" />
    <path d="M16 16H6V18H16V16Z" fill="#2F3947" />
    <path d="M16 20H6V22H16V20Z" fill="#2F3947" />
    <path d="M10 8H6V10H10V8Z" fill="#2F3947" />
    <path
      d="M62 25V9C62 8.735 61.895 8.48 61.707 8.293L55.707 2.293C55.52 2.105 55.266 2 55 2H45C44.447
      2 44 2.448 44 3V25C44 25.552 44.447 26 45 26H52C52 27.654 50.654 29 49 29H46C43.243 29 41 31.243 41
      34V35.586L38.707 33.293L37.293 34.707L41.293 38.707C41.488 38.902 41.744 39 42 39C42.256 39 42.512
      38.902 42.707 38.707L46.707 34.707L45.293 33.293L43 35.586V34C43 32.346 44.346 31 46 31H49C51.757
      31 54 28.757 54 26H61C61.553 26 62 25.552 62 25ZM56 5.414L58.586 8H56V5.414ZM46 24V4H54V9C54 9.552
      54.447 10 55 10H60V24H46Z"
      fill="#2F3947"
    />
    <path d="M58 12H48V14H58V12Z" fill="#2F3947" />
    <path d="M58 16H48V18H58V16Z" fill="#2F3947" />
    <path d="M58 20H48V22H58V20Z" fill="#2F3947" />
    <path d="M52 8H48V10H52V8Z" fill="#2F3947" />
    <path d="M29 20H27V22H29V20Z" fill="#2F3947" />
    <path d="M33 20H31V22H33V20Z" fill="#2F3947" />
    <path d="M37 20H35V22H37V20Z" fill="#2F3947" />
  </svg>
);

export default CollectionIcon;