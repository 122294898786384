import { FC, useCallback, useState } from 'react';

import LensIcon from '@mui/icons-material/Lens';

import ModalConfirmDialog from 'components/Modals/ModalConfirmDialog';
import { STATUSES } from 'constants/documentStatuses';
import { useRolePermission } from 'hooks/useRolePermission';

interface IChangeStatusButtonProps {
  statusObject: {
    statuses: string[];
    selectedStatus: string;
    onSelectStatus: (status: string) => void;
  } | undefined;
  disabledButton: boolean;
}

const ChangeStatusButton: FC<IChangeStatusButtonProps> = ({ statusObject, disabledButton }) => {
  const { enableDocumentsAutoLock } = useRolePermission();
  const [changeStatusObject, setChangeStatusObject] = useState<{ showModal: boolean, newStatus: string }>({
    showModal: false,
    newStatus: '',
  });

  const onChangeStatus = useCallback((value: string) => {
    if (disabledButton) return;
    if (statusObject && statusObject.selectedStatus && value !== statusObject.selectedStatus) {
      statusObject.onSelectStatus(value);
    }
  }, [statusObject, disabledButton]);

  const onChangeStatusHandler = (value: string) => {
    if (statusObject?.selectedStatus === STATUSES.completed && value !== STATUSES.completed) {
      setChangeStatusObject({
        showModal: true,
        newStatus: value,
      });
    } else {
      onChangeStatus(value);
    }
  };

  const getClassesForStatus = (statusValue: string): string => (
    `material-icons me-1 mb-1 status-${statusValue.toLowerCase().replaceAll(' ', '-')}`
  );

  const closeChangeStatusModal = () => {
    setChangeStatusObject((prevState) => ({ ...prevState, showModal: false }));
  };

  const shouldBlockButton = statusObject?.selectedStatus === STATUSES.completed && enableDocumentsAutoLock;

  return statusObject && statusObject.statuses
    ? (
      <>
        <div className="dropdown px-sm-2">
          <button
            className={`btn dropdown-toggle w-100 editor-header-dropdown ${shouldBlockButton ? 'border-white' : ''}`}
            type="button"
            id="selectStatusButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            disabled={disabledButton || shouldBlockButton}
          >
            <span className="d-none d-sm-inline">
              <span className="status-icon">
                <LensIcon className={getClassesForStatus(statusObject.selectedStatus)} />
                {statusObject.selectedStatus}
              </span>
            </span>
          </button>
          <ul
            className={`dropdown-menu editor-header-dropdown-menu ${shouldBlockButton ? 'd-none' : ''}`}
            aria-labelledby="selectStatusButton"
          >
            {
              Object.values(statusObject.statuses).map((value) => (
                <li key={`statusListItem_${value}`}>
                  <button
                    type="button"
                    className="dropdown-item editor-header-dropdown-item"
                    value={value}
                    onClick={() => onChangeStatusHandler(value)}
                    disabled={statusObject.selectedStatus === value}
                  >
                    <span className="status-icon">
                      <LensIcon className={getClassesForStatus(value)} />
                      {value}
                    </span>
                  </button>
                </li>
              ))
            }
          </ul>
        </div>
        <ModalConfirmDialog
          messageText="You are changing the status of a completed and signed document. Are you sure?"
          showDialog={changeStatusObject.showModal}
          onClose={closeChangeStatusModal}
          onConfirm={() => {
            closeChangeStatusModal();
            onChangeStatus(changeStatusObject.newStatus);
          }}
        />
      </>
    )
    : null;
};

export default ChangeStatusButton;