export const capitalizeString = (
  string: string,
  splitSeparator: string = ' ',
  joinSeparator: string = ' ',
): string => (
  string
    .replaceAll(splitSeparator, joinSeparator)
    .toLowerCase()
    .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
);

export const titleCaseFromCamelCase = (title: string): string => {
  const result = title.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const getLastSplittedPartByFragment = (fileUrl: string, splitPart: string = '/'): string => (
  fileUrl.split(splitPart).pop() || ''
);