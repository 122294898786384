import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import AccountSettingsWrapper from 'components/AccountSettings/AccountSettingsWrapper';
import Button from 'components/Base/Button';
import KebabMenu, { HandlersValueType } from 'components/KebabMenu';
import AddGroupMembersModal from 'components/Modals/AddGroupMembersModal';
import MUITableManagement from 'components/MuiTables/MUITableManagement';
import { GROUP_MANAGEMENT_USER_ACTIONS } from 'constants/general';
import { GROUP_LISTING_MANAGEMENT_MENU, setKebabColumnHeading } from 'constants/KebabMenus';
import { getGroup, updateGroupMember } from 'store/actions/userProfile';
import { RootStateType } from 'store/reducers';
import { MatchParams } from 'types/Route';
import { GroupListingType } from 'types/userProfile';
import { groupListingUserColumns, transformGroupUsersToTableRows } from 'utils/dataColumns';
import { isNotEmptyObject } from 'utils/isEmptyObject';

const GroupListing = () => {
  const dispatch = useDispatch();
  const { id: groupId }: MatchParams = useParams();
  const group: GroupListingType = useSelector((state: RootStateType) => state.profile.group);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getGroup(groupId));
  }, []);

  if (isNotEmptyObject(group)) {
    const { name, users: groupUserData } = group;

    const kebabColRenderGroupListingManagement = (value: HandlersValueType): JSX.Element => (
      <KebabMenu
        value={value}
        menuOptions={GROUP_LISTING_MANAGEMENT_MENU}
        methods={{
          onRemoveGroupMember: (kebabValue: HandlersValueType) => {
            if (kebabValue.id && kebabValue.groupId) {
              dispatch(updateGroupMember(kebabValue.id, kebabValue.groupId, GROUP_MANAGEMENT_USER_ACTIONS.DELETE));
            }
          },
        }}
      />
    );

    const kebabColumn = setKebabColumnHeading(kebabColRenderGroupListingManagement);
    const userColumns = transformGroupUsersToTableRows(groupUserData.map((userInfo) => (
      { ...userInfo.user, groupId: userInfo.group_id }
    )));

    return (
      <AccountSettingsWrapper fluidWrapper>
        <h2 className="account-settings__title mb-3">{name}</h2>
        <div className="management-controls d-flex justify-content-between align-items-center">
          <Button onClick={() => setIsModalOpen(true)}>
            Add members
          </Button>
        </div>
        <div className="mt-4">
          <MUITableManagement
            tableColumns={[
              ...groupListingUserColumns,
              ...kebabColumn,
            ]}
            documentsRows={userColumns}
          />
          {isNotEmptyObject(group) && (
            <AddGroupMembersModal
              onCloseModal={setIsModalOpen}
              isModalOpen={isModalOpen}
              groupId={groupId}
            />
          )}
        </div>
      </AccountSettingsWrapper>
    );
  }

  return null;
};

export default GroupListing;