/* eslint-disable no-underscore-dangle, @typescript-eslint/no-explicit-any, no-console  */
import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import rootReducer from 'store/reducers';
import rootSaga from 'store/sagas';
import { setupAxiosInterceptors } from 'utils/axiosInstance';

const saga = createSagaMiddleware({
  onError: (error) => {
    console.log(error);
    saga.run(rootSaga);
  },
});

export const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(saga),
    (window as any).__REDUX_DEVTOOLS_EXTENSION__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION__()
      : (f: any) => f,
  ),
);

setupAxiosInterceptors(store.dispatch);

export const persistor = persistStore(store);

saga.run(rootSaga);