import {
  LAUNCH_UPDATE_FIELDS_MAPPING,
  SET_COLUMN_NAME_AND_FIELD_TO_UPDATE_MAPPING,
  SET_FIELD_TYPE_CHECKER_MODAL_OPEN,
  SET_MONDAY_COM_BOARDS,
  SET_OPEN_POPUP_BECAUSE_OF_SMS_VALIDATION,
  SET_WAIT_TO_PASSWORDLESS_LINK_RESPONSE,
} from 'store/actions/actionTypes';
import { MondayComReducerType } from 'types/mondayCom/mondayComBoard';
import { Action } from 'types/redux';

const initialState: MondayComReducerType = {
  boards: null,
  subitemsBoards: null,
  workspaces: null,
  fieldTypeCheckerModalOpen: false,
  columnNameAndFieldToUpdateFieldsMapping: null,
  launchUpdateFieldsMapping: false,
  waitToPasswordlessLinkResponse: false,
  shouldOpenPopupBecauseOfSmsValidation: false,
};

// eslint-disable-next-line default-param-last
const mondayCom = (state: MondayComReducerType = initialState, action: Action): MondayComReducerType => {
  switch (action.type) {
    case SET_MONDAY_COM_BOARDS:
      return {
        ...state,
        boards: action.payload.boards,
        subitemsBoards: action.payload.subitems_boards,
        workspaces: action.payload.workspaces,
      };
    case SET_FIELD_TYPE_CHECKER_MODAL_OPEN:
      return {
        ...state,
        fieldTypeCheckerModalOpen: action.payload,
      };
    case SET_COLUMN_NAME_AND_FIELD_TO_UPDATE_MAPPING:
      return {
        ...state,
        columnNameAndFieldToUpdateFieldsMapping: action.payload,
      };
    case LAUNCH_UPDATE_FIELDS_MAPPING:
      return {
        ...state,
        launchUpdateFieldsMapping: action.payload,
      };
    case SET_WAIT_TO_PASSWORDLESS_LINK_RESPONSE:
      return {
        ...state,
        waitToPasswordlessLinkResponse: action.payload,
      };
    case SET_OPEN_POPUP_BECAUSE_OF_SMS_VALIDATION:
      return {
        ...state,
        shouldOpenPopupBecauseOfSmsValidation: action.payload,
      };
    default:
      return state;
  }
};

export default mondayCom;