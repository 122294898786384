import { useSelector } from 'react-redux';

import { DISABLED_FIELD_COLOR } from 'constants/editors';
import { RootStateType } from 'store/reducers';
import { AssignmentPositions, AssignmentsObjectType } from 'types/Editor';
import { SignerType } from 'types/SendDocuments';
import { getHexColorFromIndex } from 'utils/colorAssignmentHelper';
import { getSignersPositionsFromAssignments } from 'utils/signNow/assignmentsHelper';

const useAssignmentFieldColor = (assignment: string, isReadOnly: boolean = false): string => {
  const assignments: AssignmentsObjectType = useSelector((state: RootStateType) => state.editorSlate.assignments);

  if (isReadOnly) return DISABLED_FIELD_COLOR;

  const signersPositions: AssignmentPositions = getSignersPositionsFromAssignments(assignments);
  const position: number = signersPositions[assignment ?? SignerType.RECIPIENT];

  return getHexColorFromIndex(position);
};

export default useAssignmentFieldColor;