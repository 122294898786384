import { AxiosError, AxiosResponse } from 'axios';

import { ERROR, SUCCESS } from 'constants/api';
import { BaseRequestType, BaseResponseType } from 'types/RequestHandler';

const requestHandler = <R, P = void, E = AxiosError>(
  request: BaseRequestType<R, P>,
) => async (
    ...params: P[]
  ): Promise<BaseResponseType<R, E>> => {
    try {
      const response = await request(...params);
      return { code: SUCCESS, ...response };
    } catch (e) {
      return { code: ERROR, ...e as AxiosResponse<E> };
    }
  };

export default requestHandler;