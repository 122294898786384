import { FC, useState } from 'react';

import Pagination from '@mui/material/Pagination';

import MondayComAttachmentColumnSelect from 'components/Modals/MondayComModalWindow/MondayComAttachmentColumnSelect';
import MondayComItemDocumentFileColumnSelect from 'components/Modals/MondayComModalWindow/MondayComItemDocumentFileColumnSelect';
import MondayComSelectKeyFilterColumns from 'components/Modals/MondayComModalWindow/MondayComSelectKeyFilterColumns';
import { ICustomElement } from 'types/Editor';
import { MondayComColumnType, MondayComItemDocumentFile, MondayComSubitemBoardType } from 'types/mondayCom/mondayComBoard';
import { IMondayComItemAttachmentSettings } from 'types/mondayCom/mondayComModalWindow';
import { PDFFieldType } from 'types/PdfTemplates';
import { filterUsedColumns } from 'utils/MondayComIntegration/mondayComHelpers';
import getFieldName from 'utils/PublicPage/getFieldName';

const MondayComItemAttachmentSettings: FC<IMondayComItemAttachmentSettings> = ({
  subitemsBoards,
  templateAttachmentFields,
  templateIntegrationData,
  onChangeItemAttachmentSettingsHandler,
  onChangeItemDocumentFileSettingsHandler,
  onChangeKeyFilterColumnsHandler,
}) => {
  const [boardPage, setBoardPage] = useState(0);

  if (!templateIntegrationData) {
    return (
      <div className="d-flex justify-content-center mb-1">
        There aren&apos;t selected boards.
      </div>
    );
  }

  const currentBoardObject = templateIntegrationData ? templateIntegrationData[boardPage] : null;
  const currentSubitemsBoard: MondayComSubitemBoardType | undefined = subitemsBoards.find(
    (subitemBoard: MondayComSubitemBoardType) => subitemBoard.name.slice(12) === currentBoardObject?.board.name,
  );

  const targetColumns = Object.keys(currentBoardObject?.fieldsMapping ?? {}).length
    ? currentBoardObject?.board.columns.filter((column) => column.type === 'file') ?? []
    : currentSubitemsBoard?.columns.filter((column) => column.type === 'file') ?? [];

  return (
    <>
      <div className="d-flex justify-content-center mb-2">
        {
          templateIntegrationData.length > 1 && (
            <Pagination
              color="primary"
              count={templateIntegrationData.length}
              onChange={(event, page) => setBoardPage(page - 1)}
            />
          )
        }
      </div>
      <div className="d-flex justify-content-center mb-2 monday-com-board-name">
        {currentBoardObject?.board.name}
      </div>
      {
        templateAttachmentFields.length
          ? templateAttachmentFields.map((field: Partial<ICustomElement> | PDFFieldType) => (
            <div key={field.key} className="d-flex mb-1">
              <div className="w-50 d-flex align-items-center">
                {field.fieldName ?? getFieldName(field, false)}
              </div>
              <div className="w-50">
                <MondayComAttachmentColumnSelect
                  key={currentBoardObject?.board.id}
                  boardId={currentBoardObject?.board.id ?? ''}
                  field={field}
                  columns={targetColumns}
                  currentColumn={
                    (currentBoardObject && currentBoardObject.itemAttachmentSettings)
                      ? currentBoardObject.itemAttachmentSettings[field.key ?? 0]
                      : null
                  }
                  onChangeItemAttachmentSettingsHandler={onChangeItemAttachmentSettingsHandler}
                />
              </div>
            </div>
          ))
          : <div>There aren&apos;t any Attachment fields in the template.</div>
      }
      <div>
        <div className="d-flex justify-content-center my-3 monday-com-board-name">
          Save completed document to column
        </div>
        <div className="d-flex mb-2">
          <div className="w-50 d-flex align-items-center">
            Files
          </div>
          <div className="w-50">
            <MondayComItemDocumentFileColumnSelect
              key={currentBoardObject?.board.id}
              boardId={currentBoardObject?.board.id ?? ''}
              columns={currentBoardObject
                ? currentBoardObject.board.columns.filter((column) => column.id.startsWith('file'))
                : []}
              documentFileSettingsKey={MondayComItemDocumentFile.FILE}
              currentColumn={
                (currentBoardObject && currentBoardObject.itemDocumentFileSettings)
                  ? currentBoardObject.itemDocumentFileSettings[MondayComItemDocumentFile.FILE] as MondayComColumnType
                  : null
              }
              onChangeItemAttachmentSettingsHandler={onChangeItemDocumentFileSettingsHandler}
            />
          </div>
        </div>
        <div className="d-flex mb-2">
          <div className="w-50 d-flex align-items-center">
            Status
          </div>
          <div className="w-50">
            <MondayComItemDocumentFileColumnSelect
              key={currentBoardObject?.board.id}
              boardId={currentBoardObject?.board.id ?? ''}
              columns={currentBoardObject
                ? filterUsedColumns(currentBoardObject)!.filter((column) => column.id.startsWith('status'))
                : []}
              documentFileSettingsKey={MondayComItemDocumentFile.STATUS}
              currentColumn={
                (currentBoardObject && currentBoardObject.itemDocumentFileSettings)
                  ? currentBoardObject.itemDocumentFileSettings[MondayComItemDocumentFile.STATUS] as MondayComColumnType
                  : null
              }
              onChangeItemAttachmentSettingsHandler={onChangeItemDocumentFileSettingsHandler}
            />
          </div>
        </div>
      </div>
      {
        currentBoardObject?.itemDocumentFileSettings
        && currentBoardObject.itemDocumentFileSettings[MondayComItemDocumentFile.FILE]
        && currentBoardObject.itemDocumentFileSettings[MondayComItemDocumentFile.STATUS]
        && (
          <MondayComSelectKeyFilterColumns
            isItemDocumentFlow
            currentBoardObject={currentBoardObject}
            onChangeKeyFilterColumnsHandler={onChangeKeyFilterColumnsHandler}
          />
        )
      }

    </>
  );
};

export default MondayComItemAttachmentSettings;