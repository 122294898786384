import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { ATTACHMENT_FIELD, SIGNING_FIELD } from 'constants/editors';
import { DOCUMENT_TYPE } from 'constants/general';
import { MONDAY_COM_TABS_IDS } from 'constants/mondayComIntegration';
import useEnableSmsValidationParameter from 'hooks/MondayCom/useEnableSmsValidationParameter';
import {
  getMondayComBoards,
  saveMondayComIntegrationSettings,
} from 'store/actions/mondayComIntegration';
import { RootStateType } from 'store/reducers';
import { ICustomElement } from 'types/Editor';
import {
  FieldsMappingObjectType,
  FilterColumnsIdsObjectType,
  MondayComBoardType,
  MondayComColumnType,
  MondayComFilterColumnsIdsType,
  MondayComFilterKeys,
  MondayComIntegrationObjectType,
  MondayComItemDocumentFile,
  MondayComItemDocumentFileType,
  MondayComSubitemMappingType,
  SubitemsMappingObjectType,
} from 'types/mondayCom/mondayComBoard';
import { PDFFieldType } from 'types/PdfTemplates';
import { isPDFDocument } from 'utils/PublicPage/documentTypeChecker';
import getFieldsFromData from 'utils/PublicPage/getFieldsFromData';

const useMondayComIntegration = (showMondayComModal: boolean) => {
  const dispatch = useDispatch();
  const { boards, subitemsBoards, workspaces } = useSelector((state: RootStateType) => state.mondayCom);
  const { templateDetails, collectionDetails } = useSelector((state: RootStateType) => state.user);
  const { monday_com_access_token: mondayComAccessToken } = useSelector(
    (state: RootStateType) => state.profile?.profileInfo?.company ?? {},
  );
  const [resourceIntegrationData, setResourceIntegrationData] = useState<MondayComIntegrationObjectType[] | null>(null);
  const [templateFields, setTemplateFields] = useState<Partial<ICustomElement>[] | PDFFieldType[]>([]);
  const [templateAttachmentFields, setTemplateAttachmentFields] = useState<Partial<ICustomElement>[] | PDFFieldType[]>([]);
  const [activeTab, setActiveTab] = useState<number>(MONDAY_COM_TABS_IDS.CONNECT_BOARDS_TAB_ID);
  const [disabledTabId, setDisabledTabId] = useState<number | undefined>(undefined);

  const enabledSMSValidation = useEnableSmsValidationParameter();

  const currentIntegrationData = templateDetails
    ? templateDetails?.monday_com_integration
    : collectionDetails?.monday_com_integration;

  // User is able to chooses only one tab: either Item Settings or Subitem settings. Not both.
  useEffect(() => {
    if (resourceIntegrationData?.some((boardObject) => (
      boardObject.fieldsMapping && Object.keys(boardObject.fieldsMapping).length))
    ) {
      setDisabledTabId(MONDAY_COM_TABS_IDS.SUBITEM_SETTINGS_TAB_ID);
    }
    if (resourceIntegrationData?.some((boardObject) => (
      boardObject.subitemMapping && Object.keys(boardObject.subitemMapping).length))
    ) {
      setDisabledTabId(MONDAY_COM_TABS_IDS.ITEM_SETTINGS_TAB_ID);
    }
    if (resourceIntegrationData?.every((boardObject) => (
      !boardObject.subitemMapping || (boardObject.subitemMapping && !Object.keys(boardObject.subitemMapping).length)
    ) && (
      !boardObject.fieldsMapping || (boardObject.fieldsMapping && !Object.keys(boardObject.fieldsMapping).length)))
    ) {
      setDisabledTabId(undefined);
    }
  }, [resourceIntegrationData]);

  // Getting all Monday.com boards for current account.
  useEffect(() => {
    if (mondayComAccessToken && showMondayComModal && !boards) {
      dispatch(getMondayComBoards());
    }
  }, [dispatch, boards, showMondayComModal]);

  useEffect(() => {
    if (templateDetails) {
      const fields = getFieldsFromData({ data: templateDetails, isPDF: isPDFDocument(templateDetails) });
      // Setting template fields and excluding signature and attachment fields for now.
      setTemplateFields(fields.filter((field) => ![SIGNING_FIELD, ATTACHMENT_FIELD].includes(field.type ?? '')));
      // Setting template attachment fields.
      setTemplateAttachmentFields(fields.filter((field) => field.type === ATTACHMENT_FIELD));
    }
    // Setting Monday.com integration object (board, fields mapping, subitem mapping) if it exists in current template.
    if (currentIntegrationData) {
      setResourceIntegrationData(currentIntegrationData);
    }
  }, [currentIntegrationData, templateDetails?.sections]);

  // Auto update board columns if they were added to Monday.com board.
  useEffect(() => {
    if (boards && currentIntegrationData) {
      const updatedIntegrationObject = currentIntegrationData.map(
        (boardObject: MondayComIntegrationObjectType) => {
          const board = boards.find((board) => board.id === boardObject.board.id);
          if (board) {
            return {
              board,
              fieldsMapping: boardObject.fieldsMapping,
              ...(boardObject.subitemMapping ? { subitemMapping: boardObject.subitemMapping } : {}),
              ...(boardObject.filterColumnsIds ? { filterColumnsIds: boardObject.filterColumnsIds } : {}),
              ...(boardObject.itemAttachmentSettings ? { itemAttachmentSettings: boardObject.itemAttachmentSettings } : {}),
              ...(boardObject.itemDocumentFileSettings ? { itemDocumentFileSettings: boardObject.itemDocumentFileSettings } : {}),
            };
          }
          return boardObject;
        },
      );
      setResourceIntegrationData(updatedIntegrationObject);
    }
  }, [boards, currentIntegrationData]);

  const onSelectBoardsHandler = useCallback((event: ChangeEvent<HTMLInputElement>, boardId: string) => {
    const targetBoard = boards?.find((board: MondayComBoardType) => board.id === boardId);
    if (targetBoard) {
      if (event.target.checked) {
        setResourceIntegrationData((prevState) => {
          if (prevState) {
            return [...prevState, { board: targetBoard }];
          }
          return [{ board: targetBoard }];
        });
      } else {
        setResourceIntegrationData((prevState) => {
          const filteredBoards = prevState?.filter((boardObject) => boardObject.board.id !== boardId);
          if (filteredBoards?.length) {
            return filteredBoards;
          }
          return null;
        });
      }
    }
  }, [boards]);

  const onChangeFieldsMappingHandler = useCallback((fieldMappingObject: FieldsMappingObjectType) => {
    const currentBoard = resourceIntegrationData?.find(
      (boardObject) => boardObject.board.id === fieldMappingObject.boardId,
    );
    if (currentBoard) {
      const currentColumn = currentBoard.board.columns.find(
        (column) => column.title === fieldMappingObject.columnName,
      );
      if (currentColumn) {
        const updatedFieldsMapping = { ...currentBoard.fieldsMapping, [fieldMappingObject.fieldKey]: currentColumn };
        currentBoard.fieldsMapping = updatedFieldsMapping;
      } else {
        const fieldMappingCopy = { ...currentBoard.fieldsMapping };
        delete fieldMappingCopy[fieldMappingObject.fieldKey];
        currentBoard.fieldsMapping = fieldMappingCopy;
      }
      setResourceIntegrationData((prevState) => prevState?.map((boardObject: MondayComIntegrationObjectType) => {
        if (boardObject.board.id === currentBoard.board.id) {
          return currentBoard;
        }
        return boardObject;
      }) ?? null);
    }
  }, [resourceIntegrationData]);

  const onChangeSubitemsMappingHandler = useCallback((subitemMappingObject: SubitemsMappingObjectType) => {
    const currentBoardObject = resourceIntegrationData?.find(
      (boardObject) => boardObject.board.id === subitemMappingObject.boardId,
    );
    if (currentBoardObject) {
      if (subitemMappingObject.subitemName) {
        const updatedSubitemsMapping: MondayComSubitemMappingType = {
          ...currentBoardObject.subitemMapping,
          [subitemMappingObject.columnId]: subitemMappingObject.subitemName,
        };
        currentBoardObject.subitemMapping = updatedSubitemsMapping;
      } else {
        const subitemsMappingCopy = { ...currentBoardObject.subitemMapping };
        delete subitemsMappingCopy[subitemMappingObject.columnId];
        currentBoardObject.subitemMapping = subitemsMappingCopy;
      }
      setResourceIntegrationData((prevState) => prevState?.map((boardObject: MondayComIntegrationObjectType) => {
        if (boardObject.board.id === currentBoardObject.board.id) {
          return currentBoardObject;
        }
        return boardObject;
      }) ?? null);
    }
  }, [resourceIntegrationData]);

  const onChangeKeyFilterColumnsHandler = useCallback((
    filterColumnsIdsObject: FilterColumnsIdsObjectType,
    isItemDocumentFlow = false,
  ) => {
    const currentBoardObject = resourceIntegrationData?.find(
      (boardObject) => boardObject.board.id === filterColumnsIdsObject.boardId,
    );
    if (currentBoardObject) {
      const currentPartOfStore = isItemDocumentFlow
        ? currentBoardObject.itemDocumentFileSettings
        : currentBoardObject.filterColumnsIds;
      if (filterColumnsIdsObject.columnTitle) {
        const updatedFilterColumnsIds: MondayComFilterColumnsIdsType = {
          ...currentPartOfStore,
          [filterColumnsIdsObject.fiterType]: filterColumnsIdsObject.columnId,
        };
        if (isItemDocumentFlow) {
          currentBoardObject.itemDocumentFileSettings = updatedFilterColumnsIds;
        } else {
          currentBoardObject.filterColumnsIds = updatedFilterColumnsIds;
        }
      } else {
        const currentPartOfStoreCopy = currentPartOfStore ? { ...currentPartOfStore } : {};
        delete currentPartOfStoreCopy[filterColumnsIdsObject.fiterType];
        if (isItemDocumentFlow) {
          currentBoardObject.itemDocumentFileSettings = currentPartOfStoreCopy;
        } else {
          currentBoardObject.filterColumnsIds = currentPartOfStoreCopy;
        }
      }
      setResourceIntegrationData((prevState) => prevState?.map((boardObject: MondayComIntegrationObjectType) => {
        if (boardObject.board.id === currentBoardObject.board.id) {
          return currentBoardObject;
        }
        return boardObject;
      }) ?? null);
    }
  }, [resourceIntegrationData]);

  const onChangeItemAttachmentSettingsHandler = useCallback((
    boardId: string,
    fieldKey: number,
    targetColumn?: MondayComColumnType,
  ) => {
    const currentBoard = resourceIntegrationData?.find(
      (boardObject) => boardObject.board.id === boardId,
    );
    if (currentBoard) {
      if (targetColumn) {
        const updatedAttachmentSettings = { ...currentBoard.itemAttachmentSettings, [fieldKey]: targetColumn };
        currentBoard.itemAttachmentSettings = updatedAttachmentSettings;
      } else {
        const attachmentSettingsCopy = { ...currentBoard.itemAttachmentSettings };
        delete attachmentSettingsCopy[fieldKey];
        currentBoard.itemAttachmentSettings = attachmentSettingsCopy;
      }
      setResourceIntegrationData((prevState) => prevState?.map((boardObject: MondayComIntegrationObjectType) => {
        if (boardObject.board.id === currentBoard.board.id) {
          return currentBoard;
        }
        return boardObject;
      }) ?? null);
    }
  }, [resourceIntegrationData]);

  const onChangeItemDocumentFileSettingsHandler = useCallback((
    boardId: string,
    documentFileSettingsKey: MondayComItemDocumentFileType,
    targetColumn?: MondayComColumnType,
  ) => {
    const currentBoard = resourceIntegrationData?.find(
      (boardObject) => boardObject.board.id === boardId,
    );
    if (currentBoard) {
      if (targetColumn) {
        const updatedItemDocumentFileSettings = { ...currentBoard.itemDocumentFileSettings, [documentFileSettingsKey]: targetColumn };
        currentBoard.itemDocumentFileSettings = updatedItemDocumentFileSettings;
      } else {
        const itemDocumentFileSettingsCopy = { ...currentBoard.itemDocumentFileSettings };
        delete itemDocumentFileSettingsCopy[documentFileSettingsKey];
        currentBoard.itemDocumentFileSettings = itemDocumentFileSettingsCopy;
      }
      setResourceIntegrationData((prevState) => prevState?.map((boardObject: MondayComIntegrationObjectType) => {
        if (boardObject.board.id === currentBoard.board.id) {
          return currentBoard;
        }
        return boardObject;
      }) ?? null);
    }
  }, [resourceIntegrationData]);

  const validateSaveButton = (resourceIntegrationData: MondayComIntegrationObjectType[] | null) => {
    let disableSaveButton = false;
    resourceIntegrationData?.forEach((boardObject) => {
      if (
        (
          (boardObject.subitemMapping && Boolean(Object.keys(boardObject.subitemMapping).length))
          && (
            !boardObject.filterColumnsIds
            || (
              boardObject.filterColumnsIds
              && Object.keys(boardObject.filterColumnsIds).length < (enabledSMSValidation ? 3 : 2)
            )
          )
        )
        || (
          boardObject.itemDocumentFileSettings
          && (
            (
              boardObject.itemDocumentFileSettings[MondayComItemDocumentFile.FILE]
              && !boardObject.itemDocumentFileSettings[MondayComItemDocumentFile.STATUS]
            )
            || (
              boardObject.itemDocumentFileSettings[MondayComItemDocumentFile.STATUS]
              && !boardObject.itemDocumentFileSettings[MondayComItemDocumentFile.FILE]
            )
          )
        )
        || (
          boardObject.itemDocumentFileSettings
          && (
            (
              boardObject.itemDocumentFileSettings[MondayComFilterKeys.EMAIL_COLUMN_ID]
              && !boardObject.itemDocumentFileSettings[MondayComFilterKeys.FILTER_COLUMN_ID]
            )
            || (
              boardObject.itemDocumentFileSettings[MondayComFilterKeys.FILTER_COLUMN_ID]
              && !boardObject.itemDocumentFileSettings[MondayComFilterKeys.EMAIL_COLUMN_ID]
            )
          )
        )
      ) {
        disableSaveButton = true;
      }
    });
    return disableSaveButton;
  };

  const onSaveIntegrationSettings = useCallback((resourceType: string = DOCUMENT_TYPE) => {
    dispatch(saveMondayComIntegrationSettings(
      resourceType === DOCUMENT_TYPE ? templateDetails.id : collectionDetails.id,
      resourceIntegrationData,
      resourceType,
    ));
  }, [templateDetails?.id, collectionDetails?.id, resourceIntegrationData, dispatch]);

  return {
    isMondayComServiceAvailable: Boolean(mondayComAccessToken),
    enabledSMSValidation,
    templateFields,
    templateAttachmentFields,
    resourceIntegrationData,
    boards,
    subitemsBoards: subitemsBoards ?? [],
    workspaces,
    onSelectBoardsHandler,
    onSaveIntegrationSettings,
    activeTab,
    setActiveTab,
    onChangeFieldsMappingHandler,
    onChangeSubitemsMappingHandler,
    onChangeKeyFilterColumnsHandler,
    onChangeItemAttachmentSettingsHandler,
    validateSaveButton,
    disabledTabId,
    onChangeItemDocumentFileSettingsHandler,
  };
};

export default useMondayComIntegration;