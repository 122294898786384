import {
  COLLECTION_EXECUTED_TYPE,
  COLLECTION_TYPE,
  DOCUMENT_EXECUTED_TYPE,
  DOCUMENT_TYPE,
  URL_PUBLIC_COLLECTION,
  URL_PUBLIC_DOCUMENT,
  URL_PUBLIC_TEMPLATE,
} from 'constants/general';
import ROUTES from 'constants/routes';
import { SIGNATURE_TABS } from 'constants/signatures';
import { getPersonalSignatureData } from 'services/api/userPersonalData';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { isBase64String } from 'utils/editorFieldHelpers';

export const getSignatureFile = async () => {
  const response = await apiErrorHandler(
    getPersonalSignatureData,
    { returnErrorResult: true },
  );
  if (response?.data?.image_data && response?.data?.signature_hash) {
    return response.data;
  }
};

export const getSignatureValueAndActiveTab = async () => {
  const signatureObject = await getSignatureFile();
  const signatureValue = signatureObject.image_data;
  const tabToShow = isBase64String(signatureValue) ? SIGNATURE_TABS.DRAW_TAB : SIGNATURE_TABS.TYPE_TAB;
  return { signatureValue, signatureHash: signatureObject.signature_hash, tabToShow };
};

export const getDocTypeByPageType = (documentType: string) => {
  switch (documentType) {
    case URL_PUBLIC_TEMPLATE:
      return DOCUMENT_TYPE;
    case URL_PUBLIC_DOCUMENT:
      return DOCUMENT_EXECUTED_TYPE;
    case URL_PUBLIC_COLLECTION:
      return COLLECTION_TYPE;
    case ROUTES.PUBLIC_COLLECTION_EXECUTED:
      return COLLECTION_EXECUTED_TYPE;
    default:
      return DOCUMENT_TYPE;
  }
};