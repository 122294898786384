import { useDispatch } from 'react-redux';

import { setDocumentUUID } from 'store/actions/publicPages';

const useSendBeacon = () => {
  const dispatch = useDispatch();

  const sendBeaconHandler = (url: string, data: any) => {
    navigator.sendBeacon(url, JSON.stringify(data));
  };

  const sendDocumentDataUsingBeacon = (url: string, data: any, documentUUID?: string | null) => {
    let updatedData = {};
    if (!documentUUID) {
      const uuid = crypto.randomUUID();
      dispatch(setDocumentUUID(uuid));
      updatedData = { ...data, documentUUID: uuid };
    } else {
      updatedData = { ...data, documentUUID };
    }
    sendBeaconHandler(url, updatedData);
  };

  return {
    sendDocumentDataUsingBeacon,
  };
};

export default useSendBeacon;