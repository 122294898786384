import { SignerFlow, SignerRecord } from 'constants/signNow';
import {
  CLEAR_SIGN_NOW_FILE_FROM_STORE,
  CLEAR_SIGN_NOW_STORE,
  SET_CURRENT_SIGNER,
  SET_CURRENT_SIGNER_FLOW_TO_STORE,
  SET_IS_REMIND_DOCUMENT_ACTIVE,
  SET_IS_REMIND_FOR_ALL_SIGNERS,
  SET_IS_SIGN_NOW_ACTIVE,
  SET_SIGN_NOW_DOCUMENT_INFO,
  SET_SIGN_NOW_FILE_URL,
  SET_SIGN_NOW_PDF_FORM_FIELDS,
  SET_SIGNERS_LIST,
} from 'store/actions/actionTypes';
import { Action } from 'types/redux';
import { SignersInfoType } from 'types/SendDocuments';
import { IPDFFileInfo, ISignNowStore } from 'types/signNow/store';

const initialState: ISignNowStore = {
  file: null,
  currentFlow: null,
  signers: {
    [SignerRecord.One]: [],
    [SignerRecord.Many]: [],
  },
  currentSigner: null,
  isSignNow: false,
  isRemindDocumentActive: false,
  isRemindForAllSigners: false,
  document: {
    id: null,
    title: null,
    isError: false,
  },
  pdfFormFields: null,
};

// eslint-disable-next-line default-param-last
const signNow = (state: ISignNowStore = initialState, { type, payload }: Action): ISignNowStore => {
  switch (type) {
    case SET_SIGN_NOW_FILE_URL:
      return {
        ...state,
        file: payload as IPDFFileInfo,
      };
    case SET_SIGN_NOW_PDF_FORM_FIELDS:
      return {
        ...state,
        pdfFormFields: payload,
      };
    case CLEAR_SIGN_NOW_FILE_FROM_STORE:
      return {
        ...state,
        file: null,
      };
    case SET_CURRENT_SIGNER_FLOW_TO_STORE:
      return {
        ...state,
        currentFlow: payload as SignerFlow,
      };
    case SET_SIGN_NOW_DOCUMENT_INFO:
      return {
        ...state,
        document: {
          ...state.document,
          ...payload,
        },
      };
    case SET_SIGNERS_LIST:
      return {
        ...state,
        signers: {
          ...(state.signers && { ...state.signers }),
          [payload.record as SignerRecord]: payload.signers as SignersInfoType[],
        },
      };
    case SET_CURRENT_SIGNER:
      return {
        ...state,
        currentSigner: payload,
      };
    case SET_IS_SIGN_NOW_ACTIVE:
      return {
        ...state,
        isSignNow: true,
      };
    case SET_IS_REMIND_DOCUMENT_ACTIVE:
      return {
        ...state,
        isRemindDocumentActive: true,
      };
    case SET_IS_REMIND_FOR_ALL_SIGNERS:
      return {
        ...state,
        isRemindForAllSigners: payload,
      };
    case CLEAR_SIGN_NOW_STORE: {
      const isClearFullStore = payload;
      const isRemindDocumentActive = state.isRemindDocumentActive;
      const pdfFormFields = state.pdfFormFields;
      return {
        ...initialState,
        ...(!isClearFullStore && {
          isRemindDocumentActive,
        }),
        ...(pdfFormFields && pdfFormFields.length && { pdfFormFields }),
      };
    }
    default:
      return state;
  }
};

export default signNow;