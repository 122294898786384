import { Dispatch as ReactDispatch, useEffect, useState } from 'react';

import { MUIDataTableColumn } from 'mui-datatables';

import { QueryParameters } from 'constants/queryParametersManager';
import { DocumentStatusType } from 'types/Documents';
import {
  getCurrentActiveStatusesByQueryParam,
  getCurrentTabByStatuses,
  replaceTabQueryParam,
} from 'utils/QueryParameters/documentsTabsQueryParameters ';

type UseQueryParametersManagerType = ({
  setActiveStatuses,
  activeStatuses,
}: {
  activeStatuses?: DocumentStatusType,
  setActiveStatuses?: ReactDispatch<React.SetStateAction<string[]>>,
}) => {
  urlQueryParams: URLSearchParams,
  onChangeTableFilterHandler: (changedColumn: string | MUIDataTableColumn | null, value: string) => void,
};

const useQueryParametersManager: UseQueryParametersManagerType = ({
  activeStatuses,
  setActiveStatuses,
}) => {
  const [localCurrentTab, setLocalCurrentTab] = useState<string | null>(null);
  const urlParams = new URLSearchParams(window.location.search);
  const previousTabQueryParam = urlParams.get(QueryParameters.TAB);
  const currentTab = getCurrentTabByStatuses(activeStatuses).toLowerCase().replaceAll(' ', '-');

  useEffect(() => {
    if (setActiveStatuses && previousTabQueryParam && !activeStatuses?.length && !localCurrentTab) {
      setActiveStatuses(getCurrentActiveStatusesByQueryParam(previousTabQueryParam));
    } else if (!previousTabQueryParam) {
      urlParams.append(QueryParameters.TAB, currentTab);
      replaceTabQueryParam(urlParams);
    } else if (previousTabQueryParam !== currentTab) {
      urlParams.set(QueryParameters.TAB, currentTab);
      replaceTabQueryParam(urlParams);
    }
    setLocalCurrentTab(currentTab);
  }, [activeStatuses]);

  const onChangeTableFilterHandler = (changedColumn: string | MUIDataTableColumn | null, value: string) => {
    if (value) {
      urlParams.set(changedColumn as string, value);
      replaceTabQueryParam(urlParams);
    } else {
      urlParams.delete(changedColumn as string);
      replaceTabQueryParam(urlParams);
    }
  };

  return {
    urlQueryParams: urlParams,
    onChangeTableFilterHandler,
  };
};

export default useQueryParametersManager;