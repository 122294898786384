import { FC, useEffect, useRef, useState } from 'react';

import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import moment from 'moment';
import { DisplayData } from 'mui-datatables';

import Button from 'components/Base/Button';
import { MOMENT_DATE_FORMAT } from 'constants/dateField';
import useCSVData, { CSVDataType, CSVHeaderType } from 'hooks/useCSVData';
import { getDataForBatchExcelExport } from 'services/api';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { RootStateType } from 'store/reducers';
import { IDocumentsIdsArray, SelectedRowsType } from 'types/Mui';
import { ICollectionDetails, ICollectionTemplate } from 'types/MultiTemplate';
import { createDocsIdsArray, getIsDataForExportEmpty } from 'utils/sendDocumentsHelpers';

interface IBatchExportExcelButton {
  selectedRows: SelectedRowsType;
  displayData: DisplayData;
  isCollectionPage?: boolean;
}

const BatchExportExcelButton: FC<IBatchExportExcelButton> = ({
  selectedRows,
  displayData,
  isCollectionPage = false,
}) => {
  const { collectionsExecuted } = useSelector((state: RootStateType) => state.user);
  const { downloadAttachmentsZIP } = useCSVData();

  const [csvData, setCsvData] = useState<{ headers: CSVHeaderType[], data: CSVDataType[] }>({ headers: [], data: [] });
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const csvInstance = useRef<any | null>(null);

  const handlerExportExcel = async () => {
    const documentsIds = createDocsIdsArray(selectedRows, displayData);
    const docsInCollectionIds:IDocumentsIdsArray[] = [];
    if (isCollectionPage) {
      documentsIds.forEach((item) => {
        const currentCollection = collectionsExecuted.find(
          (collection: ICollectionDetails) => collection.id === item.id,
        );
        currentCollection?.documents.forEach((docObject: ICollectionTemplate) => {
          const document = docObject.document;
          if (document) {
            docsInCollectionIds.push({ id: document.id, type: document.type });
          }
        });
      });
    }
    const dataForExport = await apiErrorHandler(
      getDataForBatchExcelExport,
      isCollectionPage ? docsInCollectionIds : documentsIds,
    );
    const isDataForExportEmpty = getIsDataForExportEmpty(dataForExport);

    if (!isDataForExportEmpty) {
      setDisableButton(true);
      downloadAttachmentsZIP(dataForExport.attachments_full_links);
      setCsvData({ headers: dataForExport.headers, data: dataForExport.data });
      toast.info('Unicorns are creating CSV file, please wait a few seconds...');
      return;
    }
    toast.error('There are no fields in selected document(s)');
  };

  useEffect(() => {
    if (csvData && csvInstance && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData({ headers: [], data: [] });
        setDisableButton(false);
      });
    }
  }, [csvData]);

  return (
    <>
      <Button
        classes="button-outlined-pink mui-table-button"
        onClick={handlerExportExcel}
        disabled={disableButton}
      >
        {disableButton ? 'Downloading...' : 'Export'}
      </Button>
      {
        csvData.headers.length !== 0 ? (
          <CSVLink
            data={csvData.data}
            headers={csvData.headers}
            filename={`Documents_${moment(Date.now()).format(MOMENT_DATE_FORMAT)}.csv`}
            ref={csvInstance}
          />
        ) : null
      }
    </>
  );
};

export default BatchExportExcelButton;