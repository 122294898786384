import { Action } from 'types/redux';
import {
  SET_IS_SUBMIT_BUTTON,
} from 'store/actions/actionTypes';

const initialState = {
  isButtonSubmit: false,
};

export type ValidationType = {
  isButtonSubmit: boolean;
}

// eslint-disable-next-line default-param-last
export default (state: ValidationType = initialState, { type, payload }: Action) => {
  switch (type) {
    case SET_IS_SUBMIT_BUTTON:
      return {
        ...state,
        isButtonSubmit: payload,
      };
    default:
      return state;
  }
};
