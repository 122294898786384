/* eslint-disable */
import React, { useEffect, useState, ChangeEvent, KeyboardEvent } from 'react';
import { Label } from 'reactstrap';
import { Element as SlateElement } from 'slate';
import FieldProperties from './FieldProperties';
import { ATTACHMENT_DEFAULT_PARAMS, SYMBOLS_FOR_IGNORE } from 'constants/editors';

interface IAttachmentFieldProperties {
  field: any;
  updateField: (props: { updatedField: Partial<SlateElement> }) => void;
}

const AttachmentFieldProperties: React.FC<IAttachmentFieldProperties> = ({ field, updateField }) => {
  const defaultLimit = ATTACHMENT_DEFAULT_PARAMS.limit;
  const [attachmentLimit, setAttachmentLimit] = useState<number>(defaultLimit);

  useEffect(() => {
    if (field) {
      setAttachmentLimit(field.properties?.limit || defaultLimit);
    }
  }, [field, setAttachmentLimit, defaultLimit]);

  const changeProperty = (event: ChangeEvent<HTMLInputElement>): void => {
    const targetValue = parseInt(event.target.value);
    const currentValue = (
      targetValue > field.properties.maxLimit
        ? field.properties.maxLimit
        : targetValue
      ) || defaultLimit;
    const updatedField = {
      properties: {
        ...field.properties,
        limit: currentValue,
      }
    };
    updateField({ updatedField });
    setAttachmentLimit(currentValue);
  };

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (SYMBOLS_FOR_IGNORE.includes(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <>
      <FieldProperties
        label="Attachment Limit"
        type="number"
        value={`${attachmentLimit}`}
        onChangeHandler={changeProperty}
        onKeyDown={onKeyDown}
      />
      <div className="max-width-300">
        <Label>Attachments are limited to {ATTACHMENT_DEFAULT_PARAMS.maxMBLimit} MB in total</Label>
      </div>
    </>
  )
}

export default React.memo(AttachmentFieldProperties);