import {
  SET_PAGE_SETTING_VALUE_BY_KEY,
} from 'store/actions/actionTypes';
import { PageSettingsKeyType, PageSettingsValueType } from 'types/PageSettingsType';

export const setPageSettingValueByKey = ({
  key,
  value,
}: { key: PageSettingsKeyType, value: PageSettingsValueType }) => ({
  type: SET_PAGE_SETTING_VALUE_BY_KEY,
  payload: { key, value },
});

export default undefined;