import { FC, memo } from 'react';

import { Tooltip as MuiTooltip, TooltipProps } from '@mui/material';

import vars from 'variables.module.scss';

const Tooltip: FC<TooltipProps> = ({
  children,
  placement = 'top',
  arrow = true,
  title,
  ...restProps
}) => (
  <MuiTooltip
    placement={placement}
    arrow={arrow}
    title={title}
    componentsProps={{
      tooltip: {
        sx: {
          backgroundColor: vars.blackColor,
          fontSize: '1rem',
          padding: '0.25rem 0.75rem',
          fontWeight: vars.normalText,
          fontFamily: vars.mainFont,
        },
      },
      arrow: {
        sx: {
          color: vars.blackColor,
        },
      },
    }}
    {...restProps}
  >
    {children}
  </MuiTooltip>
);

export default memo(Tooltip);