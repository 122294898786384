import { Editor as SlateEditor, Element as SlateElement, Path, Range, Transforms } from 'slate';

import { ListsEditor, ListType } from 'components/Editor/editor-custom-plugins/lists';
import { isInList, isListTypeActive as isListStyleTypeActive } from 'components/Editor/editor-custom-plugins/lists/lib';
import {
  setGeneralListType,
  unwrapList,
  wrapInList,
} from 'components/Editor/editor-custom-plugins/lists/transformations';
import { isInTable } from 'components/Editor/editor-custom-plugins/table/utils';
import { ListFormatType } from 'components/Editor/editor-custom-plugins/withLists';
import {
  BULLETED_LIST,
  LIST_ITEM,
  LIST_TYPES,
  NUMBERED_LIST,
  NUMBERED_LIST_ITEM,
  PARAGRAPH,
  SECTION,
} from 'constants/editors';
import { BlockFormatType, ListStyleViewType } from 'types/Editor';

export const isBlockActive = (editor: SlateEditor, format: BlockFormatType): boolean => {
  const matchedNodes = Array.from(SlateEditor.nodes(editor, {
    match: (node) => (
      SlateElement.isElement(node)
      && node.type === format
    ),
  }));
  return !!matchedNodes.length;
};

export const isListTypeActive = (editor: SlateEditor, format: BlockFormatType, type: string = ''): boolean => {
  const isList = LIST_TYPES.includes(format);
  if (!isList) return false;
  return isListStyleTypeActive(editor as ListsEditor, format, type);
};

/**
 * Used for toggling Headers and Lists
 * @param editor SlateEditor | ListsEditor
 * @param format type attribute for Slate element
 * @param type additional properties like listStyle for Slate element
 */
export const toggleBlock = (editor: SlateEditor | ListsEditor, format: BlockFormatType, type: string = ''): void => {
  SlateEditor.withoutNormalizing(editor, () => {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);
    const listItemTypeToApply = (format === NUMBERED_LIST) ? NUMBERED_LIST_ITEM : LIST_ITEM;

    if (isList) {
      let switchList = false;
      const listStyle = { listStyle: type };

      if (!isInList(editor as ListsEditor)) {
        const switchListType: ListType = (format === NUMBERED_LIST ? ListType.ORDERED : ListType.UNORDERED);
        const isListStyleActive = isListTypeActive(editor, format, type);
        wrapInList(editor as ListsEditor, switchListType, { ...listStyle, type: format });

        if (isActive && isListStyleActive) return;

        switchList = true;
      }

      if (isInList(editor as ListsEditor) || switchList) {
        const topLevelListNode = SlateEditor.above(editor, {
          match: (n) => SlateElement.isElement(n) && LIST_TYPES.includes(n.type),
          mode: 'highest',
        });
        const pathToApplyChanges = topLevelListNode ? { at: topLevelListNode[1] } : {};

        if (isActive) {
          const isListStyleActive = isListTypeActive(editor, format, type);
          if (format === NUMBERED_LIST) {
            if (!switchList && isListStyleActive) {
              unwrapList(editor as ListsEditor);
              return;
            }
            setGeneralListType(editor as ListsEditor, format, listStyle);
            return;
          }
        } else { // !isActive
          const switchListType = (format === NUMBERED_LIST ? BULLETED_LIST : NUMBERED_LIST);
          const switchListItemsType = (format === NUMBERED_LIST ? LIST_ITEM : NUMBERED_LIST_ITEM);
          setGeneralListType(editor as ListsEditor, format as ListFormatType, listStyle, switchListType);

          Transforms.setNodes(
            editor,
            { type: listItemTypeToApply },
            {
              ...pathToApplyChanges,
              match: (node) => (
                !SlateEditor.isEditor(node)
                && SlateElement.isElement(node)
                && node.type === switchListItemsType
              ),
              mode: 'all',
            },
          );
          return;
        }
      }
    }

    unwrapList(editor as ListsEditor);

    const listNodesFormat = isList ? listItemTypeToApply : format;
    Transforms.setNodes(editor, {
      type: isActive ? PARAGRAPH : listNodesFormat,
    });

    if (!isActive && isList) {
      const { selection } = editor;
      const isExpanded = selection && Range.isExpanded(selection);

      if (isExpanded && isInTable(editor)) {
        const match: any = SlateEditor.nodes(editor, {
          match: (node) => (
            SlateElement.isElement(node)
            && [LIST_ITEM, NUMBERED_LIST_ITEM].includes(node.type)
          ),
        });
        for (const cell of match) {
          Transforms.setSelection(editor, SlateEditor.range(editor, cell[1]));
          Transforms.insertNodes(editor, { type: format, children: [cell[0]] }, { at: cell[1] });
          Transforms.removeNodes(editor, { at: Path.next(cell[1]) });
        }
        return;
      }

      const listStyle = (isList && format === NUMBERED_LIST) ? { listStyle: type as ListStyleViewType } : {};
      const block = { type: format, children: [], ...listStyle };
      Transforms.wrapNodes(editor, block, {
        split: false,
        mode: 'highest',
        match: (node) => (
          !SlateEditor.isEditor(node)
          && SlateElement.isElement(node)
          && node?.type !== SECTION
        ),
      });

      // clean up <section> wrapped by <ul> or <ol> if we select a few sections content
      Transforms.unwrapNodes(editor, {
        match: (node) => (
          SlateElement.isElement(node)
          && LIST_TYPES.includes(node.type)
          && node.children && (
            SlateElement.isElement(node.children[0])
            && node.children[0].type === SECTION
          )
        ),
        split: false,
      });
    }
  });
};