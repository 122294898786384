import {
  CREATE_COLLECTION_EXECUTED_PUBLIC_LINK,
  GET_COLLECTION_EXECUTED_DETAILS,
  SET_ALL_COLLECTIONS_EXECUTED,
  SET_COLLECTION_EXECUTED_DETAILS,
  SET_COLLECTION_EXECUTED_PUBLIC_LINK,
  UPDATE_COLLECTION_EXECUTED,
} from 'store/actions/actionTypes';

export const getCollectionExecutedDetails = (id: number) => ({
  type: GET_COLLECTION_EXECUTED_DETAILS,
  payload: id,
});

export const setCollectionExecutedDetails = (collectionExecutedDetails: any | null) => ({
  type: SET_COLLECTION_EXECUTED_DETAILS,
  payload: collectionExecutedDetails,
});

export const updateCollectionExecutedDetails = (collectionExecutedDetails: any) => ({
  type: UPDATE_COLLECTION_EXECUTED,
  payload: collectionExecutedDetails,
});

export const createCollectionExecutedPublicLink = (body: any) => ({
  type: CREATE_COLLECTION_EXECUTED_PUBLIC_LINK,
  payload: body,
});

export const setCollectionExecutedPublicLink = (linkData: any) => ({
  type: SET_COLLECTION_EXECUTED_PUBLIC_LINK,
  payload: linkData,
});

export const setAllCollectionsExecuted = (collectionsExecuted: any) => ({
  type: SET_ALL_COLLECTIONS_EXECUTED,
  payload: collectionsExecuted,
});