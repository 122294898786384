import { useEffect } from 'react';

const useBodyOverflow = (overflow: string): void => {
  useEffect(() => {
    document.body.style.overflow = overflow;
    return () => {
      document.body.style.removeProperty('overflow');
    };
  });
};

export default useBodyOverflow;