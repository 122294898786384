import { FC } from 'react';

import ROUTES from 'constants/routes';
import CollectionIcon from 'svg/GlobalNavigationIcons/CollectionIcon';
import DocumentIcon from 'svg/GlobalNavigationIcons/DocumentIcon';
import FormIcon from 'svg/GlobalNavigationIcons/FormIcon';
import PdfIcon from 'svg/GlobalNavigationIcons/PdfIcon';

// TODO link and globalLink paths have to be required
export interface IGlobalNavigation {
  title: string;
  linkTitle: string;
  linkPath?: string;
  documentLinkTitle?: string;
  documentLinkPath?: string;
  Icon: FC;
  globalPath: string;
}

export const globalNavigationDocuments: IGlobalNavigation[] = [
  {
    title: 'Documents',
    linkTitle: 'Document Template',
    linkPath: ROUTES.DOCUMENTS_EDITOR,
    documentLinkTitle: 'Single Document',
    documentLinkPath: ROUTES.DOCUMENTS_EXECUTED_EDITOR_SINGLE,
    Icon: DocumentIcon,
    globalPath: ROUTES.DOCUMENTS,
  },
];

export const globalNavigationPdfs: IGlobalNavigation[] = [
  {
    title: 'PDFs',
    linkTitle: 'PDF Template',
    linkPath: ROUTES.PDF_EDITOR,
    documentLinkTitle: 'Single PDF',
    documentLinkPath: ROUTES.PDF_EXECUTED_EDITOR_SINGLE,
    Icon: PdfIcon,
    globalPath: ROUTES.PDF_LIST,
  },
];

export const globalNavigationForms: IGlobalNavigation[] = [
  {
    title: 'Forms',
    linkTitle: 'Form Template',
    linkPath: ROUTES.FORM_EDITOR,
    documentLinkTitle: 'Single Form',
    documentLinkPath: ROUTES.FORM_EXECUTED_EDITOR_SINGLE,
    Icon: FormIcon,
    globalPath: ROUTES.FORMS,
  },
];

export const globalNavigationCollections: IGlobalNavigation[] = [
  {
    title: 'Collections',
    linkTitle: 'Collection',
    linkPath: ROUTES.COLLECTIONS_EDITOR,
    Icon: CollectionIcon,
    globalPath: ROUTES.COLLECTIONS,
  },
];