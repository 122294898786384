import { FC } from 'react';

interface IDocumentInfoRow {
  rowInfo: {
    title: string;
    value: string | string[];
  }
}

const DocumentInfoRow: FC<IDocumentInfoRow> = ({
  rowInfo,
}) => (
  rowInfo.value ? (
    <div className="row document-info-row">
      <div className="col-6 p-0">
        {rowInfo.title}
      </div>
      {
        typeof rowInfo.value === 'string'
          ? (
            <div className="col-6">
              {rowInfo.value}
            </div>
          )
          : (
            <div className="col-6">
              {rowInfo.value.map((row: string) => (
                <div className="col-12" key={row}>
                  {row}
                </div>
              ))}
            </div>
          )
      }
    </div>
  ) : null
);

export default DocumentInfoRow;