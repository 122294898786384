import { FC } from 'react';

import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import LogoutButton from 'components/AccountSettings/LogoutButton';
import ROUTES from 'constants/routes';
import USER_MENU_CONFIG, { PROFILE_SECTIONS } from 'constants/UserMenu';
import { useRolePermission } from 'hooks/useRolePermission';
import { RootStateType } from 'store/reducers';
import { isNavItemActive } from 'utils/routeHelpers';

const Menu: FC = () => {
  const {
    permissionUsingGroupManagement,
    isUserReadOnly,
    permissionUsingCompanyBranding,
  } = useRolePermission();
  const profileInfo = useSelector((state: RootStateType) => state.profile.profileInfo);

  return (
    <div className="settings-menu h-100">
      <h2 className="settings-menu__title">
        My Account
      </h2>
      <nav className="settings-menu__menu">
        {
          USER_MENU_CONFIG.map(({ name, path, IconComponent, permissions, isHelpButton }) => {
            if (isHelpButton) return null;
            if (
              isUserReadOnly
              && (path !== ROUTES.PROFILE && name !== PROFILE_SECTIONS.LOGOUT)
            ) return null;
            if (!!permissions?.length && !permissions.includes(profileInfo?.userRole)) return null;
            if (path === ROUTES.GROUP_MANAGEMENT && !permissionUsingGroupManagement) return null;
            if (path === ROUTES.BRANDING && !permissionUsingCompanyBranding) return null;
            if (path) {
              return (
                <NavLink
                  key={name}
                  to={path}
                  activeClassName="is-active"
                  isActive={(match, location) => isNavItemActive(name, match, location)}
                >
                  {IconComponent && <IconComponent />}
                  <span className="title">{name}</span>
                </NavLink>
              );
            }
            if (!path && name === PROFILE_SECTIONS.LOGOUT) {
              return (
                <div key={name}>
                  <hr />
                  <LogoutButton icon={IconComponent} title={name} />
                </div>
              );
            }
            return (
              <p className="title">{name}</p>
            );
          })
        }
      </nav>
    </div>
  );
};

export default Menu;