import { ChangeEvent, FC, useState } from 'react';

import { Cropper } from 'react-cropper';
import { useDispatch } from 'react-redux';
import { Form } from 'reactstrap';

import SettingsRow from 'components/AccountSettings/Branding/SettingsRow';
import ModalWindow from 'components/Modals';
import { ACCEPT_LOGO_TYPES, CLEAR, CLOSE, DELETE, UPLOAD_IMAGE } from 'constants/general';
import { deleteCompanyLogo, uploadCompanyLogo } from 'store/actions/companyBranding';
import AttachmentIcon from 'svg/AttachmentIcon';
import { getCompanyLogoModalStatus } from 'utils/CompanyBranding/logoHelpers';

const LOGO_IS_EMPTY = 'The form is empty. Please add your logo.';
const PLEASE_DELETE_LOGO = 'If you want to upload a new logo, please delete a previous one.';

interface ILogoInput {
  logoPath: string | null;
}

const LogoInput: FC<ILogoInput> = ({
  logoPath,
}) => {
  const dispatch = useDispatch();
  const [logoError, setLogoError] = useState<string>('');
  const [logoUrl, setLogoUrl] = useState<string>('');
  const [fileType, setFileType] = useState<string>('');
  const [cropper, setCropper] = useState<Cropper>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const openLogoModal = () => {
    setIsModalOpen(true);
  };

  const CLOSE_BUTTON_TITLE = getCompanyLogoModalStatus(logoPath, logoUrl);

  const cancelModalHandler = () => {
    const status = CLOSE_BUTTON_TITLE;
    setLogoError('');
    switch (status) {
      case CLOSE:
        setIsModalOpen(false);
        break;
      case CLEAR:
        setLogoUrl('');
        break;
      case DELETE:
        dispatch(deleteCompanyLogo());
        setLogoUrl('');
        break;
      default:
        break;
    }
  };

  const getNewUrl = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const currentFileType = file.type;
      if (ACCEPT_LOGO_TYPES.includes(currentFileType)) {
        setLogoUrl(URL.createObjectURL(file));
        setFileType(currentFileType);
        setLogoError('');
      }
    }
  };

  const uploadFile = async () => {
    if (cropper) {
      const croppedCanvasUrl = cropper.getCroppedCanvas().toDataURL();
      const file: File = await fetch(croppedCanvasUrl)
        .then((res) => res.blob())
        .then((blob) => new File([blob], String(new Date().getTime()), { type: fileType }));

      dispatch(uploadCompanyLogo(file));
      setLogoError('');
      setLogoUrl('');
      setIsModalOpen(false);
    }
  };

  const uploadLogoButtonHandler = () => {
    if (logoPath) {
      return setLogoError(PLEASE_DELETE_LOGO);
    }
    if (cropper && logoUrl) {
      uploadFile();
    } else {
      setLogoError(LOGO_IS_EMPTY);
    }
  };

  const closeModalHandler = () => {
    setLogoError('');
    setIsModalOpen(false);
  };

  return (
    <>
      <SettingsRow label="Company logo">
        <button
          className={logoPath ? 'branding-logo-image' : 'branding-logo-button'}
          type="button"
          onClick={openLogoModal}
        >
          {
            logoPath
              ? (
                <img src={logoPath} alt="Company Logo" width="150" height="150" />
              )
              : (
                <>
                  <AttachmentIcon />
                  {UPLOAD_IMAGE}
                </>
              )
          }
        </button>
      </SettingsRow>
      <ModalWindow
        isOpen={isModalOpen}
        onClose={closeModalHandler}
        onCancel={cancelModalHandler}
        onButtonClick={uploadLogoButtonHandler}
        title="Upload Company Logo"
        buttonTitle="Upload Image"
        cancelTitle={CLOSE_BUTTON_TITLE}
      >
        {
          logoPath
            ? (
              <div className="branding-logo-modal">
                <img className="branding-logo-preview" src={logoPath} alt="Company Logo" />
              </div>
            )
            : (
              <Form>
                {
                  !logoUrl
                    ? (
                      <label className="branding-logo-button branding-logo-upload-button">
                        <AttachmentIcon />
                        {UPLOAD_IMAGE}
                        <input
                          type="file"
                          accept={ACCEPT_LOGO_TYPES.join(', ')}
                          onChange={getNewUrl}
                        />
                      </label>
                    )
                    : (
                      <Cropper
                        src={logoUrl}
                        style={{ height: '100%', width: '100%' }}
                        initialAspectRatio={1}
                        aspectRatio={1}
                        minCropBoxHeight={100}
                        minCropBoxWidth={100}
                        guides={false}
                        checkOrientation={false}
                        onInitialized={(instance) => {
                          setCropper(instance);
                        }}
                      />
                    )
                }
              </Form>
            )
        }
        {
          logoError && (
            <p className="branding-logo-error">{logoError}</p>
          )
        }
      </ModalWindow>
    </>
  );
};

export default LogoInput;