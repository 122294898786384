import { Component, ReactNode } from 'react';

import Intro from 'components/Intro/Intro';
import ROUTES from 'constants/routes';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    return hasError
      ? (
        <Intro
          title="Oops, something went wrong..."
          description="Please go to dashboard or contact the site administrator"
          descriptionWidth="large"
          descriptionMobileWidth="small"
          styles={{
            description: { margin: '0px', textAlign: 'center' },
          }}
        >
          <a href={ROUTES.DASHBOARD} className="error-boundary_link">Dashboard</a>
        </Intro>
      )
      : children;
  }
}

export default ErrorBoundary;