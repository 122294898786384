import cn from 'classnames';
import Button from 'components/Base/Button';
import SimpleSpinner from 'components/Base/SimpleSpinner';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import { UNDERLINE_SYMBOL } from 'constants/editors';
import { useStripeSubscription } from 'hooks/payment/stripe';
import { capitalizeString } from 'utils/stringsHelpers';

const StripeCurrentPlan = () => {
  const {
    subscription,
    onCancelSubscription,
    onRenewSubscription,
    isLoading,
    isConfirmModalOpen,
    onCancelHandler,
    onConfirmHandler,
  } = useStripeSubscription();

  if (isLoading) {
    return <SimpleSpinner />;
  }

  return subscription
    ? (
      <>
        <div key={subscription.productName}>
          <div className="stripe-subscription__title mb-2">
            <h2>
              {subscription.productName}
            </h2>
            <span
              className={cn(
                'stripe-subscription__tag',
                {
                  [`stripe-subscription__tag--${subscription.status}`]: subscription.status
                    && !subscription.cancelAtPeriodEnd,
                  'stripe-subscription__tag--canceled': subscription.cancelAtPeriodEnd,
                },
              )}
            >
              {subscription.cancelAtPeriodEnd ? 'canceled' : capitalizeString(subscription.status, UNDERLINE_SYMBOL)}
            </span>
          </div>
          {
            !subscription.isFreeTier && <h3>{subscription.priceInfo}</h3>
          }
          <p>
            {
              subscription.cancelAtPeriodEnd && <>Your subscription will be canceled on {subscription.renewalDate}</>
            }
            {
              subscription.status === 'trialing' && !subscription.cancelAtPeriodEnd
                && <>Trial ends on {subscription.renewalDate}</>
            }
            {
              !(subscription.cancelAtPeriodEnd || subscription.isFreeTier || subscription.status === 'trialing')
                && <>Your plan renews on {subscription.renewalDate}</>
            }
          </p>
        </div>
        {
          !(subscription.isFreeTier || subscription.cancelAtPeriodEnd)
            && (
              <Button
                type="button"
                onClick={onCancelSubscription}
                classes="outline outlined-with-border me-3"
              >
                Cancel plan
              </Button>
            )
        }
        {
          subscription.cancelAtPeriodEnd
            && (
              <Button
                type="button"
                onClick={onRenewSubscription}
                classes="outline outlined-with-border me-3"
              >
                Renew canceled plan
              </Button>
            )
        }
        <ConfirmationModal
          description={(
            <>
              <p>Attention, your subscription will be canceled on {subscription.renewalDate}</p>
              <p>
                Do you agree to cancel your current plan?
              </p>
            </>
          )}
          isOpen={isConfirmModalOpen}
          onCancel={onCancelHandler}
          onConfirm={onConfirmHandler}
        />
      </>
    )
    : <h2 className="mb-4">Free Tier</h2>;
};

export default StripeCurrentPlan;