import { FC } from 'react';

interface IDropdownButtonProps {
  title: string;
  onClickHandler: () => void;
  isDisabled?: boolean;
}

const DropdownButton: FC<IDropdownButtonProps> = ({
  title,
  onClickHandler,
  isDisabled = false,
}) => (
  <button
    type="button"
    className="dropdown-item editor-header-dropdown-item"
    onClick={onClickHandler}
    disabled={isDisabled}
  >
    {title}
  </button>
);

export default DropdownButton;