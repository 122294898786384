import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from 'components/Base/Button';
import ModalWindow from 'components/Modals';
import InnerComponentsWrapper from 'components/SendDocuments/InnerComponentsWrapper';
import ROUTES from 'constants/routes';
import { cleanAssignments } from 'store/actions/editorSlate';
import { setCreatedDocumentId } from 'store/actions/publicPages';
import { clearStore, sendDocumentsViaEmail, setSigners } from 'store/actions/sendDocuments';
import { setTemplateDetails } from 'store/actions/userData';
import { RootStateType } from 'store/reducers';
import CloseIcon from 'svg/CloseIcon';
import { INNER_PAGES, SignersInfoType, SignerType } from 'types/SendDocuments';
import { generateSignersFromAssignments, validateSignersArray } from 'utils/sendDocumentsHelpers';

const SendDocuments = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { initialLocation, documentsInfo } = useSelector((state: RootStateType) => state.sendDocuments);
  const profileInfo = useSelector((state: RootStateType) => state.profile.profileInfo);
  const assignments = useSelector((state: RootStateType) => state.editorSlate.assignments);

  const managerInfo: SignersInfoType = {
    type: SignerType.MANAGER,
    position: 0,
    firstName: profileInfo.first_name,
    lastName: profileInfo.last_name,
    email: profileInfo.email,
  };

  const [currentPage, setCurrentPage] = useState<INNER_PAGES>(INNER_PAGES.FIRST_PAGE);
  const [signersArray, setSignersArray] = useState<SignersInfoType[]>([managerInfo]);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);

  useEffect(() => {
    if (assignments) {
      const signersArray: SignersInfoType[] = generateSignersFromAssignments(assignments, managerInfo);
      setSignersArray(signersArray);
    }

    return () => {
      dispatch(clearStore());
      dispatch(setCreatedDocumentId(0));
      dispatch(cleanAssignments());
      dispatch(setTemplateDetails(null));
      window.history.pushState(null, 'deprecatedParam', initialLocation || ROUTES.DASHBOARD);
    };
  }, []);

  const redirectInitialLocation = () => {
    history.push(initialLocation || ROUTES.DASHBOARD);
  };

  const handlerNextButton = () => {
    if (currentPage === INNER_PAGES.FIRST_PAGE) {
      if (validateSignersArray(setSignersArray)) {
        dispatch(setSigners(signersArray));
        setCurrentPage((prev) => prev + 1);
      }
    }
    if (currentPage === INNER_PAGES.SECOND_PAGE) {
      dispatch(setCreatedDocumentId(0));
      dispatch(sendDocumentsViaEmail());
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlerCloseButton = () => {
    if (currentPage === INNER_PAGES.THIRD_PAGE) {
      redirectInitialLocation();
    } else {
      setConfirmModal(true);
    }
  };

  return (
    <div className="container">
      <div className="row editor-header">
        <div className="col-1" />
        <div className="col-10 text-center">
          Send documents
        </div>
        <div className="col-1 d-flex justify-content-end">
          <Button
            classes="button-simple"
            variant="text"
            size="small"
            startIcon={<CloseIcon />}
            onClick={handlerCloseButton}
          >
            Close
          </Button>
        </div>
      </div>
      <div className="row editor-main editor-main--send text-center pb-4">
        <div className="col-6 mx-auto">
          <div>
            <InnerComponentsWrapper
              innerPage={currentPage}
              signersArray={signersArray}
              setSignersArray={setSignersArray}
            />
          </div>
          <div
            className={`d-flex justify-content-end ${
              currentPage === INNER_PAGES.THIRD_PAGE ? 'justify-content-center' : ''
            }`}
          >
            {
              currentPage === INNER_PAGES.SECOND_PAGE ? (
                <Button
                  classes="button-simple"
                  variant="text"
                  size="small"
                  onClick={() => setCurrentPage((prev) => prev - 1)}
                >
                  Back
                </Button>
              ) : null
            }
            {
              [INNER_PAGES.FIRST_PAGE, INNER_PAGES.SECOND_PAGE].includes(currentPage) ? (
                <Button
                  classes="button-contained-pink"
                  onClick={handlerNextButton}
                  disabled={!documentsInfo.length}
                >
                  {currentPage === INNER_PAGES.FIRST_PAGE ? 'Next' : 'Send'}
                </Button>
              ) : null
            }
          </div>
        </div>
      </div>
      <ModalWindow
        isOpen={confirmModal}
        onClose={() => setConfirmModal(false)}
        onButtonClick={redirectInitialLocation}
        title="No information will be sent"
        buttonTitle="Yes, I am sure."
        cancelTitle="Continue Sending"
      >
        <div>
          Are you sure you want to cancel sending this document? No information will be sent.
        </div>
      </ModalWindow>
    </div>
  );
};

export default SendDocuments;