import { useSelector } from 'react-redux';
import { Progress } from 'reactstrap';

import { FORM_TAB_ID } from 'constants/tabs';
import { RootStateType } from 'store/reducers';
import { ICompanyBranding } from 'types/CompanyBranding';
import { IPublicPageDocumentStructure } from 'types/PublicPage';

import styled from '@emotion/styled';
import vars from 'variables.module.scss';

const StyledProgress = styled(Progress)`
  .progress-bar {
    background: ${(props) => props.cssModule?.backgroundColor || 'inherit'};
  }
`;

const ProgressBar = () => {
  const { styleJSON }: ICompanyBranding = useSelector((state: RootStateType) => state.companyBranding);
  const currentDocument: IPublicPageDocumentStructure | null = useSelector((state: RootStateType) => (
    state.publicPages.currentDocument
  ));

  if (!currentDocument) return null;

  const visibilityClass = currentDocument?.activeEditorTab === FORM_TAB_ID ? 'opacity-100' : 'opacity-0';

  return (
    <StyledProgress
      className={visibilityClass}
      cssModule={{ backgroundColor: styleJSON?.mainBrandColor || vars.brandPrimary }}
      max={currentDocument.totalIndexSlides}
      value={currentDocument.currentSlideIndex}
    />
  );
};

export default ProgressBar;