import { FC } from 'react';

interface ISimpleSpinnerParams {
  small?: boolean;
}

const SimpleSpinner: FC<ISimpleSpinnerParams> = ({
  small = false,
}) => (
  <div className="d-flex justify-content-center">
    <div
      className="spinner-border text-primary"
      data-dz-spinner
      role="status"
      style={small ? { width: '26px', height: '26px' } : {}}
    />
  </div>
);

export default SimpleSpinner;