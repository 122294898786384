import {
  DOMAIN,
  URL_MANAGER_DOCUMENT,
  URL_MANAGER_TEMPLATE,
  URL_PUBLIC_COLLECTION,
  URL_PUBLIC_DOCUMENT,
  URL_PUBLIC_TEMPLATE,
} from 'constants/general';
import { SOMETHING_WENT_WRONG_MESSAGE } from 'constants/generalErrors';
import { ALL_BOARDS, NO_BOARDS } from 'constants/mondayComIntegration';
import ROUTES from 'constants/routes';
import { MondayComIntegrationObjectType } from 'types/mondayCom/mondayComBoard';
import { isRecipientAssignment } from 'utils/assignmentsHelpers';

export const getMainRoute = (publicRoute: string, assignment: string) => {
  if (isRecipientAssignment(assignment)) {
    return publicRoute;
  }
  return publicRoute === URL_PUBLIC_TEMPLATE ? URL_MANAGER_TEMPLATE : URL_MANAGER_DOCUMENT;
};

const createPublicLink = (
  shareLink: string,
  mainRoute: string,
  boardQueryParameter: string | null = null,
  isRecipientLink: boolean = false,
) => {
  const mondayComBoard = boardQueryParameter && isRecipientLink ? `&board=${btoa(boardQueryParameter)}` : '';
  if (shareLink) {
    return `${DOMAIN}/${mainRoute}/${shareLink}${mondayComBoard}`;
  }
  return SOMETHING_WENT_WRONG_MESSAGE;
};

export const getShareLinkUrl = (fullShareLink: string): string => {
  const urlArray = fullShareLink.split('/');
  if (urlArray.includes(URL_PUBLIC_TEMPLATE)) return URL_PUBLIC_TEMPLATE;
  if (urlArray.includes(URL_PUBLIC_DOCUMENT)) return URL_PUBLIC_DOCUMENT;
  if (urlArray.includes(URL_PUBLIC_COLLECTION)) return URL_PUBLIC_COLLECTION;
  if (urlArray.includes(ROUTES.PUBLIC_COLLECTION_EXECUTED)) return ROUTES.PUBLIC_COLLECTION_EXECUTED;
  return '';
};

export const getTargetMondayComBoardId = (
  mondayComIntegration: MondayComIntegrationObjectType[],
  boardName: string | null,
) => {
  if (!boardName) return NO_BOARDS;
  if ([NO_BOARDS, ALL_BOARDS].includes(boardName)) return boardName;

  const targetBoard = mondayComIntegration.find((boardObject) => boardObject.board.name === boardName);
  if (targetBoard) {
    return targetBoard.board.id;
  }
};

export default createPublicLink;