import { CSSProperties, FC, KeyboardEvent, MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { arrayMove, SortableContainer, SortEvent } from 'react-sortable-hoc';

import { createEditor, Editor as SlateEditor, Element as SlateElement, Transforms } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, RenderElementProps, RenderLeafProps, Slate, withReact } from 'slate-react';

import cn from 'classnames';
import PdfRenderer from 'components/Editor/components/pdfRenderer/PdfRenderer';
import Element from 'components/Editor/components/RenderElement';
import Leaf from 'components/Editor/components/RenderLeaf';
import Toolbar from 'components/Editor/components/Toolbar';
import { withCheckList } from 'components/Editor/editor-custom-plugins/withCheckList';
import { withCopyPaste } from 'components/Editor/editor-custom-plugins/withCopyPaste';
import withDeleteParagraphForward from 'components/Editor/editor-custom-plugins/withDeleteParagraphForward';
import withFields from 'components/Editor/editor-custom-plugins/withFields';
import { withForms } from 'components/Editor/editor-custom-plugins/withForms';
import { withHeaders } from 'components/Editor/editor-custom-plugins/withHeaders';
import { withImages } from 'components/Editor/editor-custom-plugins/withImage';
import { withLinks } from 'components/Editor/editor-custom-plugins/withLinks';
import { withListsPlugin } from 'components/Editor/editor-custom-plugins/withLists';
import { withSections } from 'components/Editor/editor-custom-plugins/withSections';
import withTableQuestion from 'components/Editor/editor-custom-plugins/withTableQuestion';
import { withTables } from 'components/Editor/editor-custom-plugins/withTables';
import { withUpdateNodes } from 'components/Editor/editor-custom-plugins/withUpdateNodes';
import DocumentEditorDevTools from 'components/Editor/EditorDevTools/DocumentEditorDevTools';
import AddSectionButton from 'components/FormConstructor/Buttons/FormAddSectionButton';
import FormTypeElement from 'components/FormConstructor/Elements/RenderFormTypeElement';
import ScrollToBottom from 'components/ScrollToBottom';
import { PARAGRAPH } from 'constants/editors';
import useBodyOverflow from 'hooks/useBodyOverflow';
import { useRolePermission } from 'hooks/useRolePermission';
import useRootStyles from 'hooks/useRootStyles';
import { sortFormDocumentSections, updateTemplateSections } from 'store/actions/template';
import { RootStateType } from 'store/reducers';
import { IEditorProps, ISortIndex } from 'types/Editor';
import { PageSettingsType } from 'types/PageSettingsType';
import { shouldCancelStartOfSorting } from 'utils/arrayMove';
import composePlugins from 'utils/composePlugins';
import { decorate as editorDecorate } from 'utils/editorHelpers';
import { onEditorKeyDown } from 'utils/hotkeysHelpers';
import { removeDraggableSectionStyles, setDraggableSectionStyles } from 'utils/ManagerEditor/formEditorHelpers';
import { getMarginsForPageInInches, getPageSettings } from 'utils/PageSettings';

const SortableEditor = SortableContainer(Editable);

const Editor: FC<IEditorProps> = (props) => {
  const dispatch = useDispatch();
  useBodyOverflow('hidden');
  const styles: CSSProperties = useMemo(() => ({
    'overflow-y': 'hidden',
    display: 'grid',
    'grid-template-rows': 'auto 1fr',
    height: '100vh',
  }), []);
  useRootStyles(styles);

  const {
    content,
    onChange,
    previewMode,
    search,
    styledSections,
    viewForms = false,
    modal = false,
    readOnlyMode = false,
    visibleEditorDevTools = true,
    isTemplate = false,
    isCompletedModal = false,
  } = props;

  const plugins = [
    withUpdateNodes,
    withDeleteParagraphForward,
    withListsPlugin,
    withForms,
    withFields,
    withCheckList,
    withLinks,
    withImages,
    withTableQuestion,
    withTables,
    withCopyPaste,
    withHistory,
    withReact,
    withHeaders,
  ];

  const scrollableNode: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);
  const shouldRenderFormActionButtons: boolean = viewForms && visibleEditorDevTools && isTemplate;
  const { pdfFileLink } = useSelector((state: RootStateType) => state.editorSlate);
  const { permissionUsingSectionsLight } = useRolePermission();
  if (permissionUsingSectionsLight) {
    plugins.push(withSections);
  }
  const { sections } = useSelector((state: RootStateType) => state.template);
  const renderElement = useCallback((props: RenderElementProps) => {
    if (viewForms) {
      return (
        <FormTypeElement
          {...props}
          readOnlyMode={readOnlyMode}
          visibleButtons={visibleEditorDevTools && isTemplate}
        />
      );
    }

    return (
      <Element
        {...props}
        styledSections={styledSections}
        readOnlyMode={readOnlyMode}
      />
    );
  }, [readOnlyMode, styledSections, viewForms, visibleEditorDevTools]);
  const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, []);
  const editorRef: MutableRefObject<SlateEditor | undefined> = useRef();
  if (!editorRef.current) {
    editorRef.current = composePlugins(plugins, withReact(createEditor()), dispatch);
  }
  const editor: SlateEditor = editorRef.current;
  const decorate = useCallback(editorDecorate(search), [search, content]);
  const pageSettings: PageSettingsType | null = useSelector((state: RootStateType) => getPageSettings(state));
  const sectionPadding = getMarginsForPageInInches(pageSettings);

  useEffect(() => {
    if (permissionUsingSectionsLight) {
      if (content.length > 1 && !viewForms) {
        Transforms.select(editor, {
          anchor: { path: [0, 0, 0], offset: 0 },
          focus: { path: [0, 0, 0], offset: 0 },
        });
      }

      if (viewForms && sections.length !== content.length) {
        editor.selection = null;
      }
    }
  }, [sections]);

  useEffect(() => {
    if (
      !permissionUsingSectionsLight
      && content !== editor.children
      && content.length !== editor.children.length
    ) {
      editor.selection = null;
      editor.children = content;
    }
  }, [sections, content, permissionUsingSectionsLight]);

  const onKeyDown = (event: KeyboardEvent<HTMLElement>): void => {
    onEditorKeyDown(editor, event);
  };

  const shouldReloadComponent = (): boolean => {
    if (!readOnlyMode && !permissionUsingSectionsLight && content !== editor.children) {
      const editorLength = editor.children.length;
      if (editorLength === 0) {
        return false;
      }

      if (editorLength === 1) {
        const elementEditor: Partial<SlateElement> = editor.children[0];
        const elementContent: Partial<SlateElement> = content[0];
        if (elementEditor.type === PARAGRAPH) {
          return false;
        }
        if (elementEditor.type !== elementContent.type) {
          editor.selection = null;
        }
      }

      if (content.length !== editorLength) {
        return true;
      }
    }
    return false;
  };

  if (shouldReloadComponent()) return null;

  const handleSortEnd = ({ oldIndex, newIndex }: ISortIndex) => {
    // used only for Forms and prevents inside the shouldCancelStartOfSorting()
    if (editor) editor.selection = null;
    removeDraggableSectionStyles();
    const sortedSections = arrayMove(sections, oldIndex, newIndex)
      .map((section, index) => ({ ...section, position: index }));
    dispatch(updateTemplateSections(sortedSections));
    dispatch(sortFormDocumentSections(true));
  };

  const shouldCancelSorting = (event: SortEvent) => shouldCancelStartOfSorting(event, !viewForms || readOnlyMode);

  return (
    <Slate editor={editor} value={content} onChange={onChange}>
      {!readOnlyMode && <Toolbar />}
      <div className="d-flex justify-content-center">
        <div
          className="w-100"
          data-forms-scrollable={shouldRenderFormActionButtons ? 'forms-scrollable' : ''}
          ref={scrollableNode}
        >
          {
            previewMode
              ? (
                <div className="w-100 pdf-viewer-container editor-size">
                  <PdfRenderer fileLink={pdfFileLink} />
                </div>
              )
              : (
                <>
                  <div className={cn(
                    {
                      'pb-2': viewForms,
                      'py-4': !viewForms,
                    },
                  )}
                  >
                    <div
                      data-html-source="true"
                      className={cn({
                        'form-type-editor': viewForms,
                        'editor-editable editor-width': !viewForms,
                      })}
                      style={{
                        padding: viewForms ? 0 : sectionPadding,
                      }}
                    >
                      <SortableEditor
                        readOnly={readOnlyMode}
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                        placeholder={viewForms ? '' : 'Enter some rich text…'}
                        spellCheck
                        autoFocus
                        onKeyDown={onKeyDown}
                        decorate={decorate}
                        onSortStart={setDraggableSectionStyles}
                        onSortEnd={handleSortEnd}
                        shouldCancelStart={shouldCancelSorting}
                        hideSortableGhost={false}
                        lockAxis="y"
                        useDragHandle
                      />
                    </div>
                  </div>
                  {shouldRenderFormActionButtons && (
                    <ScrollToBottom
                      scrollableNode={scrollableNode}
                    >
                      {(scrollToBottom: () => void) => (
                        <AddSectionButton onClick={scrollToBottom} />
                      )}
                    </ScrollToBottom>
                  )}
                </>
              )
          }
        </div>
      </div>
      {
        !isCompletedModal && (
          <div className={`editor-navigator-wrapper right-0 ${visibleEditorDevTools ? '' : ' inactive'}`}>
            {(!modal && visibleEditorDevTools) ? <DocumentEditorDevTools isFormView={viewForms} /> : null}
          </div>
        )
      }
    </Slate>
  );
};

export default Editor;