import { FC, SVGAttributes } from 'react';

const ReadyIcon: FC<SVGAttributes<unknown>> = ({ fill = '#05D370' }) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 1.83334C5.94004 1.83334 1.83337 5.94001 1.83337 11C1.83337 16.06 5.94004 20.1667 11
      20.1667C16.06 20.1667 20.1667 16.06 20.1667 11C20.1667 5.94001 16.06 1.83334 11 1.83334ZM11
      18.3333C6.95754 18.3333 3.66671 15.0425 3.66671 11C3.66671 6.95751 6.95754 3.66668 11 3.66668C15.0425
      3.66668 18.3334 6.95751 18.3334 11C18.3334 15.0425 15.0425 18.3333 11 18.3333ZM15.2075 6.94834L9.16671
      12.9892L6.79254 10.6242L5.50004 11.9167L9.16671 15.5833L16.5 8.25001L15.2075 6.94834Z"
      fill={fill}
    />
  </svg>
);

export default ReadyIcon;