import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import { SUCCESS } from 'constants/api';
import {
  addStripePaymentMethod,
  changeStripePaymentMethod,
  deleteStripePaymentMethod,
  getStripePaymentMethods,
} from 'services/api/payment';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { ICardData } from 'types/payment/AddPayment';
import { IStripePaymentMethod, IStripePaymentMethodsResponse } from 'types/payment/Stripe';

const usePaymentMethods = () => {
  const [paymentMethods, setPaymentMethods] = useState<IStripePaymentMethod[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isMethodUpdating, setIsMethodUpdating] = useState<boolean>(false);
  const [isMethodAdding, setIsMethodAdding] = useState<boolean>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState<boolean>(false);
  const [currentPaymentMethodId, setCurrentPaymentMethodId] = useState<string | null>(null);

  const getPaymentMethods = async () => {
    setIsLoading(true);
    const response = await apiErrorHandler<IStripePaymentMethodsResponse>(getStripePaymentMethods);
    if (response.code === SUCCESS) {
      setPaymentMethods(response.data.paymentMethods);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getPaymentMethods();
  }, []);

  const onChangeDefaultPaymentMethod = async (id: string) => {
    setIsMethodUpdating(true);
    const response = await apiErrorHandler(changeStripePaymentMethod, id);

    if (response.code === SUCCESS) {
      const updatedPaymentMethods = paymentMethods.map((paymentMethod) => {
        if (paymentMethod.id === id) {
          return {
            ...paymentMethod,
            isDefault: true,
          };
        }
        return {
          ...paymentMethod,
          isDefault: false,
        };
      });

      setPaymentMethods(updatedPaymentMethods);
    }
    setIsMethodUpdating(false);
  };

  const deletePaymentMethod = async (id: string) => {
    setIsMethodUpdating(true);
    const response = await apiErrorHandler(deleteStripePaymentMethod, id);

    if (response.code === SUCCESS) {
      const updatedPaymentMethods = paymentMethods.filter((paymentMethod) => paymentMethod.id !== id);

      setPaymentMethods(updatedPaymentMethods);
    }
    setIsMethodUpdating(false);
  };

  const onDeletePaymentMethod = (id: string) => {
    setIsConfirmModalOpen(true);
    setCurrentPaymentMethodId(id);
  };

  const onCancelDeleteModalHandler = () => {
    setIsConfirmModalOpen(false);
  };

  const onConfirmDeleteModalHandler = () => {
    if (currentPaymentMethodId) {
      setIsConfirmModalOpen(false);
      deletePaymentMethod(currentPaymentMethodId);
    }
  };

  const onAddPaymentMethod = () => {
    setIsPaymentModalOpen(true);
  };

  const onCancelAddPaymentMethodModalHandler = () => {
    setIsPaymentModalOpen(false);
  };

  const onConfirmAddPaymentMethodHandler = async (cardData: ICardData) => {
    setIsMethodAdding(true);
    const response = await apiErrorHandler(addStripePaymentMethod, cardData);

    if (response.code === SUCCESS) {
      toast.success('Hooray! A new payment method has been added!');
    }

    setIsMethodAdding(false);
  };

  return {
    paymentMethods,
    isLoading,
    isMethodUpdating,
    isMethodAdding,
    isConfirmModalOpen,
    isPaymentModalOpen,
    onAddPaymentMethod,
    onChangeDefaultPaymentMethod,
    onDeletePaymentMethod,
    onCancelDeleteModalHandler,
    onConfirmDeleteModalHandler,
    onCancelAddPaymentMethodModalHandler,
    onConfirmAddPaymentMethodHandler,
  };
};

export default usePaymentMethods;