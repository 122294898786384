import { cloneElement, FC, useState } from 'react';

import ExpandMoreLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIconIcon from '@mui/icons-material/ExpandMore';

interface IShowMoreButton {
  data: JSX.Element[];
  numberOfItemsToDisplay?: number;
}

const ShowMoreButton: FC<IShowMoreButton> = ({
  data,
  numberOfItemsToDisplay = 3,
}) => {
  const [showAll, setShowAll] = useState<boolean>(false);

  const shouldNotDisplayShowMore: boolean = data.length <= numberOfItemsToDisplay;
  const visibleData: JSX.Element[] = data.slice(0, numberOfItemsToDisplay);
  const dataToHide: JSX.Element[] = data.slice(numberOfItemsToDisplay).map((ChildToHide: JSX.Element) => (
    cloneElement(ChildToHide, { style: { display: showAll ? 'block' : 'none' } })
  ));

  const showAllHandler = () => {
    setShowAll(!showAll);
  };

  return (
    shouldNotDisplayShowMore
      ? (
        <>
          {data.map((node: JSX.Element) => node)}
        </>
      )
      : (
        <>
          {visibleData}
          {dataToHide}
          <div className="show-more-button" onClick={showAllHandler} role="button" tabIndex={-1}>
            <span className="show-more-button_text">Show {showAll ? 'less' : 'more'}</span>
            {
              showAll
                ? <ExpandMoreLessIcon className="show-more-button_icon" />
                : <ExpandMoreIconIcon className="show-more-button_icon" />
            }
          </div>
        </>
      )
  );
};

export default ShowMoreButton;