import { FC } from 'react';

import { useSelector } from 'react-redux';

import Forms from 'components/Editor/FormView/Forms';
import SlideWrapper from 'components/Editor/FormView/SlideWrapper';
import TitleAndDescription from 'components/Editor/FormView/TitleAndDescription';
import PdfFormView from 'components/PdfEditor/PdfFormView';
import { RECIPIENT_ASSIGNMENT } from 'constants/editors';
import { ShareViewModes } from 'constants/shareViewModes';
import { useSortedSections } from 'hooks/useSortedSections';
import { RootStateType } from 'store/reducers';
import { INewSection } from 'types/Editor';
import { IPublicPageDocumentStructure, PublicPageDataType } from 'types/PublicPage';
import { ITemplateSection } from 'types/redux';
import { getFieldsToShow } from 'utils/FormBuilder/formBuilderHelpers';
import {
  getIsCollectionFormBuilder,
  getIsDocumentWithFormBuilder,
  getIsFormBuilder,
  getIsPDFDocument,
} from 'utils/PublicPage/documentTypeChecker';
import { isCollectionType } from 'utils/typeChecker';

interface ISectionFormContentProps {
  section: ITemplateSection;
  sectionIndex: number;
  isPublicPage?: boolean;
  readOnlyMode?: boolean;
  isFormsView?: boolean;
  isFormTypeDocument?: boolean;
}

/**
 * SectionFormContent view for only Public pages
 */

const getFormBuilderSections = (
  sections: ITemplateSection[],
  isPublicPage: boolean = false,
  mainAssignment: string = RECIPIENT_ASSIGNMENT,
): INewSection[] => (
  sections
    .filter((section: ITemplateSection) => section.fields?.length)
    .map((section: ITemplateSection, index: number) => {
      const { fieldsToShow } = getFieldsToShow(section.fields || [], isPublicPage, mainAssignment);
      const newSections: INewSection = {
        key: Number(section.key) || 0,
        position: index,
        fields: fieldsToShow,
      };
      return newSections;
    })
);

const SectionFormContent: FC<ISectionFormContentProps> = ({
  section,
  sectionIndex,
  readOnlyMode = false,
  isPublicPage = false,
  isFormsView = true,
  isFormTypeDocument = false,
}) => {
  const data: PublicPageDataType = useSelector((state: RootStateType) => state.publicPages.data);
  const currentDocument: IPublicPageDocumentStructure = useSelector((state: RootStateType) => (
    state.publicPages.currentDocument
  ));
  const { main } = useSelector((state: RootStateType) => state.publicPages.structure);

  const isCollectionFormBuilder: boolean = getIsCollectionFormBuilder(data);
  const isFormBuilder: boolean = getIsFormBuilder(data.view_mode);
  const isPDFDocument: boolean = getIsPDFDocument(currentDocument.documentType);
  const isCurrentDocumentFormBuilderView: boolean = currentDocument.viewMode === ShareViewModes.SHARE_MODE_FORM_BUILDER;
  const isDocumentWithFormBuilderView: boolean = getIsDocumentWithFormBuilder(data.view_mode);

  const formBuilderSections: INewSection[] = isCollectionType(data)
    || isFormBuilder
    || (isDocumentWithFormBuilderView && isPDFDocument)
    ? getFormBuilderSections(currentDocument.slides, isPublicPage, main.mainAssignment)
    : [];

  const editorSections: INewSection[] = useSortedSections({
    isPublicPage,
    isFormsView,
    isFormPage: isFormTypeDocument,
    currentDocAssignment: main.mainAssignment,
  });

  let slideContent = null;

  if (isPublicPage && section.isPdfTemplate && !isDocumentWithFormBuilderView) {
    if (section.isEmptyPdfTemplate) {
      return null;
    }

    slideContent = (
      <PdfFormView
        pdfTemplateId={section.templateId}
        isPublicPage
        isMultiTemplate
      />
    );
  } else {
    const currentSectionForm = isCollectionFormBuilder
      || isCurrentDocumentFormBuilderView
      || ((isFormBuilder || isDocumentWithFormBuilderView) && isPDFDocument)
      ? formBuilderSections.find((el: INewSection) => el.key === section.key)
      : editorSections.find((el: INewSection) => el.key === section.key);

    if (!currentSectionForm?.fields.length && sectionIndex !== 0 && !section.description) return null;
    slideContent = currentSectionForm
      ? Boolean(currentSectionForm.fields.length) && (
        <Forms
          section={currentSectionForm}
          key={currentSectionForm.key || sectionIndex}
          isPublicPage={isPublicPage}
          readOnlyMode={readOnlyMode}
          isFormsView={isFormsView}
          isFormTypeDocument={isFormTypeDocument}
        />
      )
      : isPublicPage && (
        <TitleAndDescription
          title={section.name}
          description={section.description}
          isReadOnlyMode
          isPublicPage
        />
      );
  }

  if (!slideContent) return null;

  return (
    <SlideWrapper isPublicPage={isPublicPage}>
      {slideContent}
    </SlideWrapper>
  );
};

export default SectionFormContent;