import { FONT_TYPE_FORMAT } from 'constants/editors';
import { EnabledFontsType, StyleSchemaStructureType, StyleSchemaType } from 'types/BrandingColorSchema';

import vars from 'variables.module.scss';

export const enum StyleSchemaKeysEnum {
  HEADER_TEXT_FONT_FAMILY = 'headerTextFontFamily',
  PARAGRAPH_TEXT_FONT_FAMILY = 'paragraphTextFontFamily',
  MAIN_BRAND_COLOR = 'mainBrandColor',
  IS_REMOVE_BRANDING_ACTIVE = 'removeBrandingActive',
}

export const enum SchemaInputTypesEnum {
  SELECT= 'select',
  TEXT = 'text',
  COLOR = 'color',
  CHECKBOX = 'checkbox',
}

export const DEFAULT_COLOR_SCHEMA: StyleSchemaType = {
  [StyleSchemaKeysEnum.HEADER_TEXT_FONT_FAMILY]: vars.accentFont,
  [StyleSchemaKeysEnum.PARAGRAPH_TEXT_FONT_FAMILY]: vars.mainFont,
  [StyleSchemaKeysEnum.MAIN_BRAND_COLOR]: vars.brandPrimary,
  [StyleSchemaKeysEnum.IS_REMOVE_BRANDING_ACTIVE]: false,
};

const fonts: EnabledFontsType[] = [
  {
    type: vars.accentFont,
    label: vars.accentFontFamily,
  },
  {
    type: vars.mainFont,
    label: vars.mainFontFamily,
  },
  ...FONT_TYPE_FORMAT,
];

export const STYLE_SCHEMA_STRUCTURE: StyleSchemaStructureType<EnabledFontsType> = {
  [StyleSchemaKeysEnum.HEADER_TEXT_FONT_FAMILY]: {
    title: 'Header Text Font Family',
    type: SchemaInputTypesEnum.SELECT,
    options: fonts,
  },
  [StyleSchemaKeysEnum.PARAGRAPH_TEXT_FONT_FAMILY]: {
    title: 'Paragraph Text Font Family',
    type: SchemaInputTypesEnum.SELECT,
    options: fonts,
  },
  [StyleSchemaKeysEnum.MAIN_BRAND_COLOR]: {
    title: 'Main Brand Color',
    type: SchemaInputTypesEnum.COLOR,
  },
  [StyleSchemaKeysEnum.IS_REMOVE_BRANDING_ACTIVE]: {
    title: 'Remove UnicornForms branding from public pages',
    type: SchemaInputTypesEnum.CHECKBOX,
  },
};