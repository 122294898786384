import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { TEXT_FIELD_SUBTYPES } from 'constants/fieldSubtypes';
import { RootStateType } from 'store/reducers';

export const usePIIList = () => {
  const piiList = useSelector((state: RootStateType) => state.user?.piiList);

  const [allPiiList, setAllPiiList] = useState<{ [key: string]: boolean | string | number }[]>([]);

  useEffect(() => {
    const mappedPiiList = piiList?.map((item: { [key: string]: boolean | string | number }) => ({
      id: item.id,
      type: item.pii_name,
      label: item.title,
      disabled: !item.is_visible,
    })) || [];
    setAllPiiList(TEXT_FIELD_SUBTYPES.concat(mappedPiiList));
  }, [piiList]);

  return allPiiList;
};

export default usePIIList;