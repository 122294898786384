/* eslint-disable */
import { Editor as SlateEditor, Transforms } from "slate";
import { BlockTextAlignType } from "../types/Editor";

export const isTextAlignActive = (editor: SlateEditor, format: BlockTextAlignType) => {
  const [match]: any = SlateEditor.nodes(editor, {
    match: (node) => (
      SlateEditor.isBlock(editor, node) &&
      node.textAlign === format
    )
  });

  return !!match;
}

export const addPropertyTextAlign = (editor: SlateEditor, format: BlockTextAlignType) => {
  Transforms.setNodes(editor, { textAlign: format }, {
    match: (node) => SlateEditor.isBlock(editor, node),
  });
}