import { FC } from 'react';
import { InfoItem } from 'types/Misc';

const InfoSection: FC<InfoItem> = ({ label, text }) => (
  <div className="d-flex align-items-center" key={label}>
    <p className="label mb-1">
      {label}:&nbsp;
    </p>
    <p className="fs-14 gray-dk mb-1">{text}</p>
  </div>
);

export default InfoSection;
