import ENDPOINT from 'constants/endpoints';
import { ModalUserType } from 'types/userProfile';
import { axiosInstance } from 'utils/axiosInstance';

export const getUsers = async () => (
  axiosInstance.get(`${ENDPOINT.USERS}`)
);

export const createUser = async (data: ModalUserType) => (
  axiosInstance.post(`${ENDPOINT.USERS}`, data)
);

export const updateUser = async (userId: number, data: ModalUserType) => (
  axiosInstance.patch(`${ENDPOINT.USERS}/${userId}`, data)
);

export const deleteUser = async (userId: number) => (
  axiosInstance.delete(`${ENDPOINT.USER}/${userId}`)
);