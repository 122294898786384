import { ChangeEvent, FC, useEffect, useState } from 'react';

import { SIGNATURE_FIELD_INITIALS_VARIATION } from 'constants/signatures';

interface ITypeTab {
  typedSignature: string;
  changeTypedSignature: (value: string) => void;
  handlerClear: (value: boolean) => void;
  existingValue: string | undefined;
  signatureFieldVariation: string;
}

export const TypeTab: FC<ITypeTab> = ({
  changeTypedSignature,
  typedSignature,
  handlerClear,
  existingValue,
  signatureFieldVariation,
}) => {
  const [wasTouched, setWasTouched] = useState<boolean>(false);

  useEffect(() => () => {
    setWasTouched(false);
  }, []);

  return (
    <>
      <div className="position-absolute end-0 text-end mx-4 p-2">
        <button type="button" className="btn-clear mr-2" onClick={() => handlerClear(true)}>Clear</button>
      </div>
      <div className="form-signature-wrapper">
        <div className="form-signature-wrapper-typed">
          <input
            className="form-signature-wrapper-typed_input"
            placeholder={
              `Type ${signatureFieldVariation === SIGNATURE_FIELD_INITIALS_VARIATION ? 'initials' : 'signature'} here`
            }
            value={wasTouched ? typedSignature : existingValue}
            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
              changeTypedSignature(value);
              setWasTouched(true);
            }}
          />
          <div className="divider" />
        </div>
      </div>
    </>
  );
};

export default TypeTab;