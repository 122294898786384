import { FC, FormEvent } from 'react';

import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import Tooltip from 'components/Tooltip';
import { MAX_TEMPLATES_COUNT_RESTRICTION } from 'constants/accountSettings';
import { IGlobalNavigation } from 'constants/globalNavigation';
import ROUTES from 'constants/routes';
import { useRolePermission } from 'hooks/useRolePermission';
import { RootStateType } from 'store/reducers';

const GlobalNavigationItem: FC<IGlobalNavigation> = ({
  title,
  linkTitle,
  Icon,
  globalPath,
  linkPath = ROUTES.DASHBOARD,
  documentLinkTitle,
  documentLinkPath,
}) => {
  const history = useHistory();
  const { documentsCount } = useSelector((state: RootStateType) => state.user);
  const { isUserReadOnly, maxTemplatesNumber, permissionUsingSignNow } = useRolePermission();

  const onClickButtonHandler = (event: FormEvent<HTMLElement>, link: string) => {
    event.preventDefault();
    if (maxTemplatesNumber && maxTemplatesNumber <= documentsCount) {
      toast.info(MAX_TEMPLATES_COUNT_RESTRICTION);
    } else {
      history.push(link);
    }
  };

  const canCreateSingleDocument = documentLinkTitle
    && documentLinkPath
    && !isUserReadOnly
    && !maxTemplatesNumber
    && !permissionUsingSignNow;

  return (
    <div className="col-6 col-md-6 col-lg-3 d-flex justify-content-center">
      <Link
        to={globalPath}
        className="global-navigation-item d-flex flex-column align-items-center justify-content-center mt-3"
      >
        <div className="global-navigation-item_icon"><Icon /></div>
        <div className="pt-3 pb-3 global-navigation-item_title">
          {title}
        </div>
        <div className="global-navigation__buttons">
          {
            !isUserReadOnly && (
              <Tooltip title={`${linkTitle}s are intended to be reused many times`}>
                <span
                  onClick={(event) => onClickButtonHandler(event, linkPath)}
                  className="global-navigation-item_link global-navigation-item_link--accent"
                  role="button"
                  tabIndex={-1}
                >
                  Create {linkTitle}
                </span>
              </Tooltip>
            )
          }
          {
            canCreateSingleDocument && (
              <Tooltip title={`${documentLinkTitle} is intended to be used only once`}>
                <span
                  onClick={(event) => onClickButtonHandler(event, documentLinkPath)}
                  className="global-navigation-item_link"
                  role="button"
                  tabIndex={-1}
                >
                  {documentLinkTitle}
                </span>
              </Tooltip>
            )
          }
        </div>
      </Link>
    </div>
  );
};

export default GlobalNavigationItem;