import { FC, SVGAttributes } from 'react';

const LinkIcon: FC<SVGAttributes<any>> = ({ fill = 'inherit' }) => (
  <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00015 10H6.21015C4.10415 10 2.20415 8.465 2.01715 6.366C1.80415 3.996 3.67315 2 6.00015
      2H8.00015C8.55215 2 9.00015 1.553 9.00015 1C9.00015 0.447 8.55215 0 8.00015 0H6.20915C3.16015
      0 0.439151 2.187 0.051151 5.211C-0.417849 8.869 2.43215 12 6.00015 12H8.00015C8.55215 12 9.00015
      11.553 9.00015 11C9.00015 10.447 8.55215 10 8.00015 10ZM7.00015 6C7.00015 6.553 7.44815 7 8.00015
      7H14.0002C14.5522 7 15.0002 6.553 15.0002 6C15.0002 5.447 14.5522 5 14.0002 5H8.00015C7.44815
      5 7.00015 5.447 7.00015 6ZM15.7593 0.0002C18.8263 0.0002 21.5783 2.1972 21.9533 5.2422C22.4023
      8.8882 19.5583 12.0002 16.0003 12.0002H14.0003C13.4483 12.0002 13.0003 11.5532 13.0003 11.0002C13.0003
      10.4472 13.4483 10.0002 14.0003 10.0002H16.0003C18.3273 10.0002 20.1963 8.0042 19.9832 5.6342C19.7963
      3.5352 17.8963 2.0002 15.7893 2.0002H14.0003C13.4483 2.0002 13.0003 1.5532 13.0003 1.0002C13.0003
      0.4472 13.4433 0.0002 14.2493 0.0002H15.7593Z"
      fill={fill}
    />
  </svg>
);

export default LinkIcon;