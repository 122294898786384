import { FC, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import DownChevron from '@mui/icons-material/KeyboardDoubleArrowDown';
import UpChevron from '@mui/icons-material/KeyboardDoubleArrowUp';
import { createEditor, Editor as SlateEditor } from 'slate';
import { Editable, RenderElementProps, RenderLeafProps, Slate } from 'slate-react';

import cn from 'classnames';
import Element from 'components/Editor/components/RenderElement';
import Leaf from 'components/Editor/components/RenderLeaf';
import plugins from 'components/Editor/editor-custom-plugins';
import AttachmentsView from 'components/PublicPage/AttachmentsView';
import PublicFormView from 'components/PublicPage/PublicFormView';
import { ATTACHMENTS_TAB_ID, DOCUMENT_TAB_ID, FORM_TAB_ID } from 'constants/tabs';
import { useRolePermission } from 'hooks/useRolePermission';
import useShowFieldsFilledInMessage from 'hooks/useShowFieldsFilledInMessage';
import { RootStateType } from 'store/reducers';
import { ICompanyBranding } from 'types/CompanyBranding';
import { ICustomElement, IEditorProps } from 'types/Editor';
import { PageSettingsType } from 'types/PageSettingsType';
import { IPublicPageDocumentStructure } from 'types/PublicPage';
import shouldShowBrandingInRoutes from 'utils/CompanyBranding/shouldShowBrandingInRoutes';
import composePlugins from 'utils/composePlugins';
import { getMarginsForPageInInches, getPageSettings } from 'utils/PageSettings';
import { getIsFormDocument } from 'utils/PublicPage/documentTypeChecker';
import { extractFieldsFromChildren, getMappedFieldKeyValues } from 'utils/PublicPage/fieldHelpers';
import { toShowBlock } from 'utils/routeHelpers';

import vars from 'variables.module.scss';

const PublicEditor: FC<IEditorProps> = ({
  content,
  onChange,
  isFormsView = false,
  readOnlyMode = false,
  showOnlyEditor = false,
}) => {
  const { userRoleAssignments } = useRolePermission();

  const editorSectionRef = useRef<HTMLDivElement>(null);

  const currentDocument: IPublicPageDocumentStructure | null = useSelector(
    (state: RootStateType) => state.publicPages.currentDocument,
  );
  const [showUpChevron, setShowUpChevron] = useState<boolean>(false);
  const [showDownChevron, setShowDownChevron] = useState<boolean>(false);
  const location = useLocation();
  const isBrandingAvailable = shouldShowBrandingInRoutes(location.pathname);
  const { styleJSON }: ICompanyBranding = useSelector((state: RootStateType) => state.companyBranding);
  const { mainAssignment } = useSelector((state: RootStateType) => state.publicPages.structure.main);
  const emptyFormType = (
    currentDocument && getIsFormDocument(currentDocument.documentType) && currentDocument.emptyDocument
  ) || false;
  const doesDocumentHaveAttachments = !!currentDocument?.attachmentFields?.length;
  const activeTab = emptyFormType ? DOCUMENT_TAB_ID : (currentDocument?.activeEditorTab || DOCUMENT_TAB_ID);
  const { pathname } = useLocation();
  const isPublicURL = toShowBlock(pathname, 'public');
  const editorScrollableNode: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const navigationArrowNode: Element | null = document.querySelector('.navigation-arrow-wrapper_icon');
  const pageSettings: PageSettingsType | null = useSelector((state: RootStateType) => getPageSettings(state));
  const sectionPadding = getMarginsForPageInInches(pageSettings);

  const renderLeaf = useCallback((propsLeaf: RenderLeafProps) => <Leaf {...propsLeaf} />, []);
  const editor: SlateEditor = useMemo(() => composePlugins(plugins, createEditor()), []);
  editor.publicPage = true;

  // it means pdf_file_url
  const isNotPDFFormView = !(isPublicURL && isFormsView && !content.length && activeTab === FORM_TAB_ID);
  const fields: Partial<ICustomElement>[] = extractFieldsFromChildren(content);
  const filledInFields: Array<{ key: number; isFilledIn: boolean }> = getMappedFieldKeyValues(fields, mainAssignment);

  useShowFieldsFilledInMessage(fields, readOnlyMode);

  useEffect(() => {
    /**
     * TODO It's not clear what this code should do.
     * It runs only once now because 'editorScrollableNode' and 'navigationArrowNode' don't change.
     */
    if (editorScrollableNode.current && navigationArrowNode) {
      const arrowRect: DOMRect = navigationArrowNode.getBoundingClientRect();

      if (arrowRect.top < editorScrollableNode.current.scrollTop) {
        // Element is above the scroll position
        setShowUpChevron(true);
      } else if (arrowRect.bottom > (editorScrollableNode.current.scrollTop + window.innerHeight)) {
        // Element is below the scroll position
        setShowDownChevron(true);
      } else {
        // Element is within the visible viewport
        setShowDownChevron(false);
        setShowUpChevron(false);
      }
    }
  }, [editorScrollableNode.current, navigationArrowNode, fields]);

  const renderElement = useCallback((propsElement: RenderElementProps) => {
    const isReadOnlyByAssignment = propsElement.element?.assignment !== mainAssignment;
    return (
      <Element
        {...propsElement}
        styledSections={false}
        isPublicPage
        readOnlyMode={emptyFormType || readOnlyMode || isReadOnlyByAssignment}
        filledInFields={filledInFields}
        editorRef={editorSectionRef}
      />
    );
  }, [emptyFormType, readOnlyMode, userRoleAssignments, fields]);

  return (
    <Slate editor={editor} value={content} onChange={onChange}>
      {/* Probably we need to change isMobileDevice to screen width. It'll be done in separate user story */}
      {showUpChevron && (
        <i className="editor-chewron-nav">
          <UpChevron htmlColor={isBrandingAvailable ? styleJSON?.mainBrandColor : vars.brandPrimary} />
        </i>
      )}

      <div
        className="col d-flex flex-column"
        data-html-source="true"
        ref={editorScrollableNode}
      >
        {
          isNotPDFFormView
            ? (
              <>
                <div
                  className={cn(
                    'd-flex justify-content-center py-3',
                    {
                      'd-none': activeTab !== DOCUMENT_TAB_ID,
                    },
                  )}
                >
                  <div
                    className="js-editor-section editor-width cancel-paddings-for-small-screen"
                    ref={editorSectionRef}
                    style={{
                      padding: sectionPadding,
                    }}
                  >
                    <Editable
                      readOnly
                      renderElement={renderElement}
                      renderLeaf={renderLeaf}
                      placeholder="Enter some rich text…"
                      spellCheck
                      autoFocus
                    />
                  </div>
                </div>
                {
                  doesDocumentHaveAttachments && activeTab === ATTACHMENTS_TAB_ID
                    ? <AttachmentsView />
                    : null
                }
              </>
            )
            : null
        }
        {
          !showOnlyEditor && activeTab === FORM_TAB_ID
            ? <PublicFormView isReadOnly={readOnlyMode} isFormsView={isFormsView} />
            : null
        }
      </div>

      {showDownChevron && (
        <i className="editor-chewron-nav editor-chewron-nav-top">
          <DownChevron htmlColor={isBrandingAvailable ? styleJSON?.mainBrandColor : vars.brandPrimary} />
        </i>
      )}
    </Slate>
  );
};

export default PublicEditor;