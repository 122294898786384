import {
  CLEAR_FORM_BUILDER_SELECTED_FIELDS_KEYS,
  CLEAR_OUTLINE_ACTIVE_SECTION_KEY,
  SET_FORM_BUILDER_GROUPED_FIELDS_KEYS,
  SET_FORM_BUILDER_SELECTED_FIELDS_KEYS,
  SET_IS_DRAG_START_STATUS,
  SET_IS_DRAG_STOP_STATUS,
  SET_OUTLINE_ACTIVE_SECTION_KEY,
} from 'store/actions/actionTypes';
import { FormBuilderStateType } from 'types/FormBuilder';
import { Action } from 'types/redux';

const initialState: FormBuilderStateType = {
  isDragInProcess: false,
  selectedFormBuilderFieldsKeys: [],
  outlineActiveSectionKey: null,
  groupedFieldsKeys: null,
};

// eslint-disable-next-line default-param-last
const formBuilder = (state: FormBuilderStateType = initialState, { type, payload }: Action) => {
  switch (type) {
    case SET_FORM_BUILDER_SELECTED_FIELDS_KEYS:
      return {
        ...state,
        selectedFormBuilderFieldsKeys: payload,
      };
    case CLEAR_FORM_BUILDER_SELECTED_FIELDS_KEYS:
      return {
        ...state,
        selectedFormBuilderFieldsKeys: [],
      };
    case SET_IS_DRAG_START_STATUS:
      return {
        ...state,
        isDragInProcess: true,
      };
    case SET_IS_DRAG_STOP_STATUS:
      return {
        ...state,
        isDragInProcess: false,
      };
    case SET_OUTLINE_ACTIVE_SECTION_KEY:
      return {
        ...state,
        outlineActiveSectionKey: payload,
      };
    case CLEAR_OUTLINE_ACTIVE_SECTION_KEY:
      return {
        ...state,
        outlineActiveSectionKey: null,
      };
    case SET_FORM_BUILDER_GROUPED_FIELDS_KEYS:
      return {
        ...state,
        groupedFieldsKeys: payload,
      };
    default:
      return state;
  }
};

export default formBuilder;