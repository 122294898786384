import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { COLLECTION_TYPE, URL_PUBLIC_TEMPLATE } from 'constants/general';
import PUBLIC_PAGE_STATUS from 'constants/publicPage';
import {
  clearAttachments,
  setAllDocumentsStatus,
  setAutoFillPIIData,
  setCreatedDocumentId,
  setPublicPageData,
} from 'store/actions/publicPages';
import { RootStateType } from 'store/reducers';
import { ICollectionDetails, ICollectionTemplate } from 'types/MultiTemplate';
import { IPublicPageState } from 'types/PublicPage';

const useStartOverButton = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isAuthenticated: boolean = useSelector((state: RootStateType) => state.user.isAuthenticated);
  const {
    data,
    structure,
    startLink,
  }: IPublicPageState = useSelector((state: RootStateType) => state.publicPages);

  const getAllowMultipleSubmissions = () => {
    if (data?.type === COLLECTION_TYPE) {
      return (data as ICollectionDetails).templates?.some(
        (templateObject: ICollectionTemplate) => templateObject.template.page_settings?.allow_multiple_submissions_in_session,
      );
    }
    return data?.page_settings?.allow_multiple_submissions_in_session as boolean | undefined;
  };

  const getAllowStarOverButton = () => (
    getAllowMultipleSubmissions() && (structure.main.pageType === URL_PUBLIC_TEMPLATE || data?.type === COLLECTION_TYPE)
  );

  const redirectAndReloadToStart = () => {
    if (isAuthenticated) {
      window.location.replace(startLink);
    } else {
      dispatch(setAllDocumentsStatus(PUBLIC_PAGE_STATUS.NOT_OPEN));
      dispatch(clearAttachments());
      dispatch(setCreatedDocumentId(0));
      dispatch(setAutoFillPIIData(true));
      dispatch(setPublicPageData(null));
      history.push(startLink);
    }
  };

  return {
    allowMultipleSubmissions: getAllowMultipleSubmissions(),
    allowStarOverButton: getAllowStarOverButton(),
    redirectAndReloadToStart,
  };
};

export default useStartOverButton;