/* eslint-disable */
import React, { useState } from "react";

import ToolbarCommandList from "./ToolbarCommandList";
import ToolbarNavTabs from "./ToolbarNavTabs";

import { TOOLBAR_LIST, TOOLBAR_TABS } from "constants/toolbarList";
import { BaseSelection as SlateBaseSelection, Transforms } from "slate";
import { useSlate } from "slate-react";

interface IVerticalToolbarProps {
  toolbarRef: React.MutableRefObject<HTMLDivElement | null>;
  toolbarInputRef: React.MutableRefObject<HTMLInputElement | null>;
  lastEditorSelection: SlateBaseSelection | null;
}

const VerticalToolbar: React.FC<IVerticalToolbarProps> = (props) => {

  const {
    toolbarRef,
    toolbarInputRef,
    lastEditorSelection,
  } = props;

  const editor = useSlate();

  const [selectedTab, setSelectedTab] = useState("ALL");
  const [inputFilter, setInputFilter] = useState("");

  const onInputFilter = () => {
    if(lastEditorSelection) Transforms.select(editor, lastEditorSelection);
  }

  const onChangeInput: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setInputFilter(event.target.value);
  }

  return (
    <>
      <div className={`toolbar`} ref={toolbarRef}>
          <div>
              <h3 className="m-2">Add blocks</h3>
              <input
                  ref={toolbarInputRef}
                  className="input-toolbar-filter w-100"
                  placeholder="Keep typing to filter, or escape to exit"
                  value={inputFilter}
                  onFocus={onInputFilter}
                  onBlur={onInputFilter}
                  onChange={onChangeInput}
              />
              <ToolbarNavTabs
                  toolbarTabs={TOOLBAR_TABS}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
              />
          </div>
          <ToolbarCommandList toolbarList={TOOLBAR_LIST} selectedTab={selectedTab} />
      </div>
    </>
  )
}

export default VerticalToolbar;