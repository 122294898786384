import {
  SET_ACTIVE_STRIPE_PRODUCT_ID,
  SET_ACTIVE_STRIPE_SUBSCRIPTION,
} from 'store/actions/actionTypes';
import { IPaymentStore } from 'types/payment/Payment';
import { Action } from 'types/redux';

const initialState: IPaymentStore = {
  stripe: {
    activeProductID: null,
    activePriceID: null,
    subscriptionID: null,
  },
  activeSubscription: null,
};

// eslint-disable-next-line default-param-last
const payment = (state: IPaymentStore = initialState, action: Action) => {
  switch (action.type) {
    case SET_ACTIVE_STRIPE_PRODUCT_ID:
      return {
        ...state,
        stripe: action.payload,
      };
    case SET_ACTIVE_STRIPE_SUBSCRIPTION:
      return {
        ...state,
        activeSubscription: action.payload,
      };
    default:
      return state;
  }
};

export default payment;