import { useEffect, useState } from 'react';

import { Timeout } from 'react-number-format/types/types';
import { useSelector } from 'react-redux';

import { Descendant } from 'slate';

import { RootStateType } from 'store/reducers';
import { PDFFieldTypeObject } from 'types/PdfTemplates';
import {
  IPublicPageState,
  PublicPageDataType,
} from 'types/PublicPage';
import updateData from 'utils/PublicPage/updateData';
import { isTemplateType } from 'utils/typeChecker';

const useAutoUpdateUnfinishedDocument = (
  content: Descendant[],
  saveDocumentHandler: (
    isUnfinished?: boolean,
    documentUUID?: string | null,
    updatedData?: PublicPageDataType | null,
  ) => void,
  documentUUID: string | null,
  isDisabledModalButton: boolean,
) => {
  const { data }: IPublicPageState = useSelector((state: RootStateType) => state.publicPages);
  const pdfFields: PDFFieldTypeObject = useSelector((state: RootStateType) => state.pdfTemplates.pdfTemplateFields);
  const isTemplate = isTemplateType(data);
  const pageSettings = data?.page_settings;

  const [localTimeout, setLocalTimeout] = useState<Timeout | null>(null);

  useEffect(() => {
    if (isDisabledModalButton && localTimeout) {
      clearTimeout(localTimeout);
      setLocalTimeout(null);
    } else if (
      isTemplate
      && data?.enable_reminders
      && (pageSettings?.enable_email_reminder || pageSettings?.enable_sms_reminder)
    ) {
      if (localTimeout) {
        clearTimeout(localTimeout);
        setLocalTimeout(null);
      }

      const timeoutAutoUpdate = setTimeout(() => {
        const updatedData = updateData(data, content, pdfFields);
        saveDocumentHandler(true, documentUUID, updatedData);
      }, 5000);
      setLocalTimeout(timeoutAutoUpdate);
    }

    return () => {
      if (localTimeout) {
        clearTimeout(localTimeout);
        setLocalTimeout(null);
      }
    };
  }, [data?.enable_reminders, content, documentUUID, isDisabledModalButton]);
};

export default useAutoUpdateUnfinishedDocument;