import { DEFAULT_LANGUAGE, GOOGLE_TRANSLATE_ELEMENT } from 'constants/googleTranslate';

export const changeLanguage = (language: string) => {
  const bodyElement = document.querySelector('body');
  const googleTranslateElement = document.getElementById(GOOGLE_TRANSLATE_ELEMENT) as HTMLElement;
  if (googleTranslateElement) {
    const select = googleTranslateElement.getElementsByTagName('select')[0];
    if (select) {
      if (language !== DEFAULT_LANGUAGE) {
        select.value = language;
        bodyElement?.classList.remove('notranslate');
      } else {
        bodyElement?.classList.add('notranslate');
      }
      select.dispatchEvent(new Event('change'));
    }
  }
};

export const setGoogleTranslateCookieToNone = (translateOption: string) => {
  if (translateOption === DEFAULT_LANGUAGE) {
    setTimeout(() => {
      document.cookie = 'googtrans=none; path=/;';
    }, 0);
  }
};