import { put, select, takeLatest } from 'redux-saga/effects';

import { RESTORE_DATA_FROM_PERSISTENT_STORAGE } from 'store/actions/actionTypes';
import { setPublicPageData } from 'store/actions/publicPages';
import { RootStateType } from 'store/reducers';
import { safe } from 'store/sagas/errorHandlerSaga';
import { StorePersistentStorageDataType } from 'types/persistentStorage';
import { PublicPageDataType } from 'types/PublicPage';
import { Action } from 'types/redux';
import { getUpdatedDataFromPersistentStorage } from 'utils/PersistentStorage/restoreDataHelper';

const restoreDataFromStoreSaga = function* ({ payload: key }: Action<string>) {
  const pageData: PublicPageDataType = yield select((state: RootStateType) => state.publicPages.data);
  const persistentStorageData: StorePersistentStorageDataType = yield select((state: RootStateType) => (
    state.persistentStorage.data
  ));

  const storedFields = persistentStorageData[key];
  if (!storedFields) return;

  const updatedData = getUpdatedDataFromPersistentStorage(pageData, storedFields);
  yield put(setPublicPageData(updatedData));
};

export default [
  takeLatest(RESTORE_DATA_FROM_PERSISTENT_STORAGE, safe(restoreDataFromStoreSaga, false)),
];