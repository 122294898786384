/* eslint-disable */
import { Descendant } from 'slate';
import { FIELD_TYPE } from 'constants/editors';
import { DOCUMENT_TYPE, FORM_TYPE, PDF_TYPE } from 'constants/general';
import { ITemplateData, ITemplateDetails, ITemplateRequestObj } from 'types/Templates';
import { IDocumentSection } from 'types/Sections';
import { generateNumber } from './editorFieldHelpers';
import { PDFFieldType } from 'types/PdfTemplates';
import ROUTES from 'constants/routes';

export const parseFields = (content: any, callback: any): void => {
  if (FIELD_TYPE.includes(content.type)) return callback(content);
  if (content.children) {
    content.children.forEach((child: any) => {
      if (child.children) return parseFields(child, callback);
      if (FIELD_TYPE.includes(child.type)) return callback(child);
    });
  }
}

export const prepareNewTemplate = (template: ITemplateDetails, isSectionLightTemplate: boolean): ITemplateData | ITemplateRequestObj => {
  const { states, description, template_title, doctype_id, sections, assignments } = template;
  const getStates = states.map((state) => ({ id: state.id })) || [];
  const templateName = 'Copy of ' + template.name;

  const templateData: ITemplateRequestObj = {
    name: templateName,
    description,
    template_title,
    doctype_id,
    states: getStates,
    type: template.type,
    is_single: false,
    assignments,
  }

  if (isSectionLightTemplate) {
    return {
      name: templateName,
      template: templateData,
      sections: sections.map((sectionData: IDocumentSection) => {
        const { content_json, name, description } = sectionData.section;

        if (content_json) {
          content_json.map((content: Descendant) => {
            parseFields(content, (el: any) => {
              el.key = generateNumber();
            });
          })
        }
        return {
          name,
          description,
          content_json,
          states: getStates,
        }
      }),
    };
  }
  return {
    ...templateData,
    sections: sections.map((section, index) => ({
      section_id: section.section_id,
      position: index,
    })),
  };
};

export const prepareNewPdfTemplate = (template: ITemplateDetails): ITemplateData | ITemplateRequestObj => {
  const {
    states,
    description,
    template_title,
    doctype_id,
    assignments,
    pdf_fields_json,
    form_builder_structure: formBuilder,
  } = template;
  const getStates = states.map((state) => ({ id: state.id })) || [];
  const templateName = `Copy of ${template.name}`;

  const updatedPdfFields: Record<number, PDFFieldType> = {};
  let formBuilderCopy = formBuilder ? [...formBuilder] : null;

  Object.entries(pdf_fields_json).forEach(([originFieldkey, field]) => {
    const newFieldKey = generateNumber();
    updatedPdfFields[newFieldKey] = { ...field, key: newFieldKey };
    if (Array.isArray(formBuilderCopy)) {
      formBuilderCopy = formBuilderCopy.map((section) => {
        return {
          ...section,
          key: generateNumber(),
          fields: section.fields.map((field) => {
            if (field.key === Number(originFieldkey)) {
              return { ...field, key: newFieldKey };
            }
            return field;
          })
        }
      })
    }
  });

  const templateData: ITemplateRequestObj = {
    name: templateName,
    description,
    template_title,
    doctype_id,
    states: getStates,
    type: PDF_TYPE,
    is_single: false,
    assignments,
    pdf_fields_json: updatedPdfFields,
    form_builder_structure: formBuilderCopy,
  };

  return { ...templateData, pdf_file_url: template.pdf_file_url };
};

export const getRouteForCopiedDocument = (documentTpe?: string) => {
  switch (documentTpe) {
    case PDF_TYPE:
      return ROUTES.PDF_EDITOR;
    case FORM_TYPE:
      return ROUTES.FORM_EDITOR;
    case DOCUMENT_TYPE:
    default:
      return ROUTES.DOCUMENTS_EDITOR;
  }
};