import { FC } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Nav, NavItem } from 'reactstrap';

import NavigationTabIcon from 'components/Base/NavigationTabIcon';
import { setCurrentDocumentActiveTab } from 'store/actions/publicPages';
import { RootStateType } from 'store/reducers';
import { ITab, PublicPageNavigation } from 'types/Tabs';

import 'scss/components/TabNavigation/TabNavigation.scss';

const TabNavigation: FC<{ navigationTabs: PublicPageNavigation }> = ({ navigationTabs }) => {
  const dispatch = useDispatch();
  const { activeEditorTab } = useSelector((state: RootStateType) => state.publicPages.currentDocument);

  return (
    <Nav className="tab-navigation">
      {
        navigationTabs.map((tab: ITab) => (
          <NavItem
            key={tab.id}
            className={`tab-navigation__tab ${activeEditorTab === tab.id ? 'tab-navigation__tab--active' : ''}`}
            onClick={() => dispatch(setCurrentDocumentActiveTab(tab.id))}
          >
            <NavigationTabIcon type={tab.label} />
            <span className="ps-1">{tab.label}</span>
          </NavItem>
        ))
      }
    </Nav>
  );
};

export default TabNavigation;