/* eslint-disable */
import { Editor, Range, Element as SlateElement, Point, Transforms } from 'slate';
import { CHECK_LIST_ITEM, PARAGRAPH } from 'constants/editors';

export const withCheckList = (editor: Editor) => {
  const { deleteBackward } = editor;

  editor.deleteBackward = (...args) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [match]: any = Editor.nodes(editor, {
        match: (node) => (
          !Editor.isEditor(node) &&
          SlateElement.isElement(node) &&
          node.type === CHECK_LIST_ITEM
        )
      });

      if (match) {
        const [, path] = match;
        const start = Editor.start(editor, path);

        if (Point.equals(selection.anchor, start)) {
          const newProperties: Partial<SlateElement> = {
            type: PARAGRAPH,
          };
          Transforms.setNodes(editor, newProperties, {
            match: (node) => (
              !Editor.isEditor(node) &&
              SlateElement.isElement(node) &&
              node.type === CHECK_LIST_ITEM
            )
          });
          return;
        }
      }
    }

    deleteBackward(...args);
  };

  return editor;
};