import {
  ChangeEvent,
  Dispatch as ReactDispatch,
  FC,
  MutableRefObject,
  useEffect,
  useRef,
} from 'react';

import { SMS_CODE_LENGHT } from 'hooks/SMSValidation/useVerifyUniqueCode';

import 'scss/components/TabNavigation/TabNavigation.scss';

interface IDigitInputBox {
  setReferences: ReactDispatch<React.SetStateAction<MutableRefObject<any>[]>>;
  index: number;
  value: string;
  changeCodeBoxHandler: (event: ChangeEvent<HTMLInputElement>, index: number) => void;
}

const DigitInputBox: FC<IDigitInputBox> = ({
  setReferences,
  index,
  value,
  changeCodeBoxHandler,
}) => {
  const digitInputRef: MutableRefObject<any> = useRef(null);

  useEffect(() => {
    if (digitInputRef) {
      setReferences((prevState) => {
        prevState.splice(index, 1, digitInputRef);
        return prevState;
      });
    }
  }, [digitInputRef]);

  return (
    <div className="mx-2">
      <input
        className="digit-input-box"
        ref={digitInputRef}
        type="text"
        maxLength={SMS_CODE_LENGHT}
        value={value}
        onChange={(event) => changeCodeBoxHandler(event, index)}
      />
    </div>
  );
};

export default DigitInputBox;