import { useDispatch, useSelector } from 'react-redux';

import cn from 'classnames';
import InputField from 'components/Base/InputField';
import DocumentsInfoList from 'components/SendDocuments/DocumentsInfoList';
import DocumentsViewMode from 'components/SendDocuments/DocumentsViewMode';
import CarbonCopy from 'components/SignNow/CarbonCopy';
import Tooltip from 'components/Tooltip';
import { TEXTAREA_ROWS_COUNT } from 'constants/editors';
import { SignerFlow } from 'constants/signNow';
import { setEmailInfo } from 'store/actions/sendDocuments';
import { RootStateType } from 'store/reducers';
import { SignersInfoType } from 'types/SendDocuments';
import { getFullSignerName } from 'utils/sendDocumentsHelpers';

const ReviewAndSend = () => {
  const dispatch = useDispatch();
  const {
    signersInfo,
    // emailSubject,
    emailMessage,
  } = useSelector((state: RootStateType) => state.sendDocuments);

  const {
    isRemindDocumentActive,
    isRemindForAllSigners,
    isSignNow,
    currentFlow,
  } = useSelector((state: RootStateType) => state.signNow);

  const handlerOnChange = (target: string, value: string) => {
    dispatch(setEmailInfo(target, value));
  };

  const signedStatusTitle = 'We will not send an email to this recipient '
    + 'because they have completed all required fields';

  return (
    <div className="text-start">
      <div className="mt-3 select-signers-title">Review and send</div>
      <div className="container select-signers-card">
        <div className="row mt-3">
          <div className="col-12">
            <div className="signer-card">
              Documents
            </div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-12">
            <DocumentsInfoList />
          </div>
        </div>
      </div>
      {!isSignNow && <DocumentsViewMode />}
      <div className="container select-signers-card">
        <div className="row mt-3">
          <div className="col-12">
            <div className="signer-card">
              Signers
            </div>
          </div>
        </div>
        <div className="row my-3">
          {
            signersInfo.map((signer: SignersInfoType, index: number) => (
              <div
                key={`SignersInfo${index + 1}`}
                className={cn({
                  'send-documents-final-opacity': isRemindDocumentActive && !isRemindForAllSigners && signer.signed,
                })}
              >
                <span>
                  {
                    currentFlow === SignerFlow.NoOrder
                      ? '- '
                      : `${index + 1}. `
                  }
                </span>
                <span className="send-documents-final-name">{getFullSignerName(signer)},&nbsp;</span>
                <span>{signer.email}</span>
                {
                  isRemindDocumentActive && signer.signed && !isRemindForAllSigners
                    && (
                      <span className="send-documents-final-name">
                        {
                          signer.signed
                            ? (
                              <Tooltip title={signedStatusTitle}>
                                <span>
                                  &nbsp;-&nbsp;Signed
                                </span>
                              </Tooltip>
                            )
                            : ''
                        }
                      </span>
                    )
                }
              </div>
            ))
          }
        </div>
      </div>
      {isSignNow && <CarbonCopy />}
      {/* <div className="my-4">
        <InputField
          label="Email subject"
          value={emailSubject}
          onChange={(event) => handlerOnChange('emailSubject', event.target.value)}
        />
      </div> */}
      <div className="my-4">
        <InputField
          multiline
          maxRows={TEXTAREA_ROWS_COUNT}
          type="textarea"
          label="Message"
          value={emailMessage}
          onChange={(event) => handlerOnChange('emailMessage', event.target.value)}
        />
      </div>
    </div>
  );
};

export default ReviewAndSend;