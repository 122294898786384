import type { Node } from 'slate';
import { Element } from 'slate';

import { BULLETED_LIST, NUMBERED_LIST } from 'constants/editors';

import type { ListsEditor } from '../types';
import { ListType } from '../types';

/**
 * Returns the "listStyle" of a given list node.
 */
export const getListStyleProperty = (editor: ListsEditor, node: Node): object => {
  const isElement = Element.isElement(node);

  if (isElement) {
    if (editor.isListNode(node, ListType.ORDERED) || node.type === NUMBERED_LIST) {
      return { listStyle: node.listStyle || '' };
    }

    if (editor.isListNode(node, ListType.UNORDERED) || node.type === BULLETED_LIST) {
      return {};
    }
  }

  return {};
};

export default getListStyleProperty;