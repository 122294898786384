import { FC, ReactNode } from 'react';

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Button from 'components/Base/Button';
import SimpleSpinner from 'components/Base/SimpleSpinner';
import Logo from 'components/Intro/Logo';
import { CANCEL, CONFIRM } from 'constants/general';
import useStyleSchema, { CustomStylesEnum } from 'hooks/useStylesFromSchema';

import classes from 'scss/components/Modal/ConfirmationModal.module.scss';

interface IConfirmModalParams {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  isDisabled?: boolean;
  withLogo?: boolean;
  withBranding?: boolean;
  title?: string;
  description?: ReactNode;
  cancelButtonText?: string;
  confirmButtonText?: string;
  showSpinnerIfDisabled?: boolean;
}

// This is the general Confirmation Modal Window for every situation
const ConfirmationModal: FC<IConfirmModalParams> = ({
  isOpen,
  onCancel,
  onConfirm,
  isDisabled = false,
  withBranding = false,
  withLogo = false,
  title = 'Confirmation',
  description = '',
  cancelButtonText = CANCEL,
  confirmButtonText = CONFIRM,
  showSpinnerIfDisabled = false,
}) => {
  const styleSchema = useStyleSchema<
    CustomStylesEnum.button
    | CustomStylesEnum.outlinedBorderButton
    | CustomStylesEnum.title
    | CustomStylesEnum.description
  >([
    CustomStylesEnum.button,
    CustomStylesEnum.outlinedBorderButton,
    CustomStylesEnum.title,
    CustomStylesEnum.description,
  ]);

  return (
    <Modal isOpen={isOpen} centered>
      <ModalHeader toggle={!isDisabled || !showSpinnerIfDisabled ? onCancel : undefined}>
        {
          withLogo
          && (
            <span className={classes.Logo}>
              <Logo />
            </span>
          )
        }
        <span
          className={classes.Title}
          style={withBranding ? styleSchema.title : {}}
        >
          {title}
        </span>
      </ModalHeader>
      <ModalBody>
        <div className={classes.Body}>
          {
            description
            && (
              <div
                className="text-center"
                style={withBranding ? styleSchema.description : {}}
              >
                {description}
              </div>
            )
          }
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex align-items-center justify-content-center w-100 gap-4">
          <Button
            disabled={isDisabled && showSpinnerIfDisabled}
            classes="outline outlined-with-border"
            onClick={onCancel}
            style={withBranding ? styleSchema.outlinedBorderButton : {}}
          >
            {cancelButtonText}
          </Button>
          {
            isDisabled && showSpinnerIfDisabled ? (
              <div
                className="spinner-in-button"
                style={withBranding ? styleSchema.button : {}}
              >
                <SimpleSpinner />
              </div>
            ) : (
              <Button
                disabled={isDisabled}
                onClick={onConfirm}
                style={withBranding ? styleSchema.button : {}}
              >
                {confirmButtonText}
              </Button>
            )
          }
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;