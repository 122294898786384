import { FC, useEffect, useState } from 'react';

import { Progress } from 'reactstrap';

import { Box, Modal, Typography } from '@mui/material';

const ModalProgressBar: FC<{ generalCount: number; onFinish?: () => void; }> = ({
  generalCount,
  onFinish = () => null,
}) => {
  const [progress, setProgress] = useState<number>(0);
  const allElements = Array.from(document.querySelectorAll('.dz-preview'));

  useEffect(() => {
    if (!allElements.length) {
      onFinish();
      return;
    }

    const timer = setInterval(() => {
      if (progress >= 100) {
        clearInterval(timer);
        onFinish();
      } else {
        const uploadedElements = Array.from(document.querySelectorAll('.dz-preview.dz-complete'));
        setProgress(Math.floor((uploadedElements.length * 100) / allElements.length));
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [progress, allElements, onFinish]);

  return (
    <Modal open={!!generalCount} onClose={() => null}>
      <Box style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        backgroundColor: '#fff',
        padding: '5% 2%',
      }}
      >
        <Progress value={progress}>{progress}%</Progress>
        <Typography>Please, wait while attachments are uploading...</Typography>
      </Box>
    </Modal>
  );
};

export default ModalProgressBar;