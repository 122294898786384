import { FC, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Box, LinearProgress, Modal } from '@mui/material';

import { RootStateType } from 'store/reducers';
import { IPublicPageState } from 'types/PublicPage';

import classes from 'scss/components/Modal/AttachmentLoadingModal.module.scss';

interface IAttachmentLoading {
  isVisible: boolean;
  onFinish: () => void;
}

const AttachmentLoadingModal: FC<IAttachmentLoading> = ({ isVisible, onFinish }) => {
  const {
    totalAttachedFilesCounter,
    sentFilesCounter,
  }: IPublicPageState = useSelector((state: RootStateType) => state.publicPages);
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    if (!totalAttachedFilesCounter && isVisible) {
      onFinish();
    }

    const timer = setInterval(() => {
      if (progress >= 100) {
        clearInterval(timer);
        onFinish();
      } else {
        setProgress(Math.floor((sentFilesCounter * 100) / totalAttachedFilesCounter));
      }
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, [progress, totalAttachedFilesCounter, sentFilesCounter, isVisible]);

  return (
    <Modal open={isVisible}>
      <Box className={classes.ModalBox}>
        <h2 className={classes.Title}>File upload</h2>
        <div className={classes.List}>Please, wait while attachments are uploading...</div>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
    </Modal>
  );
};

export default AttachmentLoadingModal;