import { PUBLIC_PAGE_STATUS } from 'constants/publicPage';

const STARTED_STATUS = 0.33;
const PROGRESS_STATUS = 0.66;

const getPageStatus = (
  current: number,
  total: number,
  emptyDocument: boolean,
  readOnlyMode: boolean,
): PUBLIC_PAGE_STATUS => {
  if (emptyDocument || readOnlyMode) {
    return PUBLIC_PAGE_STATUS.DONE;
  }
  const value = current / total;

  if (value < STARTED_STATUS) return PUBLIC_PAGE_STATUS.STARTED;
  if (value > STARTED_STATUS && value < PROGRESS_STATUS) return PUBLIC_PAGE_STATUS.IN_PROGRESS;
  return PUBLIC_PAGE_STATUS.ALMOST_DONE;
};

export default getPageStatus;