import { CSSProperties, FC } from 'react';

import { useLocation } from 'react-router-dom';

import Logo from 'components/Intro/Logo';
import useStyleSchema, { CustomStylesEnum } from 'hooks/useStylesFromSchema';
import shouldShowBrandingInRoutes from 'utils/CompanyBranding/shouldShowBrandingInRoutes';

import classes from 'scss/components/Intro/Intro.module.scss';

type descriptionWidthType = 'small' | 'large';

type IntroType = {
  logoSource?: string;
  title: string;
  description: string | JSX.Element;
  descriptionWidth?: descriptionWidthType;
  descriptionMobileWidth?: descriptionWidthType;
  justifyContent?: 'center' | 'start';
  styles?: {
    description?: CSSProperties;
    logo?: CSSProperties;
  };
}

const Intro: FC<IntroType> = ({
  logoSource,
  title,
  description,
  descriptionWidth = 'small',
  descriptionMobileWidth = 'small',
  justifyContent = 'start',
  children,
  styles = {},
}) => {
  const location = useLocation();
  const isBrandingAvailable = shouldShowBrandingInRoutes(location.pathname);
  const widthDescriptionClassname = descriptionWidth === 'small'
    ? classes.DescriptionSmall
    : classes.DescriptionLarge;

  const widthDescriptionMobileClassname = descriptionMobileWidth === 'small'
    ? classes.DescriptionSmallMobile
    : classes.DescriptionLargeMobile;

  const justifyContentStart = justifyContent === 'start' ? classes.JustifyContentStart : '';

  const styleSchema = useStyleSchema<CustomStylesEnum.title | CustomStylesEnum.description>(
    [CustomStylesEnum.title, CustomStylesEnum.description],
  );

  const titleStyleSchema = {
    ...(isBrandingAvailable && styleSchema.title),
  };

  const descriptionStyleSchema = {
    ...styles?.description,
    ...(isBrandingAvailable && styleSchema.description),
  };

  return (
    <div className="greeting-wrapper">
      <section className={`${classes.Intro} ${justifyContentStart}`}>
        <Logo logoSource={logoSource} />
        <h1
          className="title"
          style={titleStyleSchema}
        >
          {title}
        </h1>
        <h3
          className={`${classes.Description} ${widthDescriptionClassname} ${widthDescriptionMobileClassname}`}
          style={descriptionStyleSchema}
        >
          {description}
        </h3>
        {children}
      </section>
    </div>
  );
};

export default Intro;