import { MANAGER_ASSIGNMENT, RECIPIENT_ASSIGNMENT } from 'constants/editors';
import { AssignmentsMainParts, AssignmentsObjectType, AssignmentType } from 'types/Editor';
import { SignerType } from 'types/SendDocuments';

export const isRecipientAssignment = (assignment: string | undefined): boolean => (
  Boolean(assignment?.startsWith(RECIPIENT_ASSIGNMENT)) || !assignment
);

export const isManagerAssignment = (assignment: string | undefined): boolean => (
  Boolean(assignment?.startsWith(MANAGER_ASSIGNMENT))
);

export const isCCAssignment = (assignment: string | undefined): boolean => (
  Boolean(assignment?.startsWith(SignerType.CC_RECIPIENT))
);

export const isFieldAssignment = (value: string): boolean => (
  isManagerAssignment(value) || isRecipientAssignment(value)
);

export const getMainTypeAssignment = (assignment?: string) => (
  isRecipientAssignment(assignment) ? RECIPIENT_ASSIGNMENT : MANAGER_ASSIGNMENT
);

export const getAssignmentsMainPartInStore = (assignment?: string) => (
  isRecipientAssignment(assignment) ? AssignmentsMainParts.RECIPIENTS : AssignmentsMainParts.MANAGERS
);

export const getActualAssignmentsState = (
  originAssignments: AssignmentsObjectType,
  oldAssignment: string | undefined,
  newAssignment: string,
  fieldKey: number,
): AssignmentsObjectType => {
  const assignmentsType = getAssignmentsMainPartInStore(newAssignment || oldAssignment);
  const assignmentsCopy = { ...originAssignments };

  return {
    ...assignmentsCopy,
    [assignmentsType]: assignmentsCopy[assignmentsType].map((item: AssignmentType) => {
      if (item.type === oldAssignment) {
        const currentIndex = item.fieldsKeys?.indexOf(fieldKey);
        if (currentIndex !== undefined && currentIndex >= 0) {
          item.fieldsKeys?.splice(currentIndex, 1);
        }
      } else if (item.type === newAssignment) {
        item.fieldsKeys?.push(fieldKey);
      }
      return item;
    }),
  };
};

export const adaptOldAssignmentsObject = (assignments: AssignmentsObjectType) => {
  const copyAssignments: AssignmentsObjectType = { ...assignments };
  Object.keys(copyAssignments).forEach((value: string) => {
    const mainKey = value as AssignmentsMainParts;
    copyAssignments[mainKey] = copyAssignments[mainKey]
      .map((assignment: AssignmentType) => {
        const copyAssignment = { ...assignment };
        if (!copyAssignment.fieldsKeys) {
          copyAssignment.fieldsKeys = [];
        }
        return copyAssignment;
      });
  });
  return copyAssignments;
};

export const updateAssignmentStorePart = (arrayToUpdate: AssignmentType[], assignment: string, fieldKey: number) => (
  arrayToUpdate.map((item: AssignmentType) => {
    if (item.type === assignment) {
      item.fieldsKeys?.push(fieldKey);
    }
    return item;
  })
);

export const splitAssignmentsArray = (assignments: AssignmentType[]) => {
  let firstRecipient: AssignmentType | null = null;
  const restOfArray: AssignmentType[] = [];
  if (assignments) {
    assignments.forEach((assignment) => {
      if (assignment.type === RECIPIENT_ASSIGNMENT) {
        firstRecipient = assignment;
      } else {
        restOfArray.push(assignment);
      }
    });
  }
  return {
    firstRecipient,
    restOfArray,
  };
};