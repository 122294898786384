import {
  DELETE_PERSISTENT_STORAGE_DATA_BY_KEY,
  RESTORE_DATA_FROM_PERSISTENT_STORAGE,
  SET_PERSISTENT_STORAGE_FIELD_DATA,
  SET_RESTORE_DATA_MODAL_VISIBILITY,
} from 'store/actions/actionTypes';
import { PersistentStorageFieldValueType } from 'types/persistentStorage';

export const setPersistentStorageFieldData = (
  storageKey: string,
  fieldKey: number,
  fieldValue: PersistentStorageFieldValueType,
) => ({
  type: SET_PERSISTENT_STORAGE_FIELD_DATA,
  payload: {
    storageKey,
    fieldKey,
    fieldValue,
  },
});

export const deletePersistentStorageDataByKey = (key: string) => ({
  type: DELETE_PERSISTENT_STORAGE_DATA_BY_KEY,
  payload: key,
});

export const restoreDataFromPersistentStorage = (key: string) => ({
  type: RESTORE_DATA_FROM_PERSISTENT_STORAGE,
  payload: key,
});

export const setRestoreDataModalVisibility = (wasVisible: boolean) => ({
  type: SET_RESTORE_DATA_MODAL_VISIBILITY,
  payload: wasVisible,
});