import { useCallback, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Descendant, Editor as SlateEditor, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { FIELD_GROUPING_VALUE } from 'constants/editors';
import usePermissionUsingRememberFormProgress from 'hooks/usePermissionUsingRememberFormProgress';
import { updateFieldByKey } from 'store/actions/pdfTemplates';
import { setPersistentStorageFieldData } from 'store/actions/persistentReduxStorage';
import {
  saveTemporaryPIIUserDataInDatabase,
  updateFormBuilderField,
  updateGroupedField,
} from 'store/actions/publicPages';
import { RootStateType } from 'store/reducers';
import { DocumentTypesType } from 'types/Documents';
import { FieldGroupByType, ICustomElement } from 'types/Editor';
import { PDFFieldType, PDFFieldTypeObject } from 'types/PdfTemplates';
import {
  AggregatedFieldsStructureType,
  IPublicPageGeneralStructure,
  IPublicPageState,
} from 'types/PublicPage';
import { getGroupedFields } from 'utils/editorFieldHelpers';
import {
  getIsDocumentType,
  getIsDocumentWithFormBuilder,
  getIsFormBuilder,
  getIsFormDocument,
  getIsPDFDocument,
} from 'utils/PublicPage/documentTypeChecker';
import { getFieldsFromChildren } from 'utils/PublicPage/getFieldsFromData';
import {
  isNewFormDocumentPage,
  isNewPDFDocumentPage,
  isNewSlateDocumentPage,
} from 'utils/routeHelpers';

type UseGroupedFieldsProps = {
  editor?: SlateEditor | null,
}

const getFieldGropedByFilterKeys = (
  currentField: Partial<ICustomElement | PDFFieldType>,
  groupedFieldsStructure?: AggregatedFieldsStructureType[],
): number[] => {
  if (!groupedFieldsStructure?.length) return [];
  const fieldsByFilter = groupedFieldsStructure.filter((field) => (
    field.groupBy === FIELD_GROUPING_VALUE.BY_FILTER
    && field.filterName === currentField.name
  ));
  if (!fieldsByFilter) return [];
  return fieldsByFilter
    .map((item) => item.keys)
    .flat();
};

const useFieldsHandler = ({
  editor = null,
}: UseGroupedFieldsProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { templateDetails, documentDetails } = useSelector((state: RootStateType) => state.user);
  const { main, groupedFieldsStructure }: IPublicPageGeneralStructure = useSelector((state: RootStateType) => (
    state.publicPages.structure
  ));
  const { data: publicPageData, currentDocument }: IPublicPageState = useSelector((state: RootStateType) => (
    state.publicPages
  ));
  const pdfTemplateFields: PDFFieldTypeObject = useSelector((state: RootStateType) => (
    state.pdfTemplates.pdfTemplateFields
  ));

  const isPermissionRememberProgress: boolean = usePermissionUsingRememberFormProgress();

  const type: DocumentTypesType | undefined = currentDocument?.documentType
    || templateDetails?.type
    || documentDetails?.type;

  const isDocument: boolean = getIsDocumentType(type) || isNewSlateDocumentPage(location.pathname);
  const isForm: boolean = getIsFormDocument(type) || isNewFormDocumentPage(location.pathname);
  const isPDF: boolean = getIsPDFDocument(type) || isNewPDFDocumentPage(location.pathname);

  const isFormBuilderView: boolean = getIsFormBuilder(publicPageData?.view_mode);
  const isDocumentWithFormBuilderView: boolean = getIsDocumentWithFormBuilder(publicPageData?.view_mode);

  const pdfTemplateFieldsRef = useRef(pdfTemplateFields);
  pdfTemplateFieldsRef.current = pdfTemplateFields;

  const updateFieldsHandler = useCallback((
    currentField: Partial<ICustomElement | PDFFieldType>,
    updatedFieldData: Partial<ICustomElement | PDFFieldType>,
  ) => {
    if (!currentField) return;

    const fields: Partial<ICustomElement | PDFFieldType>[] = isPDF
      ? Object.values(pdfTemplateFieldsRef.current)
      : [];

    if (editor && (isDocument || isForm)) {
      getFieldsFromChildren(editor.children as Descendant[], (field) => {
        fields.push(field);
      });
    }

    const groupedFields: Partial<ICustomElement | PDFFieldType>[] = getGroupedFields(fields, currentField.key ?? 0);

    groupedFields.forEach((field: Partial<ICustomElement | PDFFieldType>) => {
      if (editor && (isDocument || isForm)) {
        const fieldPath = ReactEditor.findPath(editor, field as Descendant);
        Transforms.setNodes(editor, updatedFieldData, { at: fieldPath });
      }
      if (isPDF) {
        dispatch(updateFieldByKey(
          field.key ?? 0,
          { ...field, ...updatedFieldData } as PDFFieldType,
        ));
      }
      if (isPermissionRememberProgress) {
        dispatch(setPersistentStorageFieldData(main.id, field.key ?? 0, updatedFieldData));
      }
    });

    if (publicPageData) {
      const keysGroupedFieldsByFilter: number[] = getFieldGropedByFilterKeys(currentField, groupedFieldsStructure)
        .filter((key: number) => key !== currentField.key);

      if (keysGroupedFieldsByFilter.length && !!updatedFieldData.value) {
        const groupedByFilterFields: Partial<ICustomElement | PDFFieldType>[] = fields
          .filter((field: Partial<ICustomElement | PDFFieldType>) => (
            field.key && keysGroupedFieldsByFilter.includes(field.key) && field.subtype
          ));

        groupedByFilterFields.forEach((field: Partial<ICustomElement | PDFFieldType>) => {
          if (editor && (isDocument || isForm)) {
            const fieldPath = ReactEditor.findPath(editor, field as Descendant);
            Transforms.setNodes(editor, { groupByKey: updatedFieldData.value }, { at: fieldPath });
          }
          if (isPDF) {
            dispatch(updateFieldByKey(
              field.key ?? 0,
              { ...field, groupByKey: updatedFieldData.value } as PDFFieldType,
            ));
          }
        });

        dispatch(saveTemporaryPIIUserDataInDatabase(
          groupedByFilterFields.map((field: Partial<ICustomElement | PDFFieldType>) => ({
            subtype: field.subtype as string,
            value: field.value as string,
            groupBy: field.groupBy as FieldGroupByType,
            groupByKey: updatedFieldData.value,
            filterName: field.filterName,
          })),
        ));
      }

      if (updatedFieldData.value) {
        dispatch(updateGroupedField(currentField.key ?? 0, updatedFieldData.value ?? ''));
        if (currentField.subtype) {
          dispatch(saveTemporaryPIIUserDataInDatabase([{
            subtype: currentField.subtype,
            value: updatedFieldData.value,
            groupBy: currentField.groupBy,
            groupByKey: currentField.groupByKey,
            filterName: currentField.filterName,
          }]));
        }
      }

      if (currentField.key && (isFormBuilderView || isDocumentWithFormBuilderView)) {
        dispatch(updateFormBuilderField([currentField.key], { ...updatedFieldData }));
        const keysGroup = groupedFieldsStructure.find((group) => (
          group.keys.includes(currentField.key ?? 0)
        ));
        if (keysGroup) {
          const keys: number[] = keysGroup.keys;
          dispatch(updateFormBuilderField(keys, { ...updatedFieldData }));
          if (keysGroupedFieldsByFilter.length) {
            dispatch(updateFormBuilderField(
              keysGroupedFieldsByFilter,
              { groupByKey: updatedFieldData.value },
            ));
          }
        }
      }
    }
  }, [
    isPDF,
    editor,
    isDocument,
    isForm,
    publicPageData,
    isPermissionRememberProgress,
    dispatch,
    main.id,
    groupedFieldsStructure,
    isFormBuilderView,
    isDocumentWithFormBuilderView,
  ]);

  return {
    updateFieldsHandler,
  };
};

export default useFieldsHandler;