/* eslint-disable */
import React, { useMemo, useRef } from 'react';
import { RenderElementProps, useSelected, useSlate } from 'slate-react';
import { Editor, Element as SlateElement, NodeEntry } from 'slate';
import { options } from './options';
import { TableCardbar, HorizontalToolbar, VerticalToolbar } from './ui';

export const Table: React.FC<RenderElementProps> = props => {
  const { attributes, children, element } = props;
  const selected = useSelected();
  const editor = useSlate();

  switch (element.type) {
    case 'table': {
      let existSelectedCell = false;
      let table: NodeEntry | null = null;

      if (selected && editor.selection) {
        [table] = Array.from(
          Editor.nodes(editor, {
            match: n => SlateElement.isElement(n) && n.type === 'table',
            at: Editor.path(editor, editor.selection),
          })
        );

        if (table) {
          const [selectedCell] = Array.from(
            Editor.nodes(editor, {
              at: Editor.range(editor, table[1]),
              match: n => (SlateElement.isElement(n) && n.selectedCell) || false,
            })
          );

          if (selectedCell) {
            existSelectedCell = true;
          }
        }
      }
      const classCard = (selected || existSelectedCell) ? "selected" : "";

      return (
        <div className="position-relative">
          <TableCardbar className={classCard} />
          <TableComponent {...props} table={table}>{children}</TableComponent>
        </div>
      );
    }

    case 'table-row': {
      return (
        <tr
          {...attributes}
          className="table-tr"
          slate-table-element="tr"
          data-key={element.uuid}
          onDrag={e => e.preventDefault()}
        >{children}</tr>
      );
    }

    case 'table-cell': {
      const node = {
        width: element.width || 0,
        height: element.height || 0,
        selectedCell: element.selectedCell || false,
        colspan: element.colspan || 1,
        rowspan: element.rowspan || 1,
      };
      return (
        <CellComponent
          {...props}
          uuid={element.uuid || ""}
          node={node}
        >
          {children}
        </CellComponent>
      );
    }

    case 'table-content': {
      return (
        <div slate-table-element="content" className="table-content">
          {children}
        </div>
      );
    }

    default:
      return <p {...props} />;
  }
};

const TableComponent: React.FC<{
  table: NodeEntry | null;
} & RenderElementProps> = props => {
  const { table, children } = props;
  const selected = useSelected();
  const ref = useRef<HTMLTableElement>(null);

  const ResizeToolbar = useMemo(() => {
    return (
      selected &&
      ref.current &&
      table && (
        <>
          <HorizontalToolbar table={ref.current} tableNode={table} />
          <VerticalToolbar table={ref.current} tableNode={table} />
        </>
      )
    );
  }, [selected, table]);

  return (
    <>
      {ResizeToolbar}
      <table
        className="w-100 table table-bordered mb-1"
        slate-table-element="table"
        ref={ref}
        style={options.tableStyle}
      >{children}</table>
    </>
  );
};

const CellComponent: React.FC<{
  node: {
    width: number;
    height: number;
    selectedCell?: boolean;
    colspan?: number;
    rowspan?: number;
  };
  uuid: string;
} & RenderElementProps> = ({ attributes, node, uuid, children }) => {
  const { selectedCell } = node;

  return (
    <td
      {...attributes}
      className={`border table-td${selectedCell ? " selectedCell" : ""}`}
      slate-table-element="td"
      data-key={uuid}
      colSpan={node.colspan || 1}
      rowSpan={node.rowspan || 1}
      onDragStart={e => e.preventDefault()}
      style={{
        width: node.width ? `${node.width}px` : 'auto',
        height: node.width ? `${node.height}px` : 'auto',
      }}>{children}</td>
  );
};