import { MutableRefObject, ReactElement, FC } from 'react';

interface IScrollToBottom {
  children: (scrollToBottom: () => NodeJS.Timeout | null) => ReactElement | null;
  scrollableNode: MutableRefObject<HTMLDivElement | null> | Element | null;
}

const ScrollToBottom: FC<IScrollToBottom> = ({
  children,
  scrollableNode,
}) => {
  if (!scrollableNode) {
    return children(() => null);
  }

  const scrollToBottom = (): void => {
    if ((scrollableNode as MutableRefObject<HTMLDivElement | null>).current) {
      const scrollableNodeRef: MutableRefObject<HTMLDivElement> = scrollableNode as MutableRefObject<HTMLDivElement>;

      const scrollableNodeHeight: number = scrollableNodeRef.current.scrollHeight;
      scrollableNodeRef.current.scrollTo({ behavior: 'smooth', top: scrollableNodeHeight });
    } else {
      const scrollableElement = scrollableNode as Element;
      const scrollableElementHeight: number = scrollableElement.scrollHeight;
      scrollableElement.scrollTo({ behavior: 'smooth', top: scrollableElementHeight });
    }
  };

  return children(() => setTimeout(scrollToBottom, 0));
};

export default ScrollToBottom;