import ENDPOINT from 'constants/endpoints';
import { IResponseOfRequest } from 'types/Api';
import { PostStyleSchemaType } from 'types/BrandingColorSchema';
import { ICompanyBranding, ICompanyLogoResponse } from 'types/CompanyBranding';
import axiosInstance from 'utils/axiosInstance';

export const getCompanyBranding = (): Promise<IResponseOfRequest<ICompanyBranding>> => (
  axiosInstance.get(ENDPOINT.COMPANY_BRANDING)
);

export const postCompanyLogo = async (file: File): Promise<IResponseOfRequest<ICompanyLogoResponse>> => {
  const formData = new FormData();
  formData.append('logo', file, file.name);
  const data: IResponseOfRequest<ICompanyLogoResponse> = await axiosInstance
    .post(ENDPOINT.COMPANY_BRANDING_LOGO, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  return data;
};

export const deleteCompanyLogo = () => (
  axiosInstance.delete(ENDPOINT.COMPANY_BRANDING_LOGO)
);

export const postCompanyStyleSchema = async (colorSchema: PostStyleSchemaType) => (
  axiosInstance.post(ENDPOINT.COMPANY_BRANDING, colorSchema)
);