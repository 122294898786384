import { FC } from 'react';

import SignersContainer from 'components/SendDocuments/SignersContainer';
import ButtonAddOutline from 'components/Sidebar/ButtonAddOutline';
import { useCarbonCopy } from 'components/SignNow/CarbonCopyContext';

const CarbonCopy: FC = () => {
  const {
    carbonCopyUsers,
    setCarbonCopyUsers,
    addCarbonCopyHandler,
  } = useCarbonCopy();

  return (
    <div>
      <ButtonAddOutline
        onClick={addCarbonCopyHandler}
        title="Add CC"
      />
      <div>
        <SignersContainer
          signersArray={carbonCopyUsers}
          setSignersArray={setCarbonCopyUsers}
          canDeleteCard
          isSignNow
        />
      </div>
    </div>
  );
};

export default CarbonCopy;