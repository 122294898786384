import { Dispatch, FC, SetStateAction } from 'react';

import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import PageSettingsComponent from 'components/PageSettings/PageSettingsComponent';
import RemindersSettingsComponent from 'components/PageSettings/RemindersSettingsComponent';

interface IPageSettingsProps {
  showPageSettingsModal: boolean;
  setShowPageSettingsModal: Dispatch<SetStateAction<boolean>>;
}

const PageSettingsModal: FC<IPageSettingsProps> = ({
  showPageSettingsModal,
  setShowPageSettingsModal,
}) => (
  <Modal centered isOpen={showPageSettingsModal} contentClassName="document-info mx-auto">
    <ModalHeader className="modal-header border-0" toggle={() => setShowPageSettingsModal(false)}>
      <div className="description-title text-center">
        Page Settings
      </div>
    </ModalHeader>
    <ModalBody className="page-settings-modal">
      <PageSettingsComponent />
      <RemindersSettingsComponent />
    </ModalBody>
  </Modal>
);

export default PageSettingsModal;