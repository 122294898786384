import { FC, memo } from 'react';

import { IRenderElementProps } from 'components/Editor/components/RenderElement';
import { getFieldFontStyles } from 'utils/Fields/fieldFontSizeHelpers';

export interface IRenderFillerProps {
  inline?: boolean;
  childrenForLeaf?: IRenderElementProps['children'];
}

const SlateFieldFiller: FC<IRenderFillerProps> = ({ childrenForLeaf, inline = false }) => {
  const additionalStyles = getFieldFontStyles(childrenForLeaf, '');

  if (inline) {
    return (
      <span contentEditable={false} style={{ userSelect: 'none', ...additionalStyles }} className="d-inline" />
    );
  }

  return (
    <span className="me-1" contentEditable={false} style={{ userSelect: 'none', ...additionalStyles }}>:</span>
  );
};

export default memo(SlateFieldFiller);