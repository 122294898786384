export const DEFAULT_MARGIN_X_IN_INCHES = 1;
export const DEFAULT_MARGIN_Y_IN_INCHES = 1;

export const PAGE_SETTINGS_MARGINS = {
  Top: 'margin_top',
  Right: 'margin_right',
  Bottom: 'margin_bottom',
  Left: 'margin_left',
} as const;

export const PAGE_SETTINGS_MARGIN_KEY = Object.values(PAGE_SETTINGS_MARGINS);

export const DISABLE_GET_STARTED_POP_UP_KEY = 'disable_get_started_pop_up';
export const DO_NOT_SEND_COMPLETED_PDF_KEY = 'do_not_send_completed_pdf';
export const ALLOW_MULTIPLE_SUBMISSIONS_IN_SESSION = 'allow_multiple_submissions_in_session';
export const VERIFY_IDENTITY_VIA_SMS = 'verify_identity_via_sms';
export const COMBINE_ATTACHMENTS_IN_DOWNLOADED_PDF = 'combine_attachments_in_downloaded_pdf';
export const SAVE_IN_PROGRESS_SUBMISSIONS = 'save_in_progress_submissions';

export const REMINDERS_PROPERTIES = {
  ENABLE_EMAIL_REMINDER: 'enable_email_reminder',
  SEND_EMAIL_DELAY_DAYS: 'send_email_delay_days',
  ENABLE_SMS_REMINDER: 'enable_sms_reminder',
  SEND_SMS_DELAY_DAYS: 'send_sms_delay_days',
} as const;