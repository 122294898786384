const CloseOpenSidebarIcon = () => (
  <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 15V17H1V15H19ZM4.596 0.903992L6.01 2.31799L2.828 5.49999L6.01 8.68199L4.596 10.096L0 5.49999L4.596
      0.903992ZM19 7.99999V9.99999H10V7.99999H19ZM19 0.999992V2.99999H10V0.999992H19Z"
      fill="#2F3947"
    />
  </svg>
);

export default CloseOpenSidebarIcon;