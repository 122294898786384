import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Descendant } from 'slate';

import { ShareViewModes } from 'constants/shareViewModes';
import usePIIUserData from 'hooks/usePIIUserData';
import useReadOnlyMode from 'hooks/useReadOnlyMode';
import { updateFieldByKey } from 'store/actions/pdfTemplates';
import { setAutoFillPIIData, setGroupedFieldsStructure, updateFormBuilderField } from 'store/actions/publicPages';
import { resetUserPIIData } from 'store/actions/userPersonalData';
import { RootStateType } from 'store/reducers';
import { ICustomElement } from 'types/Editor';
import { PDFFieldType } from 'types/PdfTemplates';
import { IPublicPageGeneralStructure } from 'types/PublicPage';
import { PIIDataType } from 'types/userProfile';
import { updateSubtypeContentNodes } from 'utils/editorFieldHelpers';
import {
  extractFieldsByKeys,
  mergeValueFromPIIDataToGropedFieldsStructure,
} from 'utils/PIIAndGrouping/groupedAndPIIFieldsHelper';
import { updatePIIFieldData } from 'utils/PublicPage/autoFillDataHelper';
import { getIsDocumentWithFormBuilder, getIsFormBuilder } from 'utils/PublicPage/documentTypeChecker';

interface IUseAutoFillFieldsProps {
    contentData?: Descendant[];
    updateContent?: (contentData: Descendant[]) => void;
}

const useAutoFillFields = ({
  contentData = [],
  updateContent = () => null,
}: IUseAutoFillFieldsProps) => {
  const dispatch = useDispatch();
  const userPIIData: PIIDataType[] = usePIIUserData();
  const isReadOnlyMode: boolean = useReadOnlyMode();

  const {
    autoFillPIIData,
    data,
    structure,
    currentDocument,
    autofillPIIMondayItem,
  } = useSelector((state: RootStateType) => state.publicPages);
  const { groupedFieldsStructure }: IPublicPageGeneralStructure = structure;

  const pdfTemplateFields = useSelector((state: RootStateType) => state.pdfTemplates.pdfTemplateFields);

  const isFormBuilderView: boolean = getIsFormBuilder(data?.view_mode);
  const isDocumentWithFormBuilderView: boolean = getIsDocumentWithFormBuilder(data?.view_mode);

  const autoFillPIIFieldsInContentHandler = (piiData: PIIDataType[]) => {
    const updatedFields: Partial<ICustomElement>[] = extractFieldsByKeys(
      groupedFieldsStructure,
      piiData,
    );

    dispatch(setGroupedFieldsStructure(mergeValueFromPIIDataToGropedFieldsStructure(groupedFieldsStructure, piiData)));
    if (updatedFields.length) {
      const updatedContent = updateSubtypeContentNodes(contentData, updatedFields);
      updateContent(updatedContent);

      updatedFields.forEach((field) => {
        const fields = Object.values(pdfTemplateFields) as PDFFieldType[];
        const pdfField = fields.find((item) => item.key === field.key);

        if (pdfField) {
          dispatch(updateFieldByKey(field.key ?? 0, {
            ...pdfField,
            value: field.value ?? '',
            ...(field.groupByKey && { groupByKey: field.groupByKey }),
          }));
        }

        if (currentDocument.viewMode === ShareViewModes.SHARE_MODE_FORM_BUILDER) {
          dispatch(updateFormBuilderField([field.key ?? 0], { value: field.value }));
        }
      });
      dispatch(resetUserPIIData(piiData));
    }
  };

  useEffect(() => {
    if (
      autoFillPIIData
      && userPIIData.length
      && !isReadOnlyMode
      && (
        contentData.some((node) => node.key)
        || Object.keys(pdfTemplateFields).length
        || isFormBuilderView
        || isDocumentWithFormBuilderView
      )
    ) {
      let updatedPIIUserData = userPIIData;
      if (autofillPIIMondayItem) {
        updatedPIIUserData = updatePIIFieldData(
          groupedFieldsStructure,
          userPIIData,
          autofillPIIMondayItem.title,
          autofillPIIMondayItem.value,
        );
      }
      autoFillPIIFieldsInContentHandler(updatedPIIUserData);
      /** We run autofilling only once on Public and Fill&Sign pages */
      dispatch(setAutoFillPIIData(false));
    }
  }, [autoFillPIIData, userPIIData, contentData, pdfTemplateFields]);

  return autoFillPIIFieldsInContentHandler;
};

export default useAutoFillFields;