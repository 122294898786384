import { FC, useEffect, useState } from 'react';

import { Descendant } from 'slate';

import SearchFieldIconAndDropDown from 'components/Editor/EditorDevTools/SearchFieldIconAndDropDown';
import Search from 'components/Search';
import { replaceText } from 'utils/editorHelpers';

interface ISearchReplaceFormProps {
  content: Descendant[];
  setContent: (data: Descendant[]) => void;
  setString: (value: string) => void;
  showSearch?: boolean;
  isReadOnly?: boolean;
}

const SearchReplaceForm: FC<ISearchReplaceFormProps> = ({
  content,
  setContent,
  setString,
  showSearch = false,
  isReadOnly = false,
}) => {
  const [searchString, setSearchString] = useState<string>('');
  const [replaceString, setReplaceString] = useState<string>('');

  const replaceAll = () => {
    if (isReadOnly) return;
    if (!searchString || !replaceString) return;
    setContent(replaceText(content, searchString, replaceString));
  };

  useEffect(() => {
    setString(searchString);
  }, [searchString]);

  return (
    <>
      <SearchFieldIconAndDropDown fieldList={[]} contentJSON={content} />
      {
        showSearch
          ? (
            <Search
              replaceAll={replaceAll}
              setReplaceString={setReplaceString}
              setSearchString={setSearchString}
              searchString={searchString}
              replaceString={replaceString}
              isReadOnly={isReadOnly}
            />
          )
          : null
      }
    </>
  );
};

export default SearchReplaceForm;