import { useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { SelectChangeEvent } from '@mui/material';

import { setCurrentSigner } from 'store/actions/signNow';
import { RootStateType } from 'store/reducers';
import { AssignmentSigner, AssignmentsObjectType, AssignmentType } from 'types/Editor';
import { UseSetSignerSelectHookType } from 'types/signNow/base';

const getAssignmentsList = (assignments: AssignmentsObjectType): AssignmentSigner[] => (
  Object
    .values(assignments)
    .flat()
    .map((assignment: AssignmentType, index): AssignmentSigner => ({
      type: assignment.type,
      label: assignment.label,
      position: assignment.position ?? index,
    }))
    .sort((a: AssignmentSigner, b: AssignmentSigner) => a.position - b.position)
);

const useSetSignerSelectMenu: UseSetSignerSelectHookType = () => {
  const dispatch = useDispatch();
  const assignments: AssignmentsObjectType = useSelector((state: RootStateType) => (
    state.editorSlate.assignments
  ));

  const signerAssignments: AssignmentSigner[] = useMemo(() => getAssignmentsList(assignments), [assignments]);

  const [signer, setSigner] = useState<string>('');

  useEffect(() => {
    dispatch(setCurrentSigner(signer));
  }, [dispatch, signer]);

  useEffect(() => {
    const signerExists = signerAssignments.some((assignment) => assignment.type === signer);

    if (!signerExists && signerAssignments.length > 0) {
      setSigner(signerAssignments[0].type);
    }
  }, [signer, signerAssignments]);

  const handleChange = useCallback(({ target }: SelectChangeEvent) => {
    setSigner(target.value);
  }, []);

  return {
    signer,
    handleChange,
    signerAssignments,
  };
};

export default useSetSignerSelectMenu;