import { FC } from 'react';

import getRequiredSymbol from 'utils/PublicPage/getRequiredSymbol';

interface IFieldNameProps {
  name: string,
  isRequired: boolean
  additionalClassName?: string;
  additionalStyles?: Record<string, string>;
}

const FieldName: FC<IFieldNameProps> = ({
  name,
  isRequired,
  additionalClassName = '',
  additionalStyles = {},
}) => {
  const requiredSymbol = getRequiredSymbol(isRequired);
  return (
    <p className={`mb-1 field-help-text ${additionalClassName}`} style={additionalStyles}>
      {name}
      <span>
        {requiredSymbol}
      </span>
    </p>
  );
};

export default FieldName;