import { FC, FormEvent, MutableRefObject } from 'react';

import UploadIcon from 'svg/UploadIcon';

interface IUploadTab {
  handlerClickButton: () => void;
  fileInputRef: MutableRefObject<HTMLInputElement | null>;
  handleUploadImage: (event: FormEvent<HTMLInputElement>) => void;
}

const UploadTab: FC<IUploadTab> = ({
  handlerClickButton,
  fileInputRef,
  handleUploadImage,
}) => (
  <div className="form-signature-wrapper form-signature-wrapper-upload">
    <div className="d-flex flex-column justify-content-center align-items-center w-100 py-3">
      <span className="mb-1 mt-5">
        <UploadIcon />
      </span>
      <div className="upload-message">Upload an image of your signature</div>
      <button
        type="button"
        className="button-main btn-upload p-2 mb-5 mt-2"
        onClick={handlerClickButton}
      >
        Select image
      </button>
      <input
        hidden
        name="upload-signature"
        multiple={false}
        ref={fileInputRef}
        type="file"
        accept="image/png,image/jpeg"
        onChange={handleUploadImage}
      />
    </div>
  </div>
);

export default UploadTab;