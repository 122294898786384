import { ICollectionExecutedDetails } from 'types/CollectionExecuted';
import { IDocumentDetails } from 'types/Documents';
import { ICollectionDetails } from 'types/MultiTemplate';
import { PublicPageDataType } from 'types/PublicPage';
import { ITemplateDetails } from 'types/Templates';

export const isTemplateType = (template: PublicPageDataType | null): template is ITemplateDetails => (
  (<ITemplateDetails>template)?.sections !== undefined
);

export const isDocumentType = (document: PublicPageDataType | null): document is IDocumentDetails => (
  (<IDocumentDetails>document)?.content_json !== undefined
);

export const isCollectionType = (collection: PublicPageDataType | null): collection is ICollectionDetails => (
  (<ICollectionDetails>collection)?.templates !== undefined
);

export const isCollectionExecutedType = (
  collectionExecuted: PublicPageDataType | null,
): collectionExecuted is ICollectionExecutedDetails => (
  (<ICollectionExecutedDetails>collectionExecuted)?.documents !== undefined
);