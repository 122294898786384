import { ChangeEvent, Dispatch, FC, MutableRefObject, SetStateAction, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import SignaturePad from 'react-signature-canvas';
import { ModalBody } from 'reactstrap';

import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

import DrawTab from 'components/Modals/SignatureModal/DrawTab';
import TypeTab from 'components/Modals/SignatureModal/TypeTab';
import UploadTab from 'components/Modals/SignatureModal/UploadTab';
import { SIGNATURE_TABS } from 'constants/signatures';
import { updateSaveSignatureProperty } from 'store/actions/userPersonalData';
import { RootStateType } from 'store/reducers';
import { isBase64String } from 'utils/editorFieldHelpers';

export interface IBodySignModal {
  isShownCanvas: boolean;
  handlerClear: (clearValue: boolean) => void;
  fileInputRef: MutableRefObject<HTMLInputElement | null>;
  existingValue: string | undefined;
  handleUploadImage: (event: React.FormEvent<HTMLInputElement>) => void;
  onBeginDraw: () => void;
  setSignaturePad: Dispatch<SetStateAction<SignaturePad | null>>;
  signatureTab: SIGNATURE_TABS;
  changeTypedSignature: (value: string) => void;
  typedSignature: string;
  signatureFieldVariation: string;
  shouldApplySignatureAutosaving: boolean;
}

const BodySignModal: FC<IBodySignModal> = ({
  isShownCanvas,
  onBeginDraw,
  handlerClear,
  fileInputRef,
  existingValue,
  setSignaturePad,
  handleUploadImage,
  signatureTab,
  changeTypedSignature,
  typedSignature,
  signatureFieldVariation,
  shouldApplySignatureAutosaving,
}) => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state: RootStateType) => state.user);
  const { personalData } = useSelector((state: RootStateType) => state.profile);
  const { save_signature: saveSignature } = personalData ?? {};

  const [isCheckSaveSignature, setIsCheckSaveSignature] = useState<boolean>(false);

  useEffect(() => {
    if (isAuthenticated) {
      setIsCheckSaveSignature(saveSignature ?? false);
    }
  }, [saveSignature, isAuthenticated]);

  const handlerChangeSaveSignature = (_: ChangeEvent, checked: boolean) => {
    dispatch(updateSaveSignatureProperty(checked));
  };

  const handlerSelectImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const isExistingValueInBase64: boolean = isBase64String(existingValue);

  const renderSignatureModalTab = (): JSX.Element => {
    if (isShownCanvas || signatureTab === SIGNATURE_TABS.DRAW_TAB) {
      return (
        <DrawTab
          existingValue={isExistingValueInBase64 ? existingValue : undefined}
          handlerClear={handlerClear}
          onBeginDraw={onBeginDraw}
          setSignaturePad={setSignaturePad}
        />
      );
    }

    if (signatureTab === SIGNATURE_TABS.UPLOAD_TAB) {
      return (
        <UploadTab
          fileInputRef={fileInputRef}
          handleUploadImage={handleUploadImage}
          handlerClickButton={handlerSelectImageClick}
        />
      );
    }

    return (
      <TypeTab
        changeTypedSignature={changeTypedSignature}
        typedSignature={typedSignature}
        handlerClear={handlerClear}
        existingValue={isExistingValueInBase64 ? undefined : existingValue}
        signatureFieldVariation={signatureFieldVariation}
      />
    );
  };

  return (
    <ModalBody>
      {renderSignatureModalTab()}
      {shouldApplySignatureAutosaving && (
        <FormGroup className="align-items-center">
          <FormControlLabel
            control={(
              <Checkbox
                checked={isCheckSaveSignature}
                onChange={handlerChangeSaveSignature}
              />
            )}
            label="Save Signature for future use"
          />
        </FormGroup>
      )}
    </ModalBody>
  );
};

export default BodySignModal;