import { Input } from 'reactstrap';

import { TEXTAREA_MAX_LEN } from 'constants/validation';
import useDebounceInputField from 'hooks/useDebounceInputField';
import { PDFPropsType } from 'types/PdfTemplates';
import { getMainTypeAssignment } from 'utils/assignmentsHelpers';
import { createNameConstant } from 'utils/createNameConstant';

const TextareaEditable: React.FC<PDFPropsType> = ({
  field,
  isReadOnlyMode,
  onChangeField,
  fieldStyle,
}) => {
  const processChange = (newValue: string) => {
    onChangeField({ target: { value: newValue } } as any, field);
  };

  const {
    localValue,
    processChangeWithDebounce,
  } = useDebounceInputField(
    field.value,
    processChange,
  );

  return (
    <Input
      type="textarea"
      rows="1"
      name={createNameConstant(field.fieldName)}
      className={`drag-and-drop-textarea pdf-field-${getMainTypeAssignment(field.assignment)}`}
      value={localValue}
      maxLength={TEXTAREA_MAX_LEN}
      autoComplete="off"
      disabled={isReadOnlyMode}
      placeholder={field.fieldName}
      onChange={processChangeWithDebounce}
      style={fieldStyle}
      data-pdf-field
    />
  );
};

export default TextareaEditable;