export const STRIPE_INTERVAL_PRIORITY: Record<string, number> = {
  day: 1,
  week: 7,
  month: 30,
  year: 365,
};

export const enum PaymentMethodEnum {
  AMERICAN_EXPRESS = 'amex',
  DINERS = 'diners',
  DISCOVER = 'discover',
  MAESTRO = 'maestro',
  MASTERCARD = 'mastercard',
  PAYPAL = 'paypal',
  VISA = 'visa',
}