import { FC, useCallback } from 'react';

import SignersContainer from 'components/SendDocuments/SignersContainer';
import ButtonAddOutline from 'components/Sidebar/ButtonAddOutline';
import { EMPTY_SIGNER } from 'constants/sendDocuments';
import { ISelectSigners } from 'types/SendDocuments';

const SelectDocumentSigners: FC<ISelectSigners> = ({
  signersArray,
  setSignersArray,
}) => {
  const handlerAddButton = useCallback(() => {
    setSignersArray([...signersArray, { ...EMPTY_SIGNER, position: signersArray.length }]);
  }, [setSignersArray, signersArray]);

  return (
    <div className="text-start">
      <div className="mt-3 select-signers-title">Please select and order signers</div>
      <SignersContainer signersArray={signersArray} setSignersArray={setSignersArray} />
      {signersArray.length === 1 ? <ButtonAddOutline onClick={handlerAddButton} title="Add signers" /> : null}
    </div>
  );
};

export default SelectDocumentSigners;