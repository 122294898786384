import { Dispatch } from 'redux';

import { Editor } from 'slate';

import { setSelectedTableQuestion } from 'store/actions/editorSlate';
import { ICustomElement } from 'types/Editor';
import { findColumnFieldIndexes } from 'utils/TableQuestion/tableQuestionHelpers';

export const cleanTableQuestionSelection = (
  dispatch: Dispatch,
  isTableField: boolean = false,
  selectedTableQuestionKey: number | null = null,
) => {
  if (!isTableField && selectedTableQuestionKey) {
    dispatch(setSelectedTableQuestion(null));
  }
};

export const updateFieldsInColumn = (editor: Editor, field: ICustomElement, newValues: Partial<ICustomElement>) => {
  if (field?.isTableField) {
    const indexes = findColumnFieldIndexes(editor, field);
    if (!indexes || !indexes.length) return;
    editor.findNodesAndUpdate((node) => {
      if (indexes.includes(node.key ?? 0)) {
        return {
          ...node,
          ...(newValues && { ...newValues }),
        };
      }
      return node;
    });
  }
};