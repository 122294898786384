/* eslint-disable */
export interface IDocumentWithCreationDate {
  creation_date: string;
}

const sortDocumentsByDate = <T extends IDocumentWithCreationDate>(a: T, b: T): number => {
  const aDate = a.creation_date;
  const bDate = b.creation_date;

  if (aDate > bDate) return -1;
  if (aDate < bDate) return 1;

  return 0;
};

export default sortDocumentsByDate;