import assignmentColors from 'constants/assignmentColors';

export const getHexColorFromIndex = (index: number): string => {
  if (index > 20) {
    const red = ((index * 150) % 256).toString(16).padStart(2, '0');
    const green = ((index * 40) % 256).toString(16).padStart(2, '0');
    const blue = ((index * 10) % 256).toString(16).padStart(2, '0');

    return `#${red}${green}${blue}`;
  }
  return assignmentColors[index];
};

export default undefined;