import { EMPTY_SIGNER } from 'constants/sendDocuments';
import { SignersInfoType, SignerType } from 'types/SendDocuments';

export const getNewUserCard = ({
  position,
  label = 'Signer',
  type = SignerType.RECIPIENT,
}: {
  position: number;
  label?: string;
  type?: SignerType;
}): SignersInfoType => ({
  ...EMPTY_SIGNER,
  type,
  position,
  label,
});

export default undefined;