import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import { SUCCESS } from 'constants/api';
import { EmailNotificationSettingsOption } from 'constants/notifications';
import { getEmailNotificationsSettings, updateEmailNotificationsSettings } from 'services/api/emailNotifications';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { IEmailNotificationsSettingsRequestParams } from 'types/emailNotificationsSettings';

interface IUseCompanyNotificationHandlerReturns {
  notificationValue: EmailNotificationSettingsOption | undefined;
  isLoading: boolean;
  onClickUpdateHandler: (value: EmailNotificationSettingsOption) => void;
  onClickResetHandler: () => void;
}

const useCompanyNotificationHandler = (): IUseCompanyNotificationHandlerReturns => {
  const [
    notificationValue,
    setNotificationValue,
  ] = useState<EmailNotificationSettingsOption>(EmailNotificationSettingsOption.OnlyMy);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getEmailNotificationsSettingsHandler = async () => {
      setIsLoading(true);
      const response = await apiErrorHandler<EmailNotificationSettingsOption>(getEmailNotificationsSettings);

      if (response.code === SUCCESS) {
        setNotificationValue(response.data);
      }
      setIsLoading(false);
    };

    getEmailNotificationsSettingsHandler();
  }, []);

  const onClickUpdateHandler = async (emailSettingsOption: EmailNotificationSettingsOption): Promise<void> => {
    const response = await apiErrorHandler<EmailNotificationSettingsOption, IEmailNotificationsSettingsRequestParams>(
      updateEmailNotificationsSettings,
      { emailSettingsOption },
    );
    if (response.code === SUCCESS) {
      setNotificationValue(response.data);
      toast.success('Email notification settings were updated successfully.');
    }
  };

  const onClickResetHandler = async () => {
    const response = await apiErrorHandler<EmailNotificationSettingsOption, IEmailNotificationsSettingsRequestParams>(
      updateEmailNotificationsSettings,
      { emailSettingsOption: EmailNotificationSettingsOption.OnlyMy },
    );

    if (response.code === SUCCESS) {
      setNotificationValue(response.data);
      toast.success('Email notification settings were changed by default.');
    }
  };

  return {
    onClickUpdateHandler,
    onClickResetHandler,
    notificationValue,
    isLoading,
  };
};

export default useCompanyNotificationHandler;