import React, { useState } from 'react';

import { Descendant } from 'slate';
import { useSlate } from 'slate-react';

import TableSizeSelector from 'components/Editor/components/TableSizeSelector';
import { BlockFormatType, IBlockButtonProps } from 'types/Editor';
import { insertTable, isTableActive } from 'utils/editorTableHelpers';

export interface IListActionTable {
  id: number;
  Component: React.FC<IBlockButtonProps>;
  icon: React.ReactNode | string;
  format: BlockFormatType;
  horizontalToolbar?: boolean;
}

const BlockButton: React.FC<IBlockButtonProps> = (props) => {
  const {
    format,
    icon,
    horizontalToolbar,
    key,
    title,
  } = props;
  const editor = useSlate();
  const active = isTableActive(editor);
  const [showSelector, setShowSelector] = useState<boolean>(false);

  const onMouseDownButton: React.MouseEventHandler<HTMLLIElement> = () => {
    setShowSelector(!showSelector);
  };

  const createTableNodes = (tableBody: Descendant[]) => {
    setShowSelector(false);
    return !active && insertTable(editor, format, tableBody);
  };

  return horizontalToolbar ? (
    <span
      onMouseEnter={onMouseDownButton}
      onMouseLeave={onMouseDownButton}
      className="btn-toolbar-editor horizontal-toolbar-icon"
    >
      {icon}
      {showSelector && <TableSizeSelector onCreateTableNodes={createTableNodes} styles={{ userSelect: 'none' }} />}
    </span>
  )
    : (
      <li
        key={key}
        className={`item-toolbar-editor table-toolbar-button ${active && 'text-dark'}`}
        style={{ stroke: active ? '#343a40' : '#ccc' }}
        onMouseEnter={onMouseDownButton}
        onMouseLeave={onMouseDownButton}
      >
        <div className="d-flex">
          <div className="icon">{icon}</div>
          <div>
            <div className="text-dark">{title}</div>
            <div className="command-description text-muted">Shortcut 4 + &#8984; or type - + space</div>
          </div>
        </div>
        {showSelector && <TableSizeSelector onCreateTableNodes={createTableNodes} />}
      </li>
    );
};

export default BlockButton;