import { FC } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import DropDownButton from 'components/Base/DropDownButton';
import SimpleSpinner from 'components/Base/SimpleSpinner';
import StripePaymentIcon from 'components/Payment/Stripe/PaymentMethods/StripePaymentIcon';
import Tooltip from 'components/Tooltip';
import { IStripePaymentMethod } from 'types/payment/Stripe';

interface IPaymentItemParams {
  isMethodUpdating: boolean;
  payment: IStripePaymentMethod;
  onDelete: (id: string) => void;
  onMakeDefault: (id: string) => void;
}

const PaymentItem: FC<IPaymentItemParams> = ({
  isMethodUpdating,
  payment,
  onDelete,
  onMakeDefault,
}) => (
  <li className="d-flex align-items-center w-100 gap-2">
    <StripePaymentIcon type={payment.brand} />
    <span className="d-flex flex-grow-1 flex-shrink-1 flex-column flex-sm-row">
      <span className="d-flex gap-2 flex-grow-1 flex-shrink-1 align-items-center">
        <span className="text-capitalize">
          {payment.brand}
        </span>
        <span>
          •••• {payment.last4}
        </span>
        {
          payment.isDefault && (
            <span className="stripe-payment-methods__default">
              Default
            </span>
          )
        }
      </span>
      <span>
        Expires {payment.expire}
      </span>
    </span>
    <span className="stripe-payment-methods__buttons-wrapper">
      {
        isMethodUpdating
          ? <SimpleSpinner small />
          : (
            <span>
              {
                payment.isDefault
                  ? (
                    <Tooltip title="Your default payment method can't be deleted because you have an active plan">
                      <span className="stripe-payment-methods__button stripe-payment-methods__button--disabled">
                        <CloseIcon />
                      </span>
                    </Tooltip>
                  )
                  : (
                    <DropDownButton
                      buttonClassName="stripe-payment-methods__button"
                      buttonNode={(
                        <MoreHorizIcon />
                      )}
                      menuItems={[
                        <button
                          className="stripe-payment-methods__menu-button"
                          key="make-default"
                          type="button"
                          onClick={() => onMakeDefault(payment.id)}
                        >
                          Make default
                        </button>,
                        <button
                          className="stripe-payment-methods__menu-button stripe-payment-methods__menu-button--red"
                          key="delete"
                          type="button"
                          onClick={() => onDelete(payment.id)}
                        >
                          Delete
                        </button>,
                      ]}
                    />
                  )
              }
            </span>
          )
      }
    </span>
  </li>
);

export default PaymentItem;