import { FC } from 'react';

import { Alert, Input, InputGroup } from 'reactstrap';

import Button from 'components/Base/Button';

export interface ISearchProps {
  searchString: string;
  setSearchString: (value: string) => void;
  replaceString: string;
  setReplaceString: (value: string) => void;
  replaceAll: () => void;
  isReadOnly?: boolean;
  apiVars?: any;
  errorApiVars?: string | null;
  needApiVars?: boolean;
  setApiVars?: (value: string) => void;
}

const Search: FC<ISearchProps> = (props) => {
  const {
    searchString,
    setSearchString,
    replaceString,
    setReplaceString,
    replaceAll,
    isReadOnly = false,
    apiVars = null,
    errorApiVars = null,
    needApiVars = false,
    setApiVars = () => null,
  } = props;

  const onChangeSearchString: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearchString(event.target.value);
  };

  const onChangeReplaceString: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (isReadOnly) return;
    setReplaceString(event.target.value);
  };

  const onClickReplaceAll = () => replaceAll();

  return (
    <>
      <Input
        className="mb-4 mt-4"
        placeholder="Search..."
        value={searchString}
        onChange={onChangeSearchString}
      />
      <InputGroup className="mb-4">
        <Input
          value={replaceString}
          placeholder="Replace"
          onChange={onChangeReplaceString}
          disabled={isReadOnly}
        />
        <Button
          classes="m-0 button-simple button-contained-pink"
          variant="contained"
          size="small"
          onClick={onClickReplaceAll}
          disabled={isReadOnly}
        >
          Replace All
        </Button>
      </InputGroup>
      <div className="dropdown-divider w-100" />
      {
        needApiVars
          && (
          <>
            <Input
              type="textarea"
              className="mb-4 mt-4 h-25"
              defaultValue={JSON.stringify(apiVars)}
              onChange={(e) => setApiVars(e.target.value)}
            />
            {needApiVars && !!errorApiVars && (
              <Alert color="danger">
                {errorApiVars}
              </Alert>
            )}
          </>
          )
      }
    </>
  );
};

export default Search;