/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Descendant } from 'slate';
import { TABLE_MAX_SIZE } from 'constants/tables';
import { createTableBody } from 'utils/editorTableHelpers';

export interface ITableSizeSelector {
  onCreateTableNodes: (tableBody: Descendant[]) => void;
  styles?: React.CSSProperties;
}

export interface ITableSizeObject {
  columns: number;
  rows: number;
}

const TableSizeSelector: React.FC<ITableSizeSelector> = (props) => {

  const { onCreateTableNodes, styles } = props;
  const [tableSize, setSize] = useState<ITableSizeObject>({ columns: 2, rows: 2 });
  const [tableInput, setTableInput] = useState(
    Array.from({ length: TABLE_MAX_SIZE }, () =>
      Array.from({ length: TABLE_MAX_SIZE }, (v, i) => ({
        bg: 'lightGray',
        column: i
      }))
    )
  );

  useEffect(() => {
    const newTable = Array.from({ length: TABLE_MAX_SIZE }, (obj, row) =>
      Array.from({ length: TABLE_MAX_SIZE }, (v, col) => ({
        bg:
          row + 1 <= tableSize.rows && col + 1 <= tableSize.columns
            ? 'orange'
            : 'lightGray',
        column: col
      }))
    );
    setTableInput(newTable);
  }, [tableSize]);

  const onSelectSizeButton = (): any => {
    const tableBody = createTableBody(tableSize.columns, tableSize.rows);
    setSize({ rows: -1, columns: -1 });
    return onCreateTableNodes(tableBody);
  }

  return (
    <div className="popup select-table-size" style={styles}>
      {tableSize.rows >= 1 && (
        <div>{`${tableSize.rows} x ${tableSize.columns}`}</div>
      )}
      <div className="table-input">
        {tableInput.map((grp, row) =>
          grp.map(({ column, bg }) => (
            <div
              key={`${row}${column}`}
              onClick={() => onSelectSizeButton()}
              onMouseOver={() =>
                setSize({ rows: row + 1, columns: column + 1 })
              }
              className="table-unit"
              style={{ border: `1px solid ${bg}` }}
            ></div>
          ))
        )}
      </div>
    </div>
  );
};

export default TableSizeSelector;