import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Element as SlateElement } from 'slate';

import { RootStateType } from 'store/reducers';
import { PDFFieldType } from 'types/PdfTemplates';
import { areAllRequiredFieldsFilledIn } from 'utils/PublicPage/fieldHelpers';

const useShowFieldsFilledInMessage = <T extends Partial<SlateElement> | PDFFieldType>(
  fields: T[],
  readOnlyMode: boolean = false,
) => {
  const { validationFields } = useSelector((state: RootStateType) => state.editorSlate.publicFormValidationResults);
  const isError = Object.values(validationFields).some((value) => value);
  const areRequiredFieldsDone: boolean = fields.length > 0 && areAllRequiredFieldsFilledIn(fields, isError);

  useEffect(() => {
    if (areRequiredFieldsDone && !readOnlyMode) {
      toast.success('All required fields are done.');
    }
  }, [areRequiredFieldsDone, readOnlyMode]);
};

export default useShowFieldsFilledInMessage;