import { FC } from 'react';

import DocumentNavigator from 'components/DocumentNavigator';
import EditorContainer from 'components/Editor/EditorContainer';
import PdfEditor from 'components/PdfEditor/PdfEditor';
import PdfFieldsSidebar from 'components/PdfEditor/PdfFieldsSidebar';
import SetSignersAndDocumentTitleHeader from 'components/SignNow/SetSignersAndDocumentTitleHeader';
import { SIGN_NOW_MOST_COMMON_FIELDS, SIGN_NOW_SIGNATURE_FIELDS } from 'constants/editors';
import { useSetFields } from 'hooks/signNow';
import { IStepManagerChildComponentProps } from 'types/signNow/base';

import 'scss/components/Editor/_editor.scss';

const SetFieldsAndEditor: FC<IStepManagerChildComponentProps> = ({
  onNextHandler,
}) => {
  useSetFields({
    onNextHandler,
  });

  return (
    <>
      <SetSignersAndDocumentTitleHeader />
      <EditorContainer>
        <DocumentNavigator
          panelTitle=""
        >
          <div>
            <div className="editor-navigator-title">
              Signature Fields
            </div>
            <PdfFieldsSidebar hasDescription={false} fields={SIGN_NOW_SIGNATURE_FIELDS} />
            <div className="mt-4 editor-navigator-title">
              Most Common Fields
            </div>
            <PdfFieldsSidebar hasDescription={false} fields={SIGN_NOW_MOST_COMMON_FIELDS} />
            <div className="mt-4 editor-navigator-title">
              Standard Fields
            </div>
            <PdfFieldsSidebar />
          </div>
        </DocumentNavigator>
        <PdfEditor visibleEditorDevTools />
      </EditorContainer>
    </>
  );
};

export default SetFieldsAndEditor;