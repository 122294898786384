import { FC, useRef } from 'react';

import { Descendant } from 'slate';

import Button from 'components/Base/Button';
import PublicFooter from 'components/PublicPage/PublicFooter';
import { FINISH_DOCUMENT, NEXT, NEXT_ADD_ATTACHMENT, NEXT_DOCUMENT } from 'constants/general';
import { PUBLIC_PAGE_STATUS } from 'constants/publicPage';
import { ATTACHMENTS_TAB_ID } from 'constants/tabs';
import usePublicPageState from 'hooks/usePublicPageState';
import useSliderValidation from 'hooks/useSliderValidation';
import useStyleSchema, { CustomStylesEnum } from 'hooks/useStylesFromSchema';
import ArrowRightIcon from 'svg/ArrowIcons/ArrowRightIcon';

import classes from 'scss/components/PublicFooter/PublicFooter.module.scss';

interface IPublicFooterWrapperParams {
  onSave: (status: PUBLIC_PAGE_STATUS, redirect?: boolean) => void;
  content: Descendant[];
}

const PublicFooterWrapper: FC<IPublicFooterWrapperParams> = ({
  onSave,
  content,
}) => {
  const footerRef = useRef<HTMLDivElement>(null);
  const styleSchema = useStyleSchema<CustomStylesEnum.button>([CustomStylesEnum.button]);

  const {
    isFormsView,
    isReadOnlyMode,
    emptyDocument,
    shouldShowNextButton,
    shouldShowAttachmentsButton,
    shouldShowFinishDocumentButton,
    isTheLastDocumentAndAllOthersDone,
  } = usePublicPageState();

  const {
    changeCurrentSlideIndex,
    validateAndSaveDocument,
    validateFieldsWithoutAttachmentsAndChangeTabIndex,
  } = useSliderValidation(
    isFormsView,
    content,
  );

  if (isReadOnlyMode && emptyDocument) {
    return null;
  }

  return (
    <PublicFooter
      ref={footerRef}
    >
      {
        (shouldShowAttachmentsButton && !isReadOnlyMode) && (
          <Button
            classes={classes.PublicButton}
            onClick={() => validateFieldsWithoutAttachmentsAndChangeTabIndex(ATTACHMENTS_TAB_ID)}
            style={styleSchema.button}
          >
            {NEXT_ADD_ATTACHMENT}
          </Button>
        )
      }
      {
        (shouldShowNextButton) && (
          <Button
            classes={classes.PublicButton}
            onClick={changeCurrentSlideIndex}
            endIcon={<ArrowRightIcon />}
            style={styleSchema.button}
          >
            {NEXT}
          </Button>
        )
      }
      {
        (shouldShowFinishDocumentButton) && (
          <Button
            classes={classes.PublicButton}
            onClick={() => validateAndSaveDocument(onSave)}
            style={styleSchema.button}
          >
            {isTheLastDocumentAndAllOthersDone ? FINISH_DOCUMENT : NEXT_DOCUMENT}
          </Button>
        )
      }
    </PublicFooter>
  );
};

export default PublicFooterWrapper;