import { createTheme } from '@mui/material';

import vars from 'variables.module.scss';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&.button': {
            backgroundColor: vars.brandPrimary,
            fontFamily: vars.mainFont,
            textTransform: 'capitalize',
            fontWeight: vars.mediumText,
            fontSize: vars.buttonFontSize,
            boxShadow: 'none',
            borderRadius: 6,
            minHeight: 48,
            padding: '10px 54px',
            transition: 'all .3s ease',
            color: vars.white,
            zIndex: 111,
            '&:hover': {
              backgroundColor: vars.brandPrimary,
              boxShadow: `0 4px 12px ${vars.brandPrimaryShadow}`,
            },
            '&:disabled': {
              backgroundColor: vars.lightGray,
              color: vars.mediumGray,
            },
          },
          '&.outline': {
            backgroundColor: 'transparent',
            fontFamily: vars.mainFont,
            textTransform: 'capitalize',
            fontWeight: vars.normalText,
            fontSize: vars.mainFontSize,
            boxShadow: 'none',
            borderRadius: 6,
            minHeight: 48,
            padding: '10px 24px',
            transition: 'all .3s ease',
            color: vars.blackColor,
            '&:hover': {
              color: vars.brandSecondary,
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
            '&:disabled': {
              backgroundColor: 'transparent',
              boxShadow: 'none',
              color: vars.mediumGray,
            },
          },
          '&.button-simple': {
            fontWeight: vars.normalText,
            fontSize: vars.mainFontSize,
            padding: '2px 0.5rem',
            margin: '0 3px',
            color: vars.blackColor,
            backgroundColor: vars.white,
            minHeight: 'unset',
            minWidth: 'auto',
            '&:hover': {
              backgroundColor: vars.veryLightGray,
              boxShadow: 'none',
            },
          },
          '&.close-button': {
            fontWeight: vars.normalText,
            fontSize: vars.mainFontSize,
            padding: '2px',
            margin: '0',
            color: vars.blackColor,
            backgroundColor: vars.white,
            minHeight: 'unset',
            minWidth: 'auto',
            '&:hover': {
              backgroundColor: vars.veryLightGray,
              boxShadow: 'none',
            },
          },
          '&.mobile-icon-button': {
            '@media screen and (max-width: 1199px)': {
              minHeight: 38,
              '& .MuiButton-startIcon': {
                marginRight: 0,
              },
            },
          },
          '&.button-outlined-pink': {
            border: `2px solid ${vars.brandPrimary}`,
            '&:hover': {
              backgroundColor: vars.veryLightGray,
            },
            '&.hovered-red:hover': {
              borderColor: 'red',
              backgroundColor: 'red',
              color: vars.white,
            },
            '&.mui-table-button': {
              borderRadius: 6,
              minHeight: 44,
              padding: '8px 54px',
              backgroundColor: vars.white,
              color: vars.blackColor,
              zIndex: 111,
            },
          },
          '&.outlined-with-border': {
            border: `2px solid ${vars.brandPrimary}`,
            fontWeight: vars.mediumText,
            color: vars.brandPrimary,
            '&:hover': {
              backgroundColor: 'transparent',
              border: `2px solid ${vars.brandPrimary}`,
              color: 'inherit!important',
              boxShadow: 'none',
            },
            '&:disabled': {
              border: `2px solid ${vars.brandPrimary}`,
            },
          },
          '&.button-contained-pink': {
            backgroundColor: vars.brandPrimary,
            color: vars.white,
            border: `2px solid ${vars.brandPrimary}`,
            '&:hover': {
              backgroundColor: vars.brandPrimary,
            },
          },
          '&.document-link': {
            fontFamily: vars.mainFont,
            minHeight: 50,
            backgroundColor: vars.veryLightGray,
            border: `1px solid ${vars.veryLightGray}`,
            borderRadius: 6,
            display: 'flex',
            justifyContent: 'flex-start',
            padding: '13px 50px 12px 15px',
            width: '100%',
            position: 'relative',
            transition: 'background-color 1s ease',
            textTransform: 'inherit',
            fontSize: '1em',
            lineHeight: '1em',
            marginBottom: '18px',
            '&:hover': {
              backgroundColor: vars.veryLightGray,
            },
            ' .MuiTouchRipple-child': {
              backgroundColor: vars.lightGray,
              opacity: 1,
            },
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          '&.user-menu-list': {
            '& li': {
              display: 'block',
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '&.select-field-autocomplete button': {
            padding: 3,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginRight: 0,
          marginLeft: 0,
          fontFamily: vars.mainFont,
          color: vars.darkGray,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: vars.lightGray,
          borderRadius: 100,
          height: 7,
        },
        bar: {
          backgroundColor: vars.brandPrimary,
        },
      },
    },
  },
});

export default theme;