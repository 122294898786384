import { IPublicPageGeneralStructure, PublicPageDataType } from 'types/PublicPage';
import {
  getPublicPageGroupedFieldsFromData,
} from 'utils/ManagerEditor/getGroupedFieldsFromTemplateArray';
import getStructureDocuments from 'utils/PublicPage/getStructureDocuments';

interface IGeneralStructureGetter {
  data: PublicPageDataType;
  id: string;
  type: string;
  salt?: string | null;
  decodedAssignment?: string;
}

const getGeneralDocumentStructure = (
  { type, id, data, salt, decodedAssignment }: IGeneralStructureGetter,
): IPublicPageGeneralStructure => ({
  main: {
    pageType: type,
    id,
    mainAssignment: decodedAssignment,
  },
  documents: getStructureDocuments({ data, salt, currentDocAssignment: decodedAssignment }),
  groupedFieldsStructure: getPublicPageGroupedFieldsFromData(data, type, decodedAssignment),
  formBuilderFields: {},
});

export default getGeneralDocumentStructure;