import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  SEND_EMAIL_IN_PII_RECALL_FLOW,
  SET_SHOW_RECALL_PII_DATA_MOCAL_WINDOW,
} from 'store/actions/actionTypes';
import { RootStateType } from 'store/reducers';

const useRememberPIIUserData = () => {
  const dispatch = useDispatch();
  const { showRecallPIIDataModalWindow, data } = useSelector((state: RootStateType) => state.publicPages);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (showRecallPIIDataModalWindow && data.sender?.enable_data_reuse) {
      setIsModalOpen(showRecallPIIDataModalWindow);
    }
  }, [showRecallPIIDataModalWindow, data]);

  const closeModalHandler = () => {
    setIsModalOpen(false);
    dispatch({ type: SET_SHOW_RECALL_PII_DATA_MOCAL_WINDOW, payload: false });
  };

  const closeAndSendEmailWithPasswordlessLink = () => {
    dispatch({ type: SEND_EMAIL_IN_PII_RECALL_FLOW });
    setIsModalOpen(false);
    dispatch({ type: SET_SHOW_RECALL_PII_DATA_MOCAL_WINDOW, payload: false });
  };

  return {
    isModalOpen,
    closeModalHandler,
    closeAndSendEmailWithPasswordlessLink,
  };
};

export default useRememberPIIUserData;