import { ChangeEvent, FC, KeyboardEvent, useState } from 'react';

import { Check } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';

import { KEY_CODE } from 'constants/editors';
import { SECTIONS_TITLE_MAX_LEN } from 'constants/validation';

interface IFormBuilderHeader {
  sectionName: string;
  changeSectionName: (sectionName: string) => void;
}

const FormBuilderHeader: FC<IFormBuilderHeader> = ({
  changeSectionName,
  sectionName,
}) => {
  const [isReadOnlyMode, setIsReadOnlyMode] = useState<boolean>(true);
  const [name, setName] = useState<string>(sectionName);

  return (
    <div className="form-builder_section-header">
      {isReadOnlyMode ? (
        <>
          <p className="section-name">{sectionName}</p>
          <span
            tabIndex={0}
            role="button"
            onClick={() => setIsReadOnlyMode(!isReadOnlyMode)}
          >
            <EditIcon className="icon" />
          </span>
        </>
      ) : (
        <>
          <input
            value={name}
            className="input"
            maxLength={SECTIONS_TITLE_MAX_LEN}
            onChange={
              (event: ChangeEvent<HTMLInputElement>) => setName(event.target.value)
            }
            onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
              if (event.key === KEY_CODE.ENTER) {
                setIsReadOnlyMode(true);
                changeSectionName(name);
              }
            }}
          />
          <span
            tabIndex={0}
            role="button"
            onClick={() => {
              setIsReadOnlyMode(!isReadOnlyMode);
              changeSectionName(name);
            }}
          >
            <Check />
          </span>
        </>
      )}
    </div>
  );
};

export default FormBuilderHeader;