import { Element as SlateElement } from 'slate';

import PUBLIC_PAGE_STATUS from 'constants/publicPage';
import { ShareViewModes } from 'constants/shareViewModes';
import { DOCUMENT_TAB_ID, FORM_TAB_ID } from 'constants/tabs';
import { IPublicPageDocumentStructure, PublicPageDataType } from 'types/PublicPage';
import { ITemplateSection } from 'types/redux';
import { getIsAttachmentField } from 'utils/Fields/fieldsTypeChecker';
import getSectionsFromContentJson from 'utils/getSectionsFromContentJson';
import {
  getIsDocumentWithFormBuilder,
  getIsFormBuilder,
  isFormDocument,
  isPDFDocument,
} from 'utils/PublicPage/documentTypeChecker';
import getFieldsFromData from 'utils/PublicPage/getFieldsFromData';
import { isCollectionType, isTemplateType } from 'utils/typeChecker';

interface IDocumentDataGetter {
  data: PublicPageDataType;
  assignment?: string;
  salt?: string | null;
  viewMode?: ShareViewModes;
}

const getDocumentData = ({ data, assignment, salt, viewMode }: IDocumentDataGetter): IPublicPageDocumentStructure => {
  const isPDF: boolean = isPDFDocument(data);
  const isForm: boolean = isFormDocument(data);
  const sections: ITemplateSection[] = getSectionsFromContentJson(data, assignment);

  const allFields = isTemplateType(data) && isPDF
    ? getFieldsFromData({ data, assignment, isPDF })
    : getFieldsFromData({ data, isPDF });
  const attachmentFields: Partial<SlateElement>[] = allFields.filter((field) => (
    getIsAttachmentField(field.type)
  ));

  const isEmptyDocument = allFields.filter((field) => field.assignment === assignment).length === 0;

  const documentData: IPublicPageDocumentStructure = {
    name: data.name,
    id: data.id,
    documentType: data.type,
    slides: sections,
    currentSlideIndex: 1,
    totalIndexSlides: sections.length,
    status: PUBLIC_PAGE_STATUS.NOT_OPEN,
    attachmentFields,
    emptyDocument: isEmptyDocument,
    activeEditorTab: isForm ? FORM_TAB_ID : DOCUMENT_TAB_ID,
    identifier: salt,
    viewMode: data.view_mode,
    pageSettings: data.page_settings,
  };

  const isDocumentWithFormBuilderView: boolean = getIsDocumentWithFormBuilder(data.view_mode);
  const isFormBuilderView: boolean = getIsFormBuilder(data.view_mode);

  if (isCollectionType(data) || (isTemplateType(data) && (isFormBuilderView || isDocumentWithFormBuilderView))) {
    const formBuilderSections = data.form_builder_structure?.filter((section) => (
      section.fields.length && section.fields.some((field) => field.assignment === assignment)
    )) || [];

    const attachmentFields: Partial<SlateElement>[] = [];
    formBuilderSections.forEach((section) => {
      section.fields.forEach((field) => {
        if (getIsAttachmentField(field.type)) {
          attachmentFields.push(field);
        }
      });
    });

    return {
      ...documentData,
      slides: formBuilderSections,
      totalIndexSlides: formBuilderSections.length,
      emptyDocument: false,
      activeEditorTab: isDocumentWithFormBuilderView && !isCollectionType(data) ? DOCUMENT_TAB_ID : FORM_TAB_ID,
      attachmentFields,
      ...(viewMode && { viewMode }),
    };
  }

  return documentData;
};

export default getDocumentData;