import { capitalizeString } from 'utils/stringsHelpers';

/* eslint-disable max-len */
export const GROUPED_FIELD_MESSAGES = {
  CHECK_SIMILAR: 'We have found similar grouped fields. Please re-check the field options to make sure values are correct.',
  SUBTYPE_RESET: 'Grouped fields cannot have a Subtype option selected, so the Subtype was reset.',
  GROUP_SELECTED: 'There is a Grouping option selected, so the field cannot have a Subtype yet.',
};

export const ADD_OTHER_OPTION = 'Add \'Other\' option';

export enum TEXT_FIELD_MASKS {
  NONE = 'none',
  LAST_FOUR = 'last_four',
  LAST_SIX = 'last_six',
  CUSTOM_TEXT = 'custom_text',
  HIDDEN = 'hidden',
}

export const FIELD_MASK_CUSTOM_TEXT_DEFAULT = 'Saved on File';

export const TEXT_FIELD_MASK_OPTIONS = [
  {
    id: 1,
    type: TEXT_FIELD_MASKS.NONE,
    label: capitalizeString(TEXT_FIELD_MASKS.NONE, '_'),
  },
  {
    id: 2,
    type: TEXT_FIELD_MASKS.LAST_FOUR,
    label: capitalizeString(TEXT_FIELD_MASKS.LAST_FOUR, '_'),
  },
  {
    id: 3,
    type: TEXT_FIELD_MASKS.LAST_SIX,
    label: capitalizeString(TEXT_FIELD_MASKS.LAST_SIX, '_'),
  },
  {
    id: 4,
    type: TEXT_FIELD_MASKS.CUSTOM_TEXT,
    label: capitalizeString(TEXT_FIELD_MASKS.CUSTOM_TEXT, '_'),
  },
  {
    id: 5,
    type: TEXT_FIELD_MASKS.HIDDEN,
    label: capitalizeString(TEXT_FIELD_MASKS.HIDDEN, '_'),
  },
];

export default null;