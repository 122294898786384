import { FC } from 'react';

import { ModalHeader } from 'reactstrap';

import { SIGNATURE_TABS } from 'constants/signatures';

export interface IHeaderSignModal {
  changeCurrentTab: (tab: SIGNATURE_TABS) => void;
  signatureTab: string;
  handlerCloseModal: () => void;
  title: string;
}

const HeaderSignModal: FC<IHeaderSignModal> = ({
  handlerCloseModal,
  changeCurrentTab,
  signatureTab,
  title,
}) => (
  <>
    <ModalHeader className="signature-header mb-2" toggle={handlerCloseModal}>
      {title}
    </ModalHeader>
    <div className="d-flex justify-content-around modal-signature-menu">
      <button
        type="button"
        className={`${signatureTab === SIGNATURE_TABS.DRAW_TAB ? 'active-tab' : ''} `}
        onClick={() => changeCurrentTab(SIGNATURE_TABS.DRAW_TAB)}
      >
        Draw
      </button>
      <button
        type="button"
        className={`${signatureTab === SIGNATURE_TABS.TYPE_TAB ? 'active-tab' : ''} `}
        onClick={() => changeCurrentTab(SIGNATURE_TABS.TYPE_TAB)}
      >
        Type
      </button>
      <button
        type="button"
        className={`${signatureTab === SIGNATURE_TABS.UPLOAD_TAB ? 'active-tab' : ''} `}
        onClick={() => changeCurrentTab(SIGNATURE_TABS.UPLOAD_TAB)}
      >
        Upload
      </button>
    </div>
  </>
);

export default HeaderSignModal;