import { FC } from 'react';

import { useLocation } from 'react-router-dom';

import GoogleTranslate from 'components/GoogleTranslate/GoogleTranslate';

import PublicHeader from 'components/PublicHeader';
import { URL_PUBLIC_FILL_OUT } from 'constants/general';

interface IPublicPageWrapperProps {
  isPublicPage: boolean;
  isManagerPublicPage: boolean;
}

const PublicPageWrapper: FC<IPublicPageWrapperProps> = ({
  isPublicPage,
  isManagerPublicPage,
  children,
}) => {
  const { pathname } = useLocation();
  const regex = new RegExp(`${URL_PUBLIC_FILL_OUT}`);
  const isFillOutPage = regex.test(pathname);

  if (isFillOutPage) {
    return (
      <>
        <GoogleTranslate />
        {!isManagerPublicPage && <PublicHeader />}
        {children}
      </>
    );
  }

  return (
    isPublicPage
      ? (
        <>
          <GoogleTranslate />
          <div className="public-page">
            <PublicHeader />
            {children}
          </div>
        </>
      )
      // eslint-disable-next-line react/jsx-no-useless-fragment
      : <>{children}</>
  );
};

export default PublicPageWrapper;