import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { ChromePicker, ColorChangeHandler, ColorResult } from 'react-color';

import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

import SettingsRow from 'components/AccountSettings/Branding/SettingsRow';
import { SchemaInputTypesEnum, STYLE_SCHEMA_STRUCTURE, StyleSchemaKeysEnum } from 'constants/brandingColorSchema';
import useStyleSchemaPermissions from 'hooks/useStyleSchemaPermissions';
import { StyleSchemaType, UseStyleSchemaInputsReturnsType } from 'types/BrandingColorSchema';
import { isEnableFontFieldType } from 'utils/CompanyBranding/typeChecker';

const useStyleSchemaInputs = (initialStyleSchema: StyleSchemaType): UseStyleSchemaInputsReturnsType => {
  const [colorSchema, setColorSchema] = useState<StyleSchemaType>(initialStyleSchema);

  const { styleSchemaPermissions } = useStyleSchemaPermissions();

  useEffect(() => {
    setColorSchema(initialStyleSchema);
  }, [initialStyleSchema]);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setColorSchema({
      ...colorSchema,
      [name]: value,
    });
  }, [colorSchema]);

  const handleCheck = useCallback((event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const { name } = event.target;
    setColorSchema({
      ...colorSchema,
      [name]: checked,
    });
  }, [colorSchema]);

  const onChangeColor: ColorChangeHandler = useCallback((color: ColorResult) => {
    setColorSchema({
      ...colorSchema,
      mainBrandColor: color.hex,
    });
  }, [colorSchema]);

  Object.keys(colorSchema).forEach((key) => {
    if (!(key in STYLE_SCHEMA_STRUCTURE)) {
      console.warn(
        `Key "${key}" is not in the COLOR_SCHEMA_STRUCTURE. Please add new key to the COLOR_SCHEMA_STRUCTURE.`,
      );
    }
  });

  const inputs = useMemo(() => Object.entries(STYLE_SCHEMA_STRUCTURE).map(([key, structure]) => {
    const styleSchemaKey = key as StyleSchemaKeysEnum;
    const value = colorSchema[styleSchemaKey];

    if (!styleSchemaPermissions[styleSchemaKey]) return null;

    switch (structure.type) {
      case SchemaInputTypesEnum.SELECT:
        return (
          <SettingsRow key={styleSchemaKey} label={structure.title}>
            <select
              className="branding-select-field"
              id={styleSchemaKey}
              name={styleSchemaKey}
              value={value as string}
              onChange={handleChange}
            >
              {
                structure.options?.map((option: unknown) => {
                  if (isEnableFontFieldType(option)) {
                    return (
                      <option
                        key={option.type}
                        value={option.type}
                      >
                        {option.label}
                      </option>
                    );
                  }
                  return null;
                })
              }
            </select>
          </SettingsRow>
        );
      case SchemaInputTypesEnum.COLOR:
        return (
          <SettingsRow key={styleSchemaKey} label={structure.title}>
            <ChromePicker
              disableAlpha
              color={value as string}
              onChange={onChangeColor}
            />
          </SettingsRow>
        );
      case SchemaInputTypesEnum.CHECKBOX:
        return (
          <FormGroup key={styleSchemaKey}>
            <FormControlLabel
              control={(
                <Checkbox
                  name={styleSchemaKey}
                  checked={value as boolean}
                  onChange={handleCheck}
                />
              )}
              label={structure.title}
            />
          </FormGroup>
        );
      case SchemaInputTypesEnum.TEXT:
      default:
        return (
          <SettingsRow key={key} label={structure.title}>
            <input
              id={key}
              type={structure.type}
              name={key}
              value={value as string}
              onChange={handleChange}
            />
          </SettingsRow>
        );
    }
  }), [colorSchema, handleChange, onChangeColor]);

  return [colorSchema, inputs];
};

export default useStyleSchemaInputs;
