import { FC, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Element as SlateElement, Path, Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import AttachmentsList from 'components/AttachmentsListComponents/AttachmentsList';
import NavigationArrows from 'components/Editor/components/DocumentNavigationArrows/NavigationArrows';
import { IRenderElementPropsWithValidation } from 'components/Editor/components/RenderElement';
import { DOCUMENT_TAB_ID } from 'constants/tabs';
import { useAssignmentFieldColor } from 'hooks/signNow';
import { RootStateType } from 'store/reducers';
import { IPublicPageState } from 'types/PublicPage';
import { SignerType } from 'types/SendDocuments';
import { createFieldDataAttributes } from 'utils/editorCopyPasteHelpers';
import { getSelectedFieldClass, setSelectedFieldState } from 'utils/editorFieldHelpers';
import { getFieldFontStyles } from 'utils/Fields/fieldFontSizeHelpers';
import { cleanTableQuestionSelection } from 'utils/TableQuestion/fieldsTableQuestionHelpers';
import { updateFieldNameAndProps } from 'utils/updateNode';

export interface IRenderElementProps extends IRenderElementPropsWithValidation {
  readOnly?: boolean;
}

const AttachmentEditable: FC<IRenderElementProps> = ({
  attributes,
  children,
  element,
  readOnly = false,
  filledInFields = undefined,
  isPublicPage = undefined,
}) => {
  const editor = useSlateStatic();
  const dispatch = useDispatch();
  const { selectedField, selectedTableQuestionKey } = useSelector((state: RootStateType) => state.editorSlate);
  const { currentDocument }: IPublicPageState = useSelector((state: RootStateType) => state.publicPages);
  const [elementValue, setElementValue] = useState(element.value);
  const [showToolbar, setShowToolbar] = useState<boolean>(false);

  useEffect(() => {
    updateFieldNameAndProps(editor, element);

    if (currentDocument && currentDocument.attachmentFields?.length) {
      const currentField = currentDocument.attachmentFields.find((field) => field.key === element.key);
      if (currentField) {
        setElementValue(currentField?.value);
      }
    }
  }, [element.children, currentDocument]);

  const clickFieldHandler = () => {
    setSelectedFieldState(selectedField, element.key, dispatch);
    // If a user clicks on attachment field, cursor will be putted in the end of the field.
    if (SlateElement.isElement(element) && !editor.selection?.focus?.offset) {
      const path = ReactEditor.findPath(editor, element);
      Transforms.select(editor, Path.next(path));
    }
    cleanTableQuestionSelection(dispatch, element?.isTableField, selectedTableQuestionKey);
  };

  const hoverFieldHandler = () => {
    if (!editor.publicPage && !readOnly) {
      setShowToolbar(true);
    }
  };

  const leaveFieldHandler = () => {
    setShowToolbar(false);
  };

  const selectedFieldClass = getSelectedFieldClass([Number(selectedField)], element.key);
  const assignmentColor = useAssignmentFieldColor(
    element.assignment ?? SignerType.RECIPIENT,
    readOnly,
  );

  return (
    <span
      role="form"
      className={`attachments-wrap ${showToolbar ? 'blue-bg' : ''}${selectedFieldClass} slate-attachment`}
      {...(createFieldDataAttributes({
        field: element,
        attributes,
        activeTab: DOCUMENT_TAB_ID,
      }))}
      onClick={clickFieldHandler}
      onFocus={hoverFieldHandler}
      onMouseOver={hoverFieldHandler}
      onMouseLeave={leaveFieldHandler}
    >
      <NavigationArrows field={element} isPublicPage={isPublicPage} filledInFields={filledInFields}>
        <>
          <span
            {...attributes}
            className="label-text-field-editable label-text-field-editable__after"
            style={{
              color: assignmentColor,
            }}
          >
            {children}
          </span>
          <AttachmentsList
            documentView
            fieldValue={elementValue}
            fieldKey={element.key ?? 0}
            readOnlyMode={readOnly}
            style={getFieldFontStyles(children, element.fontSize)}
          />
        </>
      </NavigationArrows>
    </span>
  );
};

export default AttachmentEditable;