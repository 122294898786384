import { FC, ReactNode } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { SortEndHandler, SortStartHandler } from 'react-sortable-hoc';

import { SortableList } from 'components/FormBuilder/FormBuilderSortableComponents';
import { FORM_BUILDER_CONTAINER_ID } from 'constants/general';
import {
  clearFormBuilderSelectedFieldsKeys,
  setFormBuilderSelectedFieldsKeys,
  setIsDragStartStatus,
  setIsDragStopStatus,
} from 'store/actions/formBuilder';
import { RootStateType } from 'store/reducers';
import { ICustomElement } from 'types/Editor';
import { FormBuilderType } from 'types/FormBuilder';
import { IFormBuilderSection } from 'types/Sections';
import { arrayMoveFieldBetweenSections, setFormBuilderGroupedFields } from 'utils/FormBuilder/formBuilderHelpers';
import { getFormBuilderByType } from 'utils/FormBuilder/store';

interface SortableListWrapperProps {
  children: JSX.Element & ReactNode;
  isFormBuilderOpened: boolean;
  formBuilderType: FormBuilderType;
  sectionKey?: number;
  hoveredSectionKey?: number;
  fieldsToShow?: Partial<ICustomElement>[];
}

const SortableListWrapper: FC<SortableListWrapperProps> = ({
  children,
  isFormBuilderOpened,
  formBuilderType,
  sectionKey,
  hoveredSectionKey,
  fieldsToShow,
}) => {
  const dispatch = useDispatch();
  const formBuilderFromStore: IFormBuilderSection[] = useSelector((state: RootStateType) => (
    getFormBuilderByType(state, formBuilderType)
  ));
  const {
    selectedFormBuilderFieldsKeys,
    outlineActiveSectionKey,
    groupedFieldsKeys,
  } = useSelector((state: RootStateType) => state.formBuilder);

  if (!isFormBuilderOpened) return children;

  const formBuilder: IFormBuilderSection[] = setFormBuilderGroupedFields(formBuilderFromStore);

  const onSortStart: SortStartHandler = (sort) => {
    dispatch(setIsDragStartStatus());
    const { fieldKey } = sort.helper.dataset;

    if (fieldKey && !selectedFormBuilderFieldsKeys.length) {
      dispatch(setFormBuilderSelectedFieldsKeys([fieldKey]));
    }
  };

  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    arrayMoveFieldBetweenSections({
      dispatch,
      formBuilder,
      hoveredSectionKey: outlineActiveSectionKey || hoveredSectionKey,
      sectionKey,
      newIndex,
      oldIndex,
      formBuilderType,
      selectedFormBuilderFieldsKeys,
      fieldsToShow,
      groupedFieldsKeys,
    });
    dispatch(setIsDragStopStatus());
    dispatch(clearFormBuilderSelectedFieldsKeys());
  };

  return (
    <SortableList
      useDragHandle
      getContainer={() => {
        const node = document.getElementById(FORM_BUILDER_CONTAINER_ID);
        return node || document.body;
      }}
      distance={1}
      onSortStart={onSortStart}
      onSortEnd={onSortEnd}
      lockToContainerEdges={false}
    >
      {children}
    </SortableList>
  );
};

export default SortableListWrapper;