import { ChangeEvent, CSSProperties, FC, memo, MouseEvent } from 'react';

import Draggable, { DraggableData } from 'react-draggable';
import { useDispatch } from 'react-redux';

import { NumberSize, Resizable } from 're-resizable';

import { SortableItem } from 'components/FormBuilder/FormBuilderSortableComponents';
import SelectableItem from 'components/FormBuilder/SelectableItem';
import ElementSortButton from 'components/FormConstructor/Buttons/ElementSortButton';
import PdfField from 'components/PdfEditor/Fields/PdfField';
import PdfFieldsButtons from 'components/PdfEditor/PdfFieldsButtons/PdfFieldsButtons';
import FieldIconWrapper from 'components/PdfEditor/PdfFieldsSidebar/FieldIconWrapper';
import {
  checkboxDefaultStyles,
  DEFAULT_PDF_FIELD_HEIGHT,
  DEFAULT_PDF_FIELD_WIDTH,
  MIN_CHECKBOX_FIELD_WIDTH,
  MIN_FIELD_HEIGHT,
  MIN_FIELD_WIDTH,
  paddingForWrapperSize,
  PDF_FIELD_FONT_SIZE,
  SIGNING_FIELD,
} from 'constants/editors';
import { DEFAULT_PAGE_WIDTH } from 'constants/PDF';
import { SIGNATURE_TABS } from 'constants/signatures';
import { setSelectedFieldKey } from 'store/actions/pdfTemplates';
import { AssignmentPositions, PDFFieldRadioCheckboxUpdateHandler } from 'types/Editor';
import { HTMLFieldElement, PDFFieldType, PDFFieldTypeObject } from 'types/PdfTemplates';
import { isRecipientAssignment } from 'utils/assignmentsHelpers';
import { getHexColorFromIndex } from 'utils/colorAssignmentHelper';
import { getSelectedFieldClass, isFieldHasSubtype } from 'utils/editorFieldHelpers';
import { getFieldFontStyles } from 'utils/Fields/fieldFontSizeHelpers';
import { getIsPublicSelectFieldType, isCheckboxFieldType } from 'utils/pdfTemplatesHelpers';

interface IDragAndDropElement {
  idsToSelect: number[];
  element: PDFFieldType;
  signersPositions: AssignmentPositions;
  pageNumber: number;
  index: number;
  pdfTemplateWidth: number;
  customFields: PDFFieldType[] | null;
  resizeBoundParent: HTMLElement;
  isMultiTemplate: boolean;
  pdfTemplateId: number;
  hideValueStateObject: Record<number, Record<string, boolean>>;
  pdfTemplateFields: PDFFieldTypeObject;
  isDisabledField: (element: PDFFieldType) => boolean;
  updateFieldCoords: (data: DraggableData, field: PDFFieldType) => void;
  getFieldAndChangeIndex: ({ target }: MouseEvent<HTMLDivElement>, key: number, zIndex: string) => void;
  hideRevealFieldValueHandler: (fieldKey: number, newValue: boolean, stateType?: 'mask' | 'hide') => void;
  updateFieldSize: (delta: NumberSize, field: PDFFieldType) => void;
  changeFieldValueAndUpdateStore: (
    event: ChangeEvent<HTMLFieldElement> | null,
    field: PDFFieldType,
    newValue?: string
  ) => void;
  changeRadioSelectValueAndUpdateStore: PDFFieldRadioCheckboxUpdateHandler;
  onChangeHandlerOtherOption: (event: ChangeEvent<HTMLFieldElement>, field: PDFFieldType) => void;
  isResizable: boolean;
  enabledResize: {
    top: boolean;
    right: boolean;
    bottom: boolean;
    left: boolean;
    topRight: boolean;
    bottomRight: boolean;
    bottomLeft: boolean;
    topLeft: boolean;
  };
  readOnlyMode: boolean;
  isPublicPage: boolean;
  isFormBuilderOpened: boolean;
}

const DragAndDropElement: FC<IDragAndDropElement> = ({
  idsToSelect,
  element,
  signersPositions,
  pageNumber,
  index,
  pdfTemplateWidth,
  customFields,
  resizeBoundParent,
  isMultiTemplate,
  pdfTemplateId,
  hideValueStateObject,
  pdfTemplateFields,
  isDisabledField,
  updateFieldCoords,
  getFieldAndChangeIndex,
  hideRevealFieldValueHandler,
  updateFieldSize,
  changeFieldValueAndUpdateStore,
  changeRadioSelectValueAndUpdateStore,
  onChangeHandlerOtherOption,
  isResizable,
  enabledResize,
  readOnlyMode,
  isPublicPage,
  isFormBuilderOpened,
}) => {
  const dispatch = useDispatch();
  const position = signersPositions[element.assignment];
  const assignmentColor = getHexColorFromIndex(position);
  const isCheckboxField = isCheckboxFieldType(element.type);
  const isPublicSelectFieldType = getIsPublicSelectFieldType(
    element.type,
    element.selectFieldType,
    isPublicPage,
  );

  const hasSubtype = !isPublicPage && isFieldHasSubtype(element.subtype);
  const selectedFieldClass = isFormBuilderOpened ? '' : getSelectedFieldClass(idsToSelect, element.key, hasSubtype);

  const fieldStyle: CSSProperties = {
    height: '100%',
    fontSize: PDF_FIELD_FONT_SIZE,
    ...(!isPublicSelectFieldType && { border: `1px solid ${assignmentColor}` }),
    ...getFieldFontStyles(undefined, element.fontSize),
    ...(isCheckboxField ? checkboxDefaultStyles : {}),
    ...(selectedFieldClass && { boxShadow: `0 0 4px 2px ${assignmentColor} inset` }),
    ...(isDisabledField(element) && { boxShadow: 'none', border: '1px solid gray' }),
  };
  const resizeWrapperStyle: CSSProperties = {
    // adjusting styles for typed signature on pdf page, typed signature field should have 0px of paddings
    padding: element.type === SIGNING_FIELD && element.signatureTab === SIGNATURE_TABS.TYPE_TAB ? 0 : 5,
    minHeight: 30,
  };

  if ((element.pageNumber || 0) !== pageNumber) return null;
  const managerClass = isRecipientAssignment(element.assignment) ? '' : 'drag-and-drop-element--manager';

  // Allow to show fields area as read only viewer with changed values
  const currentField = isPublicPage && readOnlyMode && customFields?.find(
    (field: any) => field.key === Number(element.key),
  );
  const customElement = currentField ? { ...element, ...currentField } : { ...element };
  const existingFieldMask = Boolean(element.textFieldMask) && element.textFieldMask !== 'none';
  hideRevealFieldValueHandler(element.key, Boolean(existingFieldMask), 'mask');

  return (
    <Draggable
      axis="both"
      key={element.key}
      disabled={!isResizable}
      handle=".drag-and-drop-cursor"
      bounds={{
        left: 0,
        right: (pdfTemplateWidth - element.size.width) ?? DEFAULT_PAGE_WIDTH,
        ...(pageNumber === 0 && { top: 0 }),
      }}
      defaultPosition={{
        x: element.coords?.x,
        y: element.coords?.y,
      }}
      onStop={(e, data) => updateFieldCoords(data, element)}
    >
      <div
        onClick={() => !currentField && dispatch(setSelectedFieldKey(element.key))}
        onMouseEnter={(event) => getFieldAndChangeIndex(event, element.key, '101')}
        onMouseLeave={(event) => getFieldAndChangeIndex(event, element.key, '100')}
        className={`drag-and-drop-element-wrapper ${element.originFieldRef ? 'origin-field-ref' : ''}`}
        data-element-wrapper={element.key}
        role="button"
        tabIndex={-1}
      >
        <div className={`drag-and-drop-element ${managerClass}${selectedFieldClass}`}>
          {
            isResizable && (
              <div
                className="drag-and-drop-cursor"
                style={{
                  color: assignmentColor,
                  fill: assignmentColor,
                }}
              >
                <FieldIconWrapper type={element.type} />
              </div>
            )
          }
          <SortableItem fieldKey={element.key} index={index} isFormBuilderOpen={isFormBuilderOpened}>
            <>
              {
                isFormBuilderOpened && (
                  <div>
                    <ElementSortButton className="sort_button pdf-editor-sort-button" />
                  </div>
                )
              }
              <SelectableItem fieldKey={customElement.key}>
                <Resizable
                  enable={enabledResize}
                  className="resizable-wrapper"
                  size={{
                    width: (element?.size?.width || DEFAULT_PDF_FIELD_WIDTH),
                    height: (element?.size?.height || DEFAULT_PDF_FIELD_HEIGHT),
                  }}
                  minHeight={
                    isCheckboxField ? MIN_CHECKBOX_FIELD_WIDTH : (MIN_FIELD_HEIGHT + paddingForWrapperSize)
                  }
                  minWidth={
                    isCheckboxField ? MIN_CHECKBOX_FIELD_WIDTH : (MIN_FIELD_WIDTH + paddingForWrapperSize)
                  }
                  bounds={resizeBoundParent}
                  onResizeStop={(e, d, r, delta) => updateFieldSize(delta, element)}
                  style={resizeWrapperStyle}
                >
                  <PdfField
                    field={customElement}
                    onChangeField={changeFieldValueAndUpdateStore}
                    onChangeRadioSelectField={changeRadioSelectValueAndUpdateStore}
                    onChangeHandlerOtherOption={onChangeHandlerOtherOption}
                    isReadOnlyMode={isDisabledField(element) || isFormBuilderOpened}
                    isPublicPage={isPublicPage}
                    fieldStyle={fieldStyle}
                    isMultiTemplate={isMultiTemplate}
                    pdfTemplateId={pdfTemplateId}
                    maskValuesState={hideValueStateObject[element.key]}
                  />
                </Resizable>
              </SelectableItem>
            </>
          </SortableItem>
          <PdfFieldsButtons
            field={element}
            pdfFieldsCount={Object.keys(pdfTemplateFields).length}
            existingFieldMask={existingFieldMask}
            hideRevealFieldValueHandler={hideRevealFieldValueHandler}
            maskValuesState={hideValueStateObject[element.key]}
            isResizable={isResizable}
          />
        </div>
      </div>
    </Draggable>
  );
};

export default memo(DragAndDropElement);