import { FC, MouseEventHandler } from 'react';

import { ITab } from 'types/Tabs';

export interface INavigationShareModalProps {
  tabs: ITab[];
  selectedTab: number;
  onChangeSelectedTab: (id: number) => MouseEventHandler<HTMLLIElement>;
}

const NavigationShareModal: FC<INavigationShareModalProps> = ({
  tabs,
  selectedTab,
  onChangeSelectedTab,
}) => (
  <div className="d-flex justify-content-between px-2 navigation-share-modal">
    <div>
      <ul className="nav nav-tabs">
        {
          tabs.map((tab) => (
            <li
              key={tab.id}
              className={`nav-item nav-link ${selectedTab === tab.id && 'active'}`}
              onClick={onChangeSelectedTab(tab.id)}
            >
              {tab.label}
            </li>
          ))
        }
      </ul>
    </div>
  </div>
);

export default NavigationShareModal;