import { FC, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import DocumentNavigation from 'components/DocumentNavigation/DocumentNavigation';
import GeneralMUITableWrapper from 'components/GeneralMUITableWrapper';
import GlobalNavigation from 'components/GlobalNavigation/GlobalNavigation';
import useDocumentsStatuses from 'hooks/useDocumentsStatuses';
import { RootStateType } from 'store/reducers';
import { ListingViewsTabs, TableRowsType } from 'types/Mui';
import { createTableData } from 'utils/dataColumns';

const Dashboard: FC = () => {
  const { documents, collections, documentsExecuted } = useSelector((state: RootStateType) => state.user);
  const [documentsRows, setDocumentsRows] = useState<TableRowsType>([]);

  const {
    isUserReadOnly,
    activeStatuses,
    setActiveStatuses,
  } = useDocumentsStatuses();

  useEffect(() => {
    if (activeStatuses.length > 0) {
      setDocumentsRows(createTableData(documentsExecuted, '', activeStatuses));
    } else {
      setDocumentsRows(createTableData(documents.concat(collections)));
    }
  }, [documents, collections, activeStatuses, documentsExecuted]);

  return (
    <>
      <GlobalNavigation />
      <div className="w-75 mx-auto pb-5">
        <div className="d-flex justify-content-between my-3">
          <div className="document-navigation-dashboard-title">My documents</div>
        </div>
        <DocumentNavigation
          isDashboard
          activeTab={activeStatuses}
          setActiveTab={setActiveStatuses}
          isUserReadOnly={isUserReadOnly}
        />
        <GeneralMUITableWrapper
          tableRows={documentsRows}
          isAllDocumentsTab={!activeStatuses.length}
          currentTableTab={ListingViewsTabs.DASHBOARD}
          setActiveTab={setActiveStatuses}
          activeStatuses={activeStatuses}
        />
      </div>
    </>
  );
};

export default Dashboard;