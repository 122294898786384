import { FC } from 'react';

import SimpleSpinner from 'components/Base/SimpleSpinner';
import BoardsAccordion from 'components/Modals/MondayComModalWindow/BoardsAccordion';
import { IMondayComBoardsPerWorkspace } from 'types/mondayCom/mondayComModalWindow';

const MondayComBoardsPerWorkspace: FC<IMondayComBoardsPerWorkspace> = ({
  workspaces,
  boards,
  boardsExistInAccount,
  onSelectBoardsHandler,
  templateIntegrationData,
}) => {
  if (!workspaces) {
    return (<SimpleSpinner />);
  }

  return boardsExistInAccount
    ? (
      <>
        {
          workspaces.map((workspace) => (
            <BoardsAccordion
              key={workspace.id}
              workspaceId={workspace.id}
              boardsInWorkspace={boards?.filter((board) => board.workspace_id === workspace.id) ?? []}
              workspace={workspace}
              onSelectBoardsHandler={onSelectBoardsHandler}
              templateIntegrationData={templateIntegrationData}
            />
          ))
        }
      </>
    )
    : (<div className="text-center">There are no workspaces in your Monday.com account.</div>);
};

export default MondayComBoardsPerWorkspace;