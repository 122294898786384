import { ChangeEventHandler, FC, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useSlateStatic } from 'slate-react';

import cn from 'classnames';
import NavigationArrows from 'components/Editor/components/DocumentNavigationArrows/NavigationArrows';
import ErrorValidation from 'components/Editor/components/ErrorValidation';
import OtherOptionElement from 'components/Editor/components/Fields/OtherOptionElement';
import RadioSelectElement from 'components/Editor/components/Fields/RadioSelectElement';
import SelectElement from 'components/Editor/components/Fields/SelectElement';
import FieldWrapper from 'components/Editor/components/FieldWrapper';
import {
  IRenderSelectElementPropsWithValidation,
} from 'components/Editor/components/RenderElement';
import SlateFieldFiller from 'components/Editor/components/SlateFieldFiller';
import FieldToolbar from 'components/Editor/components/Toolbars/FieldToolbar';
import { SELECT_FIELD_TYPE_RADIO, SELECT_FIELD_TYPE_SELECT } from 'constants/editors';
import { DOCUMENT_TAB_ID } from 'constants/tabs';
import useFieldsHandler from 'hooks/useFieldsHandler';
import { useFormFields } from 'hooks/useFormFields';
import { RootStateType } from 'store/reducers';
import { SelectUpdateEventHandler } from 'types/Editor';
import { createFieldDataAttributes } from 'utils/editorCopyPasteHelpers';
import {
  getGroupedFieldKeys,
  getSelectedFieldClass,
  isFieldHasSubtype,
  setSelectedFieldState,
  updateSelectFieldOptionsHandler,
} from 'utils/editorFieldHelpers';
import { getSelectValuesToUpdate } from 'utils/editorSelectFieldHelpers';
import { getIsRadioOrCheckbox } from 'utils/Fields/checkboxRadioHelpers';
import { getFieldFontStyles } from 'utils/Fields/fieldFontSizeHelpers';
import { cleanTableQuestionSelection } from 'utils/TableQuestion/fieldsTableQuestionHelpers';
import { updateFieldNameAndProps } from 'utils/updateNode';
import { validateFieldHelper } from 'utils/validation';

const SelectEditable: FC<IRenderSelectElementPropsWithValidation> = ({
  attributes,
  children,
  element,
  readOnlyMode = false,
  isError = false,
  validationErrorText = null,
  viewMode = SELECT_FIELD_TYPE_SELECT,
  filledInFields = undefined,
  isPublicPage = undefined,
}) => {
  const editor = useSlateStatic();
  const dispatch = useDispatch();
  const { selectedField, selectedTableQuestionKey } = useSelector((state: RootStateType) => state.editorSlate);
  const [value, setValue] = useState(element.value);
  const [otherOptionValue, setOtherOptionValue] = useState<string>(element.value ?? '');
  const [showToolbar, setShowToolbar] = useState<boolean>(false);
  const forms = useFormFields(true, false);

  const { updateFieldsHandler } = useFieldsHandler({ editor });

  useEffect(() => {
    updateFieldNameAndProps(editor, element);
  }, [element.children]);

  useEffect(() => {
    if (value !== element.value) {
      setValue(element.value);
    }

    if (element.addedOtherOption) {
      setOtherOptionValue(element.value ?? '');
    }
  }, [element.value, element.selectedOtherOption]);

  const onChangeHandler: SelectUpdateEventHandler = (event) => {
    if (readOnlyMode) return;

    const target = event.target as HTMLSelectElement & HTMLInputElement;
    const targetValue = target.value;

    const { selectedIndex } = target;
    const { id: optionId } = target.dataset;

    const { updatedOptions } = updateSelectFieldOptionsHandler({
      options: element.options || [],
      viewMode,
      id: Number(optionId),
      selectedIndex,
    });

    const previousValue = element.selectedOtherOption ? otherOptionValue : '';
    const updatedProperties = getSelectValuesToUpdate({
      fieldValue: targetValue,
      previousValue,
      updatedOptions,
      fieldView: viewMode,
    });

    validateFieldHelper({
      field: {
        ...element,
        ...updatedProperties,
      },
      prevValue: value || '',
      value: updatedProperties.value || '',
      dispatch,
    });

    setValue(updatedProperties.value || '');

    updateFieldsHandler(element, updatedProperties);
  };

  const onChangeHandlerOtherOption: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
    updateFieldsHandler(element, { value });
  };

  const clickFieldHandler = () => {
    setSelectedFieldState(selectedField, element.key, dispatch);
    cleanTableQuestionSelection(dispatch, element?.isTableField, selectedTableQuestionKey);
  };

  const hoverFieldHandler = () => {
    if (!editor.publicPage && !readOnlyMode && !element?.isTableField) {
      setShowToolbar(true);
    }
  };

  const leaveFieldHandler = () => {
    setShowToolbar(false);
  };

  const selectWrapperClassName = SELECT_FIELD_TYPE_RADIO ? 'doc-radio-select' : 'field-editable';
  const hasSubtype = !editor.publicPage && isFieldHasSubtype(element.subtype);
  const idsToSelect = getGroupedFieldKeys(forms || [], selectedField);
  const selectedFieldClass = getSelectedFieldClass(idsToSelect, element.key, hasSubtype);

  return (
    <span
      className={cn(
        'custom-subtype-highlight inline-field-wrapper white-space-inline-field-wrapper ',
        selectedFieldClass,
        {
          'blue-bg': showToolbar,
        },
      )}
      data-value={value}
      {...(createFieldDataAttributes({
        field: element,
        attributes,
        activeTab: DOCUMENT_TAB_ID,
        selectFieldViewMode: viewMode,
      }))}
      onClick={clickFieldHandler}
      onFocus={hoverFieldHandler}
      onMouseOver={hoverFieldHandler}
      onBlur={leaveFieldHandler}
      onMouseLeave={leaveFieldHandler}
      role="textbox"
      tabIndex={-1}
      style={getFieldFontStyles(children, element.fontSize)}
    >
      <NavigationArrows field={element} isPublicPage={isPublicPage} filledInFields={filledInFields}>
        <>
          {showToolbar && <FieldToolbar fieldKey={element.key} />}
          <SlateFieldFiller inline />
          <FieldWrapper
            assignment={element.assignment}
            fieldChildren={children}
            labelConfiguration={element.labelConfiguration}
            isReadOnly={readOnlyMode}
            element={element}
            isPublicPage={isPublicPage}
          >
            <span
              contentEditable={false}
              style={{
                userSelect: 'none',
                position: 'relative',
              }}
              className={`me-1 ${selectWrapperClassName}`}
            >
              {
                viewMode === SELECT_FIELD_TYPE_SELECT
                  ? (
                    <SelectElement
                      element={element}
                      value={
                        element.selectedOtherOption
                          ? element.options?.find((option) => option.isSelectOtherOption)?.label
                          : value
                      }
                      onChangeSelectField={onChangeHandler}
                      isReadOnly={readOnlyMode}
                    />
                  )
                  : null
              }
              {
                getIsRadioOrCheckbox(viewMode)
                  ? (
                    <RadioSelectElement
                      element={element}
                      viewMode={viewMode}
                      onChangeRadioOption={onChangeHandler}
                      isReadOnlyMode={readOnlyMode}
                    />
                  )
                  : null
              }
              {
                element.selectedOtherOption && (
                  <OtherOptionElement
                    name={element.name}
                    value={otherOptionValue}
                    onChangeHandler={onChangeHandlerOtherOption}
                    addMargin
                    disabled={readOnlyMode}
                  />
                )
              }
              <ErrorValidation isError={isError} errorText={validationErrorText} />
            </span>
          </FieldWrapper>
        </>
      </NavigationArrows>
    </span>
  );
};

export default SelectEditable;