import React, { useRef, useState } from 'react';

import FontTypeButton from 'components/Editor/components/FontTypeButton';
import ToolbarDropdown from 'components/Editor/components/ToolbarDropdown';
import { useOutsideAlerter } from 'hooks/useOutsideAlerter';
import { IBlocksSelectProps } from 'types/Editor';
import { reduceFontType } from 'utils/reduceFontType';

const PropertiesSelect: React.FC<IBlocksSelectProps> = (props) => {
  const {
    key,
    formats,
    icon,
    title,
    horizontalToolbar,
    dropdownType,
  } = props;
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const modalRef = useRef(null);
  useOutsideAlerter(modalRef, setIsDropdownVisible);

  const onModal: React.MouseEventHandler<HTMLElement> = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const selectFontTypes: any[] = formats.reduce(reduceFontType, null);

  // TODO: active icon view for Horizontal Toolbar
  const renderPropertiesDropdown = (): JSX.Element => (
    <div ref={modalRef} className="d-inline-block">
      <span
        className="border-0 text-muted btn-toolbar-editor horizontal-toolbar-icon"
        onClick={onModal}
        role="button"
        tabIndex={-1}
      >
        {icon}
      </span>
      {
        isDropdownVisible
        && <ToolbarDropdown dropdownType={dropdownType} formats={formats} setIsDropdownVisible={setIsDropdownVisible} />
      }
    </div>
  );

  // TODO: Vertical Toolbar: create separate component and implement new flow for font types
  const renderPropertiesButton = (): JSX.Element => (
    <li
      key={key}
      ref={modalRef}
      className="item-toolbar-editor"
      onClick={onModal}
    >
      <div className="d-flex">
        <div
          className="icon border-0 text-muted btn-toolbar-editor bg-white"
        >
          {icon}
        </div>
        <div>
          <div className="text-dark">{title}</div>
          <div className="command-description text-muted">Shortcut 4 + &#8984; or type - + space</div>
        </div>
      </div>
      <ul
        className={`modal-vertical-toolbar-editor bg-white ${isDropdownVisible ? 'd-block' : 'd-none'}`}
      >
        {
          selectFontTypes.map(({ format, type }, index) => {
            if (['font-type-list'].includes(format)) {
              return <li key={`${format} ${type} - ${Number(index)}`} className="p-1 text-dark">{type}</li>;
            }

            const label = type.split(', ')?.slice(0, 1);

            return (
              <li key={`${format} ${type} - ${Number(index)}`} className="pt-1 pl-3 pb-1 text-muted">
                <FontTypeButton format={format} value={type} icon={label} />
              </li>
            );
          })
        }
      </ul>
    </li>
  );

  return (
    <div>
      {horizontalToolbar ? renderPropertiesDropdown() : renderPropertiesButton()}
    </div>
  );
};

export default PropertiesSelect;