import { toast } from 'react-toastify';

import { BaseSelection, Editor, NodeEntry, Transforms } from 'slate';

import {
  TABLE_QUESTION_ROW,
} from 'constants/editors';
import {
  MIN_TABLE_QUESTION_ROW_COUNT,
  COLUMNS_REMOVING_MESSAGE,
  TABLE_QUESTION_CELL_LIST,
} from 'constants/tableQuestion';
import { ICustomElement } from 'types/Editor';
import { findEditorNodesByPosition } from 'utils/TableQuestion/tableQuestionHelpers';

interface IRemoveTableQuestionColumnProps {
  editor: Editor,
  tableBody: NodeEntry<ICustomElement> | undefined,
  selection: BaseSelection,
}

const removeTableQuestionColumn = ({
  editor,
  tableBody,
  selection,
}: IRemoveTableQuestionColumnProps) => {
  if (!tableBody || !selection) {
    toast.warning(COLUMNS_REMOVING_MESSAGE.CANT_REMOVE_HERE);
    return;
  }

  const cellNodes = findEditorNodesByPosition(editor, selection, TABLE_QUESTION_CELL_LIST);

  if (!cellNodes || !Array.isArray(cellNodes)) return;

  const [[, startPath]] = cellNodes;

  const columnIndex = startPath[startPath.length - 1];

  const tableRows = findEditorNodesByPosition(editor, tableBody[1], [TABLE_QUESTION_ROW]);
  if (!tableRows || !Array.isArray(tableRows)) return;

  if (columnIndex === 0 || columnIndex === 1) {
    toast.warning(COLUMNS_REMOVING_MESSAGE.CANT_INSERT_THIS);
    return;
  }
  if ((tableRows?.[0]?.[0]?.children?.length ?? 0) <= MIN_TABLE_QUESTION_ROW_COUNT) {
    toast.warning(COLUMNS_REMOVING_MESSAGE.CANT_INSERT_MORE);
    return;
  }

  tableRows.forEach(([rowNode, rowPath]) => {
    const cellNode = rowNode.children && rowNode.children[columnIndex];
    if (cellNode) {
      Transforms.removeNodes(editor, { at: [...rowPath, columnIndex] });
    }
  });
};

export default removeTableQuestionColumn;