import { ICustomElement } from 'types/Editor';
import { IPublicPageDocumentStructure } from 'types/PublicPage';

interface IUpdatePublicPageDocumentsProps {
  document: IPublicPageDocumentStructure | null;
  payload: {
    keys: number[];
    value: Record<string, string>;
    additionalParameter: Record<string, string>;
  };
}

const updatePublicPageDocuments = ({
  document,
  payload,
}: IUpdatePublicPageDocumentsProps) => ({
  ...document,
  slides: [
    ...document?.slides?.map((section) => ({
      ...section,
      fields: section.fields?.map((field: Partial<ICustomElement>) => {
        if (payload.keys.includes(field.key ?? 0)) {
          return {
            ...field,
            ...payload.value,
            ...payload.additionalParameter,
          };
        }
        return field;
      }),
    })) || [],
  ],
});

export default updatePublicPageDocuments;