import { FC, memo, useEffect, useState } from 'react';

import { Label } from 'reactstrap';

import { Element as SlateElement } from 'slate';

import FieldProperties from 'components/Editor/EditorDevTools/FieldProperties';
import { PDFFieldType } from 'types/PdfTemplates';

interface ITodaysDateFieldProperties {
  field: Partial<SlateElement | PDFFieldType>;
  openConfirmationWindow: (callbackMethod: () => void) => void;
  updateField: (props: { updatedField: Partial<SlateElement>; withGroupedFieldsUpdating?: boolean; }) => void;
  setGroupedFieldCount: (count: number) => void;
}

const TodaysDateFieldProperties: FC<ITodaysDateFieldProperties> = ({
  field,
  updateField,
  openConfirmationWindow,
  setGroupedFieldCount,
}) => {
  const [isActiveTodayDate, setIsActiveTodayDate] = useState<boolean>(false);

  useEffect(() => {
    if (field) {
      setIsActiveTodayDate(field?.isTodaysDate || false);
    }
  }, [field]);

  const changeIsTodaysDate = () => {
    const updatedIsTodaysDate = {
      isTodaysDate: !isActiveTodayDate,
    };

    const todayDate = isActiveTodayDate ? { ...updatedIsTodaysDate, value: '' } : updatedIsTodaysDate;
    const todayDateValue = isActiveTodayDate ? field.value : '';

    const updatedField = {
      ...todayDate,
      todayDateValue,
    };

    openConfirmationWindow(() => {
      updateField({
        updatedField,
        withGroupedFieldsUpdating: true,
      });
      setIsActiveTodayDate(!isActiveTodayDate);
      setGroupedFieldCount(0);
    });
  };

  return (
    <>
      <FieldProperties
        label="Today Date"
        type="checkbox"
        checked={isActiveTodayDate}
        onChangeHandler={changeIsTodaysDate}
      />
      {
        isActiveTodayDate && (
          <div className="max-width-300">
            <Label>
              Default&apos;s to the date of signing
            </Label>
          </div>
        )
      }
    </>
  );
};

export default memo(TodaysDateFieldProperties);