import { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useCarbonCopy } from 'components/SignNow/CarbonCopyContext';
import { useStepHandler } from 'components/SignNow/StepManager/StepHandlerContext';
import { setCreatedDocumentId } from 'store/actions/publicPages';
import { sendDocumentsViaEmail } from 'store/actions/sendDocuments';
import { RootStateType } from 'store/reducers';
import { SignersInfoType } from 'types/SendDocuments';
import { UseReviewAndSendHookType } from 'types/signNow/base';
import { validateSignersArray } from 'utils/sendDocumentsHelpers';

const useReviewAndSend: UseReviewAndSendHookType = ({
  onNextHandler,
}): void => {
  const dispatch = useDispatch();
  const { setOnNext, isNextButtonDisabled, setIsNextButtonDisabled } = useStepHandler();

  const { signersInfo } = useSelector((state: RootStateType) => state.sendDocuments);
  const isRemindForAllSigners: boolean = useSelector((state: RootStateType) => state.signNow.isRemindForAllSigners);
  const { setCarbonCopyUsers } = useCarbonCopy();

  useEffect(() => {
    const disabled: boolean = signersInfo.every((signer: SignersInfoType) => signer.signed) && !isRemindForAllSigners;

    setIsNextButtonDisabled(disabled);
  }, [isRemindForAllSigners, setIsNextButtonDisabled, signersInfo]);

  const onNextHandlerWrapper = useCallback((): void => {
    if (isNextButtonDisabled) return undefined;

    if (validateSignersArray(setCarbonCopyUsers)) {
      dispatch(setCreatedDocumentId(0));
      dispatch(sendDocumentsViaEmail());
      onNextHandler();
    }
  }, [dispatch, isNextButtonDisabled, onNextHandler, setCarbonCopyUsers]);

  useEffect(() => {
    setOnNext(() => onNextHandlerWrapper);
  }, [setOnNext, onNextHandlerWrapper]);
};

export default useReviewAndSend;