import { FC } from 'react';

import EditorDevTools from 'components/Editor/EditorDevTools';
import RenderFieldProperties from 'components/Editor/EditorDevTools/RenderFieldProperties';
import { useFormFields } from 'hooks/useFormFields';

interface IDocumentEditorDevToolsProps {
  isFormView: boolean;
}

const DocumentEditorDevTools: FC<IDocumentEditorDevToolsProps> = ({ isFormView }) => {
  const fields = useFormFields(false, isFormView);

  return (
    <EditorDevTools fields={fields}>
      <RenderFieldProperties fields={fields} />
    </EditorDevTools>
  );
};

export default DocumentEditorDevTools;