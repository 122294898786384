/* eslint-disable no-param-reassign */
import { Editor as SlateEditor, Transforms } from 'slate';

import { FIELD_TYPE, TAB_KEY_SETTINGS, WHITE_SPACE } from 'constants/editors';
import { LABEL_CONFIGURATION } from 'types/Editor';
import { labelConfigurationHandler } from 'utils/editorFieldHelpers';
import { getSelectedFieldTypeNodes } from 'utils/editorHelpers';

export const withForms = (editor: SlateEditor) => {
  const { insertBreak, insertText, isInline } = editor;

  editor.isInline = (element) => (
    FIELD_TYPE.includes(element.type) || isInline(element)
  );

  editor.insertText = (text) => {
    const { selection } = editor;

    if ([TAB_KEY_SETTINGS.tabIndent, WHITE_SPACE].includes(text)) {
      const selectedFieldNodes = getSelectedFieldTypeNodes(editor);
      const currentPath = selection?.focus.path;

      if (selectedFieldNodes?.length && currentPath && currentPath.length > 2) {
        Transforms.move(editor, { distance: 1, unit: 'offset', reverse: true });
        const selectedFieldType = getSelectedFieldTypeNodes(editor);
        if (selectedFieldType?.length) {
          Transforms.move(editor, { distance: 1, unit: 'offset' });
          if (selectedFieldType.length > 1) {
            return;
          }
        }
      }
    }
    insertText(text);
  };

  editor.insertBreak = () => {
    const { selection } = editor;
    if (selection) {
      const currentPath = selection.focus.path;
      const selectedNode = getSelectedFieldTypeNodes(editor);

      if (selectedNode?.length && currentPath.length > 2) {
        const currentElementLabel = (
          'labelConfiguration' in selectedNode[0][0] && selectedNode[0][0]?.labelConfiguration
        ) || LABEL_CONFIGURATION.LEFT;
        const { isLeftLabel, isNoneLabel } = labelConfigurationHandler(currentElementLabel as LABEL_CONFIGURATION);
        const stepBack = isLeftLabel || isNoneLabel;
        Transforms.move(editor, { distance: 1, unit: 'offset', reverse: stepBack });
        const selectedFieldType = getSelectedFieldTypeNodes(editor);
        if (!selectedFieldType?.length) {
          insertBreak();
        } else {
          Transforms.move(editor, { distance: 1, unit: 'offset', reverse: !stepBack });
        }
        return;
      }
    }

    insertBreak();
  };

  return editor;
};

export default withForms;