import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import DocumentHistoryRow from 'components/Modals/DocumentHistoryRow';
import { getDocumentExecutedHistory } from 'services/api/serverSideEvents';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { DocumentHistoryType } from 'types/DocumentsHistory';
import { MatchParams } from 'types/Route';

interface IDocumentHistoryModal {
  showDocHistoryModal: boolean;
  setShowDocHistoryModal: Dispatch<SetStateAction<boolean>>;
}

const DocumentHistoryModal: FC<IDocumentHistoryModal> = ({
  showDocHistoryModal,
  setShowDocHistoryModal,
}) => {
  const { id }: MatchParams = useParams();
  const [docHistory, setDocHistory] = useState<DocumentHistoryType>({ isLoading: false, history: [] });

  const handlerOpenDocumentHistory = async () => {
    setDocHistory({ isLoading: true, history: [] });
    const documenHistory = await apiErrorHandler(getDocumentExecutedHistory, id);
    if (documenHistory) {
      setDocHistory({ isLoading: false, history: documenHistory.data });
    }
  };

  useEffect(() => {
    if (showDocHistoryModal) {
      handlerOpenDocumentHistory();
    }
  }, [showDocHistoryModal]);

  return (
    <Modal
      scrollable
      centered
      size="xl"
      isOpen={showDocHistoryModal}
      className="document-history-modal"
    >
      <ModalHeader className="modal-header border-0" toggle={() => setShowDocHistoryModal(false)}>
        <div className="description-title text-center">
          Document History
        </div>
      </ModalHeader>
      <ModalBody className="p-0">
        <div className="container">
          {
            docHistory.isLoading ? (
              <div className="d-flex justify-content-center">
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress />
                </Box>
              </div>
            ) : (
              docHistory.history.map((item, index) => (
                <DocumentHistoryRow
                  key={`DocumentInfoRow${index + 1}`}
                  record={item}
                />
              ))
            )
          }
        </div>
        <ModalFooter className="modal-footer border-0" />
      </ModalBody>
    </Modal>
  );
};

export default DocumentHistoryModal;