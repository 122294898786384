import { Element as SlateElement } from 'slate';

import { FIELD_GROUPING_VALUE, PROPERTIES, SELECT_FIELD_TYPE_SELECT } from 'constants/editors';
import { initialSigners } from 'store/reducers/editorSlate';
import { AssignmentsMainParts, AssignmentType, ICustomElement } from 'types/Editor';
import { PDFFieldType } from 'types/PdfTemplates';
import { AggregatedFieldsStructureType, PublicPageDataType } from 'types/PublicPage';
import {
  IPublicPageSubtypesDataWithAssignments,
  IPublicPageSubtypesDataWithDeps,
  ISubtypesFromTemplatesGetter,
} from 'types/Subtypes';
import { ITemplateDetails } from 'types/Templates';
import getAssignmentByUrl from 'utils/getAssignmentByUrl';
import { isPDFDocument } from 'utils/PublicPage/documentTypeChecker';
import getFieldsFromData from 'utils/PublicPage/getFieldsFromData';
import { isCollectionExecutedType, isCollectionType } from 'utils/typeChecker';

export const getGroupedFieldsFromTemplateArray = ({
  templates,
  collectionAssignments = initialSigners,
  assignmentsMainPart = AssignmentsMainParts.RECIPIENTS,
  dependenciesArray = [PROPERTIES.VALUE],
  groupedBy = FIELD_GROUPING_VALUE.BY_SUBTYPE,
}: ISubtypesFromTemplatesGetter): IPublicPageSubtypesDataWithAssignments => {
  const temp: IPublicPageSubtypesDataWithAssignments = {};
  collectionAssignments[assignmentsMainPart].forEach((assignmentItem: AssignmentType) => {
    const groupedDependencies: IPublicPageSubtypesDataWithDeps = {};
    const groupedFields: Partial<SlateElement>[] = templates
      .map((template) => {
        const isPDF: boolean = isPDFDocument(template as ITemplateDetails);
        return getFieldsFromData({
          data: template as ITemplateDetails,
          assignment: assignmentItem.type,
          isPDF,
          customFilterHandler: (array) => {
            if (groupedBy === FIELD_GROUPING_VALUE.BY_ASSIGNEE) {
              return array.filter((el) => el.groupBy === FIELD_GROUPING_VALUE.BY_ASSIGNEE);
            }
            return array.filter((el) => el.subtype);
          },
        });
      })
      .flat();

    if (groupedFields.length) {
      groupedFields.forEach((groupedField) => {
        const field = groupedField as Record<string, Partial<ICustomElement>>;
        const fieldName = (
          groupedBy === FIELD_GROUPING_VALUE.BY_SUBTYPE
            ? groupedField.subtype
            : groupedField.name?.trim()
        ) || '';
        const dependenciesScope: { [key: string]: Partial<ICustomElement> } = {};

        dependenciesArray.forEach((key: string) => {
          switch (key) {
            case PROPERTIES.REQUIRED_FIELD:
              dependenciesScope[key] = field[key] ?? false;
              break;
            case PROPERTIES.SELECT_FIELD_TYPE:
              dependenciesScope[key] = field[key] || SELECT_FIELD_TYPE_SELECT;
              break;
            default:
              dependenciesScope[key] = field[key] ?? '';
          }
        });

        groupedDependencies[fieldName] = {
          ...groupedDependencies[fieldName],
          [groupedField.key || 0]: {
            ...(dependenciesScope && dependenciesScope),
          },
        };
      });
    }

    temp[assignmentItem.type] = groupedDependencies;
  });

  return temp;
};

const getGropedFields = (fields: Partial<ICustomElement | PDFFieldType>[]): AggregatedFieldsStructureType[] => {
  const aggregatedFields: AggregatedFieldsStructureType[] = [];

  fields.forEach(({ groupBy, filterName, subtype, name, key, groupByKey, value }) => {
    const existing = aggregatedFields.find((field) => (
      groupBy
      && field.groupBy === groupBy
      && field.groupByKey === groupByKey
      && (
        field.filterName === filterName
        || (filterName === undefined && field.filterName === '')
        || (filterName === '' && field.filterName === undefined)
      )
      && field.name === name
      && field.subtype === subtype
    ));
    if (existing) {
      existing?.keys.push(key ?? 0);
    } else if (groupBy) {
      aggregatedFields.push({
        groupBy,
        groupByKey,
        filterName,
        name: name ?? '',
        subtype,
        keys: [key ?? 0],
        value: value ?? '',
      });
    }
  });

  return aggregatedFields;
};

export const getAllFieldsFromData = (
  data: PublicPageDataType,
  type: string,
  assignment: string | undefined = undefined,
): Partial<ICustomElement | PDFFieldType>[] => {
  const signerAssignment = assignment ?? getAssignmentByUrl(type);
  if (isCollectionType(data)) {
    if (!data.templates) return [];

    const collectionFields: Partial<ICustomElement | PDFFieldType>[] = [];

    data.templates.forEach(({ template }) => {
      const fields: Partial<ICustomElement | PDFFieldType>[] = getFieldsFromData({
        data: template,
        assignment: signerAssignment,
        isPDF: isPDFDocument(template),
      });
      collectionFields.push(...fields);
    });
    return collectionFields;
  }
  if (isCollectionExecutedType(data)) {
    if (!data.documents) return [];

    const collectionFields: Partial<ICustomElement | PDFFieldType>[] = [];

    data.documents.forEach(({ document }) => {
      const fields: Partial<ICustomElement | PDFFieldType>[] = getFieldsFromData({
        data: document,
        assignment: signerAssignment,
        isPDF: isPDFDocument(document),
      });
      collectionFields.push(...fields);
    });
    return collectionFields;
  }

  return getFieldsFromData({
    data,
    assignment: signerAssignment,
    isPDF: isPDFDocument(data),
  });
};

export const getPublicPageGroupedFieldsFromData = (
  data: PublicPageDataType,
  type: string,
  assignment: string | undefined = undefined,
): AggregatedFieldsStructureType[] => getGropedFields(getAllFieldsFromData(data, type, assignment));

export default getGroupedFieldsFromTemplateArray;