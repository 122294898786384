/* eslint-disable */
import { Editor, Element as SlateElement, Transforms, NodeEntry } from 'slate';
import { splitedTable } from '../selection';
import { createRowBody } from 'utils/editorTableHelpers';
import { Col } from 'types/EditorTable';

export const insertAbove = (table: NodeEntry, editor: Editor) => {
  const { selection } = editor;
  if (!selection || !table) return;

  const yIndex = table[1].length;

  const { gridTable, getCol } = splitedTable(editor, table);

  const [startCell]: any = Array.from(Editor.nodes(editor, {
    match: n => SlateElement.isElement(n) && n.type === 'table-cell',
    at: Editor.path(editor, selection),
  }));

  const [insertPositionCol] = getCol(
    (c: Col) => c.isReal && c.cell.key === startCell[0].key
  );

  let checkInsertEnable = true;
  const insertYIndex = insertPositionCol.path[yIndex];
  const insertCols = new Map<string, Col>();

  gridTable[insertYIndex].forEach((col: Col) => {
    if (!col.isReal) {
      const [originCol] = getCol(
        (c: Col) => c.isReal && c.cell.key === col.cell.key
      );

      if (originCol.path[yIndex] === insertYIndex) {
        insertCols.set(String(originCol.cell.key), originCol);
      } else {
        checkInsertEnable = false;
        return;
      }
    } else {
      insertCols.set(String(col.cell.key), col);
    }
  });

  if (!checkInsertEnable || !insertCols.size) {
    return;
  }

  let rows: NodeEntry | null = null;
  [rows] = Array.from(
    Editor.nodes(editor, {
      match: n => SlateElement.isElement(n) && n.type === 'table-row',
      at: Editor.path(editor, selection),
    })
  );
  const currentRow: any = rows[0];

  const newRow = createRowBody((currentRow.children).length);

  const [[, path]] = Array.from(Editor.nodes(editor, {
    match: n => SlateElement.isElement(n) && n.type === 'table-row',
  }));

  Transforms.insertNodes(editor, newRow, {
    at: path,
  });
}