import { Route } from 'react-router-dom';

import { IRoute } from 'types/Route';

export const isThe404Path = (matchLocation: IRoute | undefined) => (
  matchLocation?.path === '*'
);

const createRoutes = (routes: IRoute[]) => (
  routes.map((route) => (
    <Route
      component={route.component}
      path={route.path}
      key={route.path}
      exact
    />
  ))
);

export default createRoutes;