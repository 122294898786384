import { FC, useCallback } from 'react';

import { Input } from 'reactstrap';

import { TEXT_FIELD_MASKS } from 'constants/fieldPropertiesTab';
import { STRING_INPUT_MAX_LEN } from 'constants/validation';
import useDebounceInputField from 'hooks/useDebounceInputField';
import useFieldMaskValue from 'hooks/useFieldMaskValue';
import useTextWidth from 'hooks/useTextWidth';
import { PDFPropsType } from 'types/PdfTemplates';
import { getMainTypeAssignment } from 'utils/assignmentsHelpers';
import { createNameConstant } from 'utils/createNameConstant';
import { createFieldDataAttributes } from 'utils/editorCopyPasteHelpers';
import { getTextSizeClassName } from 'utils/editorHelpers';

import vars from 'variables.module.scss';

const InputEditable: FC<PDFPropsType> = ({
  field,
  isReadOnlyMode,
  onChangeField,
  fieldStyle,
  maskValuesState,
}) => {
  const valueWidth: number = useTextWidth({
    text: field.value,
    font: `${vars.pdfFieldValuesFontSize} ${vars.mainFont}`,
  });
  const isValueOutOfInputBoundaries: boolean = field.size?.width ? field.size.width < valueWidth : false;

  const additionalClassName: string = isValueOutOfInputBoundaries
    ? `adaptive-text-format ${getTextSizeClassName(field.value.length)}`
    : '';

  const hideValue = !maskValuesState?.mask || !maskValuesState?.hide;

  const { hiddenValue, onChangeValueWithMask } = useFieldMaskValue({
    fieldMaskType: field.textFieldMask,
    hideValue: maskValuesState?.mask,
    realValue: field.value,
    onChangeRealValue: onChangeField,
    pdfField: field,
  });

  const processChange = useCallback((newValue: string) => {
    if (hideValue) {
      onChangeField({ target: { value: newValue } } as any, field);
    } else {
      onChangeValueWithMask({ target: { value: newValue } } as any);
    }
  }, [field, hideValue]);

  const {
    localValue,
    processChangeWithDebounce,
  } = useDebounceInputField(
    hideValue ? field.value : hiddenValue,
    processChange,
  );

  return (
    <Input
      autoComplete="off"
      name={createNameConstant(field.fieldName)}
      placeholder={field.fieldName}
      type="text"
      value={localValue}
      onChange={processChangeWithDebounce}
      disabled={isReadOnlyMode}
      style={fieldStyle}
      maxLength={STRING_INPUT_MAX_LEN}
      className={
        `input-editable-field ${additionalClassName} pdf-field-${getMainTypeAssignment(field.assignment)}`
        + `${field.textFieldMask === TEXT_FIELD_MASKS.HIDDEN ? ' hidden-field-in-pdf' : ''}`
      }
      data-pdf-field
      {...(createFieldDataAttributes({ field }))}
    />
  );
};

export default InputEditable;