import { Dispatch as ReactDispatch, FC, SetStateAction, useMemo } from 'react';

import AutocompleteField from 'components/Modals/MondayComModalWindow/AutocompleteField';
import { ALL_BOARDS, NO_BOARDS } from 'constants/mondayComIntegration';
import { MondayComIntegrationObjectType } from 'types/mondayCom/mondayComBoard';

import 'scss/components/TabNavigation/TabNavigation.scss';

interface IMondayComBoardsDropdown {
  mondayComIntegration: MondayComIntegrationObjectType[];
  boardQueryParameter: string | null;
  onChangeMondayComBoardQueryParameter: ReactDispatch<SetStateAction<string | null>>;
}

/**
 * This component is used in share link modal window.
 * A manager select a board, which will be filled out using data from certain template.
 */
const MondayComBoardsDropdown: FC<IMondayComBoardsDropdown> = ({
  mondayComIntegration,
  boardQueryParameter,
  onChangeMondayComBoardQueryParameter,
}) => {
  const isMondayComItemsBoard = useMemo(
    () => mondayComIntegration?.some((boardObject) => boardObject.fieldsMapping),
    [mondayComIntegration],
  );
  const isMondayComSubitemsBoard = useMemo(
    () => mondayComIntegration?.some((boardObject) => boardObject.subitemMapping),
    [mondayComIntegration],
  );
  const isMondayComItemDocumentFileBoard = useMemo(
    () => mondayComIntegration?.some((boardObject) => boardObject.itemDocumentFileSettings),
    [mondayComIntegration],
  );
  if (
    !mondayComIntegration
    || (!isMondayComItemsBoard && !isMondayComSubitemsBoard && !isMondayComItemDocumentFileBoard)
  ) {
    return null;
  }

  const onChangeBoardHandler = (value: string | null) => {
    onChangeMondayComBoardQueryParameter(value);
  };

  const getCurrentOptions = () => {
    let currentOptions = [];
    if (isMondayComItemsBoard) {
      currentOptions = mondayComIntegration
        .filter((boardObject) => boardObject.fieldsMapping)
        .map((boardObject) => boardObject.board.name);
    } else if (isMondayComSubitemsBoard) {
      currentOptions = mondayComIntegration
        .filter((boardObject) => boardObject.subitemMapping)
        .map((boardObject) => boardObject.board.name);
    } else {
      currentOptions = mondayComIntegration
        .filter((boardObject) => boardObject.itemDocumentFileSettings)
        .map((boardObject) => boardObject.board.name);
    }
    return [NO_BOARDS, ALL_BOARDS].concat(currentOptions);
  };

  return (
    <AutocompleteField
      options={getCurrentOptions()}
      currentValue={boardQueryParameter}
      onChangeHandler={onChangeBoardHandler}
      inputLabel={`Select Monday.com ${isMondayComSubitemsBoard ? 'Subitems' : ''} Board to Populate`}
      classes={{ endAdornment: 'endAdornment-top-upset' }}
    />
  );
};

export default MondayComBoardsDropdown;