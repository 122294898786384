import { SignersInfoType, SignerType } from 'types/SendDocuments';

export const EMPTY_SIGNER: SignersInfoType = {
  type: SignerType.RECIPIENT,
  firstName: '',
  firstNameError: false,
  firstNameErrorText: '',
  lastName: '',
  email: '',
  emailError: false,
  emailErrorText: '',
};

export enum SharingResources {
  DOCUMENT = 'document',
  DOCUMENTS_EXECUTED = 'documentExecuted',
  COLLECTION = 'collection',
  COLLECTION_EXECUTED = 'collectionExecuted',
  EMPTY_STRING = '',
}

export const EMAIL_SUBJECT = 'Documents for signature';