import React from 'react';

import { Editor as SlateEditor, Transforms } from 'slate';

import {
  INDENT_DECREASE_FORMAT,
  INDENT_INCREASE_FORMAT,
  INDENT_STEP_MARGIN,
  MAX_INDENT_STEP_MARGIN,
  TAB_KEY_SETTINGS,
} from 'constants/editors';
import { BlockIndentType } from 'types/Editor';

export const getBlockIndent = (editor: SlateEditor) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [match]: any = SlateEditor.nodes(editor, {
    match: (node) => SlateEditor.isBlock(editor, node) && (!!node.textIndent || !!node.margin),
  });

  return match && match[0];
};

export const addPropertyIndent = (
  editor: SlateEditor,
  format: BlockIndentType,
  event?: React.KeyboardEvent<HTMLElement>,
) => {
  const nodeWithIndent = getBlockIndent(editor);
  let marginValue: number = nodeWithIndent?.margin || 0;
  let textIndent: number = nodeWithIndent?.textIndent || 0;
  const { selection } = editor;

  switch (format) {
    case INDENT_INCREASE_FORMAT:
      if (event) event.preventDefault();
      if (selection && selection.focus.offset > 0) {
        editor.insertText(TAB_KEY_SETTINGS.tabIndent);
        break;
      }
      if (!nodeWithIndent || (marginValue === 0 && textIndent === 0)) {
        textIndent = INDENT_STEP_MARGIN * 3;
        break;
      }
      marginValue = (marginValue < MAX_INDENT_STEP_MARGIN)
        ? (marginValue + INDENT_STEP_MARGIN)
        : (marginValue || 0);
      break;
    case INDENT_DECREASE_FORMAT:
      if (selection && selection.focus.offset > 0) {
        break;
      }
      if (nodeWithIndent) {
        if (event) event.preventDefault();
        if (nodeWithIndent.textIndent > 0) {
          textIndent = 0;
          break;
        }
        marginValue = marginValue >= INDENT_STEP_MARGIN
          ? (marginValue - INDENT_STEP_MARGIN)
          : (marginValue || 0);
      }
      break;
    default:
      break;
  }

  Transforms.setNodes(editor, { margin: marginValue, textIndent }, {
    match: (node) => SlateEditor.isBlock(editor, node),
  });
};