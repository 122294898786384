import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Input, Label } from 'reactstrap';

import RadioButtonsGroup from 'components/RadioButtonsGroup';
import { PDF_TYPE } from 'constants/general';
import {
  SHARE_VIEW_DOCUMENT_AND_FORM_BUILDER,
  SHARE_VIEW_FORM_BUILDER_MODE,
  SHARE_VIEW_MODES,
  ShareViewModes,
} from 'constants/shareViewModes';
import { useRolePermission } from 'hooks/useRolePermission';
import { setLinkViewMode } from 'store/actions/sendDocuments';
import { RootStateType } from 'store/reducers';
import { SendDocumentsReducerType } from 'types/SendDocuments';
import { isCollectionPagePath, isPDFTemplateEditorOrListPagePath } from 'utils/routeHelpers';

const DocumentsViewMode = () => {
  const dispatch = useDispatch();
  const { permissionUsingFormBuilder, permissionUsingPDFFormBuilder } = useRolePermission();

  const { isDocumentCompleted } = useSelector((state: RootStateType) => state.sendDocuments ?? {});
  const {
    initialLocation,
    documentsInfo,
  }: SendDocumentsReducerType = useSelector((state: RootStateType) => state.sendDocuments);

  const handlerChangeViewMode = (viewMode: string) => {
    dispatch(setLinkViewMode(viewMode));
  };

  const isDocumentsCollection = documentsInfo.length > 1;
  const isPDFDocument = documentsInfo.length === 1 && documentsInfo[0].type === PDF_TYPE;

  const isCollectionPage = isCollectionPagePath(initialLocation ?? '');
  const isPDFTemplateEditor = isPDFTemplateEditorOrListPagePath(initialLocation ?? '');

  const canUseForCollection = (isCollectionPage || isDocumentsCollection) && permissionUsingFormBuilder;
  const canUseForPDFDocument = (isPDFTemplateEditor || isPDFDocument) && permissionUsingPDFFormBuilder;

  const modes = canUseForCollection || canUseForPDFDocument
    ? SHARE_VIEW_MODES.concat(SHARE_VIEW_FORM_BUILDER_MODE, SHARE_VIEW_DOCUMENT_AND_FORM_BUILDER)
    : SHARE_VIEW_MODES;

  return (
    <div className="container select-signers-card">
      <div className="row mt-3">
        <div className="col-12">
          <div className="signer-card">
            Documents view mode
          </div>
        </div>
      </div>
      <div className="row my-3">
        <div className="col-12">
          {
            isDocumentCompleted ? (
              <FormGroup className="ms-2" check inline>
                <Input type="checkbox" disabled checked={isDocumentCompleted} />
                <Label check>Read Only</Label>
              </FormGroup>
            ) : (
              <RadioButtonsGroup
                radiosArray={modes}
                onChangeHandler={handlerChangeViewMode}
                defaultValue={ShareViewModes.SHARE_MODE_BOTH}
              />
            )
          }
        </div>
      </div>
    </div>
  );
};

export default DocumentsViewMode;