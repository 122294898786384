import { FC } from 'react';

import {
  ATTACHMENT_FIELD,
  CHECKBOX_FIELD,
  DATE_FIELD,
  OPTION,
  QUESTION_FIELD,
  SELECT_FIELD,
  SIGNING_FIELD,
  TEXT_FIELD,
  TEXTAREA_FIELD,
} from 'constants/editors';
import {
  AttachIcon,
  CalendarIcon,
  CheckboxIcon,
  DropdownIcon,
  InputFieldIcon,
  InsertTextIcon,
  SignatureIcon,
} from 'svg/FieldsToolbarsIcons';
import MoveIcon from 'svg/MoveIcon';

interface IFieldIcon {
  type: string;
}

const FieldIconWrapper: FC<IFieldIcon> = ({ type }) => {
  switch (type) {
    case TEXT_FIELD:
      return <InputFieldIcon />;
    case DATE_FIELD:
      return <CalendarIcon />;
    case SELECT_FIELD:
      return <DropdownIcon />;
    case SIGNING_FIELD:
      return <SignatureIcon />;
    case TEXTAREA_FIELD:
      return <InsertTextIcon />;
    case ATTACHMENT_FIELD:
      return <AttachIcon />;
    case QUESTION_FIELD:
      return <InputFieldIcon />;
    case CHECKBOX_FIELD:
      return <CheckboxIcon />;
    case OPTION:
      return <MoveIcon />;
    default:
      return <InputFieldIcon />;
  }
};

export default FieldIconWrapper;