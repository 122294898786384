import { Dispatch, FC, ReactElement, SetStateAction, useEffect, useState } from 'react';

import OpenCloseButton from 'components/Sidebar/OpenCloseButton';
import SectionEditorFormModal from 'containers/SectionEditorFormModal';
import { useRolePermission } from 'hooks/useRolePermission';
import { ISection } from 'types/Sections';
import { ITemplate } from 'types/Templates';
import { isWindowWidthLessXLSize } from 'utils/mobileDeviceHelper';

interface IDocumentNavigator {
  panelTitle?: string;
  sections?: ISection[];
  addSection?: (section: ISection) => void;
  addTemplate?: (template: ITemplate) => void;
  sectionsAddModal?: boolean;
  setSectionsAddModal?: Dispatch<SetStateAction<boolean>>;
  readOnlyMode?: boolean;
  showPanelDefault?: boolean;
  topRenderChildren?: ReactElement | null;
  renderChildrenUnderSidebar?: ReactElement | null;
}

const DocumentNavigator: FC<IDocumentNavigator> = ({
  panelTitle = 'Outline',
  sections = [],
  addSection = () => null,
  addTemplate = () => null,
  sectionsAddModal = false,
  setSectionsAddModal = () => null,
  readOnlyMode = false,
  showPanelDefault = true,
  topRenderChildren = null,
  renderChildrenUnderSidebar = null,
  children,
}) => {
  const { permissionUsingSections, isUserReadOnly } = useRolePermission();
  const readOnlyUserStyle = isUserReadOnly ? ' d-none' : '';

  const [showPanel, setShowPanel] = useState<boolean>(showPanelDefault);

  useEffect(() => {
    setShowPanel(showPanelDefault);
  }, [showPanelDefault]);

  useEffect(() => {
    if (isWindowWidthLessXLSize(window.innerWidth)) {
      setShowPanel(false);
    }
  }, [window.innerWidth]);

  if (readOnlyMode) {
    return null;
  }

  return (
    <div
      className={
        `editor-navigator-wrapper left-0 ${showPanel ? '' : ' inactive'}${readOnlyUserStyle}`
      }
    >
      {
        showPanel
          ? (
            <div className="p-3 sidebar-panel">
              {
                topRenderChildren || null
              }
              <div className="p-3 editor-navigator">
                <div className="editor-navigator-header">
                  <div className="editor-navigator-title">
                    { panelTitle }
                  </div>
                  <div className="toggle-button">
                    <OpenCloseButton
                      isActive={showPanel}
                      toggleHandler={setShowPanel}
                    />
                  </div>
                </div>
                { children }
                <SectionEditorFormModal
                  create_docs
                  showModal={sectionsAddModal}
                  addSection={addSection}
                  addTemplate={addTemplate}
                  closeModal={setSectionsAddModal}
                  templateSections={sections}
                  permissionUsingSections={permissionUsingSections}
                />
              </div>
            </div>
          )
          : (
            <OpenCloseButton
              isActive={showPanel}
              toggleHandler={setShowPanel}
            />
          )
      }
      {
        showPanel && renderChildrenUnderSidebar
          ? renderChildrenUnderSidebar
          : null
      }
    </div>
  );
};

export default DocumentNavigator;