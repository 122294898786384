/* eslint-disable */
import { withHistory } from 'slate-history';
import { withReact } from 'slate-react';
import { withCheckList } from './withCheckList';
import { withCopyPaste } from './withCopyPaste';
import { withForms } from './withForms';
import { withHeaders } from './withHeaders';
import { withImages } from './withImage';
import { withLinks } from './withLinks';
import { withTables } from './withTables';
import { withUpdateNodes } from './withUpdateNodes';
import withFields from './withFields';

export default [
  withUpdateNodes,
  withForms,
  withFields,
  withCheckList,
  withLinks,
  withImages,
  withTables,
  withCopyPaste,
  withHistory,
  withReact,
  withHeaders,
];