import { FC } from 'react';

import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

interface IAutocompleteField {
  options: string[];
  currentValue: string | null;
  onChangeHandler: (value: string | null) => void;
  inputLabel: string;
  classes?: Record<string, string>
  additionalParameters?: Record<string, string>
}

const AutocompleteField: FC<IAutocompleteField> = ({
  options,
  currentValue,
  onChangeHandler,
  inputLabel,
  classes = {},
}) => (
  <Autocomplete
    disablePortal
    blurOnSelect
    clearOnEscape
    options={options}
    isOptionEqualToValue={(option, value) => (option === value as string)}
    value={currentValue}
    onChange={(_, value) => onChangeHandler(value)}
    renderInput={(params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        onChange={() => null}
        label={inputLabel}
      />
    )}
    classes={classes}
  />
);

export default AutocompleteField;