import { Editor, Element } from 'slate';

import { FIELD_TYPE } from 'constants/editors';

export const getIsSlateFieldName = (editor: Editor) => {
  const [match] = Array.from(Editor.nodes(editor, {
    match: (n) => Element.isElement(n) && FIELD_TYPE.includes(n.type),
  }));

  return Boolean(match);
};

export default undefined;