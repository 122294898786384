import { FC } from 'react';

import AutocompleteField from 'components/Modals/MondayComModalWindow/AutocompleteField';
import { ICustomElement } from 'types/Editor';
import { MondayComColumnType } from 'types/mondayCom/mondayComBoard';
import { PDFFieldType } from 'types/PdfTemplates';

interface IMondayComAttachmentColumnSelect {
  boardId: string;
  field: Partial<ICustomElement> | PDFFieldType;
  columns: MondayComColumnType[];
  currentColumn: MondayComColumnType | null;
  onChangeItemAttachmentSettingsHandler: (
    boardId: string,
    fieldKey: number,
    targetColumn?: MondayComColumnType
  ) => void;
}

const MondayComAttachmentColumnSelect: FC<IMondayComAttachmentColumnSelect> = ({
  boardId,
  field,
  columns,
  currentColumn,
  onChangeItemAttachmentSettingsHandler,
}) => {
  const changeAttachmentColumnHandler = (value: string | null) => {
    const targetColumn = columns.find((column) => column.title === value);
    onChangeItemAttachmentSettingsHandler(boardId, field.key ?? 0, targetColumn);
  };

  return (
    <AutocompleteField
      options={columns.map((column: MondayComColumnType) => column.title)}
      currentValue={currentColumn ? currentColumn.title : ''}
      onChangeHandler={changeAttachmentColumnHandler}
      inputLabel="Select Column"
    />

  );
};

export default MondayComAttachmentColumnSelect;