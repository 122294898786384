import { useDispatch, useSelector } from 'react-redux';

import useEnableSmsValidationParameter from 'hooks/MondayCom/useEnableSmsValidationParameter';
import { setUserSmsConsent } from 'store/actions/publicPages';
import { RootStateType } from 'store/reducers';

const usePhoneNumberUserIdentity = () => {
  const dispatch = useDispatch();
  const {
    data,
    userSmsConsent,
  } = useSelector((state: RootStateType) => state.publicPages);
  const enabledSMSValidation = useEnableSmsValidationParameter(true);
  const isMondayComSubitem = data?.monday_com_subitem;

  const changeSmsConsenthandler = () => {
    dispatch(setUserSmsConsent(!userSmsConsent));
  };

  return {
    smsConsent: userSmsConsent,
    isVerifyIdentityViaSms: enabledSMSValidation,
    isMondayComSubitem,
    changeSmsConsenthandler,
  };
};

export default usePhoneNumberUserIdentity;