import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { RootStateType } from 'store/reducers';
import { CssStyleObjectType } from 'types/BrandingColorSchema';
import { ICompanyBranding } from 'types/CompanyBranding';

export const enum CustomStylesEnum {
  button = 'button',
  outlinedBorderButton = 'outlinedBorderButton',
  title = 'title',
  description = 'description',
  input = 'input',
}

const useStyleSchema = <T extends CustomStylesEnum>(
  keys: Array<T>,
): Partial<Record<T, CssStyleObjectType>> => {
  const { styleJSON }: ICompanyBranding = useSelector((state: RootStateType) => state.companyBranding);

  return useMemo(() => {
    const style: Partial<Record<CustomStylesEnum, CssStyleObjectType>> = {
      [CustomStylesEnum.button]: {
        ...(styleJSON?.mainBrandColor && {
          background: styleJSON.mainBrandColor,
          hoverBoxShadow: `0px 4px 12px ${styleJSON.mainBrandColor}`,
        }),
      },
      [CustomStylesEnum.outlinedBorderButton]: {
        ...(styleJSON?.mainBrandColor && {
          background: 'transparent',
          hoverBoxShadow: 'none',
          border: `2px solid ${styleJSON.mainBrandColor}`,
          color: `${styleJSON.mainBrandColor}`,
        }),
      },
      [CustomStylesEnum.title]: {
        ...(styleJSON?.headerTextFontFamily && { fontFamily: styleJSON.headerTextFontFamily }),
      },
      [CustomStylesEnum.description]: {
        ...(styleJSON?.paragraphTextFontFamily && { fontFamily: styleJSON.paragraphTextFontFamily }),
      },
      [CustomStylesEnum.input]: {
        ...(styleJSON?.mainBrandColor && { labelColor: styleJSON.mainBrandColor }),
      },
    };

    const resultStyleObject: Partial<Record<CustomStylesEnum, CssStyleObjectType>> = {};

    keys.forEach((key) => {
      resultStyleObject[key] = style[key];
    });

    return resultStyleObject;
  }, [styleJSON, keys]);
};

export default useStyleSchema;