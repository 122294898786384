import { FC } from 'react';

import { NavLink } from 'react-router-dom';

import { useRolePermission } from 'hooks/useRolePermission';
import { isNavItemActive } from 'utils/routeHelpers';

const TopNav: FC = () => {
  const { headerTabsPermission } = useRolePermission();

  return (
    <>
      {
        headerTabsPermission.map((value) => (
          <NavLink
            key={value}
            to={`/${value.replace('\'', '')}`}
            activeClassName="is-active"
            isActive={(match, location) => isNavItemActive(value, match, location)}
          >
            <span className="text-capitalize">{value}</span>
          </NavLink>
        ))
      }
    </>
  );
};

export default TopNav;