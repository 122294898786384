import { FC, ReactNode } from 'react';

interface ISettingRowProps {
  label: string;
  children: ReactNode;
}

const SettingsRow: FC<ISettingRowProps> = ({
  label,
  children,
}) => (
  <div className="row mb-3 pb-3 d-flex align-items-center account-settings__row">
    <div className="col-12 col-lg-3 account-settings__label">{label}</div>
    <div className="col-12 col-lg">
      <div className="account-settings__text">
        {children}
      </div>
    </div>
  </div>
);

export default SettingsRow;