import { FC, useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { CellMeasurer, CellMeasurerCache, List, WindowScroller } from 'react-virtualized';

import { DeleteOutline as DeleteIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import FormBuilderDescription from 'components/FormBuilder/FormBuilderDescription';
import FormBuilderFieldView from 'components/FormBuilder/FormBuilderFieldView';
import FormBuilderHeader from 'components/FormBuilder/FormBuilderHeader';
import { SortableItem } from 'components/FormBuilder/FormBuilderSortableComponents';
import SelectableItem from 'components/FormBuilder/SelectableItem';
import SortableListWrapper from 'components/FormBuilder/SortableListWrapper';
import ElementSortButton from 'components/FormConstructor/Buttons/ElementSortButton';
import { setSelectedFieldKey } from 'store/actions/pdfTemplates';
import { updateFormBuilderSection, updateFormBuilderSections } from 'store/actions/userData';
import { RootStateType } from 'store/reducers';
import { ICustomElement } from 'types/Editor';
import { FormBuilderType } from 'types/FormBuilder';
import { IFormBuilderSection } from 'types/Sections';
import { setFormBuilderGroupedFields } from 'utils/FormBuilder/formBuilderHelpers';
import { getFormBuilderByType } from 'utils/FormBuilder/store';

import 'react-virtualized/styles.css';

interface IFormBuilderSectionProps {
  formBuilderSection: IFormBuilderSection;
  hoveredSectionKey: number;
  formBuilderType: FormBuilderType;
}

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 100,
});

const FORM_BUILDER_FIELDS_WIDTH = 506;
const FORM_BUILDER_SCROLL_HEIGHT = 350;

const FormBuilderSection: FC<IFormBuilderSectionProps> = ({
  formBuilderSection,
  hoveredSectionKey,
  formBuilderType,
}) => {
  const { description, fields, name, key } = formBuilderSection;

  const [isSectionSelected, setIsSectionSelected] = useState<boolean>(false);
  const formBuilder: IFormBuilderSection[] = setFormBuilderGroupedFields(useSelector((state: RootStateType) => (
    getFormBuilderByType(state, formBuilderType)
  )));

  const isSectionRemovable: boolean = fields.length === 0;

  const dispatch = useDispatch();

  const changeSectionName = (sectionName: string) => (
    dispatch(updateFormBuilderSection({
      formBuilder: { name: sectionName },
      key,
      formBuilderType,
    }))
  );

  const changeDescription = (description: string) => (
    dispatch(updateFormBuilderSection({
      formBuilder: { description },
      key,
      formBuilderType,
    }))
  );

  const handleDeleteSection = (): void => {
    dispatch(updateFormBuilderSection({
      formBuilder: formBuilder.filter(
        (formBuilderSection: IFormBuilderSection) => (
          formBuilderSection.key !== key
        ),
      ),
      formBuilderType,
    }));
  };

  const onDeleteFormBuilderParagraph = useCallback((fieldKey: number) => {
    const updatedFormBuilder = formBuilder.map((section) => ({
      ...section,
      fields: section.fields.filter((field) => field.key !== fieldKey),
    }));
    dispatch(updateFormBuilderSections(updatedFormBuilder, formBuilderType));
  }, [dispatch, formBuilder, formBuilderType]);

  const getRowsHeight = useCallback((fields: Partial<ICustomElement>[]): number => (
    fields.reduce((acc: number, field: Partial<ICustomElement>) => acc + cache.getHeight(field.position ?? 0, 1), 0)
  ), [fields.length]);

  const totalHeight: number = getRowsHeight(fields);

  return (
    <div
      className="d-flex"
      onMouseEnter={() => setIsSectionSelected(true)}
      onMouseLeave={() => setIsSectionSelected(false)}
    >
      <div className="form-builder_sort_button_wrapper">
        <ElementSortButton className={`sort_button ${isSectionSelected ? 'visible' : 'invisible'}`} />
      </div>
      <div
        className={`form-builder_section ${isSectionSelected ? 'active' : ''}`}
      >
        <FormBuilderHeader
          sectionName={name}
          changeSectionName={changeSectionName}
        />
        <FormBuilderDescription
          description={description}
          setDescription={changeDescription}
        />
        <SortableListWrapper
          formBuilderType={formBuilderType}
          hoveredSectionKey={hoveredSectionKey}
          fieldsToShow={fields}
          sectionKey={key}
          isFormBuilderOpened
        >
          <WindowScroller>
            {({ height, registerChild, isScrolling, onChildScroll }) => (
              <div ref={registerChild}>
                <List
                  width={FORM_BUILDER_FIELDS_WIDTH}
                  autoHeight={height > totalHeight}
                  height={height - FORM_BUILDER_SCROLL_HEIGHT}
                  isScrolling={isScrolling}
                  onScroll={onChildScroll}
                  deferredMeasurementCache={cache}
                  rowHeight={cache.rowHeight}
                  rowCount={fields.length}
                  overscanRowCount={2}
                  rowRenderer={({ index, key: listKey, style, parent }) => (
                    <CellMeasurer
                      key={listKey}
                      cache={cache}
                      parent={parent}
                      columnIndex={0}
                      rowIndex={index}
                    >
                      {({ registerChild, measure }) => (
                        <div style={style} ref={() => registerChild}>
                          <SortableItem
                            index={index}
                            key={`${fields[index].name}-${fields[index].key}`}
                            fieldKey={fields[index].key}
                          >
                            <SelectableItem fieldKey={fields[index].key}>
                              <FormBuilderFieldView
                                index={index}
                                measure={measure}
                                width={FORM_BUILDER_FIELDS_WIDTH}
                                cache={cache}
                                sectionKey={key}
                                field={{
                                  ...fields[index],
                                }}
                                onClick={(selectedFieldKey: number) => dispatch(setSelectedFieldKey(selectedFieldKey))}
                                formBuilderType={formBuilderType}
                                onDeleteParagraphField={onDeleteFormBuilderParagraph}
                              />
                            </SelectableItem>
                          </SortableItem>
                        </div>
                      )}
                    </CellMeasurer>
                  )}
                />
              </div>
            )}
          </WindowScroller>
        </SortableListWrapper>
      </div>
      <IconButton
        title="Delete"
        size="small"
        className={`p1 ${isSectionRemovable && isSectionSelected ? 'visible' : 'invisible'}`}
        onClick={handleDeleteSection}
        color="error"
      >
        <DeleteIcon />
      </IconButton>
    </div>
  );
};

export default FormBuilderSection;