/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';

import ENDPOINT from 'constants/endpoints';
import {
  URL_PUBLIC_COLLECTION,
  URL_PUBLIC_DOCUMENT,
  URL_PUBLIC_DOCUMENT_EXECUTED_LINK,
  URL_PUBLIC_DOCUMENT_LINK,
  URL_PUBLIC_MULTI_TEMPLATE,
  URL_PUBLIC_MULTI_TEMPLATE_LINK,
  URL_PUBLIC_TEMPLATE,
} from 'constants/general';
import { SUCCESS_CODE } from 'constants/generalErrors';
import { IFileApiDeleteRequestData, IResponseDeleteObj, IResponseOfRequest } from 'types/Api';
import {
  IChangePasswordRequestObj,
  ICompany,
  ILoginRequestObj,
  ILoginResponse,
  IRegisterResponse,
  IUserDetail,
  RegisterRequestObjType,
  UpdateMyCompanyRequestObjType,
  UpdateMyDetailsRequestObjType,
} from 'types/Auth';
import { ICreateDocumentRequestObj, IDocumentDetails, IUpdateDocumentRequestObj } from 'types/Documents';
import { IDoctype, IState } from 'types/Metadata';
import { ICollectionDetails } from 'types/MultiTemplate';
import { ISection, ISectionRequestObj } from 'types/Sections';
import { SendDocumentInfoType, SendDocumentsReducerType } from 'types/SendDocuments';
import { ITemplate, ITemplateData, ITemplateDetails, ITemplateRequestObj } from 'types/Templates';
import { axiosInstance } from 'utils/axiosInstance';
import { fileLinkDownloadHelper } from 'utils/fileLinkDownload';

// AUTH
export const login = async (user: ILoginRequestObj): Promise<IResponseOfRequest<ILoginResponse>> => (
  axiosInstance.post(ENDPOINT.LOGIN, user)
);

export const register = async (user: RegisterRequestObjType): Promise<IResponseOfRequest<IRegisterResponse>> => (
  axiosInstance.post('/auth/register', user)
);

export const refreshToken = (): Promise<IResponseOfRequest<ILoginResponse>> => (
  axiosInstance.post(ENDPOINT.REFRESH)
);

export const logout = (): Promise<IResponseOfRequest<unknown>> => (
  axiosInstance.post(ENDPOINT.LOGOUT, {})
);

export const createResetPasswordLink = (data: any): Promise<IResponseOfRequest<unknown>> => (
  axiosInstance.post(ENDPOINT.RESET_PASSWORD, data)
);

export const checkResetPasswordLink = (linkId: string): Promise<IResponseOfRequest<unknown>> => (
  axiosInstance.get(`${ENDPOINT.RESET_PASSWORD}/${linkId}`)
);

export const resetPassword = (linkId: string, password: string): Promise<IResponseOfRequest<unknown>> => (
  axiosInstance.post(`${ENDPOINT.RESET_PASSWORD}/${linkId}`, { password })
);

export const getMyProfile = (): Promise<IResponseOfRequest<IUserDetail>> => (
  axiosInstance.get('/auth/me')
);

export const updateMyDetails = (body: UpdateMyDetailsRequestObjType): Promise<IResponseOfRequest<IUserDetail>> => (
  axiosInstance.patch('/auth/me', body)
);

export const getMyCompany = (): Promise<ICompany> => (
  axiosInstance.get('/auth/me/company').then((res) => res.data)
);

export const updateMyCompany = (body: UpdateMyCompanyRequestObjType): Promise<IResponseOfRequest<ICompany>> => (
  axiosInstance.patch('/auth/me/company', body)
);

export const changePassword = (body: IChangePasswordRequestObj): Promise<IResponseOfRequest<{ message: string }>> => (
  axiosInstance.post('/auth/change-password', body)
);

// SECTION
export const getSectionsList = (): Promise<IResponseOfRequest<ISection[]>> => (
  axiosInstance.get(ENDPOINT.SECTION)
);

export const getSectionDetails = (id: string): Promise<IResponseOfRequest<ISection>> => (
  axiosInstance.get(`${ENDPOINT.SECTION}/${id}`)
);

export const addSection = (body: ISectionRequestObj): Promise<IResponseOfRequest<ISection>> => (
  axiosInstance.post(ENDPOINT.SECTION, body)
);

export const editPartialSections = (body: {
  sections: Partial<ISection>[];
}): Promise<IResponseOfRequest<ISection[]>> => (
  axiosInstance.patch(ENDPOINT.SECTION, body)
);

export const editSection = (id: string, body: ISection): Promise<IResponseOfRequest<ISection>> => (
  axiosInstance.put(`${ENDPOINT.SECTION}/${id}`, body)
);

export const removeSection = (id: string): Promise<IResponseOfRequest<IResponseDeleteObj>> => (
  axiosInstance.delete(`${ENDPOINT.SECTION}/${id}`)
);

// PUBLIC PAGE
export const publicPageRequestHandler = <T>(url: string): Promise<IResponseOfRequest<T>> => (
  axiosInstance.get(url)
);

export const getResources = (): Promise<IResponseOfRequest<unknown>> => (
  axiosInstance.get(ENDPOINT.RESOURCES)
);

export const getMetadataResources = (): Promise<IResponseOfRequest<unknown>> => (
  axiosInstance.get(ENDPOINT.METADATA)
);

export const getTemplatesList = (): Promise<IResponseOfRequest<ITemplate[]>> => (
  axiosInstance.get(ENDPOINT.DOCUMENT)
);

// TODO combine next two methods
export const addTemplate = (body: ITemplateRequestObj): Promise<IResponseOfRequest<ITemplate>> => (
  axiosInstance.post(ENDPOINT.DOCUMENT, body)
);

export const addTemplateWithSection = (body: ITemplateData): Promise<IResponseOfRequest<ITemplate>> => (
  axiosInstance.post(ENDPOINT.DOCUMENT, body)
);

export const getTemplateDetails = async (id: string): Promise<IResponseOfRequest<ITemplateDetails> | unknown> => (
  axiosInstance.get(`${ENDPOINT.DOCUMENT}/${id}`)
);

export const editTemplate = (id: string, body: ITemplateRequestObj): Promise<IResponseOfRequest<ITemplate>> => (
  axiosInstance.put(`${ENDPOINT.DOCUMENT}/${id}`, body)
);

export const removeTemplate = (id: string): Promise<IResponseOfRequest<IResponseDeleteObj>> => (
  axiosInstance.delete(`${ENDPOINT.DOCUMENT}/${id}`)
);

export const batchSendDocument = (data: { id: string, content: object }) => (
  axiosInstance.post(`${ENDPOINT.DOCUMENT}/${data.id}/batch-send`, data.content)
);

export const getDocumentList = (): Promise<IResponseOfRequest<ITemplateDetails[]>> => (
  axiosInstance.get(ENDPOINT.ALL_DOCUMENTS)
);

// DOCUMENTS_EXECUTED
export const getDocuments = (): Promise<IResponseOfRequest<IDocumentDetails[]>> => (
  axiosInstance.get(ENDPOINT.DOCUMENT_EXECUTED)
);

export const getDocumentDetails = (id: string): Promise<IResponseOfRequest<IDocumentDetails>> => (
  axiosInstance.get(`${ENDPOINT.DOCUMENT_EXECUTED}/${id}`)
);

export const editDocumentDetails = (
  id: string,
  body: IUpdateDocumentRequestObj,
): Promise<IResponseOfRequest<IDocumentDetails>> => (
  axiosInstance.patch(`${ENDPOINT.DOCUMENT_EXECUTED}/${id}`, body)
);

export const createDocument = (body: ICreateDocumentRequestObj): Promise<IResponseOfRequest<IDocumentDetails>> => (
  axiosInstance.post(ENDPOINT.DOCUMENT_EXECUTED, body)
);

export const deleteDocumentDetails = (id: string): Promise<IResponseOfRequest<IResponseDeleteObj>> => (
  axiosInstance.delete(`${ENDPOINT.DOCUMENT_EXECUTED}/${id}`)
);

// PUBLIC TEMPLATE
export const getPublicTemplate = (id: string): Promise<IResponseOfRequest<ITemplateDetails>> => (
  axiosInstance.get(`/${URL_PUBLIC_TEMPLATE}/${id}`)
);

export const saveTemplatePublicLink = (body: any): Promise<IResponseOfRequest<unknown>> => (
  axiosInstance.post(`/${URL_PUBLIC_DOCUMENT_LINK}`, body)
);

export const createDocumentPublic = (data: any): Promise<IResponseOfRequest<unknown>> => (
  axiosInstance.post(`/${URL_PUBLIC_DOCUMENT}/${data.id}?type=public`, data.body)
);

// PUBLIC DOCUMENTS
export const getPublicDocument = (id: string): Promise<IResponseOfRequest<IDocumentDetails>> => (
  axiosInstance.get(`/${URL_PUBLIC_DOCUMENT}/${id}`)
);

export const saveDocumentPublicLink = (body: any): Promise<IResponseOfRequest<unknown>> => (
  axiosInstance.post(`/${URL_PUBLIC_DOCUMENT_EXECUTED_LINK}`, body)
);

export const editPublicDocument = async (data: any): Promise<IResponseOfRequest<unknown>> => {
  const response: any = await axiosInstance.patch(`/${URL_PUBLIC_DOCUMENT}/${data.id}?type=public`, data.document);
  return response;
};

// PUBLIC COLLECTIONS (multi templates)
export const getPublicMultiTemplate = (id: string): Promise<IResponseOfRequest<ICollectionDetails>> => (
  axiosInstance.get(`/${URL_PUBLIC_MULTI_TEMPLATE}/${id}`)
);

export const saveMultiTemplatePublicLink = (body: any): Promise<IResponseOfRequest<unknown>> => (
  axiosInstance.post(`/${URL_PUBLIC_MULTI_TEMPLATE_LINK}`, body)
);

export const createCollection = (data: any): Promise<IResponseOfRequest<unknown>> => (
  axiosInstance.post(`/${URL_PUBLIC_COLLECTION}/${data.id}?type=public`, data.body)
);

export const updateExecutedCollection = (data: any): Promise<IResponseOfRequest<unknown>> => (
  axiosInstance.patch(`${ENDPOINT.PUBLIC_COLLECTION_EXECUTED}/${data.id}?type=public`, data.body)
);

// ATTACHMENTS: download, delete
export const downloadAttachment = async (data: any) => {
  if (data.pdfFileLink) {
    fileLinkDownloadHelper([data.pdfFileLink]);
    return 'fileKey';
  }
  const response = await axiosInstance.post('/files-api', data);
  if (response && response.status === SUCCESS_CODE && response.data.isCollection) {
    fileLinkDownloadHelper(response.data.path);
    return true;
  }

  const fileLink: string = response?.data.path;
  const fileKey: string = response?.data.key;

  const auditLogFileLink: string | undefined = response?.data.audit_log_path;
  const auditLogFileKey: string = response?.data.audit_log_key;

  const nom151FileLink: string | undefined = response?.data.nom151_path;
  const nom151FileKey: string = response?.data.nom151_key;

  if (response && response.status === SUCCESS_CODE && fileLink) {
    const filesLinkArray = [fileLink];
    if (auditLogFileLink) filesLinkArray.push(auditLogFileLink);
    if (nom151FileLink) filesLinkArray.push(nom151FileLink);

    fileLinkDownloadHelper(filesLinkArray);
    if (fileKey || (fileKey && auditLogFileKey)) {
      return fileKey || (fileKey && auditLogFileKey && nom151FileKey);
    }
    return true;
  }
};

export const getLinkForPdfRenderer = async (
  {
    pdf,
    html,
    css,
    combinePDF,
    executedDocId,
    addLogPage,
    pageMargins,
  }: {
    pdf: boolean,
    html: string | undefined,
    css: string,
    combinePDF: boolean;
    executedDocId: string;
    addLogPage: boolean;
    pageMargins: string;
  },
) => {
  const response = await axiosInstance.post('/files-api', {
    pdf,
    html,
    css,
    combinePDF,
    executedDocId,
    addLogPage,
    pageMargins,
  });
  if (response && response.status === 200) {
    return response;
  }
  return false;
};

export const deleteAttachment = async (data: IFileApiDeleteRequestData) => {
  const response: AxiosResponse<string> = await axiosInstance.patch('/files-api', data);
  if (response && response.status === 200) {
    return response.data;
  }
};

// MISC
export const getHTMLFromStaticDir = async (name: string): Promise<string | undefined> => {
  const response = await axiosInstance.get(`/static/html/${name}.html`) as string | undefined;
  if (response) return response;
};

//  METADATA
export const getMetadataDocumentStatusList = (): Promise<IResponseOfRequest<unknown>> => (
  axiosInstance.get('/metadata/status')
);

export const getMetadataStatesList = (): Promise<IResponseOfRequest<IState[]>> => (
  axiosInstance.get('/metadata/state')
);

export const getMetadataDoctypeList = (): Promise<IResponseOfRequest<IDoctype>> => (
  axiosInstance.get('/metadata/doctype')
);

// Send documents share link via email
export const sendDocuments = (
  data: SendDocumentsReducerType,
): Promise<IResponseOfRequest<unknown>> => (
  axiosInstance.post(ENDPOINT.SEND_DOCUMENTS, data)
);

// Get full files links for downloading on the front-end
export const getFilesFullLinks = async (filesPath: string[]) => {
  const result = await axiosInstance.post(ENDPOINT.FILES_URLS, filesPath);
  return result.data;
};

export const getDataForBatchExcelExport = async (documentsIds: SendDocumentInfoType[]) => {
  const result = await axiosInstance.post(ENDPOINT.BATCH_EXCEL_EXPORT, documentsIds);
  return result.data;
};

// Public pages views (both, document, form)
export const changeShareLinkViewMode = (data: { resource: string, linkId: string, viewMode: string }) => (
  axiosInstance.patch(ENDPOINT.SHARED_LINK, data)
);