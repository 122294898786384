import {
  CREATE_MULTI_TEMPLATE,
  DELETE_MULTI_TEMPLATES_START,
  DELETE_PDF_PARTS_MULTI_TEMPLATE,
  GET_MULTI_TEMPLATE_DETAILS,
  GET_MULTI_TEMPLATES_START,
  SET_MULTI_TEMPLATE_DETAILS,
  SET_PDF_FIELD_BY_IDS_MULTI_TEMPLATE,
  SET_PDF_PART_MULTI_TEMPLATE,
  UPDATE_MULTI_TEMPLATE,
} from 'store/actions/actionTypes';
import { DocumentTypesType } from 'types/Documents';
import { ICollectionDetails, IMultiTemplateRequestObj } from 'types/MultiTemplate';
import { PDFFieldType } from 'types/PdfTemplates';

export const getMultiTemplateDetails = (id: number, isFormBuilderAvailable: boolean) => ({
  type: GET_MULTI_TEMPLATE_DETAILS,
  payload: {
    id,
    isFormBuilderAvailable,
  },
});

export const setMultiTemplateDetails = (multiTemplateDetails: ICollectionDetails | null) => ({
  type: SET_MULTI_TEMPLATE_DETAILS,
  payload: multiTemplateDetails,
});

export const createMultiTemplateDetails = (multiTemplateData: IMultiTemplateRequestObj) => ({
  type: CREATE_MULTI_TEMPLATE,
  payload: multiTemplateData,
});

export const updateMultiTemplateDetails = (multiTemplateData: IMultiTemplateRequestObj) => ({
  type: UPDATE_MULTI_TEMPLATE,
  payload: multiTemplateData,
});

export const getMultiTemplatesStart = () => ({
  type: GET_MULTI_TEMPLATES_START,
});

export const deleteMultiTemplateStart = (ids: number[], resourceType: DocumentTypesType) => ({
  type: DELETE_MULTI_TEMPLATES_START,
  payload: { ids, resourceType },
});

export const setPdfPartOfMultiTemplate = (pdfTemplate: any, fields: PDFFieldType[] | null = null) => ({
  type: SET_PDF_PART_MULTI_TEMPLATE,
  payload: {
    pdfTemplate,
    fields,
  },
});

export const setPdfFieldByIdsMultiTemplate = (
  { templateId, field, fieldKey }: { templateId: number, field: PDFFieldType, fieldKey: number },
) => ({
  type: SET_PDF_FIELD_BY_IDS_MULTI_TEMPLATE,
  payload: {
    templateId,
    field,
    fieldKey,
  },
});

export const deletePdfPartsMultiTemplate = () => ({
  type: DELETE_PDF_PARTS_MULTI_TEMPLATE,
});