import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { COLLECTION, DOCUMENT, DOCUMENT_EXECUTED } from 'constants/documentTypes';
import { DOCUMENT_TYPE } from 'constants/general';
import {
  ALLOW_MULTIPLE_SUBMISSIONS_IN_SESSION,
  COMBINE_ATTACHMENTS_IN_DOWNLOADED_PDF,
  DEFAULT_MARGIN_X_IN_INCHES,
  DEFAULT_MARGIN_Y_IN_INCHES,
  DISABLE_GET_STARTED_POP_UP_KEY,
  DO_NOT_SEND_COMPLETED_PDF_KEY,
  PAGE_SETTINGS_MARGINS,
  SAVE_IN_PROGRESS_SUBMISSIONS,
  VERIFY_IDENTITY_VIA_SMS,
} from 'constants/pageSettings';
import { useRolePermission } from 'hooks/useRolePermission';
import { RootStateType } from 'store/reducers';
import { DocumentTypesType } from 'types/Documents';
import { IPageSettingsSchemaElement, PageSettingsElementTypesEnum, PageSettingsType } from 'types/PageSettingsType';
import {
  getCurrentDocumentTypeByRoute,
  getCurrentDocumentTypeByStore,
  getDocumentTypeForPageSettingsFromStorePath,
  getPageSettings,
} from 'utils/PageSettings';

const usePageSettingsStructure = () => {
  const history = useHistory();
  const { permissionUsingSmsValidation, permissionUsingReminders } = useRolePermission();

  const pageSettings: PageSettingsType | null = useSelector((state: RootStateType) => getPageSettings(state));
  const documentType: DocumentTypesType | null = useSelector((state: RootStateType) => (
    getCurrentDocumentTypeByStore(state)
  ));
  const documentTypeByRoute: DocumentTypesType | null = getCurrentDocumentTypeByRoute(history.location.pathname);
  const resourceType: string | null = useSelector(
    (state: RootStateType) => getDocumentTypeForPageSettingsFromStorePath(state),
  );

  const defaultSettingsSchema: IPageSettingsSchemaElement[] = [
    {
      key: 'margin',
      title: 'Margins',
      type: PageSettingsElementTypesEnum.Container,
      value: [
        {
          key: PAGE_SETTINGS_MARGINS.Top,
          title: 'Margin Top',
          type: PageSettingsElementTypesEnum.Number,
          value: DEFAULT_MARGIN_Y_IN_INCHES,
        },
        {
          key: PAGE_SETTINGS_MARGINS.Right,
          title: 'Margin Right',
          type: PageSettingsElementTypesEnum.Number,
          value: DEFAULT_MARGIN_X_IN_INCHES,
        },
        {
          key: PAGE_SETTINGS_MARGINS.Bottom,
          title: 'Margin Bottom',
          type: PageSettingsElementTypesEnum.Number,
          value: DEFAULT_MARGIN_Y_IN_INCHES,
        },
        {
          key: PAGE_SETTINGS_MARGINS.Left,
          title: 'Margin Left',
          type: PageSettingsElementTypesEnum.Number,
          value: DEFAULT_MARGIN_X_IN_INCHES,
        },
      ],
      isAvailable: [documentType, documentTypeByRoute].includes(DOCUMENT_TYPE),
    },
    {
      key: DISABLE_GET_STARTED_POP_UP_KEY,
      title: 'Disable Get Started Pop Up',
      type: PageSettingsElementTypesEnum.Checkbox,
      value: false,
      isAvailable: true,
      disabled: Boolean(pageSettings?.verify_identity_via_sms) || Boolean(pageSettings?.save_in_progress_submissions),
    },
    {
      key: DO_NOT_SEND_COMPLETED_PDF_KEY,
      title: 'Do not send completed PDF',
      type: PageSettingsElementTypesEnum.Checkbox,
      value: false,
      isAvailable: true,
    },
    {
      key: ALLOW_MULTIPLE_SUBMISSIONS_IN_SESSION,
      title: 'Allow multiple submissions in a single session',
      type: PageSettingsElementTypesEnum.Checkbox,
      value: false,
      isAvailable: [DOCUMENT, COLLECTION].includes(resourceType ?? ''),
    },
    {
      key: VERIFY_IDENTITY_VIA_SMS,
      title: 'Verify identity via SMS',
      type: PageSettingsElementTypesEnum.Checkbox,
      value: false,
      isAvailable: [DOCUMENT, DOCUMENT_EXECUTED].includes(resourceType ?? '') && permissionUsingSmsValidation,
      disabled: Boolean(pageSettings?.disable_get_started_pop_up),
    },
    {
      key: COMBINE_ATTACHMENTS_IN_DOWNLOADED_PDF,
      title: 'Combine Attachments in Downloaded PDF',
      type: PageSettingsElementTypesEnum.Checkbox,
      value: false,
      isAvailable: true,
      disabled: false,
    },
    {
      key: SAVE_IN_PROGRESS_SUBMISSIONS,
      title: 'Save in progress submissions',
      type: PageSettingsElementTypesEnum.Checkbox,
      value: false,
      isAvailable: permissionUsingReminders && [DOCUMENT, DOCUMENT_EXECUTED].includes(resourceType ?? ''),
      disabled: Boolean(pageSettings?.disable_get_started_pop_up),
    },
  ].filter((setting: IPageSettingsSchemaElement) => setting.isAvailable);

  if (!pageSettings) return defaultSettingsSchema;

  return defaultSettingsSchema.map((element: IPageSettingsSchemaElement) => {
    if (Array.isArray(element.value)) {
      return {
        ...element,
        value: element.value.map((subElement) => {
          const value = pageSettings[subElement.key];
          return value !== undefined ? { ...subElement, value } : subElement;
        }),
      };
    }
    const value = pageSettings[element.key];
    return value !== undefined ? { ...element, value } : element;
  });
};

export default usePageSettingsStructure;