import { FC } from 'react';

import { ATTACHMENTS_TAB, DOCUMENT_TAB, FORM_TAB } from 'constants/tabs';
import AttachmentsTabIcon from 'svg/TabIcons/AttachmentsTabIcon';
import DocumentTabIcon from 'svg/TabIcons/DocumentTabIcon';
import FormTabIcon from 'svg/TabIcons/FormTabIcon';
import { IIconObject } from 'types/PublicPage';

interface ITabIcon {
  type: string;
}

const NavigationTabIcon: FC<ITabIcon> = ({ type }) => {
  const icon: IIconObject = {
    [DOCUMENT_TAB]: <DocumentTabIcon />,
    [FORM_TAB]: <FormTabIcon />,
    [ATTACHMENTS_TAB]: <AttachmentsTabIcon />,
  };

  return icon[type] || null;
};

export default NavigationTabIcon;