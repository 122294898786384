import { FC, ReactNode } from 'react';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';

export const SortableList = SortableContainer(({ children }: { children: JSX.Element }) => children);

interface SortableItemProps {
  children: JSX.Element & ReactNode;
  index: number;
  isFormBuilderOpen?: boolean;
  fieldKey?: number;
}

const WrapperElement = SortableElement(({ children, fieldKey }: { children: JSX.Element, fieldKey: number }) => (
  <div className="sortable-element" data-field-key={fieldKey}>
    {children}
  </div>
));

export const SortableItem: FC<SortableItemProps> = ({ children, index, fieldKey = 0, isFormBuilderOpen = true }) => {
  if (isFormBuilderOpen) {
    return <WrapperElement index={index} fieldKey={fieldKey}>{children}</WrapperElement>;
  }
  return children;
};