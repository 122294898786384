import { FC } from 'react';

import { Document, Page } from 'react-pdf';

import Button from 'components/Base/Button';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import SyncLoader from 'components/SyncLoader';
import { useDocumentThumbnail } from 'hooks/signNow';
import CloseIcon from 'svg/CloseIcon';
import { IUseDocumentThumbnailReturns } from 'types/signNow/base';

import 'scss/components/SignNow/_documentThumbnail.scss';

const DocumentThumbnail: FC = () => {
  const {
    file,
    isRemoveFileModalOpen,
    onCancelRemoveFileHandler,
    onConfirmRemoveFileHandler,
    onRemoveFileHandler,
  }: IUseDocumentThumbnailReturns = useDocumentThumbnail();

  return file
    ? (
      <div className="document-thumbnail">
        <Button
          classes="close-button"
          variant="text"
          size="small"
          onClick={onRemoveFileHandler}
        >
          <CloseIcon />
        </Button>
        <Document
          file={file.fileUrl}
        >
          <Page
            loading={<SyncLoader small />}
            width={120}
            pageNumber={1}
          />
        </Document>
        <ConfirmationModal
          isOpen={isRemoveFileModalOpen}
          onCancel={onCancelRemoveFileHandler}
          onConfirm={onConfirmRemoveFileHandler}
          confirmButtonText="Yes"
          cancelButtonText="No"
          description="Are you sure you want to remove this document and start over?"
        />
      </div>
    )
    : null;
};

export default DocumentThumbnail;