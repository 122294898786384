import { ChangeEventHandler } from 'react';

import { useDispatch } from 'react-redux';

import useSmsValidationPhoneNumberFieldForMondaycom from 'hooks/MondayCom/useSmsValidationPhoneNumberField';
import { setPageSettingValueByKey } from 'store/actions/pageSettings';
import { PageSettingsElementTypesEnum, PageSettingsKeyType } from 'types/PageSettingsType';

type UsePageSettingsValueUpdateType = HTMLInputElement | HTMLSelectElement;

const usePageSettingsValueUpdate = <T extends UsePageSettingsValueUpdateType>(
  type: PageSettingsElementTypesEnum,
  key: PageSettingsKeyType,
) => {
  const dispatch = useDispatch();
  const {
    checkAndOpenMondayComModalWindow,
  } = useSmsValidationPhoneNumberFieldForMondaycom();

  const getValue = (target: UsePageSettingsValueUpdateType) => {
    switch (type) {
      case PageSettingsElementTypesEnum.Number:
        return target.value;
      case PageSettingsElementTypesEnum.Checkbox:
        return (target as HTMLInputElement).checked;
      default:
        return target.value;
    }
  };

  const updateValueHandler: ChangeEventHandler<T> = ({ target }) => {
    const value = getValue(target);
    dispatch(setPageSettingValueByKey({ key, value }));
    checkAndOpenMondayComModalWindow(key, value);
  };

  return updateValueHandler;
};

export default usePageSettingsValueUpdate;