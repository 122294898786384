import { FC } from 'react';

import usePageSettingsValueUpdate from 'hooks/pageSettings/usePageSettingsValueUpdate';
import { IPageSettingProps } from 'types/PageSettingsType';

const InputSetting: FC<IPageSettingProps> = ({ setting }) => {
  const value = setting.value as string | number;
  const setValue = usePageSettingsValueUpdate(setting.type, setting.key);

  return (
    <div className="page-settings-modal__input">
      <label>
        {setting.title}: <input type={setting.type} defaultValue={value} onChange={setValue} />
      </label>
    </div>
  );
};

export default InputSetting;