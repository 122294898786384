import { FC, memo, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Navbar } from 'reactstrap';

import DownloadIcon from '@mui/icons-material/Download';

import Button from 'components/Base/Button';
import DownloadDocument from 'components/EditorHeader/DownloadDocument';
import GoogleTranslateMenu from 'components/GoogleTranslate/GoogleTranslateMenu';
import MainCompanyLogo from 'components/MainCompanyLogo';
import { DOWNLOAD, URL_PUBLIC_FILL_OUT } from 'constants/general';
import useReadOnlyMode from 'hooks/useReadOnlyMode';
import { setPdfTemplateKey } from 'store/actions/pdfTemplates';
import { RootStateType } from 'store/reducers';
import TranslateIcon from 'svg/TranslateIcon';
import { isMobileDevice } from 'utils/mobileDeviceHelper';
import { isPDFDocument } from 'utils/PublicPage/documentTypeChecker';

const PublicHeader: FC = () => {
  const dispatch = useDispatch();
  const isReadOnlyMode = useReadOnlyMode();

  const fieldInFocus = useSelector((state: RootStateType) => state.editorSlate.fieldInFocus);
  const { data } = useSelector((state: RootStateType) => state.publicPages);
  const { styleJSON } = useSelector((state: RootStateType) => state.companyBranding);
  const { isLoading } = useSelector((state: RootStateType) => state.errorLoading);

  const isFillOutPage = window.location.pathname.split('/').includes(URL_PUBLIC_FILL_OUT);

  useEffect(() => {
    if (data && isPDFDocument(data)) {
      const url: URL = new URL(data.pdf_full_link);
      dispatch(setPdfTemplateKey(url.pathname.replace('/', '')));
    }
  }, [data?.id]);

  const isLogoAvailable = !styleJSON?.removeBrandingActive;

  return (
    <header className="public-page__header">
      <Navbar className={`public-navbar${fieldInFocus ? ' field-in-focus' : ''}`}>
        {isLoading && <div />}
        {
          isLogoAvailable && !isLoading
            ? <MainCompanyLogo />
            : <div />
        }
        <div className="d-flex align-items-center">
          {
            isFillOutPage && isReadOnlyMode && (
              <DownloadDocument
                document={data}
                render={(downloadDocumentHandler: () => void, isDisabled: boolean) => (
                  <Button
                    classes="button-simple button-contained-pink me-3"
                    disabled={isDisabled}
                    onClick={downloadDocumentHandler}
                    size="small"
                  >
                    {isMobileDevice() ? <DownloadIcon /> : DOWNLOAD}
                  </Button>
                )}
              />
            )
          }
          <div className="public-header-menu__translate-icon me-2">
            <TranslateIcon />
          </div>
          <div className="public-header-menu__translate-menu">
            <GoogleTranslateMenu />
          </div>
          {/* <PublicHeaderMenu /> */}
        </div>
      </Navbar>
    </header>
  );
};

export default memo(PublicHeader);