import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { COLLECTION_TYPE } from 'constants/general';
import ROUTES from 'constants/routes';
import {
  createMultiTemplate,
  deleteMultiTemplates,
  editMultiTemplate,
  getMultiTemplateById,
  getMultiTemplatesList,
} from 'services/api/multiTemplates';
import { history } from 'services/history';
import {
  CREATE_MULTI_TEMPLATE,
  DELETE_MULTI_TEMPLATES_START,
  DELETE_MULTI_TEMPLATES_SUCCESS,
  GET_MULTI_TEMPLATE_DETAILS,
  GET_MULTI_TEMPLATES_START,
  GET_MULTI_TEMPLATES_SUCCESS,
  SET_MULTI_TEMPLATE_DETAILS,
  UPDATE_MULTI_TEMPLATE,
} from 'store/actions/actionTypes';
import { RootStateType } from 'store/reducers';
import { safe } from 'store/sagas/errorHandlerSaga';
import { IResponseOfRequest } from 'types/Api';
import { DocumentTypesType } from 'types/Documents';
import {
  ICollectionDetails,
  ICollectionTemplate,
  IMultiTemplate,
} from 'types/MultiTemplate';
import { PublicPageDataType } from 'types/PublicPage';
import { IFormBuilderSection } from 'types/Sections';
import { ProfileInfoType } from 'types/userProfile';
import { trackAmplitudeEvent } from 'utils/amplitude/amplitudeTrackingUtlis';
import {
  COLLECTION_TEMPLATE_WAS_CREATED,
  COLLECTION_TEMPLATE_WAS_DELETED,
  COLLECTION_TEMPLATE_WAS_SAVED,
} from 'utils/amplitude/amplituteConstants';
import { getFormBuilderSectionsFromTemplates } from 'utils/FormBuilder/formBuilderHelpers';

const updateMultiTemplateListState = function* (data: ICollectionDetails, update: boolean = false) {
  const collections: IMultiTemplate[] = yield select((state) => state.user.collections);
  if (!collections.length) {
    yield put({ type: GET_MULTI_TEMPLATES_START });
    return null;
  }

  let updatedMultiTemplates: IMultiTemplate[] = [];
  if (update) {
    updatedMultiTemplates = collections.map((multiTemplate: IMultiTemplate) => {
      if (multiTemplate.id === data.id) {
        return {
          ...multiTemplate,
          name: data.name,
          modification_date: data.modification_date,
          last_modified_by: data.last_modified_by,
        };
      }
      return multiTemplate;
    });
  } else {
    updatedMultiTemplates = [
      ...collections,
      {
        creation_date: data.creation_date,
        creator: data.creator,
        id: data.id,
        name: data.name,
        modification_date: data.modification_date,
        last_modified_by: data.last_modified_by,
      },
    ];
  }
  yield put({
    type: GET_MULTI_TEMPLATES_SUCCESS,
    payload: {
      data: updatedMultiTemplates,
      isSuccess: true,
    },
  });
};

const getMultiTemplateDetails = function* (
  { payload: { id, isFormBuilderAvailable } }
  : { type: string; payload: { id: string; isFormBuilderAvailable: boolean } },
) {
  const multiTemplateResponse: IResponseOfRequest<ICollectionDetails> = yield getMultiTemplateById(id);

  const formBuilderSections: IFormBuilderSection[] = getFormBuilderSectionsFromTemplates(
    multiTemplateResponse?.data?.templates?.map(
      (template: ICollectionTemplate) => template.template,
    ) as PublicPageDataType[],
  );

  const multiTemplate: ICollectionDetails = {
    ...multiTemplateResponse.data,
    ...(isFormBuilderAvailable && !multiTemplateResponse?.data?.form_builder_structure
      ? { form_builder_structure: formBuilderSections }
      : {}
    ),
  };

  yield put({ type: SET_MULTI_TEMPLATE_DETAILS, payload: multiTemplate });
};

const createMultiTemplateDetails = function* ({ payload }: any) {
  const newMultiTemplate: IResponseOfRequest<ICollectionDetails> = yield createMultiTemplate(payload);
  if (newMultiTemplate.data && newMultiTemplate.data?.id) {
    const newData = newMultiTemplate.data;
    const userInfo: ProfileInfoType = yield select((state: RootStateType) => state.profile.profileInfo);
    yield put({ type: SET_MULTI_TEMPLATE_DETAILS, payload: newData });
    yield updateMultiTemplateListState(newData);

    trackAmplitudeEvent({
      eventName: COLLECTION_TEMPLATE_WAS_CREATED,
      userInfo,
    });

    yield toast.success('Collection created');
    yield history.push(`${ROUTES.COLLECTIONS_EDITOR}/${newData.id}`);
  } else {
    return toast.error('An error occurred while saving the collection');
  }
};

const updateMultiTemplate = function* ({ payload }: any) {
  const multiTemplateResponse: IResponseOfRequest<ICollectionDetails> = yield editMultiTemplate(payload.id, payload);
  if (multiTemplateResponse.data && multiTemplateResponse.data?.id) {
    const updatedData = multiTemplateResponse.data;
    const userInfo: ProfileInfoType = yield select((state: RootStateType) => state.profile.profileInfo);
    yield put({ type: SET_MULTI_TEMPLATE_DETAILS, payload: updatedData });

    yield updateMultiTemplateListState(updatedData, true);
    trackAmplitudeEvent({
      eventName: COLLECTION_TEMPLATE_WAS_SAVED,
      userInfo,
    });

    yield toast.success('Collection updated');
  } else {
    return toast.error('An error occurred while saving the collection');
  }
};

const getMultiTemplates = function* () {
  const { data } = yield call(getMultiTemplatesList);
  yield put({
    type: GET_MULTI_TEMPLATES_SUCCESS,
    payload: {
      data,
      isSuccess: true,
    },
  });
};

const deleteMultiTemplatesSaga = function* ({ payload }: {
  type: typeof DELETE_MULTI_TEMPLATES_START,
  payload: {
    ids: number[],
    resourceType: DocumentTypesType
  },
}) {
  const resourceType = payload.resourceType;
  yield call(deleteMultiTemplates, payload.ids, resourceType);

  if (resourceType === COLLECTION_TYPE) {
    const userInfo: ProfileInfoType = yield select((state: RootStateType) => state.profile.profileInfo);
    trackAmplitudeEvent({
      eventName: COLLECTION_TEMPLATE_WAS_DELETED,
      userInfo,
    });
  }

  const storeSelectorAction = (resourceType === COLLECTION_TYPE)
    ? (state: RootStateType) => state.user.collections
    : (state: RootStateType) => state.user.collectionsExecuted;
  const dataFromStore: IMultiTemplate[] = yield select(storeSelectorAction);

  const filteredData: IMultiTemplate[] = dataFromStore.filter(
    (template: IMultiTemplate) => !payload.ids.includes(template.id),
  );
  yield put({
    type: DELETE_MULTI_TEMPLATES_SUCCESS,
    payload: { data: filteredData, resourceType },
  });
};

export default [
  takeLatest(GET_MULTI_TEMPLATE_DETAILS, safe(getMultiTemplateDetails)),
  takeLatest(CREATE_MULTI_TEMPLATE, safe(createMultiTemplateDetails)),
  takeLatest(UPDATE_MULTI_TEMPLATE, safe(updateMultiTemplate)),
  takeLatest(GET_MULTI_TEMPLATES_START, safe(getMultiTemplates)),
  takeLatest(DELETE_MULTI_TEMPLATES_START, safe(deleteMultiTemplatesSaga)),
];