import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { SUCCESS } from 'constants/api';
import { deleteStripeSubscription, getStripeSubscriptions, renewStripeSubscription } from 'services/api/payment';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { setActiveStripeProductID, setActiveStripeSubscription } from 'store/actions/payment';
import { RootStateType } from 'store/reducers';
import {
  IStripeSubscription,
  IStripeSubscriptionDeleteResponse,
  IStripeSubscriptionResponse,
} from 'types/payment/Stripe';

const useStripeSubscription = () => {
  const dispatch = useDispatch();
  const { subscriptionID } = useSelector((state: RootStateType) => state.payment.stripe);
  const [subscription, setSubscription] = useState<IStripeSubscription | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  const currentPlanHandler = async () => {
    setIsLoading(true);
    const response = await apiErrorHandler<IStripeSubscriptionResponse>(getStripeSubscriptions);
    if (response.code === SUCCESS) {
      const currentSubscription = response.data.subscription;

      if (currentSubscription !== null) {
        setSubscription(currentSubscription);
        dispatch(setActiveStripeSubscription(currentSubscription));
        dispatch(setActiveStripeProductID({
          activeProductID: currentSubscription.productID,
          activePriceID: currentSubscription.priceID,
          subscriptionID: currentSubscription.id,
        }));
      }
    }
    setIsLoading(false);
  };

  const cancelCurrentSubscription = async () => {
    const response = await apiErrorHandler<
      IStripeSubscriptionDeleteResponse,
      string
    >(deleteStripeSubscription, subscriptionID);
    if (response.code === SUCCESS) {
      currentPlanHandler();
      toast.success('Your subscription was canceled successfully.');
    }
  };

  const onRenewSubscription = async () => {
    const response = await apiErrorHandler<
      IStripeSubscriptionDeleteResponse,
      string
    >(renewStripeSubscription, subscriptionID);
    if (response.code === SUCCESS) {
      currentPlanHandler();
      toast.success('Your subscription cancellation has been successfully reversed.');
    }
  };

  const onCancelSubscription = () => {
    setIsConfirmModalOpen(true);
  };

  const onCancelModalHandler = () => {
    setIsConfirmModalOpen(false);
  };

  const onConfirmModalHandler = () => {
    setIsConfirmModalOpen(false);
    cancelCurrentSubscription();
  };

  useEffect(() => {
    currentPlanHandler();
  }, []);

  return {
    currentPlanHandler,
    subscription,
    onCancelSubscription,
    onRenewSubscription,
    isLoading,
    isConfirmModalOpen,
    onCancelHandler: onCancelModalHandler,
    onConfirmHandler: onConfirmModalHandler,
  };
};

export default useStripeSubscription;