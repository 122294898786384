import { InputType } from 'reactstrap/types/lib/Input';

import { Element as SlateElement } from 'slate';

import {
  SELECT_FIELD,
  SELECT_FIELD_TYPE_CHECKBOX,
  SELECT_FIELD_TYPE_RADIO,
  SELECT_FIELD_TYPE_RADIO_CHECKBOX,
} from 'constants/editors';
import { LABEL_CONFIGURATION, SelectFieldOptionsType } from 'types/Editor';

export const getCheckboxPrintClass = (viewMode?: SelectFieldOptionsType): string => (
  viewMode === SELECT_FIELD_TYPE_CHECKBOX ? 'print-checkbox-input' : ''
);

export const getInputTypeByViewMode = (viewMode: SelectFieldOptionsType): InputType => (
  viewMode === SELECT_FIELD_TYPE_RADIO ? SELECT_FIELD_TYPE_RADIO : SELECT_FIELD_TYPE_CHECKBOX
);

export const getIsRadioOrCheckbox = (viewMode: SelectFieldOptionsType): boolean => (
  SELECT_FIELD_TYPE_RADIO_CHECKBOX.includes(viewMode)
);

export const isCheckboxSelectView = (viewMode: SelectFieldOptionsType): boolean => (
  [SELECT_FIELD_TYPE_CHECKBOX].includes(viewMode)
);

export const isSingleCheckboxField = (field: Partial<SlateElement>) => (
  field.type === SELECT_FIELD
  && field.selectFieldType === SELECT_FIELD_TYPE_CHECKBOX
  && field.labelConfiguration === LABEL_CONFIGURATION.NONE
  && field.options?.length === 1
);