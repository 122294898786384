import { useSelector } from 'react-redux';

import { useRolePermission } from 'hooks/useRolePermission';
import { RootStateType } from 'store/reducers';
import { PublicPageDataType } from 'types/PublicPage';

const usePermissionUsingRememberFormProgress = (): boolean => {
  const isAuthenticated: boolean = useSelector((state: RootStateType) => state.user.isAuthenticated);
  const restoreDataModalWasVisible: boolean = useSelector((state: RootStateType) => (
    state.persistentStorage.restoreDataModalWasVisible
  ));

  const publicPageData: PublicPageDataType | null = useSelector((state: RootStateType) => (
    state.publicPages.data
  ));

  const { permissionUsingRememberFormProgress } = useRolePermission();

  const isPossibleToUse = (publicPageData && publicPageData.remember_form_progress)
    || permissionUsingRememberFormProgress;

  return isAuthenticated && restoreDataModalWasVisible && isPossibleToUse;
};

export default usePermissionUsingRememberFormProgress;