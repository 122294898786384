import React, { BaseSyntheticEvent, useEffect, useRef, useState } from 'react';

import { Button, Input, InputGroup } from 'reactstrap';

import Check from '@mui/icons-material/Check';
import { Transforms } from 'slate';
import { useSlate } from 'slate-react';

import { useOutsideAlerter } from 'hooks/useOutsideAlerter';
import { LinkIcon, UnlinkIcon } from 'svg/FieldsToolbarsIcons';
import { IBlockButtonProps } from 'types/Editor';
import { insertLink, isLinkActive, unwrapLink } from 'utils/editorLinkHelpers';

const LinkButton: React.FC<IBlockButtonProps> = ({ format, icon }) => {
  const editor = useSlate();
  const modalRef = useRef(null);
  const [modal, setModal] = useState<boolean>(false);
  const [selection, setSelection] = useState<any>(null);
  const [url, setUrl] = useState<string>('');
  const [createLinkModal, setCreateLinkModal] = useState<boolean>(false);
  useOutsideAlerter(modalRef, setModal);

  useEffect(() => {
    if (!editor.selection) return;
    if (!modal) return;
    setSelection(editor.selection);
  }, [editor.selection, modal]);

  const onSubmit = () => {
    if (!url.length) return;
    if (selection) Transforms.select(editor, selection);
    insertLink(editor, format, { url, blank: true });
    setCreateLinkModal(false);
    setModal(false);
  };

  const onChangeInput = (event: BaseSyntheticEvent) => {
    setUrl(event.target.value);
  };

  const onModal = () => {
    setModal(true);
  };

  const active = isLinkActive(editor, format);

  return (
    <div
      ref={modalRef}
      className="d-inline-block"
      onClick={onModal}
      role="button"
      tabIndex={-1}
    >
      <span
        className={`btn-toolbar-editor ${active && 'text-dark'} horizontal-toolbar-icon`}
        style={{ fill: active ? 'black' : '#ccc' }}
      >
        {icon}
      </span>
      <div
        className={`position-absolute modal-horizontal-toolbar-editor w-auto ${modal ? 'd-block' : 'd-none'}`}
      >
        <ul className="toolbar-dropdown-list p-2">
          <li
            onClick={() => setCreateLinkModal(!createLinkModal)}
            className="toolbar-dropdown-list_item user-select-none"
          >
            <LinkIcon />
            <span className="ps-1">Create Link</span>
          </li>
          {createLinkModal && (
            <li
              className="toolbar-dropdown-list p-2 create-link-modal position-absolute horizontal-toolbar-modal"
              data-toolbar-id="horizontal-toolbar-modal"
            >
              <InputGroup size="sm">
                <Input
                  type="url"
                  value={url}
                  className="toolbar-input"
                  onChange={onChangeInput}
                  placeholder="Paste link"
                />
                <Button
                  size="sm"
                  type="button"
                  className="submit_link_button"
                  onClick={onSubmit}
                  disabled={!url}
                >
                  <Check />
                </Button>
              </InputGroup>
            </li>
          )}
          <li
            className="toolbar-dropdown-list_item"
            onClick={() => unwrapLink(editor, format)}
          >
            <UnlinkIcon />
            <span className="ps-1">Remove Link</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default LinkButton;