import { FC } from 'react';

import cn from 'classnames';
import { IStepManagerChildComponentProps, IStepManagerConfigItem } from 'types/signNow/base';

interface IBreadcrumbItemProps {
  currentStep: number;
  item: IStepManagerConfigItem;
  isDisabled: boolean;
}

const BreadcrumbItem: FC<IBreadcrumbItemProps & IStepManagerChildComponentProps> = ({
  currentStep,
  item,
  goToStep,
  isDisabled,
}) => (
  <li className="breadcrumbs__item">
    <button
      disabled={isDisabled}
      className={cn(
        'breadcrumbs__button',
        {
          'breadcrumbs__button--active': currentStep === item.step,
          'breadcrumbs__button--visited': currentStep >= item.step,
        },
      )}
      type="button"
      onClick={() => (goToStep ? goToStep(item.step) : undefined)}
    >
      {item.name}
    </button>
  </li>
);

export default BreadcrumbItem;