import { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useStepHandler } from 'components/SignNow/StepManager/StepHandlerContext';
import { PDF_TYPE } from 'constants/general';
import ROUTES from 'constants/routes';
import { ShareViewModes } from 'constants/shareViewModes';
import {
  setAllPdfFieldsAndInfo,
  setPdfTemplateFileLink,
  setPdfTemplateKey,
  setPdfTemplateWidth,
} from 'store/actions/pdfTemplates';
import { setLinkViewMode, setSendDocumentsInfo, setSigners } from 'store/actions/sendDocuments';
import { setSignNowDocumentInfo } from 'store/actions/signNow';
import { editDocumentDetails, getDocumentDetails } from 'store/actions/userData';
import { RootStateType } from 'store/reducers';
import { IDocumentDetails } from 'types/Documents';
import { MatchParams } from 'types/Route';
import { IUseSetFields, UseSetFieldsHookType } from 'types/signNow/base';
import { trimHTMLValue } from 'utils/descriptionHelper';
import { isNotEmptyObject } from 'utils/isEmptyObject';
import { createFieldsFromPdfFormfields, getPdfUrlAndWidth } from 'utils/pdfTemplatesHelpers';
import { getSignersFromAssignments } from 'utils/sendDocumentsHelpers';

const useSetFields: UseSetFieldsHookType = ({
  onNextHandler = () => null,
}): IUseSetFields => {
  const { id }: MatchParams = useParams();
  const dispatch = useDispatch();
  const { setOnNext, isNextButtonDisabled, setIsNextButtonDisabled } = useStepHandler();
  const {
    document,
    file,
    pdfFormFields,
    currentSigner,
  } = useSelector((state: RootStateType) => state.signNow);
  const { isSelectFieldError } = useSelector((state: RootStateType) => state.editorSlate);
  const { documentDetails } = useSelector((state: RootStateType) => state.user);
  const {
    pdfTemplateFields,
    pdfTemplateInfo: { templateDescription },
  } = useSelector((state: RootStateType) => state.pdfTemplates);
  const { assignments } = useSelector((state: RootStateType) => state.editorSlate);

  useEffect((): void => {
    if (isNotEmptyObject(isSelectFieldError)) {
      const isError: boolean = Object.values(isSelectFieldError).some((item: boolean) => item);
      setIsNextButtonDisabled(isError);
    }
  }, [isSelectFieldError, setIsNextButtonDisabled]);

  useEffect((): void => {
    if (id ?? document.id) {
      dispatch(getDocumentDetails(id ?? document.id));
    }
  }, [dispatch, document.id, id]);

  useEffect((): void => {
    if (!documentDetails) return undefined;
    if (file) {
      dispatch(setPdfTemplateKey(file.fileKey));
      dispatch(setPdfTemplateWidth(file.pdfWidth));
      dispatch(setPdfTemplateFileLink(file.fileUrl));
    } else {
      const [pdfFileUrl, pdfFileWidth] = getPdfUrlAndWidth(documentDetails.template?.pdf_file_url);
      dispatch(setPdfTemplateKey(pdfFileUrl));
      dispatch(setPdfTemplateWidth(parseInt(pdfFileWidth)));
      dispatch(setPdfTemplateFileLink(documentDetails.pdf_full_link));
    }
    dispatch(setAllPdfFieldsAndInfo({
      fields: documentDetails.content_json,
      info: {
        templateName: documentDetails.name,
        templateTitle: documentDetails.document_title,
        templateDescription: documentDetails.description,
      },
    }));
    if (pdfFormFields && pdfFormFields.length) {
      createFieldsFromPdfFormfields(pdfFormFields, dispatch, currentSigner);
    }
    dispatch(setSignNowDocumentInfo({
      id: documentDetails.id,
      title: documentDetails.name,
    }));
  }, [dispatch, documentDetails, file]);

  const onNextHandlerWrapper = useCallback((): void => {
    if (isNextButtonDisabled) return undefined;
    if (!document.title || document.isError) {
      dispatch(setSignNowDocumentInfo({
        isError: true,
      }));
      return undefined;
    }

    const editedPdfDocument: Partial<IDocumentDetails> = {
      name: document.title.trim(),
      document_title: document.title.trim(),
      description: trimHTMLValue(templateDescription),
      doctype_id: documentDetails.doctype.id,
      state_id: documentDetails.state.id,
      content_json: pdfTemplateFields,
      type: PDF_TYPE,
      assignments,
    };

    dispatch(setLinkViewMode(ShareViewModes.SHARE_MODE_DOCUMENT));
    dispatch(setSendDocumentsInfo(
      [{
        id: Number(id),
        type: PDF_TYPE,
        position: 0,
        name: document.title.trim(),
      }],
      ROUTES.DASHBOARD,
      PDF_TYPE,
    ));
    const signers = getSignersFromAssignments(assignments);
    dispatch(setSigners(signers));

    dispatch(editDocumentDetails({
      id,
      document: editedPdfDocument,
      completeFlow: false,
    }));

    onNextHandler();
  }, [
    assignments,
    dispatch,
    documentDetails,
    id,
    isNextButtonDisabled,
    onNextHandler,
    pdfTemplateFields,
    templateDescription,
    document.title,
    document.isError,
  ]);

  useEffect(() => {
    setOnNext(() => onNextHandlerWrapper);
  }, [setOnNext, onNextHandlerWrapper]);

  return {
    isNextButtonDisabled,
    onNextHandlerWrapper,
  };
};

export default useSetFields;